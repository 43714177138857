import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SignatureDetails,
  SignatureDetailsResolverResponse,
  SignatureSaveData,
  SignaturesListResolverResponse,
} from './admin-signatures.types';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../../core/api.urls';
import { ApiResponse, HttpRequestOptions } from '../../../core/global.types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminSignaturesService {

  constructor(
    private http: HttpClient,
  ) {}

  createSignatureDetails(): SignatureDetails {
    return {
      id: 0,
      content: '',
      macro: '',
      name: '',
      type: 'text',
      description: '',
    } as SignatureDetails;
  }

  deleteSignatureV2(signatureId: number): Observable<void> {
    const url = ApiUrls.getKey('DeleteSignatureV2')
      .replace(/{signatureId}/gi, String(signatureId));
    return this.http.delete(url)
      .pipe(map(() => void(0)));
  }

  getSignatureDetailsV2(signatureId: number): Observable<SignatureDetailsResolverResponse> {
    const url = ApiUrls.getKey('GetSignatureDetailsV2')
      .replace(/{signatureId}/gi, String(signatureId));
    return this.http.get<ApiResponse<SignatureDetailsResolverResponse>>(url)
      .pipe(map(response => response.data));
  }

  getSignatureListV2(): Observable<SignatureDetails[]> {
    const url = ApiUrls.getKey('GetAllSignaturesV2');
    return this.http.get<ApiResponse<SignatureDetails[]>>(url)
      .pipe(map(response => response.data));
  }

  preCheckMacroExistsV2(macro: string): Observable<SignatureDetails[]> {
    const url = ApiUrls.getKey('CheckMacroExistsV2')
      .replace(/{macro}/gi, macro);
    return this.http.get<ApiResponse<SignatureDetails[]>>(url)
      .pipe(map(response => response.data));
  }

  saveSignatureV2(signatureId: number, signature: SignatureSaveData): Observable<SignatureDetails> {
    let url = signatureId === 0 ?
      ApiUrls.getKey('CreateSignatureV2') :
      ApiUrls.getKey('SaveSignatureV2')
        .replace(/{signatureId}/gi, String(signatureId));
    return this.http.post<ApiResponse<any>>(url, signature)
      .pipe(map(response => response.data.signature));
  }

  copySignature(signature: SignatureDetails, macroName: string): Observable<SignatureDetails> {
    const url = ApiUrls.getKey('CopySignature').replace('{signatureId}', String(signature.id));
    const payload = {
      title: macroName
    }
    return this.http.post<ApiResponse<SignatureDetails>>(url, payload, HttpRequestOptions)
      .pipe(map(response => response.textMacro));
  }
}
