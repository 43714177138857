import { Core } from './core.types';

export class IconHelper {

  static svgIconForObjType(type: Core.DistributableType, objSubType?: Core.CourseType) {
    switch (type) {
      case Core.DistributableType.lms_course:
        if (objSubType === Core.CourseType.Recording) {
          return 'file-video-outline'
        }
        return 'laptop';
      case Core.DistributableType.lms_curriculum:
        return 'school-outline';
      case Core.DistributableType.lms_offlineCnt:
        if (objSubType === Core.CourseType.Seminar) {
          return 'account-group';
        } else if (objSubType === Core.CourseType.Virco) {
          return 'monitor';
        } else if (objSubType === Core.CourseType.Hybrid) {
          return 'cast-education';
        }
        return 'earth';
      case Core.DistributableType.lms_offline_event:
        return 'clipboard-clip-text-outline';
      case Core.DistributableType.lms_vroom_group:
        return 'monitor-cellphone-star';
      case Core.DistributableType.lms_context:
        return 'script-text-outline';
      case Core.DistributableType.lms_workflow:
      case Core.DistributableType.skilltarget:
        return 'sitemap-outline';
      default:
        return 'crosshairs-question';
      }
  }

  static svgIconForType(mimeType: string): string {
    switch ( mimeType ) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/giff':
        return 'file-image';
      case 'application/pdf':
        return 'file';
      case 'text/csv':
        return 'file-delimited';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'file-word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'file-excel';
      default:
        return 'file';
    }
  }
}
