<div class="container" [@.disabled]="disableAnimations$ | async">
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@syscheck-title">System check</span>
    </div>
  </rag-page-header>

  <div @cardAnimation class="card-container">
    <ng-template #actions let-description="description" let-icon="icon" let-support="support">
      <mat-card-actions>
        <mat-icon matTooltip="{{description}}" matTooltipPosition="above" svgIcon="{{icon}}"></mat-icon>
        <mat-icon *ngIf="support === sysCheckSupport.Supported"
                  class="correct" matTooltip="OK" matTooltipPosition="above"
                  svgIcon="shield-check"></mat-icon>
        <mat-icon
          *ngIf="support !== sysCheckSupport.Supported" color="warn" i18n-matTooltip="@@warning_tooltip"
          matTooltip="Warning"
          matTooltipPosition="above" svgIcon="alert"></mat-icon>
      </mat-card-actions>
    </ng-template>

    <!-- OS -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_os_header">OS</span>
        </mat-card-title>
        <mat-card-subtitle>{{sysCheck.osDescr()}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: sysCheck.osIcon(), description: sysCheck.osDescr(), support: sysCheck.osSupport()}">
        </ng-container>
        <!--OS not recognized! -->
        <div *ngIf="sysCheck.osSupport() !== 0" class="card-error">
          <button (click)="showHintsOsHelper(sysCheck.osSupport())" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>

        </div>
      </mat-card-content>
    </mat-card>

    <!-- Browser -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_browser_header">Browser</span>
        </mat-card-title>
        <mat-card-subtitle>{{sysCheck.browserDescr()}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: sysCheck.browserIcon(), description: sysCheck.browserDescr(), support: sysCheck.browserSupport()}">
        </ng-container>
        <div *ngIf="sysCheck.browserSupport() !== 0" class="card-error">

          <button (click)="showHintsBrowserHelper(sysCheck.browserSupport())" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>

        </div>
      </mat-card-content>
    </mat-card>

    <!-- Device -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_device_header">Device</span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="sysCheck.isDesktop">
          <span i18n="@@syscheck_device_desktop">Desktop</span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="sysCheck.isMobile">
          <span i18n="@@syscheck_device_smartphone">Smartphone</span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="sysCheck.isTablet">
          <span i18n="@@syscheck_device_tablet">Tablet</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <ng-container
          *ngTemplateOutlet="actions; context: {icon: sysCheck.screenIcon(), description: sysCheck.resolutionDescr(), support:sysCheck.deviceSupport()}">
        </ng-container>
        <div *ngIf="sysCheck.deviceSupport() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('SYSCHECK_ERROR_RESOLUTION_MOBILE')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Resolution -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@global_resolution">Resolution</span>
        </mat-card-title>
        <mat-card-subtitle>{{sysCheck.resolutionDescr()}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: 'image-size-select-actual', description: sysCheck.resolutionDescr(), support: sysCheck.resolutionSupported()}">
        </ng-container>
        <div *ngIf="sysCheck.resolutionSupported() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('SYSCHECK_ERROR_RESOLUTION_MOBILE')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>

        </div>
      </mat-card-content>
    </mat-card>

    <!-- Color depth -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_color_depth_header">Color depth</span>
        </mat-card-title>
        <mat-card-subtitle>{{sysCheck.screen.colorDepth}} Bit</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: 'palette', description: '', support: sysCheck.colorDepthSupport()}">
        </ng-container>
        <div *ngIf="sysCheck.colorDepthSupport() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('SYSCHECK_ERROR_COLORDEPTH_16')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>


        </div>
      </mat-card-content>
    </mat-card>

    <!-- Cookies -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_cookies_header">Cookies</span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="sysCheck.cookieEnabled">
          <span i18n="@@syscheck_cookies_subtitle">Cookies allowed</span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="!sysCheck.cookieEnabled">
          <span i18n="@@syscheck_cookies_denied_subtitle">Cookies not allowed</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: 'cookie', description: '', support: sysCheck.cookiesSupport()}">
        </ng-container>
        <div *ngIf="sysCheck.cookiesSupport() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('SYSCHECK_ERROR_COOKIES_DISABLED')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Local storage -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_localstorage_header">Local storage</span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="sysCheck.cookieEnabled">
          <span i18n="@@syscheck_localstorage_subtitle">Local storage is enabled</span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="!sysCheck.cookieEnabled">
          <span i18n="@@syscheck_localstorage_denied_subtitle">Local storage is not enabled</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <ng-container
          *ngTemplateOutlet="actions; context: {icon: 'sd', description: '', support: sysCheck.cookiesSupport()}">
        </ng-container>
        <div *ngIf="sysCheck.cookiesSupport() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('localStorage')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- PDF -->
    <mat-card appearance="outlined" class="rag-card fadeIn">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@syscheck_pdf">PDF</span>
        </mat-card-title>
        <mat-card-subtitle *ngIf="sysCheck.supportsPDF">
          <span i18n="@@syscheck_pdf_subtitle">PDF is supported</span>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="!sysCheck.supportsPDF">
          <span i18n="@@syscheck_pdf_not_supported_subtitle">PDF is not supported</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <mat-dialog-container
          *ngTemplateOutlet="actions; context: {icon: 'file-pdf-box', description: '', support: sysCheck.pdfSupport()}">
        </mat-dialog-container>
        <div *ngIf="sysCheck.pdfSupport() !== SysCheckSupport_Supported" class="card-error">
          <button (click)="showHints('SYCHECK_ERROR_PDF_NOT_SUPPORTED')" color="warn"
                  mat-stroked-button>
            <span i18n="@@sys-hint-error">Hint</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="send-results-wrapper">
    <button (click)="showTips()" class="fadeIn ml" color="primary" mat-stroked-button>
      <span i18n="@@button_8_tips">8 Tips on Handling Courses</span>
    </button>
    <button [queryParams]="{includeSysCheck: true}" color="primary"
            mat-flat-button routerLink="/contact">
      <span i18n="@@button_problem">Contact support</span>
    </button>
  </div>
</div>

