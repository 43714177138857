import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { PrincipalService } from './principal/principal.service';

type ParseResult<T> =
  | { parsed: T; hasError: false; error?: undefined }
  | { parsed?: undefined; hasError: true; error?: unknown };

@Injectable({
  providedIn: 'root'
})
export class UserSettingsResolverService<T = any> implements Resolve<T> {

  constructor(private principalService: PrincipalService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T | null> {
    const userSettingsContext = route.data?.settingsContext;
    if (userSettingsContext === undefined) {
      return of(null);
    }

    if (!this.principalService.isLogged) {
      return of(null);
    }

    // parse input text as of T, setup error if any
    const safeJsonParse = <K>(guard: (o: any) => o is K) => (text: string): ParseResult<K> => {
      if (text === undefined || '' === text) {
        text = '{}';
      }
      const parsed = JSON.parse(text);
      return guard(parsed) ? { parsed, hasError: false } : { hasError: true };
    };

    return this.principalService
      .getUserSettings(userSettingsContext, true)
      .pipe(map(userSettings => safeJsonParse(this.isCorrectType)(userSettings)))
      .pipe(tap(result => {
        if (result.hasError) {
          console.error(result.error);
        }
      }))
      .pipe(map(result => result.parsed))
      .pipe(take(1)); // because of reusing cached stream which might already been once completed
  }

  private isCorrectType(someObj: any): someObj is T {
    // we can perform here some validation for generic fields
    return true;
  }


}
