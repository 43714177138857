import { Catalogs } from './catalog.types';

export class CatalogHelper {

  static bookableFromBooking(catalogBooking: Catalogs.CatalogBooking): Catalogs.Bookable {
    if (catalogBooking == null) {
      return null;
    }
    return {
      id: catalogBooking.id,
      objType: catalogBooking.objType,
      catalogBooking
    };
  }

  static isInMyAccount(bookable: Catalogs.Bookable) {
    if (bookable == null) {
      return false;
    }
    return bookable.distributed || CatalogHelper.isBooked(bookable.catalogBooking);
  }

  static isBooked(catalogBooking?: Catalogs.CatalogBooking) {
    const actionCount = catalogBooking?.actions?.length;
    if ( !(actionCount > 0) ) {
      // there are no booking actions recorded -> nothing booked
      return false;
    }

    // check that the booking is active and the last action is of type "BOOK"
    return catalogBooking.status === 'ACTIVE' &&
      // this assumes that the actions are sorted by create date (descending)
      catalogBooking.actions[actionCount - 1].actionType === 'BOOK';
  }

  static isBookingPending(catalogBooking?: Catalogs.CatalogBooking) {
    const actionCount = catalogBooking?.actions?.length;
    if ( !(actionCount > 0) ) {
      // there are no booking actions recorded -> nothing pending
      return false;
    }

    // check that the booking is active and the last action is of type "REQUEST"
    return catalogBooking.status === 'ACTIVE' &&
      // this assumes that the actions are sorted by create date (descending)
      catalogBooking.actions[actionCount - 1].actionType === 'REQUEST';
  }

  static isBookingStatusMatching(bookable: Catalogs.Bookable, query: string) {
    query = query?.trim() ?? '';
    if ( query === '' ) {
      // no query implies anything matches
      return true;
    }

    if ( bookable == null ) {
      // hide empty entries
      return false;
    }

    switch ( query ) {
      case 'not_booked':
        return CatalogHelper.isAvailableForBooking(bookable);

      case 'booked':
        return CatalogHelper.isBooked(bookable.catalogBooking);

      case 'pending':
        return CatalogHelper.isBookingPending(bookable.catalogBooking);

      case 'assigned':
        return CatalogHelper.isAssigned(bookable);

      default:
        return false;
    }
  }

  static isAssigned(bookable: Catalogs.Bookable): boolean {
    if ( bookable.distributed !== true ) {
      // content has not been assigned by an administrator
      return false;
    }

    // content does not have any booking progress -> must have been assigned by an administrator
    return bookable.catalogBooking == null;
  }

  static isAvailableForBooking(bookable: Catalogs.Bookable) {
    if ( bookable.distributed ) {
      // content has been assigned by an administrator
      return false;
    }

    if ( !(bookable.catalogBooking?.actions?.length > 0) ) {
      // there are no booking actions recorded -> may book
      return true;
    }

    // COMPLETE indicates (somehow) that the catalog entry is available for booking
    return bookable.catalogBooking.status === 'COMPLETE';
  }

}
