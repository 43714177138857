import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../../../core/core.module';
import { UserCurriculumDetailsComponent } from './user-curriculum-details.component';
import { UserCurriculumResolver } from './user-curriculum.resolver';
import { UserCurriculumService } from './user-curriculum.service';
import { ControllingCurriculumModule } from '../../../../component/controlling/controlling-curriculum/controlling-curriculum.module';
import { ControllingCurriculumMultiActionsModule } from '../../../../component/controlling/controlling-curriculum-multi-actions/controlling-curriculum-multi-actions.module';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [
    UserCurriculumDetailsComponent,
  ],
  exports: [
    UserCurriculumDetailsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    PageHeaderComponent,
    ControllingCurriculumModule,
    ControllingCurriculumMultiActionsModule,
  ],
  providers: [
    UserCurriculumService,
    UserCurriculumResolver,
  ],
})
export class UserCurriculumDetailsModule {
}
