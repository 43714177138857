<rag-dialog-header [closingEnabled]="true">
  <span i18n="@@global_preview">Preview</span>
</rag-dialog-header>
<div mat-dialog-content class="content">
  <ng-container *ngIf="data.version === 1">
    <rag-login [previewMode]="true" [texts]="data.texts"></rag-login>
  </ng-container>
  <ng-container *ngIf="data.version === 2">
    <rag-login-v2 [previewMode]="true" [texts]="data.texts"></rag-login-v2>
  </ng-container>
</div>
