import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { MatAutocompleteActivatedEvent, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Observable, Subject, tap } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule
  ],
  selector: 'rag-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: TagsComponent },  // part of MatFormFieldControl interface
  ],
})
export class TagsComponent implements OnInit, OnDestroy, MatFormFieldControl<Array<string>>, ControlValueAccessor {

  static nextId = 0;

  @HostBinding() id = `rag-tags-input-${TagsComponent.nextId++}`;
  @ViewChild('tagInput', {static: true}) inputElement: ElementRef;

  // @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() allTags: Array<string>;
  @Input()
  get placeholder() {
    return this._placeHolder;
  }
  set placeholder(v: string) {
    this._placeHolder = v;
    this._stateChanges.next();
  }

  allTags$: Observable<Array<string>>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  stateChanges: Observable<void>;
  focused: boolean;

  get empty() {
    return this._value == null || this._value.length === 0;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(required) {
    this._required = coerceBooleanProperty(required);
    this._stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.inputElement.nativeElement.disabled = this._disabled;
    this._stateChanges.next();
  }

  get errorState(): boolean {
    return this._touched && this.empty && this._required;
  }

  controlType = 'rag-tags-input';

  autofilled?: boolean;

  private _value: Array<string>;
  private _placeHolder: string;
  private _stateChanges = new Subject<void>();
  private _required = false;
  private _disabled = false;
  private _touched = false;
  private _optionSelected = false;
  private _allTags$ = new CachedSubject<Array<string>>([]);
  private _touchedFn: any;
  private _changeFn: any;

  get value() {
    return this._value;
  }
  set value(value: Array<string> | null) {
    this._value = value;
    this._stateChanges.next();
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.stateChanges = this._stateChanges.asObservable().pipe(tap(_ => this._changeFn?.(this._value)));
    this.allTags$ = this._allTags$.asObservable();
    if ( this.ngControl != null ) {
        this.ngControl.valueAccessor = this;
      }
  }

  ngOnInit(): void {
    this._allTags$.next(this.allTags);
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.inputElement.nativeElement;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.inputElement.nativeElement.focus();
    }
  }

  writeValue(v: string[] | null): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this._changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._touchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.inputElement == null) {
      return;
    }
    this._disabled = isDisabled;
    this._stateChanges.next();
    this.inputElement.nativeElement.disabled = isDisabled;
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this._stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this.inputElement.nativeElement.contains(event.relatedTarget as Element)) {
      this._touched = true;
      this.focused = false;
      // this.onTouched();
      this._stateChanges.next();
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value ?? '').trim().toLocaleLowerCase();
    event.chipInput.clear();

    if ( value && !this._optionSelected ) {
      if ( this._value.indexOf(value) === -1 ) {
        this._value.push(value);
        this.allTags.push(value);
        this._stateChanges.next();
      } else {
        return;
      }
    }
    this._allTags$.next(this.allTags);
  }

  removeTag(tag: string) {
    const index = this._value.indexOf(tag);
    if (index >= 0) {
      this._value.splice(index, 1);
      this._stateChanges.next();
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    const value = (event.option.viewValue || '').trim();
    this.inputElement.nativeElement.value = '';

    if ( value ) {
      if ( this._value.indexOf(value) === -1 ) {
        this._value.push(event.option.viewValue);
        this._stateChanges.next();
        this._optionSelected = false;
      } else {
        return;
      }
    }
  }

  activateOption($event: MatAutocompleteActivatedEvent) {

    const value = $event.option?.value;
    this._optionSelected = value !== '' && value !== undefined;
  }

}
