import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbsAssignmentTypeComponent } from './breadcrumbs-assignment-type/breadcrumbs-assignment-type.component';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbsLinkComponent } from './breadcrumbs-link/breadcrumbs-link.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    BreadcrumbsAssignmentTypeComponent,
    BreadcrumbsComponent,
    BreadcrumbsLinkComponent,
  ],
  exports: [
    BreadcrumbsAssignmentTypeComponent,
    BreadcrumbsComponent,
    BreadcrumbsLinkComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
})
export class BreadcrumbsModule {
}
