import { TableColumnBuilder } from 'src/app/component/table/table-column.builder';
import {
  TableColumnDataType,
  TableControllerTypes,
} from 'src/app/component/table/table-controller/table-controller.types';
import { TransactionsListRow } from '../transactions.types';
import { FinancialsHelper } from '../../../../core/financials/financials.helper';
import { FilterOperator } from '../../../../core/column-settings/column-filter.types';


export interface TransactionsListColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: TransactionsListColumnMenuItemMap;
}

export interface TransactionsListColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  actions: TableControllerTypes.ColumnMenuItem;
  total: TableControllerTypes.ColumnMenuItem;
  currency: TableControllerTypes.ColumnMenuItem;
  status: TableControllerTypes.ColumnMenuItem;
  type: TableControllerTypes.ColumnMenuItem;
  lineItemsName: TableControllerTypes.ColumnMenuItem;
  whenCreated: TableControllerTypes.ColumnMenuItem;
}

export const TRANSACTIONS_LIST_DEFAULT_MENU_COLUMNS: TransactionsListColumnMenuData = {
  startWith: [ 'whenCreated' ],
  endWith: [],
  menuItems: {

    whenCreated: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('whenCreated')
      .withType(TableColumnDataType.date)
      .withTitle($localize`:@@global_date:Date`)
      .withSelected()
      .withFilterAction(FilterOperator.EQ)
      .build(),

    type: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('type')
      .withType(TableColumnDataType.dropdown)
      .withTitle($localize`:@@global_type:Type`)
      .withDropDownOptions({
        checkout: $localize`:@@transaction_type_checkout:Checkout`,
        billing_agreement: $localize`:@@transaction_type_billing_agreement:Billing agreement`,
        refund: $localize`:@@transaction_type_refund:Refund`,
      })
      .withSelected(true)
      .build(),

    total: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('total')
      .withType(TableColumnDataType.number)
      .withTitle($localize`:@@global_amount:Amount`)
      .withSelected()
      .withFilterAction(FilterOperator.GT)
      .withFilterDataAccessor(entry => FinancialsHelper
          .asPrice(entry.total) ?? 0)
      .build(),

    currency: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('currency')
      .withType(TableColumnDataType.dropdown).withDropDownOptions({
        EUR: 'Euro (€)',
      })
      .withTitle($localize`:@@global_currency:Currency`)
      .withSelected(false)
      .build(),

    status: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('status')
      .withType(TableColumnDataType.dropdown)
      // ToDo Translate
      .withTitle($localize`:@@global_status:Status`)
      .withDropDownOptions({
        success: $localize`:@@transaction_status_success:Success`,
        declined: $localize`:@@transaction_status_declined:Declined`,
        error: $localize`:@@transaction_status_error:Error`,
        authorization_pending: $localize`:@@transaction_status_authorization_pending:Authorization pending`,
        processing: $localize`:@@transaction_status_processing:Processing`,
      })
      .withSelected()
      .build(),

    lineItemsName: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('lineItemsName')
      .withType(TableColumnDataType.html)
      // ToDo Translate
      .withTitle($localize`:@@global_contents:Contents`)
      .withSelected()
      .build(),

    actions: TableColumnBuilder.start<TransactionsListRow>()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),
  },
};
