import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { ReportConfig } from 'src/app/core/report/report.types';
import * as uuid from 'uuid';
import { StyleSettings } from '../../route/admin/account-design/account-design.types';
import { Layout, LayoutDictionary, Widget, WidgetLayout } from '../rag-layout.types';
import {
  WIDGET_DEFAULTS,
  WidgetConf,
  WIDGETS_SETTINGS_VER,
  WidgetSettings,
  WidgetsUUID,
} from '../widgets/widgets.types';
import { LanguageHelper } from '../../core/language.helper';
import { AccountDesignService } from '../../route/admin/account-design/account-design.service';
import { AnyObject } from '../../core/core.types';
import { PermissionStates } from '../../core/principal/permission.states';
import { LearnerGamificationService } from '../../core/gamification/learner-gamification.service';


@Injectable()
export class RagPageService {

  gamificationEnabled = false;
  _permissions: PermissionStates;

  constructor(
    private accountDesignService: AccountDesignService,
    private learnerGamificationService: LearnerGamificationService,
    private principalService: PrincipalService,
  ) {
    this.principalService.permissionStates$
      .pipe(map(permissions => this._permissions = permissions))
      .subscribe();

    this.learnerGamificationService.gamificationEnabled$
      .pipe(map(gamificationEnabled => this.gamificationEnabled = gamificationEnabled))
      .pipe(take(1))
      .subscribe();
  }

  static applyBoundaries(value: number, lowerBoundary: number, upperBondary: number): number {
    return Math.min(Math.max(lowerBoundary, value), upperBondary);
  }

  private applyAdministratorSettings(widget: WidgetLayout, widgetSettings: WidgetSettings): WidgetLayout {
    const newWidgetLayout = widget;
    const adminSettings = widgetSettings[widget.widgetUUID];

    // check whether settings are within the limits set by the administrator and adjust the values accordingly
    if (adminSettings.resizeEnabled) {
      newWidgetLayout.gridsterItem.cols =
        RagPageService.applyBoundaries(newWidgetLayout.gridsterItem.cols, adminSettings.minItemCols, adminSettings.maxItemCols);
      newWidgetLayout.gridsterItem.rows =
        RagPageService.applyBoundaries(newWidgetLayout.gridsterItem.rows, adminSettings.minItemRows, adminSettings.maxItemRows);
    } else {
      newWidgetLayout.gridsterItem.cols = adminSettings.cols;
      newWidgetLayout.gridsterItem.rows = adminSettings.rows;
    }

    // overwrite user settings with admin settings
    newWidgetLayout.gridsterItem.minItemCols = adminSettings.minItemCols;
    newWidgetLayout.gridsterItem.maxItemCols = adminSettings.maxItemCols;
    newWidgetLayout.gridsterItem.minItemRows = adminSettings.minItemRows;
    newWidgetLayout.gridsterItem.maxItemRows = adminSettings.maxItemRows;
    newWidgetLayout.gridsterItem.dragEnabled = false;
    newWidgetLayout.gridsterItem.draggable = adminSettings.dragEnabled;

    // handle the case wehn this widget has been disabled for selection by the administartor
    // but the user has it in his layout
    // or
    // handle the case when gamification is disabled
    // but the user has the gamification widget in his layout
    if ( !(adminSettings.selectable || adminSettings.static) ||
      (adminSettings.uuid === WidgetsUUID.GamificationWidgetUUID && !this.gamificationEnabled) ) {
      if ( newWidgetLayout.settings != null ) {
        newWidgetLayout.settings.toBeRemoved = true;
      } else {
        newWidgetLayout.settings = {
          toBeRemoved: true,
        };
      }
    } else if ( newWidgetLayout.settings?.toBeRemoved != null ) {
      // reset removal flag
      delete newWidgetLayout.settings.toBeRemoved;
    }

    // return item with applied settings
    return newWidgetLayout;
  }

  // todo: refactor!!! this is an ugly solution
  addReportLinksWidget(pageId: string, reportConfig: ReportConfig): Observable<boolean> {

    const reportUUID = reportConfig.uuid;
    const WidgetUUID = WidgetsUUID.ReportLinksWidgetUUID;
    let layoutRef: LayoutDictionary;
    let hasFoundReportSettings = true;

    return this.appendWidget(pageId, WidgetUUID, (widget, layout) => {
      // steal the layout
      layoutRef = layout;

      const existingReportLinksWidget = Object.values(layout).find(_widget => _widget.widgetUUID === WidgetUUID);
      if ( existingReportLinksWidget != null ) {
        hasFoundReportSettings = existingReportLinksWidget.settings[reportUUID] != null;
        if ( hasFoundReportSettings ) {
          return false;
        }
        // append this widget to the existing report-links widget and save the layout
        existingReportLinksWidget.settings[reportUUID] = {
          reportUUID,
        };
        // do not create new instance of report-links widget
        return false;
      }

      widget.settings = {
        [reportUUID]: {
          reportUUID,
        },
      };

      return true;

    }).pipe(switchMap(success => {
      if ( success ) {
        return of(success);
      }
      if ( hasFoundReportSettings ) {
        return of(false);
      }
      return this.postLayout(pageId, layoutRef);
    }));
  }

  addWidgetAndSaveLayout(pageId: string, widget: Widget, allWidgets: Widget[]): Observable<boolean> {

    const _allWidgets = [ ...allWidgets, widget ];
    const layout = _allWidgets.reduce<LayoutDictionary>((pV, _widget) => {
      pV[_widget.id] = {
        widgetUUID: _widget.widgetUUID,
        gridsterItem: _widget.gridsterItem,
        settings: _widget.settings,
      };
      return pV;
    }, {});

    // call to save new Layout
    return this.postLayout(pageId, layout);
  }

  appendWidget(pageId: string, widgetUUID: string,
               shouldAddWidget?: (widget: WidgetLayout, layout: LayoutDictionary) => boolean): Observable<boolean> {

    return this.getWidgetsLayout(pageId, true).pipe(take(1), switchMap(response => {
      // todo: fixme (implement settings for ctrl widgets)
      const styleSettings: StyleSettings = {
        acc: {
          widgets: {
            [WidgetsUUID.ReportPieChartWidgetUUID]: WIDGET_DEFAULTS[WidgetsUUID.ReportPieChartWidgetUUID],
            [WidgetsUUID.ReportLinksWidgetUUID]: WIDGET_DEFAULTS[WidgetsUUID.ReportLinksWidgetUUID],
            [WidgetsUUID.ReportBarChartWidgetUUID]: WIDGET_DEFAULTS[WidgetsUUID.ReportBarChartWidgetUUID],
          },
        },
        base: {},
        logoUrl: '',
      };

      const layout = response.layout;
      const widget = this.createWidgetForId(pageId, null, widgetUUID, styleSettings);
      if ( typeof shouldAddWidget === 'function' ) {
        if ( !shouldAddWidget(widget, layout) ) {
          return of(false);
        }
      }
      layout[widget.id] = {
        widgetUUID: widget.widgetUUID,
        gridsterItem: widget.gridsterItem,
        settings: widget.settings,
      };
      return this.postLayout(pageId, layout);
    }));
  }

  /**
   * Create a widget based on persisted layout settings and apply updates, if any.
   */
  createWidgetByLayoutWidget(pageId: string, widgetId: string, layoutWidget: WidgetLayout, styleSettings: StyleSettings): Widget {
    // ToDo make own methods for this observables!!!
    const widgetInstance: Widget = this.createWidgetForId(pageId, widgetId, layoutWidget.widgetUUID, styleSettings);

    // make sure all settings from administator are applied
    const adminSettingsWidget = this.applyAdministratorSettings(layoutWidget, styleSettings.acc.widgets);
    merge(widgetInstance, adminSettingsWidget);

    // ToDo make method for versions
    while ( WIDGETS_SETTINGS_VER > layoutWidget.version ) {
      switch ( layoutWidget.widgetUUID ) {
        case WidgetsUUID.CodeRedeemWidgetUUID:
          if ( layoutWidget.version === 2 ) {
            layoutWidget.gridsterItem.rows = WIDGET_DEFAULTS[layoutWidget.widgetUUID].rows;
            layoutWidget.gridsterItem.minItemRows = WIDGET_DEFAULTS[layoutWidget.widgetUUID].minItemRows;
          }
          break;
        case WidgetsUUID.GamificationWidgetUUID:
          if ( layoutWidget.version === 2 ) {
            layoutWidget.gridsterItem.rows = WIDGET_DEFAULTS[layoutWidget.widgetUUID].rows;
            layoutWidget.gridsterItem.minItemRows = WIDGET_DEFAULTS[layoutWidget.widgetUUID].minItemRows;
            layoutWidget.gridsterItem.maxItemRows = WIDGET_DEFAULTS[layoutWidget.widgetUUID].maxItemRows;
          }
          break;
        case WidgetsUUID.OverviewWidgetUUID:
          if ( layoutWidget.version == null ) {
            layoutWidget.gridsterItem.cols = 3;
          }
          break;
        case WidgetsUUID.NewsWidgetUUID:
          if ( layoutWidget.version < 2 ) {
            layoutWidget.gridsterItem.rows = 10;
            layoutWidget.gridsterItem.minItemRows = 10;
            layoutWidget.gridsterItem.maxItemRows = 10;
          }
          break;
        case WidgetsUUID.MycertificatesWidgetUUID:
          if ( layoutWidget.version < 2 ) {
            layoutWidget.gridsterItem.cols = 3;
          }
          break;
        case WidgetsUUID.HtmlWidgetUUID:
          break;
        case WidgetsUUID.SpecialsWidgetUUID:
          if ( layoutWidget.version < 2 ) {
            layoutWidget.gridsterItem.rows = 10;
            layoutWidget.gridsterItem.minItemRows = 10;
            layoutWidget.gridsterItem.maxItemRows = 10;
          }
          break;
        case WidgetsUUID.ReportLinksWidgetUUID:
          if ( layoutWidget.version < 3 ) {
            layoutWidget.gridsterItem.minItemRows = 5;
            layoutWidget.gridsterItem.rows = 5;
          }
      }
      layoutWidget.version += 1;
    }

    return widgetInstance;
  }

  // todo: replace StyleSettings with styleSettings.acc.widgets
  createWidgetForId(pageId: string, widgetId: string, widgetUUID: string, styleSettings: StyleSettings): Widget {

    const widgets = styleSettings.acc.widgets;

    const widgetSettings: WidgetConf = widgets[widgetUUID];
    const widgetDefaults: WidgetConf = WIDGET_DEFAULTS[widgetUUID];

    // initialize widget with adminSettings
    const widget: Widget = {
      id: widgetId || uuid.v4(),
      pageId,
      version: WIDGETS_SETTINGS_VER,
      widgetUUID,
      title: LanguageHelper.objectToText(widgetDefaults?.title || widgetSettings.title),
      gridsterItem: {
        cols: widgetSettings.cols,
        rows: widgetSettings.rows,
        x: widgetSettings.x,
        y: widgetSettings.y,
        minItemRows: widgetSettings.minItemRows,
        maxItemRows: widgetSettings.maxItemRows,
        minItemCols: widgetSettings.minItemCols,
        maxItemCols: widgetSettings.maxItemCols,
      },
    };

    // make sure all settings from administator are applied
    const adminSettingsWidget = this.applyAdministratorSettings(widget, styleSettings.acc.widgets);
    merge(widget, adminSettingsWidget);

    return widget;
  }

  getWidgetAdminSettings(pageId: string, forceReload = false): Observable<AnyObject<WidgetConf>> {
    return this.accountDesignService.getStyleSettings(forceReload)

      // pick widget configuration from style settings
      .pipe(map(styleSettings => (styleSettings?.acc?.widgets ?? {})))

      .pipe(map(adminWidgets =>

        // iterate over known widgets
        Object.values(WIDGET_DEFAULTS ?? {})

          // pick only those matching the current pageId
          .filter(widgetDefaults => widgetDefaults.context === pageId)

          // apply administrative settings
          .map(widgetDefaults => adminWidgets[widgetDefaults.uuid] ?? widgetDefaults)

          // convert back to map
          .reduce((pV, widget) => {
            pV[widget.uuid] = widget;
            return pV;
          }, {})));
  }

  getWidgetsLayout(pageId: string, forceReload = false): Observable<Layout> {
    return this.principalService.getUserSettings(pageId, forceReload)
      .pipe(map(settings => {
        if ( settings == null ) {
          return {};
        }
        let layout;
        try {
          layout = JSON.parse(settings);
        } catch ( e ) {
          layout = {};
        }
        return {
          layout,
        };
      }));

    // const url = `${ApiUrls.getKey('UserSettings')}/${pageId}`;
    // return this.http.get<ApiResponse<string>>(url)
    //   .pipe(map(response => {
    //     const settings = response[pageId] || '{}';
    //     let layout;
    //     try {
    //       layout = JSON.parse(settings);
    //     } catch (e) {
    //       layout = {};
    //     }
    //     return {
    //       layout: layout,
    //       success: true,
    //     };
    //   }));
  }

  removeWidget(pageId: string, widget: Widget, allWidgets: Widget[]): Observable<number> {
    return new Observable(observer => {

      const widgetIndex = allWidgets.indexOf(widget);
      if ( widgetIndex < 0 ) {
        observer.next(widgetIndex);
        return;
      }

      const layout = allWidgets.reduce<LayoutDictionary>((pV, _widget) => {
        if ( widget !== _widget ) {
          pV[_widget.id] = {
            widgetUUID: _widget.widgetUUID,
            gridsterItem: _widget.gridsterItem,
            settings: _widget.settings,
          };
        }
        return pV;
      }, {});

      // call to save new Layout
      this.postLayout(pageId, layout).subscribe(() => observer.next(widgetIndex));
    });
  }

  /**
   * Remove widget from layout
   * @param pageId string
   * @param widgetId string (this is an instance Id and not WidgetUUID)
   * @param falseWhenNotFound when true, send false as result when this woidget is not found, otherwise true
   */
  removeWidgetById(pageId: string, widgetId: string, falseWhenNotFound: boolean = false): Observable<boolean> {
    return this.getWidgetsLayout(pageId, true)
      .pipe(take(1))
      .pipe(switchMap(response => {
        const layout = response.layout;
        if ( layout == null ) {
          return of(!falseWhenNotFound);
        }
        delete layout[widgetId];
        return this.postLayout(pageId, layout);
      }));
  }

  replaceWidget(widget: Widget, falseWhenNotFound: boolean = false): Observable<boolean> {

    return this.getWidgetsLayout(widget.pageId, true)
      .pipe(take(1))
      .pipe(switchMap(response => {
        const layout = response.layout;
        if ( layout == null ) {
          return of(!falseWhenNotFound);
        }
        const targetWidget = layout[widget.id];
        if ( targetWidget == null ) {
          return of(!falseWhenNotFound);
        }
        targetWidget.settings = widget.settings;
        return this.postLayout(widget.pageId, layout);
      }));
  }

  resetWidgets(pageId: string): Observable<boolean> {
    // save empty layout to get initial values from admin settings
    return this.postLayout(pageId, {});
  }

  saveLayout(pageId: string, allWidgets: Widget[]): Observable<boolean> {

    const layout = allWidgets.reduce<LayoutDictionary>((pV, widget) => {
      pV[widget.id] = {
        widgetUUID: widget.widgetUUID,
        gridsterItem: widget.gridsterItem,
        settings: widget.settings,
      };
      return pV;
    }, {});

    return this.postLayout(pageId, layout);
  }

  private postLayout(pageId: string, layout: LayoutDictionary): Observable<boolean> {
    const payload = JSON.stringify(layout);
    return this.principalService.saveUserSettings(pageId, payload);
  }
}
