import { NgModule } from '@angular/core';
import { ToggleDirective } from './toggle.directive';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [ ToggleDirective ],
  exports: [ ToggleDirective ],
  imports: [
    CommonModule,
  ],
})
export class ToggleModule {
}
