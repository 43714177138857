import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AdminCurriculumActionsComponent } from './admin-curriculum-actions.component';
import { RouterModule } from '@angular/router';
import {AdminComponentsModule} from '../../admin-components/admin-components.module';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [ AdminCurriculumActionsComponent ],
  exports: [ AdminCurriculumActionsComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    AdminComponentsModule,
    MatDividerModule,
  ],
})
export class AdminCurriculumActionsModule {

}
