import { Injectable } from '@angular/core';
import { ApiUrls } from '../../../core/api.urls';
import { catchError, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import {
  UserAttribute,
  UserAttributeGroup,
  UserAttributeResponse,
  UpdateAttributesResponse,
  UpdateUserAttributeResponse, UserAttributesForMessaging,
} from '../../../core/admin-user-attribute.types';
import { InfoType } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import { AnyObject } from 'src/app/core/core.types';

@Injectable({ providedIn: 'root' })
export class AdminUserAttributeService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getUserAttribute(fieldName): Observable<UserAttribute> {
    const url = ApiUrls.getKey('GetUserAttribute')
      .replace(/{attributeName}/gi, fieldName);
    return this.http.get<UpdateAttributesResponse>(url)
      .pipe(map(response => response.userAttribute));
  }

  getAllUserAttributeGroups(): Observable<UserAttributeResponse> {
    const url = ApiUrls.getKey('GetAllUserAttributeGroups');
    return this.http.get<UserAttributeResponse>(url);
  }

  getAllUserAttributes(): Observable<AnyObject<UserAttribute>> {
    const url = ApiUrls.getKey('GetAllUserAttributes');
    return this.http.get<UserAttributeResponse>(url).pipe(map(response => response.userAttributes));
  }

  getUserAttributesForMessaging(): Observable<UserAttributesForMessaging> {
    const url = ApiUrls.getKey('GetUserAttributesForMessaging');
    return this.http.get<UserAttributesForMessaging>(url);
  }

  saveUserAttribute(fieldName, userAttribute: UserAttribute): Observable<UserAttribute> {
    const url = ApiUrls.getKey('PostUserAttribute')
      .replace(/{attributeName}/gi, fieldName);

    return this.http.post<UpdateAttributesResponse>(url, userAttribute)
      .pipe(map(response => response.userAttribute));
  }

  updateGroupSort(groupIds: number[]): Observable<UserAttributeResponse> {
    const url = ApiUrls.getKey('UpdateUserAttributeGroupSort');

    return this.http.post<UserAttributeResponse>(url, groupIds);
  }

  saveUserAttributeList(
    sortedGroupIds: number[] | null,
    changedUserAttributes: Map<string, UserAttribute>,
  ): Observable<void> {

    const tasks = [];

    sortedGroupIds = sortedGroupIds?.filter(groupId => groupId != null);
    if (sortedGroupIds?.length > 0) {
      tasks.push(this.updateGroupSort(sortedGroupIds));
    }

    if (changedUserAttributes.size > 0) {
      tasks.push(this.saveUserAttributes(changedUserAttributes));
    }

    if (tasks.length === 0) {
      return EMPTY;
    }

    return forkJoin(tasks)
      .pipe(take(1))
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }
      ))
      .pipe(map(() => void(0)));
  }

  saveUserAttributes(userAttributes: Map<string, UserAttribute>): Observable<AnyObject<UserAttribute>> {
    const url = ApiUrls.getKey('PostUserAttributes');

    const payload = {
      attributes: {}
    };

    userAttributes.forEach((value: UserAttribute, key: string) => {
      payload.attributes[key] = value;
    });

    return this.http.post<UpdateUserAttributeResponse>(url, payload)
      .pipe(map(response => response.attributes));
  }

  saveUserAttributeGroup(data: UserAttributeGroup): Observable<UserAttributeResponse> {
    const url = ApiUrls.getKey('PostUserAttributeGroup');

    return this.http.post<UserAttributeResponse>(url, data)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }));
  }

  deleteAttributeGroup(id: number): Observable<any> {
    const url = ApiUrls.getKey('DeleteUserAttributeGroup')
      .replace(/{groupId}/gi, String(id));

    return this.http.delete(url)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }));
  }
}
