import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { ApiUrls } from '../../../../core/api.urls';
import { MessageAccountInfo, Principal } from '../../../../core/core.types';
import { ApiResponse, HttpRequestOptions, TrainResponse } from '../../../../core/global.types';
import { MailEntry, MailMessage, MailRequest, MessageRequest, SearchApi } from '../../../../core/mail.types';
import { OkButton } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { GenericTextInputDialogComponent } from '../../../../component/generic-text-input-dialog/generic-text-input-dialog.component';
import { GenericTextInputDialogData } from '../../../../component/generic-text-input-dialog/generic-text-input-dialog.types';
import { LanguageHelper } from '../../../../core/language.helper';

@Injectable({
  providedIn: 'root',
})
export class MailComposerService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  private static fixMacros(mailRequest: MailMessage): void {
    mailRequest.body = (mailRequest.body || '')
      .replace(/({[^}]*&quot;[^}]*})/g, macro => macro.replace(/&quot;/g, '"'));
    mailRequest.subject = (mailRequest.subject || '')
      .replace(/({[^}]*&quot;[^}]*})/g, macro => macro.replace(/&quot;/g, '"'));
  }

  getSender(): Observable<Principal[]> {
    return this.http
      .get<TrainResponse<Principal[]>>(ApiUrls.getKey('Mailbox_NewApi_GetAccountSenders'))
      .pipe(map(response => response.data));
  }

  getReceiver(): Observable<MessageAccountInfo[]> {
    return this.http
      .get<TrainResponse<MessageAccountInfo[]>>(ApiUrls.getKey('Mailbox_NewApi_GetAccountReceivers'))
      .pipe(map(response => response.data));
  }

  post(mailRequest: MailRequest): Observable<boolean> {
    MailComposerService.fixMacros(mailRequest);
    return this.http
      .request<TrainResponse>('PUT', ApiUrls.getKeyWithParams('Mailbox', '2'), { body: mailRequest })
      .pipe(map(() => true));
  }

  save(mailRequest: MessageRequest): Observable<MailEntry> {
    MailComposerService.fixMacros(mailRequest);
    const url = `${ApiUrls.getKey('Mailbox_NewApi_SaveOnly')}`;
    return this.http
      .request<ApiResponse<MailEntry>>('POST', url, { body: mailRequest })
      .pipe(map(response => response.message));
  }

  saveAsDraft(mailRequest: MessageRequest): Observable<MailEntry> {
    MailComposerService.fixMacros(mailRequest);
    const url = `${ApiUrls.getKey('Mailbox_NewApi_SaveAsDraft')}`;

    return this.infoService.showDialog<GenericTextInputDialogComponent, GenericTextInputDialogData, string>(GenericTextInputDialogComponent, {
      title: $localize`:@@mail_composer_enter_draft_name:Enter draft name`,
      message: $localize`:@@mail_composer_enter_draft_name:Enter draft name`,
      buttons: OkButton,
      data: {
        text: LanguageHelper.objectToText(mailRequest.subject),
      },
    })
      .pipe(takeWhile( text => text != null))
      .pipe(switchMap(text => {
        mailRequest.scheduledTitle = text;
        return this.http.request<ApiResponse<MailEntry>>('POST', url, { body: mailRequest });
      }))
      .pipe(map(response => response.message));
  }

  searchForPrincipalsByText(search: string): Observable<Principal[]> {
    const url = `${ApiUrls.getKey('Mailbox_NewApi_GetAccounts')}/find`;
    return this.http
      .post<SearchApi>(url, JSON.stringify({ filter: search }), HttpRequestOptions)
      .pipe(map(response => response.data));
  }

}
