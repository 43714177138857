import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PipesModule } from '../pipes/pipes.module';
import { MaintenanceComponent } from './maintenance.component';
import { MaintenanceService } from './maintenance.service';


@NgModule({
  declarations: [
    MaintenanceComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    PipesModule,
  ],
  providers: [
    MaintenanceService,
  ],
})
export class MaintenanceModule {
}
