import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../../component/table/table-controller/table-controller.types';
import { TableColumnBuilder } from '../../../../../component/table/table-column.builder';
import { NotificationTableData } from '../admin-notifications.types';

export interface AdminNotificationsColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: AdminNotificationsColumnMenuItemMap;
}

export interface AdminNotificationsColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap<NotificationTableData> {
  actionId: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  enabled: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  eventTitle: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  evtMod: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  excludedTargetGroups: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  offset: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  onElement: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  targetGroups: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  title: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  isNotification: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  actions: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  defaultTemplate: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
  activeHint: TableControllerTypes.ColumnMenuItem<NotificationTableData>;
}

export const ADMIN_NOTIFICATIONS_LIST_COLUMNS: AdminNotificationsColumnMenuData = {
  startWith: ['evtMod'],
  endWith: ['actions'],
  menuItems: {
    evtMod: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('evtMod')
      .withTitle($localize`:@@global_module:Module`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        course: $localize`:@@admin_lu_header:Learning content`,
        user: $localize`:@@global_users:Users`,
        curriculum: $localize`:@@admin_content_content_curriculum:Curriculum`,
        offlineContent: $localize`:@@header_nav_admin_offline:Events`,
        other: $localize`:@@courseItemStatus_other:Other`
      })
      .withSelected()
      .build(),

    isNotification: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('isNotification')
      .withTitle($localize`:@@global_type:Type`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        true: $localize`:@@global_notification:Notification`,
        false: $localize`:@@global_url:Url`,
      })
      .withSelected()
      .build(),

    enabled: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('enabled')
      .withTitle($localize`:@@admin_news_column_active:Active`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        true: $localize`:@@global_enabled:enabled`,
        false: $localize`:@@global_disabled:disabled`,
      })
      .withSelected()
      .build(),

    activeHint: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('activeHint')
      .withTitle($localize`:@@admin_notifications_column_active_hint:Active Hint`)
      .withType(TableColumnDataType.text)
      .build(),

    title: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('title')
      .withTitle($localize`:@@global_title:Title`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    onElement: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('onElement')
      .withTitle($localize`:@@global_on_element:On element`)
      .withType(TableColumnDataType.text)
      .withDataAccessor(data => {
        if (Number(data.evtModId) === 10) {
          return '-';
        }
        return data.onElement;
      })
      .withSelected()
      .build(),

    eventTitle: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('eventTitle')
      .withTitle($localize`:@@admin_offline_notifications_column_eventName:Event`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    offset: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('offset')
      .withTitle($localize`:@@offset:Offset`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    targetGroups: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('targetGroups')
      .withTitle($localize`:@@global_target_groups:Target groups`)
      .withType(TableColumnDataType.html)
      .withSelected()
      .build(),

    excludedTargetGroups: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('excludedTargetGroups')
      .withTitle($localize`:@@admin_offline_notifications_column_excludedTargetGroups:Target groups (excluded)`)
      .withType(TableColumnDataType.html)
      .withSelected(false)
      .build(),

    actionId: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('actionId')
      .withTitle($localize`:@@admin_offline_notifications_column_actionId:Action ID`)
      .withType(TableColumnDataType.number)
      .withSelected(false)
      .build(),

    defaultTemplate: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('defaultTemplate')
      .withTitle($localize`:@@global_notification_template:Notification template`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        true: $localize`:@@global_yes:Yes`,
        false: $localize`:@@global_no:No`,
      })
      .withSelected(false)
      .build(),

    actions: TableColumnBuilder.start<NotificationTableData>()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),
  }
};
