import { Injectable } from '@angular/core';
import { ApiUrls } from '../api.urls';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private elementId: string;
  private useCacheBusting: boolean;

  constructor() {
    this.elementId = 'favicons-service-injected-node';
    // ToDo get favicon from styleSettings
    this.useCacheBusting = true;

    // Since the document may have a static favicon definition, we want to strip out
    // any exisitng elements that are attempting to define a favicon. This way, there
    // is only one favicon element on the page at a time.
    this.removeExternalLinkElements();
  }

  public activateSource(iconHref: string, iconType: string = 'png'): void {
    // console.log('Activating new favicon...');
    this.setNode(iconType, iconHref);
  }

  public activateSourceByFileId(fileId: number): void {
    // console.log('Activating new favicon...');
    const href = ApiUrls.getKey('ShowFileById')
      .replace(/{fileId}/gi, String(fileId));
    // ToDo get type of picture
    const type = 'null';
    this.setNode(type, href);
  }

  // reset the favicon to use the "default" favicon.
  public resetFavicon(): void {
    // console.log('Resetting favicon');
    this.reset();
  }

  // inject the favicon element into the document header.
  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
    // console.log('Favicon successfully set!');
  }

  // return an augmented HREF value with a cache-busting query-string parameter.
  private cacheBustHref(href: string): string {
    const augmentedHref = (href.indexOf('?') === -1)
      ? `${href}?faviconCacheBust=${Date.now()}`
      : `${href}&faviconCacheBust=${Date.now()}`;

    return (augmentedHref);
  }

  // remove any favicon nodes that are not controlled by this service.
  private removeExternalLinkElements(): void {
    const linkElements = document.querySelectorAll('link[rel~="icon"]');

    for ( const linkElement of Array.from(linkElements) ) {
      linkElement.parentNode.removeChild(linkElement);
    }
  }

  // remove the favicon node from the document header.
  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementId);

    if ( linkElement ) {
      document.head.removeChild(linkElement);
    }
  }

  // activate the default favicon
  private reset(): void {
    // ToDo change default favicon to reflact
    this.setNode('image/x-icon', 'assets/favicon/iLearn24/ilearn24-favicon.ico');
  }

  // remove the existing favicon node and inject a new favicon node with the given element settings.
  private setNode(type: string, href: string): void {
    const augmentedHref = this.useCacheBusting ? this.cacheBustHref(href) : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}
