import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent, WidgetsUUID } from '../widgets.types';
import { CodeRedeemWidgetService } from './code-redeem-widget.service';
import { WidgetsService } from '../widgets.service';
import { InfoService } from '../../../core/info/info.service';
import { InfoType } from '../../../core/info/info.types';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { DateHelper } from 'src/app/core/date.helper';

@Component({
  selector: 'rag-code-redeem-widget',
  templateUrl: './code-redeem-widget.component.html',
  styleUrls: [ './code-redeem-widget.component.scss' ],
  providers: [ CodeRedeemWidgetService ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeRedeemWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent {

  static WidgetUUID = WidgetsUUID.CodeRedeemWidgetUUID;
  isCodeRedeemed = false;
  isLoading = false;
  myform: UntypedFormGroup;
  @ViewChild('code', { static: true })
  textInputDomRef: ElementRef;
  private readonly msgCodeRedeemed: string;

  constructor(
    private codeRedeemWidgetService: CodeRedeemWidgetService,
    private infoService: InfoService,
    private widgetService: WidgetsService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();

    this.msgCodeRedeemed =
      $localize`:@@code_successfully_redeemed:
        The code has been redeemed successfully!. You can start learning <a routerLink="/content-overview">here</a>.`;
    this.setTitle($localize`:@@widget_license_code_title:Redeem Licence Code`);

    this.createForm();
  }

  clear = (): void => {
    this.isCodeRedeemed = false;
    this.isLoading = false;
    this.textInputDomRef.nativeElement.blur();
    this.myform.reset();
  };

  displayInfo() {
    this.infoService.showMessage($localize`:@@placeholder_license_code:
      Please enter the licence code for one of our courses.`,
      { infoType: InfoType.Information });
  }

  isRedeemButtonDisabled() {
    return this.isLoading || this.myform.invalid;
  }

  redeem() {

    const code = this.myform.get('codeInputControl').value;
    if ( !code ) {
      this.clear();
      return;
    }

    if ( this.isCodeRedeemed ) {
      this.clear();
      return;
    }

    const licenseCode = code.trim();
    if ( licenseCode === '' ) {
      return;
    }

    this.isLoading = true;
    this.codeRedeemWidgetService.redeemCode(licenseCode)
      .pipe(take(1))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError(this.handleError))
      .pipe(tap(this.codeHasBeenRedeemed))
      .subscribe();
  }

  private codeHasBeenRedeemed = (): void => {
    this.isCodeRedeemed = true;
    this.infoService.showMessage(this.msgCodeRedeemed, { infoType: InfoType.Success });

    this.myform.setErrors(null);
    this.textInputDomRef.nativeElement.focus();
    this.textInputDomRef.nativeElement.blur();

    setTimeout(() => {
      this.widgetService.reload(WidgetsUUID.OverviewWidgetUUID);
    }, 5000);
  };

  private createForm() {
    this.myform = this.formBuilder.group({
      codeInputControl: ['', [Validators.required]]
    });
  }

  private handleError = (error): Observable<never> => {
    const errorCode = error?.error?.errorCode ?? 'general';

    const message = this.getMessage(errorCode, error);
    const durationInSeconds = (errorCode === '#ACE6') ? 60 : 0;
    this.infoService.showMessage(message, { infoType: InfoType.Error, durationInSeconds });

    this.myform.get('codeInputControl').setErrors({
      invalid: errorCode,
    });
    this.textInputDomRef.nativeElement.focus();
    this.textInputDomRef.nativeElement.blur();

    return EMPTY;
  };

  private getMessage = (errorCode, error): string =>  {
    switch (errorCode) {
      case '#ACE1':
      case '#ACE2':
      case '#ACE3':
        const msg1 = $localize`:@@action_code_runtime_error1:There was an error during action code activation!`;
        return `${msg1} (#${errorCode})`;
      case '#ACE4':
        console?.log('check for details');
        console?.log(error);
        return $localize`:@@action_code_runtime_error1:There was an error during action code activation!`
      case '#ACE5':
        return $localize`:@@invalid_code:The code you entered could not be found!`
      case '#ACE6':
        const msg6 = $localize`:@@activated_another_code_from_charge_in_last_24_h:You have already redeemed a similar code on #date#. If the first redemption leads to an issue, please contact us via the contact form!`
        const latestExecutionDate = DateHelper.format(new Date(error?.error?.errorHint),'DD.MM.yyyy HH:mm:ss');
        return msg6.replace('#date#',latestExecutionDate );
      case '#ACE7':
        return $localize`:@@already_executed:Your code was already activated!`
      case '#ACE8':
      case '#ACE9':
        const msg8 = $localize`:@@code_not_executable:The code was deactivated!`
        return `${msg8} (#${errorCode})`;
      default:
        return $localize`:@@general_error:The last operation failed. Please try again later.`;
    }
  }

}
