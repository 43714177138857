/**
 * @see https://github.com/angular/components/issues/5488#issuecomment-448199220
 */
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

const preserveSvgViewBox = (matIconRegistry: MatIconRegistry) => () => {
    const fnExtractSvgIconFromSet: ((iconSet: Element, iconName: string) => Element) =
      matIconRegistry['_extractSvgIconFromSet'].bind(matIconRegistry);
    matIconRegistry['_extractSvgIconFromSet'] = (iconSet: Element, iconName: string): Element => {
      const svg = fnExtractSvgIconFromSet(iconSet, iconName);
      let viewBox;
      if ( iconSet && svg && (viewBox = iconSet.getAttribute('viewBox')) ) {
        svg.setAttribute('viewBox', viewBox);
      }
      return svg;
    }
    ;
  };

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [ MatIconRegistry ],
      useFactory: preserveSvgViewBox,
      multi: true,
    },
  ],
})
export class RagIconModule {
}
