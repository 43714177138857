import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import Chart from 'chart.js/auto';
import { ChartConfiguration, Color } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LanguageHelper } from '../../../core/language.helper';
import { Translation } from '../../../core/translation/translation.types';
import { ChartService } from '../chart.service';
import { DEFAULT_OPTIONS, DoughnutChartDataSet, DoughnutChartOptions } from './doughnut-chart.types';
import { MergeHelper } from '../../../core/primitives/merge.helper';

/**
 * @see https://www.chartjs.org/docs/latest/charts/doughnut.html
 */
@Directive({
  selector: 'canvas[ragDoughnutChart]',
})
export class DoughnutChartDirective
  implements AfterViewInit, OnDestroy {

  WIDGET_TITLE: Translation = {
    de: 'Ampelstatus',
    en: 'Course status',
  };
  @Input()
  backgroundColor = 'white';
  @Input()
  reportTitle: string;
  private chartColors: Color[] = [];
  private chartData: number[] = [];
  private chartJs: Chart;
  private chartLabels: string[] = [];
  private chartJsConfig: ChartConfiguration = {
    type: 'doughnut',
    options: {
      aspectRatio: 1,
      backgroundColor: 'transparent'
    },
    data: {
      labels: this.chartLabels,
      datasets: [ {
        data: this.chartData,
        backgroundColor: this.chartColors,
      } ],
    },
  };

  private _data: DoughnutChartDataSet[];

  @Input()
  set data(data: DoughnutChartDataSet[]) {
    this._data = data;
    this.updateData();
    this.updateChartJs();
  }

  private _data$: Subscription;

  @Input()
  set data$(data$: Observable<DoughnutChartDataSet[]>) {
    this.cleanData$();

    if ( data$ ) {
      this._data$ = data$
        .pipe(map(data => {
          this.data = data;
        }))
        .subscribe();
    }
  }

  private _downloadChart$: Subscription;

  @Input()
  set downloadChart$(value: Observable<void>) {
    this.cleanDownloadChart$();

    if ( value ) {
      this._downloadChart$ = value
        .pipe(tap(this.downloadChart))
        .subscribe();
    }
  }

  private _options: DoughnutChartOptions = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS);

  @Input()
  set options(value: DoughnutChartOptions) {
    this._options = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS, value);

    this.updateChartJs();
  }

  private _startResize: Subscription;

  @Input()
  set startResize(value: Observable<void>) {
    this.cleanStartResize();

    this._startResize = value
      .pipe(tap(() => {
        if ( this.chartJs ) {
          this.chartJs.destroy();
          this.chartJs = null;
        }
        this.createChartJs();
      }))
      .subscribe();
  }

  constructor(
    private chartService: ChartService,
    private elementRef: ElementRef,
  ) {
  }

  downloadChart = (): void => {
    this.chartService.downloadChart(this.chartJsConfig, this.reportTitle, LanguageHelper.translate(this.WIDGET_TITLE));
  };

  ngAfterViewInit(): void {
    this.updateData();
    this.createChartJs();
    this.updateChartJs();
  }

  ngOnDestroy(): void {
    this.cleanData$();
    this.cleanDownloadChart$();
    this.cleanStartResize();
  }

  private cleanData$() {
    if ( this._data$ ) {
      this._data$.unsubscribe();
      this._data$ = null;
    }
  }

  private cleanDownloadChart$() {
    if ( this._downloadChart$ ) {
      this._downloadChart$.unsubscribe();
      this._downloadChart$ = null;
    }
  }

  private cleanStartResize(): void {
    if ( this._startResize ) {
      this._startResize.unsubscribe();
      this._startResize = null;
    }
  }

  private createChartJs() {
    this.chartJs = new Chart(this.elementRef.nativeElement, this.chartJsConfig);
  }

  private updateChartJs() {
    if ( !this.chartJs ) {
      return;
    }

    const options = this.chartJs.options;
    const legend = options.plugins.legend;
    if ( !this._options.showLegend ) {
      options.aspectRatio = 1;
      legend.display = false;
    } else {
      legend.display = true;
      if ( this._options.showLegend === 'right' ) {
        options.aspectRatio = 2;
        legend.position = 'right';
        legend.align = 'center';
      } else if ( this._options.showLegend === 'bottom' ) {
        options.aspectRatio = 1;
        legend.position = 'bottom';
        legend.align = 'start';
      }
    }

    this.chartJs.update();
  }

  private updateData() {
    if ( !this._data ) {
      return;
    }

    this.chartData.splice(0);
    this.chartLabels.splice(0);


    this._data?.forEach((entry: DoughnutChartDataSet) => {
      this.chartColors.push(entry.color);
      this.chartData.push(entry.value);
      this.chartLabels.push(entry.label);
    });
  }

}
