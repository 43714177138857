import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LearningTimeChartOptions } from 'src/app/component/chart/learning-time-chart/learning-time-chart.types';
import { ReportService } from 'src/app/core/report/report.service';
import { ReportLearningTime } from 'src/app/core/report/report.types';
import { ReceivedResponseEvent } from 'src/app/route/user/certificates/certificates.types';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { AbstractChartWidgetComponent } from '../widgets.types';


@Component({
  selector: 'rag-report-bar-chart-widget',
  templateUrl: './report-bar-chart-widget.component.html',
  styleUrls: [ './report-bar-chart-widget.component.scss' ],
})
export class ReportBarChartWidgetComponent
  extends AbstractChartWidgetComponent
  implements WidgetComponent, OnInit {

  chartLearningTime: ReportLearningTime;
  chartLearningTimeOptions: LearningTimeChartOptions;
  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasResponse = false;
  reportUUID: string;
  shouldDisplaySpinner = true;
  sizingDone$: Observable<void>;

  constructor(private reportService: ReportService) {
    super();
    this.setAspectRatio(2);
    this.sizingDone$ = this.getSizingDone();
    this.setTitle($localize`:@@report_bar_chart_widget_title:Learning time analysis`);
  }

  @ViewChild('sizingContainer')
  set sizingContainer(value: ElementRef) {
    this.setSizingContainer(value);
  }

  @ViewChild('sizingReference')
  set sizingReference(value: ElementRef) {
    this.setSizingReference(value);
  }

  isDataDriven() {
    return true;
  }

  ngOnInit() {
    const reportUUID = this.widget.settings.reportUUID;
    this.reportService
      .fetchReport(reportUUID)
      .pipe(map(report => {
        this.reportUUID = reportUUID;
        this.setTitle(report.reportConfig.title);
        return report.reportConfig;
      }))
      .pipe(switchMap(reportConfig => this.reportService.getLearningTime(reportConfig)))
      .subscribe(reportLearningTime => {
        this.chartLearningTime = reportLearningTime;
        this.setDidReceiveResponse({ hasContent: true });
      }, error => {
        this.setDidReceiveResponse({ hasContent: false });
      });
  }

  setDidReceiveResponse(receivedResponse: ReceivedResponseEvent) {
    this.shouldDisplaySpinner = false;
    this.hasResponse = true;
    this.didReceiveResponse(receivedResponse);
  }

}
