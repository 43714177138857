<ng-container *ngIf="(data$ | async) as data">
  <div class="login-wrapper">
    <div class="centering">
      <div class="login-picture">
        <div class="login-outer-Wrapper">
          <div class="login-inner-wrapper">
            <img [src]="data.pictureUrl" alt=""/>
          </div>
          <div
            *ngIf="data.welcomeText as welcomeText"
            class="welcome-text ck-styles"
            [innerHTML]="welcomeText | safe:'html'"
          ></div>
        </div>
      </div>

      <rag-login-form
        [showDoubleOptInSuccessInline]="false"
        [previewMode]="previewMode"
        [texts]="texts"
      ></rag-login-form>
    </div>
  </div>

  <div
    *ngIf="data.welcomeText as welcomeText"
    class="smaller-device-text"
    [innerHTML]="welcomeText | safe:'html'"
  ></div>

</ng-container>
