import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LinkedUsersService } from './linked-users.service';
import { LinkedUserTypes } from './linked-users.types';

@Injectable({
  providedIn: 'root',
})
export class LinkedUsersOwnUuidResolver {

  constructor(
    private linkService: LinkedUsersService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LinkedUserTypes.LinkedUser[]> {
    return this.linkService.getOwnUUID()
      .pipe(take(1));
  }
}
