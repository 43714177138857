<div class="container">

  <div class="col1">
    <!-- action button -->
    <rag-content-action
      [content]="getExecutableItem(content)"
      [parent]="content"
    ></rag-content-action>
  </div>

  <div class="col2">

    <div class="row1">
      <div class="details">

        <rag-clamp
          [clamp]="2"
          [input]="getExecutableItem(content).title | localeText">
        </rag-clamp>
      </div>

      <rag-status-lights
        [displayStatus]="getExecutableItem(content).displaystatus"
        statusType="DisplayStatus"
        [targetType]="getExecutableItem(content).objType"
        [courseType]="getExecutableItem(content)?.courseType"
        [hasConfirmation]="getExecutableItem(content)?.hasConfirmation"
      ></rag-status-lights>
    </div>

    <div class="row2">
      <div class="info">
        <mat-icon
          color="gray"
         [svgIcon]="svgIconForObjType(getExecutableItem(content).objType)"></mat-icon>
        &nbsp;
        <span>{{typeForContent(getExecutableItem(content))}}</span>
      </div>

      <a
        *ngIf="hasDescription(content)"
        class="details-button"
        (click)="onDetailsClick($event, getExecutableItem(content))">
        <span i18n="@@global_details">Details</span>
        <!-- <mat-icon svgIcon="chevron-down"></mat-icon> -->
      </a>
    </div>
  </div>

</div>
