import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtLoginsModule } from './ext-logins/ext-logins.modules';
import { ExtOauth2Module } from './ext-oauth2/ext-oauth2.module';
import { ExtPaymentModuleModule } from './ext-payment-module/ext-payment-module.module';

@NgModule({
  imports: [
    CommonModule,
    ExtLoginsModule,
    ExtOauth2Module,
    ExtPaymentModuleModule,
  ],
  declarations: [],
})
export class ExternalsModule {
}
