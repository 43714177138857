import { Injectable } from '@angular/core';
import { CurriculumCockpitResponse } from './curriculum-cockpit.types';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../../core/api.urls';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class CurriculumCockpitService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getCurriculumCockpitDetails(curriculumId: number): Observable<CurriculumCockpitResponse> {
    const url = ApiUrls.getKey('GetCurriculumInfo')
      .replace(/{curriculumId}/gi, String(curriculumId));
    return this.http.get<CurriculumCockpitResponse>(url);
  }

}
