import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { OfflineContent } from '../../../../../../core/admin-offline.types';
import { ApiUrls } from '../../../../../../core/api.urls';
import { InfoService } from '../../../../../../core/info/info.service';
import { InfoType, MessageConstants, MessageKey } from '../../../../../../core/info/info.types';
import { ModalDialog } from '../../../../../../core/modal-dialog';
import {
  EventParticipantsDateDialogComponent,
  EventParticipantsDateDialogData,
  EventParticipantsDateDialogResult,
} from './event-participants-date-dialog/event-participants-date-dialog.component';
import { VirtualRoomAttendance } from '../../../../../../core/virtual-room-attendance.types';
import { CachedSubject } from '../../../../../../core/cached-subject';
import { NavigationStart, Router } from '@angular/router';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { offptcpd_OFF_CTRL_LIST_PARTICIPANTS_DENIAL } from '../../../../../../core/principal/permission.states';
import { ApiResponse } from '../../../../../../core/global.types';
import { EventParticipantsHelper } from './event-participants.helper';
import { EventParticipants } from './event-participants.types';

@Injectable({ providedIn: 'root' })
export class EventParticipantsService {

  readonly participants$: Observable<VirtualRoomAttendance.ParticipantsData>;
  private _participants = new CachedSubject<VirtualRoomAttendance.ParticipantsData>(null);

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private router: Router,
    private principalService: PrincipalService
  ) {
    // clear cache on navigation
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .pipe(tap(this._participants.reset))
      .subscribe();

    this.participants$ = this._participants.withoutEmptyValues();
  }

  getParticipantsData(contentId: number, eventId: number): Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipants')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId));

    return this.http.get<VirtualRoomAttendance.ParticipantsData>(url)
      .pipe(map(data => {
        if (Object.keys(data.attendanceDuration  ?? {} ).length === 0) {
          return data;
        }
        data.assignedParticipants
          .forEach(assignedParticipant => {

            const userId = assignedParticipant.userId;

            const userAttendance = data.attendanceDuration[userId];
            const {
              attendanceDuration,
              attendanceDurationPercentage,
            } = EventParticipantsHelper.getAttendanceDurationForUser(
              userAttendance?.attendanceDurationMinutes ?? -1,
              userAttendance?.attendanceDurationPercentage ?? 0,
            );
            assignedParticipant.attendanceDuration = attendanceDuration;
            assignedParticipant.attendanceDurationPercentage = attendanceDurationPercentage;
          });
        return data;
      }))
      .pipe(tap(this._participants.next))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return EMPTY;
      }));
  }

  fetchVirtualRoomAttendance(contentId: number, eventId: number): Observable<VirtualRoomAttendance.Fetch> {
    const url = ApiUrls.getKey('VirtualRoomAttendance')
      .replace(/{offlineContentId}/i, String(contentId))
      .replace(/{offlineEventId}/i, String(eventId)) + '/fetch';

    return this.http.get<ApiResponse<VirtualRoomAttendance.Fetch>>(url)
      .pipe(map(data => data.attendanceFetch));
  }

  loadParticipants(contentId: number, eventId: number): Observable<VirtualRoomAttendance.ParticipantsData> {
    // always reset -> multiple queries in single cache
    this._participants.reset();

    if ( this._participants.queryStart() ) {

      return this.principalService
        .getObjectPermissions('offline_content', String(contentId))
        .pipe(switchMap(rbacActions => {

          if ( rbacActions?.includes(offptcpd_OFF_CTRL_LIST_PARTICIPANTS_DENIAL) === true ) {
            // offptcpd denies controlling permissions
            const data: VirtualRoomAttendance.ParticipantsData = {
              assignedParticipants: [],
              availableParticipants: [],
            };
            this._participants.next(data);
            return of(data);
          }
          return this.getParticipantsData(contentId, eventId)
            .pipe(tap(this._participants.next));
        }))
        .pipe(finalize(this._participants.queryDone));
    }

    return this._participants
      .withoutEmptyValuesWithInitial();
  }

  setExaminationStatus(contentId: number, eventId: number, passed: boolean, participants: OfflineContent.Participant[]):
    Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipantExaminationStatus')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId))
      .replace(/{action}/i, passed ? 'passed' : 'failed');
    return this.http.post<VirtualRoomAttendance.ParticipantsData>(url, participants.map(entry => ({ userId: entry.userId })))
      .pipe(tap(this._participants.next));
  }

  setInvitationStatus(contentId: number, eventId: number, invitationStatus: OfflineContent.OfflineInvitationStatus,
                      participants: OfflineContent.Participant[], date: moment.Moment = null):
    Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipantInvitationStatus')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId));
    return of(null)
      .pipe(switchMap(() => {
        if ( date?.isValid() ) {
          return of(participants.map(entry => ({
              invitationStatus,
              invitationStatusDate: date.unix() * 1000,
              userId: entry.userId,
            })));
        } else {
          const now = moment().startOf('d');
          return this.dialog
            .openModal<EventParticipantsDateDialogComponent, EventParticipantsDateDialogData,
              EventParticipantsDateDialogResult>(EventParticipantsDateDialogComponent, {
              data: {
                invitationStatus,
                participants: participants.map(entry => {
                  const invitationStatusDate = moment(entry.invitationStatusDate);
                  return {
                    date: invitationStatusDate.isValid() ? invitationStatusDate : now.clone(),
                    firstname: entry.firstname,
                    lastname: entry.lastname,
                    userId: entry.userId,
                  };
                }),
              },
            }).afterClosed()
            .pipe(switchMap((result: EventParticipantsDateDialogResult) => {
              if ( result?.participants?.length > 0 ) {
                return of(result.participants.map(entry => ({
                    invitationStatus,
                    invitationStatusDate: entry.date.unix() * 1000,
                    userId: entry.userId,
                  })));
              }
              return EMPTY;
            }));
        }
      }))
      .pipe(switchMap(changes => this.http.post<VirtualRoomAttendance.ParticipantsData>(url, changes)))
      .pipe(tap(this._participants.next));
  }

  setParticipationRequest(contentId: number, eventId: number, hasParticipated: boolean, participants: OfflineContent.Participant[]):
    Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipantParticipationRequest')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId))
      .replace(/{action}/i, hasParticipated ? 'accept' : 'reject');
    return this.http.post<VirtualRoomAttendance.ParticipantsData>(url, participants.map(entry => ({ userId: entry.userId })))
      .pipe(tap(this._participants.next));
  }

  setParticipationStatus(
    contentId: number, eventId: number, hasParticipated: boolean,
    participants: Pick<OfflineContent.Participant, 'userId'>[],
  ): Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipantParticipationStatus')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId))
      .replace(/{action}/i, hasParticipated ? 'participated' : 'didNotParticipate');
    return this.http.post<VirtualRoomAttendance.ParticipantsData>(url, participants.map(entry => ({ userId: entry.userId })))
      .pipe(tap(this._participants.next));
  }

  setRegistrationStatus(contentId: number, eventId: number, registrationStatus: OfflineContent.OfflineParticipationRegistrationStatus,
                        participants: OfflineContent.Participant[]): Observable<VirtualRoomAttendance.ParticipantsData> {
    const url = ApiUrls.getKey('CtrlOfflineParticipantRegistrationStatus')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId))
      .replace(/{registrationStatus}/i, registrationStatus);
    return this.http.post<VirtualRoomAttendance.ParticipantsData>(url, participants.map(entry => ({ userId: entry.userId })))
      .pipe(tap(this._participants.next));
  }

  updateParticipants(contentId: number, eventId: number, changes: VirtualRoomAttendance.AssignmentUpdate):
    Observable<VirtualRoomAttendance.ParticipantsData | never> {

      const assignChangesCount = changes.assign?.length ?? 0;
      const unAssignChangesCount = changes.unassign?.length ?? 0;
    if ( (changes == null) || !(contentId > 0) || !(eventId > 0) ||
      (assignChangesCount + unAssignChangesCount) === 0 ) {
      return EMPTY;
    }

    const url = ApiUrls.getKey('CtrlOfflineParticipants')
      .replace(/{contentId}/i, String(contentId))
      .replace(/{eventId}/i, String(eventId));
    return this.http.post<VirtualRoomAttendance.ParticipantsData>(url, changes)
      .pipe(catchError(this.handleError))
      .pipe(tap(this._participants.next));
  }

  enterPayment( request: EventParticipants.EnterTransactionRequest) {
    const url = `${ApiUrls.getKey('Financials')}/transaction/enter`;
    return this.http.post<ApiResponse<OfflineContent.MerchantTransaction>>(url, request)
      .pipe(map(response => response.transaction));
  }

  private handleError = (): Observable<void> => {
    this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
    return EMPTY;
  };

}
