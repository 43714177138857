<form [formGroup]="formGroup">

  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@admin_script_account_script">Account-wide JavaScript</span>
    </div>

    <div class="page-header-buttons">
      <button
        type="button"
        (click)="onSave()"
        [disabled]="!isDirty"
        color="primary"
        mat-flat-button
      >
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </rag-page-header>

  <div class="content">

    <mat-form-field appearance="outline">
      <textarea formControlName="accountScript" [rows]="20" matInput></textarea>
      <mat-hint >
        <span i18n="@@admin_script_hint">Enter JavaScript to execute for all users on this account.</span>
      </mat-hint>
    </mat-form-field>

  </div>

</form>
