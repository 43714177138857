<div class="sizing-container">
  <div *ngIf="(myLayout$ | async)" class="container">
    <!-- menu for add widget -->
    <mat-menu #addWidgetMenu class="widgets-panel-class">
      <div *ngIf="(selectableHelper$ | async) as helper">
        <button (click)="addWidget(redeemWidgetUUID)"
                *ngIf="helper.codeRedeemWidget.selectable && showSelectionCodeRedeem" mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="shield-key-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_license_code_title">Redeem Licence Code</span>
              <div class="widget-description" i18n="@@widget_redeem_descr">
                This widget is used to redeem license codes.
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(overviewWidgetUUID)"
                *ngIf="helper.overviewWidget.selectable && showSelectionOverview" mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="file-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_overview_title">Courses to be completed</span>
              <div class="widget-description" i18n="@@widget_overview_descr">
                This widget displays all open content.
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(newsWidgetUUID)" *ngIf="helper.newsWidget.selectable && showSelectionNews"
                mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="ballot-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_news_title">News</span>
              <div class="widget-description" i18n="@@widget_news_descr">
                This widget displays all news.
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(mycertificatesUUID)"
                *ngIf="helper.myCertificatesWidget.selectable && showSelectionMyCertificates"
                [disabled]="myWidgetsDisabled$ | async"
                mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="account-box-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_mycertificates_title">My Certificates</span>
              <div class="widget-description" i18n="@@widget_mycertificates_descr">
                This widget displays all certificates.
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(getSpecialsWidgetUUID)"
                *ngIf="helper.specialWidget.selectable && showSelectionSpecials" mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="star-circle-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_specials_title">Welcome</span>
              <div class="widget-description" i18n="@@widget_specials_descr">
                This widget displays a welcome message.
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(getEventsWidgetUUID)"
                *ngIf="helper.eventsWidget.selectable && showSelectionSpecials" mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="star-circle-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@widget_events_title">Events</span>
              <div class="widget-description">
                <span i18n="@@widget_events_descr">Display upcoming events</span>
              </div>
            </div>
          </div>
        </button>
        <button (click)="addWidget(gamificationWidgetUUID)"
                *ngIf="helper.gamificationWidget.selectable && showGamificationWidget" mat-menu-item>
          <div class="widgetWrapper">
            <mat-icon svgIcon="gamepad-variant-outline"></mat-icon>
            <div class="contentWidget">
              <span i18n="@@header_top_gamification">Awards</span>
              <div class="widget-description" i18n="@@widget_gamification_widget_descr">
                This widget displays your achievements.
              </div>
            </div>
          </div>
        </button>
      </div>
    </mat-menu>

    <mat-menu #headerActionsMenu>
      <button
        *ngIf="this.showAddWidgetButton"
        [matMenuTriggerFor]="addWidgetMenu"
        mat-menu-item
      >
        <mat-icon svgIcon="plus"></mat-icon>
        <span i18n="@@add_widget">Add Widget</span>
      </button>

      <button
        (click)="clearWidgetsAndLoadNewFromAdminSetting()"
        mat-menu-item>
        <mat-icon svgIcon="reload"></mat-icon>
        <span i18n="@@home_reset_widgets">Reset</span>
      </button>
    </mat-menu>

    <rag-page-header>
      <div
        *ngIf="!anonymous"
        class="page-header-title"
      >
        <span i18n="@@welcome">Welcome,</span>
        <span> {{ this.fullName }}</span>
      </div>

      <div class="page-header-buttons">

        <button
          (click)="toggleEdit()"
          *ngIf="isInEditMode && widgetsEditableForUser"
          mat-icon-button>
          <mat-icon svgIcon="check-outline"></mat-icon>
        </button>
        <button
          (click)="toggleEdit()"
          *ngIf="!isInEditMode && widgetsEditableForUser"
          mat-icon-button>
          <mat-icon svgIcon="pencil-outline"></mat-icon>
        </button>

        <button
          [disabled]="!isInEditMode"
          *ngIf="widgetsEditableForUser"
          [matMenuTriggerFor]="headerActionsMenu"
          class="rag-stroked-button"
          mat-stroked-button>
          <mat-icon svgIcon="menu"></mat-icon>
        </button>

      </div>
    </rag-page-header>

    <div class="rag-page-container">
      <rag-page #ragPage [pageId]="'layout-home'"></rag-page>
    </div>
  </div>
</div>
