import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { CachedSubject } from 'src/app/core/cached-subject';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageKey, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { ControllingSingleUserService } from '../../../../ctrl-single-user-util/ctrl-single-user.service';
import { CtrlSingleUserCertificates } from '../../ctrl-single-user-certificates.types';

@Component({
  selector: 'rag-ctrl-single-user-curr-validity',
  templateUrl: './curr-validity.component.html',
  styleUrls: [ './curr-validity.component.scss' ],
})
export class CurrValidityComponent
  implements OnDestroy {

  displayColumns = [ 'validity_period' ];
  isPristine$: Observable<boolean>;
  saveButtonDisabled$: Observable<boolean>;
  shouldReload = false;
  dataSource: MatTableDataSource<ControllingSingleUserTypes.CertificateAccountMinimumData>;
  tmpCertificateAccount: ControllingSingleUserTypes.CertificateAccountMinimumData;

  private _isPristine$ = new CachedSubject<boolean>(true);
  private _datesValidityState = new CachedSubject<boolean>(true);
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<CurrValidityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserCertificates.EditValidityDialogParams,
    private ctrlSingleUserService: ControllingSingleUserService,
    private infoService: InfoService
  ) {
    this.isPristine$ = this._isPristine$.asObservable();
    this.saveButtonDisabled$ = combineLatest([
      this.isPristine$,
      this._datesValidityState.asObservable(),
    ]).pipe(map(([ isPristine, datesValidityState ]) => isPristine || !datesValidityState));
    this.dataSource = new MatTableDataSource();
    if ( this.data.certificateAccount  != null ) {
      this.tmpCertificateAccount = { ...this.data.certificateAccount };
      this.dataSource.data = [ this.tmpCertificateAccount ];
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  dateFor(timestamp: number) {
    if ( timestamp != null && typeof timestamp === 'number' ) {
      return moment(timestamp);
    }
    return null;
  }

  onAddValidSince(event: moment.Moment) {
    if ( event.isValid() ) {
      setTimeout(() => {
        this.tmpCertificateAccount.validSince = event.unix() * 1000;
        this._isPristine$.next(false);
      });
    }
  }

  onValidityChanges($event: boolean) {
    this._datesValidityState.next($event);
  }

  onAddValidUntil(event: moment.Moment) {
    if ( moment.isMoment(event) ) {
      if ( event.isValid() ) {
        setTimeout(() => {
          this.tmpCertificateAccount.validUntil = event.unix() * 1000;
          this._isPristine$.next(false);
        });
      }
    } else {
      setTimeout(() => {
        this.tmpCertificateAccount.validUntil = null;
        this._isPristine$.next(false);
      });
    }
  }


  onSaveChanges() {
    this.ctrlSingleUserService
      .saveCertificateAccount(this.data.userId, this.tmpCertificateAccount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(savedIteration => {
        this.infoService.showSnackbar(null, InfoType.Success, {
          message: $localize`:@@ctrl_single_user_validity_saved:The validity has been updated successfully`,
        });
        this.shouldReload =  true;
        this._isPristine$.next(true);
        this.dialogRef.close(true);
    });
  }

  onCancel() {
    if ( this._isPristine$.value ) {
      this.dialogRef.close(false);
      return;
    }
    this.infoService
      .showConfirm(MessageKey.GENERAL_UNSAVED_CHANGES, YesNoButtons)
      .subscribe(button => {
        if ( button === YesButton ) {
          this.dialogRef.close(this.shouldReload);
        }
      });
  }

}

