import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Quests } from 'src/app/core/quest/quest.types';
import { map, Observable, of, switchMap, takeWhile, tap } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { InfoService } from 'src/app/core/info/info.service';
import { GenericMessageDialogComponent } from '../../generic-message-dialog/generic-message-dialog.component';
import { CancelButton, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { QuestService } from 'src/app/core/quest/quest.service';
import * as uuid from 'uuid';
import { MyValidators } from 'src/app/core/validators';
import { QuestsHelper } from 'src/app/core/quest/quest.helper';

@Component({
  selector: 'rag-quest',
  templateUrl: './quest.component.html',
  styleUrls: ['./quest.component.scss']
})
export class QuestComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  readonly = false;
  questTitle: string;

  readonly submitButtonDisabled$: Observable<boolean>;
  readonly conditions: Map<string, CachedSubject<boolean>>;
  private _submitButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private dialogRef: MatDialogRef<QuestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Quests.CtrlQuestResponse,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private questService: QuestService
  ) {
    this.conditions = new Map();
    this.submitButtonDisabled$ = this._submitButtonDisabled$.asObservable();
    this.readonly = this.data.feedback !== undefined;
  }

  ngOnInit(): void {
    const ctrlQuest = this.data.ctrlQuest;
    this.questTitle = this.data.quest.adminTitle !== undefined ?
      this.data.quest.adminTitle :
      `${ctrlQuest.title} : ${ctrlQuest.firstName} ${ctrlQuest.lastName}`;
    this.buildForm();
  }

  ngAfterViewInit(): void {
    // emulate trigger clicks if the feedback is given
    if (this.data.feedback === undefined) {
      return;
    }
    this.data.quest.quest.fragments.forEach(fragment => {
      if (fragment.options !== undefined) {
        fragment.options.forEach(option => {
          const condElement = document.querySelector(`[ng-cond="${option.condition}"]`);
          if (condElement === undefined) {
            return;
          }
          // the element has not been positioned yet. Let's do it.
          const triggerElement = document.querySelector(`[ng-trigger="${option.condition}"]`);
          if (triggerElement) {
            triggerElement.parentElement.insertBefore(condElement, triggerElement.nextSibling);
            condElement.classList.remove('hidden');
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getForm(i: number): FormGroup {
    return this.form.get(String(i)) as FormGroup;
  }

  onCancel() {
    if (!this.form.pristine) {
      this.infoService.showDialog(GenericMessageDialogComponent, {
        title: $localize`:@@global_warning:Warning`,
        message: $localize`:@@global_changes:You have made some changes. Would you really like to exit without saving?`,
        buttons: CancelButton | YesButton
      })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(tap(_ => this.dialogRef.close()))
      .subscribe();
      return;
    }
    this.dialogRef.close();
  }

  onSubmit(_: Event) {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      title: $localize`:@@global_warning:Warning`,
      message: $localize`:@@ctrl_feedback_submit_notice:
        Once you submit your entries for '${this.data.quest.adminTitle}' you cannot change it or redo it.
        Would you like to submit?
        `,
      buttons: YesNoButtons
    })
    .pipe(takeWhile(button => button === YesButton))
    .pipe(tap(_ => this._submitButtonDisabled$.next(true)))
    .pipe(map(_ => this.buildFeedback()))
    .pipe(switchMap(feedback => {
      if (this.data.quest.type === 'sup') {
        return this.questService.submitCtrlFeedback(
          this.data.quest.uuid,
          this.data.objType,
          this.data.objId,
          feedback
        );
      }
      return this.questService.submitFeedback(
        this.data.quest.uuid,
        this.data.objType,
        this.data.objId,
        feedback
      );
    }))
    .pipe(tap(feedback => this.dialogRef.close(feedback)))
    .pipe(tap(_ => this.infoService.showMessage($localize`:@@ctrl_feedback_submit_success:
      Your data for '${this.data.quest.adminTitle}' hav been submitted successfully.
    `)))
    .subscribe();
  }

  hiddenConditionForComp(conditionId: string): Observable<boolean> {
    if (conditionId === undefined) {
      return of(false);
    }
    const condition = this.conditions.get(conditionId);
    return condition !== undefined ? condition : of(false);
  }

  conditionIdIfAny(element: Quests.ConditionRelated) {
    if (element.condition !== undefined) {
      return element.condition;
    }
    return null;
  }

  private buildFeedback(): Quests.Feedback {
    const feedback = this.data.quest.quest.fragments.reduce((pV, fragment, index) => {
      if ( !QuestsHelper.isInputFragment(fragment) ) {
        return pV;
      }
      const fragmentForm = this.form.get(String(index));
      return {...pV, ...fragmentForm.value};
    }, {});

    const payload: Quests.Feedback = {
      objId: this.data.objId,
      objType: this.data.objType,
      uuid: uuid.v4(),
      feedback: feedback,
      questUUID: this.data.quest.uuid,
      whenSubmitted: 0,
      iteration: this.data.ctrlQuest?.iteration ?? 0
    };

    if (this.data.quest.type === 'sup') {
      payload.targetUserId = this.data.ctrlQuest?.userId ?? 0;
    }

    return payload;
  }

  private buildForm() {
    this.form = this.formBuilder.group({});
    let fIndex = 0;

    const { quest } = this.data.quest;
    const conditions = quest.conditions;

    if ( conditions !== undefined ) {
      Object.keys(conditions).forEach(conditionUUID => {
        const condition = conditions[conditionUUID];
        this.conditions.set(conditionUUID, new CachedSubject<boolean>(condition.hidden));
      });
    }

    quest.fragments.forEach(fragment => {
      const _fragmentForm = this.formBuilder.group({});
      fragment.id = fIndex;
      if ( fragment.items !== undefined ) {
        fragment.items.forEach(item => {
          const formControl = new FormControl();
          // handle already given feedback
          const givenFeedback = this.data.feedback?.feedback;
          if (givenFeedback !== undefined) {
            const feedbackValue = givenFeedback[item.uuid];
            if (feedbackValue !== undefined) {
              formControl.setValue(feedbackValue, { emitEvent: false });
            }
            formControl.disable();
          } else {
            // handle required flag
            if ( item.required ) {
              formControl.addValidators(Validators.required);
              if ( fragment.type === 'checkbox' ) {
                formControl.addValidators(MyValidators.ValueValidator(true));
              }
            }
          }

          _fragmentForm.addControl(item.uuid, formControl);
          // handle options conditions, if any
          if (fragment.options !== undefined) {
            fragment.options.forEach(option => {
              if (option.condition !== undefined) {
                formControl.valueChanges.pipe(map(_value => {
                  const hideCondValue = _value !== option.value;
                  const condElement = document.querySelector(`[ng-cond="${option.condition}"]`);
                  if (condElement === undefined) {
                    return;
                  }
                  if (!hideCondValue) {
                    // move the target element as child to the trigger
                    if (!condElement.hasAttribute('ng-repositioned')) {
                      // the element has not been positioned yet. Let's do it.
                      const triggerElement = document.querySelector(`[ng-trigger="${option.condition}"]`);
                      if (triggerElement) {
                        triggerElement.parentElement.insertBefore(condElement, triggerElement.nextSibling);
                        condElement.setAttribute('ng-repositioned', '');
                      }
                    }
                  } else {
                    this.resetItemsForFragment(condElement.getAttribute('ng-fragment'));
                  }
                  this.conditions.get(option.condition).next(hideCondValue);
                }))
                .pipe(takeUntilDestroyed(this))
                .subscribe();
              }
            })
          }
        });
      }
      this.form.addControl(String(fIndex++), _fragmentForm);
    });

    this.form.statusChanges.pipe(map(status => {
      this._submitButtonDisabled$.next(status !== 'VALID');
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  private resetItemsForFragment(fragmentId: string) {
    if (fragmentId == null) {
      return;
    }
    const fragmetnForm = this.form.get(fragmentId);
    if (fragmetnForm === undefined) {
      return;
    }
    fragmetnForm.reset();
  }

}
