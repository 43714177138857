<div *ngIf="(startPage$ | async) as startPage" class="container">
  <rag-page-header>
    <h1 class="page-header-title">
      <span i18n="@@title_about_password_forgotten">Password forgotten?</span>
    </h1>
  </rag-page-header>
  <div *ngIf="!requestIsSent || form.invalid" class="box">
    <h2>
      <span i18n="@@subtitle_about_password_forgotten">How to reset your password</span>
    </h2>
    <p id="text" [innerHTML]="descriptionAboutPasswordForgotten | safe:'html'">
    </p>
    <div class="form">
      <form (submit)="tryPasswordForgotten()" [formGroup]="form">
        <mat-form-field appearance="outline">
          <mat-label>
            <span>{{startPage.acc.loginPlaceholder | localeText}}</span>
          </mat-label>
          <input [formControlName]="'login'"
                 matInput
                 required type="text">
          <mat-error>
            <span *ngIf="hasError('login', 'required')"
                  [innerHTML]="startPage.acc.loginError | localeText | safe:'html'"
                  displayOne
                  i18n="@@validation_required">This field is required</span>
            <span *ngIf="hasError('login', 'network')"
                  [innerHTML]="networkError | safe:'html'"
                  displayOne></span>
            <rag-messages *ngIf="hasError('login', 'network_generic')"
                          displayOne
                          key="general_error"
            ></rag-messages>
          </mat-error>
        </mat-form-field>

        <div id="buttons" class="row row-right">
          <button (click)="cancel()"
                  class="rag-stroked-button" mat-stroked-button
                  type="button">
            <span i18n="@@cancel_button">Cancel</span>
          </button>
          <button
            [disabled]="form.pristine || form.invalid"
            color="primary"
            mat-flat-button
            type="submit">
            <span i18n="@@password_reset_button">Request password</span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="requestIsSent" class="box" id="request-is-sent">
    <div id="pswd-is-sent">
      <h2>
        <span i18n="@@subtitle_reset_request_has_been_sent">Your request has been processed</span>
      </h2>
      <p id="thanks" i18n="@@password_reset_request_has_been_sent_text1">Thank you! Your request has been processed.</p>

      <ul class="withIcon">
        <li>
          <div><mat-icon svgIcon="check-circle-outline" class="green"></mat-icon></div>
          <span [innerHTML]="passwordResetRequestHasBeenSentText2 | safe:'html'"></span>
        </li>
        <li>
          <div><mat-icon svgIcon="check-circle-outline" class="green"></mat-icon></div>
          <span [innerHTML]="passwordResetRequestHasBeenSentText3 | safe:'html'"></span>
        </li>
        <li>
          <div><mat-icon svgIcon="alert-circle-outline" class="red"></mat-icon></div>
          <span [innerHTML]="passwordResetRequestHasBeenSentText4 | safe:'html'"></span>
        </li>
        <li>
          <div><mat-icon svgIcon="alert-circle-outline" class="red"></mat-icon></div>
          <span [innerHTML]="passwordResetRequestHasBeenSentText5 | safe:'html'"></span>
        </li>
      </ul>
    </div>

    <button id="go-home" mat-stroked-button routerLink="/home" type="button">
      <span i18n="@@navigation_home">Home</span>
    </button>
  </div>
</div>
