import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CertIterationDetails, CurStatusItem } from '../certificates.types';

@Component({
  selector: 'rag-certificates-info',
  templateUrl: './certificates-info.component.html',
  styleUrls: [ './certificates-info.component.scss' ],
})

export class CertificatesInfoComponent
  implements OnInit {
  datasource: MatTableDataSource<CurStatusItem>;
  displayAll = false;
  displayedColumns: string[] = [ 'displayStatus', 'title', 'finishDate', 'result' ];

  constructor(
    public dialogRef: MatDialogRef<CertificatesInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CertIterationDetails) {

    this.datasource = new MatTableDataSource([]);
  }

  get hasData() {
    return this.datasource.data.length > 0;
  }

  get hasMore() {
    return this.data.curstatus.items.length > 1;
  }

  private get lastOne() {
    const items = this.data.curstatus.items;
    return [ items[items.length - 1] ];
  }

  ngOnInit() {
    const _items = this.data.curstatus.items;
    if ( _items != null && _items.length > 0 ) {
      this.datasource.data = this.lastOne;
      return;
    }
  }

  toggleDisplayAll($event: MouseEvent) {
    this.displayAll = !this.displayAll;
    if ( this.displayAll ) {
      this.datasource.data = this.data.curstatus.items;
    } else {
      this.datasource.data = this.lastOne;
    }
  }
}
