import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../widgets.types';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { LearnerGamificationService } from '../../../core/gamification/learner-gamification.service';
import { map, take } from 'rxjs/operators';
import { GamificationProfile } from '../../../core/gamification/gamification.types';
import { Core } from '../../../core/core.types';
import DistributableType = Core.DistributableType;

@Component({
  selector: 'rag-gamification-widget',
  templateUrl: './gamification-widget.component.html',
  styleUrls: [ './gamification-widget.component.scss' ],
})
export class GamificationWidgetComponent
  extends BaseWidgetComponent
  implements OnInit {
  @Input() didReceiveResponse: DidReceiveResponseHandler;
  gamificationProfile: GamificationProfile;
  learningCount = 0;
  curriculumCount = 0;
  eventCount = 0;
  hasData = false;
  xpProgressBarValue = 0;

  constructor(
    private learnerGamificationService: LearnerGamificationService,
  ) {
    super();
    this.setTitle($localize`:@@header_top_gamification:Awards`);
  }

  ngOnInit() {
    this.learnerGamificationService
      .getGamificationDataForWidget()
      .pipe(map(response => {
        this.gamificationProfile = response;
        const badgeCounts = this.gamificationProfile?.counts ?? [];
        badgeCounts?.forEach(badgeCount => {
            switch ( badgeCount.objectType ) {
              case DistributableType.lms_course:
                this.learningCount = badgeCount.count;
                break;
              case DistributableType.lms_curriculum:
                this.curriculumCount = badgeCount.count;
                break;
              case DistributableType.lms_offlineCnt:
                this.curriculumCount = badgeCount.count;
                break;
            }
          },
        );
        this.xpProgressBarValue = this.getProgressBarValue();
        this.hasData = true;
        this.didReceiveResponse({
          hasContent: this.gamificationProfile != null,
        });
      }))
      .pipe(take(1))
      .subscribe();
  }

  getProgressBarValue(): number {
    const xpForLevel = this.learnerGamificationService
      .getXpForGamificationLevel((this.gamificationProfile?.level + 1) ?? 0);
    return ((this.gamificationProfile?.xp ?? 0) / xpForLevel) * 100;
  }

  isDataDriven() {
    return true;
  }

  getBadge(): any {
    const url = 'assets/images/badge1.png';
    console.log('70');
    return {
      'background-image': `url('${url}')`,
    };
  }

  getXpTooltip(): string {
    const xpForNextLevel = this.learnerGamificationService.getXpForGamificationLevel(this.gamificationProfile?.level);
    return `${this.gamificationProfile?.xp}/${xpForNextLevel}xp`;
  }
}
