import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { TrainImage } from '../account-design.types';
import { AccountDesignService } from '../account-design.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { DeleteDialogComponent } from '../../../../component/delete-dialog/delete-dialog.component';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { ModalDialog } from '../../../../core/modal-dialog';
import { cloneDeep } from 'lodash';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';
import { CKEditorDefaults } from '../../../../core/ckeditor.types';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { RagCKEditorHelper } from '../../../../core/input/rag-ckeditor/ragCkeditorHelper';

interface EditLoginPageData {
  item: string;
  language: string;
  value: any;
}

@Component({
  selector: 'rag-edit-login-page-dialog',
  templateUrl: './edit-login-page-dialog.component.html',
  styleUrls: [ './edit-login-page-dialog.component.scss' ],
})

export class EditLoginPageDialogComponent
  implements OnInit, OnDestroy {

  Editor = Editor;
  MAX_LENGTH = 70;
  config = MergeHelper.cloneDeep(CKEditorDefaults.CKEDITOR_OPTIONS);
  images$ = new BehaviorSubject<TrainImage[]>(null);
  language: string;
  toBeEdited: any;

  constructor(
    private dialogRef: MatDialogRef<EditLoginPageDialogComponent>,
    private service: AccountDesignService,
    private infoService: InfoService,
    public dialog: ModalDialog,
    @Inject(MAT_DIALOG_DATA) public data: EditLoginPageData,
  ) {
    this.language = data.language;
    this.toBeEdited = cloneDeep(data.value);
    const removeItems = [
      'elementspath', 'a11yhelp', 'about', 'copyformatting', 'div', 'selectall', 'language', 'find', 'flash', 'forms',
      'image', 'magicline', 'pagebreak', 'pastefromword', 'preview', 'print', 'showblocks', 'table', 'tableselection',
      'tabletools', 'templates', 'imageInsert', 'trainMacros', 'sourceEditing',
    ];
    RagCKEditorHelper.reduceCKEditorItems(removeItems, this.Editor);
  }

  deleteImage(id: number) {
    this.service.deleteImage(id)
      .pipe(switchMap(() => this.service.getImages()),
      )
      .pipe(take(1))
      .pipe(catchError(e => {
        this.infoService.showSnackbar(MessageKey.ACC_PICTURE_NOT_DELETED, InfoType.Error);
        return e;
      }))
      .subscribe(_ => this.infoService.showSnackbar(MessageKey.ACC_PICTURE_DELETED, InfoType.Success));
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    // load images
    this.service.getImages()
      .pipe(map(images => {
        this.images$.next(images);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onClick(result: boolean): void {
    this.dialogRef.close({ confirmed: result, data: this.toBeEdited });
  }

  openDialog(id: number): void {
    this.dialog.openModal(DeleteDialogComponent)
    .afterClosed()
    .pipe(take(1))
    .subscribe(result => {
      if ( result ) {
        this.deleteImage(id);
      }
    });
  }

  reloadImages() {
    this.service.getImages().pipe(map(images => {
      this.images$.next(images);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  selectAsNewWelcomePicture(fileId: number) {
    this.toBeEdited = fileId;
  }
}
