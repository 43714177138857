import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rag-details-row-toggle',
  templateUrl: './details-row-toggle.component.html',
  styleUrls: [ './details-row-toggle.component.scss' ],
})
export class DetailsRowToggleComponent
  implements OnInit {

  _expanded = false;
  @Output() toggleChange = new EventEmitter<boolean>(false);

  constructor() {
  }

  get expanded(): boolean {
    return this._expanded;
  }

  @Input() set expanded(value: boolean) {
    this._expanded = value;
  }

  ngOnInit() {
  }

  toggle() {
    this.expanded = !this.expanded;
    this.toggleChange.emit(this.expanded);
  }

}
