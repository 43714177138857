<div class="container">
  <div *ngIf="showTotal || (statistics.totalCount >= 0)" class="row">
    <span *ngIf="showAsText" class="total" i18n="@@report_total">Total:</span>
    <span
      class="entries-current"
      i18n="@@report_entries_current">{statistics.totalCount, plural, =1 {1 entry} other {{{statistics.totalCount}} entries}}</span>
    <ng-container
      *ngIf="showTotal && (statistics.grandTotalCount >= 0) && (statistics.totalCount !== statistics.grandTotalCount)">
      &nbsp;<span
      class="entries-total"
      i18n="@@report_entries_total">{statistics.grandTotalCount, plural, other { (of {{statistics.grandTotalCount}})}}</span>
    </ng-container>
  </div>
  <div *ngIf="showAsText; else showIcons" class="text-statistics">
    <div class="count">
      <span class="count-text">{{statistics.redCount + statistics.otherCount}}</span>
      <span i18n="@@report_text_status_red">red</span>
    </div>
    <div class="separator">&mdash;</div>
    <div *ngIf="contentType === 'lms_curriculum'" class="count">
      <span class="count-text">{{statistics.recertificationCount}}</span>
      <span i18n="@@report_text_status_redgreen">red/green</span>
    </div>
    <div *ngIf="contentType !== 'lms_curriculum'" class="count">
      <span class="count-text">{{statistics.yellowCount}}</span>
      <span i18n="@@report_text_status_yellow">yellow</span>
    </div>
    <div class="separator">&mdash;</div>
    <div class="count">
      <span class="count-text">{{statistics.greenCount}}</span>
      <span i18n="@@report_text_status_green">green</span>
    </div>
  </div>
  <ng-template #showIcons>
    <div *ngFor="let status of statusValues" class="status-count">
      <rag-status-lights [statusType]="contentType" [displayStatus]="status"></rag-status-lights>
      <ng-container [ngSwitch]="status">
        <!-- red -->
        <span *ngSwitchCase="16" class="count">{{statistics.redCount + statistics.otherCount}}</span>
        <span *ngSwitchCase="2" class="count">{{statistics.redCount + statistics.otherCount}}</span>
        <!-- incomplete -->
        <span *ngSwitchCase="4" class="count">{{statistics.yellowCount}}</span>
        <!-- recertification -->
        <span *ngSwitchCase="7" class="count">{{statistics.recertificationCount}}</span>
        <!-- green -->
        <span *ngSwitchCase="1" class="count">{{statistics.greenCount}}</span>
        <span *ngSwitchCase="5" class="count">{{statistics.greenCount}}</span>
      </ng-container>
    </div>
  </ng-template>
</div>
