import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportTableService } from '../report-table.service';
import { map, tap } from 'rxjs/operators';
import { Report, ReportConfig } from '../../../../../../core/report/report.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../core/reactive/until-destroyed';
import { Statistics } from '../../../report-generator/status-statistics/status-statistics.types';
import { ActivatedRoute } from '@angular/router';
import { isNothing } from 'src/app/core/utils';


@Component({
  selector: 'rag-report-table-controls',
  templateUrl: './report-table-controls.component.html',
  styleUrls: [ './report-table-controls.component.scss' ],
})
export class ReportTableControlsComponent
  implements OnInit, OnDestroy {

    isNothing = isNothing;

  private _report: Report;
  private _curriculumIds: Array<number>;

  constructor(
    private reportTableService: ReportTableService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.reportTableService.report$
      .pipe(tap(this.updateReport))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.activatedRoute.paramMap.pipe(map(params => {
      const curriculumIds = params.get('curriculumId');
      if (!isNothing(curriculumIds)) {
        this._curriculumIds = curriculumIds.split(',').map(s => parseInt(s, 10));
      }
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  get canSave(): boolean {
    if ( this.reportTableService.isNew && !this.reportTableService.canSaveNewReport ) {
      // not allowed to save additional reports
      return false;
    }

    return this.reportTableService.isDirty;
  }

  get hasSelections(): boolean {
    return this.reportTableService.selectedRows.length > 0;
  }

  get reportConfig(): ReportConfig | null {
    return this._report?.reportConfig;
  }

  get reportStatistics(): Statistics | null {
    return this._report?.statistics;
  }

  get isOffline() {
    return this.reportConfig?.targetType === 'Offline';
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onSaveReport(): void {
    this.reportTableService.saveReportConfig(this._curriculumIds);
  }

  private updateReport = (report: Report | null): void => {
    this._report = report;

    this._curriculumIds = report.reportConfig.curriculumIds;
  };

}
