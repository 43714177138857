import { Price } from './financials.types';
import { Core, PrincipalType } from '../core.types';

export class FinancialsHelper {

  /**
   * Accept a form input value and return a price rounded to two decimal places.<br/>
   * <br/>
   * <strong>Warning:</strong> You need to use {@link FinancialsHelper.fromPriceInCent} to render values from an entity.
   */
  static asPrice(value: string | number): number {
    if ( value == null ) {
      return null;
    }

    value = String(value)
      // replace the last decimal point with an x
      .replace(/[.,](\d+)$/, 'x$1')
      // remove all other "decimal" points (e.g. for thousands)
      .replace(/[.,]/g, '')
      // restore the decimal point
      .replace(/x/g, '.')
      // remove currency sign
      .replace(/€/g, '')

      .trim();

    if ( !/^\d+([.]\d+)?$/.test(value) ) {
      return null;
    }

    const result = parseFloat(value);
    if ( result % 1 > 0 ) {

      // trim to two decimal places (ignores anything further than two digits)
      return Math.round(result * 100) / 100.0;
    } else {

      // there are no digits after the decimal point -> return verbatim
      return result;
    }
  }

  static formatInputValue(value: string | number): string {
    const price = FinancialsHelper.asPrice(FinancialsHelper.stripCurrency(value));
    if ( price == null ) {
      return '';
    }

    return price.toFixed(2);
  }

  static asPriceInCent(value: string | number): number {
    const result = FinancialsHelper.asPrice(value);

    if ( result == null ) {
      return null;
    }

    return Math.round(result * 100);
  }

  static fromPriceInCent(value: string | number): string {
    if ( value == null ) {
      return '';
    }

    return (parseInt(String(value), 10) / 100.0).toFixed(2);
  }

  static stripCurrency<T = string | number>(value: T): T {
    if ( typeof (value) === 'string' ) {
      return (value as any)
        // remove anything in front, and at the end, that is not a number (with decimals / hundreds)
        .replace(/^.*?([\d.,]+).*?$/, '$1');
    } else {
      return value;
    }
  }

  static createPrice(
    price: number,
    uuid?: string,
    comment?: string,
    objId?: number,
    objType?: Core.DistributableType,
    targetId?: number,
    currency: string = 'EUR',
  ): Price {
    return {
      objectId: objId ?? null,
      objectType: objType ?? null,
      targetId: targetId ?? null,
      targetType: PrincipalType.targetGroup,
      value: FinancialsHelper.asPriceInCent(price),
      currency: currency,
      uuid: uuid ?? null,
      description: comment,
    }
  }
}
