import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormGroup } from '@angular/forms';
import { InfoService } from '../../../core/info/info.service';
import { InputHelper } from '../input.types';
import { InfoType } from '../../../core/info/info.types';
import { Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'rag-form-submit-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './form-submit-button.component.html',
  styleUrls: ['./form-submit-button.component.scss']
})
export class FormSubmitButtonComponent {

  @Input() form: FormGroup | null;
  @Input() disabled = false;
  @Input() label: string = $localize`:@@global_save:Save`;
  @Input() warningMessage: string = $localize`:@@form_submit_button_warningMessage:
    The form contains errors. Please review and correct them before submitting.
  `;

  @Output() readonly doSubmit: Observable<void>;

  private _doSubmit = new EventEmitter<void>();

  constructor(
    private infoService: InfoService,
  ) {
    this.doSubmit = this._doSubmit.asObservable();
  }

  protected onSubmit(): void {
    this._doSubmit.emit();
  }

  protected onSubmitCheckInvalid(): void {
    const invalid = InputHelper.touchIfInvalid(this.form);
    if (invalid && this.warningMessage) {
      this.infoService.showMessage(this.warningMessage, { infoType: InfoType.Error });
    }
  }

  protected isLoading(form: AbstractControl | null): boolean {
    return InputHelper.hasAnyLoading(form);
  }

  protected hasError(form: AbstractControl | null): boolean {
    const isNotInvalid = form?.invalid !== true;
    if (isNotInvalid) {
      return false;
    }
    // do not show error button while loading
    return !this.isLoading(form);

  }

}
