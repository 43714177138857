import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TableControllerComponent } from '../../table/table-controller/table-controller.component';
import { CurriculumPathSwitchColumns } from './curriculum-path-switch.columns';
import { TableColumnMenuService } from '../../table/table-column-menu/table-column-menu.service';
import { TableHelper } from '../../table/table-helper';
import { TableGroupingTypes } from '../../table/table-grouping/table-grouping.types';
import { CurriculumPathSwitchTypes } from 'src/app/core/curriculum/curriculum-path-switch.types';


export interface CurriculumPathSwitchDialogData {
  entries: CurriculumPathSwitchTypes.CurriculumSwitchData[];
}

@Component({
  selector: 'rag-curriculum-path-switch',
  templateUrl: './curriculum-path-switch.component.html',
  styleUrls: [ './curriculum-path-switch.component.scss' ],
})
export class CurriculumPathSwitchComponent
  extends TableControllerComponent<CurriculumPathSwitchColumns.RowData,
    CurriculumPathSwitchColumns.ColumnMenuData> {

  formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CurriculumPathSwitchDialogData,
    private formBuilder: UntypedFormBuilder,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);

    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;

    this.formGroup = this.formBuilder.group(this.data.entries.reduce((pV, entry) => {
      const formControl = new UntypedFormControl(null, Validators.required);
      pV[String(entry.curriculumId)] = formControl;
      formControl.registerOnChange(() => {
        entry.selectedVariationCounter = formControl.value;
      });
      return pV;
    }, {}));

    const menuData = TableColumnMenuService.createFromDefaults(CurriculumPathSwitchColumns.DEFAULT_MENU_COLUMNS);
    this.setColumns(TableColumnMenuService.menuDataToColumns(menuData));
    this.columnMenuData = menuData;

    this.setTableData(CurriculumPathSwitchColumns.Util.toTableRows(this.data.entries));
    this.checkFilter();
  }

  get result(): CurriculumPathSwitchTypes.CurriculumSwitchData[] {
    return this.data.entries
      .map(entry => {
        const control = this.formGroup.get(String(entry.curriculumId));
        if ( control.invalid ) {
          return;
        }
        entry.selectedVariationCounter = control.value;
        return entry;
      })
      .filter(entry => entry != null);
  }

  asChild(row: CurriculumPathSwitchColumns.RowData): CurriculumPathSwitchColumns.RowDataChild {
    if ( !this.isChild(row) ) {
      return null;
    }
    return row as CurriculumPathSwitchColumns.RowDataChild;
  }

  asParent(row: CurriculumPathSwitchColumns.RowData): CurriculumPathSwitchColumns.RowDataParent {
    if ( !this.isParent(row) ) {
      return null;
    }
    return row as CurriculumPathSwitchColumns.RowDataParent;
  }

  getUsers(curriculum: CurriculumPathSwitchTypes.CurriculumSwitchData): CurriculumPathSwitchTypes.UserInfo[] {
    return curriculum.users;
  }

  isChild(row: CurriculumPathSwitchColumns.RowData): boolean {
    return row.$rowType === 'child';
  }

  isChildChanged(row: CurriculumPathSwitchColumns.RowData): boolean {
    if ( row.$rowType !== 'child' ) {
      return false;
    }

    const parent = row.$parent;
    const selectedVariationCounter = parent?.$data?.selectedVariationCounter;
    if ( selectedVariationCounter == null ) {
      return false;
    }

    const childRow = row as CurriculumPathSwitchColumns.RowDataChild;
    return childRow.$data?.curriculumVariationCounter !== selectedVariationCounter;
  }

  isChildRow = (index: number, row: CurriculumPathSwitchColumns.RowData): boolean => this.isChild(row);

  isControlInvalid(row: CurriculumPathSwitchColumns.RowData): boolean {
    const formControl = this.asParent(row)?.formControl;
    if ( formControl == null ) {
      return true;
    }
    return this.formGroup.get(formControl)?.invalid ?? true;
  }

  isParent(row: CurriculumPathSwitchColumns.RowData): boolean {
    return row.$rowType === 'parent';
  }

  isParentRow = (index: number, row: CurriculumPathSwitchColumns.RowData): boolean => this.isParent(row);

  private filterPredicate = (data: CurriculumPathSwitchColumns.RowData): boolean => data.$visible;

  private sortingDataAccessor = (data: CurriculumPathSwitchColumns.RowData, sortHeaderId: string) => {
    const column = this.columnMenuData?.menuItems?.[sortHeaderId];
    if ( column == null ) {
      // failed to find column details!
      return TableHelper.sortingDataAccessor(data, sortHeaderId);
    }

    // magic :D
    const direction = this.dataSource.sort?.direction;
    return TableGroupingTypes.Util.sortingDataAccessor(data, sortHeaderId, direction,
      row => TableGroupingTypes.Util.sortingDataAccessorToString(row[column.id], column));
  };

}
