import { Injectable } from '@angular/core';
import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { mapTo, switchMap, take } from 'rxjs/operators';
import { LoginDialogComponent } from './login-dialog.component';
import { Observable } from 'rxjs';
import { InfoService } from '../../../core/info/info.service';
import { LoginFormService } from '../login-form/login-form.service';
import { LoginFormData } from '../login-form/login-form.types';


@Injectable({ providedIn: 'root' })
export class LoginDialogService {

  constructor(
    private accountDesignService: AccountDesignService,
    private infoService: InfoService,
    private loginFormService: LoginFormService,
  ) {
  }

  showDialog(): Observable<void> {
    return this.loginFormService.getLoginFormData()
      .pipe(take(1))
      .pipe(switchMap(data => this.infoService
        .showDialog<LoginDialogComponent, LoginFormData>(LoginDialogComponent, data)))
      .pipe(mapTo(void (0)));
  }

}
