<div class="flex-box-column">
  <mat-divider></mat-divider>
  <div class="margin-border">
    <h4 i18n="@@global_todo_task">Task description</h4>
    <div class="flex-row">

      <div class="margin-right title" [innerHTML]="courseTodo.taskDescription | localeText | safe:'html'"></div>

      <rag-file-multi
        class="file-width"
        [files]="courseTodo.attachments"
        [fitVertical]="true"
        [readonly]="true">
      </rag-file-multi>

    </div>
  </div>

  <ng-container *ngIf="hasSampleSolution()">
    <mat-divider></mat-divider>

    <div class="margin-border">
      <div class="align-horizontal">
        <h4 i18n="@@global_sample_solution">Sample solution</h4>
        <button
          *ngIf="sampleSolution.onlyForControlling"
          type="button"
          mat-icon-button
        >
          <mat-icon
            svgIcon="information-outline"
            [matTooltip]="getSampleSolutionTooltip()"
          ></mat-icon>
        </button>
      </div>

      <div class="flex-row">

        <div class="margin-right title"
             [innerHTML]="getSampleSolutionText() | localeText | safe:'html'"></div>

        <rag-file-multi
          class="file-width background-white"
          [files]="sampleSolution.files"
          [fitVertical]="true"
          [readonly]="true">
        </rag-file-multi>

      </div>
    </div>
  </ng-container>

  <mat-divider></mat-divider>

  <div class="background">
    <div class="margin-border">
      <h4 i18n="@@learner_user_course_todo_solution">Solution</h4>
      <div class="flex-row">

        <div class="margin-right title"
             [innerHTML]="courseTodo.contribution?.contribution | localeText | safe:'html'"></div>

        <rag-file-multi
          class="file-width background-white"
          [files]="courseTodo.contribution?.files"
          [fitVertical]="true"
          [readonly]="true">
        </rag-file-multi>

      </div>
    </div>
  </div>

  <ng-container *ngIf="courseWithSelfAssessment()">
    <mat-divider></mat-divider>
    <div class="background">
      <div class="margin-border">
        <h4 i18n="@@global_self_assessment">Self-Assessment</h4>
        <ng-container *ngIf="hasSelfAssessment() else noSelfAssessment">

          <div class="flex-row">

            <div class="self-assessment title" [innerHTML]="getSelfAssessmentComment() | localeText | safe:'html'"></div>

            <div class="flex-box-column slider">
              <!-- TODO: The 'tickInterval' property no longer exists -->
              <mat-slider
                step="1"
                min="1"
                max="10"
                aria-label="units"
                color="primary"
              ><input matSliderThumb /><input matSliderThumb [value]="getSliderValue()" />
              </mat-slider>
              <div class="flex-box-row">
                <ng-container *ngFor="let value of scala">
                  <span class="scala-item" [ngStyle]="getStyles(value)">{{value}}</span>
                </ng-container>
              </div>
              <div class="slider-value">
                (<span i18n="@@global_estimation">Estimation</span>
                <span>: {{getSliderValue()}}</span>)
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noSelfAssessment>
  <span class="description" i18n="@@todo_data_no_self_assessment">There is no self-assessment yet.</span>
</ng-template>
