import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LearnerContentTypes } from '../../../../../../user/content/learner-content-util/learner-content.types';
import { AdminCoursesTypes } from '../../../../../../../core/admin-courses.types';
import { SelfEstimationResponse } from '../../../../../../user/content/learner-course/learner-course-self-estimation/learner-course-self-estimation.types';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SubscriptionHolder } from '../../../../../../../core/reactive/subscription-holder';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../../core/reactive/until-destroyed';
import { Translation } from '../../../../../../../core/translation/translation.types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'rag-todo-data-v2',
  templateUrl: './todo-data-v2.component.html',
  styleUrls: [ './todo-data-v2.component.scss' ],
})
export class TodoDataV2Component
  implements OnInit, OnDestroy {

  @Input() courseTodo: LearnerContentTypes.CourseAccountToDo;
  @Input() extensions: AdminCoursesTypes.CourseExtension[];
  estimation: SelfEstimationResponse;
  sampleSolution: AdminCoursesTypes.SampleSolutionExt;
  scala = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  courseId = 0;
  private _clearSolutionAndEstimation = new SubscriptionHolder<boolean>(this);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params
      .subscribe(params => {
        const courseId = parseInt(params['courseId'], 10);
        if ( (this.courseId > 0) && (this.courseId !== courseId) ) {
          // if the user navigate to another course todo page -> force reload
          this.forceReload();
        }
        this.courseId = courseId;
      });
  }

  @Input() set clearSolutionAndEstimation$(value: Observable<boolean>) {
    this._clearSolutionAndEstimation.observable = value;
  }

  ngOnInit(): void {
    this.estimation = this.extensions
      .find(ext => ext.type === AdminCoursesTypes.Extensions.Estimation) as SelfEstimationResponse;

    this.sampleSolution = this.extensions
      .find(ext => ext.type === AdminCoursesTypes.Extensions.SampleSolution) as AdminCoursesTypes.SampleSolutionExt ?? null;

    this._clearSolutionAndEstimation.value$
      .pipe(filter(clear => clear === true))
      .pipe(tap(() => this.clearValues()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  getSelfAssessmentComment(): string {
    return this.estimation
      ?.commitment?.commitment?.comment;
  }

  getSampleSolutionText(): Translation {
    return this.sampleSolution?.text;
  }

  getSampleSolutionTooltip(): string {
    return $localize`:@@todo_data_sample_solution_tooltip:The sample solution is not displayed to the learner.`;
  }

  getSliderValue(): number {
    return this.estimation
      ?.commitment?.commitment?.value;
  }

  getStyles(value: number): any {
    return {
      'font-weight': this.isBold(value) ? 'bold' : null,
      'font-size': this.isBold(value) ? '16px' : null,
      transform: this.isBold(value) ? 'translate(0,-3px)' : null,
      'text-decoration': this.isBold(value) ? 'underline' : null,
    };
  }

  hasContribution() {
    if ( !!this.courseTodo.contribution?.contribution || !!this.courseTodo.contribution?.files ) {
      return true;
    }
    return false;
  }

  hasSampleSolution() {
    return this.sampleSolution != null;
  }

  hasSelfAssessment() {
    if ( !!this.getSelfAssessmentComment() || !!this.getSliderValue() ) {
      return true;
    }
    return false;
  }

  courseWithSelfAssessment(): boolean {
    return this.estimation != null;
  }

  isBold(value: number): boolean {
    return value === this.estimation?.commitment?.commitment?.value;
  }

  private clearValues() {
    this.clearContribution();
    this.clearSelfEstimation();
  }

  private clearSelfEstimation() {

    const commitment = this.estimation?.commitment?.commitment;
    if (commitment == null) {
      return;
    }

    if ( commitment.comment != null ) {
      commitment.comment = '';
    }

    if ( commitment.value != null ) {
      commitment.value = null;
    }
  }

  private clearContribution() {

    const contribution = this.courseTodo?.contribution;
    if (contribution == null) {
      return;
    }

    if ( contribution.contribution != null ) {
      contribution.contribution = null;
    }

    if ( contribution.files?.length > 0 ) {
      contribution.files = [];
    }
  }

  private forceReload() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const onSameUrlNavigation = this.router.onSameUrlNavigation;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([ currentUrl ]).then(
      () => this.router.onSameUrlNavigation = onSameUrlNavigation,
      () => this.router.onSameUrlNavigation = onSameUrlNavigation,
    );
  }
}
