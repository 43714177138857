import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { clamp } from '@clampy-js/clampy';
import * as Hypher_DE from 'hyphenation.de';
import * as Hypher_EN from 'hyphenation.en-us';
import * as Hypher from 'hypher';
import * as DOMPurify from 'dompurify';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { State } from '../../app.state';
import { ApplicationStateService } from '../../core/application-state.service';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { InfoService } from '../../core/info/info.service';
import { GenericMessageDialogComponent } from '../generic-message-dialog/generic-message-dialog.component';
import { MessageKey, OkButton } from '../../core/info/info.types';
import { TextDecodeHelper } from '../../core/translation/text-decode.helper';
import { Translateable } from 'src/app/core/core.types';
import { LanguageHelper } from 'src/app/core/language.helper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from 'src/app/core/core.module';

const HYPHER_EN = new Hypher(Hypher_EN);
const HYPHER_DE = new Hypher(Hypher_DE);
const KB_TEXT = 1024;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ],
  selector: 'rag-clamp',
  templateUrl: './clamp.component.html',
  styleUrls: [ './clamp.component.scss' ],
})
export class ClampComponent
  implements OnDestroy, OnInit {

  content: string;
  contentHyphens: string;
  @Input()
  detailsButtonEnabled = false;
  @Input()
  detailsTitle: string;
  initialized = false;
  showDetailsButton = false;
  showTooltip = false;
  @Input()
  tooltipEnabled = true;
  private _clamp: number;
  private _contentElt: ElementRef<HTMLElement>;
  private _debounceClamp;
  private _maxHeightDebounce;
  private _sizingDetectionElt: ElementRef<HTMLElement>;
  private input_: string;

  constructor(
    private applicationStateService: ApplicationStateService,
    private infoService: InfoService,
  ) {
    subscribeUntilDestroyed(
      combineLatest([ this.applicationStateService.viewportState$, this.applicationStateService.sidenavVisible$ ])
        .pipe(tap(this.debounceClamp)), this);
  }

  get clamp(): string | number {
    return this._clamp;
  }

  @Input()
  set clamp(value: string | number) {
    this._clamp = parseInt(String(value), 10) || 3;
    this.setMaxHeight();
  }

  @ViewChild('contentElt', { static: true })
  set contentElt(value: ElementRef<HTMLElement>) {
    this._contentElt = value;
    this.setMaxHeight();
  }

  get input(): string {
    return this.content;
  }

  @Input()
  set input(value: string | Translateable) {
    if (typeof value === 'object') {
      value = LanguageHelper.objectToText(value);
    }
    this.input_ = value;
    this.updateHyphens(value);
    this.debounceClamp();
  }

  @ViewChild('sizingDetectionElt', { static: true })
  set sizingDetectionElt(value: ElementRef<HTMLElement>) {
    // @see https://stackoverflow.com/a/4515470
    this._sizingDetectionElt = value;
    this.setMaxHeight();
  }

  get tooltip() {
    if (this.showTooltip) {
      const content = TextDecodeHelper.decodeHtml(this.content, false);
      if (this.content.length > KB_TEXT) {
        return content.substring(0, KB_TEXT) + ' ...';
      }
      return content;
    }
    return null;
  }

  static getHyphens(language: string) {
    if ( language === 'de' ) {
      return HYPHER_DE;
    } else {
      return HYPHER_EN;
    }
  }

  static hyphenateValue(hyphens, value: string) {
    const parts = [];
    (hyphens.hyphenate(value) ?? []).forEach((part: string) => {
      if ( !part ) {
        return;
      }

      if ( part.length > 20 ) {
        (part.match(/.{1,20}/g) ?? []).forEach(s => {
          parts.push(s);
        });
      } else {
        parts.push(part);
      }
    });
    return parts.join('&shy;');
  }

  debounceClamp = (): void => {
    if ( this._debounceClamp ) {
      clearTimeout(this._debounceClamp);
    }
    this._debounceClamp = setTimeout(this.updateClamp, 50);
  };

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.setMaxHeight();
  }

  showDetailsDialog(): void {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      message: this.content,
      title: this.detailsTitle,
      titleKey: MessageKey.DIALOG.INFO.TITLE,
      buttons: OkButton,
    });
  }

  updateClamp = (): void => {
    const nativeElt: HTMLElement = this._contentElt.nativeElement;
    if ( !nativeElt?.style.lineHeight ) {
      // height not yet defined
      return this.setMaxHeightDebounce();
    }

    nativeElt.innerHTML = this.contentHyphens;
    clamp(nativeElt, {
      clamp: this._clamp,
    });
    const isClamped = /…$/.test(nativeElt.innerHTML);
    this.showDetailsButton = isClamped && this.detailsButtonEnabled;
    this.showTooltip = isClamped && this.tooltipEnabled;
  };

  updateHyphens(value: string): void {
    if ( (typeof (value) !== 'string') || (this.content === value) ) {
      return;
    }
    this.content = value;

    // convert to plain text
    value = DOMPurify.sanitize(value, { USE_PROFILES: {} });

    // 172 characters will fit into 1280px of content area
    const maxLength = this._clamp * 172;
    let clampLength: number = null;
    if ( value.length > maxLength ) {
      value = value.substr(0, maxLength);
      clampLength = value.length;
    }

    const hyphens = ClampComponent.getHyphens(State.language);
    const split = value.split(/[\s]+/gm);
    if ( split?.forEach ) {
      const parts = [];
      split.forEach(part => {
        if ( /^[\w]+$/.test(part) ) {
          part = ClampComponent.hyphenateValue(hyphens, part);
        }
        parts.push(part);
      });
      value = parts.join(' ');
    } else {
      value = ClampComponent.hyphenateValue(hyphens, value);
    }
    if ( value.length === clampLength ) {
      value = value + '…';
    }
    this.contentHyphens = value;
  }

  private setMaxHeight = (): void => {
    if ( this.initialized ) {
      return;
    }

    const nativeElt: HTMLElement = this._contentElt?.nativeElement;
    const sizingElt: HTMLElement = this._sizingDetectionElt?.nativeElement;
    if ( !(document.body.contains(nativeElt) && document.body.contains(sizingElt)) ) {
      return this.setMaxHeightDebounce();
    }

    let lineHeight: number;
    let fontSize: number;
    try {
      let computedHeight: any = window.getComputedStyle(nativeElt).getPropertyValue('line-height');
      if ( computedHeight === '' ) {
        // not rendered, yet
        return this.setMaxHeightDebounce();
      }
      this.initialized = true;

      fontSize = parseInt(
        parseFloat(window.getComputedStyle(nativeElt).getPropertyValue('font-size')).toFixed(0), 10);

      lineHeight = sizingElt.clientHeight;
      if ( lineHeight > 0 ) {
        computedHeight = lineHeight;
      } else if ( computedHeight === 'normal' ) {
        // use scaling values from clampy-js
        computedHeight = fontSize * 1.1;
      }
      lineHeight = parseInt(parseFloat(computedHeight).toFixed(0), 10) || 20;
    } catch ( e ) {
      lineHeight = 20;
      this.initialized = true;
    }

    // add 1 per clamp row to keep clampy happy :D
    nativeElt.style.fontSize = fontSize + 'px';
    nativeElt.style.lineHeight = lineHeight + 'px';
    // nativeElt.style.maxHeight = (this._clamp * (lineHeight + 1)) + 'px';
    this.updateClamp();
  };

  private setMaxHeightDebounce = (): void => {
    if ( this.initialized ) {
      return;
    }

    if ( this._maxHeightDebounce ) {
      clearTimeout(this._maxHeightDebounce);
    }

    this._maxHeightDebounce = setTimeout(this.setMaxHeight, 150);
  };

}
