<label>
  <input
    #eltCheckbox
    type="checkbox"
    [disabled]="valueState.disabled"
    [indeterminate]="valueState.indeterminate"
    [checked]="valueState.currentValue"
    (change)="onValueStateChange()"
  />
  <span class="checkbox-icon"></span>
  <span *ngIf="label" [ngClass]="{'label': valueState.disabled}">{{label}}</span>
</label>
