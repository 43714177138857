import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { InfoType, SnackBarData } from '../info.types';

@Component({
  selector: 'rag-error-snack-bar',
  templateUrl: './info-snack-bar.component.html',
  styleUrls: [ './info-snack-bar.component.scss' ],
})
export class InfoSnackBarComponent
  implements OnInit {
  message?: string;
  messageKey: string;
  showError = false;
  showInfo = false;
  showSuccess = false;
  showWarning = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {
    if ( data.infoType === InfoType.Information ) {
      this.showInfo = true;
    } else if ( data.infoType === InfoType.Warning ) {
      this.showWarning = true;
    } else if ( data.infoType === InfoType.Error ) {
      this.showError = true;
    } else if ( data.infoType === InfoType.Success ) {
      this.showSuccess = true;
    }

    this.message = data.message;
    this.messageKey = data.messageKey;
  }

  ngOnInit() {
  }

}
