import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rag-breadcrumbs-assignment-type',
  templateUrl: './breadcrumbs-assignment-type.component.html',
  styleUrls: [ './breadcrumbs-assignment-type.component.scss' ],
})
export class BreadcrumbsAssignmentTypeComponent
  implements OnInit {

  @Input()
  assignmentType: string;

  constructor(
    private elt: ElementRef,
  ) {
  }

  ngOnInit() {
    if ( this.elt && this.elt.nativeElement &&
      !([ null, 'both', 'mandatory', 'voluntary' ].indexOf(this.assignmentType) > 0) ) {
      this.elt.nativeElement.style.setProperty('display', 'none');
    } else {
      this.elt.nativeElement.style.setProperty('display', null);
    }
  }

}
