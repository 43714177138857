<ng-template #tplDataLoading>
  <div
    *ngIf="!embedded"
    class="no-data"
  >
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@no_content_available">Sorry, no content available!</span>
  </div>
</ng-template>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="ContentOverview@columnSettings"
></rag-table-column-menu>



<div class="switch-view-wrapper" *ngIf="isDataLoaded && !isDataEmpty">

  <button
    mat-button
    (click)="showFilter = !showFilter"
    type="button"
  >
    <mat-icon svgIcon="tune-variant"></mat-icon>
    <span *ngIf="!showFilter" i18n="@@global_show_filter">Show filter</span>
    <span *ngIf="showFilter" i18n="@@global_hide_filter">Hide filter</span>
  </button>

  <button
    mat-button
    type="button"
    class="icon-button"
    [disabled]="isDataEmpty"
    (click)="toggleViewMode()"
    [ngSwitch]="isCardView"
  >
    <ng-container *ngSwitchCase="true">
      <mat-icon color="primary" svgIcon="view-list"></mat-icon>
      <span i18n="@@overview_list_view">List view</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-icon color="primary" svgIcon="view-module"></mat-icon>
      <span i18n="@@overview_cards_view">Cards view</span>
    </ng-container>
  </button>
</div>

<mat-expansion-panel [expanded]="showFilter" class="expansion-filter" *ngIf="!embedded && isDataLoaded && !isDataEmpty">
  <!-- Filter -->
  <rag-content-filter
    class="filter"
    [context]="filterContext"
    [filters$]="filterOptions$"
    (filtersChanged)="onFilterChanged($event)"
  >
  </rag-content-filter>
</mat-expansion-panel>


<!-- Title -->
<div class="content-container">
  <rag-page-header *ngIf="!embedded">
  <div class="page-header-title">{{pageTitle$ | async}}</div>
  </rag-page-header>

  <ng-container
    *ngIf="isColumnContextLoaded && isDataLoaded; else tplDataLoading">

    <ng-container *ngIf="isCardView">

      <div
        *ngIf="!isDataEmpty; else tplDataEmpty"
        class="card-container" [ngClass]="{'card-container-notfullscreen': !fullScreenSizeEnabled, 'card-container-fullscreen': fullScreenSizeEnabled}"
        @cardAnimation
      >

        <ng-container *ngFor="let data of sortedData">
          <ng-container
            [ngTemplateOutlet]="permissionStates.showNewUserInterfaceCards ? newCards : standardCards"
            [ngTemplateOutletContext]="{data}"
          ></ng-container>
        </ng-container>

        <div class="no-data" *ngIf="isFilteredDataEmpty">
          <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
        </div>

      </div>

    </ng-container>


    <rag-sticky-scroll
      *ngIf="!isCardView"
      [recalculate]="recalculateSticky$"
    >
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        @listAnimation
        class="fadeInTable table-padding-space"
        mat-table
        matSort
        matSortDisableClear
      >

        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let item" class="actions-row" mat-cell>
            <div class="action-buttons">
              <rag-content-action
                [content]="item.$view.executableItem"
                [parent]="item"
              ></rag-content-action>
            </div>
          </td>
        </ng-container>

        <!-- Stats -->
        <ng-container matColumnDef="displaystatus">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span i18n="@@global_status">Status</span>
          </th>

          <td *matCellDef="let item" class="status-row" mat-cell>
            <!-- content type for learner account is always sent as DisplayStatus -->
            <rag-status-lights
              [displayStatus]="item.displaystatus"
              statusType="DisplayStatus"
              [targetType]="item.objType"
              [courseType]="item.courseType"
              [hasConfirmation]="item.hasConfirmation"
            ></rag-status-lights>
          </td>
        </ng-container>

        <!-- Title -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span i18n="@@global_title">Title</span>
          </th>

          <td *matCellDef="let item" mat-cell>

            <div class="title">
              <div>
                <a
                  *ngIf="getContentHref(item) as routerLink; else contentWithoutLink"
                  [routerLink]="routerLink"
                  class="title"
                  routerLinkActive="active">
                  <rag-clamp [clamp]="3" [input]="item.title"></rag-clamp>
                </a>
                <rag-sub-title
                  [objType]="item?.objType"
                  [objSubType]="item?.objSubType ?? item?.courseType"
                  [isMandatory]="item?.isMandatory"
                ></rag-sub-title>
              </div>
            </div>

            <ng-template #contentWithoutLink>
              <rag-clamp [clamp]="3" [input]="item.title"></rag-clamp>
            </ng-template>

          </td>
        </ng-container>

        <!-- Last valid since -->
        <ng-container matColumnDef="lastValidSince">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span i18n="@@content_overview_lastValidSince">Last valid since</span>
          </th>
          <td *matCellDef="let item" class="lastValidSince-row" mat-cell>
            <div *ngIf="item.lastValidSince" class="date">{{item.lastValidSince | formatDate}}</div>
            <div *ngIf="item.lastValidSince" class="time-span">{{dateFormatted(item.lastValidSince)}}</div>
          </td>
        </ng-container>

        <!-- Last valid until -->
        <ng-container matColumnDef="lastValidUntil">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span i18n="@@content_overview_lastValidUntil">Last valid until</span>
          </th>
          <td *matCellDef="let item" class="lastValidUntil-row" mat-cell>
            <ng-container *ngIf="item.lastValidUntil">
              <div [ngStyle]="{'color':item.fontColor}" class="date">{{item.lastValidUntil | formatDate}}</div>
              <div class="time-span">{{dateFormatted(item.lastValidUntil)}}</div>
            </ng-container>
          </td>
        </ng-container>

        <!-- Start date -->
        <ng-container matColumnDef="startdate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span i18n="@@content_overview_startdate">Start date</span>
          </th>
          <td *matCellDef="let item" class="startdate-row" mat-cell>
            <ng-container *ngIf="item.lastValidSince; then tplLastValidSince else tplStartDate"></ng-container>

            <ng-template #tplLastValidSince>
              <div class="date">{{item.lastValidSince | formatDate}}</div>
              <div class="time-span">{{dateFormatted(item.lastValidSince)}}</div>
            </ng-template>

            <ng-template #tplStartDate>
              <ng-container *ngIf="item.startdate">
                <div class="date">{{item.startdate | formatDate}}</div>
                <div class="time-span">{{dateFormatted(item.startdate)}}</div>
              </ng-container>
            </ng-template>
          </td>
        </ng-container>

        <!-- Progress -->
        <ng-container matColumnDef="progress">
          <th *matHeaderCellDef class="progress-bar" mat-header-cell mat-sort-header>
            <span i18n="@@progress">Progress</span>
          </th>
          <td *matCellDef="let item" class="progress-bar progress-bar-row" mat-cell>
            <span class="progress-bar-percentage">{{item.progress | number:'1.0-0'}}%</span>
            <mat-progress-bar color="primary" value="{{item.progress}}"></mat-progress-bar>
          </td>
        </ng-container>

        <!-- Settings -->
        <ng-container matColumnDef="settings">
          <th *matHeaderCellDef class="settings" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let item" mat-cell></td>
        </ng-container>

        <!-- Information -->
        <ng-container matColumnDef="information">
          <th *matHeaderCellDef class="col-important-info" mat-header-cell>
            <span i18n="@@global_information">Information</span>
          </th>
          <td *matCellDef="let item" class="col-important-info" mat-cell>
            <div>
              <rag-learner-ofl-events-state
                [content]="item"
              >
              </rag-learner-ofl-events-state>
              <rag-important-content-info
                [content]="item"
                (readDocumentAcknowledge)="onReadDocumentAcknowledge(item)"
              ></rag-important-content-info>
            </div>
          </td>
        </ng-container>


        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns" class="row fadeIn"
            mat-row></tr>
      </table>

      <div class="no-data" *ngIf="isFilteredDataEmpty">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>

    </rag-sticky-scroll>

  </ng-container>

  <ng-template #newCards let-data="data">
    <ng-container [ngSwitch]="data.objType">

      <rag-course-card
        *ngSwitchCase="'lms_course'"
        [content]="data"
      ></rag-course-card>

      <rag-event-card
        *ngSwitchCase="'lms_offlineCnt'"
        [content]="data"
      ></rag-event-card>

      <rag-curriculum-card
        *ngSwitchCase="'lms_curriculum'"
        [content]="data"
      ></rag-curriculum-card>
    </ng-container>
  </ng-template>

  <ng-template #standardCards let-data="data">
    <rag-my-content-card
      [embedded]="false"
      [content]="data">
    </rag-my-content-card>
  </ng-template>
</div>
