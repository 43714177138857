import { Component, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CommonModule } from '@angular/common';


@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-post-to-blank',
  template: '',
  styleUrls: [ './post-to-blank.component.scss' ],
})
export class PostToBlankComponent {

  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) {
  }

  private static addParamsToForm(form: HTMLElement, params?: HttpParams): void {
    if ( !params ) {
      return;
    }

    params.keys()
      .reduce((pV, key) => {
        pV.push(
          ...params.getAll(key)
            .map(value => ({ key, value })));
        return pV;
      }, [])
      .map(param => {
        const input = document.createElement('input');
        input.name = param.key;
        input.value = param.value;
        return input;
      })
      .forEach(input => form.appendChild(input));
  }

  executePost(url: string, params?: HttpParams): void {
    const elt = this.elementRef?.nativeElement;
    if ( !elt ) {
      return;
    }

    const form = document.createElement('form');
    form.action = url;
    form.target = '_blank';
    form.method = 'post';
    PostToBlankComponent.addParamsToForm(form, params);
    elt.appendChild(form);
    form.submit();
    setTimeout(() => elt.innerHTML = '');
  }

}
