<ng-container *ngIf="messages === undefined; else showTextMessage">
  <ng-container [ngSwitch]="key">
    <!-- switchCases are in info.types.ts defined! -->
    <div *ngSwitchCase="'general_error'" >
     <span i18n="@@general_error">The last operation failed. Please try again later.</span>
    </div>
    <!-- general -->
    <div *ngSwitchCase="'@@global_save'" >
     <span i18n="@@global_save">Save</span>
    </div>
    <div *ngSwitchCase="'@@global_save_as'">
      <span i18n="@@global_save_as">Save As...</span>
    </div>
    <div *ngSwitchCase="'general_save_success'">
      <span i18n="@@general_save_success">The data has been saved successfully</span>
    </div>
    <div *ngSwitchCase="'general_delete_success'">
      <span  i18n="@@general_delete_success">The target has been deleted.</span>
    </div>
    <div *ngSwitchCase="'general_delete_conflict'" >
      <span i18n="@@general_delete_conflict">This object cannot be deleted.</span>
    </div>
    <!-- !!! When this is diplayed you have to make sure the optional parameter message is set !!! -->
    <div *ngSwitchCase="'general_api_response'" >
      <span i18n="@@general_api_response">The last operation failed. Please try again later.</span>
    </div>
    <div *ngSwitchCase="'general_unsaved_changes'">
      <span i18n="@@general_unsaved_changes">You have unsaved changes. Are you sure you want to leave?</span>
    </div>
    <div *ngSwitchCase="'general_file_not_readable'">
      <span i18n="@@general_file_not_readable">File is not readable!</span>
    </div>
    <div *ngSwitchCase="'general_file_error'">
      <span i18n="@@general_file_error">An error occurred while reading this file!</span>
    </div>
    <div *ngSwitchCase="'login_form_login_failed_error'">
     <span i18n="@@login_form_login_failed_error">Login failed!</span>
    </div>

    <!-- account design -->
    <div *ngSwitchCase="'acc_picture_deleted'">
      <span i18n="@@account_design_picture_deleted">Picture deleted!</span>
    </div>
    <div *ngSwitchCase="'acc_picture_not_deleted'">
      <span i18n="@@account_design_picture_not_deleted">Picture not deleted! Please try again.</span>
    </div>
    <div *ngSwitchCase="'acc_login_page_data_safed'">
      <span i18n="@@account_design_login_page_data_safed">Settings saved for the login page!</span>
    </div>
    <div *ngSwitchCase="'acc_login_page_data_not_safed'">
      <span i18n="@@account_design_login_page_data_not_safed">Settings for the login page not saved!</span>
    </div>
    <div *ngSwitchCase="'acc_style_settings_safed'">
      <span i18n="@@account_design_style_settings_safed">Style settings saved!</span>
    </div>
    <div *ngSwitchCase="'acc_style_settings_not_safed'">
     <span i18n="@@account_design_style_settings_not_safed">Style settings not saved!</span>
    </div>
    <div *ngSwitchCase="'acc_style_get_images_failed'">
      <span i18n="@@acc_style_get_images_failed">Pictures could not be loaded</span>
    </div>
    <div *ngSwitchCase="'general_dialog_confirm_title'">
      <span i18n="@@general_dialog_confirm_title">Confirm</span>
    </div>
    <span *ngSwitchCase="'general_dialog_cancel_text'" i18n="@@general_dialog_cancel_text">You have made some changes. Would you like to ignore them and close the dialog?</span>
    <div *ngSwitchCase="'general_dialog_info_title'" >
      <span i18n="@@general_dialog_info_title">Information</span>
    </div>
    <div *ngSwitchCase="'general_dialog_pleaseconfirm_title'">
      <span i18n="@@general_dialog_pleaseconfirm_title">Please Confirm</span>
    </div>
    <span *ngSwitchCase="'dialog_unsaved_close'" i18n="@@dialog_unsaved_close">You have unsaved changes. Would you like to save it now?</span>
    <!-- account design --- widgets -->
    <div *ngSwitchCase="'widget_conf_description_initial'" i18n="@@widget_conf_desription_initial">
      <p>Applied when no widgets are available or the user logs in for the first time.</p>
      <p>Automatically enabled if the widget is static.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_static'" i18n="@@widget_conf_desription_static">
      <p>Widget is forced and cannot be deleted or added again.</p>
      <p>Automatically marked as initial.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_resizable'" i18n="@@widget_conf_desription_resizable">
      <p>The size of the widget can be changed to the size specified below.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_dragable'" i18n="@@widget_conf_desription_dragable">
      <p>Allows the learner to move the widget.</p>
      <p>Automatically disabled if 'show at top' is enabled.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_selectable'" i18n="@@widget_conf_desription_selectable">
      <p>Allows the learner to add instances of the widget.</p>
      <p>Automatically disabled if the widget is static.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_deletable'" i18n="@@widget_conf_desription_deletable">
      <p>Allows the learner to delete the widget from their view.</p>
      <p>Automatically disabled if the widget is static.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_only_once'" i18n="@@widget_conf_desription_only_once">
      <p>Widget may only be added once.</p>
      <p>The learner's menu for adding widgets is automatically adjusted if the corresponding widget already exists.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_show_at_top'" i18n="@@widget_conf_desription_show_at_top">
      <p>The widget is always displayed at the top and the learner can no longer drag it.</p>
      <p>If there are multiple widgets with this flag, they will be displayed first in random order and then all
        others.</p>
    </div>
    <div *ngSwitchCase="'widget_conf_description_fail'" i18n="@@widget_conf_desription_fail">
      <p>Oops, something's gone wrong here!</p>
      <p>Please contact an administrator with the error message: #WidgetConfInfoFail</p>
    </div>

    <!-- administration -- report generator -->
    <div *ngSwitchCase="'column_conf_description_static'">
      <p i18n="@@column_conf_description_static">If checked column is always visible and selected.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_identifier'">
      <p i18n="@@column_conf_description_identifier">This field indicates which field is referenced in the database.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_title'">
      <p i18n="@@column_conf_description_title">At this point you can enter the name for the field that the user will
        see.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_selectable'">
      <p i18n="@@column_conf_description_selectable">This attribute specifies whether the user can make the field
        showable or hideable.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_default_columns'">
      <p i18n="@@column_conf_description_default_columns">These columns will be enabled when you create a new
        report.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_pool_columns'">
      <p i18n="@@column_conf_description_pool_columns">These columns will be placed in the dropdown menu of available
        columns.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_disabled'">
      <p i18n="@@column_conf_description_disabled">These columns will not be visible to regular controllers. As you
        are allowed to configure columns, you will see them anyway when you use the report generator.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_dropdown'">
      <p i18n="@@column_conf_description_dropdown">Here you can adjust the values displayed for the dropdown menu.</p>
    </div>
    <div *ngSwitchCase="'column_conf_description_fail'">
      <p i18n="@@column_conf_description_fail">Please contact an administrator with the error message:
        #ColumnConfInfoFail</p>
    </div>

    <!-- News -->
    <div *ngSwitchCase="'news_delete_confirm'">
      <span i18n="@@news_delete_confirm">Would you like to delete this article?</span>
    </div>

    <!-- file uplaod -->
    <div *ngSwitchCase="'file_upload_wrong_apiurlkey'">
      <span i18n="@@file_upload_wrong_apiurlkey">File Upload Component does not work correctly!</span>
    </div>
    <div *ngSwitchCase="'file_upload_success'">
      <span i18n="@@file_upload_success">File successfully uploaded</span>
    </div>
    <div *ngSwitchCase="'file_upload_error'">
      <span i18n="@@file_upload_error">Upload failed</span>
    </div>
    <div *ngSwitchCase="'file_download_error'">
      <span i18n="@@file_download_error">Download failed</span>
    </div>
    <div *ngSwitchCase="'file_download_success'">
      <span i18n="@@file_download_success">Download successful</span>
    </div>

    <!-- mails -->
    <div *ngSwitchCase="'mails_saved_as_draft'">
      <span i18n="@@mails_saved_as_draft">The message has been saved as draft</span>
    </div>
    <div *ngSwitchCase="'mails_saved'">
      <span i18n="@@mails_saved">The message was saved successfully</span>
    </div>
    <div *ngSwitchCase="'delete_mail'">
      <span i18n="@@mails_delete_mail">Delete mail</span>
    </div>
    <div *ngSwitchCase="'mails_error_when_saving'">
      <span i18n="@@mails_error_when_saving">An error occurred while saving the message</span>
    </div>
    <div *ngSwitchCase="'mails_successfully_sent'">
      <span i18n="@@mails_successfully_sent">The message has been sent successfully</span>
    </div>
    <div *ngSwitchCase="'mails_error_when_sending'">
      <span i18n="@@mails_error_when_sending">An error occurred while sending the message</span>
    </div>
    <div *ngSwitchCase="'mails_error_planned_in_past'">
      <span i18n="@@mails_error_planned_in_past">The scheduled time must be in the future</span>
    </div>
    <div *ngSwitchCase="'attachment_report_generation_as_receiver_failed'">
      <span i18n="@@attachment_report_generation_as_receiver_failed">The receiver is not authorized to view the selected report from his view</span>
    </div>
    <div *ngSwitchCase="'mailbox_email_single_deleted'">
      <span i18n="@@mailbox_email_single_deleted">The email has been deleted</span>
    </div>
    <div *ngSwitchCase="'mailbox_email_multiple_deleted'">
      <span i18n="@@mailbox_email_multiple_deleted">Emails have been deleted</span>
    </div>
    <div *ngSwitchCase="'mails_mark_as_read_unread_error'">
      <span i18n="@@mails_warning_mark_as_read_unread">Email was not marked as desired! Please try again.</span>
    </div>
    <div *ngSwitchCase="'mails_mark_as_read_unread_success'">
      <span i18n="@@mails_mark_as_read_unread_success">Email status has changed successfully</span>
    </div>
    <div *ngSwitchCase="'mailbox_attachment_not_found'">
      <span i18n="@@mails_attachment_not_found">File not found!</span>
    </div>
    <div *ngSwitchCase="'attachment_is_successful'">
      <span i18n="@@attachment_is_success">The attachment has been added successfully!</span>
    </div>
    <div *ngSwitchCase="'attachment_is_not_successful'">
      <span i18n="@@attachment_is_not_success">An error occurred while trying to remove the attachment!</span>
    </div>

    <!-- Widgets -->
    <div *ngSwitchCase="'remove_report_link_confirmation_title'">
     <span i18n="@@widget_report_link_remove_title"> Delete Report Link?</span>
    </div>
    <div *ngSwitchCase="'widget_report_link_remove_link'">
      <span i18n="@@widget_report_link_remove_link">Would you like to remove this report link?</span>
    </div>
    <div *ngSwitchCase="'widget_report_link_deleted'">
      <span i18n="@@widget_report_link_deleted">This report is no longer available. Would you like to remove it from widget?</span>
    </div>

    <!-- Rag-Page -->
    <div *ngSwitchCase="'ragpage_error_cannot_add_widget'">
      <span i18n="@@ragpage_error_cannot_add_widget">Cannot add this widget to the page</span>
    </div>
    <div *ngSwitchCase="'ragpage_add_widget_success'">
      <span i18n="@@ragpage_add_widget_success">Widget successfully added</span>
    </div>
    <div *ngSwitchCase="'ragpage_add_failed_exists_already'">
      <span i18n="@@ragpage_add_failed_exists_already">A widget for this report is already present.</span>
    </div>
    <div *ngSwitchCase="'ragpage_error_cannot_remove_widget'">
      <span i18n="@@ragpage_error_cannot_remove_widget">This widget cannot be removed!</span>
    </div>
    <div *ngSwitchCase="'ragpage_remove_widget_success'">
      <span i18n="@@ragpage_remove_widget_success">Widget removed</span>
    </div>
    <div *ngSwitchCase="'ragpage_save_layout_success'">
      <span i18n="@@ragpage_save_layout_success">Layout saved successfully</span>
    </div>
    <div *ngSwitchCase="'remove_widget_confirmation_text'">
      <span i18n="@@remove_widget_confirmation_text">Would you like to remove this widget?</span>
    </div>
    <div *ngSwitchCase="'remove_widget_confirmation_title'">
      <span i18n="@@remove_widget_confirmation_title">Delete widget</span>
    </div>
    <div *ngSwitchCase="'ragpage_save_layout_error'">
      <span i18n="@@ragpage_save_layout_error">Layout could not be saved! Please try again.</span>
    </div>
    <div *ngSwitchCase="'ragpage_add_widget_unknown_uuid_error'">
      <span i18n="@@ragpage_add_widget_unknown_uuid_error">Unknown widget UUID</span>
    </div>

    <div *ngSwitchCase="'ragpage_really_want_to_reset_widgets_title'">
      <span i18n="@@ragpage_really_want_to_reset_widgets_title">Reset Widgets</span>
    </div>
    <div *ngSwitchCase="'ragpage_really_want_to_reset_widgets'">
      <p i18n="@@ragpage_really_want_to_reset_widgets_part_1">Are you sure you want to reset the widgets displayed on
        the homepage? </p>
      <p i18n="@@ragpage_really_want_to_reset_widgets_part_2">This will
        replace your custom start page configuration
        with the default setting.</p>
    </div>

    <div *ngSwitchCase="'code_redeem_success'">
      <span i18n="@@code_redeem_success">The license code has been succcessfully applied.</span>
    </div>

    <!-- Sys-Check 8 Tips -->
    <div *ngSwitchCase="'sys_check_8_tips'">
      <h3 i18n="@@sys_check_8_tips_header">Tips on handling courses</h3>

      <p i18n="@@sys_check_8_tips_disclaimer">
        The following tips will assist you in making the necessary settings for successfully taking your courses.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_1">1. Try a different browser</h3>

      <p i18n="@@sys_check_8_tips_description_tip_1">
        If editing a course with a particular Internet browser is not successful, using a different browser provides a quick remedy
        in many cases. If using a different browser does not produce the desired result, please see the other tips below.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_2">2. Stable internet connection</h3>

      <p i18n="@@sys_check_8_tips_description_tip_2">
        Make sure that you have a stable internet connection, especially if you are doing a course that is required
        for certification. If the connection between your device and the server breaks down while the course is in
        progress, your training status cannot be correctly recorded.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_3">3. Wait for the necessary loading time</h3>
      <div>
        <p i18n="@@sys_check_8_tips_description_tip_3_1">
          Even when the internet connection is good, the process of loading a course may take some time, depending
          on its size. For videos and animations, a further loading process may take place during a course.
        </p>
        <p i18n="@@sys_check_8_tips_description_tip_3_2">
          <!-- used in german -->
          &nbsp;
        </p>
      </div>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_4">4. Close other programs</h3>

      <p i18n="@@sys_check_8_tips_description_tip_4">
        Before starting work on a course, close all other programs. That will help prevent the working memory of
        your computer from becoming overloaded.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_5">5. Check the screen resolution</h3>

      <p i18n="@@sys_check_8_tips_description_tip_5">
        Make sure that the display device you are using has a resolution of at least 1024 × 768 pixels.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_6">6. Deactivate pop-up and ad blockers</h3>

      <p i18n="@@sys_check_8_tips_description_tip_6">
        Before starting a course, deactivate all pop-up blockers and any other toolbars that may be installed.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_7">7. Activate JavaScript</h3>

      <p i18n="@@sys_check_8_tips_description_tip_7">
        To enable you to access the site, JavaScript must be allowed by your browser.
      </p>

      <h3 i18n="@@sys_check_8_tips_header_header_tip_8">8. Close course correctly</h3>

      <p i18n="@@sys_check_8_tips_description_tip_8">
        When closing courses, always follow the instructions given in the course itself.
        Then wait until the learning traffic light has updated. This ensures the correct saving of your learning status.
      </p>

      <p i18n="@@sys_check_free_contact">
        If these tips do not fully answer your questions, please feel free to contact our support team at any time.
      </p>
    </div>

    <!-- Error message on click of box -->
    <div *ngSwitchCase="'syscheck_error_flash_disabled'">
      <p i18n="@@syscheck_error_flash_disabled_1">Some of the courses require Adobe Flash.</p>
      <p i18n="@@syscheck_error_flash_disabled_2">Please ensure that the Flash plugin is available in your browser and
        enabled for this site.
      </p>
    </div>
    <!--No PDF support detected. This can lead to content problems.-->
    <div *ngSwitchCase="'syscheck_error_pdf_not_supported'">
      <p i18n="@@syscheck_error_pdf_not_supported_1">For learning content that consists of PDF documents, a PDF reader
        is required.</p>
      <p i18n="@@syscheck_error_pdf_not_supported_2">We recommend downloading the latest Adobe Reader or using a browser
        with built-in PDF reader (eg Mozilla Firefox or Google Chrome).</p>

    </div>

    <div *ngSwitchCase="'syscheck_error_pdf_not_ie_supported'">
      <p i18n="@@syscheck_error_pdf_ie_not_supported1">Please note:The PDF reader plugin in Microsoft Internet Explorer
        cannot
        always be recognised correctly.</p>
      <p i18n="@@syscheck_error_pdf_ie_not_supported2">Please try to open the documents and,
        whenever this is unsuccessful, use a different browser.</p>
    </div>

    <!-- Error Local Storage-->
    <p *ngSwitchCase="'syscheck_error_localstorage_disabled'" i18n="@@syscheck_error_localstorage_disabled">
      In order to access the page, LocalStorage must be enabled in your browser.</p>

    <!-- os support error -->
    <p *ngSwitchCase="'syschecksupport_unsupported'" i18n="@@syschecksupport_unsupported">
      We recommend using a recent version of Windows, Mac OS, Android or iOS.
    </p>
    <p *ngSwitchCase="'syschecksupport_nottested'"
       i18n="@@syschecksupport_nottested">
      As you are using an operating system that is not entirely tested with, correct views and communication can
      not be guaranteed.
    </p>
    <p *ngSwitchCase="'sysCheckSupport_UnsupportedVersion'" i18n="@@sysCheckSupport_UnsupportedVersion">
      Since you are using an old operating system that is not fully tested, correct views and communication cannot
      be guaranteed.
    </p>

    <!-- cookies support error -->
    <p *ngSwitchCase="'syscheck_error_cookies_disabled'" i18n="@@syscheck_error_cookies_disabled">
      Cookies are small files that are stored by a website on your device. Only if you allow this, the use of this
      website is possible.
    </p>
    <!-- Error Resolution  Mobile error -->
    <div *ngSwitchCase="'syscheck_error_resolution_mobile'">
      <p i18n="@@syscheck_error_resolution_mobile_1">Content may not be displayed properly, especially on mobile devices
        with low resolution.</p>
      <p i18n="@@syscheck_error_resolution_mobile_2">We recommend a resolution of at least 1024 × 768 pixels.</p>
    </div>
    <!-- Error Color Depth 16 -->
    <p *ngSwitchCase="'syscheck_error_colordepth_16'" i18n="@@syscheck_error_colordepth_16">
      For a perfect representation of graphics and photos, a color depth of at least 16 bits (65,535 colors) is
      required.
    </p>

    <!-- Error In Device -->
    <p *ngSwitchCase="'syscheck_error_device_smartphone'" i18n="@@syscheck_error_device_smartphone">
      Please note that some content is not available on your phone.
    </p>

    <!-- browser error -->

    <p *ngSwitchCase="'syschecksupport_browser_unsupported'" i18n="@@syscheck_error_browser_unsupported">
      We recommend using a recent version of Mozilla Firefox, Google Chrome or Internet Explorer.
    </p>
    <div *ngSwitchCase="'syschecksupport_browser_unsupportedversion'">
      <p i18n="@@syscheck_error_browser_unsupported_version_1">You are using an older browser. We can not guarantee that
        learning content will be executed correctly.</p>
      <p i18n="@@syscheck_error_browser_unsupported_version_2">Please use a newer browser if possible!</p>
    </div>

    <!-- reports -->
    <div *ngSwitchCase="'report_would_you_like_to_reset'">
      <span i18n="@@report_would_you_like_to_reset">Would you like to reset this report? All changes will be lost.</span>
    </div>
    <div *ngSwitchCase="'report_has_been_saved'">
      <span i18n="@@report_has_been_saved" >The report has been saved successfully</span>
    </div>
    <div *ngSwitchCase="'report_confirm_delete'">
      <span i18n="@@report_confirm_delete">Do you want to delete this report?</span>
    </div>
    <div *ngSwitchCase="'remove_report_confirmation_title'">
      <span i18n="@@remove_report_confirmation_title">Delete Report</span>
    </div>
    <div *ngSwitchCase="'report_target_type_not_found'">
      <span i18n="@@report_target_type_not_found">Failed to detect report type!</span>
    </div>
    <div *ngSwitchCase="'report_save_failed'">
      <span i18n="@@report_save_failed">The report save failed! Please try again later.</span>
    </div>
    <div *ngSwitchCase="'report_keep_grouping_filter'">
      <span  i18n="@@report_keep_grouping_filter">The column you want to group has an active filter. Do you want to keep it?</span>
    </div>
    <div *ngSwitchCase="'report_keep_disabled_column_filter'">
      <span i18n="@@report_keep_disabled_column_filter">The column you want to disable has an active filter. Do you want to keep it?</span>
    </div>
    <div *ngSwitchCase="'report_title_rename'">
      <span i18n="@@report_title_rename">Rename report</span>
    </div>

    <!-- linked users -->
    <p *ngSwitchCase="'linked_users_get_auth_token_fail'">
      <span i18n="@@linked_users_get_auth_token_fail">Authentication token could not be retrieved</span>
    </p>
    <p *ngSwitchCase="'linked_users_login_with_token_success'">
      <span i18n="@@linked_users_login_with_token_success">You are successfully logged in with the new user</span>
    </p>
    <p *ngSwitchCase="'linked_users_login_with_token_fail'">
      <span i18n="@@linked_users_login_with_token_fail">Login with token failed</span>
    </p>
    <p *ngSwitchCase="'linked_users_logout_fail'">
      <span i18n="@@linked_users_logout_fail">Logout failed</span>
    </p>
    <p *ngSwitchCase="'linked_users_show_dialog_for_switching_account_title'">
      <span i18n="@@linked_users_show_dialog_for_switching_account_title">Switching account</span>
    </p>
    <p *ngSwitchCase="'linked_users_show_dialog_for_switching_account'">
      <span  i18n="@@linked_users_show_dialog_for_switching_account">Are you sure you want to change to another user?</span>
    </p>
    <p *ngSwitchCase="'linked_users_get_own_uuid_fail'">
      <span i18n="@@linked_users_get_own_uuid_fail">Failed to retrieve own UUID</span>
    </p>
    <p *ngSwitchCase="'linked_users_delete_link_to_user_success'">
      <span i18n="@@linked_users_delete_link_to_user_success">Link successfully removed</span>
    </p>
    <p *ngSwitchCase="'linked_users_delete_link_to_user_fail'">
      <span i18n="@@linked_users_delete_link_to_user_fail">Link not successfully removed</span>
    </p>
    <p *ngSwitchCase="'linked_users_add_link_to_user_success'">
      <span i18n="@@linked_users_add_link_to_user_success">Link successfully created</span>
    </p>
    <p *ngSwitchCase="'linked_users_add_link_to_user_fail'">
      <span i18n="@@linked_users_add_link_to_user_fail">Link not successfully created. Make sure that the entered data is correct.</span>
    </p>
    <p *ngSwitchCase="'linked_users_already_exist'">
     <span  i18n="@@linked_users_already_exist">This user is already exist in the list.</span>
    </p>
    <p *ngSwitchCase="'linked_users_current_user'">
      <span i18n="@@linked_users_current_user">This is a link to the current user.</span>
    </p>

    <!-- ScoResolverService -->
    <div *ngSwitchCase="'sco_confirm_document_read'">
     <span i18n="@@sco_confirm_document_read">You hereby confirm that you have read the document and understood its contents. You can interrupt the
      confirmation process at this point and work through the document again </span>
    </div>
    <div *ngSwitchCase="'selected_sco_cannot_be_executed'">
      <span i18n="@@selected_sco_cannot_be_executed">The selected content cannot be executed.</span>
    </div>
    <div *ngSwitchCase="'selected_sco_cannot_be_found'">
      <span i18n="@@selected_sco_cannot_be_found">The selected content cannot be found.</span>
    </div>
    <div *ngSwitchCase="'sco_principal_cannot_be_found'">
      <span i18n="@@sco_principal_cannot_be_found">You must be logged in to start the content.</span>
    </div>
    <div *ngSwitchCase="'sco_session_already_running'" i18n="@@sco_session_already_running">
      <p>It seems that you are still running a course.<br/>If you are not, an error in the previous session
        could lead to this error.</p>
      <p>Click yes to close the current session and start a new session afterwards,<br>click no to leave the current
        session open.</p>
    </div>

    <!-- Contact -->
    <div *ngSwitchCase="'contact_validation_leave_without_save_title'">
      <span i18n="@@contact_validation_leave_without_save_title">You are about to leave the site</span>
    </div>
    <div *ngSwitchCase="'contact_validation_leave_without_save'">
      <span i18n="@@contact_validation_leave_without_save">Would you like to leave the page without submitting a contact request?</span>
    </div>
    <div *ngSwitchCase="'contact_no_contact_set_error'">
      <span i18n="@@contact_no_contact_set_error">(Error: #SCM10) There is no recipient email address for this request. Please contact your administrator if you get this message!</span>
    </div>

    <!-- Assign content -->
    <div *ngSwitchCase="'assign_content_confirm_save'" i18n="@@assign_content_confirm_save">
      <p>The changes that you have made may have an effect on the assignment of courses. Please make sure to check your settings before applying them.</p>
      <p>Would you like to apply your changes?</p>
    </div>
    <div *ngSwitchCase="'assign_content_save_success'">
      <span i18n="@@assign_content_save_success">The changes have been saved successfully</span>
    </div>
    <div *ngSwitchCase="'assign_content_confirm_reset'">
     <span i18n="@@assign_content_confirm_reset">Would you like to discard all changes that you have made?</span>
    </div>
    <div *ngSwitchCase="'@@header_nav_development_goals_content_distribution'" >
      <span i18n="@@header_nav_development_goals_content_distribution">Content distribution</span>
    </div>

    <div *ngSwitchCase="'assign_content_invisible_changes_confirm'">
      <span i18n="@@assign_content_invisible_changes_confirm">A filter is set which makes some changes invisible. Would you like to proceed?</span>
    </div>

    <!-- Admin Offline Content  -->
    <div *ngSwitchCase="'offline_cnt_learner_content_not_executable'">
      <span i18n="@@offline_cnt_learner_content_not_executable">Unfortunately this Event is not available at this time.</span>
    </div>
    <div *ngSwitchCase="'content_offline_event_failed_to_book'">
      <span i18n="@@content_offline_event_failed_to_book">An error ocurred while booking the event!</span>
    </div>
    <div *ngSwitchCase="'content_offline_event_booked'">
    <span i18n="@@content_offline_event_booked">The event has been booked for you.</span>
    </div>
    <div *ngSwitchCase="'content_offline_event_booked_requested'">
      <span i18n="@@content_offline_event_booked_requested">A request has been sent successfully. </span>
    </div>
    <div *ngSwitchCase="'content_offline_event_booking_cancel'">
      <span i18n="@@content_offline_event_booking_cancel">Would you like to cancel this event reservation?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_delete_confirm'">
      <span i18n="@@offline_cnt_event_schedule_delete_confirm">The event schedule has been deleted successfully.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_delete_confirm_body'">
      <span i18n="@@offline_cnt_event_schedule_delete_confirm_body">Would you like to delete this schedule?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_delete_failed'">
      <span i18n="@@offline_cnt_event_schedule_delete_failed">The event schedule cannot be deleted.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_save_failed'" >
      <span i18n="@@offline_cnt_event_schedule_save_failed">Saving the event schedule failed!</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_copy_success'">
        <span i18n="@@offline_cnt_event_schedule_copy_success">The event schedule has been saved successfuly.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_status_success'">
      <span i18n="@@offline_cnt_event_schedule_status_success">The event schedule was set as done.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_status_aborted'">
      <span i18n="@@offline_cnt_event_schedule_status_aborted">The event schedule has been set as aborted.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_status_canceled'">
      <span i18n="@@offline_cnt_event_schedule_status_canceled">The event schedule has been set as canceled.</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_copy_confirm'">
      <span i18n="@@offline_cnt_event_copy_confirm">Would you like to copy this event schedule?</span>
    </div>
    <div *ngSwitchCase="'admin_offline_content_header'">
      <span i18n="@@admin_offline_content_header">Events</span>
    </div>
    <div *ngSwitchCase="'admin_offline_content_location_header'">
      <span i18n="@@admin_offline_content_location_header">Locations</span>
    </div>
    <div *ngSwitchCase="'global_trainers'">
      <span i18n="@@global_trainers">Trainers</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_new'">
    <span i18n="@@offline_cnt_event_new">New event</span>
    </div>
    <div *ngSwitchCase="'admin_offline_participants_header'">
      <span i18n="@@admin_offline_participants_header">Event participants</span>
    </div>
    <div *ngSwitchCase="'admin_offline_assignment_header'">
      <span i18n="@@admin_offline_assignment_header">Participant assignment</span>
    </div>
    <div *ngSwitchCase="'admin_offline_curriculum_header'">
      <span i18n="@@admin_offline_curriculum_header">Curriculum assignment</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_trainer_delete'">
      <span i18n="@@offline_cnt_trainer_delete">Would you like to delete this trainer?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_location_remove'">
      <span i18n="@@offline_cnt_event_location_remove">Would you like to unset location for this event?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_location_remove'">
      <span i18n="@@offline_cnt_location_remove">Would you like to delete this location?</span>
    </div>
    <div *ngSwitchCase="'user_data'">
      <span i18n="@@user_data">User data</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_provider_remove'">
      <span i18n="@@offline_cnt_event_provider_remove">Would you like to unset provider for this event?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_delete_confirm'">
      <span i18n="@@offline_cnt_event_delete_confirm">Would you like to delete this event?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_delete_success'">
      <span i18n="@@offline_cnt_event_delete_success">The event has been deleted successfully</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_provider_remove'">
      <span i18n="@@offline_cnt_provider_remove">Would you like to delete this provider?</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_timeblock_remove'">
      <span i18n="@@offline_cnt_timeblock_remove">Would you like to delete this agenda item?</span>
    </div>
    <div *ngSwitchCase="'admin_offline_content_provider_header'">
      <span i18n="@@global_provider">Provider</span>
    </div>
    <div *ngSwitchCase="'offline_cnt_event_schedule_learner_changed'">
      <span i18n="@@offline_cnt_event_schedule_learner_changed">Your changes have been saved successfully</span>
    </div>

    <!-- Learning content -->
    <div *ngSwitchCase="'lern_cnt_todo_submit'">
      <span i18n="@@lern_cnt_todo_submit">Once your work is submitted you cannot edit it anymore. Would you like to submit?</span>
    </div>

    <!-- Files -->
    <div *ngSwitchCase="'files_remove_confirm'" >
      <span i18n="@@files_remove_confirm">Would you like to remove this
        attachment?</span>
    </div>

    <!-- Ext Logins -->
    <div *ngSwitchCase="'ext_login_delete_confirm'">
      <span i18n="@@ext_login_delete_confirm">Do you really want to delete this virtual platform?</span>
    </div>
    <div *ngSwitchCase="'ext_login_delete_success'">
      <span i18n="@@ext_login_delete_success">The virtual platform was successfully deleted.</span>
    </div>
    <div *ngSwitchCase="'ext_login_delete_fail'">
      <span i18n="@@ext_login_delete_fail">Delete login failed</span>
    </div>
    <div *ngSwitchCase="'ext_login_save_error_exists'">
      <span i18n="@@ext_login_save_error_exists">A Login with this url, login
      or password already exists.</span>
    </div>

    <div *ngSwitchCase="'empty_location'">
      <span i18n="@@location_no_data">There are no locations defined yet</span>
    </div>


    <div *ngSwitchCase="'providers_no_data'">
      <span i18n="@@providers_no_data">There are no providers defined yet</span>
    </div>

    <!-- Errors -->
    <div *ngSwitchCase="'error_not_authorized'">
      <span i18n="@@error_not_authorized">You are not authorized to perform this
        operation</span>
    </div>

    <span *ngSwitchCase="'ctrl-single-user-iteration-certify'" i18n="@@ctrl-single-user-iteration-certify">This iteration is about to be finished and the user provided with a certificate. Would you like to proceed?</span>

    <span *ngSwitchCase="'ctrl-single-user-iteration-end'" i18n="@@ctrl-single-user-iteration-end">Would you like to finish this iteration?</span>

    <span *ngSwitchCase="'ctrl-single-user-iteration-reset'" i18n="@@ctrl-single-user-iteration-reset">Would you like to reset this iteration?</span>
  </ng-container>

</ng-container>

<ng-template #showTextMessage>
  <div class="message-container">
    <div *ngFor="let message of messages" [innerHTML]="message | safe:'html'"></div>
  </div>
</ng-template>
