import { EMPTY, Observable } from 'rxjs';
import { OAuth2ClientRegistration } from '../ext-oauth2.types';
import { InfoService } from '../../../../../core/info/info.service';
import { Injectable } from '@angular/core';
import { ExtOauth2EditComponent } from './ext-oauth2-edit.component';
import { Dialogs } from '../../../../../core/core.types';
import { MergeHelper } from '../../../../../core/primitives/merge.helper';
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../../../core/api.urls';
import { ApiResponse } from '../../../../../core/global.types';
import * as uuid from 'uuid';
import { InfoType, MessageConstants } from '../../../../../core/info/info.types';


@Injectable({ providedIn: 'root' })
export class ExtOauth2EditService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  static emptyClientRegistration(): OAuth2ClientRegistration {
    return {
      active: false,
      registrationId: uuid.v4(),

      authorizationGrantType: null,
      authorizationUri: null,
      clientAuthenticationMethod: null,
      clientId: null,
      clientName: null,
      clientSecret: null,
      issuerUri: null,
      jwkSetUri: null,
      platformUri: null,
      redirectUri: null,
      scopes: [ 'openid' ],
      tokenUri: null,
      userAttributeMapping: null,
      userInfoAuthenticationMethod: null,
      userInfoClaimsMapping: null,
      userInfoUri: null,
      userNameAttributeName: null,
      userSsoAttributeKey: null,
      userSsoAttributeValue: null,
      userSsoGroupId: null,
    };
  }

  editClientRegistration(
    clientRegistration: OAuth2ClientRegistration | null,
  ): Observable<OAuth2ClientRegistration | null> {

    if ( clientRegistration == null ) {
      clientRegistration = ExtOauth2EditService.emptyClientRegistration();
    }

    return this.infoService.showDialog<ExtOauth2EditComponent, OAuth2ClientRegistration | null,
      OAuth2ClientRegistration | null>(
      ExtOauth2EditComponent,
      // create a clone to prevent unintentional modification
      MergeHelper.cloneDeep(clientRegistration),
      { ...Dialogs.BigDialogProps },
    )
      .pipe(takeWhile(result => result != null));
  }

}
