import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUrls } from '../../../core/api.urls';
import { InfoService } from '../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../core/info/info.types';
import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { FileUploadService } from '../../../core/files/file-upload.service';
import * as _ from 'lodash';

export const CONVERT_BYTE_TO_MB = 1024 * 1024;

@Component({
  selector: 'rag-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: [ './file-upload.component.scss' ],
})
export class FileUploadComponent
  implements OnInit {
  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;
  @ViewChild('fileInputXHR', { static: false })
  fileInputXHR: ElementRef;
  fileName: string;
  fileToSendXHR: File;
  @Output() fileUploadFinished: EventEmitter<boolean> = new EventEmitter();
  hasFile: boolean;
  hasSizeError: boolean;
  progress: number;
  progressXHR: number;
  testWithXHR = false;
  private apiUrlKey_: string;
  private maxFileSizeMB_: number;
  private maxFileSize_ = CONVERT_BYTE_TO_MB; // default limit 1 MB

  constructor(
    private service: FileUploadService,
    private designService: AccountDesignService,
    private router: Router,
    private infoService: InfoService,
  ) {
  }

  get maxFileSizeMB() {
    return this.maxFileSizeMB_;
  }

  @Input() set apiUrlKey(value: string) {
    // make sure Api Call is there
    if ( ApiUrls.getKey(value) != null ) {
      this.apiUrlKey_ = value;
    } else {
      // no or wrong apiUrlKey defined! Please check latest implementations of <rag-file-upload> in html files
      this.infoService.showSnackbar(MessageKey.FILE_UPLOAD_WRONG_APIURLKEY, InfoType.Error);
    }
  }

  @Input() set maxFileSize(value: number) {
    if ( value > 0 ) {
      this.maxFileSize_ = value * CONVERT_BYTE_TO_MB;
      this.maxFileSizeMB_ = value;
    }
  }

  chooseFile() {
    this.fileInput.nativeElement.click();
  }

  clearInput() {
    // delete file from input element
    if ( this.fileInput.nativeElement ) {
      this.fileInput.nativeElement.value = null;
    }

    this.hasFile = false;
    this.hasSizeError = false;
    this.fileName = '';
    this.progress = 0;
  }

  ngOnInit() {
    this.hasFile = false;
    this.hasSizeError = false;
    this.fileName = '';
    this.progress = 0;
    this.progressXHR = 0;
  }

  // only for XHR test -- remove when uploadFile method shows progress correctly
  saveFile(event) {
    this.fileToSendXHR = event.target.files[0];
  }

  stopEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  // only for XHR test -- remove when uploadFile method shows progress correctly
  transferCanceled() {
    console.log('The transfer has been canceled by the user.');
  }

  // only for XHR test -- remove when uploadFile method shows progress correctly
  transferComplete() {
    console.log('The transfer is complete.');
  }

  // only for XHR test -- remove when uploadFile method shows progress correctly
  transferFailed() {
    console.log('An error occurred while transferring the file.');
  }

  // progress on transfers from the server to the client (downloads)
  updateProgress = (event) => {
    if ( event.lengthComputable ) {
      const percentComplete = _.round((event.loaded / event.total * 100), 2);
      this.progressXHR = percentComplete;
      console.log('updateProgress - percent', percentComplete);
    } else {
      // Unable to compute progress information since the total size is unknown
      console.log('updateProgress - unable to compute progress information since the total size is unknown');
    }
  };

  // only for XHR test -- remove when uploadFile method shows progress correctly
  uploadAsXHR() {
    console.log('uploadAsXHR fired');
    const formData = new FormData();
    formData.append('file', this.fileToSendXHR);
    console.log('file', this.fileToSendXHR);

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', this.updateProgress, true);
    xhr.upload.addEventListener('load', this.transferComplete);
    xhr.upload.addEventListener('error', this.transferFailed);
    xhr.upload.addEventListener('abort', this.transferCanceled);

    xhr.open('POST', ApiUrls.getKey('GetAccountImages'), true);
    xhr.send(formData);
  }

  uploadFile(event) {
    this.fileName = event.target.files[0].name;
    this.hasFile = true;

    // check if file is larger than allowed
    if ( event.target.files[0].size > this.maxFileSize_ ) {
      this.hasSizeError = true;
      return;
    }

    const input = new FormData();
    input.append('file', event.target.files[0]);

    this.service.uploadFile(input, this.apiUrlKey_).subscribe((response) => {
      // console.log('response from component method uploadFile', response);
      if ( response.type === HttpEventType.UploadProgress ) {
        // display progress
        this.progress = _.round(100 * (response.loaded / response.total), 2);
      } else if ( response.type === HttpEventType.Response ) {
        this.progress = 0;
        this.hasFile = false;
        this.fileName = '';

        this.fileUploadFinished.emit(true);
        this.infoService.showSnackbar(MessageKey.FILE_UPLOAD_SUCCESS, InfoType.Success);
        this.clearInput();
      }
    }, () => {
      this.infoService.showSnackbar(MessageKey.FILE_UPLOAD_ERROR, InfoType.Error);
    });
  }
}

