import { Component } from '@angular/core';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';
import { ControlContainer } from '@angular/forms';
import { DateHelper } from '../../../../core/date.helper';
import * as moment from 'moment';


@Component({
  selector: 'rag-profile-field-date',
  templateUrl: './profile-field-date.component.html',
  styleUrls: [ './profile-field-date.component.scss' ],
})
export class ProfileFieldDateComponent
  extends AbstractProfileFieldComponent<moment.Moment> {

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  getError(): string | null {
    const control = this.getControl();
    const errors = control?.errors;

    const keys = Object.keys(errors ?? {});
    if ( keys.length === 0 ) {
      return null;
    }

    if ( control.hasError('matDatepickerParse') ) {
      return $localize`:@@input_validators_error_date_invalid:
        The date format is invalid.`;
    }

    for (const key of keys) {
      const errorMessage = errors[key];
      if ( typeof errorMessage === 'string' ) {
        return errorMessage;
      }
    }
    return null;
  }

  protected cleanValue(): moment.Moment {
    return DateHelper.toMoment(this.field?.value);
  }

}
