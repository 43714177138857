<ng-template #tplDate>
  <rag-pivot-filter-date
    (change$)="onFilterChange($event)"
    (blur)="onBlur()"
    [debounceTimeout]="options?.filterDebounceTimeout ?? 0"
    [filter]="options?.filter"
    [label]="LanguageHelper.objectToText(column.title)"
    [outsourcedFilter]="outsourcedFilter"
  ></rag-pivot-filter-date>
</ng-template>

<ng-template #tplNumber>
  <rag-pivot-filter-number
    (change$)="onFilterChange($event)"
    (blur)="onBlur()"
    [debounceTimeout]="options?.filterDebounceTimeout ?? 0"
    [filter]="options?.filter"
    [label]="LanguageHelper.objectToText(column.title)"
    [outsourcedFilter]="outsourcedFilter"
  ></rag-pivot-filter-number>
</ng-template>

<ng-template #tplText>
  <rag-pivot-filter-text
    (change$)="onFilterChange($event)"
    (blur)="onBlur()"
    [debounceTimeout]="options?.filterDebounceTimeout ?? 0"
    [filter]="options?.filter"
    [label]="LanguageHelper.objectToText(column.title)"
    [outsourcedFilter]="outsourcedFilter"
  ></rag-pivot-filter-text>
</ng-template>

<ng-template #tplDropDown>
  <rag-pivot-filter-dropdown
    (change$)="onFilterChange($event)"
    (blur)="onBlur()"
    [debounceTimeout]="options?.filterDebounceTimeout ?? 0"
    [filter]="options?.filter"
    [options]="options?.dropDownOptions"
    [sortOptions]="options?.dropDownSortMethod"
    [placeholder]="options?.placeholder"
    [label]="LanguageHelper.objectToText(column.title)"
    [outsourcedFilter]="outsourcedFilter"
  ></rag-pivot-filter-dropdown>
</ng-template>

<ng-template #tplTags>
  <rag-pivot-filter-tags
    (change$)="onFilterChange($event)"
    (blur)="onBlur()"
    [debounceTimeout]="options?.filterDebounceTimeout ?? 0"
    [filter]="options?.filter"
    [options]="options?.dropDownOptions"
    [label]="LanguageHelper.objectToText(column.title)"
    [placeholder]="options?.placeholder"
    [outsourcedFilter]="outsourcedFilter"
  ></rag-pivot-filter-tags>
</ng-template>

<ng-template #tplHidden></ng-template>

<ng-container
  *ngTemplateOutlet="getTemplate(
    tplHidden,
    tplDate,
    tplNumber,
    tplText,
    tplDropDown,
    tplTags
  )"
></ng-container>
