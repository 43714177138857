<ng-container *ngIf="(data$ | async) as data">

  <div class="image-wrapper" [ngStyle]="data.backgroundStyles">
    <div class="image-shadow">
      <div class="login-overlay"
           [ngClass]="{
               'border-radius-half': !LanguageHelper.isEmptyTranslation(data.welcomeTexts),
               'width-400': LanguageHelper.isEmptyTranslation(data.welcomeTexts),
               }"
      >
        <div class="logo-wrapper">
          <img class="logo" [ragDefault]="" [src]="data.logoUrl" alt="Company Logo">
          <button
            *ngIf="languages?.length > 1"
            [matMenuTriggerFor]="matMenuLanguage"
            mat-button
            class="language-btn"
            type="button"
          >
            <div class="select-language">
              <ng-template #tplLanguageFallback>
                <span i18n="@@select_language">Language</span>
              </ng-template>

              <ng-container *ngIf="currentLanguage as language; else tplLanguageFallback">
                <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
              </ng-container>
            </div>
            <mat-icon svgIcon="chevron-down"></mat-icon>
            <!-- Language menu -->
            <mat-menu #matMenuLanguage class="language-menu">
              <button
                *ngFor="let language of languages"
                (click)="onSwitchToLanguage(language)"
                [disabled]="currentLanguagePath?.language === language.key"
                mat-menu-item
                type="button"
              >
                <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
              </button>
            </mat-menu>
          </button>
        </div>
        <div class="content-wrapper">
          <div class="inner-content">
            <h3 class="headline"
                [ngStyle]="{color: data.applyCustomColors ?'$rag-main-color' : 'black'}"
            >{{data.loginFormTexts}}</h3>
            <button
              *ngIf="catalogState.publicCatalog"
              class="catalog-button"
              mat-flat-button
              [routerLink]="previewMode ? null : '/catalog'"
            >
              <mat-icon svgIcon="apps"></mat-icon>
              <span i18n="@@global_catalog">Catalog</span>
            </button>
            <rag-login-form-v2
              [previewMode]="previewMode"
              [texts]="texts"
              class="login-form"></rag-login-form-v2>
          </div>
        </div>
        <div class="flex-container">
          <div class="footer-container">
            <div class="footer-wrapper">
              <a class="expand margin-left-0 footer-link space" *ngIf="checkLink(data.legalNotice)"
                 [href]="previewMode ? null : data.legalNotice"
                 target="_blank">
                <span i18n="@@impressum">Impressum</span>
              </a>
              <p *ngIf="checkLink(data.terms)" class="expand space">|</p>
              <a class="expand footer-link space" *ngIf="checkLink(data.terms)"
                 [href]="previewMode ? null : data.terms"
                 target="_blank">
                <span i18n="@@terms">Terms</span>
              </a>
              <p *ngIf="checkLink(data.policies)" class="expand space">|</p>
              <a class="expand footer-link" *ngIf="checkLink(data.policies)"
                 [href]="previewMode ? null : data.policies"
                 target="_blank">
                <span i18n="@@policies">Policies</span>
              </a>
              <a class="contact footer-link"
                 [routerLink]="previewMode ? null : '/contact'"
                 target="_blank">
                <span i18n="@@contact_title">Contact</span>
              </a>
            </div>
            <span class="expand margin-left-0">Powered by {{ data.poweredBy }}</span>
          </div>


        </div>
      </div>
      <div class="description-overlay"
           *ngIf="!LanguageHelper.isEmptyTranslation(data.welcomeTexts)">
        <div class="inner-html-flex">
          <div
            *ngIf="data.welcomeTexts as welcomeText"
            class="welcome-text ck-styles"
            [innerHTML]="welcomeText | safe:'html'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
