import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InfoModule } from 'src/app/core/info/info.module';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { GenericMessageDialogComponent } from '../generic-message-dialog/generic-message-dialog.component';
import { GenericTextInputDialogData } from './generic-text-input-dialog.types';
import { MatInputModule } from "@angular/material/input";
import { map, Observable, startWith } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatFormFieldModule,
    InfoModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  selector: 'rag-generic-text-input-dialog',
  templateUrl: './generic-text-input-dialog.component.html',
  styleUrls: ['./generic-text-input-dialog.component.scss']
})
export class GenericTextInputDialogComponent extends GenericMessageDialogComponent implements OnInit {

  form: FormGroup;
  submitButtonDisabled$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GenericTextInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericTextInputDialogData,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form = this.formBuilder.group({
      text: [null, [Validators.required]]
    });
    this.submitButtonDisabled$ = this.form.statusChanges
      .pipe(map(status => status !== 'VALID'))
      .pipe(startWith(true));
    if (this.data.data?.text !== undefined) {
      this.form.get('text').setValue(this.data.data.text);
    }
  }

  onSubmit() {
    this.dialogRef.close(this.form.get('text').value);
  }

}
