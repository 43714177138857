<div class="flex-container">
  <div class="flex-break-line">
    <a
      *ngIf="!!backUrl || (backParams != null)"
      [queryParams]="backParams"
      [routerLink]="backUrl"
      class="page-header-back"
      color="primary"
      mat-icon-button
      queryParamsHandling="merge"
      data-cy="buttonBack"
    >
      <mat-icon svgIcon="chevron-left"></mat-icon>
    </a>

    <div [ngClass]="{'has-sub-title': !!showSubTitle, 'has-prefix': !!hasPrefix}" class="header-line">
      <ng-content select=".page-header-prefix"></ng-content>

      <div class="header-title">
        <ng-content select=".page-header-title"></ng-content>
        <ng-container
          *ngIf="showSubTitle"
        >
          <ng-content select=".page-header-sub-title"></ng-content>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-content select=".page-header-buttons"></ng-content>
</div>
