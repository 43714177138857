import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordComponent } from './password.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PasswordValidationComponent } from '../../password-validation/password-validation.component';


@NgModule({
  declarations: [ PasswordComponent ],
  exports: [ PasswordComponent ],
  imports: [
    CommonModule,
    MatInputModule,
    PasswordValidationComponent,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    MatTooltipModule,
  ],
})
export class PasswordModule {
}
