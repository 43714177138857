<rag-dialog-header>
  <span i18n="@@curriculum_license_information">Assignment of the learning content:</span>
</rag-dialog-header>

<div mat-dialog-content>
  <div class="user-info">
    <div class="row">
      <div class="cell label">
        <span i18n="@@global_learner">Learner</span>
      </div>
      <div class="cell data">{{userName}}</div>
    </div>
    <div class="row">
      <div class="cell label">
        <span i18n="@@global_curriculum">Curriculum</span>
      </div>
      <div class="cell data">{{curriculumTitle | localeText}}</div>
    </div>
  </div>

  <div class="label headers">
    <div i18n="@@curriculum_license_info">Groups through which the content is assigned to you</div>
    <div class="space-consumer">({{licensesCount}}):</div>
    <div i18n="@@global_assignment">Assignment</div>
  </div>

  <cdk-virtual-scroll-viewport class="licenses" itemSize="3">
    <div *cdkVirtualFor="let license of licenses" class="license">
      <div class="item-header">
        <h4 class="title">{{license.targetGroupName}}</h4>
        <span [ngClass]="{'mandatory': isAssignmentMandatory(license.assignmentType)}" class="assignment">
            <ng-container [ngSwitch]="license.assignmentType">
              <span *ngSwitchCase="'mandatory'" i18n="@@assignment_mandatory">Mandatory</span>
              <span *ngSwitchCase="'both'" i18n="@@assignment_both">Both</span>
              <span *ngSwitchCase="'voluntary'" i18n="@@assignment_voluntary">Voluntary</span>
            </ng-container>
          </span>
      </div>
      <span class="descr" [innerHTML]="license.targetGroupDescription | safe:'html'"></span>
    </div>
  </cdk-virtual-scroll-viewport>

</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-dialog-close
    mat-stroked-button
    class="rag-stroked-button"
  >
    <span i18n="@@global_close">Close</span>
  </button>
</div>
