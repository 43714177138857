import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { InfoService } from '../info/info.service';
import { InfoType, MessageKey } from '../info/info.types';
import { ReportService } from '../report/report.service';
import { ColumnSettingsService } from './column-settings.service';
import { Column } from './column-settings.types';
import { AnyObject } from '../core.types';
import { RouteSnapshotHelper } from '../route-snapshot.helper';

@Injectable({
  providedIn: 'root',
})
export class ColumnSettingsResolver
  implements Resolve<AnyObject<Column>> {

  constructor(
    private columnSettingsService: ColumnSettingsService,
    private infoService: InfoService,
    private reportsService: ReportService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AnyObject<Column>> {
    const reset = route.routeConfig.runGuardsAndResolvers === 'always';

    let targetType = route.paramMap.get('targetType');
    if ( targetType ) {
      const forUpdate = RouteSnapshotHelper.getDataValue(route, 'columnsForUpdate', v => v);
      return this.getColumnSettings(targetType, forUpdate, reset)
        .pipe(take(1));
    }

    const uuid = route.paramMap.get('uuid');
    if ( uuid ) {
      return this.reportsService.fetchReport(uuid, true)
        .pipe(switchMap(report => {
          const reportConfig = report.reportConfig;
          targetType = reportConfig.targetType;
          if ( targetType ) {
            return this.getColumnSettings(targetType, false, reset);
          } else {
            this.infoService.showSnackbar(MessageKey.REPORT.REPORT_TARGET_TYPE_NOT_FOUND, InfoType.Error);
            return throwError(MessageKey.REPORT.REPORT_TARGET_TYPE_NOT_FOUND);
          }
        }))
        .pipe(take(1));
    }

    this.infoService.showSnackbar(MessageKey.REPORT.REPORT_TARGET_TYPE_NOT_FOUND, InfoType.Error);
    return throwError(MessageKey.REPORT.REPORT_TARGET_TYPE_NOT_FOUND);
  }

  private getColumnSettings(targetType, forUpdate, reset): Observable<AnyObject<Column>> {
    if ( forUpdate ) {
      return this.columnSettingsService.fetchColumnSettings(targetType, true)
        .pipe(take(1));
    } else {
      return this.columnSettingsService.getColumnSettings(targetType, reset)
        .pipe(take(1));
    }
  }

}
