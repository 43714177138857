import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserCurriculumData } from './user-curriculum.types';
import { takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { map, take, tap } from 'rxjs/operators';
import { UserNameHelper } from '../../../../core/user-name.helper';
import { LanguageHelper } from '../../../../core/language.helper';
import { ControllingSingleUserTypes } from '../../../../core/ctrl-single-user.types';
import { ColumnSettings } from '../../../../core/report/report.types';
import { UserCurriculumService } from './user-curriculum.service';


@Component({
  selector: 'rag-user-curriculum-details',
  templateUrl: './user-curriculum-details.component.html',
  styleUrls: [ './user-curriculum-details.component.scss' ],
})
export class UserCurriculumDetailsComponent
  implements OnDestroy, OnInit {

  curriculumId: number;
  curriculumTitle: string;
  userDetails: UserCurriculumData;
  userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;
  userFullName: string;
  userId: number;

  constructor(
    private route: ActivatedRoute,
    private userCurriculumService: UserCurriculumService,
  ) {
  }

  private static getUserDetailsView(
    curriculum: ControllingSingleUserTypes.CurriculumAccount | null,
    user: ControllingSingleUserTypes.ControllingUser | null,
    userColumns: ColumnSettings[],
  ): ControllingSingleUserTypes.UserDetailsResponse {
    return {
      userColumns,
      courses: [],
      certificates: [],
      curricula: [ curriculum ].filter(obj => obj != null),
      controllingUser: user,
      offlineContents: [],
      contentActions: []
    };
  }

  ngOnDestroy(): void {
    // required for takeUntilDestroyed
  }

  ngOnInit() {
    this.route.data
      .pipe(map(data => data.userDetails))
      .pipe(tap(this.updateRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    this.userDetails = this.route.snapshot.data.userDetails;
  }

  reloadData(): void {
    this.userCurriculumService.getCurriculumForUser(this.userId, this.curriculumId)
      .pipe(tap(this.updateRouteData))
      .pipe(take(1))
      .subscribe();
  }

  private updateRouteData = (userDetails: UserCurriculumData): void => {
    this.userDetails = userDetails;

    const curriculum = userDetails?.curriculum;
    this.curriculumId = curriculum?.curriculumId;
    this.curriculumTitle = LanguageHelper.objectToText(curriculum?.title);

    const user = userDetails?.user;
    this.userId = user?.userId;
    this.userFullName = UserNameHelper.getFullName(user);

    this.userDetailsView = UserCurriculumDetailsComponent
      .getUserDetailsView(curriculum, user, userDetails?.userColumns);
  };

}
