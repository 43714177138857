import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../../../component/header/header.module';
import { LiveAgendaComponent } from './live-agenda.component';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { CurriculumTitleModule } from '../../../component/elearning/curriculum-title/curriculum-title.module';
import { ImportantContentInfoModule } from '../../../component/elearning/important-content-info/important-content-info.module';
import { ToggleFullscreenModule } from '../../../component/elearning/toggle-fullscreen/toggle-fullscreen.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { LiveAgendaDetailsDialogModule } from './live-agenda-details-dialog/live-agenda-details-dialog.module';
import { HeaderLogoComponent } from 'src/app/component/header/header-logo/header-logo.component';


@NgModule({
  declarations: [ LiveAgendaComponent ],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    PipesModule,
    CurriculumTitleModule,
    ImportantContentInfoModule,
    LiveAgendaDetailsDialogModule,
    ToggleFullscreenModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    HeaderModule,
    HeaderLogoComponent,
    RouterModule,
  ],
  exports: [
    LiveAgendaComponent
  ]
})
export class LiveAgendaModule {
}
