import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { ContentPageTitleModule } from '../../../component/elearning/content-page-title/content-page-title.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ContextOverviewComponent } from './context-overview/context-overview.component';
import { CurriculumTitleModule } from '../../../component/elearning/curriculum-title/curriculum-title.module';
import { ImportantContentInfoModule } from '../../../component/elearning/important-content-info/important-content-info.module';
import { StatusLightsModule } from '../../../component/elearning/status-lights/status-lights.module';

@NgModule({
  declarations: [
    ContextOverviewComponent,
  ],
  exports: [
    ContextOverviewComponent,
  ],
  imports: [
    BreadcrumbsModule,
    CommonModule,
    ContentPageTitleModule,
    CurriculumTitleModule,
    ImportantContentInfoModule,
    MatButtonModule,
    MatIconModule,
    PipesModule,
    RouterModule,
    StatusLightsModule,
  ],
})
export class ContentContextModule {
}
