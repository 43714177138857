import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { NumberedAnyObject } from '../core.types';
import { LearnerAccountContextItemView, LearnerAccountView } from './learner-account.types';
import { OfflineContent } from '../admin-offline.types';

@Injectable({
  providedIn: 'root',
})
export class LearnerAccountService {

  constructor(
    private http: HttpClient,
  ) {
  }

  fetchContextItem(rootCurriculumId: number, curriculumItemId: number): Observable<LearnerAccountContextItemView> {
    const url = ApiUrls.getKey('AccountContextItem')
      .replace(/{rootCurriculumId}/gi, String(rootCurriculumId))
      .replace(/{curriculumItemId}/gi, String(curriculumItemId));
    return this.http.get<any>(url)
      .pipe(map(response => response.data || {}));
  }

  fetchCurriculumEvents(curriculumId: number): Observable<NumberedAnyObject<OfflineContent.EventSchedule[]>> {
    const url = ApiUrls.getKey('AccountCurriculumEvents')
      .replace(/{curriculumId}/gi, String(curriculumId));

    return this.http.get<any>(url)
      .pipe(map(response => response.offlineEvents || {}));
  }

  fetchOfflineContentDirect<T extends LearnerAccountView>(
    contentId: number,
    invitationKey?: string,
    onlyValid = false,
  ): Observable<T> {
    let url = ApiUrls.getKey('AccountOfflineContentDirect_v2')
      .replace(/{offlineContentId}/gi, String(contentId));

    if ( onlyValid === true ) {
      // force checking for valid accounts
      url += '/valid';
    }

    if ( invitationKey != null ) {
      url += '?invKey=' + invitationKey;
    }
    return this.http.get<any>(url)
      .pipe(map(response => response.data || {}));
  }


  fetchOfflineContentItem<T extends LearnerAccountView>(rootCurriculumId: number, curriculumItemId: number, invitationKey?: string):
    Observable<T> {
    let url = ApiUrls.getKey('AccountOfflineContentItem_v2')
      .replace(/{rootCurriculumId}/gi, String(rootCurriculumId))
      .replace(/{curriculumItemId}/gi, String(curriculumItemId));
    if ( invitationKey != null ) {
      url += '?invKey=' + invitationKey;
    }
    return this.http.get<any>(url)
      .pipe(map(response => response.data || {}));
  }

}
