<rag-breadcrumbs>
  <a *ngIf="(styleSettings$ | async) as styleSettings">{{styleSettings.acc.myContentsName | localeText}}</a>

  <rag-breadcrumbs-assignment-type
    *ngIf="assignmentType"
    [assignmentType]="assignmentType"
  ></rag-breadcrumbs-assignment-type>

  <rag-breadcrumbs-link
    [title]="curriculum.title"
    [url]="backUrl"
  ></rag-breadcrumbs-link>

  <rag-breadcrumbs-link
    [title]="curriculumItemTitle"
  ></rag-breadcrumbs-link>
</rag-breadcrumbs>

<rag-content-page-title
  [backUrl]="backUrl"
  [displayStatus]="displayStatus"
  [title]="contextItem.title"
  [typeOfTraining]="typeOfTraining"
></rag-content-page-title>

<div class="content">

  <div *ngIf="contextItem.description" class="row">
    <div class="label" i18n="@@global_description">Description</div>
    <div [innerHTML]="contextItem.description | safe:'html'"></div>
  </div>

  <div *ngIf="contextItem?.additionalData?.json?.duration" class="row">
    <div class="label" i18n="@@global_duration">Duration</div>
    <div>{{contextItem.additionalData.json.duration}}</div>
  </div>

</div>
