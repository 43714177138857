<rag-dialog-header>
  <rag-messages [key]="dialogTitleKey"></rag-messages>
</rag-dialog-header>

<form (submit)="onSubmit($event)" [formGroup]="myForm">
  <div mat-dialog-content>
    <p>
      <span
        *ngIf="existingTitle === undefined"
        i18n="@@report_save_descr">Almost there. Only one title is missing for your new report</span>
      <span
        *ngIf="existingTitle !== undefined"
        i18n="@@report_rename_descr">Enter the new name of this report</span>
    </p>
    <mat-form-field appearance="outline">
      <mat-label>
        <span i18n="@@global_title">Title</span>
      </mat-label>
      <input
        [maxLength]="255"
        cdkFocusInitial
        formControlName="titleControll"
        matInput
        name="title"
        required>
    </mat-form-field>

    <mat-checkbox (change)="onCheckboxChange($event)" *ngIf="!hideOpenCheckbox" [checked]="openAfterSave">
      <span i18n="@@report_open_after_save">Open after saving</span>
    </mat-checkbox>
  </div>

  <div align="end" mat-dialog-actions>

    <button
      [mat-dialog-close]
      mat-stroked-button
      type="button">
        <span i18n="@@global_cancel">Cancel</span>
    </button>

    <button
      [disabled]="isSubmitButtonDisabled"
      color="primary"
      mat-stroked-button
      type="submit">
        <span i18n="@@global_save">Save</span>
    </button>
  </div>
</form>
