import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../../component/table/table-controller/table-controller.types';
import { TableGroupingTypes } from '../../../../../component/table/table-grouping/table-grouping.types';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import * as moment from 'moment';
import { Core } from '../../../../../core/core.types';
import { Translation } from '../../../../../core/translation/translation.types';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { DateHelper } from '../../../../../core/date.helper';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { naturalCompare } from '../../../../../core/natural-sort';
import { LanguageHelper } from '../../../../../core/language.helper';
import { TableGroupingHelper } from '../../../../../component/table/table-grouping/table-grouping.helper';
import { TableColumnBuilder } from '../../../../../component/table/table-column.builder';
import { filterTitleWithParent } from '../ctrl-single-user-details.types';


export namespace CtrlSingleUserDetailsCurriculumTypes {

  export interface RowData
    extends TableGroupingTypes.TableRow<ControllingSingleUserTypes.CurriculumAccount,
      ControllingSingleUserTypes.CurriculumItemAccount> {
    assignmentDate: moment.Moment;
    assignmentType: Core.DistAssignmentType;
    description: string;
    displayStatus: DisplayStatus;
    finishDate: moment.Moment;
    licenceEnded: boolean;
    locked: boolean;
    orderIndex: number;
    targetType: Core.DistributableType;
    title: Translation;
    validSince: moment.Moment;
    validUntil: moment.Moment;
  }

  export interface RowDataChild
    extends RowData, TableGroupingTypes.TableRowChild<ControllingSingleUserTypes.CurriculumAccount,
      ControllingSingleUserTypes.CurriculumItemAccount> {
    $data: ControllingSingleUserTypes.CurriculumItemAccount;
    attempts: string;
  }

  export interface RowDataParent
    extends RowData, TableGroupingTypes.TableRowParent<ControllingSingleUserTypes.CurriculumAccount,
      ControllingSingleUserTypes.CurriculumItemAccount> {
    $children: RowData[];
    $data: ControllingSingleUserTypes.CurriculumAccount;
    accountStatus: Core.CurriculumAccountStatus;
    curriculumId: number;
    curriculumPathTitle: string;
  }

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    /*checkbox: TableControllerTypes.ColumnMenuItem;*/
    attempts: TableControllerTypes.ColumnMenuItem;
    accountStatus: TableControllerTypes.ColumnMenuItem;
    actions: TableControllerTypes.ColumnMenuItem;
    assignmentDate: TableControllerTypes.ColumnMenuItem;
    assignmentType: TableControllerTypes.ColumnMenuItem;
    batchCheckbox: TableControllerTypes.ColumnMenuItem;
    collapse: TableControllerTypes.ColumnMenuItem;
    curriculumId: TableControllerTypes.ColumnMenuItem;
    curriculumPathTitle: TableControllerTypes.ColumnMenuItem;
    description: TableControllerTypes.ColumnMenuItem;
    displayStatus: TableControllerTypes.ColumnMenuItem;
    finishDate: TableControllerTypes.ColumnMenuItem;
    locked: TableControllerTypes.ColumnMenuItem;
    orderIndex: TableControllerTypes.ColumnMenuItem;
    targetType: TableControllerTypes.ColumnMenuItem;
    title: TableControllerTypes.ColumnMenuItem;
    validSince: TableControllerTypes.ColumnMenuItem;
    validUntil: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'batchCheckbox', 'collapse', 'orderIndex' ],
    endWith: [ 'actions' ],
    menuItems: {
      batchCheckbox: TableControllerTypes.Util.structuralColumnFactory('batchCheckbox'),
      collapse: TableControllerTypes.Util.structuralColumnFactory('collapse'),

      orderIndex: TableColumnBuilder.start<RowData>()
        .withColumnId('orderIndex')
        .withTitle($localize`:@@global_nr:No.`)
        .withType(TableColumnDataType.number)
        .withSortingAccessor(data => data.orderIndex)
        .withSelected()
        .build(),

      title: TableColumnBuilder.start<RowData>()
        .withColumnId('title')
        .withType(TableColumnDataType.text)
        .withTitle($localize`:@@global_title:Title`)
        .withFilterDataAccessor(data => data)
        .withFilterMethod(filterTitleWithParent())
        .withSelected()
        .build(),

      displayStatus: TableControllerTypes.Util.displayStatusColumnFactory('displayStatus',
        $localize`:@@global_status:Status`, {
          green: $localize`:@@status_light_tooltip_green:Green`,
          yellow: $localize`:@@status_light_tooltip_yellow:Yellow`,
          red: $localize`:@@status_light_tooltip_red:Red`,
          not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
          not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
        }, $localize`:@@report_filter_status_is:Status is...`, null, true),
      accountStatus: TableControllerTypes.Util.dropdownColumnFactory('accountStatus',
        $localize`:@@ctrl_single_user_details_accountStatus:Account status`, {
          ended: $localize`:@@ctrl_single_user_details_accountStatus_ended:Not processable`,
          valid: $localize`:@@ctrl_single_user_details_accountStatus_valid:Processable`,
          /*@todo implement "advanced" mode. locked, annulled, not_attempted removed currently, because not useful as filter value for enduser. will be listed with all */
        }, $localize`:@@ctrl_single_user_details_accountStatus_placeholder:Status is...`, null, true),
      targetType: TableControllerTypes.Util.dropdownColumnFactory('targetType',
        $localize`:@@global_distributable_type:Type`, {
          lms_course: $localize`:@@global_distributable_type_lms_course:Course`,
          lms_workflow: $localize`:@@global_distributable_type_lms_workflow:Workflow`,
          skilltarget: $localize`:@@global_distributable_type_skilltarget:Skill target`,
          lms_curriculum: $localize`:@@global_distributable_type_lms_curriculum:Curriculum`,
          lms_offlineCnt: $localize`:@@global_distributable_type_lms_offlineCnt:Event`,
          lms_offline_event: $localize`:@@global_distributable_type_lms_offline_event:Offline event`,
          lms_vroom_group: $localize`:@@global_distributable_type_lms_vroom_group:Virtual classroom`,
          lms_context: $localize`:@@global_distributable_type_lms_context:Context item`,
          lms_cur_path: $localize`:@@global_distributable_type_lms_cur_path:Curriculum path`,
        }, $localize`:@@global_distributable_type_is:Type is...`, null, true),
      assignmentType: TableControllerTypes.Util.dropdownColumnFactory('assignmentType',
        $localize`:@@global_assignment:Assignment`, {
          mandatory: $localize`:@@global_assignment_type_filter_mandatory:Mandatory`,
          voluntary: $localize`:@@global_assignment_type_filter_voluntary:Voluntary`,
          /*'both': $localize`:@@global_assignment_type_filter_both:Mandatory + voluntary`,*/
        }, $localize`:@@global_assignment_type_filter_placeholder:Assignment is...`, null, true),
      assignmentDate: TableControllerTypes.Util.dateTimeColumnFactory('assignmentDate',
        $localize`:@@ctrl_single_user_details_assignmentDate:Assignment date`, null, true),
      finishDate: TableControllerTypes.Util.dateTimeColumnFactory('finishDate',
        $localize`:@@ctrl_single_user_details_finishDate:Finish date`, null, true),
      validSince: TableControllerTypes.Util.dateTimeColumnFactory('validSince',
        $localize`:@@ctrl_single_user_details_validSince:Valid since`, null, true),
      validUntil: TableControllerTypes.Util.dateTimeColumnFactory('validUntil',
        $localize`:@@ctrl_single_user_details_validUntil:Valid until`, null, true),
      locked: TableColumnBuilder.start<CtrlSingleUserDetailsCurriculumTypes.RowData>()
        .withColumnId('locked')
        .withType(TableColumnDataType.dropdown)
        .withTitle($localize`:@@global_assigned_or_locked:Assigned/Locked`)
        .withPlaceholder($localize`:@@global_assigned_or_locked_filter_placeholder:Is assigned or locked?`)
        .withDataAccessor(data => {
          if ( data?.$rowType === 'parent' ) {
            return data.licenceEnded ? 'unlicensed' : 'licensed';
          } else {
            return data?.locked ? 'locked' : 'unlocked';
          }
        })
        .withFilterDataAccessor(data => data)
        .withFilterMethod((data, filter) => {
          const children = TableGroupingHelper.getChildren(data);
          switch ( filter?.value ?? '' ) {
            case 'licensed':
              return data?.licenceEnded !== true;
            case 'unlicensed':
              return data?.licenceEnded === true;
            case 'locked':
              if (data?.$rowType === 'parent') {
                return children
                  .find(child => child.locked === true) != null;
              }
              return data?.locked === true;
            case 'unlocked':
              if (data?.$rowType === 'parent') {
                return children
                  .find(child => child.locked !== true) != null;
              }
              return data?.locked !== true;
            default:
              return true;
          }
        })
        .withDropDownOptions({
          licensed: $localize`:@@global_licence_ended_filter_false:Assigned`,
          unlicensed: $localize`:@@global_licence_ended_filter_true:Unassigned`,
          locked: $localize`:@@global_locked_filter_true:Locked`,
          unlocked: $localize`:@@global_locked_filter_false:Unlocked`,
        })
        .build(),
      curriculumId: TableControllerTypes.Util.numberColumnFactory('curriculumId',
        $localize`:@@ctrl_single_user_details_curriculumId:Curriculum ID`),
      description: TableControllerTypes.Util.htmlColumnFactory('description',
        $localize`:@@global_description:Description`),
      curriculumPathTitle: TableControllerTypes.Util.textColumnFactory('curriculumPathTitle',
        $localize`:@@global_distributable_type_lms_cur_path:Curriculum path`),
      attempts: TableColumnBuilder.start<RowDataChild>()
        .withColumnId('attempts')
        .withType(TableColumnDataType.number)
        .withTitle($localize`:@@ctrl_single_user_details_attempts:Attempts`)
        .withDataAccessor(row => row?.$data?.attempts ?? 0)
        .build(),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };

  export class Util {

    static curriculumItemToColumns(item: ControllingSingleUserTypes.CurriculumItemAccount,
      parent: RowDataParent): RowData {
      const row: RowDataChild = {
        $data: item,
        $parent: parent,
        $rowSort: TableGroupingTypes.Util.calculateRowSort(item.orderIndex, parent),
        $rowType: 'child',

        attempts: this.getAttemptsText(item),
        assignmentDate: DateHelper.toMoment(item.accountCreationDate),
        assignmentType: parent.assignmentType,
        description: parent.description,
        displayStatus: DisplayStatusHelper.toDisplayStatus(item.displayStatus),
        finishDate: DateHelper.toMoment(item.accountFinishDate),
        licenceEnded: parent.licenceEnded,
        locked: item.locked,
        orderIndex: item.orderIndex,
        targetType: item.targetType,
        title: item.title,
        validSince: null,
        validUntil: null,
      };

      row.$parentRoot = parent.$parentRoot;
      parent.$parentRoot.$maxDepth = Math.max(parent.$parentRoot.$maxDepth || 0, row.$rowSort.length);
      parent.$children.push(row);
      return row;
    }

    static curriculumToColumns(curriculum: ControllingSingleUserTypes.CurriculumAccount): RowDataParent {
      const parent: RowDataParent = {
        $children: [],
        $data: curriculum,
        $rowSort: [ curriculum.curriculumId ],
        $rowType: 'parent',

        accountStatus: curriculum.accountStatus,
        assignmentDate: DateHelper.toMoment(curriculum.currentStartDate),
        assignmentType: curriculum.assignmentType,
        curriculumId: curriculum.curriculumId,
        curriculumPathTitle: curriculum.curriculumPathTitle,
        description: LanguageHelper.objectToText(curriculum.description),
        displayStatus: DisplayStatusHelper.toDisplayStatus(curriculum.displayStatus),
        finishDate: DateHelper.toMoment(curriculum.lastEndDate),
        licenceEnded: curriculum.licenceEnded,
        locked: curriculum.licenceEnded,
        orderIndex: null,
        targetType: Core.DistributableType.lms_curriculum,
        title: curriculum.title,
        validSince: DateHelper.toMoment(curriculum.firstValidSince),
        validUntil: DateHelper.toMoment(curriculum.lastValidUntil),
      };

      parent.$parentRoot = parent;
      return parent;
    }

    static toTableRows<P extends ControllingSingleUserTypes.CurriculumAccount,
      C extends ControllingSingleUserTypes.CurriculumItemAccount>(curricula: P[]): RowData[] {
      const rows = curricula
        .sort((a, b) => naturalCompare(
          LanguageHelper.objectToText(a.title),
          LanguageHelper.objectToText(b.title),
        ))
        .flatMap(curriculum => {
          const parentRow = Util.curriculumToColumns(curriculum);
          const childRows = curriculum.curriculumItems.map(item => Util.curriculumItemToColumns(item, parentRow))
            .sort(TableGroupingTypes.Util.compareRowSort);
          return [ parentRow, ...childRows ];
        });

      // calculate string to keep sorting stable in grouped tables
      TableGroupingHelper.addDefaultSorting(rows);

      return rows;
    }

    private static getAttemptsText(item: ControllingSingleUserTypes.CurriculumItemAccount): string {

      const attemptsAllowed = item.attemptsAllowed;
      if ( !(attemptsAllowed > 0) ) {
        return '';
      }

      let attempts = item.attempts;
      if ( !(attempts > 0) ) {
        attempts = 0;
      }
      return `${attempts} / ${attemptsAllowed}`;
    }

  }

}
