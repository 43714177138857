import { ScoAccount, UserContentAction } from 'src/app/core/content/content.types';
import { Core, ImageableContentReference, TransientView } from './core.types';
import { DisplayStatus } from './display-status.enum';
import { ColumnSettings, ReportRowBasic } from './report/report.types';
import { ContentActionTransients } from './transients.types';
import { Translation } from './translation/translation.types';

export namespace ControllingSingleUserTypes {
  import WithOptionalRbacActions = Core.WithOptionalRbacActions;

  export interface ControllingUser
    extends ReportRowBasic, WithOptionalRbacActions<Core.RbacActionsBasis> {
    userActive: boolean;
    userArea: string;
    userCompany: string;
    userCostCenter: string;
    userCostcenterDesc: string;
    userDateOfBirth: number;
    userEmail: string;
    userEmployeeID: string;
    userExtID: string;
    userFreeTextAttribute1: string;
    userFreeTextAttribute2: string;
    userFreeTextAttribute3: string;
    userFreeTextAttribute4: string;
    userFreeTextAttribute5: string;
    userFreeTextAttribute6: string;
    userFreeTextAttribute7: string;
    userGeID: string;
    userHireDate: number;
    userHostID: string;
    userJobCode: string;
    userLocation: string;
    userLocationDesc: string;
    userLogin: string;
    userSex: number;
    userSoeID: string;
    userState: string;
    userSubstitute2ID: string;
    userSubstituteID: string;
    userSuperiorID: string;
  }

  export interface UserListResponse {
    controllingUsers: ControllingUser[];
    userColumns: ColumnSettings[];
  }

  export interface CourseAccount<T = any>
    extends ImageableContentReference<T>, Core.WithRbacActions<Core.RbacActionsElearning> {
    confirmActive: boolean;
    courseId: number;
    courseItems?: Array<ScoAccount>;
    courseType: number;
    displayStatus: DisplayStatus;
    lastExecutionDate: number;
  }

  export interface ScoCourseAccount
    extends CourseAccount {
    courseItems: ScoAccount[];
  }

  export interface CurriculumAccount
    extends Core.WithRbacActions<Core.RbacActionsElearning> {
    accountStatus: Core.CurriculumAccountStatus;
    assignmentType: Core.DistAssignmentType;
    currentStartDate: number;
    curriculumId: number;
    curriculumItems: CurriculumItemAccount[];
    curriculumPathTitle: string;
    description: Translation;
    displayStatus: DisplayStatus;
    executionEndDate: number;
    firstValidSince: number;
    iteration: number;
    lastEndDate: number;
    lastValidUntil: number;
    licenceEnded: boolean;
    title: Translation;
  }

  export interface CurriculumItemAccount
    extends Core.WithRbacActions<Core.RbacActionsElearning> {
    attempts: number;
    attemptsAllowed: number;
    accountCreationDate: number;
    accountFinishDate: number;
    course: ScoCourseAccount;
    curriculumId: number;
    curriculumItemId: number;
    displayStatus: DisplayStatus;
    itemType: Core.CurriculumItemType;
    locked: boolean;
    orderIndex: number;
    targetId: number;
    targetType: Core.DistributableType;
    title: Translation;
  }

  export interface CertificateIteration {
    certBlocked: boolean;
    creation: number;
    end?: number;
    it: number;
    path: number;
    start: number;
    status?: string;
    validSince: number;
    validUntil?: number;
    currentIteration?: number;
  }

  export interface CurriculumSteering {
    currId: number;
    current: CertificateIteration;
    maxIteration: number;
    maySave: boolean;
    name: string;
    status: string;
    title: string;
    userId: number;
  }

  export interface CurriculumHistory {
    currId: number;
    history: Array<CertificateIteration>;
    isAllowedAsCertifier: boolean;
    latest: Array<CertificateIteration>;
    maySave: boolean;
    name: string;
    title: string;
    userId: number;
  }

  export interface CertificateAccountMinimumData {
    curriculumId: number;
    curid: number;        // mess with types
    iteration: number;
    validSince: number;
    validUntil: number;
  }

  export interface CertificateAccount
    extends Core.WithRbacActions<Core.RbacActionsElearning>, CertificateAccountMinimumData {
    adminChangeStatus: number;
    certTemplateId: number;
    certifiers: string;
    curriculumTitle: string;
    curriculum?: string;      // mess with types
    pathTitle: string;
    variationCounter: number;
    fourEyesCert: boolean;
    pathtitle: string;
    availableForUser?: boolean;
    templateAvailable: boolean;
  }

  export interface CourseAccountDetails
    extends CourseAccount<ContentActionTransients> {
    assignmentType: Core.DistAssignmentType;
    licenceEndDate: number;
    licenceEnded: boolean;
  }

  export interface CurriculumItemResponse {
    curriculum: CurriculumAccount;
    curriculumItem: CurriculumItemAccount;
  }
  export interface OfflineEventRowData {
    offlineContentId: number;
    offlineEventId: number;
    offlineEventTitle: Translation;
    offlineEventStatus: number;
    offlineEventStatusExamination: number;
    offlineEventStartDate: number;
    eventBookingUUID: string;
    eventScheduleInvitationStatus: string;
    attendanceDurationMinutes: number;
    attendanceDurationPercentage: number;
    eventBookingDate?: number;
    orderIndex: number;
  }

  export interface OfflineRowData {
    offlineContentId: number;
    offlineTitle: Translation;
    offlineEventTitle: Translation;
    offlineContentStatus: number;
    offlineContentStatusExamination: number;
    contentBookingUUID: string;
    offlineEvents: OfflineEventRowData[];
  }

  export interface UserDetailsResponse {
    certificates: CertificateAccount[];
    controllingUser: ControllingUser;
    courses: CourseAccountDetails[];
    curricula: CurriculumAccount[];
    userColumns: ColumnSettings[];
    offlineContents: OfflineRowData[];
    contentActions: Array<UserContentAction>;
  }

  export interface TabUser {
    label: string;
    userId: number;
  }

  export interface TabCurriculum
    extends TabUser {
    curriculumId: number;
  }

  export interface TabCourse
    extends TabUser {
    courseId: number;
  }

  export class CertificateAccountHeper {

    static CUSTOM_COMPLETION_CHANGED = 3;
    static NO_CERTIFICATE_ALLOWED = 0;
    static PDF_CERTIFICATE_UPLOADED = 4;
    static VALID_SINCE_CHANGED = 2;
    static VALID_UNTIL_CHANGED = 1;

    static isCertBlocked = (cert: CertificateAccount) => (cert.adminChangeStatus & (1 << CertificateAccountHeper.NO_CERTIFICATE_ALLOWED)) > 0;

    static isCertUploaded = (cert: CertificateAccount) => (cert.adminChangeStatus & (1 << CertificateAccountHeper.PDF_CERTIFICATE_UPLOADED)) > 0;

    static setCertUpload = (cert: CertificateAccount, uploaded: boolean) => {
      if ( uploaded ) {
        cert.adminChangeStatus |= (1 << CertificateAccountHeper.PDF_CERTIFICATE_UPLOADED);
      } else {
        cert.adminChangeStatus &= ~(1 << CertificateAccountHeper.PDF_CERTIFICATE_UPLOADED);
      }
    };

    static setLock(cert: CertificateAccount, locked: boolean) {
      if ( locked ) {
        cert.adminChangeStatus |= (1 << CertificateAccountHeper.NO_CERTIFICATE_ALLOWED);
      } else {
        cert.adminChangeStatus &= ~(1 << CertificateAccountHeper.NO_CERTIFICATE_ALLOWED);
      }
    }
  }

}
