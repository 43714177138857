<mat-icon *ngIf="content.objType === 'lms_curriculum'" class="type-icon"
          i18n-matTooltip="@@curriculum" matTooltip="Curriculum" matTooltipPosition="above"
          svgIcon="school"></mat-icon>
<mat-icon *ngIf="content.objType === 'lms_course'" class="type-icon"
          i18n-matTooltip="@@course" matTooltip="Course" matTooltipPosition="above"
          svgIcon="google-classroom"></mat-icon>
<mat-icon *ngIf="content.objType === 'lms_context'" class="type-icon"
          i18n-matTooltip="@@context" matTooltip="Context" matTooltipPosition="above"
          svgIcon="rag-context"></mat-icon>
<mat-icon *ngIf="content.objType === 'lms_vroom_group'" class="type-icon"
          i18n-matTooltip="@@virtual_classroom" matTooltip="Virtual Classroom" matTooltipPosition="above"
          svgIcon="webcam"></mat-icon>
<ng-container *ngIf="content.objType === 'lms_offlineCnt'">
  <mat-icon *ngIf="isVirco(content)" class="type-icon"
            i18n-matTooltip="@@virtual_classroom" matTooltip="Virtual Classroom" matTooltipPosition="above"
            svgIcon="webcam"></mat-icon>
  <mat-icon *ngIf="isSeminar(content)" class="type-icon"
            i18n-matTooltip="@@global_seminar" matTooltip="On-site event" matTooltipPosition="above"
            svgIcon="account-multiple"></mat-icon>
  <mat-icon *ngIf="isHybrid(content)" class="type-icon"
            i18n-matTooltip="@@global_hybrid_event" matTooltip="Hybrid event" matTooltipPosition="above"
            svgIcon="cast-education"></mat-icon>
</ng-container>
