import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
  selector: 'rag-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: [ './dialog-title.component.scss' ],
})
export class DialogTitleComponent<T> {

  @Input() closeResult: T;

  constructor() {
  }

}
