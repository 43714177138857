import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClassicReportModule } from './classic-report/classic-report.module';
import { ReportGeneratorV2Module } from './report-generator-v2/report-generator-v2.module';

@NgModule({
  imports: [
    ClassicReportModule,
    CommonModule,
  ],
  exports: [
    ClassicReportModule,
    ReportGeneratorV2Module,
  ],
})
export class ReportModule {
}
