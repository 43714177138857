import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GuardsCheckEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';


@Injectable({
  providedIn: 'root',
})
export class ModalDialog {

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter(evt => evt instanceof GuardsCheckEnd))
      // close all dialogs on navigation (after running guard checks)
      .pipe(tap(this.closeAll))
      .subscribe();
  }

  closeAll = () => {
    this.dialog.closeAll();
  };

  open<COMPONENT_TYPE, DATA_TYPE = any, RESULT_TYPE = any>(
    componentOrTemplateRef: ComponentType<COMPONENT_TYPE> | TemplateRef<COMPONENT_TYPE>,
    config?: MatDialogConfig<DATA_TYPE>,
  ): MatDialogRef<COMPONENT_TYPE, RESULT_TYPE> {
    if ( !config ) {
      config = {};
    }
    if ( !config.hasOwnProperty('panelClass') ) {
      config.panelClass = 'max-width-dialog-container';
    }
    if ( !config.hasOwnProperty('disableClose') ) {
      config.disableClose = true;
    }
    if ( !config.hasOwnProperty('closeOnNavigation') ) {
      config.closeOnNavigation = true;
    }
    if ( !config.hasOwnProperty('scrollStrategy') ) {
      config.scrollStrategy = this.overlay.scrollStrategies.reposition({ autoClose: false });
    }
    return this.dialog.open(componentOrTemplateRef, config);
  }

  openModal<COMPONENT_TYPE, DATA_TYPE = any, RESULT_TYPE = any>(
    componentOrTemplateRef: ComponentType<COMPONENT_TYPE> | TemplateRef<COMPONENT_TYPE>,
    config?: MatDialogConfig<DATA_TYPE>
  ): MatDialogRef<COMPONENT_TYPE, RESULT_TYPE> {
    // this.closeAll();
    return this.open(componentOrTemplateRef, config);
  }

}
