import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { CurriculumTitleModule } from '../../../component/elearning/curriculum-title/curriculum-title.module';
import { ImportantContentInfoModule } from '../../../component/elearning/important-content-info/important-content-info.module';
import { ToggleFullscreenModule } from '../../../component/elearning/toggle-fullscreen/toggle-fullscreen.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ContentOfflineModule } from '../content/content-offline/content-offline.module';
import { ContentLearnerCockpitComponent } from './content-learner-cockpit.component';
import { ContentOfflineDialogModule } from './content-offline-dialog/content-offline-dialog.module';
import { PartialItemListDialogModule } from './partial-item-list/partial-item-list-dialog.module';
import { LearnerCockpitHelpDialogModule } from './learner-cockpit-help-dialog/learner-cockpit-help-dialog.module';
import { LearnerCockpitContactDialogModule } from './learner-cockpit-contact-dialog/learner-cockpit-contact-dialog.module';


@NgModule({
  declarations: [ ContentLearnerCockpitComponent ],
  imports: [
    CommonModule,
    CurriculumTitleModule,
    ImportantContentInfoModule,
    BreadcrumbsModule,
    PipesModule,
    ToggleFullscreenModule,
    ContentOfflineModule,
    ContentOfflineDialogModule,
    LearnerCockpitHelpDialogModule,
    LearnerCockpitContactDialogModule,
    PartialItemListDialogModule,
    MatProgressSpinnerModule,
  ],
})
export class ContentLearnerCockpitModule {
}
