import {
  TableColumnDataType,
  TableControllerTypes,
} from 'src/app/component/table/table-controller/table-controller.types';
import { TableColumnBuilder } from 'src/app/component/table/table-column.builder';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { MergeHelper } from '../../../../../core/primitives/merge.helper';
import { UserModelControllingColumns } from '../../../../../component/table/table-controller/table-controller.columns';


export interface ExtOauth2UsersRow
  extends ControllingSingleUserTypes.ControllingUser {
  userInfoId: number;
  principalName: string;
  lastLogin: number;
}

export interface ExtOauth2UsersMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: ExtOauth2UsersMenuItemMap;
}

export interface ExtOauth2UsersMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap<ExtOauth2UsersRow> {
  principalName: TableControllerTypes.ColumnMenuItem<ExtOauth2UsersRow>;
  lastLogin: TableControllerTypes.ColumnMenuItem<ExtOauth2UsersRow>;
  actions: TableControllerTypes.ColumnMenuItem<ExtOauth2UsersRow>;
}

export const EXT_OAUTH2_USERS_TABLE_DATA: ExtOauth2UsersMenuData = {
  startWith: ['principalName', 'lastLogin', 'userId'],
  endWith: ['actions'],
  menuItems: {

    principalName: TableColumnBuilder.start<ExtOauth2UsersRow>()
      .withColumnId('principalName')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@admin_ext_oauth2_users_principalName:SSO Principal`)
      .withSelected()
      .withDisabled()
      .withPreserve()
      .build(),

    lastLogin: TableColumnBuilder.start<ExtOauth2UsersRow>()
      .withColumnId('lastLogin')
      .withType(TableColumnDataType.dateTime)
      .withTitle($localize`:@@admin_ext_oauth2_users_lastLogin:Last login`)
      .withSelected()
      .withDisabled()
      .withPreserve()
      .build(),

    userId: TableColumnBuilder.start(UserModelControllingColumns.userId)
      .withSelected()
      .withDisabled()
      .withPreserve()
      .build(),

    userEmployeeID: TableColumnBuilder.start(UserModelControllingColumns.userEmployeeID)
      .withSelected()
      .build(),
    userLogin: TableColumnBuilder.start(UserModelControllingColumns.userLogin)
      .withSelected()
      .build(),
    userFirstname: TableColumnBuilder.start(UserModelControllingColumns.userFirstname)
      .withSelected()
      .build(),
    userLastname: TableColumnBuilder.start(UserModelControllingColumns.userLastname)
      .withSelected()
      .build(),
    userEmail: TableColumnBuilder.start(UserModelControllingColumns.userEmail)
      .withSelected()
      .build(),
    userLocationDesc: TableColumnBuilder.start(UserModelControllingColumns.userLocationDesc)
      .withSelected()
      .build(),

    ...MergeHelper.cloneDeep(UserModelControllingColumns, column => ![
      'userId',
      'userEmployeeID',
      'userFirstname',
      'userLastname',
      'userEmail',
      'userLocationDesc',
      'userLogin',
      // inactive users are filtered by default
      'userActive',
    ].includes(column)),

    actions: TableColumnBuilder.start<ExtOauth2UsersRow>()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),

  },
};
