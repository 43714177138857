import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../component/table/table-controller/table-controller.types';
import { STRUCTURAL_COLUMN, TableColumnBuilder } from '../../../../component/table/table-column.builder';

export interface AdminQAListColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: AdminQAListColumnMenuItemMap;
}

export interface AdminQAListColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  batchCheckbox: TableControllerTypes.ColumnMenuItem;
  actions: TableControllerTypes.ColumnMenuItem;
  uuid: TableControllerTypes.ColumnMenuItem;
  creationDate: TableControllerTypes.ColumnMenuItem;
  questionAbstract: TableControllerTypes.ColumnMenuItem;
  status: TableControllerTypes.ColumnMenuItem;
  processingDate: TableControllerTypes.ColumnMenuItem;
  tags: TableControllerTypes.ColumnMenuItem;
  answerText: TableControllerTypes.ColumnMenuItem;
  questionText: TableControllerTypes.ColumnMenuItem;
}

export const ADMIN_QA_LIST_DEFAULT_MENU_COLUMNS: AdminQAListColumnMenuData = {
  startWith: ['batchCheckbox'],
  endWith: ['actions'],
  menuItems: {

    batchCheckbox: TableColumnBuilder.start(STRUCTURAL_COLUMN)
      .withColumnId('batchCheckbox')
      .build(),

    creationDate: TableColumnBuilder.start()
      .withColumnId('creationDate')
      .withTitle($localize`:@@qa_column_creationDate:Date of receipt`)
      .withType(TableColumnDataType.dateTime)
      .withSelected()
      .build(),

    questionAbstract: TableColumnBuilder.start()
      .withColumnId('questionAbstract')
      .withTitle($localize`:@@global_contribution_title:Contribution title`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    status: TableColumnBuilder.start()
      .withColumnId('status')
      .withTitle($localize`:@@global_status:Status`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        NEW: $localize`:@@admin_offline_event_invitation_status_new:New`,
        DRAFT: $localize`:@@payment_status_Processing:Processing`,
        PUBLISHED: $localize`:@@global_published:Published`,
        NOT_PUBLISHED: $localize`:@@status_not_published:Not Published`,
        ARCHIVED: $localize`:@@status_archived:Archived`,
      })
      .withSelected()
      .withFilterDefaultValue('NEW')
      .build(),

    processingDate: TableColumnBuilder.start()
      .withColumnId('processingDate')
      .withTitle($localize`:@@global_processing_date:Processing date`)
      .withType(TableColumnDataType.dateTime)
      .withSelected()
      .build(),

    uuid: TableColumnBuilder.start()
      .withColumnId('uuid')
      .withTitle('UUID')
      .withType(TableColumnDataType.text)
      .withSelected(false)
      .build(),

    tags: TableColumnBuilder.start()
      .withColumnId('tags')
      .withTitle('Tags')
      .withType(TableColumnDataType.tags)
      .withSelected(false)
      .build(),

    answerText: TableColumnBuilder.start()
      .withColumnId('answerText')
      .withTitle($localize`:@@global_answer:Answer`)
      .withType(TableColumnDataType.text)
      .withSelected(false)
      .build(),

    questionText: TableColumnBuilder.start()
      .withColumnId('questionText')
      .withTitle($localize`:@@global_contribution:Contribution`)
      .withType(TableColumnDataType.text)
      .withSelected(false)
      .build(),

    actions: TableColumnBuilder.start()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build()
  }
};
