import { EventEmitter, Injectable } from '@angular/core';
import { CachedSubject } from './cached-subject';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ComEvents, Core } from './core.types';

@Injectable({
  providedIn: 'root',
})
export class ComponentCommunicationService {

  readonly addWidgetChange$: Observable<string>;
  readonly reloadLayout$: Observable<string>;
  readonly resetWidgetsClick$: Observable<string>;
  readonly userEvent: Observable<Core.UserEvent>;
  readonly comEvents$: Observable<ComEvents.Event>;

  private _addWidgetChange = new EventEmitter<string>(true);
  private _reloadLayout = new EventEmitter<string>(true);
  private _resetWidgetsClick = new EventEmitter<string>(true);
  private _userEvent = new EventEmitter<Core.UserEvent>();
  private _comEvents = new EventEmitter<ComEvents.Event>();

  constructor() {
    this.addWidgetChange$ = this._addWidgetChange.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
    this.reloadLayout$ = this._reloadLayout.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
    this.resetWidgetsClick$ = this._resetWidgetsClick.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
    this.userEvent = this._userEvent.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
    this.comEvents$ = this._comEvents.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
  }

  addWidget = (widgetUUID: string): void => {
    this._addWidgetChange.next(widgetUUID);
    // this._addWidgetChange.reset();
  };

  forceReloadLayout(pageId: string) {
    this._reloadLayout.next(pageId);
    // this._reloadLayout.reset();
  }

  resetWidgets(pageId: string): void {
    this._resetWidgetsClick.next(pageId);
  }

  postUserEvent(event: Core.UserEvent) {
    this._userEvent.next(event);
  }

  postEvent(event: ComEvents.Event) {
    this._comEvents.next(event);
  }

}
