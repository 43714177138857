import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { PivotFilterAccountDisplayStatusComponent } from './pivot-filter-account-display-status/pivot-filter-account-display-status.component';
import { PivotFilterDateComponent } from './pivot-filter-date/pivot-filter-date.component';
import { PivotFilterDropdownComponent } from './pivot-filter-dropdown/pivot-filter-dropdown.component';
import { PivotFilterNumberComponent } from './pivot-filter-number/pivot-filter-number.component';
import { PivotFilterTextComponent } from './pivot-filter-text/pivot-filter-text.component';
import { AbstractPivotFilterComponent } from './abstract-pivot-filter.component';
import { CurrencyModule } from '../../core/financials/currency/currency.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    AbstractPivotFilterComponent,
    PivotFilterAccountDisplayStatusComponent,
    PivotFilterDateComponent,
    PivotFilterDropdownComponent,
    PivotFilterNumberComponent,
    PivotFilterTextComponent,
  ],
  exports: [
    PivotFilterAccountDisplayStatusComponent,
    PivotFilterDateComponent,
    PivotFilterDropdownComponent,
    PivotFilterNumberComponent,
    PivotFilterTextComponent,
  ],
  imports: [
    CommonModule,
    CurrencyModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    PipesModule,
  ],
})
export class PivotFilterModule {
}
