<div class="design-wrapper">
  <mat-tab-group>

    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n="@@badge_templates">Badge Templates</span>
      </ng-template>
      <rag-admin-gamification-list></rag-admin-gamification-list>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n="@@global_settings">Settings</span>
      </ng-template>
      <rag-admin-gamification-settings></rag-admin-gamification-settings>
    </mat-tab>

  </mat-tab-group>
</div>
