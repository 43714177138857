import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OfflineContent } from 'src/app/core/admin-offline.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  selector: 'rag-location',
  templateUrl: './location.component.html',
  styleUrls: [ './location.component.scss' ],
})
export class LocationComponent
  implements OnInit {

  @Input() location: OfflineContent.Location;
  @Input() showIcon = true;

  constructor() {
  }

  ngOnInit() {
  }

}
