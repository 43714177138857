import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BadgeTemplateDetails } from '../../../../core/gamification/gamification.types';
import {  Observable, combineLatest } from 'rxjs';
import { AdminGamificationService } from '../../../../core/gamification/admin-gamification.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGamificationDetailsResolver
  implements Resolve<BadgeTemplateDetails> {

  constructor(
    private adminGamificationService: AdminGamificationService,
  ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BadgeTemplateDetails> {
    const uuid = route.params['badgeTemplateUUID'] ?? '';
    if ((uuid === '0') || (uuid === '')) {
      return this.adminGamificationService.createEmptyBadgeTemplate()
        .pipe(map(template => {
          const response: BadgeTemplateDetails = {
            usages: [],
            badgeTemplate: template,
          }
          return response;
        }));
    }
    return combineLatest([this.adminGamificationService.getBadgeTemplate(uuid), this.adminGamificationService.getBadgeTemplateUsages(uuid)])
      .pipe(map(([template, usages]) => {
        const response: BadgeTemplateDetails = {
          usages: usages,
          badgeTemplate: template,
        }
        return response;
      }));
  }
}
