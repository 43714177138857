import { ActivatedRouteSnapshot } from '@angular/router';


export class RouteSnapshotHelper {

  static getDataValue<T>(route: ActivatedRouteSnapshot, key: string, conversion: (value: any) => T): T {
    if ( route == null ) {
      throw Error(key + ' not found');
    }

    const result = conversion(route.data?.[key]);
    if ( result == null ) {
      const parent = RouteSnapshotHelper.getParent(route);
      return RouteSnapshotHelper.getDataValue(parent, key, conversion);
    }
    return result;
  }

  static getParamNumber(route: ActivatedRouteSnapshot, key: string, type: 'param' | 'queryParam' = 'param'): number {
    return RouteSnapshotHelper.getParamValue(route, key, type, value => {
      const result = parseInt(value, 10);
      return isNaN(result) ? null : result;
    });
  }

  static getParamValue<T>(route: ActivatedRouteSnapshot, key: string, type: 'param' | 'queryParam',
                          conversion: (value: string) => T): T {
    if ( route == null ) {
      throw Error(key + ' not found');
    }

    const map = type === 'param' ? route.paramMap : route.queryParamMap;
    const result = conversion(map.get(key));
    if ( result == null ) {
      const parent = RouteSnapshotHelper.getParent(route);
      return RouteSnapshotHelper.getParamValue(parent, key, type, conversion);
    }
    return result;
  }

  private static getParent(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if ( (route == null) || (route.parent === route) ) {
      return null;
    }
    return route.parent;
  }

}
