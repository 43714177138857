<form [formGroup]="form">
  <rag-page-header>
    <div class="page-header-title" i18n="@@global_self_registration">Self registration</div>
    <div class="page-header-buttons">
      <button
        [disabled]="saveButtonDisabled$ | async"
        color="primary"
        mat-flat-button
        (click)="onSave()"
      >
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </rag-page-header>

  <div class="display-flex">
    <div class="section split-50">
      <div class="split-left">
        <h4 class="headline" i18n="@@global_self_registration_fields">Self registration fields</h4>
        <mat-list *ngIf="selectedAttributes?.length > 0">
          <mat-list-item *ngFor="let field of selectedAttributes; let i = index">
            <div [ngClass]="{'blue': isUnsavedAttribute(field)}">
              <span class="attribute-position">{{i + 1}}:</span>
              <span class="field-title-width">{{field.label | localeText}}</span>
              <span *ngIf="field.required" class="required-marker">*</span>
              <span *ngIf="getGroupName(field) as title" class="read-only">{{title}}</span>
              <span *ngIf="!field.updateLearner" class="read-only" i18n="@@global_read_only">read only</span>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="self-reg-actions">
          <button
            type="button"
            mat-flat-button
            color="primary"
            (click)="onChangeSelectedAttributes()"
          >
            <span i18n="@@admin_self_reg_change_selections">Change selection</span>
          </button>
        </div>
      </div>

      <div class="split-right general-settings">
        <h4 class="headline" i18n="@@event_edit_general_settings">General Settings</h4>
        <mat-form-field class="checkbox" appearance="outline">
          <input matInput type="hidden"/>
          <mat-checkbox formControlName="selfRegistrationEnabled">
            <span i18n="@@admin_self_reg_enable">Enable self registration</span>
          </mat-checkbox>
          <mat-hint i18n="@@admin_self_reg_enable_hint">
            This setting generally allows automatic creation of user account. This may be done by the user themselves,
            or by signing in using one of the single-sign-on mechanisms.
          </mat-hint>
        </mat-form-field>

        <mat-form-field
          *ngIf="userGroupNames?.length > 0"
          class="default-group"
          appearance="outline"
        >
          <mat-label i18n="@@admin_self_reg_default_usergroup">Default user group</mat-label>
          <mat-select formControlName="selfRegistrationUserGroupId">
            <mat-option [value]="null">
              - <span i18n="@@global_none_selected">None selected</span> -
            </mat-option>

            <mat-option
              *ngFor="let userGroup of userGroupNames"
              [value]="userGroup.id"
            >{{userGroup.title}}</mat-option>
          </mat-select>
          <mat-hint i18n="@@admin_self_reg_usergroup_hint">
            New users will be added automatically to the selected user group.
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="section">
      <h4 class="headline"
          i18n="@@global_gtc_and_privacy_policy">General Terms and Conditions & Privacy Policy<br/></h4>

      <div class="text">
         <span
           i18n="@@global_gtc_and_privacy_policy_text"
         >
           Here you can enter either a file or an external link for
           the General Terms and Conditions / Privacy Policy.<br>
           If a privacy policy or general terms and conditions are stored,
           they must be accepted during self-registration.
        </span>
      </div>

      <div class="files">
        <rag-file
          (removeFile)="onRemovePicture('termsConditionFile')"
          (selectedFile)="onSelectedPicture($event, 'termsConditionFile')"
          [caption]="getCaption('termsCondition')"
          [fileInfo]="termsConditionFileInfo"
          [file]="getAGBFile()"
          class="file"
          [disabled]="isAGBFileDisabled()"
          [accept]="'application/pdf'"
        ></rag-file>

        <ng-container
          [ngTemplateOutlet]="tplInputUrl"
          [ngTemplateOutletContext]="{formControlName: 'termsConditionUrl'}"
        ></ng-container>
      </div>

      <div class="files">
        <rag-file
          (removeFile)="onRemovePicture('privacyPolicyFile')"
          (selectedFile)="onSelectedPicture($event, 'privacyPolicyFile')"
          [caption]="getCaption('privacyPolicy')"
          [fileInfo]="privacyPolicyFileInfo"
          [file]="getPrivacyFile()"
          class="file"
          [disabled]="isPrivacyFileDisabled()"
          [accept]="'application/pdf'"
        ></rag-file>

        <ng-container
          [ngTemplateOutlet]="tplInputUrl"
          [ngTemplateOutletContext]="{formControlName: 'privacyPolicyUrl'}"
        ></ng-container>
      </div>

    </div>
  </div>

  <ng-template #tplInputUrl let-formControlName="formControlName">
    <mat-form-field class="url" appearance="outline">
      <mat-label>{{getPlaceholder(formControlName)}}</mat-label>

      <input
        matInput
        [formControlName]="formControlName"
      />

      <mat-hint>
        <span i18n="@@ext_login_url_http_required">HTTP scheme is required</span>:
        <span class="gap">http:// </span>
        <span class="gap" i18n="@@global_or">or</span>
        <span class="gap"> https://</span>
      </mat-hint>

      <mat-error
        *ngIf="hasError(formControlName, 'pattern')"
      >
        <span i18n="@@error_url_pattern">Invalid URL pattern</span>
      </mat-error>
    </mat-form-field>
  </ng-template>

</form>
