<rag-dialog-header>
  <span i18n="@@global_information">Information</span>
</rag-dialog-header>

<div matDialogContent>
  <span>{{dialogText}}</span>
  <ul>
<!--    todo -->
    <ng-container *ngFor="let item of iterableData">
      <li>
        <a [routerLink]="item.url">
          <span>{{item.eventTitle}}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>

<div matDialogActions>
  <button
    type="button"
    color="primary"
    mat-flat-button
    i18n="@@global_close"
    [matDialogClose]="null"
  >Close</button>
</div>
