import { Component, Input, OnDestroy } from '@angular/core';
import { debounceTime, take, tap } from 'rxjs/operators';
import {
  EventAccountStatusEnum,
  LearnerEventAccountView,
  OfflineInvitationConstants,
} from '../../core/learner-account/learner-account.types';
import { OfflineContent } from '../../core/admin-offline.types';
import { ContentOfflineService } from '../../route/user/content/content-offline/content-offline.service';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { InfoService } from 'src/app/core/info/info.service';
import { CancelButton, MessageKey, OkButton } from 'src/app/core/info/info.types';
import { Content } from 'src/app/core/content/content.types';
import * as moment from 'moment';
import { ViewHelper } from 'src/app/core/view-helper';


@Component({
  selector: 'rag-content-offline-invitation',
  templateUrl: './content-offline-invitation.component.html',
  styleUrls: [ './content-offline-invitation.component.scss' ],
})
export class ContentOfflineInvitationComponent
  implements OnDestroy {

  @Input() embedded = false;
  @Input() parentContent: Content = null;

  canCancelRequest = false;
  canChangeStatus = false;
  confirmDeclineShow = false;
  confirmDeclineValue: string;
  disableChange = true;
  invitationStatusShow = false;
  invitationStatusValue: OfflineInvitationConstants;
  offlineContentId: number;
  offlineEventId: number;
  showCancelRequest = false;
  showEventActionMenu = false;
  showEventIcsExport = false;
  showResponseRequired = false;

  private _offlineContent: OfflineContent.Event;
  private _offlineEvent: OfflineContent.EventSchedule;
  private _offlineEventAccount: LearnerEventAccountView;

  constructor(
    private contentOfflineService: ContentOfflineService,
    private infoService: InfoService,
  ) {
    subscribeUntilDestroyed(this.contentOfflineService.inputDisabled$
      .pipe(debounceTime(150))
      .pipe(tap(disabled => this.disableChange = disabled)), this);
  }

  get offlineContent(): OfflineContent.Event {
    return this._offlineContent;
  }

  @Input()
  set offlineContent(value: OfflineContent.Event) {
    const doUpdate = value && (this._offlineContent !== value);
    this._offlineContent = value;
    if ( !doUpdate ) {
      return;
    }

    // todo need to check if this really covers all cases
    this.canChangeStatus = value.assignmentModeForPrincipal === 'admin';
    // @see com.reflact.sphere.letstrain.offline.OfflineContentAction#cancelOfflineEventRequest
    this.canCancelRequest = value.assignmentModeForPrincipal === 'wish';
    this.updateAvailableInteractions();
  }

  get offlineEvent(): OfflineContent.EventSchedule {
    return this._offlineEvent;
  }



  @Input()
  set offlineEvent(value: OfflineContent.EventSchedule) {
    const doUpdate = value && (this._offlineEvent !== value);
    this._offlineEvent = value;
    if ( !doUpdate ) {
      return;
    }

    this.offlineContentId = value.contentId;
    this.offlineEventId = value.id;

    const viewData = ViewHelper.getViewData(value);
    viewData.eventDateMoment = moment(value.eventDate);
    viewData.eventDateUntilMoment = moment(value.eventDateUntil);

    this.showEventIcsExport = value.eventDate && (value.eventDate <= value.eventDateUntil);
    this.updateAvailableInteractions();
  }

  get offlineEventAccount(): LearnerEventAccountView {
    return this._offlineEventAccount;
  }

  @Input()
  set offlineEventAccount(value: LearnerEventAccountView) {
    const doUpdate = value && (value !== this._offlineEventAccount);
    this._offlineEventAccount = value;
    if ( !doUpdate ) {
      return;
    }
    this.disableChange = true;
    this.resetStatus();

    this.invitationStatusShow = Object.values(OfflineInvitationConstants).includes(value.invitationStatus);
    this.invitationStatusValue = value.invitationStatus;

    // @see com.reflact.sphere.elearning.web.EacademyLearnAccountAction#updateStatus
    switch ( value.invitationStatus ) {
      case OfflineInvitationConstants.INV_STATE_INVITED:
      case OfflineInvitationConstants.INV_STATE_REMINDED:
        this.confirmDeclineValue = null;
        this.confirmDeclineShow = this.showResponseRequired = true;
        break;
      case OfflineInvitationConstants.INV_STATE_ACK:
        this.confirmDeclineValue = 'ack';
        this.confirmDeclineShow = true;
        break;
      case OfflineInvitationConstants.INV_STATE_DEC:
        this.confirmDeclineValue = 'dec';
        this.confirmDeclineShow = true;
        break;
      case OfflineInvitationConstants.INV_STATE_CANCELLED:  // INV_STATE_CANCELLED will show invitation status without choices
      case OfflineInvitationConstants.VIA_LINK:
      case OfflineInvitationConstants.VIA_FRONTEND:
      default:
        // choices are only available for invitationStatus in ('inv','rem','ack','dec')
        break;
    }

    // @see com.reflact.sphere.letstrain.offline.OfflineContentAction#cancelOfflineEventRequest
    this.showCancelRequest = (value.status === EventAccountStatusEnum.DESIRED);
    this.updateAvailableInteractions();

    setTimeout(() => this.disableChange = false);
  }

  doCancelRequest(): void {
    this.infoService
      .showConfirm(MessageKey.OFFLINE_CNT.LEARNER.EVENT_BOOKING_CANCEL, OkButton | CancelButton)
      .subscribe(button => {
        if ( button === OkButton ) {
          this.changeStatus(OfflineInvitationConstants.INV_STATE_CANCELLED);
        }
      });
  }

  doChangeConfirmValue(value: OfflineInvitationConstants) {
    if ( this.disableChange || (value == null) ) {
      return;
    }
    this.changeStatus(value);
  }

  doDownloadEvent(): void {
    this.contentOfflineService.downloadEvent(this._offlineContent, this._offlineEvent)
      .pipe(take(1))
      .subscribe();
  }

  urlForOfflineContent() {
    if (this.parentContent != null) {
      return '/content/' + this.parentContent.id + '/offline/' + this.offlineContentId;
    }
    return '/run/offline/' + this.offlineContentId;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  noEventsYet() {
    return this.offlineContent.offlineEvents?.length === 0 && !this.shouldDisplayEvents();
  }

  isBookingPossible() {
    return this.canChangeStatus && this.confirmDeclineShow;
  }

  isSingleDay() {
    const viewData = ViewHelper.getViewData(this.offlineEvent);
    return viewData?.eventDateMoment.isSame(viewData.eventDateUntilMoment, 'day');
  }

  atDay() {
    const viewData = ViewHelper.getViewData(this.offlineEvent);
    return viewData?.eventDateMoment.format('L');
  }

  atEndDay() {
    const viewData = ViewHelper.getViewData(this.offlineEvent);
    return viewData?.eventDateUntilMoment.format('L');
  }

  atTime() {
    const viewData = ViewHelper.getViewData(this.offlineEvent);
    return viewData?.eventDateMoment.format('LT');
  }

  atEndTime() {
    const viewData = ViewHelper.getViewData(this.offlineEvent);
    return viewData?.eventDateUntilMoment.format('LT');
  }

  shouldDisplayEvents() {
    if (this._offlineEventAccount != null) {
      const viewData = ViewHelper.getViewData(this.offlineEvent);
      return viewData?.eventDateMoment != null;
    }
    return false;
  }

  private changeStatus(status: OfflineInvitationConstants): void {
    this.contentOfflineService
      .changeStatus(this.offlineContentId, this.offlineEventId, status)
      .subscribe();
  }

  private resetStatus(): void {
    this.confirmDeclineShow = false;
    this.confirmDeclineValue = null;
    this.invitationStatusShow = false;
    this.invitationStatusValue = null;
    this.showCancelRequest = false;
    this.showResponseRequired = false;
  }

  private updateAvailableInteractions(): void {
    this.showEventActionMenu = this.showEventIcsExport || (this.canCancelRequest && this.showCancelRequest);
  }

}
