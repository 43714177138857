import * as deepmerge from 'deepmerge';
import { DeepPartial } from './primitives.types';


export class MergeHelper {

  /**
   * Create a deep clone of the object. If the given object is null, the function will return null as well.<br/><br/>
   *
   * <b>WARNING: do not try to clone class instances / only use interfaces! (they will lose their types)
   *
   * @param obj the source object to clone
   * @param filter return false to remove an attribute from the result. Any other return value will keep it.
   */
  static cloneDeep<T>(
    obj: T,
    filter?: (key: string, value: any) => boolean,
  ): T {
    if ( obj == null ) {
      return null;
    }

    if ( typeof (obj) !== 'object' ) {
      // no need to clone -> primitive object
      return obj;
    }

    const result = deepmerge.all([ obj ]);
    if ( (filter !== undefined) && (typeof (result) === 'object') ) {
      for ( const key of Object.keys(result) ) {
        if ( filter(key, result[key]) === false ) {
          delete result[key];
        }
      }
    }
    return result as T;
  }

  /**
   * Compare two arrays
   *
   * @see https://stackoverflow.com/a/14853974
   */
  static equalsArray<T>(a: T[], b: T[]): boolean {
    if ( a === b ) {
      // pointers are identical -> true
      return true;
    }

    if ( a?.length !== b?.length ) {
      // lengths are different (one of both may be null) -> not identical
      return false;
    }

    const length = a.length;
    for ( let i = 0; i < length; i++ ) {
      if ( a[i] !== b[i] ) {
        // one place is different -> not identical
        return false;
      }
    }

    // no differences found -> contents are identical
    return true;
  }

  /**
   * Create a copy that contains all attributes from all parameters.<br/><br/>
   *
   * <b>WARNING: null and undefined in any "b" do override the previous values!</b>
   * (skip, or maybe delete them first)<br/><br/>
   *
   * <b>WARNING #2: do not try to clone class instances / only use interfaces! (they will lose their types)
   *
   * @param a The first object needs to contain all attributes that are required by the type.
   * @param b Any number of additional options that should be merged with the first one. Null-ish objects are ignored.
   */
  static mergeDeep<T>(a: T, ...b: DeepPartial<T>[]): T {

    // remove any null-ish values
    b = b?.filter(entry => entry != null);

    if ( b?.length > 0 ) {

      // we have some valid additional objects
      return deepmerge.all([
        a,
        ...b,
      ]) as T;
    } else {

      // if only one (valid) argument is given we do a simple cloneDeep
      return MergeHelper.cloneDeep(a);
    }
  }

}
