import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_OPTIONS, DoughnutChartOptions } from './doughnut-chart.types';
import { MergeHelper } from '../../../core/primitives/merge.helper';

@Component({
  selector: 'rag-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: [ './doughnut-chart.component.scss' ],
})
export class DoughnutChartComponent {

  @Output()
  downloadClicked = new EventEmitter<void>();

  modelOptions: DoughnutChartOptions = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS);
  private _options: DoughnutChartOptions = {};
  private _optionsChanged = new EventEmitter<DoughnutChartOptions>();
  @Output()
  optionsChanged = this._optionsChanged.asObservable();

  constructor() {
  }

  get options(): DoughnutChartOptions {
    return this._options;
  }

  @Input()
  set options(value: DoughnutChartOptions) {
    this._options = value;
    this.modelOptions = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS, value);
  }

  emitOptions(): void {
    this._optionsChanged.emit(MergeHelper.mergeDeep({}, this.modelOptions));
  }

}
