<!-- MAIN VIEW CONTAINER -->
<div class="container">

  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@register_title">Register new account</span>
      <button *ngIf="environment.trainApi.includes('/ilearn24')" #tooltip="matTooltip"
              mat-icon-button
              [matTooltip]="getTooltip()"
              matTooltipClass="wider-tooltip"
              type="button">
        <mat-icon svgIcon="information-outline"></mat-icon>
      </button>
    </div>
  </rag-page-header>

  <div class="table-wrapper">
    <div *ngIf="registrationInfo && (!requestIsSent || formGroup.invalid)" class="box">
      <form (ngSubmit)="tryRegister()" [formGroup]="formGroup">
        <div class="hint hintLogin"></div>
        <div class="flx-container">
          <!-- Left container -->
          <div class="left">
            <div>
              <ng-container *ngFor="let fieldInfoKey of registrationModeFieldsLeft">

                <ng-container [ngSwitch]="fieldTypeToComponentTypeMapping[registrationInfo.model[fieldInfoKey].type]">

                  <ng-container *ngSwitchCase="'text'">

                    <div class="row">
                      <mat-form-field appearance="outline">
                        <mat-label>{{registrationInfo.model[fieldInfoKey].label | localeText}}</mat-label>
                        <input
                          (input)="onTextFieldChange($event)"
                          [email]="registrationInfo.model[fieldInfoKey].type === 'email'"
                          formControlName="{{fieldInfoKey}}"
                          matInput
                          name="{{fieldInfoKey}}"
                          required="{{registrationInfo.model[fieldInfoKey].required}}"
                          type="{{fieldInputTypeForField(registrationInfo.model[fieldInfoKey])}}">

                        <!-- <mat-icon
                          matSuffix
                          svgIcon="information-outline"
                          *ngIf="registrationInfo.model[fieldInfoKey].info"></mat-icon> -->
                        <mat-error>
                          <span *ngIf="registrationInfo.model[fieldInfoKey].type === 'email' && errors.emailNotValid" i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
                          <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                          <span *ngIf="hasError(fieldInfoKey, 'server')">{{errorForField(fieldInfoKey, 'server')}}</span>
                        </mat-error>
                      </mat-form-field>

                      <rag-info-tooltip-button
                        class="hint"
                        [info]="registrationInfo.model[fieldInfoKey]"
                      ></rag-info-tooltip-button>

                    </div>

                    <div
                      *ngIf="registrationInfo.model[fieldInfoKey].type === 'email' && registrationInfo.hasEmailConfirm"
                      class="row">
                      <!-- duplicated email field -->
                      <mat-form-field appearance="outline">
                        <mat-label i18n="@@register_email_confirm">Confirm e-mail address</mat-label>
                        <input (input)="onTextFieldChange($event)"
                          [email]="true"
                          formControlName="{{fieldInfoKey}}2"
                          inputmode="email"
                          matInput
                          name="{{fieldInfoKey}}2"
                          required="true"
                          type="email">

                        <mat-error>
                          <span *ngIf="hasError(fieldInfoKey + '2', 'required')" i18n="@@validation_required">This field is required</span>
                          <span *ngIf="this.errors.emailNotMatch" i18n="@@validation_email_not_match">The emails do not match!</span>
                        </mat-error>
                      </mat-form-field>
                      <div class="hint"></div>
                    </div>

                  </ng-container>
                  <!-- End Text -->
                  <!-- Date -->

                  <div *ngSwitchCase="'date'" class="row">
                    <mat-form-field appearance="outline">
                      <mat-label>{{registrationInfo.model[fieldInfoKey].label | localeText}}</mat-label>
                      <mat-datepicker #datePicker></mat-datepicker>
                      <input
                        [matDatepicker]="datePicker"
                        [max]="fieldInfoKey === 'dateOfBirth' ? maxBirthDate : null"
                        formControlName="{{fieldInfoKey}}"
                        matInput
                        required="{{registrationInfo.model[fieldInfoKey].required}}">

                      <mat-datepicker-toggle
                        [for]="datePicker"
                        matSuffix></mat-datepicker-toggle>

                      <mat-error>
                        <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                        <span *ngIf="hasError(fieldInfoKey, 'server')">{{errorForField(fieldInfoKey, 'server')}}</span>
                      </mat-error>
                    </mat-form-field>

                    <div class="hint">
                      <button #tooltip="matTooltip"
                              *ngIf="registrationInfo.model[fieldInfoKey].hasInfo && !!registrationInfo.model[fieldInfoKey].info"
                              [matTooltip]="registrationInfo.model[fieldInfoKey].info"
                              mat-icon-button
                              type="button">
                        <mat-icon class="mat-icon" svgIcon="information-outline"></mat-icon>
                      </button>
                    </div>

                  </div>

                  <!-- End Date -->
                  <!-- Dropdown -->
                  <div *ngSwitchCase="'dropdown'" class="row">

                    <mat-form-field appearance="outline">
                      <mat-label class="selectLabel">
                        {{registrationInfo.model[fieldInfoKey].label | localeText}}
                      </mat-label>
                      <mat-select
                        class="classic"
                        formControlName="{{fieldInfoKey}}"
                        required="{{registrationInfo.model[fieldInfoKey].required}}">
                        <mat-option
                          *ngFor="let option of registrationInfo.model[fieldInfoKey].options"
                          value="{{option.value}}">
                          {{option.label | localeText}}
                        </mat-option>
                      </mat-select>

                      <mat-error>
                        <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                      </mat-error>
                    </mat-form-field>

                    <div class="hint">
                      <button #tooltip="matTooltip"
                              *ngIf="registrationInfo.model[fieldInfoKey].hasInfo && !!registrationInfo.model[fieldInfoKey].info"
                              [matTooltip]="registrationInfo.model[fieldInfoKey].info"
                              mat-icon-button
                              type="button">
                        <mat-icon svgIcon="information-outline"></mat-icon>
                      </button>
                    </div>
                  </div>
                  <!-- End DropDown -->
                </ng-container>
              </ng-container>
            </div>
          </div>

          <!-- Right container -->
          <div class="right">
            <div>
              <ng-container *ngFor="let fieldInfoKey of registrationModeFieldsRight">

                <ng-container [ngSwitch]="fieldTypeToComponentTypeMapping[registrationInfo.model[fieldInfoKey].type]">

                  <ng-container *ngSwitchCase="'text'">
                    <div class="row">
                      <mat-form-field appearance="outline">
                        <mat-label>{{registrationInfo.model[fieldInfoKey].label | localeText}}</mat-label>
                        <input
                          (input)="onTextFieldChange($event)"
                          [email]="registrationInfo.model[fieldInfoKey].type === 'email'"
                          formControlName="{{fieldInfoKey}}"
                          matInput
                          name="{{fieldInfoKey}}"
                          required="{{registrationInfo.model[fieldInfoKey].required}}"
                          type="{{fieldInputTypeForField(registrationInfo.model[fieldInfoKey])}}">

                        <!-- <mat-icon
                          matSuffix
                          svgIcon="information-outline"
                          *ngIf="registrationInfo.model[fieldInfoKey].info"></mat-icon> -->

                        <mat-error>
                          <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                          <span *ngIf="hasError(fieldInfoKey, 'email')" i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
                          <span *ngIf="hasError(fieldInfoKey, 'server')">{{errorForField(fieldInfoKey, 'server')}}</span>
                        </mat-error>
                      </mat-form-field>

                      <div class="hint">
                        <button #tooltip="matTooltip"
                                *ngIf="registrationInfo.model[fieldInfoKey].hasInfo && !!registrationInfo.model[fieldInfoKey].info"
                                [matTooltip]="registrationInfo.model[fieldInfoKey].info"
                                mat-icon-button
                                type="button">
                          <mat-icon svgIcon="information-outline"></mat-icon>
                        </button>
                      </div>
                    </div>
                    <div
                      *ngIf="registrationInfo.model[fieldInfoKey].type === 'email' && registrationInfo.hasEmailConfirm"
                      class="row">
                      <!-- duplicated email field -->
                      <mat-form-field appearance="outline">
                        <mat-label i18n="@@register_email_confirm">Confirm e-mail address</mat-label>
                        <input
                          [email]="true"
                          formControlName="{{fieldInfoKey}}2"
                          inputmode="email"
                          matInput
                          name="{{fieldInfoKey}}2"
                          required="true"
                          type="email">

                        <mat-error>
                          <span *ngIf="hasError(fieldInfoKey + '2', 'required')" i18n="@@validation_required">This field is required</span>
                          <span *ngIf="hasError(fieldInfoKey + '2', 'email')" i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
                          <span *ngIf="hasError(fieldInfoKey + '2', 'not_match')" i18n="@@validation_email_not_match">The emails do not match!</span>
                        </mat-error>
                      </mat-form-field>
                      <div class="hint"></div>
                    </div>

                  </ng-container>
                  <!-- End Text -->
                  <!-- Date -->

                  <div *ngSwitchCase="'date'" class="row">
                    <mat-form-field appearance="outline">
                      <mat-label>{{registrationInfo.model[fieldInfoKey].label | localeText}}</mat-label>
                      <mat-datepicker #datePicker></mat-datepicker>
                      <input
                        [matDatepicker]="datePicker"
                        [max]="fieldInfoKey === 'dateOfBirth' ? maxBirthDate : null"
                        formControlName="{{fieldInfoKey}}"
                        matInput
                        required="{{registrationInfo.model[fieldInfoKey].required}}">

                      <mat-datepicker-toggle
                        [for]="datePicker"
                        matSuffix></mat-datepicker-toggle>

                      <mat-error>
                        <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                        <span *ngIf="hasError(fieldInfoKey, 'server')">{{errorForField(fieldInfoKey, 'server')}}</span>
                      </mat-error>
                    </mat-form-field>

                    <div class="hint">
                      <button #tooltip="matTooltip"
                              *ngIf="registrationInfo.model[fieldInfoKey].hasInfo && !!registrationInfo.model[fieldInfoKey].info"
                              [matTooltip]="registrationInfo.model[fieldInfoKey].info"
                              mat-icon-button
                              type="button">
                        <mat-icon svgIcon="information-outline"></mat-icon>
                      </button>
                    </div>
                  </div>

                  <!-- End Date -->
                  <!-- Dropdown -->
                  <div *ngSwitchCase="'dropdown'" class="row">
                    <mat-form-field appearance="outline">
                      <mat-label class="selectLabel">
                        {{registrationInfo.model[fieldInfoKey].label | localeText}}
                      </mat-label>
                      <mat-select
                        class="classic"
                        formControlName="{{fieldInfoKey}}"
                        required="{{registrationInfo.model[fieldInfoKey].required}}">
                        <mat-option
                          *ngFor="let option of registrationInfo.model[fieldInfoKey].options"
                          value="{{option.value}}">
                          {{option.label | localeText}}
                        </mat-option>
                      </mat-select>

                      <mat-error>
                        <span *ngIf="hasError(fieldInfoKey, 'required')" i18n="@@validation_required">This field is required</span>
                      </mat-error>
                    </mat-form-field>

                    <div class="hint">
                      <button #tooltip="matTooltip"
                              *ngIf="registrationInfo.model[fieldInfoKey].hasInfo && !!registrationInfo.model[fieldInfoKey].info"
                              [matTooltip]="registrationInfo.model[fieldInfoKey].info"
                              mat-icon-button
                              type="button">
                        <mat-icon svgIcon="information-outline"></mat-icon>
                      </button>
                    </div>

                  </div>
                  <!-- End DropDown -->

                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="requirePassword !== false"
              >
                <div class="row">
                  <mat-form-field appearance="outline">
                    <mat-label i18n="@@password">Password</mat-label>
                    <input formControlName="password" matInput minlength="8" required
                           [type]="hidePassword ? 'text' : 'password'">
                    <button (click)="this.hidePassword = ! this.hidePassword;"
                            type="button" matSuffix mat-icon-button>
                      <mat-icon [svgIcon]="hidePassword ? 'eye' : 'eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                      <span
                        *ngIf="hasError('password', 'server')"
                        i18n="@@validation_error" >Your password must be at least 8 characters long.</span>
                      <span
                        *ngIf="hasError('password', 'required')"
                        i18n="@@validation_required">This field is required</span>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field appearance="outline">
                    <mat-label i18n="@@pswd_repeat_password">Confirm password</mat-label>
                    <input formControlName="password2" matInput required="true"
                    [type]="hidePassword ? 'text' : 'password'">
                    <button (click)="this.hidePassword = ! this.hidePassword;"
                            type="button" matSuffix mat-icon-button>
                      <mat-icon [svgIcon]="hidePassword ? 'eye' : 'eye-off'"></mat-icon>
                    </button>

                    <mat-error>
                      <span *ngIf="hasError('password2', 'required')"
                            i18n="@@validation_required">This field is required</span>
                      <span
                        *ngIf="formGroup.controls.password2.hasError('passwordNotMatch')"
                        i18n="@@validation_password_do_not_match">The passwords do not match</span>
                    </mat-error>
                  </mat-form-field>
                </div>

                <rag-password-validation
                  (valid) ="onPasswordValidationChange($event)"
                  [passwordField]="getControl('password')"
                ></rag-password-validation>
              </ng-container>

              <div class="agb">
                <mat-checkbox
                  *ngIf="registrationInfo.hasTermsAndConditions"
                  formControlName="accept"
                  class="checkbox-agb"
                  required>
                  <div
                    *ngIf="(styleSettings$ | async) as styleSettings" i18n="@@register_terms_and_conditions">
                    I have read the&nbsp;<a href="{{styleSettings.acc.policies}}" target="_blank">privacy statement</a>&nbsp;
                    and the&nbsp;<a href="{{styleSettings.acc.terms}}" target="_blank">general terms and conditions</a>&nbsp;
                    and accept the terms therein.
                  </div>
                </mat-checkbox>
              </div>

              <div class="buttons-container">
                <button
                  (click)="cancel()"
                  class="rag-stroked-button"
                  i18n="@@cancel"
                  mat-stroked-button
                  type="button">Cancel</button>
                <button
                  color="primary"
                  i18n="@@Submit"
                  mat-flat-button
                  type="submit">Submit</button>
              </div>

              <p class="notice" i18n="@@contact_requred_fields">The fields marked with * are mandatory and must be completed.</p>

            </div> <!-- right -->
          </div>
        </div>
        <div class="hint hintLogin"></div>

      </form>
    </div>
  </div>

  <div *ngIf="registrationInfo && requestIsSent" class="box" id="request-is-sent">
    <p i18n="Description text after the register form has been sent@@register_request_sent">Your request has been sent successfully.</p>
    <p i18n="@@register_request_sent_you_will_get_mail">A message with a link to activate your account will be sent to the email address that you have provided.</p>

    <button
      id="go-home"
      mat-button
      routerLink="/home"
      color="primary"
      mat-flat-button
      type="button"
    >
      <span i18n="@@home">Home</span>
    </button>
  </div>

  <div *ngIf="!registrationInfo" class="error">
    <p i18n="@@register_not_available">Self registration is not available</p>
    <div class="back-to-login-container">
      <button
        (click)="navigateBackToLogin()"
        color="primary"
        mat-flat-button
        type="button">
        <span i18n="@@self_register_button_back_to_login_page">Back to login</span>
      </button>
    </div>
  </div>
</div>
