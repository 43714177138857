<mat-checkbox
  [(ngModel)]="entry.$view.multiSelected"
  (ngModelChange)="onSelectionChange()"
  [disabled]="disabled"
  [ngClass]="{inactive: (entry.value['principalActive'] === false), changed: (entry.changed === true)}"
  class="item-content"
  labelPosition="before"
  data-cy="menuItemSelectCheckbox"
>
  <mat-icon
    *ngIf="entry.$view.icon"
    [svgIcon]="entry.$view.icon"
    class="item-placement"
  ></mat-icon>

  <span class="item-title item-placement">{{entry.title}}</span>

  <ng-container
    [ngSwitch]="entry.value['assignmentType']"
  >
    <ng-container
      *ngSwitchCase="'both'"
    >
      <mat-icon
        class="muted item-placement"
        i18n-matTooltip="@@assignment_mandatory"
        matTooltip="Mandatory"
        svgIcon="card-account-details-star-outline"
      ></mat-icon>
      <mat-icon
        class="muted item-placement"
        i18n-matTooltip="@@assignment_voluntary"
        matTooltip="Voluntary"
        svgIcon="card-account-details-outline"
      ></mat-icon>
    </ng-container>
    <ng-container
      *ngSwitchCase="'mandatory'"
    >
      <mat-icon
        class="muted item-placement"
        i18n-matTooltip="@@assignment_mandatory"
        matTooltip="Mandatory"
        svgIcon="card-account-details-star-outline"
      ></mat-icon>
    </ng-container>
    <ng-container
      *ngSwitchCase="'voluntary'"
    >
      <mat-icon
        class="muted item-placement"
        i18n-matTooltip="@@assignment_voluntary"
        matTooltip="Voluntary"
        svgIcon="card-account-details-outline"
      ></mat-icon>
    </ng-container>
  </ng-container>
</mat-checkbox>
