<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <rag-dialog-header [closingEnabled]="false">
    <span i18n="@@catalog_user_fields_title">Additional information requested</span>
  </rag-dialog-header>

  <mat-dialog-content>

    <p i18n="@@catalog_user_fields_intro">This event requests additional information.</p>

    <rag-profile-field
      *ngFor="let field of data.userFields"
      [field]="field"
    ></rag-profile-field>

  </mat-dialog-content>

  <mat-dialog-actions>

    <button
      type="button"
      mat-stroked-button
      [disabled]="saving"
      [mat-dialog-close]=""
      class="rag-stroked-button"
    >
      <span i18n="@@global_skip">Skip</span>
    </button>

    <button
      (click)="onSubmit()"
      [disabled]="saving || form.invalid || form.pristine"
      color="primary"
      mat-flat-button
    >
      <span i18n="@@global_save">Save</span>
    </button>

  </mat-dialog-actions>

</form>
