import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { InputTypes } from '../input.types';
import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'rag-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
})
export class CheckboxComponent<T>
  extends InputTypes.AbstractControlValueAccessor<T>
  implements OnInit {

  @ViewChild('eltCheckbox', { read: ElementRef, static: true })
  eltCheckbox: ElementRef;
  @Input() label: string;
  @Input() valueState: InputTypes.ValueState<boolean>;
  @Output() readonly valueStateChanged: Observable<InputTypes.ValueState<boolean>>;
  private _valueStateChanged = new EventEmitter<InputTypes.ValueState<boolean>>(true);

  constructor(
    @Optional()
    protected ngControl: NgControl,
  ) {
    super();

    this.valueStateChanged = this._valueStateChanged.asObservable();
    this.setNgControl(ngControl);
  }

  @HostBinding('class.dirty')
  get dirty(): boolean {
    return this.valueState?.changed;
  }

  @HostBinding('class.disabled')
  get disabled(): boolean {
    return this.valueState?.disabled;
  }

  getInput(): HTMLInputElement {
    return this.eltCheckbox?.nativeElement;
  }

  ngOnInit(): void {
    InputTypes.Util.initializeValueState(this.valueState);
  }

  onValueStateChange() {
    const valueState = this.valueState;
    const input = this.getInput();
    if ( (valueState == null) || (input == null) ) {
      // not yet initialized
      return;
    }

    InputTypes.Util.updateValueStateChange(valueState, input.checked, input.indeterminate);
    this._valueStateChanged.next(valueState);
  }

}
