<button
  *ngIf="hasInfo"
  type="button"
  i18n-matTooltip="@@content_info_button_details"
  matTooltip="Details"
  matTooltipPosition="above"
  mat-icon-button
>
  <mat-icon svgIcon="information-outline"></mat-icon>
</button>
