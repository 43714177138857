import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { destroySubscriptions } from '../../../../core/reactive/until-destroyed';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginFormService } from '../../../../component/login/login-form/login-form.service';
import { LoginFormData } from '../../../../component/login/login-form/login-form.types';
import { LoginRedirectService } from './login-redirect.service';


@Component({
  selector: 'rag-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent
  implements OnDestroy, OnInit {

  readonly loginFormData$: Observable<LoginFormData>;
  private _showLoadingPlaceholder = true;

  constructor(
    private loginFormService: LoginFormService,
    private loginRedirectService: LoginRedirectService,
    private principalService: PrincipalService,
    private route: ActivatedRoute,
  ) {
    this.loginFormData$ = this.loginFormService.getLoginFormData();
  }

  getTemplate<T>(
    tplLoading: TemplateRef<T>,
    tplLogin: TemplateRef<T>,
  ): TemplateRef<T> {

    if (this._showLoadingPlaceholder) {
      return tplLoading;
    }

    return tplLogin;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {

    if (!this.principalService.isLogged) {
      console?.info('user is not logged in, showing login form');
      this._showLoadingPlaceholder = false;
      return;
    } else {
      console?.info('user is logged in, triggering redirect');
      this.route.queryParamMap
        .pipe(take(1))
        .pipe(tap(params => this.loginRedirectService.setRedirectUrl(params.get('url'))))
        .subscribe();
    }
  }

}
