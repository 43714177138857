import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/api.urls';
import { InfoService } from '../../../../core/info/info.service';
import { LiveAgendaDetails } from '../live-agenda.types';

@Injectable({
  providedIn: 'root',
})
export class LiveAgendaDetailsService {
  constructor(
    private infoService: InfoService,
    private http: HttpClient,
  ) {
  }

  getLiveAgendaDetails(contentId: number, eventId: number): Observable<LiveAgendaDetails> {
    const url = ApiUrls.getKey('LearnerOfflineEvent')
      .replace(/{contentId}/gi, String(contentId))
      .replace(/{eventId}/gi, String(eventId));
    return this.http.get<LiveAgendaDetails>(url)
      .pipe(tap(data => {
        const event = data.offlineEvent;
        data.offlineContentId = event.contentId;
        data.offlineEventId = event.id;
      }));
  }
}
