import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ApiUrls } from 'src/app/core/api.urls';
import { Translateable } from 'src/app/core/core.types';
import { ApiResponse } from 'src/app/core/global.types';
import { InfoService } from 'src/app/core/info/info.service';
import { EntityType, JournalEntityView } from 'src/app/core/journal/journal.types';
import { JournalDialogComponent } from './journal-dialog/journal-dialog.component';
import { JournalDialogData } from './journal-dialog/journal-dialog.types';

@Injectable({
  providedIn: 'root'
})
export class JournalService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService
  ) { }

  displayJournalForObject(entityType: EntityType, entityId: number, title: Translateable): Observable<void> {
    return this.journalForObject(entityType, entityId)
      .pipe(switchMap(journal => {
        if (journal.length === 0) {
          return this.infoService.showAlert($localize`:@@journal_no_data:There are no entries in this journal`);
        }
        return this.infoService.showDialog<JournalDialogComponent, JournalDialogData, void>(
          JournalDialogComponent, {
            title,
            journal
      }, {
        height: '80vh',
        width: '80vw'
      });
    }));
  }

  private journalForObject(entityType: EntityType, entityId: number): Observable<Array<JournalEntityView>> {
    const url = ApiUrls.getKey('Journal')
      .replace('{entityType}', String(entityType))
      .replace('{entityId}', String(entityId));
    return this.http.get<ApiResponse<Array<JournalEntityView>>>(url).pipe(map(response => response.journalViews));
  }

}
