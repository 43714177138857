import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map, take } from 'rxjs/operators';
import { CatalogService } from '../../../core/catalog/catalog.service';
import { UrlHelper } from '../../../core/url.helper';

@Injectable({ providedIn: 'root' })
export class CatalogRedirectGuard
  implements CanActivate {

  constructor(
    private catalogService: CatalogService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const shortUrl = route.params['shortUrl'];

    return this.catalogService.getRedirectByShortUrl(shortUrl)
      .pipe(take(1))
      .pipe(map(redirectResponse => {
        let urlTree: UrlTree = null;
        if (redirectResponse.catalogUUID == null && redirectResponse.accountKey == null) {

          urlTree = this.router.createUrlTree([]);
        } else if (redirectResponse.accountKey == null) {

          urlTree = this.router.createUrlTree(['catalog', redirectResponse?.catalogUUID]);
        } else {

           urlTree = this.router.createUrlTree(['catalog', redirectResponse?.catalogUUID], {
            queryParams: {
              key: redirectResponse?.accountKey,
            }
          });
          setTimeout(() => window.location.href = `${UrlHelper.getBaseUrl()}#${urlTree.toString()}`, 0);
          return true;
        }
        return urlTree;
      }));
  }

}
