import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Quests } from 'src/app/core/quest/quest.types';

@Component({
  selector: 'rag-quest-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule
  ],
  templateUrl: './quest-button.component.html',
  styleUrls: ['./quest-button.component.scss']
})
export class QuestButtonComponent {

  @Input() quest: Quests.Quest;
  @Input() hasFeedback: boolean;
  @Output() feedback: EventEmitter<Quests.Quest>;

  constructor() {
    this.feedback = new EventEmitter();
  }

  onLrnFeedback() {
    this.feedback.emit(this.quest);
  }

}
