import {ErrorHandler, Injectable} from '@angular/core';
import {GrafanaFaroService} from './grafana-faro.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private grafanaFaroService: GrafanaFaroService,
  ) {

  }

  handleError(error: any) {
    if (error instanceof Error && !console) {
      this.grafanaFaroService.pushUnexpectedError(error);
    }
    console?.error(error);
  }
}
