<rag-dialog-header [closingEnabled]="false">
  {{questTitle}}
</rag-dialog-header>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="fragment"
      *ngFor="let fragment of data.quest.quest.fragments, let i = index"
      [attr.ng-cond]="conditionIdIfAny(fragment)"
      [attr.ng-fragment]="i"
      [ngClass]="{ 'hidden': hiddenConditionForComp(fragment.condition) | async }"
      [ngSwitch]="fragment.type">

      <rag-quest-type-radio-matrix
        [form]="getForm(i)"
        [fragment]="fragment"
        *ngSwitchCase="'radio-matrix'"
      ></rag-quest-type-radio-matrix>

      <rag-quest-type-text
        [form]="getForm(i)"
        [fragment]="fragment"
        *ngSwitchCase="'text'"
      ></rag-quest-type-text>

      <rag-quest-type-paragraph
        [form]="getForm(i)"
        [fragment]="fragment"
        *ngSwitchCase="'paragraph'">
      </rag-quest-type-paragraph>

      <rag-quest-type-radio
        [form]="getForm(i)"
        [fragment]="fragment"
        *ngSwitchCase="'radio'">
      </rag-quest-type-radio>

      <rag-quest-type-checkbox
        [form]="getForm(i)"
        [fragment]="fragment"
        *ngSwitchCase="'checkbox'">
      </rag-quest-type-checkbox>

    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onCancel()" class="rag-stroked-button"
          mat-stroked-button type="button">
    <span i18n="@@add_user_dialog_button_cancel">Cancel</span>
  </button>
  <button
    mat-stroked-button
    [disabled]="submitButtonDisabled$ | async"
    color="primary"
    (click)="onSubmit($event)"
  >
    <span i18n="@@global_submit">Submit</span>
  </button>
</mat-dialog-actions>