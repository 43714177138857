import { Pipe, PipeTransform } from '@angular/core';
import { LanguageHelper } from './language.helper';

const MIN = 1;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;
const COUNT = 2;

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe
  implements PipeTransform {

  constructor() {}

  transform(value: string, locale?: string): any {

    let counter = COUNT;
    let result = '';
    let duration = 0;

    if (typeof value !== 'string') {
      if (typeof value !== 'number') {
        return result;
      }
      duration = value;
    } else {
      duration = parseInt(value, 10);
    }

    if (isNaN(duration)) {
      return result;
    }

    const days = Math.floor(duration / DAY);
    if (days > 0) {
      result = `${days} ${LanguageHelper.plural(days, {
        1: $localize`:@@global_day:Day`,
        other: $localize`:@@global_days:Days`
      })}`;
      if (--counter === 0) {
        return result;
      }
      duration -= days * DAY;
    }

    const hours = Math.floor(duration / HOUR);
    if (hours > 0) {
      result = result + (counter < COUNT ? ', ' : '') + `${hours} ${LanguageHelper.plural(hours, {
        1: $localize`:@@global_hour:Hour`,
        other: $localize`:@@global_hours:Hours`
      })}`;
      if (--counter === 0) {
        return result;
      }
      duration -= hours * HOUR;
    }

    const mins = duration;
    if (mins > 0) {
      result = result + (counter < COUNT ? ', ' : '') + `${mins} ${LanguageHelper.plural(mins, {
        1: $localize`:@@global_minute:Minute`,
        other: $localize`:@@global_minutes:Minutes`
      })}`;
    }

    return result;
  }

}
