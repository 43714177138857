export enum JournalActionType {
  NONE = 0,
  ASSIGN = 1,
  CHANGE = 2,
  CREATE = 3,
  DELETE = 4,
  ACTIVATE = 5,
  DEACTIVATE = 6
}

export function getActionTypeToLocaleLookup() {
  return {
    NONE: $localize`:@@journal_entity_action_type_none:None`,
    CHANGE: $localize`:@@journal_entity_action_type_change:Change`,
    CREATE: $localize`:@@journal_entity_action_type_create:Create`,
    DELETE: $localize`:@@journal_entity_action_type_delete:Delete`,
  }
}

export function actionTypeToLocale(actionType: JournalActionType) {
  return getActionTypeToLocaleLookup()[actionType];
}


export enum EntityType {
  CURRICULUM = 'CURRICULUM',
  COURSE = 'COURSE',
  PUBSETTING = 'PUBSETTING',
  TARGETGROUP = 'TARGETGROUP',
  ROLE = 'ROLE',
  PROTOTYPE = 'PROTOTYPE',
  WORKPROFILE = 'WORKPROFILE',
  EVENTACTION = 'EVENTACTION',
  USER = 'USER',
  EVENTHANDLING = 'EVENTHANDLING',
  NOTFICATION_HANDLING = 'NOTFICATION_HANDLING',
  URLCALL_HANDLING = 'URLCALL_HANDLING',
  OFFLINE_CONTENT = 'OFFLINE_CONTENT',
  LIC_DATA = 'LIC_DATA',
  ACCOUNT = 'ACCOUNT',
  OFFLINE_EVENT = 'OFFLINE_EVENT',
  NOTIFICATION_TEMPLATE = 'NOTIFICATION_TEMPLATE',
  USER_IMPORT = 'USER_IMPORT',
  ACTIONCODECHARGE = 'ACTIONCODECHARGE',
  OAUTH_USER_INFO = 'OAUTH_USER_INFO',
}

export interface JournalEntityView {
  actionType: JournalActionType;
  date: number;
  entityType: EntityType;
  message: string;
  firstName?: string;
  lastName?: string;
}

export interface JournalEntry extends JournalEntityView {
  id: number;
  userId: number;
  changedEntityId: number;
  type: number;
}

export interface JournalResponse {
  journal: Array<JournalEntityView>;
}
