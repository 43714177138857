<div>
  <form [formGroup]="formGroup">
    <div class="display-flex">
      <mat-form-field appearance="outline" class="title">
        <mat-label>
          <span i18n="@@global_title">Title</span>
        </mat-label>
        <input
          matInput
          required
          formControlName="title"
          placeholder="Title"
          i18n-placeholder="@@global_title"
          data-cy="inputTitle">
      </mat-form-field>

      <rag-admin-signatures-input-macro
        class="macro"
        [form]="formGroup"
        [control]="formGroup.get('macro')"
        [signatureId]="data?.id"
      ></rag-admin-signatures-input-macro>

      <button
        *ngIf="data.id > 0 && usageCount > 0"
        type="button"
        [matTooltip]="getCountTooltip()"
        (click)="onOpenUsageDialog()"
        mat-icon-button
        data-cy="tooltipCount"
      >
        <mat-icon svgIcon="information-outline"></mat-icon>
      </button>

      <mat-form-field appearance="outline" class="type" *ngIf="showType">
        <mat-label>
          <span i18n="@@global_type">Type</span>
        </mat-label>
        <mat-select
          required
          formControlName="type"
          placeholder="Type"
          i18n-placeholder="@@global_type"
          data-cy="selectType"
        >
          <mat-option [value]="'text'" data-cy="selectTypeText">
            <span i18n="@@global_text">Text</span>
          </mat-option>
          <mat-option [value]="'Layout'" data-cy="selectTypeLayout">
            <span i18n="@@global_layout">Layout</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="display-flex">
      <div class="flex">
        <h4 class="headline">
          <span i18n="@@global_description">Description</span>
          <span class="required-marker"> *</span>
        </h4>
        <mat-form-field appearance="outline" class="description">
          <textarea
            matInput
            required
            [rows]=10
            formControlName="description"
            data-cy="textDescription"
          ></textarea>
        </mat-form-field>
      </div>


      <div class="flex">
        <h4 class="headline margin-left">
          <span i18n="@@global_content">Content</span>
          <span class="required-marker"> *</span>
        </h4>
        <ckeditor
          class="ckEditor"
          [editor]="Editor"
          [config]="ckOptions"
          formControlName="content"
          data-cy="ckContent"
        ></ckeditor>
      </div>

    </div>

  </form>
</div>
