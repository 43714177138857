import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CachedSubject } from '../../../core/cached-subject';
import { AnyObject, Identifiable } from '../../../core/core.types';
import { AdminSaveSupportTypes } from './admin-save-support.types';
import { DirtyCheckService } from 'src/app/core/dirty-check.service';


@Injectable({
  providedIn: 'root',
})
export class AdminSaveSupportService<T extends Identifiable> {

  readonly editObject$: Observable<T>;
  readonly hasChanges$: Observable<AdminSaveSupportTypes.ComponentFormEvent>;
  readonly invalidated$: Observable<AdminSaveSupportTypes.ComponentFormEvent>;
  private _componentsStates: AnyObject<AdminSaveSupportTypes.ComponentState> = {};
  private _editObject = new CachedSubject<T>(null);
  private _hasChanges = new CachedSubject<AdminSaveSupportTypes.ComponentFormEvent>(null);
  private _invalidated = new CachedSubject<AdminSaveSupportTypes.ComponentFormEvent>(null);
  private _saveButtonEnabled = new CachedSubject<boolean>(false);

  constructor(private dirtyService: DirtyCheckService) {
    this.editObject$ = this._editObject.asObservable();
    this.hasChanges$ = this._hasChanges
      .pipe(filter(event => event != null))
      .pipe(tap(event => this.getComponentState(event).changed = event.eventValue));
    this.invalidated$ = this._invalidated
      .pipe(filter(response => response != null))
      .pipe(tap(event => this.getComponentState(event).invalidated = event.eventValue));

    this.init();
  }

  get saveButtonEnabled$(): Observable<boolean> {
    return this._saveButtonEnabled.withoutEmptyValuesWithInitial();
  }

  emitChange(hasChanges: AdminSaveSupportTypes.ComponentFormEvent) {
    this._hasChanges.next(hasChanges);
    this.dirtyService.submitNextState(hasChanges.componentId, hasChanges.eventValue);
  }

  emitInvalidate(invalidated: AdminSaveSupportTypes.ComponentFormEvent) {
    this._invalidated.next(invalidated);
  }

  /**
   * Register required components to be filled in order the save button get enabled.
   * @param components components IDs
   */
  registerRequiredComponents(components: Array<string>, removeOther = false) {

    components ??= [];
    components.forEach(componentId => {
      if (this._componentsStates[componentId] != null) {
        // ignore already defined components
        return;
      }

      // add new components
      this._componentsStates[componentId] = {
        changed: false,
        invalidated: false,
      };
      this.dirtyService.submitNextState(componentId, false);
    });

    if (!removeOther) {
      return;
    }

    // remove unmentioned components
    const removedIds = Object.keys(this._componentsStates)
      .filter(entry => !components.includes(entry));
    removedIds.forEach(id => {
      delete this._componentsStates[id];
      this.dirtyService.submitNextState(id, false);
    });
  }

  resetRequiredComponents() {
    Object.keys(this._componentsStates).forEach(key => {
      this._componentsStates[key].changed = false;
      this.dirtyService.submitNextState(key, false);
    });
    this._saveButtonEnabled.next(false);
  }

  setEditObject(object: T) {
    this._editObject.next(object);
  }

  private getComponentState(event: AdminSaveSupportTypes.ComponentFormEvent): AdminSaveSupportTypes.ComponentState {
    const componentId = event.componentId;
    let componentState = this._componentsStates[componentId];
    if ( componentState == null ) {
      componentState = this._componentsStates[componentId] = {
        invalidated: false,
        changed: event.eventValue,
      };
    }
    return componentState;
  }

  private init() {
    // handling save button state
    combineLatest([ this.hasChanges$, this.invalidated$, this.editObject$ ])
      .pipe(filter(([, , object]) => object != null && Object.keys(this._componentsStates).length > 0))
      .pipe(map(([, , object]) => {
        const componentKeys = Object.keys(this._componentsStates);
        if ( object.id > 0 ) {
          // edit object
          const {invalidatedCount, changedCount} = componentKeys.reduce((pV, componentId) => {
            const componentState = this._componentsStates[componentId];
            pV.invalidatedCount += componentState.invalidated ? 1 : 0;
            pV.changedCount += componentState.changed ? 1 : 0;
            return pV;
          }, {invalidatedCount: 0, changedCount: 0});

          if (invalidatedCount > 0) {
            // there is at least one invalidated component
            return false;
          }

          return changedCount > 0;
        } else {
          // create new object
          // save button is disabled when at least one component is not changed or is invalidated
          // console.log(this._componentsStates)
          return componentKeys
            .reduce((pV, componentId) => {
              const componentState = this._componentsStates[componentId];
              // console.log(componentId, componentState);
              return pV + (!componentState.changed || componentState.invalidated ? 1 : 0);
            }, 0) === 0;
        }
      }))
      .subscribe(this._saveButtonEnabled.next);
  }

}
