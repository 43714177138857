import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileComponent } from '../../../component/file/file.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { FileInfo, NumberedAnyObject } from '../../../core/core.types';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, take, tap } from 'rxjs/operators';
import { PageHeaderComponent } from '../../../component/page-header/page-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PermissionConceptSettings } from './admin-permission-concept.types';
import { AdminPermissionConceptService } from './admin-permission-concept.service';
import { InfoService } from '../../../core/info/info.service';
import { InfoType, MessageConstants } from 'src/app/core/info/info.types';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfService } from '../../../core/pdf.service';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { LanguageHelper } from '../../../core/language.helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../core/cached-subject';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AdminConfigurationService } from '../../../core/admin/configuration/admin-configuration.service';
import { AdminConfiguration } from 'src/app/route/admin/admin-configuration/admin-configuration.types';

@Component({
  selector: 'rag-admin-permission-concept',
  standalone: true,
  imports: [
    CommonModule,
    FileComponent,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeaderComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatOptionModule,
    PipesModule,
  ],
  templateUrl: './admin-permission-concept.component.html',
  styleUrls: ['./admin-permission-concept.component.scss']
})
export class AdminPermissionConceptComponent implements OnInit, OnDestroy {

  @ViewChild('columnInput') columnInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA]
  selectedFile: File;
  fileInfo: FileInfo;
  permissionConceptData: PermissionConceptSettings;
  exclusionCriteriaText =
    $localize`:@@permission_concept_excluded_roles_text:Here you can select the roles that should not be added to the generated document.`
  excludedRoleIds: string = '';
  permissionNames:  NumberedAnyObject<string> = []
  allRoleIds: number[];
  selectedExcludedRoles: number[];
  excludedRolesFormControl = new UntypedFormControl();
  deleteFile: boolean = false;

  readonly filteredRoles$: Observable<number[]>;
  private _filteredRoles = new CachedSubject<number[]>([]);

  constructor(
    private adminConfigurationService: AdminConfigurationService,
    private route: ActivatedRoute,
    private adminPermissionConceptService: AdminPermissionConceptService,
    private infoService: InfoService,
    private pdfService: PdfService,
  )
  {
    this.filteredRoles$ = this._filteredRoles.withoutEmptyValues();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data.data)))
      .pipe(take(1))
      .subscribe();

    this.adminConfigurationService.setSaveButtonVisible(true);
    this.adminConfigurationService.setSaveButtonDisabled(true);
    this.adminConfigurationService.setPermissionExportVisible(true);

    this.adminConfigurationService.eventListener$.pipe(map(event => {
      if (event === AdminConfiguration.Event.SAVE) {
        this.onSavePermissionConceptSettings();
      } else if (event === AdminConfiguration.Event.DOWNLOAD_PERMISSION_CONCEPT) {
        this.onDownloadPermissionConcept();
      }
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onDownloadPermissionConcept(): void {
    this.pdfService.generatePermissionExport()
      .pipe(take(1))
      .subscribe();
  }

  onSavePermissionConceptSettings(): void {
    this.adminPermissionConceptService.savePermissionConceptSettings(
      this.selectedFile,
      this.selectedExcludedRoles?.join(','),
      this.deleteFile,
    )
      .pipe(tap(data => {
        this.fileInfo = data.template;
        this.selectedFile = null;
        this.deleteFile = false;
        this.adminConfigurationService.setSaveButtonDisabled(true);
        this.infoService.showMessage($localize`:@@settings_saved:The settings have been saved successfully`, {
          infoType: InfoType.Success,
        })
      }))
      .pipe(catchError(() => this.infoService.showMessage(MessageConstants.API.ERROR, {
        infoType: InfoType.Error,
      })))
      .pipe(take(1))
      .subscribe();
  }

  onSelectedFile($event: File): void {
    this.selectedFile = $event;
    this.deleteFile = false;
    this.adminConfigurationService.setSaveButtonDisabled(false);
  }

  onRemoveFile(): void {
    this.selectedFile = null;
    this.fileInfo = null;
    this.deleteFile = true;
    this.adminConfigurationService.setSaveButtonDisabled(false);
  }

  removeExcludedRole(roleId: number): void {
    const index = this.selectedExcludedRoles.indexOf(roleId);
    if (!(index >= 0)) {
      // could not find column -> skip remove
      return;
    }

    this.selectedExcludedRoles.splice(index, 1);
    this.excludedRolesFormControl.setValue(this.excludedRolesFormControl?.value ?? '');
    this.adminConfigurationService.setSaveButtonDisabled(false);
  }

  selectedColumn(event: MatAutocompleteSelectedEvent): void {

    if (event.option.value == null || this.selectedExcludedRoles.includes(event.option.value)) {
      // column is undefined -> skip add
      return;
    }

    // clear autocomplete filter
    this.columnInput.nativeElement.value = '';
    // add selected column to event

    this.selectedExcludedRoles.push(event.option.value);
    this.excludedRolesFormControl.setValue('');
    this.adminConfigurationService.setSaveButtonDisabled(false);
  }

  private updateRouteData(data: PermissionConceptSettings | null): void {
    this.permissionConceptData = data;
    this.fileInfo = data?.template;
    this.permissionNames = data?.permissionNames ?? {};

    this.allRoleIds = Object.keys(data?.permissionNames ?? {})
      .map(o => Number(o))
      .filter(o => !isNaN(o));

    this.selectedExcludedRoles = (data?.excludedRoleIds ?? [])
      .filter(o => this.allRoleIds.includes(o));

    this._filteredRoles.next(this.allRoleIds
      .filter(permission => !this.selectedExcludedRoles.includes(permission))
    );

    this.excludedRolesFormControl.valueChanges.pipe(map(filterValue => {

      if (typeof filterValue !== 'string') {
        return;
      }

      filterValue = (filterValue ?? '').trim().toLocaleLowerCase();

      if (filterValue === '') {

        this._filteredRoles.next(this.allRoleIds
          .filter(permission => !this.selectedExcludedRoles.includes(permission))
        );
      } else {

        const filteredColumns = this.allRoleIds
          .filter(permission => !this.selectedExcludedRoles.includes(permission))
          .filter(column => LanguageHelper
            .objectToText(this.permissionNames[column]).toLocaleLowerCase().includes(filterValue));
        this._filteredRoles.next(filteredColumns);
      }
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

  }
}
