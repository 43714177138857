import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Core } from 'src/app/core/core.types';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';
import { DisplayStatusHelper } from 'src/app/core/display-status-helper';
import { InfoService } from 'src/app/core/info/info.service';
import { AdminCoursesService } from 'src/app/route/admin/admin-courses/admin-courses-util/admin-courses.service';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { TableGroupingControllerComponent } from '../../../../../component/table/table-grouping/table-grouping-controller.component';
import { DateHelper } from '../../../../../core/date.helper';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { ViewHelper } from '../../../../../core/view-helper';
import { ControllingSingleUserService } from '../../ctrl-single-user-util/ctrl-single-user.service';
import { CourseAccountState } from '../../ctrl-single-user-util/ctrl-single-user.types';
import { CtrlSingleUserDetailsCourseTypes } from '../ctrl-single-user-details-course/ctrl-single-user-details-course.types';
import { CtrlSingleUserDetailsCurriculumTypes } from '../ctrl-single-user-details-curriculum/ctrl-single-user-details-curriculum.types';
import { TodoDataPreviewDialogComponent } from './components/todo-data-preview-dialog/todo-data-preview-dialog.component';
import { CtrlSingleUserDetailsLearningDataColumns } from './ctrl-single-user-details-learning-data.columns';
import { CtrlSingleUserDetailsLearningDataTypes } from './ctrl-single-user-details-learning-data.types';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { MatDialogRef } from '@angular/material/dialog';
import { CtrlSingleUserDetailsLearningDataDialogComponent } from '../ctrl-single-user-details-learning-data-dialog/ctrl-single-user-details-learning-data-dialog.component';
import { Observable, of } from 'rxjs';
import { SubscriptionHolder } from '../../../../../core/reactive/subscription-holder';
import { LearnerContentTypes } from '../../../../user/content/learner-content-util/learner-content.types';
import { CourseTypeHelper } from '../../../../../core/course-type-helper';
import { InfoType } from '../../../../../core/info/info.types';
import { LanguageHelper } from '../../../../../core/language.helper';


@Component({
  selector: 'rag-ctrl-single-user-details-learning-data',
  templateUrl: './ctrl-single-user-details-learning-data.component.html',
  styleUrls: [ './ctrl-single-user-details-learning-data.component.scss' ],
})
export class CtrlSingleUserDetailsLearningDataComponent
  extends TableGroupingControllerComponent<CtrlSingleUserDetailsLearningDataTypes.RowData,
    CtrlSingleUserDetailsLearningDataTypes.RowDataChild,
    CtrlSingleUserDetailsLearningDataTypes.RowDataParent,
    CtrlSingleUserDetailsLearningDataColumns.ColumnMenuData>
  implements OnInit {

    @Output() saveButtonDisabled: EventEmitter<boolean>;
    @Input() dialogRef: MatDialogRef<CtrlSingleUserDetailsLearningDataDialogComponent>;

    maxDate: Date;
  readonly ContentType_Course = Core.DistributableType.lms_course;
  readonly clearSolutionAndEstimation$: Observable<boolean>;

  formGroup: UntypedFormGroup;
  singleTodoCourse: ControllingSingleUserTypes.CourseAccountDetails;
  data: CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams;
  maySave = false;

  private _clearSolutionAndEstimation = new EventEmitter<boolean>(false);
  private _submitClicked = new SubscriptionHolder<boolean>(this);
  private _tableRows: CtrlSingleUserDetailsLearningDataTypes.RowData[];
  private ignoreClicks = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private ctrlService: ControllingSingleUserService,
    private infoService: InfoService,
    private adminCourseService: AdminCoursesService,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);

    this.dataSource.filterPredicate = this.filterPredicate;

    const today = new Date().getTime();
    this.maxDate = moment(today).toDate();
    this.inputDisabled = false;
    this.saveButtonDisabled = new EventEmitter();
    this.clearSolutionAndEstimation$ = this._clearSolutionAndEstimation.asObservable();
  }

  get tableRows(): CtrlSingleUserDetailsLearningDataTypes.RowData[] {
    this._tableRows = CtrlSingleUserDetailsLearningDataTypes.Util.toTableRows(this.data?.courses as any ?? []);
    return this._tableRows;
  }

  @Input()
  set inputData(value: CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams) {
    this.data = value;
    this.handleSingleToDoCourse();
    if ( this.formGroup == null ) {
      this.buildForm();
      this.formGroup.statusChanges
        .pipe(map(() => this.emitSaveButtonDisabled()))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }
    this.updateAvailableColumns();
    this.setTableData(this.tableRows);
  }

  @Input() set submitClicked$(value: Observable<boolean>) {
    this._submitClicked.observable = value;
  }

  ngOnInit() {
    if ( this.data == null ) {
      this.activeRoute.data
        .pipe(map(data => {
          const userId = parseInt(this.activeRoute.snapshot.params['userId'], 10);
          this.data = {
            courses: [ data.courseDetails ],
            userId,
            extensions: data.courseDetails.extensions
          };
          this.handleSingleToDoCourse();
          this.buildForm();
          this.formGroup.statusChanges
            .pipe(map(() => {
              this.saveButtonDisabled.emit(this.formGroup.pristine || this.formGroup.invalid);
            }))
            .pipe(takeUntilDestroyed(this))
            .subscribe();
          this.updateAvailableColumns();
          this.setTableData(this.tableRows);
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }

    this._submitClicked.value$
      .pipe(filter(clicked => clicked === true))
      .pipe(tap(() => this.onSubmit()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  buildForm(): void {
    const controlsConfig = this.tableRows
      ?.map(row => this.asChild(row))
      ?.filter(row => row != null)
      .reduce((pV, item) => {
        const identifier = this.getIdentifier(item);
        if ( identifier == null ) {
          // should not happen, but just in case
          return pV;
        }

        const displayStatus = DisplayStatusHelper.toDisplayStatus(item.displayStatus);
        pV[identifier + '.displayStatus'] = new UntypedFormControl({
          value: displayStatus,
          disabled: !this.maySave,
        });

        const disabled = !this.maySave || (displayStatus === DisplayStatus.NOT_ATTEMPTED);
        pV[identifier + '.executionDate'] = new UntypedFormControl({
          value: item.executionDate,
          disabled,
        }, [ Validators.required ]);
        pV[identifier + '.result'] = new UntypedFormControl({
          value: item.result,
          disabled,
        }, [ Validators.min(0), Validators.max(100) ]);
        return pV;
      }, {});

    if ( controlsConfig != null ) {
      this.formGroup = new UntypedFormGroup(controlsConfig);
      this.emitSaveButtonDisabled();
    }
  }

  disableInput(key, disabled = true): void {
    const control = this.getControl(key);
    if ( disabled ) {
      control?.disable();
    } else {
      control?.enable();
      control?.markAsTouched();
    }
  }

  getControl(key): AbstractControl {
    return this.formGroup.controls[key];
  }

  getCourseAsToDo(): LearnerContentTypes.CourseAccountToDo {

    if ( !CourseTypeHelper.isTodo(this.singleTodoCourse) ) {
      return null;
    }

    return this.singleTodoCourse as any;
  }

  getIdentifier(row: CtrlSingleUserDetailsLearningDataTypes.RowDataChild): string | null {
    const course = row?.$parent?.$data;
    const courseItem = row?.$data;
    if ( (course == null) || (courseItem == null) ) {
      return null;
    }
    return courseItem.scoId + '.' + course.courseId;
  }

  getOrderIndex(row: CtrlSingleUserDetailsLearningDataTypes.RowData): string {
    if (row.orderIndex < 0) {
      return null;
    }
    if (row.$rowType === 'parent') {
      return String(row.orderIndex + 1);
    }
    const childRow = this.asChild(row);
    if ( childRow?.orderIndex > -1 ) {
      const parentRow = row.$parent;
      if (parentRow != null) {
        // TODO: fix the type
        return `${parentRow['orderIndex'] + 1}.${childRow.orderIndex + 1}`;
      }
    } else {
      return null;
    }
  }

  isDirty(childRow: CtrlSingleUserDetailsCurriculumTypes.RowData, key: string): boolean {
    const viewData = ViewHelper.getViewData(childRow);
    return viewData.dirtyStates?.[key] === true;
  }

  isTodo(row: CtrlSingleUserDetailsLearningDataTypes.RowData) {
    return this.asParent(row).$data.courseType === Core.CourseType.ToDo;
  }

  onToDoDetails(row: CtrlSingleUserDetailsLearningDataTypes.RowDataParent) {
    if ( this.ignoreClicks ) {
      return;
    }
    this.ignoreClicks = true;
    const courseData = this.asParent(row).$data;
    this.adminCourseService
      .getCourseAccountForUser(this.data.userId, courseData.courseId)
      .subscribe(response => {
        const { course, contribution } = response;
        // if the user has not provided any solition then display alert and exit
        if ( contribution == null ) {
          this.infoService.showAlert($localize`:@@ctrl-single-user-no-contribution:
            The user has not provided any solution for this task`);
          return;
        }
        // the user has provided solution for this task
        course.title = LanguageHelper.objectToText(course.title);
        course.courseType = courseData.courseType;
        this.infoService.showDialog(TodoDataPreviewDialogComponent, {
          course: { ...course, contribution },
        });
        this.ignoreClicks = false;
      }, () => {
        this.ignoreClicks = false;
      });
  }

  onModelChange(childRow: CtrlSingleUserDetailsLearningDataTypes.RowDataChild, key: string, value: any): void {
    const courseItem = childRow?.$data;
    if ( (courseItem == null) ) {
      return;
    }

    switch ( key ) {
      case 'result':
        const numValue = parseInt(value, 10);
        if ( !isNaN(numValue) ) {
          childRow.result = numValue;
          // no else case to prevent overwriting current value with invalid ones
        }
        break;
      case 'displayStatus':
        childRow.displayStatus = DisplayStatusHelper.toDisplayStatus(value);

        const identifier = this.getIdentifier(childRow);
        this.disableInput(identifier + '.executionDate',
          childRow.displayStatus === DisplayStatus.NOT_ATTEMPTED);
        this.disableInput(identifier + '.result',
          childRow.displayStatus === DisplayStatus.NOT_ATTEMPTED);
        break;
      case 'executionDate':
        childRow.executionDate = value;
        break;
    }

    const viewData = ViewHelper.getViewData(childRow);
    const dirtyStates = viewData.dirtyStates = viewData.dirtyStates || {};
    dirtyStates['displayStatus'] = childRow.displayStatus !== courseItem.displayStatus;
    dirtyStates['executionDate'] = !DateHelper.equalsDay(childRow.executionDate, courseItem.executionDate);
    dirtyStates['result'] = childRow.result !== courseItem.result;
    viewData.isDirty = Object.values(dirtyStates).find(state => state === true) === true;
  }

  onSubmit(): void {

    let emitClear = false;
    if ( this.formGroup.invalid ) {
      // invalid -> cannot save
      return;
    }

    if ( this.formGroup.pristine ) {
      // prevent saving unchanged data
      return this.dialogRef?.close(false);
    }

    const updateData: CourseAccountState[] = Object.values(this.dataSource.data
      ?.reduce((pV, row) => {
        const childRow = this.asChild(row);
        const viewData = ViewHelper.getViewData(childRow);
        if ( viewData?.isDirty !== true ) {
          return pV;
        }

        const courseId = childRow.$parent?.$data?.courseId;
        if ( !(courseId > 0) ) {
          // should not happen!
          return pV;
        }

        if ( pV[courseId] == null ) {
          pV[courseId] = { courseId, itemStates: [] };
        }

        let unixDate: number = null;
        const executionDate = childRow.executionDate;
        if ( executionDate?.isValid() ) {
          unixDate = executionDate.unix() * 1000;
        }

        const clearValues = childRow.displayStatus === DisplayStatus.NOT_ATTEMPTED;

        emitClear ||= clearValues;
        const courseItem = childRow.$data;
        pV[courseId].displayStatus = childRow.displayStatus;
        pV[courseId].executionDate = clearValues ? null : unixDate;
        pV[courseId].itemStates.push({
          courseItemId: courseItem.scoId,
          displayStatus: childRow.displayStatus,
          executionDate: clearValues ? null : unixDate,
          result: clearValues ? -1 : (childRow.result ?? -1),
        });

        return pV;
      }, {}) ?? {});

    if ( updateData.length === 0 ) {
      // nothing changed -> cancel dialog
      return this.dialogRef?.close(false);
    }

    const userId = this.data.userId;
    this.ctrlService.saveCourseStatesForUser(userId, {
      states: updateData,
      userId,
    })
      .pipe(take(1))
      .pipe(tap(() => {
        this.infoService.showMessage(
          $localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
        this._clearSolutionAndEstimation.next(emitClear);
        this.reset();
      }))
      .pipe(switchMap(() => {
        if (this.dialogRef != null) {
          return of(void(0));
        }
        return this.ctrlService.getUserDetails(userId, true);
      }))
      .pipe(tap(() => this.dialogRef?.close(true)))
      .subscribe();
  }

  protected filterPredicate = (data): boolean => data.$visible;

  private emitSaveButtonDisabled(): void {
    const formGroup = this.formGroup;
    const saveDisabled = (formGroup == null) || formGroup.pristine || formGroup.invalid || !this.maySave;
    this.saveButtonDisabled.emit(saveDisabled);
  }

  private handleSingleToDoCourse() {
    const courses = this.data?.courses;
    if ( courses == null || courses.length === 0 || courses.length > 1 ) {
      return;
    }
    const singleCourse = courses[0];
    if ( singleCourse.courseType === Core.CourseType.ToDo ) {
      this.singleTodoCourse = singleCourse;
    } else {
      this.singleTodoCourse = null;
    }

    this.maySave = singleCourse?.rbacActions?.maySave ?? true;
    this.emitSaveButtonDisabled();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService
      .createFromDefaults(CtrlSingleUserDetailsLearningDataColumns.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private reset() {
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.emitSaveButtonDisabled();
  }
}
