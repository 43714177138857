import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { cloneDeep, forIn, remove } from 'lodash';
import { take, tap } from 'rxjs/operators';
import { ReportTargetType } from 'src/app/core/report/report.types';
import { sortByIndex } from 'src/app/rag-layout/widgets/widgets.types';
import { ColumnSettingsService, TableColumnSettings } from '../../../../core/column-settings/column-settings.service';
import {
  Column,
  DefaultColumnsCourse,
  DefaultColumnsCurriculum,
} from '../../../../core/column-settings/column-settings.types';
import { InfoService } from '../../../../core/info/info.service';
import { MessageKey, OkButton } from '../../../../core/info/info.types';
import { ViewHelper } from '../../../../core/view-helper';
import { DirtyCheckService } from '../../../../core/dirty-check.service';

@Component({
  selector: 'rag-column-conf',
  templateUrl: './column-conf.component.html',
  styleUrls: [ './column-conf.component.scss' ],
})
export class ColumnConfComponent
  implements OnInit {

    @Input()
    targetType: ReportTargetType;
  disabled: Column[] = [];
  isDirty = false;
  pool: Column[] = [];
  referenceConfig: Column[] = [];
  selected: Column[] = [];
  workConfig: Column[] = [];
  private _columnSettings: TableColumnSettings;
  private initDone = false;
  private itemsEdited_: string [] = [];

  constructor(
    private columnService: ColumnSettingsService,
    private dirtyCheckService: DirtyCheckService,
    private infoService: InfoService,
    private router: Router,
  ) {
  }

  get columnSettings(): TableColumnSettings {
    return this._columnSettings;
  }

  @Input()
  set columnSettings(value: TableColumnSettings) {
    this._columnSettings = value;
    this.initColumnSettings();
  }

  detectChanges(triggeredByCheckAll: boolean, uniqueIdentifier: string): void {
    const workArray: Column[] = this.workConfig;
    const original: Column[] = this.referenceConfig;

    let changed = false;
    workArray.forEach(item => {
      const viewData = ViewHelper.getViewData(item);
      // only compare item with matching identifier
      if ( uniqueIdentifier === item.columnName ) {
        original.forEach(originalItem => {
          // matching item found in other array --> starting to compare if not already found an item with differences
          if ( originalItem.columnName === item.columnName && !changed ) {
            // compare all values and update changed accordingly
            if ( !ViewHelper.isEqual(originalItem, item) ) {
              changed = true;
              viewData.modified = true;
              // check if item is not in original container
              if ( originalItem.visible !== item.visible ) {
                viewData.containerChanged = true;
              }
            } else if ( ViewHelper.isEqual(originalItem, item) ) {
              viewData.modified = false;
              // reset if item is in original container
              if ( originalItem.visible !== item.visible ) {
                viewData.containerChanged = false;
              }
            }
          }
        });
      }
    });

    if ( changed ) {
      // only insert identifier once into the array
      if ( !this.itemsEdited_.includes(uniqueIdentifier) ) {
        this.itemsEdited_.push(uniqueIdentifier);
      }
    } else {
      remove(this.itemsEdited_, (value) => value === uniqueIdentifier);
    }

    // only check if it is triggered from one element
    if ( !triggeredByCheckAll ) {
      this.isSomethingDirty();
    }
  }

  detectChangesAnywhere() {
    this.workConfig.forEach((value) => {
      this.detectChanges(true, value.columnName);
    });

    // push after iterated over all items
    this.isSomethingDirty();
  }

  disableItem(item: Column) {
    let collection = this.pool;
    if ( item.visible ) {
      collection = this.selected;
    }
    const pos = collection.indexOf(item);
    if ( pos >= 0 ) {
      collection.splice(pos, 1);
    }
    item.selectable = item.fixed = false;
    this.disabled.splice(0, 0, item);

    this.detectChanges(false, item.columnName);
  }

  drop(event: CdkDragDrop<Column[]>) {
    if ( event.previousContainer === event.container ) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.updateItemAfterContainerChanged();
    }
    // after drop calculate index
    this.calculateIndex();
    // detect changes
    this.detectChangesAnywhere();
  }

  enableItem($event: MatSelectChange) {
    const item: Column = $event.value;
    if ( !item ) {
      return;
    }

    item.visible = false;
    item.selectable = true;
    const pos = this.disabled.indexOf(item);
    if ( pos >= 0 ) {
      this.disabled.splice(pos, 1);
    }
    this.pool.push(item);
    item.orderIndex = this.pool.length;

    this.detectChanges(false, item.columnName);
  }

  initColumnSettings() {
    if ( !(this.initDone && this._columnSettings) ) {
      return;
    }

    const loadedFromServer = this._columnSettings;

    // clear arrays
    this.itemsEdited_.splice(0);
    this.referenceConfig.splice(0);
    this.clearArrays();
    this.isSomethingDirty();

    forIn(loadedFromServer, (item: Column) => {
      this.workConfig.push(cloneDeep(item));
    });

    // sort arrays by index
    this.workConfig.sort(sortByIndex);

    // fill active and pool
    this.fillActiveAndPool();

    // calculate index
    this.calculateIndex();

    // create a reference for matching
    this.referenceConfig = cloneDeep(this.workConfig);
  }

  isSomethingDirty = (): void => {
    // If no elements have been changed return true
    this.isDirty = this.itemsEdited_.length > 0;
    // console.log('isSomethingDirty', isDirty);
    this.dirtyCheckService.submitNextState('ColumnConfComponent', this.isDirty);
  };

  ngOnInit() {
    this.initDone = true;
    this.initColumnSettings();
  }

  onChangeTargetType() {
    this.router.navigateByUrl('/admin/configuration/report-gen-admin/' + this.targetType, {}).then();
  }

  resetSettings() {
    this.columnService.fetchColumnSettings(this.targetType, true)
      .pipe(take(1))
      .pipe(tap(columnSettings => {
        this.columnSettings = columnSettings;
      }))
      .subscribe();
  }

  resetToDefaultSettings() {
    let columnSettings: TableColumnSettings;
    // this will reset texts to the default-default values.
    // this will overwrite any changes from the user administration!
    if ( this.targetType === 'Course' ) {
      columnSettings = DefaultColumnsCourse;
    } else {
      columnSettings = DefaultColumnsCurriculum;
    }

    // clear arrays
    this.clearArrays();

    let orderIndex = 1;
    forIn(columnSettings, (item: Column) => {
      item = cloneDeep(item);
      item.orderIndex = orderIndex++;
      this.workConfig.push(item);
    });

    // fill active and pool
    this.fillActiveAndPool();

    // calculate index
    this.calculateIndex();

    // detect changes
    this.detectChangesAnywhere();
  }

  saveSettings() {
    const data: TableColumnSettings = this.workConfig.reduce((previousValue, columnConfig) => {
      previousValue[columnConfig.columnName] = columnConfig;
      return previousValue;
    }, {});

    this.columnService.updateColumnSettings(this.targetType, data)
      .pipe(tap(columnSettings => this.columnSettings = columnSettings))
      .subscribe();
  }

  showInformation(key: string, event?: Event) {
    let messageKey = '';
    switch ( key ) {
      case 'static':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_STATIC;
        break;
      case 'identifier':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_IDENTIFIER;
        break;
      case 'title':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_TITLE;
        break;
      case 'selectable':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_SELECTABLE;
        break;
      case 'disabled':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_DISABLED;
        break;
      case 'defaultColumns':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_DEFAULT_COLUMNS;
        break;
      case 'poolColumns':
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_POOL_COLUMNS;
        break;
      case 'dropdown':
        // only display message and do not open panel
        event.stopPropagation();
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_DROPDOWN;
        break;
      default:
        messageKey = MessageKey.COLUMN_CONF.COLUMN_CONF_DESCRIPTION_FAIL;
        break;
    }
    this.infoService.showConfirm(messageKey, OkButton);
  }

  validateStatic(item: Column) {
    if ( item.fixed ) {
      // move item to default columns
      item.visible = true;
      // make sure it cannot be unselected
      item.selectable = false;
    } else {
      // item should now be selectable again
      item.selectable = true;
    }
  }

  private calculateIndex() {
    let index = 1;
    forIn(this.selected, (item: Column) => {
      item.orderIndex = index++;
    });
    forIn(this.pool, (item: Column) => {
      item.orderIndex = index++;
    });
    forIn(this.disabled, (item: Column) => {
      item.orderIndex = index++;
    });
  }

  private clearArrays() {
    this.disabled.splice(0);
    this.pool.splice(0);
    this.selected.splice(0);
    this.workConfig.splice(0);
  }

  private fillActiveAndPool() {
    forIn(this.workConfig, (item: Column) => {
      // validate inputs
      if ( item.fixed ) {
        // move item to default columns
        item.visible = true;
        // make sure it cannot be unselected
        item.selectable = false;
      }

      ViewHelper.getViewData(item);
      if ( !(item.selectable || item.fixed) ) {
        this.disabled.push(item);
      } else if ( item.visible ) {
        this.selected.push(item);
      } else {
        this.pool.push(item);
      }
    });
  }

  private updateItemAfterContainerChanged() {
    // for all items in selected now change visible to true
    forIn(this.selected, (item: Column) => {
      item.visible = true;
    });

    // for all items now in pool change visibility to false
    forIn(this.pool, (item: Column) => {
      item.visible = false;
    });
  }

}
