<rag-page-header
  backUrl="/admin/configuration/gamification"
>
  <div class="page-header-title">
    {{title}}
  </div>
  <div class="page-header-buttons">
    <button
      type="button"
      mat-flat-button
      color="primary"
      [disabled]="saveButtonDisabled$ | async"
      (click)="onSave()"
    >
      <span i18n="@@global_save">Save</span>
    </button>
  </div>
</rag-page-header>

<form [formGroup]="form" class="container">
    <div class="container-left">
      <mat-form-field appearance="outline">
        <mat-label>
          <span i18n="@@global_title">Title</span>
        </mat-label>
        <input type="text" matInput formControlName="adminTitle">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          <span i18n="@@global_description">Description</span>
        </mat-label>
        <textarea matInput formControlName="adminDescription" [rows]="10"></textarea>
      </mat-form-field>
    </div>

    <rag-file
      [actionButtonsDisabled]="true"
      [fileInfo]="fileInfo$ | async"
      [file]="uploadedPicture"
      (selectedFile)="onSelectedPicture($event)"
      (removeFile)="onRemovePicture()"
    ></rag-file>

</form>

<div *ngIf="this.badgeTemplate.templateUUID != null && !isDataEmpty">

  <mat-divider></mat-divider>

  <h4 class="headline" i18n="@@badge_templates_usage">This badge template is used in the following content:</h4>

  <ng-container
    *ngIf="isDataLoaded; else tplDataLoading">
    <rag-sticky-scroll [recalculate]="recalculateSticky$">
      <ng-container
        *ngIf="isPaginatorSet && isColumnContextLoaded; else tplDataLoading">
        <table
          [dataSource]="dataSource"
          mat-table
          matSort>

          <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{column.title | localeText}}
            </th>
            <td *matCellDef="let row" mat-cell>
              <rag-tables-column-render-default
                [column]="column"
                [row]="row"
              ></rag-tables-column-render-default>
            </td>
          </ng-container>
          <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
            <th *matHeaderCellDef mat-header-cell>
              <rag-table-header-filter-default
                [column]="column"
                (filterChange)="onFilterChange($event.filter, column)"
              ></rag-table-header-filter-default>
            </th>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef class="actions" mat-header-cell>
              <button
                (click)="tableColumnMenu.openMenu($event)"
                mat-icon-button>
                <mat-icon svgIcon="dots-vertical"></mat-icon>
              </button>
            </th>
            <td *matCellDef="let usage" class="actions" mat-cell>
              <button
                [disabled]="inputDisabled"
                [matMenuTriggerData]="{usage: usage}"
                [matMenuTriggerFor]="badgeTemplateActionsMenu"
                mat-icon-button>
                <mat-icon svgIcon="menu"></mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="actionsFilter">
            <th *matHeaderCellDef class="actions" mat-header-cell>
              <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
                <mat-icon svgIcon="filter-off-outline"></mat-icon>
              </button>
            </th>
          </ng-container>

          <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
          <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
          <tr *matRowDef="let row; columns: columns;" mat-row></tr>
        </table>

        <div *ngIf="isFilteredEmpty" class="no-data">
          <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
        </div>
      </ng-container>
    </rag-sticky-scroll>
    <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
      <mat-paginator
        [disabled]="isDataEmpty"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </ng-container>

</div>

<mat-menu #badgeTemplateActionsMenu xPosition="before">
  <ng-template let-usage="usage" matMenuContent>
    <button
      mat-menu-item
      (click)="onEditUsage(usage)"
    >
      <mat-icon svgIcon="pencil-outline"></mat-icon>
      <span i18n="@@global_edit">Edit</span>
    </button>
  </ng-template>
</mat-menu>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="admin-gamification-usages-list-columns">
</rag-table-column-menu>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>
