import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { InputHelper } from '../../input.types';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';


@Component({
  selector: 'rag-profile-field-text',
  templateUrl: './profile-field-text.component.html',
  styleUrls: [ './profile-field-text.component.scss' ],
})
export class ProfileFieldTextComponent
  extends AbstractProfileFieldComponent<string> {

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  getErrorLinked(): {text: string, link: string, linkText: string} | null {
    const control = this.getControl();
    const error = InputHelper.getError(control);
    const regex = new RegExp('([\\s\\S]*)<[\\s\\S]*href="([\\s\\S]*)">([\\s\\S]*)<', "gm");
    const match = regex.exec(error);
    if (match != null && match[0] != null && match[1] != null && match[2] != null) {
      return {text: match[1], link: match[2], linkText: match[3]};
    }
    return {text: error, link:'', linkText:''};
  }

}
