import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[ragDefault]',
})
/**
 * @see https://medium.com/@sub.metu/angular-fallback-for-broken-images-5cd05c470f08
 */
export class ImageDefaultDirective {
  @Input() ragDefault: string;
  @HostBinding() @Input() src: string;

  constructor() {
  }

  @HostListener('error')
  setDefaultSrc() {
    this.src = this.ragDefault;
  }

}
