import { Component, Input } from '@angular/core';
import { ChangeNotesTocEntry } from './change-notes-toc.types';


@Component({
  selector: 'rag-change-notes-toc',
  templateUrl: './change-notes-toc.component.html',
  styleUrls: [ './change-notes-toc.component.scss' ],
})
export class ChangeNotesTocComponent {

  @Input() headlines: ChangeNotesTocEntry[];

  getLevelClass(headline: ChangeNotesTocEntry): string {
    return `level-${headline?.level ?? 1}`;
  }

}
