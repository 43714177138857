<div class="container">
  <ng-container *ngIf="hasSchedules; else noSchedules">
    <!-- Back -->
    <button
      mat-icon-button
      class="nav-button"
      [disabled]="!hasPrevSchedule"
      (click)="onBack()">
      <mat-icon svgIcon="chevron-left"></mat-icon>
    </button>

    <div class="info-panel" [ngClass]="{'fade-in': fadeIn, 'fade-out': fadeOut}">

      <div class="row">
        <div class="icon">&nbsp;</div>
        <div class="flex">
          <span i18n="@@catalog_schedule_index" class="label">Schedule</span>
          <span class="label" *ngIf="schedulesCount > 1">&nbsp;{{scheduleIndex}}/{{schedulesCount}}</span>
        </div>
      </div>

      <div class="row">
        <div class="icon">
          <mat-icon svgIcon="calendar-outline"></mat-icon>
        </div>
        <div>
          {{eventDateFormatted}} - {{eventTimeFormatted}}
        </div>
      </div>

    </div>

    <!-- Forth -->
    <button
      mat-icon-button
      class="nav-button"
      [disabled]="!hasNextSchedule"
      (click)="onForth()">
      <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>

  </ng-container>
</div>

<div class="block-event label" *ngIf="blockEvent">
  <span i18n="@@appointment_series">Appointment series</span>
</div>

<ng-template #noSchedules>
  <div class="no-data no-data-fix">
    <span i18n="@@no_schedules_available_yet">No schedules available yet</span>
  </div>
</ng-template>
