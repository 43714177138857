<div class="container">
  <div class="row">
    <div class="block label">
      <span i18n="@@admin_courses_type_toDo">Task</span>
    </div>
    <div class="block label">
      <span i18n="@@learner_user_course_todo_solution">Solution</span>
    </div>
  </div>

  <div class="row">
    <div class="block noflex text" [innerHTML]="courseTodo.taskDescription | localeText | safe:'html'"></div>
    <div class="block noflex text" [innerHTML]="courseTodo.contribution?.contribution | localeText | safe:'html'"></div>
  </div>

  <div class="row">
    <div class="block label">
      <span i18n="@@global_files">Files</span>
    </div>
  </div>

  <div class="row">
    <div class="block">
      <rag-file-multi
        [files]="courseTodo.attachments"
        [fitVertical]="true"
        [readonly]="true">
      </rag-file-multi>
    </div>
    <div class="block">
      <rag-file-multi
        [files]="courseTodo.contribution?.files"
        [fitVertical]="true"
        [readonly]="true">
      </rag-file-multi>
    </div>
  </div>
</div>
