import { ApiUrls } from '../../../core/api.urls';
import { ApiResponse } from '../../../core/global.types';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PasswordService {

  constructor(
    private http: HttpClient,
  ) {
  }

  generatePassword() {
    const url = ApiUrls.getKey('GeneratePassword');
    return this.http.put<ApiResponse<any>>(url, null)
      .pipe(map(response => response.pass));
  }

}
