import { Component, Inject } from '@angular/core';
import { CtrlSingleUserCurriculumTypes } from '../ctrl-single-user-curriculum.types';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { CtrlSingleUserCurriculumEventsColumns } from './ctrl-single-user-curriculum-events.columns';
import { TableControllerComponent } from '../../../../../component/table/table-controller/table-controller.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { CtrlSingleUserCurriculumEventsService } from './ctrl-single-user-curriculum-events.service';
import { PrincipalService } from '../../../../../core/principal/principal.service';

export interface CtrlSingleUserCurriculumEventsParams extends CtrlSingleUserCurriculumTypes.CurriculumEventResponse {
  cannotChangeData?: boolean;
}

@Component({
  selector: 'rag-ctrl-single-user-curriculum-events',
  templateUrl: './ctrl-single-user-curriculum-events.component.html',
  styleUrls: [ './ctrl-single-user-curriculum-events.component.scss' ],
})
export class CtrlSingleUserCurriculumEventsComponent
  extends TableControllerComponent<CtrlSingleUserCurriculumTypes.CurriculumEvent,
    CtrlSingleUserCurriculumEventsColumns.ColumnMenuData> {

  canChangeData = false;

  constructor(
    private ctrlSingleUserCurriculumEventsService: CtrlSingleUserCurriculumEventsService,
    public dialogRef: MatDialogRef<CtrlSingleUserCurriculumEventsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserCurriculumEventsParams,
    private principalService: PrincipalService,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);
    this.defaultSort = 'expires';

    this.dataSource.filterPredicate = this.filterPredicateDefault;

    this.updateEventData(data);
  }

  onRecalculate(): void {
    this.inputDisabled = true;
    const userId = this.data?.userId;
    const curriculumId = this.data?.curriculumId;
    this.ctrlSingleUserCurriculumEventsService.recalculateEvents(userId, curriculumId)
      .pipe(take(1))
      .pipe(switchMap(() => this.ctrlSingleUserCurriculumEventsService.getEvents(userId, curriculumId)))
      .pipe(take(1))
      .pipe(tap(this.updateEventData))
      .pipe(finalize(() => this.inputDisabled = false))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(
      CtrlSingleUserCurriculumEventsColumns.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateEventData = (data: CtrlSingleUserCurriculumEventsParams): void => {
    this.canChangeData = data?.userId !== this.principalService.userId && !data.cannotChangeData;
    const events: CtrlSingleUserCurriculumTypes.CurriculumEvent[] = data?.events;
    this.updateAvailableColumns();
    this.setTableData(events ?? []);
    this.inputDisabled = false;
    this.checkFilter();
  };

}
