<div class="container">

  <div *ngIf="hasCaption()" class="caption">{{fragment.caption}}</div>

  <form [formGroup]="form">
    <div *ngFor="let item of fragment.items">
      <label [attr.for]="item.uuid">
        {{item.text}}
      </label>
      <mat-radio-group
        [attr.id]="item.uuid"
        [formControlName]="item.uuid">
        <div *ngFor="let option of fragment.options">
          <mat-radio-button [value]="option.value" [attr.ng-trigger]="conditionIdIfAny(option)">
            {{option.label}}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </form>

</div>