import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../component/table/table-controller/table-controller.types';
import { TableColumnBuilder } from '../../../../component/table/table-column.builder';
import { SignatureDetails } from '../../admin-signatures/admin-signatures.types';

export interface TextMacrosListColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: TextMacrosListColumnMenuItemMap;
}

export interface TextMacrosListColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  macro: TableControllerTypes.ColumnMenuItem;
  name: TableControllerTypes.ColumnMenuItem;
  description: TableControllerTypes.ColumnMenuItem;
  id: TableControllerTypes.ColumnMenuItem;
  creationDate: TableControllerTypes.ColumnMenuItem;
  content: TableControllerTypes.ColumnMenuItem;
  actions: TableControllerTypes.ColumnMenuItem;
}

export const TEXT_MACROS_LIST_COLUMNS: TextMacrosListColumnMenuData = {
  startWith: [ 'id' ],
  endWith: [ 'actions' ],
  menuItems: {

    id: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('id')
      .withTitle($localize`:@@global_text_macro_id:Text macro Id`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    name: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('name')
      .withTitle($localize`:@@global_title:Title`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    macro: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('macro')
      .withTitle($localize`:@@global_macro:Macro`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    creationDate: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('creationDate')
      .withTitle($localize`:@@global_added:Added`)
      .withType(TableColumnDataType.dateTime)
      .withSelected()
      .build(),

    content: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('content')
      .withTitle($localize`:@@content:Content`)
      .withType(TableColumnDataType.html)
      .withSelected(false)
      .build(),

    description: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('description')
      .withTitle($localize`:@@global_description:Description`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),

    actions: TableColumnBuilder.start<SignatureDetails>()
      .withColumnId('actions')
      .withHiddenSelected()
      .withType(TableColumnDataType.other)
      .build(),
  }
};
