import { Component, Input, OnInit } from '@angular/core';
import { Content } from '../../../core/content/content.types';

@Component({
  selector: 'rag-curriculum-info',
  templateUrl: './curriculum-info.component.html',
  styleUrls: [ './curriculum-info.component.scss' ],
})
export class CurriculumInfoComponent
  implements OnInit {
  availableSince: Date | number;
  @Input() curriculum: Content;
  description: string;
  dueBy: Date | number;
  hasValidity: boolean;
  @Input() showDescription = true;
  validSince: Date | number;

  constructor() {
  }

  ngOnInit() {
    if ( this.curriculum ) {
      this.availableSince = this.curriculum.startdate;
      this.dueBy = this.curriculum.lastValidUntil;
      this.description = this.curriculum.description;
      this.validSince = this.curriculum.lastValidSince;
    }
    this.hasValidity = (this.availableSince > 0) || (this.dueBy > 0) || (this.validSince > 0);
  }

}
