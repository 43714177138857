import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LearnerGamificationOverviewData } from '../../../core/gamification/gamification.types';
import { Observable } from 'rxjs';
import { LearnerGamificationService } from '../../../core/gamification/learner-gamification.service';

@Injectable({
  providedIn: 'root',
})
export class GamificationOverviewResolver
  implements Resolve<LearnerGamificationOverviewData> {

  constructor(
    private learnerGamificationService: LearnerGamificationService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LearnerGamificationOverviewData> {
    // const response: LearnerGamification = {
    //   badges: [],
    //   gamificationProfile: {
    //     count: null,
    //     level: 2,
    //     xp: 11,
    //   }
    // };
    // return of(response);
    return this.learnerGamificationService.getGamificationData();
  }
}
