import { Injectable } from '@angular/core';
import { ControllingSingleUserService } from '../../../../ctrl-single-user-util/ctrl-single-user.service';
import { Observable } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';
import { CtrlSingleUserCurrIterationStatusDialogComponent } from './ctrl-single-user-curr-iteration-status-dialog.component';
import { InfoService } from '../../../../../../../core/info/info.service';


@Injectable({ providedIn: 'root' })
export class CtrlSingleUserCurrIterationStatusDialogService {

  constructor(
    private controllingSingleUserService: ControllingSingleUserService,
    private infoService: InfoService,
  ) {
  }

  showDialog(userId: number, curriculumId: number, iteration: number): Observable<void> {

    return this.controllingSingleUserService.getCurriculumIterationStatus(userId, curriculumId, iteration)

      .pipe(switchMap(status => this.infoService
        .showDialog(CtrlSingleUserCurrIterationStatusDialogComponent, {
          iteration,
          status,
        })))

      .pipe(mapTo(void (0)));
  }

}
