import {
  AbstractLearnerAccountViewParent,
  LearnerAccountTypes,
} from '../../../../core/learner-account/learner-account.types';
import { Translation } from '../../../../core/translation/translation.types';
import { Core, FileInfo, Identifiable } from '../../../../core/core.types';

// TODO: move it to global scope, perhaps core
export namespace LearnerContentTypes {

  export enum ExtensionType {
    Estimation = 'ESTIMATION',
    SampleSolution = 'SAMPLE_SOLUTION',
  }
  export interface Extensions {
    text: Translation;
    files: FileInfo[];
    onlyForControlling: boolean;
    type: ExtensionType;
  }
  export enum PublishStatus {
    draft = 'DRAFT',
    published = 'PUBLISHED',
    archived = 'ARCHIVED',
    deleted = 'DELETED',
  }

  export interface UpdateContext {
    courseId?: number;
    curriculumId?: number;
    curriculumItemId?: number;
  }

  export interface Contribution
    extends Identifiable {
    comment: boolean;
    contribution: string;
    creationDate: number;
    files?: FileInfo[];
    lastModified: number;
    targetIteration: number;
    uuid: string;
    status: PublishStatus;
  }

  export interface CourseAccount
    extends AbstractLearnerAccountViewParent, LearnerAccountTypes.CourseWithAcceptanceMask {
    assignmentMandatory: boolean;
    attachments: FileInfo[];
    courseType: Core.CourseType;
  }

  export interface CourseAccountToDo
    extends CourseAccount {
    contribution?: Contribution;
    taskDescription: Translation;
    extensions: ExtensionType[];
  }

}
