import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogModule,
  ],
  selector: 'rag-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: [ './delete-dialog.component.scss' ],
})
export class DeleteDialogComponent
  implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>) {
  }

  ngOnInit() {
  }

  onDeleteClick() {
    this.dialogRef.close(true);
  }

  onKeepClick() {
    this.dialogRef.close(false);
  }

}
