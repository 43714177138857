<rag-dialog-header>
  <span i18n="@@ctrl_single_user_curriculum_notifications">Notifications</span>
</rag-dialog-header>

<div mat-dialog-content>

  <ng-template #tplDataEmpty>
    <div class="no-data">
      <span i18n="@@ctrl_single_user_curriculum_no_notifications">There are no notifications for this curriculum.</span>
    </div>
  </ng-template>

  <ng-template #tplDataLoading>
    <div class="no-data">
      <rag-loading-indicator
      ></rag-loading-indicator>
    </div>
  </ng-template>

  <div
    *ngIf="(renderColumns?.length > 0) else tplDataLoading"
    class="content"
  >
    <rag-sticky-scroll [recalculate]="recalculateSticky$">
      <ng-container
        *ngIf="isPaginatorSet; else tplDataLoading"
      >
        <table
          *ngIf="(dataSource.data?.length > 0); else tplDataEmpty"
          [dataSource]="dataSource"
          mat-table
          matSort
        >
          <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{column.title | localeText}}
            </th>
            <td *matCellDef="let row" mat-cell>
              <rag-tables-column-render-default
                [row]="row"
                [column]="column"
              ></rag-tables-column-render-default>
            </td>
          </ng-container>
          <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
            <th *matHeaderCellDef mat-header-cell>
              <rag-table-header-filter-default
                [column]="column"
                (filterChange)="onFilterChange($event.filter, column)"
              ></rag-table-header-filter-default>
            </th>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef mat-cell></td>
          </ng-container>
          <ng-container matColumnDef="actionsFilter">
            <th *matHeaderCellDef mat-header-cell>
              <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
                <mat-icon svgIcon="filter-off-outline"></mat-icon>
              </button>
            </th>
          </ng-container>

          <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
          <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
          <tr *matRowDef="let row; columns: columns;" mat-row></tr>
        </table>

        <div class="no-data" *ngIf="isFilteredDataEmpty">
          <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
        </div>
      </ng-container>

    </rag-sticky-scroll>
    <div [ngClass]="{paginatorInVisibility: isFilteredDataEmpty}">
      <mat-paginator
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-flat-button
    color="primary"
    [disabled]="inputDisabled || !canChangeData"
    (click)="onRecalculate()"
  >
    <span i18n="@@ctrl_single_user_recalculate">Recalculate</span>
  </button>

  <div class="flex-spacer"></div>

  <button
    type="button"
    [mat-dialog-close]
    class="rag-stroked-button"
    mat-stroked-button
  >
    <span i18n="@@global_close">Close</span>
  </button>
</div>
