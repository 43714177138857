import {
  AnyObject,
  Core,
  FileInfo,
  Identifiable,
  ImageableContentReference,
  NumberedAnyObject,
  Principal, TransientView,
  UserGroup,
} from './core.types';
import { ViewData } from './view-helper';
import { Translation } from './translation/translation.types';
import { LearnerAccountTypes } from './learner-account/learner-account.types';
import { UserContentAction, UserContentRelation } from './content/content.types';
import { MacroContextSettings } from './macros.types';
import { TextMacroResponse } from '../route/admin/admin-text-macros/admin-text-macros.types';
import { Legacy } from './legacy/legacy.types';
import { BadgeTemplate } from './gamification/gamification.types';
import { Curriculum } from './curriculum/curriculum.types';


export namespace AdminCoursesTypes {

  import CourseType = Core.CourseType;
  import DistributableType = Core.DistributableType;

  export interface CourseExtension {
    type: Extensions;
    [key: string]: any;
  }

  export interface SampleSolutionExt extends CourseExtension {
    text: Translation;
    files: Array<FileInfo>;
    onlyForControlling: boolean;
  }

  export type EstimationExt = CourseExtension;

  export enum Extensions {
    Estimation = 'ESTIMATION',
    SampleSolution = 'SAMPLE_SOLUTION',
  }

  export interface CourseResponse extends Identifiable {
    course: Course;
    extensions?: CourseExtension[];
  }

  export interface CourseExtensionsRequest {
    extensions: CourseExtension[];
  }

  export interface CourseWithExtensions {
    course: Course;
    extensions?: CourseExtensionsRequest;
  }

  export interface AvailableCourseTags {
    tags: string[];
  }

  export interface CourseTags {
    data: Legacy.Tag[];
  }

  export interface CourseView extends ViewData {
    coursePicture?: File;
    extensions?: Extensions[];
  }

  export interface Course
    extends Identifiable, Core.WithOptionalRbacActions<Core.RbacActionsElearning>, ImageableContentReference<CourseView> {
    attachments?: FileInfo[];
    courseId: number;
    courseType: Core.CourseType;
    description: Translation;
    shortTitle: Translation;
    adminTitle: string;
    status: LearnerAccountTypes.CoursePublishStatusLowerCase;
    title: Translation;
    creationDate: number;
    lastModified: number;
    confirmActive?: boolean;
    extensions?: Extensions[];
    order?: number;
    tags?: string;
    titleColumn?: string;
    resolution?: string;
    pictureId?: number;
    pictureUUID?: string;
    overrideHref?: string;
    href?: string;
    usedAsOverrideHref?: boolean;
    language?: string;
  }

  export interface CourseTodo
    extends Course, LearnerAccountTypes.CourseWithAcceptanceMask {
    taskDescription: Translation;
    trainerIds?: Array<number>;
    trainers?: Array<Principal>;
  }

  export interface CourseScoSaveData {
    id: number;
    title: string;
    href: string;
    masteryScore: string;
    maxTimeAllowed: string;
  }

  export interface CourseRedeploySaveData {
    content?: File;
    url?: string;
    changeNote: string;
    forceAccountReset: boolean;
    versionNumber?: string;
  }

  export interface CourseSaveData {
    id?:  number;
    adminTitle?: string;
    type?: CourseType;
    status?: string;
    language?: string;
    overrideHref?: string;
    hasConfirmation?: boolean;
    hasWarning?: boolean;
    downloadable?: boolean;
    resultUserVisible?: boolean;
    viewableLoggedOut?: boolean;
    typicalLearningTime?: string;
    typicalLearningTimeDimension?: LearningDimension;
    executionModus?: string;
    relevantItem?: any;
    resolution?: any;
    orignator?: string;
    editor?: string;
    topic?: string;
    specialIntegration?: any;
    pictureId?: number;
    cardPictureUUID?: string;
    scos?: CourseScoSaveData[];
    texts?: AnyObject<CourseSettingsTexts>;
    description?: string;
  }

  export interface CourseDetails {
     id: number;
     courseType: Core.CourseType;
     shortTitle: Translation;
     title: Translation;
     description: Translation;
     status: LearnerAccountTypes.CoursePublishStatusLowerCase;
     tags: string;
  }

  export interface CourseDetailsResponse {
     course: CourseDetails;
  }

  export interface CourseDetailsV2 {
    course: AdminCoursesTypes.Course;
    tags: string[];
  }

  export interface  CourseVersionHistorySupport {
    createdBy: number;
    createdByAsString: string;
    changeNote: string;
    rawSize: number;
    forceAccountReset: boolean;
    creationDate: number;
    versionNumber: string;
    zippedSize: number;
  }

  export enum LearningDimension {
    minutes = 1,
    hours = 2,
  }

  export interface CourseContent {
    versions: CourseVersionHistorySupport[];
    id: number;
  }

  export interface CourseSco {
    id: number;
    href: string;
    title: string;
    maxTimeAllowed: number;
    masteryscore: number;
  }

  export interface TableDataCourseSco {
    id: number;
    directory: string;
    startFile: string;
    title: string;
    maxTimeAllowed: string;
    masteryscore: number;
  }

  export interface CourseContentVersionDialogData {
    version: string;
    description?: string;
    forceAccountReset?: boolean;
  }

  export interface CourseRedeploymentResponse {
    changes: CourseRedeploymentResponseData;
  }

  export interface CourseRedeploymentResponseData {
    title: OldNewValue;
    scos: CourseRedeploymentSco[];
  }

  export interface CourseRedeploymentSco {
    id: number;
    href?: OldNewValue;
    title?: OldNewValue;
    masteryScore?: OldNewValue;
  }

  export interface CourseContentChange {
    id?: number;
    attributeName: Translation;
    attribute: string;
    oldValue: string;
    newValue: string;
  }

  export interface OldNewValue {
    oldValue: string;
    newValue: string;
  }

  export interface CourseOther {
    editor: string;
    scos: CourseSco[];
    id: number;
    orignator: string;
  }

  export interface CourseContentResponse {
    details: AdminCoursesTypes.Course;
    content: CourseContent;
  }

  export interface CourseOtherResponse {
    details: AdminCoursesTypes.Course;
    other: CourseOther;
  }

  export interface CourseNotification {
    id: number;
    title: string;
    desc: string;
    evt: string;
    evtId: string;
    evtModId: string;
    enabled: boolean;
    delay: string;
    par: string;
    tar: string;
  }

  export interface CourseCurricula {
    data: Curriculum.UsageDetails[];
    count: number;
  }

  export interface CourseCurriculaResponse {
    details: Course;
    curricula: Curriculum.UsageDetails[];
  }

  export interface CourseNotificationsResponse {
    details: Course;
    notifications: CourseNotification[];
  }

  export interface CourseSettingsTexts {
    context?: string;
    confirmationHint?: string;
    adminTitle?: string;
    courseLanguages?: string;
    language?: string;
    href?: string;
    shortTitle?: string;
    state?: string;
    title?: string;
    description?: string;
    warningText?: string;
    warningTitle?: string;
  }

  export interface CourseSettings {
    hasWarning: boolean;
    resultUserVisible: boolean;
    downloadable: boolean;
    hasConfirmation?: boolean;
    viewableLoggedOut: boolean;
    // tbd
    texts: AnyObject<CourseSettingsTexts>;
    id: number;
  }

  export interface EditCourseResponse {
    details?: Course;
    content?: AdminCoursesTypes.LearningProgram | AdminCoursesTypes.ScoDocument | AdminCoursesTypes.Link;
    settings?: AdminCoursesTypes.CourseSettings;
    other?: AdminCoursesTypes.CourseOther;
    textMacros?: MacroContextSettings;
  }

  export interface CourseDurationSupport {
    typicalLearningTimeDimension: LearningDimension;
    typicalLearningTime: number;
  }

  export interface CourseLanguageSupport {
    language: string;
  }
  export interface LearningProgram
    extends CourseDurationSupport, CourseLanguageSupport, CourseContent {
  }

  export interface Test
    extends CourseDurationSupport, CourseLanguageSupport, CourseContent {
  }

  export interface ScoDocument
    extends CourseDurationSupport, CourseLanguageSupport, CourseContent {
  }

  export interface Link
    extends CourseDurationSupport, CourseLanguageSupport, CourseContent {
    context: {
      url: string;
    };
    // link: string;
  }

  export enum SaveSupportComponents {
    AdminCoursesDetailsComponent = 'AdminCoursesDetailsComponent',
    AdminCoursesTodoComponent = 'AdminCoursesTodoComponent',
    AdminCoursesSampleSolutionComponent = 'AdminCoursesSampleSolutionComponent',
  }

  export const saveSupportGetRequired = (course: AdminCoursesTypes.Course): string[] => {
    switch ( course.courseType ) {
      case Core.CourseType.ToDo:
        const hasSampleSolution = course?.extensions?.find(extension => extension === AdminCoursesTypes.Extensions.SampleSolution) != null;
        if (hasSampleSolution) {
          return [
            SaveSupportComponents.AdminCoursesDetailsComponent,
            SaveSupportComponents.AdminCoursesTodoComponent,
            SaveSupportComponents.AdminCoursesSampleSolutionComponent,
          ];
        } else {
          return [
            SaveSupportComponents.AdminCoursesDetailsComponent,
            SaveSupportComponents.AdminCoursesTodoComponent,
          ];
        }
    }
    return [];
    // todo implement missing course types
    // throw Error('not implemented yet!');
  };

  export const updateAcceptanceMask = (course: LearnerAccountTypes.CourseWithAcceptanceMask, flag: number, checked: boolean): void => {
    if ( (course == null) || (flag == null) || (checked == null) ) {
      return;
    }
    if ( checked ) {
      course.acceptanceMask |= flag;
    } else {
      course.acceptanceMask &= ~flag;
    }
  };

}

export interface CourseListResponse {
  courses: AdminCoursesTypes.Course[];
  relations: UserContentRelation[];
  actions: UserContentAction[];
}

export interface CourseListResolverResponse extends CourseListResponse {
  users: any;
  userGroups: UserGroup[];
  /*
  key: userId, value: groupId[]
   */
  user2Groups: NumberedAnyObject<number[]>;
}
