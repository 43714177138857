import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Directive({
  selector: '[ragTouch]',
})
export class TouchDirective
  implements AfterViewInit, OnDestroy {

  @Output()
  readonly touchEnd: Observable<TouchEvent>;
  @Output()
  readonly touchStart: Observable<TouchEvent>;
  private _touchEnd = new EventEmitter<TouchEvent>();
  private _touchStart = new EventEmitter<TouchEvent>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
  ) {
    this.touchEnd = this._touchEnd.asObservable();
    this.touchStart = this._touchStart.asObservable();
  }

  ngAfterViewInit(): void {
    const node = this.elementRef?.nativeElement;
    if ( !node ) {
      return;
    }

    this.unbindEventListeners(node);

    node.addEventListener('touchstart', this.onTouchStart);
    node.addEventListener('touchend', this.onTouchEnd);
  }

  ngOnDestroy(): void {
    this.unbindEventListeners(this.elementRef?.nativeElement);
  }

  private onTouchEnd = (event: TouchEvent): void => {
    this._touchEnd.next(event);
  };

  private onTouchStart = (event: TouchEvent): void => {
    this._touchStart.next(event);
  };

  private unbindEventListeners(node: HTMLElement): void {
    if ( !node ) {
      return;
    }

    node.removeEventListener('touchstart', this.onTouchStart);
    node.removeEventListener('touchend', this.onTouchEnd);
  }

}
