import { Component, Input, OnInit } from '@angular/core';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';

@Component({
  selector: 'rag-catalog-card-course',
  templateUrl: './catalog-card-course.component.html',
  styleUrls: ['./catalog-card-course.component.scss']
})
export class CatalogCardCourseComponent implements OnInit {

  @Input() catalogEntry: Catalogs.CatalogEntry;

  constructor() { }

  ngOnInit(): void {
  }

  get objSubTypeAsText() {
    if (this.catalogEntry.objSubType !== undefined) {
      return DistributionTypeHelper.asText(this.catalogEntry.objType, this.catalogEntry.objSubType);
    }
    return null;
  }

  get hasDistSubType() {
    return this.catalogEntry.objSubType !== undefined;
  }

}
