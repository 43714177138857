import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ColoredDateComponent } from './colored-date.component';

@NgModule({
  declarations: [
    ColoredDateComponent,
  ],
  exports: [
    ColoredDateComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
  ],
})
export class ColoredDateModule {
}
