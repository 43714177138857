import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {map, tap} from "rxjs/operators";
import {destroySubscriptions, takeUntilDestroyed} from "../../core/reactive/until-destroyed";
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { BotService } from 'src/app/core/bot.service';
import { environment } from 'src/environments/environment';

declare class BotApi {
  userBypass(test: string): void;
}

declare function botApi(string, boolean);

@Component({
  standalone: true,
  selector: 'rag-bot-dialog',
  templateUrl: './bot-dialog.component.html',
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PipesModule
  ],
  styleUrls: ['./bot-dialog.component.scss']
})
export class BotDialogComponent implements OnInit, OnDestroy {

  protected botURL: string;
  protected currentBypassInformation: string[] = [];
  protected visible = false;
  protected dialogOpen = false;

  private botApi: BotApi;

  constructor(
    private botService: BotService
  ) {}

  ngOnInit(): void {

    this.botService.bypassInformation$.pipe(tap(message=> {
      if (this.dialogOpen) {
        this.botApi.userBypass(message);
      }
      this.currentBypassInformation.push(message);
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe()

    this.botService.visible$
      .pipe(map(event => {
        this.botURL = event.url;
        this.visible = event.visible;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  toggleOpen() {
    this.dialogOpen = !this.dialogOpen;
    if (!this.dialogOpen) {
      this.botApi = undefined;
      return;
    }
    setTimeout(async () => {
      if (this.botApi === undefined) {
        this.botApi = await botApi("bot", !environment.production);
      }
      if (this.visible && (this.currentBypassInformation != null)) {
        while (this.currentBypassInformation.length > 0) {
          this.botApi.userBypass(this.currentBypassInformation.shift());
        }
      }
    });
  }
}

