<rag-dialog-header>
  <span i18n="@@global_warning">Warning</span>
</rag-dialog-header>

<div mat-dialog-content>
  <div i18n="@@offline_cnt_relations_info">This object is connected to the following contents:</div>

  <ul>
    <li *ngFor="let usage of usages">
      <span class="title">{{usage.objectTitle | localeText}}</span>
      <span *ngIf="!usage.active" class="removed" i18n="@@offline_cnt_relations_removed">(removed)</span>
    </li>
  </ul>

  <ng-template #canNotDelete>
    <h3 i18n="@@offline_cnt_relations_cannotdelete">This object is still in use and cannot be deleted!</h3>
  </ng-template>
  <ng-container *ngIf="actionEdit || canDelete; else canNotDelete">
    <h3 i18n="@@offline_cnt_relations_confirm">Would you like to proceed?</h3>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button [mat-dialog-close]="false" class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    *ngIf="actionEdit"
    [mat-dialog-close]="true"
    color="primary"
    mat-flat-button
  >
    <span i18n="@@global_update">Update</span>
  </button>

  <button
    *ngIf="actionRemove"
    [disabled]="!canDelete"
    [mat-dialog-close]="true"
    color="primary"
    mat-flat-button
  >
    <span i18n="@@global_delete">Delete</span>
  </button>
</div>
