<div class="container">
  <!-- / -->
  <div class="contents">
    <rag-content-overview
      #overviewComponent
      [didReceiveResponse]="didReceiveResponse"
      [embedded]="true"
      [maxItems]="9"></rag-content-overview>
  </div>
  <div class="actions">
    <a [queryParams]="linkParams"
       color="primary"
       mat-flat-button
       routerLink="/content-overview">
      {{linkLabel}}
    </a>
  </div>
</div>
<!-- Custom menu item -->
<ng-template #viewMode>
  <button (click)="toggleViewMode()" mat-button>
    <mat-icon [svgIcon]="isBoxViewMode ? 'view-list' : 'view-module'" color="primary"></mat-icon>
    <span *ngIf="!isBoxViewMode" i18n="@@overview_cards_view">Cards view</span>
    <span *ngIf="isBoxViewMode" i18n="@@global_list_view">List view</span>
  </button>
</ng-template>
