import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplicationStateService, ViewMode} from '../../../core/application-state.service';
import {combineLatest, Observable} from 'rxjs';
import {DesignSettings} from '../../admin/account-design/account-design.types';
import {AccountDesignService} from '../../admin/account-design/account-design.service';
import {LanguageHelper, LanguageInfo} from '../../../core/language.helper';
import {NavigationService, PathInfo} from '../../../core/navigation/navigation.service';
import {map, take, tap} from 'rxjs/operators';
import {CachedSubject} from '../../../core/cached-subject';
import {AsyncPipe, CommonModule} from '@angular/common';
import {PipesModule} from '../../../core/pipes/pipes.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {LoginFormV2Module} from '../../../component/login/login-form-v2/login-form-v2.module';
import {MatButtonModule} from '@angular/material/button';
import {destroySubscriptions, takeUntilDestroyed} from '../../../core/reactive/until-destroyed';
import {PrincipalService} from '../../../core/principal/principal.service';
import {RouterModule} from '@angular/router';
import {Catalogs} from '../../../core/catalog/catalog.types';
import {CatalogService} from '../../../core/catalog/catalog.service';
import {ImageUrlHelper} from '../../../core/image-url-helper';
import CatalogState = Catalogs.CatalogState;

interface DisplayData {
  welcomeTexts: string,
  applyCustomColors: boolean,
  loginFormTexts: string,
  backgroundStyles: any,
  legalNotice: string,
  terms: string,
  policies: string,
  poweredBy: string,
  logoUrl: string,
}

@Component({
  standalone: true,
  selector: 'rag-login-v2',
  templateUrl: './login-v2.component.html',
  imports: [
    AsyncPipe,
    CommonModule,
    PipesModule,
    MatMenuModule,
    MatIconModule,
    LoginFormV2Module,
    MatButtonModule,
    RouterModule,
  ],
  styleUrls: ['./login-v2.component.scss']
})
export class LoginV2Component implements OnInit, OnDestroy {
  languages: LanguageInfo[];
  currentLanguage: LanguageInfo;
  currentLanguagePath: PathInfo;
  pictureUrl = '';
  catalogState: CatalogState;
  @Input() previewMode: boolean = false;
  @Input() texts: DesignSettings;
  private _data$ = new CachedSubject<DisplayData>(null);
  readonly data$: Observable<DisplayData>;

  protected readonly LanguageHelper = LanguageHelper;

  constructor(
    private accountDesignService: AccountDesignService,
    private applicationStateService: ApplicationStateService,
    private catalogService: CatalogService,
    private navigationService: NavigationService,
    private principalService: PrincipalService,
  ) {
    this.data$ = this._data$.withoutEmptyValues();

    this.principalService.isLogged$
      .pipe(tap(() => this.updateLanguageInfo()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.catalogService.getCatalogState()
      .pipe(take(1))
      .pipe(map(catalogState => this.catalogState = catalogState))
      .subscribe();
  }


  ngOnDestroy(): void {
    this.applicationStateService.setViewMode(ViewMode.DEFAULT);
    destroySubscriptions(this);
  }

  ngOnInit() {
    if (!this.previewMode) {
      this.applicationStateService.setViewMode(ViewMode.FULLSCREEN);

      combineLatest([
        this.accountDesignService.getStartPage(),
        this.accountDesignService.getStyleSettings()
      ])
        .pipe(map(([startPage, styleSettings]) => {
          return {
            welcomeTexts: LanguageHelper.objectToText(startPage.acc.welcomeTexts),
            applyCustomColors: startPage.acc.applyCustomColors,
            loginFormTexts: LanguageHelper.objectToText(startPage.acc.loginFormTexts),
            backgroundStyles: {
              'background-size': 'cover',
              'background-image': `url(${startPage.welcomePictureUrl})`
            },
            legalNotice: styleSettings.acc.legalNotice,
            terms: styleSettings.acc.terms,
            policies: styleSettings.acc.policies,
            poweredBy: styleSettings.acc.poweredBy,
            logoUrl: styleSettings.logoUrl
          };
        }))
        .pipe(tap(this._data$.next))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    } else {
      const url = ImageUrlHelper.urlForPicture(this.texts.welcomePictureId, null) ??
        'assets/images/train_bg_login.jpg';
      this.accountDesignService.getStyleSettings()
        .pipe(tap(styleSettings => {
          this._data$.next({
            welcomeTexts: LanguageHelper.objectToText(this.texts.welcomeTexts),
            applyCustomColors: this.texts.applyCustomColors,
            loginFormTexts: LanguageHelper.objectToText(this.texts.loginFormTexts),
            backgroundStyles: {
              'background-size': 'cover',
              'background-image': `url(${url})`
            },
            legalNotice: styleSettings.acc.legalNotice,
            terms: styleSettings.acc.terms,
            policies: styleSettings.acc.policies,
            poweredBy: styleSettings.acc.poweredBy,
            logoUrl: styleSettings.logoUrl,
          });
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }
  }

  checkLink(link: string): boolean {
    if (link == null) {
      return false;
    }
    const linkTrim = link.trim();
    return linkTrim !== '';
  }

  getLanguageFlag(language: LanguageInfo): string {
    return LanguageHelper.getLanguageFlag(language);
  }

  onSwitchToLanguage(language: LanguageInfo): void {
    if (this.previewMode) {
      return;
    }

    const languageKey = language?.key;
    const trainLanguage = language?.trainLanguage;
    const languagePath = NavigationService.getLanguagePathFromLocale(languageKey);
    if (trainLanguage && this.currentLanguagePath?.languagePath !== languagePath) {
      try {
        localStorage.setItem('switched_language', trainLanguage);
      } catch (e) {
        if (console && console.error) {
          console.error(e);
        }
      }
      this.navigationService.switchToLocale(languageKey);
    }
  }

  private updateLanguageInfo(): void {
    this.languages = LanguageHelper.LANGUAGES;
    this.currentLanguagePath = this.navigationService.getCurrentLanguage();
    this.currentLanguage = LanguageHelper.getLanguage(this.currentLanguagePath?.language);
  }

}
