import { Component } from '@angular/core';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import {
  ColumnFilterDropdownOption,
  dropdownOptionsFactory,
  FilterOperator,
} from '../../../core/column-settings/column-filter.types';

@Component({
  selector: 'rag-pivot-filter-text',
  templateUrl: './pivot-filter-text.component.html',
  styleUrls: [ './pivot-filter-text.component.scss' ],
})
export class PivotFilterTextComponent
  extends AbstractPivotFilterComponent {

  static ACTIONS = {
    $endsWith: $localize`:@@pivot_filter_text_ends_with:ends with...`,
    $eq: $localize`:@@pivot_filter_text_eq:is...`,
    $in: $localize`:@@pivot_filter_text_in:one of...`,
    $like: $localize`:@@pivot_filter_text_contains:contains...`,
    $notIn: $localize`:@@pivot_filter_text_not_in:not one of...`,
    $notLike: $localize`:@@pivot_filter_text_not_like:not contain...`,
    $startsWith: $localize`:@@pivot_filter_text_starts_with:starts with...`,
  };
  actionOptions: ColumnFilterDropdownOption[];

  constructor() {
    super();

    this.defaultAction = FilterOperator.LIKE;
    this.actions = Object.keys(PivotFilterTextComponent.ACTIONS);
    this.actionOptions = dropdownOptionsFactory(PivotFilterTextComponent.ACTIONS);
  }

  get hasValue() {
    // for some reason we cannot use super.hasValue when compiling productive :(
    return (this.filter?.value?.length > 0);
  }

  getActionText(): string {
    if (!this.showActions) {
      // hide action label when not showing selection button
      return null;
    }

    return PivotFilterTextComponent.ACTIONS[this.action];
  }

  cleanValue(value: string): string {
    value = value?.trim();
    if ( !value ) {
      value = null;
    }
    return value;
  }

}
