import { Core } from '../core.types';


export enum FilterContext {
  Catalog = 'Catalog',
  LearnerAccount = 'LearnerAccount',
}

export interface FilterMatch {
  targetId: number;
  targetType: Core.DistributableType;
}

export interface FilterOption {
  label: string;
  value: string;
}

/**
 * generic wrapper interface for filter results
 */
// eslint-disable-next-line
export interface FilterResult {
}

export interface FilterResultDropDown
  extends FilterResult {
  matches: FilterMatch[];
  option: FilterOption;
}

export interface FilterResultNumber
  extends FilterMatch, FilterResult {
  value: number;
}
