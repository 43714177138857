import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LearnerContentComponent } from './learner-content.controller';


@NgModule({
  declarations: [
    LearnerContentComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class LearnerContentUtilModule {
}
