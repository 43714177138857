import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../api.urls';
import { catchError, delay, map } from 'rxjs/operators';
import { AnyObject } from '../core.types';


interface ValidationData {
  data: { span: string };
  error: string;
  errorCode: string;
  success: boolean;
}

/**
 * @example https://rag-staging.reflact.com/letstrain/API/util/testspheredatespan/0-0-0-0-60/v2
 */
export const sphereDateSpanValidator = ( http: HttpClient, allowEmpty: boolean = false, allowZero: boolean = true ): AsyncValidatorFn => {

  const validationResults: AnyObject<string | null> = {};

  return (
    control: AbstractControl,
  ): Observable<ValidationErrors | null> => {

    if ( control.pristine ) {
      // ignore unchanged inputs
      return of(null);
    }

    const errorMessage = $localize`:@@sphere_date_span_error_pattern:
          Please use the specified pattern of yyyy-MM-dd or yyy-MM-dd-HH-mm.`;
    const errorMessageZero = $localize`:@@sphere_date_span_error_zero:
          Please use a date span that isn't zero/instantaneous.`;

    const value = control.value;
    //prevent returning error if empty entries are allowed and the entry is empty
    //! This skips all kinds of validation. Make sure you allow and expect empty entries.
    //! Do not simply pass on the value to APIs that might expect non-empty entries.
    if (allowEmpty && value === "") {
      return of(null);
    }
    if ( !SphereDateSpanValidator.matchReSphereDateSpan(value)) {
      return of({ sphereDateSpan: errorMessage });
    }
    if (!allowZero && (value === "0-0-0-0-0" || value === "0-0-0")) {
      return of({ sphereDateSpan: errorMessageZero });
    }

    if ( validationResults.hasOwnProperty(value) ) {
      const message = validationResults[value];
      if ( message == null ) {
        return of(null);
      } else {
        return of({ sphereDateSpan: message });
      }
    }

    const url = ApiUrls.getKey('TestSphereDateSpan')
      .replace(/{pattern}/gi, value);
    return http.get<ValidationData>(url)
      .pipe(delay(1000))
      .pipe(map(() => (validationResults[value] = null)))
      // success false redirects to catchError
      .pipe(catchError(err => {
        const message = validationResults[value] = err?.error ?? errorMessage;
        return of({ sphereDateSpan: message });
      }));
  };
};

export class SphereDateSpanValidator {

  static matchReSphereDateSpan(
    sphereDateSpan: string | null,
  ): boolean {

    return /^(?:-?)(\d+)-(-?\d+)-(-?\d+)(?:-(-?\d+)-(-?\d+))?(?:(?:-(0*(?:2|3|4|6|12)(?:x0*(?:1[01]|[0-9]))?)|(?:[+](m))?)?)?$/g
      .test(sphereDateSpan);
  }

}
