<rag-dialog-header>
  <span i18n="@@delete_user_dialog_deletion_of_linked_user_title">Delete linked user</span>
</rag-dialog-header>

<div class="spacing-element">
  <div class="info-wrapper">
    <span class="label">Account:</span>
    <span>{{data.accountName}}</span>
  </div>
  <div class="info-wrapper">
    <span class="label">Login:</span>
    <span>{{data.login}}</span>
  </div>
  <div class="info-wrapper">
    <span class="label">Firstname:</span>
    <span>{{data.firstname}}</span>
  </div>
  <div class="info-wrapper">
    <span class="label">Lastname:</span>
    <span>{{data.lastname}}</span>
  </div>
</div>
<p mat-dialog-content>
  <span i18n="@@delete_user_dialog_deletion_of_linked_user">
    Are you sure you want to delete the link to this user?</span>
</p>

<mat-dialog-actions>
  <div class="button-wrapper">
    <button (click)="onClick(false)" autofocus mat-button type="button">
      <span i18n="@@delete_user_dialog_button_cancel">Cancel</span>
    </button>
    <button (click)="onClick(true)" color="warn" mat-button type="button">
      <span i18n="@@delete_user_dialog_button_delete">Delete</span>
    </button>
  </div>
</mat-dialog-actions>
