import { Translation } from '../../../../core/translation/translation.types';
import { ContentService } from '../../../../core/content/content.service';
import {
  AbstractLearnerAccountViewParent,
  LearnerCurriculumAccountView,
  LearnerCurriculumItemAccountView,
} from '../../../../core/learner-account/learner-account.types';
import { AccountDesignService } from '../../../admin/account-design/account-design.service';
import { DisplayStatus } from '../../../../core/display-status.enum';
import { StyleSettings } from '../../../admin/account-design/account-design.types';
import { Observable } from 'rxjs';
import { AnyObject, Core, Translateable } from '../../../../core/core.types';
import { Component, OnDestroy } from '@angular/core';
import { LearnerContentTypes } from './learner-content.types';
import { destroySubscriptions } from '../../../../core/reactive/until-destroyed';
import { AdminLanguageController } from '../../../admin/admin-includes/admin-language.controller';


@Component({ template: '' })
export class LearnerContentComponent<T extends AbstractLearnerAccountViewParent>
  implements OnDestroy {

  assignmentType: string;
  backParams: AnyObject<string>;
  backUrl: string;
  curriculum: LearnerCurriculumAccountView;
  curriculumItem: LearnerCurriculumItemAccountView;
  learningUnit: T;
  readonly styleSettings$: Observable<StyleSettings>;
  private _curriculumItemTitle: Translateable;

  constructor(
    protected accountDesignService: AccountDesignService,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();
  }

  get curriculumItemTitle() {
    return this._curriculumItemTitle;
  }

  set curriculumItemTitle(title: Translateable) {
    this._curriculumItemTitle = title;
  }

  get displayStatus(): DisplayStatus {
    return this.learningUnit?.displayStatus ?? DisplayStatus.UN_KNOWN;
  }

  get title(): Translation {
    return this.learningUnit?.title;
  }

  get updateContext(): LearnerContentTypes.UpdateContext {
    const curriculumId = this.curriculum?.id ?? this.curriculumItem?.curriculumId;
    const curriculumItemId = this.curriculumItem?.curriculumItemId;
    if ( curriculumId > 0 ) {
      // we have something to do with a curriculum
      return {
        curriculumId,
        curriculumItemId,
      };
    }

    const courseId = this.learningUnit?.id;
    if ( courseId > 0 ) {
      // directly assigned course
      return {
        courseId,
      };
    }

    // nothing found -.-
    return null;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  protected setBackUrl(curriculum: LearnerCurriculumAccountView, assignmentType?: string) {
    // todo handle direct assignment
    let backUrl: string;
    if ( curriculum ) {
      backUrl = ContentService.getContentHref({
        id: curriculum.curriculumId,
        type: Core.DistributableType.lms_curriculum,
        archived: undefined,
        assignmentType: undefined,
        description: undefined,
        displaystatus: undefined,
        hasDirectAssignment: undefined,
        hideInLearnerAccount: undefined,
        items: undefined,
        lastModified: undefined,
        objType: undefined,
        sequentialCur: undefined,
        title: undefined
      }, false);
    }

    this.backParams = null;
    if ( backUrl ) {
      this.backUrl = backUrl;
    } else {
      this.backUrl = '/content-overview';
      if ( assignmentType != null ) {
        this.backParams = { at: assignmentType };
      }
    }
  }

}
