import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/core/content/content.service';
import { DisplayStatusHelper } from 'src/app/core/display-status-helper';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { Widget } from '../../rag-layout.types';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent } from '../widgets.types';

export class LearnContent {
  assignmentType?: string;
  courseType?: number;
  coursetype?: string;
  description?: string;
  id: number;
  isGray: boolean;
  isGreen: boolean;
  isRed: boolean;
  locked: boolean;
  name: string;
  status: number;
}

@Component({
  selector: 'rag-todo-content-widget',
  templateUrl: './todo-content-widget.component.html',
  styleUrls: [ './todo-content-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoContentWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent, OnInit {

  static WidgetUUID = '404F14BF-24AB-4A80-9BA1-651A7EE38837';
  dataSource$: Observable<LearnContent[]>;
  displayedColumns: string[];
  @Input() removeItem: (widget: Widget) => void;
  @Input() resizeEvent: EventEmitter<any>;
  resultsState = 'unknown';
  @Input() widget: Widget;

  constructor(
    private contentService: ContentService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
  ) {
    super();

    this.setTitle($localize`:@@widget_todo_title:Courses to be completed`);
  }

  ngOnInit() {
    this.dataSource$ = this.contentService.fetchOpenLearnContents<LearnContent>((content) => {
      if ( !content.hasOwnProperty('displaystatus') || DisplayStatusHelper.isStatusGreen(content.displaystatus) ) {
        return null;
      }
      const instance = new LearnContent();
      instance.id = content.currItemId || content.id;
      instance.name = content.title || '';
      instance.status = content.displaystatus;
      instance.locked = content.locked;
      instance.description = content.description;
      instance.isGreen = DisplayStatusHelper.isStatusGreen(content.displaystatus);
      instance.isRed = DisplayStatusHelper.isStatusRed(content.displaystatus);
      instance.isGray = DisplayStatusHelper.isStatusGrey(content.displaystatus);
      instance.assignmentType = content.assignmentType;
      instance.courseType = content.courseType;
      return instance;
    });

    this.dataSource$.subscribe(contents => {
      this.resultsState = contents.length === 0 ? 'empty' : 'data';
      this.cdr.detectChanges();
    });

    this.regenerate();
  }

  onClick(content: LearnContent) {
    this.navigationService.navigateByUrl('/content/' + content.id);
  }

  regenerate() {
    this.displayedColumns = [ 'status', 'name' ].concat(this.widget.settings.columnsDefs);
    // this.displayedColumns = [ 'status', 'name' ].concat(this.columnsDefs.map(x => x.columnId));
    this.cdr.detectChanges();
  }

}
