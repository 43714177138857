import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurriculumLinkDialogComponent } from './curriculum-link-dialog.component';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';


@NgModule({
  declarations: [ CurriculumLinkDialogComponent ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CurriculumLinkDialogModule {
}
