import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { ContentFilterModule } from '../../content-filter/content-filter.module';
import { MatIconModule } from '@angular/material/icon';
import { CatalogViewComponent } from './catalog-view.component';
import { MatDividerModule } from '@angular/material/divider';
import { MyContentActionsModule } from '../../content/components/my-content-actions/my-content-actions.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CatalogCardV2Component } from '../catalog-card-v2/catalog-card-v2.component';
import { ClampComponent } from '../../clamp/clamp.component';
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component';
import { StickyScrollComponent } from '../../sticky-scroll/sticky-scroll.component';
import { CourseCardModule } from '../../card/course/course-card.module';
import { EventCardModule } from '../../card/event/event-card.module';
import { CurriculumCardModule } from '../../card/curriculum/curriculum-card.module';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        MyContentActionsModule,
        MatButtonModule,
        MatTableModule,
        MatSortModule,
        MatCardModule,
        MatDividerModule,
        ClampComponent,
        RouterModule,
        ContentFilterModule,
        MatIconModule,
        MatTooltipModule,
        LoadingIndicatorComponent,
        StickyScrollComponent,
        CatalogCardV2Component,
        CourseCardModule,
        EventCardModule,
        CurriculumCardModule,
        MatExpansionModule
    ],
  declarations: [
    CatalogViewComponent,
  ],
  exports: [
    CatalogViewComponent,
  ],
})
export class CatalogViewModule {
}
