import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminSelfRegistrationService } from './admin-self-registration.service';
import { SelfRegAdminResponse } from './admin-self-registration.types';


@Injectable({ providedIn: 'root' })
export class AdminSelfRegistrationResolver
  implements Resolve<SelfRegAdminResponse> {

  constructor(
    private adminSelfRegistrationService: AdminSelfRegistrationService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SelfRegAdminResponse> {
    return this.adminSelfRegistrationService.getSettings();
  }

}
