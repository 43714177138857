import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtrlSingleUserDetailsLearningDataDialogComponent } from './ctrl-single-user-details-learning-data-dialog.component';
import { CtrlSingleUserDetailsLearningDataModule } from '../ctrl-single-user-details-learning-data/ctrl-single-user-details-learning-data.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';


@NgModule({
  declarations: [
    CtrlSingleUserDetailsLearningDataDialogComponent,
  ],
  imports: [
    CommonModule,
    CtrlSingleUserDetailsLearningDataModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CtrlSingleUserDetailsLearningDataDialogModule {
}
