import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CtrlUserListModule } from './ctrl-user-list/ctrl-user-list.module';
import { UserCourseDetailsModule } from './user-course-details/user-course-details.module';
import { UserCurriculumDetailsModule } from './user-curriculum-details/user-curriculum-details.module';
import { UserCurriculumItemModule } from './user-curriculum-item/user-curriculum-item.module';


@NgModule({
  exports: [
    CtrlUserListModule,
    UserCourseDetailsModule,
    UserCurriculumDetailsModule,
  ],
  imports: [
    CommonModule,
    CtrlUserListModule,
    UserCourseDetailsModule,
    UserCurriculumDetailsModule,
    UserCurriculumItemModule,
  ],
})
export class UserDetailsModule {
}
