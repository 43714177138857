<rag-dialog-header>
  <span i18n="@@switch_user_dialog_deletion_of_linked_user_title">Switch linked user</span>
</rag-dialog-header>

<div mat-dialog-content>
  <div class="spacing-element">
    <div class="info-wrapper">
      <span class="label">Account:</span>
      <span>{{data.accountName}}</span>
    </div>
    <div class="info-wrapper">
      <span class="label">Login:</span>
      <span>{{data.login}}</span>
    </div>
    <div class="info-wrapper">
      <span class="label">Firstname:</span>
      <span>{{data.firstname}}</span>
    </div>
    <div class="info-wrapper">
      <span class="label">Lastname:</span>
      <span>{{data.lastname}}</span>
    </div>
  </div>

  <div i18n="@@switch_user_dialog_switch_to_linked_user">Are you sure you want to switch to this user?</div>
</div>

<mat-dialog-actions>
  <button
    (click)="onClick(false)"
    class="rag-stroked-button"
    i18n="@@switch_user_dialog_button_cancel"
    mat-stroked-button
  >
    Cancel
  </button>
  <button
    (click)="onClick(true)"
    autofocus
    color="primary"
    i18n="@@switch_user_dialog_button_switch"
    mat-flat-button
  >
    Switch
  </button>
</mat-dialog-actions>
