import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../../core/api.urls';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../core/global.types';
import { map } from 'rxjs/operators';
import { QAQuestion } from '../../user/qa/qa.types';

@Injectable({
  providedIn: 'root',
})
export class AdminQAService {

  constructor(
    private http: HttpClient,
  ) {}

  changeQuestionStatus(questionUUID: string, status: string): Observable<QAQuestion> {
    const url = ApiUrls.getKey('AdminChangeQuestionStatus')
      .replace(/{questionUUID}/gi, questionUUID);

    const saveData = {
      status,
    };

    return this.http.post<ApiResponse<QAQuestion>>(url, saveData)
      .pipe(map(response => response.question));
  }

  getAllQuestions(): Observable<QAQuestion[]> {
    const url = ApiUrls.getKey('GetAllQAs');

    return this.http.get<ApiResponse<QAQuestion[]>>(url)
      .pipe(map(data => data.questions));
  }

  getQuestionForUUID(uuid: string): Observable<QAQuestion> {
    const url = ApiUrls.getKey('GetQAForUUID')
      .replace(/{uuid}/gi, uuid);

    return this.http.get<ApiResponse<QAQuestion>>(url)
      .pipe(map(response => response.question));
  }

  resetQuestion(uuid: string): Observable<QAQuestion> {
    const url = ApiUrls.getKey('AdminResetQuestion')
      .replace(/{questionUUID}/gi, uuid);

    return this.http.post<ApiResponse<QAQuestion>>(url, {})
      .pipe(map(response => response.question));
  }

  saveQuestion(questionData: QAQuestion): Observable<QAQuestion> {
    const url = ApiUrls.getKey('AdminSaveQuestion')
      .replace(/{uuid}/, questionData.uuid);

    return this.http.post<ApiResponse<QAQuestion>>(url, questionData)
      .pipe(map(response => response.question));
  }
}
