import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from '../../../../core/info/info.types';
import { switchMap, take, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { QAService } from '../qa-list/qa-list.service';
import { GenericMessageDialogComponent } from '../../../../component/generic-message-dialog/generic-message-dialog.component';
import { ModalDialog } from '../../../../core/modal-dialog';

@Component({
  selector: 'rag-qa-create',
  templateUrl: './qa-create.component.html',
  styleUrls: [ './qa-create.component.scss' ],
})
export class QACreateComponent
  implements OnInit {

  form: FormGroup;

  constructor(
    private dialog: ModalDialog,
    private qaService: QAService,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  onSendQuestion(): void {
    const saveData = {
      questionAbstract: this.form.controls['title'].value,
      questionText: this.form.controls['question'].value,
    };

    this.dialog.open(GenericMessageDialogComponent, {
      data: {
        title: MessageConstants.DIALOG.TITLE.CONFIRM,
        buttons: YesNoButtons,
        message: $localize`:@@global_confirm_send_question:Do you want to send this contribution? This cannot be reversed.
       Please make sure that you have followed all the rules.`,
      },
      maxWidth: '30vw',
    }).afterClosed()
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(_ => this.qaService.sendNewQuestion(saveData)))
      .pipe(switchMap(_ => this.router.navigateByUrl('/qa')))
      .pipe(switchMap(_ => this.infoService.showMessage(
        $localize`:@@global_send_success_contribution:The contribution was sent successfully.`,
        {
          infoType: InfoType.Success,
          durationInSeconds: 20,
        })))
      .pipe(take(1))
      .subscribe();
  }

  private buildFormGroup(): void {
    this.form = this.formBuilder.group({
      title: [],
      question: [],
    });
  }
}
