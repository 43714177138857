import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContentService } from '../../../../../core/content/content.service';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { LearnerContentService } from '../../learner-content-util/learner-content.service';
import { LearnerCourseService } from './learner-course.service';
import { LearnerContentTypes } from '../../learner-content-util/learner-content.types';
import { ViewHelper } from '../../../../../core/view-helper';
import { AdminCoursesTypes } from '../../../../../core/admin-courses.types';


@Injectable({
  providedIn: 'root',
})
export class LearnerCourseGuard
  implements CanActivate {

  constructor(
    private contentService: ContentService,
    private learnerContentService: LearnerContentService,
    private learnerCourseService: LearnerCourseService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    const itemId = Number(route.paramMap.get('itemId'));
    if ( !(itemId > 0) ) {
      return throwError('course-id-invalid');
    }

    const curriculumId = Number(route.paramMap.get('id'));
    let query: Observable<LearnerContentTypes.CourseAccountToDo>;
    if ( !(curriculumId > 0) ) {
      // no need for a parent, fetch only course
      query = this.getCourse(itemId);
    } else {
      // fetch item in parent curriculum
      query = this.getCourseInCurriculum(curriculumId, itemId);
    }
    return query
      .pipe(map( courseAccount => {
        this.learnerCourseService.setLearningUnit(courseAccount);
        const displayStatus = courseAccount.displayStatus;
        const extensions = ViewHelper.getViewData(courseAccount)?.extensions ?? [];
        const extensionsOrder = LearnerCourseService.getExtensionOrder(courseAccount.extensions);
        switch ( route.data[0] ) {

          case 'ToDo':
            return true;

          case 'SampleSolution':
            switch ( extensionsOrder ) {
              case -1:
              case null:
              case 4:
                return false;
              case 2:
                const hasComment = extensions
                  .find(ext => ext.type === AdminCoursesTypes.Extensions.Estimation).commitment.commitment.comment;
                if (hasComment != null && hasComment !== '') {
                  return !DisplayStatusHelper.isStatusRed(displayStatus);
                } else {
                  return false;
                }
              case 1:
              case 3:
                return !DisplayStatusHelper.isStatusRed(displayStatus);
            }
            break;

          case 'SelfEstimation':
            switch ( extensionsOrder ) {
              case -1:
              case null:
              case 3:
                return false;
              case 1:
              case 2:
              case 4:
                return !DisplayStatusHelper.isStatusRed(displayStatus);
            }
            break;
          default:
            return false;
        }
      }))
      .pipe(take(1));
  }

  private getCourse(
    itemId: number,
  ): Observable<LearnerContentTypes.CourseAccountToDo> {
    return this.learnerContentService.getCourseAccount(itemId)
      .pipe(map( response => response as LearnerContentTypes.CourseAccountToDo));
  }

  private getCourseInCurriculum(
    curriculumId: number,
    itemId: number,
  ): Observable<LearnerContentTypes.CourseAccountToDo> {
    return this.learnerContentService.getCourseAccountInCurriculum(curriculumId, itemId)
      .pipe(map( response => response as LearnerContentTypes.CourseAccountToDo));
  }
}
