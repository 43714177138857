import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ReportService } from '../../../../core/report/report.service';
import { Report, ReportConfig } from '../../../../core/report/report.types';
import { ViewHelper } from '../../../../core/view-helper';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../core/principal/permission.states';
import { ROUTE_MATCH_OPTIONS_EXACT } from '../../../../core/navigation/navigation.types';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    RouterModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  selector: 'rag-report-nav',
  templateUrl: './report-nav.component.html',
  styleUrls: [ './report-nav.component.scss' ],
})
export class ReportNavComponent
  implements OnDestroy, OnInit {

  countOfNotOwnedByMeReports = 0;
  openReports: ReportConfig[];
  permissions: PermissionStates;
  subMenuNewReportOpened = false;

  constructor(
    private principalService: PrincipalService,
    private reportService: ReportService,
    private router: Router,
  ) {
    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  @Input()
  set reports(reports: Report[]) {
    this.countOfNotOwnedByMeReports = 0;
    const tempReports: ReportConfig[] = reports.reduce((pV, report) => {
      const reportConfig = report && report.reportConfig;
      this.countOfNotOwnedByMeReports += (reportConfig.ownedByMe ? 0 : 1);
      const viewData = ViewHelper.getViewData(reportConfig);
      if ( viewData && viewData.tabOpen ) {
        pV.push(reportConfig);
      }
      return pV;
    }, []);
    ReportNavComponent.sortReports(tempReports);
    this.openReports = tempReports;
  }

  private static getOrderId(reportConfig: ReportConfig, maxOrderId: number): number {
    const viewData = ViewHelper.getViewData(reportConfig);
    if ( !viewData.hasOwnProperty('tabOrder') ) {
      // place any tabs without specific order at the end
      viewData.tabOrder = maxOrderId;
    }
    return viewData.tabOrder;
  }

  private static sortReports(tempReports: ReportConfig[]) {
    const maxOrderId = tempReports.length;
    tempReports.sort((a, b) => {
      const orderA = ReportNavComponent.getOrderId(a, maxOrderId);
      const orderB = ReportNavComponent.getOrderId(b, maxOrderId);
      return orderA - orderB;
    });
    let orderId = 0;
    tempReports.map(reportConfig => {
      const viewData = ViewHelper.getViewData(reportConfig);
      viewData.tabOrder = orderId++;
    });
  }

  closeReport($event: Event, reportConfig: ReportConfig) {
    // @see https://github.com/angular/angular/issues/21457#issuecomment-419792282
    $event.preventDefault();
    $event.stopPropagation();

    const fnDelete = () => {
      this.reportService.toggleReportTab(reportConfig);
    };

    let active: boolean;
    if ( reportConfig.targetType === 'ClassicReport' ) {
      active = this.router.isActive('/report/classic/' + reportConfig.reportTemplateId, ROUTE_MATCH_OPTIONS_EXACT);
    } else {
      active = this.router.isActive('/report/v2/saved/' + reportConfig.uuid, ROUTE_MATCH_OPTIONS_EXACT);
    }

    if ( active ) {
      this.router.navigate([ '/report/v2/saved/' ])
        .then(fnDelete, () => void (0));
    } else {
      fnDelete();
    }
  }

  getReportUrl(reportConfig: ReportConfig) {
    const targetType = reportConfig && reportConfig.targetType;
    if ( targetType === 'ClassicReport' && reportConfig.reportTemplateId ) {
      return '/report/classic/' + reportConfig.reportTemplateId;
    }
    return '/report/v2/saved/' + reportConfig.uuid;
  }

  hasAnyDirty(
    reportConfig: ReportConfig | null = null,
  ): boolean {

    const uuid: string = reportConfig?.uuid ?? '';
    return this.openReports?.find((config: ReportConfig) => {

        if ( uuid && (config.uuid === uuid) ) {
          // check if any report _except_ this one, is dirty
          return false;
        }

        const viewData = ViewHelper.getViewData(config);
        // report is dirty? -> use as match, and abort checking others
        return (viewData?.isDirty === true);
      })
      // found a report that is dirty? (excluding the one given as argument) -> return true
      != null;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.reportService.myReports$
      .pipe(map(reports => this.reports = reports))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

}
