import { HttpHeaders, HttpParams } from '@angular/common/http';


export enum RagRequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Options = 'OPTIONS',
  Head = 'HEAD',
  Patch = 'PATCH',
}

export class RagRequestOptions {
  method?: RagRequestMethod;
  options?: {
    body?: HttpParams | FormData;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'response' | 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: 'json' | 'text';
    reportProgress?: boolean;
    withCredentials?: boolean;
  };
  url: string;

  constructor() {
    this.options = {
      observe: 'response',
    };
  }
}
