import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoService } from 'src/app/core/info/info.service';
import { map, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import {
  SelectItemDialogComponent,
  SelectItemDialogComponentData
} from 'src/app/component/select-item-dialog/select-item-dialog.component';
import { AdminCurriculum } from 'src/app/route/admin/admin-curriculum/admin-curriculum.types';
import CurriculumMeta = AdminCurriculum.CurriculumMeta;

@Component({
  selector: 'rag-select-curriculum',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './select-curriculum.component.html',
  styleUrls: ['./select-curriculum.component.scss']
})
export class SelectCurriculumComponent implements OnInit, OnDestroy {

  constructor(
    private infoService: InfoService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.activeRoute.data.pipe(switchMap(data => {
        const items: CurriculumMeta[] = (data.curricula ?? [])
          .filter((o: CurriculumMeta) => o?.status === 'published');
        return this.infoService.showDialog<SelectItemDialogComponent<CurriculumMeta>,
          SelectItemDialogComponentData<CurriculumMeta>,
          Array<number>>(SelectItemDialogComponent, {
          items,
          header: $localize`:@@select_curricula:Select curricula`,
          placeholder: $localize`:@@curriculum:Curriculum`
        })
          .pipe(map(selectedCurricula => {
            const commands: string[] = (selectedCurricula === null) ?
              ['report', 'v2', 'generator', 'Curriculum'] :
              ['report', 'v2', 'generator', 'Curriculum', selectedCurricula.toString()];
            this.router.navigate(commands).then();
          }));
      }
    ))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }
}
