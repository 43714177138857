export enum DisplayStatus {
  CERTIFIED = 1, // lms_curriculum
  NOT_CERTIFIED = 2, // lms_curriculum
  PASSED = 3, // lms_course
  FAILED = 4, // lms_course
  COMPLETE = 5, // lms_course
  INCOMPLETE = 6, // lms_course
  RECERTIFICATION_TIME = 7, // lms_curriculum
  NOT_ATTEMPTED = 0, // lms_course / lms_curriculum
  UN_KNOWN = -1, // lms_...
  UN_ASSIGNED = -2, // lms_curriculum
}

export const DisplayStatusOrder: { [key: string]: string } = {};
// green
DisplayStatusOrder[String(DisplayStatus.CERTIFIED)] = '1';
DisplayStatusOrder[String(DisplayStatus.PASSED)] = '2';
DisplayStatusOrder[String(DisplayStatus.COMPLETE)] = '3';
// red-green
DisplayStatusOrder[String(DisplayStatus.RECERTIFICATION_TIME)] = '4';
// yellow
DisplayStatusOrder[String(DisplayStatus.INCOMPLETE)] = '5';
// red
DisplayStatusOrder[String(DisplayStatus.NOT_CERTIFIED)] = '6';
DisplayStatusOrder[String(DisplayStatus.FAILED)] = '7';
DisplayStatusOrder[String(DisplayStatus.NOT_ATTEMPTED)] = '8';
// other
DisplayStatusOrder[String(DisplayStatus.UN_ASSIGNED)] = '9';
DisplayStatusOrder[String(DisplayStatus.UN_KNOWN)] = '10';
