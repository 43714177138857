import { Translation } from './translation/translation.types';
import { AnyObject, FileInfo, UserField } from './core.types';
import { AssignmentDialogTypes } from '../component/assignment-dialog/assignment-dialog.types';
import { ProfileFieldTypes } from './input/profile-field/profile-field.types';
import { ReducedUserAttributes } from '../route/admin/admin-reduced-user-attributes/admin-reduced-user-attributes.types';

export interface UserAttributeSettings {
  text: Translation;
  value: UserAttributeSettingsValue;
}

export enum UserAttributeSettingsValue {
  readAdmin = 'readAdmin',
  readLearner = 'readLearner',
  required = 'required',
  requiresConsent = 'requiresConsent',
  updateLearner = 'updateLearner',
  updatePSTD = 'updatePSTD',
  recordField = 'recordField',
  readController = 'readController',
}

export interface UserAttributeTypeSetting {
  language: string;
  label: string;
  dataBaseValue: string;
  disabled: boolean;
}

export interface UserAttribute {
  attributeField: string;
  attributeType: UserAttributeType;
  consentText?: Translation;
  info: Translation;
  label: Translation;
  readAdmin: boolean;
  readLearner: boolean;
  modifiable?: boolean;
  orderIndex?: number;
  required: boolean;
  requiresConsent?: boolean;
  systemField?: boolean;
  updatePSTD: boolean;
  updateLearner: boolean;
  recordField: boolean;
  readController: boolean;
  knownAttribute?: boolean;
  attributeName: string;
  attributeGroupId: number;
  attributeOptions: UserAttributeOption[];
}

export interface UpdateUserAttributeResponse {
  attributes: AnyObject<UserAttribute>;
}

export interface UpdateAttributesResponse {
  userAttribute: UserAttribute;
}

export interface UserAttributesForMessaging {
  userAttributes: AnyObject<UserField>;
}

export interface UserAttributeGroup {
  id: number;
  title: Translation;
  userAttributes: string[];
  orderIndex: number;
  profileFields?: ProfileFieldTypes.ProfileField[];
}

export interface UserAttributeResponse {
  userAttributes: AnyObject<UserAttribute>;
  userAttributeGroups: UserAttributeGroup[];
  reducedUserAttributes: AnyObject<ReducedUserAttributes.Attribute>;
}

export interface UserAttributeResolverResponse {
  userAttributeGroups: UserAttributeGroup[];
  attribute: any;
  reducedUserAttributes: AnyObject<ReducedUserAttributes.Attribute>;
}

export interface UserAttributeTypeWithText {
  type: UserAttributeType;
  label: Translation;
}

/**
 * @see TableColumnDataType
 */
export enum UserAttributeType {
  date = 'date',
  dropdown = 'dropdown',
  email = 'email',
  multiselect = 'multiselect',
  number = 'number',
  password = 'password',
  radio = 'radio',
  text = 'text',
}

export interface UserAttributeOption {
  attributeName: string;
  label?: Translation;
  optionValue: string;
}

export interface UserAttributeOptionPreviewData {
  userAttributeOptions: UserAttributeOption[];
  fieldLabel: Translation;
  type: UserAttributeType;
  attributeName: string;
  info: Translation;
}

export interface UserAttributeOptionDialogResponse {
  label: Translation;
  dataBaseValue: string;
}

export interface UserAttributeContext {
  id: number;
  accountId: number;
  context: string;
  userAttributes: string[];
  privacyUrl: string;
  privacyFile?: FileInfo;
  agreementFile?: FileInfo;
  privacyFileUUID?: string;
  agreementFileUUID?: string;
  agreementUrl: string;
}

export interface UserAttributeContextResponse {
  context?: UserAttributeContext;
  userAttributes: AnyObject<UserAttribute>;
  userAttributeGroups: UserAttributeGroup[];
}

export interface UserAttributeContextSaveData {
  context: string;
  userAttributes: string[];
  privacyFileUUID: string;
  agreementFileUUID: string;
  privacyUrl?: string;
  agreementUrl?: string;
}

export interface UserAttributeGroupEditData {
  allUserAttributeGroups: UserAttributeGroup[];
  userAttributeGroup: UserAttributeGroup;
  userAttributes: AnyObject<UserAttribute>;
  i18n?: AssignmentDialogTypes.AssignmentDialogTranslations;
}

export interface AdminUserFieldsByGroupsData {
  attributeMap: AnyObject<UserAttribute>;
  groups: UserAttributeGroup[];
}
