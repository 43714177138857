import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PaymentModuleSettings } from './ext-payment-module.types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ExtPaymentModuleService } from './ext-payment-module.service';


@Injectable({ providedIn: 'root' })
export class ExtPaymentModuleResolver
  implements Resolve<PaymentModuleSettings> {

  constructor(
    private http: HttpClient,
    private extPaymentModuleService: ExtPaymentModuleService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentModuleSettings> {

    return this.extPaymentModuleService.getPaymentSettings()
      .pipe(take(1));

  }

}
