import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MyContentActionsComponent } from './my-content-actions.component';


@NgModule({
  declarations: [
    MyContentActionsComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule
  ],
  exports: [
    MyContentActionsComponent
  ]
})
export class MyContentActionsModule { }
