import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CurriculumCockpitResponse } from './curriculum-cockpit.types';
import { CurriculumCockpitService } from './curriculum-cockpit.service';

@Injectable({
  providedIn: 'root',
})
export class CurriculumCockpitResolver
  implements Resolve<CurriculumCockpitResponse> {

  constructor(
    private curriculumCockpitService: CurriculumCockpitService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurriculumCockpitResponse> {
    const curriculumId = route.params['id'];
    return this.curriculumCockpitService.getCurriculumCockpitDetails(curriculumId);
  }
}
