import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError, queueScheduler } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, observeOn, switchMap, take, tap } from 'rxjs/operators';
import { State } from 'src/app/app.state';
import { ApiUrls } from 'src/app/core/api.urls';
import { ApiResponse, HttpRequestOptions, LmsError } from 'src/app/core/global.types';
import { RagPageService } from 'src/app/rag-layout/rag-page/rag-page.service';
import { CertificatesService } from 'src/app/route/user/certificates/certificates.service';
import {
  ReportTitleDialogComponent,
  ReportTitleDialogComponentData,
  ReportTitleDialogComponentResult,
} from '../../route/ctrl/report/report-generator/report-title-dialog/report-title-dialog.component';
import { CachedSubject } from '../cached-subject';
import { GenericMessageDialogComponent } from '../../component/generic-message-dialog/generic-message-dialog.component';
import { InfoService } from '../info/info.service';
import { DeleteButton, DeleteCancelButtons, InfoType, MessageKey } from '../info/info.types';
import { ModalDialog } from '../modal-dialog';
import { PrincipalService } from '../principal/principal.service';
import { ViewHelper } from '../view-helper';
import {
  AddWidgetEvent,
  ColumnSettings,
  QlExpression,
  Report,
  ReportConfig,
  ReportDataResponse,
  ReportLearningTime,
  ReportRow,
  ReportRowStatistics,
  ReportsResponse,
  ReportTargetType,
} from './report.types';
import { CertificateDownloadObject } from '../../route/user/certificates/certificates.types';
import { MergeHelper } from '../primitives/merge.helper';

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  readonly myReports$: Observable<Report[]>;
  private myReports_ = new CachedSubject<Report[]>(null);
  private _reportTasks: Array<Observable<Report>> = [];

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private ragPageService: RagPageService,
    private certificatesService: CertificatesService,
  ) {
    this.myReports$ = this.myReports_.withoutEmptyValues();
    this.principalService.isLogged$
      .pipe(map(isLoggedIn => {
        if ( !isLoggedIn ) {
          this.myReports_.next(null);
        }
      }))
      .subscribe();
  }

  /**
   * Copies the distinctValue of each first grouping into the appropriate column filter.
   */
  static addGroupFilters(
    reportConfig: ReportConfig,
    data: ReportRow[] | null,
  ): ReportRowStatistics | null {

    if ( !(data?.length > 0) ) {
      return null;
    }

    const entry: ReportRowStatistics = data
      .find(row => !!row.column && row.hasOwnProperty('distinctValueText'));
    if ( entry == null ) {
      return null;
    }

    // copy distinct value of grouping to filter
    const filterMap: QlExpression = reportConfig.filter = reportConfig.filter ?? {};
    filterMap[entry.column] = {
      $eq: entry.distinctValue ?? null,
    };

    // find the next child
    const lastGroup = ReportService.addGroupFilters(reportConfig, entry.data);
    // if the child does not exist, return self -> should terminate at the deepest child with distinctValueText
    return lastGroup ?? entry;
  }

  private static contextForDataType(reportDataType: ReportTargetType): string {
    return 'rep@columnSettings@' + reportDataType;
  }

  addWidget(event: AddWidgetEvent) {
    this.ragPageService.appendWidget(event.pageId, event.widgetUUID, (widget, layout) => {
      // multiple widgets for the same report are not allowed
      const existingWidget = Object.values(layout).find(
        _widget => _widget.widgetUUID === event.widgetUUID && _widget.settings.reportUUID === event.reportConfig.uuid,
      );
      if ( existingWidget != null ) {
        this.infoService.showMessage(
          $localize`:@@ragpage_add_failed_exists_already:A widget for this report is already present.`,
          { infoType: InfoType.Warning });
        return false;
      }
      // assign settings to newly instantiated widget
      widget.settings = {
        reportUUID: event.reportConfig.uuid,
      };
      return true;
    })

      .pipe(tap(success => {
        if (!success) {
          return;
        }

        this.infoService.showMessage(
          $localize`:@@ragpage_add_widget_success:Widget successfully added`, { infoType: InfoType.Success });
      }))

      .pipe(take(1))
      .subscribe();
  }

  canSaveMore(): Observable<boolean> {
    return combineLatest([
      this.principalService.permissionStates$
        .pipe(map(state => state.ctrlReportGeneratorUnlimited)),
      this.fetchMyReports(),
    ])
      .pipe(map(([ hasNoRestrictions, myReports ]) => hasNoRestrictions || !myReports || (myReports.length < 5)));
  }

  copyNameForReport(reportConfig: ReportConfig) {
    const title = reportConfig.title;
    const candidateTitle = State.language === 'de' ? 'Kopie von ' + title : 'Copy of ' + title;
    const regex = new RegExp(`${candidateTitle} \\((\\d+)\\)`);
    let candidateTitleExists = false;
    let nextIndex = this.myReports_.value.reduce((pV, report) => {
      candidateTitleExists = candidateTitleExists || candidateTitle === report.reportConfig.title;
      const matches = report.reportConfig.title.match(regex);
      if ( matches ) {
        return Number(matches[1]) + 1;
      }
      return pV;
    }, 0);

    if ( nextIndex === 0 && candidateTitleExists ) {
      nextIndex = 1;
    }

    if ( nextIndex > 0 ) {
      return `${candidateTitle} (${nextIndex})`;
    }

    return candidateTitle;
  }

  deleteReport(reportUUID: string) {
    if ( !reportUUID ) {
      return;
    }

    this.infoService.showDialog(GenericMessageDialogComponent, {
      messageKey: MessageKey.REPORT_CONFIRM_DELETE,
      titleKey: MessageKey.REMOVE_REPORT_CONFIRMATION_TITLE,
      buttons: DeleteCancelButtons,
    })
      .pipe(map(button => {
        if ( button === DeleteButton ) {
          this.deleteReportQuery(reportUUID, (error) => {
            if ( error ) {
              let errorMsg = '';
              if ( typeof error === 'string' ) {
                errorMsg = error;
              } else if ( typeof error === 'object' ) {
                errorMsg = JSON.stringify(error);
              }
              this.infoService.showSnackbar(null, InfoType.Error, {
                message: errorMsg,
              });
              return;
            }
          });
        }
      }))
      .pipe(take(1))
      .subscribe();
  }

  deleteReportQuery(reportUUID: string, callback: (error: LmsError) => void) {
    const url = `${ApiUrls.getKey('DeleteReport')}/${reportUUID}`;
    this.http.delete(url)
      .subscribe(() => {
        this.spliceReport(reportUUID);
        callback(null);
      }, error => {
        console.error(error);
        callback(error.message);
      });
  }

  fetchFirstGroup(report: Report): Observable<Report> {
    const config = ViewHelper.cloneDeep(report.reportConfig);
    let data = report.data || [];
    if ( report.data && report.data.length ) {
      const entry = ReportService.addGroupFilters(config, report.data);
      if ( !entry ) {
        // warning, warning... did not find any valid grouping!
        return of(report);
      }
      data = entry.data = [];
    }

    return this.reportData({ reportConfig: config })
      .pipe(map(response => {

        // append data for first grouping
        (response?.data ?? []).map(row => data.push(row));

        return report;
      }));
  }

  fetchMyReports(): Observable<Report[]> {
    if ( this.myReports_.queryStart() ) {
      this.http.get<ReportsResponse>(ApiUrls.getKey('MyReports'))
        .pipe(map(response => {
          const reportConfigs = response.reportConfigs.sort((configA, configB) => {
            const titleA = (configA && configA.title || '').toLocaleLowerCase();
            const titleB = (configB && configB.title || '').toLocaleLowerCase();
            return titleA.localeCompare(titleB);
          });
          return reportConfigs.map<Report>(reportConfig => ({
              reportConfig,
            }));
        }))
        .pipe(finalize(this.myReports_.queryDone))
        .pipe(map(this.myReports_.next))
        .subscribe();
    }
    return this.myReports$;
  }

  fetchReport(uuid: string, tabOpen = false): Observable<Report> {
    if ( !uuid ) {
      return throwError(new Error('uuid must not be empty!'));
    }

    return this.fetchMyReports()
      .pipe(map(reports => {
        const result: Report = (reports ?? [])
          .find(report => report?.reportConfig?.uuid === uuid);
        if ( result == null ) {
          throw new Error('could not find report with uuid \'' + uuid + '\'');
        }

        if ( tabOpen ) {
          const viewData = ViewHelper.getViewData(result.reportConfig);
          viewData.tabOpen = true;
        }
        return result;
      }));
  }

  fetchStatistics(config: ReportConfig): Observable<Report> {
    if ( !config ) {
      return throwError('report config required!');
    }
    return this.http.post(ApiUrls.getKey('ReportStatistics'), config)
      .pipe(map((response: any) => {
        const report: Report = response.statistics;
        report.reportConfig = report.reportConfig || config;
        ViewHelper.copyData(config, report.reportConfig);
        return report;
      }))
      .pipe(switchMap(report => this.fetchFirstGroup(report)));
  }

  fetchStatisticsByReportUUID(reportUUID: string): Observable<Report> {

    const __handleTasks = (task: Observable<Report>) => {
      task
        .pipe(observeOn(queueScheduler))
        .pipe(tap(_ => {
          this._reportTasks.splice(0, 1);
          if (this._reportTasks.length > 0) {
            __handleTasks(this._reportTasks[0]);
          }
        }))
        .pipe(catchError(e => {
          console.error(e);
          return e;
        }))
        .subscribe();
    };

    const _task = new Observable<Report>(observer => {
      const url = `${ApiUrls.getKey('ReportStatisticsByUUID')}/${reportUUID}`;
      const __task = this.http.get(url)
        .pipe(map((response: any) => response.statistics))
        .pipe(tap(statistics => observer.next(statistics)))
        .pipe(tap(_ => observer.complete()));
      this._reportTasks.push(__task);
      if (this._reportTasks.length === 1) {
        __handleTasks(this._reportTasks[0]);
      }
    });

    return _task;
  }

  getFullReport(reportConfig: ReportConfig, curriculumId?: string): Observable<Report> {
    let url: string;
    if (curriculumId === undefined) {
      url = ApiUrls.getKey('ReportGetFull');
    } else {
      url = ApiUrls.getKey('ReportGetCurriculum').replace('{curriculumId}', curriculumId);
    }
    return this.http.post<ApiResponse<Report>>(url, {
      ...reportConfig,
      // clear filter to fetch all entries
      filter: {},
    })
      .pipe(map(response => {
        const result = response.report;
        // write back the filter
        result.reportConfig.filter = reportConfig.filter;
        return result;
      }));
  }

  getLearningTime(reportConfig: ReportConfig): Observable<ReportLearningTime> {
    return this.http.post<any>(ApiUrls.getKey('ReportTimelineChart'), reportConfig)
      .pipe(map(response => response.reportLearningTime))
      .pipe(take(1));
  }

  getStatisticsWithData(report: Report): Observable<Report> {
    const url = ApiUrls.getKey('ReportComplete');
    return this.http.post<any>(url, report.reportConfig)
      .pipe(map(dataResponse => {
        const responseReport = dataResponse.statistics;
        report.data = responseReport.data;
        report.statistics = responseReport.statistics;

        // report.reportConfig = dataResponse.report.reportConfig;
        report.reportConfig.columnSettings = responseReport.reportConfig.columnSettings;

        // TODO: Deprecated! remove it
        report.reportConfig.columns = responseReport.reportConfig.columns;
        report.reportConfig.groupings = responseReport.reportConfig.groupings || [];
        return report;
      }));
  }

  removeSavedColumnSettingsForType(reportDataType: ReportTargetType): Observable<boolean> {
    const context = ReportService.contextForDataType(reportDataType);
    return this.principalService.removeUserSettings(context);
  }

  renameReport(reportConfig: ReportConfig): Observable<boolean> {
    const dialogRef = this.dialog.open<ReportTitleDialogComponent, ReportTitleDialogComponentData, ReportTitleDialogComponentResult>(ReportTitleDialogComponent, {
      width: '350px',
      disableClose: false,
      data: {
        title: reportConfig.title || '',
        dialogTitleKey: '@@report_title_rename',
        hideOpenCheckbox: true,
      },
    });

    return dialogRef
      .afterClosed()
      .pipe(filter(result => result != null && result.title.trim().length > 0))
      .pipe(concatMap(result => {
        const config = MergeHelper.cloneDeep(reportConfig);
        config.title = result.title;
        return this.saveReport({ reportConfig: config })
          .pipe(map(() => {
            reportConfig.title = result.title;
            return false;
          }));
      }))
      .pipe(catchError((e, caught) => {
        this.infoService.showSnackbar(MessageKey.REPORT_SAVE_FAILED, InfoType.Error);
        return caught;
      }));
  }

  /**
   * Requests reports data based on conditions in report object.
   * @param report Report object containing all defined by the user conditions and settings for this request
   * @param page define a range to load data with
   */
  reportData(report: Report, page?: { offset: number; count: number }): Observable<Report> {
    let url = ApiUrls.getKey('ReportPreview');
    if ( page && (page.offset >= 0) && (page.count > 0) ) {
      url += '?offset=' + page.offset + '&count=' + page.count;
    }
    return this.http.post<ReportDataResponse>(url, JSON.stringify(report.reportConfig), HttpRequestOptions)
      .pipe(map(dataResponse => {
        // convert report filter into report settings for visualisation
        // report.reportConfig.settings = this.prepareInternalFilterStructure(report.reportConfig);
        report.data = dataResponse.report.data;

        // report.reportConfig = dataResponse.report.reportConfig;
        report.reportConfig.columnSettings = dataResponse.report.reportConfig.columnSettings;

        // TODO: Depricated! remove it
        report.reportConfig.columns = dataResponse.report.reportConfig.columns;
        report.reportConfig.groupings = dataResponse.report.reportConfig.groupings || [];
        return report;
      }));
  }

  requestCertificatesForCurriculaAndUsers(targetObjects: CertificateDownloadObject[]) {
    const userId = this.principalService.currentUser.userId;
    this.certificatesService.downloadPdfCertificates(userId, targetObjects);
  }

  saveAsReport(reportConfig: ReportConfig, shouldReplaceInTab = false): Observable<{
    report: Report;
    openAfterSave: boolean;
  }> {

    const reportTitle = this.copyNameForReport(reportConfig);

    const dialogRef = this.dialog.open<ReportTitleDialogComponent, ReportTitleDialogComponentData, ReportTitleDialogComponentResult>(ReportTitleDialogComponent, {
      width: '350px',
      disableClose: false,
      data: {
        title: reportTitle,
        dialogTitleKey: '@@global_save_as',
      },
    });

    return dialogRef
      .afterClosed()
      .pipe(filter(result => result != null && result.title.trim().length > 0))
      .pipe(concatMap(result => {

        const config = MergeHelper.cloneDeep(reportConfig);
        config.title = result.title;
        config.userId = this.principalService.currentUser.userId;

        delete config.uuid;
        delete config.permissions;

        return this.saveReport({ reportConfig: config }, result.openAfterSave).pipe(map((savedReport) => {
          if ( shouldReplaceInTab && !result.openAfterSave ) {
            const reportToReplace = this.myReports_.value.find(r => r.reportConfig.uuid === reportConfig.uuid);
            const savedOriginReport = this.myReports_.value.find(r => r.reportConfig.uuid === savedReport.reportConfig.uuid);
            if ( reportToReplace ) {
              // release the tab for current report
              savedOriginReport.reportConfig.$view = {
                ...reportToReplace.reportConfig.$view,
                isDirty: false,
              };
              this.toggleReportTab(reportToReplace.reportConfig);
            }
          }

          return {
            report: savedReport,
            openAfterSave: result.openAfterSave,
          };
        }));
      }))
      .pipe(catchError((e, caught) => {
        this.infoService.showSnackbar(MessageKey.REPORT_SAVE_FAILED, InfoType.Error);
        return caught;
      }));
  }

  saveColumnSettingsForType(reportDataType: ReportTargetType, settings: Array<ColumnSettings>): Observable<boolean> {
    const payload = JSON.stringify(settings);
    const context = ReportService.contextForDataType(reportDataType);
    return this.principalService.saveUserSettings(context, payload);
  }

  saveReport(report: Report, openAfterSave = false): Observable<Report> {
    const observable = this.http
      .post<Report>(ApiUrls.getKey('SaveReport'), JSON.stringify(report.reportConfig), HttpRequestOptions)
      .pipe(map(response => {
        report.reportConfig = response.reportConfig;
        if ( openAfterSave ) {
          report.reportConfig.$view = {
            tabOpen: openAfterSave,
          };
        }
        return report;
      }));
    return this.updateMyReports(observable);
  }

  /**
   * Closes a report tab.
   */
  spliceReport(reportUUID: string): void {
    this.fetchMyReports()
      .pipe(take(1))

      .pipe(map(reports => {
        const pos = (reports ?? [])
          .findIndex(report => report?.reportConfig?.uuid === reportUUID);
        if ( pos === -1 ) {
          // report not found -> maybe it has been removed already
          return;
        }

        // remove report from result
        reports.splice(pos, 1);
        this.myReports_.next(reports);
      }))

      .subscribe();
  }

  toggleFavorite(reportConfig: ReportConfig) {
    const favorite = !reportConfig.favorite;
    const method = favorite ? 'put' : 'delete';
    const url = ApiUrls.getKey('ReportFavorite').replace(/[{]uuid[}]/gi, reportConfig.uuid);
    this.http.request<any>(method, url, { body: null })
      .pipe(map(() => {
        reportConfig.favorite = favorite;
        this.myReports_.next(this.myReports_.value);
      }))
      .subscribe();
  }

  toggleReportTab(reportConfig: ReportConfig) {
    const viewData = ViewHelper.getViewData(reportConfig);
    if ( viewData ) {
      delete viewData.tabOpen;
      delete viewData.tabOrder;
      delete viewData.isDirty;
      this.myReports_.next(this.myReports_.value);
    }
  }

  setLastToCurrentIteration(curriculumId: number, userId: number) {
    const url = ApiUrls.getKey('CtrlCurriculumLastToCurrent')
      .replace(/[{]curId[}]/gi, String(curriculumId))
      .replace(/[{]userId[}]/gi, String(userId));
    this.http.request<any>('put', url, { body: null })
      .pipe(map(() => {
        this.myReports_.next(this.myReports_.value);
        this.infoService.showMessage($localize`:@@ctrl_cur_last_to_current_success:Status successfully corrected.`, {
          infoType: InfoType.Success
        });
      }))
      .subscribe();
  }

  private updateMyReports(observable: Observable<Report>): Observable<Report> {
    return combineLatest([
      observable.pipe(take(1)),
      this.fetchMyReports().pipe(take(1)),
    ])
      .pipe(map(([ report, reports ]) => {
        const uuid = report.reportConfig.uuid;

        const responseReport = (reports ?? [])
          .find(r => r?.reportConfig?.uuid === uuid);
        if ( responseReport == null ) {

          // this report is unknown -> add to reports
          reports.push(report);

        } else {

          // copy report config from old state into response
          const reportConfig = ViewHelper.cloneDeep(report.reportConfig);
          ViewHelper.copyData(responseReport.reportConfig, reportConfig);
          responseReport.reportConfig = reportConfig;
        }

        reports.sort((reportA, reportB) => {
          const titleA = (reportA && reportA.reportConfig && reportA.reportConfig.title || '').toLocaleLowerCase();
          const titleB = (reportB && reportB.reportConfig && reportB.reportConfig.title || '').toLocaleLowerCase();
          return titleA.localeCompare(titleB);
        });

        this.myReports_.next(reports);
        return report;
      }));
  }

}
