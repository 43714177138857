import { AbstractControl, ValidatorFn } from "@angular/forms";
import { isNothing } from "./utils";
import { GEO_PATTERN, TIME_PATTERN, TELEPHONE_PATTERN } from 'src/app/core/core.types';

// 40.741895, -73.989308
export namespace MyValidators {

  export const GeoValidator: ValidatorFn = (c: AbstractControl) => {
    if (isNothing(c.value) || c.value.trim().length === 0) {
      return null;
    }
    if (GEO_PATTERN.test(c.value)) {
      return null;
    }
    return { invalid: true };
  }

  export const ValueValidator = (value: any) : ValidatorFn => {
    return (c) => value === c.value ? null : { invalid: true };
  }

  export const TimeValidator: ValidatorFn = (c: AbstractControl) => {
    if ( c.parent == null ) {
      return null;
    }
    if ( c.value == null || c.value.length === 0 ) {
      return null;
    }
    if ( c.value.match(TIME_PATTERN) ) {
      return null;
    }
    return {
      timeFormat: true,
    };
  };

  export const TelephoneValidator = (c: AbstractControl) => {
    if (c.parent == null ) {
      return null;
    }

    if (c.value == null || c.value.length === 0) {
      return null;
    }

    if (c.value.match(TELEPHONE_PATTERN) ) {
      return null;
    }

    return {
      telephoneFormat: true,
    }
  }
}
