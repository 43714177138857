import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelfRegisterResolverService } from 'src/app/core/resolvers/self-register.service';
import { AuthGuestGuard } from '../../../auth-guest.guard';
import { SelfRegisterActivationComponent } from './self-register-activation/self-register-activation.component';
import { SelfRegisterFormModule } from './self-register-form/self-register-form.module';
import { SelfRegisterFormComponent } from './self-register-form/self-register-form.component';
import { SelfRegisterFormResolver } from './self-register-form/self-register-form.resolver';
import { SelfRegisterFormGuard } from './self-register-form/self-register-form.guard';
import { SelfRegisterComponent } from './self-register.component';
import { SelfRegisterBridgeComponent } from './self-register-bridge/self-register-bridge.component';


const routes: Routes = [
  {
    path: 'register',
    component: SelfRegisterBridgeComponent,
    canActivate: [ SelfRegisterFormGuard ],
    resolve: {
      info: SelfRegisterResolverService,
      infoV2: SelfRegisterFormResolver,
    },
  },
  {
    path: 'register-v1',
    component: SelfRegisterComponent,
    resolve: { info: SelfRegisterResolverService },
    canActivate: [ AuthGuestGuard ],
  },
  {
    path: 'register-v2',
    component: SelfRegisterFormComponent,
    canActivate: [ SelfRegisterFormGuard ],
    resolve: { infoV2: SelfRegisterFormResolver },
  },
  {
    path: 'register/:key',
    component: SelfRegisterActivationComponent,
    canActivate: [ AuthGuestGuard ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SelfRegisterFormModule,
  ],
  exports: [
    RouterModule,
  ],
})
export class SelfRegisterRoutingModule {
}
