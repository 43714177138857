import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, map, take } from 'rxjs/operators';
import { ApiError } from '../global.types';
import { InfoService } from '../info/info.service';
import { InfoType, MessageKey } from '../info/info.types';
import { MaintenanceService } from '../maintenance/maintenance.service';
import { ApiUrls } from '../api.urls';
import {GrafanaFaroService} from '../grafana-faro/grafana-faro.service';


export const BYPASS_ERRORS_INTERCEPTOR = new HttpContextToken(() => false);

type ErrorMessageFactory = (error: ApiError) => string;

interface StatusHandlingResponse {
  shouldRethrow: boolean;
  shouldCancel: boolean;
}

const defaultErrorMessageFactory: ErrorMessageFactory = (error: ApiError) => `ERROR: errorCode:${error.errorCode}, messageKey: ${error.messageKey}, type: ${error.type}`;

const errCrs010: ErrorMessageFactory = (error: ApiError) =>
  // example
   defaultErrorMessageFactory(error)
;

const errCrsCtrl003: ErrorMessageFactory = (error: ApiError) => {
  const transMessage = $localize`:@@error_ctrl_user_validation:
    Invalid provided state. Please check your input and try again.`;
  return `${transMessage} ('${error.errorCode}', '${error.type}')`;
};

const errCrsCtrl002: ErrorMessageFactory = (error: ApiError) => {
  switch ( error.details?.detailErrorCode ) {
    case '#CCSNI4':
      return $localize`:@@general_not_allowed_operation:You are not allowed to perform this operation`;
  }
  return defaultErrorMessageFactory(error);
};

const errCrsCtrl004: ErrorMessageFactory = (error: ApiError) => {
  switch ( error.details?.detailErrorCode ) {
    case '#CCEWP3':
      return $localize`:@@general_not_allowed_operation:You are not allowed to perform this operation`;
    default:
      return $localize`:@@general_certification_error:Certification failed. Please, try again later`;
  }
};

const errCat1: ErrorMessageFactory = (error: ApiError) => {
  switch ( error.details?.detailErrorCode ) {
    case '#CABO1':
      return $localize`:@@cat_not_supported_cnt_type:This content type is not supported yet`;
    case '#CABO3':
    case '#CABO6':
      return $localize`:@@cat_cnt_already_assigned:
        You have this content already assigned to you. You will find it in your overview`;
    default:
      return defaultErrorMessageFactory(error);
  }
};

const errDist7: ErrorMessageFactory = () => $localize`:@@dist_error:The last operation failed. Please contact your system administrator for help.`;

const errCerts1: ErrorMessageFactory = (classicCertErrorResponse: any) => {
  switch ( classicCertErrorResponse.errorCode ) {
    case '#GCA1':
    case '#GCA2':
    case '#GCA3':
      return $localize`:@@certificates_unexpected_error:Unexpected error`;
    case '#GCA4':
      return $localize`:@@certificates_No_certificate_template:No certificate available`;
    case '#GCA5':
      return $localize`:@@certificates_single_pdf_method_called_with_multi_user_ids:
        Single pdf method called with multi user ids`;
    case '#GCA6':
      return $localize`:@@certificates_no_valid_certification_data:No valid certification data`;
    case '#GCA7':
      return $localize`:@@certificates_certificate_is_blocked:Certificate is blocked`;
    case '#GCA8':
      return $localize`:@@certificates_only_curriculum_accepted:Only curriculum accepted`;
    case '#GCA9':
      return $localize`:@@certificates_no_content_generated:No content generated`;
    case '#GCA10':
      return $localize`:@@certificates_certificate_file_not_found:
        Previously generated certificate file expected, but not found`;
    default:
      return classicCertErrorResponse.error;
  }
};

const errGen4: ErrorMessageFactory = () => $localize`:@@error.general.4:Invalid input parameters`;

const errCerts2: ErrorMessageFactory = () => $localize`:@@general_not_valid_operation:This iteration has no valid status`;

const errAdbConnect: ErrorMessageFactory = (error: ApiError) => {

  if ( error.errorCode === 'ERR_GENERAL_2' ) {
    return $localize`:@@error.general.2:The server is not reachable, please try again later.`;
  } else if (error.errorCode === 'ERR_AC_LOGIN_FAILED') {
    return $localize`:@@error_adb_cnt_login_failed:Provided login credentials are not correct.`;
  }

  return defaultErrorMessageFactory(error);
};

const errBadRequest: ErrorMessageFactory = () => $localize`:@@error.general.400:The content is in use or this operation is prohibited by some circumstances`;

const errSelfRegNotAvailable: ErrorMessageFactory = () => $localize`:@@register_not_available:Self registration is not available`;

const errSelfRegProcessAlreadyCompleted: ErrorMessageFactory = () => $localize`:@@register_already_completed:This self registration process has already been completed.`;

const ErrorFactories = new Map<string, ErrorMessageFactory>();
ErrorFactories.set('ERR_CRS_010', errCrs010);
ErrorFactories.set('ERR_CRS_CTRL_003', errCrsCtrl003);
ErrorFactories.set('ERR_CTRL_CERT_ITER_002', errCrsCtrl002);
ErrorFactories.set('ERR_CTRL_CERT_ITER_004', errCrsCtrl004);
ErrorFactories.set('ERR_CAT_1', errCat1);
ErrorFactories.set('ERR_CAT_004', () => $localize`:@@catalog-no-access:The catalog is not available for this user.`);
ErrorFactories.set('ERR_CAT_005', () => $localize`:@@catalog-no-data:There are no catalog entries available.`);
ErrorFactories.set('ERR_CAT_006', () => $localize`:@@catalog-no-data:There are no catalog entries available.`);
ErrorFactories.set('ERR_CAT_007', () => $localize`:@@catalog-no-data:There are no catalog entries available.`);
ErrorFactories.set('ERR_CERTS_1', errCerts1);
ErrorFactories.set('#CCEWP1', errCerts2);
ErrorFactories.set('ERR_DST_007', errDist7);
ErrorFactories.set('ERR_AC_LOGIN_FAILED', errAdbConnect);
ErrorFactories.set('ERR_GENERAL_4', errGen4);
ErrorFactories.set('ERR_API_004', errBadRequest);
ErrorFactories.set('ERR_ACC_018', errSelfRegNotAvailable);
ErrorFactories.set('ERR_ACC_020', errSelfRegProcessAlreadyCompleted);

@Injectable({
  providedIn: 'root',
})
export class ErrorsInterceptorService
  implements HttpInterceptor{

  private isError500AlertDisplayed = false;

  constructor(
    private maintenanceService: MaintenanceService,
    private infoService: InfoService,
    private grafanaFaroService: GrafanaFaroService,
  ) {
  }

  public static handleMultipleErrors(e) {
    if ( e == null ) {
      return null;
    }
    if ( Array.isArray(e.errors) && e.errors.length ) {

      // there is an error
      const err = new Error();

      const firstError = e.errors[0];
      const errorCode = firstError?.errorDetails?.errorCode ?? firstError.errorCode;
      if (errorCode == null) {
        err.name = 'Unkown error';
        return err;
      }

      const messageFactory = ErrorFactories.get(errorCode) ?? defaultErrorMessageFactory;

      if ( messageFactory === defaultErrorMessageFactory ) {
        if ( firstError?.errorDetails?.errorMessage ) {
          err.message = firstError?.errorDetails?.errorMessage;
          if ( firstError?.errorDetails?.errorCode ) {
            err.message += ' (' + firstError?.errorDetails?.errorCode + ')';
          }
        }
      }

      err.name = 'multiple errors';
      if (err.message == null || err.message === '') {
        err.message = messageFactory(firstError);
      }
      err['errors'] = e.errors;
      return err;
    }
    return e;
  }

  private static checkSuccess(obj: any) {

    if ( obj == null ) {
      return;
    }

    if ( obj.success === true || obj.success === 'true' ) {
      // all is fine, success === true
      return;
    }

    if ( obj.error ) {

      const errorResponse = (typeof (obj.error) === 'object') ? JSON.stringify(obj.error) : obj.error;
      // there is an error
      const err = new Error(errorResponse || 'received success false!');
      err['error'] = obj.error;
      err['errorCode'] = obj.errorCode;
      err['response'] = obj;
      throw err;
    }

    const e = ErrorsInterceptorService.handleMultipleErrors(obj);
    if ( e !== obj ) {
      throw e;
    }
  }

  private static shouldIgnoreErrors(url?: string): boolean {

    // todo add more specific cases
    url = url ?? '';
    return url.includes(ApiUrls.getKey('GlobalMaintenance')) ||
      url.includes(ApiUrls.getKey('UserProfileUpdateCredentials')) ||
      /\/admin\/v1\/user\/imports\/[\w\d-]+\/run$/.test(url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.context.get(BYPASS_ERRORS_INTERCEPTOR) === true) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(filter(event => event instanceof HttpResponse && event.body))
      .pipe(map((event: HttpResponse<any>) => {
        ErrorsInterceptorService.checkSuccess(event.body);
        ErrorsInterceptorService.checkSuccess(event.body.data);
        this.maintenanceService.clearApiError(false);
        return event;
      }))
      .pipe(catchError(e => {
        // handle general Forbidden error
        const statusHandling = this.handleHttpStatus(e);
        if (statusHandling.shouldRethrow) {
          return throwError(e);
        }

        if (e.status === 0 || statusHandling.shouldCancel) {
          // ignore cancelled requests
          return EMPTY;
        }

        if ( Array.isArray(e?.error?.errors) ) {
          e = ErrorsInterceptorService.handleMultipleErrors(e.error);
        }

        if ( !this.maintenanceService.showMaintenanceApiError(e) ) {
          // ToDo snackBar for HttpRequest Errors only if there is a special flag to show them
          // Nicht alle Fehler sollen angezeigt werden! (Bsp. erster fehlgeschlagene Aufruf der Loginseite)
          /*if (!req.hideErrors) {
            this.infoService.showSnackbar(MessageKey.GENERAL_API_ERROR_RESPONSE, InfoType.Error, {message: e});
          }*/
        } else {
          this.maintenanceService.clearApiError();
        }

        if ( e.name === 'multiple errors' ) {
          this.infoService.showSnackbar(null, InfoType.Error, {
            message: e.message,
          });
        } else if ( ErrorFactories.has(e) ) {
          const errorkey = ErrorFactories.get(e);
          const message = errorkey(e);
          this.infoService.showMessage(message, { infoType: InfoType.Error },
          );
        } else if (e.error && e.error instanceof Blob && e.error?.type === 'application/json') {
          const reader = new FileReader();
          reader.addEventListener('loadend', (pe) => {
            if ( typeof pe.target.result === 'string' ) {
              const errorObject = JSON.parse(pe.target.result);
              const msg = errCerts1(errorObject);
              this.infoService.showMessage(msg, { infoType: InfoType.Error });
            }
          });
          reader.readAsText(e?.error);
        } else {
          switch ( e.status ) {
            case 500:
              this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
              break;
          }
        }
        return throwError(e);
      }));
  }

  private handleHttpStatus(httpErrorResponse: HttpErrorResponse): StatusHandlingResponse {

    const httpStatus = httpErrorResponse.status;

    const response: StatusHandlingResponse = {
      shouldRethrow: true,
      shouldCancel: false,
    };

    const url = httpErrorResponse.url;
    if ( ErrorsInterceptorService.shouldIgnoreErrors(url) ) {
      return response;
    }

    switch ( true ) {

      case (httpStatus === 0):
      case (httpStatus >= 200 && httpStatus < 400):
        // all is fine / request was cancelled
        response.shouldRethrow = false;
        break;

      case (httpStatus === 400): {
        response.shouldRethrow = false;
        return response;
      }

      case (httpStatus === 403):
        if ( url.includes('/API/account/settings/styleinfo/') ) {
          // this happens in case of a corrupted http session (roles are empty / usually guests may call this)
          // @see TF-5035
          response.shouldRethrow = true;
          return response;
        }
        this.infoService.showAlert($localize`:@@general_status_403:You do not have required privileges to perform this operations.`);
        this.grafanaFaroService.pushNotPermitted( httpErrorResponse.message );
        break;

      case (httpStatus === 408):
        if (this.isError500AlertDisplayed) {
          break;
        }
        this.isError500AlertDisplayed = true;
        this.infoService.showAlert($localize`:@@maintenance_timeout_error:
                  The server appears to be slow at the moment. Please check your network connection.`)
          .pipe(finalize(() => this.isError500AlertDisplayed = false))
          .pipe(take(1))
          .subscribe();
        break;

      case (httpStatus >= 500): {
        if (this.isError500AlertDisplayed) {
          break;
        }
        this.isError500AlertDisplayed = true;
        const timestamp = new Date().getTime();
        this.infoService.showAlert($localize`:@@general_status_500:An unexpected error occurred. Please try again later. <br>If the problem persists, report it using the contact form and specify the time at which the error occurred (${timestamp}:timestamp:).`)
          .pipe(finalize(() => this.isError500AlertDisplayed = false))
          .pipe(take(1))
          .subscribe();
        this.grafanaFaroService.pushWhoops( httpErrorResponse.message );
      }
        break;

      default:
          console.warn('Not implemented handler for Http Status ' + httpStatus);
    }

    return response;
  }

}
