import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ContentOverviewModule } from './content-overview/content-overview.module';
import { ContentModule } from './content/content.module';
import { HomeModule } from './home/home.module';
import { LinkModule } from './link/link.module';
import { MediaLibraryComponent } from './media-library/media-library.component';
import { ProfileModule } from './profile/profile.module';
import { ScoModule } from './sco/sco.module';
import { ContentLearnerCockpitModule } from './content-learner-cockpit/content-learner-cockpit.module';
import { LiveAgendaModule } from './live-agenda/live-agenda.module';
import { TransactionsModule } from './transactions/transactions.module';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';

@NgModule({
  exports: [
    ContentModule,
    ContentLearnerCockpitModule,
    ContentOverviewModule,
    HomeModule,
    LinkModule,
    ProfileModule,
    ScoModule,
  ],
  imports: [
    CommonModule,
    ContentModule,
    ContentLearnerCockpitModule,
    ContentOverviewModule,
    HomeModule,
    LinkModule,
    PageHeaderComponent,
    ProfileModule,
    ScoModule,
    PipesModule,
    MatDialogModule,
    LiveAgendaModule,
    TransactionsModule,
  ],
  declarations: [ MediaLibraryComponent ],
})
export class UserModule {
}
