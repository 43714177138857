<rag-page-header
  backUrl="/qa"
>
  <div class="page-header-title">
    <span i18n="@@global_new_contribution">New contribution</span>
  </div>
  <div class="page-header-buttons">
    <button
      type="button"
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="onSendQuestion()"
      data-cy="submitButton"
    >
      <span i18n="@@Submit">Submit</span>
    </button>
  </div>
</rag-page-header>

<form [formGroup]="form" class="form">
  <div class="main">
    <div class="left">
      <mat-form-field appearance="outline">
        <mat-label i18n="@@global_contribution_title">Contribution title</mat-label>
        <input
          formControlName="title"
          type="text"
          matInput
          required
          placeholder="Title"
          i18n-placeholder="@@global_title"
          data-cy="contributionTitleInput"
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label i18n="@@global_contribution_feedback_suggestion">Contribution, feedback or suggestion</mat-label>
        <textarea
          formControlName="question"
          matInput
          rows="10"
          required
          placeholder="Contribution, feedback or suggestion"
          i18n-placeholder="@@global_contribution_feedback_suggestion"
          data-cy="contributionInput"
        ></textarea>
      </mat-form-field>

      <div class="rules-wrapper">
        <span class="bold" i18n="@@qa_create_rules_1">Rules for contributions:</span>
        <br>
        <span i18n="@@contribution_rules">In order for your contribution to be published, please pay attention to these three points: <br>
          Do not give names - your contribution must not allow conclusions to be drawn about specific persons. <br>
          Do not pass on any unofficial knowledge. <br>
          It goes without saying, but we would still like to mention it: Please do not make any insulting statements!
        </span>
      </div>
    </div>
    <div class="right">
      <img alt="" [src]="'assets/images/qa_default.png'" class="qa-default">
    </div>
  </div>
</form>
