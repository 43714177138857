import {NgModule} from '@angular/core';
import {CourseCardComponent} from './course-card/course-card.component';
import {CatalogCourseCardComponent} from './catalog-course-card/catalog-course-card.component';
import {AsyncPipe, CommonModule, NgIf} from '@angular/common';
import {PipesModule} from '../../../core/pipes/pipes.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    AsyncPipe,
    PipesModule,
    MatCardModule,
    NgIf,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [
    CourseCardComponent,
    CatalogCourseCardComponent,
  ],
  exports: [
    CourseCardComponent,
    CatalogCourseCardComponent,
  ]
})

export class CourseCardModule {

}
