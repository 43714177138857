import { NgModule } from '@angular/core';
import { ReportTitleDialogComponent } from './report-title-dialog.component';
import { InfoModule } from '../../../../../core/info/info.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';


@NgModule({
  declarations: [ ReportTitleDialogComponent ],
  exports: [ ReportTitleDialogComponent ],
  imports: [
    CommonModule,
    DialogHeaderComponent,
    InfoModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
  ],
})
export class ReportTitleDialogModule {
}
