import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';

export namespace CtrlSingleUserDetailsCurriculumEditColumns {

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    displayStatus: TableControllerTypes.ColumnMenuItem;
    finishDate: TableControllerTypes.ColumnMenuItem;
    locked: TableControllerTypes.ColumnMenuItem;
    orderIndex: TableControllerTypes.ColumnMenuItem;
    title: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'orderIndex' ],
    endWith: [ 'actions' ],
    menuItems: {
      orderIndex: TableControllerTypes.Util.structuralColumnFactory('orderIndex', $localize`:@@global_nr:No.`),

      title: TableControllerTypes.Util.textColumnFactory('title', $localize`:@@global_title:Title`, null, true),
      locked: TableControllerTypes.Util
        .dropdownColumnFactory('locked', $localize`:@@global_locked:Locked`, {
          true: $localize`:@@global_locked_filter_true:Locked`,
          false: $localize`:@@global_locked_filter_false:Unlocked`,
        }, $localize`:@@global_locked_filter_placeholder:Is locked?`, null, true),
      displayStatus: TableControllerTypes.Util.displayStatusColumnFactory('displayStatus',
        $localize`:@@global_status:Status`, {
          green: $localize`:@@status_light_tooltip_green:Green`,
          yellow: $localize`:@@status_light_tooltip_yellow:Yellow`,
          red: $localize`:@@status_light_tooltip_red:Red`,
          not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
          not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
        }, $localize`:@@report_filter_status_is:Status is...`, null, true),
      finishDate: TableControllerTypes.Util.dateColumnFactory('finishDate',
        $localize`:@@ctrl_single_user_details_finishDate:Finish date`, null, true),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };

}
