import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import { AdminQAService } from '../admin-qa.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from '../../../../core/info/info.types';
import { QAAnswer, QAQuestion, QAStatus } from 'src/app/route/user/qa/qa.types';

@Component({
  selector: 'rag-admin-qa-edit',
  templateUrl: './admin-qa-edit.component.html',
  styleUrls: [ './admin-qa-edit.component.scss' ],
})
export class AdminQAEditComponent
  implements OnInit, OnDestroy {

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  question: QAQuestion;
  form: UntypedFormGroup;
  allTags: Array<string> = [];
  saveButtonDisabled$: Observable<boolean>;
  private _saveButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private adminQAService: AdminQAService,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private route: ActivatedRoute,
  ) {
    this.saveButtonDisabled$ = this._saveButtonDisabled$.asObservable();
  }

  hasQuestionChanges(): boolean {
    const controls = this.form.controls;
    return controls['questionTitle'].value !== this.question.questionAbstract ||
      controls['questionText'].value !== this.question.questionText ||
      this.question.modifiedByAdmin === true;
  }

  onResetQuestion(): void {
    const controls = this.form.controls;
    this.infoService.showMessage(
      $localize`:@@contribution_reset_confirm:Would you like to reset the contribution to the original contribution?`,
      {
        buttons: YesNoButtons,
        title: MessageConstants.DIALOG.TITLE.CONFIRM
      })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => this.adminQAService.resetQuestion(this.question.uuid)))
      .pipe(tap(question => {
        controls['questionTitle'].setValue(question.questionAbstract, {emitEvent: false});
        controls['questionText'].setValue(question.questionText, {emitEvent: false});
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
      }))
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {

    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onSaveQuestion(): void {
    const controls = this.form.controls;
    const answer: QAAnswer = {
      uuid: this.question?.answer?.uuid ?? null,
      questionUUID: this.question?.uuid,
      answerText: controls['answerText'].value,
    };

    const uuid = this.question?.answer?.uuid;
    if (uuid != null) {
      answer.uuid = uuid;
    }

    const status = this.question.status === QAStatus.NEW ? QAStatus.DRAFT : this.question.status;
    const saveData: QAQuestion = {
      uuid: this.question.uuid,
      questionAbstract: controls['questionTitle'].value,
      questionText: controls['questionText'].value,
      status,
      tags: controls['tags'].value.join(','),
      answer,
    };

    this.adminQAService.saveQuestion(saveData)
      .pipe(tap(response => {
        this.question = response;
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
        this._saveButtonDisabled$.next(true);
      }))
      .pipe(take(1))
      .subscribe();
  }

  private buildFormGroup(): void {
    let tags = [];
    if ( this.question.tags !== '' ) {
      tags = this.question.tags.split(',');
    }
    this.form = this.formBuilder.group({
      questionTitle: [ this.question.questionAbstract ],
      questionText: [ this.question.questionText ],
      answerText: [ this.question?.answer?.answerText ?? '' ],
      tags: [ tags ],
    });

    this.form.statusChanges.pipe(map(_ => {
      this._saveButtonDisabled$.next(!this.form.valid);
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

  }

  private updateRouteData(data): void {
    this.question = data.details.question;
    this.allTags = data.details.tags.split(',');
    this.buildFormGroup();
  }
}
