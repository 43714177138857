<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@admin_text_macros">Text macros</span>
  </div>

  <div
    class="page-header-buttons"
  >
    <a
      color="primary"
      mat-flat-button
      [routerLink]="'/admin/textMacros/details/0'"
      data-cy="buttonAdd">
      <span i18n="@@general_create">Create</span>
    </a>
  </div>
</rag-page-header>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="admin-text-macros-list-columns"
  data-cy="menuTableColumns">
</rag-table-column-menu>

<mat-menu #signaturesActionsMenu xPosition="before">
  <ng-template let-signature="signature" matMenuContent>
    <a
      mat-menu-item
      type="button"
      [routerLink]="'/admin/textMacros/details/' + signature.id"
      data-cy="menuItemDetails"
    >
      <mat-icon svgIcon="magnify"></mat-icon>
      <span i18n="@@global_details">Details</span>
    </a>

    <mat-divider></mat-divider>

    <button
      type="button"
      mat-menu-item
      (click)="onDelete(signature.id)"
      data-cy="menuItemDelete"
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">
  <rag-sticky-scroll [recalculate]="recalculateSticky$" [ngClass]="{'sticky-scroll-height': !isDataEmpty}">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded && (renderColumns?.length > 0); else tplDataLoading">
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort
      >
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [row]="row"
              [column]="column"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
              data-cy="filterTable"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
              data-cy="dotsGlobal"
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let signature" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{signature: signature}"
              [matMenuTriggerFor]="signaturesActionsMenu"
              mat-icon-button
              data-cy="hamTableRow">
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged" data-cy="filterReset">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
