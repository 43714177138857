<div class="section-header">{{section?.title | localeText}}</div>

<div class="profile-fields">

  <rag-profile-field
    *ngFor="let field of section?.fields"
    [field]="field"
    [disabled]="disabled"
    class="item"
  ></rag-profile-field>

</div>
