import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageHelper, LanguageInfo} from '../../../../core/language.helper';
import {PermissionStates} from '../../../../core/principal/permission.states';
import {catchError, map, take, tap} from 'rxjs/operators';
import {PrincipalService} from '../../../../core/principal/principal.service';
import {destroySubscriptions, takeUntilDestroyed} from 'src/app/core/reactive/until-destroyed';
import {AccountDesignService} from '../account-design.service';
import {InfoType} from '../../../../core/info/info.types';
import {InfoService} from '../../../../core/info/info.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {StartPageSettings} from '../account-design.types';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';
import {MergeHelper} from '../../../../core/primitives/merge.helper';
import {CKEditorDefaults} from '../../../../core/ckeditor.types';
import {EditLoginPageDialogComponent} from '../edit-login-page-dialog/edit-login-page-dialog.component';
import {ModalDialog} from '../../../../core/modal-dialog';
import {DirtyCheckService} from '../../../../core/dirty-check.service';
import {RagCKEditorHelper} from '../../../../core/input/rag-ckeditor/ragCkeditorHelper';
import { LoginPreviewComponent } from './login-preview/login-preview.component';

@Component({
  selector: 'rag-login-page-conf',
  templateUrl: './login-page-conf.component.html',
  styleUrls: ['./login-page-conf.component.scss']
})
export class LoginPageConfComponent
  implements OnInit, OnDestroy {
  language: LanguageInfo;
  permissionStates: PermissionStates;
  welcomePictureURL: string = 'assets/images/train_bg_login.jpg';
  form: FormGroup;
  startPage: StartPageSettings;
  editor = Editor;
  languages: LanguageInfo[] = LanguageHelper.LANGUAGES;
  readonly config = MergeHelper.cloneDeep(CKEditorDefaults.CKEDITOR_OPTIONS);

  constructor(
    private accountDesignService: AccountDesignService,
    private dirtyCheckService: DirtyCheckService,
    private principalService: PrincipalService,
    private infoService: InfoService,
    private formBuilder: FormBuilder,
    public dialog: ModalDialog,
  ) {
    const removeItems = [
      'elementspath', 'a11yhelp', 'about', 'copyformatting', 'div', 'selectall', 'language', 'find', 'flash', 'forms',
      'image', 'magicline', 'pagebreak', 'pastefromword', 'preview', 'print', 'showblocks', 'table', 'tableselection',
      'tabletools', 'templates', 'imageInsert', 'trainMacros', 'sourceEditing',
    ];
    RagCKEditorHelper.reduceCKEditorItems(removeItems, this.editor);
  }

  ngOnInit() {
    this.principalService.permissionStates$
      .pipe(map(permissions => this.permissionStates = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.accountDesignService.getStartPage()
      .pipe(tap(data => this.startPage = data))
      .pipe(tap(() => this.reloadImages()))
      .pipe(tap(() => this.buildFormGroup()))
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onLanguageChange(languageInfo: LanguageInfo) {
    this.language = languageInfo;
    this.form.get('loginFormTexts')
      .setValue(LanguageHelper.objectToText(this.startPage.acc.loginFormTexts, this.language.key), { emitEvent: false });

    this.form.get('loginPlaceholder')
      .setValue(LanguageHelper.objectToText(this.startPage.acc.loginPlaceholder, this.language.key), { emitEvent: false });

    this.form.get('loginError')
      .setValue(LanguageHelper.objectToText(this.startPage.acc.loginError, this.language.key), { emitEvent: false });

    this.form.get('welcomeTexts')
      .setValue(LanguageHelper.objectToText(this.startPage.acc.welcomeTexts, this.language.key), { emitEvent: false });
  }

  buildFormGroup(): void {
    const acc = this.startPage.acc;
    this.form = this.formBuilder.group({
      loginFormTexts: [acc.loginFormTexts],
      loginPlaceholder: [acc.loginPlaceholder],
      loginError: [acc.loginError],
      welcomeTexts: [acc.welcomeTexts],
      // registrationButtonActive: [acc.registrationButtonActive],
      showNewLogin: [acc.showNewLogin ?? false],
      applyCustomColors: [acc.applyCustomColors],
    });

    this.form.get('loginFormTexts').valueChanges
      .pipe(map(value => {
        LanguageHelper.setTranslation(this.startPage.acc.loginFormTexts, value, this.language.key);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.get('loginPlaceholder').valueChanges
      .pipe(map(value => {
        LanguageHelper.setTranslation(this.startPage.acc.loginPlaceholder, value, this.language.key);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.get('loginError').valueChanges
      .pipe(map(value => {
        LanguageHelper.setTranslation(this.startPage.acc.loginError, value, this.language.key);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.get('welcomeTexts').valueChanges
      .pipe(map(value => {
        LanguageHelper.setTranslation(this.startPage.acc.welcomeTexts, value, this.language.key);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.statusChanges.pipe(map(status => {
      this.emitChange(true, status === 'VALID');
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  editLoginPicture(): void {
    this.dialog.openModal(EditLoginPageDialogComponent, {
      data: {
        item: 'welcomePictureId',
        value: this.startPage.acc.welcomePictureId,
        language: this.language
      },
      disableClose: true,
      minWidth: '70vw',
    })
      .afterClosed()
      .pipe(tap(result => {
        if (result?.confirmed && (result.data != this.startPage.acc.welcomePictureId)) {
          this.startPage.acc.welcomePictureId = result.data;
          this.emitChange(true, this.form.valid);
          this.form.markAsDirty();
          this.reloadImages();
        }
      }))
      .pipe(take(1))
      .subscribe();
  }

  reloadImages(): void {
    this.accountDesignService.getImages()
      .pipe(map(images => {
        images.forEach(image => {
          if (image.fileId === this.startPage.acc.welcomePictureId) {
            this.welcomePictureURL = image.link;
          }
        });
      }))
      .pipe(take(1))
      .subscribe();
  }

  onOpenPreview(): void {
    const loginPageInformation = {
      applyCustomColors: this.form.get('applyCustomColors').value,
      welcomePictureId: this.startPage.acc.welcomePictureId,
      loginError: this.form.get('loginError').value,
      loginFormTexts: this.form.get('loginFormTexts').value,
      loginPlaceholder: this.form.get('loginPlaceholder').value,
      welcomeTexts: this.form.get('welcomeTexts').value,
      // registrationButtonActive: this.form.get('registrationButtonActive').value,
    };
    const showNewLogin = this.form.get('showNewLogin').value === true;
    const config = {
      data: {
        version: showNewLogin ? 2 : 1,
        texts: loginPageInformation,
      },
    };

    if (showNewLogin) {
      config['minWidth'] = '400px';
      config['width'] = '90vw';
    } else {
      config['width'] = '75vw';
    }
    this.dialog.open(LoginPreviewComponent, config);
  }

  onSave() {
    const acc = this.startPage.acc;
    const loginPageInformation = {
      applyCustomColors: this.form.get('applyCustomColors').value,
      welcomePictureId: acc.welcomePictureId,
      loginError: acc.loginError,
      loginFormTexts: acc.loginFormTexts,
      loginPlaceholder: acc.loginPlaceholder,
      welcomeTexts: acc.welcomeTexts,
      // registrationButtonActive: this.form.get('registrationButtonActive').value,
      showNewLogin: this.form.get('showNewLogin').value,
    };

    this.accountDesignService.uploadLoginPageInformation(loginPageInformation)
      .pipe(tap(() => {
        this.emitChange(false, this.form.valid);
        this.form.markAsPristine();
        this.infoService.showMessage($localize`:@@account_design_login_page_data_safed:Settings saved for the login page!`, {
          infoType: InfoType.Success
        });
      }))
      .pipe(catchError(() =>
        this.infoService.showMessage($localize`:@@account_design_login_page_data_not_safed:Settings for the login page not saved!`, {
          infoType: InfoType.Error
        })))
      .subscribe();
  }

  private emitChange(isDirty = true, isValid = true): void {
    this.dirtyCheckService.submitNextState('AccountDesignComponent', isDirty);
  }

}
