<div mat-dialog-title class="header">
  <button mat-mini-fab
          color="warn"
          [mat-dialog-close]="true">
    <mat-icon svgIcon="close-thick"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p><strong>Haben Sie im Vorfeld des Praxis-Camps Fragen?</strong></p>
  <p>Dann nutzen Sie bitte unser <a routerLink="/contact">Kontaktformular</a>. Wir melden uns schnellstmöglich bei Ihnen.</p>
  <br>
  <p><strong>Benötigen Sie am Tag des Praxis-Camps direkte Unterstützung?</strong></p>
  <p>Dann erreichen Sie unser Event-Team unter <a href="tel:+49 208 77899 707">+49 208 77899 707</a>.</p>
</div>
