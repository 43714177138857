import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, mapTo, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { ApiUrls } from '../../api.urls';
import { CachedSubject } from '../../cached-subject';
import { InfoService } from '../../info/info.service';
import { InfoType, MessageKey } from '../../info/info.types';
import { PrincipalService } from '../../principal/principal.service';
import { PrincipalData } from '../../principal/principal.types';
import { LinkedUserTypes } from './linked-users.types';
import { Router } from '@angular/router';
import { RedirectHelper } from '../../redirect.helper';
import { DeleteUserDialogComponent } from 'src/app/component/delete-user-dialog/delete-user-dialog.component';
import { SwitchUserDialogComponent } from 'src/app/component/switch-user-dialog/switch-user-dialog.component';
import { LogoutService } from '../../principal/logout.service';
import { TrainResponse } from '../../global.types';


@Injectable({
  providedIn: 'root',
})
export class LinkedUsersService {

  private _linkedUsers = new CachedSubject<LinkedUserTypes.LinkedUser[]>(null);

  constructor(
    private accountDesignService: AccountDesignService,
    private http: HttpClient,
    private infoService: InfoService,
    private logoutService: LogoutService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
    this.principalService.isLogged$
      .pipe(tap(this.resetLinkedUsers))
      .subscribe();
  }

  private static storeLoginRedirect<T>(token: T, url): Observable<T> {
    return RedirectHelper.setRedirect(url)
      .pipe(mapTo(token));
  }

  get linkedUsers$(): Observable<LinkedUserTypes.LinkedUser[]> {
    return this._linkedUsers.withoutEmptyValuesWithInitial();
  }

  addLinkToUser(user: LinkedUserTypes.AddLinkedUser): Observable<any> {
    return this.http.put<any>(ApiUrls.getKey('AddLinkToUser'), {
      login: '' + user.login,
      pass: '' + user.password,
    }, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map(response => {
        // console.log('addLinkToUser', response);
        const currentUserId = this.principalService.currentUser.userId;
        if ( currentUserId === response.userId ) {
          this.infoService.showSnackbar(MessageKey.LINKED_USERS_IS_CURRENT_USER, InfoType.Information);
          return;
        }
        const linkedUsers: LinkedUserTypes.LinkedUser[] = this._linkedUsers.value || [];
        const index = linkedUsers.findIndex(x => x.userId === response.userId);
        if ( index === -1 ) {
          this.infoService.showSnackbar(MessageKey.LINKED_USERS_IS_CURRENT_USER, InfoType.Success);
        } else {
          this.infoService.showSnackbar(MessageKey.LINKED_USERS_ALREADY_EXIST, InfoType.Error);
        }
        return response;
      }))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_ADD_LINK_TO_USER_FAIL, InfoType.Error);
        return EMPTY;
      }))
      .pipe(switchMap(() =>
        // refresh linked users to update table
         this.getLinkedUsers(true)
      ));
  }

  getLinkedUsers(reset = false): Observable<LinkedUserTypes.LinkedUser[]> {
    if ( reset ) {
      this.resetLinkedUsers();
    }
    if ( this._linkedUsers.queryStart() ) {
      this.http.get<TrainResponse<LinkedUserTypes.LinkedUser[]>>(ApiUrls.getKey('MyLinkedUsers'))
        .pipe(map(response => response?.data ?? []))
        .pipe(catchError(() => of([])))
        .pipe(map(this._linkedUsers.next))
        .pipe(finalize(this._linkedUsers.queryDone))
        .subscribe();
    }
    return this.linkedUsers$;
  }

  getLinkedUsersDropDown(): Observable<LinkedUserTypes.LinkedUserDropdownEntry[]> {
    return this.linkedUsers$
      .pipe(map(users => {
        const result: LinkedUserTypes.LinkedUserDropdownEntry[] = [];
        Object.values(users || []).forEach(user => {
          const login = user.login || '';
          const accountName = user.accountName || '';
          const fullName = user.firstname + ' ' + user.lastname;
          result.push({
            text: login + ' (' + accountName + ', ' + fullName + ')',
            login,
            accountName,
            user,
          });
        });
        return result;
      }))
      .pipe(map(users => users.sort((a, b) => {
          const accountA = a.accountName.toLocaleLowerCase();
          const accountB = b.accountName.toLocaleLowerCase();
          let result = accountA.localeCompare(accountB);
          if ( result === 0 ) {
            const userA = a.login.toLocaleLowerCase();
            const userB = b.login.toLocaleLowerCase();
            result = userA.localeCompare(userB);
          }
          return result;
        })));
  }

  getOwnUUID(): Observable<any> {
    return this.http.get<any>(ApiUrls.getKey('GetOwnUUID'))
      .pipe(map(response => response.personUUID))
      .pipe(catchError(err => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_GET_OWN_UUID_FAIL, InfoType.Error);
        return err;
      }));
  }

  resetLinkedUsers = (): void => {
    this._linkedUsers.next(null);
  };

  showDialogForDeletionOfLinkedUser(user: LinkedUserTypes.LinkedUser) {
    this.infoService
      .showDialog<DeleteUserDialogComponent, LinkedUserTypes.LinkedUser, boolean>(DeleteUserDialogComponent, user)
      .pipe(takeWhile(confirmed => confirmed === true))
      .pipe(switchMap(() => this.deleteLinkToUser(user.userId)))
      .pipe(take(1))
      .subscribe();
  }

  showDialogForSwitchingAccount(user: LinkedUserTypes.LinkedUser) {
    this.infoService
      .showDialog<SwitchUserDialogComponent, LinkedUserTypes.LinkedUser, boolean>(SwitchUserDialogComponent, user)
      .pipe(takeWhile(confirmed => confirmed === true))
      .pipe(map(() => user.userId))
      .pipe(switchMap(this.getAuthTokenFromUserId))
      .pipe(switchMap(this.logout))
      .pipe(switchMap(token => LinkedUsersService.storeLoginRedirect(token, this.router.url)))
      // perform login after successful logout
      .pipe(switchMap(this.login))
      .pipe(take(1))
      .subscribe();
  }

  private deleteLinkToUser(userId: number): Observable<any> {
    return this.http.delete<any>(ApiUrls.getKey('DeleteLinkToUser')
      .replace('{userId}', String(userId)))
      .pipe(map(response => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_DELETE_LINK_TO_USER_SUCCESS, InfoType.Success);
        return response;
      }))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_DELETE_LINK_TO_USER_FAIL, InfoType.Error);
        return EMPTY;
      }))
      .pipe(switchMap(() =>
        // refresh linked users to update table
         this.getLinkedUsers(true)
      ));
  }

  private getAuthTokenFromUserId = (userId: number): Observable<string> => {
    const url = ApiUrls.getKey('GetAuthTokenFromUserId')
      .replace('{uid}', String(userId));
    return this.http.post<any>(url, {})
      .pipe(map(response => {
        if ( response?.token ) {
          return response.token;
        }
        throw Error('token-not-valid');
      }))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_GET_AUTH_TOKEN_FAIL, InfoType.Error);
        return EMPTY;
      }));
  };

  /**
   * API call to log user in with bearer token
   */
  private login = (token: string) => this.http.get<PrincipalData>(ApiUrls.getKey('LoginSwitchUser'), {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Forward-Flag': 'true',
      }),
    })
      .pipe(take(1))
      .pipe(map(() => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_LOGIN_WITH_TOKEN_SUCCESS, InfoType.Success);
        // reload and clear resources
        window.location.reload();
      }))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.LINKED_USERS_LOGIN_WITH_TOKEN_FAIL, InfoType.Error);
        return EMPTY;
      }));

  /**
   * logout without using principalService to prevent flash of empty permissions
   */
  private logout = <T>(value: T): Observable<T> => this.logoutService.logout()
      .pipe(mapTo(value));

}
