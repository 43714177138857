import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminNotificationsDetailsContentComponent } from './admin-notifications-details-content.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { FileMultiComponent } from 'src/app/component/file-multi/file-multi.component';
import { SelectUserGroupsModule } from 'src/app/component/select-user-groups/select-user-groups.module';
import { DateTimeComponent } from 'src/app/component/date-time/date-time.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AdminNotificationsDetailsContentComponent
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatStepperModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    DateTimeComponent,
    FileMultiComponent,
    SelectUserGroupsModule,
    MatButtonModule,
    CKEditorModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    PipesModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    AdminNotificationsDetailsContentComponent,
  ]
})
export class AdminNotificationsDetailsContentModule { }
