import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// '%'.charCodeAt(0).toString(16).toUpperCase()
const ENC_PERCENTAGE = '\\u0025';

@Injectable({
  providedIn: 'root',
})
export class JsonEncodingInterceptor
  implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const body = req.body;
    const headers = req.headers;
    if ( body
      && (req.responseType === 'json')
      && !(body instanceof FormData)
      && !(body instanceof HttpParams) ) {
      const bodyType = typeof (body);
      const modified: { body?: string; headers?: HttpHeaders } = {};
      if ( bodyType === 'string' ) {
        modified.body = body;
      } else if ( bodyType === 'object' ) {
        modified.body = JSON.stringify(body);
      }
      if ( modified.body ) {
        modified.body = modified.body.replace(/[%]/g, '\\u0025');
        modified.headers = headers.set('Content-Type', 'application/json');
        // TODO: make it configureable
        // if ( console && console.warn ) {
        //   console.warn('checked encoding for request', req.url);
        // }
        return next.handle(req.clone(modified));
      }
    }
    return next.handle(req);
  }

}
