import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurriculumLockedByComponent } from './curriculum-locked-by.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CurriculumLinkDialogModule } from '../curriculum-link-dialog/curriculum-link-dialog.module';


@NgModule({
  declarations: [ CurriculumLockedByComponent ],
  exports: [ CurriculumLockedByComponent ],
  imports: [
    CommonModule,
    CurriculumLinkDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class CurriculumLockedByModule {
}
