<rag-dialog-header>
  <span i18n="@@global_iteration">Iteration</span> {{data.iteration}}
</rag-dialog-header>

<mat-dialog-content>
  <div class="container">
    <div class="grouping-container">
      <div class="grouping-element">
        <div class="info-label"><span i18n="@@certificates_info_name">Name</span>:</div>
        <div class="info-label"><span i18n="@@certificates_info_curriculum">Curriculum</span>:</div>
        <div class="info-label"><span i18n="@@certificates_info_completed">Completed</span>:</div>
        <div class="info-label"><span i18n="@@certificates_info_valid_since">Valid since</span>:</div>
        <div class="info-label"><span i18n="@@certificates_info_valid_until">Valid until</span>:</div>
      </div>

      <div class="grouping-element">
        <div class="info-data">{{data.name}}</div>
        <div class="info-data">{{data.curstatus.curriculumTitle}}</div>
        <div class="info-data">{{data.curstatus.completed}}</div>
        <div class="info-data">{{data.curstatus.validSince}}</div>
        <div class="info-data">{{data.curstatus.validUntil}}</div>
      </div>
    </div>

    <div class="data">
      <table *ngIf="hasData" [dataSource]="datasource" mat-table>
        <!-- displayStatus Column -->
        <ng-container matColumnDef="displayStatus">
          <th *matHeaderCellDef i18n="@@certificates_info_table_status" mat-header-cell>Status</th>

          <td *matCellDef="let element" class="status-row" mat-cell>
            <!-- content type for learner account is always sent as DisplayStatus -->
            <rag-status-lights
              [displayStatus]="element.displayStatus"
              statusType="CourseStatus"
            ></rag-status-lights>
          </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef i18n="@@global_title" mat-header-cell>Title</th>
          <td *matCellDef="let element" mat-cell> {{element.title}} </td>
        </ng-container>

        <!-- finishDate Column -->
        <ng-container matColumnDef="finishDate">
          <th *matHeaderCellDef i18n="@@certificates_info_table_finish_date" mat-header-cell>Finish date</th>
          <td *matCellDef="let element"
              mat-cell> {{element.finishDate}} </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="result">
          <th *matHeaderCellDef i18n="@@certificates_info_table_result" mat-header-cell>Result</th>
          <td *matCellDef="let element" mat-cell> {{element.result}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="toggleDisplayAll($event)" *ngIf="hasMore" class="rag-stroked-button" mat-stroked-button>
    <span *ngIf="!displayAll" i18n="@@global_show_all">Display more</span>
    <span *ngIf="displayAll" i18n="@@global_show_less">Display less</span>
  </button>
  <button color="primary" mat-dialog-close mat-flat-button>OK</button>
</mat-dialog-actions>
