import { Component, Input, OnDestroy } from '@angular/core';
import { PermissionStates } from '../../../../../core/principal/permission.states';
import { PrincipalService } from '../../../../../core/principal/principal.service';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { Router } from '@angular/router';
import { TransactionsListRow } from '../../transactions.types';

@Component({
  selector: 'rag-transactions-list-actions',
  templateUrl: './transactions-list-actions.component.html',
  styleUrls: ['./transactions-list-actions.component.scss']
})
export class TransactionsListActionsComponent
  implements OnDestroy {

  @Input() transaction: TransactionsListRow;

  permissions: PermissionStates;

  constructor(
    private principalService: PrincipalService,
    private router: Router,
  ) {
    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onClickInformation(): void {
    if (this.transaction.extras.bookingUUID != null) {
      this.router.navigateByUrl('/bookings/' + this.transaction.extras.bookingUUID);
    }
  }

  informationButtonDisabled(): boolean {
    if ( this.transaction.extras?.bookingUUID != null ) {
      return false;
    } else {
      return true;
    }
  }
}
