<div class="site-wrapper" (click)="onNavigateToDetails()" tabindex="0" (keypress)="onNavigateToDetails()">
  <div class="card-wrapper">
    <div class="newstyle-card">
      <div
        class="image-wrapper"
        [ngStyle]="cardBackgroundStyles$ | async"
      >
        <div class="tag" *ngIf="offlineContentSubType as type">
          <mat-icon class="type icon" [svgIcon]="iconForType"></mat-icon>
          <span class="type font-medium">{{ type }}</span>
        </div>
        <div
          tabindex="0"
          #matTooltip="matTooltip"
          *ngIf="isBooked(content.catalogBooking)"
          class="card-icon-booked rounded-div"
          matTooltip="Booked"
          i18n-matTooltip="@@booked"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
          (keypress)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_favourite.svg" alt="booked">
        </div>
        <div
          tabindex="0"
          #matTooltip="matTooltip"
          *ngIf="isAssigned(content)"
          class="card-icon-assigned rounded-div"
          matTooltip="Assigned"
          i18n-matTooltip="@@catalog_assigned"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
          (keypress)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_assigned.svg" alt="favourite">
        </div>

      </div>
      <div class="mat-card-content">
        <div>
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <div>
            <span class="mat-card-subtitle font-light letterspacing-more">{{ objSubTypeAsText }}</span>
            <span
              *ngIf="eventScheduleCount > 1 && !content.blockEvent"
              class="mat-card-subtitle count"
              i18n="@@count_schedules"
            >
              ({{ eventScheduleCount }} schedules)
            </span>
          </div>
        </div>
        <div class="divider-line"></div>
        <div class="mat-card-bottom-wrapper">
          <div *ngIf="eventScheduleCount > 0; else noData" class="schedules">
            <div class="schedule-picker" *ngIf="showSchedulePicker; else blockEventWithModules">
              <button
                (click)="selectedScheduleChanged('previous', $event)"
                mat-icon-button
                type="button"
                [disabled]="eventScheduleCount === 1"
              >
                <mat-icon svgIcon="chevron-left" class="chevron-icon"></mat-icon>
              </button>
              <div class="schedule-info">
                <span class="semi-bold schedules-title">{{ selectedSchedule.title | localeText }}</span>
                <div class="schedule-info-content">
                  <span class="event-date">{{ selectedSchedule.eventDate | formatDate:'date-time' }}</span>
                  <span class="point-centered">🞄</span>
                  <span class="price-value">({{ price }})</span>
                </div>
              </div>
              <button
                (click)="selectedScheduleChanged('next', $event)"
                mat-icon-button
                type="button"
                [disabled]="eventScheduleCount === 1"
              >
                <mat-icon svgIcon="chevron-right" class="chevron-icon"></mat-icon>
              </button>
            </div>
            <span
              *ngIf="eventScheduleCount > 1"
              class="mat-card-subtitle page-index"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="schedules">
    <span i18n="@@nothing-to-select-from" class="no-schedules">Currently no schedules are available.</span>
  </div>
</ng-template>

<ng-template #blockEventWithModules>
  <div class="incl-module">
    <span i18n="@@card_content_contains_modules">Multiple schedules are available</span>
  </div>
</ng-template>
