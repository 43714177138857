<ng-template #tplLoading>
  <rag-loading-indicator></rag-loading-indicator>
</ng-template>
<ng-template #tplSelfRegisterV1>
  <rag-self-register></rag-self-register>
</ng-template>
<ng-template #tplSelfRegisterV2>
  <rag-self-register-form></rag-self-register-form>
</ng-template>
<ng-container
  [ngTemplateOutlet]="getTemplate(tplLoading, tplSelfRegisterV1, tplSelfRegisterV2)"
></ng-container>
