import { inject } from '@angular/core';
import { UserProfileResponse } from './user-profile.types';
import { Observable } from 'rxjs';
import { UserProfileService } from './user-profile.service';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

export const UserProfileResolver: ResolveFn<UserProfileResponse> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    userProfileService: UserProfileService = inject(UserProfileService)
  ): Observable<UserProfileResponse> => userProfileService.getProfile();
