import { ColumnFilterMethod, filterTextV2 } from '../../../../core/column-settings/column-filter.types';

export const CTRL_SINGLE_USER_MSG = {

  OWN_LEARNING_STATUS_NOT_EDITABLE: $localize`:@@ctrl_single_user_own_learning_status_not_editable:You are not allowed to edit your own learning status.`,
  NOT_AUTHORIZED: $localize`:The user is not authorized to perform the last operation@@global_error_not_authorized:You are not authorized to perform this operation. Please contact your system administrator for more information.`,

};

export const filterTitleWithParent = (): ColumnFilterMethod => (data, filter) => {

  if ( !filter?.value ) {
    return true;
  }

  const title = data?.title;
  if ( filterTextV2(title, filter) ) {
    return true;
  }

  if ( data.$rowType === 'child' ) {
    const parentTitle = data.$parent?.title;
    return filterTextV2(parentTitle, filter);
  }

  return false;
};
