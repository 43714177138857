import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { LearnerAccountService } from 'src/app/core/learner-account/learner-account.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { LearnerAccountOfflineContentViewV2 } from '../../../../core/learner-account/learner-account.types';
import { ContentOfflineService } from '../../content/content-offline/content-offline.service';


@Component({
  selector: 'rag-content-offline-dialog',
  templateUrl: './content-offline-dialog.component.html',
  styleUrls: [ './content-offline-dialog.component.scss' ],
})
export class ContentOfflineDialogComponent
  implements OnDestroy {

  offlineEventId: number;

  constructor(
    private contentOfflineService: ContentOfflineService,
    private learnerAccountService: LearnerAccountService,
    @Inject(MAT_DIALOG_DATA) public data: LearnerAccountOfflineContentViewV2) {

    // try to guess the event id
    if ( this.data.offlineEvents?.length === 1 ) {
      this.offlineEventId = this.data.offlineEvents
        ?.find(() => true)?.id;
    } else {
      this.offlineEventId = null;
    }

    this.contentOfflineService.needsReload$
      .pipe(debounceTime(50))

      .pipe(switchMap(() => this.learnerAccountService
        .fetchOfflineContentItem<LearnerAccountOfflineContentViewV2>(
          this.data.curriculum.curriculumId, this.data.curriculumItem.curriculumItemId)))
      .pipe(map(contentView => {
        if ( contentView == null ) {
          return;
        }

        const offlineEventId = this.offlineEventId;
        if ( offlineEventId > 0 ) {
          const event = contentView.offlineEvents
            ?.find(entry => entry.id === offlineEventId);
          const eventAccount = contentView.offlineEventAccountViews
            ?.find(entry => entry.offlineEventId === offlineEventId);
          if ( (event != null) && (eventAccount != null) ) {
            // we have a valid event account -> hide other entries
            contentView.offlineEvents = [ event ];
            contentView.offlineEventAccountViews = [ eventAccount ];
          }
        }

        this.data = contentView;
      }))

      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

}
