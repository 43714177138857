<mat-form-field
  [formGroup]="form"
  appearance="outline"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <mat-select
    [formControlName]="field.fieldId"
    multiple
    [disabled]="isDisabled()"
  >

    <mat-option
      *ngFor="let option of options"
      [value]="option.key"
    >{{option.value}}</mat-option>

  </mat-select>

  <mat-error
    *ngIf="getError() as error"
  >{{error}}</mat-error>

</mat-form-field>
