import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import Chart from 'chart.js/auto';
import ColorHash from 'color-hash';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { LanguageHelper } from '../../../core/language.helper';
import { Translation } from '../../../core/translation/translation.types';
import { ChartService } from '../chart.service';
import { DEFAULT_OPTIONS, LearningTimeChartDataSet, LearningTimeChartOptions } from './learning-time-chart.types';
import {
  ReportLearningTime,
  ReportLearningTimeCurriculum,
  ReportLearningTimeCurriculumPath,
  ReportLearningTimeEntry,
} from '../../../core/report/report.types';
import { CartesianScaleOptions, ChartConfiguration, ChartDataset, ScatterDataPoint, TimeUnit } from 'chart.js';
import { MergeHelper } from '../../../core/primitives/merge.helper';


/**
 * @see https://github.com/zenozeng/color-hash
 * @see https://en.wikipedia.org/wiki/HSL_and_HSV
 */
const colorHash: ColorHash = new ColorHash({ lightness: 0.5 });
const toRgb = (value: string): string => {
  // "randomize" the value in a reproducible way
  // probably only works for the used test cases ;)
  const hashValue = Object.values(Md5.hashStr(value))
    .sort((a) => -1 * (a.charCodeAt(0) % 2))
    .join('X');
  return 'rgb(' + colorHash.rgb(hashValue).join(', ') + ')';
};

interface LocalTimeScaleOptions {
  max: string | number;
  min: string | number;
  unit: false | TimeUnit;
}

const thousandsSeparator = (() => {
  // @see https://stackoverflow.com/a/45309230
  let example: string;
  try {
    const languages = (typeof (window.navigator.languages) === 'string') ?
      [ window.navigator.languages ] : [ ...window.navigator.languages ?? [] ];
    example = Intl.NumberFormat(languages).format(1000);
  } catch ( e ) {
    example = '1.000';
  }
  return (example.indexOf('.') === -1) ? ',' : '.';
})();


/**
 * @see https://www.chartjs.org/docs/latest/charts/bar.html
 * @see https://www.chartjs.org/docs/latest/axes/cartesian/time.html#time-cartesian-axis
 */
@Directive({
  selector: 'canvas[ragLearningTimeChart]',
})
export class LearningTimeChartDirective
  implements AfterViewInit, OnDestroy {

  WIDGET_TITLE: Translation = {
    de: 'Lernzeit',
    en: 'Learning time',
  };
  @Input() backgroundColor = 'white';
  @Input() reportTitle: string;
  private _chartDatasets: ChartDataset[] = [];
  private _chartJs: Chart;
  private _chartLabels: string[] = [];
  private _data: LearningTimeChartDataSet[];
  private _downloadChart$: Subscription;
  private _options: LearningTimeChartOptions = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS);
  private _chartJsConfig: ChartConfiguration = {
    type: 'bar',
    data: {
      labels: this._chartLabels,
      datasets: this._chartDatasets,
    },
    options: {
      backgroundColor: 'transparent',
      plugins: {
        legend: {
          display: false,
          position: 'right',
          align: 'center',
        },
        tooltip: {
          callbacks: {
            label: (context): string | string[] => {
              // tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData
              let label = context.chart.data.datasets[context.datasetIndex].label || '';
              if ( label ) {
                label += ': ';
              }
              return label + LearningTimeChartDirective.formatDuration(context.formattedValue);
            },
          },
        },
      },
      scales: {
        x: {
          display: true,
          type: 'category',
          grid: {
            lineWidth: 1,
          },
          stacked: true,
        },
        y: {
          display: true,
          type: 'linear',
          ticks: {
            callback: (value: any, index: number, arr): string => {
              if ( this._options.axisType === 'linear' ) {
                return LearningTimeChartDirective.formatDuration(value);
              }

              // for logarithmic scale, autoSkip must be implemented inside the callback
              const remain = value / (Math.pow(10, Math.floor(Math.log10(value))));

              if ( remain === 1 || remain === 2 || remain === 5 || index === 0 || index === arr.length - 1 ) {
                return LearningTimeChartDirective.formatDuration(value);
              } else {
                return '';
              }
            },
          },
        },
      }
    },
  };
  private _reportLearningTime: ReportLearningTime;
  private _reportLearningTime$: Observable<ReportLearningTime>;
  private _reportLearningTimeSubscription: Subscription;
  private _startResize: Subscription;
  private _timeOptions: LocalTimeScaleOptions = {
    min: 0,
    max: 0,
    unit: 'month',
  };

  constructor(
    private chartService: ChartService,
    private elementRef: ElementRef,
  ) {
  }

  @Input() set downloadChart$(value: Observable<void>) {
    this.cleanDownloadChart$();

    if ( value ) {
      this._downloadChart$ = value
        .pipe(tap(this.downloadChart))
        .subscribe();
    }
  }

  get options(): LearningTimeChartOptions {
    return this._options;
  }

  @Input() set options(value: LearningTimeChartOptions) {
    value = value || {};
    this._options.axisType = value.axisType || 'linear';
    const showLastMonths = this._options.showLastMonths = value.showLastMonths || 12;
    const unit = this._options.unit = value.unit || 'quarter';
    this._options.stacked = value.stacked == null ? true : value.stacked;
    this._options.showLegend = value.showLegend;


    const timeOptions: LocalTimeScaleOptions = this._timeOptions;
    if ( unit === 'month' ) {
      timeOptions.unit = 'month';
    } else {
      timeOptions.unit = 'quarter';
    }

    if ( showLastMonths > 0 ) {
      const now = moment().endOf('month');
      timeOptions.max = now
        .format();
      timeOptions.min = now
        .clone()
        .startOf('month')
        .subtract(showLastMonths, 'month')
        .format();
    } else {
      delete timeOptions.max;
      delete timeOptions.min;
    }

    this.updateData();
  }

  get reportLearningTime(): ReportLearningTime {
    return this._reportLearningTime;
  }

  @Input() set reportLearningTime(value: ReportLearningTime) {
    this._reportLearningTime = value;
    this.updateData();
  }

  get reportLearningTime$(): Observable<ReportLearningTime> {
    return this._reportLearningTime$;
  }

  @Input() set reportLearningTime$(value: Observable<ReportLearningTime>) {
    this._reportLearningTime$ = value;
    this.observeLearningTime();
  }

  @Input() set startResize(value: Observable<void>) {
    this.cleanStartResize();

    this._startResize = value
      .pipe(tap(() => {
        this._chartJs.resize();
      }))
      .subscribe();
  }

  static formatDuration(value: string, separator = thousandsSeparator) {
    if ( (typeof (value) === 'string') && new RegExp(`^\\d+([${separator}]\\d{3})+`).test(value) ) {
      value = value.replace(new RegExp(`[${separator}]`, 'g'), '');
    }
    const duration = moment.duration(Number(value), 'minutes');
    if ( !duration.isValid() ) {
      return value;
    }

    let result = '';
    const days = Math.floor(duration.as('days'));
    if ( days ) {
      result += days + 'd ';
    }
    const hours = duration.get('hours');
    if ( result || hours ) {
      result += hours + 'h ';
    }
    result += duration.get('minutes') + 'm';
    return result;
  }

  downloadChart = (): void => {
    this.chartService.downloadChart(this._chartJsConfig, this.reportTitle, LanguageHelper.translate(this.WIDGET_TITLE));
  };

  ngAfterViewInit(): void {
    this._chartJs = new Chart(this.elementRef.nativeElement, this._chartJsConfig);
    this.updateData();
    this.observeLearningTime();
  }

  ngOnDestroy(): void {
    this.cleanDownloadChart$();
    this.cleanReportLearningTime();
    this.cleanStartResize();
  }

  private calculateTimelineMinMax(projection): { min: moment.Moment; max: moment.Moment; format: string } {
    if ( projection !== 'month' ) {
      projection = 'quarter';
    }

    const timeOptions = this._timeOptions || {
      min: 0,
      max: 0,
      unit: 'month',
    };

    let min: moment.Moment = null;
    if ( timeOptions.min ) {
      min = moment(timeOptions.min).startOf(projection);
    }
    let max: moment.Moment = null;
    if ( timeOptions.max ) {
      max = moment(timeOptions.max).endOf(projection);
    }

    let format;
    if ( projection === 'month' ) {
      format = 'MMM YYYY';
    } else {
      format = '[Q]Q - YYYY';
    }

    if ( min && max ) {
      let label = min.clone().endOf(projection).add(1, projection);
      const end = max.clone().startOf(projection).add(1, projection);
      this._chartLabels.splice(0);
      const lineWidths: number[] = [];
      let run = true;
      while ( run ) {
        this._chartLabels.push(label.format(format));
        label = label.add(1, projection);
        run = label.isBefore(end);
        if ( run ) {
          lineWidths.push(1);
        } else {
          lineWidths.push(5);
        }
      }
      this._chartJs.options.scales.x.grid.lineWidth = lineWidths;
    }
    return { min, max, format };
  }

  private cleanDownloadChart$() {
    if ( this._downloadChart$ ) {
      this._downloadChart$.unsubscribe();
      this._downloadChart$ = null;
    }
  }

  private cleanReportLearningTime() {
    if ( this._reportLearningTimeSubscription ) {
      this._reportLearningTimeSubscription.unsubscribe();
      this._reportLearningTimeSubscription = null;
    }
  }

  private cleanStartResize(): void {
    if ( this._startResize ) {
      this._startResize.unsubscribe();
      this._startResize = null;
    }
  }

  private learningTimeDataToDataSets(learningTime): LearningTimeChartDataSet[] {
    const groupBy = (this._timeOptions || {}).unit;
    const projection = groupBy === 'month' ? groupBy : 'quarter';
    const { min, max, format } = this.calculateTimelineMinMax(projection);

    const result: LearningTimeChartDataSet[] = [];
    Object.values(learningTime?.entries ?? {}).forEach((curriculum: ReportLearningTimeCurriculum) => {
      const curriculumId = '' + curriculum.curriculumId;
      Object.values(curriculum?.paths ?? {}).forEach((path: ReportLearningTimeCurriculumPath) => {
        const valuesByUnit: { [key: string]: number } = {};
        (path?.entries ?? []).forEach((entry: ReportLearningTimeEntry) => {
          const validSince = moment(entry.accountValidSince);
          if ( !validSince.isValid() || (min && validSince.isBefore(min)) || (max && validSince.isAfter(max)) ) {
            return;
          }
          const quarterStr = validSince.format(format);
          valuesByUnit[quarterStr] = (valuesByUnit[quarterStr] || 0) + entry.timeInMinutes;
        });

        let valueSum = 0;
        const data: ScatterDataPoint[] = (this._chartLabels ?? []).map((label, x) => {
          const value = valuesByUnit[label];
          const y = (value > 0) ? value : 0;
          valueSum += y;
          return { x, y };
        });

        if ( valueSum ) {
          const label = curriculum.title + ' (' + path.variationCounter + (path.title ? ': ' + path.title : '') + ')';
          result.push({
            backgroundColor: toRgb(curriculum.curriculumId + ' ' + path.variationCounter + ' ' + valueSum),
            data,
            label,
            stack: curriculumId,
          });
        }
      });
    });
    return result;
  }

  private observeLearningTime() {
    this.cleanReportLearningTime();
    if ( this._reportLearningTime$ ) {
      this._reportLearningTimeSubscription = this._reportLearningTime$
        .pipe(map(learningTime => {
          this.reportLearningTime = learningTime;
        }))
        .subscribe();
    }
  }

  private setMinMaxTimeFromData(learningTime): void {
    const timeOptions = this._timeOptions;
    if ( timeOptions && !(timeOptions.min || timeOptions.max) ) {
      if ( learningTime.firstEntryDate ) {
        timeOptions.min = moment(learningTime.firstEntryDate)
          .startOf('month')
          .format();
      } else {
        delete timeOptions.min;
      }
      if ( learningTime.lastEntryDate ) {
        timeOptions.max = moment(learningTime.lastEntryDate)
          .startOf('month')
          .add(1, 'month')
          .format();
      } else {
        delete timeOptions.max;
      }
    }
  }

  private updateData() {
    if ( !this._chartJs ) {
      return;
    }

    if ( !this._options.showLegend ) {
      this._chartJs.options.plugins.legend.display = false;
    } else {
      this._chartJs.options.plugins.legend.display = true;
      if ( this._options.showLegend === 'right' ) {
        this._chartJs.options.plugins.legend.position = 'right';
      } else if ( this._options.showLegend === 'bottom' ) {
        this._chartJs.options.plugins.legend.position = 'bottom';
      }
    }

    (this._chartJs.options.scales.x as CartesianScaleOptions).stacked = this._options.stacked;
    this._chartJs.options.scales.y.type = this._options.axisType;

    this._chartDatasets.splice(0);
    if ( this._reportLearningTime ) {
      this.setMinMaxTimeFromData(this._reportLearningTime);
      this._data = this.learningTimeDataToDataSets(this._reportLearningTime);
    }
    if ( this._data ) {
      this._data?.forEach((entry: LearningTimeChartDataSet) => {
        const dataset: ChartDataset = {
          yAxisID: 'y',
          ...entry,
        };
        this._chartDatasets.push(dataset);
      });
    }
    this._chartJs.update();
  }

}
