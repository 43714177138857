import { isFunction, isString } from 'lodash';
import { Observable, throwError } from 'rxjs';


export interface ScormApiEvent {
  type: ScormApiEventType;
}

export enum ScormApiEventType {
  Initialized,
  Finished,
}

export const ScormCmiTree = {
  cmi: {
    core: {
      credit: '',
      entry: '',
      exit: '',
      lesson_location: '',
      lesson_mode: '',
      lesson_status: '',
      session_time: '',
      student_id: '',
      student_name: '',
      total_time: '',
      score: {
        raw: '',
        min: '',
        max: '',
      }
    },
    objectives: {
      id: '',
      score: '',
      status: '',
    },
    interactions: {
      id: '',
      time: '',
      type: '',
      correct_responses: '',
      weighting: '',
      student_responses: '',
      result: '',
      latency: '',
    },
    suspend_data: '',
    student_data: {
      mastery_score: '',
      max_time_allowed: '',
      time_limit_action: '',
    },
  },
};

export enum ScormError {
  NOERROR = '0',
  GENERALEXCEPTION = '101',
  INVALID_ARGUMENT = '201',
  ELEMENT_CAN_NOT_HAVE_Children = '202',
  ELEMENT_IS_NOT_AN_ARRAY = '203',
  NOT_INITIALIZED = '301',
  NOT_IMPLEMENTED = '401',
  INVALID_SETVALUE = '402',
  ELEMENT_IS_READ_ONLY = '403',
  ELEMENT_IS_WRITE_ONLY = '404',
  INCORRECT_DATATYPE = '405',
  MAX_TIME_ALLOWED_EXPIRED = '501',
}

export enum ScormCodePointer {
  LMSCommitImpl1 = 'LMSCommitImpl1',
  LMSCommitImpl2 = 'LMSCommitImpl2',
  LMSFinishImpl1 = 'LMSFinishImpl1',
  LMSFinishImpl2 = 'LMSFinishImpl2',
  LMSGetValueChildren1 = 'LMSGetValueChildren1',
  LMSGetValueChildren2 = 'LMSGetValueChildren2',
  LMSGetValueChildren3 = 'LMSGetValueChildren3',
  LMSGetValueChildren4 = 'LMSGetValueChildren4',
  LMSGetValueCount1 = 'LMSGetValueCount1',
  LMSGetValueCount2 = 'LMSGetValueCount2',
  LMSGetValueCount3 = 'LMSGetValueCount3',
  LMSGetValueCount4 = 'LMSGetValueCount4',
  LMSGetValueCount5 = 'LMSGetValueCount5',
  LMSGetValueCount6 = 'LMSGetValueCount6',
  LMSGetValueDoGetValue1 = 'LMSGetValueDoGetValue1',
  LMSGetValueDoGetValue2 = 'LMSGetValueDoGetValue2',
  LMSGetValueImpl1 = 'LMSGetValueImpl1',
  LMSGetValueImpl2 = 'LMSGetValueImpl2',
  LMSGetValueImpl3 = 'LMSGetValueImpl3',
  LMSGetValueImpl4 = 'LMSGetValueImpl4',
  LMSInitializeImpl1 = 'LMSInitializeImpl1',
  LMSInitializeImpl2 = 'LMSInitializeImpl2',
  LMSSetValueImpl1 = 'LMSSetValueImpl1',
  LMSSetValueImpl2 = 'LMSSetValueImpl2',
  LMSSetValueImpl3 = 'LMSSetValueImpl3',
  LMSSetValueImpl4 = 'LMSSetValueImpl4',
  LMSSetValueImpl5 = 'LMSSetValueImpl5',
  LMSSetValueImpl6 = 'LMSSetValueImpl6',
  LMSSetValueImpl7 = 'LMSSetValueImpl7',
  LMSSetValueImpl8 = 'LMSSetValueImpl8',
  LMSSetValueImpl9 = 'LMSSetValueImpl9',
  LMSSetValueImpl10 = 'LMSSetValueImpl10',
  LMSSetValueImpl11 = 'LMSSetValueImpl11',
  LMSSetValueImpl12 = 'LMSSetValueImpl12',
  LMSSetValueImpl13 = 'LMSSetValueImpl13',
  LMSSetValueImpl14 = 'LMSSetValueImpl14',
  LMSSetValueImpl15 = 'LMSSetValueImpl15',
  LMSSetValueImpl16 = 'LMSSetValueImpl16',
  LMSSetValueImpl17 = 'LMSSetValueImpl17',
  LMSSetValueImpl18 = 'LMSSetValueImpl18',
  LMSSetValueImpl19 = 'LMSSetValueImpl19',
  LMSSetValueImpl20 = 'LMSSetValueImpl20',
  LMSSetValueImpl21 = 'LMSSetValueImpl21',
  LMSSetValueImpl22 = 'LMSSetValueImpl22',
  LMSSetValueImpl23 = 'LMSSetValueImpl23',
  StartSco1 = 'StartSco1',
}

export const ScormErrorStrings = {};
ScormErrorStrings[ScormError.NOERROR] = 'No error';
ScormErrorStrings[ScormError.GENERALEXCEPTION] = 'General exception';
ScormErrorStrings[ScormError.INVALID_ARGUMENT] = 'Invalid argument error';
ScormErrorStrings[ScormError.ELEMENT_CAN_NOT_HAVE_Children] = 'Element cannot have children';
ScormErrorStrings[ScormError.ELEMENT_IS_NOT_AN_ARRAY] = 'Element not an array - cannot have count';
ScormErrorStrings[ScormError.NOT_INITIALIZED] = 'Not initialized';
ScormErrorStrings[ScormError.NOT_IMPLEMENTED] = 'Not implemented error';
ScormErrorStrings[ScormError.INVALID_SETVALUE] = 'Invalid set value, element is a keyword';
ScormErrorStrings[ScormError.ELEMENT_IS_READ_ONLY] = 'Element is read only';
ScormErrorStrings[ScormError.ELEMENT_IS_WRITE_ONLY] = 'Element is write only';
ScormErrorStrings[ScormError.INCORRECT_DATATYPE] = 'Incorrect Data type';
ScormErrorStrings[ScormError.MAX_TIME_ALLOWED_EXPIRED] = 'Max time allowed expired';

export enum ScormResult {
  SUCCESS = 'true',
  FAILURE = 'false'
}

export interface ScormQueryResponse {
  lastError?: ScormError;
  result?: string;
}

export class ScormApiState {
  cmiCoreElements: {
    'cmi.core.credit'?: string;
    'cmi.core.entry'?: string;
    'cmi.core.exit'?: string;
    'cmi.core.lesson_location'?: string;
    'cmi.core.lesson_mode'?: string;
    'cmi.core.lesson_status'?: string;
    'cmi.core.score.raw'?: string;
    'cmi.core.score.max'?: string;
    'cmi.core.score.min'?: string;
    'cmi.core.student_id'?: string;
    'cmi.core.student_name'?: string;
    'cmi.core.total_time'?: string;
    'cmi.interactions._count'?: string;
    'cmi.launch_data'?: string;
    'cmi.objectives._count'?: string;
    'cmi.student_data.max_time_allowed'?: string;
    'cmi.student_data.time_limit_action'?: string;
    'cmi.student_data.mastery_score'?: string;
    'cmi.student_data._count'?: string;
    'cmi.core._count'?: string;
    'cmi._count'?: string;
  } = {};
  initialized = false;

  private _diagnostic = '';
  private _lastError = ScormError.NOERROR;

  get diagnostic() {
    return this._diagnostic || '';
  }

  get errorString() {
    return ScormErrorStrings[this.lastError] || '';
  }

  get lastError() {
    return this._lastError || ScormError.NOERROR;
  }

  resetErrors(): void {
    this.setErrors(ScormError.NOERROR);
  }

  setErrors(lastError: ScormError, diagnostic?: string): void {
    this._lastError = lastError;
    this._diagnostic = diagnostic || '';
  }
}

export class ScormResultValue {
  result: string;
  errorCode: ScormError;

  constructor(result: string, errorCode?: ScormError) {
    this.result = result;
    this.errorCode = errorCode ?? ScormError.NOERROR;
  }
}

export class ScormQueryResult
  implements ScormQueryResponse {

    codePointer: string;
  private _diagnostic: string;
  private _lastError: ScormError;
  private _query: Observable<ScormResultValue>;
  private _result: string;

  protected constructor(codePointer: string, diagnostic: string, lastError: ScormError, result: string) {
    this.codePointer = codePointer;
    this._diagnostic = diagnostic;
    this._lastError = lastError;
    this._result = result;
  }

  get success(): boolean {
    return this.lastError === ScormError.NOERROR;
  }

  get diagnostic(): string {
    return this._diagnostic || '';
  }

  set diagnostic(diagnostic: string) {
    this._diagnostic = diagnostic;
  }

  get lastError(): ScormError {
    return this._lastError || ScormError.NOERROR;
  }

  set lastError(lastError: ScormError) {
    this._lastError = lastError;
  }

  get query(): Observable<ScormResultValue> {
    if ( this._query ) {
      return this._query;
    } else if ( !this.success ) {
      return throwError(this.lastError);
    }
    return null;
  }

  set query(query: Observable<ScormResultValue>) {
    this._query = query;
  }

  get result() {
    if ( isString(this._result) ) {
      return this._result;
    } else if ( this.success ) {
      return ScormResult.SUCCESS;
    } else {
      return ScormResult.FAILURE;
    }
  }

  set result(result: string) {
    this._result = result;
  }

  static createFailure(codePointer: string, lastError: ScormError, diagnostic?: string,
                       result?: string): ScormQueryResult {
    return new ScormQueryResult(codePointer, diagnostic, lastError, result);
  }

  static createSuccess(codePointer: string, result?: string): ScormQueryResult {
    return new ScormQueryResult(codePointer, null, null, result);
  }

  static createSuccessWithQuery(codePointer: string, callback?: (...args: any[]) => any,
                                result?: string): ScormQueryResult {
    const object = new ScormQueryResult(codePointer, null, null, result);
    if ( isFunction(callback) ) {
      const query = callback(object);
      if ( query && !object.query ) {
        object.query = query;
      }
    }
    return object;
  }
}
