import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserCourseService } from './user-course.service';
import { UserCourseData } from './user-course.types';

@Injectable()
export class UserCourseResolver
  implements Resolve<UserCourseData> {

  constructor(
    private userCourseService: UserCourseService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserCourseData> {
    const userId = Number(route.paramMap.get('userId'));
    if ( !(userId > 0) ) {
      return throwError('Missing required userId parameter');
    }
    const courseId = Number(route.paramMap.get('courseId'));
    if ( !(courseId > 0) ) {
      return throwError('Missing required courseId parameter');
    }

    return this.userCourseService.getCourseForUser(userId, courseId)
      .pipe(take(1));
  }

}
