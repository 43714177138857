<ng-container *ngIf="!isNothing(content)">
  <rag-breadcrumbs *ngIf="!isPreview">
    <a *ngIf="(styleSettings$ | async) as styleSettings">{{styleSettings.acc.myContentsName | localeText}}</a>

    <rag-breadcrumbs-assignment-type
      [assignmentType]="content.assignmentType"
    ></rag-breadcrumbs-assignment-type>

    <a>{{content.title}}</a>
  </rag-breadcrumbs>

  <div>
    <div *ngIf="!isPreview" class="cockpit-actions">
      <rag-curriculum-title [curriculum]="content">
        <rag-important-content-info
          [content]="content"
          [displayMore]="true"
        ></rag-important-content-info>
      </rag-curriculum-title>
    </div>

    <div [ngClass]="{'is-fullscreen': fullscreen | async}" class="cockpit-wrapper">
      <div class="cockpit-content">
        <ng-template [ngIfElse]="showContent" [ngIf]="loading" class="loading-page">
          <mat-spinner class="spinner spinner-alignment"></mat-spinner>
        </ng-template>

        <ng-template #showContent>
          <div #headerBar class="header-bar">
            <div class="logo-wrapper">
              <div *ngIf="pictureId > 0; else logo">
                <a routerLink="/">
                  <img [src]="getImageUrl(pictureId)" alt="Company Logo">
                </a>
              </div>
              <ng-template #logo>
                <rag-header-logo></rag-header-logo>
              </ng-template>
            </div>
            <p class="content-title">{{content.title}}</p>
            <rag-toggle-fullscreen
              (toggle)="toggleFullscreen($event)"
              [fullscreen]="fullscreen | async">
            </rag-toggle-fullscreen>
          </div>
          <mat-grid-list [cols]="lengthOfColumns" rowHeight="fit" gutterSize="1em" class="tile-grid" [attr.data-rows]="tiles.length">
            <mat-grid-tile *ngFor="let tile of tiles.flat()"
                           [ngClass]="{'placeholder': tile.placeholder, 'live': tile.isLive, 'past': tile.isInPast}">
              <ng-template [ngIf]="tile.placeholder" [ngIfElse]="showTile">
              </ng-template>
              <ng-template #showTile>
                <div class="event-wrapper">
                  <div class="is-live-wrapper">
                    <span class="dot"></span>
                    <span class="is-live">NOW LIVE</span>
                  </div>
                  <div class="title-spacer">
                    <div class="info-wrapper">
                      <span (click)="openEvent(tile)" class="title">{{tile.title | localeText}}</span>
                      <button
                        *ngIf="tile.description"
                        type="button" mat-icon-button
                        (click)="openEventInfo(tile)"
                      >
                        <mat-icon svgIcon="information" color="primary"></mat-icon>
                      </button>
                    </div>
                    <div class="flag-wrapper" *ngIf="!isNothing(tile.pictureUUID)">
                      <div class="flag-part-two"></div>
                      <div class="flag-part-one">{{tile.title | localeText}}</div>
                    </div>
                  </div>
                  <div class="image-wrapper">
                    <img *ngIf="!isNothing(tile.pictureUUID)" (click)="openEvent(tile)" [src]="showPicture(tile)" alt="picture">
                    <mat-icon *ngIf="isPreview && isNothing(tile.pictureUUID)" svgIcon="image-off-outline"></mat-icon>
                  </div>
                </div>
              </ng-template>
            </mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list class="timeframe-grid" [cols]="lengthOfColumns" rowHeight="40px" gutterSize="1em">
            <mat-grid-tile *ngFor="let timeframe of timeframes">
              <div class="timeframe">
                <span>{{timeframe}}</span>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
