import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';
import { RagLayoutModule } from '../../../rag-layout/rag-layout.module';
import { ProfileLanguageComponent } from './profile-language.component';
import { ProfileComponent } from './profile.component';
import { ComponentsModule } from '../../admin/externals/ext-logins/components/components.module';
import { MatDividerModule } from '@angular/material/divider';
import { ProfileFieldModule } from '../../../component/input/profile-field/profile-field.module';
import { InfoTooltipButtonComponent } from 'src/app/component/info-tooltip-button/info-tooltip-button.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileLanguageComponent,
  ],
  exports: [
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDividerModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    PageHeaderComponent,
    RagLayoutModule,
    RouterModule,
    InfoTooltipButtonComponent,
    ProfileFieldModule,
  ],
})
export class ProfileModule {
}
