<div class="content-wrapper">
  <ng-content></ng-content>

  <div (click)="$event.stopPropagation()" class="actions">
    <button [matMenuTriggerFor]="actionMenu" mat-icon-button type="button">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <mat-menu #actionMenu xPosition="before">

      <div (click)="$event.stopPropagation()" class="chart-options">

        <mat-radio-group [(ngModel)]="modelOptions.showLastMonths" (ngModelChange)="emitOptions()">
          <div mat-menu-item>
            <mat-radio-button [value]="6" i18n="@@learning_time_months_6">6 Months</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button [value]="12" i18n="@@learning_time_months_12">12 Months</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button [value]="24" i18n="@@learning_time_months_24">24 Months</mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-divider></mat-divider>

        <mat-radio-group [(ngModel)]="modelOptions.unit" (ngModelChange)="emitOptions()">
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_unit_months" value="month">Months</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_unit_quarters" value="quarter">Quarters</mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-divider></mat-divider>

        <mat-radio-group [(ngModel)]="modelOptions.axisType" (ngModelChange)="emitOptions()">
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_axis_linear" value="linear">Linear</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_axis_logarithmic" value="logarithmic">Logarithmic</mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-divider></mat-divider>

        <mat-radio-group [(ngModel)]="modelOptions.showLegend" (ngModelChange)="emitOptions()">
          <div mat-menu-item>
            <mat-radio-button [value]="false" i18n="@@learning_time_legend_hidden">Legend hidden</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_legend_bottom" value="bottom">Legend at bottom</mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button i18n="@@learning_time_legend_right" value="right">Legend to the right</mat-radio-button>
          </div>
        </mat-radio-group>

      </div>

      <mat-divider></mat-divider>

      <button (click)="downloadChart.emit()" mat-menu-item type="button">
        <mat-icon svgIcon="file-image"></mat-icon>
        <span i18n="@@chart_export_as_png">Export to PNG</span>
      </button>

      <button (click)="downloadCsv.emit()" mat-menu-item type="button">
        <mat-icon svgIcon="file-delimited"></mat-icon>
        <span i18n="@@pivot_table_export_xlsx">Export to XLSX</span>
      </button>

    </mat-menu>
  </div>
</div>
