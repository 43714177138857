import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  GridType
} from 'angular-gridster2';
import { forIn } from 'lodash';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ComponentCommunicationService } from 'src/app/core/component-communication.service';
import {
  destroySubscriptions, takeUntilDestroyed
} from 'src/app/core/reactive/until-destroyed';
import { GenericMessageDialogComponent } from '../../component/generic-message-dialog/generic-message-dialog.component';
import { AnyObject } from '../../core/core.types';
import { InfoService } from '../../core/info/info.service';
import { DeleteButton, DeleteCancelButtons, InfoType, MessageKey } from '../../core/info/info.types';
import { AccountDesignService } from '../../route/admin/account-design/account-design.service';
import { StyleSettings } from '../../route/admin/account-design/account-design.types';
import { Layout, Widget, WidgetLayout } from '../rag-layout.types';
import { WidgetConf } from '../widgets/widgets.types';
import { RagPageService } from './rag-page.service';


class WidgetLayoutImpl {
  adminConf: WidgetConf;
  widgetLayout: WidgetLayout;
}

@Component({
  selector: 'rag-page',
  templateUrl: './rag-page.component.html',
  styleUrls: [ './rag-page.component.scss' ],
})
export class RagPageComponent implements OnInit, OnDestroy {

  @Input() pageId: string;

  isInEditMode = false;
  isLayoutInitialized = false;
  options: GridsterConfig;
  resizeEvent: EventEmitter<any> = new EventEmitter<any>();
  styleSettings: StyleSettings;
  widgets$ = new BehaviorSubject<Array<Widget>>([]);

  private widgets: Widget[] = [];
  private saveLayoutDebouncer$ = new Subject<any>();

  constructor(
    private ragPageService: RagPageService,
    private componentCommunicationService: ComponentCommunicationService,
    private infoService: InfoService,
    private accountService: AccountDesignService,
  ) {}

  ngOnInit() {
    this.options = {
      gridType: GridType.VerticalFixed,
      displayGrid: DisplayGrid.OnDragAndResize,
      itemChangeCallback: this.itemChange,
      // setGridSize: true,
      itemResizeCallback: (item) => {
        // update DB with new size
        // send the update to widgets
        this.resizeEvent.emit(item);
      },
      keepFixedHeightInMobile: true,
      keepFixedWidthInMobile: false,
      minCols: 3,
      maxCols: 3,
      compactType: CompactType.CompactLeftAndUp,
      draggable: {
        enabled: this.edit,
      },
      resizable: {
        enabled: this.edit,
      },
      swap: true,
      pushItems: true,
      fixedRowHeight: 20,
      fixedColWidth: 75,
      outerMargin: false,
      outerMarginBottom: 20,
      //! IMPORTANT
      //Gridster seems to break when not in mobile mode, except if set as position: absolute.
      //This is kind of horrible, as absolute is a rough CSS property that messes up and complicates a lot of
      //otherwise streamlined, predictable and easy calculations. Forcing gridster in mobile mode
      //(through setting the breakpoint to a very high number) does not SEEM to change any functionality
      //and the page continues to work, thus I have accepted this as a feasible solution for now, especially since
      //Gridster is an old component and most of the code here is already four years old, and thus finding an
      //efficient and on-point solution might be near impossible with such outdated libraries.
      //If someone understands Gridster good enough, feel free to find a better fix.
      mobileBreakpoint: Infinity,
      // maxItemRows: 3,
      // setGridSize: true
    };

    // subscribe saveLayoutDebouncer and wait for saveLayout events with more then 500ms intervals
    // avoiding multiple save layout API requests on every single widget misplacement.
    this.saveLayoutDebouncer$
      .pipe(debounceTime(500))
      .pipe(map(paylaod => {
        if (paylaod == null) {
          return;
        }
        this.ragPageService.saveLayout(this.pageId, this.widgets).subscribe(success => {
          if ( success ) {
            this.infoService.showSnackbar(MessageKey.RAGPAGE_SAVE_LAYOUT_SUCCESS, InfoType.Information);
          } else {
            this.infoService.showSnackbar(MessageKey.RAGPAGE_SAVE_LAYOUT_ERROR, InfoType.Error);
          }
        });
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    //  fetch the user's layout settings and regenerate it
    this.initLayout();

    // subscribe for add new widget event
    this.componentCommunicationService.addWidgetChange$
      .pipe(map(widgetUUID => this.addWidget(widgetUUID)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    // subscribe for reset widgets event
    this.componentCommunicationService.resetWidgetsClick$
      .pipe(map(pageId => {
        if ( this.pageId !== pageId ) {
          return;
        }
        this.ragPageService.resetWidgets(pageId).subscribe(success => {
          if ( success ) {
            // empty widget array to display only default widgets from admin setting
            this.initLayout(false);
          }
        });
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  // true - enable the widgets to be moved ot removed, false by default
  @Input() set edit(edit: boolean) {
    this.options.draggable = {
      enabled: edit,
    };
    this.options.resizable = {
      enabled: edit,
    };
    this.isInEditMode = edit;

    forIn(this.widgets, (widget: Widget) => {
      widget.gridsterItem.dragEnabled = widget.gridsterItem.draggable && edit;
    });

    if ( this.options.api ) {
      this.options.api.optionsChanged();
    }
  }

  addWidget = (widgetUUID: string): void => {
    // create widget instance and bind api handlers
    const newWidget: Widget = this.ragPageService.createWidgetForId(this.pageId, null, widgetUUID, this.styleSettings);

    newWidget.api = {
      saveLayout: this.saveLayout,
    };

    if ( this.options.api ) {
      const gridsterItem = this.options.api.getFirstPossiblePosition(newWidget.gridsterItem);
      newWidget.gridsterItem = { ...newWidget.gridsterItem, ...gridsterItem };
    }

    this.ragPageService.addWidgetAndSaveLayout(this.pageId, newWidget, this.widgets).subscribe(success => {
      if ( success ) {
        this.widgets$.next(this.widgets);
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ADD_WIDGET_SUCCESS, InfoType.Success);
      } else {
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ERROR_CANNOT_ADD_WIDGET, InfoType.Error);
      }
    }, error => {
      console.error(error);
    });
  };

  changedOptions() {
    if ( this.options.api && this.options.api.optionsChanged ) {
      this.options.api.optionsChanged();
    }
  }

  itemChange = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
    // save only when in editing mode
    if ( this.isInEditMode && !Object.prototype.hasOwnProperty.call(itemComponent,'notPlaced') ) {
      this.saveLayout();
    }
  };

  reloadLayout() {
    this.widgets.splice(0);
    this.initLayout();
  }

  removeItem = (widget: Widget) => {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      messageKey: MessageKey.RAGPAGE_REMOVE_WIDGET_CONFIRMATION_TEXT,
      titleKey: MessageKey.RAGPAGE_REMOVE_WIDGET_CONFIRMATION_TITLE,
      buttons: DeleteCancelButtons,
    })
      .subscribe(button => {
        if ( button === DeleteButton ) {
          // remove the widget locally only if the server responds with OK
          const subscription = this.ragPageService
            .removeWidget(this.pageId, widget, this.widgets)
            .subscribe(index => {
              subscription.unsubscribe();
              // if index >=0 then we are allowed to remove the widget locally
              if ( index >= 0 ) {
                this.widgets$.next(this.widgets);
                this.infoService.showSnackbar(MessageKey.RAGPAGE_REMOVE_WIDGET_SUCCESS, InfoType.Information);
                return;
              }
              this.infoService.showSnackbar(MessageKey.RAGPAGE_ERROR_CANNOT_REMOVE_WIDGET, InfoType.Error);
            });
        }
      });
  };

  saveLayout = async () => {
    // register saveLayout request
    this.saveLayoutDebouncer$.next({});
  };

  private initLayout(_: boolean = true): void {
    this.accountService.getStyleSettings()
      .pipe(tap(styleSettings => this.styleSettings = styleSettings))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    combineLatest([
      this.ragPageService.getWidgetsLayout(this.pageId),
      this.ragPageService.getWidgetAdminSettings(this.pageId),
    ])
    .pipe(tap(([ myLayout, adminWidgets ]) => this.layoutRestoreHandler(this.pageId, myLayout, adminWidgets)))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  private layoutRestoreHandler(pageId: string, layoutResponse: Layout, adminWidgets: AnyObject<WidgetConf>): void {

    const widgetsToDisplay: WidgetLayoutImpl[] = [];
    const layout = layoutResponse.layout;
    const adminConf: WidgetConf[] = Object.values(adminWidgets);

    // the user has no widgets defined yet
    if ( Object.keys(layout).length === 0 ) {

      this.widgets =
        adminConf
          .filter(widgetConf => widgetConf.initial || widgetConf.static)
          .sort((widgetConf1, widgetConf2) => {
            if ( widgetConf1.showAtTop ) {
              if ( widgetConf2.showAtTop ) {
                return widgetConf1.index - widgetConf2.index;
              }
              return -1;
            } else if ( widgetConf2.showAtTop ) {
              return 1;
            }
            return widgetConf1.index - widgetConf2.index;
          })
          .map(widgetConf => {

            const newWidget = this.ragPageService.createWidgetForId(
              this.pageId, null, widgetConf.uuid, this.styleSettings);
            newWidget.api = {
              saveLayout: this.saveLayout,
            };
            return newWidget;
          });

      this.isLayoutInitialized = true;
      this.widgets$.next(this.widgets);
      return;
    }

    // ensure there are single instances for widgets configured as onlyonce = true in admin settings
    const pushedOnlyOnceUUIDs = new Set<string>();
    Object.keys(layout).forEach(instanceUUID => {

      const widgetLayout = layout[instanceUUID];
      widgetLayout.instanceUUID = instanceUUID;
      if ( !widgetLayout.version ) {
        widgetLayout.version = 1;
      }

      const widgetUUID = widgetLayout.widgetUUID;
      const widgetAdminConf = adminConf.find(_w => _w.uuid === widgetUUID);
      if ( widgetAdminConf != null ) {
        // check if widget is only allowed to be present once
        if ( widgetAdminConf.onlyOnce ) {
          if ( pushedOnlyOnceUUIDs.has(widgetUUID) ) {
            // ignore this widget's instance
            return;
          }
          pushedOnlyOnceUUIDs.add(widgetUUID);
        }
      }

      widgetsToDisplay.push({
        widgetLayout,
        adminConf: widgetAdminConf,
      });
    });

    // compare saved user and admin settings and insert static widgets that are not saved
    adminConf.forEach(adminWidgetConf => {

      const isThisWidgetAlreadyThere = widgetsToDisplay.find(
        widgetLayoutImpl => widgetLayoutImpl.widgetLayout.widgetUUID === adminWidgetConf.uuid);
      if ( isThisWidgetAlreadyThere ) {
        // this widget has been already proceeded, ignore it
        return;
      }

      // if item is in admin settings and not in user settings add widget
      if ( adminWidgetConf.static ) {
        const widgetLayout: WidgetLayout = {
          gridsterItem: adminWidgetConf,
          widgetUUID: adminWidgetConf.uuid,
        };
        widgetsToDisplay.push({
          widgetLayout,
          adminConf: adminWidgetConf,
        });
      }
    });

    this.widgets = widgetsToDisplay.sort((layoutImpl1, layoutImpl2) => {
      const adminConf1 = layoutImpl1.adminConf;
      const adminConf2 = layoutImpl2.adminConf;
      if ( adminConf1?.showAtTop ) {
        if ( adminConf2?.showAtTop ) {
          return adminConf1.index - adminConf2.index;
        }
        return -1;
      } else if ( adminConf2?.showAtTop ) {
        return 1;
      }
      return layoutImpl1.widgetLayout.gridsterItem.y - layoutImpl2.widgetLayout.gridsterItem.y;
    }).map(layoutImpl => {
      if ( layoutImpl.widgetLayout.instanceUUID == null ) {
        return this.ragPageService.createWidgetForId(pageId, null, layoutImpl.widgetLayout.widgetUUID, this.styleSettings);
      }

      const widgetInstance = this.ragPageService.createWidgetByLayoutWidget(
        pageId, layoutImpl.widgetLayout.instanceUUID, layoutImpl.widgetLayout, this.styleSettings);
      widgetInstance.gridsterItem.dragEnabled = widgetInstance.gridsterItem.draggable && this.isInEditMode;
      return widgetInstance;
    });

    this.isLayoutInitialized = true;
    this.widgets$.next(this.widgets);
  }

}
