import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChartService } from 'src/app/component/chart/chart.service';
import { CachedSubject } from 'src/app/core/cached-subject';
import { ReportService } from 'src/app/core/report/report.service';
import { Report } from 'src/app/core/report/report.types';
import { ReceivedResponseEvent } from 'src/app/route/user/certificates/certificates.types';
import { DoughnutChartDataSet, DoughnutChartOptions } from '../../../component/chart/doughnut-chart/doughnut-chart.types';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { AbstractChartWidgetComponent, WidgetsUUID } from '../widgets.types';


@Component({
  selector: 'rag-report-pie-chart-widget',
  templateUrl: './report-pie-chart-widget.component.html',
  styleUrls: [ './report-pie-chart-widget.component.scss' ],
})
export class ReportPieChartWidgetComponent
  extends AbstractChartWidgetComponent
  implements WidgetComponent, OnInit {

  @ViewChild('sizingContainer')
  set sizingContainer(value: ElementRef) {
    this.setSizingContainer(value);
  }

  @ViewChild('sizingReference')
  set sizingReference(value: ElementRef) {
    this.setSizingReference(value);
  }

  // todo check if WidgetUUID should be WidgetsUUID.ReportLinksWidgetUUID
  static WidgetUUID = WidgetsUUID.ReportPieChartWidgetUUID;

  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasResponse = false;
  pieChartOptions: DoughnutChartOptions;
  report: Report;
  shouldDisplaySpinner = true;
  sizingDone$: Observable<void>;
  private chartPie_ = new CachedSubject<DoughnutChartDataSet[]>(null);
  chartPie$ = this.chartPie_.withoutEmptyValues();

  constructor(
    private reportService: ReportService,
    private chartService: ChartService) {
    super();
    this.setAspectRatio(1);
    this.sizingDone$ = this.getSizingDone();

    this.setTitle($localize`:@@report_pie_chart_widget_title:Learning progress chart`);
  }

  isDataDriven() {
    return true;
  }

  ngOnInit() {
    this.reportService.fetchStatisticsByReportUUID(this.widget.settings.reportUUID)
      .pipe(map(report => {
        this.report = report;
        this.setTitle(report.reportConfig.title);
        return report;
      }))
      .pipe(switchMap(report => this.chartService.statisticsAsPie(report.statistics, report.reportConfig.targetType)))
      .pipe(map(this.chartPie_.next))
      .pipe(map(() => {
        this.setDidReceiveResponse({ hasContent: true });
      }))
      .pipe(catchError(() => {
        this.setDidReceiveResponse({ hasContent: false });
        return of(null);
      }))
      .subscribe();
  }

  setDidReceiveResponse(receivedResponse: ReceivedResponseEvent) {
    this.shouldDisplaySpinner = false;
    this.hasResponse = true;
    this.didReceiveResponse(receivedResponse);
  }

  setOptions = (options: DoughnutChartOptions): void => {
    const legend = options && options.showLegend;

    let aspectRatio: number;
    if ( legend === 'right' ) {
      aspectRatio = 2;
    } else {
      aspectRatio = 1;
    }

    this.pieChartOptions = options;
    if ( aspectRatio !== super.getAspectRatio() ) {
      this.setAspectRatio(aspectRatio);
    }
  };

}
