<footer>
  <div
    *ngIf="(styleSettings$ | async) as styleSettings"
    class="flex-container"
  >
    <p class="expand">Powered by {{styleSettings.acc.poweredBy}}</p>
    <a class="footer-link space" *ngIf="checkLink(styleSettings.acc.legalNotice)" href="{{styleSettings.acc.legalNotice}}"
       target="_blank">
      <span i18n="@@impressum">Impressum</span>
    </a>
    <p *ngIf="checkLink(styleSettings.acc.terms)" class="space">|</p>
    <a class="footer-link space" *ngIf="checkLink(styleSettings.acc.terms)" href="{{styleSettings.acc.terms}}"
       target="_blank">
      <span i18n="@@terms">Terms</span>
    </a>
    <p *ngIf="checkLink(styleSettings.acc.policies)" class="space">|</p>
    <a class="footer-link" *ngIf="checkLink(styleSettings.acc.policies)" href="{{styleSettings.acc.policies}}"
       target="_blank">
      <span i18n="@@policies">Policies</span>
    </a>
  </div>
  <div class="flex-container">
    <p>© 1997 - {{currentYear}}</p>
    <div class="jira-wrapper spacer">
      <div
        *ngIf="showJiraCollector"
        class="feedback-collector"
        id="myCustomTrigger"
      >
        <span i18n="@@global_give_feedback">Give feedback</span>
      </div>
    </div>
    <a
      class="footer-link space"
      (click)="this.isLoggedIn && openVersionInfo()"
      [ngStyle]="{'cursor': this.isLoggedIn ? 'pointer' : 'auto'}"
      target="_blank"
    >
      <span i18n="@@version_info_link_name">Version {{currentVersion}}</span>
    </a>
  </div>
</footer>
