<rag-breadcrumbs>
  <a *ngIf="(styleSettings$ | async) as styleSettings">{{styleSettings.acc.myContentsName | localeText}}</a>

  <rag-breadcrumbs-assignment-type
    *ngIf="assignmentType"
    [assignmentType]="assignmentType"
  ></rag-breadcrumbs-assignment-type>

  <rag-breadcrumbs-link
    *ngIf="curriculum"
    [title]="curriculum.title"
    [url]="backUrl"
  ></rag-breadcrumbs-link>

  <rag-breadcrumbs-link
    *ngIf="curriculum"
    [title]="curriculumItemTitle"
  ></rag-breadcrumbs-link>
</rag-breadcrumbs>

<rag-page-header
  [backParams]="backParams"
  [backUrl]="backUrl"
  [showSubTitle]="true"
>
  <div class="page-header-prefix">
    <rag-status-lights
      [displayStatus]="displayStatus"
      class="page-status"
      statusType="DisplayStatus"
    ></rag-status-lights>
  </div>
  <div class="page-header-title">
    {{title | localeText}}
  </div>
  <div class="page-header-sub-title" i18n="@@learner_user_course_todo">Task</div>

  <div class="page-header-buttons">
    <button
      *ngIf="!isOnSampleSolutionPage()"
      (click)="onSave()"
      [disabled]="getSaveButtonDisabledState()"
      class="submit"
      color="primary"
      mat-flat-button
      type="button"
    >
      <span i18n="@@global_save">Save</span>
    </button>

    <button
      *ngIf="!hasSubmitted || extensionsStepsCount === 0"
      (click)="onSubmit()"
      [disabled]="isSubmitDisabled"
      class="submit"
      color="primary"
      mat-flat-button
      type="button"
    >
      <span i18n="@@global_submit">Submit</span>
    </button>
  </div>
</rag-page-header>

<ng-container *ngIf="extensionsStepsCount === 0; else stepperTmpl">
  <ng-container *ngTemplateOutlet="todo"></ng-container>
</ng-container>

<ng-template #stepperTmpl>

  <mat-stepper
    #stepper
    [orientation]="'horizontal'"
    [linear]="true"
    [selectedIndex]="step"
    (selectionChange)="selectionChanged($event)"
  >
    <mat-step
      [stepControl]="getStepControlForTodo()"
      [completed]="getTodoControlCompletedStatus()"
      label="ToDo"
      i18n-label="@@global_distributable_subtype_todo"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="pencil-outline"></mat-icon>
      </ng-template>

      <ng-container *ngTemplateOutlet="todo"></ng-container>
    </mat-step>

    <mat-step
      *ngFor="let step of learningUnit.extensions"
      [stepControl]="getStepControl(step)"
      [completed]="getCompletedStatus(step)"
      [label]="getLabelText(step)">

      <ng-container [ngSwitch]="step.valueOf()">

        <ng-container *ngSwitchCase="'SAMPLE_SOLUTION'">
          <div *ngIf="isStatusGreen() else notGraded">
          <div class="container">

            <div class="row">
              <div
                *ngIf="hasSampleSolution() else noSampleSolution"
                class="block description"
              >
                <div>
                  <div class="headline">
                    <h4 i18n="@@global_sample_solution">Sample solution</h4>
                  </div>
                  <div
                    *ngIf="sampleSolutionText as text"
                    class="todo-descr"
                    [innerHTML]="text | safe: 'html'"
                  ></div>
                </div>
              </div>

              <div
                *ngIf="isStatusGreen() && getFiles() as files"
                class="block assets one-third"
              >
                <div>
                  <div class="headline">
                    <h4 i18n="@@download_sample_solution">Download sample solution</h4>
                  </div>
                  <div class="attachments">
                    <rag-file-render
                      *ngFor="let fileInfo of getFiles()"
                      [file]="fileInfo"
                      [readonly]="true"
                    ></rag-file-render>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </ng-container>

        <ng-container
          *ngSwitchCase="'ESTIMATION'">
          <div class="container flex-box-center">
            <form [formGroup]="selfEstimationFormGroup">
              <div class="headline">
                <h4 i18n="@@global_self_assessment">Self-Assessment</h4>
              </div>
              <div class="self-assessment-text">


                <h4 i18n="@@self_assessment_headline">How do you rate yourself?</h4>
                <div>
                  <span i18n="@@self_assessment_text_1">Would you say you have reached the optimum?
                    Or do you think your result is expandable?
                    Maybe you couldn't cope with the task at all?</span>
                </div>
                <div class="margin-top">
                  <span i18n="@@self_assessment_text_2">Try to classify your result here on a scale from
                    "Very dissatisfied" to "Very satisfied".
                    Feel free to add a comment to justify your assessment.</span>
                </div>
              </div>
              <rag-step-slider #slider
                [steps]="[
                  {label: veryDissatisfied, value:0},
                  {label: dissatisfied, value:1},
                  {label: ok, value:2},
                  {label: satisfied, value:3},
                  {label: verySatisfied, value:4},
                ]"
                [stepBars]="[
                  { height: '12px', color: '#f72257' },
                  { height: '28px', color: '#ff800d' },
                  { height: '45px', color: '#ffc112' },
                  { height: '68px', color: '#d8e800' },
                  { height: '90px', color: '#78c400' }
                ]"
                [startingIndex]="2"
                [hideActive]="true"
              ></rag-step-slider>
              <div class="headline margin-top">
                <h4 i18n="@@learner_user_course_todo_feedback">Comment</h4>
              </div>
              <mat-form-field appearance="outline" class="text-area">
              <textarea
                [readonly]="isSelfAssessmentReadonly"
                [rows]="10"
                [formControlName]="'comment'"
                matInput
              ></textarea>
              </mat-form-field>
            </form>
          </div>
        </ng-container>

      </ng-container>
    </mat-step>

  </mat-stepper>

  <div class="buttons" *ngIf="hasSubmitted && extensionsStepsCount > 0">
    <div>
      <button
        type="button"
        *ngIf="step > 0"
        (click)="onBackStep(stepper)"
        mat-stroked-button>
        <span i18n="@@admin_cockpits_details_back_button">Back</span>
      </button>
    </div>

    <button
      *ngIf="this.step < extensionsStepsCount"
      (click)="onNextStep(stepper)"
      [disabled]="nextButtonDisabled(step)"
      class="submit"
      color="primary"
      mat-flat-button
      type="button">
      <span i18n="@@global_go_next">Next</span>
    </button>
    <button
      *ngIf="!isStatusGreen() && hasSampleSolution() && nextStepIsSampleSolution()"
      type="button"
      mat-flat-button
    >
      <mat-icon
        svgIcon="information-outline"
        [matTooltip]="getSampleSolutionInfoTooltip()"
      ></mat-icon>
    </button>
  </div>

</ng-template>

<ng-template #todo>
  <div class="container">

    <ng-container
      *ngIf="hasTask"
    >

      <div class="row background-color">
        <div
          *ngIf="hasTaskDescription"
          class="block description"
        >
          <div>
            <div class="headline">
              <h4 i18n="@@learner_user_course_todo_task_description">Task description</h4>:
            </div>
            <div
              [innerHTML]="taskDescription | safe:'html'"
              class="todo-descr"
            ></div>
          </div>
        </div>

        <!-- External data -->
        <div
          *ngIf="hasTaskAttachments"
          class="block assets one-third"
        >
          <div>
            <div class="headline">
              <h4 i18n="@@global_documents">Documents</h4>:
            </div>
            <div class="attachments">
              <rag-file-render
                *ngFor="let fileInfo of learningUnit.attachments"
                [file]="fileInfo"
                [readonly]="true"
              ></rag-file-render>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

    <form (ngSubmit)="onSave()" [formGroup]="todoFormGroup">

      <div class="headline">
        <ng-template #tplSolutionTitleComment>
          <h4 i18n="@@learner_user_course_todo_feedback">Comment</h4>
        </ng-template>
        <h4
          *ngIf="(learningUnit?.acceptanceMask > 0); else tplSolutionTitleComment"
          i18n="@@learner_user_course_todo_solution">Solution</h4>
      </div>

      <div class="row">

        <div class="block editor">
          <div>
            <div class="label">
              <ng-template #tplSolutionLabelComment>
            <span i18n="@@learner_user_course_todo_feedback_info">
              If you want, you can add a comment regarding this task:
            </span>
              </ng-template>
              <span
                *ngIf="(learningUnit?.acceptanceMask > 0); else tplSolutionLabelComment"
                i18n="@@learner_user_course_todo_solution_info">Please enter your solution here:</span>
            </div>
            <div class="editor-container">
              <ckeditor
                #ckeditor
                [editor]="Editor"
                [disabled]="isCKEditorDisabled()"
                [config]="ckOptions"
                [formControlName]="'contribution'"
              ></ckeditor>
            </div>
          </div>
        </div>

        <div
          *ngIf="showFileUpload"
          class="block files margin-left"
        >
          <div class="solution-files">

            <div class="label">
              <span i18n="@@learner_user_course_todo_solution_files">Attached files</span>:
            </div>

            <div>
              <rag-file-multi
                (changeEvent)="onFileChange($event)"
                [files]="solutionFiles"
                [readonly]="isReadonly"
              ></rag-file-multi>
            </div>

          </div>
        </div>
      </div>

      <div class="published" *ngIf="isPublished">
        <mat-icon svgIcon="check"></mat-icon>
        <span i18n="@@learner_user_course_todo_submitted">You have submitted your work</span>
      </div>

    </form>

  </div>
</ng-template>

<ng-template #noSampleSolution>
  <div class="no-sample-solution">
                  <span
                    i18n="@@learner_course_todo_no_sample_solution_text">There is no sample solution stored yet</span>
  </div>
</ng-template>

<ng-template #notGraded>
  <div class="no-sample-solution">
                  <span
                    i18n="@@learner_course_todo_not_graded_sample_solution_text"
                  >The sample solution can be viewed only after the task has been successfully solved.</span>
  </div>
</ng-template>
