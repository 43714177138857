import { DisplayStatus } from './display-status.enum';

export enum CMILessonStatus {
  PASSED = 1,
  COMPLETED = 2,
  FAILED = 3,
  INCOMPLETE = 4,
  BROWSED = 5,
  NOT_ATTEMPTED = 0,
  UN_KNOWN = -1,
}

export class CmiLessonStatusHelper {

  public static toDisplayStatus(status: CMILessonStatus): DisplayStatus {
    if ( status == null || status === undefined ) {
      return DisplayStatus.UN_KNOWN;
    }

    switch ( status ) {
      case CMILessonStatus.PASSED:
        return DisplayStatus.PASSED;
      case CMILessonStatus.COMPLETED:
      case CMILessonStatus.BROWSED:
        return DisplayStatus.COMPLETE;
      case CMILessonStatus.FAILED:
        return DisplayStatus.FAILED;
      case CMILessonStatus.INCOMPLETE:
        return DisplayStatus.INCOMPLETE;
      case CMILessonStatus.NOT_ATTEMPTED:
        return DisplayStatus.NOT_ATTEMPTED;
      case CMILessonStatus.UN_KNOWN:
        return DisplayStatus.UN_KNOWN;

    }
  }

  public static toLessonStatus(status: string | number): CMILessonStatus {
    if ( typeof (status) === 'string' ) {
      if ( status === 'green' ) {
        // this may be PASSED or COMPLETED for lms_course!
        status = CMILessonStatus.COMPLETED;
      } else if ( status === 'yellow' ) {
        status = CMILessonStatus.INCOMPLETE;
      } else if ( status === 'red' ) {
        status = CMILessonStatus.NOT_ATTEMPTED;
      } else {
        status = Number(status);
      }
    }
    if ( Number.isNaN(status) ) {
      return CMILessonStatus.UN_KNOWN;
    }

    switch ( status ) {
      case CMILessonStatus.PASSED:
        return CMILessonStatus.PASSED;
      case CMILessonStatus.COMPLETED:
        return CMILessonStatus.COMPLETED;
      case CMILessonStatus.BROWSED:
        return CMILessonStatus.BROWSED;
      case CMILessonStatus.FAILED:
        return CMILessonStatus.FAILED;
      case CMILessonStatus.INCOMPLETE:
        return CMILessonStatus.INCOMPLETE;
      case CMILessonStatus.NOT_ATTEMPTED:
        return CMILessonStatus.NOT_ATTEMPTED;
      case CMILessonStatus.UN_KNOWN:
      default:
        return CMILessonStatus.UN_KNOWN;
    }
  }

}
