<div class="container">

  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@admin_ext_logins_header">Virtual platforms</span>
    </div>

    <div class="page-header-buttons">
      <button (click)="onNewExtLogin()" color="primary" mat-flat-button>
        <span i18n="@@global_create_entry">Create entry</span>
      </button>
    </div>
  </rag-page-header>

  <mat-menu #menuActions>
    <ng-template let-login="login" matMenuContent>
      <button (click)="onEditLogin(login)" mat-menu-item>
        <mat-icon svgIcon="pencil-outline"></mat-icon>
        <span i18n="@@global_edit">Edit</span>
      </button>

      <button mat-menu-item (click)="onTestLogin(login)">
        <mat-icon svgIcon="wrench-check-outline"></mat-icon>
        <span i18n="@@test">Test</span>
      </button>

      <mat-divider></mat-divider>

      <button (click)="onDeleteLogin(login)" mat-menu-item>
        <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
        <span i18n="@@global_delete">Delete</span>
      </button>
    </ng-template>
  </mat-menu>

  <ng-container *ngIf="hasData; else noData">

    <table
      [dataSource]="dataSource"
      mat-table
      matSort
    >
      <!--
      todo apply default sorting without RCE in translated titles
      matSortActive="serverName"
      matSortDirection="asc"
      -->

      <!-- Name Column -->
      <ng-container matColumnDef="serverName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <span i18n="@@global_name">Name</span>
        </th>
        <td *matCellDef="let login" mat-cell>{{login.serverName}}</td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="serverType">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <span i18n="@@global_type">Type</span>
        </th>
        <td *matCellDef="let login" mat-cell>
          <span [ngSwitch]="login.serverType">
            <span *ngSwitchCase="'adobe_connect'" i18n="@@ext_login_adobe_connect">Adobe Connect</span>
            <span *ngSwitchCase="'any'" i18n="@@global_any">Any</span>
          </span>
        </td>
      </ng-container>

      <!-- login Column -->
      <ng-container matColumnDef="login">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <span i18n="@@global_login">Login</span>
        </th>
        <td *matCellDef="let login" mat-cell>{{login.login}}</td>
      </ng-container>

      <!-- url Column -->
      <ng-container matColumnDef="url">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <span i18n="@@global_url">Url</span>
        </th>
        <td *matCellDef="let login" mat-cell>{{login.url}}</td>
      </ng-container>

      <!-- Menu Column -->
      <ng-container matColumnDef="menu">
        <th *matHeaderCellDef class="menu" mat-header-cell>&nbsp;</th>
        <td (click)="$event.stopPropagation()" *matCellDef="let login" class="menu" mat-cell>
          <button [matMenuTriggerData]="{login: login}" [matMenuTriggerFor]="menuActions" mat-icon-button>
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </ng-container>

  <ng-template #noData>
    <div class="no-data">
      <span i18n="@@ext_login_no_data">
       There are no virtual platforms defined yet. Click on "Add entry".
      </span>
    </div>
  </ng-template>
</div>
