import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { OAuth2ClientRegistration } from './ext-oauth2.types';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from '../../../../core/global.types';
import { catchError, map, mapTo, switchMap, takeWhile } from 'rxjs/operators';
import { ApiUrls } from '../../../../core/api.urls';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { ErrorHelper } from '../../../../core/error.helper';


@Injectable({ providedIn: 'root' })
export class ExtOauth2Service {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  deleteClientRegistration(
    registrationId: string,
  ): Observable<void> {

    if ( !registrationId ) {
      return EMPTY;
    }

    const message = $localize`:@@ext_oauth2_confirm_delete:
      Do you really wish to delete this OpenID Connect Server?<br />
      In case you wish to restore the previous configuration at a later point,<br />
      you can simply copy the JSON-Data from the configuration dialog, before deleting the entry.
    `;
    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUpdate')
      .replace(/{registrationId}/gi, registrationId);


    return this.infoService.showMessage(message, {
      buttons: YesNoButtons,
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
    })
      .pipe(takeWhile(button => button === YesButton))

      .pipe(switchMap(() => this.http.delete(url)))

      .pipe(catchError(this.handleError))
      .pipe(mapTo(void (0)));
  }

  fetchWellKnown(
    issuerUri: string,
  ): Observable<Partial<OAuth2ClientRegistration>> {

    const url = ApiUrls.getKey('ExtOAuth2WellKnown')
      .replace(/{issuerUri}/, issuerUri);
    return this.http.get<ApiResponse<OAuth2ClientRegistration>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response?.wellKnown ?? null));
  }

  getClientRegistrations(): Observable<OAuth2ClientRegistration[]> {

    const url = ApiUrls.getKey('ExtOAuth2ClientRegistration');
    return this.http.get<ApiResponse<OAuth2ClientRegistration[]>>(url)
      .pipe(map(response => response?.clientRegistrations ?? []));
  }

  updateClientRegistration(
    clientRegistration: OAuth2ClientRegistration | null,
  ): Observable<OAuth2ClientRegistration | null> {

    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUpdate')
      .replace(/{registrationId}/gi, clientRegistration.registrationId);
    return this.http.post<ApiResponse<OAuth2ClientRegistration>>(url, clientRegistration)
      .pipe(map(response => response?.clientRegistration ?? null));
  }

  private handleError = (response: HttpErrorResponse): Observable<never> => {

    let message: string;
    switch ( ErrorHelper.findApiError(response)?.errorCode ?? '' ) {
      case 'ERR_OAUTH_018':
        message = $localize`:@@ext_oauth2_error_client_in_use:
            You can only delete a client registration until it has been used by at least one user.<br/>
            After that, you can only disable the entry.`;
        break;
      default:
        message = MessageConstants.API.ERROR;
    }

    this.infoService.showMessage(message, { infoType: InfoType.Error });
    return EMPTY;
  };

}
