import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageHeaderComponent } from '../../page-header/page-header.component';
import { ContentInfoButtonModule } from '../content-info-button/content-info-button.module';
import { StatusLightsModule } from '../status-lights/status-lights.module';
import { CurriculumTitleComponent } from './curriculum-title.component';
import {PipesModule} from '../../../core/pipes/pipes.module';

@NgModule({
  declarations: [
    CurriculumTitleComponent,
  ],
  exports: [
    CurriculumTitleComponent,
  ],
  imports: [
    CommonModule,
    StatusLightsModule,
    ContentInfoButtonModule,
    PageHeaderComponent,
    PipesModule,
  ],
})
export class CurriculumTitleModule {
}
