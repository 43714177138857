<rag-page-header>
  <div class="page-header-title" i18n="@@global_contributions_and_answers">Contributions and Answers</div>
  <div class="page-header-buttons">
    <a
      mat-flat-button
      color="primary"
      [routerLink]="'/qa/new'"
      data-cy="createButton"
    >
      <span i18n="@@global_create_new_contribution">Create a new contribution</span>
    </a>
  </div>
</rag-page-header>

<rag-qa-list></rag-qa-list>
