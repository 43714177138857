import { Component, OnDestroy } from '@angular/core';
import { Report } from '../../../../core/report/report.types';
import { ActivatedRoute } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { map, tap } from 'rxjs/operators';
import { ReportGeneratorV2RouteData } from './report-generator-v2.types';

@Component({
  selector: 'rag-report-generator-v2',
  templateUrl: './report-generator-v2.component.html',
  styleUrls: [ './report-generator-v2.component.scss' ],
})
export class ReportGeneratorV2Component
  implements OnDestroy {

  reports: Array<Report> = [];

  constructor(
    private route: ActivatedRoute,
  ) {

    this.route.data
      .pipe(map(data => data?.reportGeneratorData))
      .pipe(tap(this.setRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  private setRouteData = (data: ReportGeneratorV2RouteData) => {
    this.reports = data?.reports ?? [];
  };

}
