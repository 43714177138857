import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AnyObject } from '../../core/core.types';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  selector: 'rag-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: [ './page-header.component.scss' ],
})
export class PageHeaderComponent
  implements OnDestroy, OnInit {

  @Input() backParams: AnyObject<string>;
  @Input() backUrl: string;
  @Input() hasPrefix = false;
  @Input() showSubTitle = false;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  static parseBackParams(paramString: string): AnyObject<string> | null {
    try {
      return JSON.parse(paramString);
    } catch ( e ) {
      return null;
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.route.queryParams
      // update needs to happen after @Inputs have been processed
      .pipe(tap(params => this.updateBackUrl(params)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private clearBackParam(key: string): void {

    if ( this.backParams == null ) {
      this.backParams = {};
    }

    this.backParams[key] = null;
  }

  private updateBackUrl(params: Params): void {

    const backUrl = params.backUrl;
    if ( backUrl ) {
      this.backUrl = backUrl;
      this.clearBackParam('backUrl');
    }

    const backParams = PageHeaderComponent.parseBackParams(params.backParams);
    if ( backParams ) {
      this.backParams = backParams;
      this.clearBackParam('backParams');
    }
  }

}
