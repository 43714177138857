<div class="site-wrapper" style="" (click)="onNavigateToDetails()">
  <div class="card-wrapper">
    <div class="newstyle-card">
      <div class="card-image-wrapper" [ngStyle]="cardBackgroundStyles$ | async">
        <div
          #matTooltip="matTooltip"
          *ngIf="isBooked(content.catalogBooking)"
          class="card-icon-booked rounded-div"
          matTooltip="Booked"
          i18n-matTooltip="@@booked"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_favourite.svg" alt="booked">
        </div>
        <div
          #matTooltip="matTooltip"
          *ngIf="isAssigned(content)"
          class="card-icon-assigned rounded-div"
          matTooltip="Assigned"
          i18n-matTooltip="@@catalog_assigned"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_assigned.svg" alt="favourite">
        </div>
      </div>
      <div class="mat-card-content">
        <div>
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <div>
            <span class="mat-card-subtitle font-light letterspacing-more">{{ objSubTypeAsText }}</span>
          </div>
        </div>
        <div class="divider-line"></div>
        <div class="mat-card-bottom-wrapper">
          <div *ngIf="itemCount > 0; else noData" class="schedules">
            <div class="schedule-picker">
              <button
                (click)="selectedItemChanged('previous', $event)"
                mat-icon-button
                type="button"
                [disabled]="itemCount === 1"
              >
                <mat-icon svgIcon="chevron-left" class="chevron-icon"></mat-icon>
              </button>
              <div class="schedule-info">
                <span class="semi-bold item-title">{{ selectedItem.title | localeText }}</span>
              </div>
              <button
                (click)="selectedItemChanged('next', $event)"
                mat-icon-button
                type="button"
                [disabled]="itemCount === 1"
              >
                <mat-icon svgIcon="chevron-right" class="chevron-icon"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #noData>
  <div class="schedules">
    <span i18n="@@no_content_curriculum" class="no-items">There is currently no content available</span>
  </div>
</ng-template>
