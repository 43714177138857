import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { ReportNavComponent } from '../report-nav/report-nav.component';
import { ClassicReportComponent } from './classic-report.component';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';

@NgModule({
  declarations: [
    ClassicReportComponent,
  ],
  exports: [
    ClassicReportComponent,
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    PipesModule,
    ReportNavComponent,
    LoadingIndicatorComponent,
  ],
})
export class ClassicReportModule {
}
