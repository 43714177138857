import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ColumnSettings, Report } from '../../../../../../core/report/report.types';
import { ReportTableService } from '../report-table.service';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../core/reactive/until-destroyed';
import { UntypedFormControl } from '@angular/forms';
import { LanguageHelper } from '../../../../../../core/language.helper';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { naturalCompare } from '../../../../../../core/natural-sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { combineLatest, Observable } from 'rxjs';
import { CachedSubject } from '../../../../../../core/cached-subject';


@Component({
  selector: 'rag-report-table-grouping',
  templateUrl: './report-table-grouping.component.html',
  styleUrls: [ './report-table-grouping.component.scss' ],
})
export class ReportTableGroupingComponent
  implements OnDestroy {

    @ViewChild('eltInput') eltInput: ElementRef<HTMLInputElement>;
  readonly available$: Observable<ColumnSettings[]>;
  readonly formControl = new UntypedFormControl(null);
  readonly separatorKeysCodes: number[] = [ ENTER, COMMA ];
  private _columns: ColumnSettings[] = [];
  private _columnsAvailable: ColumnSettings[];
  private _columnsChanged = new CachedSubject<void>(null);
  private _columnsSelected: ColumnSettings[];
  private _disabled = false;
  private _groupings: string[] | null;
  private _report: Report;

  constructor(
    private reportTableService: ReportTableService,
  ) {
    this.reportTableService.loading$
      .pipe(tap(this.toggleDisabled))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.reportTableService.report$
      .pipe(tap(this.updateReport))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.available$ = combineLatest([
      this.formControl.valueChanges
        // selection in mat-autocomplete should clear the filter value
        .pipe(map(value => typeof (value) === 'string' ? value : ''))
        // generate the initial list
        .pipe(startWith('')),
      // delay until the columns have been set
      this._columnsChanged.asObservable(),
    ])
      .pipe(map(([ value ]) => value))
      .pipe(debounceTime(5))
      .pipe(map(this.filterAvailable));
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get selected(): ColumnSettings[] {
    return this._columnsSelected ?? [];
  }

  clearSearch(): void {
    this.eltInput.nativeElement?.blur();

    // delay clearing to allow the mat-autocomplete to close first
    setTimeout(() => {
      this.formControl.setValue('');

      const nativeElement = this.eltInput.nativeElement;
      if ( nativeElement != null ) {
        nativeElement.value = '';
      }
    }, 50);
  }

  columnIdToTitle = (columnId: string): string => {
    const column = this._columns.find(c => c.id === columnId);
    return LanguageHelper.objectToText(column?.title);
  };

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onDrop(event: CdkDragDrop<ColumnSettings[], any>): void {
    if ( this._disabled || event.previousIndex === event.currentIndex ) {
      return;
    }

    moveItemInArray(this._groupings, event.previousIndex, event.currentIndex);
    this.updateColumns();
    this.emitChange(false);
  }

  onRemove(column: ColumnSettings): void {
    if ( this._disabled ) {
      return;
    }

    const columnId = column?.id;
    if ( columnId == null ) {
      return;
    }

    const index = this._groupings.findIndex(entry => entry === columnId);
    if ( index === -1 ) {
      return;
    }

    this._groupings.splice(index, 1);
    this.updateColumns();
    this.emitChange(false);
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    const column = event.option.value;
    if ( !this._disabled && (column != null) ) {

      const columnId = column.id;
      this._groupings.push(columnId);
      this.updateColumns();

      const columns = this._report?.reportConfig?.columns ?? [];
      const needsReload = !columns.includes(columnId);
      this.emitChange(needsReload);
    }
  }

  private emitChange(needsReload: boolean): void {
    this.reportTableService.updateGroupings(this._groupings, needsReload);
  }

  private filterAvailable = (value: string) => {
    value = (value ?? '').trim().toLocaleLowerCase();
    if ( value === '' ) {
      return this._columnsAvailable;
    }

    return this._columnsAvailable
      // todo prevent redundant calls for objectToText, and localeLowerCase
      .filter(column => LanguageHelper.objectToText(column.title).toLocaleLowerCase().includes(value));
  };

  private toggleDisabled = (disabled: boolean): void => {
    this._disabled = disabled;

    if ( disabled ) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  };

  private updateColumns(): void {
    const columns = this._columns ?? [];
    const groupings = this._groupings ?? [];

    // iterate over groupings to preserve order
    const selected = this._columnsSelected = groupings
      .map(columnId => columns.find(column => column.id === columnId))
      .filter(column => column != null);

    this._columnsAvailable = columns
      .filter(column => !selected.includes(column))
      .sort((a, b) =>
        naturalCompare(LanguageHelper.objectToText(a.title), LanguageHelper.objectToText(b.title)));

    this._columnsChanged.next();
  }

  private updateReport = (report: Report | null): void => {
    this._report = report;
    const reportConfig = report?.reportConfig;

    this._columns = (reportConfig?.columnSettings ?? []);
    this._groupings = reportConfig?.groupings ?? [];
    this.updateColumns();
  };

}
