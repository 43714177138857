<mat-form-field
  appearance="outline"
  (click)="eltInput.focus()"
>
  <mat-label i18n="@@pivot_table_label_groupings">Group by...</mat-label>

  <mat-chip-grid
    #matChipGrid
    cdkDropList
    (cdkDropListDropped)="onDrop($event)"
    [cdkDropListDisabled]="disabled"
    cdkDropListOrientation="horizontal"
  >
    <mat-chip-row
      *ngFor="let column of selected"
      (removed)="onRemove(column)"
      cdkDrag
    >
      {{column.title | localeText}}
      <mat-icon matChipRemove svgIcon="close"></mat-icon>
    </mat-chip-row>

    <input
      type="text"
      matInput
      #eltInput
      [formControl]="formControl"
      [matAutocomplete]="matAutocomplete"
      [matChipInputFor]="matChipGrid"
      (blur)="(!matAutocomplete.isOpen) && clearSearch()"
    />
  </mat-chip-grid>

  <mat-autocomplete
    #matAutocomplete
    (optionSelected)="onSelected($event)"
    (closed)="clearSearch()"
  >
    <mat-option
      *ngFor="let column of available$ | async"
      [value]="column"
    >
      {{column.title | localeText}}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
