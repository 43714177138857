import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogHeaderService } from 'src/app/core/dialog-header/dialog-header.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
  selector: 'rag-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent {

  @Input() closingEnabled = true;

  constructor(
    private dialogRef: MatDialogRef<DialogHeaderComponent>,
    private dialogHeaderService: DialogHeaderService,
  ) {
  }

  onCloseClick() {
    this.dialogHeaderService.publish({
      type: 'close'
    });

    this.dialogRef.close(null);
  }

}
