<div id="receiver-field">

  <span *ngIf="itemsCount > 0" class="container">

    <!-- display all items when displayAll = true -->
    <div *ngIf="displayAll" class="all-items-container">
      <div *ngFor="let item of items" class="item" [ngClass]="{'deactivated-item': isItemInvalid(item)}">
        <span>{{item?.name}}</span>
      </div>
    </div>

    <!-- display only one items when displayAll = false -->
    <div *ngIf="!displayAll" [ngClass]="{'deactivated-item': isItemInvalid(items[0])}">{{items[0].name}}</div>

    <!-- display more if displayAll = false and there are more than 1 items -->
    <div *ngIf="!displayAll && itemsCount > 1" class="more">
      <div>{{itemsCount - 1}}</div>
      <div class="more-count">
        <span i18n="@@more">more</span>
      </div>
    </div>
  </span>

  <span *ngIf="itemsCount === 0" class="container padding empty">{{placeholder}}</span>
</div>
