import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PreloadService } from '../preload.service';
import { PrincipalService } from '../principal/principal.service';
import {
  NavigationData,
  NavItem,
  NavItemHideForIlearn,
  NavItemMenu,
  NavItemSpecial,
  NavItemType,
  ROUTE_MATCH_OPTIONS_EXACT,
  ROUTE_MATCH_OPTIONS_NOT_EXACT,
} from './navigation.types';
import { PermissionStates } from '../principal/permission.states';
import { DEFAULT_NAVIGATION_GUEST, DEFAULT_NAVIGATION_USER } from './navigation.default';
import { CachedSubject } from '../cached-subject';
import { DateHelper } from '../date.helper';
import { ApiUrls } from '../api.urls';
import { CatalogService } from '../catalog/catalog.service';
import { AccountInterceptor } from '../interceptors/account.interceptor';
import { DEFAULT_NAVIGATION_AVS_USER } from './navigation-avs.default';
import { DEFAULT_NAVIGATION_VTP_GUEST, DEFAULT_NAVIGATION_VTP_USER } from './navigation-vtp.default';
import { AccountDesignService } from '../../route/admin/account-design/account-design.service';
import { StyleSettings } from '../../route/admin/account-design/account-design.types';
import { LanguageHelper } from '../language.helper';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import {
  DEFAULT_NAVIGATION_RAG_DIALOG_GUEST,
  DEFAULT_NAVIGATION_RAG_DIALOG_USER,
} from './navigation-rag-dialog.default';
import { MergeHelper } from '../primitives/merge.helper';


@Injectable({ providedIn: 'root' })
export class HeaderService {

  readonly activeNavItems$: Observable<NavItem[]>;
  readonly navigationData$: Observable<NavigationData>;
  private _navigationData$ = new CachedSubject<NavigationData>(null);

  constructor(
    private accountDesignService: AccountDesignService,
    private catalogService: CatalogService,
    private preloadService: PreloadService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
    this.navigationData$ = this._navigationData$.asObservable();

    merge(
      this.principalService.isLogged$
        .pipe(map(() => this.principalService.currentUser)),
      this.principalService.principal$
    )
      .pipe(map(user => user?.userId))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(() => this.preloadService.getNavigationData(true)))
      .pipe(tap(this._navigationData$.next))
      .subscribe();

    this.activeNavItems$ = combineLatest([
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
      this.getNavigation(),
    ])
      .pipe(map(([ , navigationData ]) => [
        ...this.getActiveRoutes(navigationData.top),
        ...this.getActiveRoutes(navigationData.main),
      ]));

    this.activeNavItems$
      .pipe(map(items => HeaderService.getItemsAsTitle(items)))
      .pipe(tap(title => this.accountDesignService.setPageTitle(title, true)))
      .subscribe();
  }

  public static generateAdditionalRoutes(
    navItems: NavItem[] | null,
  ): string[] {
    return (navItems ?? []).flatMap(item => {
      if ( (item.type === NavItemType.menu) || (item.type === NavItemType.menuIcon) ) {
        item.additionalRoutes ??= [];
        const childRoutes = HeaderService.generateAdditionalRoutes((item as NavItemMenu).children);
        item.additionalRoutes.push(...childRoutes);
        return [
          ...childRoutes,
          item.routerLink,
        ];
      }
      return [ item.routerLink ];
    })
      .filter(item => !!item);
  }

  public static getItemsAsTitle(items: NavItem[] | null): string | null {
    return items?.reverse().map(item => item.title).join(' - ');
  }

  private static filterNavItems(
    navItems: NavItem[] | null,
    filterFunction: (item: NavItem) => boolean,
  ): NavItem[] | null {
    return navItems?.filter(item => {
      if (filterFunction(item)) {
        if (item.hasOwnProperty('children')) {
          item['children'] = this.filterNavItems(item['children'], filterFunction);
        }
        return true;
      }
      return false;
    }) ?? [];
  }

  getNavigation(): Observable<NavigationData> {
    return combineLatest([
      this.navigationData$,
      this.principalService.permissionStates$
    ]).pipe(switchMap(([navigationData, permissions]) =>
      this.getNavigationForData(navigationData, permissions)));
  }

  private getActiveRoutes(items: NavItem[] | null): NavItem[] {
    return (items ?? []).flatMap(item => {

      if ( item == null ) {
        return [];
      }

      const activeItems = [];
      const hasActiveAdditional = item.additionalRoutes
        ?.find(route => this.router.isActive(route, ROUTE_MATCH_OPTIONS_NOT_EXACT)) != null;
      if ( hasActiveAdditional ) {
        activeItems.push(item);
      } else if ( item.routerLink ) {
        let matchOptions: IsActiveMatchOptions | null;
        if ( item.routerLinkActiveOptions?.hasOwnProperty('exact') ) {
          matchOptions = (item.routerLinkActiveOptions['exact'] === true) ?
            ROUTE_MATCH_OPTIONS_EXACT :
            ROUTE_MATCH_OPTIONS_NOT_EXACT;
        } else {
          matchOptions = item.routerLinkActiveOptions as IsActiveMatchOptions;
        }
        const urlTree = this.router.createUrlTree(item.routerLink.split('/'), {
          queryParams: item.queryParams,
        });
        if ( this.router.isActive(urlTree, matchOptions) ) {
          activeItems.push(item);
        }
      }

      if ( item.hasOwnProperty('children') ) {
        activeItems.push(...this.getActiveRoutes((item as NavItemMenu).children));
      }
      return activeItems;
    });
  }

  private getNavigationForData(
    navigationData: NavigationData | null,
    permissions: PermissionStates,
  ): Observable<NavigationData> {

    const isGuest = !permissions.isLogged;
    const accountKey = AccountInterceptor.getAccountKey();
    if ( (accountKey === 'lqjkjo0bf7hj1i0yp85jhlyj6jzjtmj59g6') ||
      (accountKey === '5x5zn2zahdyn3a8pssxwk5gpc5mnezcugcm') ||
      (accountKey === 'bgynzm0vvbcq3gxzwt0ca8wyihyeytdifd4')) {
      // minimal navigation for VTP
      navigationData ??= (isGuest ? DEFAULT_NAVIGATION_VTP_GUEST : DEFAULT_NAVIGATION_VTP_USER);

    } else if (accountKey === 'qxvcd80b0of5gexg2u719jwu04n3ilaq31s') {
      // minimal navigation for RAG Dialog
      navigationData ??= (isGuest ? DEFAULT_NAVIGATION_RAG_DIALOG_GUEST : DEFAULT_NAVIGATION_RAG_DIALOG_USER);

    } else if ( permissions.hideForILearn ) {
      // keep previous navigation scheme for AVS
      navigationData ??= (isGuest ? DEFAULT_NAVIGATION_GUEST : DEFAULT_NAVIGATION_AVS_USER);

    } else {
      // fall back to defaults
      navigationData ??= (isGuest ? DEFAULT_NAVIGATION_GUEST : DEFAULT_NAVIGATION_USER);
    }

    navigationData = MergeHelper.cloneDeep(navigationData);

    navigationData.top = HeaderService.filterNavItems(navigationData.top ?? [],
      item => this.postProcessItem(item, permissions) != null);
    HeaderService.generateAdditionalRoutes(navigationData.top);

    navigationData.main = HeaderService.filterNavItems(navigationData.main ?? [],
      item => this.postProcessItem(item, permissions) != null);
    HeaderService.generateAdditionalRoutes(navigationData.main);

    if ( isGuest ) {
      return this.getNavigationForGuest(navigationData);
    } else {
      return this.accountDesignService.getStyleSettings().pipe(switchMap(settings =>
        this.getNavigationForUsers(navigationData, settings, permissions)));
    }
  }

  private getNavigationForGuest(navigationData: NavigationData): Observable<NavigationData> {
    return this.catalogService.getCatalogState()
      .pipe(take(1))
      .pipe(map(catalogState => {

        if ( !catalogState.publicCatalog ) {
          // remove both login buttons and catalog from all navigation -> starts at login anyway
          navigationData.top = HeaderService.filterNavItems(navigationData.top, item => (item.special !== NavItemSpecial.login));
          navigationData.main = HeaderService.filterNavItems(
            navigationData.main, item => (item.special !== NavItemSpecial.catalog) && (item.special !== NavItemSpecial.login));
          return navigationData;
        }

        if ( catalogState.displayAsHome ) {
          navigationData.main = HeaderService.filterNavItems(
            navigationData.main, item => (item.special !== NavItemSpecial.catalog) && (item.special !== NavItemSpecial.login));
        } else {
          navigationData.top = HeaderService.filterNavItems(navigationData.top, item => (item.special !== NavItemSpecial.login));
        }
        return navigationData;
      }));
  }

  private getNavigationForUsers(
    navigationData: NavigationData,
    settings: StyleSettings,
    permissions: PermissionStates,
  ): Observable<NavigationData> {
    return this.preloadService.getGamificationEnabled()
      .pipe(catchError(() => of(false)))
      .pipe(take(1))
      .pipe(map(gamificationEnabled => {
        // remove both login from header -> already logged in
        navigationData.top = HeaderService
          .filterNavItems(navigationData.top, item => {
            switch(item.special) {
              case NavItemSpecial.login:
                return false;
              case NavItemSpecial.gamification:
                return gamificationEnabled;
              default:
                return true;
            }
          });
        const myContentsLabel = LanguageHelper.objectToText(settings?.acc?.myContentsName);
        navigationData.main = HeaderService.filterNavItems(navigationData.main, item => {
          if (!gamificationEnabled && (item.special === NavItemSpecial.gamification)) {
            return false;
          }

          if ( item.hideForIlearn === NavItemHideForIlearn.apply ) {
            if (permissions.hideForILearn) {
              return false;
            }
          }
          if ( myContentsLabel && (item.special === NavItemSpecial.myTrainings) ) {
            item.title = myContentsLabel;
          }
          return (item.special !== NavItemSpecial.login);
        });
        return navigationData;
      }));
  }

  private postProcessItem(
    navItem: NavItem,
    permissions: PermissionStates,
  ): NavItem | null {

    if ( (navItem == null) || (navItem.checkPermissions && !permissions[navItem.checkPermissions]) ) {
      return null;
    }

    if ( navItem.special === NavItemSpecial.adminOfflineCalendar ) {
      const date = DateHelper.format(new Date(), 'YYYY-MM-DD');
      navItem.routerLink = navItem.routerLink
        .replace(/\{date}/gi, date);
    }

    if ( navItem.url?.includes('{classicApi}') ) {
      navItem.url = navItem.url
        .replace(/\{classicApi}/gi, ApiUrls.classicApi);
    }

    navItem.routerLinkActiveOptions ??= { exact: false };

    if ( navItem.special === NavItemSpecial.language ) {
      // no need to check children of language menus
      return navItem;
    }

    if ( (navItem.type === NavItemType.menu) || (navItem.type === NavItemType.menuIcon) ) {
      const menuItem = navItem as NavItemMenu;
      const children = HeaderService.filterNavItems(menuItem.children,
        child => this.postProcessItem(child, permissions) != null);
      if ( !(children?.length > 0) && (navItem.special !== NavItemSpecial.profileLinkedUser) ) {
        return null;
      }
      menuItem.children = children;
    }

    return navItem;
  }

}
