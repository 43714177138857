/**
 * Creates a copy of the input data without any duplicate values.
 */
export const uniq = <T>(data: T[] | null): T[] => {

  if ( !(data?.length > 0) ) {
    // return an empty array
    return [];
  }

  return data.reduce((pV, entry) => {

    if ( pV.includes(entry) ) {
      // skip duplicates
      return pV;
    }

    // add entry to collection
    pV.push(entry);

    return pV;
  }, []);
};
