import { PlatformLocation } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, NgZone } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationExtras, Router, UrlTree } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LanguageHelper } from '../language.helper';
import { State } from '../../app.state';

export const DEFAULT_LANGUAGE = 'en';

export class RouterLinkData {
  params = {};
  url = '';
}

export class PathInfo {
  baseHref = '';
  language = DEFAULT_LANGUAGE;
  languagePath = LanguageHelper.LANGUAGE_PATHS[DEFAULT_LANGUAGE];
}

declare interface LocationInfo {
  baseHref: string;
  language: string;
  languagePath: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  private baseHref: LocationInfo;
  private currentLocation: string;
  private lastLocation: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private ngZone: NgZone,
    private platformLocation: PlatformLocation,
    private router: Router,
  ) {
    this.subscribeLocationChange();
  }

  static getLanguageFromLocale(locale: string): string {
    return (locale || DEFAULT_LANGUAGE).substr(0, 2);
  }

  static getLanguagePathFromLocale(locale: string): string {
    const lang = NavigationService.getLanguageFromLocale(locale);
    const paths = LanguageHelper.LANGUAGE_PATHS;
    const path = paths[lang];
    if ( path ) {
      return path;
    }
    return paths[DEFAULT_LANGUAGE];
  }

  static parseBaseHrefFromUrl(url: string): LocationInfo {
    let baseHref = url + '/';

    // remove all duplicate slashes
    baseHref = baseHref.replace(/[/]+/g, '/');

    let language = '';
    let languagePath = '';

    Object.entries(LanguageHelper.LANGUAGE_PATHS)
      .find(([ lang, path ]) => {
        const PATH_MATCHER = new RegExp(path + '/$', 'i');
        if ( PATH_MATCHER.test(baseHref) ) {
          // baseHref ends with "{path}/"

          // store language name, and path
          language = lang;
          languagePath = path;

          // remove language specific part from baseHref
          baseHref = baseHref.replace(PATH_MATCHER, '');

          // abort iteration by simulating a match
          return true;
        }
      });

    if ( baseHref.endsWith('/') ) {
      baseHref = baseHref.substr(0, baseHref.length - 1);
    }

    if ( !language ) {
      // at least make sure the language is defined
      language = State.language;
    }

    return { baseHref, language, languagePath };
  }

  getBackUrl(fallbackUrl?: string): string {
    if ( typeof (fallbackUrl) !== 'string' ) {
      fallbackUrl = null;
    }
    if ( !this.lastLocation || (this.lastLocation === this.currentLocation) ) {
      return fallbackUrl || '/';
    }
    return this.lastLocation;
  }

  getCompleteUrlForLocale(locale: string, path?: PathInfo): string {
    const languagePath = NavigationService.getLanguagePathFromLocale(locale);
    let search = this.platformLocation.search;
    if ( search ) {
      search += '&_=';
    } else {
      search = '?_=';
    }
    search += (new Date().getTime());

    const baseUrl = this.urlForLanguage(languagePath, path);
    return baseUrl + search + (this.platformLocation.hash || '');
  }

  getCurrentLanguage(): PathInfo {
    const result = new PathInfo();

    const { baseHref, language, languagePath } = this.getBaseHref();
    if ( language && languagePath ) {
      result.language = language;
      result.languagePath = languagePath;
    }

    const PATH_MATCHER = new RegExp(languagePath + '/$', 'i');
    if ( PATH_MATCHER.test(baseHref) ) {
      result.baseHref = baseHref.replace(PATH_MATCHER, '');
    } else {
      result.baseHref = baseHref;
    }

    return result;
  }

  getCurrentUrl(fallbackUrl?: string): string {
    if ( typeof (fallbackUrl) !== 'string' ) {
      fallbackUrl = null;
    }
    return this.currentLocation || fallbackUrl || '/';
  }

  getPathInfoForLocale(locale: string): PathInfo {
    const result = new PathInfo();

    const currentBaseHref = this.getBaseHref();
    result.baseHref = currentBaseHref.baseHref;

    const language = LanguageHelper.getLanguage(locale) ?? LanguageHelper.getCurrentLanguage();
    const languagePath = LanguageHelper.LANGUAGE_PATHS[language.key];

    if ( language && languagePath ) {
      // we could find a valid language -> store in result
      result.language = language.key;
      result.languagePath = languagePath;
    } else {

      // somehow we did not find a valid language? -> keep current settings
      result.language = currentBaseHref.language;
      result.languagePath = currentBaseHref.languagePath;
    }

    return result;
  }

  navigate(url: any[], extras?: NavigationExtras): void {
    this.ngZone.run(() => {
      this.router.navigate(url, extras).then();
    });
  }

  navigateBack = (fallbackUrl?: string): void => {
    const url = this.getBackUrl(fallbackUrl);
    this.navigateWithReplace(url);
  };

  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): void {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url, extras).then();
    });
  }

  navigateCurrent = (fallbackUrl?: string): void => {
    const url = this.getCurrentUrl(fallbackUrl);
    this.navigateWithReplace(url);
  };

  navigateWithReplace(url: string): void {
    this.navigateByUrl(url, { replaceUrl: true });
  }

  parseForRouterLink(url: string): RouterLinkData {
    const result = new RouterLinkData();
    if ( !(url.length > 0) ) {
      return result;
    }

    const urlTree = this.router.parseUrl(url);
    Object.entries(urlTree.queryParams ?? {})
      .forEach(([ key, value ]) => {
        result.params[key] = value;
      });
    urlTree.queryParams = {};
    result.url = urlTree.toString();
    return result;
  }

  switchToLocale(locale: string): void {
    const path = this.getPathInfoForLocale(locale);
    const shouldSwitch = path.language !== State.language;

    const { languagePath } = this.getBaseHref();
    if ( !languagePath ) {
      if (shouldSwitch) {
        console?.warn('would switch language from', State.language, 'to', path);
      }
      // ignore baseHref without language
      return;
    }

    if ( shouldSwitch ) {
      console?.warn('switching to different language from', State.language, 'to', path);
      window.location.href = this.getCompleteUrlForLocale(locale, path);
    }
  }

  /**
   * Creates new absolute url for specified language modifying the baseHref
   * @param languagePath the language for which the url gets created
   * @param path (optional) PathInfo object to use as base
   */
  urlForLanguage(languagePath: string, path?: PathInfo): string {
    if ( !path ) {
      path = this.getCurrentLanguage();
    }
    return path.baseHref + languagePath + '/';
  }

  private checkCancelRedirect(event: NavigationCancel): void {
    if ( (this.lastLocation == null) && (event.reason === '') ) {
      // cannot navigate back and have no explicit redirect
      setTimeout(() => this.router.navigate([]).then());
    }
  }

  private getBaseHref(): LocationInfo {
    if ( !this.baseHref ) {
      const baseHref = window.location.pathname;
      this.baseHref = NavigationService.parseBaseHrefFromUrl(baseHref);
    }
    return this.baseHref;
  }

  private subscribeLocationChange(): void {
    this.router.events
      .pipe(tap(e => {
        if ( e instanceof NavigationEnd ) {
          this.updateLastLocation(e);
        } else if ( e instanceof NavigationCancel ) {
          this.checkCancelRedirect(e);
        }
      }))
      .subscribe();
  }

  private updateLastLocation(event: NavigationEnd): void {
    const url = event.url;
    if ( url !== this.currentLocation ) {
      this.lastLocation = this.currentLocation;
      this.currentLocation = url;
    }
  }

}
