<div class="filter-box">
  <div class="input-wrapper">
    <mat-form-field appearance="outline">
      <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>

      <mat-select
        [(ngModel)]="filter.value"
        (ngModelChange)="onChange($event)"
        [placeholder]="placeholderText"
        #matSelect
      >
        <mat-option [value]="null">
          <span i18n="@@all">All</span>
        </mat-option>

        <mat-option
          *ngFor="let dropdownOption of dropdownOptions"
          [value]="dropdownOption.value"
          #eltOption
        >
          {{dropdownOption.label}}

          <ng-container
            *ngIf="fixMatOptionHighlight(eltOption)"
          ></ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
