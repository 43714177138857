<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@change_notes_title">Change notes</span>
    <span class="version" *ngIf="versionString"> ({{versionString}})</span>
  </div>
  <div class="page-header-buttons">
    <rag-change-notes-toc
      *ngIf="headlines?.length > 0"
      [headlines]="headlines"
    ></rag-change-notes-toc>
  </div>
</rag-page-header>

<markdown
  [ngClass]="{block: hasData}"
  (load)="onLoad()"
  (error)="onError()"
  [src]="documentationUrl"
></markdown>

<ng-container
  *ngIf="hasError"
>
  <span i18n="@@change_notes_failed_to_load">Unfortunately there are no documented changes for this version.</span>
</ng-container>
