import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { Quests } from "src/app/core/quest/quest.types";

@Component({
  selector: 'rag-base-quest-fragment',
  template: '',
  styles: []
})
export abstract class BaseQuestFragmentComponent {
  @Input() form: FormGroup;
  @Input() fragment: Quests.Fragment;
  @Input() conditions: Map<string, Observable<boolean>>;

  hasCaption() {
    return this.fragment.caption !== undefined;
  }

  conditionIdIfAny(element: Quests.ConditionRelated) {
    if (element.condition !== undefined) {
      return element.condition;
    }
    return null;
  }

  isReadonly(elementId: string) {
    return this.form.get(elementId)?.disabled;
  }
}