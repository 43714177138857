<button
  type="button"
  mat-flat-button
  [disabled]="multiActionsDisabled()"
  [matMenuTriggerFor]="headerActionsMenu"
>
  <mat-icon svgIcon="menu" class="icon-fix"></mat-icon>

  <mat-menu #headerActionsMenu>

      <a
        *ngIf="maySaveUser"
        mat-menu-item
        [routerLink]="editUserUrl"
      >
        <mat-icon svgIcon="account-outline"></mat-icon>
        <span i18n="@@header_nav_administrator">Administration</span>
      </a>

        <button
      type="button"
          mat-menu-item
          (click)="controllingComponent.onLearningDataBatch()"
          [disabled]="!controllingComponent.mayManageLearningStatus() || !controllingComponent.hasSelected"
        >
          <mat-icon svgIcon="list-status"></mat-icon>
          <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
        </button>

        <button
      type="button"
          mat-menu-item
          (click)="controllingComponent.onResetCourses()"
          [disabled]="!controllingComponent.permissions.ctrlCourseReset || !controllingComponent.hasSelected"
        >
          <mat-icon svgIcon="undo-variant"></mat-icon>
          <span i18n="@@global_reset">Reset</span>
        </button>

  </mat-menu>
</button>
