import { Component, Input, OnInit } from '@angular/core';
import { Core, ImageableContentReference } from 'src/app/core/core.types';

@Component({
  selector: 'rag-item-type-icon',
  templateUrl: './item-type-icon.component.html',
  styleUrls: [ './item-type-icon.component.scss' ],
})
export class ItemTypeIconComponent
  implements OnInit {
  @Input() content: ImageableContentReference;

  constructor() {
  }

  ngOnInit() {
  }

  isVirco(content: ImageableContentReference) {
    return content.objSubType === Core.CourseType.Virco;
  }

  isSeminar(content: ImageableContentReference) {
    return content.objSubType === Core.CourseType.Seminar;
  }

  isHybrid(content: ImageableContentReference) {
    return content.objSubType === Core.CourseType.Hybrid;
  }
}
