import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { ApiUrls } from '../../../core/api.urls';
import { AnyObject } from '../../../core/core.types';
import { ApiResponse } from '../../../core/global.types';
import { LanguageHelper } from '../../../core/language.helper';
import { ColumnSettings } from '../../../core/report/report.types';
import { ViewHelper } from '../../../core/view-helper';
import { TableColumnMenu } from './table-column-menu.types';
import { naturalCompare } from '../../../core/natural-sort';
import { MergeHelper } from '../../../core/primitives/merge.helper';
import { Sort } from '@angular/material/sort';
import { TableColumnDataType } from '../table-column.types';
import { TableColumnBuilder } from '../table-column.builder';


@Injectable({
  providedIn: 'root',
})
export class TableColumnMenuService {

  readonly resetToDefaultSettings$: Observable<void>;
  readonly sortChanged$: Observable<Sort>;
  readonly filterChanged$: Observable<void>;
  private _resetToDefaultSettings$ = new Subject<void>();
  private _sortChanged$ = new Subject<Sort>();
  private _filterChanged$ = new Subject<void>();

  constructor(
    private http: HttpClient,
  ) {
    this.filterChanged$ = this._filterChanged$.asObservable();
    this.resetToDefaultSettings$ = this._resetToDefaultSettings$.asObservable();
    this.sortChanged$ = this._sortChanged$.asObservable()
      .pipe(distinctUntilChanged((a, b) => (a?.active === b?.active) && (a.direction === b.direction)));
  }

  static getLanguageDropdownOptions(): AnyObject<string> {
    return LanguageHelper.LANGUAGES
      .map(o => o.trainLanguage)
      .reduce((pV, languageKey) => {
        let label: string;
        switch ( languageKey ) {
          case 'de_DE':
            label = $localize`:@@global_language_german:German`;
            break;
          case 'en_GB':
            label = $localize`:@@global_language_english:English`;
            break;
          case 'fr_FR':
            label = $localize`:@@global_language_french:French`;
            break;
          case 'pl_PL':
            label = $localize`:@@global_language_polish:Polish`;
            break;
        }

        if ( label != null ) {
          pV[languageKey] = label;
        }
        return pV;
      }, {});
  }

  static addDynamicDropDownOptions(
    menuData: TableColumnMenu.MenuData,
    columnId: TableColumnMenu.MenuItemId,
    dropDownOptions: AnyObject<string>,
  ): void {

    const menuItemOptions = menuData.menuItems[columnId]?.options;
    if ( menuItemOptions == null ) {
      return;
    }

    menuItemOptions.dropDownOptions = MergeHelper.cloneDeep(dropDownOptions);
    menuItemOptions.dropDownOptionsOriginal = dropDownOptions;

    const menuItemDefaultsOptions = menuData.menuItemDefaults[columnId]?.options;
    if ( menuItemDefaultsOptions == null ) {
      return;
    }

    menuItemDefaultsOptions.dropDownOptions = MergeHelper.cloneDeep(dropDownOptions);
    menuItemDefaultsOptions.dropDownOptionsOriginal = MergeHelper.cloneDeep(dropDownOptions);
  }

  static applyStoredSettings(menuData: TableColumnMenu.MenuData, storedItems: TableColumnMenu.MenuItemMap): void {
    const menuItems = { ...menuData.menuItems };

    const startWith = menuData.startWith.map(id => this.extractItem(menuItems, id));
    const endWith = menuData.endWith.map(id => this.extractItem(menuItems, id));

    const fromStoredItems = Object.values(storedItems)
      .sort(TableColumnMenuService.compareMenuItemOrderIndex)
      .map(storedItem => {
        const item = this.extractItem(menuItems, storedItem.id);
        if ( (item != null) && !item.hidden && !item.disabled ) {
          item.selected = storedItem.selected;
          const sortActive = item.sortActive = (storedItem.sortActive === true);
          item.sortDirection = sortActive ? storedItem.sortDirection : '';
        }
        return item;
      });
    const remainingItems = Object.values(menuItems)
      .sort(TableColumnMenuService.compareMenuItemOrderIndex);
    const result = [
      ...startWith,
      ...fromStoredItems,
      ...remainingItems,
      ...endWith,
    ].filter(item => item != null);
    TableColumnMenuService.updateOrderIndex(result);
  }

  static buildSettingsUrl(context: string, key: string) {
    context = context.replace('@columnSettings', '');
    const contextEncoded = encodeURIComponent(context);
    return `${ApiUrls.getKey(key)}/${contextEncoded}`;
  }

  static compareMenuItemOrderIndex = (a, b) => a.orderIndex - b.orderIndex;

  /**
   * Creates a deep clone of the input and adds localized translations to MenuItem.$view.title
   */
  static createFromDefaults<T extends TableColumnMenu.MenuData = TableColumnMenu.MenuData>(
    menuData: T, serverColumns?: ColumnSettings[], keepAllRemoteColumns: boolean = false,
  ): T {
    const result = MergeHelper.cloneDeep(menuData);
    // create copy with default selections
    result.menuItemDefaults = MergeHelper.cloneDeep(result.menuItems);
    if ( serverColumns?.length > 0 ) {
      TableColumnMenuService.mergeFromServerDefaults(result, serverColumns, keepAllRemoteColumns);
    }
    TableColumnMenuService.translateTitles(result);
    TableColumnMenuService.sortByTitle(result, result.menuItems);
    TableColumnMenuService.sortByTitle(result, result.menuItemDefaults);
    return result;
  }

  static findChangeableItems(menuData: TableColumnMenu.MenuData): TableColumnMenu.MenuItem[] {
    return Object.values(menuData.menuItems)
      .filter(item => !item.hidden)
      .sort(TableColumnMenuService.compareMenuItemOrderIndex);
  }

  /**
   * Compares two input arrays. Identical contents will return the previous array instance.
   */
  static getCheckedColumns(currentColumns: string[], newColumns: string[]) {
    if ( MergeHelper.equalsArray(currentColumns, newColumns) ) {
      return currentColumns;
    }
    return newColumns;
  }

  static menuDataToColumns<T extends TableColumnMenu.MenuData = TableColumnMenu.MenuData>(menuData: T): TableColumnMenu.MenuItemId[] {
    const menuItems = { ...menuData.menuItems };
    // remove items with known positions
    menuData.startWith.forEach(id => delete menuItems[id]);
    menuData.endWith.forEach(id => delete menuItems[id]);

    return [
      ...menuData.startWith,
      ...TableColumnMenuService.menuItemsAsArray(menuItems)
        .map(item => item.id),
      ...menuData.endWith,
    ].filter(id => {
      if (menuData.menuItems[id] == null) {
        console?.error(`Menu item with id "${id}" is undefined`);
      }
      return menuData.menuItems[id].selected;
    });
  }

  static menuItemsAsArray(menuItems: TableColumnMenu.MenuItemMap): TableColumnMenu.MenuItem[] {
    return Object.values(menuItems)
      .sort(TableColumnMenuService.compareMenuItemOrderIndex);
  }

  /**
   * This method will replace / merge all columns that are not defined as startWith and endWith.
   * The method also updates the orderIndex.
   * The method removes all columns that are not defined both in menuData.menuItems and serverColumns.
   */
  static mergeFromServerDefaults(
    menuData: TableColumnMenu.MenuData,
    serverColumns: ColumnSettings[] = [],
    keepAllRemoteColumns: boolean,
  ) {
    const defaultsMap = menuData.menuItemDefaults;
    const menuItemsMap = menuData.menuItems;
    const preservedColumns = Object.values(menuItemsMap)
      .filter(o => o.preserve)
      .map(o => o.id);
    const orderedColumnIds = serverColumns.map(column => column.id)
      // ignore unknown columns
      .filter(column => keepAllRemoteColumns || menuItemsMap[column] != null);
    const startWith = menuData.startWith ?? [];
    const endWith = menuData.endWith ?? [];

    // insert preserved columns at the start (cannot properly place them otherwise)
    orderedColumnIds.splice(0, 0, ...preservedColumns);

    // remove all unmapped entries (e.g. user columns not available to the principal)
    Object.keys(menuItemsMap)
      .filter(column => !(orderedColumnIds.includes(column) || startWith.includes(column) || endWith.includes(column)))
      .forEach(column => {
        delete menuItemsMap[column];
        delete defaultsMap[column];
      });

    const serverColumnsMap = serverColumns.reduce((pV, column) => {
      pV[column.id] = column;
      return pV;
    }, {});

    let index = 0;
    let hasAnySelected = false;
    const processedColumns: string[] = [];
    const fnMergeColumn = (column: string): void => {
      if ( processedColumns.includes(column) ) {
        // prevent duplicate columns
        return;
      }
      processedColumns.push(column);

      if ( keepAllRemoteColumns && (menuItemsMap[column] == null) ) {
        // create placeholder for unknown items
        menuItemsMap[column] = {
          id: column,
          selected: false,
        };
        defaultsMap[column] = {
          id: column,
          selected: false,
        };
      }

      if ( menuItemsMap[column] != null ) {
        TableColumnMenuService.mergeFromServerDefaultsMerge(index++, menuItemsMap[column], serverColumnsMap[column]);
        defaultsMap[column].title = menuItemsMap[column].title;
        if ( defaultsMap[column].options != null ) {
          defaultsMap[column].options.dataType = menuItemsMap[column].options?.dataType ?? TableColumnDataType.text;
        }
      }
    };
    startWith.forEach(fnMergeColumn);
    orderedColumnIds.forEach(column => {
      fnMergeColumn(column);
      if ( menuItemsMap[column]?.selected ) {
        hasAnySelected = true;
      }
    });
    endWith.forEach(fnMergeColumn);

    if ( !hasAnySelected && (orderedColumnIds.length > 0) ) {
      // no columns are selected -> select first non-default column
      const id = orderedColumnIds[0];

      if ( menuItemsMap[id] != null ) {
        menuItemsMap[id].selected = true;
        defaultsMap[id].selected = true;
      }
    }
  }

  static mergeFromServerDefaultsMergeOptions(
    menuItem: TableColumnMenu.MenuItem,
    dataType: TableColumnDataType,
    dropDownOptions?: AnyObject<string>,
  ): void {

    const builder = TableColumnBuilder.start(menuItem, false);
    if ( dataType != null ) {
      builder.withType(dataType);
    }

    if ( dropDownOptions != null ) {
      builder.withDropDownOptions(dropDownOptions);
    }
  }

  static moveItem(menuData: TableColumnMenu.MenuData, menuItem: TableColumnMenu.MenuItem, targetItem: TableColumnMenu.MenuItem): void {
    const fromIndex = menuItem.orderIndex;
    const toIndex = targetItem.orderIndex;
    const menuItems = TableColumnMenuService.menuItemsAsArray(menuData.menuItems);
    moveItemInArray(menuItems, fromIndex, toIndex);
    TableColumnMenuService.updateOrderIndex(menuItems);
  }

  static sortByTitle(menuData: TableColumnMenu.MenuData, menuItems: TableColumnMenu.MenuItemMap): void {
    const startWith = (menuData.startWith || []);
    const endWith = (menuData.endWith || []);
    const selectedColumns: TableColumnMenu.MenuItem[] = [];
    const availableColumns: TableColumnMenu.MenuItem[] = [];
    const alphabeticalColumns: TableColumnMenu.MenuItem[] = [];
    const unavailableColumns: TableColumnMenu.MenuItem[] = [];
    Object.values(menuItems ?? {})
      .forEach(column => {
        const columnId = column.id;
        if ( startWith.includes(columnId) || endWith.includes(columnId) ) {
          // skip columns with fixed positions
          return;
        } else if ( column.hidden || column.disabled ) {
          unavailableColumns.push(column);
        } else if ( column.selected ) {
          selectedColumns.push(column);
        } else if ( column.sortByTitle !== false ) {
          alphabeticalColumns.push(column);
        } else {
          availableColumns.push(column);
        }
      });

    this.ensureTwoSelectedColumns(selectedColumns, availableColumns, alphabeticalColumns);

    let index = 0;
    [
      ...startWith.map(columnId => menuItems[columnId]),
      ...selectedColumns.sort(TableColumnMenuService.compareMenuItemOrderIndex),
      ...availableColumns.sort(TableColumnMenuService.compareMenuItemOrderIndex),
      ...alphabeticalColumns.sort((a, b) => {
        const aTitle = a.title = LanguageHelper.objectToText(a.title);
        const bTitle = b.title = LanguageHelper.objectToText(b.title);
        return naturalCompare(aTitle, bTitle);
      }),
      ...endWith.map(columnId => menuItems[columnId]),
      ...unavailableColumns,
    ]
      .filter(o => o != null)
      // update orderIndex to match
      .forEach(column => column.orderIndex = index++);
  }

  static toggleItem(menuItem: TableColumnMenu.MenuItem, active: boolean): void {
    if ( active ) {
      // maybe leave selected untouched?
      menuItem.selected = true;
      menuItem.hidden = false;
    } else {
      menuItem.selected = false;
      menuItem.hidden = true;
    }
  }

  static translateTitles(menuData: TableColumnMenu.MenuData): void {
    Object.values(menuData.menuItems)
      .forEach(item => {
        const title = LanguageHelper.translate(item.title);
        const viewData = ViewHelper.getViewData(item);
        viewData.title = title || '';
      });
  }

  static updateOrderIndex(sortedItems: TableColumnMenu.MenuItem[]): void {
    sortedItems.forEach((item, i) => item.orderIndex = i);
  }

  private static ensureTwoSelectedColumns(selectedColumns: TableColumnMenu.MenuItem[],
    availableColumns: TableColumnMenu.MenuItem[], alphabeticalColumns: TableColumnMenu.MenuItem[]) {
    if ( selectedColumns.length < 2 ) {
      selectedColumns.push(...availableColumns.splice(0, 2));
    }
    if ( selectedColumns.length < 2 ) {
      selectedColumns.push(...alphabeticalColumns.splice(0, 2));
    }
    selectedColumns.forEach(column => column.selected = true);
  }

  private static extractItem(menuItems: TableColumnMenu.MenuItemMap, id: string) {
    const item = menuItems[id];
    if ( item == null ) {
      // items may already have been removed!
      return null;
    }
    delete menuItems[id];
    return item;
  }

  private static mergeFromServerDefaultsMerge(
    index: number,
    menuItem: TableColumnMenu.MenuItem,
    settings: ColumnSettings | null,
  ): void {

    menuItem.orderIndex = index;

    if ( settings == null ) {
      // e.g. a preserved column without server settings -> skip remaining code
      return;
    }

    menuItem.selected = settings.selected ?? menuItem.selected;
    menuItem.title = settings.title ?? menuItem.title;
    menuItem.sortActive = settings.sortActive ?? menuItem.sortActive;
    menuItem.sortDirection = settings.sortDirection ?? menuItem.sortDirection;

    if ( settings.systemField !== true ) {
      // do not replace type and options of system fields
      TableColumnMenuService
        .mergeFromServerDefaultsMergeOptions(menuItem, settings.dataType, settings.dropDownOptions);
    }
  }

  getUsersColumnSettings(context: string): Observable<TableColumnMenu.MenuItemMap> {
    if ( !context ) {
      return of({});
    }

    const url = TableColumnMenuService.buildSettingsUrl(context, 'UserColumnSettings');
    return this.http.get<ApiResponse<string>>(url)
      .pipe(map(response => response?.userSettings || response?.accountSettings || '{}'))
      .pipe(map(json => JSON.parse(json)))
      .pipe(catchError(err => {
        if ( console && console.warn ) {
          console.warn('TableColumnMenuService.getUsersColumnSettings', err);
        }
        return of({});
      }));
  }

  resetToDefaults<T extends TableColumnMenu.MenuData = TableColumnMenu.MenuData>(menuData: T): void {
    if ( menuData.menuItemDefaults == null ) {
      // need default values to reset to
      throw Error('defaults-missing');
    }

    // THIS LINE BREAKS THE WORK OF FILTERS !!! DO NOT UNCOMMENT
    // the menuItems map cannot be replaced. This brakes the normal operation of filters.
    // menuData.menuItems = ViewHelper.cloneDeep(menuData.menuItemDefaults);

    Object.keys(menuData.menuItemDefaults).forEach(key => {
      const menuItem = menuData.menuItems[key];
      if ( menuItem == null ) {
        menuData.menuItems[key] = { ...menuData.menuItemDefaults[key] };
      } else {
        const defaultMenuitem = menuData.menuItemDefaults[key];
        menuItem.disabled = defaultMenuitem.disabled;
        menuItem.hasFilter = defaultMenuitem.hasFilter;
        menuItem.hidden = defaultMenuitem.hidden;
        menuItem.options = MergeHelper.cloneDeep(defaultMenuitem.options);
        menuItem.orderIndex = defaultMenuitem.orderIndex;
        menuItem.selected = defaultMenuitem.selected;
        menuItem.sortByTitle = defaultMenuitem.sortByTitle;
        menuItem.title = defaultMenuitem.title;
      }
    });

    TableColumnMenuService.translateTitles(menuData);

    this._resetToDefaultSettings$.next();
  }

  nextDataSourceFilter(): void {
    this._filterChanged$.next();
  }

  resetUsersColumnSettings(context: string): Observable<TableColumnMenu.MenuItemMap> {
    if ( !context ) {
      return of({});
    }

    const url = TableColumnMenuService.buildSettingsUrl(context, 'UserColumnSettings');
    return this.http.delete<ApiResponse<string>>(url)
      .pipe(map(response => response?.userSettings || response?.accountSettings || '{}'))
      .pipe(map(json => JSON.parse(json)))
      .pipe(catchError(err => {
        if ( console && console.warn ) {
          console.warn('TableColumnMenuService.resetUsersColumnSettings', err);
        }
        return of({});
      }));
  }

  setAccountsColumnSettings(
    context: string,
    menuData: TableColumnMenu.MenuData,
  ): Observable<TableColumnMenu.MenuItemMap> {

    if ( !context ) {
      return of({});
    }

    const url = TableColumnMenuService.buildSettingsUrl(context, 'AccountColumnSettings');
    const contextData = this.buildContextData(menuData);
    return this.http.post<ApiResponse<string>>(url, contextData)
      .pipe(map(() => contextData))
      .pipe(catchError(err => {
        if ( console && console.warn ) {
          console.warn('TableColumnMenuService.setAccountsColumnSettings', err);
        }
        return EMPTY;
      }));
  }

  setUsersColumnSettings(context: string, menuData: TableColumnMenu.MenuData): Observable<TableColumnMenu.MenuItemMap> {
    if ( !context ) {
      return EMPTY;
    }

    const contextData = this.buildContextData(menuData);

    const url = TableColumnMenuService.buildSettingsUrl(context, 'UserColumnSettings');
    return this.http.post<ApiResponse<string>>(url, contextData)
      .pipe(map(() => contextData))
      .pipe(catchError(err => {
        if ( console && console.warn ) {
          console.warn('TableColumnMenuService.setContext', err);
        }
        return EMPTY;
      }));
  }

  triggerSortChanged(sort: Sort): void {
    this._sortChanged$.next(sort);
  }

  private buildContextData(menuData: TableColumnMenu.MenuData) {
    const contextData: TableColumnMenu.MenuItemMap =
      Object.values(menuData.menuItems)
        .map(item => ({
          id: item.id,
          orderIndex: item.orderIndex,
          selected: item.selected,
          sortActive: item.sortActive,
          sortDirection: item.sortDirection,
        }))
        .reduce((result, item) => {
          result[item.id] = item;
          return result;
        }, {});
    return contextData;
  }

}
