import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfoService } from '../../../../../core/info/info.service';
import { AdminCoursesTypes } from '../../../../../core/admin-courses.types';
import CourseExtension = AdminCoursesTypes.CourseExtension;
import { EMPTY, Observable } from 'rxjs';
import { ApiResponse } from '../../../../../core/global.types';
import { ApiUrls } from '../../../../../core/api.urls';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { catchError } from 'rxjs/operators';
import { InfoType, MessageKey } from '../../../../../core/info/info.types';
import { CommitResponse } from './learner-course-self-estimation.types';


@Injectable({ providedIn: 'root' })
export class LearnerCourseSelfEstimationService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  saveExtension(commitment: any, courseId: number, extType: AdminCoursesTypes.Extensions): Observable<CommitResponse> {
    const url = ApiUrls.getKey('LearnerCourseSaveExtension')
      .replace(/{courseId}/, String(courseId))
      .replace(/{extensionType}/, String(extType));

    return this.http.post<CommitResponse>(url, JSON.stringify(commitment))
      .pipe(catchError(this.handleError));
  }

  private handleError = <T>(): Observable<never> => {
    this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
    return EMPTY;
  };
}
