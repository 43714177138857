import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { CatalogCardSchedulesPagerComponent } from './catalog-card-schedules-pager.component';


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    CatalogCardSchedulesPagerComponent
  ],
  exports: [
    CatalogCardSchedulesPagerComponent
  ],
})
export class CatalogCardSchedulesPagerModule {
}
