import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { ApplicationStateService } from '../../../core/application-state.service';
import { ScoComponent } from './sco.component';
import { ScormApi } from './scorm-api';

@Injectable({
  providedIn: 'root',
})
export class ScoGuardService
  implements CanDeactivate<ScoComponent> {

  constructor(
    private applicationStateService: ApplicationStateService,
  ) {
  }

  canDeactivate(component: ScoComponent, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    if ( component.scoRunning === undefined ) {
      // abort before sco started
      return false;
    }

    const scormApi = component.scormApi;
    if ( !scormApi ) {
      return true;
    }

    if ( component.scoRunning === false || !scormApi.sessionStarted ) {
      // some error while initializing
      this.applicationStateService.scoRunning = false;
      return this.handleFinishEvent(scormApi);
    }

    // here we don't need to warn before leaving as the SCORM communication should be able to complete as intended
    component.close();
    return this.handleFinishEvent(scormApi);
  }

  private handleFinish(scormApi: ScormApi): Observable<boolean> {
    if ( scormApi.sessionFinished ) {
      return of(true);
    } else {
      return scormApi.LMSFinishImpl().query
        .pipe(map(() => true));
    }
  }

  private handleFinishEvent(scormApi: ScormApi): Observable<boolean> {
    return of(null)
      // wait for scorm content to write last updates
      .pipe(delay(1000))
      // wait for empty queue
      .pipe(switchMap(() => scormApi.queueEmpty))
      // take only first and timeout after 15s
      .pipe(take(1), timeout(15000))
      // timeout as finish should have been written already
      .pipe(switchMap(() => this.handleFinish(scormApi)))
      // close stream after first finish
      .pipe(take(1))
      // wait for some processing on the server
      .pipe(delay(250))
      // flag state as not running
      .pipe(tap(() => this.applicationStateService.scoRunning = false))
      .pipe(map(() => true));
  }

}
