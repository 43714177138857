import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSignaturesDetailsComponent } from './admin-signatures-details.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RagCKEditorModule } from '../../../../core/input/rag-ckeditor/rag-ckeditor.module';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { TextMacrosComponent } from 'src/app/component/text-macros/text-macros.component';
import { FormSubmitButtonComponent } from '../../../../component/input/form-submit-button/form-submit-button.component';

@NgModule({
  declarations: [
    AdminSignaturesDetailsComponent
  ],
  imports: [
    CommonModule,
    TextMacrosComponent,
    PageHeaderComponent,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RagCKEditorModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    FormSubmitButtonComponent,
  ]
})
export class AdminSignaturesDetailsModule {
}
