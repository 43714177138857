import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '../../../../component/breadcrumbs/breadcrumbs.module';
import { ContentPageTitleModule } from '../../../../component/elearning/content-page-title/content-page-title.module';
import { CurriculumTitleModule } from '../../../../component/elearning/curriculum-title/curriculum-title.module';
import { ImportantContentInfoModule } from '../../../../component/elearning/important-content-info/important-content-info.module';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { ContentOfflineChooseComponent } from './content-offline-choose/content-offline-choose.component';
import { ContentOfflineComponent } from './content-offline.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { ContentOfflineInvitationModule } from 'src/app/component/content-offline-invitation/content-offline-invitation.module';
import { ContentOfflineViewComponent } from './content-offline-view/content-offline-view.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContentOfflineInfoDateComponent } from './content-offline-info-date/content-offline-info-date.component';
import { MyContentActionsModule } from 'src/app/component/content/components/my-content-actions/my-content-actions.module';
import { ContentOfflineInfoComponent } from 'src/app/component/content-offline-info/content-offline-info.component';
import { DateOffsetComponent } from 'src/app/component/date-offset/date-offset.component';
import { ContactCardComponent } from 'src/app/component/contact-card/contact-card.component';
import { OfflineContentClosedStatusComponent } from 'src/app/component/offline-content-closed-status/offline-content-closed-status.component';
import { FileRenderComponent } from 'src/app/component/file-render/file-render.component';
import { LocationComponent } from 'src/app/component/location/location.component';
import { GoogleMapComponent } from 'src/app/component/google-map/google-map.component';
import {PostToBlankComponent} from '../../../../component/post-to-blank/post-to-blank.component';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    ContentOfflineChooseComponent,
    ContentOfflineComponent,
    ContentOfflineViewComponent,
    ContentOfflineInfoDateComponent,
  ],
  exports: [
    ContentOfflineComponent,
    ContentOfflineChooseComponent,
    ContentOfflineViewComponent,
  ],
  imports: [
    BreadcrumbsModule,
    CommonModule,
    FileRenderComponent,
    DateOffsetComponent,
    ContentPageTitleModule,
    OfflineContentClosedStatusComponent,
    ContentOfflineInvitationModule,
    GoogleMapComponent,
    ContentOfflineInfoComponent,
    CurriculumTitleModule,
    FormsModule,
    ImportantContentInfoModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    LocationComponent,
    PipesModule,
    RouterModule,
    MatExpansionModule,
    ContactCardComponent,
    MyContentActionsModule,
    PostToBlankComponent,
    MatTooltipModule,
  ],
})
export class ContentOfflineModule {
}
