<ng-template #tplDetailsButton>
  <button
    (click)="showDetailsDialog()"
    *ngIf="showDetailsButton"
    mat-icon-button
  >
    <mat-icon
      svgIcon="information-outline"
    ></mat-icon>
  </button>
</ng-template>

<div
  class="flex-container"
>
  <span
    #sizingDetectionElt
    aria-hidden="true"
    class="sizing-detection"
  >Q</span>

  <div class="block">
    <div
      #contentElt
      [innerHTML]="contentHyphens | safe:'html'"
      [matTooltip]="tooltip"
    ></div>
  </div>

  <button
    *ngIf="!initialized; else tplDetailsButton"
    class="placeholder"
    mat-icon-button
  ></button>
</div>
