<button
  *ngIf="quest !== undefined && !hasFeedback"
  mat-flat-button
  color="primary"
  class="left-8"
  (click)="onLrnFeedback()">
  <span i18n="@@global_give_feedback">Give feedback</span>
</button>

<button
  *ngIf="hasFeedback"
  mat-stroked-button
  class="left-8"
  (click)="onLrnFeedback()">
  <span i18n="@@global_preview_feedback">Preview feedback</span>
</button>