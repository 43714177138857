/**
 * @see https://youmightnotneed.com/lodash
 */
export class Lodash {

  public static isNumber(value: any | null): boolean {
    return (typeof (value) === 'number') && !isNaN(value);
  }

}
