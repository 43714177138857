import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserCourseData } from './user-course.types';
import { ControllingSingleUserTypes } from '../../../../core/ctrl-single-user.types';
import { UserCourseService } from './user-course.service';
import { ColumnSettings } from '../../../../core/report/report.types';
import { LanguageHelper } from '../../../../core/language.helper';
import { map, take, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { UserNameHelper } from '../../../../core/user-name.helper';


@Component({
  selector: 'rag-user-course-details',
  templateUrl: './user-course-details.component.html',
  styleUrls: [ './user-course-details.component.scss' ],
})
export class UserCourseDetailsComponent
  implements OnDestroy, OnInit {

  courseId: number;
  courseTitle: string;
  userDetails: UserCourseData;
  userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;
  userFullName: string;
  userId: number;

  constructor(
    private route: ActivatedRoute,
    private userCourseService: UserCourseService,
  ) {
  }

  private static getUserDetailsView(
    course: ControllingSingleUserTypes.CourseAccountDetails | null,
    user: ControllingSingleUserTypes.ControllingUser | null,
    userColumns: ColumnSettings[],
  ): ControllingSingleUserTypes.UserDetailsResponse {
    return {
      userColumns,
      courses: [ course ].filter(obj => obj != null),
      certificates: [],
      curricula: [],
      controllingUser: user,
      offlineContents: [],
      contentActions: []
    };
  }

  ngOnDestroy(): void {
    // required for takeUntilDestroyed
  }

  ngOnInit() {
    this.route.data
      .pipe(map(data => data.userDetails))
      .pipe(tap(this.updateRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    this.userDetails = this.route.snapshot.data.userDetails;
  }

  reloadData(): void {
    this.userCourseService.getCourseForUser(this.userId, this.courseId)
      .pipe(tap(this.updateRouteData))
      .pipe(take(1))
      .subscribe();
  }

  private updateRouteData = (userDetails: UserCourseData): void => {
    this.userDetails = userDetails;

    const course = userDetails?.course;
    this.courseId = course?.courseId;
    this.courseTitle = LanguageHelper.objectToText(course?.title);

    const user = userDetails?.user;
    this.userId = user?.userId;
    this.userFullName = UserNameHelper.getFullName(user);

    this.userDetailsView = UserCourseDetailsComponent
      .getUserDetailsView(course, user, userDetails?.userColumns);
  };

}
