import { AnyObject, Core, FileInfo } from '../core.types';
import DistributableType = Core.DistributableType;
import { Translation } from '../translation/translation.types';
import { Buttons } from '../../route/admin/admin-user-import/admin-user-import-list/admin-user-import-list-actions/admin-user-import-list-actions.component';
import { DialogButton } from '../info/info.types';

export interface GamificationProfileResponse {
  gamificationProfile: GamificationProfile;
}

export interface GamificationProfile {
  xp: number;
  level: number;
  counts: BadgeCount[];
}

export interface BadgeCount {
  count: number;
  objectType: DistributableType;
  userId: number;
}

export enum GamificationLevel {
  Level0 = 10,
  Level1 = 10,
  Level2 = 15,
  Level3 = 25,
  Level4 = 25,
  Level5 = 25,
  Level6 = 50,
  Level7 = 50,
  Level8 = 75,
  Level9 = 75,
  Level10 = 100,
  Level11 = 100,
  Level12 = 125,
  Level13 = 125,
  Level14 = 150,
  Level15 = 150,
}

export interface Badge {
  userId: number;
  badgeUUID: string;
  contentConfig: BadgeContentConfig;
  whenAcquired: number;
  info: AnyObject;
}

export interface BadgeInfo {
  description: Translation;
  title: Translation;
  contentTitle: Translation;
}

export interface ReducedBadge {
  objectType: DistributableType;
  info: BadgeInfo;
  objectId: number;
  pictureId: number;
  pictureUUID: string;
  badgeUUID: string;
  whenAcquired: number;
}

export interface BadgeTemplate {
  accountId: number;
  templateUUID: string;
  pictureId: number;
  pictureUUID: string;
  adminTitle: string;
  adminDescription: string;
  picture?: FileInfo;
  contents: BadgeContentConfig[];
}

export interface BadgeTemplateDetails {
  badgeTemplate: BadgeTemplate;
  usages: BadgeTemplateUsages[]
}

export interface BadgeTemplateUsages {
  targetTitle: string;
  targetId: number;
  targetType: DistributableType;
}

export interface BadgeContentConfigDialogResult {
  button: DialogButton;
}

export class BadgeContentConfig {
  badgeConfigUUID: string;
  objectId: number;
  objectType: DistributableType;
  template: BadgeTemplate;
  title: Translation;
  description: Translation;
  templateUUID: string;
}

export interface BadgeContentConfigDialogData {
  badgeTemplates: BadgeTemplate[];
  currentContentConfig: BadgeContentConfig;
  objectId: number;
  objectType: DistributableType;
}

export class BadgeContentConfigSaveData {
  objectId: number;
  objectType: DistributableType;
  templateUUID: string;
  title: Translation;
  description: Translation;
}

export class BadgeTemplateSaveData {
  pictureId: number;
  adminTitle: string;
  adminDescription: string;
  badgeTemplateUUID: string;
}

export interface LearnerGamificationOverviewData {
  gamificationProfile: GamificationProfile;
  badges: ReducedBadge[];
}

export interface LearnerGamificationResponse {
  gamificationData: LearnerGamificationOverviewData;
}

export interface GamificationSettings {
  enabled: boolean;
}

export interface AdminGamificationSettings {
  enabled: boolean;
}

export interface AdminGamificationSettingsSaveData {
  enabled: boolean;
}
