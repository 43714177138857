import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { ModalDialog } from '../../../../../core/modal-dialog';
import { CtrlSingleUserDetailsCurriculumEditComponent } from './ctrl-single-user-details-curriculum-edit.component';
import { map, takeWhile } from 'rxjs/operators';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { CtrlSingleUserDetailsCurriculumEditTypes } from './ctrl-single-user-details-curriculum-edit.types';
import { InfoService } from '../../../../../core/info/info.service';
import { HttpClient } from '@angular/common/http';
import { InfoType } from '../../../../../core/info/info.types';


@Injectable({ providedIn: 'root' })
export class CtrlSingleUserDetailsCurriculumEditService {

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  editAccount(userId: number, curricula: ControllingSingleUserTypes.CurriculumAccount[]): Observable<void> {
    return this.dialog.openModal<CtrlSingleUserDetailsCurriculumEditComponent,
      CtrlSingleUserDetailsCurriculumEditTypes.DialogData, boolean>(
      CtrlSingleUserDetailsCurriculumEditComponent, {
        data: {
          curricula,
          userId,
        },
      }).afterClosed()
      .pipe(takeWhile(result => result === true))
      .pipe(map(() => void (0)));
  }

  private handleError = (): Observable<void> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

}
