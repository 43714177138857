import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileInfo } from 'src/app/core/core.types';
import { IconHelper } from 'src/app/core/icon.helper';
import { FileHelper } from 'src/app/core/utils';
import { map } from 'rxjs/operators';
import { InfoService } from 'src/app/core/info/info.service';
import { MessageKey, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { ApiUrls } from 'src/app/core/api.urls';
import { Lodash } from '../../core/lodash/lodash';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UploadFile } from 'src/app/core/files.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  selector: 'rag-file-render',
  templateUrl: './file-render.component.html',
  styleUrls: [ './file-render.component.scss' ],
})
export class FileRenderComponent
  implements OnInit {

  @Input() file: FileInfo | UploadFile;
  @Input() readonly = false;
  @Output() remove: EventEmitter<FileInfo | UploadFile>;
  @Input() removeConfirmation = true;

  constructor(
    private infoService: InfoService,
  ) {
    this.remove = new EventEmitter();
  }

  get downloadProgress$() {
    if ( this.file instanceof UploadFile ) {
      return this.file.activityProgress$.pipe(map(value => {
        if ( value < 1.0 ) {
          return `${value * 100.0}%`;
        }
        return '0';
      }));
    }
    return null;
  }

  get size(): string {
    if ( Lodash.isNumber(this.file.fileSize) ) {
      return FileHelper.sizeFormatted(this.file.fileSize);
    } else if ( Lodash.isNumber(this.file.size) ) {
      return FileHelper.sizeFormatted(this.file.size);
    }
    return '???';
  }

  get svgIcon(): string {
    return IconHelper.svgIconForType(this.file.mime);
  }

  ngOnInit() {
  }

  onClick($event: Event) {
    // prevent previewing still not saved files (newly appended to the list)
    if ( this.file instanceof UploadFile ) {
      return;
    }
    if ( this.file.uuid ) {
      const url = `${ApiUrls.getKey('Files')}/${this.file.uuid}`;
      window.open(url, '_blank');
    }
  }

  onDelete($event: Event) {
    // prevent previewing the image when clicked on remove icon
    $event.stopPropagation();
    if ( this.removeConfirmation ) {
      this.infoService.showConfirm(MessageKey.FILES.REMOVE.CONFIRM, YesNoButtons).subscribe(button => {
        if ( button === YesButton ) {
          this.remove.emit(this.file);
        }
      });
      return;
    }
    this.remove.emit(this.file);
  }

}
