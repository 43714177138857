import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, scan, switchMap } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { TrainResponse } from '../global.types';
import { News } from './news.types';

interface NewsWidgetList {
  news: News[];
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {

  constructor(private http: HttpClient) {
  }

  loadNews(): Observable<News[]> {
    const url = ApiUrls.getKey('News');
    return this.load(url);
  }

  loadSpecials(): Observable<News[]> {
    const url = ApiUrls.getKey('Special');
    return this.load(url);
  }

  private load(url: string) {
    return this.http
      .get<NewsWidgetList>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.news));
  }

  private handleError = (err): Observable<never> => {
    console?.error(err);
    return EMPTY;
  };
}
