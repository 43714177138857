import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RuntimeEnvironmentService} from '../../../core/runtime-environment.service';
import {take, tap} from 'rxjs/operators';
import {ContentOverviewV2Component} from '../content-overview-v2/content-overview-v2.component';
import {ContentOverviewComponent} from '../content-overview/content-overview.component';
import { LoadingIndicatorComponent } from '../../../component/loading-indicator/loading-indicator.component';
import { PreloadService } from '../../../core/preload.service';
import { AccountDesignService } from '../../admin/account-design/account-design.service';

@Component({
  selector: 'rag-content-overview-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    ContentOverviewComponent,
    ContentOverviewV2Component,
    LoadingIndicatorComponent,
  ],
  templateUrl: './content-overview-wrapper.component.html',
  styleUrls: ['./content-overview-wrapper.component.scss']
})
export class ContentOverviewWrapperComponent {

  showNewNavigation = false;
  loading = true;

  constructor(
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private preloadService: PreloadService,
    private accountDesignService: AccountDesignService,
  ) {

    this.accountDesignService.newNavigationEnabled$
      .pipe(tap(newNavigationEnabled => {
        this.showNewNavigation = newNavigationEnabled;
        this.loading = false;
      }))
      .pipe(take(1))
      .subscribe();

  }
}
