import { Component } from '@angular/core';
import { BaseQuestFragmentComponent } from '../../quest.types';

@Component({
  selector: 'rag-quest-type-radio',
  templateUrl: './quest-type-radio.component.html',
  styleUrls: ['./quest-type-radio.component.scss']
})
export class QuestTypeRadioComponent extends BaseQuestFragmentComponent {

}
