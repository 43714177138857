import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

/**
 * add class sub-header-token as token wrapper
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-sub-header-tokens',
  templateUrl: './sub-header-tokens.component.html',
  styleUrls: [ './sub-header-tokens.component.scss' ],
})
export class SubHeaderTokensComponent {

  constructor() {
  }

}
