<div class="container">
  <button
    mat-icon-button
    (click)="onClickArrow('left', viewport)"
  >
    <mat-icon svgIcon="chevron-left"></mat-icon>
  </button>
  <div class="main">
    <cdk-virtual-scroll-viewport #viewport orientation="horizontal" itemSize="266" class="example-viewport">
      <div *cdkVirtualFor="let badge of badges">
        <rag-badge-card [badge]="badge" class="example-item"></rag-badge-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
  <button
    mat-icon-button
    (click)="onClickArrow('right', viewport)"
  >
    <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>
</div>


