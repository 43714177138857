<div class="container gridster-item-content">
  <form (submit)="redeem()" [formGroup]="myform">
    <mat-form-field appearance="outline">
      <input
        #code
        [readOnly]="isCodeRedeemed"
        formControlName="codeInputControl"
        matInput
      />
      <button matSuffix mat-icon-button (click)="displayInfo()" type="button">
        <mat-icon svgIcon="information-outline"></mat-icon>
      </button>
    </mat-form-field>

    <button
      [disabled]="isRedeemButtonDisabled()"
      color="primary"
      mat-flat-button
      class="redeem"
      type="submit">
      <span class="button-content">
        <span *ngIf="!isLoading && !isCodeRedeemed" i18n="@@redeem">Redeem</span>
        <span *ngIf="isCodeRedeemed" i18n="@@global_ok">OK</span>
        <mat-spinner *ngIf="isLoading" color="white" diameter="24"></mat-spinner>
      </span>
    </button>
  </form>
</div>
