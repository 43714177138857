<rag-dialog-header *ngIf="title || titleKey">
  <rag-messages [key]="titleKey" [message]="title"></rag-messages>
</rag-dialog-header>

<div class="content" mat-dialog-content>
  <rag-messages *ngIf="message || messageKey" [key]="messageKey" [message]="message"></rag-messages>

  <form [formGroup]="form">
    <mat-form-field appearance="outline">
      <input matInput formControlName="text" data-cy="genericTextInput">
    </mat-form-field>
  </form>
</div>

<mat-dialog-actions>

  <button [mat-dialog-close]
          class="rag-stroked-button" mat-stroked-button data-cy="cancelDialogButton">
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    (click)="onSubmit()"
    [disabled]="submitButtonDisabled$ | async"
    color="primary"
    mat-flat-button
    data-cy="confirmDialogButton"
  >
    <span i18n="@@global_confirm">Confirm</span>
  </button>

</mat-dialog-actions>
