import { ParamMap } from '@angular/router';
import { Core, Distributable, Identifiable, Visualizable } from '../core.types';
import { OfflineContent } from '../admin-offline.types';
import { WithTags } from '../primitives/primitives.types';

export type ContentInfo = Visualizable & Distributable & Identifiable;

export enum UserContentActionEnum {
  READ_ACKNOWLEDGE = 'READ_ACKNOWLEDGE',
  NEED_CLARIFICATION = 'NEED_CLARIFICATION'
}

export interface AnyContent {
  uuid?: string;
  objId: number;
  objType: Core.DistributableType;
}

export enum UserContentRelationEnum {
  LECTURER = 'LECTURER',
  SUPERVISOR = 'SUPERVISOR'
}

export interface UserContentRelation extends AnyContent {
  userId: number;
  relation: UserContentRelationEnum;
}

export interface UserContentAction extends AnyContent {
  userId?: number;
  action: UserContentActionEnum;
  text?: string;
}

interface FrontEndContent {
  calculatedDuration?: string;
  duration?: any;
  durationDimension?: string;
  durationSum?: number;
  finishedStatus?: boolean[];
  fullhref?: string;
  identifier?: string;
  imageUrl?: string;
  iteratedOverForDuration?: boolean;
  lastEnddate?: number;
  lastModified?: number;
  lastValidSince?: number;
  lastValidUntil?: number;
  locked?: boolean;
  progress?: number;
  startdate?: number;
  status?: string;
  statusColor?: string;
  truncatedTitle?: string;

  [any: string]: any;
}

export interface Sco {
  fullhref?: string;
  index: number;
  scoId: number;
  href?: string;
  lastModified?: number;
  scoTitle: string;
}

export interface ScoAccount extends Sco {
  result: number;
  execCount?: number;
  locked: boolean;
  displayStatus: number;
  executionDate: number;
}

export interface Content
  extends FrontEndContent, Distributable, WithTags {
  $view?: { [any: string]: any };
  agendaDate?: number;
  archived: string;
  assignmentType: string;
  courseType?: number;
  currentAccountStatus?: Core.CurriculumAccountStatus;
  description: string;
  displaystatus: number;
  doneCount?: number;
  dueBy?: number;
  execCount?: number | string;
  /**
   * e.g. only optional items are remaining in a curriculum
   */
  hasAllRequiredItemsDone?: boolean;
  /**
   * @since 2021.50.6
   */
  hasConfirmation?: boolean;
  /**
   * @since 2022.13
   */
  hasSupervisor?: boolean;
  hasDirectAssignment: boolean;
  hideInLearnerAccount: boolean;
  items: any[];
  lastModified: number;
  pictureId?: string | number;
  pictureUUID?: string;
  sequentialCur: boolean;
  title: string;
  booked?: boolean;
  canBook?: boolean;
  /**
   * @deprecated to be replaced with {@link Content.items}
   */
  offlineEvents?: OfflineContent.EventSchedule[];
  /**
   * calculated using {@link ContentService.calculateEventDates}.
   * is either the closest upcoming date, or the minimal value of
   * {@link Content.offlineEvents}[].eventDate (if all events are done).
   */
  eventDate?: number;
  /**
   * calculated using {@link ContentService.calculateEventDates}.
   * the event is currently active, if {@link Content.eventDateUpcoming} is null, and
   * {@link Content.eventInPast} is false
   */
  eventDateUpcoming?: number;
  /**
   * calculated using {@link ContentService.calculateEventDates}.
   * if true, this indicates that all {@link OfflineContent.EventSchedule.eventDateUntil} are in the past.
   * only offlineContents with valid offlineEvents are marked as in the past (i.e. events where you still need
   * to select an offlineEvent are marked as "upcoming" without valid {@link Content.eventDateUpcoming})
   */
  eventInPast?: boolean;
}

export interface LearningSession {
  scoId: number;
  userid: number;
  lastError: number;
  lessonLocation: string;
  credit: number;
  entry: string;
  sessionTime: number;
  rawScoreInt: number;
  exitReason: number;
  suspendData: string;
  launchData: string;
  diagnostics: string;
  running: boolean;
  startTime: number;
  minScoreInt: number;
  lock: number;
  rawScoreFloat: number;
  minScoreFloat: number;
  sessionToken: string;
  iteration: number;
}

export interface NamedDistributable {
  objectId: number;
  systemName: string;
  objectType?: Core.DistributableType;
  objectSubType?: Core.CourseType;
}

export class ContentOverviewComponentFilter {

  readonly SERIALIZE_FIELDS = [
    'ts',
    'at',
    'ct',
    'st',
    'es',
    'isValid',
  ];
  /**
   * AssignmentType
   */
  _at: string;
  /**
   * ContentType
   */
  _ct: string;
  /**
   * View type (card, false => list)
   */
  _cv = true;
  /**
   * Alphabet and Expiry Sort
   */
  _es: string;
  /**
   * Certificate specific
   * valid or not valid
   */
  _isValid: string;
  /**
   * Status (open, done)
   */
  _st: string;
  readonly _storageContext: string;
  /**
   * Title search
   */
  _ts: string;
  _updateStorage = false;
  _updateStorageDebounce;

  constructor(context?: string) {
    this._storageContext = 'ContentOverviewComponentFilter.' + (context || 'default');
  }

  get at(): string {
    return this._at || '';
  }

  set at(value: string) {
    this._at = value;
    this.updateStorage();
  }

  get ct(): string {
    return this._ct || '';
  }

  set ct(value: string) {
    this._ct = value;
    this.updateStorage();
  }

  get cv(): boolean {
    return this._cv;
  }

  set cv(value: boolean) {
    this._cv = value;
    this.updateStorage();
  }

  get es(): string {
    return this._es;
  }

  set es(value: string) {
    this._es = value;
    this.updateStorage();
  }

  get isValid(): string {
    return this._isValid;
  }

  set isValid(value: string) {
    this._isValid = value;
    this.updateStorage();
  }

  get st(): string {
    return this._st || '';
  }

  set st(value: string) {
    this._st = value;
    this.updateStorage();
  }

  get ts(): string {
    return this._ts || '';
  }

  set ts(value: string) {
    this._ts = value;
    this.updateStorage();
  }

  /**
   * Return an object containing only filter's variables which are set
   */
  asValidObject(): any {
    return this.SERIALIZE_FIELDS.reduce((p, c) => {
      const v = this[c];
      if ( v !== undefined && '' !== v ) {
        p[c] = v;
      }
      return p;
    }, {});
  }

  fromDefaults(): void {
    this._ts = '';
    this._es = 'title';
    this._isValid = 'newest';
  }

  fromStorage(): void {
    this.fromDefaults();
    this._updateStorage = true;

    let initialState;
    try {
      initialState = JSON.parse(localStorage.getItem(this._storageContext));
    } catch ( e ) {
      initialState = null;
    }

    initialState = initialState || {};
    this.SERIALIZE_FIELDS.forEach(key => {
      if ( initialState.hasOwnProperty(key) ) {
        this[key] = initialState[key];
      }
    });
  }

  nothingToSearchFor() {
    return this._ts == null || this._ts.trim().length === 0 &&
      this._st === undefined &&
      this._ct === undefined &&
      this._at === undefined;
  }

  /**
   * Parse the query parameters from the url and set the local state accordingly
   * @param params queryParams to parse
   */
  parseParams(params?: ParamMap) {
    if ( params.has('ts') ) {
      this.ts = params.get('ts');
    }
    if ( params.has('ct') ) {
      this.ct = params.get('ct');
    }
    if ( params.has('at') ) {
      this.at = params.get('at');
    }
    if ( params.has('st') ) {
      this.st = params.get('st');
    }
    if ( params.has('es') ) {
      this.es = params.get('es');
    }
    if ( params.has('isValid') ) {
      this.isValid = params.get('isValid');
    }
    if ( params.has('cv') ) {
      this.cv = params.get('cv') == null || (params.get('cv') === 'true');
    }
  }

  updateStorage(): void {
    if ( !this._updateStorage ) {
      return;
    }

    if ( this._updateStorageDebounce ) {
      clearTimeout(this._updateStorageDebounce);
    }

    this._updateStorageDebounce = setTimeout(() => {
      try {
        localStorage.setItem(this._storageContext, JSON.stringify(this.asValidObject()));
      } catch ( e ) {
        // OK
      }
    }, 150);
  }

}

/**
 * see com.reflact.sphere.elearning.lms.sco.CourseDTO
 */
export interface CourseInfo {
  adminTitle?: string;
  confirmationText?: string;
  context?: { [key: string]: any };
  courseLanguages?: string;
  description?: string;
  downloadable?: boolean;
  editor?: string;
  hasConfirmation?: boolean;
  hasWarning?: boolean;
  href?: string;
  id?: number;
  intendedLearner?: string;
  language?: string;
  maintenanceEnd?: number;
  maintenanceStart?: number;
  originator?: string;
  overrideHref?: string;
  pictureId?: number;
  relevantItem?: number;
  resultUserVisible?: boolean;
  status?: string;
  title?: string;
  type?: number;
  typicalLearningTime?: string;
  typicalLearningTimeDimension?: number;
  warningText?: string;
  warningTitle?: string;
}

export type ContentServiceEventType = 'SetCourseSupervisor' | 'RemoveCourseSupervisor';
export interface ContentServiceEvent {
  type: ContentServiceEventType;
  courseId?: number;
  payload?: any;
}
