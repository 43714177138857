import { Component, OnInit } from '@angular/core';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';
import { ControlContainer } from '@angular/forms';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';
import { Core } from 'src/app/core/core.types';


@Component({
  selector: 'rag-profile-field-multiselect',
  templateUrl: './profile-field-multiselect.component.html',
  styleUrls: [ './profile-field-multiselect.component.scss' ],
})
export class ProfileFieldMultiselectComponent
  extends AbstractProfileFieldComponent<string[]> implements OnInit {

    options: Core.KeyValue<string>[];

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.options = this.field?.options ?? [];
  }

  protected cleanValue(): string[] {
    const field = this.field;
    if ( field == null || field.value == null ) {
      return [];
    }

    return ProfileFieldTypes.toControlValue(field.type, field.value);
  }
}
