import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { AdminCurriculum } from '../admin-curriculum.types';
import { AdminCurriculumListService } from './admin-curriculum-list.service';

export const AdminCurriculumListResolver: ResolveFn<AdminCurriculum.CurriculumMeta[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  adminCurriculumListService: AdminCurriculumListService = inject(AdminCurriculumListService)
) => {
  const status = route.data['status'];
  return adminCurriculumListService.getCurricula(status);
}
