import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Translation } from 'src/app/core/translation/translation.types';
import { ApplicationStateService } from '../../core/application-state.service';
import { CachedSubject } from '../../core/cached-subject';
import { getDimensions } from '../../core/offset-helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { ViewportStateType } from '../../core/viewport-state';
import { Widget } from '../rag-layout.types';


export const WIDGETS_SETTINGS_VER = 3;

@Component({ template: '' })
export class BaseWidgetComponent {

  @HostBinding('class.edit-mode')
  @Input() edit: boolean;
  @Input() removeItem: (widget: Widget) => void;
  /**
   * The resize event is triggered for all widgets if one of them is resized.
   */
  @Input() resizeEvent: EventEmitter<GridsterItem>;
  @Input() widget: Widget;
  @Input() settings: Observable<any>;
  @Output() settingsChange: EventEmitter<any>;
  private _title$ = new CachedSubject<string>(null);

  constructor() {
    this.settingsChange = new EventEmitter();
  }

  get title$(): Observable<string> {
    return this._title$.withoutEmptyValuesWithInitial();
  }

  setTitle = (title: string) => {
    this._title$.next(title);
  };

}

@Component({ template: '' })
export class AbstractCardWidgetComponent
  extends BaseWidgetComponent
  implements OnDestroy {

  // display boxes by default
  isBoxViewMode = true;
  maxItems: Observable<number>;

  private _gridCols = 3;
  private _maxItemsFromViewportState: number = null;
  private _maxItems = new CachedSubject<number>(this._maxItemsFromViewportState);

  constructor(
    private applicationStateService: ApplicationStateService,
  ) {
    super();

    this.maxItems = this._maxItems.withoutEmptyValues()
      // if we have defined number of columns, normalize maxItems to reduced space
      .pipe(map(maxItems => (this._gridCols > 0) ? Math.max(1,
        Math.floor(this._gridCols * maxItems / 3)) : maxItems))
        // if we show the list view, we can display all values with scroll bars
        .pipe(map(maxItems => this.isBoxViewMode ? maxItems : 0))
        .pipe(distinctUntilChanged());

    this.observeViewportState();
  }

  get gridCols(): number {
    return this._gridCols;
  }

  set gridCols(value: number) {
    this._gridCols = value;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  refreshMaxItems = (): void => {
    this._maxItems.next(this._maxItemsFromViewportState);
  };

  protected observeResize() {
    this.resizeEvent
        // wait for operation to finish
        .pipe(delay(0))
        // update number of columns
        .pipe(map(() => this._gridCols = this.widget.gridsterItem.cols))
        // redraw layout
        .pipe(map(this.refreshMaxItems))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
  }

  protected observeViewportState(): void {
    this.applicationStateService.viewportState$
        .pipe(map(state => {
          switch ( state.state ) {
            case ViewportStateType.Small:
              return 1;
            case ViewportStateType.Medium:
              return 2;
            case ViewportStateType.Large:
              return 3;
            case ViewportStateType.Huge:
            default:
              return 4;
          }
        }))
        .pipe(map(maxItems => this._maxItemsFromViewportState = maxItems))
        .pipe(map(this._maxItems.next))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
  }

}

@Component({ template: '' })
export class AbstractChartWidgetComponent
  extends BaseWidgetComponent
  implements AfterViewInit, OnDestroy {

  private _aspectRatio: number;
  private _resizeEventSubscription: Subscription;
  private _sizingContainer: ElementRef;
  private _sizingDone = new EventEmitter<void>(true);
  private _sizingDone$ = this._sizingDone.asObservable();
  private _sizingReference: ElementRef;

  getAspectRatio(): number {
    return this._aspectRatio || 2;
  }

  getSizingDone = (): Observable<void> => this._sizingDone$;

  ngAfterViewInit(): void {
    this.observeResize();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
    this.cleanResizeEvent();
  }

  updateSizingContainer = (): void => {
    const nativeElement = this._sizingContainer && this._sizingContainer.nativeElement;
    const dimensions = nativeElement && getDimensions(this._sizingReference);
    if ( !(nativeElement && dimensions.innerHeight > 0) ) {
      return;
    }

    const aspectRatio = this.getAspectRatio();
    const maxWidth = Math.floor(dimensions.innerHeight * aspectRatio);
    nativeElement.style.width = maxWidth + 'px';
    this._sizingDone.emit();
  };

  protected cleanResizeEvent(): void {
    if ( this._resizeEventSubscription ) {
      this._resizeEventSubscription.unsubscribe();
      this._resizeEventSubscription = null;
    }
  }

  protected observeResize(): void {
    this.cleanResizeEvent();

    this._resizeEventSubscription = this.resizeEvent
      .pipe(debounceTime(150))
      .pipe(tap(this.updateSizingContainer))
      .subscribe();
  }

  protected setAspectRatio = (value: number): void => {
    this._aspectRatio = value;
    this.updateSizingContainer();
  };

  protected setSizingContainer = (value: ElementRef): void => {
    this._sizingContainer = value;
    this.updateSizingContainer();
  };

  protected setSizingReference = (value: ElementRef): void => {
    this._sizingReference = value;
    this.updateSizingContainer();
  };

}

export class WidgetsUUID {
  static CodeRedeemWidgetUUID = '9E3FF2DD-9E51-4F33-86AF-6C7FAF901CE2';
  static HtmlWidgetUUID = '48217909-B041-40DB-B8CE-629190C74B4A';
  static MycertificatesWidgetUUID = '35074766-F308-4450-B85D-17DFDEA7507D';
  static NewsWidgetUUID = 'D03634E5-D8A7-4027-8323-C590BA441596';
  static OverviewWidgetUUID = '3C17F210-FC89-43D0-B62E-3F525BC4822C';
  static ReportBarChartWidgetUUID = 'EABF49C2-771A-43F5-BF4F-412EA5B19406';
  static ReportFavouritesWidgetsUUID = '9AAE14FE-7D2D-4FD3-B7BD-DBA411A53A6A';
  static ReportLinksWidgetUUID = 'EEC734FA-B77E-48AB-807D-F2093EFD9CA8';
  static ReportPieChartWidgetUUID = '42894284-156A-4B99-B272-9E7C16FF8233';
  static SpecialsWidgetUUID = '372CA887-BBB3-451D-92AE-AC39B5267AAF';
  static EventsWidgetUUID = '173EE970-0516-4F0B-B0BD-B165562FBB56';
  static GamificationWidgetUUID = '86E1BB33-103F-4A63-B5F5-F05C342E5B88';
}

export interface WidgetReloadEvent {
  widgetUUID: string;
}

export interface SortableByIndex {
  orderIndex?: number;
}

export class WidgetContext {
  static LayoutControllingDashboard = 'layout-ctrl-dashboard';
  static LayoutHome = 'layout-home';
}

export interface WidgetConf
  extends GridsterItem, SortableByIndex {
  // todo add context to widgets
  context?: string;
  deletable?: boolean;
  draggable?: boolean;
  initial?: boolean;
  instanceUUID?: string;
  onlyOnce?: boolean;
  resizeEnabled?: boolean;
  selectable?: boolean;
  showAtTop?: boolean;
  static?: boolean;
  title?: Translation;
  uuid?: string;
}

export interface ReportLinksWidgetSettings {
  enabled: boolean;
  ownedByMe: boolean;
  reportUUID: string;
  title: string;
}

export interface WidgetSettings {
  [key: string]: WidgetConf;
}

export const WIDGET_DEFAULTS: WidgetSettings = {
  [WidgetsUUID.CodeRedeemWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 1,
    initial: false,
    cols: 3,
    rows: 4,
    resizeEnabled: false,
    minItemRows: 4,
    maxItemRows: 4,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_license_code_title:Redeem Licence Code`,
    uuid: WidgetsUUID.CodeRedeemWidgetUUID,
    x: 0,
    y: 0,
  },
  [WidgetsUUID.MycertificatesWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 2,
    initial: false,
    cols: 3,
    rows: 16,
    resizeEnabled: true,
    minItemRows: 16,
    maxItemRows: 28,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_mycertificates_title:My Certificates`,
    uuid: WidgetsUUID.MycertificatesWidgetUUID,
    x: 0,
    y: 0,
  },
  [WidgetsUUID.NewsWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 3,
    initial: false,
    cols: 3,
    rows: 10,
    resizeEnabled: false,
    minItemRows: 10,
    maxItemRows: 25,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_news_title:News`,
    uuid: WidgetsUUID.NewsWidgetUUID,
    x: 0,
    y: 0,
    isTextBox: false,
  },
  [WidgetsUUID.OverviewWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 4,
    initial: true,
    cols: 3,
    rows: 17,
    resizeEnabled: true,
    minItemRows: 17,
    maxItemRows: 28,
    minItemCols: 1,
    maxItemCols: 3,
    deletable: false,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_overview_title:Courses to be completed`,
    uuid: WidgetsUUID.OverviewWidgetUUID,
    x: 0,
    y: 0,
  },
  [WidgetsUUID.SpecialsWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 5,
    initial: false,
    cols: 3,
    rows: 10,
    resizeEnabled: false,
    minItemRows: 10,
    maxItemRows: 10,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_specials_title:Welcome`,
    uuid: WidgetsUUID.SpecialsWidgetUUID,
    x: 0,
    y: 0,
  },
  [WidgetsUUID.ReportPieChartWidgetUUID]: {
    context: WidgetContext.LayoutControllingDashboard,
    index: 6,
    initial: false,
    cols: 1,
    rows: 10,
    resizeEnabled: true,
    minItemRows: 10,
    maxItemRows: 20,
    minItemCols: 1,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: false,
    title: $localize`:@@report_pie_chart_widget_title:Learning progress chart`,
    uuid: WidgetsUUID.ReportPieChartWidgetUUID,
    x: 0,
    y: 0,
    // todo: remove me
    dragEnabled: true,
  },
  [WidgetsUUID.ReportFavouritesWidgetsUUID]: {
    context: WidgetContext.LayoutControllingDashboard,
    index: 7,
    initial: false,
    cols: 1,
    rows: 6,
    resizeEnabled: true,
    minItemRows: 6,
    maxItemRows: 12,
    minItemCols: 1,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: false,
    title: $localize`:@@widget_report_favorite_title:Favourites`,
    uuid: WidgetsUUID.ReportFavouritesWidgetsUUID,
    x: 0,
    y: 0,
    // todo: remove me
    dragEnabled: true,
  },
  [WidgetsUUID.ReportLinksWidgetUUID]: {
    context: WidgetContext.LayoutControllingDashboard,
    index: 8,
    initial: false,
    cols: 1,
    rows: 5,
    resizeEnabled: true,
    minItemRows: 5,
    maxItemRows: 5,
    minItemCols: 1,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: false,
    title: $localize`:@@widget_report_links_title:Quick links to reports`,
    uuid: WidgetsUUID.ReportLinksWidgetUUID,
    x: 0,
    y: 0,
    // todo: remove me
    dragEnabled: true,
  },
  [WidgetsUUID.ReportBarChartWidgetUUID]: {
    context: WidgetContext.LayoutControllingDashboard,
    index: 9,
    initial: false,
    cols: 1,
    rows: 10,
    resizeEnabled: true,
    minItemRows: 10,
    maxItemRows: 20,
    minItemCols: 1,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: false,
    title: $localize`:@@report_bar_chart_widget_title:Learning time analysis`,
    uuid: WidgetsUUID.ReportBarChartWidgetUUID,
    x: 0,
    y: 0,
    // todo: remove me
    dragEnabled: true,
  },
  [WidgetsUUID.EventsWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 10,
    initial: false,
    cols: 3,
    rows: 16,
    resizeEnabled: false,
    minItemRows: 16,
    maxItemRows: 28,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@widget_events_title:Events`,
    uuid: WidgetsUUID.EventsWidgetUUID,
    x: 0,
    y: 0,
  },
  [WidgetsUUID.GamificationWidgetUUID]: {
    context: WidgetContext.LayoutHome,
    index: 11,
    initial: false,
    cols: 3,
    rows: 8,
    resizeEnabled: false,
    minItemRows: 8,
    maxItemRows: 8,
    minItemCols: 3,
    maxItemCols: 3,
    deletable: true,
    draggable: true,
    selectable: true,
    showAtTop: false,
    static: false,
    onlyOnce: true,
    title: $localize`:@@header_top_gamification:Awards`,
    uuid: WidgetsUUID.GamificationWidgetUUID,
    x: 0,
    y: 0,
    version: 2,
  },
};

export const sortByIndex = (o1: SortableByIndex, o2: SortableByIndex) => o1.orderIndex - o2.orderIndex;
