import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { SignatureDetails, SignatureSaveData, SignatureUsageDetails, UsageDetails } from '../admin-signatures.types';
import { CKEditorDefaults } from 'src/app/core/ckeditor.types';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ModalDialog } from '../../../../core/modal-dialog';
import { AdminSignaturesUsageDialogComponent } from '../admin-signatures-usage-dialog/admin-signatures-usage-dialog.component';
import { AdminSignaturesService } from '../admin-signatures.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType } from '../../../../core/info/info.types';
import { EMPTY, of } from 'rxjs';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { RagCKEditorHelper } from '../../../../core/input/rag-ckeditor/ragCkeditorHelper';

@Component({
  selector: 'rag-admin-signatures-details',
  templateUrl: './admin-signatures-details.component.html',
  styleUrls: ['./admin-signatures-details.component.scss']
})
export class AdminSignaturesDetailsComponent implements OnInit, OnDestroy {

  saveOrCreateText: string;
  signature: SignatureDetails;
  usages: UsageDetails[] = [];
  formGroup: FormGroup;

  @ViewChildren(CKEditorComponent) ckEditorChildren: QueryList<CKEditorComponent>;
  constructor(
    private adminSignaturesService: AdminSignaturesService,
    private dialog: ModalDialog,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();
  }

  onSave() {
    this.ckEditorChildren.toArray()
      ?.forEach(c => RagCKEditorHelper.disableSourceEditing(c));

    this.adminSignaturesService.preCheckMacroExistsV2(this.signature.macro)
      .pipe(take(1))
      .pipe(map(data => {
        if (data.length > 1) {
          return true;
        } else if (data.length === 0) {
          return false;
        } else return data[0].id !== this.signature.id;
      }))
      .pipe(switchMap(exists => {
        if (exists === false) {
          return of(false);
        }
        this.infoService.showMessage($localize`:@@admin_signatures_details_macro_exist:
        The signature could not be saved because the macro already exists.`, {
          infoType: InfoType.Error
        });
        return EMPTY;
      }))
      .pipe(switchMap(() => {
        const saveData: SignatureSaveData = {
          macro: this.signature.macro,
          name: this.signature.name,
          description: this.signature.description,
          type: this.signature.type,
          content: this.signature.content,
        };
        return this.adminSignaturesService.saveSignatureV2(this.signature.id, saveData);
      }))
      .pipe(switchMap(savedSignature => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`, {
          infoType: InfoType.Success,
        });
        this.signature.id = savedSignature.id;
        this.signature.macro = savedSignature.macro;
        this.formGroup.markAsUntouched();
        this.formGroup.markAsPristine();
        const url = `/admin/notifications/signatures/details/${this.signature.id}`;
        return this.router.navigateByUrl(url);
      }))
      .pipe(catchError(() =>
      this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`, {
        infoType: InfoType.Error
      })
      ))
      .subscribe();
  }

  protected noWhitespaceValidator(control: UntypedFormControl) {
    const controlValue: string = control.value ?? '';
    const hasNoWhitespace = controlValue.indexOf(' ') === -1;
    return hasNoWhitespace ? null : { whitespace: true };
  }

  protected onlyLatinAlphanumericalValidator(control: UntypedFormControl) {
    const controlValue: string = control.value ?? '';
    const goodSymbols = /^[A-z0-9]*$/.test(controlValue);
    return goodSymbols ? null : { illegalSymbols: true };
  }

  private buildFormGroup() {
    this.formGroup = this.formBuilder.group({
      title: [this.signature.name],
      description: [this.signature.description],
      content: [this.signature.content],
      type: [this.signature.type],
      macro: [this.signature.macro, [this.noWhitespaceValidator, this.onlyLatinAlphanumericalValidator]],
    });

    this.formGroup.get('title').valueChanges.pipe(map(data => {
      this.signature.name = data;
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.formGroup.get('description').valueChanges.pipe(map(data => {
      this.signature.description = data;
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.formGroup.get('content').valueChanges.pipe(map(data => {
      this.signature.content = data;
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.formGroup.get('type').valueChanges.pipe(map(data => {
      this.signature.type = data;
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.formGroup.get('macro').valueChanges.pipe(map(data => {
      this.signature.macro = data;
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }
  private updateRouteData(data) {
    this.signature = data.data.signature;
    this.usages = data.data.usage;
    this.buildFormGroup();

    if (!(this.signature.id > 0)) {
      this.saveOrCreateText = $localize`:@@general_create:Create`;
    } else {
      this.saveOrCreateText = $localize`:@@global_save:Save`;
    }
  }
}
