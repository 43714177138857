import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LicenseGroupToTgTypes } from 'src/app/core/development-goals/license-group-to-tg.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-licence-group-column-statistics',
  templateUrl: './licence-group-column-statistics.component.html',
  styleUrls: [ './licence-group-column-statistics.component.scss' ],
})
export class LicenceGroupColumnStatisticsComponent {

  @Input() statistics: LicenseGroupToTgTypes.DistAssignmentTotals;

  constructor() {
  }

  get countMandatory(): number {
    const mandatory = this.statistics?.mandatory;
    return (mandatory?.checkedCount + mandatory?.disabledCount) || 0;
  }

  get countVoluntary(): number {
    const voluntary = this.statistics?.voluntary;
    return (voluntary?.checkedCount + voluntary?.disabledCount) || 0;
  }

}
