import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { OAuthUserInfoResponse } from './ext-oauth2-users.types';
import { ApiUrls } from '../../../../../core/api.urls';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { InfoService } from '../../../../../core/info/info.service';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { UserNameHelper } from '../../../../../core/user-name.helper';
import { InfoType, MessageConstants } from '../../../../../core/info/info.types';
import { ExtOauth2UsersRow } from './ext-oauth2-users.columns';
import { UserHelper } from '../../../../../core/users.helper';


export const extOauth2UsersResolver: ResolveFn<OAuthUserInfoResponse> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  extOauth2UsersService: ExtOauth2UsersService = inject(ExtOauth2UsersService)
): Observable<OAuthUserInfoResponse> => {
  const registrationId = route.params['registrationId'];
  if (!registrationId) {
    throw Error('registrationId missing from URL!');
  }

  return extOauth2UsersService.getUsers(registrationId);
};


@Injectable({ providedIn: 'root' })
export class ExtOauth2UsersService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private userHelper: UserHelper,
  ) {
  }

  getUsers(registrationId: string): Observable<OAuthUserInfoResponse> {
    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUsers')
      .replaceAll(/\{registrationId}/gi, registrationId);
    return this.http.get<OAuthUserInfoResponse>(url);
  }

  setUserId(
    registrationId: string, principalName: string, id: number,
  ): Observable<OAuthUserInfoResponse> {
    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUsers')
      .replaceAll(/\{registrationId}/gi, registrationId);
    return this.http.post<OAuthUserInfoResponse>(url, {
      principalName, userId: id,
    })
      .pipe(catchError(() => {
        this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(() => this.infoService
        .showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success })));
  }

  changeUser(
    registrationId: string,
    row: ExtOauth2UsersRow,
    principalName: string,
    userId: number | null,
    systemUsers: ControllingSingleUserTypes.ControllingUser[],
  ): Observable<OAuthUserInfoResponse | never> {
    const data = UserHelper.convertToDialogData(userId, systemUsers, 1);
    const title = UserNameHelper.getFullNameWithLogin(row.userFirstname, row.userLastname, row.userEmail,
      row.userLogin ?? principalName, row.userId);
    if (title) {
      data.i18n.title += ` - ${title}`;
    }
    return this.userHelper.showAssignmentDialog(data)
      .pipe(map(o => o[0]?.value?.userId))
      .pipe(switchMap(id => this.setUserId(registrationId, principalName, id)))
      .pipe(take(1));
  }

}
