import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CertificatesComponent } from 'src/app/route/user/certificates/certificates.component';
import { ApplicationStateService } from '../../../core/application-state.service';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { AbstractCardWidgetComponent, WidgetsUUID } from '../widgets.types';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { map } from 'rxjs/operators';
import { CachedSubject } from 'src/app/core/cached-subject';


@Component({
  selector: 'rag-mycertificates-widget',
  templateUrl: './mycertificates-widget.component.html',
  styleUrls: [ './mycertificates-widget.component.scss' ],
})
export class MycertificatesWidgetComponent
  extends AbstractCardWidgetComponent
  implements WidgetComponent, AfterViewInit, OnInit, OnDestroy {

  static WidgetUUID = WidgetsUUID.MycertificatesWidgetUUID;
  @ViewChild('certificatesOverview', { static: false }) certificatesOverview: CertificatesComponent;
  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasContent = false;
  receivedResponse = false;
  @ViewChild('selection')
  selection: ElementRef;
  userIsAuthorized$ = new CachedSubject<boolean>(undefined);
  @ViewChild('viewMode', { static: true })
  viewModeRef: TemplateRef<any>;

  constructor(
    applicationStateService: ApplicationStateService,
    private principalService: PrincipalService,
  ) {
    super(applicationStateService);

    this.setTitle($localize`:@@widget_mycertificates_title:My Certificates`);

    this.observeViewportState();
  }

  downloadPdfCertificates() {
    this.certificatesOverview.downloadPdfCertificates();
  }

  getMenuReferences() {
    return [ this.viewModeRef ];
  }

  isDataDriven() {
    return true;
  }

  ngAfterViewInit() {
    this.hasContent = this.certificatesOverview?.hasContent;
    this.observeResize();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit() {

    this.principalService.permissionStates$.pipe(map(permissionStates => {
      if ( permissionStates.navUserCertificates ) {
        this.userIsAuthorized$.next(true);
        return;
      }
      this.userIsAuthorized$.next(false);
      // stop activity indicator
      this.receivedResponse = true;
      this.didReceiveResponse({
        hasContent: false,
      });
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.settings
      .pipe(map(userSettings => {
        const isBoxViewMode = userSettings['isCards'] ?? true;
        if (isBoxViewMode !== this.isBoxViewMode) {
          this.toggleViewMode(false);
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onDidReceivedResponse = (receivedResponse): void => {
    this.receivedResponse = true;
    this.hasContent = receivedResponse.hasContent;
    this.didReceiveResponse(receivedResponse);
  };

  toggleViewMode(emiEvent = true) {
    this.isBoxViewMode = !this.isBoxViewMode;
    if ( this.isBoxViewMode ) {
      this.certificatesOverview.toggleViewMode();
    } else {
      this.certificatesOverview.toggleViewMode();
    }
    this.refreshMaxItems();
    if (emiEvent) {
      this.settingsChange.next({ isCards: this.isBoxViewMode });
    }
  }

}
