import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { CachedSubject } from 'src/app/core/cached-subject';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { MatChipsModule } from '@angular/material/chips';
import { Identifiable, Nameable } from 'src/app/core/core.types';

export type SelectableItem = Identifiable & Nameable;

export interface SelectItemDialogComponentData<T extends SelectableItem> {
  items: Array<T>;
  header: string;
  placeholder: string;
}

@Component({
  selector: 'rag-select-item-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  templateUrl: './select-item-dialog.component.html',
  styleUrls: ['./select-item-dialog.component.scss']
})
export class SelectItemDialogComponent<T extends SelectableItem> implements OnInit {

  form: FormGroup;
  filteredItemsOptions$: Observable<Array<T>>;
  selectButtonDisabled$: Observable<boolean>;
  selectedItems$: Observable<Array<T>>;
  protected header: string;
  protected placeholder: string;

  private _selectedItems$ = new CachedSubject<Array<T>>([]);
  private items: Array<T> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectItemDialogComponentData<T>,
    private dialogRef: MatDialogRef<SelectItemDialogComponent<T>>,
    private formBuilder: FormBuilder
  ) {
    this.selectedItems$ = this._selectedItems$.asObservable();
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('60vh');
    this.buildForm();

    this.items = this.data.items;
    this.header = this.data.header;
    this.placeholder = this.data.placeholder;

    this.filteredItemsOptions$ = this.form.get('item').valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map(value => this._searchForCurriculum(value)));
  }

  onClearSelectedItems() {
    this.form.get('item').setValue(null);
  }

  onDelete(item: T) {
    let _selectedItems = this._selectedItems$.value;
    _selectedItems = _selectedItems.filter(c => c.id !== item.id);
    this._selectedItems$.next(_selectedItems);

    const _selectedItemsIds = _selectedItems.map(c => c.id);

    this.items = this.data.items.filter( c => !_selectedItemsIds.includes(c.id) );

    this.form.get('item').setValue(null);
  }

  onItemSelected($event: MatAutocompleteSelectedEvent) {
    const selectedCurriculum = $event.option.value;
    const _selectedCurricula = this._selectedItems$.value;
    _selectedCurricula.push($event.option.value);
    this._selectedItems$.next(_selectedCurricula);
    this.onClearSelectedItems();

    this.items = this.items.filter( c => c.id !== selectedCurriculum.id );
  }

  onSelect() {
    this.dialogRef.close(this._selectedItems$.value.map(c => c.id));
  }

  renderItem(c: T) {
    if (c == null) {
      return null;
    }
    return c.name;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      item: [null, []]
    });

    this.selectButtonDisabled$ = this.selectedItems$.pipe(map(collection => collection.length === 0));
  }

  private _searchForCurriculum(value: string | null | T): T[] {
    if (value == null) {
      return this.items;
    }
    if (typeof value === 'object') {
      return [value];
    }
    return this.items
      .filter(c => c.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }

}
