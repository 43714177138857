import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../component/table/table-controller/table-controller.types';
import { TableColumnBuilder } from '../../../../component/table/table-column.builder';

export interface AdminGamificationColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: AdminGamificationColumnMenuItemMap;
}

export interface AdminGamificationColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  templateUUID: TableControllerTypes.ColumnMenuItem;
  picture: TableControllerTypes.ColumnMenuItem;
  adminTitle: TableControllerTypes.ColumnMenuItem;
  adminDescription: TableControllerTypes.ColumnMenuItem;
  actions: TableControllerTypes.ColumnMenuItem;
}

export const ADMIN_GAMIFICATION_DEFAULT_MENU_COLUMNS: AdminGamificationColumnMenuData = {
  startWith: [ 'picture' ],
  endWith: [],
  menuItems: {
    templateUUID: TableColumnBuilder.start()
      .withColumnId('templateUUID')
      .withType(TableColumnDataType.text)
      .withTitle('UUID')
      .build(),

    picture: TableColumnBuilder.start()
      .withColumnId('picture')
      .withDataAccessor(entry => entry.pictureUUID)
      .withType(TableColumnDataType.image)
      .withSelected()
      .withTitle($localize`:@@global_image:Image`)
      .build(),

    adminTitle: TableColumnBuilder.start()
      .withColumnId('adminTitle')
      .withType(TableColumnDataType.text)
      .withSelected()
      .withTitle($localize`:@@global_title:Title`)
      .build(),

    adminDescription: TableColumnBuilder.start()
      .withColumnId('adminDescription')
      .withType(TableColumnDataType.text)
      .withSelected()
      .withTitle($localize`:@@global_description:Description`)
      .build(),

    actions: TableColumnBuilder.start()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),
  }
}
