import { Content } from './content/content.types';
import { Core, ImageableContentReference } from './core.types';
import { DisplayStatus } from './display-status.enum';

export enum DisplayStatusColors {
  none = -1,
  red_green = 0,
  red = 1,
  yellow = 2,
  green = 3,
}

/**
 * DisplayStatus
 *   unKnown:            -1 gray
 *   notAttempted:        0 red
 *   certified:           1 green
 *   notCertified:        2 red
 *   passed:              3 green
 *   failed:              4 red
 *   complete:            5 green
 *   incomplete:          6 yellow
 *   recertificationTime  7 green / red
 */
export class DisplayStatusHelper {

  /**
   * compare two display statuses
   * @param status1 DisplayStatus
   * @param status2 DisplayStatus
   * @returns -1 - if status1 is 'greater' than status2, 1 - if status1 is 'less' than status2, otherwise 0.
   */
  public static compare(status1: number, status2: number): number {
    return Math.sign(DisplayStatusHelper.toColor(status1) - DisplayStatusHelper.toColor(status2));
  }

  public static isStatusGreen(status: string | number): boolean {
    if ( status === 'green' ) {
      return true;
    }
    return DisplayStatusHelper.toColor(status) === DisplayStatusColors.green;
  }

  public static isStatusGrey(status: string | number): boolean {
    return DisplayStatusHelper.toColor(status) === DisplayStatusColors.none;
  }

  public static isStatusRecert(status: string | number): boolean {
    return DisplayStatusHelper.toColor(status) === DisplayStatusColors.red_green;
  }

  public static isStatusRed(status: string | number): boolean {
    if ( status === 'red' ) {
      return true;
    }
    return DisplayStatusHelper.toColor(status) === DisplayStatusColors.red;
  }

  public static isStatusYellow(status: string | number): boolean {
    if ( status === 'yellow' ) {
      return true;
    }
    return DisplayStatusHelper.toColor(status) === DisplayStatusColors.yellow;
  }

  public static isToDoContent(displayStatus: string | number): boolean {
    return DisplayStatusHelper.isStatusYellow(displayStatus) ||
      DisplayStatusHelper.isStatusRecert(displayStatus) ||
      DisplayStatusHelper.isStatusRed(displayStatus);
  }

  public static isToDoContentRecursive(c: ImageableContentReference): boolean {
    if ( !c ) {
      return false;
    }
    if ( c.objType === Core.DistributableType.lms_curriculum && c.items ) {
      return c.items.find(ci => DisplayStatusHelper.isToDoContentRecursive(ci)) !== undefined;
    }
    if ( DisplayStatusHelper.isToDoContent(c.displaystatus) ) {
      return c.isMandatory !== false;
    }
    return false;
  }

  public static toColor(status: string | number): DisplayStatusColors {
    status = DisplayStatusHelper.toDisplayStatus(status);

    switch ( status ) {
      case DisplayStatus.FAILED:
      case DisplayStatus.NOT_ATTEMPTED:
      case DisplayStatus.NOT_CERTIFIED:
        return DisplayStatusColors.red;

      case DisplayStatus.INCOMPLETE:
        return DisplayStatusColors.yellow;

      case DisplayStatus.CERTIFIED:
      case DisplayStatus.COMPLETE:
      case DisplayStatus.PASSED:
        return DisplayStatusColors.green;

      case DisplayStatus.RECERTIFICATION_TIME:
        return DisplayStatusColors.red_green;

      case DisplayStatus.UN_KNOWN:
      case DisplayStatus.UN_ASSIGNED:
      default:
        return DisplayStatusColors.none;
    }
  }

  public static toDisplayStatus(status: string | number): DisplayStatus {
    if ( typeof (status) === 'string' ) {
      switch ( status ) {
        case 'CERTIFIED':
        case 'green':
          // 'green' may be PASSED or COMPLETED for lms_course!
          return DisplayStatus.CERTIFIED;
        case 'NOT_CERTIFIED':
          return DisplayStatus.NOT_CERTIFIED;
        case 'PASSED':
          return DisplayStatus.PASSED;
        case 'FAILED':
          return DisplayStatus.FAILED;
        case 'COMPLETE':
          return DisplayStatus.COMPLETE;
        case 'INCOMPLETE':
        case 'yellow':
          return DisplayStatus.INCOMPLETE;
        case 'RECERTIFICATION_TIME':
          return DisplayStatus.RECERTIFICATION_TIME;
        case 'NOT_ATTEMPTED':
        case 'red':
          return DisplayStatus.NOT_ATTEMPTED;
        case 'UN_ASSIGNED':
          return DisplayStatus.UN_ASSIGNED;
        case 'UN_KNOWN':
          return DisplayStatus.UN_KNOWN;
      }
      status = Number(status);
    }

    if ( Number.isNaN(status) ) {
      return DisplayStatus.UN_KNOWN;
    }

    return status >= DisplayStatus.UN_ASSIGNED && status <= DisplayStatus.RECERTIFICATION_TIME ? status : DisplayStatus.UN_KNOWN;
  }

  public static toString(status: string | number): string {
    return DisplayStatus[DisplayStatusHelper.toDisplayStatus(status)].toString();
  }

}
