import { ApiUrls } from '../api.urls';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../global.types';
import { Observable } from 'rxjs';
import {
  GamificationLevel,
  GamificationProfile,
  LearnerGamificationOverviewData,
  LearnerGamificationResponse,
} from './gamification.types';
import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CachedSubject } from '../cached-subject';
import { PreloadService } from '../preload.service';

@Injectable({
  providedIn: 'root',
})
export class LearnerGamificationService {

  gamificationEnabled$: Observable<boolean>

  private _gamificationEnabled$ = new CachedSubject(false);
  constructor(
    private http: HttpClient,
    private preloadService: PreloadService,
  ) {
    this.gamificationEnabled$ = this._gamificationEnabled$.asObservable();

    preloadService.getGamificationEnabled()
      .pipe(tap(gamificationEnabled => {
        this._gamificationEnabled$.next(gamificationEnabled);
      }))
      .pipe(take(1))
      .subscribe();
  }

  public getGamificationData(): Observable<LearnerGamificationOverviewData> {
    const url = ApiUrls.getKey('GamificationOverview');
    return this.http.get<LearnerGamificationResponse>(url)
      .pipe(map(response => response.gamificationData));
  }

  public getGamificationDataForWidget(): Observable<GamificationProfile> {
    const url = ApiUrls.getKey('GamificationWidget');
    return this.http.get<ApiResponse<GamificationProfile>>(url)
      .pipe(map(response => response.gamificationProfile));
  }

  public getXpForGamificationLevel(level: number): number {
    switch ( level ) {
      case 0:
        return GamificationLevel.Level0;
      case 1:
        return GamificationLevel.Level1;
      case 2:
        return GamificationLevel.Level2;
      case 3:
        return GamificationLevel.Level3;
      case 4:
        return GamificationLevel.Level4;
      case 5:
        return GamificationLevel.Level5;
      case 6:
        return GamificationLevel.Level6;
      case 7:
        return GamificationLevel.Level7;
      case 8:
        return GamificationLevel.Level8;
      case 9:
        return GamificationLevel.Level9;
      case 10:
        return GamificationLevel.Level10;
      case 11:
        return GamificationLevel.Level11;
      case 12:
        return GamificationLevel.Level12;
      case 13:
        return GamificationLevel.Level13;
      case 14:
        return GamificationLevel.Level14;
      case 15:
        return GamificationLevel.Level15;
    }
  }
}
