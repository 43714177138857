import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurriculumInfoModule } from '../curriculum-info/curriculum-info.module';
import { ContentInfoButtonDialogComponent } from './content-info-button-dialog.component';
import { ContentInfoButtonComponent } from './content-info-button.component';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { DialogTitleComponent } from '../../dialog-title/dialog-title.component';

@NgModule({
  declarations: [
    ContentInfoButtonComponent,
    ContentInfoButtonDialogComponent,
  ],
  exports: [
    ContentInfoButtonComponent,
    ContentInfoButtonDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    CurriculumInfoModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
})
export class ContentInfoButtonModule {
}
