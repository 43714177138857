import { TableColumnDataType, TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { TableColumnBuilder } from '../../table/table-column.builder';
import { Catalogs } from '../../../core/catalog/catalog.types';
import { LanguageHelper } from '../../../core/language.helper';
import { CatalogHelper } from '../../../core/catalog/catalog.helpers';
import { ContentFilterHelper } from '../../content-filter/content-filter.helper';
import { filterNumberV2 } from '../../../core/column-settings/column-filter.types';
import { FinancialsHelper } from '../../../core/financials/financials.helper';
import { Core } from '../../../core/core.types';


export interface CatalogOverviewColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: CatalogOverviewColumnMenuItemMap;
}

export interface CatalogOverviewColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap,
    CatalogOverviewColumnMenuItemMapVisible,
    CatalogOverviewColumnMenuItemMapFilters,
    CatalogOverviewColumnMenuItemMapSort {
}

export interface CatalogOverviewColumnMenuItemMapVisible {

  catalogState: TableControllerTypes.ColumnMenuItem;
  description: TableControllerTypes.ColumnMenuItem;
  image: TableControllerTypes.ColumnMenuItem;
  title: TableControllerTypes.ColumnMenuItem;

}

export interface CatalogOverviewColumnMenuItemMapFilters {

  catalog: TableControllerTypes.ColumnMenuItem;
  contentType: TableControllerTypes.ColumnMenuItem;
  search: TableControllerTypes.ColumnMenuItem;
  priceRange: TableControllerTypes.ColumnMenuItem;
  tags: TableControllerTypes.ColumnMenuItem;
  categories: TableControllerTypes.ColumnMenuItem;
}

export interface CatalogOverviewColumnMenuItemMapSort
  extends CatalogOverviewColumnMenuItemMapVisible {

  sortAttribute: TableControllerTypes.ColumnMenuItem;
  creationDate: TableControllerTypes.ColumnMenuItem;
}

const defaultColumnBuilder = TableColumnBuilder.start<Catalogs.CatalogEntry>()
  .withType(TableColumnDataType.html)
  .withFilterDefaultValue('')
  .withSelected();

const filterColumnBuilder = TableColumnBuilder.start<Catalogs.CatalogEntry>()
  .withType(TableColumnDataType.dropdown)
  .withFilterDataAccessor(entry => entry)
  .withFilterDefaultValue('')
  .withHiddenDeselected();

export const CATALOG_OVERVIEW_MENU_COLUMNS: CatalogOverviewColumnMenuData = {
  startWith: [],
  endWith: [],
  menuItems: {

    image: defaultColumnBuilder.clone()
      .withColumnId('image')
      .withType(TableColumnDataType.other)
      .withTitle($localize`:@@global_image:Image`)
      .build(),

    title: defaultColumnBuilder.clone()
      .withColumnId('title')
      .withTitle($localize`:@@global_title:Title`)
      .withSortingAccessor(data => LanguageHelper.objectToText(data?.title).toLocaleLowerCase())
      .build(),

    description: defaultColumnBuilder.clone()
      .withColumnId('description')
      .withTitle($localize`:@@global_description:Description`)
      .build(),

    catalogState: defaultColumnBuilder.clone()
      .withType(TableColumnDataType.dropdown)
      .withHiddenSelected()
      .withColumnId('catalogState')
      .withLabel($localize`:@@catalog_booking_state:Booking state`)
      .withFilterStateAttribute('catalogState')
      .withDropDownOptions({
        not_booked: $localize`:@@catalog_not_booked:Available contents`,
        pending: $localize`:@@catalog_pending:Pending booking`,
        booked: $localize`:@@catalog_booked:Booked contents`,
        assigned: $localize`:@@catalog_assigned:Assigned`,
      })
      .withFilterDataAccessor(entry => entry)
      .withFilterMethod((content, filter) =>
        CatalogHelper.isBookingStatusMatching(content, filter?.value ?? ''))
      .withSortingAccessor(data => {
        if ( CatalogHelper.isBookingPending(data.catalogBooking) ) {
          // sort pending contents to the top
          return 0;

        } else if ( CatalogHelper.isBooked(data.catalogBooking) ) {
          // next come the already booked contents
          return 1;

        } else if ( CatalogHelper.isAvailableForBooking(data) ) {
          // then the available ones
          return 2;

        } else if ( CatalogHelper.isAssigned(data) ) {
          // then the manually assigned ones
          return 3;
        }
        // others come last
        return 4;
      })
      .build(),


    /**
     * used as filter
     */
    search: filterColumnBuilder.clone()
      .withColumnId('search')
      .withType(TableColumnDataType.html)
      .withLabel($localize`:@@global_search:Search`)
      .withFilterStateAttribute('search')
      .withFilterMethod((data, filter) => {
        const terms = (filter?.value ?? '').toLocaleLowerCase();
        return ContentFilterHelper.searchInObject(data, terms);
      })
      .withFilterDebounceTimeout(150)
      .build(),

    categories: filterColumnBuilder.clone()
      .withColumnId('categories')
      .withType(TableColumnDataType.multiselect)
      .withLabel($localize`:@@categories:Categories`)
      .withFilterStateAttribute('cat')
      .withDropDownOptions({})
      .withFilterDataAccessor(entry => {
        return (entry?.categories ?? []).map(catId => String(catId));
      })
      .withFilterMethod((data, filter) => {
        return filter.value.split(',').find(id => data.includes(id)) !== undefined;
      })
      .build(),

    contentType: filterColumnBuilder.clone()
      .withColumnId('contentType')
      .withLabel($localize`:@@placeholder_select_type:Select type`)
      .withFilterStateAttribute('contentType')
      .withDropDownOptions({
        lms_curriculum: $localize`:@@select_curriculum:Curriculum`,
        lms_course: $localize`:@@select_course:Course`,
        lms_offlineCnt: $localize`:@@global_event:Event`,
        lms_recording: $localize`:@@global_recording:Recording`,
      })
      .withFilterDataAccessor(entry => {
        if (entry?.objType != Core.DistributableType.lms_course) {
          return entry?.objType
        }
        if (entry?.objSubType === Core.CourseType.Recording) {
          return Core.DistributableType.lms_recording;
        }

        return Core.DistributableType.lms_course;
      })
      .withSortingAccessor(entry => String(entry?.objType).toLocaleLowerCase())
      .build(),

    priceRange: TableColumnBuilder.start<Catalogs.CatalogEntry>()
      .withColumnId('priceRange')
      .withType(TableColumnDataType.price)
      .withHiddenDeselected()
      .withLabel($localize`:@@global_price:Price`)
      .withFilterStateAttribute('priceRange')
      .withFilterDataAccessor(entry => entry)
      .withFilterMethod((data, filter) => {
        const { minPrice, maxPrice } = data;
        return filterNumberV2(FinancialsHelper.fromPriceInCent(minPrice ?? 0), filter) ||
          filterNumberV2(FinancialsHelper.fromPriceInCent(maxPrice ?? 0), filter);
      })
      .build(),

    catalog: filterColumnBuilder.clone()
      .withColumnId('catalog')
      .withType(TableColumnDataType.dropdown)
      .withLabel($localize`:@@global_catalog:Catalog`)
      .withFilterStateAttribute('catalog')
      .withFilterMethod((data, filter) => {

        const filterValue = filter?.value;
        if (!filterValue) {
          // filter is not active
          return true;
        }

        if (!(data?.catalogUUIDs?.length > 0)) {
          // filter is active, but no uuids are included in this content -> no match
          return false;
        }

        // find any matching uuid
        return data.catalogUUIDs.includes(filterValue);
      })
      .build(),


    /**
     * used for sorting from dropdown
     */
    sortAttribute: filterColumnBuilder.clone()
      .withColumnId('sortAttribute')
      .withLabel($localize`:@@overview_placeholder_select_sort:Sort by`)
      .withFilterStateAttribute('sortBy')
      .withFilterDefaultValue('title')
      .withDropDownOptions({
        whenCreated: $localize`:@@catalog_sort_newest:Newest`,
        eventDateFromNow: $localize`:@@catalog_sort_upcoming_first:Upcoming first`,
        title: $localize`:@@global_title:Title`,
        catalogState: $localize`:@@catalog_booking_state:Booking state`,
        contentType: $localize`:@@content_type:Content type`,
        eventDate: $localize`:@@global_date:Date`,
        maxPrice: $localize`:@@global_price:Price`,
      })
      .withDropDownWithoutAll()
      // skip filtering logic
      .withFilterMethod(() => true)
      .build(),

    eventDateFromNow: filterColumnBuilder.clone()
      .withColumnId('eventDate')
      .build(),

    whenCreated: filterColumnBuilder.clone()
      .withColumnId('whenCreated')
      .build(),

    maxPrice: filterColumnBuilder.clone()
      .withColumnId('maxPrice')
      .build(),

    creationDate: filterColumnBuilder.clone()
      .withColumnId('creationDate')
      .withType(TableColumnDataType.dateTime)
      .withSortingAccessor(data => {

        const events = (data?.offlineEventsViews ?? [])
          .map(event => event.creationDate ?? event.eventDate)
          .filter(date => date != null)
          .sort();
        return (events.length > 0) ? events[events.length - 1] : Number.MAX_VALUE;
      })
      .withHiddenDeselected()
      .build(),

    eventDate: filterColumnBuilder.clone()
      .withColumnId('eventDate')
      .withType(TableColumnDataType.dateTime)
      .withSortingAccessor(data => {

        const events = (data?.offlineEventsViews ?? [])
          .map(event => event.eventDate)
          .filter(date => date != null)
          .sort();
        return (events.length > 0) ? events[0] : Number.MAX_VALUE;
      })
      .withHiddenDeselected()
      .build(),

    tags: filterColumnBuilder.clone()
      .withColumnId('tags')
      .withType(TableColumnDataType.tags)
      .withLabel('Tags')
      .build(),

  },
};
