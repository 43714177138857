import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { BreadcrumbsModule } from '../../../../../component/breadcrumbs/breadcrumbs.module';
import { StatusLightsModule } from '../../../../../component/elearning/status-lights/status-lights.module';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { RagCKEditorModule } from '../../../../../core/input/rag-ckeditor/rag-ckeditor.module';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LearnerCourseTodoComponent } from './learner-course-todo.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { FileMultiComponent } from 'src/app/component/file-multi/file-multi.component';
import { FileRenderComponent } from 'src/app/component/file-render/file-render.component';
import { StepSliderComponent } from 'src/app/component/step-slider/step-slider.component';


@NgModule({
  declarations: [
    LearnerCourseTodoComponent,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    BreadcrumbsModule,
    PageHeaderComponent,
    StatusLightsModule,
    PipesModule,
    MatButtonModule,
    FileRenderComponent,
    FileMultiComponent,
    ReactiveFormsModule,
    RagCKEditorModule,
    MatIconModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    StepSliderComponent
  ],
})
export class LearnerCourseTodoModule { }
