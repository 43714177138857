import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../../../core/core.module';
import { UserCourseDetailsComponent } from './user-course-details.component';
import { UserCourseResolver } from './user-course.resolver';
import { UserCourseService } from './user-course.service';
import {
  ControllingCourseMultiActionsModule
} from '../../../../component/controlling/controlling-course-multi-actions/controlling-course-multi-actions.module';
import { ControllingCourseModule } from '../../../../component/controlling/controlling-course/controlling-course.module';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [
    UserCourseDetailsComponent,
  ],
  exports: [
    UserCourseDetailsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    PageHeaderComponent,
    ControllingCourseMultiActionsModule,
    ControllingCourseModule,
  ],
  providers: [
    UserCourseService,
    UserCourseResolver,
  ],
})
export class UserCourseDetailsModule {
}
