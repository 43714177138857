<rag-dialog-header>
  <span i18n="@@header_nav_ctrl_scorm_log">Scorm Log</span>
</rag-dialog-header>

<div mat-dialog-content>

  <rag-table-column-menu
    #tableColumnMenu
    (evtColumnsChanged)="setColumns($event)"
    [menuData$]="columnMenuData$"
  ></rag-table-column-menu>

  <ng-template #tplDataLoading>
    <div class="no-data">
      <rag-loading-indicator
      ></rag-loading-indicator>
    </div>
  </ng-template>

  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <ng-container
      *ngIf="isPaginatorSet; else tplDataLoading"
    >
      <table *ngIf="(dataSource.data?.length > 0)"
             [dataSource]="dataSource" mat-table matSort>

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [row]="row"
              [column]="column"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let course" class="actions" mat-cell></td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter" stickyEnd>
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div class="no-data" *ngIf="isFilteredDataEmpty">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>

  <mat-paginator
    [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-flat-button
    color="primary"
    [disabled]="inputDisabled"
    (click)="exportAsExcel()"
  >
    <mat-icon svgIcon="file-excel"></mat-icon>
    <span i18n="@@pivot_table_export_xlsx">Export to XLSX</span>
  </button>

  <div class="flex-spacer"></div>

  <button
    type="button"
    [mat-dialog-close]
    class="rag-stroked-button"
    mat-stroked-button
  >
    <span i18n="@@global_close">Close</span>
  </button>
</div>
