import { Component, ViewChild } from '@angular/core';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import { MatSelect } from '@angular/material/select';
import { ColumnFilterDropdownOption, dropdownOptionsFactory } from '../../../core/column-settings/column-filter.types';


@Component({
  selector: 'rag-pivot-filter-account-display-status',
  templateUrl: './pivot-filter-account-display-status.component.html',
  styleUrls: [ './pivot-filter-account-display-status.component.scss' ],
})
export class PivotFilterAccountDisplayStatusComponent
  extends AbstractPivotFilterComponent {

  static OPTIONS = {
    green: $localize`:@@status_light_tooltip_certified:Certified`,
    red: $localize`:@@status_light_tooltip_notCertified:Not certified`,
    recertification: $localize`:@@status_light_tooltip_recertificationTime:Recertification`,
    not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
    not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
  };
  @ViewChild('matSelect', { read: MatSelect, static: true }) matSelect: MatSelect;
  dropdownOptions: ColumnFilterDropdownOption[];

  constructor() {
    super();

    this.dropdownOptions = dropdownOptionsFactory(PivotFilterAccountDisplayStatusComponent.OPTIONS);
  }

  clearValue() {
    super.clearValueDropdown(this.matSelect);
  }

  getActionText(): string | null {
    return $localize`:@@report_filter_status_is:Status is...`;
  }

}
