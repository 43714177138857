import { Component, HostBinding, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GenericMessageDialogComponent } from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { InfoService } from '../../../core/info/info.service';
import { MessageKey, OkButton } from '../../../core/info/info.types';
import { SysCheck, SysCheckSupport } from './tech-check.types';
import { CardGrowOnEnterStatic } from '../../../core/animations';
import { MediaMatchService } from '../../../core/media-match.service';

@Component({
  selector: 'rag-tech-check',
  templateUrl: './tech-check.component.html',
  styleUrls: [ './tech-check.component.scss' ],
  animations: [
    CardGrowOnEnterStatic,
  ],
})
export class TechCheckComponent
  implements OnInit {
  SysCheckSupport_Supported = SysCheckSupport.Supported;
  sysCheck: SysCheck;
  sysCheckSupport = SysCheckSupport;
  disableAnimations$ = this.mediaMatch.mediaQueryListeners$['prefers-reduced-motion'];

  constructor(
    private deviceDetector: DeviceDetectorService,
    private infoService: InfoService,
    private mediaMatch: MediaMatchService,
  ) {
    this.sysCheck = new SysCheck(deviceDetector);
  }

  ngOnInit() {
  }

  showHints(key: string): void {
    let messageKey = '';
    switch ( key ) {
      /* OS */
      case 'os Unsupported':
        messageKey = MessageKey.SYSCHECKSUPPORT_UNSUPPORTED;
        break;
      case 'os NotTested':
        messageKey = MessageKey.SYSCHECKSUPPORT_NOTTESTED;
        break;
      case 'os UnsupportedVersion':
        messageKey = MessageKey.SYSCHECKSUPPORT_UNSUPPORTEDVERSION;
        break;

      /* Browser */
      case 'browser Unsupported':
        messageKey = MessageKey.SYSCHECKSUPPORT_browser_UNSUPPORTED;
        break;
      case 'browser UnsupportedVersion':
        messageKey = MessageKey.SYSCHECKSUPPORT_browser_UNSUPPORTEDVERSION;
        break;
      case 'localStorage':
        messageKey = MessageKey.SYSCHECK_ERROR_LOCALSTORAGE_DISABLED;
        break;
      case 'SYCHECK_ERROR_PDF_NOT_SUPPORTED':
        if ( this.sysCheck.browser.name === 'IE' ) {
          messageKey = MessageKey.SYCHECK_ERROR_PDF_NOT_IE_SUPPORTED;
        } else {
          messageKey = MessageKey.SYCHECK_ERROR_PDF_NOT_SUPPORTED;
        }
        break;
      case 'SYSCHECK_ERROR_RESOLUTION_MOBILE':
        messageKey = MessageKey.SYSCHECK_ERROR_RESOLUTION_MOBILE;
        break;
      case 'SYSCHECK_ERROR_COLORDEPTH_16':
        messageKey = MessageKey.SYSCHECK_ERROR_COLORDEPTH_16;
        break;
      case 'SYSCHECK_ERROR_COOKIES_DISABLED':
        messageKey = MessageKey.SYSCHECK_ERROR_COOKIES_DISABLED;
        break;
      case 'SYSCHECK_ERROR_DEVICE_SMARTPHONE':
        messageKey = MessageKey.SYSCHECK_ERROR_DEVICE_SMARTPHONE;
        break;
    }
    this.infoService.showDialog(GenericMessageDialogComponent, {
      titleKey: MessageKey.DIALOG.INFO.TITLE,
      messageKey,
      buttons: OkButton,
    });
  }

  showHintsBrowserHelper(sysCheck: SysCheckSupport) {
    let key = '';
    if ( sysCheck === SysCheckSupport.Unsupported ) {
      key = 'browser Unsupported';
    }
    if ( sysCheck === SysCheckSupport.UnsupportedVersion ) {
      key = 'browser UnsupportedVersion';
    }

    this.showHints(key);
  }

  showHintsOsHelper(sysCheck: SysCheckSupport) {
    let key = '';

    if ( sysCheck === SysCheckSupport.NotTested ) {
      key = 'os NotTested';
    }
    if ( sysCheck === SysCheckSupport.UnsupportedVersion ) {
      key = 'os UnsupportedVersion';
    }
    if ( sysCheck === SysCheckSupport.Unsupported ) {
      key = 'os Unsupported';
    }

    this.showHints(key);
  }

  showTips() {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      titleKey: MessageKey.DIALOG.INFO.TITLE,
      messageKey: MessageKey.SYS_CHECK_8_TIPS,
      buttons: OkButton,
    });
  }

}
