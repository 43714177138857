import { CompareMethod, TransformerMethod } from '../../core/primitives/primitives.types';
import { AnyObject } from '../../core/core.types';
import {
  ColumnFilterDataAccessor,
  ColumnFilterDropdownOption,
  ColumnFilterMethod,
  ColumnFilterMethodAsync,
  ColumnFilterV2,
} from '../../core/column-settings/column-filter.types';
import { FilterResult } from '../../core/column-settings/filter-api.types';
import { UserAttributeType } from 'src/app/core/admin-user-attribute.types';


/**
 * @see UserAttributeType
 */
export enum TableColumnDataType {
  // user attribute types
  date = 'date',
  dropdown = 'dropdown',
  email = 'email',
  multiselect = 'multiselect',
  number = 'number',
  password = 'password',
  radio = 'radio',
  text = 'text',

  // other column types
  dateTime = 'dateTime',
  html = 'html',
  price = 'price',
  tags = 'tags',
  image = 'image',
  other = 'other',
}

export const hasTableColumnDataTypeDate = (
  dataType: TableColumnDataType | null,
): boolean => {
  switch ( dataType ?? '' ) {

    case TableColumnDataType.date:
    case TableColumnDataType.dateTime:
      return true;

    default:
      return false;
  }
};

export const hasTableColumnDataTypeDropDown = (
  dataType: TableColumnDataType | UserAttributeType | null,
): boolean => {
  switch ( dataType ?? '' ) {

    case UserAttributeType.dropdown:
    case UserAttributeType.multiselect:
    case UserAttributeType.radio:
    case TableColumnDataType.dropdown:
    case TableColumnDataType.multiselect:
    case TableColumnDataType.radio:
    case TableColumnDataType.tags:
      return true;

    default:
      return false;
  }
};

export interface TableColumnOptions<ROW_TYPE = any, ID = string, COLUMN_TYPE = any> {
  dataAccessor?: TransformerMethod<ROW_TYPE, COLUMN_TYPE>;
  /**
   * ColumnDataType "other" must be handled individually by columnId
   */
  dataType: TableColumnDataType;
  /**
   * transform the current value to a text to display in a table (for example)
   */
  displayValueAccessor?: TransformerMethod<ROW_TYPE, string | number | null>;
  dropDownOptions?: AnyObject<string>;
  dropDownOptionsColor?: AnyObject<string>;
  dropDownOptionsIcons?: AnyObject<string>;
  dropDownSortMethod?: CompareMethod<ColumnFilterDropdownOption>;
  /**
   * used when filtering {@link TableControllerTypes.ColumnOptions.dropDownOptions} by available content
   */
  dropDownOptionsOriginal?: AnyObject<string>;
  /**
   * TF-3046 do not hide these options if there are no results.
   */
  dropDownOptionsStatic?: string[];
  dropDownWithoutAll?: boolean;
  filter: ColumnFilterV2<ID>;
  /**
   * Function to get attributes from a row.
   */
  filterDataAccessor?: ColumnFilterDataAccessor<ROW_TYPE, ID>;
  /**
   * define a customized debounce timeout for this column (in milliseconds)
   */
  filterDebounceTimeout?: number;
  /**
   * hide this filter in {@link ContentFilterComponent}
   */
  filterHidden?: boolean;
  filterMethod?: ColumnFilterMethod<ID, ROW_TYPE>;
  filterMethodAsync?: ColumnFilterMethodAsync<ID, ROW_TYPE>;
  /**
   * dynamically added
   */
  filterResults?: FilterResult[];
  /**
   * attribute to include in URLs
   */
  filterStateAttribute?: string;
  /**
   * Set an explicit label for the input. This overrides any label from the active action.
   */
  label?: string;
  placeholder?: string;
  /**
   * transform the current value into sortable form (argument is the column value of the current data row)
   */
  sortingAccessor?: TransformerMethod<ROW_TYPE, COLUMN_TYPE>;
}
