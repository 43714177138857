import { Component, OnInit } from '@angular/core';
import { ApiUrls } from '../../../core/api.urls';

@Component({
  selector: 'rag-officials-dashboards',
  templateUrl: './officials-dashboards.component.html',
  styleUrls: [ './officials-dashboards.component.scss' ],
})
export class OfficialsDashboardsComponent
  implements OnInit {

  url: string;

  constructor() {
  }

  ngOnInit() {
    this.url = ApiUrls.getKey('CtrlFad');
  }

}
