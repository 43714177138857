import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoDataV2Component } from './todo-data-v2.component';
import { PipesModule } from '../../../../../../../core/pipes/pipes.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FileMultiComponent } from 'src/app/component/file-multi/file-multi.component';



@NgModule({
  declarations: [
    TodoDataV2Component
  ],
  imports: [
    CommonModule,
    FileMultiComponent,
    PipesModule,
    MatDividerModule,
    MatSliderModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [
    TodoDataV2Component,
  ]
})
export class TodoDataV2Module { }
