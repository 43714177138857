import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { ToggleFullscreenModule } from '../../../component/elearning/toggle-fullscreen/toggle-fullscreen.module';
import { CoreModule } from '../../../core/core.module';
import { ScoWarningComponent } from './sco-warning/sco-warning.component';
import { ScoCloseDialogComponent, ScoComponent } from './sco.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';

@NgModule({
  declarations: [
    ScoComponent,
    ScoWarningComponent,
    ScoCloseDialogComponent,
  ],
  exports: [
    ScoComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ToggleFullscreenModule,
    BreadcrumbsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
})
export class ScoModule {
}
