<button
  (click)="toggle()"
  *ngIf="_expanded"
  class="details-toggle collapse"
  i18n-matTooltip="@@details_row_toggle_collapse"
  mat-icon-button
  matTooltip="hide details"
  matTooltipPosition="above">

  <mat-icon svgIcon="chevron-down"></mat-icon>
</button>

<button
  (click)="toggle()"
  *ngIf="!_expanded"
  class="details-toggle expand"
  i18n-matTooltip="@@details_row_toggle_expand"
  mat-icon-button
  matTooltip="show details"
  matTooltipPosition="above">

  <mat-icon svgIcon="chevron-right"></mat-icon>
</button>
