import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/api.urls';
import { MessageAccountInfo, Principal } from 'src/app/core/core.types';
import { ApiResponse, HttpRequestOptions, TrainResponse } from 'src/app/core/global.types';
import { ModalDialog } from '../../../core/modal-dialog';
import { PrincipalService } from '../../../core/principal/principal.service';
import { MailComposerComponent } from './mail-composer/mail-composer.component';
import { MailBox, MailBoxType, MailEntry, TrainReport } from '../../../core/mail.types';
import { MacroContext } from '../../../core/macros.types';
import { MacrosService } from 'src/app/core/macros.service';
import { MailComposerDialogData } from 'src/app/core/mail-composer.types';

interface MailEntryResponse
  extends MailEntry, TrainResponse {
  message: MailEntry;
}

@Injectable({
  providedIn: 'root',
})
export class MailService {

  constructor(
    private http: HttpClient,
    private dialog: ModalDialog,
    private principalService: PrincipalService,
    private macrosService: MacrosService
  ) {
  }

  convertPrincipalIdsIntoMessageAccountIds(userIds?: Array<number>, groupIds?: Array<number>): Observable<Array<MessageAccountInfo>> {
    const url = ApiUrls.getKey('Mailbox_NewApi_Convert');
    const payload = {};
    if (userIds != null && userIds.length > 0) {
      payload['userIds'] = userIds;
    }
    if (groupIds != null && groupIds.length > 0) {
      payload['groupIds'] = groupIds;
    }
    return this.http.post<ApiResponse<Array<MessageAccountInfo>>>(
      url, payload, HttpRequestOptions).pipe(map(response => response.accounts));
  }

  deleteAttachmentForMessage(messageId: number, fileUUID: string): Observable<boolean> {
    if (messageId == null) {
      messageId = 0;
    }
    const url = `${ApiUrls.getKey('Mailbox_NewApi_Delete_Attachment')}/${messageId}/${fileUUID}`;
    return this.http
      .delete<TrainResponse>(url)
      .pipe(map(response => response.success));
  }

  /**
   * Delete selected mails from server
   * @param toBeDeleted array of mail ids to be deleted
   * @param box ['inbox' | 'outbox' | 'drafts', 'planned']
   */
  deleteByIdAndBox(toBeDeleted: Array<number>, box: string): Observable<boolean> {
    const body = { ...HttpRequestOptions, body: { box, messageIds: toBeDeleted } };
    return this.http
      .request<TrainResponse>('delete', ApiUrls.getKey('Mailbox_NewApi_Delete_Message'), body)
      .pipe(map(() => true));
  }

  fetchReports(): Observable<TrainReport[]> {
    return this.http
      .get<TrainResponse<Array<TrainReport>>>(ApiUrls.getKey('GetReportsForMailComposer'))
      .pipe(map(response => response.data));
  }

  getBox(box: string, pageIndex: number = 0, pageSize: number = 10): Observable<MailBox> {
    const url = `${ApiUrls.getKey('Mailbox_NewApi')}/${box}/${pageIndex}/${pageSize}`;
    return this.http
      .get<TrainResponse<MailBox>>(url)
      .pipe(map(response => response.data))
      .pipe(tap(mailBox => {
        mailBox.messages.forEach(_message => {
          _message.received = _message.received ? new Date(_message.received) : null;
          _message.sent = _message.sendDate ? new Date(_message.sendDate) : null;
          _message.read = 'inbox' !== box || _message.status.indexOf('unread') === -1;
          _message.created = _message.created ? new Date(_message.created) : null;
        });
      }));
  }

  /**
   * The API automatically flags the message as read
   */
  getById(messageId: number): Observable<MailEntry> {
    const url = `${ApiUrls.getKey('Mailbox_NewApi')}/${messageId}`;
    return this.http.get<MailEntryResponse>(url)
      .pipe(map(response => response.message))
      // result is ignored -> no active subscription to clean up
      .pipe(tap(() => this.principalService.getMailsCount(true)));
  }

  markAsReadUnread(toBeMarked: Array<number>, read: boolean): Observable<boolean> {
    const payload = {
      read,
      messageIds: toBeMarked,
    };
    return this.http.post<TrainResponse>(
      ApiUrls.getKey('Mailbox_NewApi_SetRead'),
      JSON.stringify(payload),
      HttpRequestOptions).pipe(map(() => true));
  }

  reply(messageId: number): Observable<MailEntry> {
    const url = `${ApiUrls.getKey('Mailbox_NewApi_Reply')}/${messageId}`;
    return this.http.get<TrainResponse<MailEntry>>(url).pipe(map(response => response.data));
  }

  /**
   * Result "true" indicates mail delivery.
   */
  showMailComposer(mailEntry: MailEntry | null, box: MailBoxType, receivers: Principal[] | null,
    isReply: boolean = false, macroContext: MacroContext = 'Messaging', dialogTitle?: string): Observable<boolean> {
      return this.macrosService.getMacros(macroContext)
        .pipe(switchMap(macroSettings => this.dialog.openModal<MailComposerComponent, MailComposerDialogData, boolean>(MailComposerComponent, {
          panelClass: 'mail-composer-container',
          disableClose: true,
          data: {
            mailEntry,
            box,
            reply: isReply,
            selectedReceivers: receivers,
            dialogTitle,
            trainMacros: macroSettings
          },
        })
        .afterClosed()));
  }

  testSphereDateSpan(sphereDateSpan: string): Observable<void> {
    return this.http.get<any>(ApiUrls.getKeyWithParams('TestSphereDateSpan', '1', sphereDateSpan))
      .pipe(map(response => {
        if ( !(response && response.example) ) {
          throw Error('did not receive a valid response!');
        }
      }));
  }

}
