import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminTextMacrosService } from '../admin-text-macros.service';
import { TextMacro, TextMacroResponse } from '../admin-text-macros.types';

@Injectable({ providedIn: 'root' })
export class AdminTextMacrosListResolver {
  constructor(
    private adminTextMacrosService: AdminTextMacrosService,
  ) {
  }

  resolve(): Observable<TextMacro[]> {
    return this.adminTextMacrosService.getTextMacrosForType([ 'all', 'curriculum', 'course' ]);
  }
}
