<ng-template
  #tplEmptyData
>
  <div class="menu-item empty">
    <ng-container>
      <span i18n="@@global_search_no_data">No matches</span>
    </ng-container>
  </div>
</ng-template>

<button
  #matMenuTrigger
  [matMenuTriggerFor]="columnMenu"
  class="hidden-trigger mat-icon-button"
></button>

<mat-menu
  #columnMenu="matMenu"
  class="sized-menu table-column-menu"
>
  <ng-template matMenuContent>
    <rag-menu-search
      [label]="getSearchLabel()"
      [(value)]="searchFilter"
      (valueChange)="filterHasChanged($event)"
    ></rag-menu-search>

    <div
      (click)="$event.stopPropagation()"
      class="menu-body"
    >

      <div *ngIf="(filteredFilter$ | async).length > 0; else tplEmptyData" class="menu-body-scroll">
        <ul class="table-columns">
          <li
            *ngFor="let menuItem of (filteredFilter$ | async)"
            class="menu-item"
          >
            <mat-checkbox
              color="primary"
              [ngModel]="((selectedFilter$ | async) ?? []).includes(menuItem)"
              (change)="columnToggled(menuItem, $event)"
            >
              <span class="entry-title">{{ menuItem.$view?.title ?? menuItem.title }}</span>
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>

  </ng-template>
</mat-menu>
