import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PreloadService } from '../../../core/preload.service';
import { take, tap } from 'rxjs/operators';
import { LearnerGamificationService } from '../../../core/gamification/learner-gamification.service';

@Injectable({ providedIn: 'root' })
export class GamificationOverviewGuard
  implements CanActivate {

  private _gamificationEnabled = false;

  constructor(
    private learnerGamificationService: LearnerGamificationService,
    private router: Router,
  ) {
    this.learnerGamificationService.gamificationEnabled$
      .pipe(tap(gamificationEnabled => this._gamificationEnabled = gamificationEnabled))
      .pipe(take(1))
      .subscribe();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if ( this._gamificationEnabled ) {
      return of(true);
    }
    return of(this.router.parseUrl('/home'));
  }
}
