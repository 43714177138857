import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TableGroupingControllerComponent } from '../../../../../component/table/table-grouping/table-grouping-controller.component';
import { CtrlSingleUserDetailsCurriculumTypes } from '../ctrl-single-user-details-curriculum/ctrl-single-user-details-curriculum.types';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { ViewHelper } from '../../../../../core/view-helper';
import { DateHelper } from '../../../../../core/date.helper';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { Core, NumberedAnyObject } from '../../../../../core/core.types';
import { CtrlSingleUserDetailsCurriculumEditTypes } from './ctrl-single-user-details-curriculum-edit.types';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { CtrlSingleUserDetailsCurriculumEditColumns } from './ctrl-single-user-details-curriculum-edit.columns';
import { take, tap } from 'rxjs/operators';
import { ControllingSingleUserService } from '../../ctrl-single-user-util/ctrl-single-user.service';


@Component({
  selector: 'rag-ctrl-single-user-details-curriculum-edit',
  templateUrl: './ctrl-single-user-details-curriculum-edit.component.html',
  styleUrls: [ './ctrl-single-user-details-curriculum-edit.component.scss' ],
})
export class CtrlSingleUserDetailsCurriculumEditComponent
  extends TableGroupingControllerComponent<CtrlSingleUserDetailsCurriculumTypes.RowData,
    CtrlSingleUserDetailsCurriculumTypes.RowDataChild,
    CtrlSingleUserDetailsCurriculumTypes.RowDataParent,
    CtrlSingleUserDetailsCurriculumEditColumns.ColumnMenuData> {

  disabledItemIds: NumberedAnyObject<boolean>;
  formGroup: UntypedFormGroup;
  maxDate = new Date();
  private _tableRows: CtrlSingleUserDetailsCurriculumTypes.RowData[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserDetailsCurriculumEditTypes.DialogData,
    private dialogRef: MatDialogRef<CtrlSingleUserDetailsCurriculumEditComponent>,
    private ctrlSingleUserService: ControllingSingleUserService,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);

    this.dataSource.filterPredicate = this.filterPredicate;

    this._tableRows = null;

    this.buildForm();

    this.updateAvailableColumns();
    this.setTableData(this.tableRows);
    this.inputDisabled = false;
  }

  get tableRows(): CtrlSingleUserDetailsCurriculumTypes.RowData[] {
    if ( (this._tableRows == null) && (this.data != null) ) {
      this._tableRows = CtrlSingleUserDetailsCurriculumTypes.Util.toTableRows(this.data.curricula ?? []);
    }
    return this._tableRows;
  }

  private static dialogResultToPostData(results: CtrlSingleUserDetailsCurriculumEditTypes.DialogResultEntry[]):
    CtrlSingleUserDetailsCurriculumEditTypes.PostDataCurriculum[] {
    const curriculumMap = results.reduce((pV, row) => {
      const curriculumId = row.curriculumId;
      const curriculum = pV[curriculumId] = pV[curriculumId] ?? {
        curriculumId,
        itemStatus: [],
      };
      curriculum.itemStatus.push({
        curriculumItemId: row.curriculumItemId,
        accountFinishDate: row.finishDate,
        displayStatus: row.displayStatus,
        locked: row.locked,
      });
      return pV;
    }, {});
    return Object.values(curriculumMap);
  }

  buildForm(): void {
    if ( this.formGroup != null ) {
      return;
    }

    const curriculumItemIds: NumberedAnyObject<boolean> = {};
    this.disabledItemIds = {};
    const controlsConfig = this.tableRows
      ?.map(row => this.asChild(row))
      ?.filter(row => row != null)
      .reduce((pV, item) => {
        const identifier = this.getIdentifier(item);
        if ( identifier == null ) {
          return pV;
        }
        const disabled = this.checkItemDisabled(item, curriculumItemIds);

        pV[identifier + '.locked'] = new UntypedFormControl({
          value: item.locked,
          disabled,
        });
        const displayStatus = DisplayStatusHelper.toDisplayStatus(item.displayStatus);
        pV[identifier + '.displayStatus'] = new UntypedFormControl({
          value: displayStatus,
          disabled,
        });
        pV[identifier + '.finishDate'] = new UntypedFormControl({
          value: item.finishDate,
          disabled: disabled || (displayStatus === DisplayStatus.NOT_ATTEMPTED),
        }, [ Validators.required ]);
        return pV;
      }, {});

    this.formGroup = new UntypedFormGroup(controlsConfig);
  }

  disableInput(key, disabled = true): void {
    const control = this.getControl(key);
    if ( disabled ) {
      control?.disable();
    } else {
      control?.enable();
      control?.markAsTouched();
    }
  }

  getControl(key): AbstractControl {
    return this.formGroup.controls[key];
  }

  getIdentifier(row: CtrlSingleUserDetailsCurriculumTypes.RowDataChild): string | null {
    const curriculumItem = row?.$data;
    if ( curriculumItem == null ) {
      return null;
    }
    return curriculumItem.curriculumId + '.' + curriculumItem.orderIndex;
  }

  getOrderIndex(row: CtrlSingleUserDetailsCurriculumTypes.RowData): number {
    if ( (row?.$parent != null) && (row.orderIndex > -1) ) {
      return row.orderIndex + 1;
    } else {
      return null;
    }
  }

  isDirty(childRow: CtrlSingleUserDetailsCurriculumTypes.RowData, key: string): boolean {
    const viewData = ViewHelper.getViewData(childRow);
    return viewData.dirtyStates?.[key] === true;
  }

  isRowDisabled(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    return this.disabledItemIds[row?.$sortDefaultAsc] === true;
  }

  onCancel(): void {
    this.ctrlSingleUserService.confirmClose(this.dialogRef, this.formGroup?.dirty === true);
  }

  onModelChange(childRow: CtrlSingleUserDetailsCurriculumTypes.RowDataChild, key: string, value: any): void {
    const curriculumItem = childRow?.$data;
    if ( (curriculumItem == null) || !childRow.$data.rbacActions.maySave) {
      return;
    }

    switch ( key ) {
      case 'locked':
        childRow.locked = value === true;
        break;
      case 'displayStatus':
        childRow.displayStatus = DisplayStatusHelper.toDisplayStatus(value);

        const identifier = this.getIdentifier(childRow);
        this.disableInput(identifier + '.finishDate',
          childRow.displayStatus === DisplayStatus.NOT_ATTEMPTED);
        break;
      case 'finishDate':
        childRow.finishDate = value;
        break;
    }

    const viewData = ViewHelper.getViewData(childRow);
    const dirtyStates = viewData.dirtyStates = viewData.dirtyStates || {};
    dirtyStates['locked'] = childRow.locked !== curriculumItem.locked;
    dirtyStates['displayStatus'] = childRow.displayStatus !== curriculumItem.displayStatus;
    dirtyStates['finishDate'] = !DateHelper.equalsDay(childRow.finishDate, curriculumItem.accountFinishDate);
    viewData.isDirty = Object.values(dirtyStates).find(state => state === true) === true;
  }

  onSubmit(): void {
    if ( this.formGroup.pristine || this.formGroup.invalid ) {
      // prevent saving unchanged data
      return;
    }

    const result = this._tableRows
      .map(row => {
        const childRow = this.asChild(row);
        const viewData = ViewHelper.getViewData(childRow);
        if ( viewData?.isDirty !== true ) {
          return null;
        }

        const finishDate = childRow.finishDate;
        let unixFinishDate: number = null;
        if ( (childRow.displayStatus !== DisplayStatus.NOT_ATTEMPTED) && finishDate?.isValid() ) {
          unixFinishDate = finishDate.unix() * 1000;
        }
        const dirtyStates = viewData.dirtyStates = viewData.dirtyStates || {};

        return {
          curriculumId: childRow.$data.curriculumId,
          curriculumItemId: childRow.$data.curriculumItemId,
          displayStatus: childRow.displayStatus,
          // values may be null to leave unchanged
          finishDate: dirtyStates['finishDate'] === true ? unixFinishDate : null,
          locked: dirtyStates['locked'] === true ? childRow.locked : null,
          orderIndex: childRow.$data.orderIndex,
        };
      })
      .filter(row => row != null);

    if ( result.length === 0 ) {
      // no changes -> cancel dialog
      this.dialogRef.close(false);
    } else {
      // ok -> update data and then close dialog
      const postData = CtrlSingleUserDetailsCurriculumEditComponent.dialogResultToPostData(result);
      this.ctrlSingleUserService.saveCurriculumForUser(this.data.userId, postData)
        .pipe(take(1))
        .pipe(tap(() => this.dialogRef.close(true)))
        .subscribe();
    }
  }

  protected filterPredicate = (data): boolean => data.$visible;

  private checkItemDisabled(row: CtrlSingleUserDetailsCurriculumTypes.RowDataChild,
    curriculumItemIds: NumberedAnyObject<boolean>): boolean {
    if ( row.targetType === Core.DistributableType.lms_curriculum ) {
      // you cannot change the status of a child curriculum
      return true;
    }

    const curriculumItemId = row.$data?.curriculumItemId;
    if ( curriculumItemIds[curriculumItemId] ) {
      this.disabledItemIds[row.$sortDefaultAsc] = true;
      return true;
    } else {
      curriculumItemIds[curriculumItemId] = true;
      return false;
    }
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService
      .createFromDefaults(CtrlSingleUserDetailsCurriculumEditColumns.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

}
