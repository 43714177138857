import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { DirtyCheckService } from '../dirty-check.service';
import { LogoutDialogComponent } from '../../component/logout-warning-dialog/logout-dialog.component';
import { ApiUrls } from '../api.urls';
import { RedirectHelper } from '../redirect.helper';
import { PostLoginService } from './post-login.service';
import { InfoService } from '../info/info.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class LogoutService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private dirtyCheckService: DirtyCheckService,
  ) {
  }

  askLogout() {

    const dirtyFlags = this.dirtyCheckService.getGlobalState();
    this.dirtyCheckService.canDeactivate()
      .pipe(take(1))

      .pipe(takeWhile(confirmed => confirmed === true))

      // if is dirty and was confirmed -> immediately log out
      .pipe(switchMap(() => this.infoService.showDialog(LogoutDialogComponent, {})))
      .pipe(catchError(() => EMPTY))
      .pipe(take(1))

      .pipe(switchMap(confirmed => {
        if ( confirmed ) {
          return this.logout();
        }
        this.dirtyCheckService.restoreFlags(dirtyFlags);
        return EMPTY;
      }))

      // reload to reset javascript
      .pipe(map(_ => {
        if ( window.location.hash.startsWith('#/home') ) {
          window.location.reload();
        } else {
          window.location.hash = '#/';
          window.location.reload();
        }
      }))

      .subscribe();
  }

  /**
   * API call to log current user out
   */
  logout() {
    return this.http.get(ApiUrls.getKey('Logout'))
      .pipe(switchMap(RedirectHelper.clearRedirect))
      .pipe(switchMap(PostLoginService.clearPostLoginAction));
  }

}
