<div *ngIf="offlineEvent" class="date title">
  <span *ngIf="isSameDate; else notTheSame">
    {{dateFormatted(eventDate)}}
    <!-- the same day, display date only once & time -->
    <span class="divider">/</span>
    <ng-container *ngIf="hasAgenda; else noAgenda">
      <span class="divider" i18n="@@global_time_from"></span>
      {{timeFormatted(begin)}}
      <span class="divider" i18n="@@global_time_to">-</span>
      {{timeFormatted(end)}}
    </ng-container>
  </span>
</div>


<ng-template #notTheSame>
  <span>{{dateFormatted(eventDate)}} <span class="divider" i18n="@@global_time_to">-</span> {{dateFormatted(eventUntilDate)}}</span>
</ng-template>

<ng-template #noAgenda>
  <span>&nbsp;{{dateFormatted(eventDate, 'LT')}}</span>
</ng-template>