import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { OfflineContent } from '../../../../../../../core/admin-offline.types';

export interface EventParticipantsDateDialogEntry {
  date: moment.Moment;
  firstname: string;
  lastname: string;
  userId: number;
}

export interface EventParticipantsDateDialogData {
  invitationStatus: OfflineContent.OfflineInvitationStatus;
  participants: EventParticipantsDateDialogEntry[];
}

export interface EventParticipantsDateDialogResult {
  participants: EventParticipantsDateDialogEntry[];
}

@Component({
  selector: 'rag-event-participants-date-dialog',
  templateUrl: './event-participants-date-dialog.component.html',
  styleUrls: [ './event-participants-date-dialog.component.scss' ],
})
export class EventParticipantsDateDialogComponent {

  invitationStatusLabel: string;
  participants: EventParticipantsDateDialogEntry[] = [];

  constructor(
    private dialogRef: MatDialogRef<EventParticipantsDateDialogComponent, EventParticipantsDateDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: EventParticipantsDateDialogData,
  ) {
    this.participants = (data?.participants || []);
    this.invitationStatusLabel = EventParticipantsDateDialogComponent.getInvitationStatusLabel(data?.invitationStatus);
  }

  private static getInvitationStatusLabel(invitationStatus: OfflineContent.OfflineInvitationStatus): string {
    if ( invitationStatus == null ) {
      return null;
    }

    switch ( invitationStatus ) {
      case OfflineContent.OfflineInvitationStatus.ack:
        return $localize`:@@admin_offline_event_invitation_status_ack:Participant confirmed`;
      case OfflineContent.OfflineInvitationStatus.can:
        return $localize`:@@admin_offline_event_invitation_status_can:Event cancellation sent`;
      case OfflineContent.OfflineInvitationStatus.dec:
        return $localize`:@@admin_offline_event_invitation_status_dec:Participant declined`;
      case OfflineContent.OfflineInvitationStatus.inv:
        return $localize`:@@admin_offline_event_invitation_status_inv:Invitation sent`;
      case OfflineContent.OfflineInvitationStatus.rem:
        return $localize`:@@admin_offline_event_invitation_status_rem:Reminder sent`;
      case OfflineContent.OfflineInvitationStatus.frontend:
      case OfflineContent.OfflineInvitationStatus.link:
      default:
        return null;
    }
  }

  onConfirm() {
    this.dialogRef.close({
      participants: this.participants,
    });
  }

}
