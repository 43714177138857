import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { RagLayoutModule } from '../../../rag-layout/rag-layout.module';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [
    HomeComponent,
  ],
  exports: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    RagLayoutModule,
    PageHeaderComponent,
    MatButtonModule,
  ],
})
export class HomeModule {
}
