import { EMPTY, Observable } from 'rxjs';
import { CtrlSingleUserCurriculumTypes } from '../ctrl-single-user-curriculum.types';
import { ApiUrls } from '../../../../../core/api.urls';
import { catchError, map } from 'rxjs/operators';
import { InfoType } from '../../../../../core/info/info.types';
import { HttpClient } from '@angular/common/http';
import { InfoService } from '../../../../../core/info/info.service';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class CtrlSingleUserCurriculumNotificationsService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getNotifications(userId: number, curriculumId: number):
    Observable<CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse> {
    const url = ApiUrls.getKey('CtrlSingleUserCurrNotifications')
      .replace('{userId}', String(userId))
      .replace('{curriculumId}', String(curriculumId));
    return this.http.get<CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse>(url);
  }

  recalculateNotifications(userId: number, curriculumId: number): Observable<void> {
    const url = ApiUrls.getKey('CtrlSingleUserCurrRecalculateNotifications')
      .replace('{userId}', String(userId))
      .replace('{curriculumId}', String(curriculumId));
    return this.http.post(url, null)
      .pipe(catchError(this.handleError))
      .pipe(map(() => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  private handleError = (): Observable<void> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

}
