import { Component, OnInit } from '@angular/core';
import { InfoService } from '../../../core/info/info.service';
import { DeleteButton, DeleteCancelButtons, InfoType, MessageKey } from '../../../core/info/info.types';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent, ReportLinksWidgetSettings } from '../widgets.types';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { GenericMessageDialogComponent } from 'src/app/component/generic-message-dialog/generic-message-dialog.component';
import { RagPageService } from '../../rag-page/rag-page.service';
import { ComponentCommunicationService } from 'src/app/core/component-communication.service';
import { ReportService } from 'src/app/core/report/report.service';
import { Report } from 'src/app/core/report/report.types';
import { PermissionService } from '../../../core/principal/permission.service';

@Component({
  selector: 'rag-report-links-widget',
  templateUrl: './report-links-widget.component.html',
  styleUrls: [ './report-links-widget.component.scss' ],
})
export class ReportLinksWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent, OnInit {

  reportUUIDs: ReportLinksWidgetSettings[];

  constructor(
    private navigationService: NavigationService,
    private infoService: InfoService,
    private ragPageService: RagPageService,
    private componentCommunicationService: ComponentCommunicationService,
    private permissoinService: PermissionService,
    private reportService: ReportService,
  ) {
    super();

    this.setTitle($localize`:@@widget_report_links_title:Quick links to reports`);
  }

  ngOnInit() {
    this.refresh();
  }

  onClick(reportData: ReportLinksWidgetSettings) {
    if ( reportData.enabled ) {
      const url = `/report/v2/saved/${reportData.reportUUID}`;
      this.navigationService.navigateByUrl(url);
    } else {
      this.infoService.showDialog(GenericMessageDialogComponent, {
        messageKey: MessageKey.WIDGET_REPORT_LINK_REPORT_DELETED,
      }).subscribe(confirmed => {
        if ( confirmed ) {
          this.doRemoveReportLink(reportData);
        }
      });
    }
  }


  onRemoveReportLink(reportData: ReportLinksWidgetSettings) {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      messageKey: MessageKey.WIDGET_REPORT_LINK_REMOVE_LINK,
      titleKey: MessageKey.REMOVE_REPORT_LINK_CONFIRMATION_TITLE,
      buttons: DeleteCancelButtons,
    }).subscribe(button => {
      if ( button === DeleteButton ) {
        this.doRemoveReportLink(reportData);
      }
    });
  }

  private doRemoveReportLink(reportData: ReportLinksWidgetSettings) {
    const dummyWidget = { ...this.widget };
    delete dummyWidget.settings[reportData.reportUUID];
    if ( Object.keys(dummyWidget.settings).length > 0 ) {
      // update the widgets
      this.ragPageService.replaceWidget(dummyWidget).subscribe(success => {
        if ( success ) {
          this.widget = dummyWidget;
          this.refresh();
        } else {
          this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        }
      });
    } else {
      // remove the widget
      this.ragPageService.removeWidgetById(dummyWidget.pageId, dummyWidget.id).subscribe(success => {
        if ( success ) {
          this.componentCommunicationService.forceReloadLayout(dummyWidget.pageId);
        } else {
          this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        }
      });
    }
  }

  private generateReportUUIDs = (reports: Array<Report>) => {

    const settings = this.widget.settings;
    this.reportUUIDs = reports.reduce((pV, report) => {
      if ( settings[report.reportConfig.uuid] != null ) {
        const reportData = {
          reportUUID: report.reportConfig.uuid,
          title: report.reportConfig.title,
          ownedByMe: report.reportConfig.ownedByMe,
          enabled: false,
        };
        this.permissoinService.rbacGetPermissions(report.reportConfig.uuid, 'report').subscribe(permissins => {
          if ( permissins.find(p => p === 'repr') != null ) {
            reportData.enabled = true;
          }
        });
        pV.push(reportData);
      }
      return pV;
    }, []);
  };

  private refresh() {
    this.reportService.fetchMyReports().subscribe(this.generateReportUUIDs);
  }
}
