<div id="mail-composer">
  <div id="header">
    <span id="title">{{dialogTitle}}</span>
    <span>
      <mat-icon (click)="onDismiss()" svgIcon="close"></mat-icon>
    </span>
  </div>
  <div id="content">
    <div class="select-wrapper">
      <mat-select
        [(value)]="selectedSenderId"
        (ngModelChange)="onRequiredFieldChange()"
        placeholder="Sender"
        i18n-placeholder="@@sender"
        [disabled]="inputDisabled">
        <mat-option *ngFor="let sender of senders" [value]="sender.id">
          {{sender.name}}
        </mat-option>
      </mat-select>
    </div>

    <div class="select-user-groups-wrapper">
      <rag-select-user-groups
        [disabled]="inputDisabled"
        [(ngModel)]="selectedReceivers"
        (ngModelChange)="onRequiredFieldChange()"
        placeholder="Receiver"
        i18n-placeholder="@@receiver"
        appendPopupTo="rag-mail-composer"
      ></rag-select-user-groups>
    </div>

    <!-- Subject -->
    <input
      [formControl]="subjectControl"
      class="subject"
      i18n-placeholder="@@Subject"
      matInput
      placeholder="Subject"
      required
      type="text">

    <ckeditor
      #ckeditor
      [editor]="Editor"
      [config]="ckOptions"
      (ngModelChange)="onRequiredFieldChange()"
      [(ngModel)]="editorData"></ckeditor>

    <rag-mail-settings
      [mailEntry]="mailEntry"
      (composerData)="onComposerData($event)"
      (validChanged)="onMailSettingsValidityChange($event)"
    ></rag-mail-settings>

  </div>

  <div id="actions">
    <button
      mat-stroked-button
      (click)="onSaveAsDraft()"
      *ngIf="canSaveAsDraft$ | async"
      [disabled]="saveAsDraftButtonDisabled$ | async"
      class="fadeIn ml rag-stroked-button">
      <mat-icon svgIcon="email-open"></mat-icon>
      <span i18n="@@save_as_draft">Save as draft</span>
    </button>

    <button
      mat-flat-button
      (click)="onSendMessage()"
      [disabled]="sendButtonDisabled$ | async"
      class="fadeIn m2"
      color="primary">

      <ng-container [ngSwitch]="isActionButtonSend$ | async">

        <ng-container *ngSwitchCase="true">
          <mat-icon svgIcon="send"></mat-icon>
          <span i18n="@@send">Send</span>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <mat-icon svgIcon="floppy"></mat-icon>
          <span i18n="@@global_save">Save</span>
        </ng-container>
      </ng-container>

    </button>
  </div>
</div>
