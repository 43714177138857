import { Component, Input } from '@angular/core';
import { NgForOf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import _default from 'chart.js/dist/core/core.interaction';
import { BadgeCardComponent } from '../cards/badge-card/badge-card.component';
import { Badge, ReducedBadge } from '../../core/gamification/gamification.types';

@Component({
  standalone: true,
  selector: 'rag-badge-slider',
  templateUrl: './badge-slider.component.html',
  imports: [
    NgForOf,
    MatButtonModule,
    MatIconModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    BadgeCardComponent,
  ],
  styleUrls: ['./badge-slider.component.scss']
})
export class BadgeSliderComponent {

  @Input() badges: ReducedBadge[];
  index = 0;
  // badges = Array.from({length: 100}).map((_, i) => `Badge ${i}`);

  onClickArrow(direction: string, viewport: CdkVirtualScrollViewport) {
    console.log(viewport);
    if (direction === 'left') {
      if (this.index === 0) {
        return;
      }
      this.index--;
      viewport.scrollToIndex(this.index, 'smooth');
      return;
    }
    if (this.index === this.badges.length) {
      console.log('38');
      return;
    }
    this.index++;
    viewport.scrollToIndex(this.index, 'smooth');
  }
}
