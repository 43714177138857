import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DoughnutChartComponent } from './doughnut-chart.component';
import { DoughnutChartDirective } from './doughnut-chart.directive';

@NgModule({
  declarations: [
    DoughnutChartComponent,
    DoughnutChartDirective,
  ],
  exports: [
    DoughnutChartComponent,
    DoughnutChartDirective,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    FormsModule,
    MatDividerModule,
  ],
})
export class DoughnutChartModule {
}
