import { Component, OnInit } from '@angular/core';
import { ApiUrls } from '../../../core/api.urls';

@Component({
  selector: 'rag-zuep',
  templateUrl: './zuep.component.html',
  styleUrls: [ './zuep.component.scss' ],
})
export class ZuepComponent
  implements OnInit {

  url: string;

  constructor() {
  }

  ngOnInit() {
    this.url = ApiUrls.getKey('CtrlZuep');
  }

}
