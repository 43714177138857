<div class="logo">
  <div [ngClass]="[cardStyle]">
    <div class="container front">
      <div class="flex">
        <div class="image">
          <img [src]="pictureUrl$ | async" alt="">
        </div>
        <button
          [disabled]="previewMode"
          class="download-button"
          type="button"
          mat-icon-button
          (click)="onDownloadBadge()"
        >
          <mat-icon svgIcon="download"></mat-icon>
        </button>
      </div>
      <div class="information-wrapper">
        <div class="acquired-date">
          <div class="space">
            <span class="received-date" i18n="@@global_received_on">Received on</span>
            <br>
            <div class="date-wrapper">
              <span *ngIf="!previewMode">{{badge?.whenAcquired | formatDate:'date'}}</span>
              <span *ngIf="previewMode">dd.mm.yyyy</span>
              <mat-icon class="check-icon" svgIcon="check"></mat-icon>
            </div>
          </div>
        </div>
        <button
          type="button"
          (click)="onTurnCard('front')"
          mat-icon-button
          class="toggle-button"
        >
          <mat-icon svgIcon="information-outline"></mat-icon>
        </button>
      </div>
      <div
        class="title"
        [matTooltip]="title$ | async"
        [matTooltipShowDelay]="1000"
      >
        <span>{{title$ | async}}</span>
      </div>
    </div>
    <div
      class="container back"
      (click)="onTurnCard('back')"
      [matTooltip]="description$ | async"
      [matTooltipShowDelay]="1000"
    >
      <div class="description-wrapper">
        <span i18n="@@global_description">Description</span>
      </div>
      <div class="info-description">
        <span>{{description$ | async}}</span>
      </div>
    </div>
  </div>
</div>

