<ng-template #tplCatalog>
  <rag-dialog-header [closingEnabled]="false">
    <span i18n="@@booking_in_progress">Booking process</span>
    <!-- <mat-spinner></mat-spinner> -->
  </rag-dialog-header>

  <mat-dialog-content>

    <mat-stepper
      [orientation]="'vertical'"
      [disableRipple]="true"
      [linear]="true"
      #stepper>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="check"></mat-icon>
      </ng-template>

      <!-- Booking step -->
      <mat-step
        *ngFor="let bookingStep of bookingSteps"
        [editable]="false"
        [completed]="bookingStep.complete"
      >
        <ng-template matStepLabel>{{getStepLabel(bookingStep)}}</ng-template>
        <!-- <div class="error" *ngIf="errorMessage" [innerHTML]="errorMessage | safe:'html'"></div> -->
      </mat-step>

      <!-- Done -->
      <mat-step [editable]="false">
        <ng-template matStepLabel>
          <span i18n="@@done">Done</span>
        </ng-template>
      </mat-step>

    </mat-stepper>

  </mat-dialog-content>

  <mat-dialog-actions>

    <div
      *ngIf="closeButtonDisabled$ | async"
      class="wait">
      <span i18n="@@global_please_wait">please wait ...</span>
    </div>

    <button
      *ngIf="!data.isReservation"
      mat-stroked-button
      [disabled]="closeButtonDisabled$ | async"
      (click)="onDialogClose()"
    >
      <span i18n="@@global_continue">Continue</span>
    </button>
  </mat-dialog-actions>

</ng-template>

<ng-template #tplExternalCatalog>

  <rag-dialog-header [closingEnabled]="false">
    <span i18n="@@global_login">Login</span>
  </rag-dialog-header>

  <rag-login-form
    [showTitle]="true"
    [showDoubleOptInSuccessInline]="true"
    [forwardProcessHash]="userProcess?.hash"
  ></rag-login-form>

  <mat-dialog-actions>
    <button
      mat-stroked-button
      (click)="onDialogClose(true)"
    >
      <span i18n="@@global_ok">OK</span>
    </button>
  </mat-dialog-actions>

</ng-template>


<ng-container
  *ngTemplateOutlet="getTemplate(tplExternalCatalog, tplCatalog)"
></ng-container>
