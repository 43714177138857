import { Component, Inject } from '@angular/core';
import { CtrlSingleUserCurriculumTypes } from '../ctrl-single-user-curriculum.types';
import { CtrlSingleUserCurriculumNotificationsColumns } from './ctrl-single-user-curriculum-notifications.columns';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from '../../../../../component/table/table-controller/table-controller.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { CtrlSingleUserCurriculumNotificationsService } from './ctrl-single-user-curriculum-notifications.service';
import { PrincipalService } from '../../../../../core/principal/principal.service';

@Component({
  selector: 'rag-ctrl-single-user-curriculum-notifications',
  templateUrl: './ctrl-single-user-curriculum-notifications.component.html',
  styleUrls: [ './ctrl-single-user-curriculum-notifications.component.scss' ],
})
export class CtrlSingleUserCurriculumNotificationsComponent
  extends TableControllerComponent<CtrlSingleUserCurriculumTypes.CurriculumNotification,
    CtrlSingleUserCurriculumNotificationsColumns.ColumnMenuData> {

  canChangeData = false;

  constructor(
    private ctrlSingleUserCurriculumNotificationsService: CtrlSingleUserCurriculumNotificationsService,
    public dialogRef: MatDialogRef<CtrlSingleUserCurriculumNotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse,
    private principalService: PrincipalService,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);
    this.defaultSort = 'expires';

    this.dataSource.filterPredicate = this.filterPredicateDefault;

    this.updateNotificationData(data);
  }

  onRecalculate(): void {
    this.inputDisabled = true;
    const userId = this.data?.userId;
    const curriculumId = this.data?.curriculumId;
    this.ctrlSingleUserCurriculumNotificationsService.recalculateNotifications(userId, curriculumId)
      .pipe(take(1))
      .pipe(switchMap(() => this.ctrlSingleUserCurriculumNotificationsService
        .getNotifications(userId, curriculumId)))
      .pipe(take(1))
      .pipe(tap(this.updateNotificationData))
      .pipe(finalize(() => this.inputDisabled = false))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(
      CtrlSingleUserCurriculumNotificationsColumns.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateNotificationData = (data: CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse): void => {
    if ( data == null ) {
      // data empty -> close dialog
      this.dialogRef.close();
      return;
    }

    this.canChangeData = data.userId !== this.principalService.userId;
    const notifications: CtrlSingleUserCurriculumTypes.CurriculumNotification[] = data?.notifications;
    this.updateAvailableColumns();
    this.setTableData(notifications ?? []);
    this.inputDisabled = false;
    this.checkFilter();
  };

}
