export enum FileAuthorizationRefType {
  accountimgfile,
  accountstylefile,
  article_attachment,
  base_news_attachment,
  catalogueentry_pic,
  certtemplate,
  contribution,
  course_attachment,
  course_pic,
  curriculum_attachment,
  curriculum_pic,
  dist_category_pic,
  doclib,
  elea_category_pic,
  f2fcontent_attachment,
  f2fcontent_pic,
  f2fevent_attachment,
  f2flocation_attachment,
  language_bpic,
  language_spic,
  message_attachment,
  news_mpic,
  news_spic,
  news_xlpic,
  report,
  user_pic,
  user_import,
  usergroup_pic,
  personalFile,
  notification_attachment,
  selfreg_attachment,
  tbd,
  trainer_pic,
  badge_template_pic,
}

export enum FileAuthorizationType {
    unchecked,
    login_required,
    permission_required,
    personal,
    forbidden,
}
