<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{dateLabel}}</mat-label>
    <input [formControlName]="'date'" [matDatepicker]="date" matInput [required]="required">
    <mat-datepicker-toggle [for]="date" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #date></mat-datepicker>
    <mat-error class="error">
      <span
        *ngIf="hasError('date', 'required')"
        i18n="@@validation_required">This field is required</span>
      <span
      *ngIf="hasError('date', 'invalid')"
      i18n="@@global_end_before_start_date">The end date is too early</span>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="!dateOnly">
    <mat-label>{{timeLabel}}</mat-label>
    <input [formControlName]="'time'" matInput placeholder="HH:MM" [required]="required">
    <!-- todo enable when timepicker available -->
    <!--<mat-icon svgIcon="clock-outline" matSuffix></mat-icon>-->
    <mat-error class="error">
      <span
        *ngIf="hasError('time', 'required')"
        displayOne
        i18n="@@validation_required">This field is required</span>
      <span
        *ngIf="hasError('time', 'timeFormat')"
        displayOne
        i18n="@@validation_invalid_time_format">Format must be 'HH:MM'</span>
      <span
        *ngIf="hasError('time', 'invalid')"
        displayOne
        i18n="@@validation_invalid_time">Time value is invalid</span>
    </mat-error>
  </mat-form-field>

</form>
