import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../core/api.urls';
import { InfoService } from '../../../core/info/info.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { InfoType, MessageKey } from '../../../core/info/info.types';
import { AdminScriptTypes } from './admin-script.types';


interface AccountScriptResponse {
  data: AdminScriptTypes.AccountScriptData;
}

@Injectable({ providedIn: 'root' })
export class AdminScriptService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getAccountScript(): Observable<string> {
    const url = ApiUrls.getKey('AccountScriptAdmin');
    return this.http.get<AccountScriptResponse>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response?.data?.accountScript));
  }

  saveAccountScript(accountScript: string): Observable<string> {
    const url = ApiUrls.getKey('AccountScriptAdmin');
    return this.validateAccountScript(accountScript)
      .pipe(switchMap(() => this.http.post<AccountScriptResponse>(url, JSON.stringify({ accountScript }))))
      .pipe(catchError(this.handleError))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(map(response => response?.data?.accountScript));
  }

  private handleError = (): Observable<never> => {
    this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
    return EMPTY;
  };

  private validateAccountScript(script: string): Observable<string> {
    try {
      // eslint-disable-next-line no-eval
      window.eval(script);
      return of(script);
    } catch ( e ) {
      this.infoService.showMessage($localize`:@@admin_script_test_failed:
        The script failed to execute successfully. Please check for syntax errors!`,
        { infoType: InfoType.Error });
      return EMPTY;
    }
  }

}
