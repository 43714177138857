import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { InputHelper } from '../../../../component/input/input.types';
import { AdminSignaturesService } from '../admin-signatures.service';
import { SubscriptionHolder } from '../../../../core/reactive/subscription-holder';
import { destroySubscriptions } from '../../../../core/reactive/until-destroyed';


@Component({
  selector: 'rag-admin-signatures-input-macro',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './admin-signatures-input-macro.component.html',
  styleUrls: ['./admin-signatures-input-macro.component.scss']
})
export class AdminSignaturesInputMacroComponent
  implements OnChanges, OnDestroy {

  @Input() signatureId: number | null;
  @Input() control: AbstractControl;
  @Input() form: FormGroup;
  @Input() required: boolean = true;

  protected formControl: FormControl | null;

  private _subscription = new SubscriptionHolder<void>(this);

  constructor(
    private adminSignaturesService: AdminSignaturesService,
  ) {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.formControl = this.control as FormControl;
    this._subscription.observable = this.control?.valueChanges
      // we need to observe valueChanges to allow debouncing the resulting http requests
      .pipe(distinctUntilChanged())
      .pipe(tap(() => {
        // ensure the save button is not enabled during calculation
        const formErrors = this.form?.errors ?? {};
        formErrors['loading'] = true;
        InputHelper.setErrors(this.control, formErrors);
      }))
      .pipe(debounceTime(1000))
      .pipe(switchMap(macro => {

        const errors = this.control?.errors ?? {};
        delete errors['loading'];
        delete errors['required'];
        delete errors['includesSpace'];
        delete errors['macroExists'];

        if (!macro || (macro.trim().length === 0)) {
          errors['required'] = this.required;
          return of(errors);
        }

        if (String(macro).indexOf(' ') !== -1) {
          // whitespace is not allowed in macros
          return of({
            includesSpace: $localize`:@@error_no_whitespace_allowed:Whitespace is not allowed`,
          });
        }

        if (!/^[A-z0-9]*$/.test(macro)) {
          // whitespace is not allowed in macros
          return of({
            illegalSymbols: $localize`:@@error_only_latin_alphanumerical:Only latin alphanumerical symbols allowed`,
          });
        }

        return this.adminSignaturesService.preCheckMacroExistsV2(macro).pipe(map(data => {
          const hasConflict = (data?.length > 0) && data
            .find(x => x.id !== this.signatureId) != null;
          if (hasConflict) {
            errors['macroExists'] = $localize`:@@error_macro_already_exists:This macro already exists`;
          } else {
            delete errors['macroExists'];
          }
          return errors;
        }));
      }))
      .pipe(debounceTime(0))
      .pipe(map(errors => {
        InputHelper.setErrors(this.control, errors);
        // do not require focus loss to show error message
        this.control?.markAsTouched();
      }));

    this.control?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getError(control: AbstractControl): string | null {
    return InputHelper.getError(control);
  }

}
