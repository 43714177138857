import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControllingCourseComponent } from '../controlling-course/controlling-course.component';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';


@Component({
  selector: 'rag-controlling-course-multi-actions',
  templateUrl: './controlling-course-multi-actions.component.html',
  styleUrls: [ './controlling-course-multi-actions.component.scss' ],
})
export class ControllingCourseMultiActionsComponent
  implements OnChanges {

  @Input() controllingComponent: ControllingCourseComponent;
  editUserUrl: string;
  maySaveUser = false;
  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;

  multiActionsDisabled(): boolean {

    if ( (this.controllingComponent == null) || this.controllingComponent.inputDisabled ) {
      return true;
    }

    if ( this.maySaveUser ) {
      return false;
    }

    return this.controllingComponent.multiActionsDisabled ||
      !this.controllingComponent.canChangeData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const controllingUser = this.userDetailsView?.controllingUser;
    this.maySaveUser = (controllingUser?.userId > 0) && controllingUser?.rbacActions?.maySave;
    this.editUserUrl = this.maySaveUser ? `/admin/user/edit/${controllingUser.userId}` : '';
  }

}
