export enum InfoType {
  Success,
  Information,
  Warning,
  Error
}

export type DialogButton = number;
export const CancelButton: DialogButton = 1;
export const NoButton: DialogButton = 1 << 1;
export const YesButton: DialogButton = 1 << 2;
export const OkButton: DialogButton = 1 << 3;
export const DeleteButton: DialogButton = 1 << 4;
export const ConfirmButton: DialogButton = 1 << 5;
export const RemoveButton: DialogButton = 1 << 6;
export const DiscardButton: DialogButton = 1 << 7;
export const ActivateButton: DialogButton = 1 << 8;
export const DeactivateButton: DialogButton = 1 << 9;
export const NextButton: DialogButton = 1 << 10;

export const YesNoButtons: DialogButton = YesButton | NoButton;
export const YesNoCancelButtons: DialogButton = YesButton | NoButton | CancelButton;
export const IgnoreButtons: DialogButton = CancelButton | NoButton;
export const DeleteCancelButtons: DialogButton = DeleteButton | CancelButton;
export const ActivateCancelButtons: DialogButton = ActivateButton | CancelButton;
export const DeactivateCancelButtons: DialogButton = DeactivateButton | CancelButton;

export interface InfoDialogOptions<T = any> {
  buttons: DialogButton;
  data?: T;
  message?: string;
  messages?: Array<string>;
  messageKey?: string;
  title?: string;
  titleKey?: string;
  closeable?: boolean;
}

export interface InfoServiceMessageOptions {
  buttons?: DialogButton;
  durationInSeconds?: number;
  infoType?: InfoType;
  title?: string;
  closeable?: boolean;
}

export interface SnackBarOptions {
  durationInSeconds?: number;
  message?: string;
}

export interface SnackBarData {
  infoType: InfoType;
  message?: string;
  messageKey: string;
}

/**
 * @deprecated please use $localize instead
 */
export class MessageKey {
  static ACC_LOGIN_PAGE_DATA_NOT_SAFED = 'acc_login_page_data_not_safed';
  static ACC_LOGIN_PAGE_DATA_SAFED = 'acc_login_page_data_safed';
  static ACC_PICTURE_DELETED = 'acc_picture_deleted';
  // Administration --> account design
  static ACC_PICTURE_NOT_DELETED = 'acc_picture_not_deleted';
  static ACC_STYLE_GET_IMAGES_FAILED = 'acc_style_get_images_failed';
  static ACC_STYLE_SETTINGS_NOT_SAFED = 'acc_style_settings_not_safed';
  static ACC_STYLE_SETTINGS_SAFED = 'acc_style_settings_safed';
  static ASSIGN_CONTENT = {
    CONFIRM_SAVE: 'assign_content_confirm_save',
    SAVE_SUCCESS: 'assign_content_save_success',
    CONFIRM_RESET: 'assign_content_confirm_reset',
    INVISIBLE_CHANGES: {
      CONFIRM: 'assign_content_invisible_changes_confirm',
    },
  };
  // Controlling
  static CNTRL = {
    USER: {
      CERT: {
        ITERATION: {
          CERTIFY: 'ctrl-single-user-iteration-certify',
          END: 'ctrl-single-user-iteration-end',
          RESET: 'ctrl-single-user-iteration-reset',
        },
      },
    },
  };
  static COLUMN_CONF = {
    COLUMN_CONF_DESCRIPTION_DROPDOWN: 'column_conf_description_dropdown',
    COLUMN_CONF_DESCRIPTION_FAIL: 'column_conf_description_fail',
    COLUMN_CONF_DESCRIPTION_IDENTIFIER: 'column_conf_description_identifier',
    COLUMN_CONF_DESCRIPTION_SELECTABLE: 'column_conf_description_selectable',
    COLUMN_CONF_DESCRIPTION_DISABLED: 'column_conf_description_disabled',
    COLUMN_CONF_DESCRIPTION_DEFAULT_COLUMNS: 'column_conf_description_default_columns',
    COLUMN_CONF_DESCRIPTION_POOL_COLUMNS: 'column_conf_description_pool_columns',
    COLUMN_CONF_DESCRIPTION_STATIC: 'column_conf_description_static',
    COLUMN_CONF_DESCRIPTION_TITLE: 'column_conf_description_title',
  };
  static CONTACT_NO_CONTACT_SET_ERROR = 'contact_no_contact_set_error';
  static CONTACT_VALIDATION_LEAVE_WITHOUT_SAVE = 'contact_validation_leave_without_save';
  // Contact
  static CONTACT_VALIDATION_LEAVE_WITHOUT_SAVE_TITLE = 'contact_validation_leave_without_save_title';
  static DIALOG = {
    CONFIRM: {
      TITLE: 'general_dialog_confirm_title',
      CANCEL: 'general_dialog_cancel_text',
    },
    INFO: {
      TITLE: 'general_dialog_info_title',
    },
    PLEASECONFIRM: {
      TITLE: 'general_dialog_pleaseconfirm_title',
    },
    UNSAVED_CLOSE: 'dialog_unsaved_close',
  };
  // general
  static ERROR = {
    NOT_AUTHORIZED: 'error_not_authorized',
  };
  static EXT_LOGIN = {
    DELETE: {
      CONFIRM: 'ext_login_delete_confirm',
      SUCCESS: 'ext_login_delete_success',
      FAILED: 'ext_login_delete_fail',
    },
    SAVE: {
      ERROR: {
        EXISTS: 'ext_login_save_error_exists',
      },
    },
  };
  static FILES = {
    REMOVE: {
      CONFIRM: 'files_remove_confirm',
    },
    UPLOAD: {
      SUCCESS: 'file_upload_success',
      FAILED: 'file_upload_error',
    },
  };
  static FILE_DOWNLOAD_ERROR = 'file_download_error';
  static FILE_DOWNLOAD_SUCCESS = 'file_download_success';
  // Administration --> report generator
  static FILE_UPLOAD_ERROR = 'file_upload_error';
  static FILE_UPLOAD_SUCCESS = 'file_upload_success';
  // file upload
  static FILE_UPLOAD_WRONG_APIURLKEY = 'file_upload_wrong_apiurlkey';
  static GENERAL_API_RESPONSE = 'general_api_response';
  static GENERAL_DELETE_CONFLICT = 'general_delete_conflict';
  static GENERAL_DELETE_SUCCESS = 'general_delete_success';
  static GENERAL_ERROR = 'general_error';
  static GENERAL_FILE_ERROR = 'general_file_error';
  static GENERAL_FILE_NOT_READABLE = 'general_file_not_readable';
  static GENERAL_SAVE_SUCCESS = 'general_save_success';
  static GENERAL_UNSAVED_CHANGES = 'general_unsaved_changes';
  static LERN_CNT = {
    TODO: {
      SUBMIT: 'lern_cnt_todo_submit',
    },
  };
  static LINKED_USERS_ADD_LINK_TO_USER_FAIL = 'linked_users_add_link_to_user_fail';
  static LINKED_USERS_ADD_LINK_TO_USER_SUCCESS = 'linked_users_add_link_to_user_success';
  static LINKED_USERS_ALREADY_EXIST = 'linked_users_already_exist';
  static LINKED_USERS_DELETE_LINK_TO_USER_FAIL = 'linked_users_delete_link_to_user_fail';
  static LINKED_USERS_DELETE_LINK_TO_USER_SUCCESS = 'linked_users_delete_link_to_user_success';
  // Linked Users
  static LINKED_USERS_GET_AUTH_TOKEN_FAIL = 'linked_users_get_auth_token_fail';
  static LINKED_USERS_GET_OWN_UUID_FAIL = 'linked_users_get_own_uuid_fail';
  static LINKED_USERS_IS_CURRENT_USER = 'linked_users_current_user';
  static LINKED_USERS_LOGIN_WITH_TOKEN_FAIL = 'linked_users_login_with_token_fail';
  static LINKED_USERS_LOGIN_WITH_TOKEN_SUCCESS = 'linked_users_login_with_token_success';
  static LINKED_USERS_LOGOUT_FAIL = 'linked_users_logout_fail';
  // Login
  static LOGIN_FORM_LOGIN_FAILED_ERROR = 'login_form_login_failed_error';
  // mails
  static Mails = {
    MAILBOX_ATTACHMENT_NOT_FOUND: 'mailbox_attachment_not_found',
    MAILBOX_EMAIL_MULTIPLE_DELETED: 'mailbox_email_multiple_deleted',
    MAILBOX_EMAIL_SINGLE_DELETED: 'mailbox_email_single_deleted',
    MAILS_ERROR_PLANNED_IN_PAST: 'mails_error_planned_in_past',
    MAILS_ERROR_WHEN_SAVING: 'mails_error_when_saving',
    MAILS_ERROR_WHEN_SENDING: 'mails_error_when_sending',
    MAILS_MARK_AS_READ_UNREAD_ERROR: 'mails_mark_as_read_unread_error',
    MAILS_MARK_AS_READ_UNREAD_SUCCESS: 'mails_mark_as_read_unread_success',
    MAILS_SAVED_AS_DRAFT: 'mails_saved_as_draft',
    MAILS_SAVED: 'mails_saved',
    MAILS_SUCCESSFULLY_SENT: 'mails_successfully_sent',
    ATTACHMENT_IS_SUCCESSFUL: 'attachment_is_successful',
    ATTACHMENT_IS_NOT_SUCCESSFUL: 'attachment_is_not_successful',
    ATTACHMENT_REPORT_GENERATION_AS_RECEIVER_FAILED: 'attachment_report_generation_as_receiver_failed',
    Delete_Mail: 'delete_mail',

  };
  static NEWS = {
    DELETE: {
      CONFIRM: 'news_delete_confirm',
    },
  };
  static OFFLINE_CNT = {
    LEARNER: {
      CONTENT_NOT_EXECUTABLE: 'offline_cnt_learner_content_not_executable',
      EVENT_BOOKING_FAILED: 'content_offline_event_failed_to_book',
      EVENT_BOOKING_SUCCESS: 'content_offline_event_booked',
      EVENT_BOOKING_REQUEST_SUCCESS: 'content_offline_event_booked_requested',
      EVENT_BOOKING_CANCEL: 'content_offline_event_booking_cancel',
    },
    SCHEDULE: {
      DELETE: {
        CONFIRM: 'offline_cnt_event_schedule_delete_confirm',
        CONFIRM_BODY: 'offline_cnt_event_schedule_delete_confirm_body',
        FAILED: 'offline_cnt_event_schedule_delete_failed',
      },
      SAVE: {
        FAILED: 'offline_cnt_event_schedule_save_failed',
        SUCCESS: 'offline_cnt_event_schedule_copy_success',
      },
      STATUS: {
        SUCCESS: 'offline_cnt_event_schedule_status_success',
        ABORTED: 'offline_cnt_event_schedule_status_aborted',
        CANCELED: 'offline_cnt_event_schedule_status_canceled',
      },
      COPY: {
        CONFIRM: 'offline_cnt_event_copy_confirm',
      },
      LEARNER: {
        CHANGED: 'offline_cnt_event_schedule_learner_changed',
      },
    },
    LOCATION: {
      DELETE: 'offline_cnt_location_remove',
      REMOVE: 'offline_cnt_event_location_remove',
    },
    TRAINER: {
      DELETE: 'offline_cnt_trainer_delete',
    },
    PROVIDER: {
      REMOVE: 'offline_cnt_provider_remove',
    },
    TIMEBLOCK: {
      REMOVE: 'offline_cnt_timeblock_remove',
    },
    EVENT: {
      LOCATION: {
        REMOVE: 'offline_cnt_event_location_remove',
      },
      PROVIDER: {
        REMOVE: 'offline_cnt_event_provider_remove',
      },
      DELETE: {
        CONFIRM: 'offline_cnt_event_delete_confirm',
        SUCCESS: 'offline_cnt_event_delete_success',
      },
    },
  };
  static PROVIDERS = {
    NO_DATA: 'providers_no_data',
  };
  static RAGPAGE_ADD_WIDGET_FAILED_EXISTS_ALREADY = 'ragpage_add_failed_exists_already';
  // password reset
  static RAGPAGE_ADD_WIDGET_SUCCESS = 'ragpage_add_widget_success';
  static RAGPAGE_ADD_WIDGET_UNKNOWN_UUID_ERROR = 'ragpage_add_widget_unknown_uuid_error';
  // rag-page
  static RAGPAGE_ERROR_CANNOT_ADD_WIDGET = 'ragpage_error_cannot_add_widget';
  static RAGPAGE_ERROR_CANNOT_REMOVE_WIDGET = 'ragpage_error_cannot_remove_widget';
  static RAGPAGE_REALLY_WANT_TO_RESET_WIDGETS = 'ragpage_really_want_to_reset_widgets';
  static RAGPAGE_REALLY_WANT_TO_RESET_WIDGETS_TITLE = 'ragpage_really_want_to_reset_widgets_title';
  static RAGPAGE_REMOVE_WIDGET_CONFIRMATION_TEXT = 'remove_widget_confirmation_text';
  static RAGPAGE_REMOVE_WIDGET_CONFIRMATION_TITLE = 'remove_widget_confirmation_title';
  static RAGPAGE_REMOVE_WIDGET_SUCCESS = 'ragpage_remove_widget_success';
  static RAGPAGE_SAVE_LAYOUT_ERROR = 'ragpage_save_layout_error';
  static RAGPAGE_SAVE_LAYOUT_SUCCESS = 'ragpage_save_layout_success';
  static REMOVE_REPORT_CONFIRMATION_TITLE = 'remove_report_confirmation_title';
  static REMOVE_REPORT_LINK_CONFIRMATION_TITLE = 'remove_report_link_confirmation_title';
  // report generator
  static REPORT = {
    REPORT_TARGET_TYPE_NOT_FOUND: 'report_target_type_not_found',
  };
  static REPORT_CONFIRM_DELETE = 'report_confirm_delete';
  static REPORT_SAVED = 'report_has_been_saved';
  static REPORT_SAVE_FAILED = 'report_save_failed';
  static REPORT_WOULD_YOU_LIKE_TO_RESET = 'report_would_you_like_to_reset';
  static SCO = {
    // SCO execution
    SCO_COMFIRM_DOCUMENT_READ: 'sco_confirm_document_read',
    SCO_PRINCIPAL_CANNOT_BE_FOUND: 'sco_principal_cannot_be_found',
    SCO_SESSION_ALREADY_RUNNING: 'sco_session_already_running',
    SELECTED_SCO_CANNOT_BE_EXECUTED: 'selected_sco_cannot_be_executed',
    SELECTED_SCO_CANNOT_BE_FOUND: 'selected_sco_cannot_be_found',
  };
  static SYCHECK_ERROR_PDF_NOT_IE_SUPPORTED = 'syscheck_error_pdf_not_ie_supported';
  static SYCHECK_ERROR_PDF_NOT_SUPPORTED = 'syscheck_error_pdf_not_supported';
  static SYSCHECKSUPPORT_NOTTESTED = 'syschecksupport_nottested';
  // OS hint-error
  static SYSCHECKSUPPORT_UNSUPPORTED = 'syschecksupport_unsupported';
  static SYSCHECKSUPPORT_UNSUPPORTEDVERSION = 'sysCheckSupport_UnsupportedVersion';
  // Browser hint-error
  static SYSCHECKSUPPORT_browser_UNSUPPORTED = 'syschecksupport_browser_unsupported';
  static SYSCHECKSUPPORT_browser_UNSUPPORTEDVERSION = 'syschecksupport_browser_unsupportedversion';
  static SYSCHECK_ERROR_COLORDEPTH_16 = 'syscheck_error_colordepth_16';
  static SYSCHECK_ERROR_COOKIES_DISABLED = 'syscheck_error_cookies_disabled';
  // static SYS_HINT_ERROR = 'sys-hint-error';
  static SYSCHECK_ERROR_DEVICE_SMARTPHONE = 'syscheck_error_device_smartphone';
  static SYSCHECK_ERROR_LOCALSTORAGE_DISABLED = 'syscheck_error_localstorage_disabled';
  /* mobile error */
  static SYSCHECK_ERROR_RESOLUTION_MOBILE = 'syscheck_error_resolution_mobile';
  // sys-check
  static SYS_CHECK_8_TIPS = 'sys_check_8_tips';
  static LOCATION = {
    NO_DATA: 'empty_location',
  };
  static USER_DATA = 'user_data';
  static WIDGET_CONF_DESCRIPTION_DELETABLE = 'widget_conf_description_deletable';
  static WIDGET_CONF_DESCRIPTION_DRAGABLE = 'widget_conf_description_dragable';
  static WIDGET_CONF_DESCRIPTION_FAIL = 'widget_conf_description_fail';
  // Administration --> account design --> widgets
  static WIDGET_CONF_DESCRIPTION_INITIAL = 'widget_conf_description_initial';
  static WIDGET_CONF_DESCRIPTION_ONLY_ONCE = 'widget_conf_description_only_once';
  static WIDGET_CONF_DESCRIPTION_RESIZABLE = 'widget_conf_description_resizable';
  static WIDGET_CONF_DESCRIPTION_SELECTABLE = 'widget_conf_description_selectable';
  static WIDGET_CONF_DESCRIPTION_SHOW_AT_TOP = 'widget_conf_description_show_at_top';
  static WIDGET_CONF_DESCRIPTION_STATIC = 'widget_conf_description_static';
  // Widgets
  static WIDGET_REPORT_LINK_REMOVE_LINK = 'widget_report_link_remove_link';
  static WIDGET_REPORT_LINK_REPORT_DELETED = 'widget_report_link_deleted';
}

export const MessageConstants = {

  DIALOG: {

    MESSAGE: {
      UNSAVED_CLOSE: $localize`:@@dialog_unsaved_close:You have unsaved changes. Would you like to save it now?`,
    },

    TITLE: {
      CONFIRM: $localize`:@@general_dialog_title_please_confirm:Please Confirm`,
      INFO: $localize`:@@general_dialog_info_title:Information`,
      WARNING: $localize`:@@global_warning:Warning`,
    },

  },

  ERRORS: {
    GENERAL: $localize`:@@general_error:The last operation failed. Please try again later.`,

    PRELOAD: {
      ERR_OAUTH_001: $localize`:@@api_error_oauth_001:The requested authorization configuration could not be found.`,
      // todo... add other possible error codes to display after a http redirect
    },

    USER_IMPORT: {
      ERR_U_023: $localize`:@@user_import_error_invalid_format:The import data file is not properly formatted`
    }
  },

  API: {
    // ToDo add colon to sentence
    SUCCESS: $localize`:@@general_save_success:The data has been saved successfully`,
    ERROR: $localize`:@@general_error:The last operation failed. Please try again later.`,
  },

  UPLOAD: {
    SUCCESS: $localize`:@@general_file_upload_success:The file has been uploaded successfully`,
  }

};
