import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { CoreModule } from 'src/app/core/core.module';
import { isNothing } from 'src/app/core/utils';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatIconModule
  ],
  selector: 'rag-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: [ './google-map.component.scss' ],
})
export class GoogleMapComponent implements OnInit, OnChanges {

  @Input() location: OfflineContent.Location;
  @Input() showCoordinates = true;

  url: string;
  showMap = false;

  constructor() {
  }

  get imgEmbeddedLocation() {
    return this.url + '&output=embed';
  }

  ngOnInit() {
    this.buildUrl();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.showCoordinates = this.showCoordinates && (!isNothing(this.location) &&
      !isNothing(this.location.latitude) &&
      !isNothing(this.location.longitude)
    );
    this.buildUrl();
  }

  onShowMap($event) {
    this.showMap = true;
  }

  private buildUrl() {
    if (!isNothing(this.location.latitude) && !isNothing(this.location.longitude)) {
      this.url = `https://www.google.com/maps?z=16&t=m&q=loc:${this.location.latitude}+${this.location.longitude}`;
    } else {
      this.url = `https://www.google.com/maps?q=${this.location.address1},${this.location.zip},${this.location.city}`;
    }
  }

}

