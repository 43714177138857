import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminEventActionsNavigationComponent } from './admin-event-actions-navigation.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    AdminEventActionsNavigationComponent,
  ],
  exports: [
    AdminEventActionsNavigationComponent,
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatSidenavModule,
    RouterModule,
    MatMenuModule,
  ],
})
export class AdminEventActionsNavigationModule { }
