import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavItem, NavItemSpecial} from '../../core/navigation/navigation.types';
import {HeaderNavHelper} from '../header/header-nav.helper';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import {Observable} from 'rxjs';
import {PrincipalService} from '../../core/principal/principal.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {LinkedUsersService} from '../../core/user/link/linked-users.service';
import {LinkedUserTypes} from '../../core/user/link/linked-users.types';
import {LanguageHelper, LanguageInfo} from '../../core/language.helper';
import {PipesModule} from '../../core/pipes/pipes.module';
import {NavigationService, PathInfo} from '../../core/navigation/navigation.service';
import {tap} from 'rxjs/operators';
import {destroySubscriptions, takeUntilDestroyed} from '../../core/reactive/until-destroyed';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[rag-top-navigation]',
  standalone: true,
  imports: [CommonModule,MatButtonModule,MatIconModule,RouterModule,MatBadgeModule,MatMenuModule,MatDividerModule,PipesModule,MatTooltipModule,],
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnDestroy, OnInit {
  @Input() navItems: NavItem[];
  @Input() isSmall: boolean = false;
  currentLanguage: LanguageInfo;
  currentLanguagePath: PathInfo;
  languages: LanguageInfo[];
  logout: NavItem;

  readonly mailsCount$: Observable<number>;
  readonly linkedUsers$: Observable<LinkedUserTypes.LinkedUserDropdownEntry[]>;
  constructor(
    public headerNavHelper: HeaderNavHelper,
    private linkedUsersService: LinkedUsersService,
    private navigationService: NavigationService,
    private principalService: PrincipalService,
  ) {
    this.linkedUsers$ = this.linkedUsersService.getLinkedUsersDropDown();
    this.mailsCount$ = this.principalService.mailsCount$;
    this.principalService.isLogged$
      .pipe(tap(() => this.updateLanguageInfo()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.logout = this.navItems.find(item => item.special === NavItemSpecial.logout);
  }
  onMenuOpenLinkedUser(): void {
    this.linkedUsersService.getLinkedUsers();
  }

  onSwitchAccount(user: LinkedUserTypes.LinkedUser): void {
    this.linkedUsersService.showDialogForSwitchingAccount(user);
  }

  onSwitchToLanguage(language: LanguageInfo): void {
    const languageKey = language?.key;
    const trainLanguage = language?.trainLanguage;
    const languagePath = NavigationService.getLanguagePathFromLocale(languageKey);
    if ( trainLanguage && this.currentLanguagePath?.languagePath !== languagePath ) {
      try {
        localStorage.setItem('switched_language', trainLanguage);
      } catch ( e ) {
        if ( console && console.error ) {
          console.error(e);
        }
      }
      this.navigationService.switchToLocale(languageKey);
    }
  }

  private updateLanguageInfo(): void {
    this.languages = LanguageHelper.LANGUAGES;
    this.currentLanguagePath = this.navigationService.getCurrentLanguage();
    this.currentLanguage = LanguageHelper.getLanguage(this.currentLanguagePath?.language);
  }

}
