import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { State } from './app.state';
import { ApplicationStateService, ViewMode } from './core/application-state.service';
import { PrincipalService } from './core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from './core/reactive/until-destroyed';
import { ThemeService } from './core/theme.service';
import { PreloadService } from './core/preload.service';
import { ErrorHelper } from './core/error.helper';
import { InfoType, MessageConstants } from './core/info/info.types';
import { ApiError } from './core/global.types';
import { InfoService } from './core/info/info.service';
import { SEO } from './core/seo.types';
import { Meta } from '@angular/platform-browser';
import { isNothing } from './core/utils';
import { AccountDesignService } from './route/admin/account-design/account-design.service';
import { BotService } from './core/bot.service';

declare global {
  interface Window {
    globals: any;
  }
}

@Component({
  selector: 'rag-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent
  implements OnDestroy, OnInit {

    botURL$: Observable<string>;
  isLogged$: Observable<boolean>;
  loading$: Observable<boolean>;
  sidenavVisible$: Observable<boolean>;
  viewModeDefault$: Observable<boolean>;
  @HostBinding('class.beta') showBeta = false;
  showBetaToggle = false;
  seo: SEO;
  showNewNavigation = false;

  constructor(
    private appState: ApplicationStateService,
    private infoService: InfoService,
    private meta: Meta,
    private preloadService: PreloadService,
    private principalService: PrincipalService,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private accountDesignService: AccountDesignService,
    private botService: BotService
  ) {
    this.isLogged$ = this.principalService.isLogged$;
    this.themeService.onInit();
    this.loading$ = this.appState.loading$;
    AppComponent.fetchBaseHref();
    this.observeViewport();
    this.observeViewMode();
    AppComponent.removePageLoading();

    this.viewModeDefault$ = this.appState.viewMode$
      .pipe(map(mode => mode === ViewMode.DEFAULT));

    this.sidenavVisible$ = combineLatest([ this.appState.sidenavVisible$, this.appState.viewMode$ ])
      .pipe(map(([ sidenavVisible, viewMode ]) => sidenavVisible && (viewMode === ViewMode.DEFAULT)));

    this.botURL$ = this.botService.visible$
      .pipe(map(event => event.visible ? event.url : undefined))
      .pipe(tap(url => {
        if (url !== undefined) {
          if (document.getElementById('botApi') !== null) {
            return;
          }
          const scriptEl = document.createElement("script");
          scriptEl.id = 'botApi'
          scriptEl.type='text/javascript';
          scriptEl.src='https://ai.reflact.info/api/botapi.js';
          document.body.appendChild(scriptEl);
        }
      }));
  }

  static removePageLoading() {
    const preload = window.document.getElementById('PAGE_LOADING');
    if ( preload && preload.parentElement ) {
      preload.parentElement.removeChild(preload);
    }
  }

  ngOnInit(): void {

    const error = ErrorHelper.findFirstApiError({ errors: this.preloadService.errors });
    const errorMessage = AppComponent.getErrorMessage(error);
    if ( errorMessage != null ) {
      this.infoService.showMessage(errorMessage, { infoType: InfoType.Error });
    }
    this.meta.addTag({name: 'robots', content: 'noindex, nofollow'});

    this.preloadService.getSEO()
      .pipe(tap(data => {
        this.seo = data;

        const robots = this.seo?.robots;
        this.meta.removeTag('name="robots"');
        if (!isNothing(robots)) {
          this.meta.addTag({name: 'robots', content: robots});
        }

        const description = this.seo?.description;
        if (!isNothing(description)) {
          this.meta.addTag({name: 'description', content: description});
        }

        const keywords = this.seo?.keywords;
        if (!isNothing(keywords)) {
          this.meta.addTag({name: 'keywords', content: keywords});
        }

        const author = this.seo?.author;
        if(!isNothing(author)) {
          this.meta.addTag({name: 'author', content: author});
        }
      }))
      .pipe(take(1))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => {
        this.showBeta = permissions.showBeta;
        this.showBetaToggle = permissions.showBetaToggle;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.accountDesignService.newNavigationEnabled$
      .pipe(tap(newNavigationEnabled => {
        this.showNewNavigation = newNavigationEnabled;
      }))
      .pipe(take(1))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  private static fetchBaseHref() {
    const baseHref = window.location.pathname;
    if ( baseHref !== '/' && baseHref.length >= 3 ) {
      let offset = 2;
      if ( baseHref.endsWith('/') ) {
        offset++;
      }
      State.language = baseHref.substr(baseHref.length - offset, 2);
    }
  }

  private static getErrorMessage(error: ApiError | null): string | null {

    if ( error == null ) {
      return;
    }

    const message = MessageConstants.ERRORS.PRELOAD[String(error ?? '')];
    if ( message != null ) {
      return message;
    }

    return MessageConstants.ERRORS.GENERAL;
  }



  toggleSideNav(): void {
    this.appState.toggleSidenav();
  }

  private observeViewMode() {
    this.appState.viewMode$
      .pipe(map(viewMode => {
        if ( viewMode === ViewMode.FULLSCREEN ) {
          this.renderer.addClass(document.documentElement, 'is-fullscreen');
        } else {
          this.renderer.removeClass(document.documentElement, 'is-fullscreen');
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private observeViewport() {
    this.appState.viewportState$
      .pipe(map(value => {
        if ( value.isGtMedium() ) {
          this.appState.setSidenavVisible(false);
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }
}
