import { sanitize } from 'dompurify';


export class TextDecodeHelper {

  public static cleanHtml(value?: string): string {
    // replace nbsp as sanitize apparently does not do so
    value = String(value ?? '').replace(/&nbsp;/gmi, ' ');

    // remove any tags and most html entities
    value = sanitize(value, { ALLOWED_TAGS: [ 'br', 'a', 'li', 'ul', 'ol', 'img', 'strong', 'em', 'u' ] });

    return value;
  }

  public static decodeHtml(value?: string, keepFormat?: boolean): string {

    // ensure we have a valid string
    value = String(value ?? '');

    // replace <a> tags with the url
    value = value.replace(/<a[^>]*\shref\s*=\s*"([^"]+)"[^>]*>([^<]+)<\/a>/gmi, '$2 ( $1 )');

    // replace nbsp as sanitize apparently does not do so
    value = value.replace(/&nbsp;/gmi, ' ');

    // remove any tags and most html entities
    if ( keepFormat ) {
      value = sanitize(value, { ALLOWED_TAGS: [ 'br', 'p', 'li', 'ul', 'ol' ] });
      value = value.replace(/<br.+?>|<ul.+?>|<ol.+?>/gmi, '\n')
        .replace(/<p.+?>/gmi, '\n\n\n')
        .replace(/<li>/gmi, '\n- ')
        .replace(/\n\n/gmi, '\n')
        .replace(/<.+?>/gmi, '');
    } else {
      value = sanitize(value, { ALLOWED_TAGS: [] });
    }

    // replace any remaining html fragments
    const node = document.createElement('textarea');
    node.innerHTML = value;
    value = node.innerText;

    return value;
  }

  public static encodeHtml(
    value?: string,
  ): string {
    return (value ?? '').replace(/[\u00A0-\u9999<>&]/gim, i => '&#' + i.charCodeAt(0) + ';');
  }

}
