<div
  *ngIf="date"
  [ngClass]="{'mode-date': mode === 'date', 'mode-time': mode === 'time'}"
  class="container"
>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'date'">
      <div [ngClass]="timeSpanClassFor" class="humanized">{{timeSpan}}</div>
      <div class="date">{{dateFormatted}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'time'">
      <div class="date">{{date | formatDate:'date'}}</div>
      <div class="time">{{date | formatDate:'time'}}</div>
    </ng-container>
  </ng-container>
</div>
