import { Component } from '@angular/core';
import { BaseQuestFragmentComponent } from '../../quest.types';

@Component({
  selector: 'rag-quest-type-paragraph',
  templateUrl: './quest-type-paragraph.component.html',
  styleUrls: ['./quest-type-paragraph.component.scss']
})
export class QuestTypeParagraphComponent extends BaseQuestFragmentComponent {

}
