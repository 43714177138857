import { CKEditor5 } from '@ckeditor/ckeditor5-angular/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { isNothing } from '../../utils';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';

export class RagCKEditorHelper {

  public static disableCKEditorSourceEditing(
    editor: CKEditor5.Editor | null,
  ): void {

    const plugins = editor?.plugins;
    if ( plugins == null ) {
      return;
    }

    try {
      // disable source editing before fetching data
      plugins.get('SourceEditing').set('isSourceEditingMode', false);
    } catch ( e ) {
      // ignore
    }
  }

  public static disableSourceEditing(
    editorComponent: CKEditorComponent | null,
  ): void {
    RagCKEditorHelper.disableCKEditorSourceEditing(editorComponent?.editorInstance);
  }

  public static reduceCKEditorItems(removeItems: string[], editor: Editor): void {
    let ckEditorItems: Array<string> = editor?.defaultConfig?.toolbar?.items;
    ckEditorItems = ckEditorItems?.filter(item => removeItems?.includes(item) === false);
    editor.defaultConfig.toolbar.items = ckEditorItems;
  }

  public static setCKEditorItems(items: string[], editor: Editor): void {
    editor.defaultConfig.toolbar.items = items;
  }

}
