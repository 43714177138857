import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { State } from '../../app.state';
import { LanguageHelper } from '../language.helper';

@Pipe({
  name: 'localeText',
})
export class LocaleTextPipe
  implements PipeTransform {

  private readonly _locale: string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    this._locale = LanguageHelper.localeToLanguage(locale);
  }

  transform(value: any, locale?: string): any {
    locale = this.getLocale(locale);
    return LanguageHelper.objectToText(value, locale);
  }

  protected getLocale(locale?: string): string {
    locale = LanguageHelper.localeToLanguage(locale);
    if ( locale ) {
      return locale;
    } else if ( this._locale ) {
      return this._locale;
    }
    return State.language;
  }

}
