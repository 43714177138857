import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

let accountKey: string;

@Injectable({
  providedIn: 'root',
})
export class AccountInterceptor
  implements HttpInterceptor {

  static getAccountKey(): string {
    if ( !accountKey ) {
      accountKey = AccountInterceptor.readAccountKeyFromStorage();
    }
    return accountKey;
  }

  static readAccountKey(): void {
    accountKey = null;
    const param = /[?&]key=([^&#]+)(?:[#&].+)?/.exec(document.location.href);
    if ( param && param.length ) {
      AccountInterceptor.writeAccountKeyToStorage(param[1]);
    } else {
      accountKey = AccountInterceptor.readAccountKeyFromStorage();
    }
  }

  static readAccountKeyFromStorage(): string {
    try {
      return localStorage.getItem('train_accountKey');
    } catch ( e ) {
      if ( console && console.error ) {
        console.error(e);
      }
    }
    return null;
  }

  static writeAccountKeyToStorage(key: string): void {
    try {
      accountKey = key;
      localStorage.setItem('train_accountKey', key);
    } catch ( e ) {
      if ( console && console.error ) {
        console.error(e);
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( accountKey ) {
      req = req.clone({
        headers: req.headers.set('acckey', accountKey),
      });
    }
    return next.handle(req);
  }

}
