import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileSectionTypes } from '../../../component/input/profile-section/profile-section.types';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileResponse } from './user-profile.types';
import { UserProfileHelper } from './user-profile.helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { ProfileFieldTypes } from '../../../core/input/profile-field/profile-field.types';
import { UserProfileService } from './user-profile.service';
import { AnyObject } from '../../../core/core.types';
import { EMPTY, Observable, of } from 'rxjs';
import { InfoService } from '../../../core/info/info.service';
import { CancelButton, InfoType, MessageConstants, YesButton } from '../../../core/info/info.types';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { CachedSubject } from 'src/app/core/cached-subject';
import { UserProfileCredentialsService } from './user-profile-credentials/user-profile-credentials.service';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { GenericMessageDialogComponent } from 'src/app/component/generic-message-dialog/generic-message-dialog.component';
import {PreloadService} from '../../../core/preload.service';


@Component({
  selector: 'rag-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: [ './user-profile.component.scss' ],
})
export class UserProfileComponent
  implements OnInit, OnDestroy {

  formGroup: FormGroup | null;
  isEditModeActive = false;
  isLoading = true;
  isReadOnly = false;
  loginField: ProfileFieldTypes.ProfileField | null;
  passwordField: ProfileFieldTypes.ProfileField | null;
  sections: ProfileSectionTypes.ProfileSection[];
  isAuthenticated = false;
  isSaveButtonDisabled$: Observable<boolean>;
  userProfileExportable = false;

  private _isSaveButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private infoService: InfoService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private userProfileCredentialsService: UserProfileCredentialsService,
    private userProfileService: UserProfileService,
    private principalService: PrincipalService,
    private router: Router,
    private preloadService: PreloadService
  ) {
    this.isSaveButtonDisabled$ = this._isSaveButtonDisabled$.asObservable();
  }

  hasEditCredentials(): boolean {
    return this.passwordField?.editable;
  }

  isUserProfileExportable(): boolean {
    return this.userProfileExportable;
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(routeData => this.updateRouteData(routeData?.profile)))
      .pipe(tap(_ => {
        this.formGroup.statusChanges.pipe(map(status => {
          this._isSaveButtonDisabled$.next(status !== 'VALID' || this.formGroup.pristine);
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.principalService.permissionStates$.pipe(map(permissions => {
      this.isAuthenticated = permissions.userProfileDeactivate;
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.preloadService
      .isUserProfileExportable$
      .pipe(takeUntilDestroyed(this))
      .subscribe(enabled => this.userProfileExportable = enabled);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  isInputDisabled(): boolean {
    return this.isLoading || !this.isEditModeActive;
  }

  async onEdit() {
    this.isEditModeActive = true;
  }

  onCancel(): void {
    this.isLoading = true;
    this.isEditModeActive = false;
    this.userProfileService.getProfile()
      .pipe(take(1))
      .pipe(tap(data => {
        this.updateRouteData(data);
      }))
      .subscribe();
  }

  onEditCredentials(): void {
    this.userProfileCredentialsService.showDialog({
      loginField: this.loginField,
      passwordField: this.passwordField,
      userId: this.principalService.currentUser.userId
    })
      .pipe(take(1))
      .pipe(tap(() => this.onCancel()))
      .subscribe();
  }

  onSave($event?: MouseEvent): void {

    $event?.preventDefault();

    const changes = this.sections
      .flatMap(o => o.fields ?? [])
      .reduce((pV, field) => {

        const fieldId = field.fieldId;
        const control = this.formGroup.get(fieldId);
        if ( control == null || control.pristine ) {
          return pV;
        }

        pV[fieldId] = ProfileFieldTypes.fromControlValue(field.type, control.value);
        return pV;
      }, {} as AnyObject);

    let pipe: Observable<void> = of(void (0));
    if ( changes.hasOwnProperty('pass') ) {
      const password = changes.pass;
      delete changes.pass;
      pipe = this.userProfileService.updatePassword(password);
    }

    let language: string = null;
    if ( changes.hasOwnProperty('language') ) {
      language = changes.language;
      delete changes.language;
      pipe = pipe
        .pipe(switchMap(_ => this.userProfileService.updateLanguage(language)));
    }

    if ( Object.values(changes).length > 0 ) {
      pipe = pipe
        .pipe(switchMap(_ => this.userProfileService.updateProfile(changes)));
    }

    this.isLoading = true;
    pipe
      .pipe(catchError(error => {
        const message = UserProfileHelper.getErrorMessage(error) ?? MessageConstants.API.ERROR;
        this.infoService.showMessage(message, { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(switchMap(_ => this.userProfileService.getProfile()))
      .pipe(tap(data => {
        this.infoService.showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success });
        this.isEditModeActive = false;
        this.updateRouteData(data);
        if ( language != null ) {
          this.navigationService.switchToLocale(language);
        }
      }))
      .pipe(take(1))
      .subscribe();
  }

  onDeactivate() {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      title: $localize`:@@global_warning:Warning`,
      messages: [
        $localize`:@@profile_deactivate_confirm_1:If you continue, your account will be deactivated and you will be logged out.`,
        $localize`:@@profile_deactivate_confirm_2:Once deactivated, you will no longer be able to enter the account.`,
        $localize`:@@profile_deactivate_confirm_3:Contact support if you need access again.`,
        '<br>',
        $localize`:@@profile_deactivate_confirm_4:Would you like to continue?`,
      ],
      buttons: YesButton | CancelButton
    })
    .pipe(takeWhile(button => button === YesButton))
    .pipe(switchMap(_ => this.userProfileService.deactivate()))
    .pipe(switchMap(success => {
      if (success) {
        return this.infoService.showMessage($localize`:@@profile_deactivate_success:Your profile has been deactivated.`, {
          title: $localize`:@@global_success:Success`
        });
      } else {
        return this.infoService.showMessage($localize`:@@profile_deactivate_failed:Deactivation of your profile failed. Please contact your system administrator for more information.`, {
          title: $localize`:@@global_success:Success`
        });
      }}
    ))
    // .pipe(switchMap(_ => this.router.navigate(['/'])))
    .subscribe();
  }

  private buildForm(
    data: UserProfileResponse | null,
  ): FormGroup | null {

    const formGroup = (this.formGroup ??= new UntypedFormGroup({}));
    UserProfileHelper.mergeFormGroup(data, formGroup, this.isEditModeActive);
    return formGroup;
  }

  private updateRouteData(
    data: UserProfileResponse | null,
  ) {

    const passwordField = UserProfileHelper.getAttributeField(data, 'pass');
    if ( !passwordField?.editable ) {
      console?.warn('password change must be allowed to change the login');
      this.loginField = this.passwordField = null;
    } else {
      passwordField.value = '';
      this.passwordField = passwordField;
      this.loginField = UserProfileHelper.getAttributeField(data, 'login');
    }

    this.sections = UserProfileHelper.asProfileSections(data);
    this.isReadOnly = !UserProfileHelper.hasEditableAttributes(data);
    this.formGroup = this.buildForm(data);
    this.isLoading = false;
  }

}
