import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";
import { ApiUrls } from "../api.urls";
import { Core } from "../core.types";
import { ApiResponse, HttpRequestOptions } from "../global.types";
import { isNothing } from "../utils";
import { Quests } from "./quest.types";

@Injectable({ providedIn: 'root' })
export class QuestService {

  constructor(
    private http: HttpClient
  ) {}

  getContentSettings(objType: Core.DistributableType, objId: number): Observable<Quests.Quest2ContentResponse> {
    const url = `${ApiUrls.getKey('Quests')}/content/${objType}/${objId}/settings`;
    return this.http.get<Quests.Quest2ContentResponse>(url)
      .pipe(map(response => ({
        settings: response.settings,
        quests: response.quests
      })));
  }

  saveContentSettings(objType: Core.DistributableType, objId: number, contentSettings: Map<Quests.Type, Quests.ContentSettings>): Observable<void> {
    const url = `${ApiUrls.getKey('AdminQuest')}/content/${objType}/${objId}/settings`;
    const settings = [];
    if (!isNothing(contentSettings)) {
      contentSettings.forEach((value, _) => {
        settings.push(value);
      });
    }
    const payload = {
      objId,
      objType,
      settings
    };
    return this.http.post<ApiResponse<void>>(url, payload, HttpRequestOptions).pipe(map(_ => void(0)));
  }

  fetchCtrlQuests(): Observable<Quests.CtrlQuestEntry[]> {
    const url = ApiUrls.getKey('CtrlQuests');
    return this.http.get<ApiResponse<Array<Quests.CtrlQuestEntry>>>(url)
      .pipe(map(response => response.quests));
  }

  fetchCtrlQuest(objType: Core.DistributableType, objId: number, userId: number): Observable<Quests.CtrlQuestResponse> {
    const url = `${ApiUrls.getKey('CtrlQuests')}/content/${objType}/${objId}/user/${userId}`;
    return this.http.get<ApiResponse<Quests.CtrlQuestResponse>>(url)
      .pipe(map(response => response.questData))
      .pipe(tap(questData => questData.ctrlQuest.objType = objType));
  }

  submitSupFeedback(objType: Core.DistributableType, objId: number, userId: number, feedback: Quests.Feedback): Observable<Quests.Feedback> {
    const url = `${ApiUrls.getKey('CtrlQuests')}/content/${objType}/${objId}/user/${userId}`;
    return this.http.post<ApiResponse<Quests.Feedback>>(url, feedback, HttpRequestOptions).pipe(map(response => response.feedback));
  }

  submitCtrlFeedback(questUUID: string, objType: Core.DistributableType, objId: number,
    feedback: Quests.Feedback): Observable<Quests.Feedback>
  {
    const url = `${ApiUrls.getKey('Quests')}/${questUUID}/content/${objType}/${objId}/user/${feedback.targetUserId}`;
    return this.http.post<ApiResponse<Quests.Feedback>>(url, feedback, HttpRequestOptions)
      .pipe(map(response => response.feedback));
  }

  submitFeedback(questUUID: string, objType: Core.DistributableType, objId: number, feedback: Quests.Feedback): Observable<Quests.Feedback>
  {
    const url = `${ApiUrls.getKey('Quests')}/${questUUID}/content/${objType}/${objId}`;
    return this.http.post<ApiResponse<Quests.Feedback>>(url, feedback, HttpRequestOptions)
      .pipe(map(response => response.feedback));
  }

  fetchQuest(questUUID: string): Observable<Quests.Quest> {
    const url = `${ApiUrls.getKey('AdminQuest')}/${questUUID}`;
    return this.http.get<ApiResponse<Quests.Quest>>(url).pipe(map(response => response.quest));
  }

  fetchFeedback(questUUID: string, objType: Core.DistributableType, objId: number): Observable<Quests.Feedback> {
    const url = `${ApiUrls.getKey('Quests')}/${questUUID}/content/${objType}/${objId}`;
    return this.http.get<ApiResponse<Quests.Feedback>>(url)
      .pipe(map(response => response.feedback));
  }

  fetchQuestCtrlData(questUUID: string, objType: Core.DistributableType, objId: number, userId: number): Observable<Quests.CtrlQuestResponse> {
    const url = `${ApiUrls.getKey('Quests')}/${questUUID}/content/${objType}/${objId}/user/${userId}`;
    return this.http.get<ApiResponse<Quests.CtrlQuestResponse>>(url)
      .pipe(map(response => response.questData));
  }

  fetchQuestForContent(objId: number, objType: Core.DistributableType): Observable<Quests.Quest2ContentResponse> {
    const url = `${ApiUrls.getKey('Quests')}/content/${objType}/${objId}`;
    return this.http.get<ApiResponse<Quests.Quest2ContentResponse>>(url)
      .pipe(map(response => response.data));
  }
}