<ng-template
  #tplEmptyData
>
  <div
    class="menu-item empty"
  >
    <rag-loading-indicator
      *ngIf="loading"
    ></rag-loading-indicator>
    <ng-container
      *ngIf="!loading"
    >
      <span i18n="@@global_search_no_data">No matches</span>
    </ng-container>
  </div>
</ng-template>


<button
  #matMenuTrigger
  [matMenuTriggerFor]="columnMenu"
  class="hidden-trigger mat-icon-button"
  data-cy="buttonMenuColumn"
></button>

<mat-menu
  #columnMenu="matMenu"
  (close)="onMenuClose()"
  class="sized-menu table-column-menu"
>
  <ng-template matMenuContent>
    <rag-menu-search
      [label]="getSearchLabel()"
      [(value)]="filter"
      (valueChange)="filterChanged()"
    ></rag-menu-search>

    <div
      (click)="$event.stopPropagation()"
      class="menu-body"
      [ngClass]="{disabled: columnsUpdating, 'stores-settings': showResetButton}"
    >

      <rag-loading-indicator
        *ngIf="columnsUpdating"
        class="columns-updating"
      ></rag-loading-indicator>

      <div
        *ngIf="filterCountDisplay > 0; else tplEmptyData"
        class="menu-body-scroll"
      >
        <ul
          (cdkDropListDropped)="columnMoved($event)"
          [cdkDropListData]="filteredMenuItems"
          [cdkDropListOrientation]="'vertical'"
          cdkDropList
          class="table-columns"
        >

          <li
            *ngFor="let menuItem of filteredMenuItems"
            [cdkDragData]="menuItem"
            [cdkDragLockAxis]="'y'"
            cdkDrag
            class="menu-item"
          >
            <mat-checkbox
              color="primary"
              [ngModel]="menuItem.selected"
              (ngModelChange)="columnToggled(menuItem, $event)"
              [disabled]="isDisabled(menuItem)"
              data-cy="menuItemCheckboxColumn"
            >
              <mat-icon
                *ngIf="menuItem.hasFilter"
                svgIcon="filter"
              ></mat-icon>

              <span class="entry-title">{{menuItem.$view?.title ?? menuItem.title}}</span>
            </mat-checkbox>

            <div
              cdkDragHandle
            >
              <mat-icon
                svgIcon="dots-horizontal"
              ></mat-icon>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      *ngIf="showResetButton"
      class="menu-footer"
      (click)="$event.stopPropagation()"
    >
      <button
        type="button"
        mat-flat-button
        (click)="onRemoveSettings()"
        data-cy="menuItemResetToDefaults"
      >
        <span i18n="@@table_column_menu_remove_settings">Reset to defaults</span>
      </button>
      <button
        *ngIf="permissions.saveDefaultColumnSettings"
        type="button"
        mat-flat-button
        (click)="saveAsDefault()"
        data-cy="menuItemSaveAsDefault"
      >
        <span i18n="@@table_column_save_as_default">Save as default</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
