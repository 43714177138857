import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Content } from '../../../../core/content/content.types';
import { DisplayStatusHelper } from '../../../../core/display-status-helper';
import { DisplayStatus } from '../../../../core/display-status.enum';
import {
  LearnerCurriculumAccountView,
  LearnerCurriculumItemAccountView,
} from '../../../../core/learner-account/learner-account.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { AccountDesignService } from '../../../admin/account-design/account-design.service';
import { StyleSettings } from '../../../admin/account-design/account-design.types';
import { ContentHelper } from '../../content/content.helper';


@Component({
  selector: 'rag-context-overview',
  templateUrl: './context-overview.component.html',
  styleUrls: [ './context-overview.component.scss' ],
})
export class ContextOverviewComponent
  implements OnDestroy {

  assignmentType: 'mandatory' | 'voluntary';
  backUrl: string;
  contextItem: LearnerCurriculumItemAccountView;
  curriculum: LearnerCurriculumAccountView;
  curriculumItemTitle: string;
  displayStatus: DisplayStatus;
  legacyContent: Content;
  styleSettings$: Observable<StyleSettings>;
  typeOfTraining: string;

  constructor(
    private accountDesignService: AccountDesignService,
    private route: ActivatedRoute,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();

    this.route.data
      .pipe(map(response => response.data || {}))
      .pipe(map(this.initContent))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.initContent(this.route.snapshot.data.data || {});
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  private initContent = (data): void => {
    if ( this.curriculum === data.curriculum ) {
      // skip duplicate initialization
      return;
    }

    const curriculum = this.curriculum = data.curriculum;
    const contextItem = this.contextItem = data.curriculumItem;
    this.backUrl = '/content/' + curriculum.curriculumId;

    const itemId = contextItem.curriculumItemId;
    // use title from curriculum for breadcrumbs
    const itemTitle = this.curriculumItemTitle = curriculum.itemTitles && curriculum.itemTitles[itemId] || contextItem.title;

    this.setAssignmentType(curriculum);
    const additionalData = contextItem.additionalData || {};
    const jsonData = additionalData.json || {};
    this.typeOfTraining = ContentHelper.getTypeOfTrainingLabel(jsonData.typeoftraining);
    this.displayStatus = DisplayStatusHelper.toDisplayStatus(contextItem.displayStatus);

    this.legacyContent = {
      displaystatus: contextItem.displayStatus,
      title: itemTitle,
      type: contextItem.targetType,
      archived: undefined,
      assignmentType: undefined,
      description: undefined,
      hasDirectAssignment: undefined,
      hideInLearnerAccount: undefined,
      id: undefined,
      items: undefined,
      lastModified: undefined,
      objType: undefined,
      sequentialCur: undefined
    };
  };

  private setAssignmentType(curriculum) {
    if ( curriculum.assignmentMandatory === true ) {
      this.assignmentType = 'mandatory';
    } else if ( curriculum.assignmentMandatory === false ) {
      this.assignmentType = 'voluntary';
    } else {
      this.assignmentType = null;
    }
  }

}
