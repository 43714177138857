import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ImageableContentReference} from '../../../../core/core.types';
import {Observable} from 'rxjs';
import {CachedSubject} from '../../../../core/cached-subject';
import {IconHelper} from '../../../../core/icon.helper';
import {DistributionTypeHelper} from '../../../../core/distribution-type-helper';
import {ViewHelper} from '../../../../core/view-helper';
import {ImageUrlHelper} from '../../../../core/image-url-helper';
import {Catalogs} from '../../../../core/catalog/catalog.types';
import {Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {CatalogHelper} from '../../../../core/catalog/catalog.helpers';

@Component({
  selector: 'rag-catalog-curriculum-card',
  templateUrl: './catalog-curriculum-card.component.html',
  styleUrls: ['./catalog-curriculum-card.component.scss']
})
export class CatalogCurriculumCardComponent implements OnChanges {

  @Input() content!: ImageableContentReference;
  @Input() contentLink: string | null;
  iconForType: string = '';
  itemCount = 0;
  items: Catalogs.CatalogCurriculumItem[] = [];
  selectedItem: Catalogs.CatalogCurriculumItem;
  selectedItemIndex = 0;

  readonly cardBackgroundStyles$: Observable<any | null>;
  readonly DEFAULT_PICTURE_URL = 'assets/images/card_curriculum.jpg';
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL);

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get objSubTypeAsText(): string {
    return DistributionTypeHelper.asText(this.content?.objType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( Object.prototype.hasOwnProperty.call(changes, 'content') ) {
      this.updateImageUrl();
    }

    this.iconForType = IconHelper.svgIconForObjType(this.content?.objType, this.content?.objSubType);

    this.items = this.content?.items ?? [];
    if (this.items.length > 0 && this.selectedItem == null) {
      this.selectedItem = this.items[0];
    }
    this.itemCount = this.items.length;
  }

  isAssigned(bookable: Catalogs.Bookable): boolean {
    return CatalogHelper.isAssigned(bookable);
  }

  isBooked(catalogBooking?: Catalogs.CatalogBooking): boolean {
    return CatalogHelper.isBooked(catalogBooking);
  }

  onNavigateToDetails(): void {
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  selectedItemChanged(mode: 'previous' | 'next', $event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (mode === 'previous') {
      const previousSchedule = this.items?.[this.selectedItemIndex - 1];
      if (previousSchedule != null) {
        this.selectedItem = previousSchedule;
        this.selectedItemIndex -= 1;
        return;
      }
      this.selectedItem = this.items[this.itemCount - 1];
      this.selectedItemIndex = (this.itemCount - 1);
      return;
    }

    const nextSchedule = this.items?.[this.selectedItemIndex + 1];
    if (nextSchedule != null) {
      this.selectedItem = nextSchedule;
      this.selectedItemIndex += 1;
      return;
    }
    this.selectedItem = this.items[0];
    this.selectedItemIndex = 0;
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }
}
