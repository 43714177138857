import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StyleSettings } from '../../../route/admin/account-design/account-design.types';
import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
  ],
  selector: 'rag-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: [ './header-logo.component.scss' ],
})
export class HeaderLogoComponent {

  readonly styleSettings$: Observable<StyleSettings>;

  constructor(
    private accountDesignService: AccountDesignService,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();
  }

}
