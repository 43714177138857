import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CurriculumLinkDialogService } from '../curriculum-link-dialog/curriculum-link-dialog.service';
import { Content } from '../../../core/content/content.types';
import { take } from 'rxjs/operators';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { ContentService } from '../../../core/content/content.service';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { ImageableContentReference } from 'src/app/core/core.types';


@Component({
  selector: 'rag-curriculum-locked-by',
  templateUrl: './curriculum-locked-by.component.html',
  styleUrls: [ './curriculum-locked-by.component.scss' ],
})
export class CurriculumLockedByComponent
  implements OnChanges {

  @Input() content: ImageableContentReference;
  @Input() version: number = 1;
  @HostBinding('class.hidden') hostHidden = false;

  constructor(
    private contentService: ContentService,
    private curriculumLinkDialogService: CurriculumLinkDialogService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hostHidden = !(
      // is curriculum
      DistributionTypeHelper.isCurriculum(this.content.objType) &&
      // curriculum should have executable content
      DisplayStatusHelper.isToDoContent(this.content?.displaystatus) &&
      // check for any unlocked item
      this.contentService.searchFirstExecutableItem([], this.content) == null
    );
  }

  onCheckLock(event: MouseEvent): void {
    if (this.version == 2 ) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.curriculumLinkDialogService.checkCurriculumLockedBy(this.content)
      .pipe(take(1))
      .subscribe();
  }

}
