<div (click)="onClick($event)" class="container">

  <div class="icon-wrapper">
    <div class="icon">
      <mat-icon [svgIcon]="svgIcon"></mat-icon>
      <div class="progress">
        <div [ngStyle]="{'width': downloadProgress$ | async }"></div>
      </div>
    </div>
    <div *ngIf="!readonly" class="remove">
      <button (click)="onDelete($event)" color="warn" mat-mini-fab type="button">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <div class="file-size">
    ({{size}})
  </div>

  <div class="file-name">
    {{file.fileName}}
  </div>
</div>
