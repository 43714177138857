import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { QAEditResponse } from '../../../user/qa/qa.types';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { AdminQAService } from '../admin-qa.service';
import { map } from 'rxjs/operators';
import { QAService } from '../../../user/qa/qa-list/qa-list.service';

@Injectable({ providedIn: 'root' })
export class AdminQAEditResolver
  implements Resolve<QAEditResponse> {

  constructor(
    private adminQAService: AdminQAService,
    private qaService: QAService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QAEditResponse> {
    const uuid = route.params['qaUUID'] ?? '';
    if ( uuid === '' ) {
      return EMPTY;
    }
    return combineLatest([
      this.adminQAService.getQuestionForUUID(uuid),
      this.qaService.getAllTagsForQA(),
    ])
      .pipe(map(([ question, tags ]) => ({
          question,
          tags,
        })));
  }
}
