<div class="bot-aside" *ngIf="visible">
  <button
    type="button"
    class="bot-btn pulsate"
    (click)="toggleOpen()"
  > </button>
</div>
<div *ngIf="dialogOpen" class="bot-content">
  <div class="bot-header">
    <button (click)="toggleOpen()" class="bot-btn-close" mat-icon-button type="button">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <iframe
    id="bot"
    allowfullscreen=""
    frameborder="0"
    [src]="botURL | safe:'resourceUrl'"></iframe>
</div>
