import { Component, OnInit } from '@angular/core';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { TextMacro } from '../admin-text-macros.types';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { DeleteButton, DeleteCancelButtons, InfoType } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { AdminTextMacrosService } from '../admin-text-macros.service';
import { TEXT_MACROS_LIST_COLUMNS } from './admin-text-macros.columns';

@Component({
  selector: 'rag-admin-text-macros-list',
  templateUrl: './admin-text-macros-list.component.html',
  styleUrls: ['./admin-text-macros-list.component.scss']
})
export class AdminTextMacrosListComponent
  extends TableControllerComponent<TextMacro>
  implements OnInit {

  textMacros: TextMacro[];

  constructor(
    private adminTextMacrosService: AdminTextMacrosService,
    private infoService: InfoService,
    private route: ActivatedRoute,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();
  }

  onDelete(textMacroId: number): void {
    this.infoService.showMessage($localize`:@@admin_text_macro_delete_confirm:Do you want to delete this text macro?`,
      {
        buttons: DeleteCancelButtons,
        title: $localize`:@@general_dialog_confirm_title:Confirm`,
      })
      .pipe(takeWhile(button => button === DeleteButton))
      .pipe(switchMap(() => this.adminTextMacrosService.deleteTextMacro(textMacroId)))
      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`, {
          infoType: InfoType.Success,
        });
        this.textMacros = this.textMacros.filter(textMacro => textMacro.id !== textMacroId);
        this.setTableData(this.textMacros);
      }))
      .pipe(catchError(() =>
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`, {
          infoType: InfoType.Error,
        })
      ))
      .pipe(take(1))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(TEXT_MACROS_LIST_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateRouteData(data): void {
    this.textMacros = data.textMacros;
    this.updateAvailableColumns();
    this.setTableData(this.textMacros);
    this.inputDisabled = false;
    this.checkFilter();
  }

}
