import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RagPageComponent } from 'src/app/rag-layout/rag-page/rag-page.component';
import { ComponentCommunicationService } from 'src/app/core/component-communication.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { map } from 'rxjs/operators';


@Component({
  selector: 'rag-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
})
export class DashboardComponent
  implements OnInit, OnDestroy {

  hasWidgets = false;
  isInEditMode = false;
  private ragPage_: RagPageComponent;

  constructor(
    private componentCommunicationService: ComponentCommunicationService,
  ) {
  }

  @ViewChild('ragPage')
  set ragPage(value: RagPageComponent) {
    if ( value == null ) {
      return;
    }

    this.ragPage_ = value;

    value.widgets$
      .pipe(map(widgets => {
        this.hasWidgets = (widgets?.length > 0);
        if ( !this.hasWidgets ) {
          this.isInEditMode = false;
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.componentCommunicationService.reloadLayout$
      .pipe(map(() => this.ragPage_.reloadLayout()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  toggleEdit() {
    this.isInEditMode = !this.isInEditMode;
    this.ragPage_.edit = this.isInEditMode;
  }

}
