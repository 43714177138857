import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { LicenseGroupToTgTypes } from '../../../../core/development-goals/license-group-to-tg.types';
import { ApiUrls } from '../../../../core/api.urls';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, YesButton, YesNoButtons } from '../../../../core/info/info.types';
import { ApiResponse } from '../../../../core/global.types';
import { ModalDialog } from '../../../../core/modal-dialog';
import { LicenceInfoDialogComponent } from './licence-info-dialog/licence-info-dialog.component';
import { LicenceInfoDialogTypes } from './licence-info-dialog/licence-info-dialog.types';

@Injectable({ providedIn: 'root' })
export class LicenseGroupToTgService {

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  private static asAssignmentEntry(value: any | LicenseGroupToTgTypes.DistAssignmentEntry):
    null | LicenseGroupToTgTypes.DistAssignmentEntry {
    if ( value?.$isAssignmentEntry !== true ) {
      return null;
    }
    return value;
  }

  private static forUpdate(value: LicenseGroupToTgTypes.DistAssignmentEntry):
    LicenseGroupToTgTypes.DistAssignmentUpdate {
    return {
      curriculumId: value.curriculumId,
      userId: value.userId,
      assignedMandatory: value.mandatory.currentValue,
      assignedVoluntary: value.voluntary.currentValue,
    };
  }

  private static isChanged(value: LicenseGroupToTgTypes.DistAssignmentEntry): boolean {
    return (value != null) &&
      ((value.mandatory?.changed === true) || (value.voluntary?.changed === true));
  }

  getAssignmentData(): Observable<LicenseGroupToTgTypes.DistResponse> {
    const url = ApiUrls.getKey('DistLimitedAssignments');
    return this.http.get<any>(url)
      .pipe(map(response => response.assignments));
  }

  showLicenceInfo(userId: number, curriculumId: number, userName: string, curriculumTitle: string): Observable<void> {
    const url = ApiUrls.getKey('CurriculumLicenseInfo')
      .replace('{userId}', String(userId))
      .replace('{curriculumId}', String(curriculumId));

    return this.http.get<ApiResponse<LicenceInfoDialogTypes.LicenceInfo[]>>(url)
      .pipe(map(response => ({
          curriculumTitle,
          licences: response.curriculumLicenceInfo,
          userName,
        })))
      .pipe(switchMap(this.showLicenceDialog));
  }

  updateAssignmentData(tableRows: LicenseGroupToTgTypes.TableRow[]): Observable<void> {
    const url = ApiUrls.getKey('DistLimitedAssignments');
    const data = tableRows.flatMap(row => Object.values(row).map(column => {
        const entry = LicenseGroupToTgService.asAssignmentEntry(column);
        return LicenseGroupToTgService.isChanged(entry) ? entry : null;
      }))
      .filter(column => column != null)
      .map(LicenseGroupToTgService.forUpdate);
    if ( !(data.length > 0) ) {
      // nothing to change
      return EMPTY;
    }

    return this.infoService.showMessage($localize`:@@dist_limited_update_assignment_confirm:
          The changes that you have made may have an effect on the assignment of courses. Please make sure to check your
          settings before applying them. Would you like to apply your changes?
    `, {
      buttons: YesNoButtons,
      title: $localize`:@@general_dialog_confirm_title:Confirm`,
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(take(1))

      .pipe(switchMap(() => this.http.post<any>(url, { assignments: data })))
      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }))
      .pipe(catchError(this.handleError));
  }

  private handleError = (): Observable<void> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

  private showLicenceDialog = (data: LicenceInfoDialogTypes.DialogData): Observable<void> =>
    this.dialog.openModal<LicenceInfoDialogComponent, LicenceInfoDialogTypes.DialogData>(LicenceInfoDialogComponent, { data })
      .afterClosed()
      .pipe(take(1));

}
