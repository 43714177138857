import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/api.urls';
import { ApiResponse } from 'src/app/core/global.types';
import { AdminCurriculum, AdminCurriculumLegacy } from '../admin-curriculum.types';
import { InfoType } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';

@Injectable({
  providedIn: 'root',
})
export class AdminCurriculumListService {

  @Output() readonly updateData$: Observable<void>;
  private _updateData = new EventEmitter<void>();

  constructor(
    private infoService: InfoService,
    private http: HttpClient,
  ) {
    this.updateData$ = this._updateData.asObservable();
  }

  getCurricula(status?: string): Observable<AdminCurriculum.CurriculumMeta[]> {
    let url = ApiUrls.getKey('GetCurriculaMeta');
    if (status !== undefined) {
      url += '/status/' + status;
    }
    return this.http.get<ApiResponse<AdminCurriculum.CurriculumMeta[]>>(url)
      .pipe(map(response => (response.data ?? [])));
  }

  getCurriculaByStatus(status = 'published'): Observable<Array<AdminCurriculumLegacy>> {
    const url = ApiUrls.getKey('GetCurriculaByStatus') + '/' + status;
    return this.http.get<ApiResponse<Array<AdminCurriculumLegacy>>>(url)
      .pipe(map(response => response.curricula))
      .pipe(map(curricula => curricula.sort((c1, c2) => c1.name.localeCompare(c2.name))));
  }

  deleteCurriculum(curriculumId: number): Observable<void> {
    const url = ApiUrls.getKey('DeleteCurriculum')
    .replace('{currId}', String(curriculumId));

    return this.http.delete<ApiResponse<void>>(url)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Warning });
        return EMPTY;
      }))
      .pipe(map(() => {
        this._updateData.emit();
        this.infoService.showMessage($localize`:@@admin_curriculum_delete_success:The curriculum has been deleted successfully`,
          { infoType: InfoType.Success });

      }));
  }
}
