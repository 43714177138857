import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PipesModule } from '../pipes/pipes.module';
import { InfoSnackBarComponent } from './snack-bar/info-snack-bar.component';
import { UserDataDialogComponent } from './user-data-dialog/user-data-dialog.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { MessagesComponent } from './messages/messages.component';

@NgModule({
  declarations: [
    InfoSnackBarComponent,
    UserDataDialogComponent,
    MessagesComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule,
    PipesModule,
    MatDialogModule,
    MatButtonModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
  exports: [
    MessagesComponent
  ]
})
export class InfoModule {
}
