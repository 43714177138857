import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RuntimeEnvironmentService } from '../../../core/runtime-environment.service';
import { map, switchMap, take } from 'rxjs/operators';
import { AccountInterceptor } from '../../../core/interceptors/account.interceptor';
import { RouteSnapshotHelper } from '../../../core/route-snapshot.helper';
import { Injectable } from '@angular/core';
import { ContentService } from '../../../core/content/content.service';
import { InfoService } from '../../../core/info/info.service';
import { Core } from '../../../core/core.types';


/**
 * mapping a curriculumId to a specific path
 */
interface CurriculumMapping {
  [key: number]: string;
}

/**
 * define some mappings for selected accounts (by accountKey)
 */
interface AccountMapping {
  [key: string]: CurriculumMapping;
}

const MAPPINGS: AccountMapping = {

  // reflact@morgenroth auf https://staging24.reflact.com/
  wxwruetgyiqeb2cf0u065tqe4jozi1sjgoh: {
    // pxc staging v1
    // 3814: '/content/3814/cockpit',
    // pxc staging v2
    3851: '/content/3851/cockpit',
  },
  // reflact@pxc auf https://www.reflact-train.com/
  '8yrw71itn01q5cq6bzljtk5reg0jhvnncxp': {
    // PXC live 29.10.
    // 571: '/content/571/cockpit',
    // PXC live 10.11.
    // 572: '/content/572/cockpit',
    // pxc 2021-03
    602: '/content/602/cockpit',
  },

};

@Injectable({ providedIn: 'root' })
export class ContentGuard
  implements CanActivate {

  constructor(
    private contentService: ContentService,
    private infoService: InfoService,
    private router: Router,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    let contentId = RouteSnapshotHelper.getParamNumber(route, 'id');
    if ( isNaN(contentId) ) {
      contentId = route.data?.curId;
    }

    return this.contentService.fetchAccountData()
      .pipe(take(1))
      .pipe(switchMap(account => {

        state.root.data = {
          account
        };

        const content = ContentService.getContentById(account, contentId, false,
          [ Core.DistributableType.lms_course, Core.DistributableType.lms_curriculum ]);

        if ( content == null ) {
          // content could not be found?
          this.infoService
            .showAlert($localize`:@@error_content_not_found:This content does not exist or you do not have access.`)
            .pipe(take(1))
            .subscribe();
          return of(this.router.parseUrl('/'));
        }

        if ( content.viewType === 'a294578e-ab2f-4576-a106-0177600b67ad' ) {
          return of(this.router.parseUrl('/content/' + contentId + '/cockpit/OnlineAgenda'));
        } else if ( content.viewType === '174b90c1-5781-4c1e-a5dd-989a3618af4e' ) {
          return of(this.router.parseUrl('/content/' + contentId + '/cockpit/CustomView'));
        }

        return this.checkStaticMapping(route);
      }));
  }

  private checkStaticMapping(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.runtimeEnvironmentService.environment$

      .pipe(map(env => {

        if ( env.enableLearnerCockpitSwitch !== true ) {
          // skip checking for redirect if flag is not set to true
          return true;
        }

        const accountKey = AccountInterceptor.getAccountKey();
        const accountMappings = MAPPINGS[accountKey];
        if ( accountMappings == null ) {
          // there are no mappings for this account -> use ContentComponent as usual
          return true;
        }

        const contentId = RouteSnapshotHelper.getParamNumber(route, 'id');
        const url = accountMappings[contentId];
        if ( !url ) {
          // there is no mapping for this content -> use ContentComponent as usual
          return true;
        }

        // redirect to the given url
        return this.router.parseUrl(url);
      }))
      .pipe(take(1));
  }

}
