<button
  type="button"
  mat-icon-button
  [matMenuTriggerFor]="tocMenu"
>
  <mat-icon svgIcon="menu"></mat-icon>

  <mat-menu #tocMenu xPosition="before">
    <a
      *ngFor="let headline of headlines"
      mat-menu-item
      [ngClass]="getLevelClass(headline)"
      routerLink="."
      [queryParams]="{anchor: headline.id}"
    >
      <span class="title">{{headline.text}}</span>
    </a>
  </mat-menu>
</button>
