import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { getDimensions, getScrollParent } from '../../core/offset-helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';

const ELT_HTML = document.querySelector('html');

/**
 * @see https://stackoverflow.com/a/33621048
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-sticky-scroll',
  templateUrl: './sticky-scroll.component.html',
  styleUrls: [ './sticky-scroll.component.scss' ],
})
export class StickyScrollComponent
  implements OnInit, AfterViewInit, OnDestroy {

  readonly updateScrollX: Observable<void>;
  readonly updateScrollY: Observable<void>;
  private _eltScrollParent: Element;
  private _recalculate: Observable<void>;
  private _recalculateSubscription: Subscription;
  private _updateScrollX = new EventEmitter<void>();
  private _updateScrollY = new EventEmitter<void>();
  @ViewChild('contentWrapper', { read: ElementRef, static: true })
  private eltContentWrapper: ElementRef;
  @ViewChild('scrollContent', { read: ElementRef, static: true })
  private eltScrollContent: ElementRef;
  @ViewChild('scrollX', { read: ElementRef, static: true })
  private eltScrollX: ElementRef;

  constructor(
    private eltHost: ElementRef,
    private eventManager: EventManager,
  ) {
    this.updateScrollX = this._updateScrollX.asObservable()
      .pipe(debounceTime(10));
    this.updateScrollY = this._updateScrollY.asObservable()
      .pipe(debounceTime(50));
  }

  @Input()
  set recalculate(value: Observable<void>) {
    const doUpdate = value && (this._recalculate !== value);
    this._recalculate = value;
    if ( !doUpdate ) {
      return;
    }
    this._recalculateSubscription?.unsubscribe();
    this._recalculateSubscription = value
      .pipe(debounceTime(50))
      .pipe(tap(this.calculateWidths))
      .subscribe();
  }

  ngOnInit(): void {
    this.updateScrollX.pipe(tap(this.scrollX))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.updateScrollY.pipe(tap(this.scrollY))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(this.calculateWidths, 50);

    this.eltScrollX.nativeElement?.addEventListener('scroll', () => this._updateScrollX.emit());

    this.bindScrollParent();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
    this._recalculateSubscription?.unsubscribe();
  }

  private bindScrollParent(): void {
    const eltHost = this.eltHost.nativeElement;
    const eltScrollParent = getScrollParent(eltHost.offsetParent);
    if ( !eltScrollParent || this._eltScrollParent === eltScrollParent ) {
      return this._updateScrollY.emit();
    }

    if ( this._eltScrollParent ) {
      this.updateScrollParentListener(eltScrollParent, false);
    }
    this._eltScrollParent = eltScrollParent;
    this.updateScrollParentListener(eltScrollParent, true);
    this._updateScrollY.emit();
  }

  @HostListener('window:resize')
  private calculateWidths = (): void => {
    const dimHost = getDimensions(this.eltHost);
    const dimContentWrapper = getDimensions(this.eltContentWrapper);

    if ( dimContentWrapper.outerWidth === 0 ) {
      return;
    }

    const eltScrollX = this.eltScrollX.nativeElement;
    // apply position to scroll bar
    const eltHost = this.eltHost.nativeElement;
    eltScrollX.style.left = `${dimHost.x}px`;
    eltScrollX.style.width = `${dimHost.innerWidth}px`;
    eltScrollX.scrollLeft = eltHost.scrollLeft;

    // resize virtual scroll content
    const eltScrollContent = this.eltScrollContent.nativeElement;
    eltScrollContent.style.width = `${dimContentWrapper.outerWidth}px`;

    this.bindScrollParent();
  };

  private onScrollParent = (): void => {
    const eltScrollParent = this._eltScrollParent || document.querySelector('html');
    const parentBottom = eltScrollParent.scrollTop + (eltScrollParent as any).offsetHeight;

    const dimHost = getDimensions(this.eltHost);
    const hostTop = dimHost.y;
    const hostBottom = hostTop + dimHost.outerHeight;
    if ( (parentBottom > hostTop) && (parentBottom < hostBottom) ) {
      const eltScrollX = this.eltScrollX.nativeElement;
      eltScrollX.scrollLeft = this.eltHost.nativeElement.scrollLeft;
      eltScrollX.style.display = '';
    } else {
      this.eltScrollX.nativeElement.style.display = 'none';
    }
  };

  private scrollX = (): void => {
    const eltScrollX = this.eltScrollX.nativeElement;
    const eltHost = this.eltHost.nativeElement;
    if ( (eltHost != null) && (eltScrollX != null) ) {
      eltHost.scrollLeft = eltScrollX.scrollLeft;
    }
  };

  private scrollY = (): void => {
    this.onScrollParent();
  };

  private updateScrollParentListener(elt: any, add: boolean): void {
    if ( elt === ELT_HTML ) {
      elt = window;
    }
    if ( add ) {
      elt.addEventListener('scroll', this.onScrollParent);
    } else {
      elt.removeEventListener('scroll', this.onScrollParent);
    }
  }

}
