import { Component, Inject } from '@angular/core';
import { AdminCoursesTypes } from '../../../../core/admin-courses.types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewHelper } from '../../../../core/view-helper';
import { naturalCompare } from '../../../../core/natural-sort';
import { LanguageHelper } from '../../../../core/language.helper';
import { Curriculum } from '../../../../core/core.types';
import { AdminCoursesValidateDeleteTypes } from './admin-courses-validate-delete.types';

@Component({
  selector: 'rag-admin-courses-validate-delete',
  templateUrl: './admin-courses-validate-delete.component.html',
  styleUrls: [ './admin-courses-validate-delete.component.scss' ],
})
export class AdminCoursesValidateDeleteComponent {

  courses: AdminCoursesTypes.Course[];
  message: string;
  proceed:AdminCoursesValidateDeleteTypes.Result = 'proceed';
  proceedForAll: AdminCoursesValidateDeleteTypes.Result = 'proceedForAll';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdminCoursesValidateDeleteTypes.ApiResponse,
  ) {
    this.courses = Object.values(data.courses ?? {})
      .map(course => AdminCoursesValidateDeleteComponent.addCurricula(course, data))
      .filter(course => ViewHelper.getViewData(course)?.hasUsage === true)
      .sort((a, b) => naturalCompare(
        LanguageHelper.objectToText(a.title),
        LanguageHelper.objectToText(b.title),
      ));

    this.message = this.courses?.length > 1 ?
      $localize`:@@admin_courses_usage_dialog_title_multiple:
        This operation is not permitted for selected courses` :
      $localize`:@@admin_courses_usage_dialog_title_single:
        This operation is not permitted for this course`;
  }

  private static addCurricula(course: AdminCoursesTypes.Course,
    data: AdminCoursesValidateDeleteTypes.ApiResponse): AdminCoursesTypes.Course {
    const courseId = course.courseId;

    const curricula: Curriculum[] = (data.courseToCurriculumIds[courseId] ?? [])
      .map(curriculumId => data.curricula[curriculumId])
      .filter(c => c != null);
    const licenseCount = data.licenseCounts[courseId] ?? 0;

    const viewData = ViewHelper.getViewData(course);
    viewData.curricula = curricula.sort((a, b) => naturalCompare(
      LanguageHelper.objectToText(a.name),
      LanguageHelper.objectToText(b.name),
    ));
    viewData.licenseCount = licenseCount;
    viewData.hasUsage = (licenseCount > 0) || (curricula.length > 0);
    return course;
  }

  getCurricula(course: AdminCoursesTypes.Course): Curriculum[] {
    const curricula = ViewHelper.getViewData(course)?.curricula ?? [];
    if ( curricula.length > 0 ) {
      return curricula;
    } else {
      return null;
    }
  }

  getLicenseCount(course: AdminCoursesTypes.Course): number {
    return ViewHelper.getViewData(course)?.licenseCount ?? 0;
  }

}
