import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { versions } from 'src/environments/versions';
import { MarkdownService } from 'ngx-markdown';
import { TextDecodeHelper } from '../../../core/translation/text-decode.helper';
import { ActivatedRoute } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Slugger } from 'marked';
import { ChangeNotesTocEntry } from './change-notes-toc/change-notes-toc.types';


/**
 * @see https://jfcere.github.io/ngx-markdown/get-started
 */
@Component({
  selector: 'rag-change-notes',
  templateUrl: './change-notes.component.html',
  styleUrls: [ './change-notes.component.scss' ],
})
export class ChangeNotesComponent
  implements OnDestroy, OnInit {

  documentationUrl: string;
  hasData = false;
  hasError = false;
  headlines: ChangeNotesTocEntry[] = [];
  versionString: string;
  private _documentationFolder = `./assets/change-notes/`;
  private _routerLink: string = `${window.location.hash}`.replace(/[?].+$/, '');

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private markdownService: MarkdownService,
    private route: ActivatedRoute,
  ) {
    const language = (locale === 'de') ? 'de' : 'en';
    const version = this.versionString = ChangeNotesComponent.getVersionString(versions.version);
    this.documentationUrl = `${this._documentationFolder}${version}.${language}.md`;

    this.route.queryParamMap
      .pipe(map(params => params?.get('anchor') ?? ''))
      .pipe(filter(anchor => !!anchor))
      .pipe(distinctUntilChanged())
      .pipe(tap(anchor => ChangeNotesComponent.scrollToAnchor(anchor)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  static getVersionString(
    version: string | null,
  ): string {

    if ( !version ) {
      return '0.0.0';
    }

    const cleanVersion = /^(\d+[.]\d+[.]\d+)/.exec(version);
    if ( (cleanVersion == null) || !(cleanVersion.length > 1) ) {
      return version;
    }

    return cleanVersion[1];
  }

  static scrollToAnchor(
    anchor: string,
  ): void {

    if ( !anchor ) {
      return;
    }

    const element = document.getElementById(anchor);
    if ( element == null ) {
      return;
    }

    setTimeout(() => element.scrollIntoView());
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {

    const renderer = this.markdownService.renderer;
    this.headlines.splice(0);

    renderer.heading = (
      text: string,
      level: 1 | 2 | 3 | 4 | 5 | 6,
      raw: string,
      slugger: Slugger,
    ): string => {
      const id = renderer.options.headerPrefix + slugger.slug(raw);
      this.headlines.push({ id, level, text: raw });
      return `<h${level} id="${id}">${text}</h${level}>\n`;
    };

    renderer.image = (
      href: string | null,
      title: string | null,
      text: string,
    ): string => {

      if ( !/(^[.]\/|\/)/g.test(href) ) {
        href = `${this._documentationFolder}${href}`;
      }

      return `<img src="${href}" alt="${TextDecodeHelper.encodeHtml(title || text)}" />`;
    };

    renderer.link = (
      href: string | null,
      title: string | null,
      text: string,
    ) => {

      let target = '';
      if ( /^#/g.test(href) ) {
        href = `${this._routerLink}?anchor=${encodeURIComponent(href.replace(/^#/, ''))}`;
      } else {
        target = ' target="_blank"';
      }

      title = (!title) ? '' : ` title="${TextDecodeHelper.encodeHtml(title)}"`;

      return `<a href="${href}"${target}${title}>${text}</a>`;
    };

  }

  onError(): void {
    this.hasError = true;
  }

  onLoad(): void {
    this.hasData = true;

    const anchor = this.route.snapshot.queryParamMap.get('anchor');
    ChangeNotesComponent.scrollToAnchor(anchor);
  }

}
