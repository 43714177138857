import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { PrincipalService } from '../../../core/principal/principal.service';
import { map, take } from 'rxjs/operators';
import { PostLoginTypes } from './post-login.types';
import { Core } from '../../../core/core.types';
import { ProfileFieldTypes } from '../../../core/input/profile-field/profile-field.types';
import { naturalCompare } from '../../../core/natural-sort';
import { LanguageHelper } from '../../../core/language.helper';
import { LandingPageEnum } from 'src/app/core/global.types';
import { UserAttributeType } from 'src/app/core/admin-user-attribute.types';


@Injectable({ providedIn: 'root' })
export class PostLoginResolver
  implements Resolve<PostLoginTypes.PostLoginRouteData> {

  constructor(
    private principalService: PrincipalService,
  ) {
  }

  private static getType(field: Core.ModelField): UserAttributeType {
    switch ( field.title ?? '' ) {

      case 'email':
        return UserAttributeType.email;

      default:
        return field.fieldType;
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PostLoginTypes.PostLoginRouteData> {
    return forkJoin([
      this.fetchProfileUpdate(),
      this.checkHasToResetPassword(),
      this.fetchForwardPage(),
    ])
      .pipe(map(([ { recordFields, attributeGroups }, hasToResetPassword, forwardToPages ]) =>
        ({ recordFields, attributeGroups, hasToResetPassword, forwardToPages })));
  }

  private fetchForwardPage(): Observable<Array<LandingPageEnum>> {
    return this.principalService.getFrontendActions()
      .pipe(take(1))

      .pipe(map(actions => {
        const landingPageAction: Core.FrontendActionForwardPage = actions
          ?.find(action => action.action === 'landingPageForward') as Core.FrontendActionForwardPage;
        return (landingPageAction?.pages ?? []);
      }));
  }

  private checkHasToResetPassword(): Observable<any> {
    return this.principalService.fetchUserData()
      .pipe(take(1))
      .pipe(map(principal => principal?.hasToResetPassword === true));
  }

  private fetchProfileUpdate(): Observable<Pick<PostLoginTypes.PostLoginRouteData, 'recordFields' | 'attributeGroups'>> {
    return this.principalService.getFrontendActions()
      .pipe(take(1))
      .pipe(map(actions => {

        const recordActions = (actions ?? [])

          // select only recordFields
          .filter(action => action?.action === 'recordFields');

        const recordFields = recordActions

          // collect all fields
          .flatMap(action => (action as Core.FrontendActionRecordFields).fields ?? [])

          // translate for profile components
          .map(field => ({
            consentText: field.consentText,
            editable: true,
            fieldId: field.title,
            groupId: field.groupId,
            label: LanguageHelper.objectToText(field.label),
            options: field.options,
            orderIndex: field.orderIndex,
            required: field.required,
            type: PostLoginResolver.getType(field),
            info: field.info,
            hasInfo: field.hasInfo
          } as ProfileFieldTypes.ProfileField))

          // prevent random sorting of input fields
          .sort((a, b) => {
            let result = a.orderIndex - b.orderIndex;
            if ( isNaN(result) || result === 0 ) {
              result = naturalCompare(a.label, b.label);
            }
            return result;
          });

        const attributeGroups = recordActions

          // collect all fields
          .flatMap(action => (action as Core.FrontendActionRecordFields).groups ?? [])

          // prevent random sorting of input fields
          .sort((a, b) => {
            let result = a.orderIndex - b.orderIndex;
            if ( isNaN(result) || result === 0 ) {
              result = naturalCompare(a.title, b.title);
            }
            return result;
          });

        return { recordFields, attributeGroups };
      }));
  }

}
