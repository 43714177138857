<ng-container *ngIf="!loading; else tplLoading">
  <ng-container *ngIf="!showNewNavigation">
    <rag-content-overview></rag-content-overview>
  </ng-container>
  <ng-container *ngIf="showNewNavigation">
    <rag-content-overview-v2></rag-content-overview-v2>
  </ng-container>
</ng-container>

<ng-template #tplLoading>
  <rag-loading-indicator
  ></rag-loading-indicator>
</ng-template>
