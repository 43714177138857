import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'rag-colored-date',
  templateUrl: './colored-date.component.html',
  styleUrls: [ './colored-date.component.scss' ],
})
export class ColoredDateComponent {

  date_: Moment;
  highlightClass: 'error' | 'warning' | null = null;
  @Input()
  humanize = true;
  private warnInPast_ = false;

  @Input() set date(value: Moment | number) {
    if ( typeof (value) === 'number' ) {
      value = moment(value);
    }

    if ( !(value && value.isValid && value.isValid()) ) {
      this.date_ = null;
      return;
    }
    this.date_ = value;
    this.checkWarnInPast();
  }

  @Input() set warnInPast(value: boolean) {
    this.warnInPast_ = value;
    this.checkWarnInPast();
  }

  private checkWarnInPast() {
    if ( !(this.warnInPast_ && this.date_) ) {
      this.highlightClass = null;
      return;
    }
    if ( this.date_.isBefore() ) {
      this.highlightClass = 'error';
    } else if ( this.date_.isBefore(moment().add(7, 'days')) ) {
      this.highlightClass = 'warning';
    } else {
      this.highlightClass = null;
    }
  }

}
