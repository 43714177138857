import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNotesTocComponent } from './change-notes-toc.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [ ChangeNotesTocComponent ],
  exports: [ ChangeNotesTocComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
  ],
})
export class ChangeNotesTocModule {
}
