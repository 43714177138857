import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ItemListReadConfirmDialogData, ItemListReadConfirmDialogResult } from '../item-list.types';
import { CourseInfo } from '../../../../core/content/content.types';

@Component({
  selector: 'rag-item-list-read-confirm-dialog',
  templateUrl: './item-list-read-confirm-dialog.component.html',
  styleUrls: ['./item-list-read-confirm-dialog.component.scss']
})
export class ItemListReadConfirmDialogComponent implements OnInit {

  content: CourseInfo;
  text = '';
  selection = '0';
  confirmInfoText = $localize`:@@content_read_confirm_info:You are requested to confirm that you have read this content.`;

  get hasEnteredText() {
    return this.text.trim().length > 0;
  }

  get hasNoSupervisor() {
    return !this.data.hasSupervisor;
  }

  constructor(
    public dialogRef: MatDialogRef<ItemListReadConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemListReadConfirmDialogData,
  ) {
    this.content = data.content;
    const confirmationText = this.data.content?.confirmationText;
    if (confirmationText != null) {
      this.confirmInfoText = confirmationText;
    }
  }

  ngOnInit(): void {
  }

  onSelectionChange($event: MatRadioChange) {
    if ($event.value === '0') {
      this.text = '';
    }
  }

  onReadAcknowledgeConfirm() {

    const result: ItemListReadConfirmDialogResult = {
      confirm: true
    };

    if (this.selection === '1') {
      result.text = this.text.trim();
    }

    this.dialogRef.close(result);
  }

  onCancel() {
    const result: ItemListReadConfirmDialogResult = {
      confirm: false
    };
    this.dialogRef.close(result);
  }

}
