import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InfoService } from '../info/info.service';
import { ProcessService } from '../../route/main/process/process.service';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { Core, DoubleOptIn } from '../core.types';
import { ApiUrls } from '../api.urls';
import { State } from '../../app.state';
import { ApiResponse, HttpRequestOptions, TrainResponse } from '../global.types';
import { catchError, map, mapTo, switchMap, take, takeWhile } from 'rxjs/operators';
import { RetrieveEmailComponent } from '../../component/login/retrieve-email/retrieve-email.component';
import { AnonRegResponse, RegisterUserRequest, RegistrationInfo } from './principal.types';
import { LanguageHelper } from '../language.helper';
import { InfoType, MessageConstants } from '../info/info.types';
import { naturalCompare } from '../natural-sort';


@Injectable({ providedIn: 'root' })
export class RegistrationService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private processService: ProcessService,
  ) {
  }

  static getCaseSpecificSort(
    options: { label: string; value: string }[] | null,
  ): { label: string; value: string }[] {

    if ( !(options?.length > 0) ) {
      return [];
    }

    const currentLanguage = LanguageHelper.getCurrentLanguage();
    return options.sort((a, b) => {
      if ( a.value === currentLanguage.trainLanguage ) {
        return -1;
      } else {
        return naturalCompare(a.label, b.label);
      }
    });
  }

  private static registerUserIsErrorAlreadyTaken(err: HttpErrorResponse) {

    if ( !(err?.status === 417) ) {
      // status should be 417 for "username is already taken"
      return false;
    }

    try {
      if ('ERR_ACC_007' == err.error?.errors?.[0].errorCode) {
        return true;
      }
      const errorMessage = JSON.stringify(err.error ?? {});
      return errorMessage.includes('username') &&
        errorMessage.includes('already taken');
    } catch ( e ) {
      return false;
    }
  }

  activateKey(key: string, loginUrl: string): Observable<any> {
    return this.http.post<TrainResponse>(
      ApiUrls.getKey('SelfRegistrationActivation') + '?url=' + encodeURIComponent(loginUrl),
      JSON.stringify({ activationkey: key, url: loginUrl }),
      HttpRequestOptions)
      .pipe(take(1));
  }

  initiateDoubleOptIn(
    state: any,
    registrationType: Core.UserProcessType = 'doubleOptIn'
  ): Observable<Core.UserProcess<DoubleOptIn.State>> {
    const url = ApiUrls.getKey('Process');
    const payload = {
      type: registrationType,
      state,
      language: State.language,
    };
    return this.http.put<ApiResponse<Core.UserProcess>>(url, JSON.stringify(payload), HttpRequestOptions)
      .pipe(map(response => response.process));
  }

  registerAnonymous(code: string): Observable<string> {
    const url = ApiUrls.getKey('SelfRegistrationAnonymous');

    const saveData = {
      code,
    };

    return this.http.post<ApiResponse<string>>(url, saveData)
      .pipe(map(response => response.login));
  }

  registerDoubleOptIn(): Observable<DoubleOptIn.State> {

    // https://%s/#/process/%s
    let hostnameAndPath = location.hostname + location.pathname;
    // cut the trailing slash, if any
    if ( hostnameAndPath.endsWith('/') ) {
      hostnameAndPath = hostnameAndPath.substring(0, hostnameAndPath.length - 1);
    }

    return this.infoService
      .showDialog<void, void, string>(RetrieveEmailComponent, void (0), {
        maxWidth: '320px',
      })
      .pipe(takeWhile(email => email?.length > 0))
      .pipe(switchMap(email => this.initiateDoubleOptIn({
        ...this.processService.getContext(),
        email,
        hostname: hostnameAndPath,
        bookingInProgressHash: this.processService.bookingInProgressHash
      })))
      .pipe(map(process => process.state));
  }

  /**
   * Register new account in LMS
   * @param registerUserRequest user to be created
   * @returns Observable<boolean> true when registration has complete successfully, otherwise false
   */
  registerUser(registerUserRequest: RegisterUserRequest): Observable<boolean> {
    return this.http.put<ApiResponse<void>>(
      ApiUrls.getKey('SelfRegistration'),
      JSON.stringify(registerUserRequest),
      HttpRequestOptions,
    )
      .pipe(map(_ => true))
      .pipe(catchError((err: HttpErrorResponse) => {
        if ( RegistrationService.registerUserIsErrorAlreadyTaken(err) ) {
          this.infoService.showMessage($localize`:@@self_registration_error_already_taken:This e-mail address is already in use. Please try to log in using the address, and the matching password.<br/><br/>In case you do not know the correct password, you can use the "forgot password?" function to request a new one.`, {infoType: InfoType.Error, durationInSeconds: 60});
        } else {
          this.infoService
            .showMessage(MessageConstants.ERRORS.GENERAL, {infoType: InfoType.Error});
        }
        return of(false);
      }));
  }

  /**
   * requests registration information for this account
   */
  requestRegisterInformation(process?: Core.UserProcess): Observable<RegistrationInfo> {

    const language = LanguageHelper.getCurrentLanguage()
      ?.trainLanguage ?? 'de_DE';
    const url = ApiUrls.getKey('SelfRegistration');

    const payload = {
      lang: language,
    };

    if ( process != null ) {
      payload['process'] = process.hash;
    }

    return this.http.post<ApiResponse<RegistrationInfo>>(url, payload)
      .pipe(map(response => {
        const data = response.data;

        const dateOfBirth = Object.values(data.model)
          .find(field => field?.field === 'dateOfBirth');
        if ( dateOfBirth != null ) {
          // dateOfBirth always gets reported as type 'text'
          dateOfBirth.type = 'date';
        }

        const languageModel = Object.values(data.model)
          .find(field => field?.field === 'language');
        if ( languageModel != null ) {
          languageModel.options = RegistrationService.getCaseSpecificSort(languageModel.options);
        }

        return data;
      }));
  }

}
