<div class="container" *ngIf="hasData">
  <div class="badge">
    <span class="title">Level</span>
<!--    <div class="progress-bar" [ngStyle]="getProgressBarStyle()">-->
<!--      <span class="font-size-20">{{this.gamificationProfile?.level}}</span>-->
<!--      <progress class="progress"></progress>-->
<!--    </div>-->
    <div
      #xpTooltip="matTooltip"
      class="progress-bar"
      [matTooltip]="getXpTooltip()"
      (click)="xpTooltip.toggle(); $event.stopImmediatePropagation()"
    >
      <div class="level-title">
        <span class="font-size-20">{{gamificationProfile?.level}}</span>
      </div>
      <mat-progress-spinner strokeWidth="6" class="progress" [value]="xpProgressBarValue"></mat-progress-spinner>
    </div>
  </div>

  <div class="badge">
    <span class="title" i18n="@@admin_lu_header">Learning content</span>
    <div class="image-wrapper" [matBadge]="learningCount" matBadgeSize="large">
      <img [src]="'assets/icons/svg/badges/badge_lms_course.svg'" alt="badge">
    </div>
  </div>

  <div class="badge">
    <span class="title" i18n="@@admin_content_content_curriculum">Curriculum</span>
    <div class="image-wrapper" [matBadge]="curriculumCount" matBadgeSize="large">
      <img [src]="'assets/icons/svg/badges/badge_lms_curriculum.svg'" alt="badge">
    </div>
  </div>

  <div class="badge">
    <span class="title" i18n="@@admin_offline_content_header">Events</span>
    <div class="image-wrapper" [matBadge]="eventCount" matBadgeSize="large">
      <img [src]="'assets/icons/svg/badges/badge_lms_offlineCnt.svg'" alt="badge">
    </div>
  </div>
</div>
