import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableComponent } from './report-table.component';
import { TableColumnMenuModule } from '../../../../../component/table/table-column-menu/table-column-menu.module';
import { TableHeaderFilterDefaultModule } from '../../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { PivotFilterModule } from '../../../../../component/pivot-filter/pivot-filter.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TablesColumnRenderDefaultModule } from '../../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ReportTableGroupingValueModule } from './report-table-grouping-value/report-table-grouping-value.module';
import { StatusStatisticsModule } from '../../report-generator/status-statistics/status-statistics.module';
import { ReportTableControlsModule } from './report-table-controls/report-table-controls.module';
import { RouterModule } from '@angular/router';
import { ReportTableMultiActionsModule } from './report-table-multi-actions/report-table-multi-actions.module';
import { ReportTableSingleActionsModule } from './report-table-single-actions/report-table-single-actions.module';
import { ReportTitleDialogModule } from '../../report-generator/report-title-dialog/report-title-dialog.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ReportTableChartsComponent } from './report-table-charts/report-table-charts.component';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { ParticipantOfflineMenuComponent } from 'src/app/component/participant-offline-menu/participant-offline-menu/participant-offline-menu.component';
import { SpacerComponent } from 'src/app/component/spacer/spacer.component';
import { StickyScrollComponent } from 'src/app/component/sticky-scroll/sticky-scroll.component';
import { TableFilterComponent } from '../../../../../component/table-filter/table-filter.component';


@NgModule({
  declarations: [ ReportTableComponent ],
  exports: [ ReportTableComponent ],
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    PivotFilterModule,
    TableColumnMenuModule,
    TableHeaderFilterDefaultModule,
    StickyScrollComponent,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    TablesColumnRenderDefaultModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    SpacerComponent,
    ReportTableChartsComponent,
    ReportTableGroupingValueModule,
    StatusStatisticsModule,
    ReportTableControlsModule,
    RouterModule,
    ReportTableMultiActionsModule,
    ReportTableSingleActionsModule,
    ReportTitleDialogModule,
    ParticipantOfflineMenuComponent,
    ScrollingModule,
    TableVirtualScrollModule,
    TableFilterComponent,
  ],
})
export class ReportTableModule {
}
