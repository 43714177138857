import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LearnerContentTypes } from 'src/app/route/user/content/learner-content-util/learner-content.types';

export interface TodoDataPreviewDialogComponentParams {
  course: LearnerContentTypes.CourseAccountToDo;
}

@Component({
  selector: 'rag-todo-data-preview-dialog',
  templateUrl: './todo-data-preview-dialog.component.html',
  styleUrls: ['./todo-data-preview-dialog.component.scss']
})
export class TodoDataPreviewDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TodoDataPreviewDialogComponentParams) { }

  ngOnInit(): void {
  }

}
