import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth.guard';
import { LinkedUsersOwnUuidResolver } from '../../../core/user/link/linked-users-own-uuid.resolver';
import { LinkedUsersComponent } from './linked-users.component';
import { LinkedUsersResolver } from '../../../core/user/link/linked-users.resolver';

export const LinkRoutes = [
  {
    path: 'linked-users',
    component: LinkedUsersComponent,
    resolve: {
      linkedUsers: LinkedUsersResolver,
      ownUUID: LinkedUsersOwnUuidResolver,
    },
    canActivate: [ AuthGuard ],
    data: {
      checkPermission: 'navUserLink',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(LinkRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class LinkRoutingModule {
}

