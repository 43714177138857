import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../../component/table/table-controller/table-controller.types';
import { OAuth2ClientRegistration } from '../ext-oauth2.types';
import { TableColumnBuilder } from '../../../../../component/table/table-column.builder';


export interface ExtOAuth2ListColumnMenuData
  extends TableControllerTypes.ColumnMenuData<OAuth2ClientRegistration> {
  menuItems: ExtOAuth2ListColumnMenuItemMap;
}

export interface ExtOAuth2ListColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap<OAuth2ClientRegistration> {
  actions: TableControllerTypes.ColumnMenuItem;
  active: TableControllerTypes.ColumnMenuItem;
  clientId: TableControllerTypes.ColumnMenuItem;
  clientName: TableControllerTypes.ColumnMenuItem;
  issuerUri: TableControllerTypes.ColumnMenuItem;
}

export const EXT_OAUTH2_DEFAULT_MENU_COLUMNS: ExtOAuth2ListColumnMenuData = {
  startWith: [],
  endWith: [ 'actions' ],
  menuItems: {

    active: TableColumnBuilder.start<OAuth2ClientRegistration>()
      .withColumnId('active')
      .withType(TableColumnDataType.dropdown)
      .withTitle($localize`:@@admin_offline_notifications_column_enabled:Enabled`)
      .withDropDownOptions({
        true: $localize`:@@global_enabled:enabled`,
        false: $localize`:@@global_disabled:disabled`,
      })
      // .withFilterDefaultValue('true')
      .withSelected()
      .build(),

    clientName: TableColumnBuilder.start<OAuth2ClientRegistration>()
      .withColumnId('clientName')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@global_title:Title`)
      .withSelected()
      .build(),

    issuerUri: TableColumnBuilder.start<OAuth2ClientRegistration>()
      .withColumnId('issuerUri')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@ext_oauth2_column_issuerUri:Issuer URL`)
      .withSelected()
      .build(),

    clientId: TableColumnBuilder.start<OAuth2ClientRegistration>()
      .withColumnId('clientId')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@ext_oauth2_column_clientId:Client ID`)
      .withSelected()
      .build(),

    actions: TableColumnBuilder.start<OAuth2ClientRegistration>()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),
  },
};
