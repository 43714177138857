import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { LearnerAccountService } from './learner-account.service';
import { LearnerAccountContextItemView } from './learner-account.types';


@Injectable({
  providedIn: 'root',
})
export class ContextItemResolver
  implements Resolve<LearnerAccountContextItemView> {

  constructor(
    private learnerAccountService: LearnerAccountService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LearnerAccountContextItemView> {
    const curriculumItemId = route.params['itemId'];
    const rootCurriculumId = route.params['id'];
    if ( curriculumItemId == null || rootCurriculumId == null ) {
      throw Error('missing required parameters!');
    }

    return this.learnerAccountService.fetchContextItem(rootCurriculumId, curriculumItemId)
      .pipe(switchMap(response => {
        if ( !(response && response.curriculum && response.curriculumItem) ) {
          return throwError('response data not found');
        }
        return of(response);
      }))
      .pipe(take(1));
  }

}
