import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ReportTableRowChild } from '../report-table.types';
import { ReportTableService } from '../report-table.service';
import { PrincipalService } from '../../../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../../../core/principal/permission.states';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../core/reactive/until-destroyed';
import { ReportService } from '../../../../../../core/report/report.service';
import { ReportConfig } from 'src/app/core/report/report.types';
import { DisplayStatus } from '../../../../../../core/display-status.enum';
import { Core } from '../../../../../../core/core.types';
import CurriculumAccountStatus = Core.CurriculumAccountStatus;


@Component({
  selector: 'rag-report-table-single-actions',
  templateUrl: './report-table-single-actions.component.html',
  styleUrls: [ './report-table-single-actions.component.scss' ],
})
export class ReportTableSingleActionsComponent
  implements OnChanges, OnDestroy {

    @Input() inputDisabled: boolean;
    @Input() reportConfig: ReportConfig;
    @Input() row: ReportTableRowChild;
  detailsUrl: string;
  isCurriculum: boolean;
  isCurriculumAndCertified: boolean;
  maySave: boolean;
  maySendMessage: boolean;
  permissions: PermissionStates;

  constructor(
    private principalService: PrincipalService,
    private reportService: ReportService,
    private reportTableService: ReportTableService,
    ) {
      this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get isNotOffline() {
    return this.reportConfig.targetType !== 'Offline';
  }

  getDetailsUrl(row: ReportTableRowChild): string {

    if ( !(row && (row.userId > 0)) ) {
      return null;
    }

    if ( this.reportTableService.isCourse(row) ) {
      return '/report/v2/details/{userId}/course/{courseId}'
        .replace(/{userId}/gi, '' + row.userId)
        .replace(/{courseId}/gi, '' + row.courseId);
    }

    if ( this.reportTableService.isCurriculum(row) ) {
      return '/report/v2/details/{userId}/curriculum/{curriculumId}'
        .replace(/{userId}/gi, '' + row.userId)
        .replace(/{curriculumId}/gi, '' + row.curriculumId);
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.hasOwnProperty('row') ) {
      this.detailsUrl = this.getDetailsUrl(this.row);
      this.isCurriculum = this.reportTableService.isCurriculum(this.row);
      this.isCurriculumAndCertified = this.reportTableService.isCurriculumAndCertified(this.row);
      this.maySendMessage = this.reportTableService.maySendMessage(this.row);
      this.maySave = this.reportTableService.maySave(this.row);

    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onDownloadCertificate(): void {
    if ( this.inputDisabled || !this.reportTableService.isCurriculum(this.row) ) {
      return;
    }

    const data = [ {
      userId: this.row.userId,
      curriculumId: this.row.curriculumId,
      // -1 targets current iteration
      iteration: this.row.accountIteration ?? -1,
    } ];
    this.reportService.requestCertificatesForCurriculaAndUsers(data);
  }

  onSendMessage(): void {
    this.reportTableService.onSendMessage([ this.row ]);
  }

  onSwitchPath(): void {
    this.reportTableService.onSwitchPath([ this.row ]);
  }

  onEditLatestValidity() {
    this.reportTableService.onEditLatestValidity( this.row );
  }

  onFixStatus() {
    this.reportService.setLastToCurrentIteration(this.row.curriculumId, this.row.userId);
  }

  isCurriculumAndStatusFixable(): boolean {
    if ( this.inputDisabled || !this.reportTableService.isCurriculum(this.row) ) {
      return;
    }

    return this.row.accountProcessingTimeStatus === CurriculumAccountStatus.ended &&
      this.row.accountDisplayStatus === DisplayStatus.RECERTIFICATION_TIME;
  }


}
