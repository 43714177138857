import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSectionComponent } from './profile-section/profile-section.component';
import { ProfileFieldModule } from '../profile-field/profile-field.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ProfileSectionWrapperComponent } from './profile-section-wrapper/profile-section-wrapper.component';
import { PageHeaderComponent } from '../../page-header/page-header.component';


@NgModule({
  declarations: [
    ProfileSectionComponent,
    ProfileSectionWrapperComponent,
  ],
  exports: [ ProfileSectionComponent, ProfileSectionWrapperComponent ],
  imports: [
    CommonModule,
    ProfileFieldModule,
    PipesModule,
    PageHeaderComponent,
  ],
})
export class ProfileSectionModule {
}
