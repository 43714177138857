<rag-dialog-header>
  <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
</rag-dialog-header>

<div mat-dialog-content>
  <rag-ctrl-single-user-details-learning-data
    [dialogRef]="dialogRef"
    [inputData]="data"
    (saveButtonDisabled)="formGroupHasChanged($event)"
    [submitClicked$]="submitClicked$"
  ></rag-ctrl-single-user-details-learning-data>
</div>

<div mat-dialog-actions>
  <button
    (click)="onCancel()"
    mat-stroked-button
    type="button"
    class="rag-stroked-button"
  >
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    (click)="onSubmitClicked()"
    mat-flat-button
    [disabled]="saveButtonDisabled"
    color="primary"
  >
    <span i18n="@@global_save">Save</span>
  </button>
</div>
