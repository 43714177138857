import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { AdminNotificationsService } from '../admin-notifications.service';
import { NotificationTableData } from '../admin-notifications.types';

export const AdminNotificationsListResolver: ResolveFn<NotificationTableData[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  adminNotificationsService: AdminNotificationsService = inject(AdminNotificationsService)
) => {
  const filterType = route.data['filterType'] ?? null;
  return adminNotificationsService.getNotificationList(filterType);
}