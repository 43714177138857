import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { VersionInfoDialogData } from './version-info.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatButtonModule,
    DialogHeaderComponent,
    MatExpansionModule,
    RouterModule,
  ],
  selector: 'rag-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: [ './version-info.component.scss' ],
})
export class VersionInfoComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VersionInfoDialogData,
  ) {
  }

  hasVersionData(): boolean {
    return this.data?.versionInfo.length > 0;
  }

}
