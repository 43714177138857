import { TableGroupingTypes } from '../../../../../component/table/table-grouping/table-grouping.types';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import * as moment from 'moment';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { Translation } from '../../../../../core/translation/translation.types';
import { ScoAccount } from '../../../../../core/content/content.types';
import { naturalCompare } from '../../../../../core/natural-sort';
import { LanguageHelper } from '../../../../../core/language.helper';
import { DateHelper } from '../../../../../core/date.helper';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { TableGroupingHelper } from '../../../../../component/table/table-grouping/table-grouping.helper';


export namespace CtrlSingleUserDetailsLearningDataTypes {

  export class Util {

    static courseItemToColumns(item: ScoAccount, parent: RowDataParent): RowData {
      const row: RowDataChild = {
        $data: item,
        $parent: parent,
        $rowSort: TableGroupingTypes.Util.calculateRowSort(item.index, parent),
        $rowType: 'child',

        displayStatus: DisplayStatusHelper.toDisplayStatus(item.displayStatus),
        executionDate: DateHelper.toMoment(item.executionDate),
        orderIndex: item.index,
        title: item.scoTitle,
        result: item.result >= 0 ? item.result : null,
      };

      row.$parentRoot = parent.$parentRoot;
      parent.$parentRoot.$maxDepth = Math.max(parent.$parentRoot.$maxDepth || 0, row.$rowSort.length);
      parent.$children.push(row);
      return row;
    }

    static createParentForCourse(course: ControllingSingleUserTypes.ScoCourseAccount): RowDataParent {
      const parent: RowDataParent = {
        $children: [],
        $data: course,
        $rowSort: [ course.courseId ],
        $rowType: 'parent',

        displayStatus: DisplayStatusHelper.toDisplayStatus(course.displayStatus),
        executionDate: DateHelper.toMoment(course.lastExecutionDate),
        orderIndex: null,
        title: course.title,
        result: null,
      };

      parent.$parentRoot = parent;
      return parent;
    }

    static toTableRows<P extends ControllingSingleUserTypes.ScoCourseAccount,
      C extends ScoAccount>(courses: P[]): RowData[] {
      const rows = courses
        .sort((a, b) => naturalCompare(
          LanguageHelper.objectToText(a.title),
          LanguageHelper.objectToText(b.title),
        ))
        .flatMap((course, courseIndex) => {
          const parentRow = Util.createParentForCourse(course);
          parentRow.orderIndex = courseIndex;
          let index = 0;
          course.courseItems?.forEach(item => item.index = index++);

          const childRows = course.courseItems?.map(item => Util.courseItemToColumns(item, parentRow))
            .sort(TableGroupingTypes.Util.compareRowSort) ?? [];
          return [ parentRow, ...childRows ];
        });

      // calculate string to keep sorting stable in grouped tables
      TableGroupingHelper.addDefaultSorting(rows);

      return rows;
    }

  }

  export interface RowData
    extends TableGroupingTypes.TableRow<ControllingSingleUserTypes.ScoCourseAccount, ScoAccount> {
    displayStatus: DisplayStatus;
    executionDate: moment.Moment;
    orderIndex: number;
    result: number;
    title: Translation;
  }

  export interface RowDataChild
    extends RowData, TableGroupingTypes.TableRowChild<ControllingSingleUserTypes.ScoCourseAccount, ScoAccount> {
    $data: ScoAccount;
  }

  export interface RowDataParent
    extends RowData, TableGroupingTypes.TableRowParent<ControllingSingleUserTypes.ScoCourseAccount, ScoAccount> {
    $data: ControllingSingleUserTypes.ScoCourseAccount;
  }

}
