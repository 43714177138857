import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { State } from '../../app.state';
import { Translation } from './translation.types';
import { LanguageHelper } from '../language.helper';


/**
 * @deprecated use $localize (optionally with a switch / case) or {@link LanguageHelper.objectToText}
 */
@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  /**
   * todo replace with ngx-translate
   * @see https://github.com/ngx-translate/core
   */

  private _translations: { [key: string]: Translation } = {
    /** pattern:
     * 'key': $localize`:description@@xliff_key:source_value`
     * */
    accountDisplayStatus_green: $localize`:@@accountDisplayStatus_green:Certified`,
    accountDisplayStatus_red: $localize`:@@accountDisplayStatus_red:Not certified`,
    accountDisplayStatus_recertification: $localize`:@@accountDisplayStatus_recertification:Recertification required`,
    accountDisplayStatus_not_green: $localize`:@@accountDisplayStatus_not_green:Not green`,
    accountDisplayStatus_not_red: $localize`:@@accountDisplayStatus_not_red:Not red`,
    accountProcessingStatus_locked: $localize`:@@accountProcessingStatus_locked:Locked`,
    accountProcessingStatus_valid: $localize`:@@accountProcessingStatus_valid:Valid`,
    accountProcessingStatus_not_attempted: $localize`:@@accountProcessingStatus_not_attempted:Not attempted`,
    accountProcessingStatus_anulled: $localize`:@@accountProcessingStatus_anulled:Anulled`,
    accountProcessingStatus_ended: $localize`:@@accountProcessingStatus_ended:Ended`,
    assignmentMandatory_false: $localize`:@@assignmentMandatory_false:Voluntary`,
    assignmentMandatory_true: $localize`:@@assignmentMandatory_true:Mandatory`,
    courseItemLocked_true: $localize`:@@courseItemLocked_true:Locked`,
    courseItemLocked_notTrue: $localize`:@@courseItemLocked_notTrue:Not locked`,
    courseItemStatus_failed: $localize`:@@courseItemStatus_failed:Failed`,
    courseItemStatus_green: $localize`:@@courseItemStatus_green:Finished`,
    courseItemStatus_not_attempted: $localize`:@@courseItemStatus_not_attempted:Not attempted`,
    courseItemStatus_other: $localize`:@@courseItemStatus_other:Other`,
    courseItemStatus_yellow: $localize`:@@courseItemStatus_yellow:Incomplete`,
    courseSessionRunning_true: $localize`:@@courseSessionRunning_true:Running`,
    courseSessionRunning_false: $localize`:@@courseSessionRunning_false:Not running`,
    courseStatus_green: $localize`:@@courseStatus_green:Completed`,
    courseStatus_other: $localize`:@@courseStatus_other:Other`,
    courseStatus_red: $localize`:@@courseStatus_red:Not attempted`,
    courseStatus_yellow: $localize`:@@courseStatus_yellow:Incomplete`,
    gender_1: $localize`:@@gender_1:Female`,
    gender_2: $localize`:@@gender_2:Male`,
    gender_3: $localize`:@@gender_3:Diverse`,
    gender_4: $localize`:@@gender_4:Unknown`,
    userState_1: $localize`:@@userState_1:Yes`,
    userState_2: $localize`:@@userState_2:No`,
    /*
     * filter actions for type assignmentMandatory
     * $eq
     */
    reportFilterAction_assignmentMandatory_$eq: $localize`:@@reportFilterAction_assignmentMandatory_$eq:Equals`,
    /*
     * filter actions for type date
     * $eq
     * $gte
     * $lte
     */
    reportFilterAction_date_$eq: $localize`:@@reportFilterAction_date_$eq:Equals`,
    reportFilterAction_date_$gte: $localize`:@@reportFilterAction_date_$gte:Is after`,
    reportFilterAction_date_$lte: $localize`:@@reportFilterAction_date_$lte:Is before`,
    /*
     * filter actions for type display status
     * $eq
     */
    reportFilterAction_displayStatus_$eq: $localize`:@@reportFilterAction_displayStatus_$eq:Is`,
    /*
     * filter actions for generic dropdown
     * $eq
     */
    reportFilterAction_dropdown_$eq: $localize`:@@reportFilterAction_dropdown_$eq:Equals`,
    /*
     * filter actions for type number
     * $eq
     */
    reportFilterAction_gender_$eq: $localize`:@@reportFilterAction_gender_$eq:Is`,
    /*
     * filter actions for type number
     * $eq
     * $gt
     * $in
     * $lt
     * $notIn
     */
    reportFilterAction_number_$eq: $localize`:@@reportFilterAction_number_$eq:Equals`,
    reportFilterAction_number_$gt: $localize`:@@reportFilterAction_number_$gt:Is greater than`,
    reportFilterAction_number_$in: $localize`:@@reportFilterAction_number_$in:Is one of`,
    reportFilterAction_number_$lt: $localize`:@@reportFilterAction_number_$lt:Is less than`,
    reportFilterAction_number_$notIn: $localize`:@@reportFilterAction_number_$notIn:Is not one of`,
    reportFilterAction_number_$between: $localize`:@@reportFilterAction_number_$between:Is between (from - to)`,
    /*
     * filter actions for type processing status
     * $eq
     */
    reportFilterAction_processingStatus_$eq: $localize`:@@reportFilterAction_processingStatus_$eq:Is`,
    /*
     * filter actions for type text
     * $endsWith
     * $in
     * $like
     * $notIn
     * $notLike
     * $startsWith
     */
    reportFilterAction_text_$endsWith: $localize`:@@reportFilterAction_text_$endsWith:Ends with`,
    reportFilterAction_text_$in: $localize`:@@reportFilterAction_text_$in:Is one of`,
    reportFilterAction_text_$like: $localize`:@@reportFilterAction_text_$like:Contains`,
    reportFilterAction_text_$notIn: $localize`:@@reportFilterAction_text_$notIn:Is not one of`,
    reportFilterAction_text_$notLike: $localize`:@@reportFilterAction_text_$notLike:Does not contain`,
    reportFilterAction_text_$startsWith: $localize`:@@reportFilterAction_text_$startsWith:Starts with`,
    /**
     * content-offline
     */
    'global.alt.offlineContent.type.seminar': $localize`:@@global.alt.offlineContent.type.seminar:On-site event`,
    'global.alt.offlineContent.type.sbs': $localize`:@@global.alt.offlineContent.type.sbs:Side-by-Side`,
    'global.alt.offlineContent.type.talk': $localize`:@@global.alt.offlineContent.type.talk:Dialog`,
    'global.alt.offlineContent.type.document': $localize`:@@global.alt.offlineContent.type.document:Document(Download)`,
    'global.alt.offlineContent.type.virco': $localize`:@@global.alt.offlineContent.type.virco:Virtual event`,
  };
  private locale: string;

  constructor(
    @Inject(LOCALE_ID) private angularLocale: string,
  ) {
    // todo check if angularLocale is correct
    // initialize to AOT locale
    this.setLocale(angularLocale);
  }

  /**
   * @deprecated use $localize (optionally with a switch / case) or {@link LanguageHelper.objectToText}
   */
  translate(value: Translation, locale?: string): string {
    let result: string;
    if ( typeof (value) === 'string' ) {
      // try to translate string from static values
      result = this.translateKey(value, locale);
    }

    if ( result ) {
      return result;
    }

    return LanguageHelper.objectToText(value, locale);
  }

  /**
   * @deprecated use $localize (optionally with a switch / case)
   */
  translateKey(value: string, locale?: string): string {

    if ( !value ) {
      // empty values should always generate an empty string
      return '';
    }

    if ( Object.prototype.hasOwnProperty.call(this._translations, value) ) {
      // try to find a translation and fall back to '???key:locale???' if it is missing
      return LanguageHelper.objectToText(this._translations[value], locale) || ('???' + value + ':' + locale + '???');
    }

    // accept value as verbatim
    return value;
  }

  private setLocale = (value: string): void => {
    let locale = value || State.language;
    if ( locale.length > 2 ) {
      // convert from language tag
      locale = locale.substr(0, 2);
    }
    this.locale = locale;
  };

}
