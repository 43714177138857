<div class="filter-flex">
  <div class="filter-container">
    <ng-container *ngFor="let filter of selectedFilter$ | async">
      <rag-table-header-filter-default
        class="filter"
        [column]="filter"
        (filterChange)="onFilterChange($event.filter, filter)"
        [outsourcedFilter]="true"
      ></rag-table-header-filter-default>
    </ng-container>
    <div
      class="add-button"
      [ngStyle]="{'margin-bottom': ((selectedFilter$ | async)?.length > 0) ? '2rem' : '0'}"
    >
      <button mat-flat-button type="button" (click)="filterMenu.openMenu($event)">
        <mat-icon svgIcon="plus"></mat-icon>
        <span i18n="@@add_filter">Add filter</span>
      </button>
    </div>
    <div *ngIf="(selectedFilter$ | async)?.length > 0" class="remove-filter">
      <button mat-flat-button (click)="removeAllFilter()">
        <mat-icon svgIcon="close-circle-outline"></mat-icon>
        <span i18n="@@global_delete">Delete</span>
      </button>
    </div>
  </div>
</div>

<rag-table-filter-menu
  #filterMenu
  [filter]="getFilterItems(menuData)"
  (filterChanged)="onSelectionChanged($event)"
  [selectedFilter$]="selectedFilter$"
></rag-table-filter-menu>
