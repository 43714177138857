<mat-stepper
  #stepper
  linear
  [orientation]="'horizontal'"
  (selectionChange)="onSelectionChange($event)"
>
  <ng-template matStepperIcon="done">
    <mat-icon svgIcon="check"></mat-icon>
  </ng-template>

  <mat-step
    *ngIf="postLoginData.hasToResetPassword"
    [editable]="false"
    [completed]="passwordForm.completed"
    label="Change your password"
    i18n-label="@@post_login_stepper_change_password"
  >
    <rag-post-login-password
      #passwordForm
    ></rag-post-login-password>
  </mat-step>

  <mat-step
    *ngIf="postLoginData.recordFields?.length > 0"
    [editable]="false"
    [completed]="userDataForm.completed"
    label="Fill in your profile"
    i18n-label="@@post_login_stepper_user_profile"
  >
    <rag-post-login-user-data
      #userDataForm
      [attributeGroups]="postLoginData.attributeGroups"
      [recordFields]="postLoginData.recordFields"
    ></rag-post-login-user-data>
  </mat-step>

  <mat-step
    *ngIf="hasForwardPageActions()"
    [editable]="false"
    [completed]="false"
    aria-label="forwarding"
    label="Forwarding"
    i18n-label="@@post_login_stepper_forwarding"
  >
    <div>
      <span i18n="@@post_login_stepper_forwarding_to_page">Please wait, you are being forwarded ...</span>
    </div>
  </mat-step>

  <mat-step
    *ngIf="!hasForwardPageActions()"
    label="Finished"
    i18n-label="@@post_login_stepper_finished"
  >
    <p i18n="@@post_login_finished">Thank you very much! You can now continue.</p>

    <div class="buttons">
      <button
        mat-stroked-button type="button"
        class="rag-stroked-button"
        i18n="@@global_done"
        (click)="onDoneClicked()"
      >Done</button>
    </div>
  </mat-step>
</mat-stepper>
