import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControllingCourseMultiActionsComponent } from './controlling-course-multi-actions.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [
    ControllingCourseMultiActionsComponent,
  ],
  exports: [
    ControllingCourseMultiActionsComponent,
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        RouterLink,
        MatDividerModule,
    ],
})
export class ControllingCourseMultiActionsModule {
}
