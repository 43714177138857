import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ItemTypeIconComponent } from './item-type-icon.component';

@NgModule({
  declarations: [
    ItemTypeIconComponent,
  ],
  exports: [
    ItemTypeIconComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ItemTypeIconModule {
}
