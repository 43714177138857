import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableControllerComponent } from '../../../../../component/table/table-controller/table-controller.component';
import { EXT_OAUTH2_USERS_TABLE_DATA, ExtOauth2UsersRow } from './ext-oauth2-users.columns';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { OAuthUserInfoResponse } from './ext-oauth2-users.types';
import { ExtOauth2UsersHelper } from './ext-oauth2-users.helper';
import { ColumnSettings } from '../../../../../core/report/report.types';
import { StickyScrollComponent } from '../../../../../component/sticky-scroll/sticky-scroll.component';
import { LoadingIndicatorComponent } from '../../../../../component/loading-indicator/loading-indicator.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import {
  TablesColumnRenderDefaultModule
} from '../../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import {
  TableHeaderFilterDefaultModule
} from '../../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableColumnMenuModule } from '../../../../../component/table/table-column-menu/table-column-menu.module';
import { PageHeaderComponent } from '../../../../../component/page-header/page-header.component';
import { MatSortModule } from '@angular/material/sort';
import { ExtOauth2UsersService } from './ext-oauth2-users.service';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { MatDividerModule } from '@angular/material/divider';
import { EntityType } from '../../../../../core/journal/journal.types';
import { JournalService } from '../../../../../component/journal/journal.service';
import { PrincipalService } from '../../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../../core/principal/permission.states';


@Component({
  selector: 'rag-ext-oauth2-users',
  standalone: true,
  imports: [
    CommonModule,
    StickyScrollComponent,
    LoadingIndicatorComponent,
    MatTableModule,
    PipesModule,
    TablesColumnRenderDefaultModule,
    TableHeaderFilterDefaultModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatPaginatorModule,
    TableColumnMenuModule,
    PageHeaderComponent,
    MatSortModule,
    MatDividerModule,
  ],
  templateUrl: './ext-oauth2-users.component.html',
  styleUrls: ['./ext-oauth2-users.component.scss']
})
export class ExtOauth2UsersComponent
  extends TableControllerComponent<ExtOauth2UsersRow>
  implements OnInit {

  clientName: string;
  permissions: PermissionStates;

  private _systemUsers: ControllingSingleUserTypes.ControllingUser[];
  private _registrationId: string;

  constructor(
    protected tableColumnMenuService: TableColumnMenuService,
    private extOAuth2UsersService: ExtOauth2UsersService,
    private journalService: JournalService,
    private principalService: PrincipalService,
    private route: ActivatedRoute,
  ) {
    super(tableColumnMenuService);

    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(tap(data => this.updateRouteData(data.data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getClientName(clientName: string): string {
    const prefix = $localize`:@@header_nav_ext_oidc:OpenID Connect` + ' ' + $localize`:@@global_users:Users`;
    if (!clientName) {
      return prefix;
    }
    return `${prefix}: ${clientName}`;
  }

  onChangeUser(row: ExtOauth2UsersRow): void {
    this.extOAuth2UsersService.changeUser(this._registrationId, row, row.principalName, row.userId, this._systemUsers)
      .pipe(tap(data => this.updateRouteData(data)))
      .subscribe();
  }

  onJournal(row: ExtOauth2UsersRow): void {
    this.journalService
      .displayJournalForObject(EntityType.OAUTH_USER_INFO, row.userInfoId, row.principalName)
      .pipe(take(1))
      .subscribe();
  }

  private updateAvailableColumns(userColumns: ColumnSettings[]): void {
    const menuData = TableColumnMenuService
      .createFromDefaults(EXT_OAUTH2_USERS_TABLE_DATA, userColumns);
    const languageOptions = TableColumnMenuService.getLanguageDropdownOptions();
    TableColumnMenuService.addDynamicDropDownOptions(menuData, 'userLanguage', languageOptions);
    this.setMenuData(menuData);
  }

  private updateRouteData(data: OAuthUserInfoResponse | null): void {
    this.clientName = data.clientName;
    this._registrationId = data.registrationId;
    this._systemUsers = data.systemUsers;
    const tableData = ExtOauth2UsersHelper.asTableRows(data);
    this.updateAvailableColumns(data.userColumns);
    this.setTableData(tableData);
    this.inputDisabled = false;
    this.checkFilter();
  }

}
