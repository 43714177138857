import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableMultiActionsComponent } from './report-table-multi-actions.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { SelectTargetGroupsMenuModule } from '../../../../../../component/select-target-groups-menu/select-target-groups-menu.module';


@NgModule({
  declarations: [ ReportTableMultiActionsComponent ],
  exports: [ ReportTableMultiActionsComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    RouterModule,
    SelectTargetGroupsMenuModule,
  ],
})
export class ReportTableMultiActionsModule {
}
