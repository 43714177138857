import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsResolverService } from 'src/app/core/resolvers/reports-resolver.service';
import { AuthGuard } from '../../auth.guard';
import { NotFound404Component } from '../main/not-found-404/not-found-404.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OfficialsDashboardsComponent } from './officials-dashboards/officials-dashboards.component';
import { ClassicReportComponent } from './report/classic-report/classic-report.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { UserCourseDetailsComponent } from './user-details/user-course-details/user-course-details.component';
import { UserCourseResolver } from './user-details/user-course-details/user-course.resolver';
import { UserCurriculumDetailsComponent } from './user-details/user-curriculum-details/user-curriculum-details.component';
import { UserCurriculumResolver } from './user-details/user-curriculum-details/user-curriculum.resolver';
import { ZuepComponent } from './zuep/zuep.component';
import { FurtherBoardsComponent } from './further-boards/further-boards.component';
import { SingleListsComponent } from './single-lists/single-lists.component';
import { ReportGeneratorV2Component } from './report/report-generator-v2/report-generator-v2.component';
import { ReportGeneratorV2Resolver } from './report/report-generator-v2/report-generator-v2.resolver';
import { ReportGeneratorV2TypeGuard } from './report/report-generator-v2/report-generator-v2-type.guard';
import { UserCurriculumItemComponent } from './user-details/user-curriculum-item/user-curriculum-item.component';
import { SelectCurriculumComponent } from './report/report-generator-v2/components/select-curriculum/select-curriculum.component';
import { AdminCurriculumListResolver } from '../admin/admin-curriculum/admin-curriculum-list/admin-curriculum-list.resolver';
import { SideNavContainerHeightGuard } from 'src/app/core/side-nav-container-height.guard';


export const CtrlRoutes: Routes = [
  {
    path: 'ctrl',
    children: [
      {
        path: 'f2f',
        loadChildren: () => import('./offline/offline.module').then(m => m.OfflineModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./single-user/single-user.module').then(m => m.SingleUserModule),
      },
      {
        path: 'quest',
        loadChildren: () => import('./ctrl-quest/ctrl-quest.module').then(m => m.CtrlQuestModule)
      },
    ],
  },
  {
    path: 'trainer',
    loadChildren: () => import('./ctrl-trainer/ctrl-trainer.module').then(m => m.CtrlTrainerModule)
  },
  {
    path: 'report',
    children: [
      { path: '', redirectTo: 'v2/saved', pathMatch: 'full' },
      {
        path: 'classic',
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navCtrlReportClassic',
        },
        children: [
          { path: '', redirectTo: '/report/v2/saved', pathMatch: 'full' }, {
            path: ':reportTemplateId',
            component: ClassicReportComponent,
            resolve: {
              reports: ReportsResolverService,
            },
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [ AuthGuard ],
        canDeactivate: [ SideNavContainerHeightGuard ],
        data: {
          checkPermission: 'navCtrlReportDashboard',
        },
      },
      {
        path: 'fad',
        component: OfficialsDashboardsComponent,
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navCtrlFad',
        },
      },
      {
        path: 'v2',
        children: [ {
          path: '', pathMatch: 'full', redirectTo: 'saved',
        }, {
          path: 'details',
          canActivate: [ AuthGuard ],
          data: {
            checkPermission: 'navCtrlReportGenerator',
          },
          children: [
            {
              path: ':userId',
              children: [ {
                path: 'course/:courseId',
                component: UserCourseDetailsComponent,
                resolve: {
                  userDetails: UserCourseResolver,
                },
              }, {
                path: 'curriculum/:curriculumId',
                resolve: {
                  userDetails: UserCurriculumResolver,
                },
                children: [
                  {
                    path: '', pathMatch: 'full',
                    component: UserCurriculumDetailsComponent,
                  },
                  {
                    path: ':itemType/:itemId',
                    component: UserCurriculumItemComponent,
                  },
                ],
              } ],
            },
          ],
        }, {
          path: 'generator',
          children: [ {
            path: '',
            pathMatch: 'full',
            canActivate: [ ReportGeneratorV2TypeGuard ],
          }, {
            path: 'Curriculum',
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navCtrlReportCurriculum',
              targetType: 'Curriculum',
            },
            children: [
              {
                path: '',
                component: ReportGeneratorV2Component,
                resolve: {
                  reportGeneratorData: ReportGeneratorV2Resolver,
                },
              },
              {
                path: 'select',
                component: SelectCurriculumComponent,
                resolve: {
                  curricula: AdminCurriculumListResolver
                }
              },
              {
                path: ':curriculumId',
                component: ReportGeneratorV2Component,
                resolve: {
                  reportGeneratorData: ReportGeneratorV2Resolver,
                },
              }
            ]
          }, {
            path: 'Course',
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navCtrlReportCourse',
              targetType: 'Course',
            },
            component: ReportGeneratorV2Component,
            resolve: {
              reportGeneratorData: ReportGeneratorV2Resolver,
            },
          }, {
            path: 'Offline',
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navCtrlReportOffline',
              targetType: 'Offline',
            },
            component: ReportGeneratorV2Component,
            resolve: {
              reportGeneratorData: ReportGeneratorV2Resolver,
            },
          } ],
        }, {
          path: 'saved',
          pathMatch: 'full',
          canActivate: [ AuthGuard ],
          data: {
            checkPermission: 'navCtrlReportSaved',
          },
          component: ReportListComponent,
          resolve: {
            reports: ReportsResolverService,
          },
        }, {
          path: 'saved/:uuid',
          canActivate: [ AuthGuard ],
          data: {
            checkPermission: 'navCtrlReportGenerator',
          },
          component: ReportGeneratorV2Component,
          resolve: {
            reportGeneratorData: ReportGeneratorV2Resolver,
          },
        } ],
      },
      {
        path: 'single-lists',
        component: SingleListsComponent,
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navCtrlSingleLists',
        },
      },
      {
        path: 'zuep',
        component: ZuepComponent,
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navCtrlZuep',
        },
      },
      {
        path: 'furtherboards',
        component: FurtherBoardsComponent,
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navCtrlFurtherBoards',
        },
      },
      { path: '404', component: NotFound404Component },
      { path: '**', component: NotFound404Component },
    ],
  } ];

@NgModule({
  imports: [
    RouterModule.forChild(CtrlRoutes),
  ],
  exports: [],
})
export class CtrlRoutingModule {
}
