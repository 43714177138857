import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

export class CachedSubject<T>
  extends BehaviorSubject<T> {

  private _hasValue = false;
  private _isLoading = false;
  private _latestUpdate: number = 0;

  constructor(_value: T) {
    super(_value);
  }

  get hasValue(): boolean {
    return this._hasValue;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get latestUpdate(): number {
    return this._latestUpdate;
  }

  static isEmpty<T>(v: T): boolean {
    return (v == null) || (v === undefined);
  }

  static isNotEmpty<T>(v: T): boolean {
    return !CachedSubject.isEmpty(v);
  }

  next = (value?: T): T => {
    this._hasValue = (value != null) && (value !== undefined);
    this._isLoading = false;
    super.next(value);
    this._latestUpdate = new Date().getTime();
    return value;
  };

  /**
   * Use in <code>.pipe(catchError(this._styleInfo.nextError))</code> to pass errors into CachedSubject.
   */
  nextError = (error?: T): Observable<T> => {
    this._isLoading = false;
    super.error(error);
    return throwError(error);
  };

  /**
   * mark loading finished
   * @deprecated please use {@link CachedSubject.nextError} instead
   */
  queryDone = (): void => {
    this._isLoading = false;
    this._latestUpdate = new Date().getTime();
  };

  /**
   * check if loading data is required and prevent duplicate queries
   */
  queryStart = (): boolean => {
    if ( !(this.hasValue || this.isLoading) ) {
      this._isLoading = true;
      return true;
    }
    return false;
  };

  reset = (): void => {
    this.next(null);
  };

  /**
   * start with value and ignore any empty values (for reset)
   * Caution! should only be used in getters or methods, not in constructors to allow fetching live data
   */
  withInitial = (): Observable<T> => {
    if ( this.hasValue ) {
      return this.asObservable()
        .pipe(startWith<T>(this.value));
    }
    return this.asObservable();
  };

  /**
   * ignore any empty values (for reset)
   */
  withoutEmptyValues = (): Observable<T> => this.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));

  /**
   * start with value and ignore any empty values (for reset)
   * Caution! should only be used in getters or methods, not in constructors to allow fetching live data
   */
  withoutEmptyValuesWithInitial = (): Observable<T> => this.withInitial()
      .pipe(filter(CachedSubject.isNotEmpty));

}
