/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { AccountInterceptor } from './app/core/interceptors/account.interceptor';


enableProdMode();

try {
  AccountInterceptor.readAccountKey();
} catch ( e ) {
  if ( console && console.error ) {
    console.error(e);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
