import { TableColumnBuilder } from 'src/app/component/table/table-column.builder';
import { UserContentActionEnum } from 'src/app/core/content/content.types';
import { TableColumnDataType, TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { AdminCoursesTypes } from '../../../../../core/admin-courses.types';

export namespace CtrlSingleUserDetailsCourseTypes {

  export interface CurriculumIterationStatus {
    resul: string;
    title: string;
    displayStatus: string;
    orderIndex: number;
    finishDate: string;
    whenLastExecuted: string;
  }

  export interface CurriculumStatusType {
    [key: string]: CurriculumIterationStatus;
  }

  type CurricuumSttatusResponse = CurriculumStatusType | {
    startDate: string;
    validSince: string;
    status: string;
    displayStatus: string;
    validUntil: string;
    completed: string;
    curriculumTitle: string;
  };

  export interface CurriculumStatus {
    name: string;
    curstatus: CurricuumSttatusResponse;
  }

  export interface SingleUserCurrIterationStatusDialogParams {
    iteration: number;
    status: CurriculumStatus;
  }

  export interface SingleUserCertificatesDialogParams {
    userId: number;
    userName: string;
    curriculumId: number;
    curriculumCertificates: ControllingSingleUserTypes.CertificateAccount[];
    curriculumSteering: ControllingSingleUserTypes.CurriculumSteering;
    certificateAccount: ControllingSingleUserTypes.CertificateAccount;
  }

  export interface CourseControllingDialogParams {
    courses: ControllingSingleUserTypes.CourseAccountDetails[];
    userId: number;
    extensions?: AdminCoursesTypes.CourseExtension[];
  }

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    assignmentType: TableControllerTypes.ColumnMenuItem;
    courseId: TableControllerTypes.ColumnMenuItem;
    courseType: TableControllerTypes.ColumnMenuItem;
    description: TableControllerTypes.ColumnMenuItem;
    displayStatus: TableControllerTypes.ColumnMenuItem;
    lastExecutionDate: TableControllerTypes.ColumnMenuItem;
    licenceEnded: TableControllerTypes.ColumnMenuItem;
    title: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'batchCheckbox' ],
    endWith: [ 'actions' ],
    menuItems: {

      title: TableControllerTypes.Util
        .textColumnFactory('title', $localize`:@@global_title:Title`, null, true),

      displayStatus: TableControllerTypes.Util.displayStatusColumnFactory('displayStatus',
        $localize`:@@global_status:Status`, {
          green: $localize`:@@status_light_tooltip_green:Green`,
          yellow: $localize`:@@status_light_tooltip_yellow:Yellow`,
          red: $localize`:@@status_light_tooltip_red:Red`,
          not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
          not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
        }, $localize`:@@report_filter_status_is:Status is...`, null, true),

      assignmentType: TableControllerTypes.Util
        .dropdownColumnFactory('assignmentType', $localize`:@@global_assignment:Assignment`, {
          mandatory: $localize`:@@global_assignment_type_filter_mandatory:Mandatory`,
          voluntary: $localize`:@@global_assignment_type_filter_voluntary:Voluntary`,
          /*'both': $localize`:@@global_assignment_type_filter_both:Mandatory + voluntary`,*/
        }, $localize`:@@global_assignment_type_filter_placeholder:Assignment is...`, null, true),

      lastExecutionDate: TableControllerTypes.Util
        .dateColumnFactory('lastExecutionDate',
          $localize`:@@ctrl_single_user_details_lastExecutionDate:Last access`, null, true),

      licenceEnded: TableControllerTypes.Util.withDefaultFilter(
        TableControllerTypes.Util
          .dropdownColumnFactory('licenceEnded', $localize`:@@global_licence_ended:Unassigned`, {
            false: $localize`:@@global_licence_ended_filter_false:Assigned`,
            true: $localize`:@@global_licence_ended_filter_true:Unassigned`,
          }, $localize`:@@global_licence_ended_filter_placeholder:Is unassigned?`, null, true),
        'false',
      ),

      courseType: TableControllerTypes.Util
        .dropdownColumnFactory('courseType', $localize`:@@admin_courses_column_course_type:Type`, {
          13: $localize`:@@admin_courses_type_toDo:Task`,
          8: $localize`:@@admin_courses_type_scoDocument_multimedia:Document / Multimedia`,
          10: $localize`:@@admin_courses_type_link:Link`,
          2: $localize`:@@global_distributable_subtype_test:Test`,
          3: $localize`:@@admin_courses_type_learning:Learning`,
          12: $localize`:@@admin_courses_type_simpleConnect:Adobe Connect room`,
          1: $localize`:@@admin_courses_type_certification:Certification`,
        }, $localize`:@@admin_courses_filter_course_is:Course is...`),

      courseContributionsCount: TableColumnBuilder.start()
        .withType(TableColumnDataType.dropdown)
        .withTitle($localize`:@@ctrl_single_user_details_contributions:Comments`)
        .withColumnId('courseContributionsCount')
        .withDropDownOptions({
          true: $localize`:@@report_filter_has_comments_true:Has comments`,
          false: $localize`:@@report_filter_has_comments_false:Without comments`,
        })
        .withFilterDataAccessor(entry => entry)
        .withFilterMethod((entry, filter) => {
          let courseContributionsCount = 0;
          const actionsMap = entry.$view.actionsMap;
          if (actionsMap != null) {
            const contentActions = actionsMap.get(entry.courseId);
            if (contentActions != null) {
              courseContributionsCount = contentActions.filter(action => action.action === UserContentActionEnum.NEED_CLARIFICATION).length;
            }
          }
          if (filter.value === 'true') {
            return courseContributionsCount > 0;
          }
          return courseContributionsCount === 0;
        })
        .withDisplayValueAccessor(entry => {
          const actionsMap = entry.$view?.actionsMap;
          if (actionsMap != null) {
            const contentActions = actionsMap.get(entry.courseId);
            if (contentActions != null) {
              const count = contentActions.filter(action => action.action === UserContentActionEnum.NEED_CLARIFICATION).length;
              return count > 0 ? count : null;
            }
          }
          return '';
        })
        .build(),

      courseId: TableControllerTypes.Util
        .numberColumnFactory('courseId', $localize`:@@ctrl_single_user_details_courseId:Course ID`),

      description: TableControllerTypes.Util
        .htmlColumnFactory('description', $localize`:@@global_description:Description`),

      batchCheckbox: {
        hidden: true,
        id: 'batchCheckbox',
        selected: true,
      },
      actions: {
        hidden: true,
        id: 'actions',
        selected: true,
      },
    },
  };

}
