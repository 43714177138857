import { Component, OnInit } from '@angular/core';
import { ApiUrls } from '../../../core/api.urls';

@Component({
  selector: 'rag-single-lists',
  templateUrl: './single-lists.component.html',
  styleUrls: [ './single-lists.component.scss' ],
})
export class SingleListsComponent
  implements OnInit {

  url: string;

  constructor() {
  }

  ngOnInit() {
    this.url = ApiUrls.getKey('CtrlSingleLists');
  }

}
