import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from '../../../../component/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { InfoModule } from '../../../../core/info/info.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormSubmitButtonComponent } from '../../../../component/input/form-submit-button/form-submit-button.component';
import {
  AdminSignaturesInputMacroComponent
} from '../admin-signatures-input-macro/admin-signatures-input-macro.component';

@Component({
  selector: 'rag-admin-signatures-copy-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatButtonModule,
    MatDialogModule,
    InfoModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormSubmitButtonComponent,
    AdminSignaturesInputMacroComponent,
  ],
  templateUrl: './admin-signatures-copy-dialog.component.html',
  styleUrls: ['./admin-signatures-copy-dialog.component.scss']
})
export class AdminSignaturesCopyDialogComponent {

  protected form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AdminSignaturesCopyDialogComponent>,
  ) {
    this.form = this.formBuilder.group({
      macro: [null,],
    });
  }

  protected onSubmit(): void {
    if (this.form?.valid !== true) {
      return;
    }
    this.dialogRef.close(this.form.get('macro').value);
  }

}
