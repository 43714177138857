<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@report_saved_eports">Saved reports</span>
  </div>
</rag-page-header>

<rag-report-nav
  [reports]="reports"
></rag-report-nav>

<ng-template #reportActions let-reportConfig="reportConfig">
  <button [matMenuTriggerFor]="contextMenu" mat-icon-button>
    <mat-icon svgIcon="menu"></mat-icon>
  </button>

  <mat-menu #contextMenu="matMenu" [class]="'report-list-class'">
    <a [routerLink]="getReportUrl(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="book-open-variant"></mat-icon>
      <span i18n="@@global_open">Open</span>
    </a>
    <button (click)="deleteReport(reportConfig)" [disabled]="!canDelete(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="delete-forever"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>
    <button (click)="renameReport(reportConfig)" [disabled]="!canEdit(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="rename-box"></mat-icon>
      <span i18n="@@global_rename">Rename</span>
    </button>
    <button (click)="saveAsReport(reportConfig)" [disabled]="(canSaveMore | async) === false"
            mat-menu-item>
      <mat-icon svgIcon="content-save"></mat-icon>
      <span i18n="@@global_save_as">Save As...</span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="downloadAsCsv(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="file-delimited"></mat-icon>
      <span i18n="@@pivot_table_export_csv">Export to CSV</span>
    </button>
    <button (click)="downloadAsXlsx(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span i18n="@@pivot_table_export_xlsx">Export to XLSX</span>
    </button>
    <button
      (click)="onXLSXExportLearningTime(reportConfig)"
      *ngIf="hasType(reportConfig, 'Curriculum')"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span i18n="@@pivot_table_export_xlsx_learning_time">Export learning time to XLSX</span>
    </button>

    <ng-container *ngIf="(canUseDashboard | async)">
      <mat-divider></mat-divider>
      <button (click)="addChartWidget(reportPieChartWidgetUUID, reportConfig)" *ngIf="chartsAvailable(reportConfig)"
              mat-menu-item>
        <mat-icon svgIcon="chart-pie"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_pie_chart">Add pie chart to dashboard</span>
      </button>
      <button (click)="addChartWidget(reportBarChartWidgetUUID, reportConfig)" *ngIf="chartsAvailable(reportConfig)"
              mat-menu-item>
        <mat-icon svgIcon="chart-bar"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_bar_chart">Add bar chart to dashboard</span>
      </button>
      <button (click)="addReportLinksWidget(reportConfig)" mat-menu-item>
        <mat-icon svgIcon="link"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_links">Add quick link to dashboard</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-template>

<ng-template #reportActionsClassic let-reportConfig="reportConfig">
  <button [matMenuTriggerFor]="contextMenu" mat-icon-button>
    <mat-icon svgIcon="menu"></mat-icon>
  </button>

  <mat-menu #contextMenu="matMenu">
    <a [routerLink]="getReportUrl(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="book-open-variant"></mat-icon>
      <span i18n="@@global_open">Open</span>
    </a>
    <button (click)="downloadAsZip(reportConfig)" mat-menu-item>
      <mat-icon svgIcon="folder-zip-outline"></mat-icon>
      <span i18n="@@pivot_table_export_zip">Export to ZIP</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #noReports>
  <div class="no-reports">
    <div i18n="@@report_no_reports_to_be_shown">There are no available reports to be displayed. You can create one by
      pressing on 'New Report'.
    </div>
  </div>
</ng-template>

<div *ngIf="this.reports && this.reports.length > 0; else noReports" class="table-wrapper">
  <table [dataSource]="dataSource" mat-table
         matSort matSortActive="favorite" matSortDirection="desc" matSortDisableClear>

    <ng-template #tplDateOrDash let-date="date" let-isValid="isValid">
      <ng-container *ngIf="isValid">
        <div class="col-label">{{date | formatDate}}</div>
      </ng-container>
      <ng-container *ngIf="!isValid">
        <div class="col-label invalid-date">&mdash;</div>
      </ng-container>
    </ng-template>

    <ng-container matColumnDef="favorite">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@title_favorite">Favorite</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
        <div class="col-label">
          <button
            (click)="toggleFavorite(reportConfig)"
            [disabled]="reportConfig['targetType'] === 'ClassicReport'"
            aria-label="label"
            mat-icon-button>
            <mat-icon [ngClass]="{'favorite': !!reportConfig['favorite']}" svgIcon="star-outline"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@global_title">Title</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
      <span class="col-label">
        <a [routerLink]="getReportUrl(reportConfig)">
          <rag-clamp [clamp]="3" [input]="reportConfig.title"></rag-clamp>
        </a>
      </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="reportType">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@global_type">Type</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
      <div class="col-label report-type">{{reportTypeForConfig(reportConfig)}}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@global_author">Author</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
        <span class="col-label">
          <a [routerLink]="getReportUrl(reportConfig)">{{reportConfig['userName']}}</a>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="createDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@create_date">Created</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
        <ng-container *ngTemplateOutlet="tplDateOrDash; context: {
        date: reportConfig['createDate'],
        isValid: !!reportConfig['createDate']
      }"></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="modifiedDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        <span i18n="@@modified_date">Last modified</span>
      </th>
      <td *matCellDef="let reportConfig" mat-cell>
        <ng-container *ngTemplateOutlet="tplDateOrDash; context: {
        date: reportConfig['modifiedDate'],
        isValid: (reportConfig['modifiedDate'] && (reportConfig['modifiedDate'] !== reportConfig['createDate']))
      }"></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let reportConfig" mat-cell>
        <ng-container *ngIf="reportConfig['targetType'] !== 'ClassicReport'">
          <ng-container *ngTemplateOutlet="reportActions; context: {reportConfig: reportConfig}"></ng-container>
        </ng-container>
        <ng-container *ngIf="reportConfig['targetType'] === 'ClassicReport'">
          <ng-container *ngTemplateOutlet="reportActionsClassic; context: {reportConfig: reportConfig}"></ng-container>
        </ng-container>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let reportConfig; columns: displayedColumns;" mat-row></tr>
  </table>
</div>
