import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminScriptService } from './admin-script.service';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AdminScriptResolver
  implements Resolve<string> {

  constructor(
    private adminScriptService: AdminScriptService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    return this.adminScriptService.getAccountScript()
      .pipe(take(1));
  }

}
