import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QAService } from './qa-list.service';
import { QAResponse } from '../qa.types';

@Injectable({ providedIn: 'root' })
export class QAListResolver
  implements Resolve<QAResponse> {

  constructor(
    private qaService: QAService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QAResponse> {
    return combineLatest([
      this.qaService.getAllQAsForUser(),
      this.qaService.getAllTagsForQA(),
    ])
      .pipe(map(([questions, tags]) => ({
          questions,
          tags,
        })));
  }
}
