import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ItemTypeIconModule } from '../item-type-icon/item-type-icon.module';
import { ContentAutocompleteComponent } from './content-autocomplete.component';

@NgModule({
  declarations: [
    ContentAutocompleteComponent,
  ],
  exports: [
    ContentAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ItemTypeIconModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
})
export class ContentAutocompleteModule {
}
