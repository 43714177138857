import { DisplayStatus } from '../../../../../core/display-status.enum';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';

export namespace CtrlSingleUserDetailsCurriculumEditTypes {

  export class DialogData {
    curricula: ControllingSingleUserTypes.CurriculumAccount[];
    userId: number;
  }

  export class DialogResultEntry {
    curriculumId: number;
    curriculumItemId: number;
    displayStatus: DisplayStatus;
    finishDate: number;
    locked: boolean;
    orderIndex: number;
  }

  /**
   * values of accountFinishDate, displayStatus, and locked may be null to leave unchanged
   */
  export class PostDataCurriculumItem {
    accountFinishDate: number;
    curriculumItemId: number;
    displayStatus: DisplayStatus;
    locked: boolean;
  }

  export class PostDataCurriculum {
    curriculumId: number;
    itemStatus: PostDataCurriculumItem[];
  }

}
