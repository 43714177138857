import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PDFSource, PdfViewerModule } from 'ng2-pdf-viewer';
import { ApiUrls } from 'src/app/core/api.urls';
import { CoreModule } from 'src/app/core/core.module';
import { FileInfo } from 'src/app/core/core.types';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { Translation } from 'src/app/core/translation/translation.types';
import { Files } from "./file.types";
import { InfoService } from "../../core/info/info.service";
import { MessageConstants } from "../../core/info/info.types";

type fileType = 'image' | 'pdf' | 'csv' | 'zip' | 'document' | 'docx';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    PdfViewerModule,
    MatButtonModule,
    PipesModule,
    CoreModule,
  ],
  selector: 'rag-file',
  templateUrl: './file.component.html',
  styleUrls: [ './file.component.scss' ],
})
export class FileComponent
  implements OnInit, OnChanges {

  @Input() acceptTypes: string[];
  actionIcon = 'close';
  @HostBinding('class.disabled')
  @Input() disabled?: boolean;
  @Input() actionButtonsDisabled = false;
  @Input() caption = '';
  @Input() file?: File;
  // @Input() file?: UploadFile;
  @Input() fileInfo?: FileInfo;
  @Input() accessorryViews?: Array<TemplateRef<any>>;
  @ViewChild('file', { static: true }) fileInput: ElementRef;
  fileName: string;
  fileTypeIcon = 'image';
  imgURL: string | Uint8Array | PDFSource;
  message: string = null;
  @Input() downloadLink?: string = null;
  @Input() showSizeTooltip = false;
  @Output() removeFile: EventEmitter<void>;
  @Output() selectedFile: EventEmitter<File>;
  private _accept = 'image/*';
  private _fileType: fileType = 'image';

  constructor(
    private infoService: InfoService,
  ) {
    this.selectedFile = new EventEmitter();
    this.removeFile = new EventEmitter();
  }

  get hasAccessorryViews() {
    return (this.fileInfo != null || this.file != null) && this.accessorryViews?.length > 0;
  }

  get accept() {
    return this._accept;
  }

  @Input()
  set accept(value: string) {
    this._accept = value;
  }

  get isImage(): boolean {
    return this._fileType === 'image';
  }

  get isPdf(): boolean {
    return this._fileType === 'pdf';
  }

  get isCsv(): boolean {
    return this._fileType === 'csv';
  }

  get isDocx(): boolean {
    return this._fileType === 'docx'
  }

  get isZip(): boolean {
    return this._fileType === 'zip';
  }

  get isDocument(): boolean {
    return this._fileType === 'document';
  }

  get tooltip(): Translation {
    return this.actionIcon === 'close' ? {
      en: 'Delete file',
      de: 'Datei entfernen',
    } : {
      en: 'Revert selected file',
      de: 'Zurücksetzen',
    };
  }

  getAcceptFileExtensions(): string[] | null {
    const types = this.getFilesDropAreaAcceptTypes();
    if (!(types?.length > 0)) {
      return null;
    }
    const extensions = Files.getExtensions(types);
    return (extensions?.length > 0) ? extensions : null;
  }

  getAcceptFileExtensionsText(extensions: string[]): string {
    return $localize`:@@file_component_accepted_extensions:Allowed file extensions: ` + extensions.join(', ');
  }

  getFilesDropAreaAcceptTypes(): string[] {
    if (this.acceptTypes?.length > 0) {
      return this.acceptTypes;
    } else if (this._accept != null) {
      return [this._accept];
    }
    return [];
  }

  getInputAcceptTypes(): string | string[] | null {
    return this.acceptTypes ?? this._accept;
  }

  ngOnChanges(changes: SimpleChanges) {

    // console.log(this.fileInfo);
    // console.log(changes);
    this.setupFileIcon();
    const closure = () => {
      this.imgURL = null;
      this.fileName = null;
      this.fileInput.nativeElement.value = null;
    };

    if ( Object.prototype.hasOwnProperty.call(changes, 'file') ) {
      if ( changes.file.currentValue == null ) {
        if ( this.fileInfo != null ) {
          this.showFileInfo();
          return;
        }
        closure();
      } else {
        if (this.isZip || this.isDocument) {
          this.fileName = this.fileInfo.fileName;
        } else {
          this.preview(this.file);
        }
      }
    }
    if ( Object.prototype.hasOwnProperty.call(changes, 'fileInfo') ) {
      if ( changes.fileInfo.currentValue == null ) {
        closure();
      }
    }
  }

  ngOnInit() {
    if ( this.file != null ) {
      this.preview(this.file);
      return;
    }
    if ( this.fileInfo != null ) {
      this.showFileInfo();
    }
  }

  onFile(files: FileList) {
    if ( files.length === 0 ) {
      return;
    }
    const file = files[0];
    if ( !this.isValidType(file.type) ) {
      this.infoService.showMessage($localize`:@@global_error_file_type:The file type ${file.type} is not supported.`, {
        title: MessageConstants.DIALOG.TITLE.WARNING
      });
      this.message = 'This file type is not supported';
      return;
    }
    this.actionIcon = this.fileInfo != null ? 'undo' : 'close';
    this.selectedFile.emit(file);
  }

  onRemove(_: MouseEvent) {
    this.actionIcon = 'close';
    if ( this.file != null ) {
      this.selectedFile.emit(null);
      return;
    }
    this.imgURL = null;
    this.fileInput.nativeElement.value = null;
    this.removeFile.emit();
    this.fileName = null;
  }

  preview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      // todo: handle errors as well
      const result = reader.result;
      if (typeof result === 'string') {
        this.imgURL = reader.result as string;
      } else {
        this.imgURL = new Uint8Array(result);
      }
      this.fileName = file.name;
    };
  }

  private isValidType(type: string | null): boolean {

    if (typeof(type) !== 'string') {
      return false;
    }

    if (this.acceptTypes?.includes('document') || this.accept === 'document') {
      return true;
    }

    if (this.acceptTypes == null) {
      return type.match(this.accept) != null;
    }

    return this.acceptTypes
      .find(entry => entry.toLocaleLowerCase() === type.toLocaleLowerCase()) != null;
  }

  private setupFileIcon(): void {

    if ( this.acceptTypes?.includes('text/x-csv') ) {
      this._fileType = 'csv';
      this.fileTypeIcon = 'file-delimited';
      return;
    }

    if ( this.acceptTypes?.includes('application/x-zip-compressed') ) {
      this._fileType = 'zip';
      this.fileTypeIcon = 'folder-zip';
      return;
    }

    if (this.acceptTypes?.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      this._fileType = 'docx';
      this.fileTypeIcon = 'file-word';
      return;
    }

    if ( this._accept == null ) {
      return;
    }

    if (this.acceptTypes === Files.DOCUMENT_MIME) {
      this._fileType = 'document';
      this.fileTypeIcon = 'file-document';
    }

    if ( this._accept.startsWith('application/pdf') ) {
      this._fileType = 'pdf';
      this.fileTypeIcon = 'file-pdf-box';
    }
  }

  private showFileInfo() {
    this.actionIcon = 'close';
    if (this.fileInfo.uuid != null) {
      this.imgURL = ApiUrls.getKey('Files') + '/' + this.fileInfo.uuid;
    } else {
      this.imgURL = null;
    }
    this.fileName = this.fileInfo.fileName;
    // console.log(this.fileName);
  }

  showActionButton() {
    if (!this.imgURL) {
      return false;
    }

    return !this.actionButtonsDisabled && !this.disabled;
  }
}
