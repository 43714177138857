import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { ApiResponse } from '../global.types';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  constructor(
    private http: HttpClient,
  ) {
  }

  rbacGetPermissions(objectId: string, target: string): Observable<Array<string>> {
    const url = `${ApiUrls.getKey('GetPermissions')}/${target}/${objectId}`;
    return this.http.get<ApiResponse<Array<string>>>(url).pipe(map(response => response.permissions));
  }

}
