import { Component, Input, OnInit } from '@angular/core';
import { LearnerAccountTypes } from 'src/app/core/learner-account/learner-account.types';
import { LearnerContentTypes } from 'src/app/route/user/content/learner-content-util/learner-content.types';

@Component({
  selector: 'rag-todo-data',
  templateUrl: './todo-data.component.html',
  styleUrls: ['./todo-data.component.scss']
})
export class TodoDataComponent implements OnInit {

  @Input() courseTodo: LearnerContentTypes.CourseAccountToDo;

  constructor() { }

  ngOnInit(): void {
  }

}
