import { Component, HostBinding, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderSidenavMenuState } from './header-sidenav-menu.types';
import { CachedSubject } from '../../../core/cached-subject';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'rag-header-sidenav-menu',
  templateUrl: './header-sidenav-menu.component.html',
  styleUrls: [ './header-sidenav-menu.component.scss' ],
  exportAs: 'ragHeaderSidenavMenu',
})
export class HeaderSidenavMenuComponent {

  @Output() readonly closed$: Observable<void>;
  @Output() readonly opened$: Observable<void>;
  @HostBinding('class') state: HeaderSidenavMenuState = HeaderSidenavMenuState.collapsed;
  @Output() readonly state$: Observable<HeaderSidenavMenuState>;
  private readonly _state$: CachedSubject<HeaderSidenavMenuState>;

  constructor() {
    this._state$ = new CachedSubject<HeaderSidenavMenuState>(this.state);
    this.state$ = this._state$.asObservable();
    this.closed$ = this._state$
      .pipe(filter(state => state === HeaderSidenavMenuState.collapsed))
      .pipe(map(() => void (0)));
    this.opened$ = this._state$
      .pipe(filter(state => state === HeaderSidenavMenuState.expanded))
      .pipe(map(() => void (0)));
  }

  public onClose(): void {
    this.onToggle(HeaderSidenavMenuState.collapsed);
  }

  public onOpen(): void {
    this.onToggle(HeaderSidenavMenuState.expanded);
  }

  public onToggle(
    state?: HeaderSidenavMenuState,
  ): void {
    state ??= (this.state === HeaderSidenavMenuState.collapsed) ?
      HeaderSidenavMenuState.expanded :
      HeaderSidenavMenuState.collapsed;
    if ( this.state !== state ) {
      this.state = state;
      this._state$.next(this.state);
    }
  }

}
