import { ControllingSingleUserTypes } from './ctrl-single-user.types';


export class UserNameHelper {

  static getFullName(
    user: ControllingSingleUserTypes.ControllingUser | null,
  ): string {
    return UserNameHelper.getFullNameWithUserId(
      user.userFirstname, user.userLastname, user.userId,
    );
  }


  static getFullNameWithUserId(
    firstName: string,
    lastName: string,
    userId: number,
  ): string {

    if ( !(userId > 0) ) {
      return '';
    }

    if ( firstName && lastName ) {
      return `${firstName} ${lastName} (${userId})`;
    }

    if ( firstName ) {
      return `${firstName} (${userId})`;
    }

    if ( firstName ) {
      return `${lastName} (${userId})`;
    }

    return `${userId}`;
  }

  static getFullNameWithTitle(
    firstName: string,
    lastName: string,
    academicTitle?: string
  ): string {

    if ( academicTitle === '-' ) {
      academicTitle = '';
    }

    if ( firstName && lastName && academicTitle ) {
      return `${academicTitle} ${firstName} ${lastName}`;
    } else if ( firstName && lastName ) {
      return `${firstName} ${lastName}`;
    } else if ( firstName ) {
      return `${firstName}`;
    } else if ( lastName ) {
      return `${lastName}`;
    }
    return '';
  }

  static getFullNameWithLogin(
    firstName: string,
    lastName: string,
    email: string | null,
    login: string | null,
    userId: number,
  ): string {

    let identifier;
    if ( email != null && email !== '' ) {
      identifier = email;
    } else if ( login != null && login !== '' ) {
      identifier = login;
    } else if ( userId != null ) {
      identifier = userId;
    } else {
      identifier = '';
    }

    if ( firstName && lastName ) {
      return `${firstName} ${lastName} (${identifier})`;
    } else if ( firstName ) {
      return `${firstName} (${identifier})`;
    } else if ( lastName ) {
      return `${lastName} (${identifier})`;
    }
    return '';
  }
}
