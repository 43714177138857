import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { TableColumnMenuService } from 'src/app/component/table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from 'src/app/component/table/table-controller/table-controller.component';
import { takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { QueryParamsService } from 'src/app/core/storage/query-params.service';
import { ContentFilterHelper } from '../../../../component/content-filter/content-filter.helper';
import { ColumnFilterV2 } from '../../../../core/column-settings/column-filter.types';
import { TableControllerTypes } from '../../../../component/table/table-controller/table-controller.types';
import { PermissionStates } from '../../../../core/principal/permission.states';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { TransactionsListRow, TransactionView } from '../transactions.types';
import { TransactionsListService } from './transactions-list.service';
import { TRANSACTIONS_LIST_DEFAULT_MENU_COLUMNS } from './transactions-list.columns';
import { FinancialsHelper } from 'src/app/core/financials/financials.helper';

@Component({
  selector: 'rag-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: [ './transactions-list.component.scss' ],
})
export class TransactionsListComponent
  extends TableControllerComponent<TransactionsListRow> implements OnInit {

  isMenuOpen = false;
  permissions: PermissionStates;

  constructor(
    private transactionsListService: TransactionsListService,
    private route: ActivatedRoute,
    protected tableColumnMenuService: TableColumnMenuService,
    private queryParamsService: QueryParamsService,
    private principalService: PrincipalService,
  ) {
    super(tableColumnMenuService);
    this.defaultSort = 'whenCreated';
    this.defaultSortDirection = 'desc';
  }

  ngOnInit() {
    this.principalService.permissionStates$
    .pipe(tap(permissions => this.permissions = permissions))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.observeQueryParams();

    this.route.data
      .pipe(tap(this.updateRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.transactionsListService.updateData$
      .pipe(tap(this.onUpdateData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  isNegative(row: TransactionsListRow) {
    return row.type === 'refund';
  }

  onFilterChange<K>(f: ColumnFilterV2<string, K | TransactionsListRow>, column: TableControllerTypes.ColumnMenuItem<K | TransactionsListRow>) {
    super.onFilterChange(f, column);

    // update query params
    const queryParams = ContentFilterHelper.asQueryParams(this.filters);
    this.queryParamsService.patchQueryParams(queryParams);
  }

  onUpdateData() {
    this.transactionsListService.getTransactions()
      .pipe(tap(transactions => this.setTableData(this.convertTransactionViewsIntoRows(transactions))))
      .pipe(take(1))
      .subscribe();
  }

  private convertTransactionViewsIntoRows(entries: Array<TransactionView>) {
    return entries.map(wrapper => {
      const transaction = wrapper.transaction;
      const total = FinancialsHelper.fromPriceInCent(transaction.total);
      let currency = transaction.currency || 'EUR';
      if ( !total ) {
        currency = '';
      }
      return {
        total,
        extras: transaction.extras,
        currency,
        status: transaction.status,
        type: wrapper.type,
        whenCreated: wrapper.whenCreated,
        lineItems: wrapper.items
      };
    });
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(TRANSACTIONS_LIST_DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateRouteData = (routeData: {transactions: Array<TransactionView>}): void => {

    const tableData = this.convertTransactionViewsIntoRows(routeData.transactions);

    this.updateAvailableColumns();
    this.setTableData(tableData);
    this.inputDisabled = false;
    this.checkFilter();
  };

  private observeQueryParams(): void {

    // check if query params have changed
    this.queryParamsService.queryParams$

      // only include changes from routing
      .pipe(filter(queryParams => queryParams.mode === 'route'))

      // force async handling
      .pipe(debounceTime(10))

      // update the filter values
      .pipe(map(queryParams => ContentFilterHelper.addQueryParams(this.filters, queryParams?.queryParams)))

      // notify external observers if filter values have changed
      .pipe(tap(filters => {
        if ( filters != null ) {
          this.checkFilter();
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

}
