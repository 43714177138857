import { Component, OnDestroy } from '@angular/core';
import { NavItem, NavItemSpecial } from '../../core/navigation/navigation.types';
import { HeaderService } from '../../core/navigation/header.service';
import { ApplicationStateService } from '../../core/application-state.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { HeaderNavHelper } from './header-nav.helper';


@Component({
  selector: 'rag-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
})
export class HeaderComponent
  implements OnDestroy {

  isGtMedium = true;
  isSmall = true;
  mainItems: NavItem[] | null;
  showContentSearch = false;
  showSideNav = false;
  topItems: NavItem[] | null;

  constructor(
    private appState: ApplicationStateService,
    public headerNavHelper: HeaderNavHelper,
    private headerService: HeaderService,
  ) {
    this.headerService.getNavigation()
      .pipe(tap(navigationData => {
        this.showContentSearch = navigationData?.showContentSearch ?? false;
        const topItems = navigationData?.top ?? [];
        this.topItems = (topItems.length > 0) ? topItems : null;
        const mainItems = navigationData?.main ?? [];
        this.mainItems = (mainItems.length > 0) ? mainItems : null;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.appState.viewportState$
      .pipe(tap(state => {
        this.isSmall = state.isSmall();
        this.isGtMedium = state.isGtMedium();
        this.showSideNav = state.isLtEqMedium();
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getLanguageItem(): NavItem {
    return this.topItems?.filter(item => item.special === NavItemSpecial.language)?.[0];
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onToggleSideNav(): void {
    this.appState.toggleSidenav();
  }

}
