import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CMILessonStatus, CmiLessonStatusHelper } from '../../../core/cmi-lesson-status.helper';
import { CourseStatus, CourseStatusHelper } from '../../../core/course-status.helper';
import { DisplayStatusColors, DisplayStatusHelper } from '../../../core/display-status-helper';
import { DisplayStatus } from '../../../core/display-status.enum';
import { AnyObject, Core } from '../../../core/core.types';
import { CourseTypeHelper } from '../../../core/course-type-helper';


interface StatusLightsValues {
  courseStatus: CourseStatus;
  lessonStatus: CMILessonStatus;
  status: DisplayStatus;
}

@Component({
  selector: 'rag-status-lights',
  templateUrl: './status-lights.component.html',
  styleUrls: [ './status-lights.component.scss' ],
})
export class StatusLightsComponent
  implements OnChanges {

  private static TOOLTIPS: AnyObject<string> = {
    browsed: $localize`:@@status_light_tooltip_browsed:Browsed`,
    certified: $localize`:@@status_light_tooltip_certified:Certified`,
    complete: $localize`:@@status_light_tooltip_complete:Complete`,
    completeCourse: $localize`:@@status_light_tooltip_completeCourse:Completed`,
    completed: $localize`:@@status_light_tooltip_completeCourse:Completed`,
    failed: $localize`:@@status_light_tooltip_failed:Failed`,
    incomplete: $localize`:@@status_light_tooltip_incomplete:Incomplete`,
    invalid: $localize`:@@status_light_tooltip_invalid:Invalid`,
    notAttempted: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
    notCertified: $localize`:@@status_light_tooltip_notCertified:Not certified`,
    offlineContentComplete: $localize`:@@status_light_tooltip_offlineContentComplete:Finished`,
    offlineContentInProgress: $localize`:@@status_light_tooltip_offlineContentInProgress:Booked`,
    offlineContentNoEvents: $localize`:@@status_light_tooltip_offlineContentNoEvents:No events`,
    passed: $localize`:@@status_light_tooltip_passed:Passed`,
    readAndSignIncomplete: $localize`:@@status_light_readAndSignIncomplete:Document read`,
    readAndSignConfirmed: $localize`:@@status_light_readAndSignConfirmed:Read and understood`,
    readAndSignWithQuestions: $localize`:@@status_light_readAndSignWithQuestions:Read with questions`,
    recertificationTime: $localize`:@@status_light_tooltip_recertificationTime:Recertification`,
    unKnown: $localize`:@@status_light_tooltip_unknown:Unknown`,
  };
  @Input() courseType: Core.CourseType | number;
  @Input() displayHint = false;
  @Input() displayStatus: string | number;
  @Input() hasConfirmation: boolean;
  @Input() hideToolTip = false;
  iconTooltip: string;
  iconType: string;
  @Input() statusType: string;
  @Input() targetType: Core.DistributableType | string;

  private static getContentType(value: string): string {
    switch ( value ?? '' ) {
      case 'Course':
      case 'CourseStatus':
        return 'CourseStatus';
      case 'CMILessonStatus':
        return 'CMILessonStatus';
      default:
        return 'DisplayStatus';
    }
  }

  private static getIconType(status: DisplayStatus): string {
    switch ( DisplayStatusHelper.toColor(status) ) {
      case DisplayStatusColors.red_green:
        return 'status-red-green';
      case DisplayStatusColors.red:
        return 'status-red';
      case DisplayStatusColors.yellow:
        return 'status-yellow';
      case DisplayStatusColors.green:
        return 'status-green';
      case DisplayStatusColors.none:
      default:
        return '';
    }
  }

  private static getStatusValues(statusType: string, displayStatus: string | number): StatusLightsValues {
    let status: DisplayStatus;
    let lessonStatus: CMILessonStatus;
    let courseStatus: CourseStatus;

    if ( statusType === 'CourseStatus' ) {

      courseStatus = CourseStatusHelper.toCourseStatus(displayStatus);
      status = CourseStatusHelper.toDisplayStatus(courseStatus);
      if ( status === DisplayStatus.UN_KNOWN ) {
        courseStatus = undefined;
        status = DisplayStatusHelper.toDisplayStatus(displayStatus);
      }
    } else if ( statusType === 'CMILessonStatus' ) {

      lessonStatus = CmiLessonStatusHelper.toLessonStatus(displayStatus);
      status = CmiLessonStatusHelper.toDisplayStatus(lessonStatus);
      if ( status === DisplayStatus.UN_KNOWN ) {
        lessonStatus = undefined;
        status = DisplayStatusHelper.toDisplayStatus(displayStatus);
      }
    } else {

      status = DisplayStatusHelper.toDisplayStatus(displayStatus);
    }

    return { status, lessonStatus, courseStatus };
  }

  private static getTooltipCMILessonStatus(lessonStatus: CMILessonStatus): string {
    switch ( lessonStatus ) {
      case CMILessonStatus.PASSED:
        return StatusLightsComponent.TOOLTIPS['passed'];
      case CMILessonStatus.COMPLETED:
        return StatusLightsComponent.TOOLTIPS['completed'];
      case CMILessonStatus.FAILED:
        return StatusLightsComponent.TOOLTIPS['failed'];
      case CMILessonStatus.INCOMPLETE:
        return StatusLightsComponent.TOOLTIPS['incomplete'];
      case CMILessonStatus.BROWSED:
        return StatusLightsComponent.TOOLTIPS['browsed'];
      case CMILessonStatus.NOT_ATTEMPTED:
        return StatusLightsComponent.TOOLTIPS['notAttempted'];
      case CMILessonStatus.UN_KNOWN:
        return StatusLightsComponent.TOOLTIPS['unKnown'];
      default:
        return StatusLightsComponent.TOOLTIPS['invalid'];
    }
  }

  private static getTooltipCourseStatus(courseStatus: CourseStatus): string {
    switch ( courseStatus ) {
      case CourseStatus.COMPLETE:
        return StatusLightsComponent.TOOLTIPS['completeCourse'];
      case CourseStatus.INCOMPLETE:
        return StatusLightsComponent.TOOLTIPS['incomplete'];
      case CourseStatus.NOT_ATTEMPTED:
        return StatusLightsComponent.TOOLTIPS['notAttempted'];
      case CourseStatus.UN_KNOWN:
        return StatusLightsComponent.TOOLTIPS['unKnown'];
    }
  }

  private static getTooltipCurriculum(status: DisplayStatus): string {
    switch ( status ) {
      case DisplayStatus.UN_KNOWN:
        return StatusLightsComponent.TOOLTIPS['unKnown'];
      case DisplayStatus.CERTIFIED:
        return StatusLightsComponent.TOOLTIPS['certified'];
      case DisplayStatus.NOT_CERTIFIED:
      case DisplayStatus.NOT_ATTEMPTED:
        return StatusLightsComponent.TOOLTIPS['notCertified'];
      case DisplayStatus.RECERTIFICATION_TIME:
        return StatusLightsComponent.TOOLTIPS['recertificationTime'];
      default:
        return StatusLightsComponent.getTooltipDisplayStatus(status);
    }
  }

  private static getTooltipDisplayStatus(status: DisplayStatus): string {
    switch ( status ) {
      case DisplayStatus.UN_KNOWN:
        return StatusLightsComponent.TOOLTIPS['unKnown'];
      case DisplayStatus.CERTIFIED:
        return StatusLightsComponent.TOOLTIPS['certified'];
      case DisplayStatus.PASSED:
        return StatusLightsComponent.TOOLTIPS['passed'];
      case DisplayStatus.COMPLETE:
        return StatusLightsComponent.TOOLTIPS['complete'];
      case DisplayStatus.RECERTIFICATION_TIME:
        return StatusLightsComponent.TOOLTIPS['recertificationTime'];
      case DisplayStatus.INCOMPLETE:
        return StatusLightsComponent.TOOLTIPS['incomplete'];
      case DisplayStatus.FAILED:
        return StatusLightsComponent.TOOLTIPS['failed'];
      case DisplayStatus.NOT_CERTIFIED:
        return StatusLightsComponent.TOOLTIPS['notCertified'];
      case DisplayStatus.NOT_ATTEMPTED:
        return StatusLightsComponent.TOOLTIPS['notAttempted'];
      case DisplayStatus.UN_ASSIGNED:
      default:
        return StatusLightsComponent.TOOLTIPS['invalid'];
    }
  }

  private static getTooltipOfflineContent(status: DisplayStatus): string {
    switch ( status ) {
      case DisplayStatus.NOT_ATTEMPTED:
        return StatusLightsComponent.TOOLTIPS['offlineContentNoEvents'];
      case DisplayStatus.INCOMPLETE:
        return StatusLightsComponent.TOOLTIPS['offlineContentInProgress'];
      case DisplayStatus.COMPLETE:
        return StatusLightsComponent.TOOLTIPS['offlineContentComplete'];
      default:
        return StatusLightsComponent.getTooltipDisplayStatus(status);
    }
  }

  private static getTooltipReadAndSign(status: DisplayStatus): string | null {
    switch ( status ) {
      case DisplayStatus.INCOMPLETE:
        return StatusLightsComponent.TOOLTIPS['readAndSignIncomplete'];
      case DisplayStatus.COMPLETE:
        return StatusLightsComponent.TOOLTIPS['readAndSignConfirmed'];
      case DisplayStatus.FAILED:
        return StatusLightsComponent.TOOLTIPS['readAndSignWithQuestions'];
      case DisplayStatus.NOT_ATTEMPTED:
      default:
        return StatusLightsComponent.TOOLTIPS['notAttempted'];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const statusType = StatusLightsComponent.getContentType(this.statusType);
    const { status, lessonStatus, courseStatus } =
      StatusLightsComponent.getStatusValues(statusType, this.displayStatus);

    // set iconType
    this.iconType = StatusLightsComponent.getIconType(status);

    // set tooltip info
    if ( this.isReadAndSign() ) {
      this.iconTooltip = StatusLightsComponent.getTooltipReadAndSign(status);
    } else if ( courseStatus !== undefined ) {
      this.iconTooltip = StatusLightsComponent.getTooltipCourseStatus(courseStatus);
    } else if ( lessonStatus !== undefined ) {
      this.iconTooltip = StatusLightsComponent.getTooltipCMILessonStatus(lessonStatus);
    } else if ( this.targetType === Core.DistributableType.lms_offlineCnt ) {
      this.iconTooltip = StatusLightsComponent.getTooltipOfflineContent(status);
    } else if ( this.targetType === Core.DistributableType.lms_curriculum ) {
      this.iconTooltip = StatusLightsComponent.getTooltipCurriculum(status);
    } else {
      this.iconTooltip = StatusLightsComponent.getTooltipDisplayStatus(status);
    }
  }

  private isReadAndSign(): boolean {

    if ( this.targetType !== Core.DistributableType.lms_course ) {
      return false;
    }

    return CourseTypeHelper.isReadAndSign(Core.DistributableType.lms_course, this.courseType, this.hasConfirmation);
  }

}
