import { EventEmitter, Injectable } from '@angular/core';


const _filterReset$ = new EventEmitter<void>();

@Injectable({ providedIn: 'root' })
export class PivotFilterService {

  static readonly filterReset$ = _filterReset$.asObservable();

  static emitFilterChange() {
    _filterReset$.emit();
  }

}
