<rag-page-header backUrl="/admin/ext/oauth2">
  <div class="page-header-title">{{ getClientName(clientName) }}</div>
</rag-page-header>

<div
  *ngIf="isColumnContextLoaded; else dataLoading"
  class="content"
>
  <rag-sticky-scroll
    *ngIf="isDataLoaded; else dataLoading"
    [recalculate]="recalculateSticky$"
  >
    <table
      *ngIf="!isDataEmpty; else dataEmpty"
      [dataSource]="dataSource"
      mat-table
      matSort
    >
      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{ column.title | localeText }}
        </th>
        <td *matCellDef="let row" mat-cell>
          <rag-tables-column-render-default
            [column]="column"
            [row]="row"
          ></rag-tables-column-render-default>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
        <th *matHeaderCellDef mat-header-cell>
          <rag-table-header-filter-default
            [column]="column"
            (filterChange)="onFilterChange($event.filter, column)"
          ></rag-table-header-filter-default>
        </th>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
        </th>
        <td *matCellDef="let row" class="actions" mat-cell>
          <button
            [disabled]="inputDisabled"
            [matMenuTriggerData]="{row: row}"
            [matMenuTriggerFor]="matMenuRow"
            mat-icon-button
          >
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsFilter">
        <th *matHeaderCellDef class="actions filter" mat-header-cell></th>
      </ng-container>

      <tr *matHeaderRowDef="columns; sticky: true" class="title-header" mat-header-row></tr>
      <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter-header" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;" mat-row></tr>
    </table>

    <ng-container
      *ngIf="isFilteredDataEmpty"
    >
      <div class="no-data">
          <span
            i18n="@@admin_ext_oauth2_users_no_results"
          >There are no users matching your filter.</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>


<ng-template #dataLoading>
  <rag-loading-indicator></rag-loading-indicator>
</ng-template>

<ng-template #dataEmpty>
  <div class="no-data data-empty">
    <span
      i18n="@@admin_ext_oauth2_users_empty">There are no known users for this configuration.</span>
  </div>
</ng-template>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  [menuData$]="columnMenuData$"
  menuContext="ExtOauth2Users"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
></rag-table-column-menu>

<mat-menu #matMenuRow xPosition="before">
  <ng-template matMenuContent let-row="row">
    <button
      type="button"
      mat-menu-item
      (click)="onChangeUser(row)"
    >
      <mat-icon svgIcon="account-sync-outline"></mat-icon>
      <span i18n="@@admin_ext_oauth2_users_change">Change user</span>
    </button>

    <ng-container *ngIf="permissions?.journalRead">
      <mat-divider></mat-divider>

      <button
        type="button"
        mat-menu-item
        (click)="onJournal(row)"
      >
        <mat-icon svgIcon="history"></mat-icon>
        <span i18n="@@global_history">History</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
