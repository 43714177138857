<rag-post-to-blank
  #postToBlank
></rag-post-to-blank>

<div class="container">
  <ng-template #sameDayTemplate>
    <span>&nbsp;</span>
  </ng-template>

  <div class="offline-info" *ngIf="!canChooseEventSchedules && canActWithEventSchedules">
    <rag-content-offline-info
      [content]="offlineContent"
    ></rag-content-offline-info>
  </div>

  <div
    *ngIf="offlineEventsViewData.length > 0"
    class="label scheduled-events">
    <span i18n="@@scheduled_events">Scheduled events</span>:
  </div>

  <mat-accordion multi>

    <mat-expansion-panel
      class="expansion-panel"
      *ngFor="let viewData of offlineEventsViewData; let i = index"
      [disabled]="isReservation(viewData)"
      [expanded]="i === 0 && !isReservation(viewData)">

      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon class="outstanding-alert" svgIcon="alert" *ngIf="isReservation(viewData)"></mat-icon>

          <rag-content-offline-info-date
            [offlineEvent]="viewData.offlineEvent"
          >
          </rag-content-offline-info-date>
        </mat-panel-title>

        <mat-panel-description class="event-title">
          <span>{{viewData.offlineEvent.title | localeText}}</span>
        </mat-panel-description>

        <button
          mat-flat-button
          [disabled]="payNowDisabled$ | async"
          color="primary"
          (click)="onPayNow(viewData)"
          *ngIf="isReservation(viewData)">
          <span i18n='@@pay_now'>Pay now</span>
        </button>

      </mat-expansion-panel-header>

      <ng-container *ngIf="!isReservation(viewData)">

        <div class="top-line">

          <ng-template #noInvitation>
            <div *ngIf="viewData.offlineEvent.closedStatus" class="closed-status">
              <rag-offline-content-closed-status
                [closedStatus]="viewData.offlineEvent.closedStatus">
              </rag-offline-content-closed-status>
            </div>
          </ng-template>

        </div>

        <div *ngIf="viewData.offlineEvent.extLogin" class="ext-login-button">

          <div>

            <div
              *ngIf="getNotice(viewData) as notice"
              class="notice"
            >{{notice}}</div>

            <button
              mat-stroked-button
              [disabled]="isJoinButtonDisabled(viewData)"
              (click)="joinEvent(viewData)"
              class="ac-room"
              color="primary"
              mat-flat-button
            >
              <span>{{getButtonLabel(viewData)}}</span>
            </button>
          </div>
        </div>

        <rag-content-offline-invitation
          *ngIf="viewData.isEventInvitation; else noInvitation"
          [offlineContent]="offlineContent"
          [offlineEventAccount]="viewData.offlineEventAccount"
          [offlineEvent]="viewData.offlineEvent"
        ></rag-content-offline-invitation>

        <div class="event-schedule-info">

          <!-- Event image -->
          <img [src]="getEventPicture(viewData.offlineEvent.picture)">

          <!-- Event description info -->
          <div *ngIf="showEventDescription(viewData.offlineEvent.description | localeText, offlineContent | localeText)"
              class="row">
            <div class="title">
              <span i18n="@@global_description">Description</span>
            </div>
            <div [innerHTML]="viewData.offlineEvent.description | localeText | safe:'html'"></div>
          </div>

          <!-- Trainer info -->
          <div *ngIf="viewData.offlineEvent?.trainers.length > 0" class="row">
            <div class="title">
              <span i18n="@@global_trainers">Trainers</span>
            </div>

            <ng-container *ngFor="let trainer of viewData.offlineEvent.trainers">
              <rag-contact-card
                [name]="getTrainerName(trainer)"
                [moreInformation]="trainer.role"
                [description]="trainer.description"
                [pictureUUID]="trainer.pictureUUID"
              ></rag-contact-card>
            </ng-container>
          </div>

          <!-- Agenda info -->
          <div *ngIf="viewData.timeBlocksSorted?.length > 0" class="row">
            <div class="title">
              <span i18n="@@global_agenda">Agenda</span>
            </div>

            <!-- Time blocks -->
            <div class="time-blocks">

              <div class="blocks">
                <div *ngFor="let timeBlock of viewData.timeBlocksSorted" class="time-block">

                  <div class="time">
                    <div class="time-container">
                      <div class="from-to">
                        <div class="day">{{dateFormatted(timeBlock.startDate)}}</div>
                        <div class="hhmm">{{timeFormatted(timeBlock.startDate)}}</div>
                      </div>
                      <div class="from-to">
                        <div class="day">&nbsp;</div>
                        <div class="hhmm">-</div>
                      </div>
                      <div class="from-to">
                        <div class="day">
                          <span
                            *ngIf="!sameDay(timeBlock.startDate, timeBlock.endDate); else sameDayTemplate">{{dateFormatted(timeBlock.endDate)}}</span>
                        </div>
                        <div class="hhmm">{{timeFormatted(timeBlock.endDate)}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="description">
                    <div [innerHTML]="timeBlock.description | safe:'html'" class="content"></div>
                    <div *ngIf="shouldShowTimeBlockLink(viewData, timeBlock)">
                      <div class="button" *ngIf="isExtLoginButtonAvailable(viewData)">

                        <div
                          *ngIf="getConnectNotice(viewData, timeBlock) as notice"
                          class="notice"
                        >{{notice}}</div>

                        <!-- Button for adobe connect room -->
                        <button
                          mat-stroked-button
                          [disabled]="!isExtLoginButtonEnabled(viewData, timeBlock)"
                          (click)="joinTimeBlock(viewData, timeBlock)"
                          class="ac-room"
                          color="primary"
                          mat-flat-button>
                          <span i18n="@@global_ac_enter_room">Enter room</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="viewData.offlineEvent.location" class="row">

            <div class="title">
              <span i18n="@@global_location">Location</span>:
            </div>

            <rag-location
              *ngIf="viewData.offlineEvent.location"
              [location]="viewData.offlineEvent.location"
            ></rag-location>

            <div
              *ngIf="viewData.offlineEvent.location"
              class="map"
            >
              <rag-google-map
                [location]="viewData.offlineEvent.location"
              ></rag-google-map>
            </div>
          </div>

          <!--  Certificates  -->
          <div *ngIf="hasCertificates(viewData)" class="row">
            <div class="title">
              <span i18n="@@curriculum_certificate_templates">Certificate templates</span>
            </div>
            <button
              *ngIf="hasUserAttendedAndTemplateIsDeposited(viewData)"
              mat-flat-button
              color="primary"
              (click)="onGenerateRegistrationConfirmationPdf(viewData.offlineEvent.id, postToBlank); $event.stopPropagation()"
            >
              <mat-icon svgIcon="download"></mat-icon>
              <span i18n="@@global_participation_confirmation">Participation confirmation</span>
            </button>
          </div>

          <!-- External data -->
          <div *ngIf="hasAttachments(viewData.offlineEvent) && !accessPending(viewData)" class="row">
            <div class="title">
              <span i18n="@@global_assets">Assets</span>
            </div>
            <div class="attachments">
              <rag-file-render
                *ngFor="let fileInfo of viewData.offlineEvent.attachments"
                [file]="fileInfo"
                [readonly]="true">
              </rag-file-render>
            </div>
          </div>
        </div>

      </ng-container>

    </mat-expansion-panel>
  </mat-accordion>
