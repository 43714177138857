import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {
  ContentOfflineInvitationModule
} from 'src/app/component/content-offline-invitation/content-offline-invitation.module';
import {CoreModule} from '../../../core/core.module';
import {ContentOverviewRoutingModule} from './content-overview-routing.module';
import {ContentFilterModule} from '../../../component/content-filter/content-filter.module';
import {TableColumnMenuModule} from '../../../component/table/table-column-menu/table-column-menu.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MyContentCardComponent} from 'src/app/component/content/my-content-card/my-content-card.component';
import {SubTitleComponent} from 'src/app/component/sub-title/sub-title.component';
import {ClampComponent} from 'src/app/component/clamp/clamp.component';
import {
  LearnerOflEventsStateComponent
} from 'src/app/component/learner-ofl-events-state/learner-ofl-events-state.component';
import {LoadingIndicatorComponent} from 'src/app/component/loading-indicator/loading-indicator.component';
import {StickyScrollComponent} from 'src/app/component/sticky-scroll/sticky-scroll.component';
import {PageHeaderComponent} from 'src/app/component/page-header/page-header.component';
import {
  ImportantContentInfoModule
} from 'src/app/component/elearning/important-content-info/important-content-info.module';
import {StatusLightsModule} from 'src/app/component/elearning/status-lights/status-lights.module';
import {ContentActionModule} from 'src/app/component/elearning/content-action/content-action.module';
import {CourseCardModule} from '../../../component/card/course/course-card.module';
import { EventCardModule } from '../../../component/card/event/event-card.module';
import {CurriculumCardModule} from "../../../component/card/curriculum/curriculum-card.module";
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    ContentOverviewRoutingModule,
    ClampComponent,
    ContentOfflineInvitationModule,
    MyContentCardComponent,
    ImportantContentInfoModule,
    StatusLightsModule,
    FormsModule,
    PageHeaderComponent,
    MatButtonModule,
    ContentActionModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatRadioModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    LearnerOflEventsStateComponent,
    ContentFilterModule,
    LoadingIndicatorComponent,
    TableColumnMenuModule,
    StickyScrollComponent,
    MatPaginatorModule,
    SubTitleComponent,
    CourseCardModule,
    EventCardModule,
    CurriculumCardModule,
    MatExpansionModule
  ],
})

export class ContentOverviewModule {
}

