import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ContentOfflineModule } from '../../content/content-offline/content-offline.module';
import { ContentOfflineDialogComponent } from './content-offline-dialog.component';


@NgModule({
  declarations: [ ContentOfflineDialogComponent ],
  imports: [
    CommonModule,
    ContentOfflineModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ContentOfflineDialogModule {
}
