<div class="content">
  <ul *ngIf="hasResponse && reports.length > 0">
    <li *ngFor="let report of reports">
      <a [routerLink]="'/report/v2/saved/' + report.reportConfig['uuid']">{{report.reportConfig.title}}</a>
    </li>
  </ul>
  <div *ngIf="hasResponse && reports.length === 0" class="no_content" i18n="@@widget_report_favorite_no_content">
    You do not have any favorite reports set
  </div>
</div>
