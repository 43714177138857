import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentInfoButtonModule } from '../content-info-button/content-info-button.module';
import { StatusLightsModule } from '../status-lights/status-lights.module';
import { ContentPageTitleComponent } from './content-page-title.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ClampComponent } from '../../clamp/clamp.component';
import { PageHeaderComponent } from '../../page-header/page-header.component';


@NgModule({
  declarations: [
    ContentPageTitleComponent,
  ],
  exports: [
    ContentPageTitleComponent,
  ],
  imports: [
    ClampComponent,
    CommonModule,
    ContentInfoButtonModule,
    StatusLightsModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    PageHeaderComponent,
  ],
})
export class ContentPageTitleModule {
}
