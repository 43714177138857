import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'rag-retrieve-email',
  templateUrl: './retrieve-email.component.html',
  styleUrls: ['./retrieve-email.component.scss']
})
export class RetrieveEmailComponent implements OnInit {

  form: UntypedFormGroup;

  get result() {
    return this.form?.get('email').value;
  }

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [ Validators.required, this.equalityValidator ]],
      email2: ['', [ Validators.required, this.equalityValidator ]]
    });

    this.form.get('email');
  }

  equalityValidator = (control: AbstractControl): ValidationErrors | null => {
    if (this.form == null) {
      return null;
    }
    const emailComp = this.form.get('email');
    const email2Comp = this.form.get('email2');
    if (emailComp.value !== '' && email2Comp.value !== '') {
      if (emailComp.value !== email2Comp.value) {
        return {
          match: true
        };
      }
    }

    this.clearMatchError(control === email2Comp ? emailComp : email2Comp);

    return null;
  };

  hasError(componentId: string, errorCode: string) {
    if (this.form == null) {
      return false;
    }
    return this.form.get(componentId).hasError(errorCode);
  }

  private clearMatchError(control: AbstractControl) {
    const errors = control.errors;
    if (errors == null) {
      return;
    }
    delete errors.match;
    if (Object.keys(errors).length > 0) {
      control.setErrors(errors);
    } else {
      control.setErrors(null);
    }
  }

}
