import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { CachedSubject } from 'src/app/core/cached-subject';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageKey, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { ControllingSingleUserService } from '../../../../ctrl-single-user-util/ctrl-single-user.service';
import { CtrlSingleUserCertificates } from '../../ctrl-single-user-certificates.types';
import { SelectDateDialogComponent, SelectDateDialogParams } from '../select-date-dialog/select-date-dialog.component';

@Component({
  selector: 'rag-ctrl-single-user-curr-iteration',
  templateUrl: './curr-iteration.component.html',
  styleUrls: [ './curr-iteration.component.scss' ],
})
export class CurrIterationComponent
  implements OnDestroy {

  tmpIteration: ControllingSingleUserTypes.CertificateIteration;
  dataSource: MatTableDataSource<ControllingSingleUserTypes.CertificateIteration>;
  displayColumns = [ 'phase', 'processing_period' ];
  isPristine$: Observable<boolean>;
  saveButtonDisabled$: Observable<boolean>;
  shouldReload = false;

  private _isPristine$ = new CachedSubject<boolean>(true);
  private _datesValidityState = new CachedSubject<boolean>(true);
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<CurrIterationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserCertificates.EditIterationDialogParams,
    private ctrlSingleUserService: ControllingSingleUserService,
    private infoService: InfoService,
    ) {
      this.isPristine$ = this._isPristine$.asObservable();
      this.saveButtonDisabled$ = combineLatest([
        this.isPristine$,
        this._datesValidityState.asObservable(),
      ]).pipe(map(([ isPristine, datesValidityState ]) => isPristine || !datesValidityState));
      this.dataSource = new MatTableDataSource();
      if ( data.steering.current != null && this.isValid ) {
        this.tmpIteration = { ...this.data.steering.current };
        this.dataSource.data = [ this.tmpIteration ];
      }
  }

  get isValid() {
    return this.data.steering.status === 'valid';
  }

  get phaseForStatus() {
    switch ( this.data.steering.status ) {
      case 'valid':
        return $localize`:@@iteration_status_valid:Run`;
      case 'ended':
        return $localize`:@@iteration_status_ended:Complete`;
      case 'not_attempted':
        return $localize`:@@iteration_status_not_attempted:Not attempted`;
      case 'annuled':
        return $localize`:@@iteration_status_annuled:Annuled`;
      default:
        return status;
    }
  }

  get certifyButtonDisabled() {
    return this.data.steering.status !== 'valid';
  }

  get finishButtonDisabled() {
    return this.data.steering.status !== 'valid';
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  dateFor(timestamp: number) {
    if ( timestamp != null && typeof timestamp === 'number' ) {
      return moment(timestamp);
    }
    return null;
  }

  onAddValidSince(event: moment.Moment) {
    if ( event.isValid() ) {
      setTimeout(() => {
        this.tmpIteration.start = event.unix() * 1000;
        this._isPristine$.next(false);
      });
    }
  }

  onValidityChanges($event: boolean) {
    this._datesValidityState.next($event);
  }

  onAddValidUntil(event: moment.Moment) {
    if ( moment.isMoment(event) ) {
      if ( event.isValid() ) {
        setTimeout(() => {
          this.tmpIteration.end = event.unix() * 1000;
          this._isPristine$.next(false);
        });
      }
    } else {
      setTimeout(() => {
        this.tmpIteration.end = null;
        this._isPristine$.next(false);
      });
    }
  }

  onStartNewIteration() {
    this.ctrlSingleUserService
      .createNewPlannedIteration(this.data.curriculumId, this.data.userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(newIteration => {
        this.shouldReload = true;
        this.setIteration(newIteration);
        this.data.steering.status = newIteration.status;
        this.infoService.showSnackbar(null, InfoType.Success, {
        message: $localize`:@@ctrl_single_user_iteration_created:New iteration has been created successfully`,
      });
      if ( this.data.steering.status === 'ended' ) {
        this.dialogRef.close(true);
      }
      this._isPristine$.next(true);
    });
  }

  onSaveChanges() {
    this.ctrlSingleUserService
      .savePlannedIteration(this.data.curriculumId, this.data.userId, this.tmpIteration)
      .pipe(takeUntil(this.destroy$))
      .subscribe(savedIteration => {
        this.setIteration(savedIteration);
        this.infoService.showSnackbar(null, InfoType.Success, {
          message: $localize`:@@ctrl_single_user_iteration_saved:The iteration has been saved successfully`,
        });
        this.shouldReload = true;
        this._isPristine$.next(true);
    });
  }

  onResetIteration() {
    this.infoService
      .showConfirm(MessageKey.CNTRL.USER.CERT.ITERATION.RESET, YesNoButtons)
      .pipe(takeUntil(this.destroy$))
      .subscribe(button => {
        if ( button !== YesButton ) {
          return;
        }
        this.ctrlSingleUserService
          .resetIteration(this.data.userId, this.data.curriculumId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.infoService.showSnackbar(null, InfoType.Success, {
              message: $localize`:@@ctrl_single_user_iteration_reset_success:The iteration has been reset successfully`,
            });
            this._isPristine$.next(true);
            this.shouldReload = true;
        });
    });
  }

  onCertify() {
    const maxDate = moment();
    maxDate.set('hours', 23);
    maxDate.set('minutes', 59);
    maxDate.set('seconds', 59);
    maxDate.set('milliseconds', 999);

    this.infoService
      .showDialog<SelectDateDialogComponent, SelectDateDialogParams, moment.Moment>(SelectDateDialogComponent, {
        maxDate,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(certifyDate => {
        if ( certifyDate != null ) {
          this.infoService
            .showConfirm(MessageKey.CNTRL.USER.CERT.ITERATION.CERTIFY, YesNoButtons)
            .pipe(takeUntil(this.destroy$))
            .subscribe(button => {
              if ( button !== YesButton ) {
                return;
              }
              // ignore hours, minutes ans seconds, just take selected date at 00:00
              certifyDate.set('minutes', 0);
              certifyDate.set('hours', 0);
              certifyDate.set('seconds', 0);
              certifyDate.set('milliseconds', 0);
              this.ctrlSingleUserService
                .certifyIteration(this.data.curriculumId, this.data.userId, certifyDate.unix() * 1000)
                .pipe(takeUntil(this.destroy$))
                .subscribe(certifiedIteration => {
                  this.setIteration(certifiedIteration);
                  this.infoService.showSnackbar(null, InfoType.Success, {
                    message: $localize`:@@iteration_has_been_certified:The iteration has been finished and set as certified successfully`,
                  });
                  this._isPristine$.next(true);
                  this.data.steering.status = 'ended';
                  this.shouldReload = true;
              });
          });
        }
      });
    }

    onEndIteration() {
      this.infoService.showConfirm(MessageKey.CNTRL.USER.CERT.ITERATION.END, YesNoButtons)
        .pipe(takeWhile(button => button === YesButton))
        .pipe(take(1))
        .pipe(switchMap(() => this.ctrlSingleUserService
          .finishIteration(this.data.curriculumId, this.data.userId)))
        .pipe(tap(current => {
          const iterationDirectlyStarted = this.data.steering.current.currentIteration !== current.currentIteration
            && current.status === 'valid';
          if ( iterationDirectlyStarted ) {
            this.infoService.showSnackbar(null, InfoType.Success, {
              message: $localize`:@@iteration_has_been_finished_and_new_iteration_directly_started:
                The iteration has been reset successfully.<br>
                A new iteration has been started directly
              `,
              durationInSeconds: 5
            });
          } else {
            this.infoService.showSnackbar(null, InfoType.Success, {
              message: $localize`:@@iteration_has_been_finished:The iteration has been finished successfully`,
            });
          }
          this._isPristine$.next(true);
          this.tmpIteration = null;
          // TOOD: fix this, reloading the new curriculum's steering is not ready yet
          this.data.steering.status = current.status;
          this.data.steering.current = current;
          this.shouldReload = true;
        }))
        .subscribe();
  }

  onClose() {
    if ( this._isPristine$.value ) {
      this.dialogRef.close(this.shouldReload);
      return;
    }
    this.infoService
      .showConfirm(MessageKey.GENERAL_UNSAVED_CHANGES, YesNoButtons)
      .subscribe(button => {
        if ( button === YesButton ) {
          this.dialogRef.close(this.shouldReload);
        }
      });
  }

  private setIteration(iteration: ControllingSingleUserTypes.CertificateIteration) {
    this.data.steering.current = iteration;
    this.tmpIteration = { ...iteration };
    this.dataSource.data = [ this.tmpIteration ];
  }

}
