import { Component, OnInit } from '@angular/core';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { LicenseGroupToTgTypes } from '../../../../core/development-goals/license-group-to-tg.types';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { CachedSubject } from '../../../../core/cached-subject';
import { LicenseGroupToTgColumns } from './license-group-to-tg.columns';
import { Observable } from 'rxjs';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerTypes } from '../../../../component/table/table-controller/table-controller.types';
import { ColumnSettings } from '../../../../core/report/report.types';
import { AnyObject } from '../../../../core/core.types';
import { InputTypes } from '../../../../component/input/input.types';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { LicenseGroupToTgService } from './license-group-to-tg.service';
import { InfoService } from '../../../../core/info/info.service';
import { YesButton, YesNoButtons } from '../../../../core/info/info.types';
import { UserNameHelper } from '../../../../core/user-name.helper';
import { LanguageHelper } from '../../../../core/language.helper';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'rag-license-group-to-tg',
  templateUrl: './license-group-to-tg.component.html',
  styleUrls: [ './license-group-to-tg.component.scss' ],
})
export class LicenseGroupToTgComponent
  extends TableControllerComponent<LicenseGroupToTgTypes.TableRow> implements OnInit {

  columnStatistics: AnyObject<LicenseGroupToTgTypes.DistAssignmentTotals> = {};
  readonly curriculumColumnMenuData: Observable<LicenseGroupToTgColumns.CurriculumColumnMenuData>;
  curriculumColumns: string[];
  curriculumMenuItems: TableControllerTypes.ColumnMenuItem[];
  curriculumTitleColumns: string[];
  hasChanges = false;
  isIE = false;
  readonly userColumnMenuData: Observable<LicenseGroupToTgColumns.UserColumnMenuData>;
  userMenuItems: TableControllerTypes.ColumnMenuItem[];
  private _curriculumColumnMenuData = new CachedSubject<LicenseGroupToTgColumns.CurriculumColumnMenuData>(null);
  private _infoDialogOpen = false;
  private _userColumnMenuData = new CachedSubject<LicenseGroupToTgColumns.UserColumnMenuData>(null);
  private userColumns: string[];
  private initialized = false;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private dirtyCheckService: DirtyCheckService,
    private infoService: InfoService,
    private licenseGroupToTgService: LicenseGroupToTgService,
    private route: ActivatedRoute,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);

    this.curriculumColumnMenuData = this._curriculumColumnMenuData.asObservable();
    this.userColumnMenuData = this._userColumnMenuData.asObservable();
    this.isIE = this.deviceDetector.browser === 'IE';
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => data.assignments))
      .pipe(tap(this.handleRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  asAssignment(row: LicenseGroupToTgTypes.TableRow, column: TableControllerTypes.ColumnMenuItem):
    LicenseGroupToTgTypes.DistAssignmentEntry {
    return LicenseGroupToTgTypes.Util.getAssignmentEntry(row, column.id);
  }

  getModifiedColumns(): string[] {
    if ( !this.hasChanges ) {
      return;
    }
    const enabledColumns = this.curriculumColumns;
    return Object.entries(this.columnStatistics)
      .filter(([ key, statistic ]) => statistic.changed && enabledColumns.includes(key))
      .map(([ key ]) => key);
  }

  onChange(): void {
    const changedRows = this.getChangedRows();
    this.hasChanges = changedRows.length > 0;
    LicenseGroupToTgTypes.Util
      .calculateAssignmentCounts(this.dataSource.data, this.curriculumMenuItems, this.columnStatistics);
    this.checkChanges();
  }

  onChangeAll(statistics: LicenseGroupToTgTypes.DistAssignmentTotals, columnId: string,
    isTypeMandatory: boolean): void {
    LicenseGroupToTgTypes.Util.updateTotalAssignment(this.dataSource.filteredData, statistics, columnId, isTypeMandatory);
    this.onChange();
  }

  onReset(): void {
    this.infoService.showMessage($localize`:@@assign_content_confirm_reset:Would you like to discard all changes that you have made?`, {
      buttons: YesNoButtons,
      title: $localize`:@@general_dialog_confirm_title:Confirm`,
    })
      .pipe(take(1))
      .pipe(takeWhile(button => button === YesButton))
      .pipe(tap(() => {
        this.getChangedRows()
          .flatMap(row => Object.values(row).filter(column => column.$isAssignmentEntry))
          .forEach((assignment: LicenseGroupToTgTypes.DistAssignmentEntry) => {
            InputTypes.Util.resetValue(assignment.mandatory);
            InputTypes.Util.resetValue(assignment.voluntary);
          });
        Object.values(this.columnStatistics)
          .forEach(column => {
            InputTypes.Util.resetValue(column.mandatory.input);
            InputTypes.Util.resetValue(column.voluntary.input);
          });
        this.onChange();
      }))
      .subscribe();
  }

  onSave(): void {
    const tableRows = this.getChangedRows();
    this.inputDisabled = true;
    this.licenseGroupToTgService.updateAssignmentData(tableRows)
      .pipe(take(1))
      .pipe(switchMap(() => this.licenseGroupToTgService.getAssignmentData()))
      .pipe(take(1))
      .pipe(tap(this.handleRouteData))
      .pipe(finalize(this.checkChanges))
      .subscribe();
  }

  onShowInfo(row: LicenseGroupToTgTypes.TableRow, column: TableControllerTypes.ColumnMenuItem,
    assignment: LicenseGroupToTgTypes.DistAssignmentEntry, inputDisabled: boolean): void {
    if ( this._infoDialogOpen || (inputDisabled !== true) ) {
      // ignore enabled checkboxes and double clicks
      return;
    }

    const userName = UserNameHelper.getFullName(row);
    const curriculumTitle = LanguageHelper.objectToText(column.title);
    this._infoDialogOpen = true;
    this.licenseGroupToTgService.showLicenceInfo(assignment.userId, assignment.curriculumId, userName, curriculumTitle)
      .pipe(finalize(() => this._infoDialogOpen = false))
      .pipe(take(1))
      .subscribe();
  }

  setCurriculumColumns(columns: string[]): void {
    this.curriculumColumns = columns;
    this.checkColumns();
  }

  setUserColumns(columns: string[]): void {
    this.userColumns = columns;
    this.checkColumns();
  }

  private checkChanges = (): void => {
    this.inputDisabled = false;

    // check if any column has changes
    this.hasChanges = Object.values(this.columnStatistics)
      .reduce((pV, entry) => entry.changed || pV, null) === true;

    this.dirtyCheckService.submitNextState('LicenseGroupToTgComponent', this.hasChanges);
  };

  private checkColumns(): void {
    if ( (this.curriculumColumns == null) || (this.userColumns == null) ) {
      return;
    }
    const columns = [
      ...this.userColumns,
      ...this.curriculumColumns,
    ];
    this.curriculumTitleColumns = columns.map(column => `${column}CurriculumTitle`);
    this.setColumns(columns);
  }

  private getChangedRows(): LicenseGroupToTgTypes.TableRow[] {
    return this.dataSource.data
      .filter(row => Object.values(row)
          .filter(column => {
            if ( column.$isAssignmentEntry !== true ) {
              return false;
            }
            const assignment = column;
            return assignment.mandatory.changed || assignment.voluntary.changed;
          })
          .length > 0);
  }

  private handleRouteData = (routeData: LicenseGroupToTgTypes.DistResponse) => {
    const curriculumColumnIds = Object.values(routeData.curriculumColumnSettings)
      .map(column => String(column.targetId));
    const tableRows = LicenseGroupToTgTypes.Util
      .toTableRows(routeData.users, routeData.assignments, curriculumColumnIds);
    this.setCurriculumMenuData(routeData.curriculumColumnSettings);
    if (!this.initialized) {
      this.setUserMenuData(routeData.userColumnSettings);
      this.initialized = true;
    }
    this.setTableData(tableRows);
    this.columnStatistics = LicenseGroupToTgTypes.Util
      .calculateAssignmentCounts(tableRows, this.curriculumMenuItems);
  };

  private setCurriculumMenuData(columns: LicenseGroupToTgColumns.DistColumnSettings[]): void {
    const tableColumns = LicenseGroupToTgColumns.Util.toColumnSettings(columns);
    const menuData: LicenseGroupToTgColumns.CurriculumColumnMenuData = TableColumnMenuService.createFromDefaults(
      LicenseGroupToTgColumns.DEFAULT_CURRICULUM_MENU_COLUMNS, tableColumns, true);
    this.curriculumMenuItems = Object.values(menuData.menuItems)
      .filter(menuItem => !menuItem.hidden);
    this._curriculumColumnMenuData.next(menuData);
  }

  private setUserMenuData(columns: ColumnSettings[]): void {
    const menuData: LicenseGroupToTgColumns.UserColumnMenuData =
      TableColumnMenuService.createFromDefaults(LicenseGroupToTgColumns.DEFAULT_USER_MENU_COLUMNS, columns);
    const languageOptions = TableColumnMenuService.getLanguageDropdownOptions();
    TableColumnMenuService.addDynamicDropDownOptions(menuData, 'language', languageOptions);
    this.userMenuItems = Object.values(menuData.menuItems)
      .filter(menuItem => !menuItem.hidden);
    this._userColumnMenuData.next(menuData);

    // used for filtering
    this.columnMenuData = menuData;
  }

}
