import { Component } from '@angular/core';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent, WidgetsUUID } from '../widgets.types';

@Component({
  selector: 'rag-html-widget',
  templateUrl: './html-widget.component.html',
  styleUrls: [ './html-widget.component.scss' ],
})
export class HtmlWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent {

  static WidgetUUID = WidgetsUUID.HtmlWidgetUUID;

  constructor() {
    super();

    this.setTitle($localize`:@@html_widget_title:HTML Widget`);
  }

}
