import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../core/content/content.service';
import { map, take } from 'rxjs/operators';
import { Core, ImageableContentReference } from '../../../../core/core.types';
import { ViewHelper } from '../../../../core/view-helper';
import { Injectable } from '@angular/core';
import DistributableType = Core.DistributableType;


@Injectable({ providedIn: 'root' })
export class ContentOfflineGuard
  implements CanActivate {

  constructor(
    private contentService: ContentService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> {

    const curriculumId = parseInt(route.params['id'], 10);
    const curriculumItemId = parseInt(route.params['itemId'], 10);
    if ( (curriculumId > 0) && (curriculumItemId > 0) ) {
      // this is validated anyway -> pass assignment check
      return true;
    }

    const contentId = parseInt(route.params['contentId'], 10);
    if ( !(contentId > 0) ) {
      // no content id given
      return this.router.parseUrl('/');
    }

    return this.contentService.fetchAccountData(true)
      .pipe(take(1))
      .pipe(map(account => this.findOfflineContent(account, contentId, route.queryParams)));
  }

  private findOfflineContent(
    account: ImageableContentReference[],
    contentId: number,
    queryParams: Params,
  ): boolean | UrlTree {

    const directContent = ContentService
      .getContentById(account, contentId, false, [ DistributableType.lms_offlineCnt ]);
    if ( directContent != null ) {
      // content is directly assigned -> allow access
      return true;
    }

    const childContent = ContentService
      .getContentById(account, contentId, true, [ DistributableType.lms_offlineCnt ]);
    if ( childContent == null ) {
      // did not find any valid assignment for content
      return this.router.parseUrl('/');
    }

    const viewData = ViewHelper.getViewData(childContent);
    if ( viewData.parent == null ) {
      // content without parent? should not happen, but allow access anyway
      return true;
    }

    const curriculumId = viewData.parent.id;
    const curriculumItemId = childContent.curItemId;
    console?.warn(`redirecting to correct parent!
     '/offline/${contentId}' -> '/content/${curriculumId}/offline/${curriculumItemId}'`);
    return this.router
      .createUrlTree([ 'content', curriculumId, 'offline', curriculumItemId ], { queryParams });
  }

}
