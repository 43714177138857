import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Core, ImageableContentReference } from 'src/app/core/core.types';
import { ApiUrls } from '../../../core/api.urls';
import { ContentService } from '../../../core/content/content.service';
import { CourseInfo } from '../../../core/content/content.types';
import { InfoService } from '../../../core/info/info.service';
import { InfoType, MessageKey, YesButton, YesNoButtons } from '../../../core/info/info.types';
import { ModalDialog } from '../../../core/modal-dialog';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { PrincipalService } from '../../../core/principal/principal.service';
import { PrincipalData } from '../../../core/principal/principal.types';
import { ViewHelper } from '../../../core/view-helper';
import { ScoWarningComponent } from './sco-warning/sco-warning.component';


interface ContentWithParent {
  content: ImageableContentReference;
  parent: ImageableContentReference;
}

@Injectable({
  providedIn: 'root',
})
export class ScoResolverService
  implements Resolve<ImageableContentReference> {

  constructor(
    private contentService: ContentService,
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private navigationService: NavigationService,
    private principalService: PrincipalService,
  ) {
  }

  private static isTrue(value: string | boolean): boolean {
    return value === true || value === 'true';
  }

  // main resolve function which initiates certain checks
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ImageableContentReference> {
    return this.resolveRunningSessions(route)
      .pipe(switchMap(() => this.resolveSCO(route)))
      .pipe(switchMap(content => this.resolveCanExecute(content)))
      .pipe(take(1));
  }

  resolveCanExecute(content: ImageableContentReference): Observable<ImageableContentReference> {
    const viewData = ViewHelper.getViewData(content);
    const sco = viewData && viewData.sco;
    if ( !(sco && sco.id && content.id && (content.objType === Core.DistributableType.lms_course)) ) {
      this.errorNotFound();
      return;
    }

    const courseId = content.id;
    const url = ApiUrls.getKey('ExecutableState')
      // todo check if query by courseId should really return the scoId (multi-sco?)
      .replace(/{courseId}/gi, '' + courseId);
    return this.http.get<any>(url)
      .pipe(map(response => {
        const data = response && response.data || [];
        const info = data.length ? data[0] : {};
        if ( info.id === sco.id && ScoResolverService.isTrue(info.isExecutable)) {
          return content;
        } else if ( info.id === sco.id && ScoResolverService.isTrue(info.isPreviewable) ) {
          // in case when sco is in two curr. with different executable state
          // (one previewable / "without tracking", one locked by prereqisites)
          if (sco.fullhref?.indexOf('previewMode=true') === -1) {
            sco.fullhref += '&previewMode=true';
          }
          return content;
        }
        this.errorCannotExecute(content);
      }));

  }

  resolveContentInfo = (content: ImageableContentReference): Observable<ImageableContentReference> => {
    const $view = ViewHelper.getViewData(content);
    let courseId;
    if ( content && (content.objType === Core.DistributableType.lms_course) ) {
      courseId = content.id;
    } else if ( $view && $view.parent && ($view.parent.objType === Core.DistributableType.lms_course) ) {
      courseId = $view.parent.id;
    }

    return this.contentService.fetchCourseInfo(courseId)
      .pipe(take(1))
      .pipe(catchError(() => of({})))
      .pipe(switchMap((info: CourseInfo) => {
        $view.contentInfo = info;
        if ( info && info.hasWarning ) {
          return (this.dialog.openModal(ScoWarningComponent, {
            data: {
              title: info.warningTitle,
              content: info.warningText,
            },
          }).afterClosed())
            .pipe(switchMap((result) => {
              if ( result ) {
                return of(content);
              }
              return throwError({ message: 'user declined', ngNavigationCancelingError: true });
            }));
        }
        return of(content);
      }));
  };

  // close all running SCOs
  resolveFinishUnfinished(principal: PrincipalData): Observable<boolean> {
    return this.contentService.finishUnfinished(principal.runningscosessions)
      .pipe(take(1))
      .pipe(tap(() => this.principalService.fetchUserData(false)))
      .pipe(map(() => true));
  }

  // check principal and initiate closing running SCOs
  resolveRunningSessions(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.principalService.fetchUserData(false)
      .pipe(take(1))
      .pipe(switchMap(principal => {
        if ( !principal ) {
          this.errorNotLoggedIn();
        }

        if ( (principal.runningscosessions.length > 0) ) {
          return this.infoService.showConfirm(MessageKey.SCO.SCO_SESSION_ALREADY_RUNNING, YesNoButtons)
            .pipe(switchMap(button => {
              if ( button === YesButton ) {
                return this.resolveFinishUnfinished(principal);
              } else {
                return throwError(MessageKey.SCO.SCO_SESSION_ALREADY_RUNNING);
              }
            }));
        }

        // after that try to get content with id
        return of(true);
      }));
  }

  // check if content with id is available and return it if possible
  resolveSCO(route: ActivatedRouteSnapshot): Observable<ImageableContentReference> {
    const parentId = parseInt(route.params['id'], 10);
    const contentId = parseInt(route.params['itemId'], 10);

    return this.contentService.fetchAccountData()
      .pipe(take(1))
      .pipe(map(account => this.getContentInParentById(account, parentId, contentId)))
      .pipe(map(result => {
        let content = result.content;
        if ( !content ) {
          return this.errorNotFound();
        }
        if ( content && content.fullhref ) {
          const viewData = ViewHelper.getViewData(content);
          if ( viewData && viewData.parentCourse ) {
            // todo allow direct references to sco for multi-sco courses
            content = viewData.parentCourse;
          }
        }

        ViewHelper.getViewData(content).parent = result.parent;

        const sco = this.contentService.getFirstSco(content);
        if ( !sco ) {
          return this.errorCannotExecute(content);
        }
        return content;
      }))
      .pipe(switchMap(this.resolveContentInfo));
  }

  private errorCannotExecute(con: ImageableContentReference) {
    this.infoService.showSnackbar(MessageKey.SCO.SELECTED_SCO_CANNOT_BE_EXECUTED, InfoType.Error);
    const url = ContentService.getContentHref(con, false);
    this.navigationService.navigate([ url || '/content-overview' ]);
    throw new Error(MessageKey.SCO.SELECTED_SCO_CANNOT_BE_EXECUTED);
  }

  private errorNotFound() {
    this.infoService.showSnackbar(MessageKey.SCO.SELECTED_SCO_CANNOT_BE_FOUND, InfoType.Error);
    this.navigationService.navigateByUrl('/404');
    throw new Error(MessageKey.SCO.SELECTED_SCO_CANNOT_BE_FOUND);
  }

  private errorNotLoggedIn() {
    this.infoService.showSnackbar(MessageKey.SCO.SCO_PRINCIPAL_CANNOT_BE_FOUND, InfoType.Error);
    this.navigationService.navigateByUrl('/login');
    throw new Error(MessageKey.SCO.SCO_PRINCIPAL_CANNOT_BE_FOUND);
  }

  private getContentInParentById(account: ImageableContentReference[], parentId: number, contentId: number, deep = true): ContentWithParent {
    let parent: ImageableContentReference;
    if ( parentId > 0 ) {
      parent = ContentService.getContentById(account, parentId, false,
        [Core.DistributableType.lms_curriculum, Core.DistributableType.lms_course]);
    }

    let content: ImageableContentReference;
    if ( contentId > 0 ) {
      if ( parent ) {
        // resolve items recursively
        this.contentService.getDuration(parent);
        content = ContentService
          .getContentById(parent.items, contentId, deep, [Core.DistributableType.lms_course, null]);
      }
      if ( content == null ) {
        // if no parent or item not found in parent
        content = ContentService
          .getContentById(account, contentId, deep, [Core.DistributableType.lms_course, null]);
      }
    }

    if ( parent && content ) {
      this.contentService.searchFirstExecutableItem(account, parent, content);

      // todo validate parentId!
      // parent may be rewritten after fetching (cur in cur)
      const viewData = ViewHelper.getViewData(content);
      if ( !content.objType ) {
        // store actual parent reference for ScoResolver
        if ( parent.objType === Core.DistributableType.lms_course ) {
          viewData.parentCourse = parent;
        } else if ( viewData.parent && (viewData.parent.objType === Core.DistributableType.lms_course) ) {
          viewData.parentCourse = viewData.parent;
        }
      }
      viewData.parent = parent;
      delete viewData.contentHref;
    }

    return { content, parent };
  }

}
