import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginFormData } from '../login-form/login-form.types';


@Component({
  selector: 'rag-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: [ './login-dialog.component.scss' ],
})
export class LoginDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LoginFormData,
  ) {
  }

}
