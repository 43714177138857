import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { ReceiverFieldComponent } from "./receiver-field/receiver-field.component";
import { SelectUserGroupsComponent } from "./select-user-groups.component";

@NgModule({
  declarations: [
    SelectUserGroupsComponent,
    ReceiverFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    SelectUserGroupsComponent
  ],
})
export class SelectUserGroupsModule {
}