import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CatalogService } from '../catalog/catalog.service';
import { InfoService } from '../info/info.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseResultsResolverService implements CanActivate {

  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private infoService: InfoService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.catalogService.handlePurchaseResults(of(route.queryParams), false)
      .pipe(catchError(_ => {
        this.infoService.showAlert($localize`:@@purchase_error:An error occurred while processing your request.`);
        return EMPTY;
      }))
      .pipe(map(purchaseResult => {
        if (purchaseResult && purchaseResult.bookingUUID) {
          const selections = purchaseResult.extras['selection'];
          if (selections instanceof Array && selections.length > 0) {
            return this.router.parseUrl(`/bookings/${purchaseResult.bookingUUID}`);
          }
        }
        return true;
    })).pipe(take(1));
  }
}
