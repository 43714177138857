import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablesColumnRenderDefaultComponent } from './tables-column-render-default.component';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusLightsModule } from '../../elearning/status-lights/status-lights.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [ TablesColumnRenderDefaultComponent ],
  exports: [ TablesColumnRenderDefaultComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    PipesModule,
    StatusLightsModule,
    MatChipsModule,
    MatCardModule,
    MatIconModule,
  ],
})
export class TablesColumnRenderDefaultModule {
}
