import { filterNumberV2, filterTextV2 } from '../../column-settings/column-filter.types';
import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../component/table/table-controller/table-controller.types';
import ColumnMenuData = TableControllerTypes.ColumnMenuData;

export namespace LinkedUserTypes {
  export interface LinkedUser {
    accountName: string;
    firstname: string;
    lastname: string;
    login: string;
    userId: number;
  }

  export interface LinkedUserDropdownEntry {
    accountName: string;
    login: string;
    text: string;
    user: LinkedUser;
  }

  export interface AddLinkedUser {
    login: string;
    password: string;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [],
    endWith: [ 'actions' ],
    menuItems: {
      accountName: {
        orderIndex: 0,
        id: 'accountName',
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'accountName', action: null, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@linked_users_header_account_name:Account name`,
      },
      firstname: {
        orderIndex: 1,
        id: 'firstname',
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'firstname', action: null, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@linked_users_header_firstname:Firstname`,
      },
      lastname: {
        orderIndex: 2,
        id: 'lastname',
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'lastname', action: null, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@linked_users_header_lastname:Lastname`,
      },
      login: {
        orderIndex: 3,
        id: 'login',
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'login', action: null, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@linked_users_header_login:Login`,
      },
      userId: {
        orderIndex: 4,
        id: 'userId',
        selected: true,
        options: {
          dataType: TableColumnDataType.number,
          filter: { identifier: 'userId', action: null, value: null },
          filterMethod: filterNumberV2,
        },
        title: $localize`:@@linked_users_header_userId:User ID`,
      },
      actions: {
        orderIndex: 5,
        hidden: true,
        id: 'actions',
        selected: true,
      },
    },
  };
}
