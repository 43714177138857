import * as moment from 'moment';
import { TableControllerTypes } from '../../../../component/table/table-controller/table-controller.types';

export namespace CtrlSingleUserScormLogTypes {

  export interface DialogData {
    scormLogEntries: ScormLogEntry[];
    userLastname: string;
    scoId: number;
  }

  export interface ScormLogResponse {
    scormLogEntries: ScormLogEntry[];
  }

  export interface ScormLogEntry {
    $date?: moment.Moment;
    $timeMilli?: string;
    action: string;
    field: string;
    scoId: number;
    sessionToken: string;
    timestamp: number;
    title?: string;
    userId: number;
    value: string;
  }

  export const DEFAULT_MENU_COLUMNS: TableControllerTypes.ColumnMenuData = {
    startWith: [],
    endWith: [ 'actions' ],
    menuItems: {
      $date: TableControllerTypes.Util
        .dateColumnFactory('$date', $localize`:@@global_date:Date`, null, true),
      $timeMilli: TableControllerTypes.Util
        .textColumnFactory('$timeMilli', $localize`:@@global_timeMilli:Time`, null, true),
      field: TableControllerTypes.Util
        .textColumnFactory('field', $localize`:@@global_field:Field`, null, true),
      action: TableControllerTypes.Util
        .textColumnFactory('action', $localize`:@@global_action:Action`, null, true),
      value: TableControllerTypes.Util
        .textColumnFactory('value', $localize`:@@global_value:Value`, null, true),
      sessionToken: TableControllerTypes.Util
        .textColumnFactory('sessionToken', $localize`:@@ctrl_single_user_scorm_log_session_token:
          Session Token`),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };
}
