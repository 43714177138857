<rag-content-card
  *ngIf="hasContent$ | async"
  [accessoryTemplate]="cardAccessoryView"
  [subtitleAccessoryTemplate]="templateForContent(content.objType, content.courseType)"
  [progressTemplate]="progressTemplateForContent(content.objType)"
  [content]="content"
  [lang]="lang"
  [readonly]="readonly"
  [contentLink]="contentLink"
>

  <div class="sub-container" [ngSwitch]="content.objType">

    <!-- lms_curriculum -->
    <ng-container *ngSwitchCase="'lms_curriculum'">
      <rag-my-content-card-curriculum
        *ngIf="content.$view.executableItem"
        [content]="content">
      </rag-my-content-card-curriculum>
    </ng-container>

    <!-- default render -->
    <ng-container *ngSwitchDefault>
      <rag-clamp
        *ngIf="hasDescription(content.description); else noDescrTempl"
        class="default-render static-text"
        [tooltipEnabled]="false"
        [clamp]="5"
        [input]="content.description"></rag-clamp>
    </ng-container>
  </div>
</rag-content-card>

<ng-template #eventDateTemplate let-content="content">
  <div class="time-duration" *ngIf="content.eventDate">
    <mat-icon *ngIf="content.eventDate" svgIcon="timer-outline"></mat-icon>
    &nbsp;
    <span class="date-time">{{content.eventDate | formatDate:'date-time'}}</span>
    &nbsp;
    <div
      *ngIf="content.duration > 0"
      class="duration">
      ( {{content.duration | formatDuration}} )
    </div>
  </div>
</ng-template>

<ng-template #recordingDateTemplate let-content="content">
  <div class="time-duration" *ngIf="content.eventDate">

    <mat-icon svgIcon="calendar-outline"></mat-icon>
    &nbsp;
    <span class="date-time">{{content.eventDate | formatDate:'date'}}</span>
    &nbsp;
    <div
      *ngIf="content.duration > 0"
      class="duration"
    >
      ( {{content.duration | formatDuration}} )
    </div>
  </div>
</ng-template>
<ng-template #curriculumExecDate let-content="content">
  <div class="time-duration" *ngIf="getImportantContentInfo(content) as info">
    <mat-icon *ngIf="info.isWarning" color="warn" svgIcon="alert"></mat-icon>
    {{ info.label }} {{ info.date | formatDate: 'date' }}
  </div>
</ng-template>

<ng-template #cardAccessoryView>

  <a
    [disabled]="content.locked"
    [routerLink]="contentLink"
    mat-mini-fab
    class="card-accessory-button"
  >
    <mat-icon svgIcon="information-outline"></mat-icon>
  </a>

  <rag-status-lights
    [displayStatus]="content.displaystatus"
    statusType="DisplayStatus"
    [targetType]="content.objType"
    [courseType]="content.courseType"
    [hasConfirmation]="content.hasConfirmation"
  ></rag-status-lights>
</ng-template>

<ng-template #noDescrTempl>
  <div class="no-description">
    <span i18n="@@global_no_description">no description available</span>
  </div>
</ng-template>

<ng-template #progressTemplate let-content="content">
  <div
    [matTooltip]="progress"
    class="progress-bar"
    [ngStyle]="{width: progress}"></div>
</ng-template>
