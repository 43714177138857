import { ElementRef } from '@angular/core';

export interface ElementDimensions {
  innerHeight: number;
  innerWidth: number;
  outerHeight: number;
  outerWidth: number;
  x: number;
  y: number;
}

export const getDimensions = (elt: ElementRef): ElementDimensions => {
  if ( !(elt && elt.nativeElement) ) {
    return {
      innerHeight: 0,
      innerWidth: 0,
      outerHeight: 0,
      outerWidth: 0,
      x: 0,
      y: 0,
    };
  }

  let node = elt.nativeElement;
  const outerHeight = node.offsetHeight;
  const outerWidth = node.offsetWidth;
  const innerHeight = node.clientHeight || outerHeight;
  const innerWidth = node.clientWidth || outerWidth;

  let x = 0;
  let y = 0;
  while ( node ) {
    x += node.offsetLeft;
    y += node.offsetTop;
    node = node.offsetParent;
  }

  return {
    innerHeight,
    innerWidth,
    outerHeight,
    outerWidth,
    x,
    y,
  };
};

/**
 * @see https://stackoverflow.com/a/35940276
 */
export const getScrollParent = (elt: Element): any => {
  if ( elt == null || !(elt instanceof Element) ) {
    return document.querySelector('html');
  }

  const overflowY = window.getComputedStyle(elt).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
  if ( isScrollable && elt.scrollHeight > elt.clientHeight ) {
    return elt;
  } else {
    return getScrollParent(elt.parentNode as Element);
  }
};
