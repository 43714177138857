import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ContentService } from 'src/app/core/content/content.service';
import { Core, ImageableContentReference, Translateable } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { LanguageHelper } from 'src/app/core/language.helper';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { destroySubscriptions } from 'src/app/core/reactive/until-destroyed';
import { ContentCardComponent } from '../../cards/content-card/content-card.component';
import { ClampComponent } from '../../clamp/clamp.component';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { ContentActionModule } from '../../elearning/content-action/content-action.module';
import { StatusLightsModule } from '../../elearning/status-lights/status-lights.module';
import {
  MyContentCardCurriculumComponent
} from '../components/my-content-card-curriculum/my-content-card-curriculum.component';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../core/cached-subject';
import { ContentCardHelper, ImportantContentInfo } from '../content-card.helper';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ContentCardComponent,
    ClampComponent,
    StatusLightsModule,
    DialogHeaderComponent,
    PipesModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    RouterModule,
    ContentActionModule,
    MyContentCardCurriculumComponent
  ],
  selector: 'rag-my-content-card',
  templateUrl: './my-content-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./my-content-card.component.scss']
})
export class MyContentCardComponent implements OnInit, OnDestroy, OnChanges {

  @Input() content!: ImageableContentReference;
  @Input() readonly = false;
  @Input() embedded = false;
  @Input() lang: string;

  contentLink: string;
  progress: string;

  readonly hasContent$: Observable<boolean>;

  @ViewChild('curriculumExecDate') curriculumExecDateTemplate: TemplateRef<unknown>;
  @ViewChild('eventDateTemplate') eventDateTemplate: TemplateRef<unknown>;
  @ViewChild('progressTemplate') progressTemplate: TemplateRef<unknown>;
  @ViewChild('recordingDateTemplate') recordingDateTemplate: TemplateRef<unknown>;

  private _hasContent$ = new CachedSubject<boolean>(null);

  @HostBinding('class.is-embedded') get isEmbedded() {
    return this.embedded;
  }

  constructor() {
    this.hasContent$ = this._hasContent$.withoutEmptyValues();
  }

  ngOnInit(): void {
    this.contentLink = ContentService.getContentHref(this.content, false);
    this.progress = Math.round(this.content.progress * 100) / 100 + '%';
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => this._hasContent$.next(changes.content.currentValue != null));
  }

  hasDescription(description: string | Translateable) {
    if (description == null) {
      return false;
    }
    if ((typeof description === 'string') && (description !== 'n/a')) {
      return description.trim().length > 0 ?? false;
    }
    if (typeof description === 'object') {
      return LanguageHelper.objectToText(description).length > 0;
    }

    return false;
  }

  templateForContent(objType: Core.DistributableType, objSubType: unknown) {
    switch (objType) {
      case Core.DistributableType.lms_offlineCnt:
        return  this.eventDateTemplate;
      case Core.DistributableType.lms_course:
        if (objSubType === 16) {
          return this.recordingDateTemplate;
        }
        return null
      case Core.DistributableType.lms_curriculum:
        return this.curriculumExecDateTemplate;
        default:
          return null;
    }
  }

  progressTemplateForContent(objType: Core.DistributableType) {
    if (DistributionTypeHelper.isCurriculum(objType)) {
      return this.progressTemplate;
    }
    return null;
  }

  getImportantContentInfo(content: ImageableContentReference): ImportantContentInfo | null {
    return ContentCardHelper.getImportantContentInfo(content);
  }

}
