import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import { AnyObject } from 'src/app/core/core.types';
import { Translation } from 'src/app/core/translation/translation.types';
import { MatSelect } from '@angular/material/select';
import {
  ColumnFilterDropdownOption,
  dropdownOptionsFactory,
  FilterOperator,
} from '../../../core/column-settings/column-filter.types';
import { CompareMethod } from 'src/app/core/primitives/primitives.types';


@Component({
  selector: 'rag-pivot-filter-dropdown',
  templateUrl: './pivot-filter-dropdown.component.html',
  styleUrls: [ './pivot-filter-dropdown.component.scss' ],
})
export class PivotFilterDropdownComponent
  extends AbstractPivotFilterComponent
  implements OnChanges {

  dropdownOptions: ColumnFilterDropdownOption[];
  @ViewChild('matSelect', { read: MatSelect, static: true }) matSelect: MatSelect;
  @Input() options: AnyObject<Translation>;
  @Input() showOptionAll = true;
  @Input() sortOptions: CompareMethod<ColumnFilterDropdownOption>;

  constructor() {
    super();

    this.defaultAction = FilterOperator.EQ;
  }

  clearValue() {
    super.clearValueDropdown(this.matSelect);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.options != null ) {
      this.dropdownOptions = dropdownOptionsFactory(changes.options.currentValue);
    }
    if ( changes.sortOptions?.currentValue !== undefined ) {
      this.dropdownOptions.sort(changes.sortOptions.currentValue);
    }
  }

}
