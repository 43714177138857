import { Component, Input, OnInit } from '@angular/core';
import { ProfileSectionTypes } from '../profile-section.types';
import { AnyObject } from '../../../../core/core.types';
import { LanguageHelper } from '../../../../core/language.helper';

@Component({
  selector: 'rag-profile-section-wrapper',
  templateUrl: './profile-section-wrapper.component.html',
  styleUrls: [ './profile-section-wrapper.component.scss' ],
})
export class ProfileSectionWrapperComponent
  implements OnInit {

  columnCount: number;
  columnIds: string[];
  columns: AnyObject<ProfileSectionTypes.ProfileSection[]>;
  @Input() countBeforeGrouping: number;
  @Input() disabled: boolean;
  @Input() sections: ProfileSectionTypes.ProfileSection[];

  constructor() {
  }

  ngOnInit(): void {
    // create two columns if more than 10 attributes are displayed
    const totalFieldCount = this.sections?.flatMap(s => s?.fields ?? []).length;
    const fieldSplitLength = (totalFieldCount > (this.countBeforeGrouping ?? 10))
      ? Math.ceil(totalFieldCount / 2) : totalFieldCount;
    let fieldCount = 0;
    let columnId = 0;
    this.columns = this.sections?.reduce((pV, section) => {

      if ( fieldCount >= fieldSplitLength ) {
        // place second half into right column
        columnId = 1;
      }
      let column = (pV[columnId] ??= []);

      const sectionFieldCount = section.fields.length;

      if ( (fieldCount < fieldSplitLength) && ((fieldCount + sectionFieldCount) > fieldSplitLength) ) {

        // section does not fit into the first column -> split into halves
        const deltaCount = fieldSplitLength - fieldCount;
        const firstSection: ProfileSectionTypes.ProfileSection = { ...section };
        firstSection.fields = section.fields.slice(0, deltaCount);
        column.push(firstSection);

        columnId = 1;
        column = (pV[columnId] ??= []);
        const secondSection: ProfileSectionTypes.ProfileSection = { ...section };
        secondSection.title =
          `${LanguageHelper.objectToText(section.title)} (${$localize`:@@global_continued:continued`})`;
        secondSection.fields = section.fields.slice(deltaCount);
        column.push(secondSection);

        fieldCount += sectionFieldCount;
        return pV;
      }

      column.push(section);
      fieldCount += sectionFieldCount;
      return pV;
    }, {});
    this.columnIds = Object.keys(this.columns);
    this.columnCount = this.columnIds.length;
  }

}
