import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { ApplicationStateService } from '../../../core/application-state.service';

@Component({
  selector: 'rag-toggle-fullscreen',
  templateUrl: './toggle-fullscreen.component.html',
  styleUrls: [ './toggle-fullscreen.component.scss' ],
})
export class ToggleFullscreenComponent {
  @Output() toggle = new EventEmitter<boolean>();
  @HostBinding('class.is-fullscreen') private fullscreen_ = false;

  constructor(private applicationState: ApplicationStateService) {
  }

  get fullscreen(): boolean {
    return this.fullscreen_;
  }

  @Input() set fullscreen(fullscreen: boolean) {
    this.fullscreen_ = fullscreen;
  }

  @HostListener('click')
  toggleFullscreen(): void {
    this.fullscreen_ = !this.fullscreen_;
    this.toggle.emit(this.fullscreen_);
  }

}
