<div class="wrapper" *ngIf="content">
  <div class="container" [ngClass]="{'readonly': readonly}">
    <div
      class="header"
      [style]="'background-image: url(' + (imageUrl$ | async) + '), url(\'assets/images/curriculum.jpg\')'"
    >
      <div class="header-toolbar">
        <div class="toolbar">
          <div class="obj-type">
            <a
              *ngIf="content?.objType"
              class="type-link"
              [routerLink]="contentLinkUrl"
              [queryParams]="contentLinkParams">
                <mat-icon [svgIcon]="svgIconForObjType"></mat-icon>
                <div>
                  <span>{{objType}}</span>
                  <span *ngIf="isBooked" class="catalog-state" i18n="@@booked">Booked</span>
                  <span *ngIf="!isBooked && isAssigned" class="catalog-state" i18n="@@catalog_assigned">Assigned</span>
                </div>
            </a>
          </div>
          <div class="accessory-view">
            <ng-container
              [ngTemplateOutlet]="accessoryTemplate"
              [ngTemplateOutletContext]="{content: content}"
            ></ng-container>
          </div>
        </div>
        <ng-container
          [ngTemplateOutlet]="progressTemplate"
          [ngTemplateOutletContext]="{content: content}"
        ></ng-container>
      </div>
    </div>

    <div class="content">
      <a class="title" [routerLink]="contentLinkUrl" [queryParams]="contentLinkParams">
        <rag-clamp [clamp]="clampCount" [input]="content.title | localeText: lang"></rag-clamp>
      </a>
      <div class="sub-title-accessory-view">
        <ng-container
          [ngTemplateOutlet]="subtitleAccessoryTemplate"
          [ngTemplateOutletContext]="{content: content}">
        </ng-container>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div class="dynamic-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
