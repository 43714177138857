import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { catchError, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import {
  EventParticipantsInvitationStatusText,
  VirtualRoomAttendance,
} from 'src/app/core/virtual-room-attendance.types';
import { InfoService } from 'src/app/core/info/info.service';
import { CancelButton, InfoType, MessageKey, RemoveButton } from 'src/app/core/info/info.types';
import { PermissionStates } from 'src/app/core/principal/permission.states';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { MailService } from 'src/app/route/user/mail/mail.service';
import { MailEntry } from 'src/app/core/mail.types';
import { GenericMessageDialogComponent } from '../../generic-message-dialog/generic-message-dialog.component';
import * as moment from 'moment';
import { LanguageHelper } from 'src/app/core/language.helper';
import { UrlHelper } from 'src/app/core/url.helper';
import { combineLatest } from 'rxjs';
import { ContentOfflineService } from 'src/app/route/user/content/content-offline/content-offline.service';
import { FileUploadService } from '../../../core/files/file-upload.service';
import * as uuid from 'uuid';
import { EventParticipantsService } from 'src/app/route/admin/admin-offline/components/content-events/event-participants/event-participants.service';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { PostToBlankComponent } from '../../post-to-blank/post-to-blank.component';
import { RagHttpParameterCodec } from 'src/app/core/rag-http-parameter-codec.class';
import { HttpParams } from '@angular/common/http';
import { ApiUrls } from 'src/app/core/api.urls';
import { SelectionModel } from '@angular/cdk/collections';
import { isNothing } from 'src/app/core/utils';
import { TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { AdminOfflineEventParticipantsRow } from '../../../route/admin/admin-offline/components/content-events/event-participants/event-participants.columns';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StatusLightsModule } from '../../elearning/status-lights/status-lights.module';
import { IconTextComponent } from '../../icon-text/icon-text.component';
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component';
import { SelectTargetGroupsMenuModule } from '../../select-target-groups-menu/select-target-groups-menu.module';
import { ROUTE_MATCH_OPTIONS_NOT_EXACT } from '../../../core/navigation/navigation.types';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconTextComponent,
    MatMenuModule,
    MatIconModule,
    PostToBlankComponent,
    LoadingIndicatorComponent,
    StatusLightsModule,
    SelectTargetGroupsMenuModule,
    RouterLink,
  ],
  selector: 'rag-participant-offline-menu',
  templateUrl: './participant-offline-menu.component.html',
  styleUrls: [ './participant-offline-menu.component.scss' ],
})
export class ParticipantOfflineMenuComponent<T extends OfflineContent.Participant>
  implements OnInit, OnChanges, OnDestroy {

  hasMessagePermission = false;
  hasStatusPermission = false;
  hasAssignPermission = false;
  hasExamination = false;
  disableStatusActions = false;
  eventInPast = false;
  permissions: PermissionStates;
  hasRegistrationConfirmationTemplate = false;
  hasParticipantListTemplate = false;
  isNothing = isNothing;
  blockEvent = false;

  @Input() selection: SelectionModel<T>;
  @Input() offlineContent: OfflineContent.Event;
  @Input() eventSchedule: OfflineContent.EventSchedule;
  @Input() inputDisabled = false;
  @Input() toolbarMenuCustomTemplate: TemplateRef<any>;
  @Input() registrationStatusMenuCustomTemplate: TemplateRef<any>;
  @Input() participantActionMenuCustomTemplate: TemplateRef<any>;
  @Input() columns: TableControllerTypes.ColumnMenuItem<AdminOfflineEventParticipantsRow>[];
  @Output() removeParticipant: EventEmitter<VirtualRoomAttendance.AssignmentUpdate>;
  @Output() downloadParticipantListPdfXls: EventEmitter<void>;
  @Output() generateAttendeesListPDF: EventEmitter<void>;
  @Output() closed: EventEmitter<void>;
  @Output() finished: EventEmitter<void>;

  @ViewChild('toolbarMenu',  { static: true }) toolbarMenu: MatMenu;
  @ViewChild('participantActionMenu',  { static: true }) participantActionMenu: MatMenu;

  constructor(
    protected route: ActivatedRoute,
    private principalService: PrincipalService,
    private infoService: InfoService,
    private mailService: MailService,
    private contentOfflineService: ContentOfflineService,
    private fileService: FileUploadService,
    private eventParticipantsService: EventParticipantsService,
    private router: Router,
  ) {
    this.closed = new EventEmitter();
    this.finished = new EventEmitter();
    this.removeParticipant = new EventEmitter();
    this.downloadParticipantListPdfXls = new EventEmitter();
    this.generateAttendeesListPDF = new EventEmitter();
   }

  ngOnInit(): void {
    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(tap(this.checkPermissions))
      .pipe(tap(this.checkInputDisabled))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['eventSchedule'] != null) {
        this.eventScheduleChanged();
      }
      if (changes['offlineContent'] != null) {
        this.offlineContentChanged();
      }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  isParticipationDesired(participant: OfflineContent.Participant): boolean {
    if (participant == null) {
      return false;
    }
    const participationStatus = OfflineContent.offlineParticipationStatusFactory(participant.participationStatus);
    return participationStatus === OfflineContent.OfflineParticipationStatus.DESIRED;
  }

  onClosed(): void {
    this.closed.emit();
  }

  onRemoveParticipant(participant: OfflineContent.Participant) {
    if ( !(participant && participant.userId) ) {
      return;
    }
    this.infoService
      .showDialog(GenericMessageDialogComponent, {
        titleKey: MessageKey.DIALOG.CONFIRM.TITLE,
        message: $localize`:@@offline_cnt_event_participant_remove:
          Do you want to remove the participant from the schedule?`,
        buttons: CancelButton | RemoveButton,
      })
      .pipe(takeWhile(response => response === RemoveButton))
      .pipe(tap(() => this.removeParticipant.emit({ assign: [], unassign: [ { userId: participant.userId} ] })))
      .pipe(finalize(() => this.finished.emit()))
      .subscribe();
  }

  onDownloadRegistrationConfirmation(postToBlank: PostToBlankComponent) {
    const jsonData = {
      contentId: this.offlineContent.id,
      eventId: this.eventSchedule.id,
      contentType: 'registrationConfirmPdf',
      userIds: this.selection.selected.map(entry => entry.userId).join(','),
    };
    const url = ApiUrls.getKey('CtrlOfflineParticipantListExport');
    const params = new HttpParams({ encoder: new RagHttpParameterCodec() })
      .append('json', JSON.stringify(jsonData));
    postToBlank.executePost(url, params);
  }

  onSendNotification(invitationStatus: EventParticipantsInvitationStatusText, participant: OfflineContent.Participant) {

    const participants = participant == null ? this.selection.selected : [ participant ];
    if ( !(participants.length > 0) ) {
      return;
    }

    const userIds = participants.map(entry => entry.userId);
    const attachmentUuid = uuid.v4();
    const offlineContent = this.offlineContent;
    const eventSchedule = this.eventSchedule;
    combineLatest([
      this.mailService.convertPrincipalIdsIntoMessageAccountIds(userIds)
        .pipe(map(receivers => receivers.filter(r => r.type === 'user'))),
      this.contentOfflineService.eventToIcal('event.ics', this.offlineContent, this.eventSchedule)
        .pipe(switchMap(icalFile => this.fileService.uploadAttachment(attachmentUuid, icalFile)
          .pipe(map(() => icalFile))))
        .pipe(map(icalFile => ({
          file: icalFile,
          fileName: 'event.ics',
          size: icalFile.size,
          fileType: icalFile.type,
          uuid: attachmentUuid,
        }))),
    ])
      .pipe(switchMap(([ receivers, attachment ]) => {
        const message = this.getNotificationMessage(invitationStatus, offlineContent, eventSchedule);
        message.attachments = [attachment];
        message.context = { contentId: offlineContent?.id, eventId: eventSchedule?.id };
        return this.mailService.showMailComposer(message, 'inbox', receivers, false, 'OfflineContent');
      }))
      .pipe(take(1))
      .pipe(takeWhile(confirmed => (confirmed === true) && (invitationStatus != null)))
      .pipe(switchMap(() => this.eventParticipantsService.setInvitationStatus(this.offlineContent.id, this.eventSchedule.id,
        OfflineContent.OfflineInvitationStatus[invitationStatus], [participant], moment().startOf('d'))))
      .pipe(take(1))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .subscribe();
  }

  onSetInvitationStatus(invitationStatus: EventParticipantsInvitationStatusText, participant: OfflineContent.Participant) {
    const participants = participant == null ? this.selection.selected : [ participant ];
    if ( this.disableStatusActions || this.disableStatusActions || !(participants.length > 0) ) {
      return;
    }
    this.eventParticipantsService.setInvitationStatus(this.offlineContent.id, this.eventSchedule.id,
      OfflineContent.OfflineInvitationStatus[invitationStatus], participants)
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .pipe(take(1))
      .subscribe();
  }

  onSetParticipationRequest(accepted: boolean, participant: OfflineContent.Participant) {
    let participants = participant == null ? this.selection.selected : [ participant ];
    if ( !(participants.length > 0) ) {
      return;
    }
    participants = participants
      .filter(p => OfflineContent.offlineParticipationStatusFactory(p.participationStatus) === OfflineContent.OfflineParticipationStatus.DESIRED);
    if ( !(participants.length > 0) ) {
      this.infoService.showSnackbar(null, InfoType.Warning, {
        message: $localize`:@@admin_offline_event_participants_handle_request_none_desired:
          None of the selected participants is still waiting for confirmation!`,
      });
      return;
    }

    this.eventParticipantsService.setParticipationRequest(this.offlineContent.id, this.eventSchedule.id, accepted, participants)
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .pipe(take(1))
      .subscribe();
  }

  onSetParticipationStatus(hasParticipated: boolean, participant: OfflineContent.Participant) {
    const participants = participant == null ? this.selection.selected : [ participant ];
    if ( !(participants.length > 0) ) {
      return;
    }

    this.eventParticipantsService.setParticipationStatus(this.offlineContent.id, this.eventSchedule.id, hasParticipated, participants)
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .pipe(take(1))
      .subscribe();
  }

  onSetExaminationStatus(passed: boolean, participant: OfflineContent.Participant) {
    const participants = participant == null ? this.selection.selected : [ participant ];
    if ( !(participants.length > 0) ) {
      return;
    }

    this.eventParticipantsService.setExaminationStatus(this.offlineContent.id, this.eventSchedule.id, passed, participants)
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .pipe(take(1))
      .subscribe();
  }

  onSetRegistrationStatus(registrationStatus: string, participant: OfflineContent.Participant): void {
    const participants = participant == null ? this.selection.selected : [ participant ];
    if ( !(participants.length > 0) ) {
      return;
    }

    const status = OfflineContent.offlineParticipationRegistrationFactory(registrationStatus);
    this.eventParticipantsService.setRegistrationStatus(this.offlineContent.id, this.eventSchedule.id, status, participants)
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error)))
      .pipe(finalize(() => this.finished.emit()))
      .pipe(take(1))
      .subscribe();
  }

  onDownloadParticipantListPdf(postToBlank: PostToBlankComponent) {
    const userIds = this.selection.selected.map(entry => entry.userId);
    if ( !(userIds.length > 0) ) {
      // no participants selected
      return;
    }

    if (!this.hasParticipantListTemplate) {
      this.generateAttendeesListPDF.emit();
      return;
    }

    const jsonData = {
      contentId: this.offlineContent.id,
      eventId: this.eventSchedule.id,
      contentType: 'eventParticipantListPdf',
      userIds: userIds.join(','),
    };
    const url = ApiUrls.getKey('CtrlOfflineParticipantListExport');
    const params = new HttpParams({ encoder: new RagHttpParameterCodec() })
      .append('json', JSON.stringify(jsonData));
    postToBlank.executePost(url, params);
  }

  onDownloadParticipantListXls() {
    const users = this.selection.selected;
    if ( !(users.length > 0) ) {
      // no participants selected
      return;
    }

    this.downloadParticipantListPdfXls.emit();
  }

  private eventScheduleChanged() {
    if (this.eventSchedule != null) {
      this.hasExamination = this.eventSchedule.examination === true;
      this.eventInPast = moment().isAfter(this.eventSchedule.eventDate);
    } else {
      this.hasExamination = false;
      this.eventInPast = false;
    }
  }

  private offlineContentChanged() {
    if (this.eventSchedule != null) {
      this.blockEvent = this.offlineContent.blockEvent;
      this.hasRegistrationConfirmationTemplate = this.offlineContent.registrationConfirmTemplateId > 0;
      this.hasParticipantListTemplate = this.offlineContent.participantListTemplateId > 0;
    } else {
      this.blockEvent = false;
      this.hasRegistrationConfirmationTemplate = false;
      this.hasParticipantListTemplate = false;
    }
  }

  private checkInputDisabled = (): void => {
    this.disableStatusActions = OfflineContent.isComplete(this.eventSchedule?.closedStatus);
  };

  private checkPermissions = (permissions: PermissionStates): void => {
    this.hasAssignPermission = permissions.ctrlOfflineAssignment;
    this.hasMessagePermission = permissions.userMessagesSend;
    this.hasStatusPermission = permissions.ctrlOfflineEditStatus;
  };

  private getNotificationMessage(
    invitationStatus: EventParticipantsInvitationStatusText,
    offlineContent: OfflineContent.Event,
    eventSchedule: OfflineContent.EventSchedule): MailEntry {
    const eventDate = moment(eventSchedule.eventDate).format('LL');
    const eventTitle = LanguageHelper.objectToText(eventSchedule.title) ||
      LanguageHelper.objectToText(offlineContent.title);

    const contentId = eventSchedule.contentId;
    const href = UrlHelper.getPublicRedirect(`/run/offline/${contentId}?invKey=`);

    // add macro at the end, to prevent encodeURIComponent from creating problems
    const acceptUrl = `${href}{off:ackKey:${eventSchedule.id}}`;
    const declineUrl = `${href}{off:decKey:${eventSchedule.id}}`;

    const linkContentExecute = UrlHelper.getPublicRedirect('/run/offline/' + contentId);

    // @see https://blog.ninja-squad.com/2019/12/10/angular-localize/#i18n-with-localize-in-typescript-code
    switch ( invitationStatus ) {
      case 'inv': {
        const invSubject = $localize`:@@admin_offline_event_participants_message_invite_subject:
          Invitation to "${eventTitle}" on ${eventDate}
        `;

        const invBody = $localize`:@@admin_offline_event_participants_message_invite_body:
            Hello ${'{usr:fname} {usr:lname}'},<br/><br/>
            you have been invited to the event "${eventTitle}" on ${eventDate}. Via the following links
            you can <a href="${acceptUrl}">confirm</a> or <a href="${declineUrl}">decline</a>.<br/>
            You can use the links more than once to change your invitation status.<br/>
            Additional information to the upcoming event is available in your learn account.<br/>
            As attachment you find an entry for your organizer software in vcs-format.<br/><br/>
            Kind regards<br/>
            your elearning Team
        `;

        return {
          id: null,
          read: false,
          subject: invSubject.trim(),
          body: invBody.trim(),
        };
      }
      case 'rem': {
        const remSubject = $localize`:@@admin_offline_event_participants_message_remind_subject:
          Reminder for "${eventTitle}" on ${eventDate}
        `;

        const remBody = $localize`:@@admin_offline_event_participants_message_remind_body:
          Hello ${'{usr:fname} {usr:lname}'},<br/><br/>
          here with we would like to remind you about your booked event "${eventTitle}" which will start on ${eventDate}!
          Additional information about the upcoming event can be found in your <a href="${linkContentExecute}">Event Navigator</a>.<br/><br/>
          We appreciate your participation in our event and hope you enjoy it!<br/>
          Best regards<br/>
          your elearning Team
        `;

        return {
          id: null,
          read: false,
          subject: remSubject.trim(),
          body: remBody.trim(),
        };
      }
      default:
        return {
          id: null,
          read: false,
          subject: eventTitle,
          body: '',
        };
    }
  }

  getUrlMedical(eventId: number, participant: AdminOfflineEventParticipantsRow): string[] | null {
    if (!this.permissions.ctrlTrainerMedicalExamination || !(eventId > 0) || !(participant?.userId > 0)) {
      return null;
    }

    const principalId = this.permissions.principalId;
    const isTrainer = this.eventSchedule?.trainers
      ?.find(o => o.userId === principalId) != null;
    if (!isTrainer) {
      return null;
    }

    const isInOfflineAdmin = this.router
      .isActive('/admin/offline/content/details', ROUTE_MATCH_OPTIONS_NOT_EXACT);
    if (isInOfflineAdmin) {
      return [`${eventId}`, 'user', `${participant.userId}`, 'medical'];
    }

    const isInTrainerDashboard = this.router
      .isActive('/trainer/event', ROUTE_MATCH_OPTIONS_NOT_EXACT);
    if (isInTrainerDashboard) {
      return ['user', `${participant.userId}`, 'medical'];
    }

    return null;
  }

}
