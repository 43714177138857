import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { InfoService } from '../../../../../core/info/info.service';
import { ViewHelper } from '../../../../../core/view-helper';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { MatTableDataSource } from '@angular/material/table';
import { LanguageHelper } from '../../../../../core/language.helper';
import { ContentOfflineService } from '../content-offline.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { EventAccountStatusEnum, LearnerEventAccountView, LearnerOfflineAccountView } from 'src/app/core/learner-account/learner-account.types';
import { isTimeCrossing } from 'src/app/core/utils';
import { DisplayStatusHelper } from 'src/app/core/display-status-helper';
import { FinancialsHelper } from '../../../../../core/financials/financials.helper';
import { MessageConstants, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import { PreloadService } from 'src/app/core/preload.service';
import { CatalogBookingHelper } from '../../../../../core/catalog/catalog-booking.helper';


@Component({
  selector: 'rag-content-offline-choose',
  templateUrl: './content-offline-choose.component.html',
  styleUrls: [ './content-offline-choose.component.scss' ],
})
export class ContentOfflineChooseComponent
  implements OnInit, OnChanges, OnDestroy {

  @Input() offlineContent: OfflineContent.Event;
  @Input() offlineEventSchedules: OfflineContent.EventSchedule[];
  @Input() offlineEventAccountViews: Array<LearnerEventAccountView>;
  @Input() offlineContentAccount: LearnerOfflineAccountView;
  @Output() bookEvent = new EventEmitter<OfflineContent.EventSchedule>();
  @Output() cancelEvent = new EventEmitter<OfflineContent.EventSchedule>();

  datasource: MatTableDataSource<OfflineContent.EventSchedule>;
  displayedColumns: Array<string>;
  // used to temporary disable the buttons while operation is progress
  inputDisabled = false;
  // when true booking of other schedules is not allowed
  bookingIsDisabled = false;
  bookableContents: boolean;
  paymentModuleEnabled = false;
  isBlockEvent = false;
  rowsToSpan = 1;

  constructor(
    private contentOfflineService: ContentOfflineService,
    private infoService: InfoService,
    private preloadService: PreloadService
  ) {
    this.datasource = new MatTableDataSource();
    this.datasource.filterPredicate = this.filterPredicate;
  }

  get hasEventSchedules() {
    return this.offlineEventSchedules.length > 0;
  }

  ngOnInit() {

    // does not use async pipe to immediately retrieve current value on subscribe
    this.contentOfflineService.inputDisabled$
      .pipe(tap(inputDisabled => this.inputDisabled = inputDisabled))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.preloadService
      .isPaymentModuleEnabled$
      .pipe(takeUntilDestroyed(this))
      .subscribe(enabled => this.paymentModuleEnabled = enabled);
  }

  ngOnChanges(_: SimpleChanges): void {
    this.configure();
  }


  onBookEvent(event: OfflineContent.EventSchedule): void {
    this.bookEvent.emit(event);
  }

  freePlaces(eventSchedule: OfflineContent.EventSchedule) {
    return eventSchedule.$view['availablePlaces'] || 0;
  }


  getColorOfFreePlaces(eventSchedule: OfflineContent.EventScheduleCatalogView): string {

    const availablePlaces = eventSchedule.$view['availablePlaces'] || 0;
    const closedStatus = eventSchedule.closedStatus;
    const maxUserCount = eventSchedule.maxUserCount;
    return CatalogBookingHelper.getColorOfFreePlaces(availablePlaces, maxUserCount, closedStatus);
  }

  getFreePlacesPlaceholder(eventSchedule: OfflineContent.EventScheduleCatalogView): string {

    const availablePlaces = eventSchedule.$view['availablePlaces'] || 0;
    const closedStatus = eventSchedule.closedStatus;
    const maxUserCount = eventSchedule.maxUserCount;
    return CatalogBookingHelper
      .getFreePlacesPlaceholder(availablePlaces, maxUserCount, closedStatus);
  }

  price(eventSchedule: OfflineContent.EventSchedule) {
    return FinancialsHelper.fromPriceInCent(eventSchedule.priceForUserInCent ?? 0);
  }

  hasTransactions() {
    for (const view of this.offlineEventAccountViews) {
      if (view.merchantStatus != null && view.merchantTransactionType != null) {
        return true;
      }
    }
    return false;
  }

  getTransactionStatus(eventSchedule: OfflineContent.EventSchedule) {
    for (const currentEvent of this.offlineEventAccountViews) {
      if (currentEvent.offlineEventId === eventSchedule.id) {
        switch (currentEvent.merchantTransactionType) {

          case (OfflineContent.MerchantTransactionType.checkout): {
            switch (currentEvent.merchantStatus) {

              case (OfflineContent.MerchantStatus.success): {
                return $localize`:@@global_successfull_payed:Payment successful`;
              }
              case (OfflineContent.MerchantStatus.declined): {
                return $localize`:@@global_payment_failed:Payment failed`;
              }
              case (OfflineContent.MerchantStatus.canceled): {
                return $localize`:@@global_payment_failed:Payment failed`;
              }
              case (OfflineContent.MerchantStatus.error): {
                return $localize`:@@global_payment_failed:Payment failed`;
              }
              case (OfflineContent.MerchantStatus.pending): {
                return $localize`:@@global_payment_pending:Payment pending`;
              }
              case (OfflineContent.MerchantStatus.authorization_pending): {
                return $localize`:@@global_payment_pending:Payment pending`;
              }
              case (OfflineContent.MerchantStatus.processing): {
                return $localize`:@@global_payment_pending:Payment pending`;
              }
            }
          }
            break;

          case (OfflineContent.MerchantTransactionType.refund): {
            switch (currentEvent.merchantStatus) {

              case (OfflineContent.MerchantStatus.success): {
                return $localize`:@@global_refund_successful:Refund successful`;
              }
              case (OfflineContent.MerchantStatus.declined): {
                return $localize`:@@global_refund_failed:Refund failed`;
              }
              case (OfflineContent.MerchantStatus.canceled): {
                return $localize`:@@global_refund_failed:Refund failed`;
              }
              case (OfflineContent.MerchantStatus.error): {
                return $localize`:@@global_refund_failed:Refund failed`;
              }
              case (OfflineContent.MerchantStatus.pending): {
                return $localize`:@@global_refund_pending:Refund pending`;
              }
              case (OfflineContent.MerchantStatus.authorization_pending): {
                return $localize`:@@global_refund_pending:Refund pending`;
              }
              case (OfflineContent.MerchantStatus.processing): {
                return $localize`:@@global_refund_pending:Refund pending`;
              }
            }
          }
            break;
        }
      }
    }
    return '';
  }
  hasTrainers(eventSchedule: OfflineContent.EventSchedule) {
    return eventSchedule.trainers && eventSchedule.trainers.length > 0;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  showInfo(eventSchedule: OfflineContent.EventSchedule) {
    this.infoService.showAlert(LanguageHelper.objectToText(eventSchedule.description));
  }

  hasBookedEventSchedule(eventSchedule: OfflineContent.EventSchedule) {
    if (this.isBlockEvent) {
      return this.offlineEventSchedules.reduce((pV, oes) => {
        const viewData = ViewHelper.getViewData(oes);
        return viewData.hasBooked ? pV + 1 : pV;
      }, 0) === this.offlineEventSchedules.length;
    }
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.hasBooked;
  }

  hasApplied(eventSchedule: OfflineContent.EventSchedule) {
    if (this.isBlockEvent) {
      return this.offlineEventSchedules.reduce((pV, oes) => {
        const viewData = ViewHelper.getViewData(oes);
        return viewData.hasApplied ? pV + 1 : pV;
      }, 0) === this.offlineEventSchedules.length;
    }
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.hasApplied;
  }

  isCancelable(eventSchedule: OfflineContent.EventSchedule) {
    if (this.isBlockEvent) {
      // the user cannot cancel single event schedules when blockEvent is true
      return false;
    }
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.hasBooked || viewData.hasApplied;
  }

  isDisabledByTimeCrossing(eventSchedule: OfflineContent.EventSchedule) {
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.timeCrossing ?? false;
  }

  isDisabledByBegin(eventSchedule: OfflineContent.EventSchedule) {
    return !CatalogBookingHelper.mayBookEventSchedule(eventSchedule.closedStatus, eventSchedule.eventDate,
      eventSchedule.eventDateUntil, eventSchedule.bookingUntilEnd, eventSchedule.priceForUserInCent);
  }

  isActionAvailable(eventSchedule: OfflineContent.EventSchedule) {
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.canAct;
  }

  isBookingButtonDisabled(eventSchedule: OfflineContent.EventSchedule): boolean {

    if (this.inputDisabled || this.bookingIsDisabled) {
      return true;
    }

    return (eventSchedule?.availableForBooking === false) ||
      this.isDisabledByTimeCrossing(eventSchedule) || (this.freePlaces(eventSchedule) < 1);
  }

  isDistributed(eventSchedule: OfflineContent.EventSchedule) {
    const viewData = ViewHelper.getViewData(eventSchedule);
    return viewData.distributed;
  }

  isPayable(eventSchedule: OfflineContent.EventSchedule) {
    if (this.isBlockEvent) {
      return this.paymentModuleEnabled && (
        this.offlineEventSchedules.reduce((pV, oes) => {
          return pV + (oes.priceForUserInCent ?? 0);
        }, 0) > 0
      )
    }
    return this.paymentModuleEnabled && (eventSchedule.priceForUserInCent ?? 0) > 0;
  }

  onCancelBooking(eventSchedule: OfflineContent.EventSchedule) {

    let cancelMessage = $localize`:@@catalog_booking_cancel_confirm:Your booking is cancelled, would you like to continue?`;
    if ( this.hasApplied(eventSchedule) ) {
      cancelMessage = $localize`:@@catalog_booking_cancel_request_confirm:Your request is cancelled, would you like to continue?`;
    }

    this.infoService.showMessage(
      cancelMessage,
      {
        buttons: YesNoButtons,
        title: MessageConstants.DIALOG.TITLE.CONFIRM,
      }).subscribe(button => {
      if ( button === YesButton ) {
        this.cancelEvent.emit(eventSchedule);
      }
    });
  }

  filterPredicate = (eventSchedule: OfflineContent.EventSchedule): boolean => this.isActionAvailable(eventSchedule);

  private configure() {
    this.isBlockEvent = this.offlineContent.blockEvent;
    this.bookableContents = this.offlineContent.assignmentModeForPrincipal !== 'admin' &&
    this.offlineContent.offlineEvents?.length > 0;

    let ortColumnVisisble = false;
    let extLoginColumnVisible = false;

    this.offlineEventSchedules = this.offlineContent.offlineEvents != null ? [ ...this.offlineContent.offlineEvents ] : [];

    this.rowsToSpan = this.isBlockEvent ? this.offlineEventSchedules.length : 1;

    this.offlineEventSchedules.forEach(offlineEventSchedule => {
      this.addEventViewData(offlineEventSchedule);
      ortColumnVisisble = ortColumnVisisble || offlineEventSchedule.location != null;
      extLoginColumnVisible = extLoginColumnVisible || offlineEventSchedule.extLogin != null;
    });

    this.offlineEventSchedules
      .sort(CatalogBookingHelper.sortComparatorEventSchedule);

    this.displayedColumns = [ 'startDate', 'endDatum', 'title', 'trainers' ];

    if (ortColumnVisisble) {
      this.displayedColumns.push('location');
    }

    if (extLoginColumnVisible) {
      this.displayedColumns.push('ext-login');
    }

    this.displayedColumns = this.displayedColumns.concat([ 'notices' ]);

    if (!this.offlineContent.blockEvent) {
      this.displayedColumns = this.displayedColumns.concat([ 'freePlaces', 'price' ]);
    }

    if (this.hasTransactions()) {
      this.displayedColumns = this.displayedColumns.concat(['transactionStatus']);
    }

    if (!this.isBlockEvent) {
      this.displayedColumns = this.displayedColumns.concat([ 'actions' ]);
    }


    this.datasource.data = this.offlineEventSchedules
      ?.sort(CatalogBookingHelper.sortComparatorEventSchedule) ?? [];
    this.datasource.filter = '👹';

    this.contentOfflineService.emitInputDisabled(false);
    this.handleBookingIsAllowedFlag();
  }

  private handleBookingIsAllowedFlag() {
    if (
      !this.inputDisabled &&                                // input is allowed
      this.offlineContent?.multipleSchedules &&             // multiple booking is enabled
      this.offlineContent.allowedMaxSchedulesCount > 0) {   // there is max count of bookings allowed

    this.bookingIsDisabled =
      this.offlineContent.allowedMaxSchedulesCount <= (this.offlineEventAccountViews?.length ?? 0);
    }
  }

  private addEventViewData = (eventSchedule: OfflineContent.EventSchedule) => {
    if ( !eventSchedule || (eventSchedule.$view && eventSchedule.$view['ContentOfflineChooseComponent']) ) {
      // ignore null values and already initialized events
      return;
    }

    const viewData = ViewHelper.getViewData(eventSchedule);
    viewData.ContentOfflineChooseComponent = true;

    const eventDate = moment(eventSchedule.eventDate);
    const eventDateValid = eventDate.isValid();

    if ( eventDateValid ) {
      viewData.eventDateMonth = eventDate.format('MMM');
      viewData.eventDateDay = eventDate.format('DD');
    }

    const eventDateUntil = moment(eventSchedule.eventDateUntil);
    const eventDateUtilValid = eventDateUntil.isValid();

    let eventTimeSpan: string;
    if ( eventDateValid && eventDateUtilValid ) {
      if ( eventDate.isSame(eventDateUntil, 'day') ) {
        eventTimeSpan = eventDate.format('L');
        eventTimeSpan += ' ' + eventDate.format('LT');
        eventTimeSpan += ' - ' + eventDateUntil.format('LT');
      } else {
        eventTimeSpan = eventDate.format('L LT');
        eventTimeSpan += ' - ' + eventDateUntil.format('L LT');
      }
    } else if ( eventDateValid ) {
      eventTimeSpan = eventDate.format('L LT');
    } else if ( eventDateUtilValid ) {
      eventTimeSpan = eventDateUntil.format('L LT');
    }
    viewData.eventTimeSpan = eventTimeSpan;

    if ( eventSchedule.maxUserCount > 0 ) {
      const actualUserCount = Math.max(eventSchedule.actualUserCount || 0, 0);
      if ( actualUserCount < eventSchedule.maxUserCount ) {
        viewData.availablePlaces = eventSchedule.maxUserCount - actualUserCount;
      } else {
        viewData.availablePlaces = 0;
      }
    } else {
      viewData.availablePlaces = null;
    }

    // calculate whether this schedule is bookable
    let timeCrossing = false;

    const existingEventAccountView = this.offlineEventAccountViews.find(
      accountView => accountView.offlineEventId === eventSchedule.id);

    let hasBooked = false;
    let hasApplied = false;
    let canAct = true;
    const distributed = existingEventAccountView != null;

    if (distributed) {
      hasBooked = existingEventAccountView.bookingUUID !== undefined ||
        existingEventAccountView.status === EventAccountStatusEnum.ACCEPTED || (
          eventSchedule?.bookingUntilEnd && existingEventAccountView.status === EventAccountStatusEnum.PARTICIPATED
        );
      hasApplied = !hasBooked && existingEventAccountView.status === EventAccountStatusEnum.DESIRED;
      canAct = CatalogBookingHelper.mayBookEventSchedule(eventSchedule.closedStatus, eventSchedule.eventDate,
          eventSchedule.eventDateUntil, eventSchedule.bookingUntilEnd, eventSchedule.priceForUserInCent) &&
        // TF-2852 prevent clearing booking after attending
        !DisplayStatusHelper.isStatusGreen(existingEventAccountView.displayStatus);
    } else {
      if (this.offlineContent.multipleSchedules) {
        if (this.offlineContent.allowedMaxSchedulesCount > 0) {
          canAct = this.offlineEventAccountViews.length < this.offlineContent.allowedMaxSchedulesCount;
        }
      } else {
        canAct = this.offlineEventAccountViews.length === 0;
      }
    }

    if (this.offlineContent.multipleSchedules && !this.offlineContent.schedulesOverlapping) {
      // lookup for the first schedules account timely overlapping this schedule
      timeCrossing = this.offlineEventAccountViews.find(scheduleAccount => {
        // get the event schedule of this schedule account
        const scheduleOfThisAccount = this.offlineEventSchedules.find(
          schedule => schedule.id === scheduleAccount.offlineEventId);
        if (scheduleOfThisAccount != null) {

          return isTimeCrossing(
            eventSchedule.eventDate, eventSchedule.eventDateUntil,
            scheduleOfThisAccount.eventDate, scheduleOfThisAccount.eventDateUntil);
        }
      }) != null;
    }
    viewData.hasBooked = hasBooked;
    viewData.hasApplied = hasApplied;
    viewData.canAct = canAct;
    viewData.timeCrossing = timeCrossing;
    viewData.distributed = distributed;
  };

}
