import { SortDirection } from '@angular/material/sort';
import { TableControllerTypes } from '../table-controller/table-controller.types';
import { SelectionModel } from '@angular/cdk/collections';
import { TableGroupingHelper } from './table-grouping.helper';


export namespace TableGroupingTypes {


  export type TableRowType = 'parent' | 'child';
  export type TableRowSelectionStatus = 'checked' | 'indeterminate' | 'unchecked';

  export interface TableRow<ParentType = any, ChildType = any> {
    $data: ChildType | ParentType;
    $filterVisible?: boolean;
    $parent?: TableRowParent<ParentType, ChildType>;
    $parentRoot?: TableRowParent<ParentType, ChildType>;
    $rowSort: number[];
    $rowType: TableRowType;
    $selectionStatus?: TableRowSelectionStatus;
    $sortDefaultAsc?: string;
    $sortDefaultDesc?: string;
    $visible?: boolean;
  }

  export interface TableRowChild<ParentType = any, ChildType = any>
    extends TableRow<ParentType, ChildType> {
    $data: ChildType;
  }

  export interface TableRowParent<ParentType = any, ChildType = any>
    extends TableRow<ParentType, ChildType> {
    $childVisible?: boolean;
    $children: TableRow<ParentType, ChildType>[];
    $data: ParentType;
    $expanded?: boolean;
    $maxDepth?: number;
  }

  export class Util {

    /**
     * @deprecated replace with {@link TableGroupingHelper.applyFilter}
     */
    static applyFilter<T extends TableRow>(rows: T[], filterPredicate: (tableRow: T) => boolean): T[] {
      return TableGroupingHelper.applyFilter(rows, filterPredicate);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.applyFilterParentVisible}
     */
    static applyFilterParentVisible<T extends TableRow>(row: T): void {
      TableGroupingHelper.applyFilterParentVisible(row);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.calculateRowSort}
     */
    static calculateRowSort<T extends TableRowParent>(orderIndex: number, parent?: T): number[] {
      return TableGroupingHelper.calculateRowSort(orderIndex, parent);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.collapseChildren}
     */
    static collapseChildren(row: TableRow, collapseSelf?: boolean): boolean {
      return TableGroupingHelper.collapseChildren(row, collapseSelf);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.compareRowSort}
     */
    static compareRowSort(a: TableRow, b: TableRow): number {
      return TableGroupingHelper.compareRowSort(a, b);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.expandChildren}
     */
    static expandChildren(row: TableRow, expandSelf?: boolean): boolean {
      return TableGroupingHelper.expandChildren(row, expandSelf);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.expandParents}
     */
    static expandParents(row: TableRow, expandSelf?: boolean): boolean {
      return TableGroupingHelper.expandParents(row, expandSelf);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.getChildren}
     */
    static getChildren<T extends TableRow>(row: T): T[] {
      return TableGroupingHelper.getChildren(row);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.isFilterVisible}
     */
    static isFilterVisible(row: TableRow): boolean {
      return TableGroupingHelper.isFilterVisible(row);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.isParentExpanded}
     */
    static isParentExpanded(row: TableRow): boolean {
      return TableGroupingHelper.isParentExpanded(row);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.sortingDataAccessor}
     */
    static sortingDataAccessor<D extends TableRow>(data: D, sortHeaderId: string, direction: SortDirection,
      dataAccessor: (data) => string): string {
      return TableGroupingHelper.sortingDataAccessor(data, sortHeaderId, direction, dataAccessor);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.sortingDataAccessorToString}
     */
    static sortingDataAccessorToString(value, column: TableControllerTypes.ColumnMenuItem): string {
      return TableGroupingHelper.sortingDataAccessorToString(value, column);
    }

    /**
     * @deprecated replace with {@link TableGroupingHelper.updateSelectionStatus}
     */
    static updateSelectionStatus<T extends TableRow>(rows: T[], selection: SelectionModel<T>): void {
      TableGroupingHelper.updateSelectionStatus(rows, selection);
    }

  }

}
