import { ReportRow, ReportRowStatistics } from '../../../../core/report/report.types';
import { AnyObject } from '../../../../core/core.types';
import { Statistics } from '../report-generator/status-statistics/status-statistics.types';
import { DisplayStatusColors, DisplayStatusHelper } from '../../../../core/display-status-helper';
import { CourseStatusHelper } from '../../../../core/course-status.helper';


export interface GroupingCoordinate {
  columnId: string;
  value: any;
}

export interface GroupingWrapper {
  depth: number;
  tableGrouping: ReportRowStatistics;
  treeCoordinates: GroupingCoordinate[];
}

export class ReportGeneratorV2Helper {

  /**
   * If no columnIds are given, null is returned.<br/>
   * If the rows are empty, an empty array is returned.<br/>
   * Otherwise the tree of statistics are calculated.
   */
  static asGroupedData(rows: ReportRow[] | null, columnIds: string[] | null): ReportRowStatistics[] | null {

    if ( !(columnIds?.length > 0) ) {
      return null;
    }

    if ( !(rows?.length > 0) ) {
      return [];
    }

    const maxDepthGroupings = this.createMaxDepthGroupings(rows, columnIds);
    const groupings = ReportGeneratorV2Helper.calculateParentGroupings(maxDepthGroupings, columnIds.length);

    return Object.values(groupings)
      .map(grouping => grouping.tableGrouping);
  }

  static calculateStatistics(
    rows: ReportRow[] | null,
    statistics: Statistics = ReportGeneratorV2Helper.createEmptyStatistics(),
  ): Statistics {

    (rows ?? []).map(row => {

      // add statistics for data row / child
      const status = DisplayStatusHelper.toColor(row.accountDisplayStatus ??
        CourseStatusHelper.toDisplayStatus(row.courseStatus));

      statistics.grandTotalCount++;
      statistics.totalCount++;
      switch ( status ?? DisplayStatusColors.none ) {

        case DisplayStatusColors.red_green:
          statistics.recertificationCount++;
          break;

        case DisplayStatusColors.red:
          statistics.redCount++;
          break;

        case DisplayStatusColors.yellow:
          statistics.yellowCount++;
          break;

        case DisplayStatusColors.green:
          statistics.greenCount++;
          break;

        case DisplayStatusColors.none:
        default:
          statistics.otherCount++;
          break;
      }
    });

    return statistics;
  }

  static createEmptyStatistics(statistics: Statistics = {
    grandTotalCount: 0,
    totalCount: 0,
    greenCount: 0,
    otherCount: 0,
    recertificationCount: 0,
    redCount: 0,
    yellowCount: 0,
  }): Statistics {
    return statistics;
  }

  private static calculateParentGroupings(
    groupings: AnyObject<GroupingWrapper>,
    depth: number,
  ): AnyObject<GroupingWrapper> {

    if ( !(depth > 1) ) {
      // terminate at depth 1
      return groupings;
    }

    const groupingsAtDepth = Object.values(groupings)
      .reduce((pV, grouping) => {

        const coordinatesAtDepth = grouping.treeCoordinates.slice(0, depth - 1);

        const wrapper = ReportGeneratorV2Helper.getWrapper(pV, coordinatesAtDepth);
        const tableGrouping = wrapper.tableGrouping;

        const reportRow = grouping.tableGrouping as ReportRow;
        tableGrouping.data.push(reportRow);

        // accumulate statistics from parents
        const targetStatistics = tableGrouping.statistics;
        const sourceStatistics = grouping.tableGrouping.statistics;
        Object.keys(targetStatistics)
          .map(attr => targetStatistics[attr] += sourceStatistics[attr] ?? 0);

        return pV;
      }, {});

    return ReportGeneratorV2Helper.calculateParentGroupings(groupingsAtDepth, depth - 1);
  }

  private static createMaxDepthGroupings(
    rows: ReportRow[],
    columnIds: string[],
  ): AnyObject<GroupingWrapper> {

    const maxDepthGroupings: AnyObject<GroupingWrapper> = rows.reduce((pV, row) => {
      const coordinates = columnIds
        .map(columnId => ({
          columnId,
          value: row[columnId] ?? null,
        }) as GroupingCoordinate);

      const wrapper = ReportGeneratorV2Helper.getWrapper(pV, coordinates);
      const tableGrouping = wrapper.tableGrouping;
      tableGrouping.data.push(row);

      return pV;
    }, {});

    // update statistics
    Object.values(maxDepthGroupings)
      .map(grouping => {
        const tableGrouping = grouping.tableGrouping;
        const reportRows = tableGrouping.data;
        tableGrouping.statistics = ReportGeneratorV2Helper.calculateStatistics(reportRows);
      });

    return maxDepthGroupings;
  }

  private static createTableGrouping(
    columnId: string,
    value: any,
  ): ReportRowStatistics {

    return {
      data: [],
      column: columnId,
      distinctValue: value,
      distinctValueText: String(value ?? null),
      statistics: {
        grandTotalCount: 0,
        greenCount: 0,
        otherCount: 0,
        recertificationCount: 0,
        redCount: 0,
        totalCount: 0,
        yellowCount: 0,
      },
    };
  }

  private static getWrapper(
    groupings: AnyObject<GroupingWrapper>,
    coordinates: GroupingCoordinate[],
  ) {

    const coordinate = coordinates
      .map(({ columnId, value }) => columnId + '#' + String(value ?? null))
      .join('|');

    let result = groupings[coordinate];

    if ( result == null ) {
      const depth = coordinates.length;
      const currentEntry = coordinates[depth - 1];
      const columnId = currentEntry.columnId;
      const value = currentEntry.value;

      result = groupings[coordinate] = {
        depth,
        tableGrouping: ReportGeneratorV2Helper.createTableGrouping(columnId, value),
        treeCoordinates: coordinates,
      };
    }

    return result;
  }
}
