import {
  NavItem,
  NavItemIcon,
  NavItemMenu,
  NavItemMenuIcon,
  NavItemSpecial,
  NavItemType,
} from '../../core/navigation/navigation.types';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { LoginDialogService } from '../login/login-dialog/login-dialog.service';
import { LogoutService } from '../../core/principal/logout.service';
import { UserNameHelper } from '../../core/user-name.helper';
import { PrincipalService } from '../../core/principal/principal.service';


@Injectable({ providedIn: 'root' })
export class HeaderNavHelper {

  constructor(
    private loginDialogService: LoginDialogService,
    private logoutService: LogoutService,
    private principalService: PrincipalService,
  ) {
  }

  public asButton(navItem: NavItem | null): NavItem | null {
    if ( (navItem?.type !== NavItemType.button) && (navItem?.type !== NavItemType.buttonIcon) ) {
      return null;
    }
    return navItem;
  }

  public asButtonIcon(navItem: NavItem | null): NavItemIcon | null {
    if ( navItem?.type !== NavItemType.buttonIcon ) {
      return null;
    }
    return navItem as NavItemIcon;
  }

  public asLink(navItem: NavItem | null): NavItem | null {
    if ( navItem?.url || ((navItem?.type !== NavItemType.link) && (navItem?.type !== NavItemType.linkIcon)) ) {
      return null;
    }
    return navItem;
  }

  public asLinkHref(navItem: NavItem | null): NavItem | null {
    if ( !navItem?.url || ((navItem?.type !== NavItemType.link) && (navItem?.type !== NavItemType.linkIcon)) ) {
      return null;
    }
    return navItem;
  }

  public asLinkIcon(navItem: NavItem | null): NavItemIcon | null {
    if ( navItem?.type !== NavItemType.linkIcon ) {
      return null;
    }
    return navItem as NavItemIcon;
  }

  public asMenu(navItem: NavItem | null): NavItemMenu | null {
    if ( (navItem?.type !== NavItemType.menu) && (navItem?.type !== NavItemType.menuIcon) ) {
      return null;
    }
    if ( navItem.special === NavItemSpecial.language ) {
      return null;
    }
    return navItem as NavItemMenu;
  }

  public asMenuIcon(navItem: NavItem | null): NavItemMenuIcon | null {
    if ( navItem?.type !== NavItemType.menuIcon ) {
      return null;
    }
    if ( navItem.special === NavItemSpecial.language ) {
      return null;
    }
    return navItem as NavItemMenuIcon;
  }

  public getUserFullName(navItem: NavItem | null): string | null {
    if ( navItem?.special !== NavItemSpecial.profileUserName ) {
      return null;
    }
    const user = this.principalService.currentUser;
    return UserNameHelper.getFullNameWithTitle(user?.firstname, user?.lastname);
  }

  public getLogin(navItem: NavItem | null): string | null {
    if (navItem?.special !== NavItemSpecial.loginName) {
      return null;
    }
    const login = this.principalService.currentUser?.login ?? $localize`:@@global_unknown:Unknown`;
    return $localize`:@@global_registered_as:Registered as: ${login}`;
  }

  public getLoginForSideNav(): string {
    const login = this.principalService.currentUser?.login ?? $localize`:@@global_unknown:Unknown`;
    return  $localize`:@@global_registered_as:Registered as: ${login}`;
  }

  public onNavItemClick(
    navItem: NavItem,
    $event?: MouseEvent,
  ): void {

    if ( navItem?.routerLink != null ) {
      return;
    }

    switch ( navItem?.special ) {

      case NavItemSpecial.login:
        $event?.preventDefault();
        this.loginDialogService.showDialog()
          .pipe(take(1))
          .subscribe();
        return;

      case NavItemSpecial.logout:
        $event?.preventDefault();
        this.logoutService.askLogout();
        return;
    }
  }

}
