<div class="settings-container">
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@global_settings">Settings</span>
    </div>
    <div class="page-header-buttons">
      <button
        type="button"
        mat-flat-button
        color="primary"
        [disabled]="form.invalid || form.pristine"
        (click)="onSaveSettings()"
      >
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </rag-page-header>
  <form [formGroup]="form">
    <mat-checkbox
      formControlName="enabled"
    >
      <span i18n="@@global_enable_gamification">Gamification active</span>
    </mat-checkbox>
  </form>
</div>
