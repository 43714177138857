<button
  *ngIf="permissions?.navAdminTargetGroups"
  #matMenuTrigger="matMenuTrigger"
  type="button"
  mat-menu-item
  [disabled]="disabled || hasNoUsers()"
  [matMenuTriggerFor]="matMenu"
  (menuOpened)="onMenuOpened()"
  (mouseenter)="onMouseEnter(matMenuTrigger)"
>
  <mat-icon svgIcon="account-outline"></mat-icon>
  <span i18n="@@global_target_groups">Target groups</span>

  <mat-menu
    #matMenu
    [xPosition]="xPosition"
  >
    <ng-template matMenuContent>

      <ng-template #tplMenuItems>
        <button
          *ngIf="showButtonNew && permissions.adminTargetGroupCreate"
          type="button"
          mat-menu-item
          (click)="onNewTargetGroup()"
        >
          <mat-icon svgIcon="account-multiple-outline"></mat-icon>
          <span i18n="@@select_target_groups_menu_add_new">Create new group</span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="!(targetGroupsToRemove?.length > 0)"
          [matMenuTriggerFor]="menuGroupsRemove"
        >
          <mat-icon svgIcon="account-minus-outline"></mat-icon>
          <span i18n="@@remove_from_target_group">Remove from group</span>

          <mat-menu #menuGroupsRemove [xPosition]="xPosition">
            <button
              *ngFor="let targetGroup of targetGroupsToRemove"
              type="button"
              (click)="onRemoveFromGroup(targetGroup)"
              class="mat-mdc-menu-item"
            >
              <span>{{targetGroup.name}}</span>
            </button>
          </mat-menu>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="!(targetGroupsToAdd?.length > 0)"
          [matMenuTriggerFor]="menuGroupsAdd"
        >
          <mat-icon svgIcon="account-plus-outline"></mat-icon>
          <span i18n="@@add_to_target_group">Add to group</span>

          <mat-menu #menuGroupsAdd [xPosition]="xPosition">
            <rag-menu-search
              #menuSearch
            ></rag-menu-search>

            <button
              *ngFor="let targetGroup of getFilteredTargetGroups(targetGroupsToAdd, menuSearch.value)"
              type="button"
              mat-menu-item
              (click)="onAddToGroup(targetGroup)"
            >
              <span>{{targetGroup.name}}</span>
            </button>

          </mat-menu>
        </button>

      </ng-template>

      <rag-loading-indicator
        *ngIf="(isLoading || targetGroupData == null); else tplMenuItems"
      ></rag-loading-indicator>

    </ng-template>
  </mat-menu>

</button>
