import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetComponent, WidgetsUUID } from '../widgets.types';
import { WidgetComponent } from '../widgets.module';
import { ReportService } from 'src/app/core/report/report.service';
import { Report } from 'src/app/core/report/report.types';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { ReceivedResponseEvent } from 'src/app/route/user/certificates/certificates.types';

@Component({
  selector: 'rag-report-favorites-widget',
  templateUrl: './report-favorites-widget.component.html',
  styleUrls: [ './report-favorites-widget.component.scss' ],
})
export class ReportFavoritesWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent, OnInit {

  // todo check if WidgetUUID should be WidgetsUUID.ReportFavouritesWidgetsUUID
  static WidgetUUID = WidgetsUUID.ReportPieChartWidgetUUID;

  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasResponse = false;
  receivedResponse = false;
  reports: Report[];
  shouldDisplaySpinner = true;

  constructor(private reportService: ReportService) {
    super();
  }

  isDataDriven() {
    return true;
  }

  ngOnInit() {
    this.reportService.fetchMyReports().subscribe(reports => {
      this.reports = this.reports.filter(report => report.reportConfig.favorite);
      this.setDidReceiveResponse({ hasContent: this.reports.length > 0 });
    });
  }

  setDidReceiveResponse(receivedResponse: ReceivedResponseEvent) {
    this.shouldDisplaySpinner = false;
    this.hasResponse = true;
    this.didReceiveResponse(receivedResponse);
  }

}
