<div class="container">
  <rag-page-header>
    <div class="page-header-title" i18n="@@header_nav_report_generator">Report generator</div>
  </rag-page-header>

  <rag-report-nav
    [reports]="reports"
  ></rag-report-nav>

  <rag-report-table
  ></rag-report-table>
</div>
