<mat-sidenav-container autosize (backdropClick)="toggleSideNav()">
  <mat-sidenav [opened]="sidenavVisible$ | async" [mode]="!isGtMedium ? 'over' : 'side'" [autoFocus]="false" class="sidenav">
    <div class="height-100">
<!--      <button mat-button type="button" *ngIf="!isGtMedium && menuItemsStack?.length > 1" (click)="showLastMenu()">-->
<!--        <mat-icon svgIcon="chevron-left"></mat-icon>-->
<!--        <span i18n="@@global_back">Back</span>-->
<!--      </button>-->
<!--      <mat-divider *ngIf="!isGtMedium && menuItemsStack?.length > 1"></mat-divider>-->
      <nav
        class="nav nav-flex-container"
        rag-main-navigation
        [navItems]="isSmall? menuItemsStack[menuItemsStack.length - 1] : mainItems"
        [layer]="isSmall ? 1 : 0"
        [showContentSearch]="isSmall && isLoggedIn"
        [topItems]="topItems"
        [isSmall]="isSmall"
        [backButtonVisible]="isSmall && menuItemsStack?.length > 1"
        [showUsername]="isGtMedium || menuItemsStack?.length === 1"
      ></nav>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
<!--    <code>'{{menuItemsStack | json}}'</code>-->
    <mat-sidenav-container id="sidenavContainer" class="inner-container" autosize (backdropClick)="toggleSideNav()">
      <mat-sidenav *ngIf="isGtMedium" [opened]="menuItemsStack.length > 1" mode="over" [autoFocus]="false" class="sidenav" (closed)="removeActiveMenuItem()">
        <div>
          <button mat-button type="button" *ngIf="menuItemsStack.length > 2" (click)="showLastMenu()">
            <mat-icon svgIcon="chevron-left"></mat-icon>
            <span i18n="@@global_back">Back</span>
          </button>
          <mat-divider *ngIf="menuItemsStack.length > 2"></mat-divider>
          <nav class="nav" rag-main-navigation [navItems]="menuItemsStack[menuItemsStack.length - 1]"></nav>
        </div>
      </mat-sidenav>
      <mat-sidenav-content class="inner-content">
        <div *ngIf="(sidenavVisible$ | async) === false && isGtMedium && (viewModeDefault$ | async) && isLoggedIn" class="icon-sidenav">
          <nav
            class="nav-flex-container"
            *ngIf="mainItems"
            rag-main-navigation
            [navItems]="!isGtMedium ? menuItemsStack[menuItemsStack.length - 1] : mainItems"
            [layer]="!isGtMedium ? 1 : 0"
            [onlyIcons]="true"
          ></nav>
        </div>
        <div class="background-container">
          <div class="app-content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>
