import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControllingCurriculumComponent } from './controlling-curriculum.component';
import { TableColumnMenuModule } from '../../table/table-column-menu/table-column-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../core/pipes/pipes.module';
import {
  TablesColumnRenderDefaultModule
} from '../../table/tables-column-render-default/tables-column-render-default.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {
  TableHeaderFilterDefaultModule
} from '../../table/table-header-filter-default/table-header-filter-default.module';
import { PivotFilterModule } from '../../pivot-filter/pivot-filter.module';
import { MatButtonModule } from '@angular/material/button';
import {
  CtrlSingleUserDetailsLearningDataDialogModule
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-learning-data-dialog/ctrl-single-user-details-learning-data-dialog.module';
import {
  LearningSessionDataDialogComponent
} from '../../learning-session-data-dialog/learning-session-data-dialog.component';
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component';
import { StickyScrollComponent } from '../../sticky-scroll/sticky-scroll.component';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [
    ControllingCurriculumComponent,
  ],
  exports: [
    ControllingCurriculumComponent,
  ],
  imports: [
    CommonModule,
    TableColumnMenuModule,
    MatMenuModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    PipesModule,
    TablesColumnRenderDefaultModule,
    MatCheckboxModule,
    StickyScrollComponent,
    MatTableModule,
    MatSortModule,
    LoadingIndicatorComponent,
    TableHeaderFilterDefaultModule,
    PivotFilterModule,
    LearningSessionDataDialogComponent,
    CtrlSingleUserDetailsLearningDataDialogModule,
    MatDividerModule,
  ],
})
export class ControllingCurriculumModule {
}
