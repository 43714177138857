<form (submit)="searchContents()" class="search">
  <mat-form-field class="rag-search" appearance="fill">

    <input #inputElement (focus)="fetchContents()" [formControl]="formControl"
           [matAutocomplete]="autocomplete"
           matInput
           name="content-search"
           placeholder="Search"
           i18n-placeholder="@@global_search"
           type="text"
           class="autocomplete-input"
    />
    <button mat-icon-button matSuffix>
      <mat-icon svgIcon="magnify"></mat-icon>
    </button>
    <mat-autocomplete #autocomplete="matAutocomplete" (opened)="fetchContents()">
      <mat-option *ngIf="loading" value="">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-option>
      <ng-container *ngIf="(filteredOptions$ | async) as filteredOptions">
        <ng-template #emptyResult>
          <mat-option class="hint-empty" value="">
            <span *ngIf="!loading && (empty$ | async)" i18n="@@header_top_search_empty">
              You are not assigned any courses.
            </span>
            <span
              *ngIf="(loading && empty$ | async) === false"
              i18n="@@header_top_search_no_results">There are no contents matching your query</span>
          </mat-option>
        </ng-template>
        <ng-container *ngIf="filteredOptions.length; else emptyResult">
          <mat-option
            (onSelectionChange)="navigateToContent(option)"
            *ngFor="let option of filteredOptions"
            [disabled]="isDisabled(option)"
            [value]="option.title">

            <div *ngIf="option.$view && option.$view.parent && option.$view.parent.title"
                 class="parent-title">{{option.$view.parent.title}}</div>
            <div class="content-wrapper" ngPreserveWhitespaces>
              <rag-item-type-icon [content]="option"></rag-item-type-icon>
              <span class="content-title">{{option.title}}</span>
            </div>
          </mat-option>
        </ng-container>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>

