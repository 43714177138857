import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsageDetails, UsageDetailsWithUrl, UsageDialogData } from '../admin-signatures.types';
import { AdminNotificationsHelper } from '../../admin-event-actions/admin-notifications/admin-notifications.helper';
import { AdminNotificationRoutes } from '../../admin-event-actions/admin-notifications/admin-notifications-routing.helper';
import { Core } from '../../../../core/core.types';
import { isNothing } from '../../../../core/utils';

@Component({
  selector: 'rag-admin-signatures-usage-dialog',
  templateUrl: './admin-signatures-usage-dialog.component.html',
  styleUrls: [ './admin-signatures-usage-dialog.component.scss' ],
})
export class AdminSignaturesUsageDialogComponent implements OnInit {

  iterableData: UsageDetailsWithUrl[] = [];
  dialogText = $localize`:@@admin_signatures_usage_dialog_text:Signature is used in`;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UsageDialogData,
  ) {
  }

  ngOnInit(): void {
    if ( this.data.isTextMacro ) {
      this.dialogText = $localize`:@@admin_text_macros_usage_dialog_text:Text macro is used in`;
    }
    this.iterableData = this.data.usage
      .map(usage => {
        const url = this.getDetailsLink(usage);
        return {
          ...usage,
          url
        };
      });
  }

  getDetailsLink(item: UsageDetails): string {
    // todo replace with item.isUsedInNoti
    if ( !isNothing(item.eventId) &&
      !isNothing(item.urlCall) &&
      !isNothing(item.eventActionID)
    ) {
      const messageOrUrlCall = item.urlCall ? 'urlCall' : 'message';
      const targetType = AdminNotificationsHelper.getDistTypeByEventId(item.eventId);
      const redirectUrl = AdminNotificationRoutes.AdminNotificationDetails
        .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
        .replace(/{actionId}/gi, String(item.eventActionID));

      if ( !(item.targetId > 0) ) {
        return redirectUrl
          .replace(/{targetType}/gi, 'user')
          .replace(/{targetId}\//gi, '');
      } else {
        // return AdminNotificationRoutes.AdminNotificationDetails
        return redirectUrl
          .replace(/{targetType}/gi, targetType)
          .replace(/{targetId}/gi, String(item.targetId));
      }
    } else if(!isNothing(item.targetType) && !isNothing(item.courseType)) {
      switch ( item.targetType ) {

        case Core.DistributableType.lms_course:
          switch ( item.courseType ) {
            case Core.CourseType.ToDo:
              return `/admin/courses/todo/${item.targetId}`;
            case Core.CourseType.Link:
              return `/admin/courses/link/${item.targetId}/details`;
            case Core.CourseType.ScoDocument:
              return `/admin/courses/document/${item.targetId}/details`;
            case Core.CourseType.Learning:
              return `/admin/courses/learning/${item.targetId}/details`;
            case Core.CourseType.Test:
              return `/admin/courses/test/${item.targetId}/details`;
            default:
              return null;
          }

        case Core.DistributableType.lms_curriculum:
          // todo add correct url if curriculum admin is done
          // return `/admin/curriculum/edit/${item.targetId}/meta`;
          return null;

        case Core.DistributableType.lms_offlineCnt:
          return `/admin/offline/content/details/${item.targetId}/meta`;
      }
    }
  }
}
