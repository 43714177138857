import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../core/api.urls';
import { Observable } from 'rxjs';
import { TextMacro, TextMacroDetailsResponse, TextMacroResponse, TextMacroSaveData } from './admin-text-macros.types';
import { ApiResponse } from '../../../core/global.types';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AdminTextMacrosService {
  constructor(
    private http: HttpClient,
  ) {
  }

  createNewTextMacro(): TextMacro {
    return {
      id: 0,
      content: '',
      macro: '',
      name: '',
      type: 'all',
      creationDate: 0,
      description: '',
    } as TextMacro;
  }

  checkTextMacroExists(macro: string): Observable<boolean> {
    const url = ApiUrls.getKey('AdminCheckTextMacroExists')
      .replace(/{textMacro}/gi, encodeURIComponent(macro));

    return this.http.get<ApiResponse<boolean>>(url)
      .pipe(map(response => response.exists));
  }

  deleteTextMacro(textMacroId: number): Observable<void> {
    const url = ApiUrls.getKey('AdminDeleteTextMacro')
      .replace(/{textMacroId}/gi, String(textMacroId));

    return this.http.delete(url)
      .pipe(map(() => void(0)));
  }

  getTextMacroDetails(textMacroId: number): Observable<TextMacroDetailsResponse> {
    const url = ApiUrls.getKey('AdminGetTextMacroDetails')
      .replace(/{textMacroId}/gi, String(textMacroId));

    return this.http.get<TextMacroDetailsResponse>(url);
  }

  getTextMacrosForType(types: string[]): Observable<TextMacro[]> {
    const url = ApiUrls.getKey('AdminGetAllTextMacros');

    return this.http.post<ApiResponse<TextMacro[]>>(url, types)
      .pipe(map(response => response.textMacros));
  }

  saveTextMacro(saveData: TextMacroSaveData): Observable<TextMacroDetailsResponse> {
    const url = ApiUrls.getKey('AdminSaveTextMacro');

    return this.http.post<TextMacroDetailsResponse>(url, saveData);
  }
}
