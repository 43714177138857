import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';
import { take } from 'rxjs/operators';
import { CtrlSingleUserCurrIterationStatusDialogService } from '../ctrl-single-user-curr-iteration-status-dialog/ctrl-single-user-curr-iteration-status-dialog.service';
import CertificateIteration = ControllingSingleUserTypes.CertificateIteration;


export interface RecentFailureIterationsDialogParams {
  userId: number;
  curriculumId: number;
  history: ControllingSingleUserTypes.CurriculumHistory;
}

@Component({
  selector: 'rag-recent-failure-iterations-dialog',
  templateUrl: './recent-failure-iterations-dialog.component.html',
  styleUrls: ['./recent-failure-iterations-dialog.component.scss']
})
export class RecentFailureIterationsDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<CertificateIteration> = new MatTableDataSource();
  steeringColumnsToDisplay = ['attempt', 'createdAt', 'attemptedAt', 'endedAt', 'action'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RecentFailureIterationsDialogParams,
    private ctrlSingleUserCurrIterationStatusDialogService: CtrlSingleUserCurrIterationStatusDialogService,
    ) { }

  ngOnInit(): void {
    this.dataSource.data = this.data.history.latest;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onDetails(iteration: CertificateIteration) {

    this.ctrlSingleUserCurrIterationStatusDialogService
      .showDialog(this.data.userId, this.data.curriculumId, iteration.it)

      .pipe(take(1))
      .subscribe();
  }

  isEditDisabled(iteration: CertificateIteration) {
    return iteration.end != null;
  }

}
