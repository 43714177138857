import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { ApiResponse, ExternalLogin, HttpRequestOptions } from '../global.types';
import { AdobeConnect } from './adobe-connect.types';

@Injectable({
  providedIn: 'root',
})
export class AdobeConnectService {

  constructor(
    private http: HttpClient,
  ) {
  }

  /**
   * Fetch common info for this adobe connect server.
   * @param url adobe connect server
   * @param domain domain (optional)
   * @param session web session id (optional)
   */
  commomInfo(url: string, domain?: string, session?: string): Observable<AdobeConnect.CommonInfo> {
    const apiUrl = ApiUrls.getKey('AC') + '/common-info';
    return this.http.post<ApiResponse<AdobeConnect.CommonInfo>>(apiUrl, JSON.stringify({
      url,
      domain,
      session,
    }), HttpRequestOptions).pipe(map(response => response.commonInfo));
  }

  /**
   * Log administrator in and fetch web session ID.
   *
   * @param url adobe connect server url
   * @param login admin login
   * @param password admin password
   * @param accountId accountId (optional). Usex only for hosted adobe connect servers.
   */
  login(url: string, login: string, password: string, accountId?: number): Observable<string> {
    const apiUrl = ApiUrls.getKey('AC') + '/login';
    const request = {
      url,
      login,
      password,
    };
    if ( accountId ) {
      request['accountId'] = accountId;
    }
    return this.http.post<ApiResponse<string>>(apiUrl, JSON.stringify(request))
      .pipe(map(response => response.session));
  }

  /**
   * Create new empty meeting object
   */
  newMeeting(): AdobeConnect.Meeting {
    return {
      scoId: 0,
      name: '',
      urlPath: '',
      type: 'meeting',
      icon: 'meeting',
      folderId: 0,
      expired: false,
      permission: null,
      duration: '',
      dateEnd: '',
      dateBegin: '',
      activeParticipants: 0,
    };
  }

  /**
   * Create or update Sco object in adobe connect server.
   * @param room Sco object (meeting room) to be saved. If scoId is not 0 a new sco object is created,
   * otherwhise an sco object with this id is updated.
   */
  saveMeeting(extLogin: ExternalLogin, room: AdobeConnect.Meeting, folder?: string): Observable<AdobeConnect.Sco> {
    let apiUrl = ApiUrls.getKey('AC');
    if ( folder == null ) {
      apiUrl += '/sco-update';
    } else {
      apiUrl += `/meetings/${folder}/sco-update`;
    }

    const payload = { ...extLogin, meeting: room };

    return this.http
      .post<ApiResponse<AdobeConnect.Sco>>(apiUrl, JSON.stringify(payload), HttpRequestOptions)
      .pipe(map(response => response.sco));
  }

  reportBulkObjects(extLogin: ExternalLogin): Observable<Array<AdobeConnect.BulkObjectRow>> {

    const apiUrl = ApiUrls.getKey('AC') + '/report-bulk-objects';
    return this.http
      .post<ApiResponse<Array<AdobeConnect.BulkObjectRow>>>(apiUrl, JSON.stringify(extLogin), HttpRequestOptions)
      .pipe(map(response => response.reportBulkObjects));
  }
}
