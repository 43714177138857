import { EventEmitter, Injectable } from '@angular/core';
import { WidgetReloadEvent } from './widgets.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {

  private _widgetReloadEvent: EventEmitter<WidgetReloadEvent>;

  constructor() {
    this._widgetReloadEvent = new EventEmitter();
  }

  get widgetReload(): Observable<WidgetReloadEvent> {
    return this._widgetReloadEvent.asObservable();
  }

  reload(widgetUUID: string) {
    this._widgetReloadEvent.emit({
      widgetUUID,
    });
  }
}
