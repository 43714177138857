import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SignatureDetailsResolverResponse } from '../admin-signatures.types';
import { AdminSignaturesService } from '../admin-signatures.service';

@Injectable({ providedIn: 'root' })
export class AdminSignaturesDetailsResolver
  implements Resolve<SignatureDetailsResolverResponse> {

  constructor(
    private adminSignaturesService: AdminSignaturesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SignatureDetailsResolverResponse> {
    const signatureId = Number(route.paramMap.get('signatureId'));
    if (!(signatureId > 0)) {
      return of({
        signature: this.adminSignaturesService.createSignatureDetails(),
        usage: [],
      });
    } else {
        return this.adminSignaturesService.getSignatureDetailsV2(signatureId);
    }
  }

}
