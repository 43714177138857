export enum ViewportStateType {
  Small,
  Medium,
  Large,
  Huge,
}

export class ViewportState {
  public state: ViewportStateType = ViewportStateType.Large;

  public isGtEqMedium(): boolean {
    return this.isMedium() || this.isGtMedium();
  }

  public isGtMedium(): boolean {
    return this.isLarge() || this.isHuge();
  }

  public isHuge(): boolean {
    return ViewportStateType.Huge === this.state;
  }

  public isLarge(): boolean {
    return ViewportStateType.Large === this.state;
  }

  public isLtEqMedium(): boolean {
    return this.isMedium() || this.isSmall();
  }

  public isMedium(): boolean {
    return ViewportStateType.Medium === this.state;
  }

  public isSmall(): boolean {
    return ViewportStateType.Small === this.state;
  }

}
