import { Observable, Subscription } from 'rxjs';
import { CachedSubject } from '../cached-subject';
import { OnDestroy } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from './until-destroyed';


/**
 * Create a singleton instance inside a component. Attach the holder to the component by setting {@link component} to
 * this in the constructor.
 * Afterwards you can update {@link value} as often as you wish. {@link value$} will always point to the currently
 * wrapped observable.
 * The class is intended in components with @Input() of type Observable.
 */
export class SubscriptionHolder<T> {

  readonly value$: Observable<T>;
  private _observable: Observable<T>;
  private _parent: OnDestroy;
  private _subscription: Subscription;
  private _value$ = new CachedSubject<T>(null);

  constructor(
    parent: OnDestroy,
  ) {
    this.value$ = this._value$.withoutEmptyValues();
    this._parent = parent;
  }

  /**
   * Add observable into SubscriptionHolder.
   * The holder must be attached to a component that implements {@link OnDestroy} first!
   */
  set observable(value: Observable<T> | null) {
    if ( this._observable === value ) {
      // ignore re-setting of identical values
      return;
    }

    // remove old subscriptions
    this.clear();
    if ( value == null ) {
      return;
    }

    this._observable = value;
    this._subscription = value
      // pass values to parent
      .pipe(tap(this._value$.next))
      // pass all errors
      .pipe(catchError(this._value$.nextError))
      .pipe(takeUntilDestroyed(this._parent))
      .subscribe();
  }

  get value(): T {
    return this._value$.value;
  }

  /**
   * Manually inject a value into the {@link value$} Observable.
   */
  set value(value: T) {
    this._value$.next(value);
  }

  /**
   * remove old subscriptions; does nothing if already empty
   */
  private clear(): void {
    const subscription = this._subscription;
    if ( subscription == null ) {
      // nothing to clean up
      return;
    }

    subscription.unsubscribe();

    // reset values
    this._observable = null;
    this._subscription = null;
  }

}
