import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { InfoService } from '../info/info.service';
import { InfoType, MessageKey } from '../info/info.types';
import { LearnerAccountService } from '../learner-account/learner-account.service';
import { LearnerAccountOfflineContentViewV2 } from '../learner-account/learner-account.types';
import { PrincipalService } from '../principal/principal.service';
import { RouteSnapshotHelper } from '../route-snapshot.helper';

export const OfflineContentLearnerResolverService: ResolveFn<LearnerAccountOfflineContentViewV2> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  principalService: PrincipalService = inject(PrincipalService)
) => {
  const invitationKey = route.queryParamMap.get('invKey');

  const contentId = parseInt(route.params['contentId'], 10);
  if ( contentId > 0 ) {
    if (principalService.currentUser != null) {
      const onlyValid = RouteSnapshotHelper.getDataValue(route, 'onlyValid', value => value === true);
      return resolveOfflineContent(contentId, invitationKey, onlyValid);
    }
    return of(void 0);
  }

  const rootCurriculumId = parseInt(route.params['id'], 10);
  const curriculumItemId = parseInt(route.params['itemId'], 10);
  if ( (rootCurriculumId > 0) && (curriculumItemId > 0) ) {
    return resolveCurriculumItem(rootCurriculumId, curriculumItemId, invitationKey);
  }

  return throwError(() => 'content not found!');
}

function resolveCurriculumItem(
  rootCurriculumId: number,
  curriculumItemId: number,
  invitationKey: string,
  learnerAccountService: LearnerAccountService = inject(LearnerAccountService),
  infoService: InfoService = inject(InfoService) ) {

    return learnerAccountService.fetchOfflineContentItem<LearnerAccountOfflineContentViewV2>(
      rootCurriculumId, curriculumItemId, invitationKey)
        .pipe(switchMap(response => {
          if ( response && response.curriculum ) {
            return of(response);
          } else {
            return throwError(() => 'response is missing curriculum!');
          }
        }))
        .pipe(catchError(err => {
          infoService.showSnackbar(MessageKey.OFFLINE_CNT.LEARNER.CONTENT_NOT_EXECUTABLE, InfoType.Warning);
          return throwError(() => err);
        }))
        .pipe(take(1));
}

function resolveOfflineContent(
  contentId: number,
  invitationKey: string,
  onlyValid: boolean,
  infoService: InfoService = inject(InfoService),
  learnerAccountService: LearnerAccountService = inject(LearnerAccountService),
  router: Router = inject(Router) ) {

    return learnerAccountService
      .fetchOfflineContentDirect<LearnerAccountOfflineContentViewV2>(contentId, invitationKey, onlyValid)
      .pipe(switchMap(response => {
        if ( response ) {
          return of(response);
        }

        if ( onlyValid ) {
          // navigate to root, as the content is no longer assigned
          throw new Error(`content:${contentId} not assigned!`);
        }
        return of(null);
      }))
      .pipe(catchError(err => {
        infoService.showSnackbar(MessageKey.OFFLINE_CNT.LEARNER.CONTENT_NOT_EXECUTABLE, InfoType.Warning);
        if (onlyValid) {
          // navigate to root, as the content is no longer assigned
          setTimeout(() => router.navigateByUrl('/'));
          return throwError(() => err);
        }
        return of(null);
      }))
      .pipe(take(1));
}
