import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenceGroupColumnHeaderComponent } from './licence-group-column-header.component';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';



@NgModule({
  declarations: [ LicenceGroupColumnHeaderComponent ],
  exports: [
    LicenceGroupColumnHeaderComponent,
  ],
  imports: [
    CommonModule,
    ClampComponent,
    PipesModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LicenceGroupColumnHeaderModule { }
