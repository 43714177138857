<div class="attachments" (click)="$event.stopPropagation()">
  <ng-container *ngFor="let attachment of attachments">

    <ng-template #tplFileWoLink>
      <a class="attachment">
        <mat-icon
          [svgIcon]="iconForAttachmentType(attachment.fileType || attachment.type)"></mat-icon>
        <span class="file-name">{{attachment.fileName}}</span>
      </a>
    </ng-template>

    <a *ngIf="getAttachmentUrl(attachment) as url; else tplFileWoLink"
       [href]="url | safe:'url'"
       class="attachment"
       target="_blank">
      <mat-icon
        [svgIcon]="iconForAttachmentType(attachment.fileType || attachment.type)"></mat-icon>
      <span class="file-name">{{attachment.fileName}}</span>
    </a>

  </ng-container>
</div>
