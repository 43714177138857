import { NotificationTableData } from './admin-notifications.types';
import { Core, NumberedAnyObject } from '../../../../core/core.types';
import { DateHelper } from '../../../../core/date.helper';
import * as moment from 'moment/moment';
import {
  CONTENT_NOTIFICATIONS_TEXTS,
  NotificationAddType
} from '../../admin-offline/components/content-notifications/content-notifications.types';

export class AdminNotificationsHelper {

  static asTableRows(data: NotificationTableData[] | null): NotificationTableData[] {
    return (data ?? [])
      .map(item => {
        item.activeHint = AdminNotificationsHelper.getEnabledTooltip(item);
        item.evtMod = AdminNotificationsHelper.convertEventModuleIdToEventModule(item);
        item.eventTitle = AdminNotificationsHelper
          .getEventTitle(item.evtModId, item.eventId, item.targetSubType, item.eventTitle);
        item.targetGroups = AdminNotificationsHelper.getTargetGroupNames(item.tgIDs, item.tgNames);
        item.excludedTargetGroups = AdminNotificationsHelper.getTargetGroupNames(item.exTGs, item.exTGNames);
        return item;
      });
  }

  static getEnabledTooltip(data: NotificationTableData): string | null {
    if (data?.enabled !== 'true') {
      return null;
    }

    const activationDate = data?.enabledStartDate;
    const deactivationDate = data?.enabledEndDate;
    if (activationDate == null && deactivationDate == null) {
      return null;
    }

    const activationDateMoment = DateHelper.toMoment(activationDate);
    const deactivationDateMoment = DateHelper.toMoment(deactivationDate);

    let deactivatedText = null;
    let dateSpanText = null;

    if (moment().isAfter(deactivationDateMoment) ||
      moment().isBefore(activationDateMoment)) {
      deactivatedText = $localize`:@@global_deactivated_through_date:Deactivated through date restriction.` + '\n';
    }

    if (activationDateMoment != null && deactivationDateMoment != null) {
      dateSpanText = $localize`:@@global_active_from_until:Active from ${activationDate} until ${deactivationDate}.`;
    } else if (activationDateMoment != null) {
      dateSpanText = $localize`:@@global_active_from:Active from ${activationDate}.`;
    } else if (deactivationDateMoment != null) {
      dateSpanText = $localize`:@@global_active_until:Active until ${deactivationDate}.`;
    }

    if (deactivatedText != null) {
      return deactivatedText + dateSpanText;
    }

    return dateSpanText;
  }

  static convertEventModuleIdToEventModule(entry: NotificationTableData | number): string {
    const eventModuleId = typeof(entry) === 'number' ? entry : Number(entry.eventId.substring(0, 2));
    switch (eventModuleId) {
      case 10:
        return 'user';
      case 11:
        return 'course';
      case 115:
        return 'document';
      case 14:
      case 141:
        return 'curriculum';
      case 15:
      case 156:
        return 'offlineContent';
      default:
        return 'other';
    }
  }

  static getDistTypeByModuleId(moduleId: number): Core.DistributableType | string {
    switch (moduleId) {
      case 10:
        return 'user';
      case 11:
      case 112:
      case 113:
      case 115:
        return Core.DistributableType.lms_course;
      case 14:
      case 141:
        return Core.DistributableType.lms_curriculum;
      case 15:
      case 156:
        return Core.DistributableType.lms_offlineCnt;
    }
  }

  static getEventModuleIForEventId(eventId: number | null): number {
    if (eventId == null) {
      return 0;
    }
    const eventIdStr = String(eventId);
    const moduleIdStrFirst2 = eventIdStr.substring(0, 2);
    const moduleIdStr = eventIdStr.substring(0, 3);

    if (moduleIdStr.endsWith('0')) {
      return parseInt(moduleIdStrFirst2, 10);
    }
    return parseInt(moduleIdStr, 10);
  }

  static getDistTypeByEventId(eventId: number): Core.DistributableType | string {
    return this.getDistTypeByModuleId(this.getEventModuleIForEventId(eventId));
  }

  static getEventTitle(
    evtModId: string, evtId: string, targetSubType: string, fallbackTitle: string,
  ): string {
    const eventId = parseInt(evtId, 10);
    const moduleId = parseInt(evtModId, 10);
    const module: NotificationAddType[] = CONTENT_NOTIFICATIONS_TEXTS[moduleId];
    const event = module
      ?.find(o => (o.moduleId === moduleId) && (o.eventId === eventId));
    return (event?.nameOverride?.[targetSubType] ?? event?.name) ?? fallbackTitle;
  }

  static getTargetGroupNames(targetGroupIdsString: string, targetGroupMap: NumberedAnyObject<string>): string {
    const splittedTargetGroups = targetGroupIdsString.split(',');
    if (splittedTargetGroups.length === 0 || targetGroupMap == null) {
      return '';
    }
    let allTargetGroupName = '';
    const targetGroupIds = splittedTargetGroups
      .map(id => Number(id));
    targetGroupIds
      .forEach((id, index) => {
        const targetGroupName = targetGroupMap[id] ?? String(id);
        allTargetGroupName += targetGroupName;
        if (index !== (targetGroupIds.length - 1)) {
          allTargetGroupName += ',<br>';
        }
      });
    return allTargetGroupName;
  }

}
