import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'rag-login-preview',
  templateUrl: './login-preview.component.html',
  styleUrls: ['./login-preview.component.scss']
})
export class LoginPreviewComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}
}
