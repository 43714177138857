import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonAddToCatalogComponent } from './button-add-to-catalog/button-add-to-catalog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ButtonCatalogComponent } from './button-catalog/button-catalog.component';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonEventCopyUrlComponent } from './button-event-copy-url/button-event-copy-url.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';


@NgModule({
  declarations: [ ButtonAddToCatalogComponent, ButtonCatalogComponent, ButtonEventCopyUrlComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    LoadingIndicatorComponent,
    PipesModule,
    ClipboardModule
  ],
  exports: [
    ButtonAddToCatalogComponent,
    ButtonCatalogComponent,
    ButtonEventCopyUrlComponent
  ],
})
export class AdminComponentsModule {
}
