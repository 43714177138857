import { Component, OnInit } from '@angular/core';
import { ApiUrls } from 'src/app/core/api.urls';

@Component({
  selector: 'rag-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: [ './media-library.component.scss' ],
})
export class MediaLibraryComponent
  implements OnInit {

  url = ApiUrls.getKey('ClassicFrontEndDocLib');

  constructor() {
  }

  ngOnInit() {
  }

}
