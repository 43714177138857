import { Component, OnInit } from '@angular/core';
import { TableControllerComponent } from '../../../../../component/table/table-controller/table-controller.component';
import {
  NotificationEventChangeDialogData,
  NotificationEventChangeDialogResponse,
  NotificationEventChangeSaveData,
  NotificationIdentifiable,
  NotificationTableData,
  NotificationTableFilterType,
} from '../admin-notifications.types';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { ADMIN_NOTIFICATIONS_LIST_COLUMNS } from './admin-notifications-list.columns';
import { EhsService } from '../../../../../core/ehs/ehs.service';
import { InfoService } from '../../../../../core/info/info.service';
import { GenericMessageDialogComponent } from '../../../../../component/generic-message-dialog/generic-message-dialog.component';
import { CancelButton, InfoType, MessageConstants, YesButton } from '../../../../../core/info/info.types';
import { AdminNotificationsEventChangeDialogComponent } from '../admin-notifications-event-change-dialog/admin-notifications-event-change-dialog.component';
import { AdminNotificationsService } from '../admin-notifications.service';
import { Core } from '../../../../../core/core.types';
import { combineLatest, Observable } from 'rxjs';
import { AdminNotificationsHelper } from '../admin-notifications.helper';
import { getNotificationNameById } from '../../../admin-offline/components/content-notifications/content-notifications.types';
import { AdminNotificationRoutes } from '../admin-notifications-routing.helper';
import { TableAccessors } from '../../../../../component/table/table.accessors';
import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';
import { DateHelper } from '../../../../../core/date.helper';
import * as moment from 'moment';
import { JournalService } from 'src/app/component/journal/journal.service';
import { EntityType } from 'src/app/core/journal/journal.types';
import { CachedSubject } from 'src/app/core/cached-subject';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';

@Component({
  selector: 'rag-admin-notifications-list',
  templateUrl: './admin-notifications-list.component.html',
  styleUrls: [ './admin-notifications-list.component.scss' ],
})
export class AdminNotificationsListComponent
  extends TableControllerComponent<NotificationTableData>
  implements OnInit {

  notifications: NotificationTableData[];
  isRotated = false;
  filterType: NotificationTableFilterType;
  pageTitle: string;
  detailsUrl: string;
  defaultTemplateDetailsUrl: string;
  infoUrl: string;
  emptyPartialEventAction: Partial<NotificationTableData> = {
    eventId: null,
    targetId: '0',
  };
  historyButtonDisabled$: Observable<boolean>;

  private _historyButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private adminNotificationsService: AdminNotificationsService,
    private infoService: InfoService,
    private ehsService: EhsService,
    private route: ActivatedRoute,
    private router: Router,
    private journalService: JournalService,
    private principalService: PrincipalService,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);
    this.detailsUrl = AdminNotificationRoutes.AdminNotificationDetails;
    this.infoUrl = AdminNotificationRoutes.AdminNotificationInfo;
    this.defaultTemplateDetailsUrl = AdminNotificationRoutes.AdminNotificationDefaultTemplateDetails;
    this.historyButtonDisabled$ = this._historyButtonDisabled$.asObservable();
  }

  getDisplayValue(row, column: TableControllerTypes.ColumnMenuItem<NotificationTableData>): string {
    return TableAccessors.getDisplayValue(row, column?.options, column?.id);
  }

  getStatusIcon(eventAction: NotificationTableData): string {
    if ( eventAction.enabled === 'true' ) {
      return 'close-circle-outline';
    }
    return 'checkbox-marked-circle-outline';
  }

  getStatusText(eventAction: NotificationTableData): string {
    if ( eventAction.enabled === 'true' ) {
      return $localize`:@@global_deactivate:Deactivate`;
    }
    return $localize`:@@global_activate:Activate`;
  }

  onAddNewDefaultTemplate(): void {
    this.onOpenNotificationEventChangeDialog(this.emptyPartialEventAction, true, true)
      .pipe(takeWhile(response => response != null))
      .pipe(switchMap(data => {
        const contentType = data.eventId !== undefined ?
          AdminNotificationsHelper.getDistTypeByEventId(data.eventId) :
          AdminNotificationsHelper.getDistTypeByModuleId(data.eventModuleId);
        const url = this.defaultTemplateDetailsUrl
          .replace(/{contentType}/gi, String(contentType))
          .replace(/{actionId}/gi, '0');
        const queryParams = {
          eventId: data.eventId,
          moduleId: data.eventModuleId,
        };
        const urlTree = this.router.createUrlTree([url], {
          queryParams
        });
        return this.router.navigateByUrl(urlTree);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onAddNewEventAction(createNotification: boolean): void {

    this.onOpenNotificationEventChangeDialog(this.emptyPartialEventAction, true, false)
      .pipe(takeWhile(response => response != null))
      .pipe(switchMap(data => {
        let url = this.detailsUrl;
        const messageOrUrlCall = createNotification ? 'message' : 'urlCall';
        let targetId = data.subTargetId ? data.subTargetId : data.targetId;
        if (targetId != null) {
          targetId = Math.abs(targetId);
          const contentType = AdminNotificationsHelper.getDistTypeByEventId(data.eventId);
          url = url
            .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
            .replace(/{targetType}/gi, String(contentType))
            .replace(/{targetId}/gi, String(targetId))
            .replace(/{actionId}/gi, '0');
        } else {
          url = url
            .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
            .replace(/{targetType}/gi, 'user')
            .replace(/{targetId}\//gi, '')
            .replace(/{actionId}/gi, '0');
        }
        const queryParams = {
          eventId: data.eventId,
          moduleId: data.eventModuleId,
        };

        if (data.subTargetId != null) {
          queryParams['parentTargetId'] = data.targetId;
        }
        const urlTree = this.router.createUrlTree([url], {
          queryParams
        });
        return this.router.navigateByUrl(urlTree);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onChangeEventForNotification(eventAction: NotificationTableData, defaultTemplateMode: boolean): void {
    this.onOpenNotificationEventChangeDialog(eventAction, false, defaultTemplateMode)
      .pipe(takeWhile(response => response != null))
      .pipe(switchMap(data => {
          const saveData: NotificationEventChangeSaveData = {
            eventId: data.eventId,
            eventModuleId: data.eventModuleId,
            actionId: Number(eventAction.actionId),
          };
          if (String(data.eventModuleId).substring(0, 2) !== '10' && !defaultTemplateMode) {
            const subTargetId = Number(data.subTargetId);
            const targetId = subTargetId > 0 ? subTargetId : data.targetId;
            saveData.targetId = targetId;

            saveData.parentTargetId = targetId;
            if (data.subTargetId != null) {
              saveData.parentTargetId = data.targetId;
            }
          }
          return this.ehsService.changeEventAction(saveData)
            .pipe(map(() => data));
        }
      ))
      .pipe(tap(data => {
        const index = this.dataSource.data.indexOf(eventAction);
        const tableData = [...this.dataSource.data];
        eventAction.eventId = String(data.eventId);
        eventAction.eventTitle = getNotificationNameById(data.eventId);
        eventAction.evtModId = String(data.eventModuleId);
        eventAction.targetId = String(data.targetId);
        eventAction.onElement = data.targetTitle;
        tableData.splice(index, 1, eventAction);
        this.setTableData(tableData);

        this.infoService
          .showMessage(MessageConstants.API.SUCCESS, {infoType: InfoType.Success});
      }))
      .pipe(catchError(() => this.infoService
        .showMessage(MessageConstants.API.ERROR, {infoType: InfoType.Error})))
      .subscribe();
  }

  onChangeEvent(eventAction: NotificationTableData): void {
    if (eventAction.defaultTemplate === 'true') {
      this.onChangeEventForNotification(eventAction, true);
    } else {
      this.onChangeEventForNotification(eventAction, false);
    }
  }

  onChangeEventActionStatus(eventAction: NotificationTableData): void {
    const shouldBeEnabled = eventAction.enabled === 'false';

    const messageDescription = shouldBeEnabled ?
      $localize`:@@offline_cnt_event_activate:Do you want to activate the notification?` :
      $localize`:@@offline_cnt_event_deactivate:Do you want to deactivate the notification?`;

    this.infoService.showDialog(GenericMessageDialogComponent, {
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
      message: messageDescription,
      buttons: CancelButton | YesButton
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() =>
        this.ehsService.setNotificationStatus(Number(eventAction.actionId), shouldBeEnabled)))
      .pipe(tap(() => {
        eventAction.enabled = String(shouldBeEnabled);
        this.setTableData(this.notifications);

        this.infoService
          .showMessage(MessageConstants.API.SUCCESS, {infoType: InfoType.Success});
      }))
      .pipe(catchError(() =>
        this.infoService
        .showMessage(MessageConstants.ERRORS.GENERAL, {infoType: InfoType.Warning})))
      .subscribe();
  }

  onCopyEventAction(eventAction: NotificationTableData): void {
    const message = eventAction.isNotification ?
      $localize`:@@global_copy_notification:Do you want to copy this notification?` :
      $localize`:@@global_copy_url_call:Do you want to copy this URL-Call?`;
    this.infoService.showDialog(GenericMessageDialogComponent, {
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
      message,
      buttons: CancelButton | YesButton
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => this.ehsService.copyEventAction(Number(eventAction.actionId), Number(eventAction.targetId))))
      .pipe(switchMap(actionID => {
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {infoType: InfoType.Success});
        let url = this.detailsUrl;
        const messageOrUrlCall = eventAction.isNotification ? 'message' : 'urlCall';
        if (eventAction.targetId != null) {
          const contentType = AdminNotificationsHelper.getDistTypeByEventId(Number(eventAction.eventId));
          url = url
            .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
            .replace(/{targetType}/gi, String(contentType))
            .replace(/{targetId}/gi, String(eventAction.targetId))
            .replace(/{actionId}/gi, String(actionID));
        } else {
          url = url
            .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
            .replace(/{targetType}/, 'user')
            .replace(/{targetId}/, '')
            .replace(/{actionId}/gi, String(actionID));
        }
        return this.router.navigateByUrl(url);
      }))
      .pipe(catchError(() => this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, {infoType: InfoType.Error})))
      .subscribe();
  }

  onDeleteEventAction(eventAction: NotificationTableData): void {
    this.ehsService.deleteEhsAction(Number(eventAction.actionId))
      .pipe(tap(() => {
        this.notifications = this.notifications
          .filter(notification => notification.actionId !== eventAction.actionId);
        this.setTableData(this.notifications);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onOpenNotificationEventChangeDialog(
    eventAction: Partial<NotificationTableData>,
    createMode: boolean,
    defaultTemplateMode: boolean
  ) {
    const tasks = [] as Array<Observable<any>>;
    tasks.push(this.adminNotificationsService
      .getAllItemsWithType(Core.DistributableType.lms_course));
    tasks.push(this.adminNotificationsService
      .getAllItemsWithType(Core.DistributableType.lms_curriculum));
    tasks.push(this.adminNotificationsService
      .getAllItemsWithType(Core.DistributableType.lms_offlineCnt));

    const eventActionTargetId = Number(eventAction.targetId);
    const eventActionParentTargetId = Number(eventAction.parentId);
    const targetId = eventActionTargetId > 0 ? eventActionTargetId : null;
    const targetIdForFetch = eventActionParentTargetId > 0 ?
       eventActionParentTargetId : targetId;
    if ((createMode === false) && (targetIdForFetch != null)) {
      tasks.push(this.adminNotificationsService
        .fetchSubTargets(Number(eventAction.evtModId), targetIdForFetch));
    }
    return combineLatest(tasks)
      .pipe(switchMap(([courses, curricula, offlineCnts, subTargetsResponse]) => {
        const allItems: Array<NotificationIdentifiable> = [];
        allItems.push(...courses);
        allItems.push(...curricula);
        allItems.push(...offlineCnts);

        const subTargets = subTargetsResponse != null ? subTargetsResponse : null;
        // const eventActionParentTargetId = Number(eventAction.parentTargetId);
        const parentTargetId = eventActionParentTargetId > 0 ? eventActionParentTargetId : targetId;
        return this.infoService.showDialog<AdminNotificationsEventChangeDialogComponent,
          NotificationEventChangeDialogData,
          NotificationEventChangeDialogResponse>(AdminNotificationsEventChangeDialogComponent, {
          eventId: Number(eventAction.eventId) > 0 ? Number(eventAction.eventId) : null,
          currentTargetId: targetId,
          items: allItems,
          createMode,
          subTargets,
          parentTargetId,
          defaultTemplateMode,
        });
      }));
  }

  onJournal(eventAction: NotificationTableData) {
    const eventActionId = parseInt(eventAction.actionId, 10);
    this.journalService
      .displayJournalForObject(EntityType.EVENTACTION, eventActionId, eventAction.title)
      .subscribe();
  }

  isDetailsButtonDisabled(eventAction: NotificationTableData): boolean {
    return eventAction.distType === 'user' || this.isEventActionInvalid(eventAction);
  }

  isEventActionInvalid(eventAction: NotificationTableData): boolean {
    return !(Number(eventAction.eventId) > 0) || !(Number(eventAction.evtModId) > 0);
  }

  getUrlDetails(notification: NotificationTableData): string {
    const distType = AdminNotificationsHelper.getDistTypeByEventId(Number(notification.eventId));

    if (notification.defaultTemplate === 'true') {
      return this.defaultTemplateDetailsUrl
        .replace(/{contentType}/gi, String(distType))
        .replace(/{actionId}/gi, String(notification.actionId));
    }

    const messageOrUrlCall = notification.isNotification ? 'message' : 'urlCall';
    const url = this.detailsUrl;
    if (distType === 'user') {
      return url
        .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
        .replace(/{targetType}/gi, 'user')
        .replace(/{targetId}/gi, '')
        .replace(/{actionId}/gi, String(notification.actionId));

    }
    return url
      .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
      .replace(/{targetType}/gi, String(distType))
      .replace(/{targetId}/gi, String(notification.targetId))
      .replace(/{actionId}/gi, String(notification.actionId));

  }

  getUrlInfo(notification: NotificationTableData): string {
    const distType = AdminNotificationsHelper.getDistTypeByEventId(Number(notification.eventId));
    const messageOrUrlCall = notification.isNotification ? 'message' : 'urlCall';
    let url = this.infoUrl;
    if (distType === 'user') {
      url = url
        .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
        .replace(/{targetType}/gi, 'user')
        .replace(/{targetId}/gi, '')
        .replace(/{actionId}/, String(notification.actionId));

    } else {
      url = url
        .replace(/{messageOrUrlCall}/gi, messageOrUrlCall)
        .replace(/{targetType}/gi, String(distType))
        .replace(/{targetId}/gi, String(notification.targetId))
        .replace(/{actionId}/, String(notification.actionId));
    }

    return url;
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data.notifications, data.filterType)))
      .pipe(take(1))
      .subscribe();
    this.principalService.permissionStates$.pipe(map(permissions => {
      this._historyButtonDisabled$.next(!permissions.journalRead);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  showInfoIcon(data: NotificationTableData): boolean {
    if ( data?.enabled === 'false' ) {
      return false;
    }

    if ( data?.enabledEndDate == null ) {
      return false;
    }

    const enabledEndDateMoment = DateHelper.toMoment(data.enabledEndDate);
    const currentMoment = moment();
    return currentMoment.isAfter(enabledEndDateMoment);
  }

  private updateAvailableColumns(filterType?: 'notification' | 'urlCall') {
    const menuData = TableColumnMenuService.createFromDefaults(ADMIN_NOTIFICATIONS_LIST_COLUMNS);
    const isNotification = menuData.menuItems.isNotification;
    if ( filterType != null ) {
      isNotification.selected = false;
      isNotification.hidden = true;
      isNotification.options.filter.value = (filterType === 'notification');
    }
    this.setMenuData(menuData);
  }

  private getPageTitle(filterType?: 'notification' | 'urlCall'): string {
    if ( filterType === 'notification' ) {
      return $localize`:@@header_top_messages:Messages`;
    } else if ( filterType === 'urlCall' ) {
      return $localize`:@@global_url_call:URL-Call`;
    }
    return $localize`:@@global_notifications:Notifications`;
  }

  private updateRouteData(
    notifications: NotificationTableData[] | null,
    filterType: NotificationTableFilterType | null,
  ): void {
    this.notifications = AdminNotificationsHelper.asTableRows(notifications);
    this.filterType = filterType;
    this.pageTitle = this.getPageTitle(this.filterType);
    this.updateAvailableColumns(this.filterType);
    this.setTableData(this.notifications);
    this.inputDisabled = false;
    this.checkFilter();
  }
}
