<div class="general-design-container">
  <div class="section-wrapper">
    <!-- Powered By -->
    <h2>
      <span i18n="@@footer_conf_set_powered_by">Set Powered By</span>
    </h2>
    <div class="flex">
      <mat-form-field class="small-input-field" appearance="outline">
        <mat-label>
          <span i18n="@@footer_conf_powered_by_label">Powered by</span>
        </mat-label>
        <input (input)="isPoweredByDirty()" [(ngModel)]="pendingPoweredBy" matInput type="text"/>
      </mat-form-field>
    </div>
    <button (click)="postStyleSettings('poweredBy', pendingPoweredBy)" *ngIf="poweredByIsDirty" color="primary"
            mat-flat-button>
      <span i18n="@@footer_conf_save_powered_by_button">Save Powered By</span>
    </button>
  </div>
  <form [formGroup]="formGroup">
    <div class="section-wrapper">
    <!-- Impressum -->
    <h2>
      <span i18n="@@footer_conf_set_legal_notice">Link Legal Notice</span>
    </h2>
    <div class="flex">
      <mat-form-field class="small-input-field" appearance="outline">
        <mat-label>
          <span i18n="@@footer_conf_legal_notice_label">Legal Notice</span>
        </mat-label>
        <input (input)="isLegalNoticeDirty()" [(ngModel)]="pendingLegalNotice" [pattern]="URLregEx"
               i18n-placeholder="@@footer_conf_insert_url_placeholder"
               matInput formControlName="legalNoticeField" placeholder="Insert URL here" type="url"/>
        <mat-error *ngIf="this.formGroup.controls.legalNoticeField.invalid" i18n="@@error_url_pattern">Invalid URL pattern</mat-error>
      </mat-form-field>
    </div>
    <button (click)="postStyleSettings('legalNotice', pendingLegalNotice)" *ngIf="showSaveButton(pendingLegalNotice, legalNoticeIsDirty)" color="primary"
            mat-flat-button>
      <span i18n="@@footer_conf_legal_notice_button">Save Legal Notice</span>
    </button>
  </div>

  <div class="section-wrapper">
    <!-- Terms and Conditions -->
    <h2>
      <span i18n="@@footer_conf_set_terms">Link Terms and Conditions</span>
    </h2>
    <div class="flex">
      <mat-form-field class="small-input-field" appearance="outline">
        <mat-label>
          <span i18n="@@footer_conf_terms_label">Terms</span>
        </mat-label>
        <input (input)="isTermsDirty()" [(ngModel)]="pendingTerms" [pattern]="URLregEx"
               i18n-placeholder="@@footer_conf_insert_url_placeholder"
               matInput formControlName="termsField" placeholder="Insert URL here" type="url"/>
        <mat-error *ngIf="this.formGroup.controls.termsField.invalid" i18n="@@error_url_pattern">Invalid URL pattern</mat-error>
      </mat-form-field>
    </div>
    <button (click)="postStyleSettings('terms', pendingTerms)" *ngIf="showSaveButton(pendingTerms, termsIsDirty)" color="primary"
            mat-flat-button>
      <span i18n="@@footer_conf_terms_button">Save Terms</span>
    </button>
  </div>

  <div class="section-wrapper">
    <!-- Policies -->
    <h2>
      <span i18n="@@footer_conf_set_policies">Link Policies</span>
    </h2>
    <div class="flex">
      <mat-form-field class="small-input-field" appearance="outline">
        <mat-label>
          <span i18n="@@footer_conf_policies_label">Policies</span>
        </mat-label>
        <input (input)="isPoliciesDirty()" [(ngModel)]="pendingPolicies" [pattern]="URLregEx"
               i18n-placeholder="@@footer_conf_insert_url_placeholder"
               matInput formControlName="policiesField" placeholder="Insert URL here" type="url"/>
        <mat-error *ngIf="this.formGroup.controls.policiesField.invalid" i18n="@@error_url_pattern">Invalid URL pattern</mat-error>
      </mat-form-field>
    </div>
    <button (click)="postStyleSettings('policies', pendingPolicies)" *ngIf="showSaveButton(pendingPolicies, policiesIsDirty)" color="primary"
            mat-flat-button>
      <span i18n="@@footer_conf_policies_button">Save Policies</span>
    </button>
  </div>
</form>
</div>
