<!-- Proxy wrapper for all supported widgets -->
<div class="widget-wrapper" id="{{widget.id}}">
  <!-- active buttons -->
  <div class="header">
    <p class="widget-title">
      {{title$ | async}}
    </p>
    <div *ngIf="(styleSettings$ | async) as styleSettings" class="buttons">
      <ng-container *ngIf="menuReferences != null">
        <div [ngClass]="{'readonly': readonly, 'menu-container': true}">
          <ng-container *ngFor="let menuReference of menuReferences">
            <ng-container *ngTemplateOutlet="menuReference"></ng-container>
          </ng-container>
        </div>
      </ng-container>
      <!-- Delete button, visible only in edit mode and when the widget is deleteable -->

      <div (mousedown)="$event.stopPropagation()"
           (touchstart)="$event.stopPropagation()"
           class="delete-button-container">
        <button
          (click)="$event.stopPropagation(); removeItem(widget)"
          *ngIf="toBeRemoved || (edit && isDeletable(styleSettings, widget.widgetUUID))"
          mat-icon-button>

          <mat-icon color="warn" svgIcon="close"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- ADD YOUR WIDGETS HERE -->
  <!-- TODO: fixme -->
  <div [ngClass]="{'readonly': readonly, 'widget-content': true}" [ngSwitch]="widget.widgetUUID">

    <div *ngIf="shouldDisplaySpinner && !hasResponse" class="spinner">
      <div class="spinner-container">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </div>

    <ng-container *ngIf="!toBeRemoved">

      <rag-gamification-widget
        #child
        *ngSwitchCase="gamificationWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-gamification-widget>

      <!-- Code redeem widget -->
      <rag-code-redeem-widget
        #child
        *ngSwitchCase="redeemCodeWidgetUUID"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-code-redeem-widget>

      <!-- ToDo Content Widget-->
      <rag-todo-content-widget
        #child
        *ngSwitchCase="toDoWidgetUUID"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-todo-content-widget>

      <!-- Content Overview Widget -->
      <rag-overview-widget
        #child
        *ngSwitchCase="overviewWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-overview-widget>

      <rag-news-widget
        #child
        *ngSwitchCase="newsWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget"
        [isTextBox]="getSettings(newsWidgetUUID).isTextBox"
        style="display: flex; flex: 1">
      </rag-news-widget>

      <rag-mycertificates-widget
        #child
        *ngSwitchCase="myCertificatesWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-mycertificates-widget>

      <rag-html-widget
        #child
        *ngSwitchCase="htmlWidgetUUID"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-html-widget>

      <rag-specials-widget
        #child
        *ngSwitchCase="specialsWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-specials-widget>

      <rag-report-pie-chart-widget
        #child
        *ngSwitchCase="reportPieChartWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-report-pie-chart-widget>

      <rag-report-favorites-widget
        #child
        *ngSwitchCase="reportFavoriteWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-report-favorites-widget>

      <rag-report-links-widget
        #child
        *ngSwitchCase="reportLinksWidgetUUID"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-report-links-widget>

      <rag-report-bar-chart-widget
        #child
        *ngSwitchCase="reportBarChartWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-report-bar-chart-widget>

      <rag-events-widget
        #child
        *ngSwitchCase="eventsWidgetUUID"
        [didReceiveResponse]="setDidReceiveResponse"
        [edit]="edit"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [settings]="widgetSettings"
        (settingsChange)="onSettingsChange($event)"
        [widget]="widget">
      </rag-events-widget>
    </ng-container>

    <div class="to-be-removed" *ngIf="toBeRemoved">
        <span i18n="@@widget_to_be_removed">This widget is no longer available.</span>
    </div>

  </div>
</div>
