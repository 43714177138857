<div class="site-wrapper" (keyup)="onNavigateToDetails()" tabindex="0" data-cy="cardDetails">

  <div class="card-wrapper" [ngClass]="{'card-wrapper-mobile': !editorMode, 'editor-width': editorMode}">
    <div class="newstyle-card">
      <div class="card-image-wrapper" [ngStyle]="cardBackgroundStyles$ | async">
        <div class="card-button-wrapper">
          <button
            *ngIf="certificate.templateAvailable || certificate.certUploaded"
            (click)="onDownloadClicked()"
            type="button"
            mat-icon-button
            [disabled]="certificate.certBlocked || (!certificate.availableForUser && !certificate.certUploaded)"
            matTooltip="Download"
            i18n-matTooltip="@@download"
          >
            <mat-icon class="downloadIcon" svgIcon="download"></mat-icon>
          </button>

          <button
            (click)="onDetailsClicked()"
            type="button"
            mat-icon-button
          >
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
        </div>

      </div>
      <div class="mat-card-content">
        <div>
          <p
            *ngIf="content?.title | localeText as title"
            class="mat-card-title font-medium letterspacing-min"
            [matTooltipDisabled]="title.length < 80"
            [matTooltip]="title">{{title}}</p>
          <div
            *ngIf="(certificate.validUntil > 0) && (certificate.validUntil < today)"
            class="mat-card-subtitle font-light letterspacing-more">
            <span i18n="@@certificate_Expired">Expired</span>
          </div>
        </div>

        <div class="status-wrapper">
          <div class="validity-wrapper" *ngIf="certificate.validSince || certificate.validUntil">
            <div class="valid-since" *ngIf="certificate.validSince">
              <div class="label" i18n="@@certificate_card_valid_since">Valid since:</div>
              <span class="date">{{certificate.validSince | formatDate}}</span>
            </div>

            <div class="valid-until" *ngIf="certificate.validUntil">
              <div class="label" i18n="@@certificate_card_valid_until">Until:</div>
              <span class="date">{{certificate.validUntil | formatDate}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

