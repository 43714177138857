import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BasisFilter,
  TargetGroupAdminViewResponse,
  TargetGroupCriteria,
  TargetGroupCriteriaResponse,
  TargetGroupsAndUsersResponse,
  TargetGroupUsersResponse,
} from './admin-target-groups.types';
import { ApiUrls } from '../api.urls';
import { ApiResponse, HttpRequestOptions, TrainResponse } from '../global.types';
import { HttpClient } from '@angular/common/http';
import { AnyObject, TargetGroup } from '../core.types';
import { RolesApiResponse } from '../roles.types';
import { ContentDistributions } from '../dist/distributions.types';
import { LanguageHelper } from '../language.helper';
import { ProfileFieldTypes } from '../input/profile-field/profile-field.types';
import { DateHelper } from '../date.helper';
import {
  AdminTargetGroupEdit
} from '../../route/admin/admin-target-groups/admin-target-groups-edit/components/admin-target-groups-edit-criteria/admin-target-groups-edit-criteria.types';
import { UserAttribute, UserAttributeType } from '../admin-user-attribute.types';


const REGEX = /(K[1-9]+)/g;
const conditionAsText = (condition: string): string => {
  switch ( condition ) {
    case '<':
      return $localize`:@@op_less_than:less than`;
    case '<=':
      return $localize`:@@less_than_eq:less than or equal`;
    case '>=':
      return $localize`:@@great_than_eq:great than or equal`;
    case '=':
      return $localize`:@@equal:is equal`;
    case '>':
      return $localize`:@@great_than:great than`;
    case '<>':
      return $localize`:@@not_equal:not equal`;
    case 'contains':
      return $localize`:@@op_contains:contains`;
    case 'notContains':
      return $localize`:@@op_not_contains:not contains`;
    case 'startsWith':
      return $localize`:@@op_starts_with:starts with`;
    case 'endsWith':
      return $localize`:@@op_ends_with:ends with`;
    case 'in':
      return $localize`:@@op_in:in`;
  }
  return condition;
};

@Injectable({
  providedIn: 'root',
})
export class AdminTargetGroupsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public static translateFilter(
    userAttributes: AnyObject<UserAttribute>,
    filter: BasisFilter | null,
    withNewLineAfterCondition = false,
  ): string | null {

    const expression = filter?.criteriaExpression;
    const matches = expression?.match(REGEX);
    if ( matches == null ) {
      return null;
    }

    let configuration = expression;
    for (const match of matches) {
      const criteria = filter.criteria.find(c => c.name === match);
      if ( criteria ) {
        const field = userAttributes[criteria.field];
        const fieldName = field != null ? LanguageHelper.objectToText(field.label) : criteria.field;
        const condition = '&nbsp;<span class="op">' + conditionAsText(criteria.condition) + '</span>&nbsp;';
        const isContainsOp = AdminTargetGroupEdit.isContainOP(criteria.condition);
        let fieldValue = criteria.value;
        if ( field != null ) {
          if ( field.attributeType === UserAttributeType.date ) {
            fieldValue = DateHelper.format(DateHelper.toMoment(fieldValue), 'DD.MM.YYYY');
          } else if ( !isContainsOp && ProfileFieldTypes.isMultiValueType(field) ) {
            let values = fieldValue.split(',');
            values = values.map(v => {
              const optionLabel = field.attributeOptions.find(o => o.optionValue === v);
              if ( optionLabel != null ) {
                return LanguageHelper.objectToText(optionLabel.label);
              }
              // return '🥚';
            });
            // fieldValue = values.toString();
          }
        }
        if (withNewLineAfterCondition) {
          configuration = configuration
            .replace(match, fieldName + ' ' + condition + ' \'' + fieldValue + '\' ')
            .replaceAll(/ or /ig, '&nbsp;<span class="op">' + $localize`:@@global_or:or` + '</span>&nbsp;<br>')
            .replaceAll(/ and /ig, '&nbsp;<span class="op">' + $localize`:@@global_and:and` + '</span>&nbsp;<br>');
        } else {
          configuration = configuration
            .replace(match, fieldName + ' ' + condition + ' \'' + fieldValue + '\' ')
            .replaceAll(/ or /ig, '&nbsp;<span class="op">' + $localize`:@@global_or:or` + '</span>&nbsp;')
            .replaceAll(/ and /ig, '&nbsp;<span class="op">' + $localize`:@@global_and:and` + '</span>&nbsp;');
        }
      }
    }
    return configuration;
  }

  addUsersToTargetGroup(
    targetGroupId: number | null,
    userIds: number[] | null,
  ): Observable<TargetGroupUsersResponse | never> {

    if ( !(targetGroupId > 0) || !(userIds?.length > 0) ) {
      return EMPTY;
    }

    const url = ApiUrls.getKey('UpdateTargetGroupUsers')
      .replace(/{targetGroupId}/gi, String(targetGroupId));
    return this.http.post<TargetGroupUsersResponse>(url, { userIds });
  }

  fetchPreviewResults(targetGroupCriteria: TargetGroupCriteria): Observable<TargetGroupUsersResponse> {
    const url = ApiUrls.getKey('GetTargetGroupUserPreview');
    return this.http
      .post<TargetGroupUsersResponse>(url, targetGroupCriteria, HttpRequestOptions);
  }

  copyTargetGroups(targetGroupId: number, targetGroupName: string): Observable<number> {
    const url = ApiUrls.getKey('AdminCopyTargetGroups')
      .replace(/{tgid}/gi, String(targetGroupId));
    return this.http.post<ApiResponse<number>>(url, {
      nameOfCopy: $localize`:@@global_copy_of_with_name:Copy of ${targetGroupName}`,
    })
      .pipe(map(response => response.newId));
  }

  deleteTargetGroup(targetGroupId: number)  {
    const url = ApiUrls.getKey('AdminDeleteTargetGroups')
      .replace(/{tgid}/gi, String(targetGroupId));
    return this.http.delete<TrainResponse>(url);
  }

  getTargetGroupList(): Observable<TargetGroupAdminViewResponse> {
    const url = ApiUrls.getKey('AdminTargetGroups');
    return this.http.get<ApiResponse<TargetGroupAdminViewResponse>>(url)
      .pipe(map(response => response.groups));
  }

  getEntry(targetGroupId: number): Observable<TargetGroupCriteriaResponse> {
    const url = ApiUrls.getKey('AdminTargetGroup')
      .replace(/{targetGroupId}/gi, String(targetGroupId));
    return this.http.get<ApiResponse<TargetGroupCriteriaResponse>>(url)
      .pipe(map(response => response.group));
  }

  save(targetGroup: TargetGroup, filter: BasisFilter): Observable<TargetGroupCriteria> {
    const url = ApiUrls.getKey('AdminTargetGroups');
    const payload = {
      targetGroup,
      filter
    };
    return this.http.post<ApiResponse<TargetGroupCriteria>>(url, payload, HttpRequestOptions)
      .pipe(map(response => response.targetGroup));
  }

  fetchTargetGroupRoles(targetGroupId: number): Observable<RolesApiResponse> {
    const url = ApiUrls.getKey('GetTargetGrouprRoles')
      .replace(/{targetGroupId}/gi, String(targetGroupId));
    return this.http.get<ApiResponse<RolesApiResponse>>(url)
      .pipe(map(response => response.roles));
  }

  fetchTargetGroupAssignments(targetGroupId: number): Observable<ContentDistributions.Response> {
    const url = ApiUrls.getKey('AdminGetTargetGroupAssignments').replace('{targetGroupId}', String(targetGroupId));
    return this.http.get<ApiResponse<ContentDistributions.Response>>(url)
      .pipe(map(response => response.dists));
  }

  fetchTargetGroupUsers(targetGroupId: number): Observable<TargetGroupUsersResponse> {
    const url = ApiUrls.getKey('GetTargetGroupUsers')
      .replace(/{targetGroupId}/gi, String(targetGroupId));
    return this.http.get<TargetGroupUsersResponse>(url);
  }

  fetchTargetGroupsAndUsers(): Observable<TargetGroupsAndUsersResponse> {
    const url = ApiUrls.getKey('GetTargetGroupsAndUsers');
    return this.http.get<TargetGroupsAndUsersResponse>(url);
  }

  removeUsersFromTargetGroup(
    targetGroupId: number | null,
    userIds: number[] | null,
  ): Observable<TargetGroupUsersResponse | never> {

    if ( !(targetGroupId > 0) || !(userIds?.length > 0) ) {
      return EMPTY;
    }

    const url = ApiUrls.getKey('UpdateTargetGroupUsers')
      .replace(/{targetGroupId}/gi, String(targetGroupId));
    return this.http.delete<TargetGroupUsersResponse>(url, { body: { userIds } });
  }

}
