import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { ProfileSectionModule } from '../../../component/input/profile-section/profile-section.module';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { UserProfileCredentialsModule } from './user-profile-credentials/user-profile-credentials.module';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { SplitButtonComponent } from 'src/app/component/button/split-button/split-button.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [ UserProfileComponent ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    ProfileSectionModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    UserProfileCredentialsModule,
    SplitButtonComponent,
    MatDividerModule,
    RouterModule
  ],
})
export class UserProfileModule {
}
