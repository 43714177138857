import {
  TransformerMethod,
  WithDataType,
  WithDropdownOptions,
  WithIdentifier,
} from '../../core/primitives/primitives.types';
import { DateHelper } from '../../core/date.helper';
import { StringHelper } from '../../core/primitives/string.helper';
import { LanguageHelper } from '../../core/language.helper';
import { TableColumnDataType } from './table-column.types';


interface SortOptions<DATA_TYPE, RESULT_TYPE, COLUMN_ID>
  extends WithDataType<TableColumnDataType>, WithDropdownOptions {
  dataAccessor?: TransformerMethod<DATA_TYPE, RESULT_TYPE>;
  filter?: WithIdentifier<COLUMN_ID>;
  sortingAccessor?: TransformerMethod<DATA_TYPE, RESULT_TYPE>;
}

export class TableAccessors {

  static getDisplayValue<ROW = any, RESULT = any, COLUMN_ID = string>(data: ROW, options: {
    dataAccessor?: TransformerMethod<ROW, RESULT>;
    dataType?: TableColumnDataType | string;
    displayValueAccessor?: TransformerMethod<ROW, RESULT>;
    filter?: WithIdentifier<COLUMN_ID>;
  }, attribute?: COLUMN_ID): string {

    const displayValueAccessor = options?.displayValueAccessor;
    if ( displayValueAccessor != null ) {
      return TableAccessors
        .getValue(data, displayValueAccessor, options, attribute);
    }

    switch ( options?.dataType ) {

      case TableColumnDataType.dropdown:
      case TableColumnDataType.radio:
        const dropDownValue = TableAccessors.getColumnValue(data, options);
        return TableAccessors.getDropdownLabel(dropDownValue, options as WithDropdownOptions);

      case TableColumnDataType.date:
        const dateValue = TableAccessors.getColumnValue(data, options);
        const dateMoment = DateHelper.toMoment(dateValue as number);
        return dateMoment?.format('L');

      case TableColumnDataType.dateTime:
        const dateTimeValue = TableAccessors.getColumnValue(data, options);
        const dateTimeMoment = DateHelper.toMoment(dateTimeValue as number);
        return dateTimeMoment?.format('L LT');

      default:
        return TableAccessors
          .getValue(data, options?.dataAccessor, options, attribute);
    }
  }

  static getColumnValue<ROW = any, RESULT = any, COLUMN_ID = string>(data: ROW, options: {
    dataAccessor?: TransformerMethod<ROW, RESULT>;
    filter?: WithIdentifier<COLUMN_ID>;
  }, attribute?: COLUMN_ID): RESULT {

    return TableAccessors.getValue(data, options?.dataAccessor, options, attribute);
  }

  static getDropdownLabel(columnValue: any | null, options: WithDropdownOptions | null): string | null {
    const entry = TableAccessors.getDropdownOption(columnValue, options);
    if ( entry != null ) {
      // found column value as label
      return entry[1];
    }

    // if there is no match, return the value itself (won't match in filters, but show up as value)
    return columnValue;
  }

  static getDropdownOption(columnValue: any | null, options: WithDropdownOptions | null): string[] | null {
    if ( (columnValue == null) || (options == null) ) {
      return null;
    }

    columnValue = String(columnValue).trim();
    if ( columnValue === '' ) {
      return null;
    }

    const dropdownOptions = options.dropDownOptionsDefault ?? options.dropDownOptions ?? {};
    if (dropdownOptions.hasOwnProperty(columnValue)) {
      return [columnValue, LanguageHelper.objectToText(dropdownOptions[columnValue])];
    }

    const entry = Object.entries(dropdownOptions)
      .map(([ key, value ]) => [ key, LanguageHelper.objectToText(value) ])
      .find(([ key, value ]) => (key === columnValue) || (value === columnValue));
    if ( entry != null ) {
      // found column value as label
      return entry;
    }
  }

  static getDropdownValue(columnValue: any | null, options: WithDropdownOptions | null): string | null {
    const entry = TableAccessors.getDropdownOption(columnValue, options);
    if ( entry != null ) {
      // found column value as label
      return entry[0];
    }

    // if there is no match, return the value itself (won't match in filters, but show up as value)
    return columnValue;
  }

  static getFilterValue<ROW = any, COLUMN_ID = string>(data: ROW, options: {
    dataType?: string;
    dataAccessor?: TransformerMethod<ROW, string>;
    filter?: WithIdentifier<COLUMN_ID>;
    filterDataAccessor?: TransformerMethod<ROW, string>;
  }, attribute?: COLUMN_ID): string {

    const filterDataAccessor = options?.filterDataAccessor;
    if ( filterDataAccessor != null ) {
      return TableAccessors.getValue(data, filterDataAccessor, options, attribute);
    }

    const value = TableAccessors.getValue(data, options?.dataAccessor, options, attribute);
    switch ( options.dataType ) {

      case TableColumnDataType.dropdown:
      case TableColumnDataType.radio:
        // try to find the cleaned option key
        return TableAccessors.getDropdownValue(value, options as WithDropdownOptions);

      case TableColumnDataType.multiselect:
      case TableColumnDataType.tags:
      default:
        // tags and multiselect to not necessarily have defined options -> cannot clean values
        return value;
    }
  }

  static getSortValue<ROW_TYPE = any, COLUMN_ID = string, COLUMN_TYPE = any>(
    data: ROW_TYPE,
    options: SortOptions<ROW_TYPE, COLUMN_TYPE, COLUMN_ID>,
    attribute?: COLUMN_ID,
  ): COLUMN_TYPE {

    let value = TableAccessors
      .getValue(data, options?.sortingAccessor ?? options?.dataAccessor, options, attribute);

    switch ( options?.dataType ?? '' ) {

      case TableColumnDataType.date:
      case TableColumnDataType.dateTime:
        // sort invalid dates as last - with ascending sorting
        value = DateHelper.toMoment(value)?.unix() ?? Number.MAX_SAFE_INTEGER;
        break;

      case TableColumnDataType.dropdown:
      case TableColumnDataType.multiselect:
      case TableColumnDataType.radio:
        value = TableAccessors.getDropdownLabel(value, options);
        break;

      case TableColumnDataType.price:
      case TableColumnDataType.number:
        // no transformation
        break;

      default:
      case TableColumnDataType.html:
      case TableColumnDataType.image:
      case TableColumnDataType.other:
      case TableColumnDataType.tags:
      case TableColumnDataType.text:
        value = StringHelper.toString(value);
        break;

    }

    if ( typeof (value) === 'string' ) {
      value = value.toLocaleLowerCase();
    }

    return value;
  }

  static getValue<ROW, RESULT, COLUMN_ID>(data: ROW, dataAccessor: TransformerMethod<ROW, RESULT>, options: {
    filter?: WithIdentifier<COLUMN_ID>;
  }, attribute?: COLUMN_ID) {

    if ( dataAccessor != null ) {

      // apply default data accessor
      return dataAccessor(data, options);
    } else {

      attribute = options?.filter?.identifier ?? attribute;
      return data?.[String(attribute)];
    }
  }

}
