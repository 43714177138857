<rag-dialog-header>{{data?.dialogTitle}}</rag-dialog-header>

<mat-dialog-content>
  <div *ngIf="data?.dialogMessage" [innerHTML]="data.dialogMessage | safe:'html'"></div>

  <ul>
    <li *ngFor="let curriculum of data?.curricula">
      <a [routerLink]="getContentHref(curriculum)" [queryParams]="{
        'highlight': curriculum.id
      }">{{curriculum.title | localeText}}</a>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    [mat-dialog-close]="null"
    autofocus
    mat-flat-button
    color="primary"
    type="button"
  >
    <span i18n="@@global_ok">OK</span>
  </button>
</mat-dialog-actions>
