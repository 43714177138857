import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { RedirectHelper } from '../../../core/redirect.helper';
import { map, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from '../../../core/principal/principal.service';

/**
 * @see AuthGuard.storeLastLocation
 */
@Injectable({ providedIn: 'root' })
export class PostLoginRedirectGuard
  implements CanActivate {

  constructor(
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return forkJoin([
      this.principalService.principal$.pipe(take(1)),
      RedirectHelper.getRedirect(),
    ])
      .pipe(switchMap(([ principal, url ]) => {
        if ( !url ) {
          return of(true);
        } else if ( principal.hasToResetPassword ) {
          // do not redirect if user should enter their password
          return of(true);
        }

        console?.log('restoring last navigation...', url);
        return RedirectHelper.clearRedirect()
          .pipe(map(() => this.router.parseUrl(url)));
      }))
      .pipe(take(1));
  }

}
