import { ExtOauth2UsersRow } from './ext-oauth2-users.columns';
import { OAuthUserInfoResponse } from './ext-oauth2-users.types';
import { ColumnName, USER_COLUMN_NAMES } from '../../../../../core/column-settings/column-settings.types';
import { NumberedAnyObject } from '../../../../../core/core.types';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';


const PRESERVE_TYPE = [
  ColumnName.userActivationDate,
  ColumnName.userActive,
  ColumnName.userDateOfBirth,
  ColumnName.userDeactivationDate,
  ColumnName.userEmail,
  ColumnName.userHireDate,
  ColumnName.userId,
  ColumnName.userLanguage,
  ColumnName.userSex,
  ColumnName.userState,
];

export class ExtOauth2UsersHelper {

  public static asTableRows(data: OAuthUserInfoResponse | null): ExtOauth2UsersRow[] {
    if (!(data?.ssoUsers?.length > 0)) {
      return [];
    }

    const systemUsers = (data.systemUsers ?? [])
      .reduce((pV, user) => {
        pV[user.userId] = user;
        return pV;
      }, <NumberedAnyObject<ControllingSingleUserTypes.ControllingUser>>{});

    return data.ssoUsers.map(ssoUser => {
      const row = { ...ssoUser.ssoAttributes } as ExtOauth2UsersRow;
      row.userInfoId = ssoUser.userInfoId;
      row.principalName = ssoUser.principalName;
      row.lastLogin = ssoUser.accessTokenIssuedAt;
      const userId = row.userId = ssoUser.userId;
      const systemUser = systemUsers[userId];
      if (systemUser != null) {
        USER_COLUMN_NAMES.forEach(column =>
          row[column] = ExtOauth2UsersHelper.mergeValue(row, column, systemUser));
      }
      return row;
    });
  }

  static mergeValue(
    row: ExtOauth2UsersRow, column: ColumnName, systemUser: ControllingSingleUserTypes.ControllingUser,
  ): string {
    const systemValue = systemUser[column];
    if (PRESERVE_TYPE.includes(column)) {
      // keep the system value to allow proper filtering
      return systemValue;
    }

    const ssoValue = row[column];
    if ((ssoValue == systemValue) || !ssoValue) {
      return `${systemValue??''}`;
    }
    return `${systemValue??''} (SSO: '${ssoValue??''}')`;
  }

}
