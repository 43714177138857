import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';


export interface MaintenanceSnackbarData {
  action: string;
  message: string;
}

@Component({
  selector: 'rag-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: [ './maintenance.component.scss' ],
})
export class MaintenanceComponent
  extends SimpleSnackBar {

  constructor(
    @Inject(MatSnackBarRef) snackBarRef: MatSnackBarRef<SimpleSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) data: MaintenanceSnackbarData = {
      action: '',
      message: ''
    },
  ) {
    super(snackBarRef, data);
  }

}
