import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  selector: 'rag-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: [ './loading-indicator.component.scss' ],
})
export class LoadingIndicatorComponent
  implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
