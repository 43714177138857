import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfoService } from '../../../../core/info/info.service';
import { PaymentModuleSettings } from './ext-payment-module.types';
import { ApiUrls } from '../../../../core/api.urls';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../../core/global.types';


@Injectable({ providedIn: 'root' })
export class ExtPaymentModuleService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getPaymentSettings() {

    const url = ApiUrls.getKey('GetExtDSB');
    return this.http.get<ApiResponse<PaymentModuleSettings>>(url)
      .pipe(map(response => response.dsb));

  }

  savePaymentSettings(paymentSettings: PaymentModuleSettings) {

    const url = ApiUrls.getKey('PostExtDSB');
    return this.http.post<PaymentModuleSettings>(url, paymentSettings)
      .pipe(take(1));

  }

  deletePaymentSettings(): Observable<PaymentModuleSettings> {

    const url = ApiUrls.getKey('DeleteExtDSB');
    return this.http.delete<PaymentModuleSettings>(url);

  }
}
