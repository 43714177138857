import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Principal } from 'src/app/core/core.types';

@Component({
  selector: 'rag-receiver-field',
  templateUrl: './receiver-field.component.html',
  styleUrls: [ './receiver-field.component.scss' ],
})
export class ReceiverFieldComponent {

  @Input() displayAll = false;
  @Output() gotFocus = new EventEmitter();
  @Input() items: Principal[];
  @Input() placeholder = '';
  @HostBinding('tabindex') tabindex = 0;

  constructor() {
  }

  get itemsCount(): number {
    if ( this.items ) {
      return this.items.length;
    }
    return 0;
  }

  isItemInvalid(item: Principal): boolean {
    return item?.invalid === true;
  }

  displayMore() {
    if ( this.items ) {
      if ( this.items.length === 0 ) {
        return '';
      }
      return this.items[0].name;
    }
  }

}
