import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { sanitize } from 'dompurify';

@Pipe({
  name: 'safe',
})
export class SafePipe
  implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  public transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if ( value == null || value === undefined ) {
      value = '';
    }
    switch ( type ) {
      case 'html':
        // @see https://github.com/cure53/DOMPurify
        value = sanitize(value, { USE_PROFILES: { html: true }, ADD_ATTR: [ 'target' ] });
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Unable to bypass security for invalid type: ${type}`);
    }
  }

}
