import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { CatalogCardCourseComponent } from './catalog-card-course.component';
import { ClampComponent } from '../../clamp/clamp.component';


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    ClampComponent,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    CatalogCardCourseComponent
  ],
  exports: [
    CatalogCardCourseComponent
  ],
})
export class CatalogCardCourseModule {
}
