import { Component, Input, OnDestroy } from '@angular/core';
import { LinkedUserTypes } from '../../../core/user/link/linked-users.types';
import { LinkedUsersService } from '../../../core/user/link/linked-users.service';
import { Observable } from 'rxjs';
import { NavItem } from '../../../core/navigation/navigation.types';
import { PrincipalService } from '../../../core/principal/principal.service';
import { LogoutService } from '../../../core/principal/logout.service';
import { LoginDialogService } from '../../login/login-dialog/login-dialog.service';
import { HeaderNavHelper } from '../header-nav.helper';
import { LanguageHelper, LanguageInfo } from '../../../core/language.helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { NavigationService, PathInfo } from '../../../core/navigation/navigation.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[rag-header-nav-top]',
  templateUrl: './header-nav-top.component.html',
  styleUrls: [ './header-nav-top.component.scss' ],
})
export class HeaderNavTopComponent
  implements OnDestroy {

  currentLanguage: LanguageInfo;
  currentLanguagePath: PathInfo;
  languages: LanguageInfo[];
  readonly linkedUsers$: Observable<LinkedUserTypes.LinkedUserDropdownEntry[]>;
  readonly mailsCount$: Observable<number>;
  @Input() navItems: NavItem[];

  constructor(
    public headerNavHelper: HeaderNavHelper,
    private linkedUsersService: LinkedUsersService,
    private loginDialogService: LoginDialogService,
    private logoutService: LogoutService,
    private navigationService: NavigationService,
    private principalService: PrincipalService,
  ) {
    this.linkedUsers$ = this.linkedUsersService.getLinkedUsersDropDown();
    this.mailsCount$ = this.principalService.mailsCount$;
    this.principalService.isLogged$
      .pipe(tap(() => this.updateLanguageInfo()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onMenuOpenLinkedUser(): void {
    this.linkedUsersService.getLinkedUsers();
  }

  onSwitchAccount(user: LinkedUserTypes.LinkedUser): void {
    this.linkedUsersService.showDialogForSwitchingAccount(user);
  }

  onSwitchToLanguage(language: LanguageInfo): void {
    const languageKey = language?.key;
    const trainLanguage = language?.trainLanguage;
    const languagePath = NavigationService.getLanguagePathFromLocale(languageKey);
    if ( trainLanguage && this.currentLanguagePath?.languagePath !== languagePath ) {
      try {
        localStorage.setItem('switched_language', trainLanguage);
      } catch ( e ) {
        if ( console && console.error ) {
          console.error(e);
        }
      }
      this.navigationService.switchToLocale(languageKey);
    }
  }

  private updateLanguageInfo(): void {
    this.languages = LanguageHelper.LANGUAGES;
    this.currentLanguagePath = this.navigationService.getCurrentLanguage();
    this.currentLanguage = LanguageHelper.getLanguage(this.currentLanguagePath?.language);
  }

}
