import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/global.types';
import { versions } from '../../../environments/versions';
import { ApiUrls } from '../../core/api.urls';
import { ModalDialog } from '../../core/modal-dialog';
import { VersionInfoComponent } from './version-info.component';
import { VersionInfo, VersionInfoApi, VersionInfoBackEnd } from './version-info.types';
import { PrincipalService } from '../../core/principal/principal.service';

@Injectable({
  providedIn: 'root',
})
export class VersionInfoService {

  constructor(
    private httpClientService: HttpClient,
    private dialog: ModalDialog,
    private principalService: PrincipalService,
  ) {
  }

  static combineValues(...args): string {
    return args
      .filter(item => !!item)
      .join('-');
  }

  static formatDate(value: string | number): string {
    let date: moment.Moment;

    if ( value > 0 ) {
      date = moment(parseInt(String(value), 10));
    } else {
      date = moment(value);
    }
    if ( date.isValid() ) {
      return date.format('L LT');
    }

    return '';
  }

  getTrainApiVersion(): Observable<VersionInfo> {
    const url = ApiUrls.getKey('VersionInfoApi');

    return this.httpClientService.get<ApiResponse<VersionInfoApi>>(url)
      .pipe(map(response => {
        const serverInfo = response.serverInfo ?? {};
        const tagName = serverInfo['git.closest.tag.name'];
        const buildDate = VersionInfoService.formatDate(serverInfo['git.commit.time']);
        const branch = serverInfo['git.branch'];
        const commitHash = serverInfo['git.commit.id'];

        return {
          branch: branch.length !== 0 ? branch : 'dev',
          version: tagName.length !== 0 ? tagName : '0.0.0',
          buildDate: buildDate.length !== 0
            ? buildDate
            : VersionInfoService.formatDate('1970-01-01T00:00:00.000Z'),
          commitHash: commitHash.length !== 0 ? commitHash : 'deadbeef',
          title: $localize`:@@version_info_dialog_api_panel_title:Train API`,
        };
      }))
      .pipe(catchError(() => of({
        branch: 'dev',
        version: '0.0.0',
        buildDate: VersionInfoService.formatDate('1970-01-01T00:00:00.000Z'),
        commitHash: 'deadbeef',
        title: $localize`:@@version_info_dialog_api_panel_title:Train API`,
      })));
  }

  getTrainBackEndVersion(): Observable<VersionInfo> {
    const url = ApiUrls.getKey('VersionInfoBackEnd');

    return this.httpClientService.get<VersionInfoBackEnd>(url)
      .pipe(map(response => {
        const buildDate = VersionInfoService.formatDate(response.created);

        return {
          branch: response.generatedFrom,
          version: response.major,
          buildDate,
          commitHash: response.minor,
          title: $localize`:@@version_info_dialog_backend_panel_title:Train Back-End`,
        };
      }))
      .pipe(catchError(() => of({
        branch: 'dev',
        version: '0.0.0',
        buildDate: VersionInfoService.formatDate('1970-01-01T00:00:00.000Z'),
        commitHash: 'deadbeef',
        title: $localize`:@@version_info_dialog_backend_panel_title:Train Back-End`,
      })));
  }

  getTrainFrontEndVersion(): VersionInfo {
    const version = versions.version;
    const branch = versions.branch;
    const buildDate = VersionInfoService.formatDate(versions.buildDate);
    const commitHash = versions.revision;

    return {
      branch,
      version,
      buildDate,
      commitHash,
      title: $localize`:@@version_info_dialog_frontend_panel_title:Train Front-End`,
    };
  }

  showDialog(): void {
    const errMsg = $localize`:@@version_info_dialog_no_version_info_msg:
      Version information is currently not available, please try again later.`;

    forkJoin([
      this.getTrainApiVersion(),
      this.getTrainBackEndVersion(),
      this.principalService.permissionStates$.pipe(take(1)),
    ])
      .pipe(map(([
        trainApiVersion,
        trainBackEndVersion,
        permissions,
      ]) => ({
        versionInfo: [
          trainApiVersion,
          trainBackEndVersion,
          this.getTrainFrontEndVersion(),
        ],
        permissions,
      })))
      .pipe(take(1))
      .pipe(tap(data => this.dialog.open(VersionInfoComponent, { data })))
      .pipe(catchError(err => of(
        {
          noVersionInfo: errMsg + err.message,
        })))
      .subscribe();
  }
}
