<form [formGroup]="formGroup" *ngIf="formGroup != null">
  <rag-table-column-menu
    #tableColumnMenu
    (evtColumnsChanged)="setColumns($event)"
    [menuData$]="columnMenuData$"
  ></rag-table-column-menu>

  <!-- Controlling table -->
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
  >

    <!-- render course title as row -->
    <ng-container matColumnDef="parentTitle">
      <td *matCellDef="let row" mat-cell [colSpan]="columns.length">
        <div class="title-container">
          <span>{{getOrderIndex(row)}}. {{asParent(row)?.title}}</span>
          <!-- display info button for todo items -->
          <button
            mat-icon-button
            *ngIf="isTodo(row) && singleTodoCourse == null"
            type="button"
            (click)="onToDoDetails(row)">
            <mat-icon svgIcon="magnify">
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- order index -->
    <ng-container matColumnDef="orderIndex">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container i18n="@@global_nr">No.</ng-container>
      </th>
      <td *matCellDef="let row" mat-cell>{{getOrderIndex(row)}}</td>
    </ng-container>

    <!-- Course title -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <ng-container i18n="@@global_title">Title</ng-container>
      </th>
      <td mat-cell *matCellDef="let row">{{asChild(row)?.title}}</td>
    </ng-container>

    <!-- Sco status -->
    <ng-container matColumnDef="displayStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <ng-container i18n="@@global_status">Status</ng-container>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container
          *ngIf="asChild(row) as childRow"
        >
          <rag-ctrl-edit-display-status
            [disabled]="formGroup.disabled"
            [contentType]="ContentType_Course"
            [confirmActive]="childRow.$parent.$data?.confirmActive"
            [courseType]="childRow.$parent.$data?.courseType"
            [formControlName]="getIdentifier(childRow)+'.displayStatus'"
            (ngModelChange)="onModelChange(childRow, 'displayStatus', $event)"
          ></rag-ctrl-edit-display-status>
        </ng-container>
      </td>
    </ng-container>

    <!-- Last activity -->
    <ng-container matColumnDef="executionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span i18n="@@ctrl_single_user_details_executionDate">Finish date</span>
      </th>
      <td *matCellDef="let row" mat-cell [ngClass]="{'rag-dirty': isDirty(row, 'executionDate')}">
        <mat-form-field
          *ngIf="asChild(row) as childRow"
          appearance="outline"
          class="no-padding-bottom"
        >
          <mat-label i18n="@@ctrl_single_user_details_executionDate">Finish date</mat-label>
          <input
            matInput
            [matDatepicker]="eltExecutionDatePicker"
            [max]="maxDate"
            (dateInput)="onModelChange(childRow, 'executionDate', $event.value)"
            [formControlName]="getIdentifier(childRow)+'.executionDate'"
            [required]="childRow.displayStatus > 0"
          />
          <mat-datepicker-toggle matSuffix [for]="eltExecutionDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #eltExecutionDatePicker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Scores -->
    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span i18n="@@ctrl_single_user_details_result">Result</span>
      </th>
      <td mat-cell *matCellDef="let row" [ngClass]="{'rag-dirty': isDirty(row, 'result')}">
        <mat-form-field
          appearance="outline"
          class="no-padding-bottom">
          <input
            matInput
            class="right"
            maxlength="10"
            type="number"
            [formControlName]="getIdentifier(row)+'.result'"
            (change)="onModelChange(row, 'result', $event?.target['value'])">
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>
        <button
          (click)="tableColumnMenu.openMenu($event)"
          mat-icon-button
        >
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>
      </th>
      <td *matCellDef="let row" mat-cell></td>
    </ng-container>

    <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
    <tr *matRowDef="let row; columns: ['parentTitle']; when: isParentRow" mat-row class="parent"></tr>
    <tr *matRowDef="let row; columns: columns; when: isChildRow" mat-row class="child"></tr>
  </table>

  <ng-container *ngIf="getCourseAsToDo() as course">

    <rag-todo-data-v2
      [courseTodo]="course"
      [extensions]="this.data.extensions"
      [clearSolutionAndEstimation$]="clearSolutionAndEstimation$"
    ></rag-todo-data-v2>

  </ng-container>

</form>
