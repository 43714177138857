<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  [menuData$]="columnMenuData$"
  menuContext="CtrlSingleUserDetailsCourseComponent@columnSettings"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
></rag-table-column-menu>

<mat-menu #courseActionsMenu>
  <ng-template matMenuContent let-course="course">

    <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
         [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000" [matTooltipDisabled]="mayReadLearningStatus(course)">
      <button type="button" [disabled]="!mayReadLearningStatus(course)" mat-menu-item (click)="onDetails(course)">
        <mat-icon svgIcon="beaker-outline"></mat-icon>
        <span i18n="@@ctrl_single_user_open_tab">Open in tab</span>
      </button>
    </div>

    <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
         [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000" [matTooltipDisabled]="canChangeData">
      <button type="button" [disabled]="!canChangeData || !maySaveLearningStatus(course)" mat-menu-item (click)="onLearningDataDialog(course)">
        <mat-icon svgIcon="list-status"></mat-icon>
        <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
      </button>
    </div>

    <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
         [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000" [matTooltipDisabled]="isScormContent(course)">
      <button [disabled]="!isScormContent(course)" type="button"
              mat-menu-item (click)="onSessionDataDialog(course)">
        <mat-icon svgIcon="school"></mat-icon>
        <span i18n="@@ctrl_single_user_session_state">Session data</span>
      </button>
    </div>

    <div matTooltip="This learning content does not have interaction evaluation"
         i18n-matTooltip="@@ctrl_single_user_missing_interaction_evaluation" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
         [matTooltipDisabled]="isScormContent(course)">
      <a [disabled]="!isScormContent(course)" type="button" mat-menu-item
              [routerLink]="getInteractionsUrl(course)">
        <mat-icon svgIcon="check-circle-outline"></mat-icon>
        <span i18n="@@global_interactions_evaluation">Interaction evaluation</span>
      </a>
    </div>

    <div matTooltip="This learning content does not support scorm log"
         i18n-matTooltip="@@ctrl_single_user_missing_scorm_log" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
         [matTooltipDisabled]="isScormContent(course)">
      <button [disabled]="!isScormContent(course)" type="button" mat-menu-item
              (click)="onScormLogDialog(course)">
        <mat-icon svgIcon="math-log"></mat-icon>
        <span i18n="@@ctrl_single_user_scorm_log">Scorm log</span>
      </button>
    </div>

    <button [disabled]="!isScormContent(course)" type="button" mat-menu-item (click)="onDownloadInteractionsPDF(course)">
      <mat-icon svgIcon="download-outline"></mat-icon>
      <span i18n="@@ctrl_single_user_interactions_exp">Interaction PDF export</span>
    </button>

    <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
         [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
         [matTooltipDisabled]="canChangeData && permissions.ctrlCourseReset">
      <button type="button" [disabled]="!canChangeData || !permissions.ctrlCourseReset" mat-menu-item (click)="onResetCourse(course)">
        <mat-icon svgIcon="undo-variant"></mat-icon>
        <span i18n="@@global_reset">Reset</span>
      </button>
    </div>


  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@ctrl_single_user_no_courses">This user has not been assigned any courses.</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<div
  *ngIf="isColumnContextLoaded && (renderColumns?.length > 0) else tplDataLoading"
  class="content"
>
  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <ng-container
      *ngIf="isPaginatorSet; else tplDataLoading"
    >
      <table
        *ngIf="(dataSource.data?.length > 0); else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort
      >

        <ng-container matColumnDef="batchCheckbox">
          <th *matHeaderCellDef class="batch-checkbox" mat-header-cell>
            <mat-checkbox
              (change)="onToggleAll()"
              [checked]="isSelectAllChecked()"
              [disabled]="isDataEmpty || !canChangeData"
              [indeterminate]="isSelectAllIndeterminate()"
            ></mat-checkbox>
          </th>
          <td *matCellDef="let course" class="batch-checkbox" mat-cell>
            <mat-checkbox
              (change)="onToggleSelection($event, course)"
              [checked]="selection.isSelected(course)"
              [disabled]="!canChangeData"
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="batchCheckboxFilter">
          <th *matHeaderCellDef class="batch-checkbox" mat-header-cell></th>
        </ng-container>

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [column]="column"
              [row]="row"
              targetType="lms_course"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let course" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerFor]="courseActionsMenu"
              [matMenuTriggerData]="{course: course}"
              mat-icon-button
            >
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div class="no-data" *ngIf="isFilteredDataEmpty">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>

    </ng-container>

  </rag-sticky-scroll>

  <mat-paginator
    [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
