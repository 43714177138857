import { Injectable } from '@angular/core';
import { ApiUrls } from './api.urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { PdfEventMembershipRequest } from './pdf.types';
import { InfoService } from './info/info.service';
import { InfoType } from './info/info.types';

@Injectable({
  providedIn: 'root',
})
export class PdfService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService
  ) {}

  generateBadgePdf(badgeUUID: string): Observable<void> {
    const url = ApiUrls.getKey('PDFGenerateBadge')
      .replace(/{badgeUUID}/gi, badgeUUID);

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });
    return this.http.post(url, null, {
      observe: 'response',
      headers,
      responseType: 'blob' as 'json',
    })
      .pipe(tap((res: any) => {
        const data = res.body;

        let filename = 'Badge.pdf';
        const contentDisposition = res.headers.get('content-disposition');
        const filenameRegex = '(?:.+?);[ ]*filename="(.+?)"';
        const regexResult = contentDisposition.match(filenameRegex);
        if ( regexResult != null ) {
          filename = regexResult[1];
        }
        const pdfBlob = new Blob([ data ], { type: data.type });

        FileSaver.saveAs(pdfBlob, filename);
      }))
      .pipe(map(_ => void(0)));
  }

  generateAttendeesListPdf(requestData: PdfEventMembershipRequest): Observable<void> {
    const url = ApiUrls.getKey('PDFGenerateAttendeesList');

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });
    return this.http.post(url, JSON.stringify(requestData), {
      observe: 'response',
      headers,
      responseType: 'blob' as 'json',
    })
      .pipe(tap((res: any) => {
        const data = res.body;

        let filename = 'AttendeesList.pdf';
        const contentDisposition = res.headers.get('content-disposition');
        const filenameRegex = '(?:.+?);[ ]*filename="(.+?)"';
        const regexResult = contentDisposition.match(filenameRegex);
        if ( regexResult != null ) {
          filename = regexResult[1];
        }
        const pdfBlob = new Blob([ data ], { type: data.type });

        FileSaver.saveAs(pdfBlob, filename);
      }))
      .pipe(map(_ => void(0)));
  }

  generatePermissionExport(): Observable<void> {
    const url = ApiUrls.getKey('DocxGeneratePermissionExport');

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
    })

    return this.http.get(url, {
      observe: 'response',
      headers,
      responseType: 'blob',
    })
      .pipe(tap((res: any) => {
        const data = res.body;

        let fileName = 'PermissionExport.docx';
        const contentDisposition = res.headers.get('content-disposition');
        const filenameRegex = '(?:.+?);[ ]*filename="(.+?)"';
        const regexResult = contentDisposition.match(filenameRegex);
        if (regexResult != null) {
          fileName = regexResult[1];
        }

        const docxBlob = new Blob([data], {type: data.type});

        FileSaver.saveAs(docxBlob, fileName);
      }))
      .pipe(map(_ => void(0)));
  }

  generateUserProfileExportPdf(): Observable<void> {
    const url = ApiUrls.getKey('PDFGenerateUserProfileExport');

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });

    return this.http.post(url, null, {
      observe: 'response',
      headers,
      responseType: 'blob' as 'json',
    }).pipe(tap((res: any) => {
        const data = res.body;

        let filename = 'profile.pdf';
        const contentDisposition = res.headers.get('content-disposition');
        const filenameRegex = '(?:.+?);[ ]*filename="(.+?)"';
        const regexResult = contentDisposition.match(filenameRegex);
        if ( regexResult != null ) {
          filename = regexResult[1];
        }
        const pdfBlob = new Blob([ data ], { type: data.type });

        FileSaver.saveAs(pdfBlob, filename);
    }))
    .pipe(catchError(_ => {
      this.infoService.showMessage($localize`:@@user_profile_export_failure:You profile cannot be exported. Please, contact the system administrator`, {
        infoType: InfoType.Warning
      });
      return EMPTY;
    }))
    .pipe(map(_ => void(0)));
  }
}
