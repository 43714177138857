import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CachedSubject } from '../../../core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ViewHelper } from '../../../core/view-helper';
import { TableColumnMenuService } from './table-column-menu.service';
import { TableColumnMenu } from './table-column-menu.types';
import { RuntimeEnvironmentService } from '../../../core/runtime-environment.service';
import { PermissionStates } from '../../../core/principal/permission.states';
import { PrincipalService } from '../../../core/principal/principal.service';
import { Sort } from '@angular/material/sort';
import { SubscriptionHolder } from '../../../core/reactive/subscription-holder';


@Component({
  selector: 'rag-table-column-menu',
  templateUrl: './table-column-menu.component.html',
  styleUrls: [ './table-column-menu.component.scss' ],
})
export class TableColumnMenuComponent
  implements OnDestroy {

  columnsUpdating = false;
  @Output() readonly evtColumnsChanged: Observable<string[]>;
  @Output() readonly evtMenuItemsChanged: Observable<TableColumnMenu.MenuData>;
  filter = '';
  filterCountDisplay = -1;
  filterCountTotal = -1;
  filteredMenuItems: TableColumnMenu.MenuItem[];
  @Input() forcedEnabledColumnIds: string[];
  loading = true;
  @Input() menuContextSave: string;
  @Input() minSelections = 2;
  @Input() updateOnClose = false;
  private _changeableMenuItems: TableColumnMenu.MenuItem[];
  private _changedWhileOpened = false;
  private _contextData = new CachedSubject<TableColumnMenu.MenuItemMap>(null);
  private _evtMenuDataChanged = new EventEmitter<TableColumnMenu.MenuData>();
  private _evtNotifyMenuData = new EventEmitter<boolean>(true);
  private _filterChangedDebounce;
  private _hasItems = false;
  private _matMenuTrigger: MatMenuTrigger;
  private _matMenuTriggerRef: ElementRef<HTMLElement>;
  private _menuContext: string;
  private _menuData: TableColumnMenu.MenuData;
  private _menuDataSubscription = new SubscriptionHolder<TableColumnMenu.MenuData>(this);
  private _permissions: PermissionStates;
  private _selectedCount = 0;

  constructor(
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private tableColumnMenuService: TableColumnMenuService,
    private principalService: PrincipalService,
  ) {
    this.evtMenuItemsChanged = this._evtMenuDataChanged.asObservable();
    this.evtColumnsChanged = this.evtMenuItemsChanged
      .pipe(map(TableColumnMenuService.menuDataToColumns));

    this._evtNotifyMenuData.asObservable()
      .pipe(debounceTime(800))
      .pipe(tap(() => this.columnsUpdating = true))
      .pipe(delay(1))
      .pipe(tap(this.notifyMenuData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => this._permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.tableColumnMenuService.sortChanged$
      .pipe(tap(this.updateSort))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this._menuDataSubscription.value$
      // prevent indefinite redraw
      .pipe(filter(menuData => menuData && (menuData !== this._menuData)))
      .pipe(switchMap(menuData => {
        this.loading = true;
        return this.loadMenuContext()
          .pipe(map(fromStore => [ menuData, fromStore ]));
      }))
      .pipe(map(this.initMenuData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  /**
   * Check this if you want to hide or disable the menu button
   */
  @Output()
  get hasItems(): boolean {
    return this._hasItems;
  }

  @ViewChild('matMenuTrigger', { read: MatMenuTrigger })
  set matMenuTrigger(value: MatMenuTrigger) {
    this._matMenuTrigger = value;
  }

  @ViewChild('matMenuTrigger', { read: ElementRef })
  set matMenuTriggerRef(value: ElementRef) {
    this._matMenuTriggerRef = value;
  }

  /**
   * If defined this will save select state and sorting to backend
   */
  @Input()
  set menuContext(value: string) {
    const doUpdate = value && (value !== this._menuContext);
    this._menuContext = value;
    if ( !doUpdate ) {
      return;
    }

    this.menuContextSave = value;

    this._contextData.reset();
    this.loadMenuContext()
      .pipe(take(1))
      .subscribe();
  }

  @Input()
  set menuData$(value: Observable<TableColumnMenu.MenuData>) {
    if ( !value ) {
      return;
    }

    this.loading = true;
    this._menuDataSubscription.observable = value;
  }

  get permissions(): PermissionStates {
    return this._permissions;
  }

  get showResetButton(): boolean {
    return (this.menuContextSave != null) && (this._menuData?.menuItemDefaults != null);
  }

  columnMoved($event: CdkDragDrop<TableColumnMenu.MenuItem[]>): void {
    if ( $event.previousIndex === $event.currentIndex ) {
      // skip when unchanged
      return;
    }

    const menuItem: TableColumnMenu.MenuItem = $event.item.data;
    const targetItem = $event.container.data[$event.currentIndex];
    TableColumnMenuService.moveItem(this._menuData, menuItem, targetItem);

    this._changeableMenuItems.sort(TableColumnMenuService.compareMenuItemOrderIndex);
    this.filteredMenuItems.sort(TableColumnMenuService.compareMenuItemOrderIndex);

    this._changedWhileOpened = true;
    if ( !this.updateOnClose ) {
      this._evtNotifyMenuData.next(true);
    }
  }

  columnToggled(menuItem: TableColumnMenu.MenuItem, selected: boolean): void {
    menuItem.selected = selected;
    this._selectedCount = this._changeableMenuItems.filter(item => item.selected).length;

    this._changedWhileOpened = true;
    if ( !this.updateOnClose ) {
      this._evtNotifyMenuData.next(true);
    }
  }

  filterChanged(): void {
    if ( this._filterChangedDebounce ) {
      clearTimeout(this._filterChangedDebounce);
    }

    this._filterChangedDebounce = setTimeout(this.doFilter, 50);
  }

  getSearchLabel(): string {
    const search = $localize`:@@global_search:Search`;
    if ( (this.filterCountTotal > 0) && (this.filterCountDisplay < this.filterCountTotal) ) {
      return `${search} (${this.filterCountDisplay} / ${this.filterCountTotal})`;
    }
    return search;
  }

  isDisabled(menuItem: TableColumnMenu.MenuItem): boolean {
    return !menuItem ||
      menuItem.disabled ||
      (
        // prevent column being stuck in disabled, if it contains a default filter
        menuItem.hasFilter && menuItem.selected
      ) ||
      (
        menuItem.selected &&
        this._selectedCount <= this.minSelections
      ) ||
      this.forcedEnabledColumnIds?.includes(menuItem.id);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onMenuClose(): void {
    this.resetFilter();

    if ( this.updateOnClose && this._changedWhileOpened ) {
      this._changedWhileOpened = false;
      this._evtNotifyMenuData.next(true);
    }
  }

  onRemoveSettings(): void {
    if ( !this._hasItems ) {
      return;
    }

    this.tableColumnMenuService.resetToDefaults(this._menuData);
    this.initMenuData([ this._menuData, {} ]);

    this.tableColumnMenuService.resetUsersColumnSettings(this.menuContextSave)
      .pipe(tap(accountSettings => {
        if ( (Object.keys(accountSettings ?? {}).length === 0) || this.permissions.saveDefaultColumnSettings ) {
          this._contextData.reset();
          return;
        }
        // only apply account settings if the user is not allowed to define them
        // otherwise it would be impossible to revert to the static defaults!
        this._contextData.next(accountSettings);
      }))
      .pipe(take(1))
      .subscribe();
  }

  openMenu($event: MouseEvent): boolean {
    const nativeElement = this._matMenuTriggerRef?.nativeElement;
    if ( (nativeElement == null) || (this._matMenuTrigger == null) ) {
      return;
    }

    $event.preventDefault();
    nativeElement.style.left = $event.clientX + 'px';
    nativeElement.style.top = $event.clientY + 'px';
    this._changedWhileOpened = false;
    this._matMenuTrigger.openMenu();
    return false;
  }

  saveAsDefault(): void {
    if ( !this._hasItems ) {
      return;
    }
    this.tableColumnMenuService.setAccountsColumnSettings(this.menuContextSave, this._menuData)
      .subscribe();
  }

  private doFilter = (): void => {
    if ( this.filter?.length > 0 ) {
      const filterValue = this.filter.toLocaleLowerCase();
      this.filteredMenuItems = [ ...this._changeableMenuItems ]
        .filter(item => {
          const viewData = ViewHelper.getViewData(item);
          return viewData.title.toLocaleLowerCase().includes(filterValue);
        });
    } else {
      this.filteredMenuItems = [ ...this._changeableMenuItems ];
    }
    this.filterCountDisplay = this.filteredMenuItems.length;
  };

  private initMenuData = ([ value, fromStore ]: [ TableColumnMenu.MenuData, TableColumnMenu.MenuItemMap ]): void => {
    this.loading = false;
    this._menuData = value;

    if ( !value.menuItems ) {
      this._hasItems = false;
      return;
    }

    // stored settings must be applied first to keep sort order
    TableColumnMenuService.applyStoredSettings(value, fromStore);

    this._changeableMenuItems = TableColumnMenuService.findChangeableItems(value);
    this.filterCountTotal = this._changeableMenuItems.length;

    if ( !(this.filterCountTotal > 0) ) {
      this._hasItems = false;
      return;
    }

    this._selectedCount = this._changeableMenuItems
      .filter(item => item.selected)
      .length;
    this._hasItems = true;
    this.resetFilter();

    this._evtNotifyMenuData.next(false);
  };

  private loadMenuContext(): Observable<TableColumnMenu.MenuItemMap> {
    if ( this._contextData.queryStart() ) {
      this.tableColumnMenuService.getUsersColumnSettings(this._menuContext)
        .pipe(tap(this._contextData.next))
        .pipe(take(1))
        .subscribe();
    }
    return this._contextData.withoutEmptyValuesWithInitial()
      // for some reason the observable triggers twice
      .pipe(distinctUntilChanged());
  }

  private notifyMenuData = (updateStorage: boolean): void => {
    this._evtMenuDataChanged.next(this._menuData);

    if ( updateStorage ) {
      this.updateContextData(this._menuData);
    }
    setTimeout(() => this.columnsUpdating = false, 0);
  };

  private resetFilter(): void {
    this.filter = '';
    this.filterChanged();
  }

  private updateContextData(menuData: TableColumnMenu.MenuData): void {
    this.tableColumnMenuService.setUsersColumnSettings(this.menuContextSave, {
      startWith: [],
      menuItems: menuData.menuItems,
      endWith: [],
    })
      // cannot use next here as it would call initMenuData unnecessarily
      .pipe(tap(this._contextData.reset))
      .pipe(take(1))
      .subscribe();
  }

  private updateSort = (sort: Sort): void => {

    if ( this.loading ) {
      // ignore changes until all data was loaded
      return;
    }

    const columnId = sort?.active;
    const menuItems = this._menuData?.menuItems;
    if ( (menuItems == null) ||
      (this.menuContextSave == null) ||
      (columnId == null) || (columnId === '') ) {
      // nothing to save -> skip any further tasks
      return;
    }

    const sortDirection = sort.direction;
    const changed = Object.values(menuItems)
      .map(item => {
        const sortActive = item.id === columnId;
        let itemChanged = (item.sortActive !== sortActive);

        if (sortActive) {
          // only compare active sort direction if this is the sorted column
          itemChanged ||= (item.sortDirection !== sortDirection);
        } else {
          // not-sorted columns should have their direction cleared
          itemChanged ||= (item.sortDirection !== '');
        }

        if ( itemChanged ) {
          item.sortActive = sortActive;
          item.sortDirection = sortActive ? sortDirection : '';
        }
        return itemChanged;
      }).find(change => change === true);

    if ( changed ) {
      // trigger storing changed data
      this._evtNotifyMenuData.next(true);
    }
  };

}
