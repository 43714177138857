import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiUrls } from '../../../../core/api.urls';
import { ApiResponse } from '../../../../core/global.types';
import { UserCurriculumData } from './user-curriculum.types';

@Injectable()
export class UserCurriculumService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getCurriculumForUser(userId: number, curriculumId: number): Observable<UserCurriculumData> {
    const url = ApiUrls.getKey('UserCurriculum')
      .replace('{userId}', '' + userId)
      .replace('{curriculumId}', '' + curriculumId);
    return this.http.get<ApiResponse<UserCurriculumData>>(url)
      .pipe(map(response => response.data));
  }

}
