import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../component/table/table-controller/table-controller.types';
import { Translation } from '../../../../core/translation/translation.types';
import { ColumnSettings } from '../../../../core/report/report.types';
import {
  UserModelControllingColumnMap,
  UserModelControllingColumns,
} from '../../../../component/table/table-controller/table-controller.columns';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { STRUCTURAL_COLUMN, TableColumnBuilder } from '../../../../component/table/table-column.builder';

export namespace LicenseGroupToTgColumns {

  export class Util {

    static toColumnSettings(columns: DistColumnSettings[]): ColumnSettings[] {
      // todo sort by key
      return Object.values(columns)
        .map(column => ({
            id: column.targetId,
            enabled: column.enabled,
            selected: column.selected,
            title: column.title,
          } as ColumnSettings));
    }

  }

  export interface DistColumnSettings {
    enabled: boolean;
    selected: boolean;
    targetId: string;
    targetType: string;
    title: Translation;
  }

  export interface CurriculumColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: CurriculumColumnMenuItemMap;
  }

  export interface CurriculumColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    curriculumColumnMenu: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_CURRICULUM_MENU_COLUMNS: CurriculumColumnMenuData = {
    startWith: [],
    endWith: [ 'curriculumColumnMenu' ],
    menuItems: {
      curriculumColumnMenu: TableColumnBuilder.start<any>()
        .withColumnId('curriculumColumnMenu')
        .withType(TableColumnDataType.other)
        .withHiddenSelected()
        .withPreserve()
        .build(),
    },
  };

  export interface UserColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: UserColumnMenuItemMap;
  }

  export interface UserColumnMenuItemMap
    extends UserModelControllingColumnMap {
    userColumnMenu: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_USER_MENU_COLUMNS: UserColumnMenuData = {
    startWith: [],
    endWith: [ 'userColumnMenu' ],
    menuItems: {

      userLogin: TableColumnBuilder.start(UserModelControllingColumns.userLogin)
        .withSelected()
        .build(),
      userFirstname: TableColumnBuilder.start(UserModelControllingColumns.userFirstname)
        .withSelected()
        .build(),
      userLastname: TableColumnBuilder.start(UserModelControllingColumns.userLastname)
        .withSelected()
        .build(),
      userEmail: TableColumnBuilder.start(UserModelControllingColumns.userEmail)
        .withSelected()
        .build(),

      ...MergeHelper.cloneDeep(UserModelControllingColumns, column => ![
        'userLogin',
        'userFirstname',
        'userLastname',
        'userEmail',
        // inactive users are filtered by default
        'userActive',
      ].includes(column)),

      userColumnMenu: TableColumnBuilder.start(STRUCTURAL_COLUMN)
        .withColumnId('userColumnMenu')
        .build(),
    },
  };

}
