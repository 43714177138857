import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { BadgeTemplate } from '../../../core/gamification/gamification.types';
import { AdminGamificationService } from '../../../core/gamification/admin-gamification.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGamificationResolver
  implements Resolve<BadgeTemplate[]> {

  constructor(
    private adminGamificationService: AdminGamificationService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BadgeTemplate[]> {
    return this.adminGamificationService.getAllBadgeTemplates();
  }
}
