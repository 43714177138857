import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Attachment } from 'src/app/component/attachments/attachments.types';
import { Principal } from 'src/app/core/core.types';
import { TrainResponse } from 'src/app/core/global.types';
import { zeroFormat } from 'src/app/core/utils';

// todo: move transient fields into view
export class MailEntry {
  attachments?: Array<Attachment>;
  body?: string;
  context?: MailRequestExtraContext;
  created?: Date;
  draft?: boolean;
  draftTitle?: string;
  from?: string;
  id: number;
  read: boolean;
  received?: Date;
  receivers?: Array<Principal>;
  resendInterval?: string;
  selected?: boolean;
  sendDate?: number;
  senderId?: number;
  sent?: Date;
  status?: string;
  subject: string;
  workContextMode?: string;
}

export interface SearchApi
  extends TrainResponse {
  data: Principal[];
  total: number;
}

export interface TrainReport {
  id: number;
  selected?: boolean;
  title: string;
}

export interface MailBox {
  messages: MailEntry[];
  msgTotalCount: number;
  openedMessage?: MailEntry;
  pageSize: number;
}

export type MailBoxType = 'inbox' | 'outbox' | 'drafts' | 'planned';

// Model for the shedule settings
export class TimeSettings {
  repetitionStr: string;
  selectedDate: Moment = null;
  selectedTime: Moment = null;
  selectedTimeStr: string = null;
  titleStr: string;
  forceInvalid = false;

  get selectedTimestamp(): Moment {
    if ( this.isValid() && (this.selectedDate != null) && (this.selectedTime != null) ) {
      return this.selectedDate.clone()
        .startOf('day')
        .hours(this.selectedTime.hours())
        .minutes(this.selectedTime.minutes());
    }
    return null;
  }

  initWithMailEntry(mailEntry: MailEntry) {
    this.repetitionStr = mailEntry.resendInterval;
    this.titleStr = mailEntry.draftTitle;

    if ( mailEntry.sendDate ) {
      const selectedDate: Moment = moment(mailEntry.sendDate);
      this.selectedDate = selectedDate;
      const hours = selectedDate.hours();
      const minutes = selectedDate.minutes();
      this.selectedTimeStr = `${zeroFormat(hours, 2)}:${zeroFormat(minutes, 2)}`;
      this.selectedTime = moment(this.selectedTimeStr, 'LT', false);
    }
  }

  isValid() {
    if (this.forceInvalid) {
      return false;
    }
    this.selectedTime = null;
    if ( this.selectedTimeStr ) {
      const time = moment(this.selectedTimeStr, 'LT', false);
      if ( time.isValid() ) {
        this.selectedTime = time;
      }
    }
    // valid conditions are: 1) no settings 2) valid time and date
    return ((this.selectedDate == null) && (this.selectedTime == null)) ||
      ((this.selectedDate?.isValid() && this.selectedTime?.isValid()) ?? false);
  }

  reset() {
    this.repetitionStr = null;
    this.selectedDate = null;
    this.selectedTime = null;
    this.selectedTimeStr = null;
    this.titleStr = null;
  }
}

export class Upload {
  progress$: Observable<number>;
  uuid?: string;

  constructor(progress: Observable<number>) {
    this.progress$ = progress;
  }
}

export type ReportSettingsFunction = (workingContext: string, reports: TrainReport[]) => void;
export type TimeSettingsFunction = (timeSettings: TimeSettings) => void;
export type AttachmentsSettingsFunction = (attachments: string[]) => void;

export class MailMessage {
  body: string;
  subject: string;
}

export class MailComposerData
  extends MailMessage {
  attachments?: Array<string>;
  from: number;
  planDate?: number;
  planTitle?: string;
  reports?: Array<number>;
  resendInterval?: string;
  to: Array<number>;
  toTg: Array<number>;
  workContextMode?: any;

  applyAttachmentsSettings(attachments) {
    this.attachments = attachments;
  }

  applyReportsSettings(workingContext, reports) {
    this.workContextMode = workingContext;
    this.reports = reports.map(report => report.id);
  }

  applyTimeSettings(timeSettings) {
    const selectedTimestamp = timeSettings?.selectedTimestamp?.unix() * 1000;
    if ( selectedTimestamp > 0 ) {
      this.planDate = selectedTimestamp;
      this.resendInterval = timeSettings.repetitionStr;
      this.planTitle = '';
    } else {
      this.planDate = this.resendInterval = this.planTitle = null;
    }
  }
}

export class MailRequest
  extends MailMessage {
  attachmentUuids?: Array<string>;
  /**
   * e.g. used to define which OfflineContent, and OfflineEvent are referenced by the used macros.
   */
  context?: MailRequestExtraContext;
  from: number;
  planDate?: number;
  planTitle?: string;
  reports?: Array<number>;
  resendInterval?: string;
  to: Array<number>;
  toTg: Array<number>;
  toUserIds = false;
  workContextMode?: 'sender | receiver';
}

// eslint-disable-next-line
export interface MailRequestExtraContext {
}

export interface MailRequestExtraContextOffline
  extends MailRequestExtraContext {
  contentId: number;
  eventId: number;
}

export class MessageRequest
  extends MailMessage {
  contextMode?: 'sender' | 'receiver';
  filesUUIDs?: Array<string>;
  messageId?: number;
  receiversAccountIds: Array<number>;
  reportsIds?: Array<number>;
  scheduledDate?: number;
  scheduledInterval?: string;
  scheduledTitle?: string;
  senderAccountId: number;
}
