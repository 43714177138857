<mat-form-field appearance="outline" class="macro" [formGroup]="form">
  <mat-label>
    <span i18n="@@global_macro">Macro</span>
  </mat-label>
  <input
    matInput
    required
    [formControl]="formControl"
    placeholder="Macro"
    i18n-placeholder="@@global_macro"
    data-cy="inputMacro"
  >
  <mat-error *ngIf="getError(control) as error">{{ error }}</mat-error>
</mat-form-field>
