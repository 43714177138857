import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableSingleActionsComponent } from './report-table-single-actions.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [ ReportTableSingleActionsComponent ],
  exports: [
    ReportTableSingleActionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
  ],
})
export class ReportTableSingleActionsModule { }
