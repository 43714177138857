<rag-dialog-header>
  <span>{{content.title}}</span>
</rag-dialog-header>

<div mat-dialog-content>
  <rag-curriculum-info [curriculum]="content"></rag-curriculum-info>
</div>

<div mat-dialog-actions>
  <button [mat-dialog-close] cdkFocusInitial class="rag-stroked-button" mat-stroked-button>Ok</button>
</div>
