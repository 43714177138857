import { Component, Input, OnInit } from '@angular/core';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { ModalDialog } from 'src/app/core/modal-dialog';
import { NewsService } from 'src/app/core/news/news.service';
import { News } from 'src/app/core/news/news.types';
import { ReceivedResponseEvent } from 'src/app/route/user/certificates/certificates.types';
import { NewsPreviewDialogComponent } from '../news-widget/news-preview-dialog/news-preview-dialog.component';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent, WidgetsUUID } from '../widgets.types';
import { finalize, take } from 'rxjs/operators';
import { LanguageHelper } from '../../../core/language.helper';
import { TextDecodeHelper } from '../../../core/translation/text-decode.helper';

@Component({
  selector: 'rag-specials-widget',
  templateUrl: './specials-widget.component.html',
  styleUrls: [ './specials-widget.component.scss' ],
})
export class SpecialsWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent, OnInit {

  static WidgetUUID = WidgetsUUID.SpecialsWidgetUUID;

  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasResponse = false;
  isDialogDisplayed = false;
  news: News = null;
  newsImageUrl: string = null;

  constructor(private newsService: NewsService, private dialog: ModalDialog) {
    super();

    this.setTitle($localize`:@@widget_specials_title:Welcome`);
  }

  hasText(
    news: News | null,
  ): boolean {
    return (news?.text != null) &&
      TextDecodeHelper.decodeHtml(LanguageHelper.objectToText(news.text)).trim() !== '';
  }

  set receivedResponse(receivedResponse: ReceivedResponseEvent) {
    this.hasResponse = true;
    this.didReceiveResponse(receivedResponse);
    if ( receivedResponse.hasContent ) {
      this.setTitle('');
    }
  }

  // let the wrapper display a spinner as long the response is not received
  isDataDriven() {
    return true;
  }

  ngOnInit() {
    this.newsService.loadSpecials().subscribe(news => {

      if (news.length === 0) {
        this.receivedResponse = {
          hasContent: false,
        };
        return;
      }

      const _news = news.sort((n1, n2) => n2.published - n1.published)[0];
      this.newsImageUrl = ImageUrlHelper.urlForPictureId(_news.pictureId);
      this.receivedResponse = {
        hasContent: true,
      };
      this.news = _news;
    });
  }

  onClick() {

    if ( !this.hasText(this.news) ) {
      // nothing to display
      return;
    }

    if ( this.isDialogDisplayed ) {
      return;
    }
    this.isDialogDisplayed = true;

    this.dialog.openModal(NewsPreviewDialogComponent, {
      data: {
        news: this.news,
      },
      panelClass: 'max-width-dialog-container',
    }).afterClosed()
      .pipe(finalize(() => this.isDialogDisplayed = false))
      .pipe(take(1))
      .subscribe();
  }

}
