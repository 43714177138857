<div
  class="column"
  *ngFor="let columnId of columnIds"
  [ngStyle]="{'width': 'calc(100% / ' + this.columnCount + ')'}"
>
  <rag-profile-section
    *ngFor="let section of columns[columnId]"
    [section]="section"
    [disabled]="disabled"
  ></rag-profile-section>
</div>
