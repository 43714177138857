import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentsModule } from '../../admin/externals/ext-logins/components/components.module';
import { RegisterDataPreviewComponent } from './register-data-preview/register-data-preview.component';
import { SelfRegisterActivationComponent } from './self-register-activation/self-register-activation.component';
import { SelfRegisterRoutingModule } from './self-register-routing.module';
import { SelfRegisterComponent } from './self-register.component';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatTableModule } from '@angular/material/table';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { InfoTooltipButtonComponent } from 'src/app/component/info-tooltip-button/info-tooltip-button.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { PasswordValidationComponent } from 'src/app/component/password-validation/password-validation.component';


@NgModule({
  declarations: [
    SelfRegisterActivationComponent,
    SelfRegisterComponent,
    RegisterDataPreviewComponent,
  ],
  exports: [ SelfRegisterComponent ],
  imports: [
    CommonModule,
    ComponentsModule,
    PasswordValidationComponent,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    PageHeaderComponent,
    SelfRegisterRoutingModule,
    DialogTitleComponent,
    DialogHeaderComponent,
    PipesModule,
    InfoTooltipButtonComponent,
    MatTableModule,
  ],
})

export class SelfRegisterModule {
}
