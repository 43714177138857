import { HttpUrlEncodingCodec } from '@angular/common/http';


/**
 * @see https://github.com/angular/angular/issues/11058#issuecomment-292457108
 * @see https://github.com/angular/angular/issues/11058#issuecomment-636114557
 */
export class RagHttpParameterCodec
  extends HttpUrlEncodingCodec {

  private static fixEncoding(value: string) {
    if ( value == null ) {
      return value;
    }
    return value.replace(/\+/gm, '%2B');
  }

  encodeKey(key: string): string {
    return RagHttpParameterCodec.fixEncoding(super.encodeKey(key));
  }

  encodeValue(value: string): string {
    return RagHttpParameterCodec.fixEncoding(super.encodeValue(value));
  }

}
