import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { DetailsRowModule } from '../../../component/table/details-row/details-row.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ContentOfflineModule } from './content-offline/content-offline.module';
import { ContentComponent } from './content.component';
import { ContentActionModule } from '../../../component/elearning/content-action/content-action.module';
import { CurriculumInfoModule } from '../../../component/elearning/curriculum-info/curriculum-info.module';
import { CurriculumTitleModule } from '../../../component/elearning/curriculum-title/curriculum-title.module';
import { ItemTypeIconModule } from '../../../component/elearning/item-type-icon/item-type-icon.module';
import { StatusLightsModule } from '../../../component/elearning/status-lights/status-lights.module';
import { ItemListModule } from '../../../component/elearning/item-list/item-list.module';
import { ImportantContentInfoModule } from '../../../component/elearning/important-content-info/important-content-info.module';
import { LearnerContentUtilModule } from './learner-content-util/learner-content-util.module';
import { LearnerCourseModule } from './learner-course/learner-course.module';
import { ContainerComponent } from 'src/app/component/container/container.component';
import { AttachmentsComponent } from 'src/app/component/attachments/attachments.component';


@NgModule({
  declarations: [
    ContentComponent,
  ],
  exports: [
    ContentComponent,
  ],
  imports: [
    BreadcrumbsModule,
    CommonModule,
    ContainerComponent,
    ContentActionModule,
    ContentOfflineModule,
    CurriculumInfoModule,
    CurriculumTitleModule,
    DetailsRowModule,
    ImportantContentInfoModule,
    ItemListModule,
    ItemTypeIconModule,
    LearnerContentUtilModule,
    LearnerCourseModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    PipesModule,
    RouterModule,
    StatusLightsModule,
    AttachmentsComponent
  ],
})
export class ContentModule {
}
