import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSignaturesUsageDialogComponent } from './admin-signatures-usage-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';


@NgModule({
  declarations: [
    AdminSignaturesUsageDialogComponent
  ],
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
    RouterModule,
  ]
})
export class AdminSignaturesUsageDialogModule { }
