import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfRegisterFormComponent } from './self-register-form.component';
import { ProfileSectionModule } from '../../../../component/input/profile-section/profile-section.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterLink } from '@angular/router';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [ SelfRegisterFormComponent ],
  exports: [ SelfRegisterFormComponent ],
  imports: [
    CommonModule,
    ProfileSectionModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    PageHeaderComponent,
    MatCheckboxModule,
    LoadingIndicatorComponent,
    RouterLink,
  ],
})
export class SelfRegisterFormModule {
}
