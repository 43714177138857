<rag-receiver-field
  (click)="focusFunction()"
  (gotFocus)="focusFunction()"
  *ngIf="!focused"
  [displayAll]="displayAll"
  [items]="selectedPrincipals"
  [placeholder]="placeholder">
</rag-receiver-field>

<ng-select
  #ngselect
  (blur)="focusOutFunction()"
  [(ngModel)]="selectedPrincipals"
  (ngModelChange)="onModelChange($event)"
  *ngIf="focused"
  [addTag]="false"
  [compareWith]="compareFunc"
  [groupBy]="groupByFunc"
  [groupValue]="groupValueFunc"
  [hideSelected]="true"
  [items]="receivers$ | async"
  [loading]="isLoading"
  [multiple]="true"
  [placeholder]="placeholder"
  [typeahead]="receiverInput$"
  bindLabel="name"
  class="custom"
  [dropdownPosition]="dropdownPosition"
  [searchable]="true"
  [appendTo]="appendPopupTo"
>

  <!-- Template for the grouping elements in drop-down -->
  <ng-template let-item="item" ng-optgroup-tmp>
    <div style="display: flex; flex-direction: row; align-items: center ">
      <div class="badge-lable">{{item.name}}</div>
      <div class="badge-count">
        <div>{{item.total}}</div>
      </div>
    </div>
  </ng-template>
</ng-select>
