import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '../../../component/breadcrumbs/breadcrumbs.module';
import { ToggleFullscreenModule } from '../../../component/elearning/toggle-fullscreen/toggle-fullscreen.module';
import { CoreModule } from '../../../core/core.module';
import { TransactionsComponent } from './transactions.component';
import { MatCardModule } from '@angular/material/card';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import { TableColumnMenuModule } from '../../../component/table/table-column-menu/table-column-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AdminCurriculumActionsModule } from '../../admin/admin-curriculum/admin-curriculum-actions/admin-curriculum-actions.module';
import { TablesColumnRenderDefaultModule } from '../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { TableHeaderFilterDefaultModule } from '../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { TransactionsListActionsModule } from './transactions-list/transactions-list-actions/transactions-list-actions.module';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { StickyScrollComponent } from 'src/app/component/sticky-scroll/sticky-scroll.component';

@NgModule({
  declarations: [
   TransactionsComponent,
   TransactionsListComponent,
  ],
  exports: [
    TransactionsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ToggleFullscreenModule,
    BreadcrumbsModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    DialogTitleComponent,
    DialogHeaderComponent,
    PageHeaderComponent,
    MatCardModule,
    TableColumnMenuModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AdminCurriculumActionsModule,
    LoadingIndicatorComponent,
    StickyScrollComponent,
    TablesColumnRenderDefaultModule,
    TableHeaderFilterDefaultModule,
    TransactionsListActionsModule,
  ],
})
export class TransactionsModule {
}
