<form [formGroup]="form" class="center-content" (ngSubmit)="onSave()">

  <p i18n="@@post_login_user_data_intro">There are still some details missing from your profile. Please complete them
    at your earliest convenience.</p>

  <rag-profile-section
    *ngIf="section != null"
    [section]="section"
  ></rag-profile-section>

  <rag-profile-section-wrapper
    *ngIf="section == null && attributeGroups != null"
    [sections]="attributeGroups"
  ></rag-profile-section-wrapper>

  <div class="innerWrapper">
    <div class="buttons"></div>
    <div class="buttons">
      <button
        mat-stroked-button
        type="button"
        class="rag-stroked-button"
        (click)="onNotNow()"
        [disabled]="form.invalid"
      >
        <span i18n="@@post_login_user_data_not_now">Not now</span>
      </button>

      <button
        [disabled]="form.pristine || form.invalid"
        color="primary"
        mat-flat-button
        type="submit"
        class="submit-button"
      >
        <span i18n="@@global_next">Next</span>
      </button>
    </div>
  </div>
</form>
