import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-container',
  templateUrl: './container.component.html',
  styleUrls: [ './container.component.scss' ],
})
export class ContainerComponent
  implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
