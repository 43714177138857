import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportGeneratorV2Component } from './report-generator-v2.component';
import { ReportTableModule } from './report-table/report-table.module';
import { ReportNavComponent } from '../report-nav/report-nav.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';



@NgModule({
  declarations: [ReportGeneratorV2Component],
  imports: [
    CommonModule,
    ReportTableModule,
    ReportNavComponent,
    PageHeaderComponent,
  ],
})
export class ReportGeneratorV2Module { }
