import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { ApiUrls } from '../../../core/api.urls';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { InfoType } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import { SelfRegAdminRequest, SelfRegAdminResponse } from './admin-self-registration.types';

@Injectable({ providedIn: 'root' })
export class AdminSelfRegistrationService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getSettings(): Observable<SelfRegAdminResponse> {
    const url = ApiUrls.getKey('SelfRegistrationAdmin');
    return this.http.get<SelfRegAdminResponse>(url);
  }

  saveSettings(data: SelfRegAdminRequest): Observable<SelfRegAdminResponse | never> {
    const url = ApiUrls.getKey('SelfRegistrationAdmin');
    return this.http.post<SelfRegAdminResponse>(url, data)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }));
  }

}
