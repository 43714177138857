import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { RuntimeEnvironmentService } from '../../../../core/runtime-environment.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { AccountInterceptor } from '../../../../core/interceptors/account.interceptor';


@Component({
  selector: 'rag-self-register-bridge',
  templateUrl: './self-register-bridge.component.html',
  styleUrls: [ './self-register-bridge.component.scss' ],
})
export class SelfRegisterBridgeComponent
  implements OnDestroy {

  version: 'v1' | 'v2' = null;

  constructor(
    private runtimeEnvironmentService: RuntimeEnvironmentService,
  ) {
    this.runtimeEnvironmentService.environment$
      .pipe(tap(env => {
        // only enable v2 on iLearn, if account key is included in iLearnSelfRegisterV2
        const accountKey = AccountInterceptor.getAccountKey();
        this.version = (env.hideForIlearn && !env.iLearnSelfRegisterV2?.includes(accountKey)) ? 'v1' : 'v2';
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getTemplate(
    tplLoading: TemplateRef<any>,
    tplSelfRegisterV1: TemplateRef<any>,
    tplSelfRegisterV2: TemplateRef<any>,
  ): TemplateRef<any> {
    switch ( this.version ) {

      case 'v1':
        return tplSelfRegisterV1;

      case 'v2':
        return tplSelfRegisterV2;

      default:
        return tplLoading;
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }
}
