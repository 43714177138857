import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CtrlSingleUserDetailsCourseTypes } from '../ctrl-single-user-details-course/ctrl-single-user-details-course.types';
import { ControllingSingleUserService } from '../../ctrl-single-user-util/ctrl-single-user.service';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'rag-ctrl-single-user-details-learning-data-dialog',
  templateUrl: './ctrl-single-user-details-learning-data-dialog.component.html',
  styleUrls: ['./ctrl-single-user-details-learning-data-dialog.component.scss']
})
export class CtrlSingleUserDetailsLearningDataDialogComponent implements OnInit {

  inputData: CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams;
  formGroup = new UntypedFormGroup({});
  saveButtonDisabled = true;
  readonly submitClicked$: Observable<boolean>;
  private _submitClicked = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<CtrlSingleUserDetailsLearningDataDialogComponent>,
    private ctrlService: ControllingSingleUserService,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams,
  ) {
    this.submitClicked$ = this._submitClicked.asObservable();
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.ctrlService.confirmClose(this.dialogRef, this.formGroup?.dirty === true);
  }

  formGroupHasChanged(saveButtonDisabled: boolean) {
    this.saveButtonDisabled = saveButtonDisabled;
  }

  onSubmitClicked() {
    this._submitClicked.emit(true);
  }
}
