import { Translation } from './translation/translation.types';
import { LanguageHelper } from './language.helper';


const LANGUAGE = navigator.language || navigator.languages?.[0] || 'de';

// @see http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
export const naturalCompare = (a: string | number | Translation, b: string | number | Translation,
  caseInsensitive = true): number => {

  a = LanguageHelper.objectToText(a);
  b = LanguageHelper.objectToText(b);
  if ( caseInsensitive ) {
    a = a.toLocaleLowerCase(LANGUAGE);
    b = b.toLocaleLowerCase(LANGUAGE);
  }
  return a.localeCompare(b, LANGUAGE, { numeric: true, ignorePunctuation: true });
};
