<ng-container *ngIf="isOfflineContent">

  <ng-template #tplBookable>
    <button
      mat-icon-button
      [routerLink]="url"
      #tooltip="matTooltip"
      [matTooltip]="infoTooltip"
      [disabled]="locked"
      color="primary">
      <mat-icon svgIcon="information-outline"></mat-icon>
    </button>
  </ng-template>

  <ng-template #tplBooked>
    <span
      class="info-text has-booked"
      i18n="@@offline_cnt_event_schedule_booked"
    >scheduled</span>
  </ng-template>

  <ng-template #tplNotBookable>
    <!-- no actions available -->
  </ng-template>

  <ng-container
    *ngTemplateOutlet="getTemplate(tplBookable, tplBooked, tplNotBookable)"
  ></ng-container>

</ng-container>
