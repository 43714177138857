import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from './api.urls';
import { Core } from './core.types';
import { ApiResponse, HttpRequestOptions, TrainResponse } from './global.types';
import { MacroContextSettings, MacroContext } from './macros.types';

@Injectable({
  providedIn: 'root',
})
export class MacrosService {

  constructor(
    private http: HttpClient
  ) {}

  getMacros(macrosContext: MacroContext): Observable<MacroContextSettings> {
    const url = ApiUrls.getKey('TextMacroSettings').replace('{macroContext}', macrosContext);
    return this.http.get<ApiResponse<MacroContextSettings>>(url).pipe(map(response => response.data));
  }

  getCourseTexts(): Observable<MacroContextSettings> {
    const url = ApiUrls.getKey('CourseMacroTexts');
    return this.http.get<ApiResponse<MacroContextSettings>>(url)
      .pipe(map(response => response.data));
  }

  resolveTextForUser(text: string, objType: Core.DistributableType, objId: number, userId: number): Observable<string> {
    const url = `${ApiUrls.getKey('MacroResolveText')}/user/${userId}/v1`
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    const payload = {
      text
    };
    return this.http.post<TrainResponse<{value: string}>>(url, payload, HttpRequestOptions)
      .pipe(map(response => response.data.value));
  }

  resolveText(text: string, objType: Core.DistributableType, objId: number): Observable<string> {
    const url = `${ApiUrls.getKey('MacroResolveText')}/v1`
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    const payload = {
      text
    };
    return this.http.post<TrainResponse<{value: string}>>(url, payload, HttpRequestOptions)
      .pipe(map(response => response.data.value));
  }
}
