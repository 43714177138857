<div class="top-controls">
  <div class="top-statistics">
    <rag-report-table-charts
      *ngIf="reportConfig?.targetType === 'Curriculum'"
    ></rag-report-table-charts>
  </div>

  <div class="top-actions">
    <button
      (click)="onSaveReport()"
      [disabled]="!canSave || hasSelections"
      color="primary"
      mat-flat-button
      type="button"
    >
      <mat-icon svgIcon="content-save"></mat-icon>
      <span i18n="@@global_save">Save</span>
    </button>

    <rag-report-table-multi-actions
    ></rag-report-table-multi-actions>
  </div>
</div>

<rag-report-table-grouping
  *ngIf="!isOffline"
></rag-report-table-grouping>

<div class="bottom-controls">

  <div class="bottom-statistics">
    <rag-status-statistics
      *ngIf="!isNothing(reportStatistics) && !isOffline"
      [contentType]="reportConfig?.targetType"
      [showTotal]="true"
      [statistics]="reportStatistics"
    ></rag-status-statistics>
  </div>

  <div class="bottom-actions"></div>
</div>
