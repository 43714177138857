import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { CtrlUserListComponent } from './ctrl-user-list.component';


@NgModule({
  declarations: [ CtrlUserListComponent ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    PipesModule,
  ],
})
export class CtrlUserListModule {
}
