import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import * as _ from 'lodash';
import { FileInfo } from 'src/app/core/core.types';
import { ApiResponse } from 'src/app/core/global.types';
import { FileAuthorizationRefType, FileAuthorizationType } from './file-upload.types';

@Injectable()
export class FileUploadService {

  constructor(
    private http: HttpClient,
  ) {}

  asFileUpload() {

  }

  uploadFile(file: any, apiUrlsKey: string): Observable<HttpEvent<any>> {
    return this.http.request('POST', ApiUrls.getKey(apiUrlsKey), { body: file, observe: 'events', reportProgress: true }).pipe(
      map(event => this.getEventMessage(event, file)),
    );
  }

  uploadAttachment(fileUUID: string, file: File): Observable<FileInfo> {

    const url = `${ApiUrls.getKey('Files_Upload').replace('{authType}', 'login_required')}/${fileUUID}`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ApiResponse<FileInfo>>(url, formData)
      .pipe(map(response => response.file));
  }

  uploadAttachmentWithProgress(fileUUID: string, file: File) {

    const url = `${ApiUrls.getKey('Files_Upload').replace('{authType}', 'login_required')}/${fileUUID}`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    return this.http.request(req);
  }

  deleteFile(fileUUID: string): Observable<boolean> {
    const url = `${ApiUrls.getKey('Files')}/${fileUUID}`;
    return this.http.delete<ApiResponse<void>>(url).pipe(map(response => response.success));
  }

  uploadFileV2(fileUUID: string, file: File, authType: FileAuthorizationType,
    authRefType: FileAuthorizationRefType = FileAuthorizationRefType.tbd): Observable<FileInfo> {

    const url = ApiUrls.getKey('Files_Upload_V2')
      .replace(/{authType}/gi, FileAuthorizationType[authType])
      .replace(/{authRefType}/gi, FileAuthorizationRefType[authRefType])
      .replace(/{fileUUID}/gi, fileUUID);

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<ApiResponse<FileInfo>>(url, formData)
      .pipe(map(response => response.file));
  }

  uploadFileV2MarkAsUsed(
    fileUUID: string,
    authRefType: FileAuthorizationRefType = FileAuthorizationRefType.tbd
  ): Observable<void> {
    const url = ApiUrls.getKey('Files_Upload_V2_mark_used')
      .replace(/{fileAuthRefType}/gi, FileAuthorizationRefType[authRefType])
      .replace(/{fileUUID}/gi, fileUUID);

    return this.http.post<ApiResponse<FileInfo>>(url, null)
      .pipe(mapTo(void(0)));

  }

  fetchFileInfo(fileUUID: string): Observable<FileInfo> {
    const url = `${ApiUrls.getKey('Files')}/${fileUUID}/info`;
    return this.http.get<ApiResponse<FileInfo>>(url)
      .pipe(map(response => response.fileInfo));
  }

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>, input: FormData): HttpEvent<any> {
    const file = input.get('file') as File;
    let message = '';
    switch ( event.type ) {
      case HttpEventType.Sent:
        message = `Uploading file "${file.name}" of size ${file.size}.`;
        this.showProgress(message);
        return event;

      case HttpEventType.UploadProgress: {
        // Compute and show the % done:
        const percentDone = _.round(100 * (event.loaded / event.total), 2);
        message = `File "${file.name}" is ${percentDone}% uploaded.`;
        this.showProgress(message);
        return event;
      }

      case HttpEventType.Response:
        message = `File "${file.name}" was completely uploaded!`;
        this.showProgress(message);
        return event;

      default:
        message = `File "${file.name}" surprising upload event: ${event.type}.`;
        this.showProgress(message);
        return event;
    }
  }

  private showProgress(message: string) {
    console.log(message);
  }
}
