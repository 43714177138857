import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AnyObject } from '../core.types';
import { EMPTY, Observable, startWith } from 'rxjs';
import { CachedSubject } from '../cached-subject';


interface QueryParamsHolder {
  mode: 'patch' | 'route' | 'silent-patch';
  queryParams: AnyObject<string>;
}

@Injectable({ providedIn: 'root' })
export class QueryParamsService {

  readonly queryParams$: Observable<QueryParamsHolder>;
  private _queryParams$ = new CachedSubject<QueryParamsHolder>(null);

  constructor(
    private router: Router,
  ) {

    this.queryParams$ = this._queryParams$.withoutEmptyValues()
      // prevent race condition when reloading page
      .pipe(startWith(<QueryParamsHolder>{
        mode: 'route',
        queryParams: this.router.parseUrl(this.router.url)?.queryParams
      }));

    // automatically update route when params are patched
    this.queryParams$
      // only update if mode 'patch'
      .pipe(filter(entry => entry.mode === 'patch'))

      // prevent triggering too often
      .pipe(debounceTime(150))

      // ignore duplicates
      .pipe(distinctUntilChanged(QueryParamsService.distinctParams))

      // replace query params for route
      .pipe(map(params => {
        const urlTree = this.router.parseUrl(this.router.routerState.snapshot.url);
        urlTree.queryParams = params.queryParams;
        return urlTree;
      }))

      // navigate relative to current
      // .pipe(tap(urlTree => console?.log('storing query params', urlTree))) TODO: make it configureable
      .pipe(switchMap(urlTree => this.router.navigateByUrl(urlTree, { state: { mode: 'patch' } })))

      .subscribe();

    // copy params from navigation events
    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))

      // prevent recursive updates from prior patch
      .pipe(filter(() => this.router.getCurrentNavigation().extras?.state?.mode !== 'patch'))

      // insert current queryParams from active route
      .pipe(map(evt => this.router.parseUrl((evt as NavigationEnd).url)))

      // ignore empty values -> e.g. navigation away from a page with filters
      .pipe(filter(urlTree => Object.keys(urlTree?.queryParamMap ?? {}).length > 0))

      // prevent duplicates
      .pipe(distinctUntilChanged(QueryParamsService.distinctParams))

      // update local copy on changes
      .pipe(tap(params => this._queryParams$.next({ mode: 'route', queryParams: params.queryParams })))

      .subscribe();

  }

  get queryParams(): AnyObject<string> {
    return this._queryParams$.value?.queryParams ?? {};
  }

  private static asString(params: AnyObject<string>): string {
    const entries = Object.entries(params ?? {})

      // sort attributes to improve change detection
      .sort((a, b) => a[0].localeCompare(b[0]));

    // stringify result
    return JSON.stringify(entries);
  }

  /**
   * compare two input states.
   * @returns true, if unchanged
   */
  private static distinctParams(from: Pick<QueryParamsHolder, 'queryParams'>,
    to: Pick<QueryParamsHolder, 'queryParams'>): boolean {
    return QueryParamsService.asString(from?.queryParams) === QueryParamsService.asString(to?.queryParams);
  }

  clear(): void {
    this._queryParams$.next({ mode: 'silent-patch', queryParams: {} });
  }

  patchQueryParams(value: AnyObject<string>, updateUrl = true): void {

    const mode = updateUrl ? 'patch' : 'silent-patch';
    const newParams = {

      // preserve unset values
      ...this.queryParams,

      // override any defined values
      ...value,
    };

    this._queryParams$.next({ mode, queryParams: newParams });
  }

  /**
   * observe only selected attributes for state
   */
  pick(attributes: string[]): Observable<AnyObject<string>> {
    if ( !(attributes?.length > 0) ) {
      console?.warn('pick with empty attributes!');
      return EMPTY;
    }

    return this.queryParams$
      .pipe(map(entry => entry.queryParams))

      // fetch only requested attributes / missing attributes are set to empty
      .pipe(map(params => attributes.reduce((pV, attribute) => {
        pV[attribute] = params[attribute] ?? '';
        return pV;
      }, {})))

      // compare string version of params
      .pipe(distinctUntilChanged((x, y) =>
        QueryParamsService.distinctParams({ queryParams: x }, { queryParams: y })));
  }

}
