import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ExternalLogin } from 'src/app/core/global.types';
import { ExtLoginDialogComponent } from './components/ext-login-dialog/ext-login-dialog.component';
import { ExtLoginsService } from '../../../../core/ext-logins.service';
import { InfoService } from 'src/app/core/info/info.service';
import { CancelButton, DeleteButton, InfoType, MessageKey } from 'src/app/core/info/info.types';
import { ActivatedRoute } from '@angular/router';
import { destroySubscriptions, subscribeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { MatSort } from '@angular/material/sort';
import { State } from 'src/app/app.state';
import { MatPaginator } from '@angular/material/paginator';
import { EMPTY, Observable, of } from 'rxjs';
import { ModalDialog } from '../../../../core/modal-dialog';
import { AdobeConnectService } from 'src/app/core/adobe-connect/adobe-connect.service';


@Component({
  selector: 'rag-ext-logins',
  templateUrl: './ext-logins.component.html',
  styleUrls: [ './ext-logins.component.scss' ],
})
export class ExtLoginsComponent
  implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: MatTableDataSource<ExternalLogin>;
  displayedColumns = [ 'serverName', 'serverType', 'login', 'url', 'menu' ];

  constructor(
    private dialog: ModalDialog,
    private infoService: InfoService,
    private acivatedRouting: ActivatedRoute,
    private extLoginService: ExtLoginsService,
    private adobeConnectService: AdobeConnectService) {

    this.dataSource = new MatTableDataSource([]);
  }

  get hasData() {
    return this.dataSource.data.length > 0;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    subscribeUntilDestroyed(this.acivatedRouting.data.pipe(map(data => {
      this.dataSource.data = data.logins;
    })), this);
  }

  onCopyLogin(extLogin: ExternalLogin) {
    const copyExtLogin = { ...extLogin };
    delete copyExtLogin.id;
    copyExtLogin.uuid = uuid.v4();
    copyExtLogin.serverName = State.language === 'de' ? 'Kopie von ' + extLogin.serverName : 'Copy of ' + extLogin.serverName;
    this.onEditLogin(copyExtLogin);
  }

  onDeleteLogin(extLogin: ExternalLogin) {
    const extLoginUuid = extLogin?.uuid;
    this.extLoginService.usageConfirm(extLoginUuid, 'remove')
      .pipe(switchMap(usageCount => {
        if ( usageCount > 0 ) {
          // user already confirmed deletion
          return of(DeleteButton);
        } else {
          return this.infoService.showConfirm(MessageKey.EXT_LOGIN.DELETE.CONFIRM, CancelButton | DeleteButton);
        }
      }))
      .pipe(takeWhile(button => button === DeleteButton))
      .pipe(switchMap(() => this.extLoginService.deleteExtLogin(extLoginUuid)))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.EXT_LOGIN.DELETE.FAILED, InfoType.Error);
        return EMPTY;
      }))
      .pipe(tap(() => {
        this.dataSource.data = this.dataSource.data
          .filter(obj => obj.uuid !== extLoginUuid);
        this.infoService.showSnackbar(MessageKey.EXT_LOGIN.DELETE.SUCCESS, InfoType.Success);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onTestLogin(extLogin: ExternalLogin) {
    this.infoService.showMessage($localize`:@@admin_ext_login:Please wait, the credentials are being tested ...`, {
      infoType: InfoType.Information
    });
    this.extLoginService.test(extLogin.uuid)
    .pipe(tap(() => {
      this.infoService.showMessage($localize`:@@admin_ext_login_test_success:The test completed successfully`, {
        infoType: InfoType.Success
      });
    }))
    .pipe(catchError(err => {
      if (err.errors) {
        const error = err.errors[0];
        if (error.errorCode === 'ERR_AC_LOGIN_FAILED') {
          this.infoService.showMessage($localize`:@@admin_ext_login_test_failed:Test has failed! The credentials or the url are not correct`, {
            infoType: InfoType.Error
          });
        } else {
          this.infoService.showMessage($localize`:@@admin_ext_login_test_unknown:Test has failed! Check your registration data and try again`, {
            infoType: InfoType.Error
          });
        }
      }
      return EMPTY;
    }))
    .pipe(take(1))
    .subscribe();
  }

  onEditLogin(login: ExternalLogin) {
    const extLoginUuid = login?.uuid;
    this.extLoginService.usageConfirm(extLoginUuid, 'edit')
      .pipe(switchMap(() => this.showDialog(login)))
      .pipe(switchMap(result => this.extLoginService.saveExtLogin(result)))
      .pipe(catchError(this.extLoginService.handleUpdateError))
      .pipe(tap(result => {
        login.login = result.login;
        login.url = result.url;
        login.password = result.password;
        login.serverName = result.serverName;
        login.serverType = result.serverType;

        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`, {
          infoType: InfoType.Success
        });
      }))
      .pipe(take(1))
      .subscribe();
  }

  onNewExtLogin() {
    this.showDialog()
      .pipe(switchMap(result => this.extLoginService.saveExtLogin(result)))
      .pipe(catchError(this.extLoginService.handleUpdateError))
      .pipe(tap(this.addExtLoginToCollection))
      .pipe(take(1))
      .subscribe();
  }

  private addExtLoginToCollection = (extLogin: ExternalLogin) => {
    const newDataCollection = [ ...this.dataSource.data ];
    newDataCollection.push(extLogin);
    this.dataSource.data = newDataCollection;
    this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`, {
      infoType: InfoType.Success
    });
  };

  private showDialog(extLogin?: ExternalLogin): Observable<ExternalLogin> {
    return this.dialog.open<any, ExternalLogin, ExternalLogin>(ExtLoginDialogComponent, { minWidth: '35vw', data: extLogin })
      .afterClosed()
      .pipe(takeWhile(result => result != null));
  }

}
