import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { merge } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiUrls } from '../../../core/api.urls';
import { CachedSubject } from '../../../core/cached-subject';
import { FaviconService } from '../../../core/favicon/favicon.service';
import { TrainResponse } from '../../../core/global.types';
import { AccountInterceptor } from '../../../core/interceptors/account.interceptor';
import { PreloadService } from '../../../core/preload.service';
import { PrincipalService } from '../../../core/principal/principal.service';
import {
  StartPageSettings,
  StartPageSettingsImpl,
  StyleSettings,
  StyleSettingsImpl,
  TrainImage,
} from './account-design.types';


// allow overriding useNextGen setting
const forceNextGen = /.+[?&]nextGen=true(?:[#&].+)?/.test(document.location.href);

@Injectable({
  providedIn: 'root',
})
export class AccountDesignService {

  private images_ = new CachedSubject<TrainImage[]>(null);
  private pageTitle: string | null;
  private startPage_ = new CachedSubject<StartPageSettings>(null);
  private styleSettings_ = new CachedSubject<StyleSettings>(null);

  constructor(
    private faviconService: FaviconService,
    private http: HttpClient,
    private preloadService: PreloadService,
    private principalService: PrincipalService,
    private titleService: Title,
  ) {
    this.principalService.fetchUserData()
      .pipe(filter(CachedSubject.isEmpty))
      .pipe(switchMap(this.reloadDesignSettings))
      .subscribe();

    this.startPage$
      .pipe(map((startPage) => {
        if ( !forceNextGen && !startPage.acc.useNextGen ) {
          // redirect to classic front-end
          window.location.href = ApiUrls.getKey('ClassicFrontEnd');
        }
      }))
      .subscribe();

    this.styleSettings$
      .pipe(tap(styleSettings => {
        this.setPageTitle(styleSettings.acc.pageTitle);

        // update favicon
        if ( styleSettings.acc.faviconId > 0 ) {
          this.faviconService.activateSourceByFileId(styleSettings.acc.faviconId);
        } else {
          // console.log('Resetting Favicon');
          this.faviconService.resetFavicon();
        }
      }))
      .subscribe();
  }

  get images$(): Observable<TrainImage[]> {
    return this.images_.withoutEmptyValuesWithInitial();
  }

  get startPage$(): Observable<StartPageSettings> {
    return this.startPage_.withoutEmptyValuesWithInitial()
      // filter identical objects
      .pipe(distinctUntilChanged());
  }

  get styleSettings$(): Observable<StyleSettings> {
    return this.styleSettings_.withoutEmptyValuesWithInitial()
      // filter identical objects
      .pipe(distinctUntilChanged());
  }

  get newNavigationEnabled$(): Observable<boolean> {
    return this.styleSettings$
      .pipe(map(styleSettings => styleSettings.acc.newNavigationEnabled));
  }

  get fullScreenSizeEnabled$(): Observable<boolean> {
    return this.styleSettings$
      .pipe(map(styleSettings => styleSettings.acc.fullScreenSizeEnabled));
  }

  deleteImage(id): Observable<TrainResponse> {
    const query = ApiUrls.getKey('DeleteAccountImage').replace('{id}', id);
    return this.http.delete<TrainResponse>(query);
  }

  getImages(): Observable<TrainImage[]> {
    return this.http.get<TrainResponse>(ApiUrls.getKey('GetAccountImages'))
      .pipe(switchMap(response => {
        const images: TrainImage[] = [];
        const data = response.data;
        data.forEach((picture) => {
          const image: TrainImage = new TrainImage();
          image.fileId = picture.fileId;
          image.id = picture.id;
          image.fileType = picture.fileType;
          image.fileName = picture.fileName;
          image.link = ApiUrls.getKey('ShowFileById')
            .replace(/{fileId}/gi, String(picture.fileId));

          images.push(image);
        });

        this.images_.next(images);

        return this.images$;
      }), finalize(this.images_.queryDone));
  }

  getStartPage(reset = false): Observable<StartPageSettings> {
    if ( reset ) {
      this.startPage_.reset();
    }

    if ( this.startPage_.queryStart() ) {
      this.preloadService.getStartPage(reset)
        .pipe(
          map(response => new StartPageSettingsImpl((response || {}).data)),
          catchError(() => of(new StartPageSettingsImpl())),
        )
        .pipe(
          map(startPageSettings => {
            if ( startPageSettings.accountKey ) {
              this.principalService.accountId = startPageSettings.accountId;
              AccountInterceptor.writeAccountKeyToStorage(startPageSettings.accountKey);
            } else {
              // todo find sensible message that informs the user that the server is down
              // this.infoService.showAlert($localize`:@@global_no_key:No key is found in your browser. Please, contact your system administrator for further information.`);
            }
            this.startPage_.next(startPageSettings);
          }),
          finalize(this.startPage_.queryDone),
        )
        .subscribe();
    }
    return this.startPage$;
  }

  getStyleSettings(reset?: boolean): Observable<StyleSettings> {
    if ( reset ) {
      this.styleSettings_.reset();
    }
    if ( this.styleSettings_.queryStart() ) {
      this.queryStyleSettings()
        .pipe(map(this.styleSettings_.next))
        .pipe(finalize(this.styleSettings_.queryDone))
        .subscribe();
    }
    return this.styleSettings$;
  }

  queryStyleSettings(): Observable<StyleSettings> {
    return this.getStartPage()
      .pipe(take(1))
      .pipe(switchMap(startPage => this.preloadService.getStyleInfo(startPage.accountId, true)))
      .pipe(map(response => new StyleSettingsImpl(response)));
  }

  setPageTitle(pageTitle: string, prepend = false): void {
    let title = pageTitle;
    if ( prepend ) {
      if ( title && this.pageTitle ) {
        title = `${title} - ${this.pageTitle}`;
      } else {
        title = this.pageTitle;
      }
    } else {
      this.pageTitle = pageTitle;
    }
    this.titleService.setTitle(title);
  }

  uploadLoginPageInformation(input): Observable<void> {
    const query = ApiUrls.getKeyWithParams('StartPage', '2');
    return this.http.get<any>(query)
      .pipe(take(1))
      .pipe(map(response => merge({}, (response || { data: {} }).data.acc, input)))
      .pipe(switchMap(changes => this.http.post(
        ApiUrls.getKeyWithParams('StartPage', '1'),
        changes,
        {
          observe: 'events',
        },
      )))
      .pipe(switchMap(this.reloadDesignSettings));
  }

  uploadStyleSettings(input): Observable<void> {
    const query = ApiUrls.getKey('GetAccountStyleInfo')
      .replace('{accountid}', String(this.principalService.accountId));
    return combineLatest([ this.getStyleSettings(), this.http.get<TrainResponse>(query) ])
      .pipe(take(1))
      // [TF-128] create object which also contains current settings unrelated to the front-end
      .pipe(map(([ styleSettings, data ]) => merge({}, (data || {}).data, styleSettings)))
      // merge current settings with changes
      .pipe(map(currentSettings => merge({}, currentSettings.acc, input)))
      // post updated values to server
      .pipe(switchMap((settings) => this.http.post(
        ApiUrls.getKey('PostAccountStyleInfo'),
        settings,
        {
          observe: 'events',
        },
      )))
      // reload design settings
      .pipe(switchMap(this.reloadDesignSettings));
  }

  private reloadDesignSettings = (): Observable<void> => this.getStartPage(true)
      .pipe(take(1))
      .pipe(switchMap(() => this.getStyleSettings(true)))
      .pipe(take(1))
      .pipe(map(() => void (0)));

}
