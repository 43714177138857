  <ng-container
    *ngFor="let navItem of navItems"
  >

    <button
      *ngIf="headerNavHelper.asButtonIcon(navItem) as buttonIcon"
      type="button" class="stacked top-button" mat-icon-button
      (click)="headerNavHelper.onNavItemClick(buttonIcon)"
      [matTooltip]="buttonIcon.title | localeText"
    >
      <mat-icon [svgIcon]="buttonIcon.icon"></mat-icon>
    </button>

    <a
      *ngIf="headerNavHelper.asLinkIcon(navItem) as linkIcon"
      class="stacked" mat-icon-button
      [routerLink]="linkIcon.routerLink"
      [queryParams]="linkIcon.queryParams"
      routerLinkActive="active"
      [routerLinkActiveOptions]="linkIcon.routerLinkActiveOptions"
      [matTooltip]="linkIcon.title | localeText"
    >
      <div class="additional-routes" *ngIf="linkIcon.additionalRoutes?.length > 0">
        <div *ngFor="let route of linkIcon.additionalRoutes" [routerLink]="route"></div>
      </div>

      <ng-template #tplLinkIcon>
        <mat-icon [svgIcon]="linkIcon.icon"></mat-icon>
      </ng-template>
      <ng-container *ngIf="(linkIcon.special === 'mailbox') else tplLinkIcon">
        <mat-icon
          [matBadge]="'' + (mailsCount$ | async)"
          [matBadgeHidden]="!((mailsCount$ | async) > 0)"
          matBadgeColor="warn" matBadgeOverlap="true"
          matBadgePosition="above after" matBadgeSize="medium"
          i18n-matBadgeDescription="@@header_top_messages_new_mail"
          matBadgeDescription="New Mails"
          [svgIcon]="linkIcon.icon"
        ></mat-icon>
      </ng-container>
    </a>

    <button
      *ngIf="headerNavHelper.asMenuIcon(navItem) as menuIcon"
      class="stacked top-button" mat-icon-button type="button"
      [matMenuTriggerFor]="matIconMenu"
      routerLinkActive="active"
      [matTooltip]="menuIcon.title | localeText"
    >
      <div class="additional-routes" *ngIf="menuIcon.additionalRoutes?.length > 0">
        <div *ngFor="let route of menuIcon.additionalRoutes" [routerLink]="route"></div>
      </div>
      <mat-icon [svgIcon]="menuIcon.icon"></mat-icon>
      <mat-menu #matIconMenu class="header-navigation-menu">
        <ng-container *ngFor="let childItem of menuIcon.children">

          <button
            *ngIf="headerNavHelper.asButton(childItem) as childButton"
            type="button" mat-menu-item
            (click)="headerNavHelper.onNavItemClick(childButton)"
          >
            <span class="link-title">{{childButton.title}}</span>
            <mat-icon *ngIf="childButton.external" class="size-16" svgIcon="open-in-new"></mat-icon>
          </button>

          <mat-divider *ngIf="childItem.type === 'divider'"></mat-divider>

          <a
            *ngIf="headerNavHelper.asLink(childItem) as childLink"
            mat-menu-item
            [routerLink]="childLink.routerLink"
            [queryParams]="childLink.queryParams"
            routerLinkActive="active"
            [routerLinkActiveOptions]="childLink.routerLinkActiveOptions"
          >
            <div class="additional-routes" *ngIf="childLink.additionalRoutes?.length > 0">
              <div *ngFor="let route of childLink.additionalRoutes" [routerLink]="route"></div>
            </div>
            <span class="link-title">{{childLink.title}}</span>
          </a>

          <a
            *ngIf="headerNavHelper.asLinkHref(childItem) as childLink"
            mat-menu-item
            [href]="childLink.url"
            [target]="childLink.external ? '_blank' : null"
          >
            <div class="additional-routes" *ngIf="childLink.additionalRoutes?.length > 0">
              <div *ngFor="let route of childLink.additionalRoutes" [routerLink]="route"></div>
            </div>
            <span class="link-title">{{childLink.title}}</span>
            <mat-icon *ngIf="childLink.external" class="size-16" svgIcon="open-in-new"></mat-icon>
          </a>

          <ng-container *ngIf="headerNavHelper.getUserFullName(childItem) as userFullName">
            <div [disabled]="true" class="profile-info" mat-menu-item>
              <mat-icon svgIcon="account-circle-outline" class="margin-right"></mat-icon>
              <span class="item-title">{{userFullName}}</span>
            </div>
          </ng-container>

          <button
            *ngIf="childItem.special === 'profileLinkedUser'"
            type="button" mat-menu-item [matMenuTriggerFor]="matMenuLinkedUsers"
            routerLinkActive="active"
            (onMenuOpen)="onMenuOpenLinkedUser()"
          >
            <div class="additional-routes" *ngIf="childItem.additionalRoutes?.length > 0">
              <div *ngFor="let route of childItem.additionalRoutes" [routerLink]="route"></div>
            </div>
            <span class="link-title">{{childItem.title}}</span>

            <mat-menu #matMenuLinkedUsers class="header-navigation-menu layer-2" xPosition="before">
              <a mat-menu-item routerLink="/linked-users" routerLinkActive="active">
                <span i18n="@@header_top_linked_users_manage">Manage users</span>
              </a>

              <mat-divider></mat-divider>
              <ng-template #linkedUsersLoading>
                <div [disabled]="true" mat-menu-item>
                        <span
                          i18n="@@header_top_linked_users_quick_access"
                        >Switch user</span>
                  <mat-icon class="spin margin-left" svgIcon="loading"></mat-icon>
                </div>
              </ng-template>
              <ng-container
                *ngIf="(linkedUsers$ | async) as linkedUsers else linkedUsersLoading"
              >
                <ng-template #tplLinkedUsersEmpty>
                  <div [disabled]="true" mat-menu-item>
                    <span i18n="@@header_top_linked_users_empty">No users linked</span>
                  </div>
                </ng-template>
                <ng-container *ngIf="(linkedUsers.length > 0) else tplLinkedUsersEmpty">
                  <button
                    class="verticalPadding"
                    *ngFor="let entry of linkedUsers"
                    (click)="onSwitchAccount(entry.user)"
                    [title]="entry.text"
                    mat-menu-item
                  >{{entry.text}}</button>
                </ng-container>
              </ng-container>
            </mat-menu>
          </button>

          <button
            *ngIf="(childItem.special === 'language') && (languages?.length > 1)"
            [matMenuTriggerFor]="matMenuLanguage"
            mat-menu-item
            class="language-btn"
            type="button"
          >

            <div class="select-language">
              <ng-template #tplLanguageFallbackMenu>
                <span i18n="@@select_language">Language</span>
              </ng-template>

              <ng-container *ngIf="currentLanguage as language; else tplLanguageFallbackMenu">
                <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
                <span>{{language.title | localeText}}</span>
              </ng-container>
            </div>

            <!-- Language menu -->
            <mat-menu #matMenuLanguage class="language-menu">
              <button
                *ngFor="let language of languages"
                (click)="onSwitchToLanguage(language)"
                [disabled]="currentLanguagePath?.language === language.key"
                mat-menu-item
                type="button"
              >
                <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
                <span>{{language.title | localeText}}</span>
              </button>
            </mat-menu>
          </button>

        </ng-container>
      </mat-menu>
    </button>

    <button
      *ngIf="(navItem.special === 'language') && (languages?.length > 1)"
      [matMenuTriggerFor]="matMenuLanguage"
      mat-button
      class="language-btn"
      type="button"
      [matTooltip]="navItem.title | localeText"
    >

      <div class="select-language">
        <ng-template #tplLanguageFallback>
          <span i18n="@@select_language">Language</span>
        </ng-template>

        <ng-container *ngIf="currentLanguage as language; else tplLanguageFallback">
          <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
          <span>{{language.title | localeText}}</span>
        </ng-container>
      </div>
      <mat-icon svgIcon="chevron-down"></mat-icon>

      <!-- Language menu -->
      <mat-menu #matMenuLanguage class="language-menu">
        <button
          *ngFor="let language of languages"
          (click)="onSwitchToLanguage(language)"
          [disabled]="currentLanguagePath?.language === language.key"
          mat-menu-item
          type="button"
        >
          <span class="flag-icon flag-icon-{{language.country}} margin-right"></span>
          <span>{{language.title | localeText}}</span>
        </button>
      </mat-menu>
    </button>

  </ng-container>

