<div class="general-design-container">
  <div class="actions">
    <div class="action-buttons">
      <button (click)="onOpenPreview()" mat-stroked-button>
        <mat-icon svgIcon="eye"></mat-icon>
        <span i18n="@@global_preview">Preview</span>
      </button>
      <button (click)="onSave()" [disabled]="this.form.pristine || this.form.invalid" color="primary" mat-flat-button>
        <mat-icon svgIcon="content-save"></mat-icon>
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </div>
  <form [formGroup]="form" class="container">
    <div class="header-container">
      <h3 class="headline" i18n="@@global_settings">Settings</h3>
      <mat-checkbox formControlName="showNewLogin">
        <span i18n="@@account_design_show_new_login">Show the new login screen</span>
      </mat-checkbox>

      <!-- <mat-checkbox formControlName="registrationButtonActive">
        <span i18n="@@account_design_show_registration_button">Show the registration button</span>
      </mat-checkbox> -->

      <div *ngIf="permissionStates?.ngCustomColorsAdmin" class="apply-custom-colors">
        <mat-checkbox
          formControlName="applyCustomColors"
          color="primary">
          <span i18n="@@login_conf_apply_custom_colors">Apply custom colors</span>
        </mat-checkbox>
      </div>

      <mat-divider class="divider"></mat-divider>

      <h3 class="headline" i18n="@@global_content">Content</h3>
      <span i18n="@@login_page_conf_text">Here you can edit the contents of the login page</span>
      <div class="content-container">
        <div class="left">

          <rag-select-language
            class="language-select"
            appearance="horizontal"
            [flagsOnly]="false"
            [selected]="language"
            [languages]="languages"
            (languageChange)="onLanguageChange($event)"
          ></rag-select-language>

          <h4 i18n="@@welcome_text">Welcome text</h4>
          <mat-form-field appearance="outline">
            <input formControlName="loginFormTexts" matInput type="text">
          </mat-form-field>

          <h4 i18n="@@placeholder_login_field">Placeholder for login field</h4>
          <mat-form-field appearance="outline">
            <input formControlName="loginPlaceholder" matInput type="text">
          </mat-form-field>

          <h4 i18n="@@error_message_login_field">Error message for login field</h4>
          <mat-form-field appearance="outline">
            <input formControlName="loginError" matInput type="text">
          </mat-form-field>

          <h4 i18n="@@explanatory_text">Explanatory text</h4>
          <ckeditor
            class="ckeditor"
            [editor]="editor"
            [config]="config"
            formControlName="welcomeTexts"
          ></ckeditor>
        </div>
        <div class="right">
          <div class="image" [style.background-image]="'url('+welcomePictureURL+')'"></div>
          <button mat-flat-button color="primary" class="picture-button" (click)="editLoginPicture()">
            <span i18n="@@select_login_picture">Select login picture</span>
            <mat-icon svgIcon="image-filter-center-focus"></mat-icon>
          </button>
        </div>
      </div>


    </div>

  </form>
</div>
