import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { TableColumnMenuService } from '../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from '../../../component/table/table-controller/table-controller.component';
import { ModalDialog } from '../../../core/modal-dialog';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { AddUserDialogComponent } from '../../../component/add-user-dialog/add-user-dialog.component';
import { LinkedUsersService } from '../../../core/user/link/linked-users.service';
import { LinkedUserTypes } from '../../../core/user/link/linked-users.types';

@Component({
  selector: 'rag-link',
  templateUrl: './linked-users.component.html',
  styleUrls: [ './linked-users.component.scss' ]
})
export class LinkedUsersComponent
  extends TableControllerComponent<LinkedUserTypes.LinkedUser>
  implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [ 'accountName', 'firstname', 'lastname', 'login', 'userId', 'actions' ];

  constructor(
    private linkedUsersService: LinkedUsersService,
    public dialog: ModalDialog,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);
  }

  deleteLinkedUser(user: LinkedUserTypes.LinkedUser) {
    this.linkedUsersService.showDialogForDeletionOfLinkedUser(user);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    subscribeUntilDestroyed(
      this.linkedUsersService.linkedUsers$
        .pipe(map(linkedUsers => {
          this.updateAvailableColumns();
          this.setTableData(linkedUsers);
          this.inputDisabled = false;
          this.checkFilter();
        }))
      , this);
  }

  showDialogForAddingNewLinkedUser() {
    this.dialog.openModal(AddUserDialogComponent, {})
      .afterClosed()
      .subscribe();
  }

  switchAccount(user: LinkedUserTypes.LinkedUser) {
    this.linkedUsersService.showDialogForSwitchingAccount(user);
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(LinkedUserTypes.DEFAULT_MENU_COLUMNS);
    this.renderColumns = Object.values(menuData.menuItems)
      .filter(menuItem => !menuItem.hidden);
    this.setColumns(TableColumnMenuService.menuDataToColumns(menuData));
    this.columnMenuData = menuData;
  }
}
