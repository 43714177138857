import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as moment from 'moment';
import { LearningSession, ScoAccount } from 'src/app/core/content/content.types';
import { NumberedAnyObject } from 'src/app/core/core.types';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { LearningSessionDataType } from './learning-session-data-types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  selector: 'rag-learning-session-data-dialog',
  templateUrl: './learning-session-data-dialog.component.html',
  styleUrls: [ './learning-session-data-dialog.component.scss' ],
})
export class LearningSessionDataDialogComponent {

  readonly FIELD_EXIT_REASON = {
    0: $localize`:@@learning_session_data_exit_reason_0:Unset (0)`,
    1: $localize`:@@learning_session_data_exit_reason_1:Timeout (1)`,
    2: $localize`:@@learning_session_data_exit_reason_2:Suspended (2)`,
    3: $localize`:@@learning_session_data_exit_reason_3:Logout (3)`,
  };
  /**
   * This maps valid fields to their labels.
   * Warning: Please keep the order of attributes as is. They define the rendering order!
   */
  readonly FIELD_I18N = {
    userid: $localize`:@@learning_session_data_user_id:Student ID:`,
    scoId: $localize`:@@learning_session_data_sco_id:Chapter ID:`,
    running: $localize`:@@learning_session_data_running:Actively running:`,
    startTime: $localize`:@@learning_session_data_start_time:Start time:`,
    sessionTime: $localize`:@@learning_session_data_session_time:Session time:`,
    rawScoreFloat: $localize`:@@learning_session_data_raw_score:Raw score:`,
    minScoreFloat: $localize`:@@learning_session_data_min_score:Min. score:`,
    credit: $localize`:@@learning_session_data_credit:Credit:`,
    entry: $localize`:@@learning_session_data_entry:Entry:`,
    exitReason: $localize`:@@learning_session_data_exit_reason:Exit reason:`,
    lastError: $localize`:@@learning_session_data_last_error:Error code:`,
    diagnostics: $localize`:@@learning_session_data_diagnostics:Error string:`,
    /* todo check what timestamp_lock means exactly */
    /*'lock': $localize`:@@learning_session_data_lock:Locked:`,*/
    /* launchData appears to be deprecated */
    /*'launchData': $localize`:@@learning_session_data_launch_data:Launch data:`,*/
    lessonLocation: $localize`:@@learning_session_data_lesson_location:Lesson location:`,
    suspendData: $localize`:@@learning_session_data_suspend_data:Suspend data:`,
    /*'iteration': $localize`:@@learning_session_data_iteration:Iteration`,*/
  };
  /**
   * @see ScormError
   */
  readonly FIELD_LAST_ERROR = {
    0: $localize`:@@learning_session_data_last_error_0:NOERROR (0)`,
    101: $localize`:@@learning_session_data_last_error_101:GENERALEXCEPTION (101)`,
    201: $localize`:@@learning_session_data_last_error_201:INVALID_ARGUMENT (201)`,
    202: $localize`:@@learning_session_data_last_error_202:ELEMENT_CAN_NOT_HAVE_Children (202)`,
    203: $localize`:@@learning_session_data_last_error_203:ELEMENT_IS_NOT_AN_ARRAY (203)`,
    301: $localize`:@@learning_session_data_last_error_301:NOT_INITIALIZED (301)`,
    401: $localize`:@@learning_session_data_last_error_401:NOT_IMPLEMENTED (401)`,
    402: $localize`:@@learning_session_data_last_error_402:INVALID_SETVALUE (402)`,
    403: $localize`:@@learning_session_data_last_error_403:ELEMENT_IS_READ_ONLY (403)`,
    404: $localize`:@@learning_session_data_last_error_404:ELEMENT_IS_WRITE_ONLY (404)`,
    405: $localize`:@@learning_session_data_last_error_405:INCORRECT_DATATYPE (405)`,
  };
  readonly FIELD_TO_CMI = {
    credit: 'cmi.core.credit',
    entry: 'cmi.core.entry',
    lessonLocation: 'cmi.core.lesson_location',
    minScoreFloat: 'cmi.core.score.min',
    rawScoreFloat: 'cmi.core.score.raw',
    scoId: 'scoId',
    sessionTime: 'cmi.core.session_time',
    suspendData: 'cmi.suspend_data',
    userid: 'cmi.core.student_id',
  };
  NO_SESSION = {};
  course: ControllingSingleUserTypes.ScoCourseAccount;
  sessions: LearningSession[];
  user: ControllingSingleUserTypes.ControllingUser;
  private readonly sessionByScoId: NumberedAnyObject<LearningSession>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LearningSessionDataType,
  ) {
    this.course = data.course;
    this.user = data.user;
    this.sessions = data.sessions;
    this.sessionByScoId = this.sessions.reduce((pV, session) => {
      pV[session.scoId] = session;
      return pV;
    }, {});
  }

  fieldTextForField(field: string) {
    return this.FIELD_I18N[field] ?? field;
  }

  /**
   * Find translations for fields. This method removes fields with empty value.
   */
  fieldsForSession(session: LearningSession) {
    return Object.keys(this.FIELD_I18N)
      .map(field => {
        const value = session[field];
        if ( (value == null) || (value === '') ) {
          // ignore empty values
          return null;
        } else if ( (value === 0) && ((field === 'sessionTime') || (field === 'startTime')) ) {
          // ignore null-ish time values
          return null;
        } else if ( (value < 0) && ((field === 'rawScoreFloat') || (field === 'minScoreFloat')) ) {
          // ignore null-ish time values
          return null;
        }
        return field;
      })
      .filter(v => v != null);
  }

  sessionForCourseItem(courseItem: ScoAccount) {
    return [ this.sessionByScoId[courseItem.scoId] || this.NO_SESSION ];
  }

  tdClassForField(field: string) {
    if ( field === 'suspendData' ) {
      return 'text-block';
    }
    return null;
  }

  valueForField(field: string, session: LearningSession) {
    switch ( field ) {
      case 'startTime':
      case 'lock':
        return moment(session[field]).format('lll');
      case 'running':
        return !!session.running ? $localize`:@@global_yes:Yes` : $localize`:@@global_no:No`;
      case 'exitReason':
        // translate exit reason
        return this.FIELD_EXIT_REASON[String(session.exitReason)] ?? session[field];
      case 'lastError':
        // translate error codes
        return this.FIELD_LAST_ERROR[String(session.lastError)] ?? session[field];
      default:
        return session[field];
    }
  }

}
