<rag-breadcrumbs>
  <a *ngIf="(styleSettings$ | async) as styleSettings">{{styleSettings.acc.myContentsName | localeText}}</a>

  <rag-breadcrumbs-assignment-type
    *ngIf="assignmentType"
    [assignmentType]="assignmentType"
  ></rag-breadcrumbs-assignment-type>

  <rag-breadcrumbs-link
    *ngIf="curriculum"
    [title]="curriculum.title"
    [url]="backUrl"
  ></rag-breadcrumbs-link>

  <rag-breadcrumbs-link
    [title]="contentTitle"
  ></rag-breadcrumbs-link>
</rag-breadcrumbs>

<rag-content-page-title
  [backUrl]="backUrl"
  [displayStatus]="displayStatus"
  [title]="offlineContent.title | localeText"
  [typeOfTraining]="typeOfTraining"
  statusTargetType="lms_offlineCnt"
>
  <rag-my-content-actions
    [detailsEnabled]="false"
    [targetObject]="bookableImpl"
    (canceled)="onCanceled($event)">
  </rag-my-content-actions>
</rag-content-page-title>

<div *ngIf="bookingNotAllowed && !hasAssignedEventSchedules() ; else contentOfflineChoose" class="booking-not-allowed">
  <div i18n="@@learner_offline_no_events">There are currently no upcoming events defined.</div>

  <span
    *ngIf="offlineContent.assignmentModeForPrincipal === 'admin'"
    i18n="@@learner_offline_no_events_assignmentMode_Admin">
    You will be assigned to an event by one of our experts.
  </span>

  <span
    *ngIf="offlineContent.assignmentModeForPrincipal !== 'admin'"
    i18n="@@learner_offline_no_events_assignmentMode_notAdmin">
    You will be notified, should new schedules become available.
  </span>
</div>

<ng-template #contentOfflineChoose>
  <rag-content-offline-choose
    *ngIf="canChooseEventSchedules"
    [offlineContent]="offlineContent"
    [offlineContentAccount]="offlineContentAccount"
    [offlineEventSchedules]="offlineEvents"
    [offlineEventAccountViews]="offlineEventAccountViews"
    (bookEvent)="onBookEvent($event)"
    (cancelEvent)="onCancelEvent($event)"
  ></rag-content-offline-choose>
</ng-template>

<div class="reservation" *ngIf="hasOutstandingPayments">
  <mat-icon svgIcon="alert"></mat-icon>
  &nbsp;
  <span i18n="@@learner_offline_payment_not_complete">A payment is still pending. Once the payment is completed you will gain access to the content.</span>
  <button
    *ngIf="!shouldDisplayEventSchedules"
    mat-flat-button
    color="primary"
    (click)="onPayEventNow()">
    <span i18n='@@pay_now'>Pay now</span>
  </button>
</div>

<rag-content-offline-view
  *ngIf="shouldDisplayEventSchedules"
  [canChooseEventSchedules]="canChooseEventSchedules"
  [offlineContent]="offlineContent"
  [offlineEventAccounts]="offlineEventAccountViews"
  [offlineEvents]="offlineEvents"
  (payNow)="onPayEventScheduleNow($event)"
></rag-content-offline-view>

