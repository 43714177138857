import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnonymousRegisterDialogData, AnonymousRegisterDialogStep } from '../anonymous-register.types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '../../../../core/principal/registration.service';
import { catchError, take, tap } from 'rxjs/operators';
import { LoginMethodOAuth2, LoginType } from '../../../../core/principal/login-method';
import { EMPTY, Observable } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import { LoginService } from '../../../../core/principal/login.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants } from '../../../../core/info/info.types';

@Component({
  selector: 'rag-anonymous-register-dialog',
  templateUrl: './anonymous-register-dialog.component.html',
  styleUrls: ['./anonymous-register-dialog.component.scss']
})
export class AnonymousRegisterDialogComponent implements OnInit {

  form: FormGroup;
  dialogSteps = AnonymousRegisterDialogStep;
  step = AnonymousRegisterDialogStep.RegistrationStep;
  createdUserName = '';
  createdPassword = '';
  readonly loginButtonDisabled$: Observable<boolean>;

  private _loginButtonDisabled$ = new CachedSubject<boolean>(false);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AnonymousRegisterDialogData,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private loginService: LoginService,
    private registrationService: RegistrationService,
    private dialogRef: MatDialogRef<AnonymousRegisterDialogComponent>
  ) {
    this.loginButtonDisabled$ = this._loginButtonDisabled$.asObservable();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  onRegister(): void {
    if (this.step === AnonymousRegisterDialogStep.RegistrationStep) {
      const code = this.form.controls['code'].value;
      if (code == null || code === '') {
        return;
      }

      this.step = AnonymousRegisterDialogStep.LoadingStep;
      this.registrationService.registerAnonymous(code)
        .pipe(tap(login => {
          this.createdPassword = code;
          this.createdUserName = login;
          this.step = AnonymousRegisterDialogStep.SuccessStep;
        }))
        .pipe(catchError(() => {
          this.step = AnonymousRegisterDialogStep.RegistrationStep;
          this.infoService.showMessage(
            $localize`:@@global_wrong_registration_code:The code entered is not correct. Please check your input`,
            {
            infoType: InfoType.Error,
          });
          return EMPTY;
        }))
        .subscribe();

    } else if (this.step === AnonymousRegisterDialogStep.SuccessStep) {
      this.onLogin();
    }
  }

  onLogin(): void {
    const credentials = this.getCredentials();
    if ( !credentials.valid ) {
      return;
    }

    this._loginButtonDisabled$.next(true);

    this.loginService
      .login(this.data.loginMethod, credentials.username, credentials.password)
      .pipe(catchError(() => {
        this.dialogRef.close();
        return this.infoService.showMessage(MessageConstants.API.ERROR, {
          infoType: InfoType.Error,
        });
      }))
      .pipe(take(1))
      .pipe(tap(() => this.dialogRef.close()))
      .subscribe();
  }

  private getOAuthUrl(): string {
    const loginMethod = this.data.loginMethod as LoginMethodOAuth2;
    return loginMethod?.authorizationUrl || null;
  }

  private getCredentials(): { username?: string; password?: string; valid: boolean } {
    if ( this.data.loginMethod == null ) {
      return { valid: false };
    }

    if ( this.data.loginMethod?.type === LoginType.OAuth2 ) {
      return { valid: this.getOAuthUrl() != null };
    }

    const password = this.createdPassword ?? '';
    if ( password.length === 0 ) {
      return { valid: false };
    }

    const username = this.createdUserName ?? '';
    if ( username.length === 0 ) {
      return { valid: false };
    }

    return { username, password, valid: true };
  }
}
