import { Component, Input } from '@angular/core';
import { NavItem } from '../../../core/navigation/navigation.types';
import { HeaderNavHelper } from '../header-nav.helper';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[rag-header-nav-main]',
  templateUrl: './header-nav-main.component.html',
  styleUrls: [ './header-nav-main.component.scss' ],
})
export class HeaderNavMainComponent {

  @Input() navItems: NavItem[];

  constructor(
    public headerNavHelper: HeaderNavHelper,
  ) {
  }

}
