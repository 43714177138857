import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminQAEditComponent } from './admin-qa-edit.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { TagsComponent } from 'src/app/component/tags/tags.component';



@NgModule({
  declarations: [
    AdminQAEditComponent
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    TagsComponent,
    MatTooltipModule,
  ],
})
export class AdminQAEditModule { }
