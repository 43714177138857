<ng-container *ngIf="isNotOffline; else offlineMenu">
  <a
    *ngIf="detailsUrl"
    [routerLink]="detailsUrl"
    mat-menu-item
    target="_blank"
  >
    <mat-icon svgIcon="beaker-outline"></mat-icon>
    <span i18n="@@ctrl_single_user_open_tab">Open in tab</span>
  </a>

  <button
    (click)="onSendMessage()"
    [disabled]="inputDisabled || !maySendMessage"
    mat-menu-item
  >
    <mat-icon svgIcon="email"></mat-icon>
    <span i18n="@@global_messaging">Send message</span>
  </button>

  <button
    *ngIf="isCurriculumAndCertified && permissions?.hasCtrlEditCurriculumAccount"
    (click)="onEditLatestValidity()"
    mat-menu-item
  >
    <mat-icon svgIcon="pencil-outline"></mat-icon>
    <span i18n="@@ctrl-single-user-edit-latest-validity">Edit last validity</span>
  </button>

  <button
    *ngIf="isCurriculum && permissions?.hasCtrlEditCurriculumAccount"
    (click)="onSwitchPath()"
    [disabled]="inputDisabled || !maySave"
    mat-menu-item
  >
    <mat-icon svgIcon="call-split"></mat-icon>
    <span i18n="@@curriculum_switch_path">Switch path</span>
  </button>

  <button
    *ngIf="isCurriculum"
    (click)="onDownloadCertificate()"
    [disabled]="inputDisabled"
    mat-menu-item
    type="button"
  >
    <mat-icon svgIcon="certificate"></mat-icon>
    <span i18n="@@pivot_table_download_certificates">Download certificates</span>
  </button>
  <button
    *ngIf="isCurriculumAndStatusFixable()"
    (click)="onFixStatus()"
    mat-menu-item
    type="button"
  >
    <mat-icon svgIcon="arrow-u-down-left"></mat-icon>
    <span i18n="@@cur_ctr_fix_status">Fix status</span>
  </button>
</ng-container>

<ng-template #offlineMenu>
  <button
    (click)="onSendMessage()"
    [disabled]="inputDisabled || !maySendMessage"
    mat-menu-item
  >
    <mat-icon svgIcon="email"></mat-icon>
    <span i18n="@@global_messaging">Send message</span>
  </button>
</ng-template>
