import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { DoubleOptIn } from 'src/app/core/core.types';
import { ProcessService } from 'src/app/route/main/process/process.service';
import { InfoService } from '../../../core/info/info.service';
import { InfoType } from '../../../core/info/info.types';
import {
  LoginMethod,
  LoginMethodOAuth2,
  LoginType,
} from '../../../core/principal/login-method';
import { RegistrationService } from '../../../core/principal/registration.service';
import { LoginService } from '../../../core/principal/login.service';
import { LoginFormData } from './login-form.types';
import { LoginFormService } from './login-form.service';
import { LanguageHelper } from '../../../core/language.helper';
import { DesignSettings } from '../../../route/admin/account-design/account-design.types';
import { Router } from '@angular/router';
import { CachedSubject } from 'src/app/core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { AnonymousRegisterDialogComponent } from '../../../route/main/anonymous-register/anonymous-register-dialog/anonymous-register-dialog.component';
import { AnonymousRegisterDialogData } from '../../../route/main/anonymous-register/anonymous-register.types';
import { PreloadService } from '../../../core/preload.service';


@Component({
  selector: 'rag-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: [ './login-form.component.scss' ],
})
export class LoginFormComponent
  implements OnInit, OnDestroy {

  @Input() data: LoginFormData;
  @Input() saveUrlForLogin = true;
  @Input() showDoubleOptInSuccessInline: boolean;
  @Input() showTitle = true;
  @Input() forwardProcessHash: string;
  @Input() previewMode: boolean = false;
  @Input() texts: DesignSettings;
  doubleOptInState?: DoubleOptIn.State;
  loginMethods: LoginMethod[];
  selectedLoginMethod: LoginMethod = { type: LoginType.Credentials };
  showPassword = false;
  anonymousRegistrationEnabled = false;
  selfRegistrationEnabled = false;
  registerText = $localize`:@@login_page_register_button:Register account`;
  form: UntypedFormGroup;

  readonly loginButtonDisabled$: Observable<boolean>;

  private _designSettings: DesignSettings;
  private _isLoading = true;
  private _loginButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private loginFormService: LoginFormService,
    private processService: ProcessService,
    private loginService: LoginService,
    private registrationService: RegistrationService,
    private router: Router,
    private preloadService: PreloadService,
  ) {
    this.loginButtonDisabled$ = this._loginButtonDisabled$.asObservable();

    this.preloadService.getSelfRegistration()
      .pipe(tap(selfRegistrationEnabled => this.selfRegistrationEnabled = selfRegistrationEnabled))
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      login: [ null, [ Validators.required ] ],
      passwd: [ null, [ Validators.required ] ],
    });

    if ( this.data == null ) {
      this.loginFormService.getLoginFormData()
        .pipe(tap(this.updateRouteData))
        .pipe(take(1))
        .subscribe();
    } else {
      this.updateRouteData(this.data);
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getLoginError(): string {
    return LanguageHelper.objectToText(this.previewMode ? this.texts.loginError : this._designSettings.loginError);
  }

  getLoginFormTexts(): string {
    return LanguageHelper.objectToText(this.previewMode ? this.texts.loginFormTexts : this._designSettings.loginFormTexts);
  }

  getLoginPlaceholder(): string {
    return LanguageHelper.objectToText(this.previewMode ? this.texts.loginPlaceholder : this._designSettings.loginPlaceholder);
  }

  getTemplate(
    tplLoading: TemplateRef<any>,
    tplLoginWithCredentials: TemplateRef<any>,
    tplLoginWithOAuth2: TemplateRef<any>,
    tplDoubleOptInSuccess: TemplateRef<any>,
  ): TemplateRef<any> {

    if ( this._isLoading ) {
      // wait until fetch has finished
      return tplLoading;
    }

    if ( this.selectedLoginMethod?.type === LoginType.OAuth2 ) {
      return tplLoginWithOAuth2;
    }

    const shouldShowDoubleOptInSuccess = this.data?.doubleOptInEnabled &&
      this.showDoubleOptInSuccessInline &&
      this.doubleOptInState?.stage === 'initial';
    if ( shouldShowDoubleOptInSuccess ) {
      return tplDoubleOptInSuccess;
    }

    return tplLoginWithCredentials;
  }

  hasError(controlName: string, errorCode: string): boolean {
    if ( this.form ) {
      if ( this.form.pristine ) {
        if ( this.form.get(controlName).touched ) {
          this.form.get(controlName).setErrors({
            required: true,
          });

        } else {
          this.form.get(controlName).setErrors(null);
          return false;
        }
      }
      return this.form.get(controlName).hasError(errorCode);
    }
    return false;
  }

  onLogin(): void {

    if (this.previewMode) {
      return;
    }

    const credentials = this.getCredentials();
    if ( !credentials.valid ) {
      return;
    }

    this._loginButtonDisabled$.next(true);

    this.loginService
      .login(this.selectedLoginMethod, credentials.username, credentials.password, this.forwardProcessHash)
      .pipe(catchError(this.resetPasswordInputAndShowError))
      .pipe(take(1))
      .subscribe();
  }

  onRegister(): void {

    if (this.previewMode) {
      return;
    }

    if ( this.anonymousRegistrationEnabled ) {
      this.onOpenAnonymousRegistration();
    } else if ( this.data?.doubleOptInEnabled === true ) {
      this.onRegisterDoubleOptIn();
    } else {
      this.onRegisterDefault();
    }
  }

  shouldShowRegistration(): boolean {
    if ( this.selectedLoginMethod?.type === 'OAuth2' ) {
      // no registration for oAuth
      return false;
    }
    return this.selfRegistrationEnabled;
  }

  private getOAuthUrl(): string {
    const loginMethod = this.selectedLoginMethod as LoginMethodOAuth2;
    return loginMethod?.authorizationUrl || null;
  }

  private getCredentials(): { username?: string; password?: string; valid: boolean } {
    if ( this.selectedLoginMethod == null ) {
      return { valid: false };
    }

    if ( this.selectedLoginMethod?.type === LoginType.OAuth2 ) {
      return { valid: this.getOAuthUrl() != null };
    }

    const password = this.form.get('passwd').value?.trim() ?? '';
    if ( password.length === 0 ) {
      this.form.get('passwd').setErrors({
        required: true,
      });
      return { valid: false };
    }

    const username = this.form.get('login').value?.trim() ?? '';
    if ( username.length === 0 ) {
      this.form.get('login').setErrors({
        required: true,
      });
      return { valid: false };
    }

    return { username, password, valid: true };
  }

  private onOpenAnonymousRegistration(): void {
    this.infoService.showDialog<
      AnonymousRegisterDialogComponent,
      AnonymousRegisterDialogData,
      void
    >(AnonymousRegisterDialogComponent, {
      description:
        $localize`:@@global_anonymous_registration_description:To register, you need a registration code
        that you will receive from your company. Enter the registration code in the field and click on "Register"`,
      loginMethod: this.selectedLoginMethod,
    }, {
      width: '32vw'
    })
      .pipe(take(1))
      .subscribe();
  }

  private onRegisterDefault(): void {

    let observableUrl: Observable<string>;
    const context = this.processService.getContext();
    if ( Object.keys(context ?? {}).length > 0 ) {

      // create user process with process context
      observableUrl = this.registrationService
        .initiateDoubleOptIn(context, 'registration')
        .pipe(map(process => '/register?p=' + process.hash));

    } else {

      // simply redirect to registration page
      observableUrl = of('/register');
    }

    observableUrl
      .pipe(switchMap(url => this.router.navigateByUrl(url)))
      .pipe(take(1))
      .subscribe();
  }

  private onRegisterDoubleOptIn(): void {

    this.registrationService
      .registerDoubleOptIn()

      .pipe(tap(state => {
        this.doubleOptInState = state;

        if ( !this.showDoubleOptInSuccessInline ) {
          this.infoService.showMessage($localize`:@@dbl_opt_in_email_is_sent:An email has been sent successfully. Please check the SPAM folder of your email client if it takes longer.`, {
            title: $localize`:@@global_confirm:Confirm`,
          });
        }
      }))
      .pipe(take(1))
      //@TODO: Forward to login page or samething elseehere
      .pipe(catchError(e => {
        if (e.status === 417) {
          const errors = e.error?.errors;
          if (errors.length > 0) {
            const firstError = errors[0];
            if (firstError.errorCode === 'ERR_BASE_PROC_005') {
              this.infoService.showMessage($localize`:@@self_registration_error_already_taken:This e-mail address is already in use. Please try to log in using the address, and the matching password.<br/><br/>In case you do not know the correct password, you can use the "forgot password?" function to request a new one.`, {
                title: $localize`:@@global_warning:Warning`, infoType: InfoType.Error
              });
            }
          }
        }
        return EMPTY;
      }))
      .subscribe();
  }

  private resetPasswordInputAndShowError = (): Observable<never> => {
    this.form.get('login').setValue(null);
    this.form.get('passwd').setValue(null);
    this.infoService
      .showMessage($localize`:@@login_form_login_failed_error:Login failed!`, { infoType: InfoType.Error });
    return EMPTY;
  };

  private updateRouteData = (data: LoginFormData): void => {
    this.data = data;

    const loginMethods = data?.loginMethods ?? [];
    if ( loginMethods.length === 0 ) {
      // force at least credential login to be available
      loginMethods.push({ type: LoginType.Credentials });
    }

    this.loginMethods = loginMethods;
    this._designSettings = data?.startPageSettings?.acc ?? {};

    // select the first method by default
    this.selectedLoginMethod = this.loginMethods[0];

    this._isLoading = false;

    if (this.selectedLoginMethod.type === LoginType.OAuth2) {
      this._loginButtonDisabled$.next(this.getOAuthUrl() == null);
      return;
    }

    this.form.statusChanges.pipe(map(status => {
      this._loginButtonDisabled$.next(status !== 'VALID');
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.anonymousRegistrationEnabled = this.data?.anonymousRegistrationEnabled ?? false;
    this.registerText = this.anonymousRegistrationEnabled ?
      $localize`:@@global_register:Register` : $localize`:@@login_page_register_button:Register account`;
  };

}
