<rag-dialog-header>
  <rag-clamp clamp="1" [input]="content.title"></rag-clamp>
</rag-dialog-header>

<mat-dialog-content class="min-height">

  <div class="container">
    <div
      class="info"
      [innerHTML]="confirmInfoText | safe:'html'"
    >
    </div>

    <div class="radio">
      <mat-radio-group
        aria-label="Select an option"
        (change)="onSelectionChange($event)"
        [(ngModel)]="selection">
        <mat-radio-button value="0" i18n="@@content_read_option_0">I have read and understand it</mat-radio-button>
        <mat-radio-button value="1"
          *ngIf="!hasNoSupervisor">
          <span i18n="@@content_read_option_1">I need further assistance</span>
        </mat-radio-button>
        <div *ngIf="!hasNoSupervisor"
          class="option-2-clearance"
             [ngClass]="{'gray': selection === '0'}"
             i18n="@@content_read_option_2_clearance"
        >
          Your objection will be sent to the course supervisor with instructions to contact you regarding this matter. After clarification, please confirm "read and understood" via this channel.
        </div>
      </mat-radio-group>
    </div>

    <textarea
      *ngIf="!hasNoSupervisor"
      name="text"
      [readonly]="selection === '0'"
      rows="10"
      [(ngModel)]="text"></textarea>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-stroked-button
    (click)="onCancel()"
  >
    <span i18n="@@cancel_button">Cancel</span>
  </button>

  <button
    mat-flat-button
    color="primary"
    [disabled]="selection === '1' && !hasEnteredText"
    (click)="onReadAcknowledgeConfirm()"
  >
    <span i18n="@@global_confirm">Confirm</span>
  </button>

</mat-dialog-actions>
