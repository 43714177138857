import { Moment } from 'moment';
import {
  Core,
  EntityObject,
  EntityObjectsCollection,
  FileInfo,
  FileUploadable,
  Identifiable,
  Imageable,
  LegallyConformable,
  NumberedAnyObject,
  Principal,
  Titleable,
  TransientView,
  Translateable,
  Visualizable,
} from 'src/app/core/core.types';
import { DisplayStatus } from './display-status.enum';
import { ExternalLogin } from './global.types';
import { Price, Priceable } from './financials/financials.types';
import { IBasisUserName } from './principal/principal.types';


export namespace OfflineContent {

  // export type Type = 'document' | 'talk' | 'seminar' | 'sbs' | 'virco';
  export type TimeUnit = 'month' | 'week' | 'day';
  export type ViewType = 'cal' | 'list';
  export type ClosedStatus = 'canceled' | 'done' | 'aborted' | 'noStatus';
  export type ServiceEventType = 'event_save' | 'event_shedule_save' | 'event_notification_save';

  export const UserSettingsContextViewMode = 'UserSettingsContext@viewMode';

  export type ChangeEventType = 'modified' | 'removed' | 'invalidated' | 'reseted';

  export type UsageType = 'block' | 'location' | 'provider' | 'trainer';

  export interface ComponentChangeEvent {
    changed: boolean;
    context: string;
  }

  export interface EventModule extends Identifiable {
    adminTitle: string;
  }

  export interface UsetSettings {
    columns: Array<string>;
  }

  export enum EventType {
    hybrid = 14,
    virco = 11,
    seminar = 7,
    document = 6
  }

  export interface EventsRouteData {
    date: Moment;
    entries: Array<Event>;
    expiredEventSchedules: Array<number>;
    locations: EntityObjectsCollection;
    providers: EntityObjectsCollection;
    timeSpan: Moment;
    timeSpanEnd: Moment;
    timeUnit: TimeUnit;
    trainers: EntityObjectsCollection;
    upcomingEventSchedules: Array<number>;
    userSettings?: UsetSettings;
    viewType: ViewType;
  }

  export type EventScheduleType = 'TrainingEvening' | 'TrainingDay' | 'TrainingBlock';

  export interface Location
    extends EntityObject {
    address1: string;
    address2?: string;
    city: string;
    info?: string;
    name: string;
    zip: string;
    longitude?: number;
    latitude?: number;
  }

  export interface TrainerView {
    trainerPicture?: File;
  }

  export interface Trainer
    extends EntityObject, IBasisUserName, TransientView<TrainerView> {
    academicTitle?: string;
    address?: string;
    email?: string;
    telephone?: string;
    description?: string;
    pictureUUID?: string;
    picture?: FileInfo;
    role?: string;
  }

  export interface TrainerRoles {
    roles: string;
  }

  export interface Provider
    extends EntityObject {
    address: string;
    email: string;
    name: string;
    telephone: string;
  }

  export interface ExtLoginSupport {
    extLoginMinsPrefix?: number;
    extLoginMinsSuffix?: number;
    extLoginPath?: string;
    extLoginPathName?: string;
  }

  export interface TimeBlockView {
    timeblockOutsideEventDate: boolean;
  }

  export interface TimeBlock
    extends ExtLoginSupport, TransientView<TimeBlockView> {
    description?: string;
    endDate: number;
    id: number;
    startDate: number;
    uuid: string;
  }

  export interface EventScheduleView extends FileUploadable {
    attachments?: Array<FileInfo>;
    duration: number;
    endDate: Moment;
    expiredEvents: Array<number>;
    startDate: Moment;
    upcomingEvents: Array<number>;
    uuid: string;
    freePlaces?: number;
    trainersFormatted?: string;
    locationFormatted?: string;
    durationFormatted?: string;
    extLogin?: string;
    eventModule?: EventModule;
  }

  export interface Extras {
    costPerMember: number;
    identity: string;
    totalCost: number;
  }

  // LTR_OFFLINE_EVENT
  export interface EventSchedule
    extends ExtLoginSupport, TransientView<EventScheduleView>, Titleable, Imageable {
    active: boolean;
    actualUserCount: number;
    attachments: Array<FileInfo>;
    trainerAttachments: Array<FileInfo>;
    availableForBooking?: boolean;
    closedStatus?: ClosedStatus;
    contentId: number;
    // financials
    costPerUser?: number;
    costs?: number;
    priceForUserInCent?: number;
    recordingUrl?: string;
    creationDate: number;
    priceComment?: string;
    /**
     * if the event is in the past
     */
    eventClosed?: boolean;
    eventDate: number;
    eventDateUntil: number;
    examination: boolean;
    extIdentity?: string;
    extLogin?: ExternalLogin;
    id: number;
    lastModified: number;
    location?: Location;
    maxUserCount: number;
    minUserCount: number;
    netDuration?: number;
    prices?: Price[];
    provider?: Provider;
    timeBlocks?: Array<TimeBlock>;
    timeZone: string;
    trainers: Array<Trainer>;
    offlineContentType?: OfflineContent.EventType;
    type: EventScheduleType;
    calenderEntry?: Translateable;
    controllingRequired: boolean;
    bookingUntilEnd: boolean;
    module?: EventModule;
  }

  export interface EventView extends FileUploadable {
    participantListFile?: File;
    principals?: NumberedAnyObject<Principal>;
    registrationConfirmationFile?: File;
    participationConfirmationFile?: File;
    rbacPermissions?: Array<string>;
  }

  // LTR_OFFLINE_CONTENT
  export type EventAssignmentMode = 'admin' | 'take' | 'wish';

  export interface Agendable {
    blockEvent: boolean;
    multipleSchedules: boolean;
    allowedMaxSchedulesCount: number;
    requiredMinSchedulesCount: number;
    schedulesOverlapping: boolean;
  }

  export interface Event
    extends Identifiable, Priceable, Visualizable, Agendable, TransientView<EventView> {
    tcFile?: FileInfo;
    tcFileUpload?: File;
    tcFileId?: number;
    privacyFile?: FileInfo;
    privacyFileUpload?: File;
    privacyFileId?: number;
    accountId?: number;
    active?: boolean;
    assignmentModeForPrincipal?: EventAssignmentMode;
    createdById?: number;
    creationDate?: number;
    assignments?: {
      wish?: Array<number>;
      take?: Array<number>;
    };
    offlineEvents?: Array<EventSchedule>;
    participantListTemplate?: FileInfo;
    participantListTemplateId?: number;
    published?: boolean;
    registrationConfirmTemplate?: FileInfo;
    registrationConfirmTemplateId?: number;
    participationConfirmationTemplate?: FileInfo;
    participationConfirmationTemplateId?: number;
    tags?: string;
    eventType?: OfflineContent.EventType;
    requiredUserFields?: string[];
    allCanBook?: boolean;
    allCanRequest?: boolean;
    hasActiveAssignments: boolean;
    objType: Core.DistributableType;
    blockEvent: boolean;
    minUserCount: number;
    maxUserCount: number;
    prices?: Price[];
    actualUserCount?: number;
    modules: Array<EventModule>;
  }

  export interface CopyOptions {
    allChecked: boolean;
    events: boolean;
    event_agenda: boolean;
    event_location: boolean;
    event_trainer: boolean;
    event_provider: boolean;
    event_attachments: boolean;
    event_financials: boolean;
    other: boolean;
    other_settings: boolean;
    other_targetGroups: boolean;
    other_documents: boolean;
    other_userFields: boolean;
    assignments?: boolean;
    notifications: boolean;
    tags: boolean;
  }

  export interface CopyEventData {
    contentId: number;
  }

  export interface TagTitle {
    title: string;
  }

  export enum OfflineInvitationStatus {
    ack = 'ack',
    can = 'can',
    dec = 'dec',
    frontend = 'frontend',
    inv = 'inv',
    link = 'link',
    rem = 'rem',
    reg = 'reg',
  }

  export enum PaymentStatus {
    success = 'success',
    declined = 'declined',
    error = 'error',
  }

  export enum OfflineParticipationStatus {
    ACCEPTED = 'ACCEPTED',
    INVITED = 'INVITED',
    PARTICIPATED = 'PARTICIPATED',
    DESIRED = 'DESIRED',
    DECLINED = 'DECLINED',
    NOT_ATTENDED = 'NOT_ATTENDED',
  }

  export const offlineParticipationStatusFactory = (value: string | number): OfflineParticipationStatus => {
    if ( value != null ) {
      switch ( value ) {
        case 'ACCEPTED':
        case 0:
        case OfflineParticipationStatus.ACCEPTED:
          return OfflineParticipationStatus.ACCEPTED;
        case 'INVITED':
        case 1:
        case OfflineParticipationStatus.INVITED:
          return OfflineParticipationStatus.INVITED;
        case 'PARTICIPATED':
        case 2:
        case OfflineParticipationStatus.PARTICIPATED:
          return OfflineParticipationStatus.PARTICIPATED;
        case 'DESIRED':
        case 3:
        case OfflineParticipationStatus.DESIRED:
          return OfflineParticipationStatus.DESIRED;
        case 'DECLINED':
        case 4:
        case OfflineParticipationStatus.DECLINED:
          return OfflineParticipationStatus.DECLINED;
      }
    }
    return null;
  };

  export enum OfflineParticipationRegistrationStatus {
    NOT_GENERATED = 'NOT_GENERATED',
    CONFIRMATION_GENERATED = 'CONFIRMATION_GENERATED',
    CONFIRMATION_SENT = 'CONFIRMATION_SENT',
  }

  export enum MerchantTransactionType {
    checkout = 'checkout',
    refund = 'refund',
  }
  export enum MerchantStatus {
    success = 'success',
    declined = 'declined',
    error = 'error',
    authorization_pending = 'authorization_pending',
    processing = 'processing',
    pending = 'pending',
    canceled = 'canceled',
  }

  export interface MerchantTransaction extends Identifiable {
    whenCreated: number;
    bookingUUID: string;
    userId: number;
    type: MerchantTransactionType;
    payer?: Payer;
    provider: string;
  }

  export interface Payer {
     extId: string;
     firstName: string;
     lastName: string;
     email: string;
     billFirstName: string;
     billLastName: string;
     billCity: string;
     billCountryCode: string;
     billPostalCode: string;
     billState: string;
     billAddressLine1: string;
     billAddressLine2: string;
  }

  export const offlineParticipationRegistrationFactory = (value: string | number):
    OfflineParticipationRegistrationStatus => {
    if ( value != null ) {
      switch ( value ) {
        case 'NOT_GENERATED':
        case OfflineParticipationRegistrationStatus.NOT_GENERATED:
          return OfflineParticipationRegistrationStatus.NOT_GENERATED;
        case 'CONFIRMATION_GENERATED':
        case OfflineParticipationRegistrationStatus.CONFIRMATION_GENERATED:
          return OfflineParticipationRegistrationStatus.CONFIRMATION_GENERATED;
        case 'CONFIRMATION_SENT':
        case OfflineParticipationRegistrationStatus.CONFIRMATION_SENT:
          return OfflineParticipationRegistrationStatus.CONFIRMATION_SENT;
      }
    }
    return null;
  };

  export interface IUser {
    userId: number;
  }

  export interface Participant extends IUser {
    assignmentMode: EventAssignmentMode;
    email: string;
    employeeID: string;
    examinationStatus: DisplayStatus;
    firstname: string;
    invitationStatus: OfflineInvitationStatus;
    invitationStatusDate: number;
    lastname: string;
    locationDesc: string;
    login: string;
    paymentStatus: PaymentStatus;
    merchantStatus: MerchantStatus;
    merchantTransactionType: MerchantTransactionType;
    /**
     * stringified {@link IEventAccountStatus}
     */
    participationStatus: OfflineParticipationStatus;
    registrationStatus: OfflineParticipationRegistrationStatus;
    transactionStatus: string;
    attendanceDuration?: string;
    attendanceDurationPercentage?: number;
    payer?: Payer;
    provider?: string;
  }

  export interface ChangeEvent<T> {
    change: OfflineContent.ChangeEventType;
    data?: T;
    id: string | number;
  }

  export const isComplete = (closedStatus: OfflineContent.ClosedStatus) => closedStatus === 'aborted' || closedStatus === 'canceled' || closedStatus === 'done';

  export const isSuccessfulyClosed = (closedStatus: OfflineContent.ClosedStatus) => closedStatus === 'done';

  interface EventScheduleViewData {
    overlappingCounter: number;
    maxCountReached: boolean;
  }

  export interface EventScheduleCatalogView extends Identifiable, Titleable, TransientView<EventScheduleViewData> {
    bookingUntilEnd?: boolean;
    closedStatus?: OfflineContent.ClosedStatus;
    eventDate: number;
    eventDateUntil: number;
    location: Location;
    netDuration: number;
    trainers: Array<Trainer>;
    availablePlaces: number;
    extLogin?: ExternalLogin;
    priceCurrency?: string;
    priceValue?: number;
    priceDescription?: string;
    title?: Translateable;
    maxUserCount?: number;
    module?: OfflineContent.EventModule;
  }

  export interface EventCatalogView extends Identifiable, Titleable, Agendable, Priceable, LegallyConformable {
    assignmentMode: string;
    bookedEventIds?: number[];
    activeEventsAvailable: boolean;
    events: Array<EventScheduleCatalogView>;
    blockEvent: boolean;
    modules: Array<OfflineContent.EventModule>;
  }

  export interface BookableTypes {
    virtual?: boolean;
    offline?: boolean;
  }

  export interface ServiceEvent {
    type: ServiceEventType;
    content: Event | EventSchedule;
  }

}

export interface RouteData<T> {
  data: T;
}

export class AdminOfflineHelper {

  static participationStatusDisplayValue(
    participationStatus: OfflineContent.OfflineParticipationStatus | string | number | null,
  ): string {
    participationStatus = OfflineContent.offlineParticipationStatusFactory(participationStatus);
    const hasAttended = (participationStatus === OfflineContent.OfflineParticipationStatus.PARTICIPATED);
    if ( hasAttended ) {
      return $localize`:@@global_participation_status_participated:Participated`;
    } else {
      return $localize`:@@admin_offline_event_participants_not_attended:Not attended`;
    }
  }

  static participationStatusFilter(
    participationStatus: OfflineContent.OfflineParticipationStatus | string | number | null,
    filterValue: string | null,
  ): boolean {
    participationStatus = OfflineContent.offlineParticipationStatusFactory(participationStatus);
    const hasAttended = (participationStatus === OfflineContent.OfflineParticipationStatus.PARTICIPATED);
    if ( !hasAttended ) {
      participationStatus = OfflineContent.OfflineParticipationStatus.NOT_ATTENDED;
    }
    return participationStatus === filterValue;
  }

}
