import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import { LanguageHelper, LanguageInfo } from 'src/app/core/language.helper';
import { SelectLanguageAppearance } from '../select-language.types';

@Component({
  selector: 'rag-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit {

  @Input() languages = LanguageHelper.LANGUAGES;
  @Input() selected: LanguageInfo;
  @Input() flagsOnly = false;
  @Input() appearance: SelectLanguageAppearance = 'horizontal';
  @Input() disabled = false;
  @Output() languageChange: EventEmitter<LanguageInfo>;

  constructor() {
    this.languageChange = new EventEmitter();
   }

  ngOnInit(): void {
    if (this.selected === undefined && this.languages.length > 0) {
      this.selected = this.languages[0];
      this.languageChange.emit(this.selected);
    }
  }

  compareWith(lang1: LanguageInfo, lang2: LanguageInfo): boolean {
    return lang1.key === lang2.key;
  }

  getLanguageFlag(language: LanguageInfo): string {
    return LanguageHelper.getLanguageFlag(language);
  }

  onChange(change: MatButtonToggleChange) {
    this.selected = change.value;
    this.languageChange.emit(this.selected);
  }

  onSelectionChange($event: MatSelectChange) {
    this.selected = LanguageHelper.getLanguage($event.value);
    this.languageChange.emit(this.selected);
  }

}
