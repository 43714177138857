import { Component, Input, OnInit } from '@angular/core';
import { MessageKey } from '../info.types';

@Component({
  selector: 'rag-messages',
  templateUrl: './messages.component.html',
  styleUrls: [ './messages.component.scss' ],
})
export class MessagesComponent implements OnInit {
  @Input() key: string;
  @Input() messages: Array<string>;
  @Input() message: string;

  constructor() {
  }

  ngOnInit() {
    if ( this.messages === undefined && this.message === undefined && !this.key ) {
      this.key = MessageKey.GENERAL_ERROR;
      return;
    }
    if (this.message !== undefined) {
      this.messages = [this.message];
    }
  }

}
