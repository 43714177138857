import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelfRegisterBridgeComponent } from './self-register-bridge.component';
import { SelfRegisterFormModule } from '../self-register-form/self-register-form.module';
import { SelfRegisterModule } from '../self-register.module';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';


@NgModule({
  declarations: [ SelfRegisterBridgeComponent ],
  imports: [
    CommonModule,
    SelfRegisterFormModule,
    SelfRegisterModule,
    LoadingIndicatorComponent,
  ],
})
export class SelfRegisterBridgeModule {
}
