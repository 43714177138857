import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransactionsListService } from './transactions-list.service';
import { TransactionView } from '../transactions.types';

@Injectable({ providedIn: 'root' })
export class TransactionsListResolver
  implements Resolve<TransactionView[]> {

  constructor(
    private transactionsListService: TransactionsListService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TransactionView[]> {

    return this.transactionsListService.getTransactions();
  }
}
