<ng-template #tplFieldDate>
  <rag-profile-field-date
    [disabled]="disabled"
    [field]="field"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-date>
</ng-template>

<ng-template #tplFieldDropDown>
  <rag-profile-field-dropdown
    [disabled]="disabled"
    [field]="field"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-dropdown>
</ng-template>

<ng-template #tplFieldEmail>
  <rag-profile-field-email
    [disabled]="disabled"
    [field]="field"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-email>
</ng-template>

<ng-template #tplFieldMultiselect>
  <rag-profile-field-multiselect
    [field]="field"
    [disabled]="disabled"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-multiselect>
</ng-template>

<ng-template #tplFieldNumber>
  <rag-profile-field-number
    [field]="field"
    [disabled]="disabled"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-number>
</ng-template>

<ng-template #tplFieldPassword>
  <rag-profile-field-password
    [disabled]="disabled"
    [field]="field"
    [formGroup]="form"
    [showGeneratePassword]="showGeneratePassword"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-password>
</ng-template>

<ng-template #tplFieldRadio>
  <rag-profile-field-radio
    [disabled]="disabled"
    [field]="field"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-radio>
</ng-template>

<ng-template #tplFieldText>
  <rag-profile-field-text
    [disabled]="disabled"
    [field]="field"
    [suffixTemplate]="suffixTemplate"
  ></rag-profile-field-text>
</ng-template>

<div class="monolit">
  <ng-container
    [ngTemplateOutlet]="getTemplate(
      tplFieldDate,
      tplFieldDropDown,
      tplFieldEmail,
      tplFieldMultiselect,
      tplFieldNumber,
      tplFieldPassword,
      tplFieldRadio,
      tplFieldText
    )">
  </ng-container>

  <div class="hint">
    <rag-info-tooltip-button
      [info]="field"
    ></rag-info-tooltip-button>
  </div>
</div>

<rag-profile-field-consent
  *ngIf="field?.consentText"
  [disabled]="disabled || showGeneratePassword"
  [field]="field"
  [form]="form"
  [fieldControl]="form?.get(field?.fieldId)"
></rag-profile-field-consent>
