import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {ItemTypeIconModule} from '../item-type-icon/item-type-icon.module';
import {MatAutocompleteModule, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AnyObject, ImageableContentReference} from '../../../core/core.types';
import {ViewportState} from '../../../core/viewport-state';
import {ApplicationStateService} from '../../../core/application-state.service';
import {ContentService} from '../../../core/content/content.service';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {destroySubscriptions, subscribeUntilDestroyed} from '../../../core/reactive/until-destroyed';
import {delay, map, startWith, tap} from 'rxjs/operators';
import {ViewHelper} from '../../../core/view-helper';
import {naturalCompare} from '../../../core/natural-sort';
import {LanguageHelper} from '../../../core/language.helper';

@Component({
  selector: 'rag-content-autocomplete-v2',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ItemTypeIconModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    ReactiveFormsModule
  ],
  templateUrl: './content-autocomplete-v2.component.html',
  styleUrls: ['./content-autocomplete-v2.component.scss']
})
export class ContentAutocompleteV2Component
  implements OnDestroy {

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  readonly empty$: Observable<boolean>;
  filteredOptions$: Observable<ImageableContentReference[]>;
  formControl = new UntypedFormControl();
  @ViewChild('inputElement', { read: ElementRef }) inputElement: ElementRef;
  loading = true;
  options$: Observable<ImageableContentReference[]>;
  search = '';
  viewportState: ViewportState;
  private _empty = new BehaviorSubject<boolean>(true);

  constructor(
    private appState: ApplicationStateService,
    private contentService: ContentService,
    private navigationService: NavigationService,
  ) {
    this.empty$ = this._empty.asObservable();
    subscribeUntilDestroyed(this.appState.viewportState$.pipe(map(viewportState => {
      this.viewportState = viewportState;
    })), this);
  }

  private static accountToContents(contents: ImageableContentReference[]): ImageableContentReference[] {
    contents = ContentAutocompleteV2Component.flattenAccountData(contents);
    contents = ContentService.filterHiddenAndIndirectAssignments(contents);

    return Object
      .values(contents.reduce((pV, content) => {
        const viewData = ViewHelper.getViewData(content);
        const parent = viewData.parent;

        let sortTitle = (content.title ?? '') + '_' + content.id;
        const curriculum = viewData.curriculum;
        if ( curriculum != null ) {
          // include order inside the immediate parent
          sortTitle = (curriculum.title ?? '') + '_' + curriculum.id + '_' + content.index + '_' + sortTitle;
        }

        if ( parent != null ) {
          // add parent
          pV[parent.id + '_' + content.id] = content;
          if ( parent !== curriculum ) {
            // we need a second sorting layer to group wrapped curricula together
            sortTitle = (parent.title ?? '') + '_' + parent.id + '_' + sortTitle;
          }
        } else {
          pV['_' + content.id] = content;
        }

        // add text for sorting
        viewData.sortTitle = sortTitle.toLocaleLowerCase();

        return pV;
      }, {} as AnyObject<ImageableContentReference>))
      .sort((a, b) => naturalCompare(
        ViewHelper.getViewData(a).sortTitle,
        ViewHelper.getViewData(b).sortTitle,
      ));
  }

  private static flattenAccountData(account: ImageableContentReference[]): ImageableContentReference[] {
    return account
      .flatMap(content => {
        if ( content == null ) {
          // skip empty objects
          return [];
        } else if ( content.items?.length > 0 ) {
          // return content with items
          return [
            content,
            ...content.items,
          ];
        } else {
          return [ content ];
        }
      })
      // exclude anything without type
      .filter(content => content.objType != null);
  }

  fetchContents(): void {
    if ( !this.options$ ) {
      this.options$ = this.contentService.fetchAccountData()
        .pipe(map(ContentAutocompleteV2Component.accountToContents))
        .pipe(delay(0))
        .pipe(tap((options) => {
          this._empty.next(options.length === 0);
          this.loading = false;
        }));

      this.filteredOptions$ = this.createSearchFilter();
    }
  }

  isDisabled(content: ImageableContentReference) {
    return ContentService.isCourseLocked(content);
  }

  navigateToContent(content: ImageableContentReference) {
    if ( !(content && content.objType) ) {
      return;
    }

    const contentHref = ContentService.getContentHref(content, true);
    if ( contentHref ) {
      this.goTo([ contentHref ]);
    } else {
      this.search = LanguageHelper.objectToText(content.title);
      this.goTo();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  searchContents(): void {
    if ( !this.search ) {
      return;
    }

    const search = this.search;
    this.reset();
    this.navigationService.navigateByUrl('/content-overview', { queryParams: { search } });
  }

  private createSearchFilter(): Observable<ImageableContentReference[]> {
    // todo add lazy loading
    const sources = [
      this.options$,
      this.formControl.valueChanges.pipe(startWith('')),
    ];
    return combineLatest(sources)
      .pipe(map(([ contents, input ]) => {
        // convert search term to lower case
        input = (input ?? '').trim().toLocaleLowerCase();

        this.search = input;
        if ( this.viewportState.isSmall() && !(input.length > 0) ) {
          // small displays require some search term to display results
          return [];
        } else if ( !input ) {
          // larger devices show all contents if no search term is given
          return contents;
        }

        return contents
          // match title to search term
          .filter(content => (content?.title?.toLocaleLowerCase().indexOf(input) >= 0));
      }));
  }

  private goTo(target?: any[]): void {
    if ( target ) {
      this.reset();
      this.navigationService.navigate(target);
    } else {
      this.searchContents();
    }
  }

  private reset() {
    this.search = '';
    this.formControl.reset();
    this.autocompleteTrigger.closePanel();
    setTimeout(() => {
      if ( this.inputElement.nativeElement ) {
        this.inputElement.nativeElement.blur();
      }
    });
  }


}
