import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CertificatesService } from 'src/app/route/user/certificates/certificates.service';
import { Certificate } from 'src/app/route/user/certificates/certificates.types';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CertificatesResolverService
  implements Resolve<Certificate[]> {

  constructor(private certificatesService: CertificatesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Certificate[]> {
    return this.certificatesService.getCertificates(true).pipe(take(1));
  }
}
