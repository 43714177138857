<mat-card class="card">
  <mat-card-header>
    <div
      mat-card-avatar
      *ngIf="getImageUrl() as url"
      class="avatar"
      [ngStyle]="{'background-image': 'url(' + url + ')'}">
    </div>
    <mat-card-title>{{name}}</mat-card-title>
    <mat-card-subtitle>{{moreInformation}}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <p *ngIf="!showMoreDescription">{{description}}</p>

    <div *ngIf="showMoreDescription" class="long-description">
      <p class="description">{{description}}</p>
    </div>

    <ng-container *ngIf="hasMoreDescription">

      <button
        *ngIf="showMoreDescription"
        mat-button
        type="button"
        (click)="onToggleShowMoreDescription()"
      >
        <span i18n="@@global_show_all">Display more</span>
      </button>

      <button
        *ngIf="!showMoreDescription"
        mat-button
        type="button"
        (click)="onToggleShowMoreDescription()"
      >
        <span i18n="@@global_show_less">Display less</span>
      </button>

    </ng-container>

  </mat-card-content>
</mat-card>
