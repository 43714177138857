import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LearningTimeChartComponent } from './learning-time-chart.component';
import { LearningTimeChartDirective } from './learning-time-chart.directive';

@NgModule({
  declarations: [
    LearningTimeChartComponent,
    LearningTimeChartDirective,
  ],
  exports: [
    LearningTimeChartComponent,
    LearningTimeChartDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
})
export class LearningTimeChartModule {
}
