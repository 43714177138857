import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserCurriculumService } from './user-curriculum.service';
import { UserCurriculumData } from './user-curriculum.types';

@Injectable()
export class UserCurriculumResolver
  implements Resolve<UserCurriculumData> {

  constructor(
    private userCurriculumService: UserCurriculumService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserCurriculumData> {
    const userId = Number(route.paramMap.get('userId'));
    if ( !(userId > 0) ) {
      return throwError('Missing required userId parameter');
    }
    const curriculumId = Number(route.paramMap.get('curriculumId'));
    if ( !(curriculumId > 0) ) {
      return throwError('Missing required curriculumId parameter');
    }

    return this.userCurriculumService.getCurriculumForUser(userId, curriculumId)
      .pipe(take(1));
  }

}
