import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OAuth2ClientRegistration } from '../ext-oauth2.types';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { ExtOauth2Service } from '../ext-oauth2.service';
import { EMPTY } from 'rxjs';
import { ErrorHelper } from '../../../../../core/error.helper';
import { MessageConstants } from '../../../../../core/info/info.types';


@Component({
  selector: 'rag-ext-oauth2-edit',
  templateUrl: './ext-oauth2-edit.component.html',
  styleUrls: [ './ext-oauth2-edit.component.scss' ],
})
export class ExtOauth2EditComponent
  implements OnDestroy {

  errorMessage: string;
  formControlIssuerUri: UntypedFormControl;
  formControlJsonData: UntypedFormControl;
  formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OAuth2ClientRegistration,
    private dialogRef: MatDialogRef<ExtOauth2EditComponent>,
    private formBuilder: UntypedFormBuilder,
    private extOauth2Service: ExtOauth2Service,
  ) {

    this.formControlIssuerUri = new UntypedFormControl(this.data?.issuerUri, [ Validators.required ]);
    this.formControlJsonData = new UntypedFormControl(this.jsonData, [ Validators.required ]);
    this.formGroup = this.formBuilder.group({
      jsonData: this.formControlJsonData,
      issuerUri: this.formControlIssuerUri,
    });

    this.formControlJsonData.valueChanges
      .pipe(tap(value => this.onConfigChanged(value)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get jsonData(): string | null {

    if ( this.data == null ) {
      return null;
    }

    return JSON.stringify(this.data, null, 2);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onConfigChanged(jsonData: string): void {
    try {
      this.data = JSON.parse((jsonData ?? '{}').trim());
      this.setError();
    } catch ( e ) {
      this.setError(true, e?.message);
    }
  }

  onFetchWellKnown(): void {

    this.formGroup.disable({ emitEvent: false });
    const issuerUri = this.formControlIssuerUri.value;
    this.extOauth2Service.fetchWellKnown(issuerUri)
      .pipe(take(1))

      .pipe(catchError(e => {

        let message: string;
        switch ( ErrorHelper.findApiError(e)?.errorCode ?? '' ) {

          case 'ERR_OAUTH_011':
            message = $localize`:@@ext_oauth2_error_issuer_invalid:
              The URL you entered does not provide the required configuration.`;
            break;

          case 'ERR_OAUTH_012':
            message = $localize`:@@ext_oauth2_error_issuer_not_matching:
              The URL you entered does not exactly match the URL from the response.<br />
              Please try to add, or remove a trailing / and verify that you are using the correct protocol
              (most likely HTTPS).`;
            break;

          default:
            message = MessageConstants.API.ERROR;
        }
        this.setError(true, message);

        return EMPTY;
      }))

      .pipe(tap(response => {
        if ( response == null ) {
          return;
        }

        Object.entries(response)
          .map(([ key, value ]) => {
            this.data[key] = value;
            this.formControlJsonData
              .setValue(JSON.stringify(this.data, null, 2));
          });

        this.setError();
      }))
      .pipe(finalize(() => this.formGroup.enable({ emitEvent: false })))
      .subscribe();
  }

  onSubmit(): void {
    if ( !!this.errorMessage ) {
      return;
    }

    this.extOauth2Service.updateClientRegistration(this.data)

      .pipe(catchError(e => {

        let message: string;
        switch ( ErrorHelper.findApiError(e)?.errorCode ?? '' ) {

          case 'ERR_OAUTH_013':
            message = $localize`:@@ext_oauth2_error_client_id_missing:
              Please enter a valid client Id.`;
            break;

          case 'ERR_OAUTH_014':
            message = $localize`:@@ext_oauth2_error_client_name_missing:
              Please enter a valid client name.`;
            break;

          case 'ERR_OAUTH_015':
            message = $localize`:@@ext_oauth2_error_client_secret_missing:
              Please enter a valid client secret.`;
            break;

          default:
            message = MessageConstants.API.ERROR;
        }
        this.setError(true, message);

        return EMPTY;
      }))

      // close dialog with update result
      .pipe(tap(result => this.dialogRef.close(result)))

      .pipe(take(1))
      .subscribe();
  }

  private setError(state?: boolean, message?: string | null): void {
    if ( state === true ) {
      this.errorMessage = message;
      this.formGroup.setErrors({ invalid: true });
    } else {
      this.errorMessage = null;
      this.formGroup.setErrors(null);
    }
  }

}
