<div class="container">

  <mat-chip-grid>
    <mat-chip-row
      *ngFor="let reportData of reportUUIDs"
      (click)="onClick(reportData)"
      (removed)="onRemoveReportLink(reportData)">
      <div class="wrapper">
        <mat-icon svgIcon="link"></mat-icon>
        {{reportData.title}}
      </div>
      <button matChipRemove [attr.aria-label]="'remove ' + reportData">
        <mat-icon matChipRemove svgIcon="close"></mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>

</div>
