import { UserProfileAttribute, UserProfileResponse } from '../../../user/user-profile/user-profile.types';
import { SelfRegisterInfoResponse } from './self-register-form.types';
import { LanguageHelper } from '../../../../core/language.helper';
import { OPTIONS_3LC, RegistrationPreview } from '../self-register.types';
import { AnyObject } from '../../../../core/core.types';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';
import { FormGroup } from '@angular/forms';
import * as uuid from 'uuid';
import { hasTableColumnDataTypeDropDown } from '../../../../component/table/table-column.types';
import { AccountInterceptor } from '../../../../core/interceptors/account.interceptor';
import { url as BASE_URL } from '../../../../app.state';
import { UserAttributeType } from 'src/app/core/admin-user-attribute.types';


export class SelfRegisterFormHelper {

  public static asUserProfileResponse(
    sortedAttributes: UserProfileAttribute[],
  ): UserProfileResponse {

    const groups = [ {
      id: 0, orderIndex: 0,
      title: $localize`:@@self_register_form_group_name:Account data`,
    } ];

    const attributes = {
      0: sortedAttributes,
    };

    return { groups, attributes };
  }

  public static attributesFromForm(
    sortedAttributes: UserProfileAttribute[],
    extLoginField: string | null,
    formGroup: FormGroup,
  ): AnyObject<UserProfileAttribute> {

    const attributes = sortedAttributes
      .reduce((pV, attribute) => {
        const fieldId = attribute.fieldId;
        pV[fieldId] = attribute;

        const control = formGroup.get(fieldId);
        if ( control?.valid && control.touched ) {
          // only overwrite valid and changed values
          attribute.value = ProfileFieldTypes.fromControlValue(attribute.type, control.value);
        }

        return pV;
      }, {});

    if ( !attributes['login']?.value ) {
      const login = (attributes['login'] ??= {
        fieldId: 'login',
        type: ProfileFieldTypes.ProfileFieldType.text,
      });

      const email = attributes['email']?.value;
      if ( email && (extLoginField === 'email') ) {
        login.value = email;
      } else {
        login.value = uuid.v4();
      }
    }

    if ( !attributes['pass']?.value ) {
      const password = (attributes['pass'] ??= {
        fieldId: 'pass',
        type: ProfileFieldTypes.ProfileFieldType.password,
      });
      password.value = uuid.v4();
    }

    return attributes;
  }

  public static getSortedAttributes(
    data: SelfRegisterInfoResponse | null,
  ): UserProfileAttribute[] {

    const attributeMap = Object.values(data?.attributes ?? {})
      .flatMap(o => o)
      .map(attribute => {

        // allow writing any attribute from self registration
        attribute.editable = true;

        if ( (attribute.options != null) ||
          (attribute?.type !== ProfileFieldTypes.ProfileFieldType.dropdown) ) {
          // type is not dropdown or options are supplied -> nothing to inject
          return attribute;
        }

        const label = LanguageHelper.objectToText(attribute.label);
        if ( /3LC/.test(label) ) {
          // replace options with 3LC
          // todo replace with proper dropdown options
          attribute.options = OPTIONS_3LC;
        }
        return attribute;
      }).reduce((pV, a) => {
        pV[a.fieldId] = a;
        return pV;
      }, {});

    return (data?.attributeOrder ?? []).map(a => {
      switch ( a ) {
        case 'password':
          return 'pass';
        case 'gender':
          return 'sex';
        default:
          return a;
      }
    }).map(a => attributeMap[a])
      .filter(a => a != null);
  }

  public static previewDataFromAttributes(
    attributes: AnyObject<UserProfileAttribute> | null,
  ): RegistrationPreview {
    return Object.values(attributes ?? {})
      .reduce((pV, attribute) => {

        const fieldId = attribute?.fieldId;
        if ( !(fieldId && attribute.type) || (fieldId === 'pass') ) {
          return pV;
        }

        const title = LanguageHelper.objectToText(attribute.label);
        if ( !title ) {
          return pV;
        }

        const options = attribute.options;
        if ( (options == null) || !hasTableColumnDataTypeDropDown(attribute.type) ) {
          pV[fieldId] = { title, value: attribute.value };
          return pV;
        }

        let values: string[];
        if ( attribute.type !== UserAttributeType.multiselect ) {
          const attributeValue = attribute.value;
          values = [
            LanguageHelper.objectToText(options.find(option => option.key === attributeValue)?.value) ?? attributeValue,
          ];
        } else {
          const tokens = (attribute.value + '').split(',')
            .map(token => token.trim())
            .filter(token => !!token);
          values = options
            .filter(option => tokens.includes(option.key))
            .map(option => LanguageHelper.objectToText(option.value));
        }

        const value = values.join(', ');
        pV[fieldId] = { title, value };
        return pV;
      }, {});
  }

  public static requestBodyFromAttributes(
    attributes: AnyObject<Partial<UserProfileAttribute>>,
    processId?: string,
  ): AnyObject {

    const requestBody = Object.values(attributes)
      .reduce((pV, attribute) => {

        if ( attribute.value == null || attribute.value === '' ) {
          return pV;
        }

        const fieldId = attribute.fieldId;
        switch ( fieldId ) {
          case 'pass':
            pV['password'] = attribute.value;
            break;
          case 'login':
            // todo train api names this field as "true" if not required -.-
            pV['true'] = pV['username'] = attribute.value;
            break;
          default:
            pV[fieldId] = attribute.value;
        }

        return pV;
      }, {});

    if ( processId != null && processId !== '' ) {
      // pass on the process id
      requestBody['processId'] = processId;
    }

    const accountKey = AccountInterceptor.getAccountKey();
    requestBody['url'] = BASE_URL + window.location.pathname + '?key=' + accountKey + '#/register';

    return requestBody;
  }

}
