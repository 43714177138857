import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { ReportNavComponent } from '../report-nav/report-nav.component';
import { ReportListComponent } from './report-list.component';

@NgModule({
  declarations: [
    ReportListComponent,
  ],
  exports: [
    ReportListComponent,
  ],
  imports: [
    ClampComponent,
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    PageHeaderComponent,
    PipesModule,
    ReportNavComponent,
    RouterModule,
  ],
})
export class ReportListModule {
}
