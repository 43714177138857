import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CachedSubject } from '../cached-subject';
import { DialogHeader } from './dialog-header.types';

@Injectable({
  providedIn: 'root'
})
export class DialogHeaderService {

  headerEvents$: Observable<DialogHeader.Event>;

  private _headerEvents$ = new CachedSubject<DialogHeader.Event>(null);

  constructor() {
    this.headerEvents$ = this._headerEvents$.withoutEmptyValues();
  }

  publish(event: DialogHeader.Event) {
    this._headerEvents$.next(event);
  }
}
