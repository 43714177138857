import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OfflineContent } from 'src/app/core/admin-offline.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  selector: 'rag-offline-content-closed-status',
  templateUrl: './offline-content-closed-status.component.html',
  styleUrls: [ './offline-content-closed-status.component.scss' ],
})
export class OfflineContentClosedStatusComponent
  implements OnInit {

  @Input() closedStatus: OfflineContent.ClosedStatus;

  constructor() {
  }

  isComplete(closedStatus: OfflineContent.ClosedStatus) {
    return OfflineContent.isComplete(closedStatus);
  }

  isGreen(closedStatus: OfflineContent.ClosedStatus) {
    return this.isComplete(closedStatus) && OfflineContent.isSuccessfulyClosed(closedStatus);
  }

  isRed(closedStatus: OfflineContent.ClosedStatus) {
    return this.isComplete(closedStatus) && !OfflineContent.isSuccessfulyClosed(closedStatus);
  }

  ngOnInit() {
  }

}
