import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageHelper } from 'src/app/core/language.helper';
import { AnyObject, Core, ImageableContentReference } from '../../../core/core.types';

@Component({
  selector: 'rag-curriculum-title',
  templateUrl: './curriculum-title.component.html',
  styleUrls: [ './curriculum-title.component.scss' ],
})
export class CurriculumTitleComponent
  implements OnChanges {

  @Input() backParams: AnyObject<string>;
  @Input() backUrl = '/content-overview';
  @Input() courseType: Core.CourseType;
  @Input() curriculum: ImageableContentReference;
  @Input() description: string;
  @Input() displayStatus: string | number;
  @Input() hasConfirmation: boolean;
  @Input() subtitle: string;
  showStatus: boolean;
  @Input() title: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( this.curriculum ) {
      this.displayStatus = this.curriculum.displaystatus;
      this.title = LanguageHelper.objectToText(this.curriculum.title);
      this.description = LanguageHelper.objectToText(this.curriculum.description);
      this.courseType = this.curriculum.courseType;
      this.hasConfirmation = this.curriculum.hasConfirmation;
    }
    this.showStatus = !(this.displayStatus === undefined || this.displayStatus == null);
  }

}
