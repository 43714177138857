import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';

export namespace CtrlSingleUserDetailsLearningDataColumns {

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    displayStatus: TableControllerTypes.ColumnMenuItem;
    executionDate: TableControllerTypes.ColumnMenuItem;
    orderIndex: TableControllerTypes.ColumnMenuItem;
    result: TableControllerTypes.ColumnMenuItem;
    title: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'orderIndex' ],
    endWith: [ 'actions' ],
    menuItems: {
      orderIndex: TableControllerTypes.Util.structuralColumnFactory('orderIndex', $localize`:@@global_nr:No.`),

      title: TableControllerTypes.Util.textColumnFactory('title', $localize`:@@global_title:Title`, null, true),
      displayStatus: TableControllerTypes.Util.displayStatusColumnFactory('displayStatus',
        $localize`:@@global_status:Status`, {
          green: $localize`:@@status_light_tooltip_green:Green`,
          yellow: $localize`:@@status_light_tooltip_yellow:Yellow`,
          red: $localize`:@@status_light_tooltip_red:Red`,
          not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
          not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
        }, $localize`:@@report_filter_status_is:Status is...`, null, true),
      executionDate: TableControllerTypes.Util.dateColumnFactory('executionDate',
        $localize`:@@ctrl_single_user_details_executionDate:Finish date`, null, true),
      result: TableControllerTypes.Util
        .numberColumnFactory('result', $localize`:@@ctrl_single_user_details_result:Result`, null, true),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };

}
