<form
  *ngIf="(sections?.length > 0); else tplEmptyProfile"
  [formGroup]="formGroup"
  (ngSubmit)="onSave()"
>
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@profile_title">Profile</span>
    </div>

    <div class="page-header-buttons" [ngClass]="{'read-only': isReadOnly}">

      <ng-container *ngIf="isEditModeActive; else tplEditButton">
        <button
          type="button"
          mat-stroked-button
          class="rag-stroked-button"
          (click)="onCancel()"
        >
          <span i18n="@@global_cancel">Cancel</span>
        </button>

        <button
          mat-flat-button
          color="primary"
          [disabled]="isSaveButtonDisabled$ | async"
        >
          <span i18n="@@global_save">Save</span>
        </button>
      </ng-container>
    </div>
  </rag-page-header>

  <div class="profile-sections">
    <rag-profile-section-wrapper
      [sections]="sections"
      [disabled]="isInputDisabled()"
    ></rag-profile-section-wrapper>
  </div>
</form>

<ng-template #tplEmptyProfile>
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@profile_title">Profile</span>
    </div>
  </rag-page-header>

  <div class="no-data">
    <span
      i18n="@@user_profile_no_options_available">You are not allowed to make any changes to your user account.</span>
  </div>
</ng-template>

<ng-template #tplEditButton>

  <button
    type="button"
    mat-stroked-button
    class="mat-button-fix"

    [matMenuTriggerFor]="headerMenu">
    <mat-icon svgIcon="menu"></mat-icon>

    <mat-menu #headerMenu xPosition="before">

      <button
        type="button"
        mat-menu-item
        (click)="onEdit()"
      >
        <mat-icon svgIcon="pencil-outline"></mat-icon>
        <span i18n="@@global_edit">Edit</span>
      </button>

      <button
        type="button"
        mat-menu-item
        *ngIf="isUserProfileExportable()"
        [routerLink]="'/my-profile/export'"
      >
        <mat-icon svgIcon="export"></mat-icon>
        <span i18n="@@profile_export_user">Profile export</span>
      </button>

      <button
        type="button"
        mat-menu-item
        *ngIf="hasEditCredentials()"
        (click)="onEditCredentials()"
      >
        <mat-icon svgIcon="form-textbox-password"></mat-icon>
        <span i18n="@@user_profile_edit_credentials">Edit credentials</span>
      </button>

      <ng-container *ngIf="isAuthenticated">

        <mat-divider></mat-divider>

        <button
          type="button"
          mat-menu-item
          color="warn"
          (click)="onDeactivate()"
        >
          <mat-icon svgIcon="close-circle-outline"></mat-icon>
          <span i18n="@@global_deactivate">Deactivate</span>
        </button>
      </ng-container>
    </mat-menu>
  </button>
</ng-template>
