<div class="curriculum-info-wrapper">
  <div *ngIf="showDescription && curriculum.description" [innerHTML]="curriculum.description | safe:'html'"
       class="curriculum-info"></div>

  <div *ngIf="hasValidity" class="validities">
    <div *ngIf="validSince" class="date-wrapper date-valid-since">
      <div class="date-label" >
        <span i18n="@@elearning_curriculum_info_valid_since">Valid since:</span>
      </div>
      <div class="date-value">{{validSince | formatDate:'date'}}</div>
    </div>

    <div *ngIf="availableSince" class="date-wrapper date-available-since">
      <div class="date-label">
        <span i18n="@@elearning_curriculum_info_available_since">Available since:</span>
      </div>
      <div class="date-value">{{availableSince | formatDate:'date'}}</div>
    </div>

    <div *ngIf="dueBy" class="date-wrapper date-due-by">
      <div class="date-label">
        <span i18n="@@elearning_curriculum_info_due_by">Due by:</span>
      </div>
      <div class="date-value">{{dueBy | formatDate:'date' }}</div>
    </div>
  </div>

</div>
