import { Component, Input } from '@angular/core';

@Component({
  selector: 'rag-mail-attachment-progress',
  templateUrl: './mail-attachments-progress.component.html',
  styleUrls: [ './mail-attachments-progress.component.scss' ],
})
export class MailAttachmentProgressComponent {
  @Input() value: number;
}
