import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnonymousRegisterDialogComponent } from './anonymous-register-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';



@NgModule({
  declarations: [
    AnonymousRegisterDialogComponent
  ],
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    LoadingIndicatorComponent,
  ],
  exports: [
    AnonymousRegisterDialogComponent,
  ]
})
export class AnonymousRegisterDialogModule { }
