import { Component, Inject } from '@angular/core';
import { Core } from '../../../../../core/core.types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LicenceInfoDialogTypes } from './licence-info-dialog.types';

@Component({
  selector: 'rag-licence-info-dialog',
  templateUrl: './licence-info-dialog.component.html',
  styleUrls: [ './licence-info-dialog.component.scss' ],
})
export class LicenceInfoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LicenceInfoDialogTypes.DialogData,
  ) {
  }

  get curriculumTitle() {
    return this.data.curriculumTitle;
  }

  get licenses() {
    return this.data.licences;
  }

  get licensesCount() {
    return this.data.licences.length;
  }

  get userName() {
    return this.data.userName;
  }

  isAssignmentMandatory(assignment: Core.DistAssignmentType) {
    return (assignment === 'mandatory') || (assignment === 'both');
  }

}
