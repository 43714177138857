import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RagCKEditorDirective } from './rag-ckeditor.directive';


@NgModule({
  declarations: [ RagCKEditorDirective ],
  exports: [
    RagCKEditorDirective,
    CKEditorModule,
  ],
  imports: [
    CommonModule,
    CKEditorModule,
  ],
})
export class RagCKEditorModule {
}
