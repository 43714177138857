<rag-dialog-header>
  <span i18n="@@global_iteration">Iteration</span>&nbsp;{{iteration}}
</rag-dialog-header>

<div mat-dialog-content>
  <!-- summary information -->
  <table>
    <thead>
      <tr>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="label"><span i18n="@@global_name">Name</span>:</td>
        <td>{{status.name}}</td>
      </tr>
      <tr>
        <td class="label"><span i18n="@@global_curriculum">Curriculum</span>:</td>
        <td>{{status.curstatus.curriculumTitle}}</td>
      </tr>
      <tr>
        <td class="label"><span i18n="@@start_processing_phase">Start of the processing phase:</span>:</td>
        <td>{{status.curstatus.startDate}}</td>
      </tr>
      <tr>
        <td class="label"><span i18n="@@content_overview_selectable_lastValidSince">Valid since</span>:</td>
        <td>{{status.curstatus.validSince}}</td>
      </tr>
      <tr>
        <td class="label"><span i18n="@@ctrl_single_user_details_validUntil">Valid until</span>:</td>
        <td>{{status.curstatus.validUntil}}</td>
      </tr>
    </tbody>
  </table>

  <!-- Items -->
  <table id="items" mat-table [dataSource]="datasource">

    <!-- Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_status">Status</span>
      </th>
      <td class="center" mat-cell *matCellDef="let item">
        <div>
          <rag-status-lights
            [displayHint]="false"
            [displayStatus]="displayStatus(item.displayStatus)"
            [hideToolTip]="true"
            statusType="DisplayStatus"
          ></rag-status-lights>
        </div>
      </td>
    </ng-container>

    <!-- Title -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_title">Title</span>
      </th>
      <td class="center" mat-cell *matCellDef="let item">
        <div>{{item.title}}</div>
      </td>
    </ng-container>

    <!-- EditedAt -->
    <ng-container matColumnDef="editedAt">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_last_attempted">Last attempted</span>
      </th>
      <td class="center" mat-cell *matCellDef="let item">
        <div>{{item.whenLastExecuted}}</div>
      </td>
    </ng-container>

    <!-- Result -->
    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_scores">Scores</span>
      </th>
      <td class="center" mat-cell *matCellDef="let item">
        <div>{{item.result}}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  </table>
</div>

<!-- <div mat-dialog-actions>
  <button [mat-dialog-close] cdkFocusInitial class="rag-stroked-button" mat-stroked-button>Ok</button>
</div> -->
