import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrls } from '../../../../core/api.urls';
import { NavigationService } from '../../../../core/navigation/navigation.service';
import { Report, ReportConfig } from '../../../../core/report/report.types';
import { ViewHelper } from '../../../../core/view-helper';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType } from '../../../../core/info/info.types';

@Component({
  selector: 'rag-classic-report',
  templateUrl: './classic-report.component.html',
  styleUrls: [ './classic-report.component.scss' ],
})
export class ClassicReportComponent
  implements OnInit {

  reportConfig: ReportConfig;
  reportTemplateId: number;
  reports: Report[];
  url: string;
  isLoading$: Observable<boolean>;
  private _isLoading$ = new CachedSubject(true);

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private infoService: InfoService,
  ) {
    this.isLoading$ = this._isLoading$.asObservable();
  }

  ngOnInit() {
    window.setTimeout(() => {
      if (this._isLoading$.value === true) {
        this.infoService.showMessage($localize`:@@general_loading_time:The loading time is longer than usual. Please be patient.`, {
          infoType: InfoType.Information,
          durationInSeconds: 10,
        })
      }
    }, 10000)

    this.reports = this.route.snapshot.data.reports || [];
    const reportTemplateId = this.reportTemplateId = Number(this.route.snapshot.paramMap.get('reportTemplateId'));

    const filteredReports = this.reports
      .map(report => report && report.reportConfig)
      .filter(reportConfig => reportConfig.reportTemplateId === reportTemplateId);

    if ( filteredReports && filteredReports.length ) {
      this.reportConfig = filteredReports[0];
      const viewData = ViewHelper.getViewData(this.reportConfig);
      if ( viewData ) {
        viewData.tabOpen = true;
      }
    } else {
      this.navigationService.navigateBack('/report/v2/saved');
      return;
    }

    if ( this.reportConfig.reportTemplateType === 1 ) {
      this.url = ApiUrls.getKey('ReportClassicDashboard')
        .replace(/{reportTemplateId}/, '' + reportTemplateId);
    } else {
      this.url = ApiUrls.getKey('ReportClassic')
        .replace(/{reportTemplateId}/, '' + reportTemplateId);
    }

  }

  emitLoading(loading: boolean) {
    this._isLoading$.next(loading);
  }
}
