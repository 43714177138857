import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNavMainComponent } from './header-nav-main.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [ HeaderNavMainComponent ],
  exports: [ HeaderNavMainComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    PipesModule,
    MatDividerModule,
  ],
})
export class HeaderNavMainModule {
}
