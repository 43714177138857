import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State } from 'src/app/app.state';
import { ApiUrls } from 'src/app/core/api.urls';
import { Core } from 'src/app/core/core.types';
import { ApiResponse } from 'src/app/core/global.types';
import { BookingInProgress } from 'src/app/core/principal/principal.types';
import { isNothing } from 'src/app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  bookingInProgressHash: string;

  process$: Observable<Core.UserProcess>;
  private _context: any;

  constructor(
    private http: HttpClient
  ) {}

  getByHash(hash: string): Observable<Core.UserProcess> {
    if ( hash == null ) {
      return of(null);
    }

    const url = ApiUrls.getKey('Process') + '/' + hash;
    return this.http.get<ApiResponse<Core.UserProcess>>(url)
      .pipe(map(response => response.process))
      .pipe(tap(process => {
        if (process && process.type !== 'bookingInProgress' && process.state.bookingInProgressHash) {
          this.bookingInProgressHash = process.state.bookingInProgressHash;
        }
      }));
  }

  cancelProcess(hash: string): Observable<Core.UserProcess> {
    const url = ApiUrls.getKey('Process') + '/' + hash;
    return this.http.delete<ApiResponse<Core.UserProcess>>(url)
      .pipe(map(response => response.process));
  }

  setContext(obj: any) {
    this._context = obj;
  }

  getContext(): any {
    const context = this._context;
    // link current context with active bookingInPtogress, if any
    if (!isNothing(this.bookingInProgressHash)) {
      context['bookingInProgress'] = this.bookingInProgressHash;
    }
    return context;
  }

  getBookingInProgress(bookingInProgressHash: string): Observable<BookingInProgress> {
    return this.getByHash(bookingInProgressHash).pipe(map(process => process.state));
  }

  invalidateBookingInProgress(complete = false): Observable<boolean> {
    if (this.bookingInProgressHash == null) {
      return of(false);
    }
    const status = complete ? 'complete' : 'cancel';
    const url = `${ApiUrls.getKey('Process')}/${this.bookingInProgressHash}/${status}`;
    return this.http.delete<ApiResponse<void>>(url)
      .pipe(tap(() => this.deleteBookingInProgress()))
      .pipe(map(_ => true));
  }

  persistBookingInProgress(bookingInProgress: BookingInProgress): Observable<Core.UserProcess> {

    let url = ApiUrls.getKey('Process');
    let request: Observable<ApiResponse<Core.UserProcess>>;
    const payload = {
      type: 'bookingInProgress',
      state: bookingInProgress,
      language: State.language
    };
    if (this.bookingInProgressHash == null) {
      request = this.http.put<ApiResponse<Core.UserProcess>>(url, payload)
        .pipe(tap(response =>
          this.storeBookingInProgressHash(response.process.hash)));
    } else {
      url += `/${this.bookingInProgressHash}`;
      request = this.http.post<ApiResponse<Core.UserProcess>>(url, payload);
    }

    return request.pipe(map(response => response.process));
  }

  private deleteBookingInProgress() {
    this.bookingInProgressHash = null;
  }

  private storeBookingInProgressHash(hash: string) {
    this.bookingInProgressHash = hash;
  }
}
