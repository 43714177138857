import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { ProfileFieldTypes } from '../../../core/input/profile-field/profile-field.types';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UserAttributeType } from 'src/app/core/admin-user-attribute.types';


/**
 * You need to add a [formGroup] to some parent element to use this component.
 */
@Component({
  selector: 'rag-profile-field',
  templateUrl: './profile-field.component.html',
  styleUrls: [ './profile-field.component.scss' ],
})
export class ProfileFieldComponent
  implements OnInit {

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() disabled: boolean;
  @Input() field: ProfileFieldTypes.ProfileField;
  @Input() suffixTemplate: TemplateRef<any>;
  @Input() showGeneratePassword = false;
  form: UntypedFormGroup;

  constructor(
    private controlContainer: ControlContainer,
  ) {
  }

  getTemplate(
    tplFieldDate: TemplateRef<any>,
    tplFieldDropDown: TemplateRef<any>,
    tplFieldEmail: TemplateRef<any>,
    tplFieldMultiselect: TemplateRef<any>,
    tplFieldNumber: TemplateRef<any>,
    tplFieldPassword: TemplateRef<any>,
    tplFieldRadio: TemplateRef<any>,
    tplFieldText: TemplateRef<any>,
  ): TemplateRef<any> {
    switch ( this.field?.type ?? '' ) {

      case UserAttributeType.date:
        return tplFieldDate;

      case UserAttributeType.dropdown:
        return tplFieldDropDown;

      case UserAttributeType.email:
        return tplFieldEmail;

      case UserAttributeType.multiselect:
        return tplFieldMultiselect;

      case UserAttributeType.number:
        return tplFieldNumber;

      case UserAttributeType.password:
        return tplFieldPassword;

      case UserAttributeType.radio:
        return tplFieldRadio;

      case UserAttributeType.text:
      default:
        return tplFieldText;

    }
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control as UntypedFormGroup;
  }

}
