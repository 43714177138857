import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtrlSingleUserCurriculumItemDetailsComponent } from './ctrl-single-user-curriculum-item-details.component';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { SubHeaderTokensComponent } from 'src/app/component/page-header/sub-header-tokens/sub-header-tokens.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [ CtrlSingleUserCurriculumItemDetailsComponent ],
  exports: [ CtrlSingleUserCurriculumItemDetailsComponent ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    SubHeaderTokensComponent,
    PipesModule,
  ],
})
export class CtrlSingleUserCurriculumItemDetailsModule {
}
