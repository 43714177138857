import { Component, Input, OnInit } from '@angular/core';
import { DateHelper } from '../../../../core/date.helper';
import { QAQuestion } from '../qa.types';

@Component({
  selector: 'rag-qa-entry',
  templateUrl: './qa-entry.component.html',
  styleUrls: ['./qa-entry.component.scss']
})
export class QAEntryComponent
  implements OnInit {

  @Input() question: QAQuestion;
  @Input() isOpenedInCardView = true;
  constructor() { }

  ngOnInit(): void {
  }

  getAnsweredOnText(): string {
    const date = this.question?.answer?.creationDate;
    if (date == null) {
      return '';
    }

    const creationDate = DateHelper.format(date, 'DD.MM.YYYY HH:mm');
    let answerText = $localize`:@@global_answered_on:Answered on:` + ' ' + creationDate;

    const modifiedDate = this.question?.answer?.lastModified;
    if (modifiedDate != null) {
      const modifiedDateString = DateHelper.format(modifiedDate, 'DD.MM.YYYY HH:mm');
      answerText += ', ' + $localize`:@@global_last_modified:Last Modified:` + ' ' + modifiedDateString;
    }
    return answerText;
  }

  getAskedOnText(): string {
    const date = this.question?.creationDate;
    const creationDate = DateHelper.format(date, 'DD.MM.YYYY HH:mm');
    return $localize`:@@global_asked_on:Asked on:` + ' '  + creationDate;
  }

  getChangedText(): string | null {
    if (this.question?.modifiedByAdmin === true) {
      return $localize`:@@global_edited_by_admin:Edited by Admin`;
    }
    return null;
  }

}
