import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StorageHelper } from '../../../../../core/storage/storage.helper';
import { take, tap } from 'rxjs/operators';

export interface ReportTitleDialogComponentData {
  dialogTitleKey: string;
  hideOpenCheckbox?: boolean;
  title: string;
}

export interface ReportTitleDialogComponentResult {
  openAfterSave: boolean;
  title: string;
}

// TOOD: move it into global constants
const COOKIE_NAME = '@@report_open_after_save';

@Component({
  selector: 'rag-report-title-dialog',
  templateUrl: './report-title-dialog.component.html',
  styleUrls: [ './report-title-dialog.component.scss' ],
})
export class ReportTitleDialogComponent {

  dialogTitleKey: string;
  existingTitle: string;
  hideOpenCheckbox: boolean;
  myForm = new UntypedFormGroup({
    titleControll: new UntypedFormControl('', [ Validators.required ]),
  });
  openAfterSave = false;

  constructor(
    private dialogRef: MatDialogRef<ReportTitleDialogComponent, ReportTitleDialogComponentResult>,
    @Inject(MAT_DIALOG_DATA) private data: ReportTitleDialogComponentData,
  ) {
    this.dialogTitleKey = data.dialogTitleKey;
    this.existingTitle = data.title;
    this.hideOpenCheckbox = data.hideOpenCheckbox;
    this.myForm.get('titleControll').setValue(data.title);

    this.dialogRef.updateSize('40vw');

    StorageHelper.get<boolean>(COOKIE_NAME)
      .pipe(take(1))
      .pipe(tap(openAfterSave => this.openAfterSave = openAfterSave))
      .subscribe();
  }

  get isSubmitButtonDisabled() {
    return this.myForm.invalid ||
      (this.myForm.value['titleControll'] && this.myForm.value['titleControll'].trim().length === 0);
  }

  onCheckboxChange($event: MatCheckboxChange) {
    this.openAfterSave = $event.checked;

    StorageHelper.save<boolean>(COOKIE_NAME, this.openAfterSave)
      .pipe(take(1))
      .subscribe();
  }

  onSubmit($event: Event) {
    this.dialogRef.close({
      title: this.myForm.value['titleControll'].trim(),
      openAfterSave: this.openAfterSave,
    });
  }

}
