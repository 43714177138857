import { Core, HasInfoModel } from '../../core.types';
import { Translation } from '../../translation/translation.types';
import { DateHelper } from '../../date.helper';
import { UserAttribute, UserAttributeType } from '../../admin-user-attribute.types';


export class ProfileFieldTypes {

  static fromControlValue<T extends R, R>(
    type: ProfileFieldTypes.ProfileFieldType | string,
    controlValue: T | null,
  ): R | null {

    const unknownValue = controlValue;

    switch ( type ) {

      case ProfileFieldTypes.ProfileFieldType.date:
        const dateValue = DateHelper.toMoment(unknownValue as string);
        if ( dateValue != null ) {
          // static format of DD.MM.yyyy to prevent locale specific changes
          return dateValue.format('DD.MM.yyyy') as R;
        } else {
          return null;
        }

      case ProfileFieldTypes.ProfileFieldType.email:
      case ProfileFieldTypes.ProfileFieldType.radio:
      case ProfileFieldTypes.ProfileFieldType.text:
        if ( controlValue != null ) {
          return unknownValue;
        } else {
          return null;
        }

      case ProfileFieldTypes.ProfileFieldType.multiselect:
        if ( typeof (controlValue) === 'string' ) {
          return unknownValue;
        } else if ( Array.isArray(controlValue) ) {
          let stringValue = controlValue
            // ignore anything that is not a primitive
            .filter(o => typeof (o) !== 'object')
            // trim any strings
            .map(o => String(o).trim())
            // remove empty values
            .filter(o => !!o)
            // join by comma
            .join(',')
            // remove duplicate ','
            .replace(/[,]{2,}/g, ',');
          if (stringValue.length > 0) {
            stringValue = `,${stringValue},`;
          }
          return stringValue as R;
        }
        break;

      case ProfileFieldTypes.ProfileFieldType.number:
        const number = parseInt(unknownValue as string, 10);
        if ( !isNaN(number) ) {
          return number as R;
        } else {
          return '' as R;
        }

      case ProfileFieldTypes.ProfileFieldType.password:
        if ( controlValue && !/^[*]+$/.test(unknownValue as string) ) {
          return unknownValue as R;
        } else {
          return null;
        }
    }

    return unknownValue;
  }


  static toControlValue<T extends R, R>(
    type: ProfileFieldTypes.ProfileFieldType | string,
    value: T | null,
  ): R | null {

    const unknownValue = value;

    switch ( type ) {

      case ProfileFieldTypes.ProfileFieldType.date:
        const dateValue = DateHelper.toMoment(unknownValue as string);
        if ( dateValue != null ) {
          return dateValue as R;
        }
        break;

      case ProfileFieldTypes.ProfileFieldType.email:
      case ProfileFieldTypes.ProfileFieldType.radio:
      case ProfileFieldTypes.ProfileFieldType.text:
        if ( value != null ) {
          return unknownValue as R;
        }
        break;

      case ProfileFieldTypes.ProfileFieldType.multiselect:
        if ( typeof (unknownValue) === 'string' ) {
          return (unknownValue as string)
            .split(',') as R;
        } else if ( Array.isArray(value) ) {
          return unknownValue;
        }
        break;

      case ProfileFieldTypes.ProfileFieldType.number:
        const number = parseInt(unknownValue as string, 10);
        if ( !isNaN(number) ) {
          return number as R;
        }
        break;

      case ProfileFieldTypes.ProfileFieldType.password:
        if ( value ) {
          return unknownValue;
        } else {
          return null;
        }

    }

    return unknownValue;
  }

}

export namespace ProfileFieldTypes {

  export enum ProfileFieldType {
    date = 'date',
    dropdown = 'dropdown',
    email = 'email',
    multiselect = 'multiselect',
    number = 'number',
    password = 'password',
    radio = 'radio',
    text = 'text',
  }

  export interface ProfileFieldWithoutOptions<T = any>
    extends HasInfoModel {
    consentText?: string;
    editable: boolean;
    fieldId: string;
    groupId?: number;
    label: Translation;
    orderIndex?: number;
    required: boolean;
    setByInterface?: boolean;
    type: UserAttributeType;
    value?: T;
  }

  export interface ProfileField<T = any>
    extends ProfileFieldWithoutOptions<T> {
    options?: Core.KeyValue<string>[];
  }

  export type ProfileFieldRadio<T> = ProfileField<T>;

  export const isMultiValueType = (field: UserAttribute) =>
    field != null && ( field.attributeType === UserAttributeType.dropdown ||
      field.attributeType === UserAttributeType.radio ||
      field.attributeType === UserAttributeType.multiselect );
  };
