<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <rag-dialog-header>
    <span i18n="@@admin_signatures_copy_header">Copy signature</span>
  </rag-dialog-header>

  <div class="content" mat-dialog-content>
    <p i18n="@@admin_signature_copy_macro_name">Enter a macro name for the copy</p>

    <rag-admin-signatures-input-macro
      [form]="form"
      [control]="form?.get('macro')"
    ></rag-admin-signatures-input-macro>
  </div>

  <mat-dialog-actions>

    <button
      type="button" mat-stroked-button
      class="rag-stroked-button"
      [mat-dialog-close]="null"
      data-cy="cancelDialogButton"
    >
      <span i18n="@@global_cancel">Cancel</span>
    </button>

    <rag-form-submit-button
      [form]="form"
      (doSubmit)="onSubmit()"
      [disabled]="form.invalid || form.pristine"
      label="Save"
      i18n-label="@@global_save"
    ></rag-form-submit-button>

  </mat-dialog-actions>
</form>
