import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PermissionConceptSettings } from './admin-permission-concept.types';
import { Observable, map } from 'rxjs';
import { ApiUrls } from '../../../core/api.urls';
import { ApiResponse } from '../../../core/global.types';
@Injectable({
  providedIn: 'root',
})
export class AdminPermissionConceptService {

  constructor(
    private http: HttpClient,
  ) {}

  getPermissionConceptSettings(): Observable<PermissionConceptSettings> {
    const url = ApiUrls.getKey('PermissionConceptSettings');
    return this.http.get<ApiResponse<PermissionConceptSettings>>(url)
      .pipe(map(data => data.data))
  }

  savePermissionConceptSettings(
    selectedFile: File, excludedRoleIds: string, deleteFile: boolean,
  ): Observable<PermissionConceptSettings> {
    const url = ApiUrls.getKey('PermissionConceptSettings');
    const formData = new FormData();
    if (selectedFile != null) {
      formData.append('file', selectedFile);
    }
    formData.append('excludedRoleIds', excludedRoleIds);
    formData.append('deleteFile', String(deleteFile));

    return this.http.post<ApiResponse<PermissionConceptSettings>>(url, formData)
      .pipe(map(data => data.data));
  }
}
