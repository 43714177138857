<rag-content-autocomplete
  *ngIf="showContentSearch && isSmall"
></rag-content-autocomplete>

<mat-nav-list>
  <ng-container *ngFor="let navItem of navItems">

    <mat-divider *ngIf="navItem.type === 'divider'"></mat-divider>

    <button
      *ngIf="headerNavHelper.asButton(navItem) as button"
      mat-list-item type="button"
      (click)="headerNavHelper.onNavItemClick(button)"
    >
      <div class="title-wrapper">
        <span class="link-title">{{button.title}}</span>
        <mat-icon *ngIf="button.external" class="size-16" svgIcon="open-in-new"></mat-icon>
      </div>
    </button>

    <a
      *ngIf="headerNavHelper.asLink(navItem) as link"
      mat-list-item
      [routerLink]="link.routerLink"
      [queryParams]="link.queryParams"
      routerLinkActive="active"
      [routerLinkActiveOptions]="link.routerLinkActiveOptions"
      [target]="link.external ? '_blank' : null"
    >
      <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">
        <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>
      </div>
      <div class="title-wrapper">
        <span class="link-title">{{link.title}}</span>
        <mat-icon *ngIf="link.external" class="size-16" svgIcon="open-in-new"></mat-icon>
      </div>
    </a>

    <a
      *ngIf="headerNavHelper.asLinkHref(navItem) as link"
      mat-list-item
      [href]="link.url"
      [target]="link.external ? '_blank' : null"
    >
      <div class="title-wrapper">
        <span class="link-title">{{link.title}}</span>
        <mat-icon *ngIf="link.external" class="size-16" svgIcon="open-in-new"></mat-icon>
      </div>
    </a>

    <ng-container
      *ngIf="headerNavHelper.asMenu(navItem) as menu"
    >
      <button
        mat-list-item type="button"
        routerLinkActive="active"
        [routerLinkActiveOptions]="menu.routerLinkActiveOptions"
        [ngClass]="matIconMenu.state"
        (click)="matIconMenu.onToggle(); $event.stopPropagation()"
      >
        <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">
          <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>
        </div>
        <div class="title-wrapper">
          <span class="link-title">{{menu.title}}</span>
          <mat-icon svgIcon="chevron-down"></mat-icon>
        </div>

      </button>

      <rag-header-sidenav-menu #matIconMenu="ragHeaderSidenavMenu">
        <ng-container *ngFor="let childItem of menu.children">

          <a
            *ngIf="headerNavHelper.asLink(childItem) as childLink"
            mat-list-item
            [routerLink]="childLink.routerLink"
            [queryParams]="childLink.queryParams"
            routerLinkActive="active"
            [routerLinkActiveOptions]="childLink.routerLinkActiveOptions"
            [href]="childLink.url | safe:'url'"
            [target]="childLink.external ? '_blank' : null"
          >
            <div class="additional-routes" *ngIf="childLink.additionalRoutes?.length > 0">
              <div *ngFor="let route of childLink.additionalRoutes" [routerLink]="route"></div>
            </div>
            <span class="link-title">{{childLink.title}}</span>
            <mat-icon *ngIf="childLink.external" class="size-16" svgIcon="open-in-new"></mat-icon>
          </a>

          <a
            *ngIf="headerNavHelper.asLinkHref(childItem) as childLink"
            mat-list-item
            [href]="childLink.url | safe:'url'"
            [target]="childLink.external ? '_blank' : null"
          >
            <span class="link-title">{{childLink.title}}</span>
            <mat-icon *ngIf="childLink.external" class="size-16" svgIcon="open-in-new"></mat-icon>
          </a>

          <mat-divider *ngIf="childItem.type === 'divider'"></mat-divider>

          <ng-container
            *ngIf="headerNavHelper.asMenu(childItem) as childMenu"
          >
            <button
              mat-list-item type="button"
              routerLinkActive="active"
              [routerLinkActiveOptions]="childMenu.routerLinkActiveOptions"
              [ngClass]="matMenuChild.state"
              (click)="matMenuChild.onToggle(); $event.stopPropagation()"
            >
              <div class="additional-routes" *ngIf="childMenu.additionalRoutes?.length > 0">
                <div *ngFor="let route of childMenu.additionalRoutes" [routerLink]="route"></div>
              </div>
              <div class="title-wrapper">
                <span class="link-title">{{childMenu.title}}</span>
                <mat-icon svgIcon="chevron-down"></mat-icon>
              </div>
            </button>

            <rag-header-sidenav-menu #matMenuChild="ragHeaderSidenavMenu">
              <ng-container *ngFor="let childItem2 of childMenu.children">

                <a
                  *ngIf="headerNavHelper.asLink(childItem2) as childLink2"
                  mat-list-item
                  [routerLink]="childLink2.routerLink"
                  [queryParams]="childLink2.queryParams"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="childLink2.routerLinkActiveOptions"
                  [href]="childLink2.url | safe:'url'"
                  [target]="childLink2.external ? '_blank' : null"
                >
                  <div class="additional-routes" *ngIf="childLink2.additionalRoutes?.length > 0">
                    <div *ngFor="let route of childLink2.additionalRoutes" [routerLink]="route"></div>
                  </div>
                  <span class="link-title">{{childLink2.title}}</span>
                  <mat-icon *ngIf="childLink2.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                </a>

                <a
                  *ngIf="headerNavHelper.asLinkHref(childItem2) as childLink2"
                  mat-list-item
                  [href]="childLink2.url | safe:'url'"
                  [target]="childLink2.external ? '_blank' : null"
                >
                  <span class="link-title">{{childLink2.title}}</span>
                  <mat-icon *ngIf="childLink2.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                </a>

                <mat-divider *ngIf="childItem2.type === 'divider'"></mat-divider>

                <ng-container
                  *ngIf="headerNavHelper.asMenu(childItem2) as childMenu2"
                >

                  <button
                    type="button" mat-list-item
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="childMenu2.routerLinkActiveOptions"
                    [ngClass]="matMenuChild2.state"
                    (click)="matMenuChild2.onToggle(); $event.stopPropagation()"
                  >
                    <div class="additional-routes" *ngIf="childMenu2.additionalRoutes?.length > 0">
                      <div *ngFor="let route of childMenu2.additionalRoutes" [routerLink]="route"></div>
                    </div>
                    <div class="title-wrapper">
                      <span class="link-title">{{childMenu2.title}}</span>
                      <mat-icon svgIcon="chevron-down"></mat-icon>
                    </div>

                    <rag-header-sidenav-menu #matMenuChild2="ragHeaderSidenavMenu">
                      <ng-container *ngFor="let childItem3 of childMenu2.children">

                        <a
                          *ngIf="headerNavHelper.asLink(childItem3) as childLink3"
                          mat-list-item
                          [routerLink]="childLink3.routerLink"
                          [queryParams]="childLink3.queryParams"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="childLink3.routerLinkActiveOptions"
                          [href]="childLink3.url | safe:'url'"
                          [target]="childLink3.external ? '_blank' : null"
                        >
                          <div class="additional-routes" *ngIf="childLink3.additionalRoutes?.length > 0">
                            <div *ngFor="let route of childLink3.additionalRoutes" [routerLink]="route"></div>
                          </div>
                          <span class="link-title">{{childLink3.title}}</span>
                          <mat-icon *ngIf="childLink3.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                        </a>

                        <a
                          *ngIf="headerNavHelper.asLinkHref(childItem3) as childLink3"
                          mat-list-item
                          [href]="childLink3.url | safe:'url'"
                          [target]="childLink3.external ? '_blank' : null"
                        >
                          <span class="link-title">{{childLink3.title}}</span>
                          <mat-icon *ngIf="childLink3.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                        </a>

                        <mat-divider *ngIf="childItem3.type === 'divider'"></mat-divider>

                      </ng-container>
                    </rag-header-sidenav-menu>
                  </button>

                </ng-container>

              </ng-container>
            </rag-header-sidenav-menu>
          </ng-container>

        </ng-container>
      </rag-header-sidenav-menu>
    </ng-container>

  </ng-container>
  <ng-container *ngIf="isLogged$ | async">
    <div class="login-container">
      <span class="item-title login">{{headerNavHelper.getLoginForSideNav()}}</span>
    </div>
  </ng-container>
</mat-nav-list>
