import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Core, Distributable, ImageableContentReference } from 'src/app/core/core.types';
import { CourseTypeHelper } from '../../../core/course-type-helper';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { DisplayStatus } from '../../../core/display-status.enum';
import { map, Observable, switchMap, take, takeWhile } from 'rxjs';
import { Quests } from 'src/app/core/quest/quest.types';
import { InfoService } from 'src/app/core/info/info.service';
import { MacrosService } from 'src/app/core/macros.service';
import { QuestComponent } from '../../quest/quest/quest.component';
import { PrincipalService } from 'src/app/core/principal/principal.service';


@Component({
  selector: 'rag-important-content-info',
  templateUrl: './important-content-info.component.html',
  styleUrls: [ './important-content-info.component.scss' ],
})
export class ImportantContentInfoComponent
  implements OnInit, OnChanges {

  @Input() content: ImageableContentReference;
  @Input() displayMore = false;
  @Input() quest2content: Quests.Quest2ContentResponse;
  @Output() needsReload = new EventEmitter<boolean>();
  @Output() readonly readDocumentAcknowledge: Observable<Distributable>;

  availableSince: number;
  confirmedOn: number;
  contentHref: string;
  courseEnd: number;
  dueBy: number;
  hasCurriculumLock: boolean;
  isConfirmDisabled: boolean;
  lockReason: string;
  needsConfirm: boolean;
  hasSupervisor: boolean;
  needsReading: boolean;
  validSince: number;
  validUntil: number;

  private _readDocumentAcknowledge: EventEmitter<Distributable> = new EventEmitter();
  private _userId: number;
  protected lrnQuest: Quests.Quest;
  protected lrnFeedback: Quests.Feedback;
  protected giveFeedbackButtonDidabled$: Observable<boolean>;

  constructor(
    private infoService: InfoService,
    private macroService: MacrosService,
    private principalService: PrincipalService
  ) {
    this.readDocumentAcknowledge = this._readDocumentAcknowledge.asObservable();
  }

  private static getLockReason(
    content: ImageableContentReference
  ): string | null {

    if ( !CourseTypeHelper.isCourse(content) ) {
      // not a course -> do not check number of attempts
      return null;
    }

    const maxAttempts = content.repetitions;
    const attempts = content.attempts > 0 ? content.attempts : 0;
    if ( (content.locked === true) && (maxAttempts > 0) && (attempts >= maxAttempts) ) {
      // the allowed attempts have been reached
      return $localize`:@@important_content_info_course_max_attempts:No tries left`;
    }

    if ( !CourseTypeHelper.isTest(content) ) {
      // only show number of available attempts for test WBTs
      return null;
    }

    if ( maxAttempts > 0 ) {
      const labelAttempts = $localize`:@@important_content_info_course_n_of_attempts:Attempts`;
      return `${labelAttempts}: ${attempts} / ${maxAttempts}`;
    }

    return null;
  }

  ngOnInit(): void {
    if (this.quest2content !== undefined) {
      const setting = this.quest2content.settings?.find(
        s => s.objType === this.content.objType && s.objId === this.content.id);
      if (setting !== undefined) {
        this.lrnQuest = this.quest2content.quests?.find(q => q.uuid === setting.questUUID && q.type === 'lrn');
        this.lrnFeedback = this.quest2content.feedbacks?.find(
          f => f.questUUID === setting.questUUID && f.objType === setting.objType && f.objId === setting.objId);
      }
    }
    this._userId = this.principalService.currentUser.userId;
  }

  ngOnChanges(changes: any) {
    if ( Object.prototype.hasOwnProperty.call(changes, 'content') ) {
      this.updateValues();
    }
  }

  onReadDocumentAcknowledge(): void {
    this._readDocumentAcknowledge.emit(this.content);
  }

  onLrnFeedback($event: Quests.Quest): void {
    const objType = this.content.objType;
    const objId = this.content.id;
    this.macroService.resolveText(JSON.stringify($event.quest), objType, objId)
    .pipe(switchMap(resolvedText => {
      const questJSON = JSON.parse(resolvedText);
      const _lrnQuest = {...$event, quest: questJSON};
      return this.infoService.showDialog<QuestComponent, Quests.CtrlQuestResponse, Quests.Feedback>(QuestComponent, {
        ctrlQuest: null,
        quest: _lrnQuest,
        feedback: this.lrnFeedback,
        objType,
        objId
      }, Quests.ComponentConfig)
    }))
    .pipe(takeWhile(feedback => feedback !== undefined))
    .pipe(map(feedback => this.lrnFeedback = feedback))
    .pipe(take(1))
    .subscribe();
  }

  protected hasConfirmation(content: ImageableContentReference): boolean {
    return content?.hasConfirmation ?? false;
  }

  private updateValues() {

    this.isConfirmDisabled = false;
    this.hasCurriculumLock = false;
    this.lockReason = null;
    this.needsConfirm = false;
    this.needsReading = false;
    this.hasSupervisor = false;

    const finished = DisplayStatusHelper.isStatusGreen(this.content.displaystatus);
    if ( this.content.objType === Core.DistributableType.lms_course ) {
      this.updateValuesLMSCourse(finished);
    } else if ( this.content.objType === Core.DistributableType.lms_curriculum ) {
      this.updateValuesLMSCurriculum(finished);
    } else {
      if ( finished ) {
        this.validSince = this.content.executionDate;
      }
    }
  }

  private updateValuesLMSCourse(finished: boolean) {

    this.lockReason = ImportantContentInfoComponent.getLockReason(this.content);

    if ( CourseTypeHelper.isDocument(this.content) && this.hasConfirmation(this.content) ) {
      if ( finished ) {
        this.confirmedOn = this.content.executionDate;
      } else if ( (this.content.locked !== true) && !DisplayStatusHelper.isStatusGreen(this.content.displaystatus) ) {
        this.needsConfirm = true;
        this.hasSupervisor = this.content.hasSupervisor;
        // disable, until user visited the content at least once
        this.isConfirmDisabled = DisplayStatusHelper
          .toDisplayStatus(this.content.displaystatus) === DisplayStatus.NOT_ATTEMPTED;
      } else {
        this.needsReading = true;
      }
    } else {
      if ( finished ) {
        this.courseEnd = this.content.executionDate;
      }
    }
  }

  private updateValuesLMSCurriculum(finished: boolean) {
    if ( finished ) {
      if ( this.displayMore ) {
        this.validSince = this.content.lastValidSince;
      }
      this.validUntil = this.content.lastValidUntil;
      if ( !(this.validUntil || this.validSince) ) {
        this.validSince = this.content.executionDate;
      }
    } else {
      if ( this.displayMore ) {
        this.availableSince = this.content.startdate;
        this.hasCurriculumLock = this.content.locked;
        this.validUntil = this.content.lastValidUntil;
      }
      this.dueBy = this.content.dueBy;
    }
  }

}
