import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageableContentReference } from 'src/app/core/core.types';
import { ModalDialog } from '../../../core/modal-dialog';
import { ContentInfoButtonDialogComponent } from './content-info-button-dialog.component';

@Component({
  selector: 'rag-curriculum-info-button',
  templateUrl: './content-info-button.component.html',
  styleUrls: [ './content-info-button.component.scss' ],
})
export class ContentInfoButtonComponent
  implements OnInit {
  @Input() content: ImageableContentReference;
  dialogRef: MatDialogRef<ContentInfoButtonDialogComponent>;
  hasInfo: boolean;

  constructor(public dialog: ModalDialog) {
  }

  ngOnInit() {
    this.hasInfo = !!(this.content && this.content.description);
  }

  @HostListener('click', [ '$event' ])
  openDialog(): void {

    if ( this.dialogRef ) {
      return;
    }

    this.dialogRef = this.dialog.openModal(ContentInfoButtonDialogComponent, {
      panelClass: 'content-info-button-dialog-container',
      data: {
        content: this.content,
      },
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

}
