import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[ragFilesDropArea]',
})
export class FilesDropAreaDirective {

  @Input() accept: Array<string> = [];
  @Output() filesDropped: EventEmitter<any>;
  private className;

  constructor(private el: ElementRef) {
    this.filesDropped = new EventEmitter<any>();
    this.className = el.nativeElement.className;
  }

  @HostListener('dragleave', [ '$event' ])
  public onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.el.nativeElement.className = this.className;
  }

  @HostListener('dragover', [ '$event' ])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    const files = $event.dataTransfer.files;
    if ( files.length > 0 && this.accept.length > 0 ) {
      for ( let i = 0; i < files.length; i++ ) {
        const file = files.item(i);
        if ( this.accept.indexOf(file.type) === -1 ) {
          this.el.nativeElement.className = `${this.className} dragover error`;
          return;
        }
      }
    }
    this.el.nativeElement.className = `${this.className} dragover`;
  }

  @HostListener('drop', [ '$event' ])
  public ondrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.el.nativeElement.className = this.className;
    const files = $event.dataTransfer.files;
    if ( files.length > 0 ) {
      this.filesDropped.emit(files);
    }
  }

}
