import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class ReportGeneratorV2TypeGuard
  implements CanActivate {

  constructor(
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.principalService.permissionStates$
      .pipe(map(permissions => {
        if ( permissions.navCtrlReportCurriculum ) {
          return this.router.parseUrl('/report/v2/generator/Curriculum');
        }

        if ( permissions.navCtrlReportCourse ) {
          return this.router.parseUrl('/report/v2/generator/Course');
        }

        if ( permissions.navCtrlReportOffline ) {
          return this.router.parseUrl('/report/v2/generator/Offline');
        }

        return false;
      }))
      .pipe(take(1));
  }

}
