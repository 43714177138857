<rag-page-header [showSubTitle]="true">

  <div class="page-header-title" i18n="@@user_details_curriculum_header">Curriculum Details</div>

  <div class="page-header-sub-title details-list">
    <div class="details-item">
      <span class="label" i18n="@@global_user">User</span>
      <span>{{userFullName}}</span>
    </div>
    <div class="details-item">
      <span class="label" i18n="@@global_curriculum">Curriculum</span>
      <span>{{curriculumTitle}}</span>
    </div>
  </div>

  <div class="page-header-buttons">
    <rag-controlling-curriculum-multi-actions
      [userDetailsView]="userDetailsView"
    ></rag-controlling-curriculum-multi-actions>
  </div>
</rag-page-header>

<rag-ctrl-single-user-curriculum-item-details
  [userDetailsView]="userDetailsView"
></rag-ctrl-single-user-curriculum-item-details>
