import { Component, Input, OnInit } from '@angular/core';
import { Content } from 'src/app/core/content/content.types';
import { DOT_REGEX } from 'src/app/core/core.types';

@Component({
  selector: 'rag-item-recursion-indent',
  templateUrl: './item-recursion-indent.component.html',
  styleUrls: [ './item-recursion-indent.component.scss' ],
})
export class ItemRecursionIndentComponent
  implements OnInit {

  @Input() content: Content;
  indent = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.indent = (this.content.$view?.indexPrefix?.match(DOT_REGEX) || []).length;
  }

}
