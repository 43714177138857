<rag-page-header
  [backUrl]="backUrl"
  [showSubTitle]="!!typeOfTraining"
  [hasPrefix]="showStatus"
>

  <div class="page-header-prefix">
    <rag-status-lights
      *ngIf="showStatus"
      [displayStatus]="displayStatus"
      statusType="DisplayStatus"
      [targetType]="statusTargetType"
    ></rag-status-lights>
  </div>

  <div class="page-header-title">
    {{title}}
  </div>

  <div class="page-header-sub-title">
    <ng-container *ngIf="!!typeOfTraining">{{typeOfTraining}}</ng-container>
  </div>

  <div class="page-header-buttons">
    <ng-content></ng-content>
  </div>

</rag-page-header>
