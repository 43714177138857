import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoWarningDialogData } from './sco-warning.types';

@Component({
  selector: 'rag-sco-warning',
  templateUrl: './sco-warning.component.html',
  styleUrls: [ './sco-warning.component.scss' ],
})
export class ScoWarningComponent {

  content: string;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScoWarningDialogData,
  ) {
    this.content = data.content;
    this.title = data.title;
  }

}
