import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rag-not-found404',
  templateUrl: './not-found-404.component.html',
  styleUrls: [ './not-found-404.component.scss' ],
})
export class NotFound404Component
  implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
