import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormV2Component } from './login-form-v2.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AnonymousRegisterDialogModule } from '../../../route/main/anonymous-register/anonymous-register-dialog/anonymous-register-dialog.module';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FooterComponent} from '../../footer/footer.component';



@NgModule({
  declarations: [ LoginFormV2Component ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    RouterModule,
    MatInputModule,
    PipesModule,
    MatDialogModule,
    DialogHeaderComponent,
    LoadingIndicatorComponent,
    AnonymousRegisterDialogModule,
    MatTabsModule,
    MatTooltipModule,
    FooterComponent
  ],
  exports: [
    LoginFormV2Component,
  ],
})
export class LoginFormV2Module { }
