export const AdminNotificationRoutes = {
  // Admin Notifications - Message/UrlCall List
  AdminNotificationMessageUrlCallList: '/admin/notifications/{messageOrUrlCall}',
  // Admin Notifications - Message List
  AdminNotificationMessageList: '/admin/notifications/message',
  // Admin Notifications - UrlCall List
  AdminNotificationUrlCallList: '/admin/notifications/urlCall',
  // Admin Notifications - Message/UrlCall Details
  AdminNotificationDetails: '/admin/notifications/{messageOrUrlCall}/{targetType}/{targetId}/{actionId}',
  // Admin Notifications - Message/UrlCall Info
  AdminNotificationInfo: '/admin/notifications/{messageOrUrlCall}/{targetType}/{targetId}/{actionId}/info',
  // Admin Notifications - Default Template Details
  AdminNotificationDefaultTemplateDetails: '/admin/notifications/message/{contentType}/default/{actionId}',
  // Admin Notifications - Template List (Offline Content)
  AdminNotificationTemplateList: '/admin/offline/notifications',
};
