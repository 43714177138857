<ng-template #tplDate>
  <div
    [matTooltip]="getColumnValue() | formatDate: 'date-time'"
  >
    {{getDisplayValue()}}
  </div>
</ng-template>

<ng-template #tplDateTime>
  <div>
    {{getDisplayValue()}}
  </div>
</ng-template>

<ng-template #tplNumber>
  <div>
    {{getDisplayValue()}}
  </div>
</ng-template>

<ng-template #tplText>
  <div>
    {{getDisplayValue() | localeText}}
  </div>
</ng-template>

<ng-template #tplHtml>
  <div
    [innerHTML]="getDisplayValue() | localeText | safe:'html'"
  ></div>
</ng-template>

<ng-template #tplDisplayStatus>
  <rag-status-lights
    [displayStatus]="value"
    [targetType]="targetType"
    statusType="DisplayStatus"
    [displayHint]="displayHint"
    [courseType]="getCourseType()"
    [hasConfirmation]="hasConfirmation()"
  ></rag-status-lights>
</ng-template>

<ng-template #tplDropDown>
  <div [ngStyle]="getStyles()">
    <mat-icon class="vertical-align-middle margin-right" *ngIf="getDropDownIcon() as icon" [svgIcon]="icon"></mat-icon>
    <span class="vertical-align-middle">{{getDisplayValue()}}</span>
  </div>
</ng-template>

<ng-template #tplTags>
  <div class="tags">
    <div *ngFor="let tag of getTags()">
      {{trim(tag)}}
    </div>
  </div>
</ng-template>

<ng-template #tplImage>
  <img
    *ngIf="getPictureUrl() as src"
    mat-card-image
    [src]="src"
    alt=""
  />
</ng-template>

<ng-template #tplPriceFree>
  <div>
    <span i18n="@@global_price_free">free</span>
  </div>
</ng-template>

<ng-template #tplPrice>
  <div>{{value | currency:'EUR'}}</div>
</ng-template>

<ng-template #tplAccountPercentage>
  <div
    *ngIf="getCount(row) as count"
  >
    <span class="percentage">{{count.percentage}}%</span>
    <span class="count"> ({{count.done}} / {{count.total}})</span>
  </div>
</ng-template>


<ng-container
  [ngTemplateOutlet]="getTemplate(
    tplNumber,
    tplDate,
    tplDateTime,
    tplNumber,
    tplText,
    tplHtml,
    tplDisplayStatus,
    tplDropDown,
    tplPrice,
    tplPriceFree,
    tplAccountPercentage,
    tplTags,
    tplImage
  )"
></ng-container>
