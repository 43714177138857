<div class="container">

  <span *ngIf="hasCaption()">{{fragment.caption}}</span>

  <form [formGroup]="form">
    <div *ngFor="let item of fragment.items">
      <div class="text">
        <span>{{item.text}}</span>
      </div>
      <div class="options">
        <mat-form-field class="text-area" appearance="outline">
          <textarea
            matInput
            [rows]=10
            [readonly]="isReadonly(item.uuid)"
            [formControlName]="item.uuid"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>