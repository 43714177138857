import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OfflineContent } from 'src/app/core/admin-offline.types';

@Component({
  selector: 'rag-content-offline-info-date',
  templateUrl: './content-offline-info-date.component.html',
  styleUrls: ['./content-offline-info-date.component.scss']
})
export class ContentOfflineInfoDateComponent implements OnInit {

  @Input() offlineEvent: OfflineContent.EventSchedule;

  isSameDate: boolean;
  hasAgenda: boolean;
  eventDate: moment.Moment;
  eventUntilDate: moment.Moment;
  begin: moment.Moment;
  end: moment.Moment;

  constructor() { }

  ngOnInit(): void {
    this.eventDate = moment(this.offlineEvent.eventDate);
    this.eventUntilDate = moment(this.offlineEvent.eventDateUntil);
    this.isSameDate = this.eventDate.isSame(this.eventUntilDate, 'date');
    this.hasAgenda = this.offlineEvent.timeBlocks?.length > 0;
    if (this.hasAgenda) {
      this.begin = moment(
        this.offlineEvent.timeBlocks[0].startDate);
      this.end = moment(
        this.offlineEvent.timeBlocks[this.offlineEvent.timeBlocks.length - 1].endDate);
    }
  }

  dateFormatted(date: moment.Moment, format = 'll') {
    return date.format(format);
  }

  timeFormatted(date: moment.Moment) {
    return date.format('LT');
  }
}
