import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ValidatorFn } from '@angular/forms';


@Directive({
  selector: '[ragTimePicker][matInput]',
})
export class TimePickerDirective
 {

  /**
   * if set to true, the validation will update the input with the formatted time
   */
  @Input() ragTimePicker: string;
  previousCharacterCount = 0;

  constructor(
    private eltInput: ElementRef<HTMLInputElement>,
  ) {
  }

  @HostListener('input', [ '$event.target.value' ])
  onChange() {
    const value = this.eltInput.nativeElement.value;
    if (this.previousCharacterCount > value.length) {
      // delete input -> problem: deleting ':'
      this.previousCharacterCount = value.length;
      return;
    }

    if (value.length > 5) {
      this.eltInput.nativeElement.value = value.substring(0, 5);
      this.previousCharacterCount = this.eltInput.nativeElement.value.length;
      return;
    }

    const lastCharacter = value.charAt(value.length - 1) ?? '';
    if (value.length == 2 && lastCharacter === ':') {
      this.eltInput.nativeElement.value = ('0' + value);
      return;
    }

    const isNAN = isNaN(Number.parseInt(lastCharacter, 10));
    if (lastCharacter != '' && isNAN && value.length !== 3 && value.length !== 2) {
      this.eltInput.nativeElement.value = value.substring(0, value.length - 1);
      this.previousCharacterCount = this.eltInput.nativeElement.value.length;
      return;
    } else if (lastCharacter != '' && isNAN && lastCharacter != ':') {
      this.eltInput.nativeElement.value = value.substring(0, value.length - 1);
      this.previousCharacterCount = this.eltInput.nativeElement.value.length;
      return;
    }

    const formattedValue = value.replaceAll(':', '')
    if (!isNAN && !this.checkValidInputForIndex(formattedValue, formattedValue.length)) {
      this.eltInput.nativeElement.value = value.substring(0, value.length - 1);
      this.previousCharacterCount = this.eltInput.nativeElement.value.length;
      return;
    }

    this.checkAndAddColon(value, formattedValue);
  }

  @HostListener('blur')
  formatOnBlur() {
    const timeValue = this.eltInput?.nativeElement?.value
    if ( timeValue ) {
      this.eltInput.nativeElement.value = TimePickerDirective.formatTime(timeValue);
      this.previousCharacterCount = this.eltInput.nativeElement.value.length;
    }
  }

  private checkAndAddColon(value: string, formattedValue: string): void {
    const numberValue = Number.parseInt(formattedValue.substring(0, 2), 10);

    value = value.replaceAll(':', '');

    const insertIndex = (numberValue > 23) ? 1 : 2;
    let newValue = (insertIndex === 1) ? '0' : '';
    const until = (insertIndex === 1) ? value.length : (value.length + 1)
    for(let i = 0; i < until; i++) {
      if (i === insertIndex) {
        newValue += ':';
      }
      newValue += (value.charAt(i) ?? '');
    }
    value = newValue;
    this.eltInput.nativeElement.value = value;
    this.previousCharacterCount = this.eltInput.nativeElement.value.length;
  }

  private checkValidInputForIndex(value: string, index: number): boolean {
    const firstNumber = Number.parseInt(value.charAt(0), 10);
    const number = Number.parseInt(value.charAt(index - 1), 10);
    switch(index) {
      case 1:
        return true
      case 2:
        if (firstNumber <= 1) {
          return true;
        }
        return number < 6;
      case 3:
        return number < 6;
      case 4:
        return true;
    }
  }

  public static formatTimeOneCharacter(value: string): string {
    return `0${value}:00`;
  }

  public static formatTimeTwoCharacter(value: string): string {
    if (Number.parseInt(value, 10) < 24) {
      return `${value}:00`;
    }

    const firstNumber = Number.parseInt(value.charAt(0), 10);
    const secondNumber = Number.parseInt(value.charAt(1), 10);
    if (secondNumber <= 5) {
      return `0${firstNumber}:${secondNumber}0`;
    } else {
      return '';
    }
  }

  public static formatTimeThreeCharacter(value: string): string {
    const firstNumber = Number.parseInt(value.charAt(0), 10);
    const secondNumber = Number.parseInt(value.charAt(1), 10);
    // charAt(2) must be ':'
    const thirdNumber = Number.parseInt(value.charAt(3), 10);
    if (thirdNumber == 0) {
      return this.formatTimeTwoCharacter(value.substring(0, 2));
    }

    if (thirdNumber < 6) {
      return `${firstNumber}${secondNumber}:${thirdNumber}0`;
    }

    if (Number.parseInt(value.substring(0,2), 10) < 24) {
      return `${firstNumber}${secondNumber}:00`
    }
    return '';
  }

  public static formatTimeFourCharacter(value: string): string {
    const firstNumber = Number.parseInt(value.charAt(0), 10);
    const secondNumber = Number.parseInt(value.charAt(1), 10);
    const thirdNumber = Number.parseInt(value.charAt(3), 10);
    const fourthNumber = Number.parseInt(value.charAt(4), 10);

    if ( fourthNumber == 0) {
      return this.formatTimeThreeCharacter(value.substring(0, 4));
    }
    if (thirdNumber > 5) {
      return this.formatTimeTwoCharacter(value.substring(0, 2));
    }
    if (Number.parseInt(value.substring(0,2), 10) < 24) {
      return `${firstNumber}${secondNumber}:${thirdNumber}${fourthNumber}`;
    }
    return '';
  }

  public static formatTime(value: string): string {
    const count = value?.length;
    switch (count) {
      case 1:
        return this.formatTimeOneCharacter(value);
      case 2:
        return this.formatTimeTwoCharacter(value);
      case 3:
        return this.formatTimeTwoCharacter(value.substring(0,2));
      case 4:
        return this.formatTimeThreeCharacter(value);
      case 5:
        return this.formatTimeFourCharacter(value);
    }
    return '';
  }

  static min = (min: string): ValidatorFn => {
    return (c) => {
      const value = TimePickerDirective.formatTime(c.value);
      if (value == '' || value == null) {
        return null;
      }

      const valid = (Number.parseInt(min.replaceAll(':', ''), 10) -
        Number.parseInt(value.replaceAll(':', ''), 10)) <= 0;
      if (!valid) {
        return { minInvalid: true };
      }
      return null;
    };
  };

  static max = (max: string): ValidatorFn => {
    return (c) => {
      const value = TimePickerDirective.formatTime(c?.value);
      if (value == '' || value == null) {
        return null;
      }

      const valid = (Number.parseInt(max.replaceAll(':', ''), 10) -
      Number.parseInt(value.replaceAll(':', ''), 10)) >= 0;
      if (!valid) {
        return { maxInvalid: true };
      }
      return null;
    }
  }
}
