import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FaviconModule } from './favicon/favicon.module';
import { InfoModule } from './info/info.module';
import { PipesModule } from './pipes/pipes.module';
import { TableTemplateDirective } from './table-template.directive';
import { FilesDropAreaDirective } from './files-drop-area.directive';
import { CurrencyDirective } from './currency.directive';
import { DialogHeaderComponent } from '../component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from '../component/dialog-title/dialog-title.component';
import { WithoutSubscriptDirective } from './without-subscript.directive';

@NgModule({
  declarations: [
    TableTemplateDirective,
    FilesDropAreaDirective,
    CurrencyDirective,
    WithoutSubscriptDirective,
  ],
  imports: [
    CommonModule,
    InfoModule,
    FaviconModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
  exports: [
    FaviconModule,
    PipesModule,
    TableTemplateDirective,
    FilesDropAreaDirective,
    CurrencyDirective,
    WithoutSubscriptDirective
  ],
})
export class CoreModule {
}
