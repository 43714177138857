<rag-dialog-header>
  <span i18n="@@global_invitation_status">Invitation status</span>
</rag-dialog-header>

<div class="content" mat-dialog-content>

  <mat-hint i18n="@@admin_offline_event_participants_set_invitation_hint">
    Select the date at which the status has been set (e.g. if an invitation has been sent by a different way).
  </mat-hint>

  <mat-form-field
    *ngIf="invitationStatusLabel"
    appearance="outline"
  >
    <mat-label i18n="@@global_invitation_status">Invitation status</mat-label>

    <input
      [readonly]="true"
      [value]="invitationStatusLabel"
      matInput
    />
  </mat-form-field>

  <mat-form-field
    *ngFor="let participant of participants"
    appearance="outline"
  >
    <mat-label>{{participant.firstname}} {{participant.lastname}}</mat-label>
    <input
      [(ngModel)]="participant.date"
      [matDatepicker]="datePicker"
      matInput
    >

    <mat-datepicker-toggle
      [for]="datePicker"
      matSuffix
    ></mat-datepicker-toggle>

    <mat-datepicker
      #datePicker
    ></mat-datepicker>
  </mat-form-field>

</div>

<mat-dialog-actions>
  <button [matDialogClose]="null" i18n="@@sco_warning_cancel" mat-stroked-button>Cancel</button>
  <button (click)="onConfirm()" color="primary" mat-flat-button>
    <span i18n="@@admin_offline_event_participants_set_invitation_confirm">Set status</span>
  </button>
</mat-dialog-actions>
