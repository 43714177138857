import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { ApiUrls } from '../../../../core/api.urls';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ControllingSingleUserTypes } from '../../../../core/ctrl-single-user.types';


@Component({
  selector: 'rag-ctrl-single-user-curriculum-item-details',
  templateUrl: './ctrl-single-user-curriculum-item-details.component.html',
  styleUrls: ['./ctrl-single-user-curriculum-item-details.component.scss'],
})
export class CtrlSingleUserCurriculumItemDetailsComponent
  implements OnChanges, OnDestroy {

  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;

  protected userId: number;
  protected curriculumId: number;
  protected curriculumItemId: number;
  protected iteration: number;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.paramMap
      .pipe(tap(params => this.updateRouteParams(params)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.updateRouteParams(this.route.snapshot.paramMap);
    const curriculumAccount = this.userDetailsView?.curricula
      ?.find(o => o.curriculumId === this.curriculumId);
    this.iteration = curriculumAccount?.iteration ?? -1;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  protected getUrl(
    userId: number,
    curriculumId: number,
    curriculumItemId: number,
    iteration: number,
  ): string | null {
    if ((userId == null) || (curriculumId == null) || (curriculumItemId == null)) {
      return null;
    }

    return ApiUrls.getKey('CtrlSingleUserClassicItemCurriculum')
      .replace(/{userId}/gi, String(userId))
      .replace(/{targetId}/gi, String(curriculumItemId))
      .replace(/{curriculumId}/gi, String(curriculumId))
      .replace(/{iteration}/gi, String(iteration ?? -1));
  }

  private updateRouteParams(params: ParamMap): void {
    this.userId = parseInt(params.get('userId'), 10);
    this.curriculumId = parseInt(params.get('curriculumId'), 10);
    this.curriculumItemId = parseInt(params.get('itemId'), 10);
  }

}
