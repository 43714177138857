<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

  <rag-dialog-header>
    <span i18n="@@header_nav_ext_oidc">OpenID Connect</span>
  </rag-dialog-header>

  <mat-dialog-content>

    <mat-form-field>
      <mat-label i18n="@@ext_oauth2_column_issuerUri">Issuer URL</mat-label>
      <input
        type="text"
        [formControl]="formControlIssuerUri"
        matInput
      />

      <button
        type="button"
        matSuffix
        (click)="onFetchWellKnown()"
        [disabled]="formControlIssuerUri.pristine"
        mat-icon-button
      >
        <mat-icon svgIcon="refresh"></mat-icon>
      </button>
    </mat-form-field>

    <mat-hint i18n="@@ext_oauth2_issuerUri_fetch">
      You can enter the issuer url here, and then click on the re-load button on the right.<br />
      That will fetch the OpenID Connect configuration and insert any found values into the configuration.
    </mat-hint>

    <mat-form-field>
      <mat-label i18n="@@global_config">Configuration</mat-label>
      <textarea
        [formControl]="formControlJsonData"
        matInput
      ></textarea>
    </mat-form-field>

    <mat-error
      *ngIf="!!errorMessage"
      [innerHTML]="errorMessage | safe:'html'"
    ></mat-error>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-stroked-button type="button" [matDialogClose]="null">
      <span i18n="@@global_cancel">Cancel</span>
    </button>

    <button
      [disabled]="formGroup.pristine || !!errorMessage"
      autofocus
      color="primary"
      mat-flat-button
      type="submit"
    >
      <span i18n="@@global_save">Save</span>
    </button>
  </mat-dialog-actions>

</form>
