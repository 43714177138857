<rag-page-header>
  <div class="page-header-title" i18n="@@header_nav_ext_dsb_gateway_settings">DSB-Gateway Settings</div>
  <div class="page-header-buttons">

    <!-- Save button -->
    <button
    (click)="onSave()"
    [disabled]="isSaveButtonDisabled()"
    color="primary"
    mat-flat-button
    >
      <span i18n="@@global_save">Save</span>
    </button>

    <!-- Delete button -->
    <button
      (click)="onDelete()"
      [disabled]="isDeleteButtonDisabled()"
      color="warn"
      mat-flat-button
    >
      <span i18n="@@global_delete">Delete</span>
    </button>
  </div>
</rag-page-header>

<form [formGroup]="paymentFormGroup">
  <div class="flex-container">
    <div class="flex-left-side">
      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_client_id">Client ID</mat-label>
        <input
          [formControlName]="'clientId'"
          matInput
          type="text"
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_token">Token</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'token'"
          required
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_webhook_url">Webhook URL</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'webhookUrl'"
        >
      </mat-form-field>
    </div>
    <div class="flex-middle">
      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_webhook_secret">Webhook Secret</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'webhookSecret'"
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_auth_Login">Auth Login</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'authLogin'"
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_auth_Password">Auth Password</mat-label>
        <input
          matInput
          type="password"
          [formControlName]="'authPassword'"
        >
      </mat-form-field>
    </div>
    <div class="flex-right-side">
      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_base_url">Base URL</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'baseUrl'"
          required
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@ext_payment_module_template_id">Template ID</mat-label>
        <input
          matInput
          type="text"
          [formControlName]="'templateId'"
        >
      </mat-form-field>

    </div>
  </div>
</form>
