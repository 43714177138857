<rag-dialog-header>
  <span *ngIf="title; else defaultTitle" [innerHTML]="title | safe:'html'"></span>
  <ng-template #defaultTitle>
    <span i18n="@@sco_warning_title">Attention!</span>
  </ng-template>
</rag-dialog-header>

<ng-container *ngIf="content; else defaultContent">
  <div [innerHTML]="content | safe:'html'" mat-dialog-content></div>
</ng-container>
<ng-template #defaultContent>
  <div i18n="@@sco_warning_content" mat-dialog-content>
    <p>If you start this content it will be counted as a valid (re-)certification attempt!</p>
    <p>Do you wish to start it now?</p>
  </div>
</ng-template>

<mat-dialog-actions>
  <button
    [mat-dialog-close]="false"
    autofocus
    class="rag-stroked-button"
    i18n="@@sco_warning_cancel"
    mat-stroked-button
  >Cancel</button>
  <button
    [mat-dialog-close]="true"
    color="primary"
    i18n="@@sco_warning_confirm"
    mat-flat-button
  >
    Start
  </button>
</mat-dialog-actions>
