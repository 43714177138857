import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from './file-upload/file-upload.module';
import { TimePickerModule } from './time-picker/time-picker.module';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    TimePickerModule,
  ],
  exports: [
    FileUploadModule,
    TimePickerModule,
  ],
})
export class InputModule {
}
