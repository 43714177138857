import * as moment from 'moment';


export class DateHelper {

  static isMoment(
    value: any,
  ): boolean {
    return moment.isMoment(value);
  }

  static isValid(
    value: moment.Moment | null,
  ): boolean {
    try {
      return value?.isValid();
    } catch ( e ) {
      return false;
    }
  }

  static isValidMoment(
    value: any,
  ): boolean {
    return DateHelper.isMoment(value) && DateHelper.isValid(value);
  }

  static toDateWithFormat(value: string | null, format: string): Date | null {
    if ( value == null ) {
      return null;
    }
    const momentDate = moment(value, format);
    if ( momentDate.isValid() ) {
      return momentDate.toDate();
    }
    return null;
  }

  static format(
    value: moment.Moment | Date | number | string | null,
    format: string
  ): string | null {

    const momentDate = DateHelper.toMoment(value);
    if (momentDate == null) {
      return null;
    }

    return momentDate.format(format);
  }

  static toMoment(
    value: moment.Moment | Date | number | string | null,
  ): moment.Moment | null {

    if ( typeof (value) === 'string' ) {

      if ( /Z$/.test(value) ) {
        value = moment(value);

      } else if ( /^\d+$/.test(value) ) {
        value = moment(parseInt(value, 10));

      } else {
        value = moment(value, 'L', false);
      }

    } else if ( typeof (value) === 'number' ) {
      value = moment(value);

    } else if ( value instanceof Date ) {
      value = moment(value);
    }

    if ( DateHelper.isValid(value) ) {
      return value;
    }
    return null;
  }

  static toUnixFromMoment(
    value: any,
  ): number | null {

    const momentValue = value;
    if ( !(DateHelper.isValidMoment(value)) ) {
      return null;
    }

    return momentValue.unix() * 1000;
  }

  static compareDay(value1: number | moment.Moment, value2: number | moment.Moment): number {
    if ( !moment.isMoment(value1) ) {
      value1 = moment(value1);
    }
    if ( !moment.isMoment(value2) ) {
      value2 = moment(value2);
    }
    return value1.diff(value2, 'd');
  }

  static equalsDay(value1: number | moment.Moment, value2: number | moment.Moment): boolean {
    // eslint-disable-next-line eqeqeq
    if ( value1 === value2 ) {
      // used tslint disable to allow comparing undefined == null
      return true;
    } else if ( value1 == null || value2 == null ) {
      // either one is undefined -> not equal
      return false;
    }

    const result = DateHelper.compareDay(value1, value2);
    return result === 0;
  }

}
