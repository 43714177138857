import { SelfRegAdminResponse } from './admin-self-registration.types';
import {
  AdminUserFieldsByGroupsData,
  UserAttribute,
  UserAttributeContextResponse,
} from '../../../core/admin-user-attribute.types';
import { AnyObject } from '../../../core/core.types';
import { LanguageHelper } from '../../../core/language.helper';
import { AssignmentDialogTypes } from '../../../component/assignment-dialog/assignment-dialog.types';


export class AdminSelfRegistrationHelper {

  public static asDisplayData(
    data: UserAttributeContextResponse | null, onlyForSelfRegistration = true,
  ): AdminUserFieldsByGroupsData {

    const attributeMap = Object.entries(data?.userAttributes ?? {})
      .reduce((pV, [ key, attribute ]) => {

        if ( onlyForSelfRegistration && !attribute.readLearner ) {
          // not readable? -> not available for self registration -> skip
          return pV;
        }

        if ( !(attribute.attributeGroupId > 0) ) {
          // no grouping? -> no way to display -> skip
          return pV;
        }

        pV[key] = attribute;
        return pV;
      }, {});

    const groups = (data?.userAttributeGroups)
      // check if the group contains any available field -> skip if no field might be checked
      .filter(group => group.userAttributes.find(userField => attributeMap[userField] != null) != null)
      .sort((a, b) => a.orderIndex - b.orderIndex);

    return { attributeMap, groups };
  }

  public static asSelectionData(
    attributeMap: AnyObject<UserAttribute> | null,
    selectedAttributes: UserAttribute[] | null,
  ): AssignmentDialogTypes.AssignmentDialogEntries<UserAttribute> {
    attributeMap ??= {};
    selectedAttributes ??= [];

    const selected = (selectedAttributes ?? []);
    const missingRequired = Object.values(attributeMap)
      // using "includes" on UserAttribute doesn't seem to work well;
      // neither do selected attributes get removed from available attributes,
      // nor selected attributes get put only once, instead, they duplicate.
      // check by name is used instead
      .filter(a => a.required && !selectedAttributes.some((attribute) => attribute.attributeName == a.attributeName));
    selected.push(...missingRequired);
    return {
      available: Object.values(attributeMap)
        //check by name as explained above
        .filter(a => !selected.some((attribute) => attribute.attributeName == a.attributeName))
        .map(a => {
          const entry: AssignmentDialogTypes.AssignmentEntry<UserAttribute> = {
            selected: false,
            value: a,
            changed: false,
            title: LanguageHelper.objectToText(a.label),
            type: null,
          };
          return entry;
        }),
      selected: selected
        .map(a => {
          const entry: AssignmentDialogTypes.AssignmentEntry<UserAttribute> = {
            selected: true,
            value: a,
            //check by name as explained above
            changed: missingRequired.some((attribute) => attribute.attributeName == a.attributeName),
            title: LanguageHelper.objectToText(a.label),
            type: null,
            disabled: a.required,
          };
          return entry;
        }),
    };
  }

  public static getSelectedAttributes(
    data: SelfRegAdminResponse | null,
  ): UserAttribute[] {

    const attributeMap = (data?.userAttributes ?? {});
    const requiredAttributes = Object.values(attributeMap)
      .filter(a => a.required)
      .sort((a, b) => a.orderIndex - b.orderIndex);

    const selectedAttributes = [ ...(data?.context?.userAttributes ?? []) ];
    if ( !(selectedAttributes.length > 0) ) {
      // currently no attributes selected -> all required attributes must be added
      return requiredAttributes;
    }

    const additionalAttributes = requiredAttributes
      .map(a => a.attributeName)
      .filter(a => !selectedAttributes.includes(a));
    const hasRemovedAttributes = selectedAttributes
      .find(a => attributeMap[a] == null) != null;
    let result: string[] = selectedAttributes;
    if ( hasRemovedAttributes ) {
      result = selectedAttributes.filter(a => attributeMap[a] != null);
    }

    if ( additionalAttributes.length > 0 ) {
      result.push(...additionalAttributes);
    }
    return result
      .map(a => attributeMap[a])
      .filter(o => o != null);
  }

}
