import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { Content } from 'src/app/core/content/content.types';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@Component({
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatButtonModule,
    ClampComponent,
    MatDialogModule,
    PipesModule
  ],
  selector: 'rag-my-content-card-curriculum-info-dialog',
  templateUrl: './my-content-card-curriculum-info-dialog.component.html',
  styleUrls: ['./my-content-card-curriculum-info-dialog.component.scss']
})
export class MyContentCardCurriculumInfoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public content: Content
  ) { }

  ngOnInit(): void {
  }

}
