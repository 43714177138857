import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  BadgeContentConfig,
  BadgeContentConfigDialogData,
  BadgeContentConfigSaveData,
  BadgeInfo,
  BadgeTemplate,
  ReducedBadge,
} from '../../../../core/gamification/gamification.types';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SelectLanguageModule } from '../../../../component/select-language/select-language.module';
import { LanguageHelper, LanguageInfo } from '../../../../core/language.helper';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, map, take } from 'rxjs/operators';
import { Translation } from '../../../../core/translation/translation.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { AdminGamificationService } from '../../../../core/gamification/admin-gamification.service';
import { InfoService } from '../../../../core/info/info.service';
import { ConfirmButton, DeleteButton, InfoType, MessageConstants } from '../../../../core/info/info.types';
import { EMPTY, Observable } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import moment from 'moment';
import { BadgeCardComponent } from '../../../../component/cards/badge-card/badge-card.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';

@Component({
  standalone: true,
  selector: 'rag-admin-badge-template-select-dialog',
  templateUrl: './admin-badge-template-select-dialog.component.html',
  imports: [
    DialogHeaderComponent,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    NgForOf,
    MatButtonModule,
    SelectLanguageModule,
    ReactiveFormsModule,
    AsyncPipe,
    BadgeCardComponent,
    NgIf,
  ],
  styleUrls: [ './admin-badge-template-select-dialog.component.scss' ],
})
export class AdminBadgeTemplateSelectDialogComponent implements OnInit, OnDestroy {
  language = LanguageHelper.getCurrentLanguage();
  languages = LanguageHelper.LANGUAGES;
  form: UntypedFormGroup;
  title: Translation = LanguageHelper.getEmptyTranslation();
  description: Translation = LanguageHelper.getEmptyTranslation();
  currentContentConfig: BadgeContentConfig;
  badgeTemplates: BadgeTemplate[];
  selectedPictureId: number;
  selectedPictureUUID: string;
  selectedBadge$: Observable<ReducedBadge>;
  _selectedBadge$ = new CachedSubject<ReducedBadge>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BadgeContentConfigDialogData,
    private formBuilder: FormBuilder,
    private adminGamificationService: AdminGamificationService,
    private infoService: InfoService,
    private dialogRef: MatDialogRef<AdminBadgeTemplateSelectDialogComponent>
  ) {
    this.selectedBadge$ = this._selectedBadge$.asObservable();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.currentContentConfig = this.data?.currentContentConfig;
    this.badgeTemplates = this.data?.badgeTemplates;
    this.title = this.currentContentConfig?.title;
    this.description = this.currentContentConfig?.description;
    this.initFormGroup();
  }

  onDeleteBadgeTemplate(): void {
    if (!(this.currentContentConfig?.badgeConfigUUID)) {
      return;
    }

    this.adminGamificationService.deleteContentConfig(this.currentContentConfig.badgeConfigUUID)
      .pipe(map(_ => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`, {
          infoType: InfoType.Success
        });
        this.dialogRef.close({button: DeleteButton});
      }))
      .pipe(catchError(_ => {
        this.infoService.showMessage(MessageConstants.API.ERROR, {
          infoType: InfoType.Error,
        })
        this.dialogRef.close({button: DeleteButton});
        return EMPTY;
      }))
      .pipe(take(1))
      .subscribe();
  }

  onSaveBadgeTemplate(): void {
    const saveData: BadgeContentConfigSaveData = {
      title: this.title,
      description: this.description,
      templateUUID: this.form.get('template').value,
      objectId: this.data.objectId,
      objectType: this.data.objectType,
    };

    this.adminGamificationService.saveContentConfig(saveData)
      .pipe(map(_ => {
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
        this.dialogRef.close({button: ConfirmButton});
      }))
      .pipe(catchError(_ => {
        this.infoService.showMessage(MessageConstants.API.ERROR, {
          infoType: InfoType.Error,
        });
        this.dialogRef.close({button: ConfirmButton});
        return EMPTY;
      }))
      .pipe(take(1))
      .subscribe();
  }

  onLanguageChange($event: LanguageInfo) {
    this.language = $event;

    this.form.controls['title']
      .setValue(LanguageHelper.objectToText(this.title, this.language.key));

    this.form.controls['description']
      .setValue(LanguageHelper.objectToText(this.description, this.language.key));
  }

  private initFormGroup(): void {
    this.form = this.formBuilder.group({
      title: [LanguageHelper
        .objectToText(this.currentContentConfig?.title, this.language.key), [Validators.required]],
      description: [LanguageHelper
        .objectToText(this.currentContentConfig?.description, this.language.key), [Validators.required]],
      template: [this.currentContentConfig?.templateUUID, [Validators.required]],
    });

    const badgeTemplate = this.currentContentConfig?.template;
    if (badgeTemplate != null) {
      this.selectedPictureId = badgeTemplate.pictureId;
      this.selectedPictureUUID = badgeTemplate.pictureUUID;
      this.emitNewSelectedBadge();
    }

    if (this.badgeTemplates.length === 0) {
      this.form.get('template').disable();
    }

    this.form.get('title').valueChanges.pipe(map(value => {
      this.title = LanguageHelper.setTranslation(this.title, value, this.language.key);
      this.emitNewSelectedBadge();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.get('description').valueChanges.pipe(map(value => {
      this.description = LanguageHelper.setTranslation(this.description, value, this.language.key);
      this.emitNewSelectedBadge();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.get('template').valueChanges.pipe(map(templateUUID => {
      const templateForUUID = this.badgeTemplates
        .find(template => template.templateUUID === templateUUID);
      this.selectedPictureId = templateForUUID.pictureId;
      this.selectedPictureUUID = templateForUUID.pictureUUID;
      this.emitNewSelectedBadge();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private emitNewSelectedBadge() {
    const info: BadgeInfo = {
      title: null,
      description: null,
      contentTitle: null,
    };
    info.title = this.title;
    info.description = this.description;

    const selectedBadge: ReducedBadge = {
      badgeUUID: null,
      info,
      objectId: this.data.objectId,
      objectType: this.data.objectType,
      pictureId: this.selectedPictureId,
      pictureUUID: this.selectedPictureUUID,
      whenAcquired: moment().unix() * 1000,
    };
    this._selectedBadge$.next(selectedBadge);
  }
}
