<rag-dialog-header>
  <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
</rag-dialog-header>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <rag-table-column-menu
      #tableColumnMenu
      (evtColumnsChanged)="setColumns($event)"
      [menuData$]="columnMenuData$"
    ></rag-table-column-menu>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
    >

      <ng-container matColumnDef="parentTitle">
        <td *matCellDef="let row" mat-cell [colSpan]="columns.length">
          {{asParent(row)?.title}}
        </td>
      </ng-container>

      <ng-container matColumnDef="orderIndex">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <ng-container i18n="@@global_nr">No.</ng-container>
        </th>
        <td *matCellDef="let row" mat-cell>{{getOrderIndex(row)}}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <ng-container i18n="@@global_title">Title</ng-container>
        </th>
        <td *matCellDef="let row" mat-cell>
          <span>{{asChild(row)?.title}}</span>
          <button
            #matTooltip="matTooltip"
            *ngIf="isRowDisabled(row)"
            mat-icon-button
            type="button"
            matTooltip="This item cannot be changed because it is a duplicate of another one."
            i18n-matTooltip="@@ctrl_single_user_details_curriculum_edit_row_disabled"
            (click)="matTooltip.toggle()"
          >
            <mat-icon svgIcon="alert-outline"></mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="locked">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <ng-container i18n="@@global_locked">Locked</ng-container>
        </th>
        <td *matCellDef="let row" mat-cell [ngClass]="{'rag-dirty': isDirty(row, 'locked')}">
          <mat-form-field
            *ngIf="asChild(row) as childRow"
            appearance="outline"
            class="no-padding-bottom"
          >
            <mat-label i18n="@@global_locked">Locked</mat-label>
            <mat-select
              [formControlName]="getIdentifier(childRow)+'.locked'"
              (ngModelChange)="onModelChange(childRow, 'locked', $event)"
              [disabled]="!childRow.$data.rbacActions.maySave"
            >
              <mat-option [value]="true" i18n="@@global_locked_filter_true">Locked</mat-option>
              <mat-option [value]="false" i18n="@@global_locked_filter_false">Unlocked</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <ng-container i18n="@@global_status">Status</ng-container>
        </th>
        <td *matCellDef="let row" mat-cell [ngClass]="{'rag-dirty': isDirty(row, 'displayStatus')}">
          <ng-container
            *ngIf="asChild(row) as childRow"
          >
            <rag-ctrl-edit-display-status
              [contentType]="childRow.$data?.targetType"
              [confirmActive]="childRow.$data?.course?.confirmActive"
              [courseType]="childRow.$data?.course?.courseType"
              [formControlName]="getIdentifier(childRow)+'.displayStatus'"
              (ngModelChange)="onModelChange(childRow, 'displayStatus', $event)"
              [disabled]="!childRow.$data.rbacActions.maySave"
            ></rag-ctrl-edit-display-status>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="finishDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          <ng-container i18n="@@ctrl_single_user_details_finishDate">Finish date</ng-container>
        </th>
        <td *matCellDef="let row" mat-cell [ngClass]="{'rag-dirty': isDirty(row, 'finishDate')}">
          <mat-form-field
            *ngIf="asChild(row) as childRow"
            appearance="outline"
            class="no-padding-bottom"
          >
            <mat-label i18n="@@ctrl_single_user_details_finishDate">Finish date</mat-label>
            <input
              matInput
              [matDatepicker]="eltFinishDatePicker"
              (dateInput)="onModelChange(childRow, 'finishDate', $event.value)"
              [formControlName]="getIdentifier(childRow)+'.finishDate'"
              [required]="childRow.displayStatus > 0"
              [disabled]="!childRow.$data.rbacActions.maySave"
              [max]="maxDate"
            />
            <mat-datepicker-toggle matSuffix [for]="eltFinishDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #eltFinishDatePicker></mat-datepicker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>
          <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
        </th>
        <td *matCellDef="let row" mat-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
      <tr *matRowDef="let row; columns: ['parentTitle']; when: isParentRow" mat-row class="parent"></tr>
      <tr *matRowDef="let row; columns: columns; when: isChildRow" mat-row class="child"></tr>
    </table>
  </div>

  <div mat-dialog-actions>
    <button
      class="rag-stroked-button"
      mat-stroked-button
      type="button"
      (click)="onCancel()"
    >
      <span i18n="@@global_cancel">Cancel</span>
    </button>

    <button
      [disabled]="formGroup.pristine || formGroup.invalid"
      autofocus
      color="primary"
      mat-flat-button
    >
      <span i18n="@@global_save">Save</span>
    </button>
  </div>
</form>
