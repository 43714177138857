<mat-form-field
  appearance="outline"
  class="no-padding-bottom"
>
  <mat-label i18n="@@global_status">Status</mat-label>
  <mat-select
    [disabled]="disabled"
    [value]="displayStatus"
    (valueChange)="onValueChange($event)"
  >
    <mat-option
      *ngFor="let option of statusOptions"
      [value]="option.value"
    >
      <rag-status-lights
        [displayStatus]="option.value"
        statusType="DisplayStatus"
        [hideToolTip]="true"
      ></rag-status-lights>
      <span>{{option.title}}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
