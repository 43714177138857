// https://indepth.dev/posts/1285/in-depth-guide-into-animations-in-angular#reusing-your-animation

import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const ListItemsFadeIn = trigger('listAnimation', [
  transition(':enter' , [
    query('.fadeIn', [
      style({ opacity: 0, transform: 'translateX(100%)' }),
      stagger(30, [
        animate('400ms ease-out', style({ opacity: 1, transform: 'none' })),
      ]),
    ], { optional: true }),
  ]),
]);

// use this for dynamic content (e.g. content loaded with ngFor)
export const CardGrowOnEnterDynamic = trigger('cardAnimation', [
  transition(':enter', [
    query(':enter', [
      style({ opacity: 0, transform: 'scale(0.7)' }),
      stagger(40, [
        group([
          animate(500, style({ opacity: 1 })),
          animate('400ms ease-out', style({ transform: 'scale(1)' })),
        ]),
      ]),
    ], { optional: true }),
  ]),
]);

// use this for static content (HTML elements that are not loaded dynamically)
export const CardGrowOnEnterStatic = trigger('cardAnimation', [
  transition(':enter', [
    query('.fadeIn', [
      style({ opacity: 0, transform: 'scale(0.7)' }),
      stagger(30, [
        group([
          animate(500, style({ opacity: 1 })),
          animate('400ms ease-out', style({ transform: 'scale(1)' })),
        ]),
      ]),
    ], { optional: true }),
  ]),
]);
