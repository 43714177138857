import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take, takeWhile } from 'rxjs/operators';
import { RagPageService } from 'src/app/rag-layout/rag-page/rag-page.service';
import { WidgetsUUID } from 'src/app/rag-layout/widgets/widgets.types';
import { ExportService } from '../../../../core/export.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ReportService } from '../../../../core/report/report.service';
import { Report, ReportConfig, ReportTargetType } from '../../../../core/report/report.types';

@Component({
  selector: 'rag-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: [ './report-list.component.scss' ],
})
export class ReportListComponent
  implements AfterViewInit, OnDestroy {

    @ViewChild(MatSort) sort: MatSort;

    canSaveMore: Observable<boolean>;
  canUseDashboard: Observable<boolean>;
  dataSource = new MatTableDataSource<ReportConfig>();
  displayedColumns: Array<string> = [ 'favorite', 'title', 'reportType', 'createdBy', 'createDate', 'modifiedDate', 'actions' ];
  reports: Report[];


  constructor(
    private exportService: ExportService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private ragPageService: RagPageService,
    private reportService: ReportService,
    private router: Router,
  ) {
    this.canSaveMore = this.reportService.canSaveMore();
    this.canUseDashboard = this.principalService.permissionStates$
      .pipe(map(ps => ps.navCtrlReportDashboard));

    this.reportService.myReports$
      .pipe(map(this.ngOnInitReports))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.dataSource.sortingDataAccessor = this.sortDataAccessor;
  }

  get reportBarChartWidgetUUID(): string {
    return WidgetsUUID.ReportBarChartWidgetUUID;
  }

  get reportPieChartWidgetUUID(): string {
    return WidgetsUUID.ReportPieChartWidgetUUID;
  }

  private static hasPermission(permission: string, reportConfig: ReportConfig): boolean {
    if ( reportConfig.ownedByMe ) {
      return true;
    }
    return reportConfig && reportConfig.permissions && reportConfig.permissions.indexOf(permission) >= 0;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  hasType(reportConfig: ReportConfig | null, type: ReportTargetType): boolean {
    return reportConfig?.targetType === type;
  }

  addChartWidget(widgetUUID: string, reportConfig: ReportConfig) {
    this.ragPageService.appendWidget('layout-ctrl-dashboard', widgetUUID, (widget, layout) => {
      const existingWidget = Object.values(layout)
        .find(_widget => _widget.widgetUUID === widgetUUID && _widget.settings.reportUUID === reportConfig.uuid);
      if ( existingWidget == null ) {
        widget.settings = {
          reportUUID: reportConfig.uuid,
        };
        return true;
      }
      return false;
    }).subscribe(success => {
      if ( success ) {
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ADD_WIDGET_SUCCESS, InfoType.Success);
      } else {
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ADD_WIDGET_FAILED_EXISTS_ALREADY, InfoType.Warning);
      }
    });
  }

  addReportLinksWidget(reportConfig: ReportConfig) {
    this.ragPageService.addReportLinksWidget('layout-ctrl-dashboard', reportConfig).subscribe(success => {
      if ( success ) {
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ADD_WIDGET_SUCCESS, InfoType.Success);
      } else {
        this.infoService.showSnackbar(MessageKey.RAGPAGE_ADD_WIDGET_FAILED_EXISTS_ALREADY, InfoType.Warning);
      }
    });
  }

  canDelete(reportConfig: ReportConfig): boolean {
    return ReportListComponent.hasPermission('repd', reportConfig);
  }

  canEdit(reportConfig: ReportConfig): boolean {
    return ReportListComponent.hasPermission('repe', reportConfig);
  }

  chartsAvailable(reportConfig) {
    return reportConfig.targetType === 'Curriculum';
  }

  deleteReport(reportConfig: ReportConfig): void {
    if ( reportConfig && reportConfig.uuid ) {
      this.reportService.deleteReport(reportConfig.uuid);
    }
  }

  downloadAsCsv(reportConfig: ReportConfig) {
    this.exportService.csvExport(reportConfig);
  }

  downloadAsZip(reportConfig: ReportConfig) {
    this.exportService.zipExport(reportConfig);
  }

  downloadAsXlsx(reportConfig: ReportConfig) {
    this.exportService.xlsxExport(reportConfig);
  }

  getReportUrl(reportConfig: ReportConfig) {
    const targetType = reportConfig && reportConfig.targetType;
    if ( targetType === 'ClassicReport' ) {
      return '/report/classic/' + reportConfig['reportTemplateId'];
    }
    return '/report/v2/saved/' + reportConfig['uuid'];
  }

  onXLSXExportLearningTime(reportConfig: ReportConfig): void {
    this.exportService.xlsxExportLearningTime(reportConfig);
  }

  ngOnInitReports = (data: Report[]): void => {
    this.reports = data || [];
    this.dataSource.data = this.reports.map(report => report.reportConfig);
  };

  reportTypeForConfig(reportConfig: ReportConfig): string {
    switch ( reportConfig.targetType ) {
      case 'ClassicReport':
        return $localize`:@@report_type_classic:Classic report`;
      case 'Course':
        return $localize`:@@report_type_course:Course`;
      case 'Curriculum':
        return $localize`:@@report_type_curriculum:Curriculum`;
      case 'Offline':
        return $localize`:@@report_type_offline:Event`;
      default:
        return $localize`:@@global_unknown:Unknown`;
    }
  }

  renameReport(reportConfig: ReportConfig): void {
    if ( !reportConfig?.uuid ) {
      return;
    }

    this.reportService.renameReport(reportConfig)
      .pipe(takeWhile(openAfterSave => openAfterSave === true))
      .pipe(switchMap(() => this.openSavedReport(reportConfig)))
      .pipe(take(1))
      .subscribe();
  }

  saveAsReport(reportConfig: ReportConfig): void {
    if ( reportConfig == null || !reportConfig.uuid ) {
      return;
    }

    const report = this.reports.find(r => r.reportConfig.uuid === reportConfig.uuid);
    if ( report == null ) {
      return;
    }

    this.reportService.saveAsReport(reportConfig)
      .pipe(takeWhile(result => result?.report?.reportConfig != null))
      .pipe(switchMap(result => this.openSavedReport(result.report.reportConfig)))
      .subscribe();
  }

  toggleFavorite(reportConfig: ReportConfig): void {
    if ( reportConfig && reportConfig.uuid ) {
      this.reportService.toggleFavorite(reportConfig);
    }
  }

  private openSavedReport(reportConfig: ReportConfig): Promise<boolean> {
    this.infoService.showMessage($localize`:@@report_has_been_saved:The report has been saved successfully`, {
      infoType: InfoType.Success,
    });
    const url = this.getReportUrl(reportConfig);
    return this.router.navigateByUrl(url);
  }

  private sortDataAccessor = (reportConfig: ReportConfig, sortHeaderId: string): string | number => {
    switch ( sortHeaderId ) {
      case 'reportType':
        return this.reportTypeForConfig(reportConfig);
      case 'createdBy':
        return reportConfig['userName'];

      default:
        return reportConfig[sortHeaderId];
    }
  };

}
