<rag-page-header
  backUrl="/admin/notifications/signatures"
  >
  <div class="page-header-title">
    <span *ngIf="signature.id === 0" i18n="@@global_new_signature">New signature</span>
    <div *ngIf="signature.id > 0">
      <span>{{signature.name}}</span>
    </div>
  </div>
  <div class="page-header-buttons">
    <rag-form-submit-button
      [form]="formGroup"
      (doSubmit)="onSave()"
      [disabled]="formGroup.invalid || formGroup.pristine"
      [label]="saveOrCreateText"
    ></rag-form-submit-button>
  </div>
</rag-page-header>

<rag-text-macros
  [formGroup]="formGroup"
  [usage]="usages"
  [data]="signature"
  [isTextMacro]="false"
  [currentMacro]="signature.macro"
></rag-text-macros>
