import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeNotesComponent } from './change-notes.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MarkdownModule } from 'ngx-markdown';
import { ChangeNotesTocModule } from './change-notes-toc/change-notes-toc.module';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [ ChangeNotesComponent ],
  imports: [
    CommonModule,
    MarkdownModule.forChild(),
    MatExpansionModule,
    PageHeaderComponent,
    ChangeNotesTocModule,
  ],
})
export class ChangeNotesModule {
}
