<ng-template #tplDefault>
  <rag-tables-column-render-default
    [column]="column"
    [row]="defaultMockRow"
  ></rag-tables-column-render-default>
</ng-template>

<ng-template #tplDisplayStatus>
  <rag-status-lights
    [statusType]="targetType"
    [displayStatus]="row.distinctValue"
    [hideToolTip]="false"
    [displayHint]="true"
  ></rag-status-lights>
</ng-template>

<ng-template #tplDate>
  <rag-colored-date
    [date]="row.distinctValue"
    [warnInPast]="mode === 'dateUntil'"
  ></rag-colored-date>
</ng-template>

<div class="flex-parent">
  <div class="sticky-left">
    <rag-spacer
      [widthInPx]="(row.$rowSort.length - 1) * 40"
    ></rag-spacer>

    <button
      mat-icon-button
      type="button"
      (click)="toggleGrouping()"
      [disabled]="!hasVisibleChildren()"
    >
      <mat-icon [svgIcon]="row.$expanded ? 'chevron-down' : 'chevron-right'"></mat-icon>
    </button>

    <small class="grouping-type">{{column.title | localeText}}:</small>
    <div class="grouping-value">
      <ng-container *ngTemplateOutlet="getTemplate(
        tplDefault,
        tplDisplayStatus,
        tplDate
      )"></ng-container>
    </div>
  </div>

  <div class="flex-grow"></div>

  <rag-status-statistics
    *ngIf="row?.statistics != null && !isOffline"
    [contentType]="targetType"
    [showTotal]="true"
    [statistics]="row.statistics"
    class="sticky-right"
  ></rag-status-statistics>
</div>
