import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportConfig, ReportLearningTime } from '../../../../../../core/report/report.types';
import { DoughnutChartDataSet } from '../../../../../../component/chart/doughnut-chart/doughnut-chart.types';
import { Observable, of } from 'rxjs';
import { CachedSubject } from '../../../../../../core/cached-subject';
import { Statistics } from '../../../report-generator/status-statistics/status-statistics.types';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ReportService } from '../../../../../../core/report/report.service';
import { ChartService } from '../../../../../../component/chart/chart.service';
import { ReportTableService } from '../report-table.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../core/reactive/until-destroyed';
import { ToggleDirective } from '../../../../../../component/toggle/toggle.directive';
import { ExportService } from '../../../../../../core/export.service';
import { LearningTimeChartOptions } from '../../../../../../component/chart/learning-time-chart/learning-time-chart.types';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DoughnutChartModule } from 'src/app/component/chart/doughnut-chart/doughnut-chart.module';
import { LearningTimeChartModule } from 'src/app/component/chart/learning-time-chart/learning-time-chart.module';
import { ToggleModule } from 'src/app/component/toggle/toggle.module';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    DoughnutChartModule,
    LearningTimeChartModule,
    MatIconModule,
    MatButtonModule,
    ToggleModule,
  ],
  selector: 'rag-report-table-charts',
  templateUrl: './report-table-charts.component.html',
  styleUrls: [ './report-table-charts.component.scss' ],
})
export class ReportTableChartsComponent
  implements OnDestroy, OnInit {

  readonly chartLearningTime$: Observable<ReportLearningTime>;
  readonly chartPie$: Observable<DoughnutChartDataSet[]>;
  reportTitle: string;
  private _chartLearningTime = new CachedSubject<ReportLearningTime>(null);
  private _chartPie = new CachedSubject<DoughnutChartDataSet[]>(null);

  constructor(
    private chartService: ChartService,
    private exportService: ExportService,
    private reportService: ReportService,
    private reportTableService: ReportTableService,
  ) {
    this.chartLearningTime$ = this._chartLearningTime.withoutEmptyValues();
    this.chartPie$ = this._chartPie.withoutEmptyValues();
  }

  ngOnInit(): void {

    this.reportTableService.report$
      .pipe(switchMap(report => {
        if (report.reportConfig.uuid !== undefined) {
          return this.reportService.fetchStatisticsByReportUUID(report.reportConfig.uuid);
        }
        return of(report);
      }))
      .pipe(tap(report => {
        const reportConfig = report.reportConfig;
        this.reportTitle = reportConfig?.title;

        this._chartLearningTime.reset();
        this.updateStatistics(reportConfig, report?.statistics);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onDownloadCsv(
    options: LearningTimeChartOptions,
  ): void {
    this.exportService.xlsxExportLearningTime(this.reportTableService.report?.reportConfig, options);
  }

  toggleLearningTime(toggleTimeChart: ToggleDirective) {
    if ( toggleTimeChart.open ) {
      // no additional actions needed when closing
      toggleTimeChart.toggle(false);
      return;
    }


    this.updateLearningTime(this.reportTableService.report?.reportConfig)
      .pipe(tap(() => toggleTimeChart.toggle(true)))
      .pipe(take(1))
      .subscribe();
  }

  private updateLearningTime(reportConfig: ReportConfig | null): Observable<ReportLearningTime> {
    if ( reportConfig == null ) {
      // report config is empty -> clear chart

      return of({
        entries: {},
        firstEntryDate: null,
        lastEntryDate: null,
      })
        .pipe(tap(this._chartLearningTime.next));
    }

    return this.reportService.getLearningTime(reportConfig)
      .pipe(map(this._chartLearningTime.next));
  }

  private updateStatistics(reportConfig: ReportConfig | null, statistics: Statistics | null): void {

    // todo fix for courses (NPE when reading options for column accountDisplayStatus)
    if ( (reportConfig == null) || (statistics == null) || (reportConfig.targetType !== 'Curriculum') ) {
      // statistics are empty -> clear pie chart
      this._chartPie.next([]);
      return;
    }

    this.chartService.statisticsAsPie(statistics, reportConfig.targetType)
      .pipe(take(1))
      .pipe(map(this._chartPie.next))
      .subscribe();
  }

}
