import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenceInfoDialogComponent } from './licence-info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';


@NgModule({
  declarations: [ LicenceInfoDialogComponent ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ScrollingModule,
    PipesModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
})
export class LicenceInfoDialogModule {
}
