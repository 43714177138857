import { Component, Input, OnInit } from '@angular/core';
import { Translateable } from 'src/app/core/core.types';

@Component({
  selector: 'rag-breadcrumbs-link',
  templateUrl: './breadcrumbs-link.component.html',
  styleUrls: [ './breadcrumbs-link.component.scss' ],
})
export class BreadcrumbsLinkComponent
  implements OnInit {

  @Input()
  title: Translateable;
  @Input()
  url: string;

  constructor() {
  }

  ngOnInit() {
  }

}
