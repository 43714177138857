import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CtrlSingleUserCurriculumTypes } from './ctrl-single-user-curriculum.types';
import { catchError, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType } from '../../../../core/info/info.types';
import { ModalDialog } from '../../../../core/modal-dialog';
import { CtrlSingleUserCurriculumNotificationsComponent } from './ctrl-single-user-curriculum-notifications/ctrl-single-user-curriculum-notifications.component';
import { CtrlSingleUserCurriculumEventsComponent, CtrlSingleUserCurriculumEventsParams } from './ctrl-single-user-curriculum-events/ctrl-single-user-curriculum-events.component';
import { CtrlSingleUserCurriculumNotificationsService } from './ctrl-single-user-curriculum-notifications/ctrl-single-user-curriculum-notifications.service';
import { CtrlSingleUserCurriculumEventsService } from './ctrl-single-user-curriculum-events/ctrl-single-user-curriculum-events.service';
import { ApiUrls } from '../../../../core/api.urls';
import { SnackbarProgressService } from '../../../../component/snackbar-progress/snackbar-progress.service';

@Injectable({ providedIn: 'root' })
export class CtrlSingleUserCurriculumService {

  constructor(
    private ctrlSingleUserCurriculumEventsService: CtrlSingleUserCurriculumEventsService,
    private ctrlSingleUserCurriculumNotificationsService: CtrlSingleUserCurriculumNotificationsService,
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private snackbarProgressService: SnackbarProgressService,
  ) {
  }

  resetLocks(entries: CtrlSingleUserCurriculumTypes.UserToCurriculum[]): Observable<void> {
    if ( !(entries?.length > 0) ) {
      return EMPTY;
    }

    const baseUrl = ApiUrls.getKey('CtrlSingleUserCurrRecalculateLocks');
    const queries = entries.map(entry => {
      const url = baseUrl
        .replace(/{curriculumId}/gi, String(entry.curriculumId))
        .replace(/{userId}/gi, String(entry.userId));
      return this.http.post<void>(url, null);
    });

    return this.snackbarProgressService.showProgress(queries)
      .pipe(catchError(this.handleError));
  }

  showEvents(userId: number, curriculumId: number, cannotChangeData = false): Observable<void> {
    return this.ctrlSingleUserCurriculumEventsService.getEvents(userId, curriculumId)
      .pipe(takeWhile(result => this.checkLength(result?.events, 'events')))
      .pipe(switchMap(data => this.openDialogEvents(userId, curriculumId, data, cannotChangeData)))
      .pipe(take(1))
      .pipe(map(() => void (0)));
  }

  showNotifications(userId: number, curriculumId: number): Observable<void> {
    return this.ctrlSingleUserCurriculumNotificationsService.getNotifications(userId, curriculumId)
      .pipe(takeWhile(result => this.checkLength(result?.notifications, 'notifications')))
      .pipe(switchMap(data => this.openDialogNotifications(userId, curriculumId, data)))
      .pipe(take(1))
      .pipe(map(() => void (0)));
  }

  private checkLength<T>(result: T[], name: string): boolean {
    if ( result?.length > 0 ) {
      return true;
    }
    if ( name === 'events' ) {
      this.infoService.showMessage($localize`:@@ctrl_single_user_curriculum_no_entries:
        There are no entries for this curriculum.`,
        { infoType: InfoType.Information });
    } else if ( name === 'notifications' ) {
      this.infoService.showMessage($localize`:@@ctrl_single_user_curriculum_no_planned_notifications:
       There are currently no planned notifications.`,
        { infoType: InfoType.Information });
    }
    return false;
  }

  private handleError = (): Observable<void> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

  private openDialogEvents(userId: number, curriculumId: number,
    data: CtrlSingleUserCurriculumEventsParams, cannotChangeData = false): Observable<void> {
    data.curriculumId = curriculumId;
    data.userId = userId;
    data.cannotChangeData = cannotChangeData;
    return this.dialog.openModal<CtrlSingleUserCurriculumEventsComponent,
    CtrlSingleUserCurriculumEventsParams, void>(
      CtrlSingleUserCurriculumEventsComponent, {
        data,
        minWidth: 'calc( min( 95vw, 1200px))',
        panelClass: 'max-width-dialog-container',
      })
      .afterClosed();
  }

  private openDialogNotifications(userId: number, curriculumId: number,
    data: CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse): Observable<void> {
    data.curriculumId = curriculumId;
    data.userId = userId;
    return this.dialog.openModal<CtrlSingleUserCurriculumNotificationsComponent,
      CtrlSingleUserCurriculumTypes.CurriculumNotificationResponse, void>(
      CtrlSingleUserCurriculumNotificationsComponent, {
        data,
        minWidth: 'calc( min( 95vw, 1200px))',
        panelClass: 'max-width-dialog-container',
      })
      .afterClosed();
  }

}
