import { BehaviorSubject, Observable } from "rxjs";
import { FileInfo } from "./core.types";
import * as uuid from 'uuid';

export class UploadFile
  implements FileInfo {
  file?: File;
  fileName: string;
  fileSize: number;
  mime: string;
  size?: number;
  uuid: string;

  readonly activityProgress$: Observable<number>;

  private _activityProgress$ = new BehaviorSubject<number>(0);

  constructor(data: FileInfo | File) {

    this.activityProgress$ = this._activityProgress$.asObservable();

    if ( data instanceof File ) {

      const fileReader = new FileReader();
      fileReader.onprogress = this.onprogress;
      fileReader.onerror = this.onerror;
      fileReader.onload = this.onload;
      fileReader.readAsDataURL(data);

      this.uuid = uuid.v4();
      this.fileName = data.name;
      this.fileSize = data.size;
      this.mime = data.type;

      this.file = data;
      return;
    }

    this.uuid = data.uuid;
    this.fileName = data.fileName;
    this.fileSize = data.fileSize;
    this.size = data.fileSize;
    this.mime = data.mime;
  }

  static instanceOfUploadFile(value: FileInfo ):  value is UploadFile {
    return 'uuid' in value && 'mime' in value && 'fileSize' in value;
  }

  onerror = (error) => {
    console?.error(error);
  };

  onload = () => {
    this._activityProgress$.next(1.0);
  };

  onprogress = ($event: ProgressEvent) => {
    if ( $event.lengthComputable ) {
      this._activityProgress$.next($event.loaded / $event.total);
    }
  };

  setProgress(progress: number) {
    this._activityProgress$.next(progress);
    if (progress === 0) {
      this._activityProgress$.complete();
    }
  }

}