import { Component, Input, OnInit } from '@angular/core';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { CachedSubject } from 'src/app/core/cached-subject';
import * as moment from 'moment';
import { ExtLoginsService } from 'src/app/core/ext-logins.service';
import { ContentOfflineService } from '../../route/user/content/content-offline/content-offline.service';
import { Observable } from 'rxjs';
import { Core } from 'src/app/core/core.types';
import { ImageUrlHelper } from '../../core/image-url-helper';
import { LanguageHelper } from 'src/app/core/language.helper';
import { PrincipalService } from '../../core/principal/principal.service';
import { take } from 'rxjs/operators';
import { ContentInfo } from 'src/app/core/content/content.types';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    PipesModule,
    MatIconModule,
  ],
  selector: 'rag-content-offline-info',
  templateUrl: './content-offline-info.component.html',
  styleUrls: [ './content-offline-info.component.scss' ],
})
export class ContentOfflineInfoComponent
  implements OnInit {

  isLogged$: Observable<boolean>;
  @Input() multipleEvents = false;
  isAdobeConnectServer = false;
  isSameDate = false;
  extLink$: Observable<string>;
  offlineContent: ContentInfo;
  descriptionLength: number;
  private _content: ContentInfo;
  private _extLink = new CachedSubject<string>(null);
  private _imageUrl: string;
  private _offlineEvent: OfflineContent.EventSchedule;

  constructor(
    private extLoginsService: ExtLoginsService,
    private contentOfflineService: ContentOfflineService,
    private principalService: PrincipalService,
  ) {

    this.isLogged$ = this.principalService.isLogged$;
    this.extLink$ = this._extLink.withoutEmptyValues();
  }

  get isEnterRoomAvailable() {
    return this.extLoginsService.isEnterRoomAvailable(this._offlineEvent);
  }

  get content() {
    return this._content;
  }

  @Input()
  set content(value: ContentInfo) {
    this.offlineContent = value;
    this.descriptionLength = LanguageHelper.objectToText(this.offlineContent.description).length;
    this._content = value;
    this.imageUrl = ImageUrlHelper.urlForPicture(value?.pictureId, value?.pictureUUID);
  }


  get imageUrl() {
    return this._imageUrl;
  }

  set imageUrl(value: string) {
    this._imageUrl = value;
  }

  get offlineEvent() {
    return this._offlineEvent;
  }

  @Input()
  set offlineEvent(value: OfflineContent.EventSchedule) {
    this._offlineEvent = value;
    this.isAdobeConnectServer = value.extLogin?.serverType === 'adobe_connect';
    this.isSameDate = this.sameDay(value.eventDate, value.eventDateUntil);
    const extLogin = value.extLogin;
    if ( extLogin ) {
      this.extLoginsService
        .urlAsGuest(extLogin, value.extLoginPath)
        .pipe(take(1))
        .subscribe(url => {
          this._extLink.next(url);
      });
    }
  }

  dateFormatted(date: number) {
    return moment(date).format('ll');
  }

  ngOnInit(): void {

    // fix extLogin reference when the event is of type - offline
    if ( this._content?.objType !== Core.DistributableType.lms_offlineCnt ) {
      this._extLink.reset();
    }
  }

  joinEvent(url: string) {
    this.contentOfflineService.joinEvent(this._content.id, this._offlineEvent.id, url, this._offlineEvent.eventDate);
  }

  private sameDay(date1: number, date2: number) {
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment1.get('date') === moment2.get('date') &&
      moment1.get('month') === moment2.get('month') &&
      moment1.get('year') === moment2.get('year');
  }

}
