<ng-template #tplColumnRemoved>
  <button
    type="button"
    mat-icon-button
  >
    <mat-icon svgIcon="loading" color="warn" class="spin"></mat-icon>
  </button>
</ng-template>

<div class="rotation">
  <div class="flex">
    <rag-clamp
      [clamp]="2"
      [input]="column.title | localeText"
    ></rag-clamp>
    <ng-container
      *ngIf="!isColumnRemoved; else tplColumnRemoved"
    >
      <button
        type="button"
        mat-icon-button
        (click)="deselectColumn()"
        [disabled]="canNotRemove"
      >
        <mat-icon svgIcon="close" color="warn"></mat-icon>
      </button>
    </ng-container>
  </div>
</div>
