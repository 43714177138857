<ng-template #renderColumnPanel let-item="item">
  <div class="expansion-panel-body">
    <div class="wrapper">
      <button (click)="showInformation('identifier')" mat-icon-button>
        <mat-icon svgIcon="information-outline"></mat-icon>
      </button>
      <span i18n="@@columnConf_identifier_on_database">Identifier:</span>
      <span class="ml-5">{{item.columnName}}</span>
    </div>
    <div class="checkbox-container">
      <div class="info-checkbox-wrapper">
        <button (click)="showInformation('static')" mat-icon-button>
          <mat-icon svgIcon="information-outline"></mat-icon>
        </button>
        <mat-checkbox (change)="detectChanges(false, item.columnName); validateStatic(item)"
                      [(ngModel)]="item.fixed"
                      i18n="@@columnConf_static">
          Static
        </mat-checkbox>
      </div>
    </div>
    <div class="input-container">
      <!-- Title -->
      <div class="input-field">
        <div class="info-wrapper">
          <h3 i18n="@@global_title">Title</h3>
          <button (click)="showInformation('title')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
        </div>

        <mat-form-field>
          <mat-label i18n="@@global_de">German</mat-label>
          <input (change)="detectChanges(false, item.columnName)" [(ngModel)]="item.title.de" matInput required
                 type="text">
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n="@@global_en">English</mat-label>
          <input (change)="detectChanges(false, item.columnName)" [(ngModel)]="item.title.en" matInput required
                 type="text">
        </mat-form-field>
      </div>

      <!-- Dropdown Options -->
      <!-- todo until the dropdown options are dynamic in the frontend - use this when reenabling: *ngIf="item.dropDownOptions" -->
      <mat-expansion-panel *ngIf="false" class="input-field">
        <mat-expansion-panel-header>
          <mat-panel-title class="dropdown-panel" i18n="@@columnConf_dropdown_options_input_header">
            Dropdown options
          </mat-panel-title>
          <mat-panel-description>
            <button (click)="showInformation('dropdown', $event)" mat-icon-button>
              <mat-icon svgIcon="information-outline"></mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- options for status -->
        <div *ngIf="item.type === 'status'">
          <!-- certified -->
          <h4 i18n="@@columnConf_dropdown_options_header_certified">Certified</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.certified.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.certified.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- complete -->
          <h4 i18n="@@columnConf_dropdown_options_header_complete">Complete</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.complete.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.complete.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- failed -->
          <h4 i18n="@@columnConf_dropdown_options_header_failed">Failed</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.failed.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.failed.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- incomplete -->
          <h4 i18n="@@columnConf_dropdown_options_header_incomplete">Incomplete</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.incomplete.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.incomplete.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- not attempted -->
          <h4 i18n="@@columnConf_dropdown_options_header_not_attempted">Not Attempted</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notAttempted.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notAttempted.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- notCertified -->
          <h4 i18n="@@columnConf_dropdown_options_header_not_certified">Not Certified</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notCertified.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notCertified.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- passed -->
          <h4 i18n="@@columnConf_dropdown_options_header_passed">Passed</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.passed.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.passed.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- recertificationTime -->
          <h4 i18n="@@columnConf_dropdown_options_header_recertification_time">Recertification Time</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.recertificationTime.de"
                     matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.recertificationTime.en"
                     matInput
                     required type="text">
            </mat-form-field>
          </div>
        </div>

        <!-- options for account processing status -->
        <div *ngIf="item.type === 'accountProcessingStatus'">
          <!-- anulled -->
          <h4 i18n="@@columnConf_dropdown_options_header_anulled">Anulled</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.anulled.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.anulled.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- ended -->
          <h4 i18n="@@columnConf_dropdown_options_header_ended">Ended</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.ended.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.ended.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- locked -->
          <h4 i18n="@@columnConf_dropdown_options_header_locked">Locked</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.locked.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.locked.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- valid -->
          <h4 i18n="@@columnConf_dropdown_options_header_valid">Valid</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.valid.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.valid.en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- not attempted -->
          <h4 i18n="@@columnConf_dropdown_options_header_not_attempted">Not Attempted</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notAttempted.de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions.notAttempted.en" matInput
                     required type="text">
            </mat-form-field>
          </div>
        </div>

        <!-- options for gender -->
        <div *ngIf="item.type === 'gender'">
          <!-- male -->
          <h4 i18n="@@columnConf_dropdown_options_header_male">Male</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)" [(ngModel)]="item.dropDownOptions['2'].de"
                     matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)" [(ngModel)]="item.dropDownOptions['2'].en"
                     matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- female -->
          <h4 i18n="@@gender_1">Female</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['1'].de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['1'].en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- diverse -->
          <h4 i18n="@@columnConf_dropdown_options_header_diverse">Diverse</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['3'].de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['3'].en" matInput
                     required type="text">
            </mat-form-field>
          </div>

          <!-- unknown -->
          <h4 i18n="@@columnConf_dropdown_options_header_unknown">Unknown</h4>
          <div class="dropdown-wrapper">
            <mat-form-field>
              <mat-label i18n="@@global_de">German</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['4'].de" matInput
                     required type="text">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@global_en">English</mat-label>
              <input (change)="detectChanges(false, item.columnName)"
                     [(ngModel)]="item.dropDownOptions['4'].en" matInput
                     required type="text">
            </mat-form-field>
          </div>
        </div>

      </mat-expansion-panel>
    </div>
  </div>
</ng-template>

<div class="column-conf-container">
  <!-- buttons -->
  <div class="section-wrapper">
    <div class="button-wrapper">
      <div class="target-type">
        <mat-form-field appearance="outline">
          <mat-label i18n="@@column_conf_set_targetType">Report type...</mat-label>
          <mat-select [(ngModel)]="targetType" (ngModelChange)="onChangeTargetType()">
            <mat-option [value]="'Curriculum'" i18n="@@report_type_curriculum">Curriculum</mat-option>
            <mat-option [value]="'Course'" i18n="@@report_type_course">Course</mat-option>
            <mat-option [value]="'Offline'" i18n="@@report_type_offline">Event</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="buttons">
        <button (click)="saveSettings()" [disabled]="!isDirty" color="primary" i18n="@@column_conf_save_button"
                mat-flat-button>
          Save
        </button>
        <button (click)="resetSettings()" i18n="@@column_conf_reset_button"
                mat-flat-button>
          Reset
        </button>
        <button (click)="resetToDefaultSettings()" i18n="@@column_conf_default_button"
                mat-flat-button>
          Default
        </button>
      </div>
    </div>
  </div>

  <div class="section-wrapper">

    <div class="table-wrapper left-table">
      <h2 class="vertical-middle">
        <span i18n="@@column_conf_selected_columns">Default Columns</span>
        <button (click)="showInformation('defaultColumns')" mat-icon-button>
          <mat-icon svgIcon="information-outline"></mat-icon>
        </button>
      </h2>

      <div
        #activeList="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[poolList]"
        [cdkDropListData]="selected"
        cdkDropList
        class="drop-list drop-list-active">
        <ng-container *ngFor="let item of selected">
          <mat-expansion-panel [ngClass]="{'modified': item.$view.modified}" cdkDrag togglePosition="before">
            <mat-expansion-panel-header>
              <mat-panel-title>{{item.title | localeText}}</mat-panel-title>
              <mat-panel-description>
                <ng-container *ngIf="item.visible">
                  <span i18n="@@columnConf_position">Position:</span>
                  <span class="index">{{item.orderIndex}}</span>
                </ng-container>
                <button (click)="disableItem(item)" class="remove" mat-icon-button>
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="renderColumnPanel; context: {item: item}"></ng-container>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </div>

    <div class="table-wrapper right-table">
      <h2 class="vertical-middle">
        <span i18n="@@column_conf_columns_pool">Pool</span>
        <button (click)="showInformation('poolColumns')" mat-icon-button>
          <mat-icon svgIcon="information-outline"></mat-icon>
        </button>
      </h2>

      <div
        #poolList="cdkDropList"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="[activeList]"
        [cdkDropListData]="pool"
        cdkDropList
        class="drop-list">
        <ng-container *ngFor="let item of pool">
          <mat-expansion-panel [ngClass]="{'modified': item.$view.modified}" cdkDrag togglePosition="before">
            <mat-expansion-panel-header>
              <mat-panel-title>{{item.title | localeText}}</mat-panel-title>
              <mat-panel-description>
                <ng-container *ngIf="item.visible">
                  <span i18n="@@columnConf_position">Position:</span>
                  <span class="index">{{item.orderIndex}}</span>
                </ng-container>
                <button (click)="disableItem(item)" class="remove" mat-icon-button>
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="renderColumnPanel; context: {item: item}"></ng-container>
          </mat-expansion-panel>
        </ng-container>
      </div>

      <div *ngIf="disabled.length">
        <mat-form-field appearance="outline" class="enable-column">
          <mat-label i18n="@@column_conf_enable_column">Enable column...</mat-label>
          <mat-select (selectionChange)="enableItem($event)">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let item of disabled"
                        [value]="item">{{item.title | localeText}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="showInformation('disabled')" mat-icon-button>
          <mat-icon svgIcon="information-outline"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
