import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ProcessService } from 'src/app/route/main/process/process.service';
import { RegistrationInfo } from '../principal/principal.types';
import { RegistrationService } from '../principal/registration.service';
import { Core } from '../core.types';


@Injectable({
  providedIn: 'root',
})
export class SelfRegisterResolverService
  implements Resolve<RegistrationInfo> {

  constructor(
    private processService: ProcessService,
    private registrationService: RegistrationService,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RegistrationInfo> {

    const processHash = route.queryParamMap.get('p');
    return this.fetchProcess(processHash)
      .pipe(switchMap(process => this.registrationService.requestRegisterInformation(process)))
      .pipe(take(1));
  }

  private fetchProcess(
    processHash: string | null,
  ): Observable<Core.UserProcess | null> {

    if ( processHash == null ) {
      return of(null);
    }

    return this.processService.getByHash(processHash);
  }

}
