import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogCardCurriculumComponent } from './catalog-card-curriculum.component';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { CatalogCardSchedulesPagerModule } from '../catalog-card-schedules-pager/catalog-card-schedules-pager.module';
import { ClampComponent } from '../../clamp/clamp.component';


@NgModule({
  declarations: [ CatalogCardCurriculumComponent ],
  exports: [ CatalogCardCurriculumComponent ],
  imports: [
    CommonModule,
    ClampComponent,
    PipesModule,
    CatalogCardSchedulesPagerModule,
  ],
})
export class CatalogCardCurriculumModule {
}
