import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ContentOverviewComponent } from 'src/app/route/user/content-overview/content-overview.component';
import { ApplicationStateService } from '../../../core/application-state.service';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { AbstractCardWidgetComponent, WidgetsUUID } from '../widgets.types';
import { WidgetsService } from '../widgets.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { map, tap } from 'rxjs/operators';
import { PrincipalService } from '../../../core/principal/principal.service';
import { AnyObject } from '../../../core/core.types';
import { QueryParamsService } from '../../../core/storage/query-params.service';


@Component({
  selector: 'rag-overview-widget',
  templateUrl: './overview-widget.component.html',
  styleUrls: [ './overview-widget.component.scss' ],
})
export class OverviewWidgetComponent
  extends AbstractCardWidgetComponent
  implements AfterViewInit, OnInit, OnDestroy, WidgetComponent {

  static WidgetUUID = WidgetsUUID.OverviewWidgetUUID;

  @Input() didReceiveResponse: DidReceiveResponseHandler;
  @Input() edit = false;

  linkLabel = $localize`:@@more_contents:All courses to be completed`;
  linkParams: AnyObject<string> = { st: 'open', es: 'lastValidUntil' };

  @ViewChild('overviewComponent', { static: true })
  overviewComponent: ContentOverviewComponent;

  @ViewChild('viewMode', { static: true })
  viewModeRef: TemplateRef<any>;

  constructor(
    applicationStateService: ApplicationStateService,
    private principalService: PrincipalService,
    private queryParamsService: QueryParamsService,
    private widgetService: WidgetsService,
  ) {
    super(applicationStateService);

    this.setTitle($localize`:@@widget_overview_title:Courses to be completed`);


    this.widgetService.widgetReload
      .pipe(tap(() => this.overviewComponent.reload()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => {
        if ( permissions.navUserLearnOfflineContent ) {
          this.linkLabel = $localize`:@@overview_placeholder_select_event_status_upcoming:Upcoming events`;
          this.linkParams = { st: 'upcoming', es: 'eventDate' };
        } else {
          this.linkLabel = $localize`:@@more_contents:All courses to be completed`;
          this.linkParams = { st: 'open', es: 'lastValidUntil' };
        }

        // override filter settings for included ContentOverviewComponent
        // todo check why url is immediately changed
        this.queryParamsService.patchQueryParams(this.linkParams, false);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getMenuReferences() {
    return [ this.viewModeRef ];
  }

  isDataDriven() {
    return true;
  }

  ngAfterViewInit() {
    this.observeResize();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.gridCols = this.widget.gridsterItem.cols;
    this.overviewComponent.embedded = true;
    this.overviewComponent.toggleViewMode(this.isBoxViewMode);

    this.settings
      .pipe(map(userSettings => {
        const isBoxViewMode = userSettings['isCards'] ?? true;
        if (isBoxViewMode !== this.isBoxViewMode) {
          this.toggleViewMode(false);
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  toggleViewMode(emitEvent = true) {
    this.isBoxViewMode = !this.isBoxViewMode;
    this.overviewComponent.toggleViewMode(this.isBoxViewMode);
    this.refreshMaxItems();
    if (emitEvent) {
      this.settingsChange.next({ isCards: this.isBoxViewMode });
    }
  }

}
