import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ApplicationStateService } from '../../../core/application-state.service';
import { CachedSubject } from '../../../core/cached-subject';
import { ContentService } from '../../../core/content/content.service';
import { InfoService } from '../../../core/info/info.service';
import { InfoType, MessageKey, YesButton, YesNoButtons } from '../../../core/info/info.types';
import { PrincipalService } from '../../../core/principal/principal.service';
import { PrincipalData } from '../../../core/principal/principal.types';

interface A {
  principal: PrincipalData;
  event: RouterEvent
}

@Injectable({
  providedIn: 'root',
})
export class ScoRunningObserverService {

  private _dialogOpened = false;
  private _scoRunningCheck: boolean = null;

  constructor(
    private applicationStateService: ApplicationStateService,
    private contentService: ContentService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
    this.applicationStateService.scoRunning$
      .pipe(tap(scoRunning => {
        if ( scoRunning ) {
          // clear flag if sco is started in this tab
          this._scoRunningCheck = null;
        }
      }))
      .subscribe();
  }

  observePrincipal(): Observable<void> {
    return this.router.events
      .pipe(filter(this.filterRouterEvents))
      .pipe(switchMap(this.getPrincipal))
      .pipe(switchMap(({ principal, event }) => {
        const sessions: number[] = principal && principal.runningscosessions || [];
        if ( sessions.length && !this.applicationStateService.scoRunning ) {
          const split = /\/sco\/(\d+)(?:$|[/?])/.exec(event.url);
          let itemId; let scoId;
          if ( split && split.length === 2 ) {
            itemId = parseInt(split[1], 10);
            scoId = itemId + 2;
          }
          const isContentRunning = (sessions.indexOf(itemId) !== -1) || (sessions.indexOf(scoId) !== -1);
          if ( isContentRunning ) {
            // executing sco with running session -> OK
            return of(null);
          }

          this._dialogOpened = true;
          return this.infoService.showConfirm(MessageKey.SCO.SCO_SESSION_ALREADY_RUNNING, YesNoButtons)
            .pipe(tap(() => this._dialogOpened = false))
            .pipe(filter(button => button === YesButton))
            .pipe(map(() => principal));
        }
        return of(null);
      }))
      .pipe(filter(CachedSubject.isNotEmpty))
      .pipe(switchMap(principal => this.contentService.finishUnfinished(principal.runningscosessions)))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return of();
      }))
      .pipe(switchMap(() => this.principalService.fetchUserData(false)))
      .pipe(delay(1500))
      .pipe(switchMap(() => this.contentService.fetchAccountData(true)))
      .pipe(map(() => void (0)));
  }

  private filterRouterEvents = (event: Event | RouterEvent): boolean => {
    if ( this._dialogOpened || !(event instanceof NavigationEnd) ) {
      // dialog is already open or the event is not an instance of NavigationEnd
      return false;
    }
    if ( this._scoRunningCheck == null ) {
      const urlTree = this.router.parseUrl(event.url);
      // disable checks if scoRunningCheck is set to 'false'
      this._scoRunningCheck = urlTree.queryParamMap.get('scoRunningCheck') !== 'false';
    }
    return this._scoRunningCheck;
  };

  private getPrincipal = (event: RouterEvent): Observable<A> => this.principalService.principal$
      .pipe(startWith(this.principalService.currentUser))
      .pipe(distinctUntilChanged(PrincipalService.principalEquals))
      .pipe(filter(CachedSubject.isNotEmpty))
      .pipe(map(principal => ({ principal, event })));

}
