import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Core } from 'src/app/core/core.types';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';
import { AdminUsersHelper } from '../../../../core/user/admin-users.helper';


@Component({
  selector: 'rag-profile-field-dropdown',
  templateUrl: './profile-field-dropdown.component.html',
  styleUrls: [ './profile-field-dropdown.component.scss' ],
})
export class ProfileFieldDropdownComponent
  extends AbstractProfileFieldComponent<string>
  implements OnInit {

  options: Core.KeyValue<string>[];

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const fieldId = this.field?.fieldId;
    if ( (fieldId === 'sex') || (fieldId === 'gender') ) {
      this.options = (this.field?.options ?? [])
        .sort((a, b) => AdminUsersHelper.sortGenderOptions(a?.key, b?.key));
    } else {
      this.options = this.field?.options ?? [];
    }
  }

}
