import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ProcessService } from '../../route/main/process/process.service';
import { ApiUrls } from '../api.urls';
import { RagHttpParameterCodec } from '../rag-http-parameter-codec.class';
import { RedirectHelper } from '../redirect.helper';
import { LoginMethod, LoginMethodOAuth2, LoginType } from './login-method';
import { PostLoginService } from './post-login.service';
import { PrincipalService } from './principal.service';
import { PrincipalData } from './principal.types';


@Injectable({ providedIn: 'root' })
export class LoginService {

  private readonly isIE: boolean;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private http: HttpClient,
    private principalService: PrincipalService,
    private processService: ProcessService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isIE = this.deviceDetectorService.browser === 'IE';
  }

  login(loginMethod?: LoginMethod, username?: string, password?: string, userProcessHash?: string): Observable<void> {

    try {
      document.cookie = `JSESSIONID=nope; path=${ApiUrls.classicApi}; expires=${new Date(0).toUTCString()}`;
    } catch ( e ) {
      console?.warn('failed to invalidate JSESSIONID from wrong context', e);
    }

    switch ( loginMethod?.type ?? LoginType.Credentials ) {

      case LoginType.LDAP:
        // if windows login is selected
        return this.updateRedirectLocation(userProcessHash)
          .pipe(switchMap(() => this.loginWithLDAP(username, password)));

      case LoginType.OAuth2:

        return RedirectHelper.clearRedirect()
          .pipe(switchMap(() => this.loginWithOAuth2(loginMethod as LoginMethodOAuth2)));

      case LoginType.Credentials:
      default:
        // proceed with normal login
        return this.updateRedirectLocation(userProcessHash)
          .pipe(switchMap(() => this.loginWithCredentials(username, password)));
    }
  }

  /**
   * API call to log user in with credentials
   */
  loginWithCredentials(email: string, password: string) {
    let body = new HttpParams({ encoder: new RagHttpParameterCodec() });
    // @see https://stackoverflow.com/a/54010521
    body = body.append('password', password);

    if ( this.isIE ) {
      // IE does no automatic escaping
      email = encodeURIComponent(email);
    }

    const url = ApiUrls.getKey('Login')
      .replace(/{login}/gi, email);
    return this.loginQuery(url, body)
      .pipe(tap(() => window.location.reload()));
  }

  loginWithLDAP(login: string, password: string) {
    let body = new HttpParams({ encoder: new RagHttpParameterCodec() });
    // @see https://stackoverflow.com/a/54010521
    body = body.append('authPass', password);

    if ( this.isIE ) {
      // IE does no automatic escaping
      login = encodeURIComponent(login);
    }

    const url = ApiUrls.getKey('WindowsLoginJTA') + '?authLogin=' + login;
    return this.loginQuery(url, body)
      .pipe(tap(() => window.location.reload()));
  }

  loginWithOAuth2(loginMethod: LoginMethodOAuth2): Observable<never> {

    const location = window.location;
    let authorizationUrl = location.protocol + '//' + location.host + loginMethod.authorizationUrl;

    const redirectUrl = this.getRedirectUrl();
    if ( redirectUrl != null ) {
      // append current parameter for immediate redirect after login
      authorizationUrl += (authorizationUrl.indexOf('?') > 0) ? '&' : '?';
      authorizationUrl += 'url=' + encodeURIComponent(`#${redirectUrl}`);
    }

    const context = this.processService.getContext() ?? {};
    if ( Object.keys(context).length > 0 ) {
      authorizationUrl += (authorizationUrl.indexOf('?') > 0) ? '&' : '?';
      authorizationUrl += 'state=' + encodeURIComponent(JSON.stringify(context));
    }

    // for some reason we need to use a timeout -> otherwise the page reloads without redirect
    setTimeout(() => location.href = authorizationUrl);

    // return empty to prevent any following code from executing
    return EMPTY;
  }

  private loginQuery(url: string, body: HttpParams): Observable<void> {
    return this.http.post<PrincipalData>(url, body)

      // TF-1053 maybe catch some mysterious errors where user is undefined
      .pipe(switchMap(principal => {

        if ( !(principal?.permissions?.length > 0) ) {
          // successful login without valid principal? -> try to reload once more
          return this.principalService.fetchUserData(false);
        }

        return of(principal);
      }))

      // check for post-login actions
      .pipe(switchMap(principal => {

        if ( principal.hasToResetPassword ) {
          // user has to choose a new password
          return PostLoginService.setPostLoginAction();
        }

        // search for any open post-login actions
        return this.principalService.getFrontendActions()
          .pipe(switchMap(frontendActions => {

            if ( !(frontendActions?.length > 0) ) {

              // remove any remaining flags if no action is required
              return PostLoginService.clearPostLoginAction();

            } else {

              // user has some actions
              return PostLoginService.setPostLoginAction()
                .pipe(map(_ => void (0)));
            }

          }));
      }))

      .pipe(take(1));
  }

  private getRedirectUrl() {
    // check if we have a parameter "url" to which we should redirect
    return this.route.snapshot.queryParamMap.get('url') ||
      // otherwise use the current location as target after login
      window.location.hash.substring(1);
  }

  private updateRedirectLocation(userProcessHash?: string): Observable<void> {

    return RedirectHelper.getRedirect()

      .pipe(switchMap(url => {
        if ( !!url ) {
          // preserve previous redirect url
          return of(void (0));
        }

        url = this.getRedirectUrl();
        if (userProcessHash !== undefined) {
          let href = window.location.href;
          if (href.indexOf('?') >= 0) {
            href += '&p=' + userProcessHash;
          } else {
            href += '?p=' + userProcessHash;
          }
          window.location.replace(href);
          url = window.location.hash.substring(1);
        }
        return RedirectHelper.setRedirect(url)
          .pipe(map(_ => void (0)));
      }))

      .pipe(catchError(() => {
        console?.warn('updateRedirectLocation: failed to store redirect url');
        return of(void (0));
      }));
  }

}
