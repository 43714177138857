import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { forEach } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/api.urls';
import { ApplicationStateService } from 'src/app/core/application-state.service';
import { ContentService } from 'src/app/core/content/content.service';
import { Content, ContentOverviewComponentFilter } from 'src/app/core/content/content.types';
import { TrainResponse } from 'src/app/core/global.types';
import { ViewHelper } from '../../../core/view-helper';
import { Certificate, CertificateDownloadObject, CertIterationDetails } from './certificates.types';
import { NumberedAnyObject } from 'src/app/core/core.types';
import { InfoService } from '../../../core/info/info.service';
import { MessageConstants } from '../../../core/info/info.types';


@Injectable({
  providedIn: 'root',
})
export class CertificatesService {

  static today: number;
  loading$: Observable<boolean>;
  today: number;

  constructor(private http: HttpClient, private applicationState: ApplicationStateService,
    private infoService: InfoService,
    private contentService: ContentService) {
    this.loading$ = applicationState.loading$;
  }

  static isAssignmentMatching(cert: Certificate, query: string): boolean {
    const $view = ViewHelper.getViewData(cert);
    const con = $view.curriculum || {} as Content;
    if ( con.assignmentType === 'both' || query === undefined ) {
      return true;
    }
    return con.assignmentType.toLowerCase() === query;
  }

  static isCertificateValid(certificate: Certificate, statusStr: string): boolean {
    const now = Date.now();
    if ( statusStr === undefined || statusStr.length === 0 ) {
      return true;
    }
    if ( 'valid' === statusStr || 'newest' === statusStr ) {
      return certificate.validUntil == null || certificate.validUntil > now;
    } else if ( 'expired' === statusStr ) {
      return certificate.validUntil != null && certificate.validUntil < now;
    }
    return false;
  }

  static isTitleMatching(title: string, query: string): boolean {
    if ( query === '' ) {
      return true;
    }
    return title.toLowerCase().includes(query.toLowerCase());
  }

  downloadPdfCertificates(userId: number, targetObjects: CertificateDownloadObject[]) {
    if (!(userId >= 0) || !(targetObjects?.length > 0)) {
      // empty request
      return;
    }

    // translate object identifiers into query string
    const oi = targetObjects
      .filter(obj => (obj.userId > 0) && (obj.curriculumId > 0))
      .map(obj => `${obj.userId}:${obj.curriculumId}:${obj.iteration ?? 'all'}`)
      .join(',');

    if (oi === '') {
      // no valid objects found
      return;
    }

    const url: string = ApiUrls.getKey('DownloadPdfCertificatesPostRequest');
    const body = 'uid=' + userId + '&oi=' + oi + '&ot=1&mid=cit&vsdate=0&vudate=0&cdate=' +
      new Date().getTime() + '&zip2=1&jspResponse=false';
    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    this.http.post(url, body, {
      observe: 'response',
      headers,
      responseType: 'blob' as 'json',
    }).subscribe((res: any) => {
        const data = res.body;
        const blob = new Blob([ data ], { type: data.type });
        if ( !blob.size ) {
          this.infoService.showMessage($localize`:@@certificates_no_data:Sorry, no certificate was found!`, {
            title: MessageConstants.DIALOG.TITLE.INFO,
          });
          return;
        }
        const contentDisposition = res.headers.get('content-disposition');

        let filename: any;
        const filenameRegex = '(?:.+?);[ ]*filename="(.+?)"';
        const regexResult = contentDisposition.match(filenameRegex);
        if ( regexResult != null ) {
          filename = regexResult[1];
          filename = decodeURIComponent(filename);
        } else {
          if ( data.type === 'application/pdf' ) {
            filename = 'certificate_cid' + oi + '_uid' + userId + '.pdf';
          } else {
            filename = 'certificates.zip';
          }
        }
        FileSaver.saveAs(blob, filename);
      });
  }

  filterContent(filter: ContentOverviewComponentFilter, certificates: Certificate[], selectedValidity): Certificate[] {
    {
      const searchedContents: Certificate[] = [];
      const curriculumTitle = filter.ts.trim();
      const idHelpder: NumberedAnyObject<Certificate> = {};
      certificates.forEach((con: Certificate) => {
        // if query is matching with curriculum push to array
        if ( CertificatesService.isTitleMatching(con.curriculum, curriculumTitle) &&
          CertificatesService.isCertificateValid(con, selectedValidity) ) {

          if ( selectedValidity === 'newest' ) {
            const existingIteration = idHelpder[con.curid];
            if ( existingIteration == null || existingIteration.iteration < con.iteration ) {
              idHelpder[con.curid] = con;
            }
          } else {
            searchedContents.push(con);
          }
        }
      });
      if ( selectedValidity === 'newest' ) {
        Object.values(idHelpder)
          .forEach(content => searchedContents.push(content));
      }
      return searchedContents;
    }
  }

  // TODO
  generateCertificate(userId: number, curid: number, iteration: number) {
    const url: string = ApiUrls.getKey('GenerateCertificate')
      .replace('{userId}', String(userId))
      .replace('{curId}', String(curid))
      .replace('{iteration}', String(iteration))
      .replace('{date}', String(new Date().getTime()));
    return this.http.get(url)
      .pipe(map(response => {
        // catch error and throw message, set isLogged status to false
        if ( !response ) {
          throw new Error('Generate certificate failed!');
        }
      }));
  }

  // TODO
  getCertIterationDetails(userId: number, curid: number, iteration: number): Observable<CertIterationDetails> {
    const url: string = ApiUrls.getKey('CertIterationDetails')
      .replace('{userId}', String(userId))
      .replace('{curId}', String(curid))
      .replace('{iteration}', String(iteration));
    return this.http.get<CertIterationDetails>(url)
      .pipe(map((response: any) => {
        // catch error and throw message, set isLogged status to false
        if ( !response ) {
          throw new Error('Get certificates iteration details failed!');
        }
        return response;
      }));
  }

  getCertificates(includeCurriculumInfo = false): Observable<Certificate[]> {
    const query = this.http.get<TrainResponse<Certificate[]>>(ApiUrls.getKey('CertificatesGetRequest'))
      .pipe(map(response => response.data));

    if ( !includeCurriculumInfo ) {
      return query;
    }

    return combineLatest([ this.contentService.fetchAccountData(), query ])
      .pipe(map(([ contents, certificates ]) => {
        contents = contents || [];
        (certificates || []).forEach((certificate) => {
          const curriculumId = certificate.curid;
          forEach(contents, (content) => {
            if ( content.id === curriculumId ) {
              const $view = ViewHelper.getViewData(certificate);
              $view.curriculum = content;
              return false;
            }
          });
        });
        return certificates;
      }));
  }
}
