import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpRequestOptions, TrainResponse } from 'src/app/core/global.types';
import { ApiUrls } from './api.urls';
import { ApplicationStateService } from './application-state.service';
import { AccountData, AccountDataDivisions, AccountRequest, AnyObject } from './core.types';
import { PrincipalService } from './principal/principal.service';
import * as moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';

export interface PasswordRequest {
  login?: string;
  pass?: string;
}

const DevisionsMapping = {
  tabs: {
    profile: 'Profil',
    employee: 'Mitarbeiterinfo',
    organisation: 'Betriebsdaten',
    other: 'Weitere',
  },
  divisions: {
    login: 'profile',
    profile: 'profile',
    language: 'profile',
    employee: 'employee',
    location: 'organisation',
    organisation: 'organisation',
    other: 'other',
  },
};

const FieldsMapping = {
  divisions: {
    login: [ 'email', 'password' ],
    profile: [ 'firstname', 'lastname', 'sex', 'dateOfBirth' ],
    employee: [ 'employeeID', 'hostID', 'extID', 'soeID', 'jobCode', 'hireDate', 'superiorID', 'substituteID', 'substitute2ID' ],
    location: [ 'location', 'locationDesc', 'costCenter', 'costcenterDesc' ],
    organisation: [ 'company', 'area', 'state' ],
    other: [ 'freeTextAttribute1', 'freeTextAttribute2', 'freeTextAttribute3', 'freeTextAttribute4', 'freeTextAttribute5', 'freeTextAttribute6', 'freeTextAttribute7' ],
  },
};


/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  loading$: Observable<boolean>;

  constructor(
    private applicationState: ApplicationStateService,
    private http: HttpClient,
    private principalService: PrincipalService,
  ) {
    this.loading$ = applicationState.loading$;
  }

  formToProfileData(
    form: UntypedFormGroup | null,
  ): AnyObject {

    return Object.entries(form?.controls ?? {})
      .reduce((pV, [ fieldId, control ]) => {
        if ( control.invalid || control.pristine ) {
          // ignore unchanged or invalid values
          return pV;
        }

        if ( fieldId.endsWith('Repeat') ) {
          // ignore repeat fields
          return pV;
        }

        let value = control.value;
        if ( moment.isMoment(value) ) {
          // convert moment to unix time
          value = value.unix() * 1000;
        } else if (Array.isArray(value)) {
          value = value
            // ignore anything that is not a primitive
            .filter(o => typeof (o) !== 'object')
            // trim any strings
            .map(o => String(o).trim())
            // remove empty values
            .filter(o => !!o)
            // join by comma
            .join(',')
            // remove duplicate ','
            .replace(/[,]{2,}/g, ',');
          // add , in front, and at the end
          if (value.length > 0) {
            value = `,${value},`;
          }
        }

        if ( (typeof (value) === 'string') && !value ) {
          // ignore empty strings
          return pV;
        }

        pV[fieldId] = value;
        return pV;
      }, {});
  }

  getValue(fieldId: string, userData: AccountData): string {
    const fieldIdLowerCase = fieldId.toLowerCase();
    const tab = userData.tabs[0];
    return tab.divisions.reduce((pV, devision) => {
      const targetField = devision.fields.find(field => field.fieldId.toLocaleLowerCase() === fieldIdLowerCase);
      if (targetField != null) {
        pV = targetField.value;
      }
      return pV;
    }, '');
  }

  getUserData(): Observable<AccountData> {
    if ( !this.principalService.isLogged ) {
      return of(null);
    }

    return this.http.get<AccountDataDivisions>(ApiUrls.getKey('AccountDataGetRequest'))
      .pipe(map((response: any) => {
        // catch error and throw message, set isLogged status to false
        if ( !response || !response.success ) {
          throw new Error('Get user data failed!');
        }
        return this.mappingDivisionsTabs(response);
      }));
  }

  mappingDivisionsTabs(accountDataDivisions: AccountDataDivisions): AccountData {

    const accountData: AccountData = {
      tabs: [],
      success: true,
    };

    const tabIndex = (tabId: string, ad: AccountData): any => {
      for ( let i = 0; i < accountData.tabs.length; i++ ) {
        if ( ad.tabs[i].tabId === tabId ) {
          return i;
        }
      }
      return -1;
    };

    accountDataDivisions.divisions.map(division => {
      // get tabId to which this devisoin belongs
      const tabId = DevisionsMapping.divisions[division.divisionId];
      if ( !tabId ) {
        throw new Error('missing mapping for devision ' + JSON.stringify(division));
      }

      let _tabIndex = tabIndex(tabId, accountData);
      if ( _tabIndex === -1 ) {
        // register the tab if it does not exist yet
        accountData.tabs.push({
          tabId,
          label: DevisionsMapping.tabs[tabId],
          divisions: [],
        });
        _tabIndex = accountData.tabs.length - 1;
      }
      // insert this division to the tab
      accountData.tabs[_tabIndex].divisions.push({
        divisionId: division.divisionId,
        column: division.column,
        title: division.title,
        fields: division.fields,
      });
    });
    return accountData;
  }

  putPassword(requestData: PasswordRequest) {
    return this.http.put<TrainResponse>(
      ApiUrls.getKey('PasswordPutRequest'),
      JSON.stringify(requestData),
      HttpRequestOptions);
  }

  putUserData(requestData: AccountRequest) {
    return this.http.put<TrainResponse>(
      ApiUrls.getKey('AccountDataPutRequest'),
      JSON.stringify(requestData),
      HttpRequestOptions);
  }

}
