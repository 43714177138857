<nav mat-tab-nav-bar>
  <a #rlaNew="routerLinkActive"
     *ngIf="permissions?.navCtrlReportGenerator"
     [active]="rlaNew.isActive"
     [disabled]="hasAnyDirty()"
     mat-tab-link
     routerLinkActive=""
  >
    <div class="tab-container"
         (click)="$event.stopPropagation()"
         [matMenuTriggerFor]="reportTypeMenu"
         (menuClosed)="subMenuNewReportOpened = false"
         (menuOpened)="subMenuNewReportOpened = true"
    >
      <span class="hidden" routerLink="/report/v2/generator"></span>
      <span class="tab-label" i18n="@@report_new_report">New report</span>

      <mat-icon
        svgIcon="chevron-down"
        [ngClass]="{rotated: subMenuNewReportOpened}"
      ></mat-icon>

      <mat-menu
        #reportTypeMenu
        class="header-navigation-menu"
      >
        <a *ngIf="permissions.navCtrlReportCurriculum" mat-menu-item
           routerLink="/report/v2/generator/Curriculum"
           routerLinkActive="active"
        >
          <span class="hidden" routerLink="/report/v2/generator/Curriculum"></span>
          <span i18n="@@global_curriculum">Curriculum</span>
        </a>

        <a *ngIf="permissions.navCtrlReportCurriculum" mat-menu-item
           routerLink="/report/v2/generator/Curriculum/select"
           routerLinkActive="active"
        >
          <span class="hidden" routerLink="/report/v2/generator/Curriculum/select"></span>
          <span i18n="@@global_curriculum_selected">Selected curricula</span>
        </a>

        <a *ngIf="permissions.navCtrlReportCourse" mat-menu-item
           routerLink="/report/v2/generator/Course"
           routerLinkActive="active"
        >
          <span class="hidden" routerLink="/report/v2/generator/Course"></span>
          <span i18n="@@global_course">Course</span>
        </a>

        <a *ngIf="permissions.navCtrlReportOffline" mat-menu-item
           routerLink="/report/v2/generator/Offline"
           routerLinkActive="active"
        >
          <span class="hidden" routerLink="/report/v2/generator/Offline"></span>
          <span i18n="@@global_event">Event</span>
        </a>
      </mat-menu>
    </div>
  </a>

  <a #rlaSaved="routerLinkActive"
     [active]="rlaSaved.isActive"
     [disabled]="hasAnyDirty()"
     [routerLinkActiveOptions]="{exact: true}"
     mat-tab-link
     routerLink="/report/v2/saved"
     routerLinkActive=""
  >
    <div class="tab-container">
      <span class="tab-label" i18n="@@report_saved_eports">Saved reports</span>
    </div>
  </a>

  <ng-container *ngFor="let reportConfig of openReports">
    <a #rla="routerLinkActive"
       [active]="rla.isActive"
       [disabled]="hasAnyDirty(reportConfig)"
       [ngClass]="{'dirty': !!reportConfig.$view?.isDirty}"
       [routerLink]="getReportUrl(reportConfig)"
       mat-tab-link
       routerLinkActive=""
    >
      <div [matTooltip]="reportConfig.title" class="tab-container">
        <span class="tab-label">{{reportConfig.title}}</span>
        <button (click)="closeReport($event, reportConfig)" mat-icon-button>
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
    </a>
  </ng-container>

  <div class="spacer"></div>
</nav>
