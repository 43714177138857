import { Core } from '../../../core/core.types';
import { Translation } from '../../../core/translation/translation.types';

export interface RegistrationPreviewEntry {
  title: string;
  value: string;
}

export interface RegistrationPreview {
  [key: string]: RegistrationPreviewEntry;
}

export const OPTIONS_3LC: Core.KeyValue<Translation>[] = [ {
  key: 'AFG', value: 'AFG',
}, {
  key: 'EGY', value: 'EGY',
}, {
  key: 'ALB', value: 'ALB',
}, {
  key: 'DZA', value: 'DZA',
}, {
  key: 'AND', value: 'AND',
}, {
  key: 'AGO', value: 'AGO',
}, {
  key: 'ATG', value: 'ATG',
}, {
  key: 'GNQ', value: 'GNQ',
}, {
  key: 'ARG', value: 'ARG',
}, {
  key: 'ARM', value: 'ARM',
}, {
  key: 'AZE', value: 'AZE',
}, {
  key: 'ETH', value: 'ETH',
}, {
  key: 'AUS', value: 'AUS',
}, {
  key: 'BHS', value: 'BHS',
}, {
  key: 'BHR', value: 'BHR',
}, {
  key: 'BGD', value: 'BGD',
}, {
  key: 'BRB', value: 'BRB',
}, {
  key: 'BEL', value: 'BEL',
}, {
  key: 'BLZ', value: 'BLZ',
}, {
  key: 'BEN', value: 'BEN',
}, {
  key: 'BTN', value: 'BTN',
}, {
  key: 'BOL', value: 'BOL',
}, {
  key: 'BIH', value: 'BIH',
}, {
  key: 'BWA', value: 'BWA',
}, {
  key: 'BRA', value: 'BRA',
}, {
  key: 'BRN', value: 'BRN',
}, {
  key: 'BGR', value: 'BGR',
}, {
  key: 'BFA', value: 'BFA',
}, {
  key: 'BDI', value: 'BDI',
}, {
  key: 'CPV', value: 'CPV',
}, {
  key: 'CHL', value: 'CHL',
}, {
  key: 'CHN', value: 'CHN',
}, {
  key: 'CRI', value: 'CRI',
}, {
  key: 'CIV', value: 'CIV',
}, {
  key: 'DNK', value: 'DNK',
}, {
  key: 'DEU', value: 'DEU',
}, {
  key: 'DMA', value: 'DMA',
}, {
  key: 'DOM', value: 'DOM',
}, {
  key: 'DJI', value: 'DJI',
}, {
  key: 'ECU', value: 'ECU',
}, {
  key: 'SLV', value: 'SLV',
}, {
  key: 'ERI', value: 'ERI',
}, {
  key: 'EST', value: 'EST',
}, {
  key: 'FJI', value: 'FJI',
}, {
  key: 'FIN', value: 'FIN',
}, {
  key: 'FRA', value: 'FRA',
}, {
  key: 'GAB', value: 'GAB',
}, {
  key: 'GMB', value: 'GMB',
}, {
  key: 'GEO', value: 'GEO',
}, {
  key: 'GHA', value: 'GHA',
}, {
  key: 'GRD', value: 'GRD',
}, {
  key: 'GRC', value: 'GRC',
}, {
  key: 'GTM', value: 'GTM',
}, {
  key: 'GIN', value: 'GIN',
}, {
  key: 'GNB', value: 'GNB',
}, {
  key: 'GUY', value: 'GUY',
}, {
  key: 'HTI', value: 'HTI',
}, {
  key: 'HND', value: 'HND',
}, {
  key: 'HKG', value: 'HKG',
}, {
  key: 'IND', value: 'IND',
}, {
  key: 'IDN', value: 'IDN',
}, {
  key: 'IRQ', value: 'IRQ',
}, {
  key: 'IRN', value: 'IRN',
}, {
  key: 'IRL', value: 'IRL',
}, {
  key: 'ISL', value: 'ISL',
}, {
  key: 'ISR', value: 'ISR',
}, {
  key: 'ITA', value: 'ITA',
}, {
  key: 'JAM', value: 'JAM',
}, {
  key: 'JPN', value: 'JPN',
}, {
  key: 'YEM', value: 'YEM',
}, {
  key: 'JOR', value: 'JOR',
}, {
  key: 'YUG', value: 'YUG',
}, {
  key: 'YUG', value: 'YUG',
}, {
  key: 'KHM', value: 'KHM',
}, {
  key: 'CMR', value: 'CMR',
}, {
  key: 'CAN', value: 'CAN',
}, {
  key: 'KAZ', value: 'KAZ',
}, {
  key: 'QAT', value: 'QAT',
}, {
  key: 'KEN', value: 'KEN',
}, {
  key: 'KGZ', value: 'KGZ',
}, {
  key: 'KIR', value: 'KIR',
}, {
  key: 'COL', value: 'COL',
}, {
  key: 'COM', value: 'COM',
}, {
  key: 'COG', value: 'COG',
}, {
  key: 'COD', value: 'COD',
}, {
  key: 'PRK', value: 'PRK',
}, {
  key: 'KOR', value: 'KOR',
}, {
  key: 'XXK', value: 'XXK',
}, {
  key: 'HRV', value: 'HRV',
}, {
  key: 'CUB', value: 'CUB',
}, {
  key: 'KWT', value: 'KWT',
}, {
  key: 'LAO', value: 'LAO',
}, {
  key: 'LSO', value: 'LSO',
}, {
  key: 'LVA', value: 'LVA',
}, {
  key: 'LBN', value: 'LBN',
}, {
  key: 'LBR', value: 'LBR',
}, {
  key: 'LBY', value: 'LBY',
}, {
  key: 'LIE', value: 'LIE',
}, {
  key: 'LTU', value: 'LTU',
}, {
  key: 'LUX', value: 'LUX',
}, {
  key: 'MAC', value: 'MAC',
}, {
  key: 'MDG', value: 'MDG',
}, {
  key: 'MWI', value: 'MWI',
}, {
  key: 'MYS', value: 'MYS',
}, {
  key: 'MDV', value: 'MDV',
}, {
  key: 'MLI', value: 'MLI',
}, {
  key: 'MLT', value: 'MLT',
}, {
  key: 'MAR', value: 'MAR',
}, {
  key: 'MHL', value: 'MHL',
}, {
  key: 'MRT', value: 'MRT',
}, {
  key: 'MUS', value: 'MUS',
}, {
  key: 'MKD', value: 'MKD',
}, {
  key: 'MEX', value: 'MEX',
}, {
  key: 'FSM', value: 'FSM',
}, {
  key: 'MDA', value: 'MDA',
}, {
  key: 'MCO', value: 'MCO',
}, {
  key: 'MNG', value: 'MNG',
}, {
  key: 'MNE', value: 'MNE',
}, {
  key: 'MOZ', value: 'MOZ',
}, {
  key: 'MMR', value: 'MMR',
}, {
  key: 'NAM', value: 'NAM',
}, {
  key: 'NRU', value: 'NRU',
}, {
  key: 'NPL', value: 'NPL',
}, {
  key: 'NZL', value: 'NZL',
}, {
  key: 'NIC', value: 'NIC',
}, {
  key: 'NLD', value: 'NLD',
}, {
  key: 'NER', value: 'NER',
}, {
  key: 'NGA', value: 'NGA',
}, {
  key: 'NOR', value: 'NOR',
}, {
  key: 'OMN', value: 'OMN',
}, {
  key: 'AUT', value: 'AUT',
}, {
  key: 'PAK', value: 'PAK',
}, {
  key: 'PLW', value: 'PLW',
}, {
  key: 'PAN', value: 'PAN',
}, {
  key: 'PNG', value: 'PNG',
}, {
  key: 'PRY', value: 'PRY',
}, {
  key: 'PER', value: 'PER',
}, {
  key: 'PHL', value: 'PHL',
}, {
  key: 'POL', value: 'POL',
}, {
  key: 'PRT', value: 'PRT',
}, {
  key: 'RWA', value: 'RWA',
}, {
  key: 'ROU', value: 'ROU',
}, {
  key: 'RUS', value: 'RUS',
}, {
  key: 'SLB', value: 'SLB',
}, {
  key: 'ZMB', value: 'ZMB',
}, {
  key: 'WSM', value: 'WSM',
}, {
  key: 'SMR', value: 'SMR',
}, {
  key: 'STP', value: 'STP',
}, {
  key: 'SAU', value: 'SAU',
}, {
  key: 'SWE', value: 'SWE',
}, {
  key: 'CHE', value: 'CHE',
}, {
  key: 'SEN', value: 'SEN',
}, {
  key: 'SRB', value: 'SRB',
}, {
  key: 'SRB', value: 'SRB',
}, {
  key: 'SCG', value: 'SCG',
}, {
  key: 'SYC', value: 'SYC',
}, {
  key: 'SLE', value: 'SLE',
}, {
  key: 'ZWE', value: 'ZWE',
}, {
  key: 'SGP', value: 'SGP',
}, {
  key: 'SVK', value: 'SVK',
}, {
  key: 'SVN', value: 'SVN',
}, {
  key: 'SOM', value: 'SOM',
}, {
  key: 'SUN', value: 'SUN',
}, {
  key: 'ESP', value: 'ESP',
}, {
  key: 'LKA', value: 'LKA',
}, {
  key: 'KNA', value: 'KNA',
}, {
  key: 'LCA', value: 'LCA',
}, {
  key: 'VCT', value: 'VCT',
}, {
  key: 'XXA', value: 'XXA',
}, {
  key: 'ZAF', value: 'ZAF',
}, {
  key: 'SDN', value: 'SDN',
}, {
  key: 'SDN', value: 'SDN',
}, {
  key: 'SSD', value: 'SSD',
}, {
  key: 'SUR', value: 'SUR',
}, {
  key: 'SWZ', value: 'SWZ',
}, {
  key: 'SYR', value: 'SYR',
}, {
  key: 'TJK', value: 'TJK',
}, {
  key: 'TWN', value: 'TWN',
}, {
  key: 'TZA', value: 'TZA',
}, {
  key: 'THA', value: 'THA',
}, {
  key: 'TLS', value: 'TLS',
}, {
  key: 'TGO', value: 'TGO',
}, {
  key: 'TON', value: 'TON',
}, {
  key: 'TTO', value: 'TTO',
}, {
  key: 'TCD', value: 'TCD',
}, {
  key: 'CZE', value: 'CZE',
}, {
  key: 'CSK', value: 'CSK',
}, {
  key: 'TUN', value: 'TUN',
}, {
  key: 'TUR', value: 'TUR',
}, {
  key: 'TKM', value: 'TKM',
}, {
  key: 'TUV', value: 'TUV',
}, {
  key: 'UGA', value: 'UGA',
}, {
  key: 'UKR', value: 'UKR',
}, {
  key: 'HUN', value: 'HUN',
}, {
  key: 'URY', value: 'URY',
}, {
  key: 'UZB', value: 'UZB',
}, {
  key: 'VUT', value: 'VUT',
}, {
  key: 'VAT', value: 'VAT',
}, {
  key: 'VEN', value: 'VEN',
}, {
  key: 'ARE', value: 'ARE',
}, {
  key: 'USA', value: 'USA',
}, {
  key: 'GBR', value: 'GBR',
}, {
  key: 'VNM', value: 'VNM',
}, {
  key: 'BLR', value: 'BLR',
}, {
  key: 'CAF', value: 'CAF',
}, {
  key: 'CYP', value: 'CYP',
} ];
