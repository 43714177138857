import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable, of, TimeoutError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeWhile, tap, timeout } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { MaintenanceComponent } from './maintenance.component';
import { StorageHelper } from '../storage/storage.helper';


export interface MaintenanceInfoDebug {
  accountID: number;
  done: boolean;
  showDanger: boolean;
  showInfo: boolean;
  showWarning: boolean;
}

export type MaintenanceClass = 'none' | 'info' | 'warning' | 'danger';

export interface MaintenanceInfo {
  activeUntil: number;
  class: MaintenanceClass;
  debug: MaintenanceInfoDebug;
  isMaintenance: boolean;
  text: string;
}

export interface MaintenanceHidden {
  activeUntil: number;
  class: MaintenanceClass;
}

export const KEY_MAINTENANCE_INFO = 'MaintenanceService.maintenanceInfo';
export const KEY_MAINTENANCE_HIDDEN = 'MaintenanceService.maintenanceHidden';

@Injectable()
export class MaintenanceService {

  pollingIntervall = 60 * 1000;
  pollingMaxTimeout = 30 * 1000;
  private _clearApiErrorTimeout;
  private _isApiError = false;
  private _maintenanceHidden: MaintenanceHidden;
  private _maintenanceInfo: MaintenanceInfo;
  private errorHandle: MatSnackBarRef<SimpleSnackBar>;
  private infoHandle: MatSnackBarRef<SimpleSnackBar>;
  private poller;

  constructor(
    public http: HttpClient,
    public snackBar: MatSnackBar,
  ) {
  }

  get maintenanceInfo(): MaintenanceInfo {
    return this._maintenanceInfo || {} as MaintenanceInfo;
  }

  set maintenanceInfo(value: MaintenanceInfo) {
    this._maintenanceInfo = value;

    StorageHelper.save<MaintenanceHidden>(KEY_MAINTENANCE_INFO, value)

      .pipe(take(1))
      .subscribe();
  }

  clearApiError(restartTimeout = true) {
    if ( restartTimeout ) {
      this.cancelClearErrorTimeout();
    }
    if ( !this._clearApiErrorTimeout ) {
      this._clearApiErrorTimeout = setTimeout(() => {
        this._isApiError = false;
        this.closeErrorBar();
      }, this.pollingIntervall);
    }
  }

  isMaintenanceActive(): boolean {
    const info = this._maintenanceInfo;
    return info && info.isMaintenance && (info.activeUntil > new Date().getTime());
  }

  public onInit() {

    // fetch stored hidden state
    StorageHelper.get<MaintenanceHidden>(KEY_MAINTENANCE_HIDDEN)
      // ignore errors
      .pipe(catchError(() => of(void (0))))
      .pipe(switchMap((hidden: MaintenanceHidden) => {

        if ( !(hidden?.activeUntil > (new Date().getTime())) ) {

          // remove expired hidden message
          this._maintenanceHidden = null;
          return StorageHelper.remove(KEY_MAINTENANCE_HIDDEN);
        }

        // update local object
        this._maintenanceHidden = hidden;
        return of(void (0));
      }))

      // fetch last maintenance info
      .pipe(switchMap(() => StorageHelper.get<MaintenanceInfo>(KEY_MAINTENANCE_INFO)))
      // ignore errors
      .pipe(catchError(() => of(void (0))))
      .pipe(switchMap((hidden: MaintenanceInfo) => {

        if ( !(hidden?.activeUntil > (new Date().getTime())) ) {

          // remove expired message info
          this._maintenanceInfo = null;
          return StorageHelper.remove(KEY_MAINTENANCE_INFO);
        }

        // update local object
        this._maintenanceInfo = hidden;
        return of(void (0));
      }))

      // start a single poller
      .pipe(take(1))
      .pipe(tap(() => this.startPolling(true)))
      .subscribe();
  }

  showMaintenanceApiError(error): boolean {
    if ( !this.shouldShowError(error) ) {
      this.clearApiError();
      return false;
    }
    this.cancelClearErrorTimeout();
    this.closeInfoBar();
    if ( !this.errorHandle ) {
      this.errorHandle = this.snackBar.openFromComponent(MaintenanceComponent, {
        data: {
          message: this.maintenanceInfo.text,
          action: true
        },
        panelClass: 'maintenance-danger',
        verticalPosition: 'top',
      });
    }
    return true;
  }

  startPolling = (runImmediate: boolean = false): void => {
    if ( this.poller ) {
      clearInterval(this.poller);
    }
    if ( runImmediate === true ) {
      setTimeout(this.doPoll);
    }
    this.poller = setTimeout(this.doPoll, this.pollingIntervall);
  };

  private cancelClearErrorTimeout() {
    if ( this._clearApiErrorTimeout ) {
      clearTimeout(this._clearApiErrorTimeout);
      this._clearApiErrorTimeout = null;
    }
  }

  private cleanOutsideMaintenance = (): void => {
    const info = this._maintenanceInfo;
    if ( !info || (info.activeUntil < new Date().getTime()) ) {
      this.closeInfoBar();
      this.clearApiError();
    }
  };

  private closeErrorBar(): void {
    if ( !this._isApiError && this.errorHandle ) {
      this.errorHandle.dismiss();
      this.errorHandle = null;
    }
  }

  private closeInfoBar(): void {
    if ( this.infoHandle ) {
      this.infoHandle.dismiss();
      this.infoHandle = null;
    }
  }

  private doPoll = (): void => {
    this.fetchMaintenanceInfo()
      .pipe(map(info => {
        this.maintenanceInfo = info;
      }))
      .pipe(catchError(err => {
        if ( !this.showMaintenanceApiError(err) ) {
          // the error occurred outside of a scheduled maintenance -> check additional conditions
          if ( (err instanceof TimeoutError) || (err?.status === 504) ) {
            // status 504 = Gateway Timeout
            this.snackBar.openFromComponent(MaintenanceComponent, {
              data: {
                message: $localize`:@@maintenance_timeout_error:
                  The server appears to be slow at the moment. Please check your network connection.`,
                action: true,
              },
              panelClass: 'maintenance-warning',
              verticalPosition: 'top',
            });
          }
        }
        return EMPTY;
      }))
      .pipe(finalize(this.showInfo))
      .pipe(finalize(this.cleanOutsideMaintenance))
      .pipe(finalize(this.startPolling))
      .subscribe();
  };

  private fetchMaintenanceInfo(): Observable<MaintenanceInfo> {
    return this.http.get<MaintenanceInfo>(ApiUrls.getKey('GlobalMaintenance'))
      .pipe(catchError(() => EMPTY))
      .pipe(timeout(this.pollingMaxTimeout));
  }

  private shouldShowError(error): boolean {
    if ( error && ((error.status === 502) || (error.status === 503) || /Scheduled Maintenance/.test(error.error)) ) {
      // show generic server-is-down message
      this.maintenanceInfo.text = null;
      this._isApiError = true;
      return true;
    }
    return this.isMaintenanceActive() && (this._maintenanceInfo.class === 'danger');
  }

  private shouldShowInfo(): boolean {
    if ( !this.isMaintenanceActive() ) {
      this.clearApiError();
      return false;
    }
    const info = this._maintenanceInfo;
    const hidden = this._maintenanceHidden || {} as MaintenanceHidden;
    return info.class && (info.class !== 'none') && (info.class !== hidden.class);
  }

  private showInfo = (): void => {
    if ( !this.shouldShowInfo() ) {
      this.closeInfoBar();
      return;
    }

    if ( !(this.infoHandle || this.errorHandle) ) {
      this.infoHandle = this.snackBar.openFromComponent(MaintenanceComponent, {
        data: {
          message: this.maintenanceInfo.text,
          action: true,
        },
        panelClass: 'maintenance-' + this.maintenanceInfo.class,
        verticalPosition: 'top',
      });
      this.infoHandle.afterDismissed()

        // abort if not dismissed by user action (e.g. timeout)
        .pipe(takeWhile((response: MatSnackBarDismiss) => response?.dismissedByAction === true))

        // store relevant values for hiding repeated identical messages
        .pipe(switchMap(() => {
          const info = this.maintenanceInfo;
          this._maintenanceHidden = {
            activeUntil: info.activeUntil,
            class: info.class,
          };
          return StorageHelper.save(KEY_MAINTENANCE_HIDDEN, this._maintenanceHidden);
        }))

        .pipe(take(1))
        .subscribe();
    }
  };

}
