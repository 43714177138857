import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { PartialItemListDialogComponent } from './partial-item-list-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ItemListModule } from 'src/app/component/elearning/item-list/item-list.module';

@NgModule({
  declarations: [ PartialItemListDialogComponent ],
  imports: [
    CommonModule,
    ItemListModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class PartialItemListDialogModule { }
