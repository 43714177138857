import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ReportService } from 'src/app/core/report/report.service';
import { Report } from 'src/app/core/report/report.types';

// import { ColumnSettingsDummy } from 'src/app/route/ctrl/report/columnSettingsDummy';

@Injectable({
  providedIn: 'root',
})
export class ReportsResolverService
  implements Resolve<Report[]> {

  constructor(
    private reportsService: ReportService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Report[]> {
    return this.reportsService.fetchMyReports()
      .pipe(take(1));
  }

}
