import { map, Observable, of } from 'rxjs';
import { StorageHelper } from '../storage/storage.helper';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { PrincipalData } from './principal.types';
import { switchMap } from 'rxjs/operators';
import { RedirectHelper } from '../redirect.helper';


@Injectable({ providedIn: 'root' })
export class PostLoginService {

  constructor(
    private router: Router,
  ) {
  }

  public static clearPostLoginAction(): Observable<void> {
    return StorageHelper.remove('postLoginAction');
  }

  public static getPostLoginAction(): Observable<boolean> {
    return StorageHelper.get<boolean>('postLoginAction', true);
  }

  public static setPostLoginAction(): Observable<boolean> {
    return StorageHelper.save<boolean>('postLoginAction', true);
  }

  /**
   * @param principal principal may be null (e.g. for access to the public catalog)
   * @param redirectUrl if defined, will set as next action after /post-login handling
   */
  checkPostLoginActions(principal?: PrincipalData, redirectUrl?: string): Observable<null | UrlTree> {

    return of(principal)
      .pipe(switchMap(p => {

        if ( p?.hasToResetPassword === true ) {
          // reset password should redirect in any case
          return of(true);
        }

        // check for any delayable actions that should only run after login
        return PostLoginService.getPostLoginAction();
      }))
      .pipe(map(hasAction => this.checkPostLoginActionRedirect(hasAction, redirectUrl)));

  }

  private checkPostLoginActionRedirect(hasAction: boolean, redirectUrl: string): null | UrlTree {
    if (hasAction !== true) {
      // other checks are needed
      return null;
    }

    // pass redirect url for subsequent forward
    const queryParams = RedirectHelper.isValidRedirect(redirectUrl) ? { url: redirectUrl } : null;
    // redirect to post login page
    return this.router.createUrlTree(['/post-login'], { queryParams });
  }

}
