<div class="center-content">

  <div *ngIf="tokenInvalid; else passwordForm" class="info-box">
    <p i18n="@@pswd_token_invalid">The link you are using does not appear to be correct. To be sure, please copy the
      link by hand.</p>

    <button class="go-home" mat-button routerLink="/home" type="button">
      <span i18n="@@navigation_home">Home</span>
    </button>
  </div>

  <ng-template #passwordForm>
    <div *ngIf="!requestIsSent || form.invalid" class="">
      <h1>
        <span i18n="@@pswd_set_new_password_title">Reset password</span>
      </h1>
      <div i18n="@@pswd_set_new_password_descr" id="text">
        Please enter your new password. Notice, that the same password must be entered twice.
      </div>

      <form (submit)="resetPassword()" [formGroup]="form">

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@pswd_new_password">New password</span>
            </mat-label>
            <input [formControlName]="'newPasswordControl'"
                   i18n-placeholder="@@pswd_placeholder" matInput
                   placeholder="Enter new password"
                   required
                   type="password">
            <mat-error>
               <span
                 *ngIf="form.controls.newPasswordControl.hasError('required')">
                 <span i18n="@@validation_required">This field is required</span></span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@pswd_repeat_password">Confirm password</span>
            </mat-label>
            <input [formControlName]="'newPasswordControl2'"
                   i18n-placeholder="@@pswd_placeholder"
                   matInput
                   placeholder="Enter new password"
                   required
                   type="password">


            <mat-error>
              <span *ngIf="passwordRepeateControl.hasError('required')" i18n="@@validation_required">This field is required</span>
              <span *ngIf="passwordRepeateControl.hasError('not_match')"
                    i18n="@@valid_no_match">The values do not match.</span>
              <span

                *ngIf="hasError('newPasswordControl2', 'network')"
                displayOne>{{this.form.get('').errors['network']}}</span> <br>
            </mat-error>
          </mat-form-field>
        </div>

        <rag-password-validation [passwordField]="getControl('newPasswordControl')"></rag-password-validation>

        <div class="buttons">
          <button
            [disabled]="form.pristine || form.invalid"
            color="primary"
            mat-stroked-button
            type="submit"
            class="submit-button">
            <span i18n="@@pswd_set_new_password">Set password</span>
          </button>
          <button
            (click)="cancel()"
            color="secondary"
            class="cancel-button"
            mat-stroked-button
            type="button">
            <span i18n="@@cancel_button">Cancel</span>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="requestIsSent" class="box info-box">
      <h1>
        <span i18n="@@global-success">Success</span>
      </h1>

      <p i18n="@@pswd_new_password_is_set">Your password has been reset successfully.</p>

      <button class="go-home" mat-stroked-button routerLink="/home" type="button">
        <span i18n="@@navigation_home">Home</span>
      </button>
    </div>
  </ng-template>
</div>
<!-- Translations -->
<span #pswd_leave_without_reset class="hidden" i18n="@@pswd_leave_without_reset">Would you like to leave this page without reseting your password?</span>
