<div *ngIf="hasResponse" class="container" [ngClass]="{'has-content': hasText(news)}">
  <ng-template #emptyContent>
    <div class="no-content">
      <p i18n="@@widget_specials_no_content">There is no welcome message to display yet.</p>
    </div>
  </ng-template>

  <div
    *ngIf="(news != null) else emptyContent"
    class="content"
    (click)="onClick()"
  >
    <div
      *ngIf="newsImageUrl"
      [ngStyle]="{'background-image' : 'url(' + newsImageUrl + ')'}"
      class="background"
    ></div>

    <div class="headline">{{news.headlineColumn}}</div>
  </div>
</div>
