import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSignaturesDetailsModule } from './admin-signatures-details/admin-signatures-details.module';
import { AdminSignaturesListModule } from './admin-signatures-list/admin-signatures-list.module';
import { AdminSignaturesUsageDialogModule } from './admin-signatures-usage-dialog/admin-signatures-usage-dialog.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminSignaturesDetailsModule,
    AdminSignaturesListModule,
    AdminSignaturesUsageDialogModule,
  ]
})
export class AdminSignaturesModule { }
