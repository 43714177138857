import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextDecodeHelper } from 'src/app/core/translation/text-decode.helper';
import { HasInfoModel } from '../../core/core.types';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  selector: 'rag-info-tooltip-button',
  templateUrl: './info-tooltip-button.component.html',
  styleUrls: [ './info-tooltip-button.component.scss' ],
})
export class InfoTooltipButtonComponent implements OnInit {

  @Input() hideWhenEmpty = false;
  @Input() info: HasInfoModel;

  infoText: string;

  get hasInfo(): boolean {
    return this.info?.hasInfo !== false && !!this.infoText;
  }

  @HostBinding('class.hidden')
  get hidden(): boolean {
    return this.hideWhenEmpty && !this.hasInfo;
  }

  ngOnInit(): void {
    this.infoText = '';
    if (this.info?.info) {
      this.infoText = TextDecodeHelper.decodeHtml(this.info?.info);
    }
  }

}
