<div class="container">
  <div class="row">
    <div class="column grow-1 level-wrapper">
      <div class="level-title">
        <div class="text-align-center">
          <span class="font-size-60">{{gamificationProfile?.level}}</span>
          <br>
          <span class="font-size-20">Level</span>
        </div>
      </div>
        <mat-progress-spinner
          class="progress"
          [value]="progressBarValue"
          diameter="200"
          strokeWidth="7"
        >
        </mat-progress-spinner>
      <div
        class="circle"
        [matTooltip]="getXpForNextLevelTooltip()"
      >
      </div>
    </div>
    <div class="column badge-details-wrapper">
      <div class="badge-details">
        <mat-tab-group (selectedIndexChange)="selectedIndex = $event" animationDuration="0">
          <mat-tab label="Learning content" i18n-label="@@admin_lu_header">
          </mat-tab>
          <mat-tab label="Curriculum" i18n-label="@@admin_content_content_curriculum">
          </mat-tab>
          <mat-tab label="Events" i18n-label="@@admin_offline_content_header">
          </mat-tab>
        </mat-tab-group>
        <rag-badge-slider
          *ngIf="getBadgesForType().length !== 0; else noBadges"
          [badges]="getBadgesForType()"
          class="badge-slider"
        ></rag-badge-slider>
      </div>
    </div>
  </div>
</div>
<ng-template #noBadges>
  <div class="no-data-style">
    <span class="no-data-text" i18n="@@general-no-data">No data</span>
  </div>
</ng-template>
