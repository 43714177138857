/*
* File copied and modified from https://github.com/nwcell/ics.js
* */


import { TextDecodeHelper } from '../translation/text-decode.helper';

type RepetitionFrequency = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY';
type RepetitionDay = 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';

interface RepetitionRule {
  /**
   * Which days of the week the event is to occur. An array containing any of SU, MO, TU, WE, TH, FR, SA.
   */
  byday: RepetitionDay[];
  /**
   * Alternative to until. Repeat the event count times. Must be an integer
   */
  count?: number;
  /**
   * Required. The frequency of event recurrence. Can be DAILY, WEEKLY, MONTHLY, or YEARLY.
   */
  freq: RepetitionFrequency;
  /**
   * The interval of freq to recur at. For example, if freq is WEEKLY and interval is 2,
   * the event will repeat every 2 weeks. Must be an integer.
   */
  interval?: number;
  /**
   * internal use
   */
  rrule?: string;
  /**
   * A date string representing the date on which to end repitition. Must be friendly to Date()
   */
  until?: Date | string;
}


/*! ics.js Wed Sept 14 2017 */

export namespace ics_js {

  export const ics = (uidDomain, prodId) => {

    if ( typeof uidDomain === 'undefined' ) {
      uidDomain = 'default';
    }
    if ( typeof prodId === 'undefined' ) {
      prodId = 'Calendar';
    }

    const SEPARATOR = (navigator.appVersion.indexOf('Win') !== -1) ? '\r\n' : '\n';
    const calendarEvents = [];
    const calendarStart = [
      'BEGIN:VCALENDAR',
      'PRODID:' + prodId,
      'VERSION:2.0',
    ].join(SEPARATOR);
    const calendarEnd = SEPARATOR + 'END:VCALENDAR';
    const BYDAY_VALUES = [ 'SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA' ];

    return {
      /**
       * Returns events array
       * @return Events
       */
      events: () => calendarEvents,

      /**
       * Returns calendar
       * @return Calendar in iCalendar format
       */
      calendar: () => calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd,

      /**
       * Add event to the calendar
       * RAG edit: added htmlDescription param
       * @param  subject         Subject/Title of event
       * @param  htmlDescription description including html things
       * @param  location        Location of event
       * @param  begin           Beginning date of event
       * @param  stop            Ending date of event
       * @param  rrule           repetition rules
       */
      addEvent: (subject: string, htmlDescription: string, location: string, begin: string, stop: string, rrule?: RepetitionRule) => {

        /* *********************** rag edit begin **************************** */
        const cleanedHTMLDescription = TextDecodeHelper.cleanHtml(htmlDescription)
          .replace(/(<a)/i, '$1 target="_blank"');

        // noinspection HtmlRequiredLangAttribute
        let wrappedHtmlDescription = '<!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 3.2//EN\">' +
          '<html><head><title></title></head><body>' +
          cleanedHTMLDescription +
          '</body></html>';
        wrappedHtmlDescription = wrappedHtmlDescription.replace(/[{]MESSAGE_TEXT[}]/, '{MESSAGE_HTML}');
        wrappedHtmlDescription = wrappedHtmlDescription.replace(/\r\n|\r|\n/g, '<br>');

        let description = TextDecodeHelper.decodeHtml(htmlDescription, true);
        description = description.replace(/\r\n|\r|\n/g, '\\n');
        /* *********************** rag edit end ****************************** */

        // I'm not in the mood to make these optional... So they are all required
        if ( typeof subject === 'undefined' ||
          typeof description === 'undefined' ||
          typeof location === 'undefined' ||
          typeof begin === 'undefined' ||
          typeof stop === 'undefined'
        ) {
          return false;
        }

        // validate rrule
        if ( rrule ) {
          if ( !rrule.rrule ) {
            if ( rrule.freq !== 'YEARLY' && rrule.freq !== 'MONTHLY' && rrule.freq !== 'WEEKLY' && rrule.freq !== 'DAILY' ) {
              throw new Error('Recurrence rrule frequency must be provided and be one of the following: \'YEARLY\', \'MONTHLY\', \'WEEKLY\', or \'DAILY\'');
            }

            if ( rrule.until ) {
              if ( typeof (rrule.until) === 'string' && isNaN(Date.parse(rrule.until)) ) {
                throw new Error('Recurrence rrule \'until\' must be a valid date string');
              }
            }

            if ( rrule.interval ) {
              if ( typeof (rrule.interval) === 'string' && isNaN(parseInt(rrule.interval, 10)) ) {
                throw new Error('Recurrence rrule \'interval\' must be an integer');
              }
            }

            if ( rrule.count ) {
              if ( typeof (rrule.count) === 'string' && isNaN(parseInt(rrule.count, 10)) ) {
                throw new Error('Recurrence rrule \'count\' must be an integer');
              }
            }

            if ( typeof rrule.byday !== 'undefined' ) {
              if ( (Object.prototype.toString.call(rrule.byday) !== '[object Array]') ) {
                throw new Error('Recurrence rrule \'byday\' must be an array');
              }

              if ( rrule.byday.length > 7 ) {
                throw new Error('Recurrence rrule \'byday\' array must not be longer than the 7 days in a week');
              }

              // Filter any possible repeats
              rrule.byday = rrule.byday.filter((elem, pos) => rrule.byday.indexOf(elem) === pos);

              for ( const d in rrule.byday ) {
                if ( BYDAY_VALUES.indexOf(rrule.byday[d]) < 0 ) {
                  throw new Error('Recurrence rrule \'byday\' values must include only the following: \'SU\', \'MO\', \'TU\', \'WE\', \'TH\', \'FR\', \'SA\'');
                }
              }
            }
          }
        }

        // TODO add time and time zone? use moment to format?
        const start_date = new Date(begin);
        const end_date = new Date(stop);
        const now_date = new Date();

        const start_year = ('0000' + (start_date.getFullYear().toString())).slice(-4);
        const start_month = ('00' + ((start_date.getMonth() + 1).toString())).slice(-2);
        const start_day = ('00' + ((start_date.getDate()).toString())).slice(-2);
        const start_hours = ('00' + (start_date.getHours().toString())).slice(-2);
        const start_minutes = ('00' + (start_date.getMinutes().toString())).slice(-2);
        const start_seconds = ('00' + (start_date.getSeconds().toString())).slice(-2);

        const end_year = ('0000' + (end_date.getFullYear().toString())).slice(-4);
        const end_month = ('00' + ((end_date.getMonth() + 1).toString())).slice(-2);
        const end_day = ('00' + ((end_date.getDate()).toString())).slice(-2);
        const end_hours = ('00' + (end_date.getHours().toString())).slice(-2);
        const end_minutes = ('00' + (end_date.getMinutes().toString())).slice(-2);
        const end_seconds = ('00' + (end_date.getSeconds().toString())).slice(-2);

        const now_year = ('0000' + (now_date.getFullYear().toString())).slice(-4);
        const now_month = ('00' + ((now_date.getMonth() + 1).toString())).slice(-2);
        const now_day = ('00' + ((now_date.getDate()).toString())).slice(-2);
        const now_hours = ('00' + (now_date.getHours().toString())).slice(-2);
        const now_minutes = ('00' + (now_date.getMinutes().toString())).slice(-2);
        const now_seconds = ('00' + (now_date.getSeconds().toString())).slice(-2);

        // Since some calendars don't add 0 second events, we need to remove time if there is none...
        let start_time = '';
        let end_time = '';
        if ( parseInt(start_hours + start_minutes + start_seconds + end_hours + end_minutes + end_seconds, 10) !== 0 ) {
          start_time = 'T' + start_hours + start_minutes + start_seconds;
          end_time = 'T' + end_hours + end_minutes + end_seconds;
        }
        const now_time = 'T' + now_hours + now_minutes + now_seconds;

        const start = start_year + start_month + start_day + start_time;
        const end = end_year + end_month + end_day + end_time;
        const now = now_year + now_month + now_day + now_time;

        // recurrence rrule vars
        let rruleString;
        if ( rrule ) {
          if ( rrule.rrule ) {
            rruleString = rrule.rrule;
          } else {
            rruleString = 'rrule:FREQ=' + rrule.freq;

            if ( rrule.until ) {
              let rRuleUntil;
              if ( rrule.until instanceof Date ) {
                rRuleUntil = rrule.until;
              } else {
                rRuleUntil = new Date(Date.parse(rrule.until));
              }
              const uDate = rRuleUntil.toISOString();
              rruleString += ';UNTIL=' + uDate.substring(0, uDate.length - 13).replace(/[-]/g, '') + '000000Z';
            }

            if ( rrule.interval ) {
              rruleString += ';INTERVAL=' + rrule.interval;
            }

            if ( rrule.count ) {
              rruleString += ';COUNT=' + rrule.count;
            }

            if ( rrule.byday && rrule.byday.length > 0 ) {
              rruleString += ';BYDAY=' + rrule.byday.join(',');
            }
          }
        }
        /*rag edit -> adding x-alt-desc*/
        const calendarEvent = [
          'BEGIN:VEVENT',
          'UID:' + calendarEvents.length + '@' + uidDomain,
          'CLASS:PUBLIC',
          'DESCRIPTION:' + description,
          /* *********************** rag edit start **************************** */
          'X-ALT-DESC;FMTTYPE=text/html:' + wrappedHtmlDescription,
          'TZID:Europe/Berlin',
          /* *********************** rag edit end ****************************** */
          'DTSTAMP;VALUE=DATE-TIME:' + now,
          'DTSTART;VALUE=DATE-TIME:' + start,
          'DTEND;VALUE=DATE-TIME:' + end,
          'LOCATION:' + location,
          'SUMMARY;LANGUAGE=en-us:' + subject,
          'TRANSP:TRANSPARENT',
          'END:VEVENT',
        ];

        if ( rruleString ) {
          calendarEvent.splice(4, 0, rruleString);
        }

        const resultCalendarEvent = calendarEvent.join(SEPARATOR);

        calendarEvents.push(resultCalendarEvent);
        return resultCalendarEvent;
      },

      /**
       * Build and return the ical contents
       */
      build: () => {
        if ( calendarEvents.length < 1 ) {
          return false;
        }

        return calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd;
      },
    };
  };

}
