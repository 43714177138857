<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@register_title">Register new account</span>

    <button
      *ngIf="getHintTooltip() as tooltip"
      #matTooltip="matTooltip"
      (click)="matTooltip.toggle()"
      mat-icon-button
      [matTooltip]="tooltip"
      matTooltipClass="wider-tooltip"
      type="button">
      <mat-icon svgIcon="information-outline"></mat-icon>
    </button>
  </div>
</rag-page-header>

<ng-template #tplRegistrationForm>

  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">

    <rag-profile-section-wrapper
      [sections]="sections"
      [countBeforeGrouping]="0"
    ></rag-profile-section-wrapper>

    <div class="agb">
      <mat-checkbox
        *ngIf="hasTermsAndConditions()"
        formControlName="acceptTAC"
        class="checkbox-agb"
        required
        i18n="@@register_terms_and_conditions"
      >
        I have read the&nbsp;<a href="{{info.privacyUrl}}" target="_blank">privacy statement</a>&nbsp;
        and the&nbsp;<a href="{{info.agreementUrl}}" target="_blank">general terms and conditions</a>&nbsp;
        and accept the terms therein.
      </mat-checkbox>
    </div>

    <div
      *ngIf="formGroup.invalid"
      class="notice"
    >
      <span i18n="@@contact_requred_fields">The fields marked with * are mandatory and must be completed.</span>
    </div>

    <div class="actions">

      <button
        type="button"
        (click)="onCancel()"
        class="rag-stroked-button"
        mat-stroked-button
      >
        <span i18n="@@global_cancel">Cancel</span>
      </button>

      <button
        [disabled]="formGroup.pristine || formGroup.invalid"
        (click)="onSubmit($event)"
        color="primary"
        mat-flat-button
      >
        <span i18n="@@global_submit">Submit</span>
      </button>

    </div>

  </form>

</ng-template>

<ng-template #tplLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplRequestSent>
  <p i18n="Description text after the register form has been sent@@register_request_sent">Your request has been sent
    successfully.</p>
  <p i18n="@@register_request_sent_you_will_get_mail">A message with a link to activate your account will be sent to
    the
    email address that you have provided.</p>

  <div class="actions">
    <button
      type="button"
      mat-button
      routerLink="/home"
      color="primary"
      mat-flat-button
    >
      <span i18n="@@home">Home</span>
    </button>
  </div>
</ng-template>

<ng-container
  [ngTemplateOutlet]="getTemplate(tplLoading, tplRegistrationForm, tplRequestSent)"
></ng-container>
