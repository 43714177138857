<rag-dialog-header>
  <span>{{news.headline | localeText}}</span>
</rag-dialog-header>

<div class="container" mat-dialog-content>
  <div [innerHTML]="news.text | localeText | safe:'html'" class="news_container"></div>
  <div class="footer">
    <rag-attachments *ngIf="hasAttachments()" [attachments]="news.attachments"></rag-attachments>
  </div>
</div>

<!-- <div mat-dialog-actions>
  <button [mat-dialog-close] class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_close">Close</span>
  </button>
</div> -->
