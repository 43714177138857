import { OfflineContent } from '../../../../../../core/admin-offline.types';
import { EventParticipantsRow } from '../../../../../../core/virtual-room-attendance.types';
import { AdminOfflineEventParticipantsRow } from './event-participants.columns';
import { CtrlSingleUserDetailsOffline } from '../../../../../ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-offline/ctrl-single-user-details-offline.types';
import { FormatDurationPipe } from 'src/app/core/format-duration.pipe';

export class EventParticipantsHelper {

  static asTableRows(participants: OfflineContent.Participant[] | null): EventParticipantsRow[] {

    return (participants ?? [])
      .map(participant => ({
        ...participant,
        transactionStatus: EventParticipantsHelper.getTransactionStatus(participant),
      }));
  }

  static getAttendanceDurationForUser(
    attendanceDurationInMinutes: number,
    attendanceDurationPercentage: number,
  ): {
    attendanceDuration: string;
    attendanceDurationPercentage: number;
  } {

    if ( attendanceDurationInMinutes < 0 ) {
      // found no attendance for user
      return {
        attendanceDuration: $localize`:@@global_not_present:Not present`,
        attendanceDurationPercentage: 0,
      };
    } else if ( attendanceDurationInMinutes === 0 ) {
      return {
        attendanceDuration: $localize`:@@vroom_visited:Has entered the room`,
        attendanceDurationPercentage: 0,
      };
    }

    let timeString: string;
    timeString = new FormatDurationPipe().transform(String(attendanceDurationInMinutes));

    timeString += ` (${attendanceDurationPercentage}%)`;
    return {
      attendanceDuration: timeString,
      attendanceDurationPercentage,
    };
  }

  static getAttendanceDurationForUserWithoutPercentage(
    attendanceDurationInMinutes: number,
  ): string {

    if (attendanceDurationInMinutes == null) {
      return;
    }

    if ( !(attendanceDurationInMinutes > 0) ) {
      // found no attendance for user
      return $localize`:@@global_not_present:Not present`;
    }

    return new FormatDurationPipe().transform(String(attendanceDurationInMinutes));
  }


  public static getTransactionStatus(participant: {
    merchantTransactionType: OfflineContent.MerchantTransactionType,
    merchantStatus: OfflineContent.MerchantStatus
  }): string {

    switch ( participant.merchantTransactionType ) {

      case OfflineContent.MerchantTransactionType.checkout:
        switch ( participant.merchantStatus ) {

          case OfflineContent.MerchantStatus.success:
            return 'payment_successful';

          case OfflineContent.MerchantStatus.declined:
          case OfflineContent.MerchantStatus.canceled:
          case OfflineContent.MerchantStatus.error:
            return 'payment_failed';

          case OfflineContent.MerchantStatus.pending:
          case OfflineContent.MerchantStatus.authorization_pending:
          case OfflineContent.MerchantStatus.processing:
            return 'payment_pending';
        }
        break;

      case OfflineContent.MerchantTransactionType.refund:
        switch ( participant.merchantStatus ) {

          case OfflineContent.MerchantStatus.success:
            return 'refund_successful';

          case OfflineContent.MerchantStatus.declined:
          case OfflineContent.MerchantStatus.canceled:
          case OfflineContent.MerchantStatus.error:
            return 'refund_failed';

          case OfflineContent.MerchantStatus.pending:
          case OfflineContent.MerchantStatus.authorization_pending:
          case OfflineContent.MerchantStatus.processing:
            return 'refund_pending';
        }
        break;

      default:
        return 'no_payment_information';
    }
  }

  public static getTransactionStatusLabel(participant: AdminOfflineEventParticipantsRow): string {
    const transactionStatus = this.getTransactionStatus(participant);

    switch(transactionStatus) {
      case 'payment_successful':
        return $localize`:@@global_successfull_payed:Payment successful`;
      case 'payment_pending':
        return $localize`:@@global_payment_pending:Payment pending`;
      case 'payment_failed':
        return $localize`:@@global_payment_failed:Payment failed`;
      case 'refund_successful':
        return $localize`:@@global_refund_successful:Refund successful`;
      case 'refund_pending':
        return $localize`:@@global_refund_pending:Refund pending`;
      case 'refund_failed':
        return $localize`:@@global_refund_failed:Refund failed`;
      case 'no_payment_information':
        return $localize`:@@event_no_payment_information:No payment information`;
    }
  }

  static getInvitationStatusLabel(entry: AdminOfflineEventParticipantsRow) {
    switch ( entry?.invitationStatus ?? 'reg' ) {
      case 'ack':
        return $localize`:@@admin_offline_event_invitation_status_ack:Participant confirmed`;
      case 'can':
        return $localize`:@@admin_offline_event_invitation_status_can:Event cancellation sent`;
      case 'dec':
        return $localize`:@@admin_offline_event_invitation_status_dec:Participant declined`;
      case 'inv':
        return $localize`:@@admin_offline_event_invitation_status_inv:Invitation sent`;
      case 'frontend':
      case 'link':
        return '—';
      case 'rem':
        return $localize`:@@admin_offline_event_invitation_status_rem:Reminder sent`;
      case 'reg': {
        const participationStatusRegistered = OfflineContent
          .offlineParticipationStatusFactory(entry?.participationStatus ?? '');
        if ( participationStatusRegistered === OfflineContent.OfflineParticipationStatus.DESIRED ) {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered` +
            '(' + $localize`:@@admin_offline_event_participation_status_desired:Desired` + ')';
        } else {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered`;
        }
      }
      default: {
        const participationStatus = OfflineContent
          .offlineParticipationStatusFactory(entry?.invitationStatus ?? '');
        if ( participationStatus === OfflineContent.OfflineParticipationStatus.DESIRED ) {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered` +
            '(' + $localize`:@@admin_offline_event_participation_status_desired:Desired` + ')';
        } else {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered`;
        }
      }
    }
  }

  static getInvitationStatusLabelForCtrl(entry: CtrlSingleUserDetailsOffline.RowData) {
    switch ( entry?.eventScheduleInvitationStatus ?? 'reg' ) {
      case 'ack':
        return $localize`:@@admin_offline_event_invitation_status_ack:Participant confirmed`;
      case 'can':
        return $localize`:@@admin_offline_event_invitation_status_can:Event cancellation sent`;
      case 'dec':
        return $localize`:@@admin_offline_event_invitation_status_dec:Participant declined`;
      case 'inv':
        return $localize`:@@admin_offline_event_invitation_status_inv:Invitation sent`;
      case 'frontend':
      case 'link':
        return '—';
      case 'rem':
        return $localize`:@@admin_offline_event_invitation_status_rem:Reminder sent`;
      case 'reg': {
        const participationStatusRegistered = OfflineContent
          .offlineParticipationStatusFactory(entry?.offlineEventStatus);
        if ( participationStatusRegistered === OfflineContent.OfflineParticipationStatus.DESIRED ) {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered` +
            '(' + $localize`:@@admin_offline_event_participation_status_desired:Desired` + ')';
        } else {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered`;
        }
      }
      default: {
        const participationStatus = OfflineContent
          .offlineParticipationStatusFactory(entry?.eventScheduleInvitationStatus ?? '');
        if ( participationStatus === OfflineContent.OfflineParticipationStatus.DESIRED ) {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered` +
            '(' + $localize`:@@admin_offline_event_participation_status_desired:Desired` + ')';
        } else {
          return $localize`:@@admin_offline_event_invitation_status_registered:Registered`;
        }
      }
    }
  }
}
