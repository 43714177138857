import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostLoginComponent } from './post-login.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PostLoginPasswordModule } from './post-login-password/post-login-password.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PostLoginUserDataModule } from './post-login-user-data/post-login-user-data.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ProfileSectionModule } from '../../../component/input/profile-section/profile-section.module';


@NgModule({
  declarations: [ PostLoginComponent ],
  imports: [
    CommonModule,
    MatIconModule,
    MatStepperModule,
    PostLoginPasswordModule,
    ReactiveFormsModule,
    PostLoginUserDataModule,
    ProfileSectionModule,
    MatButtonModule,
    RouterModule,
  ],
})
export class PostLoginModule {
}
