import { Color } from 'chart.js';

export interface DoughnutChartOptions {
  showLegend?: false | 'bottom' | 'right';
}

export interface DoughnutChartDataSet {
  color: Color;
  label: string;
  value: number;
}

export const DEFAULT_OPTIONS: DoughnutChartOptions = {
  showLegend: 'bottom',
};
