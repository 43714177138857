import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentAutocompleteModule } from '../elearning/content-autocomplete/content-autocomplete.module';
import { HeaderComponent } from './header.component';
import { HeaderNavTopModule } from './header-nav-top/header-nav-top.module';
import { HeaderNavMainModule } from './header-nav-main/header-nav-main.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from '../../core/pipes/pipes.module';

@NgModule({
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ],
  imports: [
    CommonModule,
    ContentAutocompleteModule,
    HeaderNavTopModule,
    HeaderLogoComponent,
    HeaderNavMainModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    PipesModule
  ],
})
export class HeaderModule {
}
