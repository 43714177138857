import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ImportantContentInfoComponent } from './important-content-info.component';
import { CurriculumLockedByModule } from '../curriculum-locked-by/curriculum-locked-by.module';
import { QuestButtonComponent } from '../../quest-button/quest-button.component';


@NgModule({
  declarations: [
    ImportantContentInfoComponent,
  ],
  exports: [
    ImportantContentInfoComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    PipesModule,
    RouterModule,
    MatButtonModule,
    CurriculumLockedByModule,
    QuestButtonComponent
  ],
})
export class ImportantContentInfoModule {
}
