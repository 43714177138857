import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminConfigurationComponent } from './admin-configuration.component';
import { RouterLinkActive, RouterOutlet, RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    AdminConfigurationComponent
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    RouterLinkActive,
    MatTabsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    MatIconModule,
  ],
})
export class AdminConfigurationModule { }
