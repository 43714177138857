import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { SubscriptionHolder } from '../../core/reactive/subscription-holder';
import { TableColumnDataType, TableControllerTypes } from '../table/table-controller/table-controller.types';
import { FilterOperator } from '../../core/column-settings/column-filter.types';
import { QueryParamsService } from '../../core/storage/query-params.service';
import { debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { FilterContext } from '../../core/column-settings/filter-api.types';
import { FilterApiService } from '../../core/column-settings/filter-api.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { ContentFilterHelper } from './content-filter.helper';
import { AnyObject } from '../../core/core.types';


@Component({
  selector: 'rag-content-filter',
  templateUrl: './content-filter.component.html',
  styleUrls: [ './content-filter.component.scss' ],
})
export class ContentFilterComponent<T = any>
  implements OnChanges, OnDestroy {

  @Input() context: FilterContext;
  @Output() readonly filtersChanged: Observable<TableControllerTypes.ColumnOptions<T>[]>;
  hasAsyncFilters = false;
  showAsyncFilters = false;
  readonly showMoreDisabled$: Observable<boolean>;
  private _filters$ = new SubscriptionHolder<TableControllerTypes.ColumnOptions<T>[]>(this);
  private _filtersChanged = new EventEmitter<TableControllerTypes.ColumnOptions<T>[]>(true);
  private _updateQueryParamsTrigger = new EventEmitter<void>(true);

  constructor(
    private filterApiService: FilterApiService,
    private queryParamsService: QueryParamsService,
  ) {
    this.filtersChanged = this._filtersChanged.asObservable();
    this.showMoreDisabled$ = this.observeShowMoreDisabled();

    this.observeQueryParams();
  }

  @Input()
  set filters(value: TableControllerTypes.ColumnOptions<T>[]) {
    this._filters$.value = value;
    this._updateQueryParamsTrigger.emit();
  }

  get filters$(): Observable<TableControllerTypes.ColumnOptions<T>[]> {
    return this._filters$.value$;
  }

  @Input()
  set filters$(value: Observable<TableControllerTypes.ColumnOptions<T>[]>) {
    this._filters$.observable = value;
    this._updateQueryParamsTrigger.emit();
  }

  /**
   * hard cast to allow setting filter attribute without errors
   * @deprecated todo switch all other components from string to FilterOperator
   */
  asFilter(data: any): TableControllerTypes.ColumnOptions<T, string, FilterOperator> {
    return data;
  }

  filterVisible(options: TableControllerTypes.ColumnOptions<T>): boolean {
    if ( options.filterHidden === true ) {
      // filter hidden by flag
      return false;
    }

    if ( this.showAsyncFilters ) {
      // no further checks required
      return true;
    }

    if ( options.filterMethodAsync == null ) {
      // any non-async filter should be displayed immediately
      return true;
    }

    // show any active filter
    return !!(options.filter?.value ?? '');
  }

  getFilterClass(
    options: TableControllerTypes.ColumnOptions<T>,
  ): AnyObject<boolean> {

    const result: AnyObject<boolean> = {};
    const type = options?.dataType ?? '';
    if ( type ) {
      result[type] = true;
    }
    return result;
  }

  getTemplate(
    options: TableControllerTypes.ColumnOptions<T>,
    tplDate: TemplateRef<any>,
    tplNumber: TemplateRef<any>,
    tplPrice: TemplateRef<any>,
    tplText: TemplateRef<any>,
    tplDropdown: TemplateRef<any>,
    tplTags: TemplateRef<any>,
  ): TemplateRef<any> {

    switch ( options?.dataType ?? '' ) {

      case TableColumnDataType.date:
      case TableColumnDataType.dateTime:
        return tplDate;

      case TableColumnDataType.dropdown:
        return tplDropdown;

      case TableColumnDataType.number:
        return tplNumber;

      case TableColumnDataType.price:
        return tplPrice;

      case TableColumnDataType.multiselect:
      case TableColumnDataType.tags:
        return tplTags;

      default:
        return tplText;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( Object.prototype.hasOwnProperty.call(changes, 'context') ) {
      this.loadFilterResults();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onFilterChange(): void {
    this._filtersChanged.next(this._filters$.value);
  }

  toggleAsyncFilters(): void {
    this.showAsyncFilters = !this.showAsyncFilters;
  }

  /**
   * asynchronously inject filter results into {@link TableControllerTypes.ColumnOptions.dropDownOptions}
   */
  private loadFilterResults(): void {
    this.hasAsyncFilters = false;

    const queryFilterResults = (this.context == null) ?
      // no context defined -> skip loading
      of(null) :
      // load the results for any async filters
      this.filterApiService.getFilterResults(this.context);

    combineLatest([
      this._filters$.value$,
      this.queryParamsService.queryParams$,
      queryFilterResults,
    ])

      // merge results into filter options
      .pipe(map(([ filters, queryParams, filterResults ]) => {

        // preserve the current filter state
        ContentFilterHelper.addQueryParams(filters, queryParams?.queryParams);

        // and then add the (optional) filter results
        return ContentFilterHelper.addResults(filters, filterResults);
      }))

      // notify external observers if filter values have changed
      .pipe(tap(filters => {

        // check if there are any async filters - with results - included
        this.hasAsyncFilters = this.showAsyncFilters = (filters ?? [])
            // filter is async and not hidden
            .find(entry => (entry.filterMethodAsync != null) && (entry.filterHidden !== true))
          != null;

        if ( filters != null ) {
          this._filtersChanged.next(filters);
        }
      }))

      .pipe(take(1))
      .subscribe();
  }

  private observeQueryParams() {
    // check if query params have changed
    combineLatest([
      this.queryParamsService.queryParams$,
      // load data from url after reload, etc. -> triggered after _filters$ has changed
      this._updateQueryParamsTrigger.pipe(startWith(void (0))),
    ])
      .pipe(map(([queryParams]) => queryParams))

      // skip changes until the filters are set -> initialized by {@link ContentFilterComponent.loadFilterResults}
      .pipe(filter(() => this._filters$.value != null))

      // only include changes from routing
      .pipe(filter(queryParams => queryParams.mode === 'route'))

      // force async handling
      .pipe(debounceTime(10))

      // fetch or wait for the latest filters
      .pipe(switchMap(queryParams => forkJoin([
        this._filters$.value$.pipe(take(1)),
        of(queryParams.queryParams),
      ])))

      // update the filter values
      .pipe(map(([ filters, queryParams ]) => ContentFilterHelper.addQueryParams(filters, queryParams)))

      // notify external observers if filter values have changed
      .pipe(tap(filters => {
        if ( filters != null ) {
          this._filtersChanged.next(filters);
        }
      }))

      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private observeShowMoreDisabled() {
    return combineLatest([
      this.filters$,
      this.filtersChanged.pipe(startWith(true)),
    ])
      .pipe(map(([ filters ]) => filters
          // filter by async with value
          .find(entry => (entry.filterMethodAsync != null) && !(entry.filter?.value))
        // if there is no filter that can be hidden, disable the "show more"-button
        == null));
  }

}
