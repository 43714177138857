import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContentService } from '../../../core/content/content.service';
import { Content, UserContentActionEnum } from '../../../core/content/content.types';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { ViewHelper } from '../../../core/view-helper';
import { TableHelper } from '../../table/table-helper';
import { ObjectHelper } from '../../../core/object.helper';
import { Core, ImageableContentReference } from 'src/app/core/core.types';
import { InfoService } from 'src/app/core/info/info.service';
import { ItemListReadConfirmDialogComponent } from './item-list-read-confirm-dialog/item-list-read-confirm-dialog.component';
import { map, switchMap } from 'rxjs/operators';
import { ItemListReadConfirmDialogData, ItemListReadConfirmDialogResult } from './item-list.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { Quests } from 'src/app/core/quest/quest.types';

const ColumnsWithOrderIndex: string[] =
  [ 'actions', 'index', 'displaystatus', 'title', 'duration', 'importantInfo', 'details' ];

const ColumnsWithoutOrderIndex: string[] =
  [ 'actions', 'displaystatus', 'title', 'duration', 'importantInfo', 'details' ];

@Component({
  selector: 'rag-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: [ './item-list.component.scss' ],
})
export class ItemListComponent
  implements OnInit {

  /**
   * Warning: this attribute must be set before the curriculum!
   */
  @Input() highlightItemId: number;
  @Input() account: ImageableContentReference[];
  @Input() quest2content: Quests.Quest2ContentResponse;
  @Input() parent: ImageableContentReference;
  @Input() showHeaders = true;

  dataSource = new MatTableDataSource<ImageableContentReference>();
  displayedColumns: string[] = ColumnsWithOrderIndex;
  @ViewChild(MatSort, { static: true })
  matSort: MatSort;

  protected _curriculum: ImageableContentReference;
  private _sort: MatSort;

  constructor(
    private contentService: ContentService,
    private navigationService: NavigationService,
    private infoService: InfoService
  ) {
  }

  get curriculum(): ImageableContentReference {
    return this._curriculum;
  }

  @Input()
  set curriculum(value: ImageableContentReference) {
    if ( this._curriculum === value ) {
      return;
    }

    this._curriculum = value;
    window['curriculum'] = value;

    if ( this.showHeaders ) {
      this.displayedColumns = ColumnsWithOrderIndex;
    } else {
      this.displayedColumns = ColumnsWithoutOrderIndex;
    }

    this.dataSource.data = this.prepareViewData(value);

    if ( this.highlightItemId > 0 ) {
      this.highlightItem(ContentService.getContentById(this.dataSource.data, this.highlightItemId));
    }
  }

  get sort(): MatSort {
    return this._sort;
  }

  @Input()
  set sort(value: MatSort) {
    this._sort = value;
    if ( value && this.dataSource.sort !== this._sort ) {
      this.dataSource.sort = this._sort;
    }
  }

  // true when this item has at least 2 items to be listed, otherwise false
  static hasContent(item: Content): boolean {
    return (item.items && (item.items.length > 1)) ||
      (item.objType === Core.DistributableType.lms_curriculum);
  }

  findContent(obj: ImageableContentReference): ImageableContentReference {
    if ( !obj ) {
      return null;
    }

    let content: ImageableContentReference;
    const id = obj.id;
    if ( this.account && this.account.length ) {
      content = this.account.find(c => c.id === id);
      if ( content ) {
        return content;
      }
    }

    const items = this.curriculum && this.curriculum.items;
    if ( items && items.length ) {
      content = items.find(c => c.id === id);
      if ( content ) {
        return content;
      }
    }
    return null;
  }

  hasSubTypeText(item: Content) {
    return '' !== DistributionTypeHelper.asText(item.objType, item.objSubType);
  }

  hasAction(content: Content): boolean {
    const viewData = ViewHelper.getViewData(content);
    if ( !content || viewData.hasContent ) {
      return false;
    } else if ( viewData.executableItem || viewData.executableItemError ) {
      return true;
    } else if ( content.objType === Core.DistributableType.lms_context ) {
      return true;
    }
    return false;
  }

  hasDescription(content: Content): boolean {
    const description = (content?.description ?? '').trim();
    return (description !== '') && (description !== 'n/a');
  }

  hasIndexPrefix(content: Content) {
    const view$ = ViewHelper.getViewData(content);
    return view$.indexPrefix != null;
  }

  isExpanded(content: Content) {
    const view$ = ViewHelper.getViewData(content);
    return view$.detailsExpanded || view$.indexPrefix;
  }

  isHighlighted(content: Content) {
    return ViewHelper.getViewData(content)?.highlight === true;
  }

  ngOnInit() {
    if ( this.showHeaders && this._sort == null ) {
      // create new sort for root
      this._sort = this.matSort;
    }
    this.dataSource.sort = this._sort;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => TableHelper.sortingDataAccessor(data, sortHeaderId);
  }

  onReadDocumentAcknowledge($event: ImageableContentReference) {
    this.contentService.fetchCourseInfo($event.id)
      .pipe(switchMap(courseInfo =>
        this.infoService.showDialog<ItemListReadConfirmDialogComponent, ItemListReadConfirmDialogData, ItemListReadConfirmDialogResult>(ItemListReadConfirmDialogComponent, {
          content: courseInfo,
          hasSupervisor: $event?.hasSupervisor,
        })
          .pipe(map(result => {
            if (result == null || !result.confirm) {
              // close button
              return;
            }

            const action = result.text != null ? UserContentActionEnum.NEED_CLARIFICATION : UserContentActionEnum.READ_ACKNOWLEDGE;

            this.contentService.handleUserContentAction({
              objId: $event.id,
              objType: $event.objType,
              action,
              text: result.text
            });
          }))
      ))
    .subscribe();
  }

  onToggleExpanded($event: boolean, content: ImageableContentReference) {
    const $view = ViewHelper.getViewData(content);

    const currentIndex = (this.dataSource.data ?? []).findIndex((c) => c.id === content.id);
    if ( currentIndex === -1 ) {
      throw Error('found null while looking for currentIndex');
    }

    if ( $event ) {
      // toggle on
      const indexPrefix = $view.indexPrefix || '';
      this.prepareViewData($view.content, `${indexPrefix}${$view.index}.`);
      this.dataSource.data = [
        ...this.dataSource.data.slice(0, currentIndex + 1),
        ...$view.content.items ?? [],
        ...this.dataSource.data.slice(currentIndex + 1),
      ];

    } else {

      // toggle off
      const countToHide = (_content: ImageableContentReference) => {
        if ( _content.objType === Core.DistributableType.lms_curriculum ) {
          const view$ = ViewHelper.getViewData(_content);
          if ( view$.detailsExpanded && _content.items?.length > 0 ) {
            const initCount = _content.id !== content.id ? 1 : 0;
            view$.detailsExpanded = false;
            return _content.items.reduce((pV, childItem) => pV + countToHide(childItem), initCount);
          }
        }
        return 1;
      };
      const itemsToRemoveCount = countToHide(content);
      this.dataSource.data.splice(currentIndex + 1, itemsToRemoveCount);
      this.dataSource.data = this.dataSource.data;
    }

    $view.detailsExpanded = $event;
  }

  reloadData() {
    setTimeout(this.navigationService.navigateCurrent);
  }

  private highlightItem(highlightItem: ImageableContentReference): void {
    if ( !(highlightItem?.id > 0) ) {
      // no item found -> skip highlighting and remove highlightItemId
      this.highlightItemId = null;
      return;
    }

    const parents = ObjectHelper
      .recurseToList<Content>(highlightItem, content => ViewHelper.getViewData(content)?.parent);

    parents.reverse()
      .map(parent => parent.id)
      .forEach(parentId => {
        const parentRow = this.dataSource.data?.find(row => row.id === parentId);
        if ( parentRow != null ) {
          this.onToggleExpanded(true, parentRow);
        }
      });

    const highlightItemViewData = ViewHelper.getViewData(highlightItem);
    // add highlight after 1.5 seconds
    setTimeout(() => highlightItemViewData.highlight = true, 1500);
    // remove highlight after 5 seconds
    setTimeout(() => highlightItemViewData.highlight = false, 5000);
  }

  private prepareViewData(content: ImageableContentReference, indexPrefix?: string) {
    const itemsData = content?.items?.slice();
    if ( !(itemsData?.length > 0) ) {
      this.dataSource.data = [];
      return;
    }

    if (content.calculatedDuration === undefined && (content.duration != null && content.duration !== '')) {
      const minutes = parseInt(content.duration, 10);
      if (!isNaN(minutes)) {
        content.durationSum = minutes;
        ContentService.calculateDuration(content);
      }
    }

    itemsData.forEach(item => {
      const $view = ViewHelper.getViewData(item);

      $view.parent = content;
      $view.hasContent = ItemListComponent.hasContent(item);
      if ( $view.hasContent ) {
        $view.content = this.findContent(item);
      }

      const index = item.index;
      if ( index === 0 || index > 0 ) {
        $view.index = index + 1;
      }

      $view.indexPrefix = indexPrefix;

      if ( !$view.hasContent ) {
        this.contentService.searchFirstExecutableItem(itemsData, content, item);
      }
      this.contentService.getDuration(item);
      ContentService.calculateDuration(item);

      // hack! I do not like it :(
      if ((item.calculatedDuration === undefined || item.calculatedDuration === '') &&
        itemsData.length === 1) {
          item.calculatedDuration = content.calculatedDuration;
      }
    });

    return itemsData;
  }

}
