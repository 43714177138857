<div class="design-wrapper">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n="@@report_gen_admin_configure_columns_tab">Configure Columns</span>
      </ng-template>
      <rag-column-conf
        *ngIf="(columnSettings$ | async) as columnSettings"
        [columnSettings]="columnSettings"
        [targetType]="targetType"
      ></rag-column-conf>
    </mat-tab>
  </mat-tab-group>
</div>
