import { OfflineContent } from 'src/app/core/admin-offline.types';

export class ContentHelper {

  public static getTypeOfTrainingLabel(type: string | null): string | null {
    switch ( type ?? '' ) {
      case 'presence':
        return $localize`:@@controlling.curriculum.contextItem.typeoftraining.presence:Presence`;
      case 'online':
        return $localize`:@@controlling.curriculum.contextItem.typeoftraining.online:Online`;
      case 'others':
        return $localize`:@@controlling.curriculum.contextItem.typeoftraining.others:others`;
      case 'document':
        return $localize`:@@global.alt.offlineContent.type.document:Document(Download)`;
      case 'talk':
        return $localize`:@@global.alt.offlineContent.type.talk:Dialog`;
      case 'seminar':
        return $localize`:@@global.alt.offlineContent.type.seminar:On-site event`;
      case 'sbs':
        return $localize`:@@global.alt.offlineContent.type.sbs:Side-by-Side`;
      case 'virco':
        return $localize`:@@global.alt.offlineContent.type.virco:Virtual event`;
      case 'hybrid':
        return $localize`:@@global_hybrid_event:Hybrid event`;
      default:
        return null;
    }
  }

  public static getTypeOfEvent(eventType: OfflineContent.EventType) {
    switch (eventType) {
      case OfflineContent.EventType.seminar:
        return $localize`:@@global.alt.offlineContent.type.seminar:On-site event`;
      case OfflineContent.EventType.virco:
        return $localize`:@@global.alt.offlineContent.type.virco:Virtual event`;
      case OfflineContent.EventType.hybrid:
        return $localize`:@@global_hybrid_event:Hybrid event`;
      case OfflineContent.EventType.document:
        return $localize`:@@global.alt.offlineContent.type.document:Document(Download)`;
        default:
          console.warn('unsupported event type ' + eventType);
    }
    return '';
  }

}
