import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { CatalogBookingHelper } from 'src/app/core/catalog/catalog-booking.helper';
import { CatalogHelper } from 'src/app/core/catalog/catalog.helpers';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { Distributable } from 'src/app/core/core.types';
import { UrlHelper } from 'src/app/core/url.helper';

@Component({
  selector: 'rag-my-content-actions',
  templateUrl: './my-content-actions.component.html',
  styleUrls: ['./my-content-actions.component.scss']
})
export class MyContentActionsComponent implements OnInit {

  @Input() inputDisabled: boolean;
  @Input() horizontal = true;
  @Input() detailsEnabled = true;
  @Input() targetObject: Catalogs.Bookable;
  @Output() canceled: EventEmitter<Catalogs.Bookable>;

  constructor(
    private catalogBookingHelper: CatalogBookingHelper,
    private router: Router,
    private catalogService: CatalogService
  ) {
    this.canceled = new EventEmitter();
  }

  ngOnInit(): void {
  }

  isAvailableForBooking(catEntry: Catalogs.Bookable) {
    return CatalogHelper.isAvailableForBooking(catEntry);
  }

  isBooked(catEntry: Catalogs.Bookable) {
    return CatalogHelper.isBooked(catEntry?.catalogBooking);
  }

  isInMyAccount(catEntry: Catalogs.Bookable) {
    return CatalogHelper.isInMyAccount(catEntry);
  }

  getContentUrl(distObject: Distributable) {
    return UrlHelper.execUrlFor(distObject);
  }

  getQueryParams(urlTree: UrlTree | null): any | null {
    return UrlHelper.getQueryParams(urlTree);
  }

  getRouterLink(urlTree: UrlTree | null): string | null {
    return UrlHelper.getRouterLink(urlTree);
  }

  getDetailsUrl(distObject: Distributable): UrlTree | null {
    const url = this.catalogService.getDetailsUrl(distObject);
    if ( !url ) {
      return null;
    }

    return this.router.parseUrl(url);
  }

  onCancelBooking($event: Event, catEntry: Catalogs.Bookable) {
    $event.stopImmediatePropagation();
    this.catalogBookingHelper.cancel(catEntry)
      .pipe(take(1))
      .pipe(tap(_ => this.canceled.emit()))
      .subscribe();
  }

}
