import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ViewHelper } from '../../../core/view-helper';
import { AssignmentDialogTypes } from '../assignment-dialog.types';
import { CdkFixedSizeVirtualScroll } from '@angular/cdk/scrolling';


type SelectAllState = 'disabled' | 'unchecked' | 'indeterminate' | 'checked';

interface ViewData<T> {
  filter: string;
  filteredData?: AssignmentDialogTypes.AssignmentEntry<T>[];
  isDragging: boolean;
  selectAllState: SelectAllState;
  selection: SelectionModel<AssignmentDialogTypes.AssignmentEntry<T>>;
  unfilteredData?: AssignmentDialogTypes.AssignmentEntry<T>[];
}

@Component({
  selector: 'rag-assignment-dialog-lists',
  templateUrl: './assignment-dialog-lists.component.html',
  styleUrls: [ './assignment-dialog-lists.component.scss' ],
})
export class AssignmentDialogListsComponent<T, C = any>
  implements OnDestroy {

  @Output()
  readonly dataOut: Observable<AssignmentDialogTypes.AssignmentDialogEntries<T>>;
  @Input()
  i18n: AssignmentDialogTypes.AssignmentDialogTranslations;
  isDragDropDisabled = false;
  @Input()
  isInvalid: boolean;
  @Input()
  isMaxSelected: boolean;
  @Input()
  maxSelections: number;
  @Input()
  selectionCount: number;
  @Input()
  allowSortingInContainers = false;
  readonly viewDataSource: ViewData<T> = {
    filter: '',
    isDragging: false,
    selection: new SelectionModel<AssignmentDialogTypes.AssignmentEntry<T>>(true),
    selectAllState: 'unchecked',
  };
  readonly viewDataTarget: ViewData<T> = {
    filter: '',
    isDragging: false,
    selection: new SelectionModel<AssignmentDialogTypes.AssignmentEntry<T>>(true),
    selectAllState: 'unchecked',
  };
  @ViewChild('viewportSource', { read: CdkFixedSizeVirtualScroll })
  viewportSource: CdkFixedSizeVirtualScroll;
  @ViewChild('viewportTarget', { read: CdkFixedSizeVirtualScroll })
  viewportTarget: CdkFixedSizeVirtualScroll;
  private _dataIn: Observable<AssignmentDialogTypes.AssignmentDialogEntries<T>>;
  private _dataInSubscription: Subscription;
  private _dataOut = new EventEmitter<AssignmentDialogTypes.AssignmentDialogEntries<T>>();

  constructor() {
    this.dataOut = this._dataOut.asObservable();
  }

  @Input()
  set dataIn(value: Observable<AssignmentDialogTypes.AssignmentDialogEntries<T>>) {
    const doUpdate = value && (value !== this._dataIn);
    this._dataIn = value;
    if ( !doUpdate ) {
      return;
    }

    this._dataInSubscription?.unsubscribe();
    this._dataInSubscription = value
      .pipe(filter(data => (data != null) && (data.available != null) && (data.selected != null)))
      .pipe(tap(this.setData))
      .subscribe();
  }

  private static cleanEntrySetData<T, C>(entry: AssignmentDialogTypes.AssignmentEntry<T, C>):
    AssignmentDialogTypes.AssignmentEntry<T, C> {
    const viewData = ViewHelper.getViewData(entry);
    viewData.multiSelected = false;
    return entry;
  }

  private static dropFindRealCurrentIndex<T>(to: ViewData<T>, currentIndex: number): number {
    const filteredData = to?.filteredData;
    const filteredDataCount = filteredData?.length;

    if ( filteredDataCount <= 0 ) {
      // if no items are visible place element at the top
      return 0;
    }

    if ( currentIndex <= 0 ) {
      // if dropped at the top, place at the top
      return 0;
    } else if ( currentIndex < filteredDataCount ) {
      // we have a valid index for the item to place after
      const targetItem = filteredData[currentIndex - 1];
      return to.unfilteredData.findIndex(obj => obj === targetItem) + 1;
    } else {
      // move after the last visible item
      const targetItem = filteredData[filteredDataCount - 1];
      return to.unfilteredData.findIndex(obj => obj === targetItem) + 1;
    }
  }

  isButtonMoveDisabled(data: ViewData<T>): boolean {
    const selectAllState = data.selectAllState;
    return !((selectAllState === 'checked') || (selectAllState === 'indeterminate'));
  }

  ngOnDestroy(): void {
    // required for subscribeUntilDestroyed
    this._dataInSubscription?.unsubscribe();
  }

  onDragStarted(data: ViewData<T>, entry: AssignmentDialogTypes.AssignmentEntry<T>) {
    if (entry.disabled) {
      // do not select disabled entry
      return;
    }
    const viewData = ViewHelper.getViewData(entry);
    if ( !viewData.multiSelected ) {
      // list option not selected -> select only this entry
      this.setSingleSelection(data, entry);
    }
  }

  onDrop(from: ViewData<T>, to: ViewData<T>, offset: number,
         $event: CdkDragDrop<AssignmentDialogTypes.AssignmentEntry<T>[], any>): void {
    if ( ($event.previousContainer === $event.container) && !this.allowSortingInContainers ) {
      // do not allow sorting in containers when allowSortingInContainers is false
      return;
    }

    if (($event.previousContainer === $event.container) && this.allowSortingInContainers) {
      // sorting in containers
      moveItemInArray($event.container.data, $event.previousIndex + offset, $event.currentIndex + offset);
      const eventCurrentIndexForSorting = $event.currentIndex + offset;
      const currentIndexForSorting = AssignmentDialogListsComponent.dropFindRealCurrentIndex(to, eventCurrentIndexForSorting);
      this.onMoveSelectedEntries(from, to, currentIndexForSorting);
      return;
    }

    const itemDisabled = $event?.item?.data?.disabled ?? false;
    if (($event.previousContainer !== $event.container) && itemDisabled === true) {
      // not allowed to move disabled item to other container
      return;
    }

    const eventCurrentIndex = $event.currentIndex + offset;
    const currentIndex = AssignmentDialogListsComponent.dropFindRealCurrentIndex(to, eventCurrentIndex);
    this.onMoveSelectedEntries(from, to, currentIndex);
  }

  onFilter(data: ViewData<T>): void {
    setTimeout(() => this.filter(data));
  }

  onFilterReset(data: ViewData<T>): void {
    data.filter = '';
    this.onFilter(data);
  }

  onMoveSelectedEntries(from: ViewData<T>, to: ViewData<T>, toIndex = 0) {
    if ( (from.unfilteredData == null) || (to.unfilteredData == null) ) {
      return;
    }

    // need copies to force repaints
    const fromData = [ ...from.unfilteredData ];
    const toData = [ ...to.unfilteredData ];

    const split = fromData.reduce((pV, entry) => {
      const viewData = ViewHelper.getViewData(entry);
      if ( viewData.multiSelected && viewData.visible !== false ) {
        pV.move.push(entry);
      } else {
        pV.keep.push(entry);
      }
      viewData.multiSelected = false;
      return pV;
    }, { keep: [], move: [] });

    if ( split.move.length > 0 ) {
      fromData.splice.apply(fromData, [ 0, fromData.length, ...split.keep ]);
      // remove all selections from target while keeping moved items selected
      toData.map(ViewHelper.getViewData)
        .forEach(viewData => viewData.multiSelected = false);
      toData.splice.apply(toData, [ toIndex, 0, ...split.move ]);
    }

    setTimeout(() => {
      from.unfilteredData = fromData;
      to.unfilteredData = toData;
      this.onFilter(from);
      this.onFilterReset(to);
      this.emitDataOut();
    });
  }

  onSelectionChanged(data: ViewData<T>) {
    this.updateSelectAllState(data);
  }

  onToggleAll(data: ViewData<T>, $event: MatCheckboxChange): void {
    if ( data?.filteredData == null ) {
      return;
    }

    if ( !(data.filteredData.length > 0) ) {
      data.selectAllState = 'disabled';
      return;
    }

    const multiSelected = $event.checked;
    // update selection status
    data.filteredData
      .filter(entry => !entry.disabled)
      .map(entry => ViewHelper.getViewData(entry))
      .forEach(viewData => viewData.multiSelected = multiSelected);

    data.selectAllState = multiSelected ? 'checked' : 'unchecked';
  }

  private emitDataOut = (): void => {
    const available = this.viewDataSource.unfilteredData;
    const selected = this.viewDataTarget.unfilteredData;
    if ( (available == null) || (selected == null) ) {
      return;
    }

    this._dataOut.emit({
      available: [ ...available ],
      selected: [ ...selected ],
    });
  };

  private filter(data: ViewData<T>): void {
    const entries = data?.unfilteredData;
    if ( entries == null ) {
      return;
    }

    const search = (data.filter == null ? '' : data.filter.trim())
      .toLocaleLowerCase();
    // todo implement quotes to keep words together
    const split = search.split(/\s+/g);

    if ( search.length < 1 ) {
      data.filteredData = entries;
    } else {
      data.filteredData = entries.filter(entry => {
        let visible = false;
        split.forEach(term => {
          if ( !visible && (entry.title.toLocaleLowerCase().indexOf(term) !== -1) ) {
            visible = true;
          }
        });
        return visible;
      });
    }

    data.selection.clear();
    const selections = data.filteredData
      .filter(entry => ViewHelper.getViewData(entry).multiSelected === true);
    data.selection.select(...selections);

    this.updateSelectAllState(data);
  }

  private setData = (data: AssignmentDialogTypes.AssignmentDialogEntries<T, C>): void => {
    this.viewDataSource.unfilteredData = data.available
      .map(AssignmentDialogListsComponent.cleanEntrySetData);
    this.viewDataTarget.unfilteredData = data.selected
      .map(AssignmentDialogListsComponent.cleanEntrySetData);

    this.onFilterReset(this.viewDataSource);
    this.onFilterReset(this.viewDataTarget);
  };

  private setSingleSelection(data: ViewData<T>, entry: AssignmentDialogTypes.AssignmentEntry<T>) {
    const entries = data.filteredData;

    entries
      .forEach(e => ViewHelper.getViewData(e).multiSelected = false);
    ViewHelper.getViewData(entry).multiSelected = true;

    this.updateSelectAllState(data);
  }

  private updateSelectAllState(data: ViewData<T>): void {
    setTimeout(() => this.updateSelectAllStateTimeout(data));
  }

  private updateSelectAllStateCalculate(data: ViewData<T>): SelectAllState {
    const entries = data.filteredData;
    if ( entries == null ) {
      return null;
    }

    const countAll = entries.length;
    const countSelected = entries
      .map(entry => ViewHelper.getViewData(entry)?.multiSelected)
      .filter(selected => selected === true)
      .length;
    if ( !(countAll > 0) ) {
      return 'disabled';
    } else if ( countAll === countSelected ) {
      return 'checked';
    } else if ( countSelected > 0 ) {
      return 'indeterminate';
    } else {
      return 'unchecked';
    }
  }

  private updateSelectAllStateTimeout(data: ViewData<T>): void {
    const state = this.updateSelectAllStateCalculate(data);
    if ( state == null ) {
      // selection lists are still undefined (@ViewChild)
      this.updateSelectAllState(data);
      return;
    }

    let hasUnrequiredFields = false;
    data.filteredData
      .filter(entry => {
        entry.disabled = entry.disabled ?? false;
        return !entry.disabled;
      })
      .map(() => hasUnrequiredFields = true);

    if (!hasUnrequiredFields) {
      data.selectAllState = 'disabled';
    } else {
      data.selectAllState = state;
    }
  }

}
