import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { News } from '../../../../core/news/news.types';

@Component({
  selector: 'rag-news-preview-dialog',
  templateUrl: './news-preview-dialog.component.html',
  styleUrls: [ './news-preview-dialog.component.scss' ],
})
export class NewsPreviewDialogComponent
  implements OnInit {

  news: News;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.news = data.news;
  }

  hasAttachments() {
    return this.news.attachments && this.news.attachments.length > 0;
  }

  ngOnInit() {
  }
}
