import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PostLoginComponent } from './post-login.component';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from '../../../core/principal/principal.service';


@Injectable({ providedIn: 'root' })
export class PostLoginGuard
  implements CanActivate, CanDeactivate<PostLoginComponent> {

  constructor(
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  private static shouldSkipCanDeactivate(route?: ActivatedRouteSnapshot): boolean {
    if ( route == null ) {
      return false;
    } else if ( route.data?.skipPostLoginRedirect === true ) {
      return true;
    } else if ( route.children?.length === 1 ) {
      return PostLoginGuard.shouldSkipCanDeactivate(route.children[0]);
    }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // get cached user to check password reset
    return this.principalService.fetchUserData()
      .pipe(take(1))
      .pipe(map(principal => {
        if ( principal?.hasToResetPassword === true ) {
          // user must change password -> no further checks required
          return true;
        }
      }))

      .pipe(switchMap(inputRequired => {
        if ( inputRequired != null ) {
          // it is already clear that the user has to enter some values
          return of(inputRequired);
        }
        return this.principalService
          .getFrontendActions()
          .pipe(map(actions => actions?.length > 0));
      }))

      .pipe(map(postActionsToBePerformed => {
        if ( postActionsToBePerformed ) {
          // there are some postLogin actions to be done
          return true;
        }

        const url = route.queryParamMap.get('url') ?? '/';
        // if user is not required to enter anything, redirect to home
        return this.router.parseUrl(url);
      }));
  }

  canDeactivate(component: PostLoginComponent, currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if ( PostLoginGuard.shouldSkipCanDeactivate(nextState?.root) ) {
      // target route is allowed without defined password
      return of(true);
    }

    return this.principalService.fetchUserData(false)
      .pipe(take(1))
      .pipe(map(principal => principal?.hasToResetPassword !== true));
  }
}
