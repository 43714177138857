import { Directive, OnDestroy } from '@angular/core';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { RagCKEditorHelper } from './ragCkeditorHelper';


@Directive({
  selector: 'ckeditor[editor]',
})
export class RagCKEditorDirective
  implements OnDestroy {

  constructor(
    private editorComponent: CKEditorComponent,
  ) {
  }

  ngOnDestroy(): void {
    // prevent infinite loop when saving with enabled source editor
    RagCKEditorHelper.disableSourceEditing(this.editorComponent);
  }

}
