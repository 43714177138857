import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { ApiUrls } from "src/app/core/api.urls";
import { Core, TitledDistributionReference } from "src/app/core/core.types";
import { ApiResponse, HttpRequestOptions } from "src/app/core/global.types";
import { Categories } from "../../../core/categories/categories.types";

@Injectable({
  providedIn: 'root',
})
export class AdminCategoriesService {

  constructor(
    private http: HttpClient
  ) {}

  getAllCategories(): Observable<Array<Categories.Category>> {
    const url = ApiUrls.getKey('AdminCategories');
    return this.http.get<ApiResponse<Array<Categories.Category>>>(url)
      .pipe(map(response => response.categories));
  }

  saveCategory(category: Categories.Category): Observable<Categories.Category> {
    const url = ApiUrls.getKey('AdminCategories');
    return this.http.post<ApiResponse<Categories.Category>>(url, category)
      .pipe(map(response => response.category));
  }

  getAssignmentsForCategory(categoryId: number): Observable<Array<any>> {
    const url = ApiUrls.getKey('AdminCategories') + `/${categoryId}/assignments`;
    return this.http.get<ApiResponse<Array<TitledDistributionReference>>>(url).pipe(map(response => response.assignments));
  }

  deleteCategory(categoryId: number): Observable<void> {
    const url = ApiUrls.getKey('AdminCategories') + `/${categoryId}`;
    return this.http.delete<ApiResponse<boolean>>(url).pipe(map(response => response.success));
  }

  getCategories(objType: Core.DistributableType, objId: number): Observable<Categories.Selection> {
    const url = ApiUrls.getKey('Categories') + `/content/${objType}/${objId}`;
    return this.http.get<ApiResponse<Categories.Selection>>(url)
      .pipe(map(response => response.data));
  }

  saveCategoriesForContent(objType: Core.DistributableType, objId: number, categories: number[]): Observable<void> {
    const url = ApiUrls.getKey('AdminCategories') + `/content/${objType}/${objId}`;
    return this.http.post<ApiResponse<Categories.Selection>>(url, categories, HttpRequestOptions)
      .pipe(map(response => void(0)));
  }

}