
<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="tableColumns@transactions-list"
></rag-table-column-menu>

<mat-menu #transactionsMenu xPosition="before">
  <ng-template let-transaction="transaction" matMenuContent>
    <rag-transactions-list-actions
    [transaction]="transaction">
    </rag-transactions-list-actions>
  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data">There are no entries to display.</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">

  <rag-sticky-scroll [recalculate]="recalculateSticky$">

    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded; else tplDataLoading">

      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort
      >

        <!-- cell renders -->
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell [ngSwitch]="column.id">

            <ng-container *ngSwitchCase="'total'" >
              <div
                *ngIf="row.total"
                class="total"
                [ngClass]="{'negative': isNegative(row)}"
              >
                {{row.total | currency: row.currency}}
              </div>
            </ng-container>

            <div class="" *ngSwitchCase="'lineItemsName'">
              <ul class="line-items">
                <li *ngFor="let lineItem of row.lineItems">{{lineItem.title | localeText}}</li>
              </ul>
            </div>

            <rag-tables-column-render-default
              *ngSwitchDefault
              class="border-margin"
              [column]="column"
              [row]="row"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>

        <!-- filters -->
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let transaction" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{transaction: transaction}"
              [matMenuTriggerFor]="transactionsMenu"
              mat-icon-button
            >
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredDataEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredDataEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
