import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { DateOffsetTypes } from './date-offset.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    PipesModule
  ],
  selector: 'rag-date-offset',
  templateUrl: './date-offset.component.html',
  styleUrls: [ './date-offset.component.scss' ],
})
export class DateOffsetComponent
  implements OnInit {

  @Input()
  mode: DateOffsetTypes.Mode = 'date';
  private _date: moment.Moment;

  constructor() {
  }

  get date(): DateOffsetTypes.SomeDate {
    return this._date;
  }

  @Input()
  set date(newValue: DateOffsetTypes.SomeDate) {
    if ( newValue == null ) {
      this._date = null;
      return;
    }
    if ( moment.isMoment(newValue) ) {
      this._date = newValue;
    } else if ( moment.isDate(newValue) ) {
      this._date = moment(newValue);
    } else if ( typeof newValue === 'number' ) {
      if ( newValue > 9999999999 ) {
        this._date = moment(newValue);
      } else {
        this._date = moment.unix(newValue);
      }
    } else if ( typeof newValue === 'string' ) {
      this._date = moment(newValue);
    } else {
      throw Error('Unsupported date format: ' + newValue);
    }
  }

  get dateFormatted() {
    if ( this._date && this._date.isValid() ) {
      return this._date.format('LLL');
    }
    return '';
  }

  get timeSpan() {
    if ( this._date != null ) {
      return this._date.fromNow();
    }
    return null;
  }

  get timeSpanClassFor() {
    if ( this._date ) {
      if ( moment().isAfter(this._date) ) {
        return 'passed';
      }
      return 'future';
    }
  }

  ngOnInit() {
  }

}
