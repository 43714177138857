import { AdminOfflineNotificationRow, AdminOfflineNotificationStatus } from './content-notifications.columns';
import { NumberedAnyObject, TargetGroup } from '../../../../../core/core.types';
import { EhsEventAction } from '../../../../../core/ehs/ehs.types';
import { LanguageHelper } from '../../../../../core/language.helper';
import { ApiUrls } from '../../../../../core/api.urls';


export class ContentNotificationsHelper {

  static asTableRows(
    eventActions: EhsEventAction[],
    targetGroups: NumberedAnyObject<TargetGroup>,
  ): AdminOfflineNotificationRow[] {
    if ( !(eventActions?.length > 0) ) {
      return [];
    }

    if ( targetGroups == null ) {
      targetGroups = {};
    }

    return eventActions
      .map(eventAction => ({

        enabled: ContentNotificationsHelper.getEnabledStatus(eventAction.enabled, eventAction.enabledEndDate),
        enabledEndDate: eventAction.enabledEndDate,
        enabledStartDate: eventAction.enabledStartDate,

        title: LanguageHelper.objectToText(eventAction.title),
        eventName: LanguageHelper.objectToText(eventAction.eventName),
        offset: eventAction.offset,

        targetGroups: ContentNotificationsHelper
          .joinTargetGroups(eventAction.targetGroupIds, targetGroups),
        excludedTargetGroups: ContentNotificationsHelper
          .joinTargetGroups(eventAction.excludedTargetGroupIds, targetGroups),

        actionId: eventAction.actionId,
        eventId: eventAction.eventId,
        eventModuleId: eventAction.eventModuleId,
      } as AdminOfflineNotificationRow));
  }

  static getEnabledStatus(enabled: boolean, enabledEndDate?: number): AdminOfflineNotificationStatus {
    if ( enabled !== true ) {
      return AdminOfflineNotificationStatus.disabled;
    }

    if ( !enabledEndDate ) {
      // action does not have a specific end date -> is enabled
      return AdminOfflineNotificationStatus.enabled;
    }

    const now = Date.now();
    if ( now > enabledEndDate ) {
      // enabledEndDate is in the past -> event has expired
      return AdminOfflineNotificationStatus.expired;
    }

    return AdminOfflineNotificationStatus.enabled;
  }

  static getUrl(action: AdminOfflineNotificationRow | null, dispatch: string): string | null {
    const actionId = action?.actionId;
    if ( !(actionId > 0) ) {
      return null;
    }

    switch ( dispatch ?? '' ) {

      case 'showDetails':
        return ApiUrls.getKey('EhsLinkDetails')
          .replace(/{actionId}/gi, String(actionId));

      case 'edit':
        return ApiUrls.getKey('EhsLinkEdit')
          .replace(/{actionId}/gi, String(actionId));

      default:
        return null;
    }
  }

  static isEnabled(
    status: AdminOfflineNotificationStatus | null
  ): boolean {
    return status === AdminOfflineNotificationStatus.expired || status === AdminOfflineNotificationStatus.enabled;
  }

  static joinTargetGroups(targetGroupIds: number[], targetGroups: NumberedAnyObject<TargetGroup>): string {
    if ( !(targetGroupIds?.length > 0) ) {
      return '';
    }

    return targetGroupIds

      // fetch object from id
      .map(targetGroupId => LanguageHelper.objectToText(targetGroups[targetGroupId]?.name))

      // ignore empty titles
      .filter(title => (title != null) && (title?.trim() !== ''))

      // join with new lines
      .join('<br/>\n');
  }

}
