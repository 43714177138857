import { Component, Input, OnInit } from '@angular/core';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import * as moment from 'moment';
import { from } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'rag-catalog-card-schedules-pager',
  templateUrl: './catalog-card-schedules-pager.component.html',
  styleUrls: ['./catalog-card-schedules-pager.component.scss']
})
export class CatalogCardSchedulesPagerComponent implements OnInit {

  @Input() schedulesViews: Array<Catalogs.EventScheduleCatalogView>;
  @Input() blockEvent = false;

  schedulesCount = 0;
  fadeIn = false;
  fadeOut = false;

  private clickEnabled = true;

  get scheduleIndex() {
    return this.selectedIndex + 1;
  }

  get hasSchedules() {
    return this.schedulesViews?.length > 0;
  }

  get eventDateFormatted() {
    const eventSchedule = this.eventSchedule;
    if (eventSchedule) {
      const eventDate = moment(eventSchedule.eventDate);
      return eventDate.format('L');
    }
    return null;
  }

  get eventTimeFormatted() {
    const eventSchedule = this.eventSchedule;
    if (eventSchedule) {
      const eventDate = moment(eventSchedule.eventDate);
      return eventDate.format('LT');
    }
    return null;
  }

  get trainers() {
    return this.eventSchedule?.trainers ?? [];
  }

  get hasNextSchedule() {
    return this.selectedIndex < ( this.schedulesCount - 1 );
  }

  get hasPrevSchedule() {
    return this.selectedIndex > 0;
  }

  get hasTrainers() {
    return this.eventSchedule?.trainers?.length > 0;
  }

  private get eventSchedule() {
    if (this.selectedIndex < this.schedulesViews?.length) {
      return this.schedulesViews[this.selectedIndex];
    }
  }

  private selectedIndex = 0;

  constructor() { }

  ngOnInit(): void {
    this.schedulesCount = this.schedulesViews?.length ?? 0;
    this.schedulesViews = this.schedulesViews.sort((v1, v2) => v1.eventDate - v2.eventDate);
  }

  onBack() {
    if (this.clickEnabled && this.selectedIndex > 0) {
      this.animatedTransition(-1);
    }
  }

  onForth() {
    if (this.clickEnabled && this.selectedIndex < (this.schedulesCount - 1)) {
      this.animatedTransition(1);
    }
  }

  private animatedTransition(incrementer: number) {
    from([0])
    .pipe(tap(_ => {
      this.clickEnabled = false;
      this.fadeOut = true;
    }))
    .pipe(delay(300))
    .pipe(tap(_ => {
      this.selectedIndex += incrementer;
      this.fadeOut = false;
      this.fadeIn = true;
    }))
    .pipe(delay(300))
    .pipe(tap(_ => {
      this.clickEnabled = true;
      this.fadeIn = false;
    }))
    .pipe(take(1))
    .subscribe();
  }

}
