import { NgModule } from '@angular/core';
import { RagVarDirective } from './rag-var.directive';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [ RagVarDirective ],
  exports: [ RagVarDirective ],
  imports: [
    CommonModule,
  ],
})
export class RagVarModule {
}
