import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable, switchMap } from "rxjs";
import { PdfService } from "../pdf.service";

export const UserProfileExportResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  pdfService: PdfService = inject(PdfService)
): Observable<void> => {
  return pdfService.generateUserProfileExportPdf()
  .pipe(switchMap(_ => EMPTY));
};