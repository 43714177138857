import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {StartPageSettings} from '../../admin/account-design/account-design.types';
import {AccountDesignService} from '../../admin/account-design/account-design.service';
import {LoginV2Component} from '../login-v2/login-v2.component';
import {LoginComponent} from '../login/login.component';
import {AsyncPipe, CommonModule} from '@angular/common';
@Component({
  standalone: true,
  imports: [
    LoginV2Component,
    LoginComponent,
    AsyncPipe,
    CommonModule,
  ],
  selector: 'rag-login-overview',
  templateUrl: './login-overview.component.html',
  styleUrls: ['./login-overview.component.scss']
})
export class LoginOverviewComponent {

  readonly startPage$: Observable<StartPageSettings>

  constructor(
    private accountDesignService: AccountDesignService,
  ) {
    this.startPage$ = this.accountDesignService.getStartPage();
  }
}
