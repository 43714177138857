import { Component, Input, OnChanges } from '@angular/core';
import { ContentService } from '../../../core/content/content.service';
import { DisplayStatusColors, DisplayStatusHelper } from '../../../core/display-status-helper';
import { ViewHelper } from '../../../core/view-helper';
import { switchMap, take, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormatDatePipe } from '../../../core/pipes/format-date.pipe';
import { ScoWarningComponent } from '../../../route/user/sco/sco-warning/sco-warning.component';
import { ModalDialog } from '../../../core/modal-dialog';
import { Core, ImageableContentReference } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';


@Component({
  selector: 'rag-content-action',
  templateUrl: './content-action.component.html',
  styleUrls: [ './content-action.component.scss' ],
})
export class ContentActionComponent
  implements OnChanges {

  @Input() content: ImageableContentReference;
  @Input() parent: ImageableContentReference;
  @Input() version: number = 1;
  @Input() selectedItemTitle: string;

  actionType: 'context' | 'course' | 'courseError' | 'document' | 'locked' | 'offlineCnt';
  contentHref: string;
  icon: string;
  locked = true;

  constructor(
    private dialog: ModalDialog,
    private formatDate: FormatDatePipe,
    private router: Router,
  ) {
  }

  ngOnChanges(): void {
    // reset all generated properties
    this.contentHref = this.actionType = this.icon = null;
    this.locked = true;

    if ( !this.content ) {
      // no content found -> skip play button
      if ( DistributionTypeHelper.isCurriculum(this.parent.objType) && DisplayStatusHelper.isToDoContent(this.parent?.displaystatus) ) {
        // some parent without executable contents -> likely locked from somewhere else
        this.content = this.parent;
      } else {
        // abort, abort
        return;
      }
    }

    if ( this.content.locked === true ) {
      this.actionType = 'locked';
      const status = DisplayStatusHelper.toColor(this.content.displaystatus);
      const repetitions: number = this.content.repetitions;
      const isGreen = status === DisplayStatusColors.green;
      if ( isGreen && ((repetitions === -2) || (repetitions === -3)) ) {
        // content is green and locked -> repetitions still allow execution
      } else {
        // content is locked -> green should not show lock icon
        // todo maybe show disabled play button?
        this.locked = !isGreen;
        return;
      }
    }

    if ( this.content && this.parent && (this.content !== this.parent) ) {
      // check if parent is missing from content and add
      const viewData = ViewHelper.getViewData(this.content);
      if ( viewData.parent == null ) {
        viewData.parent = this.parent;
        delete viewData.contentHref;
      }
    }

    const contentHref = ContentService.getContentHref(this.content, true);
    if ( !contentHref ) {
      this.actionType = 'locked';
      // disable buttons without url to launch content
      return;
    }

    const objType = this.content.objType;
    if ( objType === Core.DistributableType.lms_course ) {
      this.locked = this.content.locked === true;
      this.actionType = 'course';
      this.content.items.forEach(item => {
        if ( item?.fullhref != null ) {
          this.locked = this.locked || item.locked; // one locked SCO will lock the whole course
          if ( item.type === '8' ) {
            this.actionType = 'document';
          }
        }
      });
      this.checkCourseError();
    } else if ( objType === Core.DistributableType.lms_offlineCnt ) {
      this.actionType = 'offlineCnt';
      if ( this.content.objSubType === Core.CourseType.Virco ) {
        this.icon = 'web';
      } else {
        this.icon = 'home-city-outline';
      }
      this.locked = false;
    } else if ( objType === Core.DistributableType.lms_context ) {
      this.actionType = 'context';
      this.locked = false;
    } else if ( this.content.fullhref ) {
      this.actionType = 'course';
      this.locked = false;
    } else {
      this.actionType = 'locked';
    }

    if ( !this.locked ) {
      this.contentHref = contentHref;
    }
  }

  getContentHref(parent: ImageableContentReference, content: ImageableContentReference) {
    ViewHelper.getViewData(content).parent ??= parent;
    return ContentService.getContentHref(content, true);
  }

  onShowCourseErrorDialog(): void {
    const viewData = ViewHelper.getViewData(this.content);
    const failedItem = viewData?.executableItemError;
    if ( failedItem == null ) {
      return;
    }

    const message = $localize`:@@elearning_content_action_courseError_confirm:
        You completed this course on ${this.formatDate.transform(failedItem.lastModified)}.
        The course will be automatically counted as completed after the next start.<br/>
        Do you wish to start the course now?
    `;
    this.dialog.openModal(ScoWarningComponent, {
      data: {
        title: $localize`:@@general_dialog_pleaseconfirm_title:Please Confirm`,
        content: message,
      },
    }).afterClosed()
      .pipe(take(1))
      .pipe(takeWhile(confirmed => confirmed === true))
      .pipe(switchMap(() => this.router.navigateByUrl(this.contentHref)))
      .subscribe();
  }

  onShowCourseErrorDialogV2(event:MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onShowCourseErrorDialog();
  }

  onExecuteItem(event:MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.locked) {
      return;
    }
    if ((this.actionType === 'locked') || (this.actionType === 'courseError')) {
      return;
    }
    this.router.navigateByUrl(this.contentHref).then();
  }

  private checkCourseError(): void {
    this.icon = 'play-circle';
    if ( this.locked ) {
      // ignore if item is locked anyway
      return;
    }

    const viewData = ViewHelper.getViewData(this.content);
    const failedItem = viewData?.executableItemError;
    if ( failedItem == null ) {
      return;
    }
    if ( DisplayStatusHelper.isStatusGreen(failedItem.displaystatus) ) {
      console?.warn('found item with inconsistent results ');
      this.actionType = 'courseError';
      this.icon = /*'alert'*/ 'play-circle';
    }
  }

}
