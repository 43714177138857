<div class="contact-container">
  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@contact_title">Contact</span>
    </div>
  </rag-page-header>

  <div *ngIf="!requestIsSent || isFormInvalid()" class="box">
    <div class="form">
      <form (ngSubmit)="tryPostContact()">
        <div class="row">
          <!-- Left columns -->
          <div class="column column-1">
            <mat-form-field appearance="outline">
              <mat-label class="selectLabel">
                <span i18n="@@contact_reason">Reason of enquiry–Please select…</span>
              </mat-label>
              <mat-select [formControl]="reasonControl" class="classic" required>
                <ng-container *ngFor="let contactDefinition of contactElements">
                  <mat-option value="{{contactDefinition.reason}}">
                    <span>{{contactDefinition.text}}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="reasonControl.invalid">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                <span i18n="@@firstname">First name</span>
              </mat-label>
              <input [formControl]="firstNameControl" matInput name="firstName" required type="text">
              <mat-error *ngIf="firstNameControl.invalid">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                <span i18n="@@lastname">Last name</span>
              </mat-label>
              <input [formControl]="lastNameControl" matInput name="lastName" required type="text">
              <mat-error *ngIf="lastNameControl.invalid">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>
                <span i18n="@@email">Email</span>
              </mat-label>
              <input [formControl]="emailControl" email matInput name="emailAddress" required type="email">
              <mat-error *ngIf="emailControl.hasError('required')">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
              <mat-error *ngIf="this.errors.notValid">
                <span i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                <span  i18n="@@register_email_confirm">Confirm e-mail address</span>
              </mat-label>
              <input [formControl]="emailRepeateControl" email matInput name="emailAddress" required type="email">
              <mat-error *ngIf="emailRepeateControl.hasError('required')">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
              <mat-error *ngIf="errors.not_Match">
                <span i18n="@@valid_no_match" >The values do not match.</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>
                <span i18n="@@telefone">Telephone number</span>
              </mat-label>
              <input [formControl]="telControl" matInput name="phoneNumber" type="tel">
            </mat-form-field>

            <div class="row single-line">

              <mat-form-field appearance="outline">
                <mat-label>
                  <span i18n="@@context_date">Contact date</span>
                </mat-label>
                <input
                  (dateChange)="dateFilterChanged($event)"
                  [formControl]="dateControl"
                  [matDatepickerFilter]="dateFilter"
                  [matDatepicker]="dt1"
                  autocomplete="off"
                  matInput>
                <mat-datepicker-toggle [for]="dt1" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #dt1></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>
                  <span i18n="@@contact_time">Contact time</span>
                </mat-label>
                <input
                  [formControl]="timeControl"
                  autocomplete="off"
                  matInput
                  ragTimePicker
                >
                <mat-error *ngIf="timeControl.invalid">
                  <span>{{invalidTimeErrorMessage}}</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Right column -->
          <div class="column column-2">
            <mat-form-field appearance="outline">
              <mat-label>
                <span i18n="@@contact_message">Message</span>
              </mat-label>
              <textarea [formControl]="messageControl" [rows]="20" matInput required></textarea>
              <mat-error *ngIf="messageControl.invalid">
                <span i18n="@@validation_required">This field is required</span>
              </mat-error>
            </mat-form-field>

            <mat-checkbox [formControl]="includeSysCheck" class="attach-syscheck">
              <div class="div-flex">
                <p>
                  <span i18n="@@contact_include_syscheck">Attach system check data</span>
                </p>
                &nbsp;(
                <mat-icon svgIcon="shield-check-outline"></mat-icon>
                )
              </div>
            </mat-checkbox>

          </div>
        </div>

        <div class="row space">
          <p class="notice">
            <span i18n="@@contact_requred_fields">The fields marked with * are mandatory and must be completed.</span>
          </p>
        </div>

        <div class="row row-right">
          <div class="buttons-container">
            <p fxLayout="row">

              <button
                (click)="cancel()"
                class="rag-stroked-button"
                mat-stroked-button
                type="button">
                <span i18n="@@cancel">Cancel</span>
              </button>

              <button
                [disabled]="isFormInvalid()"
                color="primary"
                mat-flat-button
                type="submit">
                <span i18n="@@Submit">Submit</span>
              </button>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="requestIsSent" class="box" id="request-is-sent">
    <p>
      <span i18n="Description text after the contact form has been sent@@contact_is_sent">
      Your request has been sent successfully</span>
    </p>

    <button id="go-home" mat-button routerLink="/home" type="button">
      <span i18n="@@home">Home</span>
    </button>
  </div>
</div>
<!-- Translations -->
<span #validation_time_between class="hidden"
      i18n="@@contact_time_invalid">Please schedule a time between $0 and $1</span>
