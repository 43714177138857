import { Injectable } from '@angular/core';
import { ics_js } from './ics';
import { Ical } from './ical.types';
import { AsyncSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const PRODUCT_ID = 'reflact train';
const CONTENT_TYPE = 'text/x-vCalendar;charset=' + document.characterSet;

@Injectable({
  providedIn: 'root',
})
export class IcalService {

  constructor() {
  }

  private static asBlob(data: string): Blob {
    try {
      // modern browsers should support this
      return new Blob([ data ], { type: CONTENT_TYPE });
      // The BlobBuilder API has been deprecated in favour of Blob, but older
      // browsers don't know about the Blob constructor
      // IE10 also supports BlobBuilder, but since the `Blob` constructor
      //  also works, there's no need to add `MSBlobBuilder`.
    } catch(e) {
      // nope!
      throw Error('Blob not supported by browser!');
    }
  }

  private static blobToFile(data: Blob, filename: string): File {
    const result: any = data;
    result.lastModifiedDate = new Date();
    result.name = filename;
    return result;
  }

  toICS(context: string, events: Ical.Event[]): Observable<string> {
    const subject = new AsyncSubject<string>();

    if ( !(events && events.length) ) {
      subject.error('events required!');
      subject.complete();
      return subject.asObservable();
    }

    let valid = true;
    const cal = ics_js.ics(context, PRODUCT_ID);
    events.forEach(event => {
      if ( !(valid &&
        event.begin &&
        event.end &&
        event.subject &&
        event.begin.isValid() &&
        event.end.isValid()) ) {
        valid = false;
        return;
      }
      cal.addEvent(
        event.subject,
        event.description,
        event.location,
        event.begin.toISOString(),
        event.end.toISOString(),
      );
    });
    if ( valid ) {
      subject.next(cal.calendar());
    } else {
      subject.error('events not valid!');
    }
    subject.complete();
    return subject.asObservable();
  }

  toICSFile(fileName: string, context: string, events: Ical.Event[]): Observable<File> {
    return this.toICS(context, events)
      .pipe(map(IcalService.asBlob))
      .pipe(map(data => IcalService.blobToFile(data, fileName)));
  }

}
