import {
  ColumnSettings,
  ReportConfig,
  ReportRow,
  ReportRowData,
  ReportRowStatistics,
} from '../../../../../core/report/report.types';
import {
  ReportTableColumnMenuData,
  ReportTableRow,
  ReportTableRowChild,
  ReportTableRowParent,
} from './report-table.types';
import { TableGroupingHelper } from '../../../../../component/table/table-grouping/table-grouping.helper';
import { ReportFilterHelper } from '../../../../../core/report/report-filter.helper';
import { TableColumnSettings } from '../../../../../core/column-settings/column-settings.service';


export class ReportTableHelper {

  static asReportFilter(columnMenuData: ReportTableColumnMenuData) {
    const filters = Object.values(columnMenuData?.menuItems ?? {})
      .map(column => column?.options?.filter);
    return ReportFilterHelper.asFilterExpression(filters);
  }

  static copyState(
    to: ReportTableRow[] | null,
    from: ReportTableRow[] | null,
  ): void {
    if ( (to === from) || !(to?.length > 0) || !(from?.length > 0) ) {
      // no merge required
      return;
    }

    const fromMap = from.reduce((pV, row) => {
      if ( row?.$rowType !== 'parent' ) {
        return pV;
      }

      const parentRow = row as ReportTableRowParent;
      pV[parentRow.$groupingCoordinates] = parentRow;
      return pV;
    }, {});

    to
      .map(row => {
        if ( row?.$rowType !== 'parent' ) {
          // ignore children for now
          return;
        }

        const parentRow = row as ReportTableRowParent;
        const sourceRow = fromMap[parentRow.$groupingCoordinates];
        if ( sourceRow == null ) {
          // new grouping -> nothing to copy
          return;
        }

        if ( sourceRow.$expanded ) {
          TableGroupingHelper.expandParents(parentRow, true);
        } else {
          TableGroupingHelper.collapseChildren(parentRow, true);
        }
      });
  }

  /**
   * Convert {@link ReportGeneratorV2RouteData.columnSettings} into an (optional) array of {@link ColumnSettings}.
   */
  static createColumnSettings(
    columns: TableColumnSettings,
  ): ColumnSettings[] | null {

    if ( columns == null ) {
      // keep the defaults if no settings are supplied
      return null;
    }

    return Object.values(columns)
      .map(column => ({
        enabled: column.selectable,
        fixed: column.fixed,
        id: column.columnName,
        selected: column.visible,
        title: column.title,
      }));
  }

  static filterSelectedRows(
    reportRows: ReportTableRow[] | null,
    selectedRows: ReportTableRowChild[] | null,
  ): ReportTableRowChild[] {

    if ( !(reportRows?.length > 0) || !(selectedRows?.length > 0) ) {
      return [];
    }

    const selectedIdentities = selectedRows
      .map(entry => entry.entityIdentity)
      .filter(entry => entry != null);

    return reportRows
      .map(entry => entry?.$rowType === 'child' ? entry as ReportTableRowChild : null)
      .filter(entry => selectedIdentities.includes(entry?.entityIdentity));
  }

  static getGroupingCoordinates(row: ReportTableRowParent): string {

    if ( row.$groupingCoordinates != null ) {
      // no need to calculate again
      return row.$groupingCoordinates;
    }

    const coordinates = row.column + '#' + (row.distinctValue ?? '');
    if ( (row.$parent != null) && (row.$parent !== row) ) {

      // prepend the parent coordinates
      return ReportTableHelper.getGroupingCoordinates(row.$parent) + '|' + coordinates;

    } else {

      // terminate with current coordinates
      return coordinates;
    }
  }

  static isNew(reportConfig: ReportConfig | null): boolean {
    return (reportConfig?.uuid == null) || (!reportConfig.title);
  }

  static toTableRows(
    data: ReportRow[] | ReportRowStatistics[] | null,
  ): ReportTableRow[] {

    const rootRows = ReportTableHelper.asTableRows(data, null);

    // collapse all children by default
    rootRows.map(row => TableGroupingHelper.collapseChildren(row, true));

    const reportTableRows = TableGroupingHelper.recreateArray(rootRows);
    TableGroupingHelper.addDefaultSorting(reportTableRows);
    return reportTableRows;
  }

  private static asTableRowChild(
    data: ReportRowData | null,
    rowIndex: number,
    parent: ReportTableRowParent | null,
  ): ReportTableRowChild | null {
    if ( data == null ) {
      return null;
    }

    return {
      $rowType: 'child',
      $data: data,
      $parent: parent,
      $rowSort: TableGroupingHelper.calculateRowSort(rowIndex, parent),

      // pass all attributes from data
      ...data,
    };
  }

  private static asTableRowParent(
    data: ReportRowStatistics | null,
    rowIndex: number,
    parent: ReportTableRowParent | null,
  ): ReportTableRow {

    if ( data == null ) {
      return null;
    }

    const row: ReportTableRowParent = {
      $rowType: 'parent',
      $data: data,
      $parent: parent,
      $parentRoot: parent?.$parentRoot ?? parent,
      $children: [],
      $groupingCoordinates: null,
      $rowSort: TableGroupingHelper.calculateRowSort(rowIndex, parent),

      // pass all attributes from data
      ...data,
    };

    row.$children = ReportTableHelper.asTableRows(data.data, row)
      .filter(e => e != null);
    row.$groupingCoordinates = ReportTableHelper.getGroupingCoordinates(row);

    return row;
  }

  private static asTableRows(
    data: ReportRow[] | ReportRowStatistics[] | null,
    parent: ReportTableRowParent | null,
  ): ReportTableRow[] {

    if ( !(data?.length > 0) ) {
      return [];
    }

    if ( data[0].hasOwnProperty('distinctValue') ) {

      return (data)
        .flatMap((row, i) =>
          ReportTableHelper.asTableRowParent(row, i, parent))
        .filter(row => row != null);

    } else {

      return (data)
        .map((row, i) => ReportTableHelper.asTableRowChild(row, i, parent))
        .filter(row => row != null);
    }
  }

}
