import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ColumnSettingsService } from '../../core/column-settings/column-settings.service';
import { TranslationService } from '../../core/translation/translation.service';
import { dataUrlToBlog } from '../../core/utils';
import { AccountDesignService } from '../../route/admin/account-design/account-design.service';
import { Statistics } from '../../route/ctrl/report/report-generator/status-statistics/status-statistics.types';
import { DoughnutChartDataSet } from './doughnut-chart/doughnut-chart.types';
import { ChartConfiguration } from 'chart.js';
import { ReportTargetType } from 'src/app/core/report/report.types';
import { MergeHelper } from '../../core/primitives/merge.helper';

@Injectable({
  providedIn: 'root',
})
export class ChartService {

  private _accountTitle: string;

  constructor(
    private accountDesignService: AccountDesignService,
    private columnSettingsService: ColumnSettingsService,
    private translationService: TranslationService,
  ) {
    this.accountDesignService.getStyleSettings()
      .pipe(tap(design => this._accountTitle = design.acc.pageTitle))
      .subscribe();

    const plugin = {
      id: 'background',
      beforeDraw: (_chart) => {
        const ctx = _chart.ctx;
        const canvasToDraw = _chart.canvas;
        ctx.fillStyle = _chart.options?.backgroundColor ?? 'transparent';
        ctx.fillRect(0, 0, canvasToDraw.width, canvasToDraw.height);
      },
    };
    Chart.register(plugin);
  }

  downloadChart = (chartJs: ChartConfiguration, reportName: string, reportType: string, backgroundColor = 'white') => {

    const fileName = this.getFileName(reportName, reportType);
    const config = MergeHelper.mergeDeep(chartJs, {
      options: {
        backgroundColor,
        maintainAspectRatio: false,
        animation: {
          onComplete: (): void => {
            setTimeout(() => {
              if ( chart ) {
                const dataUrl = canvas.toDataURL('image/png', 1.0);
                chart.destroy();
                const blob = dataUrlToBlog(dataUrl);
                FileSaver.saveAs(blob, fileName);
              }
              wrapper.remove();
            });
          },
        },
        plugins: {
          legend: {
            display: true,
            align: 'center',
            position: 'right',
          },
        },
      },
    });

    const wrapper = document.createElement('div');
    wrapper.style.visibility = 'hidden';
    wrapper.style.height = '800px';
    wrapper.style.width = '1200px';

    const canvas = document.createElement('canvas');
    canvas.style.height = canvas.style.width = '100%';
    wrapper.appendChild(canvas);

    document.body.appendChild(wrapper);

    const chart = new Chart(canvas, config);
  };

  /**
   * Convert a statistics entry into data for a doughnut chart.
   */
  statisticsAsPie(statistics: Statistics, targetType: ReportTargetType): Observable<DoughnutChartDataSet[]> {
    return this.columnSettingsService.getColumnSettings(targetType)
      .pipe(map(columnSettings => {
        const dropDownOptions = columnSettings.accountDisplayStatus.dropDownOptions || {};
        const totalCount = statistics.totalCount || 1;
        const pctGreen = Math.round(100 * statistics.greenCount / totalCount) || 0;
        const pctYellow = Math.round(100 * statistics.recertificationCount / totalCount) || 0;
        const pctRed = Math.max(100 - pctGreen - pctYellow, 0);
        return [ {
          label: this.translationService.translate(
            dropDownOptions.notCertified || 'accountDisplayStatus_red')
            + ' (' + pctRed + '%)',
          value: statistics.redCount,
          color: '#CA1F22',
        }, {
          label: this.translationService.translate(
            dropDownOptions.recertificationTime || 'accountDisplayStatus_recertification')
            + ' (' + pctYellow + '%)',
          value: statistics.recertificationCount,
          color: '#E8B028',
        }, {
          label: this.translationService.translate(
            dropDownOptions.certified || 'accountDisplayStatus_green')
            + ' (' + pctGreen + '%)',
          value: statistics.greenCount,
          color: '#32ab5a',
        } ];
      }));
  }

  private getFileName = (reportName: string, reportType: string): string => {
    let result = '';

    if ( this._accountTitle ) {
      result += this._accountTitle + '_';
    }

    if ( reportName ) {
      result += reportName + '_';
    }

    // clean and shorten title
    result = result
      .replace(/[^\w]+/gmi, '_')
      .replace(/[_]+/gmi, '_')
      .replace(/^([\w_]{1,32}).*/, '$1_');

    if ( reportType ) {
      result += reportType + '_';
    }

    result += moment().format('YYYY-MM-DD LT');

    result = result
      .replace(/[_]+/gmi, '_');

    return result + '.png';
  };

}
