import { DisplayStatus } from './display-status.enum';

export enum CourseStatus {
  COMPLETE = 1,
  INCOMPLETE = 4,
  NOT_ATTEMPTED = 16,
  UN_KNOWN = -1,
}

export class CourseStatusHelper {

  public static toCourseStatus(status: string | number): CourseStatus {
    if ( typeof (status) === 'string' ) {
      if ( status === 'green' ) {
        // this may be PASSED or COMPLETED for lms_course!
        status = CourseStatus.COMPLETE;
      } else if ( status === 'yellow' ) {
        status = CourseStatus.INCOMPLETE;
      } else if ( status === 'red' ) {
        status = CourseStatus.NOT_ATTEMPTED;
      } else {
        status = Number(status);
      }
    }
    if ( Number.isNaN(status) ) {
      return CourseStatus.UN_KNOWN;
    }

    switch ( status ) {
      case CourseStatus.COMPLETE:
        return CourseStatus.COMPLETE;
      case CourseStatus.INCOMPLETE:
        return CourseStatus.INCOMPLETE;
      case CourseStatus.NOT_ATTEMPTED:
        return CourseStatus.NOT_ATTEMPTED;
      case CourseStatus.UN_KNOWN:
      default:
        return CourseStatus.UN_KNOWN;
    }
  }

  public static toDisplayStatus(status: CourseStatus): DisplayStatus {
    if ( status == null || status === undefined ) {
      return DisplayStatus.UN_KNOWN;
    }

    switch ( status ) {
      case CourseStatus.COMPLETE:
        return DisplayStatus.COMPLETE;
      case CourseStatus.INCOMPLETE:
        return DisplayStatus.INCOMPLETE;
      case CourseStatus.NOT_ATTEMPTED:
        return DisplayStatus.NOT_ATTEMPTED;
      default:
      case CourseStatus.UN_KNOWN:
        return DisplayStatus.UN_KNOWN;
    }
  }

}
