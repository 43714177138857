import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ViewHelper } from '../../../core/view-helper';
import { AssignmentDialogTypes } from '../assignment-dialog.types';

@Component({
  selector: 'rag-assignment-dialog-entry',
  templateUrl: './assignment-dialog-entry.component.html',
  styleUrls: [ './assignment-dialog-entry.component.scss' ],
})
export class AssignmentDialogEntryComponent<T, C = any> {

  @Input()
  disabled: boolean;
  @Output()
  readonly entryChange: Observable<AssignmentDialogTypes.AssignmentEntry<T, C>>;
  private _entry: AssignmentDialogTypes.AssignmentEntry<T, C>;
  private _entryChange = new EventEmitter<AssignmentDialogTypes.AssignmentEntry<T, C>>();

  constructor() {
    this.entryChange = this._entryChange.asObservable();
  }

  get entry(): AssignmentDialogTypes.AssignmentEntry<T, C> {
    return this._entry || { $view: {} } as any;
  }

  @Input()
  set entry(value: AssignmentDialogTypes.AssignmentEntry<T, C>) {
    const doUpdate = value && (this.entry !== value);
    this._entry = value;
    if ( !doUpdate ) {
      return;
    }

    // ensure we have a valid $view object
    ViewHelper.getViewData(value);
  }

  onSelectionChange(): void {
    if ( this._entry == null ) {
      return;
    }

    this._entryChange.emit(this._entry);
  }

}
