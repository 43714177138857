import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDialog } from '../modal-dialog';
import { CurriculumPathSwitchTypes } from './curriculum-path-switch.types';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { NumberedAnyObject } from '../core.types';
import { InfoService } from '../info/info.service';
import { InfoType } from '../info/info.types';
import { CurriculumPathSwitchComponent, CurriculumPathSwitchDialogData } from '../../component/elearning/curriculum-path-switch/curriculum-path-switch.component';
import { SnackbarProgressService } from '../../component/snackbar-progress/snackbar-progress.service';
import { HttpRequestOptions } from '../global.types';


@Injectable({ providedIn: 'root' })
export class CurriculumPathSwitchService {

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private snackbarProgressService: SnackbarProgressService,
  ) {
  }

  getPaths(curriculumToUsers: NumberedAnyObject<number[]>):
    Observable<CurriculumPathSwitchTypes.CurriculumSwitchData[]> {
    const url = ApiUrls.getKey('CtrlCurriculumPaths');
    return this.http.post<CurriculumPathSwitchTypes.CurriculumPathResponse>(url, curriculumToUsers)
      .pipe(catchError(this.handleError))
      .pipe(switchMap(response => {
        const curricula = response.curriculumPaths.reduce((pV, path) => {
          const curriculumId = path.curriculumId;
          const curriculumInfo = pV[curriculumId] ?? {
            curriculumId,
            pathInfo: [],
            title: path.curriculumTitle,
            users: response.curriculumUsers[curriculumId] ?? [],
          } as CurriculumPathSwitchTypes.CurriculumSwitchData;
          curriculumInfo.curriculumTitle = path.curriculumTitle;
          curriculumInfo.pathInfo.push(path);
          pV[curriculumId] = curriculumInfo;
          return pV;
        }, {} as NumberedAnyObject<CurriculumPathSwitchTypes.CurriculumSwitchData>);

        // remove curricula with a single path and / or without users
        const originalResult = Object.values(curricula);
        const result = originalResult
          .filter(c => (c != null) && (c.pathInfo?.length > 1) && (c.users?.length > 0));

        if ( result.length !== originalResult.length ) {
          // some of the entries have been filtered -> show warning
          this.infoService.showMessage($localize`:@@curriculum_path_switch_users_filtered:
            Some of the selections cannot be changed and have been skipped.`,
            { infoType: InfoType.Warning });
          if ( result.length === 0 ) {
            // all entries have been removed -> do not show the empty values warning!
            return EMPTY;
          }
        }
        return of(result);
      }));
  }

  switchPaths(entries: CurriculumPathSwitchTypes.CurriculumSwitchData[]): Observable<void> {
    const curriculumToUsers = Object.values(entries)
      .reduce((pV, entry) => {
        pV[entry.curriculumId] = entry.users.map(u => u.userId);
        return pV;
      }, {});
    return this.getPaths(curriculumToUsers)
      .pipe(takeWhile(this.checkEmptyData))
      .pipe(switchMap(this.openPathSwitchDialog))
      .pipe(takeWhile(result => (result?.length > 0)))
      .pipe(switchMap(this.updatePaths))
      .pipe(tap(() => this.infoService
        .showMessage($localize`:@@curriculum_path_switch_switch_success:The curriculum paths have been changed.`,
          { infoType: InfoType.Success })));
  }

  switchPath(curriculumId: number, userId: number, pathId: number): Observable<void> {
    const _url = ApiUrls.getKey('CurriculumCtrlAction');
    const url = _url.substring(0, _url.indexOf('?'));
    const payload = {
      dispatch: 'switchPath',
      curriculumId,
      pathId,
      userId
    };
    return this.http.post<any>(url, payload, HttpRequestOptions);
  }

  private checkEmptyData = (entries: CurriculumPathSwitchTypes.CurriculumSwitchData[]): boolean => {
    if ( entries?.length > 0 ) {
      return true;
    }

    this.infoService.showMessage($localize`:@@curriculum_path_switch_empty:No paths to switch to`, {
      infoType: InfoType.Information
    });
    return false;
  };

  private handleError(): Observable<never> {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Warning });
    return EMPTY;
  }

  private openPathSwitchDialog = (entries: CurriculumPathSwitchTypes.CurriculumSwitchData[]):
    Observable<CurriculumPathSwitchTypes.CurriculumSwitchData[]> => this.dialog
      .openModal<CurriculumPathSwitchComponent, CurriculumPathSwitchDialogData, CurriculumPathSwitchTypes.CurriculumSwitchData[]>(
        CurriculumPathSwitchComponent, { data: { entries }, autoFocus: false },
      )
      .afterClosed();

  /**
   * @see https://www.learnrxjs.io/learn-rxjs/recipes/progressbar
   */
  private updatePaths = (entries: CurriculumPathSwitchTypes.CurriculumSwitchData[]): Observable<void> => {
    const url = ApiUrls.getKey('CtrlCurriculumPathSet');
    const userEntries = entries
      .flatMap(curriculum => curriculum.users
        .map(user => ({
            url: url.replace(/{curriculumId}/, String(curriculum.curriculumId))
              .replace(/{variationCounter}/, String(curriculum.selectedVariationCounter))
              .replace(/{userId}/, String(user.userId)),
            userId: user.userId,
            curriculumId: curriculum.curriculumId,
            currentVariationCounter: user.curriculumVariationCounter,
            variationCounter: curriculum.selectedVariationCounter,
          })),
      );
    const queries = userEntries
      .map(entry => {
        if ( entry.currentVariationCounter !== entry.variationCounter ) {
          return this.http.post<void>(entry.url, null);
        } else {
          return of().pipe(delay(0));
        }
      });
    return this.snackbarProgressService.showProgress(queries);
  };

}
