import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotFound404Module } from '../main/not-found-404/not-found-404.module';
import { CtrlRoutingModule } from './ctrl.routing';
import { DashboardModule } from './dashboard/dashboard.module';
import { OfficialsDashboardsModule } from './officials-dashboards/officials-dashboards.module';
import { ReportModule } from './report/report.module';
import { UserDetailsModule } from './user-details/user-details.module';
import { ZuepModule } from './zuep/zuep.module';
import { ReportListModule } from './report/report-list/report-list.module';
import { FurtherBoardsModule } from './further-boards/further-boards.module';
import { SingleListsModule } from './single-lists/single-lists.module';


@NgModule({
  imports: [
    CommonModule,
    CtrlRoutingModule,
    DashboardModule,
    NotFound404Module,
    OfficialsDashboardsModule,
    ReportModule,
    UserDetailsModule,
    ZuepModule,
    ReportListModule,
    FurtherBoardsModule,
    SingleListsModule,
  ],
})
export class CtrlModule {
}
