import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { NewsService } from 'src/app/core/news/news.service';
import { SlideshowTypes } from '../../../component/slideshow/slideshow.types';
import { InfoService } from '../../../core/info/info.service';
import { News } from '../../../core/news/news.types';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { BaseWidgetComponent, WidgetsUUID } from '../widgets.types';
import { NewsPreviewDialogComponent } from './news-preview-dialog/news-preview-dialog.component';
import { TextDecodeHelper } from '../../../core/translation/text-decode.helper';
import { LanguageHelper } from '../../../core/language.helper';

@Component({
  selector: 'rag-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: [ './news-widget.component.scss' ],
})
export class NewsWidgetComponent
  extends BaseWidgetComponent
  implements WidgetComponent, OnInit, OnDestroy {

  static WidgetUUID = WidgetsUUID.NewsWidgetUUID;
  autoplay = true;
  @Input() didReceiveResponse: DidReceiveResponseHandler;
  hasResponse = false;
  imagesData: SlideshowTypes.Slide<News>[] = [];
  isDialogDisplayed = false;
  news: News[] = [];
  @Input() isTextBox: boolean;


  private readonly _destroy$ = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private infoService: InfoService,
  ) {
    super();
    this.setTitle($localize`:@@widget_news_title:News`);
  }

  hasText(
    news: News | null,
  ): boolean {
    return (news?.text != null) &&
      TextDecodeHelper.decodeHtml(LanguageHelper.objectToText(news.text)).trim() !== '';
  }

  ngOnInit() {
    this.loadNews();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  isDataDriven() {
    return true;
  }

  loadNews() {
    this.newsService.loadNews()
      .pipe(finalize(() => {
        this.didReceiveResponse({
          hasContent: this.news.length > 0,
        });
      }))
      .pipe(takeUntil(this._destroy$))
      .subscribe(news => {
        this.news = news;
        this.imagesData = this.news.map(this.newsToIImageConverter);
        this.hasResponse = true;
        if ( this.news.length !== 0 ) {
          this.setTitle('');
        }
      }, error => {
        if (error.error === 'not authorized') {
          this.infoService.showAlert($localize`:@@widget_news_not_authorized:You are not granted to read news. Please contact your system administrator for assistence`);
        }
      });
  }

  newsToIImageConverter = (news: News): SlideshowTypes.Slide<News> => ({
      data: news,
      imageUrl: ImageUrlHelper.urlForPictureId(news.pictureId),
      title: news.headline,
      text: news.text,
    });

  onClick = (slide: SlideshowTypes.Slide<News>) => {

    if ( !this.hasText(slide?.data) ) {
      return;
    }

    if ( this.isDialogDisplayed ) {
      return;
    }
    this.isDialogDisplayed = true;
    this.autoplay = false;

    this.infoService.showDialog(NewsPreviewDialogComponent, {
      news: slide.data,
    }).pipe(tap(() => {
      this.autoplay = true;
      this.isDialogDisplayed = false;
    }))
    .pipe(takeUntil(this._destroy$))
    .subscribe();
  };

}
