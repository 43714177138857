<rag-page-header [showSubTitle]="true">
  <div class="page-header-title" i18n="@@user_details_course_header">Course Details</div>
  <div class="page-header-sub-title details-list">
    <div class="details-item">
      <span class="label" i18n="@@global_user">User</span>
      <span>{{userFullName}}</span>
    </div>
    <div class="details-item">
      <span class="label" i18n="@@global_course">Course</span>
      <span>{{courseTitle}}</span>
    </div>
  </div>

  <div class="page-header-buttons">
    <rag-controlling-course-multi-actions
      [controllingComponent]="controllingComponent"
      [userDetailsView]="controllingComponent.userDetailsView"
    ></rag-controlling-course-multi-actions>
  </div>
</rag-page-header>

<rag-controlling-course
  #controllingComponent
  (needsReload$)="reloadData()"
  [userDetailsView]="userDetailsView"
></rag-controlling-course>
