import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { ContentService } from '../content/content.service';
import { ImageableContentReference } from '../core.types';


export const AccountResolverService: ResolveFn<ImageableContentReference[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  contentService: ContentService = inject(ContentService)
) => {
  const reset = route.queryParams['reset'] === 'true';
  return contentService.fetchAccountData(reset)
    .pipe(take(1));
};