<ng-container
  [ngSwitch]="displayMode"
>
  <ng-container *ngSwitchCase="'text'">
    <span class="col-label">{{durationText}}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'duration'">
    <div class="oneline">
      <span class="label" i18n="@@global_start">Begin</span>
      <span>{{content.eventDate | formatDate:'date-time'}}</span>
    </div>
    <div *ngIf="content.eventDateUntil" class="oneline">
      <span class="label" i18n="@@global_end">End</span>
      <span>{{content.eventDateUntil | formatDate:'date-time'}}</span>
    </div>
  </ng-container>
</ng-container>
