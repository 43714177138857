<!-- carousel -->
<div
  class="carousel"
  [attr.data-textbox]="isTextBox"
>
  <ng-container
    *ngFor="let slide of slides; let i = index"
  >
    <div
      (click)="onClick(slide)"
      *ngIf="i === currentSlide"
      @carouselAnimation
      [style]="slide._backgroundStyles"
      class="slide"
    >
      <div
        *ngIf="isTextBox"
        class="textbox"
        >
        <div class="title">{{slide.title | localeText}}</div>
        <div class="text" [innerHTML]="slide.text | localeText | safe:'html'"></div>
        <button
          data-cy="buttonMore"
          mat-flat-button
          color="primary"
          type="button"
          (click)="onClick(slide)"
        >
          <span i18n="@@button_more">Learn more</span>
        </button>
      </div>

      <div
        *ngIf="!isTextBox && (slide.title | localeText) as title"
        class="caption"
      >
        <span class="title">{{title}}</span>
        <a class="more" i18n="@@button_more">Learn more</a>
      </div>
      <div class="indicators">
        <span
          *ngFor="let slide of slides; let j = index"
          (click)="onIndicatorClick(j)"
          [class.active]="j === currentSlide"
          class="indicator"
        ></span>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="slides?.length > 1"
  >
    <!-- controls -->
    <button
      (click)="onPreviousClick()"
      class="control prev"
    >
      <span class="arrow left"></span>
    </button>
    <button
      (click)="onNextClick()"
      class="control next"
    >
      <span class="arrow right"></span>
    </button>
  </ng-container>
</div>
