import { Injectable } from '@angular/core';
import { UserProfileResponse } from './user-profile.types';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../core/api.urls';
import { AnyObject } from '../../../core/core.types';
import { UserProfileCredentialsResult } from './user-profile-credentials/user-profile-credentials.types';
import { map } from 'rxjs/operators';
import { TrainResponse } from 'src/app/core/global.types';


@Injectable({ providedIn: 'root' })
export class UserProfileService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getProfile(): Observable<UserProfileResponse> {
    const url = ApiUrls.getKey('UserProfileGet');
    return this.http.get<UserProfileResponse>(url);
  }

  updateCredentials(
    data: UserProfileCredentialsResult | null,
  ): Observable<UserProfileCredentialsResult | never> {

    if ( !data.currentPassword ) {
      return EMPTY;
    }

    const body = { currentPassword: data.currentPassword };
    if ( data.password ) {
      body['pass'] = data.password;
    }
    if ( data.login ) {
      body['login'] = data.login;
    }

    const url = ApiUrls.getKey('UserProfileUpdateCredentials');
    return this.http.post<void>(url, body)
      .pipe(map(_ => data));
  }

  updateLanguage(
    language: string,
  ): Observable<void> {
    const data = new FormData();
    data.append('json', JSON.stringify({ language }));

    const url = ApiUrls.getKey('UserProfileUpdateLanguage');
    return this.http.put<void>(url, data);
  }

  updatePassword(
    password: string,
  ): Observable<void> {
    const url = ApiUrls.getKey('UserProfileUpdatePassword');
    return this.http.put<void>(url, { pass: password });
  }

  updateProfile(
    changes: AnyObject,
  ): Observable<void> {
    const url = ApiUrls.getKey('UserProfileUpdate');
    return this.http.post<void>(url, changes);
  }

  deactivate(): Observable<boolean> {
    const url = ApiUrls.getKey('UserProfileDeactivate');
    return this.http.delete<TrainResponse<any>>(url).pipe(map(response => response.success));
  }
}
