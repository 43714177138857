<div class="filter-box " [ngClass]="{'new-design-border': outsourcedFilter}">
  <mat-form-field appearance="outline" [ngClass]="{'new-design': outsourcedFilter}">
    <mat-label *ngIf="labelText as _labelText else contains">{{_labelText}}</mat-label>
    <ng-template #contains>
      <mat-label i18n="@@pivot_filter_text_contains">contains...</mat-label>
    </ng-template>
    <mat-chip-grid
      #chipList
      [ngClass]="{'has-tags': (tagsSelected?.length > 0)}"
    >
      <mat-chip-row
        *ngFor="let tag of tagsSelected"
        [removable]="true"
        (removed)="onTagRemoved(tag)"
      >
        <span>{{tag.label}}</span>

        <button matChipRemove>
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </mat-chip-row>

      <input
        #inputElement
        #trigger="matAutocompleteTrigger"
        [placeholder]="placeholderText"
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        autocomplete="off"
        (blur)="onBlur()"
      >
    </mat-chip-grid>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onTagSelected($event, trigger)"
    >
      <mat-option
        *ngFor="let tag of tagsAvailable$ | async"
        [value]="tag"
      >{{tag.label}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
