import { SortDirection } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { TableControllerTypes } from '../table-controller/table-controller.types';
import { DateHelper } from '../../../core/date.helper';
import { TableHelper } from '../table-helper';
import { TableGroupingTypes } from './table-grouping.types';
import { uniq } from '../../../core/primitives/array.uniq';
import {
  ReportTableRow,
  ReportTableRowParent,
} from '../../../route/ctrl/report/report-generator-v2/report-table/report-table.types';
import { Report, ReportRow } from '../../../core/report/report.types';
import { ReportGeneratorV2Helper } from '../../../route/ctrl/report/report-generator-v2/report-generator-v2.helper';
import { Statistics } from '../../../route/ctrl/report/report-generator/status-statistics/status-statistics.types';
import { hasTableColumnDataTypeDate, hasTableColumnDataTypeDropDown } from '../table-column.types';


export class TableGroupingHelper {

  /**
   * calculate {@link TableGroupingTypes.TableRow.$sortDefaultAsc}, and
   * {@link TableGroupingTypes.TableRow.$sortDefaultDesc} to keep sorting stable.
   */
  static addDefaultSorting<T extends TableGroupingTypes.TableRow>(
    rows: T[] | null,
  ): void {

    if ( !(rows?.length > 0) ) {
      // skip empty collections
      return;
    }

    const maxLength = TableGroupingHelper.addDefaultSortingImpl(rows);
    rows.forEach(row => {
      row.$sortDefaultAsc = row.$sortDefaultAsc.padEnd(maxLength, '0');
      row.$sortDefaultDesc = row.$sortDefaultDesc.padEnd(maxLength, '9');
    });
  }

  static applyFilter<T extends TableGroupingTypes.TableRow>(
    rows: T[],
    filterPredicate: (tableRow: T) => boolean,
  ): T[] {
    const result = rows.map(row => {
      if ( filterPredicate != null ) {
        // check if row matches filter
        row.$filterVisible = filterPredicate(row);
        if ( row.$filterVisible ) {
          // row is visible -> expand parents
          TableGroupingHelper.applyFilterParentVisible(row);
        }
      } else {
        // no filter -> all rows visible (by filter)
        row.$filterVisible = true;
      }
      if ( row.$rowType === 'child' ) {
        // parent visibility must be updated after all children have been checked
        TableGroupingHelper.updateVisibility(row);
      }
      return row;
    });

    // re-calculate parent rows
    this.applyFilterRecalculateParentVisibility(result, filterPredicate);
    return result;
  }

  static applyFilterParentVisible<T extends TableGroupingTypes.TableRow>(
    row: T,
  ): void {
    if ( row == null ) {
      return null;
    } else if ( row.$rowType === 'parent' ) {
      (row as unknown as TableGroupingTypes.TableRowParent).$childVisible = true;
    }
    if ( row.$parent != null ) {
      TableGroupingHelper.applyFilterParentVisible(row.$parent);
    }
  }

  static calculateRowSort<T extends TableGroupingTypes.TableRowParent>(
    orderIndex: number,
    parent?: T,
  ): number[] {
    if ( parent != null ) {
      return [ ...parent.$rowSort ?? [], orderIndex ];
    } else {
      return [ orderIndex ];
    }
  }

  static collapseChildren(
    row: TableGroupingTypes.TableRow,
    collapseSelf?: boolean,
  ): boolean {
    if ( row?.$rowType !== 'parent' ) {
      return false;
    }

    const rowAsParent = row as TableGroupingTypes.TableRowParent;
    const childrenChanged = (rowAsParent.$children ?? [])
      .reduce((pV, child) => TableGroupingHelper
        .collapseChildren(child, true) || pV, false);

    const changed = (collapseSelf != null) && (rowAsParent.$expanded !== false);
    if ( changed ) {
      rowAsParent.$expanded = false;
      // children with collapsed parents are invisible
      rowAsParent.$children?.forEach(child => child.$visible = child.$filterVisible = false);
    }
    return childrenChanged || changed;
  }

  static compareRowSort(
    a: TableGroupingTypes.TableRow,
    b: TableGroupingTypes.TableRow,
  ): number {
    if ( a === b ) {
      return 0;
    }

    const sortA = a.$rowSort ?? [];
    const sortB = b.$rowSort ?? [];
    const minLength = Math.min(sortA.length, sortB.length);

    // compare each sort item and return the difference (if any)
    for ( let depth = 0; depth < minLength; depth++ ) {
      const result = sortA[depth] - sortB[depth];
      if ( result !== 0 ) {
        return result;
      }
    }
    // items with more depth are smaller
    return sortA.length - sortB.length;
  }

  static expandChildren(
    row: TableGroupingTypes.TableRow,
    expandSelf?: boolean,
  ): boolean {
    if ( (row == null) || (row.$rowType !== 'parent') ) {
      return false;
    }
    const rowAsParent = row as TableGroupingTypes.TableRowParent;
    const childrenChanged = (rowAsParent.$children ?? [])
      .reduce((pV, child) => TableGroupingHelper
        .expandChildren(child, true) || pV, false);

    const changed = (expandSelf != null) && (rowAsParent.$expanded !== true);
    if ( changed ) {
      rowAsParent.$expanded = true;
      // children with collapsed parents are invisible
      rowAsParent.$children?.forEach(child => child.$visible = true);
    }
    return childrenChanged || changed;
  }

  static expandParents(
    row: TableGroupingTypes.TableRow,
    expandSelf?: boolean,
  ): boolean {
    if ( row == null ) {
      return false;
    }

    let changed = false;
    if ( row.$rowType === 'parent' ) {
      const rowAsParent = row as TableGroupingTypes.TableRowParent;
      changed = (expandSelf != null) && rowAsParent.$expanded !== true;
      if ( changed ) {
        if ( expandSelf && (rowAsParent.$children?.length > 0) ) {
          rowAsParent.$expanded = true;
          // children with expanded parents are visible
          rowAsParent.$children?.forEach(child => child.$visible = true);
        } else {
          TableGroupingHelper.collapseChildren(row, true);
        }
      }
    }

    const parentsChanged = TableGroupingHelper.expandParents(row.$parent, true);
    return parentsChanged || changed;
  }

  static getChildren<T extends TableGroupingTypes.TableRow>(
    row: T,
  ): T[] {
    return TableGroupingHelper.getChildrenImpl(row) as T[];
  }

  static getPaddingLength(length: number): number {
    if ( !(length > 0) ) {
      // lengths need to be positive to need padding
      return 0;
    }

    if ( length === 1 ) {
      // log(1) === 0 -> at least one should be padded
      return 1;
    }

    // round log10 up
    return Math.ceil(Math.log(length) * Math.LOG10E);
  }

  static getParents<T extends TableGroupingTypes.TableRow, P extends TableGroupingTypes.TableRowParent>(
    rows: T[],
  ): P[] {
    const parentRows = (rows ?? [])
      .flatMap(row => {
        const parents = [];
        let parent = row.$parent;
        while ( parent != null ) {
          parents.push(parent);
          parent = parent.$parent;
        }
        return parents;
      });
    return uniq(parentRows);
  }

  static getSortGrouping<D extends TableGroupingTypes.TableRow>(
    data: D,
    direction: SortDirection,
  ): string {

    if ( data == null ) {
      return '';
    }

    if ( data.$rowType === 'parent' ) {
      if ( direction === 'desc' ) {
        return data.$sortDefaultDesc + '9';
      } else {
        return data.$sortDefaultAsc + '0';
      }
    }

    const parentSort = TableGroupingHelper.getSortGrouping(data.$parent, direction)
      // remove the last number to be replaced
      .slice(0, -1);

    if ( direction === 'desc' ) {
      return parentSort + '8';
    } else {
      return parentSort + '1';
    }
  }

  static getSortParent(
    row: TableGroupingTypes.TableRow,
    dataAccessor: (row) => string,
    sort?: string,
  ): string {
    const parent = row.$parent;
    if ( parent != null ) {
      const sortValue = dataAccessor(parent) + '$' + parent.$sortDefaultAsc;
      sort = (sort == null) ? '' : '_' + sort;
      return TableGroupingHelper.getSortParent(parent, dataAccessor, sortValue + sort);
    } else {
      return sort;
    }
  }

  /**
   * The method checks if the row itself or any of its children are a match for the filter
   */
  static isFilterVisible(
    row: TableGroupingTypes.TableRow,
  ): boolean {
    if ( row == null ) {
      // hide null
      return false;
    } else if ( row.$filterVisible ) {
      // the row itself is visible
      return true;
    } else if ( row.$rowType !== 'parent' ) {
      // the row does not have children
      return false;
    } else {
      // check if any child is visible
      return (row as TableGroupingTypes.TableRowParent).$children.find(TableGroupingHelper.isFilterVisible) != null;
    }
  }

  static isParentExpanded(
    row: TableGroupingTypes.TableRow,
  ): boolean {
    if ( row == null ) {
      // hide null rows
      return false;
    } else if ( row.$parent == null ) {
      // top-most layer is always visible
      return true;
    } else {
      // all parents must be expanded
      return TableGroupingHelper.isParentExpanded(row.$parent) &&
        // check immediate parent last
        row.$parent.$expanded;
    }
  }

  static onToggleSelection<T extends TableGroupingTypes.TableRow>(
    checked: boolean,
    row: T,
    selection: SelectionModel<T>,
  ): void {
    if ( row.$rowType === 'parent' ) {

      // select only child rows
      TableGroupingHelper.getChildren(row)
        // ignore any hidden rows
        .filter(entry => TableGroupingHelper.isFilterVisible(entry))
        // toggle each individual row
        .forEach(entry => TableGroupingHelper.onToggleSingleSelection(checked, entry, selection));

    } else {

      TableGroupingHelper.onToggleSingleSelection(checked, row, selection);
    }
  }

  static recreateArray<T extends TableGroupingTypes.TableRow>(
    rows: T[],
  ): T[] {

    if ( !(rows?.length > 0) ) {
      return [];
    }

    if ( rows[0].$rowType === 'child' ) {
      return rows;
    }

    const rootRows = rows.filter(row => (row.$parent == null) && (row.$rowType === 'parent'));

    return TableGroupingHelper.recreateArrayImpl(
      // start re-creation from the root groupings and flatten the rest
      rootRows,
    );
  }

  static sortingDataAccessor<D extends TableGroupingTypes.TableRow>(
    data: D,
    sortHeaderId: string,
    direction: SortDirection,
    dataAccessor: (data) => string,
  ): string {

    // fetch actual sorting value
    const rowSort = dataAccessor(data) ?? '';

    // ensure stable sorting of groupings
    const groupingSort = TableGroupingHelper.getSortGrouping(data, direction);
    return groupingSort + '|' + rowSort;
  }

  static sortingDataAccessorToString(
    value,
    column: TableControllerTypes.ColumnMenuItem,
  ): string {

    const dataType = column.options?.dataType;
    if ( hasTableColumnDataTypeDate(dataType) ) {
      const date = DateHelper.toMoment(value);
      return date?.unix().toString();
    }

    if ( hasTableColumnDataTypeDropDown(dataType) ) {
      const sortValue = column.options.dropDownOptions?.[value];
      if ( sortValue != null ) {
        return sortValue;
      }
    }

    return TableHelper.toString(value);
  }

  static treeWalker<T extends TableGroupingTypes.TableRow>(
    rows: T[],
    filterAction: (row: T) => boolean,
    parentAction: (row: TableGroupingTypes.TableRowParent) => void,
  ): void {
    let parentRows: TableGroupingTypes.TableRowParent[] = uniq(
      rows
        .filter(row => filterAction(row))
        .map(row => row.$parent)
        .filter(row => row != null),
    );

    do {
      parentRows = uniq(
        parentRows
          .map(row => {
            parentAction(row);

            // start next iteration with parent of parent
            return row.$parent;
          })
          .filter(row => row != null),
      );
    } while ( parentRows.length > 0 );
  }

  static updateIntermediateStatistics(
    parentStatistics: Statistics,
    intermediateChildRows: ReportTableRowParent[],
  ): void {

    const keys = Object.keys(parentStatistics);
    // reset all statistics values
    keys.map(key => parentStatistics[key] = 0);

    intermediateChildRows.map(row => {
      const statistics = row.statistics;
      if ( TableGroupingHelper.isFilterVisible(row) ) {

        // sum up all child statistics
        keys.map(key => parentStatistics[key] += statistics[key]);

      } else {

        // only add total count for hidden rows
        parentStatistics.grandTotalCount += statistics.grandTotalCount;
      }
    });
  }

  static updateSelectionStatus<T extends TableGroupingTypes.TableRow>(
    rows: T[],
    selection: SelectionModel<T>,
  ): void {

    if ( !(rows?.length > 0) ) {
      return;
    }

    const childRows = rows.filter(row => row.$rowType === 'child');

    TableGroupingHelper.treeWalker(childRows, row => {

      // the filter action is executed for each row -> update (temporary) $selectionStatus
      row.$selectionStatus = selection.isSelected(row) ? 'checked' : 'unchecked';

      // continue only with rows that are currently visible
      return TableGroupingHelper.isFilterVisible(row);

    }, parent => {

      // fetch selection status of all children
      const childStatus = parent.$children.map(child => child.$selectionStatus);
      const includesChecked = childStatus.includes('checked');
      if ( childStatus.includes('indeterminate') || (includesChecked && childStatus.includes('unchecked')) ) {
        parent.$selectionStatus = 'indeterminate';
      } else if ( includesChecked ) {
        parent.$selectionStatus = 'checked';
      } else {
        parent.$selectionStatus = 'unchecked';
      }
    });
  }

  static updateStatistics(
    report: Report,
    tableData: ReportTableRow[],
  ): void {

    const childEntries = tableData
      .filter(row => row?.$rowType === 'child');

    const rootGroupings = tableData
      .filter(row => (row?.$parent == null) && (row.$rowType === 'parent'));

    if ( rootGroupings.length === 0 ) {
      // there are no groupings at root level -> calculate only the child statistics
      const visibleRowData = childEntries
        .filter(row => TableGroupingHelper.isFilterVisible(row))
        .map(row => row.$data as ReportRow);
      const childStatistics = ReportGeneratorV2Helper.calculateStatistics(visibleRowData);
      childStatistics.grandTotalCount = childEntries.length;
      report.statistics = childStatistics;
      return;
    }

    TableGroupingHelper.treeWalker(childEntries, row => TableGroupingHelper.isFilterVisible(row),
      (parentRow: ReportTableRowParent) => {
        // the tree walker starts at the deepest grouping -> we can do an iterative update

        const children = parentRow.$children ?? [];

        const isIntermediateGrouping = children
          // a grouping is intermediate, if children are parents themselves
          .find(row => row.$rowType === 'parent') != null;

        if ( isIntermediateGrouping ) {

          const parentStatistics = parentRow.statistics =
            ReportGeneratorV2Helper.createEmptyStatistics(parentRow.statistics);
          TableGroupingHelper.updateIntermediateStatistics(parentStatistics, children as ReportTableRowParent[]);

        } else {

          const childData = children
            .filter(row => TableGroupingHelper.isFilterVisible(row))
            .map(row => (row.$data as ReportRow));
          const statistics = ReportGeneratorV2Helper.calculateStatistics(childData);
          statistics.grandTotalCount = children.length;
          parentRow.statistics = statistics;
        }
      });

    const reportStatistics = report.statistics =
      ReportGeneratorV2Helper.createEmptyStatistics(report.statistics);
    TableGroupingHelper.updateIntermediateStatistics(reportStatistics, rootGroupings as ReportTableRowParent[]);
  }

  static updateVisibility(
    row: TableGroupingTypes.TableRow,
  ): void {
    // parents must be expanded before checking!
    if ( TableGroupingHelper.isParentExpanded(row) ) {
      if ( TableGroupingHelper.isFilterVisible(row) ) {
        row.$visible = true;
      } else {
        row.$visible = row.$parent != null;
        // row (and children) no direct match -> collapse
        TableGroupingHelper.collapseChildren(row, true);
      }
    } else {
      // parent is collapsed -> hide row
      row.$visible = false;
    }
  }

  private static addDefaultSortingImpl<T extends TableGroupingTypes.TableRow>(
    rows: T[] | null,
  ): number {

    if ( !(rows?.length > 0) ) {
      // skip empty collections
      return 0;
    }

    let maxLength = 0;
    const paddingLength = TableGroupingHelper.getPaddingLength(rows.length);
    const arrayLength = rows.length;
    rows.forEach((row, index) => {

      if ( (row.$sortDefaultAsc != null) && (row.$sortDefaultDesc != null) ) {
        // skip if already visited
        return;
      }

      const parent = row.$parent;
      const $sortDefaultAsc =
        (parent == null ? '' : parent.$sortDefaultAsc + '0') +
        String(index + 1).padStart(paddingLength, '0');
      const $sortDefaultDesc =
        (parent == null ? '' : parent.$sortDefaultDesc + '9') +
        String(arrayLength - index).padStart(paddingLength, '0');

      row.$sortDefaultAsc = $sortDefaultAsc;
      row.$sortDefaultDesc = $sortDefaultDesc;
      maxLength = Math.max(maxLength, row.$sortDefaultAsc.length, row.$sortDefaultDesc.length);

      const parentRow = row as unknown as TableGroupingTypes.TableRowParent;
      if ( (parentRow.$rowType === 'parent') && (parentRow.$children?.length > 0) ) {

        const childMaxLength = TableGroupingHelper.addDefaultSortingImpl(parentRow.$children);
        maxLength = Math.max(maxLength, childMaxLength);

        row.$sortDefaultAsc = row.$sortDefaultAsc.padEnd(maxLength, '0');
        row.$sortDefaultDesc = row.$sortDefaultDesc.padEnd(maxLength, '9');
      }

    });

    return maxLength;
  }

  private static applyFilterRecalculateParentVisibility<T extends TableGroupingTypes.TableRow>(
    rows: T[],
    filterPredicate: (tableRow: T) => boolean,
  ) {
    rows
      .filter(row => row?.$rowType === 'parent')
      .map(row => row as unknown as TableGroupingTypes.TableRowParent)
      .forEach(row => {

        if ( row.$rowType !== 'parent' ) {
          return;
        }

        if ( filterPredicate == null ) {
          delete (row).$childVisible;
        }

        TableGroupingHelper.updateVisibility(row);

        if ( row.$children?.length > 0 ) {
          TableGroupingHelper.applyFilterRecalculateParentVisibility(row.$children, filterPredicate);
        }
      });
  }

  private static getChildrenImpl(
    row: TableGroupingTypes.TableRow,
  ): TableGroupingTypes.TableRow[] {
    if ( row.$rowType === 'parent' ) {
      return [ ...(row as TableGroupingTypes.TableRowParent).$children.flatMap(TableGroupingHelper.getChildrenImpl) ];
    } else {
      return [ row ];
    }
  }

  private static onToggleSingleSelection<T extends TableGroupingTypes.TableRow>(
    checked: boolean,
    row: T,
    selection: SelectionModel<T>,
  ): void {
    if ( checked !== selection.isSelected(row) ) {
      if ( checked ) {
        selection.select(row);
        if ( row.$rowType === 'child' ) {
          row.$selectionStatus = 'checked';
        } else {
          delete row.$selectionStatus;
        }
      } else {
        selection.deselect(row);
        if ( row.$rowType === 'child' ) {
          row.$selectionStatus = 'unchecked';
        } else {
          delete row.$selectionStatus;
        }
      }
    }
  }

  private static recreateArrayImpl<T extends TableGroupingTypes.TableRow>(
    rows: T[],
  ): T[] {

    if ( !(rows?.length > 0) ) {
      return [];
    }

    if ( rows[0].$rowType === 'child' ) {
      return rows;
    }

    return rows.flatMap((row) => [
        row,
        ...TableGroupingHelper.recreateArrayImpl((row as any).$children),
      ]) as T[];
  }

}
