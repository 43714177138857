import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileFieldComponent } from './profile-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileFieldDropdownComponent } from './profile-field-dropdown/profile-field-dropdown.component';
import { ProfileFieldTextComponent } from './profile-field-text/profile-field-text.component';
import { ProfileFieldRadioComponent } from './profile-field-radio/profile-field-radio.component';
import { ProfileFieldEmailComponent } from './profile-field-email/profile-field-email.component';
import { ProfileFieldPasswordComponent } from './profile-field-password/profile-field-password.component';
import { ProfileFieldNumberComponent } from './profile-field-number/profile-field-number.component';
import { PasswordModule } from '../password/password.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AbstractProfileFieldComponent } from './abstract-profile-field.class';
import { ProfileFieldDateComponent } from './profile-field-date/profile-field-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ProfileFieldMultiselectComponent } from './profile-field-multiselect/profile-field-multiselect.component';
import { MatIconModule } from '@angular/material/icon';
import { ProfileFieldConsentModule } from './profile-field-consent/profile-field-consent.module';
import { InfoTooltipButtonComponent } from '../../info-tooltip-button/info-tooltip-button.component';


@NgModule({
  declarations: [
    AbstractProfileFieldComponent,
    ProfileFieldComponent,
    ProfileFieldDropdownComponent,
    ProfileFieldTextComponent,
    ProfileFieldRadioComponent,
    ProfileFieldEmailComponent,
    ProfileFieldPasswordComponent,
    ProfileFieldNumberComponent,
    ProfileFieldDateComponent,
    ProfileFieldMultiselectComponent,
  ],
  exports: [ ProfileFieldComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PasswordModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    PipesModule,
    InfoTooltipButtonComponent,
    MatIconModule,
    ProfileFieldConsentModule,
  ],
})
export class ProfileFieldModule {
}
