<rag-content-offline-info
  [content]="offlineContent"
>
</rag-content-offline-info>

<ng-container
  *ngIf="hasEventSchedules && datasource.filteredData.length > 0"
>
  <div class="label info-text">
    <span i18n="@@offline_cnt_select_event_schedule">Available events you can choose from</span>:
  </div>
  <div class="table-wrapper">
    <table [dataSource]="datasource" mat-table class="no-hover">

      <!-- start date -->
      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef class="date tableHeader">
          <span i18n="@@global_start_date">Start date</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <rag-date-offset [date]="eventSchedule.eventDate"></rag-date-offset>
        </td>
      </ng-container>

      <!-- end date -->
      <ng-container matColumnDef="endDatum">
        <th *matHeaderCellDef class="date tableHeader">
          <span i18n="@@global_end_date">End date</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <rag-date-offset [date]="eventSchedule.eventDateUntil"></rag-date-offset>
        </td>
      </ng-container>

      <!-- title -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_title">Title</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          {{eventSchedule.title | localeText}}
        </td>
      </ng-container>

      <!-- trainers -->
      <ng-container matColumnDef="trainers">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_trainers">Trainers</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>

          <ng-template #noTrainers>
            <span class="empty_data_trainer">-</span>
          </ng-template>

          <div *ngIf="hasTrainers(eventSchedule); else noTrainers">
            <div *ngFor="let trainer of eventSchedule.trainers" class="trainer">
              {{(trainer.academicTitle === '-' || trainer.academicTitle == null) ? '' : trainer.academicTitle}} {{trainer.firstName}} {{trainer.lastName}}
            </div>
          </div>

        </td>
      </ng-container>

      <!-- location -->
      <ng-container matColumnDef="location">
        <th *matHeaderCellDef class="tableHeader" id="location">
          <span i18n="@@global_location">Location</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>

          <ng-template #noLocation>
            <span class="empty_data_trainer">-</span>
          </ng-template>

          <rag-location
            *ngIf="eventSchedule.location ; else noLocation"
            [location]="eventSchedule.location"
          ></rag-location>
        </td>
      </ng-container>

      <!-- ext login -->
      <ng-container matColumnDef="ext-login">
        <th *matHeaderCellDef class="tableHeader" id="ext-login">
          <span i18n="@@global_ext_login">Virtual platform</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>

          <ng-template #noVirtualLocation>
            <span class="empty_data_trainer">-</span>
          </ng-template>

          <span *ngIf="eventSchedule.extLogin else noVirtualLocation">
            {{eventSchedule.extLogin.serverName}}
          </span>
        </td>
      </ng-container>

      <!-- notices -->
      <ng-container matColumnDef="notices">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_description">Description</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <div class="center">
            <button (click)="showInfo(eventSchedule)" *ngIf="eventSchedule.description | localeText" mat-icon-button>
              <mat-icon svgIcon="information-outline"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <!-- free places -->
      <ng-container matColumnDef="freePlaces">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_free_places">Free places</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <div class="center" [ngClass]="getColorOfFreePlaces(eventSchedule)">
            {{getFreePlacesPlaceholder(eventSchedule)}}
          </div>
        </td>
      </ng-container>

      <!-- price -->
      <ng-container matColumnDef="price">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_price">Price</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <div class="right">
            <ng-template #tplPriceFree>
              <span i18n="@@global_price_free">free</span>
            </ng-template>
            <span
              *ngIf="price(eventSchedule) as price; else tplPriceFree"
            >{{price | currency:'EUR'}}</span>
          </div>
        </td>
      </ng-container>

      <!-- paymentStatus -->
      <ng-container matColumnDef="transactionStatus" *ngIf="hasTransactions()">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@global_transaction_status">Transaction status</span>
        </th>
        <td *matCellDef="let eventSchedule" mat-cell>
          <div class="right">
            <span
            *ngIf="getTransactionStatus(eventSchedule) as transactionStatus"
            >{{transactionStatus}}</span>
          </div>
        </td>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="tableHeader">
          <span i18n="@@content_overview_selectable_actions">Actions</span>
        </th>
        <ng-container
          *matCellDef="let eventSchedule; let i = index"
          mat-cell
          [ngTemplateOutlet]="isBlockEvent ? blockTemplate : commonTemplate"
          [ngTemplateOutletContext]="{ eventSchedule: eventSchedule, index: i }">
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let eventSchedule; columns: displayedColumns;"
          mat-row
      ></tr>
    </table>
  </div>
</ng-container>

<ng-template #cancelBookingTmpl let-eventSchedule="eventSchedule">
  <button
    type="button"
    mat-flat-button
    [disabled]="inputDisabled || isDisabledByBegin(eventSchedule)"
    color="warn"
    class="booking-button"
    (click)="onCancelBooking(eventSchedule)"
  >
    <!-- Cancel booking -->
    <ng-container *ngIf="hasBookedEventSchedule(eventSchedule)">
      <span i18n="@@cancel_event_schedule_booking">Cancel booking</span>
    </ng-container>

    <!-- Cancel application -->
    <ng-container *ngIf="hasApplied(eventSchedule)">
      <span i18n="@@withdraw_request">Withdraw request</span>
    </ng-container>

  </button>
</ng-template>

<ng-template #bookingTmpl let-eventSchedule="eventSchedule">
  <button
      *ngIf="!isDistributed(eventSchedule)"
      (click)="onBookEvent(eventSchedule)"
      [disabled]="isBookingButtonDisabled(eventSchedule)"
      color="primary"
      mat-flat-button
      type="button">

    <ng-container *ngIf="offlineContent.assignmentModeForPrincipal === 'take'">
      <span
        *ngIf="isPayable(eventSchedule)"
        i18n="@@global_buy">Buy</span>
      <span
        *ngIf="!isPayable(eventSchedule)"
        i18n="@@global_book">Book</span>
    </ng-container>

    <span
      *ngIf="offlineContent.assignmentModeForPrincipal === 'wish'"
      i18n="@@learner_offline_event_request_event">Request event</span>
  </button>
</ng-template>

<ng-template #commonTemplate let-eventSchedule="eventSchedule">
  <td class="actions">
    <ng-container
      [ngTemplateOutlet]="isCancelable(eventSchedule) ? cancelBookingTmpl : bookingTmpl"
      [ngTemplateOutletContext]="{eventSchedule: eventSchedule}"
    ></ng-container>
  </td>
</ng-template>

<ng-template #blockTemplate let-eventSchedule="eventSchedule" let-index="index">
  <td *ngIf="index === 0" class="actions block" [attr.rowspan]="rowsToSpan">
    <ng-container
      [ngTemplateOutlet]="isCancelable(eventSchedule) ? cancelBookingTmpl : bookingTmpl"
      [ngTemplateOutletContext]="{eventSchedule: eventSchedule}"
    ></ng-container>
  </td>
</ng-template>
