import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '../../../core/core.module';
import { ContentOverviewRoutingModule } from './certificates-routing.module';
import { CertificatesComponent } from './certificates.component';
import { CertificatesInfoComponent } from './certificates-info/certificates-info.component';
import { StatusLightsModule } from '../../../component/elearning/status-lights/status-lights.module';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { CertificateCardComponent } from 'src/app/component/card/certificate/certificate-card/certificate-card.component';


@NgModule({
  declarations: [ CertificatesComponent, CertificatesInfoComponent ],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        CommonModule,
        ContentOverviewRoutingModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatTableModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatMenuModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        DialogTitleComponent,
        DialogHeaderComponent,
        ClampComponent,
        PageHeaderComponent,
        StatusLightsModule,
        CertificateCardComponent
    ],
  exports: [
    CertificatesComponent, CertificatesInfoComponent,
  ],
})
export class CertificatesModule {
}
