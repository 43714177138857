import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RedirectHelper } from '../../../../core/redirect.helper';


@Injectable({ providedIn: 'root' })
export class LoginRedirectService {

  readonly redirectUrl$: Observable<string | null>;
  private _redirectUrlSubject = new EventEmitter<string | null>(null);

  constructor(
    private router: Router,
  ) {
    this.redirectUrl$ = this._redirectUrlSubject.asObservable()
      .pipe(debounceTime(250));

    this.redirectUrl$
      .pipe(tap(url => this.doRedirect(url)))
      .subscribe();
  }

  setRedirectUrl(url: string | null): void {
    if (!RedirectHelper.isValidRedirect(url)) {
      console?.warn(`[setRedirectUrl] replacing invalid redirect '${url}' with /`);
      url = '/';
    }

    console?.info(`[setRedirectUrl] queueing redirect '${url}'`);
    this._redirectUrlSubject.next(url);
  }

  private doRedirect(redirectUrl: string | null): Observable<void> {
    // clear any previous redirect
    RedirectHelper.clearRedirect();

    console?.info(`[doRedirect] redirecting to ${redirectUrl}`);
    return from(this.router.navigateByUrl(redirectUrl))
      .pipe(map(() => void (0)));
  }

}
