import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';

export namespace CtrlSingleUserCurriculumNotificationsColumns {

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    actions: TableControllerTypes.ColumnMenuItem;
    event: TableControllerTypes.ColumnMenuItem;
    expires: TableControllerTypes.ColumnMenuItem;
    offset: TableControllerTypes.ColumnMenuItem;
    title: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [],
    endWith: [ 'actions' ],
    menuItems: {
      title: TableControllerTypes.Util.textColumnFactory('title',
        $localize`:@@global_title:Title`, 0, true),
      event: TableControllerTypes.Util.textColumnFactory('event',
        $localize`:@@ctrl_single_user_curriculum_notification_event:Event`, 1, true),
      offset: TableControllerTypes.Util.textColumnFactory('offset',
        $localize`:@@ctrl_single_user_curriculum_notification_offset:Offset`, 2, true),
      expires: TableControllerTypes.Util.dateTimeColumnFactory('expires',
        $localize`:@@ctrl_single_user_curriculum_notification_expires:Expires`, 3, true),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };

}
