import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ApiUrls } from '../../../core/api.urls';

@Component({
  selector: 'rag-curriculum-cockpit',
  templateUrl: './curriculum-cockpit.component.html',
  styleUrls: ['./curriculum-cockpit.component.scss']
})
export class CurriculumCockpitComponent implements OnInit, OnDestroy {

  cockpitURL: string;
  cockpitTitle: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  get customViewURL() {
    return ApiUrls.getKey('CustomViewURL')
      .replace(/{customViewURL}/gi, this.cockpitURL);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }


  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  private updateRouteData(data): void {
    this.cockpitTitle = data.content.title;
    this.cockpitURL = data.content.extSysConfig.customViewHref;
  }

}
