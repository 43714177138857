import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableGroupingComponent } from './report-table-grouping.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PipesModule } from '../../../../../../core/pipes/pipes.module';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [ ReportTableGroupingComponent ],
  exports: [ ReportTableGroupingComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    PipesModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
  ],
})
export class ReportTableGroupingModule {
}
