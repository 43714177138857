import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SelfRegisterInfoResponse } from './self-register-form.types';
import { Observable } from 'rxjs';
import { SelfRegisterFormService } from './self-register-form.service';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class SelfRegisterFormResolver
  implements Resolve<SelfRegisterInfoResponse> {

  constructor(
    private selfRegisterFormService: SelfRegisterFormService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SelfRegisterInfoResponse> {
    const processHash = route.queryParamMap.get('p');
    return this.selfRegisterFormService.getInfo(processHash)
      .pipe(take(1));
  }

}
