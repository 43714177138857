import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { LiveAgendaDetailsDialogComponent } from './live-agenda-details-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { AttachmentsComponent } from 'src/app/component/attachments/attachments.component';
import { LocationComponent } from 'src/app/component/location/location.component';


@NgModule({
  declarations: [ LiveAgendaDetailsDialogComponent ],
  imports: [
    CommonModule,
    DialogHeaderComponent,
    AttachmentsComponent,
    MatDialogModule,
    MatButtonModule,
    PipesModule,
    MatIconModule,
    MatTableModule,
    LocationComponent,
    MatCardModule,
  ],
})
export class LiveAgendaDetailsDialogModule {
}
