import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { InfoType } from '../../../../core/info/info.types';
import { InfoService } from '../../../../core/info/info.service';
import { ProfileService } from '../../../../core/profile.service';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'rag-post-login-password',
  templateUrl: './post-login-password.component.html',
  styleUrls: [ './post-login-password.component.scss' ],
})
export class PostLoginPasswordComponent {

  public completed = false;
  public form: UntypedFormGroup;
  inputDisabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private matStepper: MatStepper,
    private profileService: ProfileService,
  ) {
    this.form = this.formBuilder.group({});
  }

  onSave() {
    if ( this.form.invalid ) {
      return;
    }

    this.inputDisabled = true;
    this.infoService.snackBar?.dismiss();
    const password = this.form.get('password').value;
    this.profileService.putPassword({
      pass: password,
    })
      .pipe(take(1))
      .pipe(finalize(() => this.inputDisabled = false))
      .pipe(catchError(this.handleError))
      .pipe(tap(() => {
        this.completed = true;
        setTimeout(() => this.matStepper.next());
      }))
      .subscribe();
  }

  private clearInputs = (): void => {
    const passwordControl = this.form.get('password');
    const passwordRepeatControl = this.form.get('passwordRepeat');

    passwordControl.setValue('');
    passwordControl.markAsUntouched();
    passwordRepeatControl.setValue('');
    passwordRepeatControl.markAsUntouched();
  };

  private handleError = (error): Observable<never> => {
    if ( error?.errorCode === '#UOLD4' ) {
      this.clearInputs();
      this.infoService.showMessage($localize`:@@post_login_error_password_identical:
        Please enter a password that is different from the current one.`, {
        infoType: InfoType.Warning,
        durationInSeconds: 30,
      });
    } else {
      this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
        { infoType: InfoType.Error });
    }
    return EMPTY;
  };

  getValidationOffset() {
    return screen.width > 425 ? 0 : 60;
  }

}
