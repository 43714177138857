<div class="sizing-container">
  <div class="container">
    <rag-page-header>
      <div class="page-header-title">
        <span i18n="@@ctrl_dashboard_headline">Dashboard</span>
      </div>

      <div class="page-header-buttons">
        <button
          (click)="toggleEdit()"
          [disabled]="!hasWidgets"
          color="primary"
          mat-flat-button>
          <span *ngIf="isInEditMode" i18n="@@home_toggle_done_layout">Done</span>
          <span *ngIf="!isInEditMode" i18n="@@home_toggle_edit_layout">Edit</span>
        </button>
      </div>
    </rag-page-header>

    <div class="rag-page-container">
      <rag-page #ragPage [pageId]="'layout-ctrl-dashboard'"></rag-page>
    </div>
  </div>
</div>
