import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Core } from 'src/app/core/core.types';
import { ApiUrls } from '../../../../core/api.urls';
import {
  LearnerCurriculumAccountView,
  LearnerCurriculumItemAccountView,
} from '../../../../core/learner-account/learner-account.types';
import { ViewHelper } from '../../../../core/view-helper';
import { LearnerContentTypes } from './learner-content.types';
import Extensions = LearnerContentTypes.Extensions;


export interface LearnerCourseResponse {
  contribution?: LearnerContentTypes.Contribution;
  course: LearnerContentTypes.CourseAccount;
  courseAccount: LearnerContentTypes.CourseAccount;
  curriculum?: LearnerCurriculumAccountView;
  curriculumItem?: LearnerCurriculumItemAccountView;
  extensions: Extensions[];
}

@Injectable({ providedIn: 'root' })
export class LearnerContentService {

  constructor(
    private http: HttpClient,
  ) {
  }

  static responseToAccount(response: LearnerCourseResponse): LearnerContentTypes.CourseAccount {
    const courseAccount = merge(response.course, response.courseAccount);

    const viewData = ViewHelper.getViewData(courseAccount);
    viewData.curriculumAccount = response.curriculum;
    viewData.curriculumItem = response.curriculumItem;
    viewData.extensions = response.extensions;

    switch ( courseAccount.courseType ) {
      case Core.CourseType.ToDo:
        (courseAccount as LearnerContentTypes.CourseAccountToDo).contribution = response.contribution;
        break;
      case Core.CourseType.Certification:
      case Core.CourseType.Test:
      case Core.CourseType.Learning:
      case Core.CourseType.ScoDocument:
      case Core.CourseType.Link:
      case Core.CourseType.SimpleConnect:
      default:
      // todo implement me!
    }
    return courseAccount;
  }

  getCourseAccount(courseId: number): Observable<LearnerContentTypes.CourseAccount> {
    const url = ApiUrls.getKey('LearnerCourseV2')
      .replace(/{courseId}/, String(courseId));
    return this.http.get<LearnerCourseResponse>(url)
      .pipe(map(LearnerContentService.responseToAccount));
  }

  getCourseAccountInCurriculum(curriculumId: number, itemId: number): Observable<LearnerContentTypes.CourseAccount> {
    const url = ApiUrls.getKey('LearnerCourseInCurriculum')
      .replace(/{curriculumId}/, String(curriculumId))
      .replace(/{curriculumItemId}/, String(itemId));
    return this.http.get<LearnerCourseResponse>(url)
      .pipe(map(LearnerContentService.responseToAccount));
  }

}
