import { Component, Input, OnInit } from '@angular/core';
import { MailService } from '../../mail.service';
import { MailEntry, ReportSettingsFunction, TrainReport } from '../../../../../core/mail.types';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageKey } from 'src/app/core/info/info.types';

@Component({
  selector: 'rag-reports-settings',
  templateUrl: './reports-settings.component.html',
  styleUrls: [ './reports-settings.component.scss' ],
})
export class ReportsSettingsComponent
  implements OnInit {

  @Input() mailEntry: MailEntry;
  @Input() setReportsSettings: ReportSettingsFunction;
  selectedReportContext = '';
  selectedReportId = 0;
  selectedReports: TrainReport[] = [];
  private _reports: TrainReport[] = [];

  constructor(
    private mailService: MailService,
    private infoService: InfoService,
  ) {
  }

  get reports(): TrainReport[] {
    return this._reports.filter(_report => _report.selected == null);
  }

  set reports(newValue: TrainReport[]) {
    this._reports = newValue;
  }

  addReport() {
    for (const report of this.reports) {
      if ( report.id === this.selectedReportId ) {
        report.selected = true;
        this.selectedReports.push(report);
        this.selectedReportId = 0;

        this.setReportsSettings(this.selectedReportContext, this.selectedReports);

        break;
      }
    }
  }

  isAddButtonDisabled(): boolean {
    return this.selectedReportId === 0 || this.selectedReportContext === '';
  }

  ngOnInit() {
    if ( this.mailEntry ) {
      this.selectedReportContext = this.mailEntry.workContextMode || '';
      if ( this.mailEntry.attachments ) {
        this.mailEntry.attachments.forEach(attachment => {
          if ( attachment.type === 'report' && attachment.fileId > 0 ) {
            this.selectedReports.push({
              id: attachment.fileId,
              selected: true,
              title: attachment.fileName,
            });
          }
        });
        this.setReportsSettings(this.selectedReportContext, this.selectedReports);
      }
    }
  }

  refresh() {
    this.mailService.fetchReports().subscribe(reports => {
      this.reports = reports;
    }, error => {
      if ( error.error === 'not authorized' ) {
        this.infoService.showSnackbar(MessageKey.ERROR.NOT_AUTHORIZED, InfoType.Warning);
      }
    });
  }

  removeReport(report: TrainReport) {
    const reportIndex = this.selectedReports.findIndex(_report => _report.id === report.id);
    if ( reportIndex > -1 ) {
      delete report.selected;
      this.selectedReports.splice(reportIndex, 1);

      this.setReportsSettings(this.selectedReportContext, this.selectedReports);
    }
  }

}
