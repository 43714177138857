import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseGroupToTgComponent } from './license-group-to-tg.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { TableHeaderFilterDefaultModule } from '../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { TableColumnMenuModule } from '../../../../component/table/table-column-menu/table-column-menu.module';
import { TablesColumnRenderDefaultModule } from '../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from '../../../../component/input/checkbox/checkbox.module';
import { LicenceGroupColumnHeaderModule } from './licence-group-column-header/licence-group-column-header.module';
import { LicenceInfoDialogModule } from './licence-info-dialog/licence-info-dialog.module';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { StickyScrollComponent } from 'src/app/component/sticky-scroll/sticky-scroll.component';
import { LicenceGroupColumnStatisticsComponent } from 'src/app/component/licence-group-column-statistics/licence-group-column-statistics.component';


@NgModule({
  declarations: [ LicenseGroupToTgComponent ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    StickyScrollComponent,
    MatTableModule,
    MatSortModule,
    RouterModule,
    TableHeaderFilterDefaultModule,
    PipesModule,
    LoadingIndicatorComponent,
    TableColumnMenuModule,
    TablesColumnRenderDefaultModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ClampComponent,
    CheckboxModule,
    LicenceGroupColumnHeaderModule,
    LicenceGroupColumnStatisticsComponent,
    LicenceInfoDialogModule,
  ],
})
export class LicenseGroupToTgModule {
}
