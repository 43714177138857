import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MenuSearchModule } from '../input/menu-search/menu-search.module';
import { TableControllerTypes } from '../table/table-controller/table-controller.types';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { LanguageHelper } from '../../core/language.helper';
import { CachedSubject } from '../../core/cached-subject';

@Component({
  selector: 'rag-table-filter-menu',
  standalone: true,
  imports: [CommonModule, CdkDrag, CdkDragHandle, CdkDropList, LoadingIndicatorComponent, MatButtonModule,
    MatCheckboxModule, MatIconModule, MatMenuModule, MenuSearchModule, FormsModule],
  templateUrl: './table-filter-menu.component.html',
  styleUrls: ['./table-filter-menu.component.scss']
})
export class TableFilterMenuComponent implements OnInit {
  @Input() filter: TableControllerTypes.ColumnMenuItem[];
  @Output() filterChanged = new EventEmitter();
  @Input() selectedFilter$: Observable<TableControllerTypes.ColumnMenuItem[]>;
  filteredFilter$: Observable<TableControllerTypes.ColumnMenuItem[]>;
  private _filteredFilter$ = new CachedSubject<TableControllerTypes.ColumnMenuItem[]>([]);
  loading = true;
  searchFilter: string = '';
  private _matMenuTrigger: MatMenuTrigger;
  private _matMenuTriggerRef: ElementRef<HTMLElement>;

  constructor() {
    this.filteredFilter$ = this._filteredFilter$.asObservable();
  }

  ngOnInit() {
    this._filteredFilter$.next(this.filter);
  }

  @ViewChild('matMenuTrigger', { read: MatMenuTrigger })
  set matMenuTrigger(value: MatMenuTrigger) {
    this._matMenuTrigger = value;
  }

  @ViewChild('matMenuTrigger', { read: ElementRef })
  set matMenuTriggerRef(value: ElementRef) {
    this._matMenuTriggerRef = value;
  }

  getSearchLabel(): string {
    return $localize`:@@global_search:Search`;
  }

  filterHasChanged(searchValue: string): void {
    if (!searchValue) {
      this._filteredFilter$.next(this.filter);
    } else {
      const tempFilter = this.filter
        .filter(filter =>
          LanguageHelper.objectToText(filter.title).toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()));
      this._filteredFilter$.next(tempFilter);
    }

  }

  columnToggled(filter: TableControllerTypes.ColumnMenuItem, event: MatCheckboxChange): void {
    this.filterChanged.emit({ filter: filter, active: event.checked });
  }


  openMenu($event: MouseEvent): boolean {
    const nativeElement = this._matMenuTriggerRef?.nativeElement;
    if ((nativeElement == null) || (this._matMenuTrigger == null)) {
      return;
    }

    $event.preventDefault();
    nativeElement.style.left = $event.clientX + 'px';
    nativeElement.style.top = $event.clientY + 'px';
    this._matMenuTrigger.openMenu();
    return false;
  }
}
