import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { DateHelper } from '../date.helper';

export enum FormatDateTypes {
  date = 'date',
  dateTime = 'dateTime',
  dateTimeSeconds = 'dateTimeSeconds',
  time = 'time',
  timeMilli = 'timeMilli',
  human = 'human',
}

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe
  implements PipeTransform {

  private static getFormatType(type: string): FormatDateTypes {
    if ( type && typeof (type) === 'string' ) {
      switch ( type ) {
        case 'date':
          return FormatDateTypes.date;
        case 'date-time':
          return FormatDateTypes.dateTime;
        case 'date-time-seconds':
          return FormatDateTypes.dateTimeSeconds;
        case 'time':
          return FormatDateTypes.time;
        case 'timeMilli':
          return FormatDateTypes.timeMilli;
        case 'human':
          return FormatDateTypes.human;
      }
    }
    return FormatDateTypes.date;
  }

  /**
   * FormatDateTypes {date, dateTime, time, human}
   */
  transform(value: Moment | Date | number | string | null, type?: string | FormatDateTypes): string {

    value = DateHelper.toMoment(value);
    if ( value == null ) {
      return '';
    }

    const format = FormatDatePipe.getFormatType(type);

    switch ( format ) {
      case FormatDateTypes.date:
        return value.format('L');
      case FormatDateTypes.dateTime:
        return value.format('L LT');
      case FormatDateTypes.dateTimeSeconds:
        return value.format('L LTS');
      case FormatDateTypes.time:
        return value.format('LT');
      case FormatDateTypes.timeMilli:
        return value.format('HH:mm:ss,SSS');
      case FormatDateTypes.human:
        return value.fromNow();
    }
  }
}
