import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { take, takeWhile, tap } from 'rxjs/operators';
import { TableColumnMenuService } from '../../table/table-column-menu/table-column-menu.service';
import { CtrlSingleUserDetailsCourseTypes } from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-course/ctrl-single-user-details-course.types';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';
import { LearnerAccountTypes } from '../../../core/learner-account/learner-account.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { CTRL_SINGLE_USER_MSG } from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details.types';
import { InfoType } from '../../../core/info/info.types';
import { Core } from '../../../core/core.types';
import { TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { PermissionStates } from '../../../core/principal/permission.states';
import { ControllingSingleUserService } from '../../../route/ctrl/single-user/ctrl-single-user-util/ctrl-single-user.service';
import { InfoService } from '../../../core/info/info.service';
import { AdminCoursesService } from '../../../route/admin/admin-courses/admin-courses-util/admin-courses.service';
import { PrincipalService } from '../../../core/principal/principal.service';
import { TableControllerComponent } from '../../table/table-controller/table-controller.component';
import { Observable } from 'rxjs';
import { LanguageHelper } from '../../../core/language.helper';
import { CtrlSingleUserDetailsLearningDataDialogComponent } from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-learning-data-dialog/ctrl-single-user-details-learning-data-dialog.component';
import { Router } from '@angular/router';
import { UserContentAction } from 'src/app/core/content/content.types';
import { ContentActionTransients } from 'src/app/core/transients.types';
import { AdminCoursesTypes } from '../../../core/admin-courses.types';


@Component({
  selector: 'rag-controlling-course',
  templateUrl: './controlling-course.component.html',
  styleUrls: [ './controlling-course.component.scss' ],
})
export class ControllingCourseComponent
  extends TableControllerComponent<ControllingSingleUserTypes.CourseAccountDetails>
  implements AfterViewInit, OnChanges, OnDestroy {

  canChangeData = false;
  @Output() readonly needsReload$: Observable<void>;
  permissions: PermissionStates;
  user: ControllingSingleUserTypes.ControllingUser;
  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;
  private _needsReload$ = new EventEmitter<void>(true);

  constructor(
    protected controllingSingleUserService: ControllingSingleUserService,
    private infoService: InfoService,
    private adminCourseService: AdminCoursesService,
    private principalService: PrincipalService,
    private router: Router,
    tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);

    this.needsReload$ = this._needsReload$.asObservable();

    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getInteractionsUrl(course: ControllingSingleUserTypes.CourseAccountDetails): string {
    return `/ctrl/user/${this.user?.userId}/course/${course?.courseId}/interactions`;
  }

  isScormContent(course: ControllingSingleUserTypes.CourseAccount): boolean {
    return LearnerAccountTypes.Util.hasRelevantScormDetailData(course);
  }

  mayManageLearningStatus() {
    for (const selection of this.selection.selected) {
      if ( !selection.rbacActions.maySave ) {
        return false;
      }
    }
    return true;
  }

  maySaveLearningStatus(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return course.rbacActions.maySave;
  }

  mayReadLearningStatus(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return course.rbacActions.mayRead;
  }

  ngAfterViewInit() {
    if ( !this.canChangeData ) {
      this.infoService.showMessage(CTRL_SINGLE_USER_MSG.OWN_LEARNING_STATUS_NOT_EDITABLE, {
        infoType: InfoType.Warning,
        durationInSeconds: 5,
      });
    }
  }

  ngOnChanges(
    changes: SimpleChanges,
  ): void {
    if ( changes.hasOwnProperty('userDetailsView') ) {
      this.updateRouteData(this.userDetailsView);
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onLearningDataBatch() {
    if ( this.selection.hasValue ) {
      if ( this.selection.selected.length === 1 ) {
        this.onLearningDataDialog(this.selection.selected[0]);
        return;
      }
      this.openLearningDataDialog(this.selection.selected);
    }
  }

  onLearningDataDialog(courseAccount: ControllingSingleUserTypes.CourseAccountDetails) {
    if ( courseAccount.courseType === Core.CourseType.ToDo ) {
      // todo support
      this.adminCourseService.getCourseAccountForUser(this.user.userId, courseAccount.courseId).subscribe(response => {
        const { course, contribution, extensions } = response;
        course.title = LanguageHelper.objectToText(course.title);
        course.courseType = courseAccount.courseType;
        this.openLearningDataDialog([ { ...courseAccount, ...course, contribution } ], extensions);
      });
      return;
    }
    this.openLearningDataDialog([ courseAccount ]);
  }

  onResetCourse(course: ControllingSingleUserTypes.CourseAccount): void {
    this.resetCourses([ course.courseId ]);
  }

  onResetCourses(): void {
    const courseIds = this.selection.selected
      .map(course => course.courseId);
    this.resetCourses(courseIds);
  }

  onScormLogDialog(course: ControllingSingleUserTypes.CourseAccount): void {
    this.controllingSingleUserService.showScormLogUserAndSco(this.user, course)
      .pipe(take(1))
      .subscribe();
  }

  onDownloadInteractionsPDF(course: ControllingSingleUserTypes.CourseAccount): void {
    this.controllingSingleUserService.downloadInteractionsPDF(this.user.userId, course.courseId);
  }

  onSessionDataDialog(course: ControllingSingleUserTypes.CourseAccount) {
    this.controllingSingleUserService.showSessionDataForUserAndContent(this.user, course)
      .pipe(take(1))
      .subscribe();
  }

  resetMenuItem(menuItem: TableControllerTypes.ColumnMenuItem): boolean {
    const resetValue = (menuItem.id === 'licenceEnded') ? 'false' : undefined;
    return super.resetMenuItem(menuItem, resetValue);
  }

  onDetails(course: ControllingSingleUserTypes.CourseAccountDetails) {
    this.router.navigateByUrl(`/ctrl/user/${this.user.userId}/course/${course.courseId}`).then();
  }

  private openLearningDataDialog(
    courses: ControllingSingleUserTypes.CourseAccountDetails[],
    extensions?: AdminCoursesTypes.CourseExtension[],
  ): void {
    this.infoService
      .showDialog<CtrlSingleUserDetailsLearningDataDialogComponent,
        CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams,
        boolean>(CtrlSingleUserDetailsLearningDataDialogComponent, {
        userId: this.user.userId,
        courses,
        extensions: extensions ?? []
      })
      .pipe(takeWhile(result => result === true))
      .pipe(tap(() => {
        this.infoService.showMessage(
          $localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
        this.reloadData();
      }))
      .subscribe();
  }

  private reloadData = (): void => {
    this._needsReload$.emit();
  };

  private resetCourses(courseIds: number[]): void {
    if ( !(courseIds.length > 0) ) {
      // nothing to do
      return;
    }

    this.controllingSingleUserService.resetCourses(this.user.userId, courseIds)
      .pipe(take(1))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(CtrlSingleUserDetailsCourseTypes.DEFAULT_MENU_COLUMNS);
    this.renderColumns = Object.values(menuData.menuItems)
      .filter(menuItem => !menuItem.hidden);
    this.setColumns(TableColumnMenuService.menuDataToColumns(menuData));
    this.columnMenuData = menuData;
  }

  private updateRouteData = (
    userDetailsView: ControllingSingleUserTypes.UserDetailsResponse,
  ): void => {
    this.userDetailsView = userDetailsView;
    this.isColumnContextLoaded = false;
    this.user = userDetailsView.controllingUser;
    this.canChangeData = this.user.userId !== this.principalService.userId;

    this.updateAvailableColumns();

    // save current selection
    const selectedCourseIds = (this.selection.selected ?? [])
      .map(row => row.courseId);
    this.selection.clear();

    // for all courses we have the same user, so we can exclude them from the context
    const contentActionsMap = this.userDetailsView.contentActions?.reduce((pV, contentAction) => {
      let actionsArray = pV.get(contentAction.objId);
      if (actionsArray == null) {
        actionsArray = [];
        pV.set(contentAction.objId, actionsArray);
      }
      actionsArray.push(contentAction);
      return pV;
    }, new Map<number, Array<UserContentAction>>());

    const actionsMap: ContentActionTransients = {
      actionsMap: contentActionsMap
    };

    userDetailsView.courses.forEach(c => c.$view = actionsMap);

    // replace rows
    this.setTableData(userDetailsView.courses ?? []);

    // restore selection
    this.selection.select(
      ...(this.dataSource.data ?? [])
        .filter(row => selectedCourseIds.includes(row.courseId)),
    );
    this.checkMultiActionsDisabled();

    this.inputDisabled = false;
    this.checkFilter();
  };
}
