import { Core } from './core.types';

export class DistributionTypeHelper {

  static asText(distType: Core.DistributableType, objSubType?: Core.CourseType): string {
    switch ( distType ) {
      case Core.DistributableType.lms_course: {
          switch (objSubType) {
            case Core.CourseType.Certification:
              return $localize`:@@admin_courses_type_certification:Certification`;
            case Core.CourseType.Test:
              return $localize`:@@global_distributable_subtype_test:Test`;
            case Core.CourseType.Learning:
              return $localize`:@@admin_courses_type_learning:Learning`;
            case Core.CourseType.ScoDocument:
              return $localize`:@@admin_courses_type_scoDocument_multimedia:Document / Multimedia`;
            case Core.CourseType.Link:
              return $localize`:@@admin_courses_type_link:Link`;
            case Core.CourseType.SimpleConnect:
              return $localize`:@@admin_courses_type_simpleConnect:Adobe Connect room`;
            case Core.CourseType.ToDo:
              return $localize`:@@admin_courses_type_toDo:Task`;
            case Core.CourseType.Recording: {
              return $localize`:@@global_recording:Recording`;
            }
          }
        }
        return $localize`:@@global_distributable_type_lms_course:Course`;
      case Core.DistributableType.lms_recording: {
        return $localize`:@@global_recording:Recording`;
      }
      case Core.DistributableType.lms_workflow:
        return $localize`:@@global_distributable_type_lms_workflow:Workflow`;
      case Core.DistributableType.skilltarget:
        return $localize`:@@global_distributable_type_skilltarget:Skill target`;
      case Core.DistributableType.lms_curriculum:
        return $localize`:@@global_distributable_type_lms_curriculum:Curriculum`;
      case Core.DistributableType.lms_offlineCnt:
        if (objSubType === Core.CourseType.Seminar) {
          return $localize`:@@global_seminar:On-site event`;
        } else if (objSubType === Core.CourseType.Virco) {
          return $localize`:@@global_virtual_conference:Virtual event`;
        } else if (objSubType === Core.CourseType.Hybrid) {
          return $localize`:@@global_hybrid_event:Hybrid event`;
        }
        return $localize`:@@global_event:Event`;
      case Core.DistributableType.lms_offline_event:
        return $localize`:@@global_distributable_type_lms_offline_event:Offline event`;
      case Core.DistributableType.lms_vroom_group:
        return $localize`:@@global_distributable_type_lms_vroom_group:Virtual classroom`;
      case Core.DistributableType.lms_context:
        return $localize`:@@global_distributable_type_lms_context:Context item`;
      case Core.DistributableType.lms_cur_path:
        return $localize`:@@global_distributable_type_lms_cur_path:Curriculum path`;

      default:
        // SCO object in course
        return '';
    }
  }

  static isCurriculum(distType: Core.DistributableType) {
    return distType === Core.DistributableType.lms_curriculum;
  }

  static isOfflineContent(distType: Core.DistributableType) {
    return distType === Core.DistributableType.lms_offlineCnt;
  }

  static isWBT(distType: Core.DistributableType) {
    return distType === Core.DistributableType.lms_course;
  }

  static getOfflineContentSubType(distType: Core.DistributableType, objSubType: Core.CourseType): string | null {
    if (distType == null || objSubType == null) {
      return null;
    }

    if (distType !== Core.DistributableType.lms_offlineCnt) {
      return null;
    }

    switch(objSubType) {
      case Core.CourseType.Seminar:
        return $localize`:@@on_site:On-site`;
      case Core.CourseType.Virco:
        return $localize`:@@virtual:Virtual`;
      case Core.CourseType.Hybrid:
        return $localize`:@@hybrid:Hybrid`;
    }
    return null;
  }
}
