<form [formGroup]="form" class="center-content" (ngSubmit)="onSave()">

  <p i18n="@@post_login_hint_update_password">Before you can begin, you will have to change your password.</p>

  <rag-password
    appearance="outline"
    [formGroup]="form"
    [disabled]="completed"
    [validationOffsetY]="getValidationOffset()"
  ></rag-password>

  <div class="buttons">
    <button
      [disabled]="form.pristine || form.invalid"
      color="primary"
      mat-flat-button
      type="submit"
      class="submit-button"
    >
      <span i18n="@@global_next">Next</span>
    </button>
  </div>
</form>
