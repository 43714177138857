import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ContentDurationComponent } from './content-duration.component';


@NgModule({
  declarations: [ ContentDurationComponent ],
  exports: [ ContentDurationComponent ],
  imports: [
    CommonModule,
    PipesModule,
  ],
})
export class ContentDurationModule {
}
