import { Injectable } from '@angular/core';
import {
  AdminGamificationSettings, AdminGamificationSettingsSaveData,
  BadgeContentConfig,
  BadgeContentConfigDialogData, BadgeContentConfigDialogResult,
  BadgeContentConfigSaveData,
  BadgeTemplate, BadgeTemplateSaveData, BadgeTemplateUsages,
} from './gamification.types';
import { combineLatest, EMPTY, Observable, of, switchMap } from 'rxjs';
import { ApiUrls } from '../api.urls';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../global.types';
import { map, take, tap } from 'rxjs/operators';
import { Core } from '../core.types';
import DistributableType = Core.DistributableType;
import { AdminBadgeTemplateSelectDialogComponent } from '../../route/admin/admin-gamification/admin-badge-template-select-dialog/admin-badge-template-select-dialog.component';
import { InfoService } from '../info/info.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGamificationService {
  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  createEmptyBadgeTemplate(): Observable<BadgeTemplate> {
    return of({
      pictureUUID: null,
      templateUUID: null,
      picture: null,
      adminTitle: '',
      pictureId: null,
      accountId: null,
      adminDescription: '',
      contents: null,
    })
  }

  getBadgeTemplate(badgeTemplateUUID: string): Observable<BadgeTemplate> {
    const url = ApiUrls.getKey('AdminGamificationBadgeTemplateDetails')
      .replace(/{badgeTemplateUUID}/gi, badgeTemplateUUID)

    return this.http.get<ApiResponse<BadgeTemplate>>(url)
      .pipe(map(response => response.template))
  }

  getBadgeTemplateUsages(badgeTemplateUUID: string): Observable<BadgeTemplateUsages[]> {
    const url = ApiUrls.getKey('AdminGamificationBadgeTemplateUsages')
      .replace(/{badgeTemplateUUID}/gi, badgeTemplateUUID);

    return this.http.get<ApiResponse<BadgeTemplateUsages[]>>(url)
      .pipe(map(response => response.usages))
  }

  getAllBadgeTemplates(): Observable<BadgeTemplate[]> {
    const url = ApiUrls.getKey('AdminGamificationBadgeTemplates');

    return this.http.get<ApiResponse<BadgeTemplate[]>>(url)
      .pipe(map(response => response.templates));
  }

  getBadgeContentConfig(objectId: number, objectType: DistributableType): Observable<BadgeContentConfig> {
    const url = ApiUrls.getKey('AdminGamificationGetContentConfig')
      .replace(/{objectId}/gi, String(objectId) )
      .replace(/{objectType}/gi, objectType);

    return this.http.get<ApiResponse<BadgeContentConfig>>(url)
      .pipe(map(response => response.contentConfig));
  }

  onOpenBadgeTemplateDialog(objectId: number, objectType: DistributableType, callback?: (button) => void ): void {
    combineLatest([
      this.getAllBadgeTemplates(),
      this.getBadgeContentConfig(objectId, objectType)
    ])
      .pipe(switchMap(([badgeTemplate, currentBadgeContentConfig]) =>
        this.infoService.showDialog<AdminBadgeTemplateSelectDialogComponent, BadgeContentConfigDialogData, BadgeContentConfigDialogResult>(
          AdminBadgeTemplateSelectDialogComponent, {
            objectId: objectId,
            objectType: objectType,
            badgeTemplates: badgeTemplate,
            currentContentConfig: currentBadgeContentConfig,
          })
          .pipe(map(response => {
            if (callback !== undefined && response != null) {
              callback(response.button);
            }
          }))
      ))
      .subscribe();
  }

  deleteBadgeTemplate(badgeTemplateUUID: string): Observable<BadgeTemplate> {
    const url = ApiUrls.getKey('AdminGamificationDeleteBadgeTemplate')
      .replace(/{badgeTemplateUUID}/gi, badgeTemplateUUID);

    return this.http.delete<ApiResponse<BadgeTemplate>>(url)
      .pipe(map(response => response.template))
  }

  deleteContentConfig(badgeConfigUUID: string): Observable<BadgeContentConfig> {
    const url = ApiUrls.getKey('AdminGamificationDeleteContentConfig')
      .replace(/{badgeConfigUUID}/gi, badgeConfigUUID);

    return this.http.delete<ApiResponse<BadgeContentConfig>>(url)
      .pipe(map(response => response.contentConfig));
  }

  saveBadgeTemplate(badgeTemplate: BadgeTemplateSaveData): Observable<BadgeTemplate> {
    const url = ApiUrls.getKey('AdminGamificationSaveBadgeTemplate');

    return this.http.post<ApiResponse<BadgeTemplate>>(url, badgeTemplate)
      .pipe(map(data => data.template));
  }

  saveContentConfig(saveData: BadgeContentConfigSaveData): Observable<BadgeContentConfig> {
    const url = ApiUrls.getKey('AdminGamificationContentConfig');

    return this.http.post<ApiResponse<BadgeContentConfig>>(url, saveData)
      .pipe(map(response => response.contentConfig));
  }

  saveAdminGamificationSettings(saveData: AdminGamificationSettingsSaveData): Observable<AdminGamificationSettings> {
    const url = ApiUrls.getKey('AdminGamificationSettings');

    return this.http.post<ApiResponse<AdminGamificationSettings>>(url, saveData)
      .pipe(map(response => response.config));
  }
}
