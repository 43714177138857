import { Component, OnDestroy } from '@angular/core';
import { Report, ReportConfig } from '../../../../../../core/report/report.types';
import { ReportTableService } from '../report-table.service';
import { take, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../../core/reactive/until-destroyed';
import { Statistics } from '../../../report-generator/status-statistics/status-statistics.types';
import { PermissionStates } from '../../../../../../core/principal/permission.states';
import { PrincipalService } from '../../../../../../core/principal/principal.service';
import { ExportService } from '../../../../../../core/export.service';
import { ReportService } from '../../../../../../core/report/report.service';
import { WidgetsUUID } from '../../../../../../rag-layout/widgets/widgets.types';
import { InfoType } from '../../../../../../core/info/info.types';
import { RagPageService } from '../../../../../../rag-layout/rag-page/rag-page.service';
import { InfoService } from '../../../../../../core/info/info.service';
import { MergeHelper } from '../../../../../../core/primitives/merge.helper';
import { User } from '../../../../../../core/core.types';


@Component({
  selector: 'rag-report-table-multi-actions',
  templateUrl: './report-table-multi-actions.component.html',
  styleUrls: [ './report-table-multi-actions.component.scss' ],
})
export class ReportTableMultiActionsComponent
  implements OnDestroy {

  private _loading = true;
  private _permissions: PermissionStates;
  private _report: Report;

  constructor(
    private exportService: ExportService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private ragPageService: RagPageService,
    private reportService: ReportService,
    private reportTableService: ReportTableService,
  ) {
    this.principalService.permissionStates$
      .pipe(tap(permissions => this._permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.reportTableService.loading$
      .pipe(tap(loading => this._loading = loading))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.reportTableService.report$
      .pipe(tap(this.updateReport))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get canSaveNewReport(): boolean {
    return this.reportTableService.canSaveNewReport;
  }

  get hasSelections(): boolean {
    return this.reportTableService.selectedRows.length > 0;
  }

  get isDirty(): boolean {
    return this.reportTableService.isDirty;
  }

  get isNew(): boolean {
    return this.reportTableService.isNew;
  }

  get loading(): boolean {
    return this._loading;
  }

  get permissions(): PermissionStates {
    return this._permissions;
  }

  get reportConfig(): ReportConfig | null {
    return this._report?.reportConfig;
  }

  get reportStatistics(): Statistics | null {
    return this._report?.statistics;
  }

  addWidgetBarChart() {
    this.addWidget(WidgetsUUID.ReportBarChartWidgetUUID);
  }

  addWidgetPieChart(): void {
    this.addWidget(WidgetsUUID.ReportPieChartWidgetUUID);
  }

  addWidgetReportLinks() {
    this.ragPageService.addReportLinksWidget('layout-ctrl-dashboard', this.reportConfig)

      .pipe(tap(success => {
        if ( success ) {
          this.infoService.showMessage(
            $localize`:@@ragpage_add_widget_success:Widget successfully added`,
            { infoType: InfoType.Success });
        } else {
          this.infoService.showMessage(
            $localize`:@@ragpage_add_failed_exists_already:A widget for this report is already present.`,
            { infoType: InfoType.Warning });
        }
      }))

      .pipe(take(1))
      .subscribe();
  }

  getSelectionsForTargetGroup(): User[] {
    const userMap = (this.reportTableService.selectedRows ?? [])
      .filter(entry => (entry?.userId > 0))
      .map(entry => ({
        userId: entry.userId,
        firstname: entry.userFirstname,
        lastname: entry.userLastname,
      } as User))
      .reduce((pV, user) => {
        pV[user.userId] = user;
        return pV;
      }, {});
    return Object.values(userMap);
  }

  mayDownloadCertificates(): boolean {
    return this.hasSelections;
  }

  maySave(): boolean {
    return this.reportTableService.selectedRows
      ?.find(row => this.reportTableService.maySave(row)) != null;
  }

  maySendMessage(): boolean {
    return this.reportTableService.selectedRows
      ?.find(row => this.reportTableService.maySendMessage(row)) != null;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onCSVExport() {
    const reportConfig = MergeHelper.cloneDeep(this.reportTableService.reportConfig);
    reportConfig.selectedRows = (this.reportTableService.selectedRows?.map(row => row.entityIdentity) ?? [])
      .filter(entry => entry != null);
    this.exportService.csvExport(reportConfig);
  }

  onDownloadCertificates() {
    const targetObjects = this.reportTableService.selectedRows
      .filter(entry => (entry?.userId > 0) && this.reportTableService.isCurriculum(entry))
      .map(entry => ({
        userId: entry.userId,
        curriculumId: entry.curriculumId,
        // -1 targets current iteration
        iteration: entry.accountIteration ?? -1,
      }));
    this.reportService.requestCertificatesForCurriculaAndUsers(targetObjects);
  }

  onSaveAsReport(): void {
    this.reportTableService.saveReportConfigAs();
  }

  onSaveReport(): void {
    this.reportTableService.saveReportConfig();
  }

  onSendMessage(): void {
    this.reportTableService.onSendMessage(this.reportTableService.selectedRows);
  }

  onSwitchPath(): void {
    this.reportTableService.onSwitchPath(this.reportTableService.selectedRows);
  }

  onForceReload(): void {
    this.reportTableService.onForceReload();
  }

  onXLSXExport(): void {
    const reportConfig = MergeHelper.cloneDeep(this.reportTableService.reportConfig);
    reportConfig.selectedRows = (this.reportTableService.selectedRows?.map(row => row.entityIdentity) ?? [])
      .filter(entry => entry != null);
    this.exportService.xlsxExport(reportConfig);
  }

  onXLSXExportLearningTime(): void {
    const reportConfig = MergeHelper.cloneDeep(this.reportTableService.reportConfig);
    reportConfig.selectedRows = (this.reportTableService.selectedRows?.map(row => row.entityIdentity) ?? [])
      .filter(entry => entry != null);
    this.exportService.xlsxExportLearningTime(reportConfig);
  }

  private addWidget(widgetUuid: string): void {
    if ( !this._permissions?.navCtrlReportDashboard ||
      (this.reportConfig?.targetType !== 'Curriculum') ) {
      return;
    }

    this.reportService.addWidget({
      pageId: 'layout-ctrl-dashboard',
      reportConfig: this.reportConfig,
      widgetUUID: widgetUuid,
    });
  }

  private updateReport = (report: Report | null): void => {
    this._report = report;
  };

}
