import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { tap } from 'rxjs/operators';
import { LinkedUsersService } from '../../core/user/link/linked-users.service';
import { LinkedUserTypes } from '../../core/user/link/linked-users.types';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DialogHeaderComponent
  ],
  selector: 'rag-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: [ './add-user-dialog.component.scss' ],
})
export class AddUserDialogComponent
  implements OnInit {
  inputLogin = new FormControl('');
  inputPassword = new FormControl('');
  formGroupAddUser = new FormGroup({
    login: this.inputLogin,
    password: this.inputPassword,
  });

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private linkedUsersService: LinkedUsersService,
  ) {
  }

  ngOnInit() {
  }

  onClick(confirmed: boolean): void {
    const data: LinkedUserTypes.AddLinkedUser = {
      login: this.inputLogin.value,
      password: this.inputPassword.value,
    };

    // reset password
    this.inputPassword.reset();

    if ( confirmed ) {
      this.linkedUsersService.addLinkToUser(data)
        .pipe(tap(() => this.dialogRef.close()))
        .subscribe();
    } else {
      this.dialogRef.close();
    }
  }
}
