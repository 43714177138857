<button
  *ngIf="!hasError(form)"
  type="button" mat-flat-button color="primary"
  (click)="onSubmit()"
  [disabled]="disabled"
  data-cy="buttonSave"
>
  <mat-spinner
    *ngIf="isLoading(form)"
    diameter="16"
  ></mat-spinner>
  {{ label }}
</button>

<button
  *ngIf="hasError(form)"
  type="button" mat-flat-button color="warn"
  (click)="onSubmitCheckInvalid()"
  data-cy="buttonSaveInvalid"
>
  <mat-icon svgIcon="alert-outline"></mat-icon>
  {{ label }}
</button>
