import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfileCredentialsData, UserProfileCredentialsResult } from './user-profile-credentials.types';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';
import { InfoService } from '../../../../core/info/info.service';
import { UserProfileService } from '../user-profile.service';
import { catchError, map, take } from 'rxjs/operators';
import { InfoType, MessageConstants } from '../../../../core/info/info.types';
import { EMPTY } from 'rxjs';
import { UserProfileHelper } from '../user-profile.helper';
import { destroySubscriptions } from 'src/app/core/reactive/until-destroyed';


@Component({
  selector: 'rag-user-profile-credentials',
  templateUrl: './user-profile-credentials.component.html',
  styleUrls: [ './user-profile-credentials.component.scss' ],
})
export class UserProfileCredentialsComponent implements OnInit, OnDestroy, AfterViewInit {

  formGroup = new FormGroup<any>({});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserProfileCredentialsData,
    private dialogRef: MatDialogRef<UserProfileCredentialsComponent>,
    private infoService: InfoService,
    private userProfileService: UserProfileService
  ) {
  }

  ngOnInit(): void {
    UserProfileCredentialsComponent.addFormControl(this.formGroup, this.data.loginField);
    UserProfileCredentialsComponent.addFormControl(this.formGroup, this.data.passwordField);

    this.formGroup.addControl('currentPassword',
      new UntypedFormControl(null, [ Validators.required ]));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formGroup.get('currentPassword').setValue(null);
    }, 0);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onSubmit(
    $event?: MouseEvent,
  ): void {
    $event?.preventDefault();

    const formGroup = this.formGroup;
    if ( !formGroup.valid ) {
      return;
    }

    const result: UserProfileCredentialsResult = {
      login: UserProfileCredentialsComponent.getControlValue(formGroup, this.data.loginField),
      currentPassword: this.formGroup.get('currentPassword')?.value,
      password: UserProfileCredentialsComponent.getControlValue(formGroup, this.data.passwordField),
    };

    if ( !result.currentPassword || !(result.password || result.login) ) {
      // password is always required and one of the other fields has to be changed
      return this.dialogRef.close();
    }

    this.userProfileService.updateCredentials(result)
      .pipe(take(1))
      .pipe(catchError(error => {
        const message = UserProfileHelper.getErrorMessage(error) ?? MessageConstants.API.ERROR;
        this.infoService.showMessage(message, { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(map(() => {
        const message = UserProfileCredentialsComponent.getUpdateMessage(result);
        this.infoService.showMessage(message, { infoType: InfoType.Success });
        this.dialogRef.close(result);
      }))
      .subscribe();
  }

  private static addFormControl(
    formGroup: FormGroup,
    field: ProfileFieldTypes.ProfileField | null,
  ): void {

    if ( field == null ) {
      return;
    }

    const formControl = new UntypedFormControl(field.value, [ Validators.required ]);
    if ( !field.editable ) {
      formControl.disable();
    }
    formGroup.addControl(field.fieldId, formControl);
  }

  private static getControlValue(
    formGroup: FormGroup,
    field: ProfileFieldTypes.ProfileField | null,
  ): string | null {

    const formControl = formGroup.get(field?.fieldId);
    if ( !formControl?.touched ) {
      return null;
    }

    return ProfileFieldTypes.fromControlValue(field.type, formControl.value);
  }

  private static getUpdateMessage(
    result: UserProfileCredentialsResult,
  ): string {

    if ( result.login && result.password ) {
      return $localize`:@@user_profile_edit_credentials_login_pass:Your login credentials have been changed.`;

    } else {
      return $localize`:@@user_profile_edit_credentials_pass:Your password has been changed.`;
    }

  }

}
