import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTargetGroupsMenuComponent } from './select-target-groups-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MenuSearchModule } from '../input/menu-search/menu-search.module';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';


@NgModule({
  declarations: [ SelectTargetGroupsMenuComponent ],
  exports: [ SelectTargetGroupsMenuComponent ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MenuSearchModule,
    LoadingIndicatorComponent,
  ],
})
export class SelectTargetGroupsMenuModule {
}
