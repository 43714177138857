import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule
  ],
  selector: 'rag-logout-warning-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: [ './logout-dialog.component.scss' ],
})
export class LogoutDialogComponent
  implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<LogoutDialogComponent>,
  ) {
  }

  ngOnInit() {
  }

  onLogoutClick() {
    this.dialogRef.close(true);
  }

  onStayClick() {
    this.dialogRef.close(false);
  }

}
