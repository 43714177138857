<div class="switch-view-wrapper" *ngIf="isDataLoaded && !embedded && !isDataEmpty">
  <button
    mat-button
    (click)="showFilter = !showFilter"
    type="button"
    [disabled]="isDataEmpty"
  >
    <mat-icon svgIcon="tune-variant"></mat-icon>
    <span *ngIf="!showFilter" i18n="@@global_show_filter">Show filter</span>
    <span *ngIf="showFilter" i18n="@@global_hide_filter">Hide filter</span>
  </button>

  <button
    mat-button
    class="icon-button"
    type="button"
    (click)="toggleViewMode()"
    [ngSwitch]="isCards"
    [disabled]="isDataEmpty"
  >
    <ng-container *ngSwitchCase="true">
      <mat-icon color="primary" svgIcon="view-list"></mat-icon>
      <span i18n="@@overview_list_view">List view</span>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <mat-icon color="primary" svgIcon="view-module"></mat-icon>
      <span i18n="@@overview_cards_view">Cards view</span>
    </ng-container>
  </button>
</div>

<mat-expansion-panel [expanded]="showFilter" class="expansion-filter" *ngIf="!embedded && isDataLoaded">
  <rag-content-filter
    class="filter"
    [filters$]="columnFilters$"
    (filtersChanged)="onFilterChanged($event)"
  >
  </rag-content-filter>
</mat-expansion-panel>

<div
  *ngIf="isDataLoaded && !isNothing(sortedData); else tplDataLoading"
  [@.disabled]="disableAnimations$ | async"
>
  <ng-container
    [ngTemplateOutlet]="getTemplate(tplDataLoading, tplDataEmpty, tplCards, tplTable)"
  ></ng-container>
</div>


<ng-template #tplCards>
  <div
    class="card-container" [ngClass]="{'card-container-notfullscreen': !fullScreenSizeEnabled, 'card-container-fullscreen': fullScreenSizeEnabled}"
    *ngIf="!isDataEmpty; else tplDataEmpty"
    @cardAnimation
  >

    <ng-container *ngFor="let catalogEntry of sortedData">
      <ng-container
        [ngTemplateOutlet]="permissionStates.showNewUserInterfaceCards ? newCards : standardCards"
        [ngTemplateOutletContext]="{catalogEntry}"
      ></ng-container>
    </ng-container>

    <div class="no-data" *ngIf="isFilteredDataEmpty">
      <span i18n="@@catalog-no-match">No catalog entries match your search criteria</span>
    </div>
  </div>

</ng-template>

<ng-template #tplTable>

  <rag-sticky-scroll
    *ngIf="!isCards"
    [recalculate]="recalculateSticky$"
  >
    <table
      @listAnimation
      mat-table
      [dataSource]="dataSource"
      class="table-container"
      matSort
      [matSortActive]="sortBy"
    >

      <!-- Image -->
      <ng-container matColumnDef="image">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@image">Image</span>
        </th>
        <td *matCellDef="let catalogEntry" mat-cell>
          <div class="image-container">
            <img
              class="max-size"
              mat-card-image
              [ragDefault]="getDefaultPictureForType(catalogEntry)"
              alt="curriculum picture"
              src="{{imageUrlFor(catalogEntry)}}"
            />
          </div>
        </td>
      </ng-container>

      <!-- Title -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@title">Title</span>
        </th>
        <td *matCellDef="let catalogEntry" mat-cell>
          <rag-clamp
            [clamp]="3"
            [input]="catalogEntry.title | localeText"
          ></rag-clamp>
          <div class="type">
            {{typeForCatalogEntry(catalogEntry)}}
          </div>
        </td>
      </ng-container>

      <!-- Description -->
      <ng-container matColumnDef="description">
        <th *matHeaderCellDef mat-header-cell>
          <span i18n="@@global_description">Description</span>
        </th>
        <td *matCellDef="let catalogEntry" mat-cell>
          <div
            *ngIf="!isNothing(catalogEntry.description)"
            class="description"
            [innerHTML]="catalogEntry.description | localeText | safe:'html'"
          ></div>
        </td>
      </ng-container>

      <!-- Catalog state -->
      <ng-container matColumnDef="catalogState">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let catalogEntry" mat-cell>
          <ng-container *ngIf="getUrlForContent(catalogEntry) as urlTree">
            <ng-container
              [ngTemplateOutlet]="isInMyAccount(catalogEntry) ? tplOpenAssigned : tplOpenDetails"
              [ngTemplateOutletContext]="{urlTree: urlTree}"
            ></ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
      <tr class="fadeIn" *matRowDef="let row; columns: columns;" mat-row></tr>
    </table>

    <div class="no-data" *ngIf="isFilteredDataEmpty">
      <span i18n="@@catalog-no-match">No catalog entries match your search criteria</span>
    </div>

  </rag-sticky-scroll>

</ng-template>

<ng-template #tplDataLoading>
  <div
    *ngIf="!embedded"
    class="no-data"
  >
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplDataEmpty>
  <div class="no-data" *ngIf="!isLoading">
    <span i18n="@@catalog-no-data">There are no catalog entries available.</span>
  </div>
</ng-template>

<ng-template #tplOpenAssigned let-urlTree="urlTree">
  <button
    mat-stroked-button color="primary"
    [routerLink]="getRouterLink(urlTree)"
    [queryParams]="getQueryParams(urlTree)"
  >
    <span i18n="@@global_open">Open</span>
</button>
</ng-template>

<ng-template #tplOpenDetails let-urlTree="urlTree">
  <button
    mat-flat-button
    color="primary"
    [routerLink]="getRouterLink(urlTree)"
    [queryParams]="getQueryParams(urlTree)">
      <span i18n="@@global_details">Details</span>
  </button>
</ng-template>

<ng-template #cardAccessorView let-content="content">
  <a
    *ngIf="getUrlForContent(content) as urlTree"
    mat-mini-fab
    matTooltip="Details"
    i18n-matTooltip="@@global_details"
    class="card-accessory-button"
    [routerLink]="getRouterLink(urlTree)"
    [queryParams]="getQueryParams(urlTree)"
  >
    <mat-icon svgIcon="information-outline"></mat-icon>
  </a>
</ng-template>

<ng-template #newCards let-catalogEntry="catalogEntry">
  <!--New Card Design-->
  <ng-container [ngSwitch]="catalogEntry.objType">

    <rag-catalog-course-card
      *ngSwitchCase="'lms_course'"
      [content]="catalogEntry"
      [contentLink]="getUrl(catalogEntry)"
    ></rag-catalog-course-card>

    <rag-catalog-event-card
      *ngSwitchCase="'lms_offlineCnt'"
      [content]="catalogEntry"
      [contentLink]="getUrl(catalogEntry)"
    ></rag-catalog-event-card>

    <rag-catalog-curriculum-card
      *ngSwitchCase="'lms_curriculum'"
      [content]="catalogEntry"
      [contentLink]="getUrl(catalogEntry)"
    ></rag-catalog-curriculum-card>

  </ng-container>
</ng-template>

<!--Standard Card Design-->
<ng-template #standardCards let-catalogEntry="catalogEntry">
  <rag-catalog-card-v2
    [accessoryTemplate]="cardAccessorView"
    [catalogEntry]="catalogEntry"
    [contentLink]="getUrl(catalogEntry)"
  ></rag-catalog-card-v2>
</ng-template>
