import { Injectable } from '@angular/core';
import { PrincipalData } from './principal.types';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PrincipalService } from './principal.service';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PrincipalResolver
  implements Resolve<PrincipalData | null> {

  constructor(
    private principalService: PrincipalService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PrincipalData | null> {
    this.principalService.fetchUserData()
      .pipe(take(1))
      .subscribe();
    return this.principalService.permissionStates$
      .pipe(take(1))
      .pipe(map(() => this.principalService.currentUser));
  }

}
