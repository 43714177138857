import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrainResponse } from '../../../core/global.types';
import { ApiUrls } from '../../../core/api.urls';

@Injectable()
export class CodeRedeemWidgetService {

  constructor(
    private http: HttpClient,
  ) {
  }

  redeemCode(licenseCode: string): Observable<unknown> {
    const url = ApiUrls.getKey('LicenseCode').replace('{code}', licenseCode);
    return this.http.post<TrainResponse>(url, null);
  }

}
