import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { MailComponent } from './mail.component';
import { MailResolverService } from './mail-resolver.service';

const mailRoutes: Routes = [
  { path: 'mailbox', component: MailComponent, canActivate: [ AuthGuard ], resolve: { content: MailResolverService } },
  {
    path: 'mailbox/:box/:page/:size',
    component: MailComponent,
    canActivate: [ AuthGuard ],
    resolve: { content: MailResolverService },
  },
  {
    path: 'mailbox/:box/:page',
    redirectTo: 'mailbox/:box/:page/10'
  },
  {
    path: 'mailbox/:box',
    redirectTo: 'mailbox/:box/0/10'
  },
  {
    path: 'mailbox',
    redirectTo: 'mailbox/inbox/0/10',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(mailRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class MailRoutingModule {
}
