import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LearnerContentTypes } from '../../learner-content-util/learner-content.types';
import { CachedSubject } from '../../../../../core/cached-subject';
import { AdminCoursesTypes } from '../../../../../core/admin-courses.types';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { SelfEstimationResponse } from '../learner-course-self-estimation/learner-course-self-estimation.types';


@Injectable({ providedIn: 'root' })
export class LearnerCourseService {

  readonly learningUnit$: Observable<LearnerContentTypes.CourseAccountToDo>;
  readonly sampleSolutionDisabled$: Observable<boolean>;
  private _learningUnit$ = new CachedSubject<LearnerContentTypes.CourseAccountToDo>(null);
  private _sampleSolutionDisabled$ = new CachedSubject<boolean>(true);

  constructor() {
    this.learningUnit$ = this._learningUnit$.withoutEmptyValues();
    this.sampleSolutionDisabled$ = this._sampleSolutionDisabled$.asObservable();
  }

  /**
   * Order number: <br>
   * -1 = Empty <br>
   * 1 = [SampleSolution,Estimation] <br>
   * 2 = [Estimation,SampleSolution] <br>
   * 3 = [SampleSolution] <br>
   * 4 = [Estimation] <br>
   * null = error <br>
   */
  static getExtensionOrder(extensions: LearnerContentTypes.ExtensionType[]): number | null {
    if ( extensions.length === 0 ) {
      return -1;
    }
    if ( extensions.length === 1 ) {
      if ( extensions[0].valueOf() === AdminCoursesTypes.Extensions.SampleSolution ) {
        return 3;
      } else if ( extensions[0].valueOf() === AdminCoursesTypes.Extensions.Estimation ) {
        return 4;
      }
    }
    if (extensions.length === 2) {
      if ( extensions[0].valueOf() === AdminCoursesTypes.Extensions.SampleSolution &&
        extensions[1].valueOf() === AdminCoursesTypes.Extensions.Estimation ) {
        return 1;
      } else if ( extensions[0].valueOf() === AdminCoursesTypes.Extensions.Estimation &&
        extensions[1].valueOf() === AdminCoursesTypes.Extensions.SampleSolution ) {
        return 2;
      }
    }
    return null;
  }

  setLearningUnit(learningUnit: LearnerContentTypes.CourseAccountToDo): void {
    this._learningUnit$.next(learningUnit);
  }

  setSampleSolutionDisabled(disabled: boolean): void {
    this._sampleSolutionDisabled$.next(disabled);
  }

  calculateSampleSolutionDisabledState(learningUnit: LearnerContentTypes.CourseAccountToDo, extensions): boolean {
    const extensionOrder = LearnerCourseService.getExtensionOrder(learningUnit.extensions);
    switch ( extensionOrder ) {
      case null:
      case -1:
      case 4:
        this.setSampleSolutionDisabled(true);
        return;
      case 1:
      case 3:
        this.setSampleSolutionDisabled(DisplayStatusHelper.isStatusRed(learningUnit.displayStatus));
        return;
      case 2:
        const estimation: SelfEstimationResponse = extensions
          .find(extension => extension?.type === AdminCoursesTypes.Extensions.Estimation);
        const comment = estimation?.commitment?.commitment?.comment;
        if (comment != null && comment !== '') {
          this.setSampleSolutionDisabled(DisplayStatusHelper.isStatusRed(learningUnit.displayStatus));
          return;
        } else {
          this.setSampleSolutionDisabled(true);
          return;
        }
    }
    this.setSampleSolutionDisabled(true);
    return;
  }

}
