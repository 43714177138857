import { Catalogs } from '../../core/catalog/catalog.types';

export class CatalogBotHelper {

  static addDetailPageUrls(
    contents: Catalogs.CatalogEntry[],
    getUrl: (content: Catalogs.CatalogEntry) => (string | null))
  {
    contents = contents.map(content => {
      const catalogUrl = getUrl(content);
      content['detailPageUrl'] = ((catalogUrl == null) ? '' : location.origin + '/#' + catalogUrl);
      return content;
    })
  }
}
