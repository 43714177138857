<rag-page-header>
  <div class="page-header-title">
    <span>{{cockpitTitle}}</span>
  </div>
</rag-page-header>
<iframe
  [src]="customViewURL | safe:'resourceUrl'"
  allowfullscreen
  frameborder="no">

</iframe>
