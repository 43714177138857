import { MacroContextSettings } from 'src/app/core/macros.types';

export namespace CKEditorDefaults {

  export interface Options {
    trainMacros?: MacroContextSettings;
  }

  export const CKEDITOR_OPTIONS: Options = {
    trainMacros: {
      layouts: null,
      macroGroups: [],
      textModules: null
    }
  };

}
