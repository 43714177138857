import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { RagCKEditorModule } from '../../../core/input/rag-ckeditor/rag-ckeditor.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MailAttachmentProgressComponent } from './mail-composer/mail-attachments/mail-attachments-progress.component';
import { MailAttachmentsComponent } from './mail-composer/mail-attachments/mail-attachments.component';
import { MailComposerComponent } from './mail-composer/mail-composer.component';
import { MailSettingsComponent } from './mail-composer/mail-settings/mail-settings.component';
import { ReportsSettingsComponent } from './mail-composer/reports-settings/reports-settings.component';
import { TimeSettingsComponent } from './mail-composer/time-settings/time-settings.component';
import { MailRoutingModule } from './mail-routing.module';
import { MailComponent } from './mail.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { AttachmentsComponent } from 'src/app/component/attachments/attachments.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { SelectUserGroupsModule } from "../../../component/select-user-groups/select-user-groups.module";
import {
  GenericTextInputDialogComponent
} from "../../../component/generic-text-input-dialog/generic-text-input-dialog.component";

@NgModule({
  declarations: [
    MailComponent,
    MailComposerComponent,
    TimeSettingsComponent,
    ReportsSettingsComponent,
    MailSettingsComponent,
    MailAttachmentsComponent,
    MailAttachmentProgressComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RagCKEditorModule,
    CommonModule,
    PageHeaderComponent,
    ClampComponent,
    FormsModule,
    GenericTextInputDialogComponent,
    AttachmentsComponent,
    MailRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    NgSelectModule,
    MatMenuModule,
    MatPaginatorModule,
    PipesModule,
    ReactiveFormsModule,
    SelectUserGroupsModule,
  ],
})

export class MailModule {
}
