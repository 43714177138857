import { Translation } from '../translation/translation.types';


export enum LoginType {
  Credentials = 'Credentials',
  LDAP = 'LDAP',
  OAuth2 = 'OAuth2',
}

export interface LoginMethod {
  name?: Translation;
  type: LoginType;
}

export interface LoginMethodCredentials {
  type: LoginType.Credentials;
}

export interface LoginMethodLDAP {
  type: LoginType.LDAP;
}

export interface LoginMethodOAuth2 {
  authorizationUrl: string;
  type: LoginType.OAuth2;
}
