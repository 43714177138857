import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SelfRegisterFormService } from './self-register-form.service';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { catchError, map, take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class SelfRegisterFormGuard
  implements CanActivate {

  constructor(
    private principalService: PrincipalService,
    private router: Router,
    private selfRegisterFormService: SelfRegisterFormService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {

    // fetchUserData cannot be included in the pipe as it does not return anything if user is not logged in!
    this.principalService.fetchUserData(false);
    return this.principalService.isLogged$
      .pipe(take(1))
      .pipe(map(isLogged => {

        if ( !isLogged ) {
          // use is guest -> try to fetch self registration info
          return null;
        }

        if ( console?.warn != null ) {
          console.warn('You are logged in. Redirecting to home site...');
        }

        return this.router.parseUrl('/');
      }))
      .pipe(switchMap(redirect => {

        if ( redirect != null ) {
          return of(redirect);
        }

        const processHash = route.queryParamMap.get('p');
        return this.selfRegisterFormService.getInfo(processHash)
          .pipe(take(1))
          .pipe(catchError(_ => of(this.router.parseUrl('/login'))))
          .pipe(map(_ => true));
      }));
  }

}
