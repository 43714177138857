import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNavTopComponent } from './header-nav-top.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
  declarations: [ HeaderNavTopComponent ],
  exports: [ HeaderNavTopComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    PipesModule,
    RouterLink,
    MatBadgeModule,
  ],
})
export class HeaderNavTopModule {
}
