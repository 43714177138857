export namespace Files {
  export const CSV_MIME_TYPES = [
    'application/vnd.ms-excel',
    'text/plain',
    'text/x-csv',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];

  export const IMAGE_MIME = [
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'imiage/gif'
  ];

  export const PDF_MIME = [
    'application/pdf'
  ];

  export const DOCUMENT_MIME = [...IMAGE_MIME,
    'application/pdf',
    'text/plain',
    'application/vnd.ms-excel',
    'text/x-csv',
  ];

  export const FILE_EXTENSIONS = {
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'audio/mpeg': ['.mp3'],
    'text/plain': ['.txt'],
    'text/x-csv': ['.csv'],
    'application/csv': ['.csv'],
    'application/x-csv': ['.csv'],
    'text/csv': ['.csv'],
    'text/comma-separated-values': ['.csv'],
    'text/x-comma-separated-values': ['.csv'],
    'text/tab-separated-values': ['.tsv'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/svg+xml': ['.svg'],
    'image/gif': ['.gif'],
    'application/pdf': ['.pdf'],
    'video/mp4': ['.mp4'],
  };

  export function getExtensions(mimeTypes: string[]): string[] {
    return mimeTypes.reduce((acc, mimeType) => acc.concat(FILE_EXTENSIONS[mimeType] || []), []);
  }

}
