import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { CachedSubject } from '../cached-subject';
import { InfoService } from '../info/info.service';
import { InfoType, MessageKey } from '../info/info.types';
import { ReportTargetType } from '../report/report.types';
import { Column } from './column-settings.types';

export interface TableColumnSettings {
  [key: string]: Column;
}


@Injectable({
  providedIn: 'root',
})
export class ColumnSettingsService {

  readonly columnSettingsCourse$: Observable<TableColumnSettings>;
  readonly columnSettingsCurriculum$: Observable<TableColumnSettings>;
  readonly columnSettingsEvents$: Observable<TableColumnSettings>;
  private columnSettingsCourse_ = new CachedSubject<TableColumnSettings>(null);
  private columnSettingsCurriculum_ = new CachedSubject<TableColumnSettings>(null);
  private columnSettingsEvents_ = new CachedSubject<TableColumnSettings>(null);

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
    this.columnSettingsCourse$ = this.columnSettingsCourse_.withoutEmptyValues();
    this.columnSettingsCurriculum$ = this.columnSettingsCurriculum_.withoutEmptyValues();
    this.columnSettingsEvents$ = this.columnSettingsEvents_.withoutEmptyValues();
  }

  fetchColumnSettings(targetType: string, forUpdate: boolean): Observable<TableColumnSettings> {
    const url = ApiUrls.getKey(forUpdate ? 'ReportColumnSettingsUpdateGet' : 'ReportColumnSettings')
      .replace(/{targetType}/g, targetType);
    return this.http.get<any>(url)
      .pipe(map(response => response.columnConfig));
  }

  getColumnSettings(targetType: ReportTargetType, reset = false): Observable<TableColumnSettings> {
    let subject: CachedSubject<TableColumnSettings>;
    let promise: Observable<TableColumnSettings>;
    if ( targetType === 'Course' ) {
      subject = this.columnSettingsCourse_;
      promise = this.columnSettingsCourse$;
    } else if ( targetType === 'Offline' ) {
      subject = this.columnSettingsEvents_;
      promise = this.columnSettingsEvents$;
    } else {
      targetType = 'Curriculum';
      subject = this.columnSettingsCurriculum_;
      promise = this.columnSettingsCurriculum$;
    }
    if ( reset ) {
      subject.reset();
    }
    if ( subject.queryStart() ) {
      this.fetchColumnSettings(targetType, false)
        .pipe(map(subject.next))
        .pipe(finalize(subject.queryDone))
        .subscribe();
    }
    return promise;
  }

  updateColumnSettings(targetType: string, data: TableColumnSettings): Observable<TableColumnSettings> {
    let subject: CachedSubject<TableColumnSettings>;
    if ( targetType === 'Course' ) {
      subject = this.columnSettingsCourse_;
    } else if ( targetType === 'Offline' ) {
      subject = this.columnSettingsEvents_;
    } else {
      targetType = 'Curriculum';
      subject = this.columnSettingsCurriculum_;
    }
    const url = ApiUrls.getKey('ReportColumnSettingsUpdatePost')
      .replace(/{targetType}/g, targetType);
    return this.http.post<any>(url, data)
      .pipe(map(response => response.columnConfig))
      .pipe(tap(columnConfig => {
        subject.next(columnConfig);
        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_SAFED, InfoType.Success);
      }))
      .pipe(catchError(err => {
        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_NOT_SAFED, InfoType.Error);
        return throwError(err);
      }));
  }
}
