import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';


/**
 * <p>Use css classes "primary-button" and "secondary-button" to identify the left and right buttons.</p>
 *
 * <p>Beware that it is not possible to use the *ngIf-directive to conditionally hide the buttons. To hide the
 * secondary-button (right), you must use the attribute [showSecondaryButton] with a value of "false".</p>
 *
 * <p>You can use the css class "icon-button" to remove paddings and min-width from a regular button.</p>
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: [ './split-button.component.scss' ],
})
export class SplitButtonComponent {

  @Input() showSecondaryButton: boolean;

  @HostBinding('class') get cssClasses() {
    if ( this.showSecondaryButton ) {
      return { 'show-secondary-button': true };
    }
    return null;
  }

}
