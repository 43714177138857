<div class="header-top">
  <button
    *ngIf="showSideNav"
    (click)="onToggleSideNav()"
    mat-icon-button
  >
    <mat-icon svgIcon="menu"></mat-icon>
  </button>

  <rag-header-logo></rag-header-logo>
  <rag-content-autocomplete
    *ngIf="showContentSearch && isGtMedium"
  ></rag-content-autocomplete>

  <nav
    *ngIf="showSideNav ? getLanguageItem() != null : topItems != null"
    rag-header-nav-top
    [navItems]="showSideNav ? [getLanguageItem()] : topItems"
  ></nav>

</div>

<rag-content-autocomplete
  *ngIf="showContentSearch && !isSmall && !isGtMedium"
  class="margin-left margin-right margin-bottom"
></rag-content-autocomplete>


<nav
  *ngIf="mainItems && !showSideNav"
  rag-header-nav-main
  [navItems]="mainItems"
></nav>
