import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AnonymousRegisterDialogModule } from '../../../route/main/anonymous-register/anonymous-register-dialog/anonymous-register-dialog.module';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { LoadingIndicatorComponent } from '../../loading-indicator/loading-indicator.component';



@NgModule({
  declarations: [ LoginFormComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    RouterModule,
    MatInputModule,
    PipesModule,
    MatDialogModule,
    DialogHeaderComponent,
    LoadingIndicatorComponent,
    AnonymousRegisterDialogModule
  ],
  exports: [
    LoginFormComponent,
  ],
})
export class LoginFormModule { }
