import { PreloadService } from './preload.service';
import { tap, map, delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Bots } from './bots/bots.types';
import { Observable } from 'rxjs';
import { CachedSubject } from './cached-subject';
import { EventType, Router } from '@angular/router';
import { UrlHelper } from './url.helper';
import moment from 'moment/moment';

interface BotEvent {
  visible: boolean;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BotService {

  mappings = {
    "assignmentType": {
      "both": "Verpflichtend und Freiwillig",
      "mandatory": "Verpflichtend",
      "voluntary": "Freiwillig",
    },
    "displaystatus": {
      "0": "Nicht gestartet",
      "1": "Zertifiziert",
      "2": "Nicht zertifiziert",
      "3": "Bestanden",
      "4": "Durchgefallen",
      "5": "Beendet",
      "6": "Unvollständig",
      "7": "Rezertifizierungszeit"
    },
    "objType": {
      "lms_offlineCnt": "Veranstaltung",
      "lms_curriculum": "Curriculum",
      "lms_course": "Kurs",
      "lms_recording": "Aufzeichnung"
    },
    "objSubType": {
      "11": "Webinar",
      "14": "vor Ort und gleichzeitiges Webinar",
      "16": "Aufzeichnung",
      "7": "Seminar",
      "1": "Zertifizierung",
      "2": "Test",
      "3": "Learning",
      "6": "Dokument",
      "8": "Kurs Dokument",
      "10": "Link",
      "12": "SimpleConnect",
      "13": "Hausaufgabe",
    },
    "currentAccountStatus": {
      "valid": "Bearbeitung möglich",
      "ended": "Bearbeitungsphase beended",
      "anulled": "nicht zugewiesen",
      "locked": "Gesperrt",
      "not_attempted": "Nicht gestartet"
    },
    "offlineType": {
      "virco": "Webinar",
      "offline": "vor Ort",
      "hybrid": "vor Ort und gleichzeitiges Webinar"
    },
    "courseType": {
      "1": "Zertifizierung",
      "2": "Test",
      "3": "Learning",
      "6": "Dokument",
      "7": "Seminar",
      "8": "Kurs Dokument",
      "10": "Link",
      "11": "Webinar",
      "12": "SimpleConnect",
      "13": "Hausaufgabe",
      "14": "Hybrid",
      "16": "Aufzeichnung"
    },
    "distType": {
      "lms_course": "Kurs",
      "lms_offlineCnt": "Veranstaltung",
      "lms_curriculum": "Curriculum",
      "lms_recording": "Aufzeichnung",
    },
    "distSubType": {
      "1": "Zertifizierung",
      "2": "Test",
      "3": "Learning",
      "6": "Dokument",
      "7": "Seminar",
      "8": "Kurs Dokument",
      "10": "Link",
      "11": "Webinar",
      "12": "SimpleConnect",
      "13": "Hausaufgabe",
      "14": "Hybrid",
      "16": "Aufzeichnung",
    },
    "state": {
      "booked": "Gebucht",
      "pending": "Ausstehende Buchung",
      "not_booked": "Nicht gebucht",
    }
  };

  visible$: Observable<BotEvent>;
  bypassInformation$: Observable<string>;

  private botSettings: Bots.Settings[];
  private _visible$ = new CachedSubject<BotEvent>(null);
  private _bypassInformation$ = new CachedSubject<string>(null);
  private _initialMsgSent = false;
  CATALOG_SYS_MSG: string = 'Wir befinden uns auf den Katalogansichten eines Lernmanagementssystems. ' +
    'Es werden Online-Lerninhalte und Präsenzveranstaltungen zur Buchung angeboten. ' +
    'Das nachfolgende JSON Array enthält alle Katalogeinträge. ' +
    'Alle Fragen die gestellt werden, versuchst du mit diesen Daten zu beantworten. ' +
    'Fragen nach Terminen beziehen sich immer auf Termine zu den Veranstaltungen in diesen Daten.'+
    'Wenn du den Eindruck hast, dass dein Gegenüber einen Inhalt buchen will, einen Link zur Detailseite an der in neuem Fenster öffnet, mit dem Hinweis, dass dort gebucht werden kann.' +
    'Immer wenn du Informationen zu einem Inhalt rausgibst, so gib die Detailseite mit aus.' +
    'Immer wenn du einen Inhalt ausgibst, der bereits gebucht ist, erwähne das explizit.' +
    'Immer wenn du nach Terminen gefrgat wirst, schaue auf Termine in Veranstaltungen oder Serienterminen oder Aufzeichnungen'

  ;

  constructor(
    private preloadService: PreloadService,
    private router: Router
  ) {
    this.bypassInformation$ = this._bypassInformation$.withoutEmptyValues();
    this.visible$ = this._visible$.withoutEmptyValues();

    this.preloadService.getBotSettings()
      .pipe(tap(botSettings => this.botSettings = botSettings))
      .subscribe();

    this.router.events
      .pipe(map(event => {
        if (event.type !== EventType.NavigationEnd) {
          return;
        }
        const _botUrl = UrlHelper.getUrlSubdirectory(location.href);
        const botSettings = this.botSettings.find(s => s.locationURL === _botUrl);
        if (botSettings !== undefined) {
          this._visible$.next({
            url: botSettings.botURL,
            visible: true
          });
        } else {
          this._visible$.next({
            visible: false
          });
        }
      }))
      .subscribe();
  }

  bypassInformation(data: string) {
    if(!this._initialMsgSent){
      this._initialMsgSent = true;
      this._bypassInformation$.next(this.CATALOG_SYS_MSG);
    }
    this._bypassInformation$.next(data);
  }

  getBotForURL(url: string): string | null {
    return this.botSettings
      .find(botSetting => url === botSetting.locationURL)?.botURL ?? null;
  }

  formatAPIDataForBot(apiData: string): string {
    for (const key in this.mappings) {
      const mappingsForKey = this.mappings[key];
      for (const value in mappingsForKey) {
        const regex = new RegExp("\"" + key + "\":\\s*\"*" + value + "\"*,", "g");
        apiData = apiData.replace(regex, `"${key}": "${this.mappings[key][value]}",`);
      }
    }
    apiData = apiData.replace(/offlineEventsViews/g, 'offlineEventList');
    apiData = apiData.replace(/eventDateUntil/g, 'eventDateEnd');
    apiData = apiData.replace(/eventDate"/g, 'eventDateStart"');
    apiData = apiData.replace(/objType/g, 'Inhaltstyp');
    apiData = apiData.replace(/objSubType/g, 'Inhaltsuntertyp');
    apiData = apiData.replace(/distType/g, 'Inhaltstyp');
    apiData = apiData.replace(/distSubType/g, 'Inhaltsuntertyp');
    apiData = apiData.replace(/distributed/g, 'isAlreadyAssigned');
    apiData = apiData.replace(/"closedStatus":"noStatus"/g, '"":"noStatus"');
    apiData = apiData.replace(/blockEvent/g, 'isTerminserie');
    apiData = apiData.replace(/"maxUserCount":\d+,/g, '');//not to use as misleading information
    apiData = apiData.replace(/"availablePlaces":\d+,/g, '');//deprecated information
    apiData = apiData.replace(/items/g, 'curriculumItems');
    apiData = apiData.replace(/catalogBooking/g, 'catalogBookingInfo');
    apiData = apiData.replace(/"cardPictureUUID":"([^"]+?)",/g, '"cardPictureUrl": "'+location.origin+'/lms/api/files/v1/$1",');
    apiData = apiData.replace(/"pictureUUID":"([^"]+?)",/g, '"pictureUrl": "'+location.origin+'/lms/api/files/v1/$1",');
    apiData = apiData.replace(/"whenCreated":(\d+),/g, (match, p1) => {
      return `"creationDate": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY')}",`;
    });
    apiData = apiData.replace(/"creationDate":(\d+),/g,(match, p1) => {
      return `"creationDate": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY')}",`;
    });
    apiData = apiData.replace(/"eventDateStart":(\d+),/g,(match, p1) => {
      return `"eventDateStart": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY HH:mm')}",`;
    });
    apiData = apiData.replace(/"eventDateEnd":(\d+),/g,(match, p1) => {
      return `"eventDateEnd": "${moment(new Date(Number(p1)).toISOString()).format('DD.MM.YYYY HH:mm')}",`;
    });
    apiData = apiData.replace(/"price":(\d+),/g, (match, p1) => {
      return `"price": "${(Number(p1) / 100).toFixed(2)}€",`;
    });
    apiData = apiData.replace(/"priceForUserInCent":(\d+),/g, (match, p1) => {
      return `"price": "${(Number(p1) / 100).toFixed(2)}€",`;
    });
    apiData = apiData.replace(/"minPrice":(\d+),/g, (match, p1) => {
      return `"price": "ab ${(Number(p1) / 100).toFixed(2)}€",`;
    });
    apiData = apiData.replace(/"maxPrice":(\d+),/g, (match, p1) => {
      return `"price": "bis ${(Number(p1) / 100).toFixed(2)}€",`;
    });
    return apiData
  }

}
