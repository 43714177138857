import { UserAttribute, UserAttributeType } from 'src/app/core/admin-user-attribute.types';

export namespace AdminTargetGroupEdit {

  export const OP_GREAT_OR_EQUAL: Op = {
    value: '>=',
    label: '>='
  };

  export const OP_LESS: Op = {
    value: '<',
    label: '<'
  };

  export const OP_LESS_OR_EQUAL: Op = {
    value: '<=',
    label: '<='
  };

  export const OP_EQUAL: Op = {
    value: '=',
    label: '='
  };

  export const OP_GREAT: Op = {
    value: '>',
    label: '>'
  };

  export const OP_NOT_EQUAL: Op = {
    value: '<>',
    label: '<>'
  };

  export const OP_CONTAINS: Op = {
    value: 'contains',
    label: $localize`:@@op_contains:contains`
  };

  export const OP_NOT_CONTAINS: Op = {
    value: 'notContains',
    label: $localize`:@@op_not_contains:not contains`
  };

  export const OP_STARTS_WITH: Op = {
    value: 'startsWith',
    label: $localize`:@@op_starts_with:starts with`
  };

  export const OP_ENDS_WITH: Op = {
    value: 'endsWith',
    label: $localize`:@@op_ends_with:ends with`
  };

  export const OP_IN: Op = {
    value: 'in',
    label: $localize`:@@op_in:in`
  };

  export interface Op {
    value: string;
    label: string;
  }

  export const opsForProfileType = (field: UserAttribute): Array<Op> => {
    // @see classic properties "elearning.showTargetGroupFilterAdministration.conditionoptions.*"
    switch (field?.attributeField ?? '') {
      case 'active':
      case 'sex':
      case 'gender':
      case 'state':
        return [OP_EQUAL, OP_NOT_EQUAL];

      case 'hireDate':
      case 'userId':
      case 'userID':
        return [OP_GREAT, OP_GREAT_OR_EQUAL, OP_LESS, OP_LESS_OR_EQUAL, OP_EQUAL, OP_NOT_EQUAL];
    }

    switch (field?.attributeType ?? '') {

      case UserAttributeType.dropdown:
      case UserAttributeType.multiselect:
      case UserAttributeType.radio:
        return [OP_EQUAL, OP_NOT_EQUAL, OP_CONTAINS, OP_NOT_CONTAINS];

      default:
        return [OP_GREAT_OR_EQUAL, OP_LESS, OP_LESS_OR_EQUAL, OP_EQUAL, OP_GREAT, OP_NOT_EQUAL, OP_CONTAINS,
          OP_NOT_CONTAINS, OP_STARTS_WITH, OP_ENDS_WITH, OP_IN];
    }
  };

  export const isContainOP = (op: string): boolean => op === OP_CONTAINS.value || op === OP_NOT_CONTAINS.value;
}
