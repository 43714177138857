import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmptyPipe } from './empty.pipe';
import { FilterRenderPipe } from './filter-render.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { ImageDefaultDirective } from './image-default.directive';
import { LocaleTextPipe } from './locale-text.pipe';
import { SafePipe } from './safe-pipe';
import { TagsPipe } from './tags.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ValueRenderPipe } from './value-render.pipe';
import { DisplayOnePipe } from './display-one.pipe';
import { DistTypeToTextPipe } from './dist-type-to-text.pipe';
import { FormatDurationPipe } from '../format-duration.pipe';
import { FormatActionCodeTypePipe } from './format-action-code-type.pipe';

@NgModule({
  declarations: [
    EmptyPipe,
    FilterRenderPipe,
    FormatDatePipe,
    FormatDurationPipe,
    ImageDefaultDirective,
    LocaleTextPipe,
    SafePipe,
    TagsPipe,
    ToDatePipe,
    ValueRenderPipe,
    DisplayOnePipe,
    DistTypeToTextPipe,
    FormatActionCodeTypePipe
  ],
  exports: [
    EmptyPipe,
    FilterRenderPipe,
    FormatDatePipe,
    ImageDefaultDirective,
    LocaleTextPipe,
    SafePipe,
    TagsPipe,
    ToDatePipe,
    ValueRenderPipe,
    DisplayOnePipe,
    DistTypeToTextPipe,
    FormatDurationPipe,
    FormatActionCodeTypePipe
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    LocaleTextPipe,
    FormatDatePipe,
  ],
})
export class PipesModule {
}
