import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtOauth2ListModule } from './ext-oauth2-list/ext-oauth2-list.module';
import { ExtOauth2EditModule } from './ext-oauth2-edit/ext-oauth2-edit.module';


@NgModule({
  imports: [
    CommonModule,
    ExtOauth2EditModule,
    ExtOauth2ListModule,
  ],
})
export class ExtOauth2Module {
}
