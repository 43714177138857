import { ColumnFilterV2, FilterOperator } from '../../core/column-settings/column-filter.types';
import { AnyObject } from '../../core/core.types';
import { hasTableColumnDataTypeDropDown, TableColumnDataType, TableColumnOptions } from '../table/table-column.types';
import { FilterResult, FilterResultDropDown, FilterResultNumber } from '../../core/column-settings/filter-api.types';
import { LanguageHelper } from '../../core/language.helper';
import { TableAccessors } from '../table/table.accessors';


export interface ParsedFilterParam {
  action?: FilterOperator | string;
  changed?: boolean;
  value: string;
}

export class ContentFilterHelper {

  static DefaultSearchableFields = [ 'title', 'description', 'name' ];

  /**
   * check if the filter should reduce anything (is "active"), and if it differs from the default state (is "changed")
   */
  static isFilterActive<ID>(filter: ColumnFilterV2<ID> | null): { active: boolean, changed: boolean } {
    const result = { active: false, changed: false };
    if (filter == null) {
      return result;
    }

    result.active = !!(filter.action && (filter.value != null) && (filter.value !== ''));
    if (filter.defaultValue === undefined) {
      // filter is "active" and does not have a default -> mark as changed
      result.changed = result.active;
    } else {
      // filter value was changed from default (including empty filter values) -> mark as changed
      result.changed = (filter.value !== filter.defaultValue);
    }

    return result;
  }

  /**
   * @returns null if no filter values have been changed
   */
  static addQueryParams(filters: TableColumnOptions[], queryParams: AnyObject<string>):
    TableColumnOptions[] | null {

    const changed = (filters ?? [])
      .map(filterOptions => {
        const attr = filterOptions?.filterStateAttribute;
        const filterState = filterOptions.filter;
        if ( attr == null || (filterState == null) ) {
          // ignore anything without attribute for storage
          return false;
        }

        if ( !queryParams.hasOwnProperty(attr) ) {
          // value not included in current query params
          return false;
        }

        return ContentFilterHelper.setStateFromParam(filterState, queryParams[attr] ?? filterState.value);
      })

      // search for any changed filter values
      .filter(filterChanged => filterChanged).length > 0;

    return changed ? filters : null;
  }

  /**
   * @returns null if no filter options have been modified
   */
  static addResults(filters: TableColumnOptions[], filterResults: AnyObject<FilterResult[]>):
    TableColumnOptions[] | null {
    if ( filterResults == null ) {
      // no filter results to apply -> no change to filters
      return null;
    }

    const changed = (filters ?? [])

      // inject filter results into filter options
      .map((filterOption: TableColumnOptions) => {
        if ( filterOption?.filterMethodAsync == null ) {
          // the filter does not have an asynchronous filter method -> skip it
          return false;
        }

        // add results to ColumnOptions
        const results = filterOption.filterResults = filterResults[filterOption.filter?.identifier] ?? [];

        switch ( filterOption.dataType ) {

          case TableColumnDataType.number:
          case TableColumnDataType.price:
            return this.addResultsNumber(filterOption, results as FilterResultNumber[]);

          case TableColumnDataType.dropdown:
          case TableColumnDataType.radio:
            // case TableColumnDataType.tags:
            return this.addResultsDropDown(filterOption, results as FilterResultDropDown[]);

          default:
            // nothing matches -> skip filter
            return false;
        }
      })

      // search for any changed filter values
      .filter(filterChanged => filterChanged).length > 0;

    return changed ? filters : null;
  }

  static addResultsDropDown(
    filterOption: TableColumnOptions,
    results: FilterResultDropDown[],
  ): boolean {
    // show filter if there are results available
    const resultsEmpty = !(results?.length > 0);
    if ( resultsEmpty ) {

      // no results to apply
      filterOption.filterHidden = true;
      filterOption.dropDownOptions = {};
      // if the filter was active before -> flag it as changed
      return !!(filterOption?.filter?.value);
    }

    filterOption.filterHidden = false;
    filterOption.dropDownOptions = results
      .reduce((pV, result) => {
        const option = result?.option;
        const label = option?.label;
        const value = option?.value;
        if ( (label != null) && (value != null) ) {
          pV[value] = label;
        }
        return pV;
      }, {});

    // assume dropDownOptions have been changed
    return true;
  }

  static addResultsNumber(
    filterOption: TableColumnOptions,
    results: FilterResultNumber[],
  ): boolean {
    // skip number filter that does not have specific results
    return results?.length > 0;
  }

  static asQueryParams(
    filters: TableColumnOptions<unknown>[],
  ): AnyObject<string> {
    return filters

      // ignore filters without attribute
      .filter(filter => (filter?.filterStateAttribute != null))

      .reduce((pV, filter) => {
        // force override of any defined attributes (i.e. set empty filters to empty strings)
        let param = filter.filter?.value ?? '';
        if ( param !== '' ) {
          param += filter.filter.action;
        }
        pV[filter.filterStateAttribute] = param;
        return pV;
      }, {});
  }

  /**
   * @returns null if neither {@link TableColumnOptions.filterMethod} nor
   * {@link TableColumnOptions.filterMethodAsync} are defined
   */
  static filterMatch<T>(
    data: T,
    filter: TableColumnOptions<T>,
  ): boolean | null {
    const value = TableAccessors.getFilterValue(data, filter);

    const filterMethodAsync = filter.filterMethodAsync;
    if ( filterMethodAsync != null ) {
      return filterMethodAsync(filter.filterResults, value as T, filter.filter);
    }

    const filterMethod = filter.filterMethod;
    if ( filterMethod != null ) {
      return filterMethod(value as T, filter.filter);
    }

    return null;
  }

  static filtersMatch<T>(
    data: T,
    filters: TableColumnOptions<T>[],
  ): boolean {
    // return false if there is any filter that returns false - use inversion to terminate early on filter miss
    return filters.find(filter => ContentFilterHelper.filterMatch(data, filter) === false) == null;
  }

  static getOriginalDropdownOptions(
    filter: TableColumnOptions,
  ): AnyObject<string> {
    if ( !hasTableColumnDataTypeDropDown(filter?.dataType) ) {
      // ignore anything not of type dropdown or tags (ignore any defined useless dropdownOptions)
      return {};
    }

    if ( filter.dropDownOptionsOriginal == null ) {
      filter.dropDownOptionsOriginal = { ...(filter.dropDownOptions ?? {}) };
    }

    return filter.dropDownOptionsOriginal;
  }

  static initFromQueryParams(
    filters: TableColumnOptions[],
    queryParams: AnyObject<string> = {},
  ): void {

    // iterate filters and copy filter value from query params
    (filters ?? []).forEach(filter => {

      const attr = filter?.filterStateAttribute;
      const filterState = filter?.filter;
      if ( (attr == null) || (filterState == null) ) {
        // ignore if no attribute is given, or filter state is unavailable
        return;
      }

      if ( queryParams[attr] == null ) {
        // no value in query params -> skip
        return;
      }

      // otherwise simply copy the value -> allow invalid values for maybe mor useful url manipulation?
      ContentFilterHelper.setStateFromParam(filterState, queryParams[attr] ?? filterState.defaultValue);
    });
  }

  static parseFilterParam(param: string = ''): ParsedFilterParam {

    if ( !/[$]\w+$/gi.test(param) ) {
      // this does not include a filter action -> escape early for easier testing later on
      return { value: param };
    }

    const action = /[$]\w+$/gi.exec(param)[0];

    // dirty hack :D
    // @see https://stackoverflow.com/a/47755096
    // todo switch to es2017 to allow use without cast to any
    if ( (action === '') || !Object.values(FilterOperator).includes(action as any) ) {
      // no action found -> preserve original parameter
      return { value: param };
    }

    const value = param.substring(0, param.length - action.length);
    return {
      action,
      value,
    };
  }

  /**
   * @todo fetch reduced filter options from api (instead of calculating them from contents)
   */
  static reduceFilterOptions<T>(
    filters: TableColumnOptions[],
    contents?: T[],
    minOptionCount = 2,
  ): TableColumnOptions[] {

    contents = contents ?? [];
    const filter1 = (filters ?? [])

      .map(filter => {
        if ( !hasTableColumnDataTypeDropDown(filter?.dataType) ) {
          // only dropdown-type filters need to be modified
          return filter;
        }

        if ( filter.filterMethodAsync != null ) {
          // hide if there are no results
          filter.filterHidden = !(filter.filterResults?.length > 0);

          // do not modify any options
          return filter;
        }

        // create a clone of the filter options to allow overriding the active filter value
        const dummyFilter = { ...filter };
        dummyFilter.filter = { ...filter.filter };

        const originalOptions = ContentFilterHelper.getOriginalDropdownOptions(filter);
        const dropDownOptionsStatic = filter.dropDownOptionsStatic ?? [];

        const dropDownOptions = Object.entries(originalOptions)
          // remove any options that are missing
          .filter(([ key ]) => {
            if ( dropDownOptionsStatic.includes(key) ) {
              // TF-3046 preserve this option to allow for consistent navigation
              return true;
            }
            dummyFilter.filter.value = key;
            // find the first matching content
            const matchingContent = contents.find(data => ContentFilterHelper.filterMatch(data, dummyFilter));
            // include key in available options if content is found
            return matchingContent != null;
          });

        if ( (dropDownOptionsStatic.length === 0) && (dropDownOptions.length < minOptionCount) ) {
          // remove filter option if no contents are matching or there is no real choice
          // (only if there is no static option)
          return null;
        }

        // collect remaining options into object
        filter.dropDownOptions = dropDownOptions
          .reduce((pV, [ key, label ]) => {
            pV[key] = label;
            return pV;
          }, {});

        return filter;
      })

      // skip any removed filters
      .filter(filter => filter != null);
    return filter1;
  }

  static searchInObject(
    target: unknown,
    textToSearch?: string,
    searchableFields: string[] = ContentFilterHelper.DefaultSearchableFields,
  ): boolean {

    // increase likelihood to match by converting to lower case
    textToSearch = (textToSearch?.trim() ?? '').toLocaleLowerCase();

    if ( textToSearch === '' ) {
      // empty search terms allow any result / do not filter
      return true;
    }

    if ( target == null ) {
      // target cannot match terms
      return false;
    }

    if ( typeof (target) === 'object' && !Array.isArray(target) ) {
      // if target is an object, search inside the allowed keys
      return searchableFields.find(key => {
        const value = LanguageHelper.objectToText(target[key]);
        return ContentFilterHelper.searchInObject(value, textToSearch);
      }) != null;
    }

    return LanguageHelper.objectToText(target)
      .toLocaleLowerCase()
      .includes(textToSearch);
  }

  static setStateFromParam(filterState: ColumnFilterV2, param: string = ''): boolean {
    const paramState = ContentFilterHelper.parseFilterParam(param);

    const changed = filterState.value !== paramState.value;
    filterState.action = paramState.action as FilterOperator ?? filterState.action;
    filterState.value = paramState.value;
    return changed;
  }

}
