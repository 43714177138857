import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ApplicationStateService } from 'src/app/core/application-state.service';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { DidReceiveResponseHandler } from '../widget-wrapper/widget-wrapper.types';
import { WidgetComponent } from '../widgets.module';
import { AbstractCardWidgetComponent, WidgetsUUID } from '../widgets.types';

@Component({
  selector: 'rag-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss']
})
export class EventsWidgetComponent extends AbstractCardWidgetComponent
implements WidgetComponent, OnInit, OnDestroy {

  static WidgetUUID = WidgetsUUID.EventsWidgetUUID;

  contents: Array<Catalogs.CatalogEntry>;
  catalogSettings: Catalogs.CatalogSettings;
  @Input() didReceiveResponse: DidReceiveResponseHandler;

  @ViewChild('viewMode', { static: true })
  viewModeRef: TemplateRef<any>;

  constructor(
    applicationStateService: ApplicationStateService,
    private catalogService: CatalogService
  ) {
    super(applicationStateService);
    this.setTitle($localize`:@@widget_events_title:Events`);
   }

  ngOnInit(): void {
    this.loadEvents();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  getMenuReferences() {
    return [ this.viewModeRef ];
  }

  isDataDriven() {
    return true;
  }

  toggleViewMode(emitEvent = true) {
    this.isBoxViewMode = !this.isBoxViewMode;
    this.refreshMaxItems();

    if (emitEvent) {
      this.settingsChange.next({ isCards: this.isBoxViewMode });
    }
  }

  private loadEvents() {
    this.catalogService.nextUpcomingEvents(3)
      .pipe(map(catalog => {
        this.catalogSettings = catalog.settings;
        this.contents = catalog.contents;
        this.didReceiveResponse({
          hasContent: this.contents?.length > 0 ?? false,
        });
      }))
      .pipe(switchMap(_ =>
        this.settings.pipe(map(userSettings => {
          if (this.contents?.length > 0) {
            const isBoxViewMode = userSettings['isCards'] ?? true;
            if (isBoxViewMode !== this.isBoxViewMode) {
              this.toggleViewMode(false);
            }
          }
        }))
      ))
      .pipe(finalize(() => {
        // in case of error
        this.didReceiveResponse({
          hasContent: this.contents?.length > 0 ?? false,
        });
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

}
