import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { PasswordForgottenService } from '../password-forgotten/password-forgotten.service';

@Component({
  selector: 'rag-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: [ './password-update.component.scss' ],
})
export class PasswordUpdateComponent
  implements OnInit, OnDestroy {
  @ViewChild('pswd_leave_without_reset')
  PswdLeaveWithoutReset: ElementRef;
  form: UntypedFormGroup;
  requestIsSent = false;
  token = '';
  tokenInvalid = false;
  passwordRepeateControl = new UntypedFormControl();
  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private pswdService: PasswordForgottenService,
    private formBuilder: UntypedFormBuilder,
  ) {
    }
  cancel() {
    // ensure the user did not click unintentionally on cancel button
    if (this.form.dirty) {
      if (!confirm(this.PswdLeaveWithoutReset.nativeElement.innerText)) {
        return;
      }
    }
    this.navigationService.navigateByUrl('/home');
  }

  hasError(componentId: string, errorCode: string) {
    if (this.form == null) {
      return null;
    }
    return this.form.get(componentId).hasError(errorCode);
  }

  getControl(controlName: string): FormControl {
    return this.form.get(controlName) as FormControl;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      newPasswordControl: ['', Validators.required],
      newPasswordControl2: ['', Validators.required],
    });
    this.form.controls.newPasswordControl2.valueChanges.subscribe(changes => {
      if ( changes === '' ) {
        this.form.controls.newPasswordControl2.setErrors({ required: true });
      }

      if ( this.form.controls.newPasswordControl.value !== changes ) {
        this.form.controls.newPasswordControl2.setErrors({ not_match: true });
      } else {
        this.form.controls.newPasswordControl2.setErrors(null);
      }
    });

    subscribeUntilDestroyed(this.route.queryParamMap.pipe(map(queryMap => {
      this.token = queryMap.get('token');
      this.tokenInvalid = !/^\w{32}$/.test(this.token);
    })), this);
  }

  resetPassword() {
    if ( this.form.invalid ) {
      return;
    }

    const control1 = this.form.get('newPasswordControl');

    const passwd1 = control1.value.trim();

    const pwResetRequest = {
      pass: passwd1,
      key: this.token,
    };

    this.pswdService.resetPassword(pwResetRequest).subscribe(() => {
      this.requestIsSent = true;
    }, error => {
      const control2 = this.form.get('newPasswordControl2');
      control2.setErrors({ network: error.error });
    });
  }
}
