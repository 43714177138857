import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSidenavMenuComponent } from './header-sidenav-menu.component';


@NgModule({
  declarations: [ HeaderSidenavMenuComponent ],
  exports: [ HeaderSidenavMenuComponent ],
  imports: [
    CommonModule,
  ],
})
export class HeaderSidenavMenuModule {
}
