import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCurriculumItemComponent } from './user-curriculum-item.component';
import { CtrlSingleUserCurriculumItemDetailsModule } from '../../single-user/ctrl-single-user-curriculum-item-details/ctrl-single-user-curriculum-item-details.module';
import { ControllingCurriculumMultiActionsModule } from '../../../../component/controlling/controlling-curriculum-multi-actions/controlling-curriculum-multi-actions.module';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [ UserCurriculumItemComponent ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    CtrlSingleUserCurriculumItemDetailsModule,
    ControllingCurriculumMultiActionsModule,
  ],
})
export class UserCurriculumItemModule {
}
