import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { naturalCompare } from '../../../../core/natural-sort';
import { LanguageHelper } from '../../../../core/language.helper';
import { CachedSubject } from '../../../../core/cached-subject';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntypedFormControl } from '@angular/forms';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { map, take } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QAQuestion } from '../qa.types';

@Component({
  selector: 'rag-qa-list',
  templateUrl: './qa-list.component.html',
  styleUrls: ['./qa-list.component.scss']
})
export class QAListComponent
  implements OnInit, OnDestroy {

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    questions: QAQuestion[];
  filteredQuestions: QAQuestion[];

  searchFilter = '';
  tags: string;
  allTags: string[] = [];
  tagsForFilter: string[] = [];
  availableTags: string[] = [];
  tagCtrl = new UntypedFormControl();
  filterByStatusControl = new UntypedFormControl();
  sortBy = new UntypedFormControl();
  sortByValue = '';
  separatorKeysCodes: number[] = [ ENTER, COMMA ];
  index = 0;
  selectedQuestion: QAQuestion;
  withFilterStatus = true;
  cardView = true;

  readonly filteredTags$: Observable<string[]>;
  private _filteredTags$: CachedSubject<string[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this._filteredTags$ = new CachedSubject<string[]>(null);
    this.filteredTags$ = this._filteredTags$.withoutEmptyValues();
  }

  getAnswerDate(question: QAQuestion): number {
    return question?.answer?.lastModified ?? question?.answer?.creationDate;
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {

    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();

    this.route.queryParams
      .pipe(map(querys => this.setFilterFromQueryParams(querys)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.tagCtrl.valueChanges.pipe(map(filterValue => {

      if ( typeof filterValue !== 'string' ) {
        return;
      }

      filterValue = (filterValue ?? '').trim().toLocaleLowerCase();

      if ( filterValue === '' ) {

        this._filteredTags$.next(this.availableTags);
      } else {

        const filteredColumns = this.availableTags
          .filter(column => LanguageHelper.objectToText(column).toLocaleLowerCase().includes(filterValue));
        this._filteredTags$.next(filteredColumns);
      }
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.sortBy.valueChanges.pipe(map(sortBy => {
      this.sortByValue = sortBy;
      this.sortQuestions(sortBy);
      this.addQueryParams();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.sortBy.setValue('dateQuestion', {emitEvent: false});
    this.sortByValue = 'dateQuestion';
    this.sortQuestions(this.sortByValue);

    this.filterByStatusControl.valueChanges.pipe(map(_ => {
      this.filterQuestions();
      this.addQueryParams();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

  }

  sortQuestions(sortBy: string): void {
    if (sortBy === '') {
      return;
    }

    switch (sortBy) {
      case 'dateQuestion':
        this.filteredQuestions
          .sort((a, b) => b.creationDate - a.creationDate);
        break;
      case 'dateAnswer':
        this.filteredQuestions
          .sort((a, b) => {
            const dateA = a.answer?.lastModified ?? a.answer?.creationDate ?? 0;
            const dateB = b.answer?.lastModified ?? b.answer?.creationDate ?? 0;
            return dateB - dateA;
          });
    }
  }

  filterByStatus(): void {
    const filterByStatus = this.filterByStatusControl.value ?? '';
    if (filterByStatus === '') {
      return;
    }

    switch (filterByStatus) {
      case 'NEW':
      case 'DRAFT':
      case 'PUBLISHED':
      case 'ARCHIVED':
      case 'NOT_PUBLISHED':
        this.filteredQuestions = this.filteredQuestions
          .filter(question => question.status === filterByStatus);
    }
  }

  addQueryParams(): void {
    const queryParams: Params = {};
    if (!!this.searchFilter) {
      queryParams['s'] = this.searchFilter;
    }

    if (this.tagsForFilter.length > 0) {
      queryParams['tg'] = this.tagsForFilter.join(',');
    }

    if (this.filterByStatusControl.value != null) {
      queryParams['sf'] = this.filterByStatusControl.value;
    }

    if (this.sortByValue !== null) {
      queryParams['sb'] = this.sortByValue;
    }

    if (this.selectedQuestion != null) {
      queryParams['q'] = this.selectedQuestion.uuid;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  toggleViewMode(): void {
    this.cardView = !this.cardView;
  }

  applySearchFilter(eventTarget: string): void {
    this.searchFilter = eventTarget;
    this.filterQuestions();
    this.addQueryParams();
  }

  getAvailableTags() {
    this.availableTags = [];
    this.allTags.forEach(tag => {
      if ( this.tagsForFilter.indexOf(tag) === -1 ) {
        this.availableTags.push(tag);
      }
    });
    this.sortAvailableTags();
    this._filteredTags$.next(this.availableTags);

  }

  removeTagFromFilter(tag: any) {
    const index = this.tagsForFilter.indexOf(tag.toLowerCase());

    if ( index >= 0 ) {
      this.tagsForFilter.splice(index, 1);
    }

    this.filterQuestions();
    this.getAvailableTags();
    this.addQueryParams();
  }

  selectedTag(event: MatAutocompleteSelectedEvent, trigger: MatAutocompleteTrigger): void {
    this.tagsForFilter.push(event.option.viewValue.toLowerCase());
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    this.getAvailableTags();

    this.filterQuestions();

    // automatically re-open suggestion panel after selection
    setTimeout(() => trigger.openPanel());
    this.addQueryParams();
  }

  splitTags(tagString: string): string[] {
    if (tagString == null || tagString === '') {
      return [];
    }

    const strings = tagString.split(',');
    strings
      .map(tag => tag.toLowerCase());
    return strings;
  }

  onChangeQuestion(question: QAQuestion): void {
    this.selectedQuestion = question;
    this.addQueryParams();
  }

  private filterQuestions() {
    this.filteredQuestions = this.questions;

    this.sortQuestions(this.sortByValue);

    this.filterByStatus();

    if (!!this.searchFilter) {
      const lowerSearchString = this.searchFilter.toLocaleLowerCase();
      this.filteredQuestions = this.filteredQuestions
        .filter(question => question?.questionAbstract?.toLocaleLowerCase().includes(lowerSearchString) ||
          question?.questionText?.toLocaleLowerCase().includes(lowerSearchString));
    }

    if (this.tagsForFilter.length > 0) {
      this.filteredQuestions = this.filteredQuestions.filter(question => {
        const tags = this.splitTags(question.tags);
        return this.tagsForFilter.every(tag => tags.find(arrayTag => arrayTag === tag));
      }
      );
    }
    if (this.filteredQuestions.length === 0) {
      this.selectedQuestion = null;
      return;
    }

    if (!this.filteredQuestions.find(question => question.uuid === this.selectedQuestion?.uuid)) {
      this.selectedQuestion = null;
    }
  }

  private updateRouteData(data): void {
    this.questions = data.content.questions;
    this.filteredQuestions = this.questions;
    let tagArray = this.splitTags(data.content.tags);
    tagArray = tagArray.reduce((pV, tag) => {
      const questionWithTag = this.questions.find(question => this.splitTags(question.tags).includes(tag));
      if (questionWithTag != null) {
        pV.push(tag);
      }
      return pV;
    }, []);
    tagArray.forEach(tag => {
      if (this.allTags.indexOf(tag) === -1) {
        this.allTags.push(tag);
      }
    });

    this.availableTags = MergeHelper.cloneDeep(this.allTags);

    this.sortAvailableTags();

    this._filteredTags$.next(this.availableTags);
  }

  private sortAvailableTags() {
    this.availableTags
      .sort((a, b) =>
        naturalCompare(LanguageHelper.objectToText(a), LanguageHelper.objectToText(b)));
  }

  private setFilterFromQueryParams(querys: Params): void {
    let shouldApplyFilter = false;
    if (querys['s'] != null) {
      shouldApplyFilter = true;
      this.applySearchFilter(querys['s']);
    }

    if (querys['tg'] != null) {
      shouldApplyFilter = true;
      this.tagsForFilter = querys['tg'].split(',');
      this.tagCtrl.setValue(querys['tg'], {emitEvent: false});
    }

    if (querys['sf'] != null) {
      shouldApplyFilter = true;
      this.filterByStatusControl.setValue(querys['sf'], {emitEvent: false});
    }

    if (querys['sb'] != null) {
      shouldApplyFilter = true;
      this.sortByValue = querys['sb'];
      this.sortBy.setValue(querys['sb'], {emitEvent: false});
      this.sortQuestions(this.sortByValue);
    }

    if (!shouldApplyFilter) {
      // default filter value
      this.filterByStatusControl.setValue('PUBLISHED', {emitEvent: false});
    }
    this.filterQuestions();

    if (querys['q'] != null) {
      this.selectedQuestion = this.filteredQuestions
        .find(question => question.uuid === querys['q']) ?? null;
    } else if (this.filteredQuestions.length > 0) {
      this.selectedQuestion = this.filteredQuestions[0];
    }
  }
}
