import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { MergeHelper } from '../../core/primitives/merge.helper';

const Errors = {
  required: true,
  notHasCapital: true,
  notHasLow: true,
  notHasNumber: true,
  notHasSymbol: true,
  min: true,
};

type ERRORS = typeof Errors;

@Component({
  standalone: true,
  imports: [
    MatIconModule,
  ],
  selector: 'rag-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss']
})

export class PasswordValidationComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  errors: ERRORS;
  private _passwordField: UntypedFormControl;

  @Output() readonly valid = new EventEmitter<boolean>();
  @Input() set passwordField(formControl: UntypedFormControl) {
    this._passwordField = formControl;
    this.handleFormField(formControl);
  }

  get passwordField() {
    return this._passwordField;
  }

  constructor() {}

  ngOnInit() {
    this.valid.emit(true);
  }
  ngOnDestroy() {
    destroySubscriptions(this);
  }

  private handleFormField(formControl: UntypedFormControl) {
    this.errors = MergeHelper.cloneDeep(Errors);

    if ( formControl?.value != null ) {
      this.validateChanges(formControl, formControl?.value);
    }

    formControl.valueChanges
      .pipe(map(changes => this.validateChanges(formControl, changes)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private validateChanges(formControl: UntypedFormControl, changes): void {
    formControl.markAsPending();
    if ( changes == null || changes === '' ) {
      this.errors.required = true;
      this.errors.notHasLow = true;
      this.errors.notHasCapital = true;
      this.errors.notHasNumber = true;
      this.errors.notHasSymbol = true;
      this.errors.min = true;
    } else {
      this.errors.required = false;

      const hasCapital = /[A-ZÖÜÄ]/.test(changes);
      const hasLow = /[a-züäöß]/.test(changes);
      const hasNumber = /[0-9]/.test(changes);
      const hasSymbol = /[^a-zA-Z0-9ÖÜÄöüäß]/.test(changes);

      this.errors.notHasCapital = !hasCapital;
      this.errors.notHasLow = !hasLow;
      this.errors.notHasNumber = !hasNumber;
      this.errors.notHasSymbol = !hasSymbol;

      if ( changes.length < 8 ) {
        this.errors.min = true;
      } else {
        this.errors.min = false;
      }
    }
    if ( this.errors.notHasCapital ||
      this.errors.notHasLow ||
      this.errors.notHasNumber ||
      this.errors.notHasSymbol ||
      this.errors.min ||
      this.errors.required ) {
      formControl.setErrors(this.errors);
      this.valid.emit(false);
    } else {
      formControl.setErrors(null);
      this.valid.emit(true);
    }
  }

}
