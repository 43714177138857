import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';

export namespace CtrlSingleUserCurriculumEventsColumns {

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    event: TableControllerTypes.ColumnMenuItem;
    expires: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [],
    endWith: [ 'actions' ],
    menuItems: {
      event: TableControllerTypes.Util.textColumnFactory('event',
        $localize`:@@ctrl_single_user_curriculum_notification_event:Event`, 1, true),
      expires: TableControllerTypes.Util.dateTimeColumnFactory('expires',
        $localize`:@@ctrl_single_user_curriculum_notification_expires:Expires`, 2, true),

      actions: TableControllerTypes.Util.structuralColumnFactory('actions'),
    },
  };

}
