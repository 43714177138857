import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { MailService } from 'src/app/route/user/mail/mail.service';
import { MailBox } from 'src/app/core/mail.types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MailResolverService
  implements Resolve<MailBox> {

  constructor(
    private mailService: MailService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MailBox> {

    const box = route.params['box'];
    const pageIndesStr = route.params['page'];
    const pageSizeStr = route.params['size'];
    const pageIndex = pageIndesStr ? parseInt(pageIndesStr, 10) : 0;
    const pageSize = pageSizeStr ? parseInt(pageSizeStr, 10) : 10;
    const tasks: Array<Observable<any>> = [ this.mailService.getBox(box, pageIndex, pageSize) ];
    const openedMessageIdStr = route.queryParams['m'];

    if ( openedMessageIdStr ) {
      const openedMessageId = parseInt(openedMessageIdStr, 10);
      tasks.push(this.mailService.getById(openedMessageId));
    }

    return combineLatest(tasks).pipe(map(results => {
      const mailBox = results[0]; // mailbox
      mailBox.pageSize = pageSize;
      if ( results.length === 2 ) {
        mailBox.openedMessage = results[1]; // selected message
      }
      return mailBox;
    }));
  }
}
