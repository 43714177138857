import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FileUploadComponent } from './file-upload.component';
import { FileUploadService } from '../../../core/files/file-upload.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatIconModule,
  ],
  exports: [
    FileUploadComponent,
  ],
  providers: [
    FileUploadService,
  ],
})
export class FileUploadModule {
}
