import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtrlEditDisplayStatusComponent } from './ctrl-edit-display-status.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StatusLightsModule } from '../../../../../component/elearning/status-lights/status-lights.module';


@NgModule({
  declarations: [ CtrlEditDisplayStatusComponent ],
  exports: [ CtrlEditDisplayStatusComponent ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    StatusLightsModule,
  ],
})
export class CtrlEditDisplayStatusModule {
}
