import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ImageUrlHelper } from '../../core/image-url-helper';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
  ],
  selector: 'rag-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: [ './contact-card.component.scss' ],
})
export class ContactCardComponent
  implements OnChanges {

  showMoreDescription = false;
  hasMoreDescription = false;

  @Input() name: string;
  @Input() moreInformation: string;
  @Input() description: string;
  @Input() pictureUUID: string;

  constructor() {
  }

  getImageUrl(): string {
    return ImageUrlHelper.urlForPictureUUID(this.pictureUUID);
  }

  onToggleShowMoreDescription(): void {
    this.showMoreDescription = !this.showMoreDescription;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hasLongDescription = this.description?.length > 150;
    this.showMoreDescription = hasLongDescription;
    this.hasMoreDescription = hasLongDescription;
  }

}
