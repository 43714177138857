import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../../component/page-header/page-header.component';
import { MatButtonModule } from '@angular/material/button';
import { TableColumnMenuModule } from '../../../../component/table/table-column-menu/table-column-menu.module';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingIndicatorComponent } from '../../../../component/loading-indicator/loading-indicator.component';
import { CommonModule, NgIf } from '@angular/common';
import { StickyScrollComponent } from '../../../../component/sticky-scroll/sticky-scroll.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { BadgeTemplate } from '../../../../core/gamification/gamification.types';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { TableHeaderFilterDefaultModule } from '../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { catchError, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ADMIN_GAMIFICATION_DEFAULT_MENU_COLUMNS } from './admin-gamification-list.columns';
import { TablesColumnRenderDefaultModule } from '../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { MatDividerModule } from '@angular/material/divider';
import { AdminGamificationService } from '../../../../core/gamification/admin-gamification.service';
import { InfoService } from '../../../../core/info/info.service';
import { DeleteButton, DeleteCancelButtons, InfoType, MessageConstants } from '../../../../core/info/info.types';
import { EMPTY } from 'rxjs';

@Component({
  standalone: true,
  selector: 'rag-admin-gamification-list',
  templateUrl: './admin-gamification-list.component.html',
  imports: [
    PageHeaderComponent,
    MatButtonModule,
    TableColumnMenuModule,
    MatMenuModule,
    LoadingIndicatorComponent,
    NgIf,
    StickyScrollComponent,
    MatTableModule,
    MatSortModule,
    PipesModule,
    MatIconModule,
    MatPaginatorModule,
    CommonModule,
    TablesColumnRenderDefaultModule,
    TableHeaderFilterDefaultModule,
    MatDividerModule,
    RouterLink,
  ],
  styleUrls: [ './admin-gamification-list.component.scss' ],
})
export class AdminGamificationListComponent
  extends TableControllerComponent<BadgeTemplate> implements OnInit, OnDestroy {

  badgeTemplates: BadgeTemplate[];


  constructor(
    private adminGamificationService: AdminGamificationService,
    private route: ActivatedRoute,
    private router: Router,
    private infoService: InfoService,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);
    this.defaultSort = 'title';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onEditBadgeTemplate(badgeTemplateUUID: string): void {
    this.router.navigateByUrl(`/admin/configuration/gamification/edit/${badgeTemplateUUID}`).then();
  }

  onDeleteBadgeTemplate(badgeTemplateUUID: string): void {
    this.infoService.showMessage($localize`:@@gamification_delete_confirm_message:Do you want to delete this badge template?`, {
      buttons: DeleteCancelButtons,
      title: $localize`:@@global_confirm:Confirm`
    })
    .pipe(takeWhile(button => button === DeleteButton))
    .pipe(switchMap(() => this.adminGamificationService.deleteBadgeTemplate(badgeTemplateUUID)))
    .pipe(map(deletedUser => {
      this.badgeTemplates = this.badgeTemplates.filter(template => template.templateUUID != deletedUser.templateUUID);
      this.setTableData(this.badgeTemplates);
      this.infoService.showMessage($localize`:@@gamification_delete_badge_template_successfully:The badge template was deleted successfully.`, {
        infoType: InfoType.Success,
      })
    }))
    .pipe(catchError(_ => {
      this.infoService.showMessage(MessageConstants.API.ERROR, {
        infoType: InfoType.Error,
      })
      return EMPTY;
    }))
    .pipe(take(1))
    .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(ADMIN_GAMIFICATION_DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }
  private updateRouteData(data): void {
    this.badgeTemplates = data.badgeTemplates;
    this.updateAvailableColumns();
    this.setTableData(this.badgeTemplates);
    this.inputDisabled = false;
    this.checkFilter();
  }
}
