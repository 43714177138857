import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Report, ReportConfig } from '../../../../core/report/report.types';
import { Observable } from 'rxjs';
import { ReportService } from '../../../../core/report/report.service';
import { map, switchMap, take } from 'rxjs/operators';
import { RouteSnapshotHelper } from '../../../../core/route-snapshot.helper';
import { ReportGeneratorV2RouteData } from './report-generator-v2.types';
import { ColumnSettingsService } from '../../../../core/column-settings/column-settings.service';
import { ReportTableHelper } from './report-table/report-table.helper';
import { ViewHelper } from '../../../../core/view-helper';


type PartialRouteData = Pick<ReportGeneratorV2RouteData, 'report' | 'reports'>;

export const ReportGeneratorV2Resolver: ResolveFn<ReportGeneratorV2RouteData> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  columnSettingsService: ColumnSettingsService = inject(ColumnSettingsService),
  reportService: ReportService = inject(ReportService)
): Observable<ReportGeneratorV2RouteData> => {
  const curriculumIds = route.params['curriculumId'];
  return reportService.fetchMyReports()
    .pipe(switchMap(reports => getReport(route, reports, reportService, curriculumIds)))
    .pipe(switchMap(data => getColumnSettings(columnSettingsService, data)))
    .pipe(take(1));
};

const getColumnSettings = (
  columnSettingsService: ColumnSettingsService,
  data: PartialRouteData,
): Observable<ReportGeneratorV2RouteData> => {
  const targetType = data.report.reportConfig.targetType;
  return columnSettingsService.getColumnSettings(targetType)
    .pipe(map(ReportTableHelper.createColumnSettings))
    .pipe(map(columnSettings => ({ columnSettings, ...data })));
};

const getReport = (
  route: ActivatedRouteSnapshot,
  reports: Report[],
  reportService: ReportService,
  curriculumIds?: string,
): Observable<PartialRouteData> => {

  // find the optional param 'uuid'
  const uuid = route.paramMap.get('uuid');
  let reportConfig: ReportConfig;
  if ( uuid == null ) {

    // uuid is empty -> find target type
    const targetType = RouteSnapshotHelper
      .getDataValue(route, 'targetType', value => value);

    // and create dummy config
    if ( targetType === 'Course' ) {
      reportConfig = { targetType, filter: {}, groupings: [ 'courseTitle' ] };
    } else if ( targetType === 'Offline' ) {
      reportConfig = { targetType, filter: {}, groupings: [ 'eventTitle', 'eventScheduleTitle' ] };
    } else {
      reportConfig = { targetType, filter: {}, groupings: [ 'curriculumTitle' ] };
    }

  } else {

    const report = reports?.find(r => r?.reportConfig.uuid === uuid);
    reportConfig = report?.reportConfig;
    if ( reportConfig == null ) {
      throw new Error('could not find report! ' + uuid);
    }

    ViewHelper.getViewData(reportConfig)
      .tabOpen = true;
  }

  return reportService.getFullReport(reportConfig, curriculumIds)
    .pipe(map(report => ({ report, reports })));
};

