<div class="container">
<ng-container *ngFor="let filter of (filters$ | async)">
  <div
    *ngIf="filterVisible(filter)"
    class="filter-wrapper"
    [ngClass]="getFilterClass(filter)"
  >
    <ng-container *ngTemplateOutlet="getTemplate(
        filter,
        tplDate,
        tplNumber,
        tplPrice,
        tplText,
        tplDropdown,
        tplTags
      ); context: {filter: filter}"></ng-container>
  </div>
</ng-container>

<div
  *ngIf="hasAsyncFilters"
  class="filter-wrapper show-more"
>
  <div
    class="show-more-tooltip"
    matTooltip="Active filters cannot be hidden"
    i18n-matTooltip="@@content_filter_disabled_tooltip"
    [matTooltipDisabled]="(showMoreDisabled$ | async) === false"
  >
    <button
      (click)="toggleAsyncFilters()"
      type="button"
      class="icon-button"
      mat-button
      [disabled]="(showMoreDisabled$ | async)"
    >
      <ng-container *ngIf="showAsyncFilters">
        <mat-icon svgIcon="filter-minus-outline"></mat-icon>
        <span i18n="@@content_filter_show_less">less...</span>
      </ng-container>
      <ng-container *ngIf="!showAsyncFilters">
        <mat-icon svgIcon="filter-plus-outline"></mat-icon>
        <span i18n="@@content_filter_show_more">more...</span>
      </ng-container>
    </button>
  </div>
</div>

<ng-content></ng-content>
</div>

<ng-template #tplDate let-filter="filter">
  <rag-pivot-filter-date
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [label]="f.label"
    [placeholder]="f.placeholder"
  ></rag-pivot-filter-date>
</ng-template>

<ng-template #tplNumber let-filter="filter">
  <rag-pivot-filter-number
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [label]="f.label"
    [placeholder]="f.placeholder"
    [showActions]="false"
  ></rag-pivot-filter-number>
</ng-template>

<ng-template #tplPrice let-filter="filter">
  <rag-pivot-filter-number
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [label]="f.label"
    [placeholder]="f.placeholder"
  ></rag-pivot-filter-number>
</ng-template>

<ng-template #tplText let-filter="filter">
  <rag-pivot-filter-text
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [label]="f.label"
    [placeholder]="f.placeholder"
    [showActions]="false"
  ></rag-pivot-filter-text>
</ng-template>

<ng-template #tplDropdown let-filter="filter">
  <rag-pivot-filter-dropdown
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [options]="f.dropDownOptions"
    [sortOptions]="f.dropDownSortMethod"
    [showOptionAll]="f.dropDownWithoutAll !== true"
    [label]="f.label"
    [placeholder]="f.placeholder"
  ></rag-pivot-filter-dropdown>
</ng-template>

<ng-template #tplTags let-filter="filter">
  <rag-pivot-filter-tags
    *ngIf="asFilter(filter) as f"
    (change$)="onFilterChange()"
    [debounceTimeout]="f.filterDebounceTimeout"
    [filter]="f.filter"
    [options]="f.dropDownOptions"
    [label]="f.label"
    [placeholder]="f.placeholder"
  ></rag-pivot-filter-tags>
</ng-template>