import { LanguageHelper } from '../language.helper';


export class StringHelper {

  static toString<DATA = any>(value: DATA): string {
    switch ( typeof (value) ) {
      case 'object':
        return LanguageHelper.objectToText(value) ?? '';
      case 'boolean':
      case 'number':
      case 'bigint':
        return String(value);
      case 'string':
        return value;
      case 'function':
      case 'symbol':
        return typeof (value);
      default:
        return String(value ?? '');
    }
  }

}
