import { Directive, Input } from '@angular/core';


@Directive({
  selector: '[ragToggle]',
  exportAs: 'ragToggle',
})
export class ToggleDirective {

  @Input() ragToggle = false;

  get closed(): boolean {
    return this.ragToggle !== true;
  }

  get open(): boolean {
    return this.ragToggle === true;
  }

  toggle = (open?: boolean): void => {
    this.ragToggle = open ?? !this.ragToggle;
  };

}
