export interface QAQuestion {
  uuid: string;
  questionAbstract: string;
  questionText: string;
  creationDate?: number;
  modifiedByAdmin?: boolean;
  authorId?: number;
  tags: string;
  answer?: QAAnswer;
  status: QAStatus;
}

export interface QAResponse {
  questions: QAQuestion[];
  tags: string;
}

export interface QAEditResponse {
  question: QAQuestion;
  tags: string;
}

export interface QAAnswer {
  uuid: string;
  answerAbstract?: string;
  answerText: string;
  creationDate?: number;
  lastModified?: number;
  authorId?: number;
  questionUUID: string;
}

export  interface QANewQuestion {
  title: string;
  question: string;
}

export interface QAListData {
  uuid: string;
  questionAbstract: string;
  creationDate: number;
  tags: string;
  processingDate: number;
  // todo add enum
  status: QAStatus;
  answerText: string;
  questionText: string;
}

export enum QAStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  NOT_PUBLISHED = 'NOT_PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}
