import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseGroupToTgModule } from './license-group-to-tg/license-group-to-tg.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LicenseGroupToTgModule,
  ],
})
export class DevelopmentGoalsModule {
}
