import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';
import { ProfileSectionTypes } from '../../../../component/input/profile-section/profile-section.types';
import { TrainResponse } from '../../../../core/global.types';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType } from '../../../../core/info/info.types';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { ProfileService } from '../../../../core/profile.service';


@Component({
  selector: 'rag-post-login-user-data',
  templateUrl: './post-login-user-data.component.html',
  styleUrls: [ './post-login-user-data.component.scss' ],
})
export class PostLoginUserDataComponent
  implements OnChanges {

    @Input() attributeGroups: ProfileSectionTypes.ProfileSection[];
    @Input() recordFields: ProfileFieldTypes.ProfileField[];
  public completed = false;
  public form: UntypedFormGroup;
  inputDisabled = false;
  section: ProfileSectionTypes.ProfileSection;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private matStepper: MatStepper,
    private principalService: PrincipalService,
    private profileService: ProfileService,
  ) {
    this.form = this.formBuilder.group({});
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ( this.attributeGroups?.length > 0 ) {
      // apply new attribute groups
      this.section = null;

      const groupMap = this.attributeGroups
        .reduce((pV, group) => {
          pV[group.id] = group;
          return pV;
        }, {});

      this.recordFields
        ?.forEach(attribute => {
          const group = groupMap[attribute.groupId];
          if ( group == null ) {
            return;
          }

          group.fields ??= [];
          group.fields.push(attribute);
        });
      return;
    }

    // legacy API
    this.section = {
      column: 0,
      divisionId: 'recordFields',
      fields: this.recordFields,
      title: $localize`:@@post_login_user_data_section:Profile data`,
    };
  }

  onNotNow(): void {
    this.executeQuery(this.saveNotNow());
  }

  onSave(): void {
    if ( this.form.invalid ) {
      return;
    }

    this.executeQuery(this.saveChanges());
  }

  private executeQuery = (query: Observable<any>): void => {
    this.inputDisabled = true;
    this.infoService.snackBar?.dismiss();

    query
      .pipe(take(1))
      .pipe(finalize(() => this.inputDisabled = false))
      .pipe(catchError(this.handleError))
      .pipe(map(() => {
        this.completed = true;
        setTimeout(() => this.matStepper.next());
      }))
      .subscribe();
  };

  private handleError = (): Observable<never> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

  private saveChanges(): Observable<TrainResponse> {
    const profileData = this.profileService.formToProfileData(this.form);

    if ( Object.keys(profileData).length === 0 ) {
      return of(null);
    } else {
      return this.profileService.putUserData(profileData);
    }
  }

  private saveNotNow(): Observable<void> {
    const hasMissingValues = Object.values(this.form.controls)
      .map(control => control.pristine || control.invalid)
      .includes(true);

    if ( !hasMissingValues ) {
      return of(null);
    } else {
      const date = moment().add(1, 'd').toISOString();
      return this.principalService.saveUserSettings('post-login@delayUntil', date)
        .pipe(map(() => void (0)));
    }
  }

}
