<div *ngIf="question != null else noQuestion">
  <mat-divider *ngIf="!isOpenedInCardView"></mat-divider>
    <div class="question"
         [ngClass]="{
         'question-top-round': question?.answer != null,
         'question-round': question?.answer == null,
         'with-border': isOpenedInCardView,
         }"
    >
      <div class="block">
        <div class="bold text-block" *ngIf="isOpenedInCardView">
          <span>{{question?.questionAbstract}}</span>
        </div>
        <div class="text-block-wrapper">
          <span class="text-block">{{question?.questionText}}</span>
          <span class="changed">{{getChangedText()}}</span>
        </div>
        <div class="date-margin">
          <span class="date-text">{{getAskedOnText()}}</span>
        </div>
      </div>
    </div>
    <div class="answer" [ngClass]="{'with-border': isOpenedInCardView}" *ngIf="question?.answer != null">
      <mat-divider *ngIf="!isOpenedInCardView"></mat-divider>
      <div class="block">
        <div class="answer-title">
          <span i18n="@@global_answer">Answer</span>
        </div>
        <div class="text-block-wrapper">
          <span class="text-block">{{question?.answer?.answerText}}</span>
        </div>
        <div class="date-margin">
          <span class="date-text">{{getAnsweredOnText()}}</span>
        </div>
      </div>
    </div>
</div>

<ng-template #noQuestion>
  <div class="no-question">
    <span i18n="@@no_contribution_selected">No contribution is selected.</span>
  </div>
</ng-template>
