<rag-dialog-header>
  <span>{{data.offlineEvent.title | localeText}}</span>
</rag-dialog-header>

<!-- Description -->
<div *ngIf="description" class="description">
  <h3 i18n="@@global_description">Description</h3>
  <p [innerHTML]="description | safe:'html'"></p>
</div>

<div class="card-container">
  <mat-card appearance="outlined" *ngIf="eventDate" class="details-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="icon" svgIcon="clock-start"></mat-icon>
      </div>
      <mat-card-title i18n="@@event_start">Start</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <span>{{eventDate.format('LLL')}}</span>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="eventDateUntil" class="details-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="icon" svgIcon="clock-end"></mat-icon>
      </div>
      <mat-card-title i18n="@@event_end">End</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <span>{{eventDateUntil.format('LLL')}}</span>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="data.offlineEvent?.trainers?.length > 0" class="details-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="icon" svgIcon="account"></mat-icon>
      </div>
      <mat-card-title i18n="@@global_trainer">Trainer</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ol>
        <li *ngFor="let trainer of trainers">{{trainer}}</li>
      </ol>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" *ngIf="!!data.offlineEvent?.location" class="details-card">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="icon" svgIcon="account"></mat-icon>
      </div>
      <mat-card-title i18n="@@global_location">Location</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <rag-location
        [location]="data.offlineEvent.location"
        [showIcon]="false">
      </rag-location>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="attachments.length > 0" class="attachments">
  <!-- Attachments -->
  <h3 i18n="@@attachments">Attachments</h3>
  <div class="attachment-container">
    <rag-attachments [attachments]="attachments"></rag-attachments>
  </div>
</div>

<div mat-dialog-actions>
  <!-- Enter room Button -->
  <button
    *ngIf="isRoom && !isLink"
    [class.hide]="this.url === ''"
    [disabled]="isEnterRoomLocked || isPreview"
    type="button"
    mat-flat-button
    color="primary"
    (click)="onJoinEvent()">
    <span i18n="@@global_ac_enter_room">Enter room</span>
  </button>

  <button
    *ngIf="isLink"
    [class.hide]="this.url === ''"
    type="button"
    mat-flat-button
    color="primary"
    (click)="onJoinEvent()">
    <span i18n="@@global_follow_link">Follow link</span>
  </button>

  <!-- Close button -->
  <button
    type="button"
    class="rag-stroked-button"
    mat-stroked-button
    matDialogClose>
    <span i18n="@@global_close">Close</span>
  </button>

</div>
