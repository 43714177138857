import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtrlSingleUserDetailsLearningDataComponent } from './ctrl-single-user-details-learning-data.component';
import { TodoDataComponent } from './components/todo-data/todo-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableColumnMenuModule } from '../../../../../component/table/table-column-menu/table-column-menu.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CtrlEditDisplayStatusModule } from '../../ctrl-single-user-util/ctrl-edit-display-status/ctrl-edit-display-status.module';
import { MatSortModule } from '@angular/material/sort';
import { TodoDataPreviewDialogComponent } from './components/todo-data-preview-dialog/todo-data-preview-dialog.component';
import { TodoDataV2Module } from './components/todo-data-v2/todo-data-v2.module';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { FileMultiComponent } from 'src/app/component/file-multi/file-multi.component';


@NgModule({
  declarations: [
    CtrlSingleUserDetailsLearningDataComponent,
    TodoDataComponent,
    TodoDataPreviewDialogComponent,
  ],
  exports: [
    CtrlSingleUserDetailsLearningDataComponent,
    TodoDataComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableColumnMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatPaginatorModule,
    PageHeaderComponent,
    PipesModule,
    RouterModule,
    FileMultiComponent,
    CtrlEditDisplayStatusModule,
    MatSortModule,
    DialogTitleComponent,
    DialogHeaderComponent,
    TodoDataV2Module,
  ],
})
export class CtrlSingleUserDetailsLearningDataModule {
}
