import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ClampComponent } from 'src/app/component/clamp/clamp.component';
import { ContentActionModule } from 'src/app/component/elearning/content-action/content-action.module';
import { StatusLightsModule } from 'src/app/component/elearning/status-lights/status-lights.module';
import { Core, ImageableContentReference } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { IconHelper } from 'src/app/core/icon.helper';
import { InfoService } from 'src/app/core/info/info.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MyContentCardCurriculumInfoDialogComponent } from '../my-content-card-curriculum-info-dialog/my-content-card-curriculum-info-dialog.component';
import { LanguageHelper } from '../../../../core/language.helper';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ContentActionModule,
    ClampComponent,
    MatIconModule,
    StatusLightsModule,
    PipesModule
  ],
  selector: 'rag-my-content-card-curriculum',
  templateUrl: './my-content-card-curriculum.component.html',
  styleUrls: ['./my-content-card-curriculum.component.scss']
})
export class MyContentCardCurriculumComponent implements OnInit {

  @Input() content: ImageableContentReference;

  constructor(
    private infoService: InfoService
  ) { }

  getExecutableItem(content: ImageableContentReference): ImageableContentReference {
    return (content.$view as any).executableItem;
  }

  hasDescription(content: ImageableContentReference): boolean {
    const description: string = LanguageHelper.objectToText(this.getExecutableItem(content)?.description);
    return (description.trim().length > 0) && (description !== 'n/a');
  }

  ngOnInit(): void {
  }

  svgIconForObjType(objType: Core.DistributableType) {
    return IconHelper.svgIconForObjType(objType);
  }

  typeForContent(content: ImageableContentReference) {
    return DistributionTypeHelper.asText(content.objType);
  }

  onDetailsClick($event: any, content: ImageableContentReference) {
    this.infoService.showDialog(MyContentCardCurriculumInfoDialogComponent, content);
  }

}
