<div mat-dialog-title class="header">
  <button mat-mini-fab
          color="warn"
          [mat-dialog-close]="true">
    <mat-icon svgIcon="close-thick"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>Schön, dass wir Sie zum <strong>Online Praxis-Camp #spring21</strong> begrüßen dürfen</p>

  <p>Zutritt zu den einzelnen Sessions des <strong>Online Praxis-Camp #spring21</strong> erhalten Sie über unsere <strong>Live-Agenda</strong>.</p>

  <p>Die Sessions finden in <strong>Adobe Connect</strong> statt. Wir empfehlen, die aktuelle Desktop App zu installieren, aber selbstverständlich können Sie auch über Ihren Browser teilnehmen.</p>
  <p><a href="https://www.adobe.com/go/ConnectShell11" target="_blank">Download Windows</a></p>
  <p><a href="https://www.adobe.com/go/ConnectMac11Plus" target="_blank">Download Mac</a></p>

  <p>Zur Teilnahme an einer bestimmten Session klicken Sie einfach auf den jeweiligen Baustein und betreten Sie den Eventraum via Klick auf "Raum betreten"</p>

  <p>An einigen Stellen haben Sie die Wahl zwischen zwei Sessions. Zur Orientierung finden Sie in der Agenda vorher jeweils eine <strong>Auswahlhilfe</strong>. Hier können Sie ein paar kurze Fragen beantworten und erhalten anschließend eine Empfehlung, welche Session am Besten passt. Unsere Referenten in den vorherigen Sessions werden jeweils nochmal darauf eingehen.</p>

  <p>Die jeweils aktiven Sessions sind auf der Agenda gekennzeichnet, sodass Sie sich einfach zurechtfinden können.</p>

  <p>Weitere Hilfe und Informationen finden Sie auch im Lernbaustein Vorbereitung und Hilfe.</p>

  <p>Bei Fragen und Problemen stehen wir Ihnen per Mail unter <a href="mailto:pxc@reflact.com">pxc@reflact.com</a> sowie telefonisch unter <a href="tel:+49 208 77899 707">+49 208 77899 707</a> zur Verfügung</p>
</div>
