import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LearnerCourseUtilModule } from './learner-course-util/learner-course-util.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LearnerCourseUtilModule,
  ],
})
export class LearnerCourseModule {
}
