import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { url as BASE_URL } from 'src/app/app.state';
import { DirtyCheckService } from '../../../core/dirty-check.service';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { StartPageSettings } from '../../admin/account-design/account-design.types';
import { PasswordForgottenService } from './password-forgotten.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { map, take, tap } from 'rxjs/operators';
import { RuntimeEnvironmentService } from '../../../core/runtime-environment.service';


@Component({
  selector: 'rag-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: [ './password-forgotten.component.scss' ],
})
export class PasswordForgottenComponent
  implements OnInit, AfterViewInit, OnDestroy {

  form: UntypedFormGroup;
  requestIsSent = false;
  startPage$: Observable<StartPageSettings>;
  url = '';
  descriptionAboutPasswordForgotten = '';
  passwordResetRequestHasBeenSentText2 = '';
  passwordResetRequestHasBeenSentText3 = '';
  passwordResetRequestHasBeenSentText4 = '';
  passwordResetRequestHasBeenSentText5 = '';

  constructor(
    private accountDesignService: AccountDesignService,
    private dirtyCheckService: DirtyCheckService,
    private platformLocation: PlatformLocation,
    private passwordForgottenService: PasswordForgottenService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
  ) {
    this.startPage$ = this.accountDesignService.getStartPage()
      .pipe();

    this.runtimeEnvironmentService.environment$
      .pipe(tap(env => {

        let sysName = '';
        if ( env.trainApi.includes('/ilearn24') ) {
          sysName = 'iLearn24-';
        }

        this.descriptionAboutPasswordForgotten = $localize`:@@description_about_password_forgotten:
          <ol>
          <li>Enter the username of your ${sysName}account and confirm by clicking "Request password".</li>
          <li>If there is an ${sysName}account with the username you entered, an email will be sent to the email address stored in your ${sysName}account.</li>
          <li>This e-mail contains a link that you can use to set a new password.</li>
          </ol>
          If you do not receive an e-mail, please contact <a href="./#/contact">${sysName}support</a>.`;


        this.passwordResetRequestHasBeenSentText2 = $localize`:@@password_reset_request_has_been_sent_text2:
          If an ${sysName}account exists with the username you provided, an email was sent to the email address stored in your ${sysName}account.`;
        this.passwordResetRequestHasBeenSentText3 = $localize`:@@password_reset_request_has_been_sent_text3:
          Use the link contained in the e-mail to set yourself a new password.`;
        this.passwordResetRequestHasBeenSentText4 = $localize`:@@password_reset_request_has_been_sent_text4:
          If you do not receive the email, please check the spam folder of your email account first.`;
        this.passwordResetRequestHasBeenSentText5 = $localize`:@@password_reset_request_has_been_sent_text5:
            If you have not received an email within five minutes, please contact <a href="./#/contact">${sysName}support.</a>`;
      }))
      .pipe(take(1))
      .subscribe();
  }

  get networkError() {
    if ( this.form == null ) {
      return null;
    }
    return this.form.get('login').errors['network'];
  }

  cancel() {
    this.router.navigate(['login']);
  }

  hasError(componentId: string, errorCode: string): boolean {
    if ( this.form == null ) {
      return null;
    }
    return this.form.get(componentId).hasError(errorCode);
  }

  ngAfterViewInit() {
    this.form.valueChanges.pipe(map(changes => {
      this.dirtyCheckService.submitNextState('PasswordForgottenComponent', !!changes['login']);
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.url = BASE_URL + window.location.pathname + '#/update-password?token={0}';

    const username = this.route.snapshot.queryParamMap.get('username') ?? '';
    this.form = this.formBuilder.group({
      login: [username,[ Validators.required ] ],
    });

    if (!!username) {
      const loginControl = this.form.controls.login;
      loginControl.markAsTouched();
      loginControl.markAsDirty();
    }

  }

  tryPasswordForgotten() {

    if ( this.form.invalid ) {
      return;
    }

    let loginValue = this.form.value['login'];
    if ( loginValue != null ) {
      loginValue = loginValue.trim();
    }

    if ( !(loginValue?.length > 0) ) {
      this.form.get('login').setErrors({
        required: true,
      });
      return;
    }

    const pwResetRequest = {
      login: loginValue,
      reseturl: this.url,
    };

    this.passwordForgottenService.requestPasswordReset(pwResetRequest).subscribe(() => {
        this.dirtyCheckService.submitNextState('PasswordForgottenComponent', false);
        this.requestIsSent = true;
      },
      error => {
        const errorCode = error.errorCode;
        if ( errorCode === '#URN3' ) {
          // nothing to see, here :D
          this.dirtyCheckService.submitNextState('PasswordForgottenComponent', false);
          this.requestIsSent = true;
        } else if ( errorCode === '#URN6' ) {
          const linkGenerated = $localize`:@@password_link_generated:
            A link has already been generated recently and sent to your email. If you do not receive the message, please check the
            junk mail folder of your email account. If any questions remain, please use the
            <a routerLink="/contact">contact form</a> to get in touch.
          `;
          this.form.get('login').setErrors({ network: linkGenerated });
        } else if ( error.error ) {
          this.form.get('login').setErrors({ network: error.error });
        } else {
          this.form.get('login').setErrors({ network_generic: true });
        }
      },
    );

  }
}
