<rag-page-header [backUrl]="backUrl" [backParams]="backParams" [showSubTitle]="!!subtitle">
  <!-- content type for learner account is always sent as DisplayStatus -->
  <div class="page-header-prefix">
    <rag-status-lights
      *ngIf="showStatus"
      [displayStatus]="displayStatus"
      statusType="DisplayStatus"
      [targetType]="curriculum?.objType"
      [courseType]="courseType"
      [hasConfirmation]="hasConfirmation"
    ></rag-status-lights>
  </div>

  <div class="page-header-title">
    <span [title]="title" class="title">{{title}}</span>
  </div>

  <div class="page-header-sub-title">
    <div
      *ngIf="!!subtitle"
      [innerHTML]="subtitle"
    ></div>
  </div>

  <div class="page-header-buttons">
    <ng-content></ng-content>
  </div>


</rag-page-header>
