import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';
import { AnyObject, ImageableContentReference } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { IconHelper } from 'src/app/core/icon.helper';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { ViewHelper } from '../../../core/view-helper';
import { Router, RouterModule } from '@angular/router';
import { UrlHelper } from '../../../core/url.helper';
import { CatalogHelper } from 'src/app/core/catalog/catalog.helpers';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ClampComponent } from '../../clamp/clamp.component';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ClampComponent,
    RouterModule,
    PipesModule,
    MatIconModule,
    MatDividerModule
  ],
  selector: 'rag-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: [ './content-card.component.scss' ],
})
export class ContentCardComponent
  implements OnChanges {

  readonly DEFAULT_PICTURE_URL = 'assets/images/curriculum.jpg';
  @Input() content: ImageableContentReference;
  @Input() contentLink: string | string[] | null;
  @Input() accessoryTemplate: TemplateRef<any>;
  @Input() subtitleAccessoryTemplate: TemplateRef<any>;
  @Input() progressTemplate: TemplateRef<any>;
  @Input() readonly = false;
  @Input() lang: string;

  contentLinkParams: AnyObject<string> | null;
  contentLinkUrl: string;
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL);

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ( changes.content !== undefined ) {
      this.updateImageUrl();
    }

    if ( changes.contentLink !== undefined ) {
      const urlTree = UrlHelper.getUrlTree(this.router, this.contentLink);
      this.contentLinkUrl = UrlHelper.getRouterLink(urlTree) ?? null;
      this.contentLinkParams = UrlHelper.getQueryParams(urlTree) ?? null;
    }
  }

  get svgIconForObjType() {
    return IconHelper.svgIconForObjType(this.content.objType, this.content.objSubType ?? this.content.courseType ?? null);
  }

  get objType() {
    return DistributionTypeHelper.asText(this.content.objType,
      this.content.objSubType ?? this.content.eventType ?? this.content['crsType']);
  }

  get clampCount() {
    if (this.subtitleAccessoryTemplate != null) {
      return 2;
    }
    return 3;
  }

  get isAssigned(): boolean {
    return this.content?.distributed;
  }

  get isBooked(): boolean {
    return CatalogHelper.isBooked(this.content?.catalogBooking);
  }

  private updateImageUrl(): void {

    if ( !this.content ) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if ( pictureFile != null ) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
      this.content.pictureId,
      this.content.cardPictureUUID ??
      this.content.cardPicture?.uuid ??
      this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }

}
