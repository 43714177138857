import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusLightsComponent } from './status-lights.component';

@NgModule({
  declarations: [
    StatusLightsComponent,
  ],
  exports: [
    StatusLightsComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
})
export class StatusLightsModule {
}
