import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SignatureDetails } from '../admin-signatures.types';
import { AdminSignaturesService } from '../admin-signatures.service';


@Injectable({ providedIn: 'root' })
export class AdminSignaturesListResolver
  implements Resolve<SignatureDetails[]> {

  constructor(
    private adminSignaturesService: AdminSignaturesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SignatureDetails[]> {
    return this.adminSignaturesService.getSignatureListV2();
  }

}
