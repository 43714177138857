import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { FormatDatePipe } from '../../../../core/pipes/format-date.pipe';
import { CtrlSingleUserScormLogTypes } from './ctrl-single-user-scorm-log.types';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rag-ctrl-single-user-scorm-log',
  templateUrl: './ctrl-single-user-scorm-log.component.html',
  styleUrls: [ './ctrl-single-user-scorm-log.component.scss' ],
})
export class CtrlSingleUserScormLogComponent
  extends TableControllerComponent<CtrlSingleUserScormLogTypes.ScormLogEntry>
  implements OnDestroy, AfterViewInit {

  backUrl: string;
  rawData: any;
  scoId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserScormLogTypes.DialogData,
    private formatDate: FormatDatePipe,
    private principalService: PrincipalService,
    protected tableColumnMenuService: TableColumnMenuService
  ) {
    super(tableColumnMenuService);
    this.scoId = data.scoId;
    this.updateData(data);
  }

  private static saveExcelFile(buffer: any, fileName: string): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const data: Blob = new Blob([ buffer ], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  private static translateAction(action: string): string {
    switch ( action ) {
      case 'initialize':
        return $localize`:@@ctrl_single_user_scorm_log_action_initialize:Initialize`;
      case 'get':
        return $localize`:@@ctrl_single_user_scorm_log_action_getValue:Get value`;
      case 'set':
        return $localize`:@@ctrl_single_user_scorm_log_action_setValue:Set value`;
      case 'finish':
        return $localize`:@@ctrl_single_user_scorm_log_action_finish:Finish`;
      default:
        return action;
    }
  }

  exportAsExcel(): void {
    const language = this.principalService.currentUser.language;
    const exportData = this.rawData.map(entry => {
      if ( language !== 'de_DE' ) {
        const obj = {
          date: '',
          scoId: '',
          unixTimestamp: '',
          time: '',
          sessionToken: '',
          userId: '',
          action: '',
          field: '',
          value: '',
        };
        obj.scoId += entry.scoId;
        obj.userId += entry.userId;
        obj.sessionToken = entry.sessionToken;
        obj.date += FormatDatePipe.prototype.transform(entry.timestamp, 'date');
        obj.time += FormatDatePipe.prototype.transform(entry.timestamp, 'timeMilli');
        obj.unixTimestamp += entry.timestamp;
        obj.action = entry.action;
        obj.field = entry.field;
        obj.value = entry.value;

        return obj;
      } else {
        const obj = {
          Datum: '',
          scoId: '',
          unixZeitstempel: '',
          Zeit: '',
          SitzungsToken: '',
          BenutzerId: '',
          Aktion: '',
          Feld: '',
          Wert: '',
        };
        obj.scoId += entry.scoId;
        obj.BenutzerId += entry.userId;
        obj.SitzungsToken = entry.sessionToken;
        obj.Datum += FormatDatePipe.prototype.transform(entry.timestamp, 'date');
        obj.Zeit += FormatDatePipe.prototype.transform(entry.timestamp, 'timeMilli');
        obj.unixZeitstempel += entry.timestamp;
        obj.Aktion = entry.action;
        obj.Feld = entry.field;
        obj.Wert = entry.value;

        return obj;
      }
    });
    const fileName = this.data.userLastname + '_' + this.scoId;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: [ 'data' ] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    CtrlSingleUserScormLogComponent.saveExcelFile(excelBuffer, fileName);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    // required for subscribeUntilDestroyed
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(CtrlSingleUserScormLogTypes.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateData(data: CtrlSingleUserScormLogTypes.DialogData): void {
    data.scormLogEntries = data.scormLogEntries.map((entry): CtrlSingleUserScormLogTypes.ScormLogEntry => {
      entry.$date = moment(entry.timestamp);
      entry.$timeMilli = this.formatDate.transform(entry.timestamp, 'timeMilli');
      entry.action = CtrlSingleUserScormLogComponent.translateAction(entry.action ?? '');
      return entry;
    });

    this.rawData = data.scormLogEntries;

    this.updateAvailableColumns();
    this.setTableData(data.scormLogEntries ?? []);
    this.inputDisabled = false;
    this.checkFilter();
  }

}
