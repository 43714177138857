import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,
  ],
  selector: 'rag-snackbar-progress',
  templateUrl: './snackbar-progress.component.html',
  styleUrls: [ './snackbar-progress.component.scss' ],
})
export class SnackbarProgressComponent {

  bufferValue$: Observable<number>;
  progress$: Observable<number>;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { count$: Observable<number>; progress$: Observable<number> },
  ) {
    this.progress$ = data.progress$
      .pipe(startWith(0))
      .pipe(map(ratio => Math.round(100 * ratio)));
    this.bufferValue$ = combineLatest([
      this.progress$,
      data.count$
        .pipe(startWith(0))
        .pipe(map(total => Math.round(100 / total))),
    ])
      .pipe(map(([ current, step ]) => current + step));
  }

}
