<div id="content">
  <rag-page-header>
    <div class="page-header-title" i18n="@@report_saved_eports">Saved reports</div>
  </rag-page-header>

  <rag-report-nav [reports]="reports"></rag-report-nav>

  <div class="loading" *ngIf="isLoading$ | async">
    <rag-loading-indicator></rag-loading-indicator>
  </div>
  <div class="frame-wrapper">
    <iframe [src]="url | safe:'resourceUrl'" allowfullscreen frameborder="no" (load)="emitLoading(false)"></iframe>
  </div>
</div>
