import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, finalize, map, take, takeWhile, tap } from 'rxjs/operators';
import { ApiUrls } from '../../../core/api.urls';
import { ApplicationStateService, ViewMode } from '../../../core/application-state.service';
import { CachedSubject } from '../../../core/cached-subject';
import { ContentService } from '../../../core/content/content.service';
import { Content } from '../../../core/content/content.types';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ViewHelper } from '../../../core/view-helper';
import { ScormApi } from './scorm-api';
import { ScormApiEvent, ScormApiEventType } from './scorm.types';
import * as moment from 'moment';
import { OkButton, YesButton, YesNoButtons } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import {
  GenericMessageDialogComponent
} from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { PermissionStates } from '../../../core/principal/permission.states';
import { ImageableContentReference } from 'src/app/core/core.types';
import { CourseTypeHelper } from '../../../core/course-type-helper';


@Component({
  selector: 'rag-sco-close-dialog',
  templateUrl: 'sco-close-dialog.html',
  styleUrls: [ 'sco-close-dialog.scss' ],
})
export class ScoCloseDialogComponent {

}

let HAS_OVERFLOW_SCROLLING: boolean;
try {
  const elt = document.createElement('div');
  HAS_OVERFLOW_SCROLLING = elt.style['webkitOverflowScrolling'] !== undefined;
} catch ( e ) {
  HAS_OVERFLOW_SCROLLING = false;
}

@Component({
  selector: 'rag-sco',
  templateUrl: './sco.component.html',
  styleUrls: [ './sco.component.scss' ],
})
export class ScoComponent
  implements OnDestroy, OnInit {

  // @see https://stackoverflow.com/a/41095677

  con: ImageableContentReference;
  contentHidden = false;
  fullscreen = new CachedSubject<boolean>(true);
  hasOverflowScrolling = HAS_OVERFLOW_SCROLLING;
  isAdminPreview: boolean;
  sco: Content;
  scoRunning: boolean;
  scormApi: ScormApi;
  startUrl = 'about:blank';
  countdownModus = 'none';
  countdownValue = '';

  protected scoStarting: boolean;
  private _permissions: PermissionStates;
  private _scoFrame: ElementRef;
  private currentUrl: string;
  private countdownInterval: number;

  constructor(
    private applicationStateService: ApplicationStateService,
    private http: HttpClient,
    private navigationService: NavigationService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private infoService: InfoService,
    private principalService: PrincipalService,
    ) {
      this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(take(1))
      .pipe(tap((event: NavigationEnd) => this.currentUrl = event.url))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

      this.principalService.permissionStates$
      .pipe(tap(permissions => this._permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    }

  @ViewChild('scoFrame')
  set scoFrame(scoFrame: ElementRef) {
    this._scoFrame = scoFrame;
  }

  protected static prepareCaptivate(): void {
    // stop captivate from closing current window on finish
    window['DoCPExit'] = () => {
    };
  }

  @HostListener('window:beforeunload', [ '$event' ])
  protected windowBeforeUnload($event) {
    if ( this.scoRunning ) {
      // most browsers don't allow to open the dialog before the alert and the text message will be a generic one from
      // the browser

      // this.infoService.showDialog(GenericMessageDialogComponent, {
      //   messageKey: MessageKey.GENERAL_UNSAVED_CHANGES,
      // });
      $event.returnValue = 'You have unsaved changes.';
    }
  }

  close(): void {
    this.applicationStateService.scoRunning = false;
    this.scoRunning = false;
  }

  navigateBack = (replaceUrl = false): void => {
    if (this.isAdminPreview) {
      this.ngZone.run(() => this.router.navigate(['../../'], {
        replaceUrl, relativeTo: this.route,
      })).then();
      return;
    }

    const backUrl = this.getBackUrl();
    // compare url to prevent redirect loop
    if ( this.currentUrl !== backUrl ) {
      const urlTree = this.router.parseUrl(backUrl);

      const itemId = this.con?.id;
      if ( itemId > 0 ) {
        // add itemId for highlighting of last executed content
        urlTree.queryParams['highlight'] = itemId;
      }

      const url = this.router.serializeUrl(urlTree);
      this.ngZone.run(() => this.router.navigateByUrl(url, {
        replaceUrl,
      })).then();
    }
  };

  ngOnDestroy(): void {
    destroySubscriptions(this);
    this.toggleFullscreen(false);
  }

  ngOnInit() {
    this.con = this.route.snapshot.data.content;
    this.isAdminPreview = this.route.snapshot.data.isPreview;
    const viewData = ViewHelper.getViewData(this.con);
    this.sco = viewData.sco;
    if ( !this.sco ) {
      // should not happen when using a resolver!
      throw new Error('sco not found!');
    }

    let startUrl = ApiUrls.getKey('LearnerScoHref')
      .replace(/{fullHref}/gi, this.sco.fullhref);
    const isPreview = this.isAdminPreview ||
      // switch to preview mode if principal has role "scoprev"
      (this._permissions.isWBTTrackingEnabled === false);
    if (isPreview) {
      startUrl = startUrl.replace('scoExec', 'scoPreview');
    }

    this.scormApi = new ScormApi(this.http, this.isAdminPreview);
    this.scormApi.startSco(startUrl).query
      .pipe(catchError((err) => {
        this.navigateBack(true);
        console?.log(err);
        return err;
      }))
      .pipe(take(1))
      .pipe(tap(() => this.startUrl = startUrl))
      .pipe(tap(this.scoStarted))
      .subscribe();
  }

  onCloseCourse() {
    this.contentHidden = true;
    // show confirm dialog if type is Learning or Test
    // Core.CourseType
    if (!this.isAdminPreview && !this.scormApi.statusComplete &&
      (CourseTypeHelper.isLearning(this.con) || CourseTypeHelper.isTest(this.con))) {
      this.infoService.showMessage(
        $localize`:@@sco_cancel_processing:Do you really want to cancel the processing?`, {
          title: $localize`:@@global_cancel_processing:Cancel processing`,
          buttons: YesNoButtons,
        })
        .pipe(finalize(() => this.contentHidden = false))
        .pipe(take(1))
        .pipe(takeWhile(button => button === YesButton))
        .pipe(tap(() => this.navigateBack()))
        .subscribe();
    } else {
      this.navigateBack();
    }
  }

  toggleFullscreen(fullscreen: boolean) {
    this.applicationStateService.setViewMode(fullscreen ? ViewMode.FULLSCREEN : ViewMode.DEFAULT);
    this.fullscreen.next(fullscreen);
  }

  protected prepareCreate(): void {
    // stop create from closing current window on finish
    const win = this._scoFrame.nativeElement.contentWindow;
    win['myClose'] = () => {
      this.scormApi.LMSFinish();
    };
    // prevent Articulate from closing window
    win['EXIT_BEHAVIOR'] = 'NOTHING';
  }

  protected scoFinished = (): void => {
    this.toggleFullscreen(false);
    if ( this.countdownInterval ) {
      window.clearInterval(this.countdownInterval);
    }

    if ( this.scoRunning ) {
      this.startUrl = 'about:blank';
      this.scormApi = window['API'] = null;
      this.applicationStateService.scoRunning = false;
      this.scoRunning = false;
      this.scoStarting = false;
      this.navigateBack(true);
    }
  };

  protected scoStarted = (): void => {
    this.applicationStateService.scoRunning = true;
    this.scoRunning = true;
    this.toggleFullscreen(this.fullscreen.getValue());
    const API = window['API'] = this.scormApi;
    ScoComponent.prepareCaptivate();
    API.stateChange.pipe(
      map((evt: ScormApiEvent) => {
        if ( evt.type === ScormApiEventType.Initialized ) {
          this.prepareCreate();
          this.prepareInteractionTextsWithRusticiDriver();
        } else if ( evt.type === ScormApiEventType.Finished ) {
          setTimeout(this.scoFinished, 2000);
        }
      }),
      catchError((err) => {
        this.scoFinished();
        return err;
      }),
    )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    if ( this.scormApi.isMaxTimeAllowedSet ) {
      this.startCountdownTimer();
    }
  };

  private startCountdownTimer() {
    if ( this.countdownInterval ) {
      window.clearInterval(this.countdownInterval);
    }
    this.countdownModus = 'show';
    this.countdownInterval = window.setInterval(
      () => {
        try {
          this.countdownValue = this.getTimeUntilMaxTimeAllowed();
        } catch ( e ) {
          if ( e.message === 'Max time allowed reached' ) {
            this.infoService.showDialog(GenericMessageDialogComponent, {
              message: $localize`:@@max_time_allowed_expired_title:The allowed execution time expired. The content will be closed now.`,
              title: $localize`:@@max_time_allowed_expired:The time cap is reached.`,
              buttons: OkButton,
            }).subscribe(button => {
              if ( button === OkButton ) {
                this.contentHidden = false;
                this.navigateBack();
              }
            });
            this.contentHidden = true;
          } else {
            console.error(e);
          }
          window.clearInterval(this.countdownInterval);
        }
      },
      1000,
    );
  }

  private getTimeUntilMaxTimeAllowed(): string {
    if ( this.scormApi.isMaxTimeAllowedSet ) {
      /*const diff = moment.utc().diff(this.scormApi.maxTimeAllowedEndDate);*/
      const diff = this.scormApi.maxTimeAllowedEndDate.diff(moment.utc());
      const duration = moment.duration(diff);

      if ( duration.asSeconds() < 60 ) {
        this.countdownModus = 'maintenance-danger';
      }
      if ( duration.asMilliseconds() < 0 ) {
        throw Error('Max time allowed reached');
      }
      return (duration.hours() < 10 ? '0' : '') + duration.hours() + ':' +
        (duration.minutes() < 10 ? '0' : '') + duration.minutes() + ':' +
        (duration.seconds() < 10 ? '0' : '') + duration.seconds();
    }
    return '';
  }

  private getBackUrl() {
    const $view = ViewHelper.getViewData(this.con);
    const url = ContentService.getContentHref($view.parent || this.con, false);
    if ( url ) {
      // if we have a valid url for the parent, go there
      return url;
    }
    return this.navigationService.getBackUrl('/content-overview');
  }

  private prepareInteractionTextsWithRusticiDriver() {
    let cleared = false;
    // console?.log( 'start prepareInteractionTextsWithRusticiDriver' );

    const timer = setInterval(function() {
      // console?.log( 'trying prepareInteractionTextsWithRusticiDriver' );

      const scoFrame = this.frames['scoFrame'];
      const canBeApplied = scoFrame
        && scoFrame.SCORM_RecordInteraction
        && typeof scoFrame.SCORM_RecordInteraction === 'function'
        && !scoFrame.SCORM_RecordInteractionOrig;

      if ( canBeApplied ) {
        scoFrame.SCORM_RecordInteractionOrig = scoFrame.SCORM_RecordInteraction;
        scoFrame.SCORM_RecordInteraction = function(strID, strResponse, blnCorrect, strCorrectResponse, strDescription) {
          let intInteractionIndex = scoFrame.SCORM_CallLMSGetValue('cmi.interactions._count');
          if ( intInteractionIndex === '' ) {
            intInteractionIndex = 0;
          }
          const result = scoFrame.SCORM_RecordInteractionOrig.apply(this, arguments);
          scoFrame.SCORM_CallLMSSetValue('cmi.interactions.' + intInteractionIndex + '.description', strDescription);
          return result ;
        };
        clearInterval(timer);
        cleared = true;
        // console?.log( 'done prepareInteractionTextsWithRusticiDriver' );
      }
    }, 1000);

    /*clear interval if not applied within a minute*/
    window.setTimeout(() => {
      if ( !cleared ) {
        // console?.log( 'aborting prepareInteractionTextsWithRusticiDriver' );
        clearInterval(timer);
      }
    }, 60000);
  }

}
