import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { PreloadService } from '../../../core/preload.service';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoginFormData } from './login-form.types';


@Injectable({ providedIn: 'root' })
export class LoginFormService {

  constructor(
    private accountDesignService: AccountDesignService,
    private preloadService: PreloadService,
  ) {
  }

  getLoginFormData(): Observable<LoginFormData> {
    return forkJoin([
      this.preloadService.getDoubleOptIn().pipe(take(1)),
      this.preloadService.getLoginMethods().pipe(take(1)),
      this.accountDesignService.getStartPage().pipe(take(1)),
      this.preloadService.getAnonymousRegistration().pipe(take(1)),
    ])
      .pipe(map(([ doubleOptInEnabled, loginMethods, startPageSettings, anonymousRegistrationEnabled ]) =>
        ({ doubleOptInEnabled, loginMethods, startPageSettings, anonymousRegistrationEnabled })));
  }

}
