import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { InfoModule } from 'src/app/core/info/info.module';
import {
  ActivateButton,
  CancelButton,
  ConfirmButton,
  DeactivateButton,
  DeleteButton,
  DiscardButton,
  InfoDialogOptions,
  NoButton,
  OkButton,
  RemoveButton,
  YesButton,
  NextButton,
} from '../../core/info/info.types';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    InfoModule,
    DialogHeaderComponent
  ],
  selector: 'rag-generic-message-dialog',
  templateUrl: './generic-message-dialog.component.html',
  styleUrls: [ './generic-message-dialog.component.scss' ],
})
export class GenericMessageDialogComponent
  implements OnInit {

  // Good loves JS :)
  CancelButton = CancelButton;
  ConfirmButton = ConfirmButton;
  DeleteButton = DeleteButton;
  NoButton = NoButton;
  OkButton = OkButton;
  RemoveButton = RemoveButton;
  YesButton = YesButton;
  DiscardButton = DiscardButton;
  ActivateButton = ActivateButton;
  DeactivateButton = DeactivateButton;
  NextButton = NextButton;
  buttons: number;
  message: string;
  messages?: Array<string>;
  messageKey: string;
  title: string;
  titleKey: string;
  closeable = true;

  constructor(
    public dialogRef: MatDialogRef<GenericMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogOptions,
  ) {
  }

  get showButtonCancel() {
    return this.buttons & CancelButton;
  }

  get showButtonConfirm() {
    return this.buttons & ConfirmButton;
  }

  get showButtonDelete() {
    return this.buttons & DeleteButton;
  }

  get showButtonNo() {
    return this.buttons & NoButton;
  }

  get showButtonOk() {
    return this.buttons & OkButton;
  }

  get showButtonYes() {
    return this.buttons & YesButton;
  }

  get showRemoveButton() {
    return this.buttons & RemoveButton;
  }

  get showDiscardButton() {
    return this.buttons & DiscardButton;
  }

  showButton(button: number): boolean {
    return (this.buttons & button) !== 0;
  }

  ngOnInit() {
    this.message = this.data.message;
    this.messages = this.data.messages;
    this.messageKey = this.data.messageKey;
    this.buttons = this.data.buttons;
    this.title = this.data.title;
    this.titleKey = this.data.titleKey;
    if (this.data.closeable !== undefined) {
      this.closeable = this.data.closeable;
    }
  }

}
