import { Injectable } from '@angular/core';
import { ExternalLogin } from 'src/app/core/global.types';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ExtLoginsService } from '../../../../core/ext-logins.service';

@Injectable({
  providedIn: 'root',
})
export class ExtLoginsResolver
  implements Resolve<Array<ExternalLogin>> {


  constructor(
    private extLoginsService: ExtLoginsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExternalLogin[]> {

    return this.extLoginsService.fetchLogins();
  }
}
