import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { Core } from '../../core/core.types';
import { ClampComponent } from '../clamp/clamp.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ClampComponent
  ],
  selector: 'rag-sub-title',
  templateUrl: './sub-title.component.html',
  styleUrls: [ './sub-title.component.scss' ],
})
export class SubTitleComponent
  implements OnInit {

  @Input() objType: Core.DistributableType;
  @Input() objSubType?: Core.CourseType;
  @Input() isMandatory?: boolean;
  type: string;

  ngOnInit(): void {
    this.type = DistributionTypeHelper.asText(this.objType, this.objSubType);
  }

}
