import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { CurriculumPathSwitchTypes } from 'src/app/core/curriculum/curriculum-path-switch.types';
import { TableGroupingHelper } from '../../../../../component/table/table-grouping/table-grouping.helper';
import { CachedSubject } from '../../../../../core/cached-subject';
import { ColumnFilterV2 } from '../../../../../core/column-settings/column-filter.types';
import { Core, NumberedAnyObject } from '../../../../../core/core.types';
import { CurriculumPathSwitchService } from '../../../../../core/curriculum/curriculum-path-switch.service';
import { InfoService } from '../../../../../core/info/info.service';
import { InfoType, MessageConstants } from '../../../../../core/info/info.types';
import { LanguageHelper } from '../../../../../core/language.helper';
import { PermissionStates } from '../../../../../core/principal/permission.states';
import { PrincipalService } from '../../../../../core/principal/principal.service';
import { ReportFilterHelper } from '../../../../../core/report/report-filter.helper';
import { ReportService } from '../../../../../core/report/report.service';
import { Report, ReportConfig } from '../../../../../core/report/report.types';
import { UserNameHelper } from '../../../../../core/user-name.helper';
import { ViewHelper } from '../../../../../core/view-helper';
import { MailService } from '../../../../user/mail/mail.service';
import { CurrValidityComponent } from '../../../single-user/ctrl-single-user-details/ctrl-single-user-certificates/components/curr-validity/curr-validity.component';
import { CtrlSingleUserCertificates } from '../../../single-user/ctrl-single-user-details/ctrl-single-user-certificates/ctrl-single-user-certificates.types';
import { ControllingSingleUserService } from '../../../single-user/ctrl-single-user-util/ctrl-single-user.service';
import {
  ReportTitleDialogComponent,
  ReportTitleDialogComponentData
} from '../../report-generator/report-title-dialog/report-title-dialog.component';
import { ReportGeneratorV2Helper } from '../report-generator-v2.helper';
import { ReportTableHelper } from './report-table.helper';
import { ReportTableRow, ReportTableRowChild, ReportTableRowParent } from './report-table.types';


@Injectable({ providedIn: 'root' })
export class ReportTableService {

  readonly loading$: Observable<boolean>;
  readonly repaint$: Observable<void>;
  readonly report$: Observable<Report>;
  readonly selectedRows$: Observable<ReportTableRow[]>;
  private _canSaveNewReport: boolean;
  private _isDirty = false;
  private _isNew = false;
  private _loading$ = new CachedSubject<boolean>(true);
  private _originalReportConfig: ReportConfig;
  private _permissions: PermissionStates;
  private _repaint$ = new EventEmitter<void>(true);
  private _report$ = new CachedSubject<Report>(null);
  private _selectedRows$ = new CachedSubject<ReportTableRow[]>(null);

  constructor(
    private curriculumPathSwitchService: CurriculumPathSwitchService,
    private infoService: InfoService,
    private mailService: MailService,
    private reportService: ReportService,
    private router: Router,
    private principalService: PrincipalService,
    private controllingSingleUserService: ControllingSingleUserService,
  ) {
    this.loading$ = this._loading$.withoutEmptyValues();
    this.repaint$ = this._repaint$.asObservable();
    this.selectedRows$ = this._selectedRows$.withoutEmptyValues();

    this.report$ = this._report$.withoutEmptyValues();

    this.reportService.canSaveMore()
      .pipe(tap(canSaveNewReport => this._canSaveNewReport = canSaveNewReport))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => this._permissions = permissions))
      .subscribe();
  }

  get canSaveNewReport(): boolean {
    return this._canSaveNewReport === true;
  }

  get isDirty(): boolean {
    return this._isDirty === true;
  }

  get isNew(): boolean {
    return this._isNew === true;
  }

  get report(): Report | null {
    return this._report$.value;
  }

  get reportConfig(): ReportConfig | null {
    return this._report$.value?.reportConfig;
  }

  get selectedRows(): ReportTableRowChild[] | null {
    return (this._selectedRows$.value ?? [])
      .filter(entry => (entry?.$rowType === 'child') && (entry.$filterVisible !== false))
      .map(entry => entry as ReportTableRowChild);
  }

  hasActions(row: ReportTableRow): boolean {
    if ( row?.$rowType === 'child' ) {
      return this.hasMultiActions(TableGroupingHelper.getChildren(row));
    }

    const childRow = row as ReportTableRowChild;
    return childRow.$filterVisible ||
      this.maySave(childRow) ||
      this.maySendMessage(childRow);
  }

  hasMultiActions(rows: ReportTableRow[] = []): boolean {

    if ( !(rows?.length > 0) ) {
      return false;
    }

    return rows
      // check only children
      .filter(entry => entry?.$rowType === 'child')
      // find the first entry which may be saved
      .find(entry => this.hasActions(entry)) != null;
  }

  isCourse(row: ReportTableRowChild): boolean {
    return (row?.userId > 0) && (row.courseId > 0);
  }

  isCurriculum(row: ReportTableRowChild): boolean {
    return (row?.userId > 0) && (row.curriculumId > 0);
  }

  isCurriculumAndCertified(row: ReportTableRowChild): boolean {
    return (row?.userId > 0) && (row.curriculumId > 0) && (row?.accountValidSince > 0);
  }
  isPrincipal(row: ReportTableRowChild): boolean {
    return this.principalService.userId === row?.userId;
  }

  maySave(row: ReportTableRowChild): boolean {

    const data = row?.$data;
    if ( (data == null) || !(row?.userId > 0) ) {
      // row does not contain the necessary values
      return false;
    }

    if (
      // you are not allowed to edit your own account
      this.isPrincipal(row) ||
      // check permission to change curriculum accounts
      (this.isCurriculum(row) && !this._permissions?.hasCtrlEditCurriculumAccount) ||
      // check permission to change course accounts
      (this.isCourse(row) && !this._permissions?.hasCtrlEditCourseAccount)
    ) {
      return false;
    }

    // find rows that have edit permission
    const rbacActions: Core.RbacActionsElearning = data.rbacActions;
    // allow true and null / undefined as acceptable -> backwards compatible
    return rbacActions?.maySave !== false;
  }

  maySendMessage(row: ReportTableRowChild): boolean {

    const data = row?.$data;
    if ( (data == null) || !(row?.userId > 0) ) {
      // row does not contain the necessary values
      return false;
    }

    if ( this._permissions?.userMessagesSend === false ) {
      return false;
    }

    // find rows that have message send permission
    const rbacActions: Core.RbacActionsElearning = data.rbacActions;
    // allow true and null / undefined as acceptable -> backwards compatible
    return rbacActions?.maySend !== false;
  }

  onSendMessage(rows: ReportTableRowChild[]): void {

    const userIds = (rows ?? [])
      .filter(row => this.maySendMessage(row))
      .map(row => row.userId);
    if ( !(userIds.length > 0) ) {
      return;
    }

    this.mailService.convertPrincipalIdsIntoMessageAccountIds(userIds)
      .pipe(switchMap(msgAccounts => this.mailService.showMailComposer(null, 'inbox', msgAccounts)))
      .pipe(catchError(this.handleErrors))
      .pipe(take(1))
      .subscribe();
  }

  onSwitchPath(rows: ReportTableRowChild[]): void {

    const dataMap = (rows ?? [])

      .filter(row => this.maySave(row))

      .reduce((pV, row) => {

        const curriculumId = row.curriculumId;
        if ( !pV.hasOwnProperty(curriculumId) ) {
          pV[curriculumId] = {
            curriculumId,
            users: [],
          };
        }

        pV[curriculumId].users.push({ userId: row.userId });
        return pV;
      }, {} as NumberedAnyObject<CurriculumPathSwitchTypes.CurriculumSwitchData>);

    const data = Object.values(dataMap);
    if ( !(data.length > 0) ) {
      return;
    }

    this.curriculumPathSwitchService.switchPaths(data)
      .pipe(take(1))
      .pipe(catchError(this.handleErrors))
      .pipe(switchMap(() => this.triggerReload()))
      .subscribe();
  }

  resetReport(): void {
    this._report$.reset();
  }

  saveReportConfig(curriculumIds?: Array<number>): void {

    if (
      // report is empty o.0
      (this.report == null) ||
      // maximum number of reports reached
      (this._isNew && !this._canSaveNewReport) ) {
      return;
    }

    this._loading$.next(true);

    const isNew = this._isNew;

    this.checkReportTitle()

    .pipe(tap(report => report.reportConfig.curriculumIds = curriculumIds))

      .pipe(switchMap(report => this.reportService.saveReport(report)))

      .pipe(tap(() => this._isDirty = this._isNew = false))
      .pipe(finalize(() => this._loading$.next(false)))

      .pipe(switchMap(report => this.afterReportSaved(report, isNew)))

      .pipe(take(1))
      .subscribe();
  }

  saveReportConfigAs(): void {

    if (
      // report is empty o.0
      (this.report == null) ||
      // maximum number of reports reached
      (!this._canSaveNewReport) ) {
      return;
    }

    this._loading$.next(true);
    this.reportService.saveAsReport(this.report.reportConfig, false)

      .pipe(finalize(() => this._loading$.next(false)))
      .pipe(takeWhile(result => result != null))

      .pipe(tap(() => this._isDirty = this._isNew = false))
      .pipe(switchMap(result => this.afterReportSaved(result.report, result.openAfterSave)))

      .pipe(take(1))
      .subscribe();
  }

  setReport = (report: Report | null): void => {
    const reportConfig = this._originalReportConfig = report?.reportConfig;

    if ( report != null ) {
      // prevent changing the original copy to allow comparing
      report.reportConfig = ViewHelper.cloneDeep(reportConfig);
      // calculate the statistics
      report.statistics = ReportGeneratorV2Helper.calculateStatistics(report.data);
    }

    // initialize dirty and new flags
    this._isDirty = this._isNew = ReportTableHelper.isNew(reportConfig);

    // notify subscribers
    this._report$.next(report);
    this._loading$.next(false);
  };

  setSelectedRows(rows: ReportTableRow[]): void {
    this._selectedRows$.next(rows ?? []);
  }

  toggleGrouping(row: ReportTableRowParent): void {
    const reportConfig = this.report?.reportConfig;
    if ( reportConfig == null ) {
      return;
    }

    if ( row.$expanded ) {

      // close self and all children
      TableGroupingHelper.collapseChildren(row, true);

    } else {

      // expand self (show immediate children), and expand all parents
      TableGroupingHelper.expandParents(row, true);
    }

    // trigger update
    this.triggerRepaint();
  }

  triggerReload(): Observable<Report> {
    return this.reloadReport(this.report?.reportConfig);
  }

  triggerRepaint(): void {
    this._repaint$.emit();
  }

  updateColumns(): void {
    const reportConfig = this.report?.reportConfig;
    if ( reportConfig == null ) {
      return;
    }

    const columns = (reportConfig.columnSettings ?? [])
      .filter(column => column.selected && column.enabled)
      .map(column => column.id);

    const previousColumns = reportConfig.columns ?? [];
    const groupings = reportConfig.groupings ?? [];
    const needsReload =
      // there are more items than before -> new columns must be loaded
      (previousColumns.length < columns.length) ||
      // there are some columns that were not included before -> needs reload
      (columns.find(column =>
        !previousColumns.includes(column) && !groupings.includes(column),
      ) != null);

    reportConfig.columns = columns;

    this.updateDirtyState();

    if ( needsReload ) {
      this.triggerReload()
        .pipe(take(1))
        .subscribe();
    } else {
      this.triggerRepaint();
    }
  }

  updateFilter(): void {
    const reportConfig = this.report?.reportConfig;
    if ( reportConfig == null ) {
      return;
    }

    const columnFilters: ColumnFilterV2<string, string>[] = Object.entries(reportConfig.settings ?? {})
      .map(([ identifier, column ]) => ({
        identifier,
        action: column.filterAction,
        value: column.filter,
      }));

    reportConfig.filter = ReportFilterHelper.asFilterExpression(columnFilters);

    this.updateDirtyState();
  }

  updateGroupings(groupings: string[] = [], needsReload: boolean): void {
    const reportConfig = this.report?.reportConfig;
    if ( reportConfig == null ) {
      return;
    }

    reportConfig.groupings = groupings;

    this.updateDirtyState();

    if ( needsReload ) {

      // load missing data
      this.triggerReload()
        .pipe(take(1))
        .subscribe();

    } else {

      // trigger complete rebuild of table data (to include changed groupings)
      this._report$.next(this.report);
    }
  }

  onEditLatestValidity(reportTableRowChildren: ReportTableRowChild) {

    const userId = reportTableRowChildren?.userId;
    const curriculumId = reportTableRowChildren?.curriculumId;
    const curriculumTitle = reportTableRowChildren?.curriculumTitle;

    this.controllingSingleUserService
      .getCurriculumHistory(userId, curriculumId)
      .pipe(take(1))
      .pipe(switchMap(data => {
        const latestCertifiedIteration = data.history[0];
        const iterationData = {
          curriculumId,
          curid: curriculumId,        // mess with types
          iteration: latestCertifiedIteration.it,
          validSince: latestCertifiedIteration.validSince,
          validUntil: latestCertifiedIteration.validUntil,
        };
        return this.infoService.showDialog<CurrValidityComponent, CtrlSingleUserCertificates.EditValidityDialogParams, boolean>(
          CurrValidityComponent, {
            userId,
            userName: UserNameHelper.getFullNameWithTitle(reportTableRowChildren?.userFirstname, reportTableRowChildren?.userLastname),
            curriculumId,
            curriculumTitle: LanguageHelper.objectToText(curriculumTitle),
            certificateAccount: iterationData,
          });
      }))
      .pipe(switchMap(() => this.triggerReload()))
      .pipe(take(1))
      .subscribe();
  }

  onForceReload() {
    this.triggerReload()
      .pipe(take(1))
      .subscribe();
  }

  private afterReportSaved(report: Report, isNew: boolean): Promise<boolean> | Observable<void> {
    const uuid = report?.reportConfig?.uuid ?? '';
    if ( isNew && uuid !== '' ) {
      // the report is new -> we need to navigate to the opened report
      return this.router.navigateByUrl(`/report/v2/saved/${uuid}`);
    }

    return EMPTY;
  }

  private checkReportTitle(): Observable<Report> {
    const report = this.report;
    if ( !this._isNew ) {
      return of(report);
    }

    return this.infoService.showDialog<ReportTitleDialogComponent,
      ReportTitleDialogComponentData, { title?: string }>(ReportTitleDialogComponent, {
      title: this.report != null ? this.report.reportConfig.title : '',
      dialogTitleKey: '@@global_save',
      hideOpenCheckbox: true,
    }, { width: '350px' })

      .pipe(map(result => (result?.title ?? '').trim()))

      // empty title -> canceled
      .pipe(takeWhile(title => title !== ''))

      .pipe(map(title => {
        // copy title to report
        report.reportConfig.title = title;
        return report;
      }));
  }

  private handleErrors = (): Observable<void> => {
    this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
    return EMPTY;
  };

  private reloadReport(reportConfig: ReportConfig): Observable<Report> {
    if ( reportConfig == null ) {
      this._report$.reset();
      return EMPTY;
    }

    this._loading$.next(true);
    return this.reportService.getFullReport(reportConfig)
      .pipe(finalize(() => this._loading$.next(false)))
      .pipe(tap(this._report$.next));
  }

  private updateDirtyState(): void {
    if ( this._isNew ) {
      // new entries are always dirty
      this._isDirty = true;
      return;
    }

    const originalConfig = this._originalReportConfig;
    if ( originalConfig == null ) {
      // no need to compare if there is no original config
      this._isDirty = true;
      return;
    }

    const workingConfig = this.report?.reportConfig;
    if ( workingConfig == null ) {
      // hmm... -.-
      this._isDirty = false;
      return;
    }

    this._isDirty =
      // compare groupings
      JSON.stringify(workingConfig.groupings) !==
      JSON.stringify(originalConfig.groupings ?? []) ||

      // compare filters
      JSON.stringify(workingConfig.filter) !==
      JSON.stringify(originalConfig.filter ?? {}) ||

      // compare columns
      JSON.stringify(workingConfig.columns) !==
      JSON.stringify(originalConfig.columns ?? []);
  }

}
