import { Component, HostBinding, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalService } from '../../core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { SettingsToggleComponent } from '../settings/settings-toggle/settings-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'rag-beta-toggle',
  standalone: true,
  imports: [CommonModule, SettingsToggleComponent, MatSlideToggleModule, ReactiveFormsModule],
  templateUrl: './beta-toggle.component.html',
  styleUrls: ['./beta-toggle.component.scss']
})
export class BetaToggleComponent
  implements OnDestroy {

  formControl = new FormControl();
  @HostBinding('class.beta') showBeta = false;

  constructor(
    private principalService: PrincipalService,
  ) {
    this.principalService.permissionStates$
      .pipe(tap(permissions => {
        this.showBeta = permissions.showBeta;
        this.formControl.setValue(this.showBeta, { emitEvent: false });
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.formControl.valueChanges
      .pipe(tap(showBeta => this.principalService.setShowBeta(showBeta)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

}
