import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { at, each, isNull, isNumber, isObject, isString, isUndefined, keys } from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { ApiUrls } from '../../../core/api.urls';
import { CachedSubject } from '../../../core/cached-subject';
import { RagRequestMethod, RagRequestOptions } from './rag-request-options';
import {
  ScormApiEvent,
  ScormApiEventType,
  ScormApiState,
  ScormCmiTree,
  ScormCodePointer,
  ScormError,
  ScormQueryResponse,
  ScormQueryResult,
  ScormResult,
  ScormResultValue,
} from './scorm.types';
import * as moment from 'moment';

export class ScormApi {

  readonly API_ADAPTER = ApiUrls.getKey('LmsJavascriptAdapter');
  readonly queryHistory: ScormQueryResult[] = [];
  readonly queryQueue: ScormQueryResult[] = [];
  isMaxTimeAllowedSet = false;
  maxTimeAllowedEndDate;
  state: ScormApiState;
  private _finishResult: ScormQueryResult;
  private _queueEmpty = new CachedSubject<boolean>(null);
  private _queueWorkerRunning = false;
  private _sessionStarted = false;
  private _stateChange = new CachedSubject<ScormApiEvent>(null);

  constructor(
    private http: HttpClient,
    private logCommunication = false,
  ) {
    this.resetApi();
  }

  get queueEmpty(): Observable<boolean> {
    this.queueWorker();
    return this._queueEmpty
      .pipe(startWith(this.queryQueue.length > 0))
      .pipe(filter(CachedSubject.isNotEmpty))
      .pipe(distinctUntilChanged());
  }

  get sessionFinished(): boolean {
    return !!this._finishResult;
  }

  get sessionStarted(): boolean {
    return this._sessionStarted;
  }

  get stateChange(): Observable<ScormApiEvent> {
    return this._stateChange
      .pipe(startWith(this._stateChange.value))
      .pipe(filter(CachedSubject.isNotEmpty));
  }

  get statusComplete(): boolean {
    return ['passed', 'completed', 'browsed'].includes(this.state?.cmiCoreElements['cmi.core.lesson_status']);
  }

  static queryParseScormResponse(response: string): ScormQueryResponse {
    const obj: ScormQueryResponse = {};
    if ( /\d?;:;[+][+].*/.test(response) ) {
      const responseTokens = response.split(';:;++');
      if ( responseTokens && responseTokens.length > 1 ) {
        obj.lastError = responseTokens[0] as any;
        obj.result = responseTokens[1];
      }
    } else {
      obj.result = response;
    }
    return obj;
  }

  // noinspection JSUnusedGlobalSymbols
  LMSCommit(param?: string): string {
    return this.handleQueryResult(this.LMSCommitImpl(param));
  }

  // noinspection JSMethodCanBeStatic
  LMSCommitImpl(param?: string): ScormQueryResult {
    if ( !param ) {
      return ScormQueryResult.createSuccess(ScormCodePointer.LMSCommitImpl1);
    }
    return ScormQueryResult.createFailure(ScormCodePointer.LMSCommitImpl2, ScormError.INVALID_ARGUMENT);
  }

  // noinspection JSUnusedGlobalSymbols
  LMSFinish(param?: string): string {
    let result = this._finishResult;
    if ( result && result.result ) {
      return result.result;
    }

    // skip if content is already finished (or finishing)
    result = this._finishResult = this.LMSFinishImpl(param);
    return this.handleQueryResult(result);
  }

  LMSFinishImpl(param?: string): ScormQueryResult {
    if ( this._finishResult ) {
      return this._finishResult;
    }

    if ( !param ) {
      const queryResult = this._finishResult = ScormQueryResult.createSuccessWithQuery(ScormCodePointer.LMSFinishImpl1,
        (result: ScormQueryResult) => this.query('finish', null, result).pipe(
            map(() => ScormResult.SUCCESS),
            catchError(() => of(ScormResult.SUCCESS)),
          ));
      this.emitStateEvent(queryResult, { type: ScormApiEventType.Finished });
      return queryResult;
    }
    return ScormQueryResult.createFailure(ScormCodePointer.LMSFinishImpl2, ScormError.INVALID_ARGUMENT);
  }

  // noinspection JSUnusedGlobalSymbols
  LMSGetDiagnostic(): string {
    return this.state.diagnostic;
  }

  // noinspection JSUnusedGlobalSymbols
  LMSGetErrorString(): string {
    return this.state.errorString;
  }

  // noinspection JSUnusedGlobalSymbols
  LMSGetLastError(): string {
    return this.state.lastError;
  }

  // noinspection JSUnusedGlobalSymbols
  LMSGetValue(paramName: string): string {
    return this.handleQueryResult(this.LMSGetValueImpl(paramName));
  }

  // noinspection JSMethodCanBeStatic
  LMSGetValueChildren(paramName: string): ScormQueryResult {
    if ( /^cmi[.]objectives[.](?:\d+?)[.]score[.]_children$/.test(paramName) ) {
      return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueChildren1,
        keys(ScormCmiTree.cmi.core.score).join(','));
    } else {
      const path = paramName.replace(/[.]_children$/, '');
      const value = at(ScormCmiTree, path)[0];
      if ( isString(value) ) {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueChildren2,
          ScormError.ELEMENT_IS_NOT_AN_ARRAY, 'Value is not an array ' + path, '');
      } else if ( isObject(value) ) {
        return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueChildren3,
          keys(value).join(','));
      }
    }
    return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueChildren4,
      ScormError.NOT_IMPLEMENTED, 'Value not supported ' + paramName, '');
  }

  LMSGetValueCount(paramName: string): ScormQueryResult {
    if ( /^cmi[.]objectives[.](?:\d+?)[.]score._count$/.test(paramName) ) {
      return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueCount1,
        '' + keys(ScormCmiTree.cmi.core.score).length);
    } else if ( /^cmi[.](?:interactions|objectives)[.]_count$/.test(paramName) ) {
      if ( isUndefined(this.state.cmiCoreElements[paramName]) ) {
        return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueCount2, '0');
      } else {
        return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueCount3,
          this.state.cmiCoreElements[paramName]);
      }
    } else {
      const path = paramName.replace(/[.]_count$/, '');
      const value = at(ScormCmiTree, path)[0];
      if ( isString(value) ) {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueCount4,
          ScormError.ELEMENT_IS_NOT_AN_ARRAY, 'Value is not an array ' + paramName, '');
      } else if ( isObject(value) ) {
        return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueCount5, '' + keys(value).length);
      }
    }
    return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueCount6,
      ScormError.NOT_IMPLEMENTED, 'Value not supported ' + paramName, '');
  }

  LMSGetValueDoGetValue(paramName: string): ScormQueryResult {
    if ( !isUndefined(this.state.cmiCoreElements[paramName]) ) {
      return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueDoGetValue1,
        this.state.cmiCoreElements[paramName]);
    }
    // todo check sync getValue with RxJS
    return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueDoGetValue2,
      ScormError.GENERALEXCEPTION, 'Error getting value for ' + paramName, '');
  }

  LMSGetValueImpl(paramName: string): ScormQueryResult {
    if ( /^cmi[.].*[.]_version$/.test(paramName) ) {
      return ScormQueryResult.createSuccess(ScormCodePointer.LMSGetValueImpl1, '3.4');
    } else if ( /.*[.]_children$/.test(paramName) ) {
      return this.LMSGetValueChildren(paramName);
    } else if ( /^cmi[.].*[.]_count$/.test(paramName) ) {
      return this.LMSGetValueCount(paramName);
    } else {
      if (
        /^cmi[.]core[.](?:credit|entry|lesson_location|lesson_mode|lesson_status|student_id|student_name|total_time|score)$/.test(paramName)
        || /^cmi[.]core[.]score[.](?:max|min|raw)$/.test(paramName)
        || /^cmi[.]objectives[.](\d*)[.]score[.](?:max|min|raw)$/.test(paramName)
        || /^cmi[.]suspend_data$/.test(paramName)
        || /^cmi[.]student_data[.](?:mastery_score|max_time_allowed|time_limit_action)$/.test(paramName)
      ) {
        return this.LMSGetValueDoGetValue(paramName);
      } else if ( /^cmi[.]core[.](?:exit|session_time)$/.test(paramName)
        || /^cmi[.](?:objectives|interactions)[.](?:\d+?)[.](?:id|time|type|weighting|student_response|result|latency)$/.test(paramName) ) {
        /*Write only*/
        return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueImpl2,
          ScormError.ELEMENT_IS_WRITE_ONLY, '', '');
      } else if ( /^cmi[.].*$/.test(paramName) ) {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueImpl3,
          ScormError.NOT_IMPLEMENTED, '', '');
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSGetValueImpl4,
          ScormError.INVALID_ARGUMENT, '', '');
      }
    }
  }

  // noinspection JSUnusedGlobalSymbols
  LMSInitialize(param?: string): string {
    this._sessionStarted = true;
    const result = this.LMSInitializeImpl(param);
    this.emitStateEvent(result, { type: ScormApiEventType.Initialized });
    return this.handleQueryResult(result);
  }

  LMSInitializeImpl(param?: string): ScormQueryResult {
    if ( !param ) {
      const obj = ScormQueryResult.createSuccess(ScormCodePointer.LMSInitializeImpl1);
      if ( !this.state.initialized ) {
        obj.query = this.query('initialize', null, obj).pipe(
          switchMap((response: ScormResultValue) => {
            if ( response.result === ScormResult.SUCCESS ) {
              this.state.initialized = true;
            }
            // todo show error message when success false and redirect to overview
            return of(new ScormResultValue(response.result, response.errorCode));
          }),
        );
      } else if ( !obj.result ) {
        obj.result = obj.success ? 'true' : 'false';
      }
      return obj;
    }
    return ScormQueryResult.createFailure(ScormCodePointer.LMSInitializeImpl2, ScormError.INVALID_ARGUMENT);
  }

  // noinspection JSUnusedGlobalSymbols
  LMSSetValue(paramName: string, value: string): string {
    return this.handleQueryResult(this.LMSSetValueImpl(paramName, value));
  }

  LMSSetValueExecute(paramName: string, value: string, result: ScormQueryResult): ScormQueryResult {
    this.state.cmiCoreElements[paramName] = value;
    this.query('setValue', [ paramName, value], result);
    return result;
  }

  LMSSetValueImpl(paramName: string, value: string | number): ScormQueryResult {
    if ( /^cmi[.].*[.](_children|_count|_version)$/.test(paramName) ) {
      return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl1, ScormError.INVALID_SETVALUE,
        paramName + ' is not a setable field - it is a keyword');
    } else if ( /^cmi[.](?:core[.](student_name|student_id|credit|entry|total_time|lesson_mode|launch_data))$/.test(paramName) ) {
      return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl2, ScormError.ELEMENT_IS_READ_ONLY,
        paramName + ' is read only');
    } else if ( /^cmi[.](core[.]lesson_status|objectives[.]\d+?[.]status)$/.test(paramName) ) {
      /*CMIStatus*/
      if ( isString(value) && /^(passed|completed|failed|incomplete|browsed|not attempted)$/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl3));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl4, ScormError.INCORRECT_DATATYPE,
          value + ' is not an allowed value');
      }
    } else if ( /^cmi[.]core[.]score[.](raw|max|min)$/.test(paramName) ) {
      /*CMIDecimal || CMIBlank*/
      if ( isNumber(value) || (isString(value) && /^(?:\d)*?(?:[.](?:\d)+?)?$/.test(value)) ) {
        if ( /^cmi[.]core[.]score[.]raw$/.test(paramName) ) {
          const numValue = Number(value);
          if ( (numValue === 0 || numValue > 0) && (numValue <= 100) ) {
            return this.LMSSetValueExecute(paramName, '' + value,
              ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl5));
          } else {
            return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl6, ScormError.INCORRECT_DATATYPE,
              'Value has to be a decimal between 0 and 100 or empty');
          }
        } else {
          return this.LMSSetValueExecute(paramName, '' + value,
            ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl7));
        }
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl8, ScormError.INCORRECT_DATATYPE,
          'Value has to be a decimal between 0 and 100 or empty');
      }
    } else if ( /^cmi[.]core[.]exit$/.test(paramName) ) {
      /*CMIExit*/
      if ( isString(value) && /(^(logout|suspend|time-out)?$)/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl9));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl10, ScormError.INCORRECT_DATATYPE,
          value + ' is not an allowed value');
      }
    } else if ( /^cmi[.]core[.]session_time$/.test(paramName) ) {
      /*CMITimeSpan*/
      if ( isString(value) && /(^(?:\d){2,4}[:]\d\d[:]\d\d(?:[.](?:\d){0,2})?$)/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl11));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl12, ScormError.INCORRECT_DATATYPE,
          'Value has to be in format HHHH:MM:SS.SS - HHHH can be HH or HHH, .SS/.S is optional');
      }
    } else if ( /^cmi[.]interactions[.](?:\d+?)[.]time$/.test(paramName) ) {
      /*CMITime*/
      if ( isString(value) && /^\d\d[:]\d\d[:]\d\d(?:[.](?:\d){0,2})?$/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl13));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl14, ScormError.INCORRECT_DATATYPE,
          'Value has to be in format HH:MM:SS.SS - .SS/.S is optional');
      }
    } else if ( /^cmi[.]suspend_data$/.test(paramName) ) {
      /*CMI4096*/
      // todo allow arbitrary lengths
      if ( isString(value) /*&& (value.length <= 4096)*/ ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl15));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl16, ScormError.INCORRECT_DATATYPE,
          'Value must be a string of up to 4096 chars');
      }
    } else if ( /^(cmi[.](objectives|interactions)[.])(\d+?)[.]id$/.test(paramName) ) {
      /*CMIIdentifier*/
      if ( isString(value) && /^[\w].*$/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl17));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl18, ScormError.INCORRECT_DATATYPE,
          'Value is limited to alpha-numeric string');
      }
    } else if ( /^cmi[.]interactions[.](?:\d+?)[.]type$/.test(paramName) ) {
      /*CMIVocabulary*/
      if ( isString(value) && /^(true-false|choice|fill-in|matching|performance|sequencing|likert|numeric)$/.test(value) ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl19));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl20, ScormError.INCORRECT_DATATYPE,
          'Value should be from true-false,choice,fill-in,matching,performance,sequencing,likert,numeric');
      }
    } else if ( /^cmi[.]interactions[.](?:\d+?)[.]student_response$/.test(paramName) ) {
      /*CMIFeedback*/
      if ( isString(value) && value.length <= 2000 ) {
        return this.LMSSetValueExecute(paramName, value,
          ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl21));
      } else {
        return ScormQueryResult.createFailure(ScormCodePointer.LMSSetValueImpl22, ScormError.INCORRECT_DATATYPE,
          'Value must be a string of up to 255 chars');
      }
    } else {
      const countParam = /^(cmi[.](objectives|interactions)[.])(\d+?)[.].*/.exec(paramName);
      if ( countParam ) {
        const typeCountParam = countParam[1] + '_count';
        if ( isUndefined(this.state.cmiCoreElements[typeCountParam]) ||
          (countParam[3] >= this.state.cmiCoreElements[typeCountParam]) ) {
          this.state.cmiCoreElements[typeCountParam] = '' + (Number(countParam[3]) + 1);
        }
      }
      return this.LMSSetValueExecute(paramName, '' + value,
        ScormQueryResult.createSuccess(ScormCodePointer.LMSSetValueImpl23));
    }
  }

  handleQueryResult(result: ScormQueryResult): string {
    if ( result.success ) {
      this.state.resetErrors();
      if ( result.query ) {
        this.queueRequest(result);
      }
    } else {
      this.state.setErrors(result.lastError, result.diagnostic);
    }
    return result.result;
  }

  query(
    func: string, value: string | string[], result: ScormQueryResult, ignoreErrors?: boolean,
  ): Observable<ScormResultValue> {
    let query = this.queryCreate(func, value)
      .pipe(switchMap((response: ScormResultValue) =>
        this.queryTranslate(response.result, result, ignoreErrors)));
    if (this.logCommunication && (console != null)) {
      query = query.pipe(tap(response => console.log({ func, value, ...response })));
    }
    result.query = query;
    return query;
  }

  queryCreate(func: string, value?: string | string[]): Observable<ScormResultValue> {
    const options: RagRequestOptions = new RagRequestOptions();
    options.options.responseType = 'text';
    const url = this.queryCreateData(options, func, value);
    // todo translate response to scorm
    // todo add errors to result
    return this.http.request(options.method, url, {
      body: options.options.body,
      headers: new HttpHeaders({ 'Request-Timeout': '15' }),
      responseType: 'text',
    })
      .pipe(timeout(15000))
      .pipe(map((response) => new ScormResultValue(response)));
  }

  queryCreateData(options: RagRequestOptions, func: string, value?: string | string[]) {
    options.method = RagRequestMethod.Get;

    let url = this.queryCreateUrl(func);
    if ( !isUndefined(value) && !isNull(value) ) {
      const _value = isString(value) ? [ value ] : value;

      let dataUrl = url;
      each(_value, (v, i) => {

        dataUrl += '&P' + i + '=' + encodeURIComponent(v);

        if ( (dataUrl.length > 2048) || (v.indexOf('#') > -1) ) {
          options.method = RagRequestMethod.Post;
          // todo check if we can apply { encoder: new RagHttpParameterCodec() } to HttpParams
          const body: HttpParams = (options.options.body || new HttpParams()) as HttpParams;
          // @see https://stackoverflow.com/a/54010521
          options.options.body = body.set('P' + i, v);
        } else {
          url = dataUrl;
        }
      });
    }
    return url;
  }

  queryCreateScoExec(startUrl: string): Observable<any> {
    startUrl += '&doWait=true';
    return this.http.request(RagRequestMethod.Get, startUrl, { observe: 'response', responseType: 'text' }).pipe(
      map((response) => {
        let result;
        if ( response.statusText === 'OK' ) {
          result = ScormResult.SUCCESS;
        } else {
          result = ScormResult.FAILURE;
        }
        return {
          response: result,
        };
      }),
      delay(50),
    );
  }

  queryCreateUrl(func): string {
    let url = this.API_ADAPTER;
    if ( url.indexOf('?') >= 0 ) {
      url += '&';
    } else {
      url += '?';
    }
    url += 'F=' + func;
    url += '&_=' + (new Date()).getTime().toString(16).toUpperCase();
    return url;
  }

  queryTranslate(response: string, result?: ScormQueryResult, ignoreErrors?: boolean): Observable<ScormResultValue> {
    const obj = ScormApi.queryParseScormResponse(response);

    if ( isUndefined(result) ) {
      return of(new ScormResultValue(obj.result, obj.lastError));
    }

    response = result.result = obj.result;
    if ( !isUndefined(obj.lastError) ) {
      result.lastError = obj.lastError;
      if ( !result.success && (ignoreErrors !== true) ) {
        return this.queryCreate('getDiagnostic').pipe(
          map(diagnostic => {
            result.diagnostic = (diagnostic.result === ScormResult.FAILURE ? '' : diagnostic.result);
            return new ScormResultValue(result.diagnostic, result.lastError);
          }),
        );
      }
    }
    return of(new ScormResultValue(response, obj.lastError));
  }

  queueRequest(result: ScormQueryResult): Observable<ScormResultValue> {
    this.queryQueue.push(result);
    if ( result.query ) {
      this.queueWorker();
      return result.query;
    }
    return of(new ScormResultValue(result.result, result.lastError));
  }

  queueWorker(): void {
    if ( this._queueWorkerRunning ) {
      return;
    } else if ( !this.queryQueue.length ) {
      this._queueEmpty.next(true);
      return;
    }

    this._queueWorkerRunning = true;
    const query = this.queryQueue.splice(0, 1)[0];
    this.queryHistory.push(query);

    // prevent long call chains
    setTimeout(() => {
      query.query
        .pipe(take(1))
        .pipe(
          finalize(() => {
            this._queueWorkerRunning = false;
            this.queueWorker();
          }),
        )
        .subscribe();
    }, 0);
  }

  resetApi(): void {
    this.queryHistory.splice(0);
    this.queryQueue.splice(0);
    this.state = new ScormApiState();
  }

  startSco(startUrl: string): ScormQueryResult {
    const self = this;
    return ScormQueryResult.createSuccessWithQuery(ScormCodePointer.StartSco1, (result: ScormQueryResult) => {
      const doGet = (paramName: string): Observable<ScormResultValue> =>
        // todo TF-3034 check if the ignore flag is still necessary when initialization finishes correctly
         self.query('getValue', paramName, result, true)
          .pipe(tap((response: ScormResultValue) => {
            if ( response.errorCode === ScormError.NOERROR ) {
              // only populate cache if no error occurred
              self.state.cmiCoreElements[paramName] = response.result;
            }
          }))
      ;
      // calls scoExec of train
      return self.queryCreateScoExec(startUrl).pipe(switchMap(() =>
        // starts lms launch event
         self.LMSInitializeImpl().query
          .pipe(delay(1000))
          .pipe(switchMap(() =>
            // preload data for api state
             forkJoin([
              doGet('cmi.core.credit'),
              doGet('cmi.core.entry'),
              doGet('cmi.core.lesson_location'),
              doGet('cmi.core.lesson_mode'),
              doGet('cmi.core.lesson_status'),
              doGet('cmi.core.score.raw'),
              doGet('cmi.core.student_id'),
              doGet('cmi.core.student_name'),
              doGet('cmi.core.total_time'),
              doGet('cmi.interactions._count'),
              doGet('cmi.launch_data'),
              doGet('cmi.objectives._count'),
              doGet('cmi.suspend_data'),
              doGet('cmi.student_data.mastery_score'),
              doGet('cmi.student_data.max_time_allowed'),
              doGet('cmi.student_data.time_limit_action'),
            ])
          ))
      )).pipe(
        catchError((error: HttpResponse<any>) => {
          result.lastError = ScormError.GENERALEXCEPTION;
          return throwError(error);
        }),
      ).pipe(tap(() => {
        this.initMaxTimeAllowed();
      }));
    });
  }

  initMaxTimeAllowed(): void {
    const maxTimeAllowed = this.state.cmiCoreElements['cmi.student_data.max_time_allowed'];
    const isWellFormedCMITimespan = /\d+:\d+:\d+.\d+/.test(maxTimeAllowed);
    if ( !(maxTimeAllowed === '' || !isWellFormedCMITimespan) ) {
      /*@todo: get from api*/
      const endDate = moment.utc();
      const match = maxTimeAllowed.match(/(\d+):(\d+):(\d+).(\d+)/);

      endDate.add('hours', Number(match[1]));
      endDate.add('minutes', Number(match[2]));
      endDate.add('seconds', Number(match[3]));
      endDate.add('milliseconds', Number(match[4]));

      this.maxTimeAllowedEndDate = endDate;
      this.isMaxTimeAllowedSet = true;
    }
  }

  private emitStateEvent(result: ScormQueryResult, event: ScormApiEvent) {
    if ( result.success ) {
      if ( !result.query ) {
        setTimeout(() => {
          this._stateChange.next(event);
        });
      } else {
        result.query = result.query.pipe(map((response) => {
            this._stateChange.next(event);
            return response;
          }),
        );
      }
    }
  }
}
