<div
  *ngIf="(content.description | localeText) || imageUrl"
  class="info-block">

  <div class="offline-content-description">

    <div *ngIf="!multipleEvents && offlineEvent" class="date title">
        <span *ngIf="isSameDate; else notTheSame">
          {{dateFormatted(offlineEvent.eventDate)}}
        </span>
    </div>

    <div id="info">
      <div *ngIf="imageUrl" class="offline-content-image">
        <img [src]="imageUrl">
      </div>

      <div
        *ngIf="(content.description | localeText) as contentDescription"
        [innerHTML]="contentDescription | safe:'html'"
        class="description">
      </div>

    </div>

    <div
      *ngIf="(offlineEvent?.description | localeText) as eventDescription"
      class="event-description">

      <div [innerHTML]="eventDescription | safe:'html'" class="offline-event-descr"></div>
    </div>

    <div *ngIf="extLink$ | async as extLink" class="ext-login-button">
      <div class="label">
        <!-- label for adobe connect room -->
        <span *ngIf="isAdobeConnectServer" i18n="@@global_virtual_room">Virtual room</span>
        <span *ngIf="!isAdobeConnectServer"  i18n="@@global_ac_enter_room">Enter room</span>:
      </div>

      <div class="data">
        <!-- Button for adobe connect room -->
        <button
          mat-stroked-button
          [disabled]="!isEnterRoomAvailable"
          (click)="joinEvent(extLink)"
          class="ac-room"
          color="primary">
          <span  i18n="@@global_ac_enter_room">Enter room</span>
        </button>
      </div>
    </div>
  </div>

</div>

<ng-template #notTheSame>
  <span>
    {{dateFormatted(offlineEvent.eventDate)}}
    <span class="divider" i18n="@@global_time_to">-</span>
    {{dateFormatted(offlineEvent.eventDateUntil)}}
  </span>
</ng-template>
