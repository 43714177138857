<div class="filter-box " [ngClass]="{'new-design-border': outsourcedFilter}">
  <mat-form-field appearance="outline" [ngClass]="{'new-design': outsourcedFilter}">
    <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>

    <mat-select
      [(ngModel)]="filter.value"
      (blur)="onBlur()"
      (ngModelChange)="onChange($event)"
      [placeholder]="placeholderText"
      #matSelect
    >
      <mat-option
        *ngIf="showOptionAll"
        [value]="''"
      >
        <span i18n="@@all">All</span>
      </mat-option>

      <mat-option
        *ngFor="let dropdownOption of dropdownOptions"
        [value]="dropdownOption.value"
        #eltOption
      >
        {{dropdownOption.label}}

        <ng-container
          *ngIf="fixMatOptionHighlight(eltOption)"
        ></ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
