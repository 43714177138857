<rag-dialog-header>
  <span i18n="@@edit_login_page_dialog_title">Edit Login Page</span>
</rag-dialog-header>

<div class="body" mat-dialog-content>
  <p><span i18n="@@edit_login_page_dialog_field_edited">You edit the field:</span> {{data.item}}</p>
  <!-- show input field only for single line texts -->
  <mat-form-field
    appearance="outline"
    *ngIf="data.item === 'loginFormTexts' || data.item === 'loginPlaceholder' || data.item === 'loginError'">
    <input
      [(ngModel)]="toBeEdited[data.language]"
      matInput
      placeholder="Login"
      [maxlength]="MAX_LENGTH"
      type="text">
    <mat-hint>
      <span i18n="@@max_length_login_text_1">maximum length</span>
      {{MAX_LENGTH}}
      <span i18n="@@max_length_login_text_2">symbols</span>
    </mat-hint>
  </mat-form-field>

  <div class="editor-container">
    <!-- show editor for welcome text -->
    <ckeditor *ngIf="(data.item === 'welcomeTexts')"
      [editor]="Editor"
      [(ngModel)]="toBeEdited[data.language]"
      [config]="config"></ckeditor>
  </div>

  <rag-file-upload (fileUploadFinished)="reloadImages()" *ngIf="data.item === 'welcomePictureId'"
                   [apiUrlKey]="'PostAccountImage'"
                   [maxFileSize]="2"></rag-file-upload>

  <div *ngIf="data.item === 'welcomePictureId'" class="logo-wrapper">
    <mat-card appearance="outlined" *ngFor="let image of images$ | async"
              [ngClass]="{'current-logo': image.fileId === toBeEdited}"
              class="rag-card fade">
      <mat-card-header>
        <mat-card-title class="hyphenate title">{{image.fileName}}</mat-card-title>
        <mat-card-subtitle *ngIf="image.fileId === toBeEdited" class="hyphenate title"
                           i18n="@@edit_login_page_dialog_selected_welcome_picture">Selected welcome picture
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="image.fileId !== toBeEdited" class="title">&nbsp;</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <img alt="logo" mat-card-image src="{{image.link}}"/>
      </mat-card-content>

      <mat-card-actions>
        <button (click)="selectAsNewWelcomePicture(image.fileId)"
                color="primary"
                i18n-matTooltip="@@account_design_select_button" mat-icon-button
                matTooltip="Select"
                matTooltipPosition="above">
          <mat-icon svgIcon="image-filter-center-focus"></mat-icon>
        </button>
        <button (click)="openDialog(image.id)" color="warn"
                i18n-matTooltip="@@account_design_delete_button"
                mat-icon-button
                matTooltip="Delete"
                matTooltipPosition="above">
          <mat-icon svgIcon="delete-outline"></mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<div mat-dialog-actions>
  <div class="button-wrapper">
    <button (click)="onClick(false)" mat-flat-button>
      <span i18n="@@global_cancel">Cancel</span>
    </button>
    <button (click)="onClick(true)" color="primary" mat-flat-button>
      <span i18n="@@global_submit">Submit</span>
    </button>
  </div>
</div>
