import { Component } from '@angular/core';
import { BaseQuestFragmentComponent } from '../../quest.types';

@Component({
  selector: 'rag-quest-type-radio-matrix',
  templateUrl: './quest-type-radio-matrix.component.html',
  styleUrls: ['./quest-type-radio-matrix.component.scss']
})
export class QuestTypeRadioMatrixComponent extends BaseQuestFragmentComponent {

}
