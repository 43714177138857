import { Component } from '@angular/core';
import { BaseQuestFragmentComponent } from '../../quest.types';

@Component({
  selector: 'rag-quest-type-checkbox',
  templateUrl: './quest-type-checkbox.component.html',
  styleUrls: ['./quest-type-checkbox.component.scss']
})
export class QuestTypeCheckboxComponent extends BaseQuestFragmentComponent {

}
