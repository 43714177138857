import { CachedSubject } from './cached-subject';
import { Observable } from 'rxjs';

export namespace OpenEntityTypes {

  export class OpenEntities<T, K> {

    private _entities = new CachedSubject<T[]>([]);

    constructor(
      private identityAccessor: (entity: T) => K,
    ) {
    }

    get entities$(): Observable<T[]> {
      return this._entities.withoutEmptyValuesWithInitial();
    }

    closeEntity = (entity: T): void => {
      const entityId = this.identityAccessor(entity);
      if ( entityId == null ) {
        // no identity provided
        return;
      }

      const entities = [ ...this._entities.value ];
      const index = entities.findIndex(e => this.identityAccessor(e) === entityId);
      if ( index === -1 ) {
        // entity not opened
        return;
      }

      entities.splice(index, 1);
      this._entities.next(entities);
    };

    openEntity = (entity: T): void => {
      const entityId = this.identityAccessor(entity);
      if ( entityId == null ) {
        // no identity provided
        return;
      }

      const entities = [ ...this._entities.value ];
      const index = entities.findIndex(e => this.identityAccessor(e) === entityId);
      if ( index !== -1 ) {
        // entity already opened
        return;
      }

      entities.push(entity);
      this._entities.next(entities);
    };

  }

}
