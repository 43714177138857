<rag-dialog-header>
  <span i18n="@@register_preview_title">Registration preview</span>
</rag-dialog-header>

<div class="container" mat-dialog-content>
  <table mat-table [dataSource]="fields">

    <ng-container matColumnDef="key">
      <td mat-cell *matCellDef="let row" class="label">{{row.key}}:</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <td mat-cell *matCellDef="let row">{{row.value}}</td>
    </ng-container>

    <tr *matRowDef="let row; columns: ['key', 'value'];" mat-row></tr>
  </table>

  <div class="hint" i18n="@@register_hint">
    Please verify your registration data before proceeding.
  </div>
</div>

<mat-dialog-actions>
  <button [mat-dialog-close]="null" type="button" class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_cancel">Cancel</span>
  </button>
  <button [mat-dialog-close]="true" type="button" autofocus color="primary" mat-flat-button>
    <span i18n="@@global_go_next">Next</span>
  </button>
</mat-dialog-actions>
