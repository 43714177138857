import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ReportTableRow, ReportTableRowParent } from '../report-table.types';
import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../../../component/table/table-controller/table-controller.types';
import { ReportTargetType } from '../../../../../../core/report/report.types';
import { ReportTableService } from '../report-table.service';
import { TableGroupingHelper } from '../../../../../../component/table/table-grouping/table-grouping.helper';


type DisplayMode = 'default' | 'date' | 'dateUntil' | 'displayStatus';

@Component({
  selector: 'rag-report-table-grouping-value',
  templateUrl: './report-table-grouping-value.component.html',
  styleUrls: [ './report-table-grouping-value.component.scss' ],
})
export class ReportTableGroupingValueComponent
  implements OnChanges {

  @Input() column: TableControllerTypes.ColumnMenuItem<ReportTableRow>;
  @Input() row: ReportTableRowParent;
  @Input() targetType: ReportTargetType;
  mode: DisplayMode = 'default';

  constructor(
    private reportTableService: ReportTableService,
  ) {
  }

  get defaultMockRow(): any {
    const columnId = this.row.column;
    const result = {};
    result[columnId] = this.row.distinctValue;
    return result;
  }

  get isOffline() {
    return this.targetType === 'Offline';
  }

  static getMode(dataType: TableColumnDataType, columnId: string = ''): DisplayMode {

    switch ( columnId ) {

      case 'accountEndDate':
      case 'accountValidUntil':
        return 'dateUntil';

      case 'accountDisplayStatus':
      case 'courseItemStatus':
      case 'courseStatus':
        return 'displayStatus';
    }

    switch ( dataType ) {

      case TableColumnDataType.date:
      case TableColumnDataType.dateTime:
        return 'date';

      default:
        return 'default';
    }
  }

  getTemplate(
    tplDefault: TemplateRef<any>,
    tplDisplayStatus: TemplateRef<any>,
    tplDate: TemplateRef<any>,
  ): TemplateRef<any> {
    switch ( this.mode ) {

      case 'date':
      case 'dateUntil':
        return tplDate;

      case 'displayStatus':
        return tplDisplayStatus;

      case 'default':
      default:
        return tplDefault;
    }
  }

  hasVisibleChildren(): boolean {
    return TableGroupingHelper.getChildren(this.row)
      .find(row => TableGroupingHelper.isFilterVisible(row)) != null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mode = ReportTableGroupingValueComponent
      .getMode(this.column.options.dataType, this.column.id);
  }

  toggleGrouping(): void {
    this.reportTableService.toggleGrouping(this.row);
  }

}
