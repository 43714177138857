<div class="container">
  <div #sizingReference *ngIf="report != null" [routerLink]="'/report/v2/saved/' + report.reportConfig['uuid']"
       class="chart">
    <rag-doughnut-chart #doughnutChart (optionsChanged)="setOptions($event)" [options]="pieChartOptions">
      <div #sizingContainer class="sizing-container" *ngIf="chartPie$ | async">
        <canvas [data$]="chartPie$"
                [downloadChart$]="doughnutChart.downloadClicked"
                [options]="pieChartOptions"
                [reportTitle]="title$ | async"
                [startResize]="sizingDone$"
                ragDoughnutChart
        ></canvas>
      </div>
    </rag-doughnut-chart>
  </div>
  <div *ngIf="hasResponse && report == null" class="error">
    <mat-icon svgIcon="alert-circle"></mat-icon>
    <div i18n="@@widget-report-has-been-removed">The report has been removed or you are not authorized to access it.</div>
  </div>
</div>
