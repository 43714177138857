import { Component, Input } from '@angular/core';
import { ProfileSectionTypes } from '../profile-section.types';

@Component({
  selector: 'rag-profile-section',
  templateUrl: './profile-section.component.html',
  styleUrls: [ './profile-section.component.scss' ],
})
export class ProfileSectionComponent {

  @Input() disabled: boolean;
  @Input() section: ProfileSectionTypes.ProfileSection;

}
