import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { LearnerContentTypes } from '../../learner-content-util/learner-content.types';
import { ApiUrls } from '../../../../../core/api.urls';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { LearnerContentService, LearnerCourseResponse } from '../../learner-content-util/learner-content.service';
import { FileInfo } from '../../../../../core/core.types';
import { InfoService } from '../../../../../core/info/info.service';
import { InfoType, MessageKey, NoButton, YesButton } from '../../../../../core/info/info.types';
import { ViewHelper } from '../../../../../core/view-helper';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { ApiResponse, HttpRequestOptions } from 'src/app/core/global.types';
import { UploadFile } from 'src/app/core/files.types';


@Injectable({ providedIn: 'root' })
export class LearnerCourseTodoService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  private static getUrlForContext(context: LearnerContentTypes.UpdateContext) {
    if ( context.curriculumId > 0 ) {
      return ApiUrls.getKey('LearnerCourseTodoCurriculum')
        .replace(/{curriculumId}/, String(context.curriculumId))
        .replace(/{curriculumItemId}/, String(context.curriculumItemId));
    } else {
      return ApiUrls.getKey('LearnerCourseTodo')
        .replace(/{courseId}/, String(context.courseId));
    }
  }

  saveContribution(context: LearnerContentTypes.UpdateContext, contribution: LearnerContentTypes.Contribution,
    attachments: FileInfo[]): Observable<LearnerContentTypes.CourseAccountToDo> {
    const url = LearnerCourseTodoService.getUrlForContext(context);
    const formData = new FormData();

    contribution.files = attachments?.map(file => {
      if ( file instanceof UploadFile ) {
        formData.append('attachments', file['file']);
        return {
          uuid: file.uuid,
          fileName: file.fileName,
          fileSize: file.fileSize,
          mime: file.mime,
        };
      } else {
        return file;
      }
    });

    const data = ViewHelper.cloneRemoveRecursions(contribution);
    delete data.$view;
    formData.append('contribution', JSON.stringify(data));

    return this.http.post<LearnerCourseResponse>(url, formData)
      .pipe(catchError(this.handleError))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(map(response => LearnerContentService.responseToAccount(response) as LearnerContentTypes.CourseAccountToDo));
  }

  updateCourseStatus(courseId: number, displayStatus: DisplayStatus): Observable<unknown> {
    // ensure we have a numeric enum value
    displayStatus = DisplayStatusHelper.toDisplayStatus(displayStatus);
    const message = (displayStatus === DisplayStatus.NOT_ATTEMPTED) ?
      $localize`:@@learner_course_confirm_status_incomplete:
        Do you wish to continue working on your answer and un-mark it as completed?` :
      $localize`:@@learner_course_confirm_status_complete:Do you wish to mark your answer as completed?`;
    const url = ApiUrls.getKey('LearnerCourseStatus')
      .replace(/{courseId}/, String(courseId))
      .replace(/{displayStatus}/, String(DisplayStatus[displayStatus]));
    return this.infoService
      .showMessage(message, {
        buttons: YesButton | NoButton,
        title: $localize`:@@general_dialog_pleaseconfirm_title:Please Confirm`,
      })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => this.http.post<unknown>(url, null)))
      .pipe(catchError(this.handleError))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)));
  }

  submitContributionForCourse(context: LearnerContentTypes.UpdateContext): Observable<number | string | undefined> {
    const url = LearnerCourseTodoService.getUrlForContext(context) + '/status/PUBLISHED';
    return this.http.post<ApiResponse<any>>(url, null, HttpRequestOptions)
      .pipe(map(response => response.displayStatus));
  }

  private handleError = <T>(): Observable<never> => {
    this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
    return EMPTY;
  };

}
