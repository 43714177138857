import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyV2Directive } from './currency.directive';


@NgModule({
  declarations: [ CurrencyV2Directive ],
  exports: [ CurrencyV2Directive ],
  imports: [
    CommonModule,
  ],
})
export class CurrencyModule {
}
