import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {MainNavigationComponent} from '../main-navigation/main-navigation.component';
import { combineLatest, Observable } from 'rxjs';
import { ApplicationStateService, ViewMode } from '../../core/application-state.service';
import { map, tap, filter } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import {NavItem} from '../../core/navigation/navigation.types';
import {HeaderService} from '../../core/navigation/header.service';
import {Router, RouterOutlet, NavigationStart} from '@angular/router';
import { PrincipalService } from '../../core/principal/principal.service';
  @Component({
  selector: 'rag-app-content',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MainNavigationComponent,
    RouterOutlet,
  ],
  templateUrl: './app-content.component.html',
  styleUrls: ['./app-content.component.scss']
})
export class AppContentComponent implements OnDestroy {

  sidenavVisible$: Observable<boolean>;
  isGtMedium = false;
  isSmall = false;
  menuItemsStack: NavItem[][];
  mainItems: NavItem[] | null;
  topItems: NavItem[] | null;
  viewModeDefault$: Observable<boolean>;
  isLoggedIn: boolean = false;

  constructor(
    private appState: ApplicationStateService,
    private headerService: HeaderService,
    private router: Router,
    private principalService: PrincipalService,
    ) {
    this.sidenavVisible$ = combineLatest([ this.appState.sidenavVisible$, this.appState.viewMode$ ])
      .pipe(map(([ sidenavVisible, viewMode ]) => sidenavVisible && (viewMode === ViewMode.DEFAULT)));

    this.appState.menuItemsStack$
      .pipe(tap(itemStack => {
        this.menuItemsStack = itemStack;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.headerService.getNavigation()
      .pipe(tap(navigationData => {
        const mainItems = navigationData?.main ?? [];
        this.mainItems = (mainItems.length > 0) ? mainItems : null;
        const topItems = navigationData?.top ?? [];
        this.topItems = (topItems.length > 0) ? topItems : null;
        this.appState.clearMenuItemStack();
        this.appState.addToMenuItemStack(this.mainItems);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.appState.viewportState$.pipe(tap(state => {
      this.isGtMedium = state.isGtMedium();
      this.isSmall = state.isSmall();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .pipe(tap(() => {
        this.removeActiveMenuItem();
        this.appState.setSidenavVisible(false);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.viewModeDefault$ = this.appState.viewMode$
      .pipe(map(mode => mode === ViewMode.DEFAULT));

    this.isLoggedIn = this.principalService.isLogged;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  // getNavItems(): NavItem[] {
  //   if (this.isSmall) {
  //     const navItems = this.menuItemsStack[this.menuItemsStack.length - 1]
  //     return MergeHelper.mergeDeep(navItems, this.topItems);
  //   }
  //   if(!this.isGtMedium) {
  //     return this.menuItemsStack[this.menuItemsStack.length - 1];
  //   }
  //   return this.mainItems;
  // }

  removeActiveMenuItem(): void {
    this.appState.addToMenuItemStack(null);
    this.appState.clearMenuItemStack();
    this.appState.addToMenuItemStack(this.mainItems);
  }

  showLastMenu(): void {
    this.appState.popLastMenuItemStackEntry();
  }

  toggleSideNav(): void {
    this.appState.toggleSidenav();
  }

}
