import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_OPTIONS, LearningTimeChartOptions } from './learning-time-chart.types';
import { Observable } from 'rxjs';
import { MergeHelper } from '../../../core/primitives/merge.helper';


@Component({
  selector: 'rag-learning-time-chart',
  templateUrl: './learning-time-chart.component.html',
  styleUrls: [ './learning-time-chart.component.scss' ],
})
export class LearningTimeChartComponent {

  @Output() downloadChart = new EventEmitter<void>();
  @Output() downloadCsv = new EventEmitter<void>();
  modelOptions: LearningTimeChartOptions = MergeHelper.cloneDeep(DEFAULT_OPTIONS);
  @Output() readonly optionsChanged: Observable<LearningTimeChartOptions>;
  private _options: LearningTimeChartOptions = {};
  private _optionsChanged = new EventEmitter<LearningTimeChartOptions>();

  constructor() {
    this.optionsChanged = this._optionsChanged.asObservable();
  }

  get options(): LearningTimeChartOptions {
    return this._options;
  }

  @Input()
  set options(value: LearningTimeChartOptions) {
    this._options = value;
    this.modelOptions = MergeHelper.mergeDeep({}, DEFAULT_OPTIONS, value);
  }

  emitOptions(): void {
    this._optionsChanged.emit(MergeHelper.cloneDeep(this.modelOptions));
  }

}
