import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { UserProfileCredentialsData, UserProfileCredentialsResult } from './user-profile-credentials.types';
import { UserProfileCredentialsComponent } from './user-profile-credentials.component';
import { map, takeWhile } from 'rxjs/operators';
import { InfoService } from '../../../../core/info/info.service';


@Injectable({ providedIn: 'root' })
export class UserProfileCredentialsService {

  constructor(
    private infoService: InfoService,
  ) {
  }

  showDialog(
    data: UserProfileCredentialsData,
  ): Observable<void | never> {

    if ( !(data?.loginField?.editable || data?.passwordField?.editable) ) {
      return EMPTY;
    }

    return this.infoService
      .showDialog<UserProfileCredentialsComponent, UserProfileCredentialsData, UserProfileCredentialsResult>(
        UserProfileCredentialsComponent, data,
      )
      .pipe(takeWhile(result => result != null))
      .pipe(map(_ => void (0)));
  }
}
