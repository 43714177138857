import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayStatusHelper } from 'src/app/core/display-status-helper';
import { DisplayStatus } from 'src/app/core/display-status.enum';
import { CtrlSingleUserDetailsCourseTypes } from '../../../ctrl-single-user-details-course/ctrl-single-user-details-course.types';

const ITEM_PATTERN = /item[0-9]+/;

@Component({
  selector: 'rag-ctrl-single-user-curr-iteration-status-dialog',
  templateUrl: './ctrl-single-user-curr-iteration-status-dialog.component.html',
  styleUrls: ['./ctrl-single-user-curr-iteration-status-dialog.component.scss']
})
export class CtrlSingleUserCurrIterationStatusDialogComponent implements OnInit {

  iteration: number;
  status: CtrlSingleUserDetailsCourseTypes.CurriculumStatus;
  datasource: MatTableDataSource<CtrlSingleUserDetailsCourseTypes.CurriculumStatusType>;
  columnsToDisplay = ['status', 'title', 'editedAt', 'result'];

  constructor(
    private dialogRef: MatDialogRef<CtrlSingleUserCurrIterationStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CtrlSingleUserDetailsCourseTypes.SingleUserCurrIterationStatusDialogParams) {

      this.iteration = data.iteration;
      this.status = data.status;
      this.datasource = new MatTableDataSource();
      this.datasource.data = this.getDataForDatasource(data.status.curstatus);
    }

  ngOnInit(): void {
  }

  displayStatus(status: string) {
    switch (status) {
      case 'green': return DisplayStatus.COMPLETE;
      case 'red': return DisplayStatus.NOT_ATTEMPTED;
      case 'yellow': return DisplayStatus.INCOMPLETE;
      case 'greenred': return DisplayStatus.RECERTIFICATION_TIME;
      default:
        return DisplayStatus.UN_KNOWN;
    }
  }

  private getDataForDatasource(status: any): Array<CtrlSingleUserDetailsCourseTypes.CurriculumStatusType> {
    if (status == null) {
      return [];
    }
    return Object.keys(status).reduce((pV, key) => {
      if (ITEM_PATTERN.test(key)) {
        pV.push(status[key]);
      }
      return pV;
    }, []);
  }

}
