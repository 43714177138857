import { Component, OnInit } from '@angular/core';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { QAListData, QAQuestion, QAStatus } from '../../../user/qa/qa.types';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { ActivatedRoute } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { AnyObject } from '../../../../core/core.types';
import { AdminQAService } from '../admin-qa.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants } from '../../../../core/info/info.types';
import { ADMIN_QA_LIST_DEFAULT_MENU_COLUMNS } from './admin-qa-list.columns';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ExcelExportService } from '../../admin-offline/components/content-events/event-participants/excel-export.service';
import { TagsService } from '../../../../core/tags.service';

@Component({
  selector: 'rag-admin-qa-list',
  templateUrl: './admin-qa-list.component.html',
  styleUrls: [ './admin-qa-list.component.scss' ],
})
export class AdminQAListComponent
  extends TableControllerComponent<QAListData>
  implements OnInit {

  questions: QAQuestion[];
  allQAStatus = QAStatus;
  allStatus: AnyObject<string>[] = [
    {
      value: QAStatus.NEW,
      title: $localize`:@@admin_offline_event_invitation_status_new:New`,
      icon: 'file-alert-outline',
    },
    {
      value: QAStatus.DRAFT,
      title: $localize`:@@payment_status_Processing:Processing`,
      icon: 'file-edit-outline',
    },
    {
      value: QAStatus.PUBLISHED,
      title: $localize`:@@global_published:Published`,
      icon: 'file-eye-outline',
    },
    {
      value: QAStatus.NOT_PUBLISHED,
      title: $localize`:@@status_not_published:Not Published`,
      icon: 'file-cancel-outline',
    },
    {
      value: QAStatus.ARCHIVED,
      title: $localize`:@@status_archived:Archived`,
      icon: 'archive-arrow-down-outline',
    },
  ];

  constructor(
    private adminQAService: AdminQAService,
    private excelExportService: ExcelExportService,
    private infoService: InfoService,
    private route: ActivatedRoute,
    protected tableColumnMenuService: TableColumnMenuService,
    private tagsService: TagsService,
  ) {
    super(tableColumnMenuService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();
  }

  onChangeStatus(status: string, question: QAQuestion): void {
    this.adminQAService.changeQuestionStatus(question.uuid, status)
      .pipe(tap(response => {
        this.questions.map(q => {
          if ( q.uuid === response.uuid ) {
            q.status = response.status;
          }
        });
        const listData = this.generateListData();
        this.setTableData(listData);
        this.checkFilter();
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
      }))
      .pipe(take(1))
      .subscribe();
  }

  onExportQuestion(type: string): void {
    const questions = this.selection.selected;
    if ( !(questions.length > 0) ) {
      return;
    }

    switch ( type ) {
      case 'xlsx':
        this.excelExportService
          .exportAsXls(questions, this.columnMenuData, this.columns, 'questions');
        break;
      case 'csv':
        this.excelExportService
          .exportAsCsv(questions, this.columnMenuData, this.columns, 'questions', 'utf-8');
        break;
      case 'csv-iso':
        this.excelExportService
          .exportAsCsv(questions, this.columnMenuData, this.columns, 'questions', 'iso-8859-1');
    }
  }

  onToggleSelectedQuestion($event: MatCheckboxChange, question: QAListData) {
    const checked = $event.checked;
    if ( checked !== this.selection.isSelected(question) ) {
      if ( checked ) {
        this.selection.select(question);
      } else {
        this.selection.deselect(question);
      }
    }
    this.checkMultiActionsDisabled();
  }

  private generateListData(): QAListData[] {
    return this.questions.map(question => ({
        creationDate: question.creationDate,
        processingDate: question?.answer?.lastModified ?? question?.answer?.creationDate,
        tags: question.tags,
        uuid: question.uuid,
        questionAbstract: question.questionAbstract,
        status: question.status,
        answerText: question?.answer?.answerText,
        questionText: question.questionText,
      }));
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(ADMIN_QA_LIST_DEFAULT_MENU_COLUMNS);
    menuData.menuItems.tags.options.dropDownOptions = this.tagsService.getDropdownOptionsForTags(this.questions);
    this.setMenuData(menuData);
  }

  private updateRouteData(data): void {
    this.questions = data.questions;
    this.updateAvailableColumns();
    const listData = this.generateListData();
    this.setTableData(listData);
    this.inputDisabled = false;
    this.checkFilter();
  }
}
