import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusLightsModule } from '../../../../../component/elearning/status-lights/status-lights.module';
import { StatusStatisticsComponent } from './status-statistics.component';


@NgModule({
  declarations: [ StatusStatisticsComponent ],
  exports: [ StatusStatisticsComponent ],
  imports: [
    CommonModule,
    StatusLightsModule,
  ],
})
export class StatusStatisticsModule {
}
