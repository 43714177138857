import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentFilterComponent } from './content-filter.component';
import { PivotFilterModule } from '../pivot-filter/pivot-filter.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PivotFilterTagsModule } from '../pivot-filter/pivot-filter-tags/pivot-filter-tags.module';


@NgModule({
  declarations: [ ContentFilterComponent ],
  exports: [ ContentFilterComponent ],
  imports: [
    CommonModule,
    PivotFilterModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    PivotFilterTagsModule,
  ],
})
export class ContentFilterModule {
}
