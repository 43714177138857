<mat-form-field class="margin-top">
  <mat-label>
    <span i18n="@@widgetConf_label_scope">Scope</span>
  </mat-label>
  <mat-select (selectionChange)="loadContextWidgets()" [(value)]="selectedContext">
    <mat-option value="{{widgetContexts[0]}}">
      <span i18n="@@widgetConf_scope_home">Home Dashboard</span>
    </mat-option>
    <mat-option value="{{widgetContexts[1]}}">
      <span i18n="@@widgetConf_scope_controlling">Controlling Dashboard</span>
    </mat-option>
  </mat-select>
</mat-form-field>

<div>
  <mat-checkbox
    *ngIf="selectedContext === WidgetContext.LayoutHome"
    [checked]="widgetsEditable"
    (change)="onWidgetsEditableChanged($event)"
  >
    <span i18n="@@editable_on_the_start_page">Editable on the start page</span>
  </mat-checkbox>
</div>

<div (cdkDropListDropped)="drop($event)" cdkDropList class="widget-list">
  <mat-expansion-panel *ngFor="let widget of widgetArray" cdkDrag cdkDragLockAxis="y" class="widget-box">
    <mat-expansion-panel-header>
      <mat-panel-title>{{widget.title | localeText}}</mat-panel-title>
      <!-- todo: it shows always the current position and not the initial one -->
      <!-- <mat-panel-description *ngIf="widget.initial">
        <span i18n="@@widgetConf_initial_position">Initial position:</span>
        <span class="index">{{widget.index}}</span>
      </mat-panel-description> -->
      <mat-panel-description>
        <div class="handle" cdkDragHandle>
          <mat-icon svgIcon="swap-vertical-bold"></mat-icon>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="box-body">
      <div class="checkbox-container">
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('initial')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.initial"
                        [disabled]="widget.static">
            <span i18n="@@widgetConf_initial">Initial</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('static')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox
            (change)="detectChanges(false, widget.uuid); validateStatic(widget); detectDeviationFromDefaultValues()"
            [(ngModel)]="widget.static">
            <span i18n="@@widgetConf_static">Static</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('resizable')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.resizeEnabled">
            <span i18n="@@widgetConf_resizable">Resizable</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('dragable')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.dragEnabled"
                        [disabled]="widget.showAtTop">
            <span i18n="@@widgetConf_dragable">Dragable</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('selectable')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.selectable"
                        [disabled]="widget.static">
            <span i18n="@@widgetConf_selectable">Selectable</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('deletable')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.deletable"
                        [disabled]="widget.static">
            <span i18n="@@widgetConf_deletable">Deletable</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('onlyOnce')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.onlyOnce"
                        [disabled]="widget.static">
            <span i18n="@@widgetConf_only_once">Only once</span>
          </mat-checkbox>
        </div>
        <div class="info-checkbox-wrapper">
          <button (click)="showInformation('showAtTop')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); validateShowAtTop(widget);
                        detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.showAtTop">
            <span i18n="@@widgetConf_show_at_top">Show at top</span>
          </mat-checkbox>
        </div>
        <div *ngIf="isNewsWidget(widget.uuid)" class="info-checkbox-wrapper">
          <button (click)="showInformation('isTextFocus')" mat-icon-button>
            <mat-icon svgIcon="information-outline"></mat-icon>
          </button>
          <mat-checkbox (change)="detectChanges(false, widget.uuid); validateShowAtTop(widget);
                        detectDeviationFromDefaultValues()"
                        [(ngModel)]="widget.isTextBox">
            <span i18n="@@widgetConf_show_text_box">Show text box</span>
          </mat-checkbox>
        </div>
      </div>
      <div class="inputs">
        <div class="col-inputs">
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_columns">Columns</span>
            </mat-label>
            <input (change)="validateColInput($event, widget)" [value]="widget.cols" matInput required type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_columns">Values between min and max</span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_min_columns">Min Columns</span>
            </mat-label>
            <input (change)="validateMinColInput($event, widget)" [value]="widget.minItemCols" matInput required
                   type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_min_columns">Number between 1 and max</span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_max_columns">Max Columns</span>
            </mat-label>
            <input (change)="validateMaxColInput($event, widget)" [value]="widget.maxItemCols" matInput required
                   type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_max_columns">Number higher or equals min Columns and not above 3</span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="row-inputs">
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_rows">Rows</span>
            </mat-label>
            <input (change)="validateRowInput($event, widget)" [value]="widget.rows" matInput required type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_rows">Values between min and max</span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_min_rows">Min Rows</span>
            </mat-label>
            <input (change)="validateMinRowInput($event, widget)" [value]="widget.minItemRows" matInput required
                   type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_min_rows">Number between 4 and max</span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <span i18n="@@widgetConf_label_max_rows">Max Rows</span>
            </mat-label>
            <input (change)="validateMaxRowInput($event, widget)" [value]="widget.maxItemRows" matInput required
                   type="number">
            <mat-hint>
              <span i18n="@@widgetConf_hint_max_rows">Number higher or equals min Rows</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
<button (click)="onResetChanges()" [disabled]="!itemsEdited" mat-flat-button type="button">
  <span i18n="@@widgetConf_button_reset">Reset</span>
</button>
<button (click)="onResetToDefaultValues()" [disabled]="itemsHaveDefaultValue" mat-flat-button type="button">
  <span i18n="@@widgetConf_button_default">Default</span>
</button>
<button (click)="onUpdateStyleSettings()" [disabled]="!itemsEdited && !widgetsEditableWasChanged" color="primary" mat-flat-button type="button">
  <span i18n="@@widgetConf_button_save">Save</span>
</button>
