import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileFieldConsentComponent } from './profile-field-consent.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../../core/pipes/pipes.module';


@NgModule({
  declarations: [ ProfileFieldConsentComponent ],
  exports: [ ProfileFieldConsentComponent ],
  imports: [
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    PipesModule,
  ],
})
export class ProfileFieldConsentModule {
}
