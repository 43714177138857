import { AnyObject, Core, FieldType, NumberedAnyObject, Principal } from '../core.types';
import { TrainResponse } from '../global.types';
import { PermissionStates } from './permission.states';

interface Price {
  price: number;
  currency: string;
}

export const PRICELESS: Price = { price: -1, currency: null };

export type BookingComplitionType = 'book' | 'bookWithPurchaseRequest' | 'bookWihoutEvents';
export type NumberedMap = NumberedAnyObject<Array<number>>;
export type PriceMap = NumberedAnyObject<Array<Price>>;

export interface BookingInProgress {
  type: BookingComplitionType;
  selectedEvents: NumberedMap;
  selectedPrices?: PriceMap;
  bookingUUID?: string;
}

export interface PrincipalData {
  accountid: number;
  firstname: string;
  fullName: string;
  hasToResetPassword: boolean;
  isSuperAdmin?: boolean;
  language: string;
  lastname: string;
  login: string;
  permissionStates?: PermissionStates;
  permissions: string[];
  runningscosessions?: number[];
  success: boolean;
  userId: number;
}

export interface PrincipalDataScoId {
  courseId?: number;
  scoId: number;
}

export interface IBasisUserName {
  firstName: string;
  lastName: string;
}

export interface PrincipalDataV2 extends IBasisUserName {
  accountId: number;
  language: string;
  login: string;
  permissionStates?: PermissionStates;
  permissions: string[];
  runningScoSessions: PrincipalDataScoId[];
  sessionId: string;
  success: boolean;
  userId: number;
}

export type RegisterUserRequest = AnyObject;

/**
 * @deprecated use {@link UserProfileAttribute} instead
 */
export interface RegistrationFieldInfo {
  field: string;
  hasInfo?: boolean;
  info?: string;
  label: string;
  options?: Array<{ label: string; value: string }>;
  required: boolean;
  type: FieldType;
}

export interface SearchPrincipalsResponse
  extends TrainResponse {
  data: Principal[];
  total: number;
}

export interface RegistrationInfo {
  emailConfirm?: any;
  hasEmailConfirm?: boolean;
  hasTermsAndConditions?: boolean;
  /**
   * contains a randomly generated login, if extLoginField has been changed from the default
   */
  login?: string;
  model: { [key: string]: RegistrationFieldInfo };
  sortorder: Array<string>;
  termsAndConditions?: any;
  usernameEqualsField: string;
  process?: Core.UserProcess;
}

export interface AnonRegResponse {
  login: string;
}
