import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';

/**
 * Simple interface for wrapping any value with a custom label for displaying.
 */
export interface LabeledValue {
  label: string;
  value: any;
}

export interface StepperBar {
  height: string;
  color: string;
}
/**
* Stepped Slider with labels and custom values
*
* To get current value: getStep(). To set value: setStep() or setStepIndex()
*
* More info: `simeon.mitev.jr@reflact.com`
*
* @param { LabeledValue[] } steps A list of all steps. Must contain a label field for the displayed text, and the underlying value.
* @param { number } startingIndex The index of the step this slider should start with when created.
* @param { boolean } hideActive Whether the "active" portion of the slider should be hidden.
*
* */
@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule
  ],
  selector: 'rag-step-slider',
  templateUrl: './step-slider.component.html',
  styleUrls: ['./step-slider.component.scss']
})
export class StepSliderComponent {


  public constructor() {}


  @Input() stepBars: StepperBar[] = [];

  //#region Characteristics
  //#region Mutable [ red ]
  @Input() private steps: LabeledValue[] = []
  //#endregion

  @Input() private startingIndex: number;
  @Input() private hideActive: boolean;

  //#endregion


  //region States
  //#region Mutable [red ]
  private _sliderControl: FormControl = new FormControl(this.getStartingIndex())
  //#endregion
  //#endregion


  //region Properties [ yellow ]

  //#region Public [blue]

  /**
   * Gets the current value of the slider.
   *
   * **NOTE:** This returns the underlying VALUE, not the index or label.
   * To get the index, use getIndex() instead.
   */
  public getValue(): any { return this.getSteps()[this.getIndex()].value; }
  /**
   * Sets the value of the slider using an underlying value. Returns if the value isn't part of the inputted steps.
   */
  public setValue(value: any): void {
    const s = this.getSteps().find(x => x.value == value);
    if (s) {
      this.setIndex(this.getSteps().indexOf(s));
    }
  }

  /**
   * Gets the current value of the slider as an index.
   */
  public getIndex(): number { return Number.parseInt(this.getControl().value); }
  /**
   * Sets the value of the slider using an index. Returns if the index is out of bounds.
   */
  public setIndex(index: number): void { if (index > 0 && index < this.getSteps().length) { this.getControl().setValue(index); } }

  /**
   * Gets the possible steps of this slider.
   *
   * **WARNING!:** This returns a mutable value! Be careful!
   */
  public getSteps() : LabeledValue[] { return this.steps; }

  public isPristine() : boolean { return this.getControl().pristine; }

  public isDirty() : boolean { return this.getControl().dirty; }

  //#endRegion

  protected getControl(): FormControl { return this._sliderControl; }

  protected getStartingIndex(): number { return this.startingIndex; }

  protected doesHideActive() : boolean { return this.hideActive; }

  //#endregion


  //region Functions
  //#region Public [blue]

  public enable(): void { this.getControl().enable(); }
  public disable(): void { this.getControl().disable(); }

  //#endregion
  //#endregion

}
