import { Injectable } from '@angular/core';
import { ApiUrls } from '../../../core/api.urls';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { ContentService } from '../../../core/content/content.service';
import { CurriculumLinkDialogComponent } from './curriculum-link-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ModalDialog } from '../../../core/modal-dialog';
import { InfoType } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { Core, ImageableContentReference } from '../../../core/core.types';


@Injectable({ providedIn: 'root' })
export class CurriculumLinkDialogService {

  constructor(
    private contentService: ContentService,
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  checkCurriculumLockedBy(content: ImageableContentReference): Observable<void> {
    if ( !DistributionTypeHelper.isCurriculum(content.objType) ) {
      // only available for curricula, for now
      return EMPTY;
    }

    const url = ApiUrls.getKey('AccountCurriculumLockedBy')
      .replace(/{curriculumId}/gi, String(content.id));
    return forkJoin([
      this.http.get<any>(url),
      this.contentService.fetchAccountData()
        .pipe(take(1)),
    ])
      .pipe(catchError(this.handleError))
      .pipe(map(([ response, account ]) => {
        const lockingItems = response?.lockingItems;
        if ( !(lockingItems?.length > 0) ) {
          // nothing to see here
          return [];
        } else {
          return [
            // convert to distinct curriculumIds
            ...new Set(lockingItems.map(item => item.parentCurriculumId)),
          ]
            // find curricula in account - including correct parents
            .map((curriculumId: number) => ContentService
              .getContentById(account, curriculumId, false, [ Core.DistributableType.lms_curriculum ]))
            // filter any unknowns
            .filter(curriculum => curriculum != null);
        }
      }))
      .pipe(takeWhile(curricula => curricula?.length > 0))
      .pipe(switchMap(curricula => this.dialog.open(CurriculumLinkDialogComponent, {
        data: {
          curricula,
          dialogMessage: $localize`:@@content_action_curriculum_locked_message:
          To unlock this curriculum, you have to finish some other tasks first:
          `,
          dialogTitle: $localize`:@@content_action_curriculum_locked_title:This curriculum is locked`,
        },
      }).afterClosed()))
      .pipe(map(() => void (0)))
      .pipe(take(1));
  }

  private handleError = (error: any): Observable<never> => {
    console.log(error);
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

}
