<rag-dialog-header>
  <span>{{user.userFirstname}} {{user.userLastname}} / {{course.title}}</span>
</rag-dialog-header>

<div class="container" mat-dialog-content>
  <table *ngFor="let courseItem of course.courseItems">
    <tr>
      <th class="label"></th>
      <th></th>
    </tr>
    <!-- Title -->
    <tr>
      <td colspan="2" class="title">
        <span class="chapter" i18n="@@global_chapter">Chapter</span>
        <span class="chapter">:&nbsp;</span>
        <span>{{courseItem.scoTitle}}</span>
        <hr>
      </td>
    </tr>
    <!-- Session fields & values -->
    <ng-container *ngFor="let session of sessionForCourseItem(courseItem)">
      <tr *ngIf="session === NO_SESSION; else displaySession">
        <td colspan="2" class="no-session">
          no session
        </td>
      </tr>
      <ng-template #displaySession>
        <ng-container *ngTemplateOutlet="sessionTemplate; context: {session: session}"></ng-container>
      </ng-template>

    </ng-container>
  </table>

</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close color="primary">
    <span i18n="@@global_close">Close</span>
  </button>
</div>

<!-- Template to render session data -->
<ng-template #sessionTemplate let-session="session">
  <tr *ngFor="let field of fieldsForSession(session)">
    <!-- Labels -->
    <td class="label" [matTooltip]="FIELD_TO_CMI[field]">
      {{fieldTextForField(field)}}
    </td>
    <td [class]="tdClassForField(field)">
      <div class="value">
        {{valueForField(field, session)}}
      </div>
    </td>
  </tr>
</ng-template>
