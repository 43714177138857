export namespace Contacts {

  export type LoginStatus = 'loggedIn' | 'loggedOut' | 'both';
  export type Reason =
    'technical'
    | 'noaccess'
    | 'lostpw'
    | 'status'
    | 'notopen'
    | 'coursestuck'
    | 'notfound'
    | 'contentmistake';


  export class ContactDefinition {
    loginStatus: LoginStatus;
    reason: Reason;
    order: number;
    text: string;
  }

  export const classicContactDefinitions: ContactDefinition[]  = [
    {
      loginStatus: 'both',
      reason: 'technical',
      order: 0,
      text: $localize`:@@contact_select_op9:I have an unspecified reason for my support contact.`,
    }, {
      loginStatus: 'loggedOut',
      reason: 'noaccess',
      order: 1,
      text: $localize`:@@contact_select_op1:My credentials are not working.`,
    }, {
      loginStatus: 'loggedOut',
      reason: 'lostpw',
      order: 2,
      text: $localize`:@@contact_select_op2:I forgot my password.`,
    }, {
      loginStatus: 'loggedIn',
      reason: 'status',
      order: 3,
      text: $localize`:@@contact_select_op3:A course status does not change.`,
    }, {
      loginStatus: 'loggedIn',
      reason: 'notopen',
      order: 4,
      text: $localize`:@@contact_select_op4:A course does not open.`,
    }, {
      loginStatus: 'loggedIn',
      reason: 'coursestuck',
      order: 5,
      text: $localize`:@@contact_select_op5:A course is stuck.`,
    }, {
      loginStatus: 'both',
      reason: 'technical',
      order: 6,
      text: $localize`:@@contact_select_op6:I cannot find a specific course.`,
    }, {
      loginStatus: 'loggedIn',
      reason: 'technical',
      order: 7,
      text: $localize`:@@contact_select_op7:There is an error in the content of a course.`,
    }, {
      loginStatus: 'both',
      reason: 'technical',
      order: 8,
      text: $localize`:@@contact_select_op8:I have a different problem.`,
    },
  ];


}
