import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { take, tap } from 'rxjs/operators';
import { OAuth2ClientRegistration } from '../ext-oauth2.types';
import { TableControllerComponent } from '../../../../../component/table/table-controller/table-controller.component';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import { EXT_OAUTH2_DEFAULT_MENU_COLUMNS } from './ext-oauth2-list.columns';
import { ExtOauth2EditService } from '../ext-oauth2-edit/ext-oauth2-edit.service';
import { ExtOauth2Service } from '../ext-oauth2.service';


@Component({
  selector: 'rag-ext-oauth2-list',
  templateUrl: './ext-oauth2-list.component.html',
  styleUrls: [ './ext-oauth2-list.component.scss' ],
})
export class ExtOauth2ListComponent
  extends TableControllerComponent<OAuth2ClientRegistration> {

  constructor(
    private extOAuth2EditService: ExtOauth2EditService,
    private extOAuth2Service: ExtOauth2Service,
    private route: ActivatedRoute,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);

    this.defaultSort = 'clientName';
    this.updateMenuColumns();

    this.route.data
      .pipe(tap(data => this.updateRouteData(data?.clientRegistrations)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onAdd(): void {

    this.extOAuth2EditService.editClientRegistration(null)
      .pipe(take(1))

      // copy result into entity
      .pipe(tap(result => {
        const data = this.dataSource.data ?? [];
        data.push(result);
        this.setTableData(data);
      }))

      .subscribe();
  }

  onDelete(
    clientRegistration: OAuth2ClientRegistration,
  ): void {

    const registrationId = clientRegistration?.registrationId;
    if ( clientRegistration == null ) {
      return;
    }

    this.extOAuth2Service.deleteClientRegistration(registrationId)
      .pipe(take(1))

      // copy result into entity
      .pipe(tap(() => {

        const data = this.dataSource.data;
        const index = data.findIndex(entry => entry.registrationId === registrationId);
        if ( !(index >= 0) ) {
          // magically missing already -> do not modify
          return;
        }

        data.splice(index, 1);
        this.setTableData(data);
      }))

      .subscribe();
  }

  onEdit(
    clientRegistration: OAuth2ClientRegistration,
  ): void {

    if ( clientRegistration == null ) {
      return;
    }

    this.extOAuth2EditService.editClientRegistration(clientRegistration)
      .pipe(take(1))

      // copy result into entity
      .pipe(tap(result => {

        const data = this.dataSource.data ?? [];
        const registrationId = result?.registrationId;
        const index = data.findIndex(entry => entry.registrationId === registrationId);

        if ( !(index >= 0) ) {
          // magically missing entry -> update attributes manually
          Object.keys(clientRegistration)
            .map(key => delete clientRegistration[key]);
          Object.entries(result ?? {})
            .map(([ key, value ]) => clientRegistration[key] = value);
          return;
        }

        // inject update entity into data array
        data.splice(index, 1, result);
        this.setTableData(data);
      }))

      .subscribe();
  }

  private updateMenuColumns(): void {

    const menuData = TableColumnMenuService.createFromDefaults(EXT_OAUTH2_DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
    this.checkFilter();
  }

  private updateRouteData(
    clientRegistrations: OAuth2ClientRegistration[] | null,
  ): void {

    this.setTableData(clientRegistrations ?? []);

    this.inputDisabled = false;
  }

}
