import { Injectable } from '@angular/core';
import { LanguageHelper } from '../../../../../../core/language.helper';
import { TableColumnMenu } from '../../../../../../component/table/table-column-menu/table-column-menu.types';
import { TableAccessors } from '../../../../../../component/table/table.accessors';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { encode } from 'iconv-lite';


@Injectable({ providedIn: 'root' })
export class ExcelExportService {

  private static getColumnTitles(columns: TableColumnMenu.MenuItem[]): string[] {
    return columns
      .map(column => LanguageHelper.objectToText(column.title));
  }

  private static getColumns(columnMenuData: TableColumnMenu.MenuData, selectedColumnIds: string[]):
    TableColumnMenu.MenuItem[] {

    return selectedColumnIds
      .map(columnId => columnMenuData?.menuItems[columnId])
      // skip empty and hidden columns
      .filter(column => (column != null) && (column.hidden !== true));
  }

  private static getUserData<ROW>(data: ROW[], columns: TableColumnMenu.MenuItem[]): any[][] {

    return data.map(user => Object.values(columns)
      .map(column => TableAccessors.getDisplayValue(user, column.options)));
  }

  private static saveExcelFile(buffer: any, fileName: string): void {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const data: Blob = new Blob([ buffer ], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  exportAsCsv<DATA>(
    data: DATA[] | null,
    columnMenuData: TableColumnMenu.MenuData,
    selectedColumnIds: string[],
    filename: string,
    encoding: 'utf-8' | 'iso-8859-1',
  ): void {

    if ( !(data?.length > 0) ) {
      return;
    }

    const columns = ExcelExportService.getColumns(columnMenuData, selectedColumnIds);
    const csvData = [
      ExcelExportService.getColumnTitles(columns),
      ...ExcelExportService.getUserData(data, columns),
    ].map(row => {
      try {
        return row.reduce((pV, v, index) => {
          if ( index > 0 ) {
            pV += ';';
          }
          const value = `${v ?? ''}`.replace(/"/g, '""');
          pV += `"${value}"`;
          return pV;
        }, '');
      } catch ( e ) {
        return null;
      }
    }).filter(row => row != null).join('\n');

    let csvBlob: Blob;
    if ( encoding === 'iso-8859-1' ) {
      csvBlob = new Blob([
        encode(csvData, 'iso-8859-1'),
      ], { type: 'text/csv;charset=iso-8859-1' });
    } else {
      csvBlob = new Blob([
        encode(csvData, 'utf-8', { addBOM: true }),
      ], { type: 'text/csv;charset=utf-8' });
    }
    FileSaver.saveAs(csvBlob, filename);
  }

  exportAsXls<DATA>(
    data: DATA[] | null,
    columnMenuData: TableColumnMenu.MenuData,
    selectedColumnIds: string[],
    filename: string,
  ): void {

    if ( !(data?.length > 0) ) {
      return;
    }

    const columns = ExcelExportService.getColumns(columnMenuData, selectedColumnIds);
    const rows = [
      ExcelExportService.getColumnTitles(columns),
      ...ExcelExportService.getUserData(data, columns),
    ];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: [ 'data' ] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    ExcelExportService.saveExcelFile(excelBuffer, filename);
  }

}
