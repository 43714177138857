import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';


@Component({
  selector: 'rag-profile-field-number',
  templateUrl: './profile-field-number.component.html',
  styleUrls: [ './profile-field-number.component.scss' ],
})
export class ProfileFieldNumberComponent
  extends AbstractProfileFieldComponent<number> {

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

}
