<div class="flex-wrapper">
  <div class="pie-chart">
    <button
      #togglePieChart="ragToggle"
      [ragToggle]="false"
      (click)="togglePieChart.toggle()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="chart-arc"></mat-icon>
    </button>

    <rag-doughnut-chart
      *ngIf="togglePieChart.open"
      #doughnutChart
      (optionsChanged)="doughnutChart.options = $event"
    >
      <canvas
        [data$]="chartPie$"
        [downloadChart$]="doughnutChart.downloadClicked"
        [options]="doughnutChart.options"
        [reportTitle]="reportTitle"
        ragDoughnutChart
      ></canvas>
    </rag-doughnut-chart>
  </div>

  <div class="learning-time-chart">
    <button
      #toggleTimeChart="ragToggle"
      [ragToggle]="false"
      (click)="toggleLearningTime(toggleTimeChart)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="chart-bar"></mat-icon>
    </button>

    <rag-learning-time-chart
      *ngIf="toggleTimeChart.open"
      #learningTimeChart
      (optionsChanged)="learningTimeChart.options = $event"
      (downloadCsv)="onDownloadCsv(learningTimeChart.options)"
    >
      <canvas
        [reportLearningTime$]="chartLearningTime$"
        [downloadChart$]="learningTimeChart.downloadChart"
        [options]="learningTimeChart.options"
        [reportTitle]="reportTitle"
        ragLearningTimeChart
      ></canvas>
    </rag-learning-time-chart>
  </div>
</div>
