<rag-dialog-header>
  <span i18n="@@general_dialog_confirm_title">Confirm</span>
</rag-dialog-header>

<p mat-dialog-content>
  <span i18n="@@do_you_really_want_to_delete_this">Do you really want delete this?</span>
</p>

<mat-dialog-actions>
  <button (click)="onKeepClick()" mat-stroked-button>
    <span i18n="@@keep_button">Keep</span>
  </button>
  <button (click)="onDeleteClick()" autofocus color="warn" mat-flat-button>
    <span i18n="@@delete_button">Delete</span>
  </button>
</mat-dialog-actions>
