<div id="container">
    <div class="sliderBars" [ngStyle]="{'grid-template-columns': 'repeat('+(stepBars.length)+', 1fr)'}">
      <div *ngFor="let step of stepBars" class="step-bar">
        <div class="bar" [ngStyle]="{'height': step.height, 'background-color': step.color}"></div>
      </div>
    </div>
    <div id="sliderContainer">
        <mat-slider
            color="primary"
            min="0"
            [max]="getSteps().length-1"
            showTickMarks
            [class.hideActive]="doesHideActive()"
        >
            <input matSliderThumb [formControl]="getControl()"/>
        </mat-slider>
    </div>
    <div id="sliderLabels" [ngStyle]="{'grid-template-columns': 'repeat('+(getSteps()?.length)+', 1fr)'}">
        <div *ngFor="let step of getSteps()">
            {{step.label}}
        </div>
    </div>
</div>
