import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { CachedSubject } from 'src/app/core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { Core } from '../../../../../core/core.types';
import { EhsService } from '../../../../../core/ehs/ehs.service';
import {
  CONTENT_NOTIFICATIONS_TYPES,
  NotificationAddGroup,
  NotificationAddType,
} from '../../../admin-offline/components/content-notifications/content-notifications.types';
import { AdminNotificationsHelper } from '../admin-notifications.helper';
import { AdminNotificationsService } from '../admin-notifications.service';
import {
  NotificationEventChangeDialogData,
  NotificationIdentifiable,
  SubTargetOptions
} from '../admin-notifications.types';

@Component({
  selector: 'rag-admin-notifications-event-change-dialog',
  templateUrl: './admin-notifications-event-change-dialog.component.html',
  styleUrls: ['./admin-notifications-event-change-dialog.component.scss']
})
export class AdminNotificationsEventChangeDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  allEvents: NotificationAddGroup[];
  allItems: NotificationIdentifiable[];
  filteredItems: NotificationIdentifiable[];
  filteredSubTargets$: Observable<SubTargetOptions[]>;
  filteredSubTargets: SubTargetOptions[] = [];
  targetId: number;
  saveButtonDisabled$: Observable<boolean>;
  notificationsOfFilteredEvents: NotificationAddType[] = [];
  subTargetsLoading$: Observable<boolean>;
  subTargetsLoadedId: number;
  saveText: string;
  subTargetsAvailable = false;

  readonly filteredItems$: Observable<NotificationIdentifiable[]>;
  readonly notificationsOfFilteredEvents$: Observable<NotificationAddType[]>;

  private _filteredItems$ = new CachedSubject<NotificationIdentifiable[]>([]);
  private _notificationsOfFilteredEvents$ = new CachedSubject<NotificationAddType[]>([]);
  private _subTargetsLoading$ = new CachedSubject<boolean>(false);
  private _filteredSubTargets$ = new CachedSubject<SubTargetOptions[]>([]);
  private _defaultTemplateMode: boolean;

  constructor(
    private adminNotificationsService: AdminNotificationsService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: NotificationEventChangeDialogData,
    private dialogRef: MatDialogRef<AdminNotificationsEventChangeDialogComponent>
  ) {
    this.allEvents = CONTENT_NOTIFICATIONS_TYPES;
    this.allItems = data.items;
    this._defaultTemplateMode = data.defaultTemplateMode;
    this.filteredItems$ = this._filteredItems$.asObservable();
    this.targetId = data.currentTargetId ?? 0;
    this.notificationsOfFilteredEvents$ = this._notificationsOfFilteredEvents$.asObservable();
    this.subTargetsLoading$ = this._subTargetsLoading$.asObservable();
    this.filteredSubTargets$ = this._filteredSubTargets$.asObservable();
    this.saveText = this.data.createMode ?
      $localize`:@@general_create:Create` :
      $localize`:@@global_save:Save`;
  }

  ngOnInit(): void {
    this.filteredSubTargets = this.data?.subTargets ?? [];
    this._filteredSubTargets$.next(this.filteredSubTargets);
    this.subTargetsAvailable = this.filteredSubTargets.length > 0;
    this.buildFormGroup();
    this.saveButtonDisabled$ = this.form.statusChanges
    .pipe(map(formStatus => formStatus !== 'VALID'))
    .pipe(startWith(true))
    .pipe(takeUntilDestroyed(this));
  }

  getDialogTitle(): string {
    if (this.data.createMode && !this.data.defaultTemplateMode) {
      return $localize`:@@admin_offline_details_create_notification:Create a Notification`;
    } else if (this.data.defaultTemplateMode) {
      return $localize`:@@global_notification_template:Notification template`;
    }
    return $localize`:@@global_edit_notification:Edit notification`;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }


  getEventTitle(event: NotificationAddType | null): string {
    if (event == null) {
      return '';
    }

    const item: NotificationIdentifiable = this.form?.get('item')?.value;
    if (item?.objType !== Core.DistributableType.lms_course) {
      return event.name;
    }

    const subType = `${item?.objectSubType}`;
    return event.nameOverride?.[subType] ?? event.name;
  }

  onItemSelected(item: NotificationIdentifiable): void {
    this.targetId = item.objectId;
    this.form.controls['subTarget'].setValue(null);
    this.onLoadingSubTargets();
  }

  onLoadingSubTargets(): void {
    const formControls = this.form.controls;
    const moduleId = formControls['module'].value;
    const targetId = formControls['item']?.value?.objectId;

    if (targetId === this.subTargetsLoadedId) {
      return;
    }

    if (!(moduleId > 0) || !(targetId > 0)) {
      return;
    }
    this._subTargetsLoading$.next(true);
    this.adminNotificationsService.fetchSubTargets(moduleId, targetId)
      .pipe(map(results => {
        this.subTargetsAvailable = results.length > 0;
        this._filteredSubTargets$.next(results);
      }))
      .pipe(finalize(() => this._subTargetsLoading$.next(false)))
      .subscribe();
  }

  isEventDisabled(): boolean {
    const value = this.form.controls['module'].value ?? '';
    return value == null || value === 0 || (!(this.form.controls['item'].value?.objectId > 0) && value !== 10);
  }

  isElementDisabled(): boolean {
    if (this.data.defaultTemplateMode) {
      return true;
    }

    if (this.data?.elementDisabled) {
      return true;
    }

    const value = this.form.controls['module']?.value;
    return value == null || value === 0 || value === 10;
  }

  isModuleDisabled(): boolean {
    if (this.data?.moduleId != null) {
      return true;
    }
    return false;
  }

  isSubTargetObjectDisabled(): boolean {
    if (this.data.defaultTemplateMode) {
      this.form.controls['subTarget'].disable();
      return true;
    }

    if (this.form.controls['module'].value === 10 || this.form.controls['module'].value === 15) {
      this.form.controls['subTarget'].disable();
      return true;
    }

    if (!this.subTargetsAvailable) {
      this.form.controls['subTarget'].disable();
      return true;
    }

    const eventId = Number(String(this.form.controls['event'].value?.eventId).substring(0, 3));
    if (eventId !== 111 && eventId !== 141) {
      this.form.controls['subTarget'].disable();
      return true;
    }

    const value = this.form.controls['item']?.value?.objectId;
    const disabled = (value == null);
    if (disabled) {
      this.form.controls['subTarget'].disable();
    } else {
      this.form.controls['subTarget'].enable();
    }
    return disabled;
  }

  renderEvent = (event: NotificationAddType): string => {
    return this.getEventTitle(event);
  }

  renderElement(element: NotificationIdentifiable): string {
    return element?.systemName ?? '';
  }

  onSubmit() {

    const eventControl = this.form.controls['event']?.value;
    const eventId = eventControl?.eventId;
    const shortenendEventId = Math.floor(eventId / 1000);
    let moduleId;
    if (shortenendEventId % 10 === 0) {
      moduleId = Math.floor(shortenendEventId / 10);
    } else {
      moduleId = shortenendEventId;
    }
      let hasTarget = false;
      let targetValue = null;
      let subTargetId = null;
      let saveSubTargetId = null;
      if (!this._defaultTemplateMode) {
        hasTarget = String(moduleId).substring(0, 2) !== '10';
        targetValue = hasTarget ? this.form.get('item')?.value : null;
        subTargetId = this.form.controls['subTarget']?.value?.subTargetId;
        saveSubTargetId = subTargetId > 0 ? subTargetId : targetValue?.objectId;
      }
      this.dialogRef.close({
        eventId,
        targetId: targetValue?.objectId,
        eventModuleId: moduleId,
        subTargetId: saveSubTargetId,
        targetTitle: targetValue?.systemName ?? '',
      });
  }

  private filterEvents(moduleId: number, target?: NotificationIdentifiable): void {
    const eventIdFilter = EhsService.getEventIdWhitelist(
      target?.objectType ?? target?.objType, target?.objectSubType);
    const filteredEvents = this.allEvents.filter(event => event.notifications
        .find(notification => {
          const moduleID = String(notification.moduleId).substring(0, 2);
          if (eventIdFilter?.length > 0) {
            // only allow selected eventIds
            return (eventIdFilter.indexOf(notification.eventId) >= 0);
          }
          // match all in module
          return Number(moduleID) === moduleId;
        }));
    this.notificationsOfFilteredEvents = filteredEvents
      .reduce((pV, filteredEvent) => {
        pV.push(...filteredEvent.notifications.filter(notification => {
          if (!(eventIdFilter?.length > 0)) {
            return true;
          }
          return (eventIdFilter.indexOf(notification.eventId) >= 0);
        }));
        return pV;
      }, []);
    this._notificationsOfFilteredEvents$.next(this.notificationsOfFilteredEvents);
  }

  private filterItems(moduleId: number) {
    let filteredValues: NotificationIdentifiable[];
    const objTypeString = AdminNotificationsHelper.convertEventModuleIdToEventModule(moduleId);
    if (objTypeString === 'user' || this.data.defaultTemplateMode) {
      filteredValues = [{
        objType: 'user',
        objectId: -1,
        systemName: $localize`:@@ctrl-single-user-nav-all-users:All users`
      }];
      this.form.get('item').setValue('');
      this.form.get('item').setValidators([]);
      this.form.get('item').disable();
    } else {
      filteredValues = this.allItems.filter(item => {
        let distTypeString = '';
        switch ( objTypeString ) {
          case 'course':
            distTypeString = Core.DistributableType.lms_course;
            break;
          case 'curriculum':
            distTypeString = Core.DistributableType.lms_curriculum;
            break;
          case 'offlineContent':
            distTypeString = Core.DistributableType.lms_offlineCnt;
        }

        return item.objType === distTypeString;
      });
      this.form.get('item').enable();
      this.form.get('item').setValidators([Validators.required]);
    }

    this.filteredItems = filteredValues;
    this._filteredItems$.next(filteredValues);
  }

  private buildFormGroup(): void {
    let moduleId: number;
    const moduleIdString = String(this.data.moduleId) ?? String(this.data.eventId ?? '').substring(0, 3);
    if (moduleIdString.endsWith('0')) {
      moduleId = parseInt(moduleIdString.substring(0,2), 10);
    } else {
      moduleId = parseInt(moduleIdString, 10);
    }

    // const moduleId = !(this.data.moduleId > 0) ? Number(moduleIdString) : this.data.moduleId;

    let target: NotificationIdentifiable;
    let subTarget: SubTargetOptions;
    if ( this.data.parentTargetId !== this.data.currentTargetId ) {
      target = this.allItems
        .find(item => item.objectId === this.data.parentTargetId);
    } else {
      target = this.allItems
        .find(item => item.objectId === this.data.currentTargetId);
    }
    if ( this.filteredSubTargets.length > 0 ) {
      subTarget = this.filteredSubTargets
        .find(filteredSubTarget => (Math.abs(Number(filteredSubTarget.subTargetId)) === this.data.currentTargetId));
    } else {
      subTarget = null;
    }

    if ( target != null ) {
      this.subTargetsLoadedId = target.objectId;
    }

    this.filterEvents(moduleId, target);
    const event = this.notificationsOfFilteredEvents
      .find(notification => notification.eventId === this.data.eventId);

    const topModuleId = moduleId > 100 ? Math.floor(moduleId / 10) : moduleId;
    this.form = this.formBuilder.group({
      module: [ topModuleId, Validators.required ],
      event: [ event, this._defaultTemplateMode ? [] : Validators.required ],
      item: [ target, this.data?.elementDisabled ? [] :Validators.required ],
      subTarget: [ subTarget, this.data?.subTargets == null ? [] :Validators.required ],
    });

    this.filterItems(topModuleId);

    this.form.controls['module'].valueChanges.pipe(map(module => {
      this.form.controls['event'].setValue(null);
      this.form.controls['item'].setValue(null);
      this.form.controls['subTarget'].setValue(null);
      this.filterEvents(module, this.form.get('item')?.value);
      this.filterItems(module);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.form.controls['event'].valueChanges.pipe(map(eventValue => {
      this.form.controls['subTarget'].setValue(null);
      if ( eventValue === '' ) {
        this._notificationsOfFilteredEvents$.next(this.notificationsOfFilteredEvents);
        return;
      }

      this._notificationsOfFilteredEvents$.next(this.notificationsOfFilteredEvents.filter(notification =>
        notification.name.toLocaleLowerCase().includes(String(eventValue).toLocaleLowerCase())));
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.form.controls['item'].valueChanges.pipe(map(item => {
      this.filterEvents(this.form.get('module').value, item);
      this.form.controls['subTarget'].setValue(null);
      if ( item === '' ) {
        this._filteredItems$.next(this.filteredItems);
        return;
      }
      this._filteredItems$.next(this.filteredItems
        .filter(itemValue => itemValue.systemName.toLocaleLowerCase().includes(String(item).toLocaleLowerCase())));
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

}
