import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostLoginPasswordComponent } from './post-login-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from '../../../../component/input/password/password.module';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';


@NgModule({
  declarations: [ PostLoginPasswordComponent ],
  exports: [ PostLoginPasswordComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PasswordModule,
    MatButtonModule,
    MatStepperModule,
  ],
})
export class PostLoginPasswordModule {
}
