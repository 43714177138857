import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReducedBadge } from '../../../core/gamification/gamification.types';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LanguageHelper } from '../../../core/language.helper';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ImageUrlHelper } from '../../../core/image-url-helper';
import { Observable } from 'rxjs';
import { CachedSubject } from '../../../core/cached-subject';
import { PdfService } from '../../../core/pdf.service';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'rag-badge-card',
  templateUrl: './badge-card.component.html',
  imports: [
    MatIconModule,
    PipesModule,
    MatProgressBarModule,
    MatButtonModule,
    NgClass,
    MatTooltipModule,
    NgIf,
    AsyncPipe,
  ],
  styleUrls: [ './badge-card.component.scss' ],
})
export class BadgeCardComponent
  implements OnChanges {

  @Input() badge: ReducedBadge;
  @Input() languageForPreview = LanguageHelper.getCurrentLanguage().key;
  @Input() previewMode = false;
  cardStyle = '';
  pictureUrl$: Observable<string>;
  title$: Observable<string>;
  description$: Observable<string>;
  private _pictureUrl$ = new CachedSubject<string>(null);
  private _title$ = new CachedSubject<string>(null);
  private _description$ = new CachedSubject<string>(null);

  constructor(
    private pdfService: PdfService,
  ) {
    this.pictureUrl$ = this._pictureUrl$.asObservable();
    this.title$ = this._title$.asObservable();
    this.description$ = this._description$.asObservable();
  }

  ngOnChanges(changes: SimpleChanges) {
    const url = ImageUrlHelper.urlForPicture(this.badge?.pictureId, this.badge?.pictureUUID);
    this._pictureUrl$.next(url);
    this._title$.next(LanguageHelper
      .objectToText(this.badge?.info?.title, this.languageForPreview));
    this._description$.next(LanguageHelper
      .objectToText(this.badge?.info?.description, this.languageForPreview));
  }

  onDownloadBadge(): void {
    if ( this.previewMode ) {
      return;
    }

    this.pdfService.generateBadgePdf(this.badge.badgeUUID)
      .pipe(take(1))
      .subscribe();
  }

  onTurnCard(side: 'front' | 'back'): void {
    if ( side === 'front' ) {
      this.cardStyle = 'turnaround';
    } else {
      this.cardStyle = 'turnaround-back';
    }
  }
}
