import { Component, HostListener, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { HeaderService } from '../../../core/navigation/header.service';
import { NavItem, NavItemType } from '../../../core/navigation/navigation.types';
import { HeaderNavHelper } from '../header-nav.helper';
import { ApplicationStateService } from '../../../core/application-state.service';
import { Observable } from 'rxjs';
import { PrincipalService } from '../../../core/principal/principal.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[rag-header-sidenav]',
  templateUrl: './header-sidenav.component.html',
  styleUrls: [ './header-sidenav.component.scss' ],
})
export class HeaderSidenavComponent
  implements OnDestroy {

  isSmall = false;
  navItems: NavItem[];
  showContentSearch = false;
  isLogged$: Observable<boolean>

  constructor(
    public headerNavHelper: HeaderNavHelper,
    private appState: ApplicationStateService,
    private headerService: HeaderService,
    private principalService: PrincipalService
  ) {
    this.headerService.getNavigation()
      .pipe(tap(navigationData => {
        this.showContentSearch = navigationData?.showContentSearch ?? false;

        const mainItems = navigationData?.main ?? [];
        const topItems = navigationData?.top ?? [];
        if ( (mainItems.length > 0) && (topItems.length > 0) ) {
          this.navItems = [
            ...mainItems,
            { type: NavItemType.divider } as NavItem,
            ...topItems,
          ];
        } else {
          this.navItems = [
            ...mainItems,
            ...topItems,
          ];
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.appState.viewportState$
      .pipe(tap(state => this.isSmall = state.isSmall()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.isLogged$ = this.principalService.isLogged$;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  @HostListener('click') onToggleSideNav(): void {
    this.appState.toggleSidenav();
  }

}
