import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { RagPageComponent } from './rag-page/rag-page.component';
import { RagPageService } from './rag-page/rag-page.service';
import { WidgetsModule } from './widgets/widgets.module';

@NgModule({
  declarations: [ RagPageComponent ],
  imports: [
    CommonModule,
    CoreModule,
    WidgetsModule,
  ],
  providers: [
    RagPageService,
  ],
  exports: [
    RagPageComponent,
  ],
})

export class RagLayoutModule {
}
