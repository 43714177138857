import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { Catalogs } from '../../../core/catalog/catalog.types';


@Component({
  selector: 'rag-catalog-card-curriculum',
  templateUrl: './catalog-card-curriculum.component.html',
  styleUrls: [ './catalog-card-curriculum.component.scss' ],
})
export class CatalogCardCurriculumComponent {

  @Input() catalogEntry: Catalogs.CatalogEntry;

  get hasDistSubType(): boolean {
    return (this.catalogEntry.objSubType != null);
  }

  @HostBinding('class.with-events') get hasEvents(): boolean {
    return (this.catalogEntry?.offlineEventsViews?.length > 0);
  }

  get objSubTypeAsText(): string {

    const objSubType = this.catalogEntry?.objSubType;
    if ( objSubType == null ) {
      return null;
    }

    return DistributionTypeHelper.asText(this.catalogEntry.objType, this.catalogEntry.objSubType);
  }

  getTemplate(
    tplDescription: TemplateRef<any>,
    tplOfflineEvents: TemplateRef<any>,
  ): TemplateRef<any> {

    if ( this.hasEvents ) {
      return tplOfflineEvents;
    }

    return tplDescription;
  }

}
