import { DeviceDetectorService } from 'ngx-device-detector';
import { versions } from '../../../../environments/versions';

export enum SysCheckSupport {
  Supported, Unsupported, UnsupportedVersion, NotTested
}

export interface Support {
  icon: string;
  minVersion?: string;
  tested?: boolean;
  unsupportedVersions?: string[];
}

const NO_BROWSER_VERSION = '-1';

const isLocalStorage = (): boolean => {
  if ( typeof localStorage !== 'undefined' ) {
    localStorage.setItem('test', 'test');
    return localStorage.getItem('test') === 'test';
  }
  return false;
};

export class SysCheck {

  private static SUPPORTED_BROWSERS: { [id: string]: Support } = {
    Chrome: { minVersion: '50', icon: 'google-chrome' },
    Firefox: { minVersion: '26', icon: 'firefox' },
    Safari: { minVersion: '7', icon: 'apple-safari' },
    Opera: { minVersion: '36', icon: 'opera' },
    IE: { minVersion: '10', icon: 'microsoft-internet-explorer' },
    'MS-Edge': { minVersion: '12', icon: 'microsoft-edge' },
    'MS-Edge-Chromium': { minVersion: '79', icon: 'microsoft-edge' },
    'FB-Messanger': { minVersion: NO_BROWSER_VERSION, icon: ' facebook-messenger' },
    UNSUPPORTED: { minVersion: NO_BROWSER_VERSION, icon: 'help' },
  };

  private static SUPPORTED_OS: { [id: string]: Support } = {
    Windows: {
      unsupportedVersions: [ 'windows-3-11', 'windows-95', 'windows-me', 'windows-98', 'windows-ce', 'windows-2000', 'windows-xp', 'windows-server-2003', 'windows-vista' ],
      icon: 'microsoft-windows',
      tested: true,
    },
    Mac: {
      unsupportedVersions: [ 'mac-os-x', 'mac-os-x-2', 'mac-os-x-3', 'mac-os-x-4', 'mac-os-x-5', 'mac-os-x-6', 'mac-os-x-7', 'mac-os-x-8', 'mac-os-x-9', 'mac-os-x-10', 'mac-os-x-11' ],
      icon: 'apple',
      tested: true,
    },
    iOS: { icon: 'apple-ios', tested: true },
    Android: { icon: 'android', tested: true },
    Linux: { icon: 'linux' },
    Unix: { icon: 'help-rhombus' },
    'Firefox-OS': { icon: 'firefox' },
    'Chrome-OS': { icon: 'google-chrome' },
    'Windows-Phone': { icon: 'microsoft-windows' },
    Unknown: { icon: 'help-rhombus' },
  };
  browser: { name: string; version: string };
  cookieEnabled: boolean;
  device: { name: string };
  frontEndVersion: string;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  localStorageEnabled: boolean; // safari treats cookies and localStorage the same
  os: string;
  screen: { width: number; height: number; colorDepth: number };
  supportsPDF: boolean;
  userAgent: string;

  constructor(deviceDetector: DeviceDetectorService) {
    this.userAgent = navigator.userAgent;
    this.browser = { name: deviceDetector.browser, version: String(deviceDetector.browser_version) };
    this.os = deviceDetector.os;
    this.screen = { width: screen.width, height: screen.height, colorDepth: screen.colorDepth };
    this.isTablet = deviceDetector.isTablet();
    this.isMobile = deviceDetector.isMobile();
    this.isDesktop = deviceDetector.isDesktop();
    this.cookieEnabled = navigator.cookieEnabled;
    this.localStorageEnabled = isLocalStorage();
    // atm no possibility to detect pdf support on Firefox because Firefox removed entries in mimeTypes and plugins
    this.supportsPDF = this.browser.name === 'Firefox' || this.getNavigatorPlugin('application/pdf');
    this.frontEndVersion = versions.version + '-' + versions.revision;
  }

  private static getSupportedBrowser(browserId: string): Support {
    if ( SysCheck.SUPPORTED_BROWSERS.hasOwnProperty(browserId) ) {
      return SysCheck.SUPPORTED_BROWSERS[browserId];
    }
    return SysCheck.SUPPORTED_BROWSERS['UNSUPPORTED'];
  }

  browserDescr() {
    return this.browser.name + ' ' + this.browser.version;
  }

  browserIcon() {
    const supportedBrowser = SysCheck.getSupportedBrowser(this.browser.name);
    if ( supportedBrowser?.icon != null ) {
      return supportedBrowser.icon;
    }
    return SysCheck.SUPPORTED_BROWSERS['UNSUPPORTED'].icon;
  }

  browserSupport(): SysCheckSupport {
    if ( this.isBrowserRecognized() && (this.browserVersion() !== NO_BROWSER_VERSION) ) {
      if ( this.compareVersions(this.browser.version, SysCheck.getSupportedBrowser(this.browser.name).minVersion) === 1 ) {
        return SysCheckSupport.Supported;
      }
      return SysCheckSupport.UnsupportedVersion;
    }
    return SysCheckSupport.Unsupported;
  }

  browserVersion() {
    return this.browser.version;
  }

  colorDepthSupport() {
    if ( this.screen.colorDepth < 16 ) {
      return SysCheckSupport.Unsupported;
    } else {
      return SysCheckSupport.Supported;
    }
  }

  cookiesSupport(): SysCheckSupport {
    return (this.cookieEnabled) ? SysCheckSupport.Supported : SysCheckSupport.Unsupported;
  }

  deviceSupport(): SysCheckSupport {
    if ( this.isMobile ) {
      return SysCheckSupport.Unsupported;
    } else {
      return SysCheckSupport.Supported;
    }
  }

  // Helper methods
  getNavigatorPlugin(name: string): boolean {
    for ( let i = 0; i < navigator.plugins.length; i++ ) {
      const plugin = navigator.plugins.item(i);
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for ( let j = 0; j < plugin.length; j++ ) {
        const item = plugin[j];
        if ( item.type === name ) {
          return true;
        }
      }
    }
  }

  isBrowserRecognized() {
    return Object.keys(SysCheck.SUPPORTED_BROWSERS).indexOf(this.browser.name) >= 0;
  }

  osDescr() {
    return this.os;
  }

  osIcon() {
    return SysCheck.SUPPORTED_OS[this.os].icon;
  }

  osSupport(): SysCheckSupport {
    if ( this.os !== 'Unknown' ) {
      const tested = SysCheck.SUPPORTED_OS[this.os].tested || false;
      if ( !tested ) {
        return SysCheckSupport.NotTested;
      }
      return SysCheckSupport.Supported;
    }
    return SysCheckSupport.Unsupported;
  }

  pdfSupport(): SysCheckSupport {
    return (this.supportsPDF) ? SysCheckSupport.Supported : SysCheckSupport.Unsupported;
  }

  resolutionDescr() {
    return this.screen.width + ' x ' + this.screen.height;
  }

  resolutionSupported(): SysCheckSupport {
    if ( screen.width < 1024 || screen.height < 768 ) {
      return SysCheckSupport.Unsupported;
    } else {
      return SysCheckSupport.Supported;
    }
  }

  screenIcon() {
    if ( this.isDesktop ) {
      return 'monitor';
    } else if ( this.isMobile ) {
      return 'cellphone';
    } else if ( this.isTablet ) {
      return 'tablet';
    }
    return 'help-rhombus';
  }

  private compareVersions(ver1: string, ver2: string): number {
    const tails1 = ver1.split('.');
    const tails2 = ver2.split('.');
    for ( let i = 0; i < Math.min(tails1.length, tails2.length); i++ ) {
      const diff = parseInt(tails1[i], 10) - parseInt(tails2[i], 10);
      if ( diff > 0 ) {
        return 1;
      } else if ( diff < 0 ) {
        return -1;
      }
    }
    if ( tails1.length > tails2.length ) {
      return 1;
    } else if ( tails1.length < tails2.length ) {
      return -1;
    }
    return 0;
  }
}
