import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, concatAll, count, finalize, scan, share, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { SnackbarProgressComponent } from './snackbar-progress.component';


@Injectable({ providedIn: 'root' })
export class SnackbarProgressService {

  constructor(
    private snackBar: MatSnackBar,
  ) {
  }

  /**
   * collect errors to emit at a later point
   */
  private static collectErrors(errors: any[], e): Observable<any> {
    errors.push(e);
    return throwError(e);
  }

  /**
   * if there is one error, throw that one as usual.
   * if there are multiple errors, they will be thrown as an array.
   * without error the pipe continues as usual
   */
  private static handleErrors(errors: any[]): Observable<void> {
    if ( errors.length === 1 ) {
      return throwError(errors[0]);
    } else if ( errors.length > 0 ) {
      return throwError(errors);
    } else {
      return of(void (0));
    }
  }

  showProgress(observables: Observable<any>[]): Observable<void> {
    const array$ = from(observables);
    const requests$ = array$.pipe(concatAll());
    const progress$ = requests$.pipe(share());
    const count$ = array$.pipe(count());
    const errors = [];

    const snackBar = this.snackBar.openFromComponent(SnackbarProgressComponent, {
      panelClass: 'progress',
      data: {
        count$,
        progress$: progress$
          .pipe(catchError(e => SnackbarProgressService.collectErrors(errors, e)))
          .pipe(finalize(() => snackBar.dismiss()))
          .pipe(
            scan(current => current + 1, 0),
            withLatestFrom(count$, (current, total) => current / total),
          ),
      },
    });
    return snackBar.afterDismissed()
      .pipe(take(1))
      .pipe(switchMap(() => SnackbarProgressService.handleErrors(errors)));
  }

}
