<rag-dialog-header [closingEnabled]="false">
  <span>{{header}}</span>
</rag-dialog-header>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>
        <span>{{placeholder}}</span>
      </mat-label>
      <input type="text"
            [placeholder]="placeholder"
            matInput
            [formControlName]="'item'"
            [matAutocomplete]="itemsAutoComplete">
      <button
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="onClearSelectedItems()">
          <mat-icon svgIcon="close"></mat-icon>
      </button>
      <mat-autocomplete
        autoActiveFirstOption
        #itemsAutoComplete="matAutocomplete"
        [displayWith]="renderItem"
        (optionSelected)="onItemSelected($event)">
        <mat-option *ngFor="let c of filteredItemsOptions$ | async" [value]="c">
          {{c.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div class="list">
    <mat-chip-grid #chipGrid>
      <mat-chip-row *ngFor="let item of selectedItems$ | async" (removed)="onDelete(item)">
        <div>{{item.name}}</div>
        <button matChipRemove>
          <mat-icon svgIcon="delete-outline"></mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
  </div>
</mat-dialog-content>

<mat-dialog-actions>

  <div class="count">
    {{(selectedItems$ | async).length}} <span i18n="@@selected">selected</span>
  </div>

  <div class="spacer"></div>

  <button
    type="button"
    mat-stroked-button
    [matDialogClose] = "null">
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    type="button"
    color="primary"
    mat-stroked-button
    [disabled]="selectButtonDisabled$ | async"
    (click) = "onSelect()">
    <span i18n="@@global_select">Select</span>
  </button>
</mat-dialog-actions>