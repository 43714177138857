<div class="container">
  <div #sizingReference *ngIf="hasResponse && chartLearningTime != null" [routerLink]="'/report/v2/saved/' + reportUUID"
       class="chart">
    <rag-learning-time-chart #learningTimeChart
                             (optionsChanged)="chartLearningTimeOptions = $event"
                             [options]="chartLearningTimeOptions">
      <div #sizingContainer class="sizing-container">
        <canvas [downloadChart$]="learningTimeChart.downloadChart"
                [options]="chartLearningTimeOptions"
                [reportLearningTime]="chartLearningTime"
                [reportTitle]="title$ | async"
                [startResize]="sizingDone$"
                ragLearningTimeChart></canvas>
      </div>
    </rag-learning-time-chart>
  </div>
  <div *ngIf="hasResponse && chartLearningTime == null" class="error">
    <mat-icon svgIcon="alert-circle"></mat-icon>
    <div i18n="@@widget-report-has-been-removed">The report has been removed or you are not authorized to access it.</div>
  </div>
</div>
