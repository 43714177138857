import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep, remove } from 'lodash';
import { map } from 'rxjs/operators';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { AccountDesignService } from '../account-design.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'rag-footer-conf',
  templateUrl: './footer-conf.component.html',
  styleUrls: [ './footer-conf.component.scss' ],
})
export class FooterConfComponent
  implements OnInit, OnDestroy {
  URLregEx = '(http(s)?:)//([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  editedItems: string [] = [];
  legalNotice: string;
  legalNoticeIsDirty = false;
  pendingLegalNotice: string;
  pendingPolicies: string;
  pendingPoweredBy: string;
  pendingTerms: string;
  policies: string;
  policiesIsDirty = false;
  poweredBy: string;
  poweredByIsDirty = false;
  terms: string;
  termsIsDirty = false;
  formGroup: UntypedFormGroup;

  constructor(
    private service: AccountDesignService,
    private infoService: InfoService,
    private dirtyCheckService: DirtyCheckService,
  ) {
  }

  getStyleSettings() {
    subscribeUntilDestroyed(
      this.service.getStyleSettings()
        .pipe(
          map(response => {
            // get all style settings and take only footer settings
            this.poweredBy = (response.acc.poweredBy) ? response.acc.poweredBy : 'Reflact AG';
            this.pendingPoweredBy = (response.acc.poweredBy) ? response.acc.poweredBy : 'Reflact AG';
            this.legalNotice = (response.acc.legalNotice) ? response.acc.legalNotice : '';
            this.pendingLegalNotice = (response.acc.legalNotice) ? response.acc.legalNotice : '';
            this.terms = (response.acc.terms) ? response.acc.terms : '';
            this.pendingTerms = (response.acc.terms) ? response.acc.terms : '';
            this.policies = (response.acc.policies) ? response.acc.policies : '';
            this.pendingPolicies = (response.acc.policies) ? response.acc.policies : '';
          }),
        ), this);
  }

  isLegalNoticeDirty(): void {
    this.legalNoticeIsDirty = this.legalNotice !== this.pendingLegalNotice;
    if ( this.legalNoticeIsDirty && !this.editedItems.includes('legalNotice') ) {
      this.editedItems.push('legalNotice');
    } else if ( !this.legalNoticeIsDirty && this.editedItems.includes('legalNotice') ) {
      remove(this.editedItems, (value) => value === 'legalNotice');
    }

    this.isSomethingDirty();
  }

  isPoliciesDirty(): void {
    this.policiesIsDirty = this.policies !== this.pendingPolicies;
    if ( this.policiesIsDirty && !this.editedItems.includes('policies') ) {
      this.editedItems.push('policies');
    } else if ( !this.policiesIsDirty && this.editedItems.includes('policies') ) {
      remove(this.editedItems, (value) => value === 'policies');
    }

    this.isSomethingDirty();
  }

  isPoweredByDirty(): void {
    this.poweredByIsDirty = this.poweredBy !== this.pendingPoweredBy;
    if ( this.poweredByIsDirty && !this.editedItems.includes('poweredBy') ) {
      this.editedItems.push('poweredBy');
    } else if ( !this.poweredByIsDirty && this.editedItems.includes('poweredBy') ) {
      remove(this.editedItems, (value) => value === 'poweredBy');
    }

    this.isSomethingDirty();
  }

  isSomethingDirty(): void {
    // check if something is dirty and push the new state to dirtyCheckService
    console.log('isSomethingDirty', this.editedItems);
    const isDirty = this.editedItems.length > 0;
    this.dirtyCheckService.submitNextState('FooterConfComponent', isDirty);
  }

  isTermsDirty(): void {
    this.termsIsDirty = this.terms !== this.pendingTerms;
    if ( this.termsIsDirty && !this.editedItems.includes('terms') ) {
      this.editedItems.push('terms');
    } else if ( !this.termsIsDirty && this.editedItems.includes('terms') ) {
      remove(this.editedItems, (value) => value === 'terms');
    }

    this.isSomethingDirty();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.getStyleSettings();
    this.formGroup = new UntypedFormGroup({
      legalNoticeField: new UntypedFormControl(),
      termsField: new UntypedFormControl(),
      policiesField: new UntypedFormControl()
    });
  }

  postStyleSettings(modified: string, value: string | number) {
    const newPoweredBy = (modified === 'poweredBy') ? value : this.poweredBy;
    const newLegalNotice = (modified === 'legalNotice') ? value : this.legalNotice;
    const newTerms = (modified === 'terms') ? value : this.terms;
    const newPolicies = (modified === 'policies') ? value : this.policies;

    const styleInfo = {
      poweredBy: newPoweredBy,
      legalNotice: newLegalNotice,
      terms: newTerms,
      policies: newPolicies,
    };

    this.service.uploadStyleSettings(styleInfo).subscribe(
      () => {
        // reload new settings and reset values
        this.getStyleSettings();

        // update reference values
        if ( modified === 'poweredBy' ) {
          this.poweredBy = cloneDeep(this.pendingPoweredBy);
          this.isPoweredByDirty();
        }

        if ( modified === 'legalNotice' ) {
          this.legalNotice = cloneDeep(this.pendingLegalNotice);
          this.isLegalNoticeDirty();
        }

        if ( modified === 'terms' ) {
          this.terms = cloneDeep(this.pendingTerms);
          this.isTermsDirty();
        }

        if ( modified === 'policies' ) {
          this.policies = cloneDeep(this.pendingPolicies);
          this.isPoliciesDirty();
        }

        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_SAFED, InfoType.Success);
      }, () => {
        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_NOT_SAFED, InfoType.Error);
      });
  }
  showSaveButton(link: string, isDirty: boolean): boolean {
    if(link === this.pendingLegalNotice && this.formGroup.controls.legalNoticeField.invalid) {
    return false;
    }
    if(link === this.pendingTerms && this.formGroup.controls.termsField.invalid) {
      return false;
    }
    if(link === this.pendingPolicies && this.formGroup.controls.policiesField.invalid) {
      return false;
    }
    if(link === '' && isDirty) {
      return true;
    }
    if (link == null) {
      return false;
    }
    const linkTrim = link.trim();
    if (linkTrim === '') {
      return  false;
    }
    if (isDirty) {
      return true;
    }

    return false;
  }

}
