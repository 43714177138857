import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Content } from '../../../core/content/content.types';

export interface DialogData {
  content: Content;
}

@Component({
  selector: 'rag-curriculum-info-button-dialog',
  templateUrl: './content-info-button-dialog.component.html',
  styleUrls: [ './content-info-button-dialog.component.scss' ],
})
export class ContentInfoButtonDialogComponent
  implements OnInit {
  content: Content;

  constructor(public dialogRef: MatDialogRef<ContentInfoButtonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    if ( this.data.content ) {
      this.content = this.data.content;
    } else {
      this.dialogRef.close();
    }
  }

}
