import { Component, Input } from '@angular/core';
import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';
import { TableColumnMenuComponent } from '../../../../../component/table/table-column-menu/table-column-menu.component';

@Component({
  selector: 'rag-licence-group-column-header',
  templateUrl: './licence-group-column-header.component.html',
  styleUrls: [ './licence-group-column-header.component.scss' ],
})
export class LicenceGroupColumnHeaderComponent {

  @Input() canNotRemove = true;
  @Input() column: TableControllerTypes.ColumnMenuItem;
  @Input() menu: TableColumnMenuComponent;
  isColumnRemoved = false;

  constructor() {
  }

  deselectColumn(): void {
    this.isColumnRemoved = true;
    this.menu.columnToggled(this.column, false);
  }

}
