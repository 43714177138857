import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DisplayStatus } from '../../../core/display-status.enum';
import { Core, Translateable } from '../../../core/core.types';
import { LanguageHelper } from 'src/app/core/language.helper';


@Component({
  selector: 'rag-content-page-title',
  templateUrl: './content-page-title.component.html',
  styleUrls: [ './content-page-title.component.scss' ],
})
export class ContentPageTitleComponent
  implements OnChanges {

  @Input() backUrl: string;
  @Input() displayStatus: DisplayStatus;
  showStatus = false;
  @Input() statusTargetType: Core.DistributableType | string;
  @Input() typeOfTraining: string;

  private _title: string;

  constructor() {
  }

  @Input()
  set title(_title: string | Translateable) {
    if (typeof _title === 'string') {
      this._title = _title;
      return;
    }
    this._title = LanguageHelper.objectToText(_title);
  }

  get title() {
    return this._title;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showStatus = this.displayStatus != null;
  }

}
