<div mat-dialog-title class="header">
  <button mat-mini-fab
          color="warn"
          [mat-dialog-close]="true">
    <mat-icon svgIcon="close-thick"></mat-icon>
  </button>
</div>

<div mat-dialog-content>

  <ng-template #noEventSchedule>
    <rag-content-offline-choose
      [offlineContent]="data.offlineContent"
      [offlineEventSchedules]="data.offlineEvents"
      [offlineEventAccountViews]="data.offlineEventAccountViews"
    ></rag-content-offline-choose>
  </ng-template>

  <rag-content-offline-view
    *ngIf="(data.offlineEvents?.length === 1); else noEventSchedule"
    [offlineContent]="data.offlineContent"
    [offlineEvents]="data.offlineEvents"
    [offlineEventAccounts]="data.offlineEventAccountViews"
  ></rag-content-offline-view>
</div>
