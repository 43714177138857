import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextMacro } from '../../route/admin/admin-text-macros/admin-text-macros.types';
import { AdminSignaturesUsageDialogComponent } from '../../route/admin/admin-signatures/admin-signatures-usage-dialog/admin-signatures-usage-dialog.component';
import { UsageDetails, UsageDialogData } from '../../route/admin/admin-signatures/admin-signatures.types';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { ModalDialog } from '../../core/modal-dialog';
import { CKEditorDefaults } from '../../core/ckeditor.types';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';
import { MergeHelper } from '../../core/primitives/merge.helper';
import { AdminTextMacrosService } from '../../route/admin/admin-text-macros/admin-text-macros.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { isNothing } from '../../core/utils';
import { EMPTY } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  AdminSignaturesInputMacroComponent
} from '../../route/admin/admin-signatures/admin-signatures-input-macro/admin-signatures-input-macro.component';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    CommonModule,
    MatOptionModule,
    MatTooltipModule,
    CKEditorModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    AdminSignaturesInputMacroComponent,
  ],
  selector: 'rag-text-macros',
  templateUrl: './text-macros.component.html',
  styleUrls: ['./text-macros.component.scss']
})
export class TextMacrosComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup;
  @Input() usage: UsageDetails[] = [];
  @Input() data: TextMacro;
  @Input() isTextMacro = false;
  @Input() showType = true;
  @Input() currentMacro = '';
  Editor = Editor;
  usageCount = 0;

  readonly ckOptions = MergeHelper.cloneDeep(CKEditorDefaults.CKEDITOR_OPTIONS);

  constructor(
    private dialog: ModalDialog,
  ) {
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.usageCount = this.usage?.length;
  }

  getCountTooltip(): string {
    return this.isTextMacro ?
      $localize`:@@admin_text_macros_count_tooltip:Text macro is used in ${this.usageCount ?? 0} place(s).` :
      $localize`:@@admin_signatures_count_tooltip:Signature is used in ${this.usageCount ?? 0} place(s).`;
  }

  onOpenUsageDialog(): void {
    if (this.usageCount === 0) {
      return;
    }
    this.dialog.openModal<AdminSignaturesUsageDialogComponent,
      UsageDialogData>(AdminSignaturesUsageDialogComponent, {
      data: {
        isTextMacro: this.isTextMacro,
        usage: this.usage,
      },
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe();
  }

}
