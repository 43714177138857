import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrincipalService } from '../../../../../core/principal/principal.service';
import { AttachmentsSettingsFunction, MailComposerData, MailEntry, ReportSettingsFunction, TimeSettingsFunction } from '../../../../../core/mail.types';
import { ReportsSettingsComponent } from '../reports-settings/reports-settings.component';

@Component({
  selector: 'rag-mail-settings',
  templateUrl: './mail-settings.component.html',
  styleUrls: [ './mail-settings.component.scss' ],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '160px',
        'border-top': '1px solid #c1c1c1',
      })),
      state('closed', style({
        height: '0px',
      })),
      transition('open => closed', [
        animate('0.2s ease-in-out'),
      ]),
      transition('closed => open', [
        animate('0.2s ease-in-out'),
      ]),
    ]),
  ],
})
export class MailSettingsComponent {

  @Input() mailEntry?: MailEntry;
  @Output() composerData: EventEmitter<MailComposerData>;
  @Output() readonly validChanged: Observable<boolean>;

  @ViewChild('ragReportsSettings')
  ragReportsSettings: ReportsSettingsComponent;
  canPlanMessages$: Observable<boolean>;
  canSendReports$: Observable<boolean>;
  iconName = 'chevron-up';
  isOpen = false;
  selectedIndex = 0;

  private _timeSettingsValid = true;
  private _validChanged = new EventEmitter<boolean>(true);
  private mailComposerData = new MailComposerData();

  constructor(
    private principalService: PrincipalService,
  ) {
    this.composerData = new EventEmitter<MailComposerData>();
    this.validChanged = this._validChanged.asObservable();
    this.canPlanMessages$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesPlanned));
    this.canSendReports$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesSendReport));
  }

  onSelectedIndexChanged($event) {
    if ( $event === 2 ) {
      this.ragReportsSettings.refresh();
    }
  }

  onToggleClick($event) {
    this.isOpen = !this.isOpen;
    this.iconName = this.isOpen ? 'chevron-down' : 'chevron-up';
  }

  setAttachmentSettings: AttachmentsSettingsFunction = (attachments) => {
    this.mailComposerData.applyAttachmentsSettings(attachments);
    this.composerData.emit(this.mailComposerData);
    this.checkValidState();
  };

  setReportsSettings: ReportSettingsFunction = (workingContext, reports) => {
    this.mailComposerData.applyReportsSettings(workingContext, reports);
    this.composerData.emit(this.mailComposerData);
    this.checkValidState();
  };

  setTimeSettings: TimeSettingsFunction = (timeSettings) => {
    this._timeSettingsValid = timeSettings?.isValid() ?? true;
    if ( this._timeSettingsValid ) {
      // timesettings are valid or have been removed
      this.mailComposerData.applyTimeSettings(timeSettings);
      this.composerData.emit(this.mailComposerData);
    }
    this.checkValidState();
  };

  private checkValidState(): void {
    this._validChanged.next(this._timeSettingsValid);
  }

}
