<!-- Retrieve email address from user -->
<rag-dialog-header>
  <span i18n="@@enter_email_address">E-mail confirmation</span>
</rag-dialog-header>

<mat-dialog-content>

  <p class="hint">
    <span i18n="@@dbl_op_in_enter_email">An e-mail containing a link will be sent to this e-mail address. Use the link to proceed with the registration.</span>
  </p>

  <form
    class="form"
    [formGroup]="form">

    <mat-form-field class="error-placeholder" appearance="outline">
      <mat-label>
        <span i18n="@@global_email">Email</span>
      </mat-label>

      <input
        [formControlName]="'email'"
        matInput
        placeholder="Email"
        i18n-placeholder="@@global_email"
        required
        type="email"
        name="login"
      />

        <mat-error>
          <span
            displayOne
            *ngIf="hasError('email', 'required')"
            i18n="@@validation_required">This field is required</span>
          <span
            displayOne
            *ngIf="hasError('email', 'valid')"
            i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
          <span
            displayOne
            *ngIf="hasError('email', 'match')"
            i18n="@@valid_no_match" >The values do not match.</span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="error-placeholder" appearance="outline">
      <mat-label i18n="@@register_email_confirm">Confirm e-mail address</mat-label>

      <input
        [formControlName]="'email2'"
        matInput
        placeholder="Confirm e-mail address"
        i18n-placeholder="@@register_email_confirm"
        required
        type="email"
        autocomplete="off"
      />

        <mat-error>
          <span
            displayOne
            *ngIf="hasError('email2', 'required')"
            i18n="@@validation_required">This field is required</span>
          <span
            displayOne
            *ngIf="hasError('email2', 'valid')"
            i18n="@@validation_email_not_valid">Please enter a valid email address.</span>
          <span
            displayOne
            *ngIf="hasError('email2', 'match')"
            i18n="@@valid_no_match" >The values do not match.</span>
        </mat-error>
    </mat-form-field>

  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button type="button" [mat-dialog-close]="null">
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    cdkFocusInitial
    color="primary"
    mat-flat-button
    type="button"
    [disabled]="form.pristine || form.invalid"
    [mat-dialog-close]="result">
    <span i18n="@@global_register">Register</span>
  </button>
</mat-dialog-actions>
