<div class="container row">

  <ng-container *ngIf="!embedded; else embeddedTemplate">
    <!-- Status label -->
    <div *ngIf="invitationStatusShow" class="row section one">

      <label class="label">
        <span i18n="@@global_status">Status</span>:
      </label>

      <div
        [ngSwitch]="invitationStatusValue"
        class="row invitation">

        <div *ngSwitchCase="'inv'" class="row">
          <mat-icon svgIcon="email-outline"></mat-icon>
          <span
            class="status"
            i18n="@@content_offline_invitation_invited">Invited</span>
        </div>

        <div *ngSwitchCase="'rem'" class="row">
          <mat-icon svgIcon="email-alert-outline"></mat-icon>
          <span
            class="status"
            i18n="@@content_offline_invitation_reminded">Reminded</span>
        </div>

        <div *ngSwitchCase="'ack'" class="row">
          <mat-icon svgIcon="check-outline"></mat-icon>
          <span
            class="status"
            i18n="@@content_offline_invitation_confirmed">Confirmed</span>
        </div>

        <div *ngSwitchCase="'dec'" class="row">
          <mat-icon svgIcon="thumb-down-outline"></mat-icon>
          <span
            class="status"
            i18n="@@content_offline_invitation_declined">Declined</span>
        </div>

        <div *ngSwitchCase="'can'" class="row">
          <span
            class="status"
            i18n="@@content_offline_invitation_cancelled">Cancelled</span>
        </div>
      </div>
    </div>

    <!-- accept / decline buttons -->
    <div class="row section two">
      <ng-container
        *ngIf="canChangeStatus && confirmDeclineShow"
      >

        <span
          *ngIf="showResponseRequired"
          i18n="@@content_offline_invitation_please_respond">Please either confirm or decline the invitation:</span>

        <mat-button-toggle-group
          (valueChange)="doChangeConfirmValue($event)"
          [(ngModel)]="confirmDeclineValue"
          [disabled]="disableChange"
        >

          <mat-button-toggle value="ack">
            <span i18n="@@global_confirm">Confirm</span>
          </mat-button-toggle>

          <mat-button-toggle value="dec">
            <span i18n="@@global_decline">Decline</span>
          </mat-button-toggle>

        </mat-button-toggle-group>

      </ng-container>
    </div>

    <!-- action menu -->
    <div *ngIf="showEventActionMenu" class="row section three">
      <button [matMenuTriggerFor]="contextMenu" mat-icon-button>
        <mat-icon svgIcon="menu"></mat-icon>
      </button>

      <mat-menu #contextMenu="matMenu">
        <button
          (click)="doDownloadEvent()"
          *ngIf="showEventIcsExport"
          mat-menu-item
        >
          <mat-icon svgIcon="calendar-outline"></mat-icon>
          <span i18n="@@content_offline_invitation_download_ics">Add to calendar</span>
        </button>

        <button
          (click)="doCancelRequest()"
          *ngIf="canCancelRequest && showCancelRequest"
          [disabled]="disableChange"
          class="cancel-request"
          mat-menu-item
        >
          <mat-icon svgIcon="cancel"></mat-icon>
          <span i18n="@@content_offline_invitation_cancel_request">Cancel request</span>
        </button>
      </mat-menu>
    </div>
  </ng-container>
</div>

<!-- Template to display when there is available information about this event -->
<ng-template #embeddedTemplate>
  <div>
    <!-- no event schedules to select from -->
    <span
      i18n="@@content_offline_no_event_schedules"
      class="no-events"
      *ngIf="noEventsYet()">Event schedules not defined yet</span>
    <!-- There are event schedules to select from -->
    <button
      mat-flat-button
      *ngIf="isBookingPossible()"
      [routerLink]="urlForOfflineContent()"
      color="primary">
      <span i18n="@@learner_offline_event_book_event">Book event</span>
    </button>
    <!-- the user has an event schedule assigen -->
    <ng-container *ngIf="shouldDisplayEvents()">
      <ng-container *ngIf="isSingleDay(); else multipleDays">
        <span i18n="@@global_at">At</span>
        {{atDay()}}
        <span i18n="@@global_from">from</span>
        {{atTime()}}
        <span i18n="@@global_to">to</span>
        {{atEndTime()}}
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #multipleDays>
  <span i18n="@@global_from">from</span>
  {{atDay()}}
  {{atTime()}}
  <span i18n="@@global_to">to</span>
  {{atEndDay()}}
  {{atEndTime()}}
</ng-template>
