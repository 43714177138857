import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRedirectComponent } from './login-redirect.component';
import { LoginFormModule } from '../../../../component/login/login-form/login-form.module';
import { LoadingIndicatorComponent } from 'src/app/component/loading-indicator/loading-indicator.component';


@NgModule({
  declarations: [ LoginRedirectComponent ],
  exports: [ LoginRedirectComponent ],
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    LoginFormModule,
  ],
})
export class LoginRedirectModule {
}
