import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DirtyCheckService } from '../../../core/dirty-check.service';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { take, tap } from 'rxjs/operators';
import { InputTypes } from '../../../component/input/input.types';
import { AdminScriptService } from './admin-script.service';
import { ActivatedRoute } from '@angular/router';
import { PrincipalService } from '../../../core/principal/principal.service';


@Component({
  selector: 'rag-admin-script',
  templateUrl: './admin-script.component.html',
  styleUrls: [ './admin-script.component.scss' ],
})
export class AdminScriptComponent
  implements OnDestroy {

  formGroup: UntypedFormGroup;
  isDirty = false;
  private _accountScriptValue: InputTypes.ValueState<string> = {
    originalValue: '',
  };

  constructor(
    private adminScriptService: AdminScriptService,
    private dirtyCheckService: DirtyCheckService,
    private formBuilder: UntypedFormBuilder,
    private principalService: PrincipalService,
    private route: ActivatedRoute,
  ) {
    subscribeUntilDestroyed(this.route.data
      .pipe(tap(data => this.accountScript = data?.accountScript)), this);
  }

  set accountScript(value: string) {
    this._accountScriptValue.currentValue = value;
    this._accountScriptValue.originalValue = value;

    this.buildForm();
    this.formGroup.get('accountScript')
      .setValue(value, { emitEvent: false });
    this.isDirty = false;
    this.dirtyCheckService.submitNextState('AdminScriptComponent', this.isDirty);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onSave(): void {
    this.adminScriptService.saveAccountScript(this._accountScriptValue.currentValue)
      .pipe(take(1))
      .pipe(tap(this.cleanDirty))
      .subscribe();
  }

  private buildForm(): void {
    if ( this.formGroup != null ) {
      return;
    }

    this.formGroup = this.formBuilder.group({
      accountScript: [ this._accountScriptValue.originalValue, [] ],
    });

    subscribeUntilDestroyed(this.formGroup.get('accountScript').valueChanges
      .pipe(tap(value => {
        this._accountScriptValue.currentValue = value;
        this.isDirty = this._accountScriptValue.currentValue !== this._accountScriptValue.originalValue;
        this.dirtyCheckService.submitNextState('AdminScriptComponent', this.isDirty);
      })), this);
  }

  private cleanDirty = (): void => {
    this.accountScript = this._accountScriptValue.currentValue;
  };

}
