import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { CertificatesResolverService } from 'src/app/core/resolvers/certificates-resolver.service';
import { CertificatesComponent } from './certificates.component';

const routes: Routes = [
  {
    path: 'my-certificates',
    component: CertificatesComponent,
    canActivate: [ AuthGuard ],
    resolve: { certificates: CertificatesResolverService },
    data: {
      checkPermission: 'navUserCertificates',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ContentOverviewRoutingModule {
}
