import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
  ],
  selector: 'rag-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: [ './loading-page.component.scss' ],
})
export class LoadingPageComponent
  implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
