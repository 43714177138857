import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableGroupingValueComponent } from './report-table-grouping-value.component';
import { ColoredDateModule } from '../../../../../../component/elearning/colored-date/colored-date.module';
import { PipesModule } from '../../../../../../core/pipes/pipes.module';
import { StatusLightsModule } from '../../../../../../component/elearning/status-lights/status-lights.module';
import { StatusStatisticsModule } from '../../../report-generator/status-statistics/status-statistics.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TablesColumnRenderDefaultModule } from '../../../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { SpacerComponent } from 'src/app/component/spacer/spacer.component';


@NgModule({
  declarations: [ ReportTableGroupingValueComponent ],
  exports: [ ReportTableGroupingValueComponent ],
  imports: [
    CommonModule,
    ColoredDateModule,
    PipesModule,
    StatusLightsModule,
    StatusStatisticsModule,
    SpacerComponent,
    MatIconModule,
    MatButtonModule,
    TablesColumnRenderDefaultModule,
  ],
})
export class ReportTableGroupingValueModule {
}
