<div class="container">
  <div *ngIf="userIsAuthorized$ | async; else notAuthorized" class="contents">
    <rag-certificates
      #certificatesOverview
      [didReceiveResponse]="onDidReceivedResponse"
      [embedded]="true"
      [maxItems]="maxItems | async"></rag-certificates>
  </div>

  <div *ngIf="receivedResponse && userIsAuthorized$ | async" class="actions">
    <button
      (click)="downloadPdfCertificates()"
      *ngIf="hasContent && !isBoxViewMode"
      [disabled]="!this.certificatesOverview.selection.hasValue()"
      class="downloadButton"
      color="primary"
      mat-button>

      <mat-icon svgIcon="download"></mat-icon>
      <span i18n="@@download">Download</span>
    </button>

    <div class="spacer"></div>

    <a [queryParams]="{isValid: 'newest', es: 'title', ts: ''}" color="primary"
       mat-flat-button
       routerLink="/my-certificates"
    >
      <span i18n="@@more_certificates">My certificates</span>
    </a>
  </div>
</div>

<!-- The user is not authorized to access this content -->
<ng-template #notAuthorized>
  <div class="not-authorized">
        <span i18n="@@widget_mycertificates_not_authorized">
            You are not authorized to view this content.
            Please, contact the system administrator for further information.
        </span>
  </div>
</ng-template>

<!-- Custom menu item -->
<ng-template #viewMode>
  <button (click)="toggleViewMode()" mat-button>
    <mat-icon [svgIcon]="isBoxViewMode ? 'view-list' : 'view-module'" color="primary"></mat-icon>
    <span *ngIf="!isBoxViewMode" i18n="@@overview_cards_view">Cards view</span>
    <span *ngIf="isBoxViewMode" i18n="@@global_list_view">List view</span>
  </button>
</ng-template>
