<ng-container *ngIf="(startPage$ | async) as startPage">
  <ng-container [ngSwitch]="startPage.acc.showNewLogin">
    <ng-container *ngSwitchCase="true">
      <rag-login-v2></rag-login-v2>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <rag-login></rag-login>
    </ng-container>
  </ng-container>
</ng-container>
