<div class="report-settings">
  <div class="select">
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label i18n="@@reports_settings_report_context">Report context</mat-label>

      <mat-select [(value)]="selectedReportContext">
        <mat-option [value]="'creator'">
          <span i18n="@@creator">Creator</span>
        </mat-option>
        <mat-option [value]="'receiver'">
          <span i18n="@@receiver">Receiver</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="auto" appearance="outline">
      <mat-label i18n="@@reports_settings_reports">Attach report</mat-label>

      <mat-select [(value)]="selectedReportId" [disabled]="!selectedReportContext" [panelWidth]="null">
        <mat-option *ngFor="let report of reports" [value]="report.id">
          {{report.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="addReport()" [disabled]="isAddButtonDisabled()" color="primary"
            mat-stroked-button>
      <mat-icon svgIcon="plus"></mat-icon>
    </button>
  </div>
  <div class="selected">
    <div class="selected-container">
      <mat-chip-listbox>
        <mat-chip-option (removed)="removeReport(report)"
                  *ngFor="let report of selectedReports"
                  [color]="'primary'"
                  [removable]="true"
                  [selectable]="false">
          {{report.title}}
          <mat-icon matChipRemove svgIcon="close-circle"></mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
</div>
