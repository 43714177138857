import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderFilterDefaultComponent } from './table-header-filter-default.component';
import { PivotFilterModule } from '../../pivot-filter/pivot-filter.module';
import { RagVarModule } from '../../../core/rag-var/rag-var.module';
import { PivotFilterTagsModule } from '../../pivot-filter/pivot-filter-tags/pivot-filter-tags.module';


@NgModule({
  declarations: [ TableHeaderFilterDefaultComponent ],
  imports: [
    CommonModule,
    PivotFilterModule,
    RagVarModule,
    PivotFilterTagsModule,
  ],
  exports: [
    TableHeaderFilterDefaultComponent,
  ],
})
export class TableHeaderFilterDefaultModule {
}
