import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {PipesModule} from '../../core/pipes/pipes.module';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {NavItem, NavItemMenu, NavItemSpecial} from '../../core/navigation/navigation.types';
import {ApplicationStateService} from '../../core/application-state.service';
import {HeaderNavHelper} from '../header/header-nav.helper';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ContentAutocompleteModule} from '../elearning/content-autocomplete/content-autocomplete.module';
import {MergeHelper} from '../../core/primitives/merge.helper';
import {TopNavigationComponent} from '../top-navigation/top-navigation.component';
import {ContentAutocompleteV2Component} from '../elearning/content-autocomplete-v2/content-autocomplete-v2.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav[rag-main-navigation]',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    PipesModule,
    RouterLinkActive,
    RouterLink,
    MatTooltipModule,
    ContentAutocompleteV2Component,
    TopNavigationComponent
  ],
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {

  @Input() navItems: NavItem[];
  @Input() topItems: NavItem[];
  @Input() layer: number;
  @Input() onlyIcons: boolean = false;
  @Input() showContentSearch: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() backButtonVisible = false;
  @Input() showUsername = false;
  login: NavItem;

  constructor(
    private appState: ApplicationStateService,
    public headerNavHelper: HeaderNavHelper,
  ) {
  }
  //
  // get navItemsForList(): NavItem[] {
  //   if (this.navItems == null || this.topItems == null) {
  //     return;
  //   }
  //   if (false) {
  //     return this.navItems;
  //   }
  //   this.navItems.push(...this.topItems);
  //   return this.navItems;
  //   // return MergeHelper.mergeDeep
  // }

  ngOnInit() {
    this.login = this.navItems?.find(item => item.special === NavItemSpecial.loginName);
  }

  onMenuOpen(menu: NavItemMenu): void {
    if (this.onlyIcons) {
      this.appState.toggleSidenav();
    }
    if (this.layer === 0) {
      this.appState.clearMenuItemStack();
      this.appState.addToMenuItemStack(this.navItems);
    }
    this.appState.addToMenuItemStack(menu.children);
  }

  showLastMenu(): void {
    this.appState.popLastMenuItemStackEntry();
  }
}
