import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegisterDataPreviewData } from './register-data-preview.types';
import { Core } from '../../../../core/core.types';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';


@Component({
  selector: 'rag-register-data-preview',
  templateUrl: './register-data-preview.component.html',
  styleUrls: [ './register-data-preview.component.scss' ],
})
export class RegisterDataPreviewComponent {

  fields: Core.KeyValue<string>[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RegisterDataPreviewData,
  ) {
    this.fields = RegisterDataPreviewComponent.asFields(data);
  }

  static asFields(
    data: RegisterDataPreviewData | null,
  ): Core.KeyValue<string>[] {

    const modelFields = data?.model ?? {};
    return Object.entries(data?.data ?? {})
      .map(([ fieldName, field ]) => {

        let value = field.value;
        const attribute = modelFields[fieldName];
        const options = attribute?.options;
        if ( Array.isArray(options) && (attribute.type === ProfileFieldTypes.ProfileFieldType.dropdown) ) {
          // legacy code -> will now be handled by SelfRegisterFormComponent
          const optionValue = (options as Array<{ label: string; value: string }>)
            .find(option => option.value === value);
          if ( optionValue?.label != null ) {
            // show display value instead of actual value
            value = optionValue.label;
          }
        }

        return { key: field.title, value };
      });
  }

}
