import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../../core/api.urls';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../../core/global.types';
import { QAQuestion } from '../qa.types';
import { Core } from '../../../../core/core.types';
import DistributableType = Core.DistributableType;

@Injectable({
  providedIn: 'root',
})
export class QAService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllTagsForQA(): Observable<string> {
    const url = ApiUrls.getKey('GetAllTagsForType')
      .replace(/{type}/, DistributableType.qa);
    return this.http.get<ApiResponse<string>>(url)
      .pipe(map(response => response.tags));
  }

  getAllQAsForUser(): Observable<QAQuestion[]> {
    const url = ApiUrls.getKey('GetAllQAsForUser');

    return this.http.get<ApiResponse<QAQuestion[]>>(url)
      .pipe(map(data => data.questions));
  }

  sendNewQuestion(question): Observable<QAQuestion> {
    const url = ApiUrls.getKey('SendNewQA');

    return this.http.post<ApiResponse<QAQuestion>>(url, question)
      .pipe(map(response => response.question));
  }
}
