<div
  [attr.class]="iconType"
  [matTooltipDisabled]="hideToolTip"
  [matTooltip]="iconTooltip"
  matTooltipPosition="above"
>
  <svg class="traffic-light" height="24" viewBox="0 0 6.35 6.35" width="24" xmlns="http://www.w3.org/2000/svg">
    <g class="wrapper" fill="#b5b5b5" stroke-width=".47002" transform="translate(0 -290.65)">
      <circle class="light light-red" cx="3.175" cy="291.71" r=".79375"></circle>
      <circle class="light light-yellow" cx="3.175" cy="293.82" r=".79375"></circle>
      <circle class="light light-green" cx="3.175" cy="295.94" r=".79375"></circle>
    </g>
  </svg>

  <span *ngIf="displayHint" class="status-text">{{iconTooltip}}</span>
</div>
