<button
  mat-menu-item
  (click)="onClickInformation()"
  [disabled]="informationButtonDisabled()"
>
  <mat-icon svgIcon="information"></mat-icon>
  <span i18n="@@global_information">Information</span>
</button>


