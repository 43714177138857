<div class="mail-attachments">
  <div class="button">
    <input
      #fileInput
      (change)="onFileChanged($event)" style="display: none"
      type="file">
    <button
      (click)="fileInput.click()"
      color="primary"
      mat-stroked-button>
      <span i18n="Select file button|@@select_file">Select File</span>
    </button>
  </div>
  <div class="attachments">
    <div *ngFor="let file of files; let index = index" class="attachment">
      <div class="index">
        {{index + 1}}.
      </div>
      <div class="name">
        <span *ngIf="files[index]">{{files[index].name}}</span>
        <rag-mail-attachment-progress
          [value]="uploadProgress[file.name].progress$ | async"></rag-mail-attachment-progress>
      </div>
      <div class="size">
        <span *ngIf="files[index]">{{formatSize(files[index].size)}}</span>
      </div>
      <div class="delete">
        <button (click)="deleteByIndex(index)" color="warn" mat-icon-button>
          <mat-icon aria-label="remove attachment from list" svgIcon="delete-forever"></mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="files.length === 0" class="empty">
      <span i18n="@@no_attachments_selected">You have no selected attachments.</span>
    </div>
  </div>
</div>
