import { Component, Input, OnInit } from '@angular/core';
import { Statistics } from './status-statistics.types';

const STATUS_COURSE = [ 16, 4, 1 ];
const STATUS_CURRICULUM = [ 2, 7, 1 ];

@Component({
  selector: 'rag-status-statistics',
  templateUrl: './status-statistics.component.html',
  styleUrls: [ './status-statistics.component.scss' ],
})
export class StatusStatisticsComponent
  implements OnInit {

  @Input() showAsText = false;
  @Input() showTotal = false;
  @Input() statistics: Statistics;
  statusValues = STATUS_CURRICULUM;
  private _contentType: string;

  constructor() {
  }

  get contentType(): string {
    return this._contentType;
  }

  @Input()
  set contentType(value: string) {
    if ( value === 'Curriculum' ) {
      value = 'DisplayStatus';
    } else if ( value === 'Course' ) {
      value = 'CourseStatus';
    }
    this._contentType = value;
    if ( value === 'CourseStatus' ) {
      this.statusValues = STATUS_COURSE;
    } else {
      this.statusValues = STATUS_CURRICULUM;
    }
  }

  ngOnInit() {
  }

}
