import { from, Observable, of, throwError } from 'rxjs';
import * as localForage from 'localforage';
import { catchError, map, switchMap, tap } from 'rxjs/operators';


try {
  localForage.setItem('canary', 'tchilp!').then();
} catch ( e ) {
  console?.error(e);
}

export class StorageHelper {

  static get<T>(key: string, remove = false): Observable<T> {
    try {
      if ( remove !== true ) {

        return from(localForage.getItem<T>(key));
      } else {

        return from(localForage.getItem<T>(key))
          .pipe(switchMap(result => StorageHelper.remove(key)
            .pipe(catchError(() => of(result)))
            .pipe(map(() => result))));
      }
    } catch ( e ) {
      return StorageHelper.handleError(e);
    }
  }

  static remove(key: string): Observable<void> {
    try {
      return from(localForage.removeItem(key));
    } catch ( e ) {
      return StorageHelper.handleError(e);
    }
  }

  /**
   * @return Observable<T> returns an observable with the new value
   */
  static save<T>(key: string, value: T): Observable<T> {
    try {
      return from(localForage.setItem<T>(key, value))
        .pipe(catchError(() => of(value)))
        .pipe(map(() => value));
    } catch ( e ) {
      return StorageHelper.handleError(e);
    }
  }

  private static handleError<T>(e: T): Observable<T> {
    if ( console?.error != null ) {
      console.error(e);
    }
    return throwError(e);
  }

}
