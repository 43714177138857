import { MatDialogConfig } from "@angular/material/dialog";
import { AnyContent } from "../content/content.types";
import { AnyObject, Core, Identifiable } from "../core.types";

export namespace Quests {

  export const ComponentConfig: MatDialogConfig = {
    maxWidth: '1280px'
  };

  export interface QuestItem {
    uuid: string;
    item: AnyObject;
  }

  export type Type = 'lrn' | 'sup';

  export interface ContentSettings extends AnyContent {
    questUUID: string;
    timeSet: string;
  }

  export interface CtrlQuestEntry {
    questUUID: string;
    objId: number;
    objType: Core.DistributableType;
    userId: number;
    firstName: string;
    lastName: string;
    title: string;
    dueTo: number;
    whenSubmitted?: number;
    iteration: number;
  }

  export interface ConditionRelated {
    condition?: string;
  }

  export interface Option extends ConditionRelated {
    index: number;
    label: string;
    value: string;
  }

  export type OptionType = 'radio-matrix' | 'radio' | 'text' | 'paragraph' | 'checkbox';

  export interface Item extends ConditionRelated {
    uuid: string;
    text: string;
    required: boolean;
  }

  export interface Condition {
    hidden: boolean;
  }

  export interface Fragment extends Identifiable, ConditionRelated {
    type: OptionType;
    options?: Array<Option>;
    caption?: string;
    items?: Array<Item>;
  }

  export interface QuestBody {
    type: Type;
    conditions?: AnyObject<Condition>;
    fragments: Array<Fragment>
  }

  export interface Quest {
    uuid: string;
    adminTitle?: string;
    published: boolean;
    quest: QuestBody;
    type: Type;
  }

  export interface QuestFeedback {
    quest: Quest;
    feedback?: Quests.Feedback;
  }

  export interface CtrlQuestResponse extends QuestFeedback {
    ctrlQuest: CtrlQuestEntry;
    objType: Core.DistributableType;
    objId: number;
  }

  export interface Feedback {
    uuid: string;
    feedback: object;
    questUUID: string;
    targetUserId?: number;
    userId?: number;
    whenSubmitted: number;
    objType: Core.DistributableType;
    objId: number;
    iteration: number;
  }

  export interface Quest2ContentResponse {
    quests?: Array<Quests.Quest>;
    settings?: Array<Quests.ContentSettings>;
    feedbacks?: Array<Quests.Feedback>;
  }
}