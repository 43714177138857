import { Component } from '@angular/core';

@Component({
  selector: 'rag-account-design',
  templateUrl: './account-design.component.html',
  styleUrls: [ './account-design.component.scss' ],
})
export class AccountDesignComponent {
  constructor() {
  }
}
