import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { OAuth2ClientRegistration } from '../ext-oauth2.types';
import { Observable } from 'rxjs';
import { ExtOauth2Service } from '../ext-oauth2.service';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class ExtOauth2ListResolver
  implements Resolve<OAuth2ClientRegistration[]> {

  constructor(
    private extOAuth2Service: ExtOauth2Service,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<OAuth2ClientRegistration[]> {

    return this.extOAuth2Service.getClientRegistrations()
      .pipe(take(1));
  }

}
