import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AdminTextMacrosService } from '../admin-text-macros.service';
import { TextMacroDetailsResponse } from '../admin-text-macros.types';
import { AdminSignaturesService } from '../../admin-signatures/admin-signatures.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdminTextMacrosDetailsResolver {
  constructor(
    private adminTextMacrosService: AdminTextMacrosService,
    private adminSignaturesService: AdminSignaturesService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<TextMacroDetailsResponse> {
    const textMacroId = Number(route.paramMap.get('textMacroId'));

    if ( !(textMacroId > 0) ) {
      return of({
        textMacro: this.adminTextMacrosService.createNewTextMacro(),
        usages: [],
      });
    }
    return this.adminTextMacrosService.getTextMacroDetails(textMacroId);
  }
}
