<div class="container">

  <ng-template #staticMessage>
    <p *ngIf="errorAlreadyActivated" class="error">
      <span i18n="@@register_account_error_already_activated">This account has already been activated. Should you still not be able to log in, you can <a routerLink="/contact">contact our support</a>.</span>
    </p>
    <p *ngIf="hasResponse && !errorAlreadyActivated" class="all-is-done">
      <span i18n="@@register_account_is_activated">Your new account has been successfully activated. You can now use your credentials to login <a routerLink="/login">on the start page</a>.</span>
    </p>
  </ng-template>

  <rag-page-header>
    <div class="page-header-title">
      <span i18n="@@register_title">Register new account</span>
    </div>
  </rag-page-header>

  <p
    *ngIf="(errorMessage$ | async) as message; else staticMessage"
    class="error"
  >{{message}}</p>
</div>

