import { AnyObject, Core } from '../../../../../core/core.types';
import { LearnerAccountTypes } from '../../../../../core/learner-account/learner-account.types';
import { DisplayStatus } from '../../../../../core/display-status.enum';

export namespace CtrlEditDisplayStatusTypes {


  export interface StatusOptions {
    title: string;
    value: DisplayStatus;
  }

  export const STATUS_OPTIONS_FOR_TYPE: AnyObject<StatusOptions[]> = {
    Curriculum: [
      {
        value: DisplayStatus.CERTIFIED,
        title: $localize`:@@status_light_tooltip_certified:Certified`,
      },
      {
        value: DisplayStatus.NOT_CERTIFIED,
        title: $localize`:@@status_light_tooltip_notCertified:Not certified`,
      },
      {
        value: DisplayStatus.NOT_ATTEMPTED,
        title: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      },
    ],
    Default: [
      {
        value: DisplayStatus.COMPLETE,
        title: $localize`:@@status_light_tooltip_completeCourse:Completed`,
      },
      {
        value: DisplayStatus.INCOMPLETE,
        title: $localize`:@@status_light_tooltip_incomplete:Incomplete`,
      },
      {
        value: DisplayStatus.NOT_ATTEMPTED,
        title: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      },
    ],
    ScoDocument: [
      // only without confirmation!
      {
        value: DisplayStatus.COMPLETE,
        title: $localize`:@@status_light_tooltip_complete:Complete`,
      },
      {
        value: DisplayStatus.NOT_ATTEMPTED,
        title: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      },
    ],
    ScoDocumentWithReadConfirmation: [
      {
        value: DisplayStatus.COMPLETE,
        title: $localize`:@@status_light_tooltip_complete:Complete`,
      },
      {
        value: DisplayStatus.NOT_ATTEMPTED,
        title: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      },
      {
        value: DisplayStatus.INCOMPLETE,
        title: $localize`:@@status_light_tooltip_incomplete:Incomplete`,
      },
      {
        value: DisplayStatus.FAILED,
        title: $localize`:@@status_light_readAndSignWithQuestions:Read with questions`,
      },
    ],
    Quiz: [
      {
        value: DisplayStatus.PASSED,
        title: $localize`:@@status_light_tooltip_passed:Passed`,
      },
      {
        value: DisplayStatus.FAILED,
        title: $localize`:@@status_light_tooltip_failed:Failed`,
      },
      {
        value: DisplayStatus.NOT_ATTEMPTED,
        title: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      },
    ],
  };

  export interface DisplayStatusContainer {
    contentType: Core.DistributableType;
    courseConfirmActive?: boolean;
    courseType?: string | number | Core.CourseType;
  }

  export class Util {

    static getStatusForType(options: DisplayStatusContainer): StatusOptions[] {
      const contentType = options.contentType;
      switch ( contentType ) {
        case Core.DistributableType.lms_course:
          const courseType = LearnerAccountTypes.courseTypeFactory(options.courseType);
          if ( courseType === Core.CourseType.Test ) {
            return STATUS_OPTIONS_FOR_TYPE['Quiz'];
          } else if ( courseType === Core.CourseType.ScoDocument ) {
            if (options.courseConfirmActive) {
              return STATUS_OPTIONS_FOR_TYPE['ScoDocumentWithReadConfirmation'];
            }
            return STATUS_OPTIONS_FOR_TYPE['ScoDocument'];
          }
          break;
        case Core.DistributableType.lms_curriculum:
          return STATUS_OPTIONS_FOR_TYPE['Curriculum'];
      }

      return STATUS_OPTIONS_FOR_TYPE['Default'];
    }

  }

}
