import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from 'src/app/core/core.module';
import { ApiUrls } from '../../core/api.urls';
import { IconHelper } from '../../core/icon.helper';
import { ViewHelper } from '../../core/view-helper';
import { Attachment } from './attachments.types';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    CoreModule,
  ],
  selector: 'rag-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: [ './attachments.component.scss' ],
})
export class AttachmentsComponent {

  @Input() attachments: Attachment[] = [];

  constructor() {
  }

  getAttachmentUrl(attachment: Attachment) {
    const viewData = ViewHelper.getViewData(attachment);
    if ( viewData.attachmentDownloadUrl ) {
      return viewData.attachmentDownloadUrl;
    }

    if ( attachment.type === 'report' ) {
      return null;
    }

    let url;
    if ( attachment.uuid ) {
      if ( attachment.fileName ) {
        url = `${ApiUrls.getKey('Files')}/${attachment.uuid}/${attachment.fileName}`;
      } else {
        url = `${ApiUrls.getKey('Files')}/${attachment.uuid}`;
      }
    } else {
      url = `${ApiUrls.getKey('ShowFile')}/${attachment['fileId']}`;
    }

    return viewData.attachmentDownloadUrl = url;
  }

  iconForAttachmentType(mimeType: string): string {
    return IconHelper.svgIconForType(mimeType);
  }

}
