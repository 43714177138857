import { Component, Inject, OnDestroy } from '@angular/core';
import { ContentOfflineService } from '../../content/content-offline/content-offline.service';
import { LearnerAccountService } from '../../../../core/learner-account/learner-account.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { LiveAgendaDetails } from '../live-agenda.types';
import { LiveAgendaDetailsService } from './live-agenda-details.service';
import { LanguageHelper } from '../../../../core/language.helper';
import { FileInfo } from '../../../../core/core.types';
import { ExtLoginsService } from '../../../../core/ext-logins.service';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'rag-live-agenda-details-dialog',
  templateUrl: './live-agenda-details-dialog.component.html',
  styleUrls: [ './live-agenda-details-dialog.component.scss' ],
})
export class LiveAgendaDetailsDialogComponent
  implements OnDestroy {

  description: string;
  trainers: string[] = [];
  attachments: Array<FileInfo>;
  eventDate: Moment;
  eventDateUntil: Moment;
  url: string;
  isLink: boolean;
  isRoom: boolean;
  isPreview: boolean;

  constructor(
    private contentOfflineService: ContentOfflineService,
    private learnerAccountService: LearnerAccountService,
    private liveAgendaDetailsService: LiveAgendaDetailsService,
    private extLoginsService: ExtLoginsService,
    @Inject(MAT_DIALOG_DATA) public data: LiveAgendaDetails,
  ) {
    this.updateRouteData(this.data);
    this.contentOfflineService.needsReload$
      .pipe(debounceTime(50))
      .pipe(switchMap(() => this.liveAgendaDetailsService
        .getLiveAgendaDetails(this.data.offlineContentId, this.data.offlineEventId)))
      .pipe(tap(this.updateRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.createUrl();

    this.eventDate = moment(this.data.offlineEvent.eventDate);
    this.eventDateUntil = moment(this.data.offlineEvent.eventDateUntil);
  }

  get isEnterRoomLocked() {
    return !this.extLoginsService.isEnterRoomAvailable(this.data.offlineEvent);
  }

  createUrl() {
    this.url = '';
    if ( this.data.offlineEvent.extLoginPath != null && this.data.offlineEvent.extLoginPath.length > 0 ) {
      this.isRoom = true;
    }
    if ( this.data.offlineEvent.extLogin?.url != null ) {
      this.isLink = true;
    }
    if ( this.isRoom || this.isLink ) {
      const path = this.data.offlineEvent.extLoginPath || this.data.offlineEvent.extLogin.url;
      this.extLoginsService
        .urlAsGuest(this.data.offlineEvent.extLogin, path)
        .pipe(take(1))
        .subscribe(url => {
          this.url = url;
      });
    }
  }

  onJoinEvent() {
    if (this.url == null || this.url === '') {
      return;
    }

    this.contentOfflineService
      .joinEvent(this.data.offlineContentId, this.data.offlineEventId, this.url, this.data.offlineEvent.eventDate);
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  private updateRouteData = (data: LiveAgendaDetails): void => {
    this.data = data;

    if (data.isPreview != null) {
      this.isPreview = data.isPreview;
    }

    const offlineEvent = data?.offlineEvent;
    this.description = LanguageHelper.objectToText(offlineEvent?.description);
    this.attachments = data?.offlineEvent?.attachments ?? [];
    for ( let i = 0; i < offlineEvent.trainers.length; i++ ) {

      if ( offlineEvent.trainers[i].academicTitle != null &&
            offlineEvent.trainers[i].academicTitle !== '-' ) {
        this.trainers[i] =
          offlineEvent.trainers[i].academicTitle + ' ' +
          offlineEvent.trainers[i].firstName + ' ' +
          offlineEvent.trainers[i].lastName;
      } else {
        this.trainers[i] =
          offlineEvent.trainers[i].firstName + ' ' +
          offlineEvent.trainers[i].lastName;
      }
    }
  };
}
