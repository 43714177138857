import { EventEmitter, Injectable } from '@angular/core';
import { AnyObject, Core, PrincipalType } from '../../core/core.types';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { CachedSubject } from '../../core/cached-subject';
import { AssignmentDialogTypes } from './assignment-dialog.types';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { InfoService } from '../../core/info/info.service';
import { InfoType, MessageKey, NoButton, YesButton, YesNoButtons } from '../../core/info/info.types';
import { ApiUrls } from '../../core/api.urls';
import { AssignmentDialogComponent } from './assignment-dialog.component';
import { TrainResponse } from '../../core/global.types';
import { NavigationStart, Router } from '@angular/router';
import { PermissionStates } from 'src/app/core/principal/permission.states';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { MergeHelper } from '../../core/primitives/merge.helper';


@Injectable({ providedIn: 'root' })
export class AssignmentDialogService {

  readonly assignmentsChanged: Observable<void>;
  private _assignmentObjects = new CachedSubject<AssignmentDialogTypes.AssignmentInfo[]>(null);
  private _assignmentsChanged = new EventEmitter<void>(true);
  private _loading = false;
  private _permissions: PermissionStates;

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
    private router: Router,
    private principalService: PrincipalService,
  ) {
    this.assignmentsChanged = this._assignmentsChanged.asObservable();

    // clear cache on navigation
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .pipe(tap(() => this._assignmentObjects.reset()))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => this._permissions = permissions))
      .subscribe();
  }

  private static getAssignmentsUrl(objectType: Core.DistributableType, objectId: number): string {
    switch ( objectType ) {
      case Core.DistributableType.lms_course:
      case Core.DistributableType.lms_offlineCnt:
      case Core.DistributableType.lms_curriculum:
        return ApiUrls.getKey('DistributionAssignments')
          .replace(/{objectType}/gi, objectType)
          .replace(/{objectId}/gi, String(objectId));
      case Core.DistributableType.lms_workflow:
      case Core.DistributableType.skilltarget:
      case Core.DistributableType.lms_offline_event:
      case Core.DistributableType.lms_vroom_group:
      case Core.DistributableType.lms_context:
      case Core.DistributableType.lms_cur_path:
      default:
        return null;
    }
  }

  updateAssignmentsForEventAndModule(objectId: number, moduleId?: number, typeFilter?: PrincipalType[],
  ): Observable<void> {
    if ( this._loading ) {
      // abort processing
      return EMPTY;
    }

    this._loading = true;
    return combineLatest([ this.getAssignmentObjects(), this.getAssignments(Core.DistributableType.lms_offlineCnt, objectId) ])
      .pipe(take(1))
      .pipe(tap(() => this._loading = false))
      .pipe(map(data => this.convertToDialogData(data, typeFilter)))
      .pipe(switchMap(this.showAssignmentDialog))
      .pipe(takeWhile(changes => (changes?.length > 0)))
      .pipe(switchMap(changes => this.updateAssignmentsQuery(Core.DistributableType.lms_offlineCnt, objectId, changes)))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(map(() => void (0)))
      .pipe(tap(() => this._assignmentsChanged.emit()));
  }

  updateAssignments(
    objectType: Core.DistributableType, objectId: number, typeFilter?: PrincipalType[],
  ): Observable<void> {
    if ( this._loading ) {
      // abort processing
      return EMPTY;
    }

    this._loading = true;
    return combineLatest([ this.getAssignmentObjects(), this.getAssignments(objectType, objectId) ])
      .pipe(take(1))
      .pipe(tap(() => this._loading = false))
      .pipe(map(data => this.convertToDialogData(data, typeFilter)))
      .pipe(switchMap(this.showAssignmentDialog))
      .pipe(takeWhile(changes => (changes?.length > 0)))
      .pipe(switchMap(changes => this.updateAssignmentsQuery(objectType, objectId, changes)))
      .pipe(tap(() => this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success)))
      .pipe(map(() => void (0)))
      .pipe(tap(() => this._assignmentsChanged.emit()));
  }

  getAssignments(objectType: Core.DistributableType, objectId: number): Observable<AssignmentDialogTypes.AssignmentInfo[]> {
    const url = AssignmentDialogService.getAssignmentsUrl(objectType, objectId);
    if ( url == null ) {
      return this.handleError();
    }
    return this.http.get<AnyObject<AssignmentDialogTypes.AssignmentInfo[]>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.assignments));
  }

  validateEmptyAssignments(
    objectType: Core.DistributableType, objectId: number, isPublished: boolean, isBlockEvent: boolean,
  ): Observable<void> {
    if ( !isPublished || !this._permissions.ctrlOfflineAssignment ) {
      // assignments are not allowed
      return EMPTY;
    }

    const allowedTypes = isBlockEvent ? [PrincipalType.user] :
      [PrincipalType.targetGroup, PrincipalType.user];
    return this.getAssignments(objectType, objectId)
      .pipe(take(1))
      .pipe(switchMap(assignments => {
        if ( assignments?.length > 0 ) {

          // active assignments automaticly decline the update dialog
          return of(NoButton);
        } else {

          return this.infoService
            .showMessage($localize`:@@offline_cnt_event_assignment_confirm:
              You have saved the event. Do you want to assign participants?`, {
              buttons: YesNoButtons,
              title: $localize`:@@global_confirm:Confirm`,
            });
        }
      }))
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(_ => this.updateAssignments(objectType, objectId, allowedTypes)))
      .pipe(catchError(this.handleError))
      .pipe(map(() => void (0)));

  }

  private convertToDialogData(
    [available, selected]: AssignmentDialogTypes.AssignmentInfo[][], typeFilter?: PrincipalType[],
  ): AssignmentDialogTypes.AssignmentDialogData<AssignmentDialogTypes.AssignmentInfo> {
    const singleType = (typeFilter?.length == 1);
    const availableEntries = (available ?? [])
      .map(o => this.convertToDialogEntry(o));
    return ({
      data: {
        available: (typeFilter?.length > 0) ? availableEntries
          .filter(o => typeFilter.includes(o.type)) : availableEntries,
        categories: MergeHelper.cloneDeep(AssignmentDialogTypes.ASSIGNMENT_DIALOG_CATEGORIES),
        selected: (selected ?? [])
          // do not filter by typeFilter to allow removing / preserving already assigned, invalid entries
          .map(o => this.convertToDialogEntry(o)),
      },
      enableAssignmentType: true,
      i18n: {
        available: singleType ? $localize`:@@assignment_dialog_service_dlg_available_single:Available entries` :
          $localize`:@@assignment_dialog_service_dlg_available:Available users and target groups`,
        search: singleType ? $localize`:@@assignment_dialog_service_dlg_search_single:Search in entries...` :
          $localize`:@@assignment_dialog_service_dlg_search:Search in users and target groups...`,
        selected: $localize`:@@assignment_dialog_service_dlg_selected:Currently assigned`,
        title: $localize`:@@global_assignment:Assignment`,
        tooManySelections: $localize`:@@assignment_dialog_service_dlg_too_many_selections:There are more entries selected than are allowed!`,
      },
    });
  }

  private convertToDialogEntry(
    info: AssignmentDialogTypes.AssignmentInfo,
  ): AssignmentDialogTypes.AssignmentEntry<AssignmentDialogTypes.AssignmentInfo> {
    return ({
      changed: false,
      selected: info.assignmentType != null,
      title: info.principalName,
      value: info,
      type: info.principalType,
    });
  }

  private getAssignmentObjects(reset = false): Observable<AssignmentDialogTypes.AssignmentInfo[]> {
    if ( reset ) {
      this._assignmentObjects.reset();
    }
    if ( this._assignmentObjects.queryStart() ) {
      const url = ApiUrls.getKey('DistributionAssignmentObjects');
      this.http.get<AnyObject<AssignmentDialogTypes.AssignmentInfo[]>>(url)
        .pipe(catchError(this.handleError))
        .pipe(map(response => response.assignmentObjects))
        .pipe(tap(this._assignmentObjects.next))
        .pipe(finalize(this._assignmentObjects.queryDone))
        .subscribe();
    }
    return this._assignmentObjects.withoutEmptyValuesWithInitial()
      // always create copy to prevent object pollution
      .pipe(map(assignmentObjects => MergeHelper.cloneDeep(assignmentObjects)));
  }

  private handleError = (): Observable<never> => {
    this._loading = false;
    this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
    return EMPTY;
  };

  private showAssignmentDialog = (data: AssignmentDialogTypes.AssignmentDialogData<AssignmentDialogTypes.AssignmentInfo>):
    Observable<AssignmentDialogTypes.AssignmentInfo[]> => this.infoService.showDialog<
      any,
      AssignmentDialogTypes.AssignmentDialogData<AssignmentDialogTypes.AssignmentInfo>,
      AssignmentDialogTypes.AssignmentDialogEntries<AssignmentDialogTypes.AssignmentInfo> >(AssignmentDialogComponent, data)
      .pipe(takeWhile(result => (result != null) && (result.available != null) && (result.selected != null)))
      .pipe(map(result => {
        result.available.forEach(element => delete element.$view);
        result.selected.forEach(element => delete element.$view);
        return [
          ...result.selected
            .filter(obj => obj.changed)
            .map(obj => obj.value),
          ...result.available
            .filter(obj => obj.changed)
            .map(obj => obj.value),
        ];
      }));

  private updateAssignmentsQuery(objectType: Core.DistributableType, objectId: number,
                                 changes: AssignmentDialogTypes.AssignmentInfo[]): Observable<unknown> {
    const url = AssignmentDialogService.getAssignmentsUrl(objectType, objectId);
    if ( url == null ) {
      return this.handleError();
    }

    return this.http.post<TrainResponse>(url, changes)
      .pipe(catchError(this.handleError));
  }

}
