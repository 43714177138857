import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';


@Component({
  selector: 'rag-profile-field-password',
  templateUrl: './profile-field-password.component.html',
  styleUrls: [ './profile-field-password.component.scss' ],
})
export class ProfileFieldPasswordComponent
  extends AbstractProfileFieldComponent<string> {

  @Input() showGeneratePassword = false;

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

}
