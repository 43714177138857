export class AdminUsersHelper {

  public static genderFromOptionValue(
    value: string | number | null,
  ): number {
    switch ( value ) {

      case '3':
      case 'divers':
        return 3;

      case '1':
      case 'female':
        return 1;

      case '2':
      case 'male':
        return 2;

      default:
      case '4':
      case 'unknown':
        return 4;
    }
  }

  public static genderToOptionValue(
    value: string | number | null,
  ): string {

    value = `${value ?? ''}`;
    if ( !value ) {
      return 'unknown';

    } else if ( (value === '3') || value.startsWith('d') ) {
      return 'divers';

    } else if ( (value === '1') || value.startsWith('f') || value.startsWith('w') ) {
      return 'female';

    } else if ( (value === '2') || value.startsWith('m') ) {
      return 'male';

    } else {
      return 'unknown';
    }
  }

  public static optionValueFromGender(
    value: string | number | null,
  ): string {
    switch ( value ) {

      case '3':
      case 'divers':
        return 'divers';

      case '1':
      case 'female':
        return 'female';

      case '2':
      case 'male':
        return 'male';

      default:
      case '4':
      case 'unknown':
        return 'unknown';
    }
  }

  public static sortGenderOptions(
    a: string | number | null,
    b: string | number | null,
  ): number {

    const valueA = AdminUsersHelper.genderFromOptionValue(a) ?? 0;
    const valueB = AdminUsersHelper.genderFromOptionValue(b) ?? 0;
    if ( valueA === valueB ) {
      return 0;
    }

    const GENDER_ORDER_INDICES = [ 5, 2, 1, 3, 4 ];
    return (GENDER_ORDER_INDICES[valueA] ?? 5) - (GENDER_ORDER_INDICES[valueB] ?? 5);
  }

}
