<ng-template *ngIf="_isLoading">
  <rag-loading-indicator
  ></rag-loading-indicator>
</ng-template>
<div class="sso-wrapper">
  <ng-container *ngIf="oAuth2LoginMethods.length <= 2">
    <button
      *ngFor="let method of oAuth2LoginMethods"
      mat-flat-button
      class="sso-button"
      (click)="onLoginWithSSO(method)"
    >
      <mat-icon svgIcon="account-outline"></mat-icon>
      <span>{{ method.name | localeText }}</span>
    </button>
  </ng-container>

  <ng-container *ngIf="oAuth2LoginMethods.length > 2">
      <mat-select class="select" placeholder="SSO Logins" i18n-placeholder="@@sso_logins">
        <mat-option *ngFor="let method of oAuth2LoginMethods" (click)="onLoginWithSSO(method)">
          <mat-icon svgIcon="account-outline"></mat-icon>
          <span>{{method.name}}</span>
        </mat-option>
      </mat-select>
  </ng-container>
</div>
<form
  *ngIf="!_isLoading && (loginMethodsWithoutOAuth2.length > 0)"
  (submit)="onLoginWithCredentials()"
  [formGroup]="form"
  class="login-container"
>
  <mat-tab-group
    #credentialsLogin
    class="tab-group"
    *ngIf="loginMethodsWithoutOAuth2.length > 1"
  >
    <mat-tab *ngFor="let method of loginMethodsWithoutOAuth2">

      <ng-template mat-tab-label>
        <span class="tab-button-text"
          *ngIf="method.type === 'LDAP'"
          i18n="@@login_form_windows_login"
        >Windows Login</span>

        <span class="tab-button-text"
          *ngIf="method.type === 'Credentials'"
          i18n="@@login_form_normal_login"
        >Normal Login</span>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
      <mat-form-field class="error-placeholder" appearance="fill" class="input-login">
        <input
          required
          [formControlName]="'login'"
          matInput
          [placeholder]="getLoginPlaceholder()"
          type="text"
          name="login"
        />

        <button
          #matTooltip="matTooltip"
          (click)="matTooltip.toggle(); $event.stopPropagation()"
          type="button"
          matSuffix
          mat-icon-button
          *ngIf="isTouchedAndRequired('login')"
          [matTooltip]="getLoginError()"
          class="required-icon"
        >
          <mat-icon svgIcon="alert" color="warn"></mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="error-placeholder" appearance="fill" class="input-login">

        <input
          required
          [formControlName]="'passwd'"
          i18n-placeholder="@@login_form_enter_password"
          matInput
          placeholder="Enter your password here"
          [type]="showPassword ? 'text' : 'password'"
          name="password"
        />

        <button *ngIf="!isTouchedAndRequired('passwd')" (click)="this.showPassword = ! this.showPassword"
                type="button" matSuffix mat-icon-button>
          <mat-icon [svgIcon]="showPassword ? 'eye' : 'eye-off'"></mat-icon>
        </button>
        <button
          *ngIf="isTouchedAndRequired('passwd')"
          #matTooltip="matTooltip"
          type="button"
          mat-icon-button
          matSuffix
          (click)="matTooltip.toggle(); $event.stopPropagation()"
          matTooltip="You must enter a password"
          i18n-matTooltip="@@login_form_enter_password_error"
        >
          <mat-icon svgIcon="alert" color="warn"></mat-icon>
        </button>
      </mat-form-field>


  <a
    *ngIf="!anonymousRegistrationEnabled"
    class="forgot-password"
    mat-button
    [routerLink]="previewMode ? null : '/forgot-password'"
  >
    <span i18n="@@login_page_forgot_password_button">Forgot password?</span>
  </a>

  <div class="loginButton-wrapper">
    <button
      *ngIf="previewMode ? texts.registrationButtonActive : shouldShowRegistration()"
      type="button"
      (click)="onRegister()"
      class="loginButton register"
      [ngStyle]="{
      'color': (previewMode ? texts.applyCustomColors : _designSettings.applyCustomColors) ? '$rag-main-color' : 'black',
      }"
      mat-stroked-button
    >
      <span>{{ registerText }}</span>
    </button>

    <button
      [disabled]="loginButtonDisabled$ | async"
      class="loginButton login"
      color="primary"
      [ngStyle]="{
      'color': (previewMode ? texts.applyCustomColors : _designSettings.applyCustomColors) ? 'white' : 'black',
      'background-color': (previewMode ? texts.applyCustomColors : _designSettings.applyCustomColors) ? '$rag-main-color' : 'white',
      'border': (previewMode ? texts.applyCustomColors : _designSettings.applyCustomColors) ? '0' : '1px solid gray',
      }"
      mat-flat-button
      type="submit"
    >
      <span i18n="@@sign_in_button">SIGN IN</span>
    </button>
  </div>
</form>

