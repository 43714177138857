import { intersection, isArray } from 'lodash';


export class PermissionHelper {

  static checkRoles(principalRoles: string[], objectRoles: string[] | string, compareTo: number): boolean {
    if ( !objectRoles ) {
      // no requirements defined
      return true;
    }
    if ( objectRoles.length === 0 ) {
      // user should not have any roles (i.e. logged out)
      return !principalRoles || principalRoles.length === 0;
    }
    if ( typeof objectRoles === 'string' ) {
      objectRoles = [ objectRoles ];
    }
    return isArray(objectRoles) &&
      (intersection(objectRoles, principalRoles).length >= compareTo);
  }

  static hasAllRoles(principalRoles: string[], objectRoles: string[] | string): boolean {
    if ( !objectRoles ) {
      return true;
    }
    if ( typeof (objectRoles) === 'string' ) {
      return PermissionHelper.checkRoles(principalRoles, [ objectRoles ], 1);
    } else {
      return PermissionHelper.checkRoles(principalRoles, objectRoles, objectRoles.length);
    }
  }

  static hasAnyRole(principalRoles: string[], objectRoles: string[] | string): boolean {
    if ( !objectRoles ) {
      return true;
    }
    return PermissionHelper.checkRoles(principalRoles, objectRoles, 1);
  }

}
