import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';
import { Core, ImageableContentReference } from 'src/app/core/core.types';
import { ImageUrlHelper } from 'src/app/core/image-url-helper';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { UrlHelper } from 'src/app/core/url.helper';
import { ViewHelper } from 'src/app/core/view-helper';
import { Certificate } from 'src/app/route/user/certificates/certificates.types';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  selector: 'rag-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.scss']
})
export class CertificateCardComponent implements OnInit {

  @Input() certificate: Certificate;
  @Output() readonly detailsClicked;
  @Output() readonly downloadClicked;
  @Input() editorMode: boolean = false;

  protected readonly cardBackgroundStyles$: Observable<any | null>;
  protected contentLink: string;
  protected content: ImageableContentReference;
  protected today = new Date().getTime();

  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _detailsClicked = new EventEmitter<void>();
  private _downloadClicked = new EventEmitter<void>();
  private readonly DEFAULT_PICTURE_URL = "assets/images/card_curriculum.jpg";
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL)

  constructor(
    private router: Router
  ) {
    this.detailsClicked = this._detailsClicked.asObservable();
    this.downloadClicked = this._downloadClicked.asObservable();
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  ngOnInit(): void {
    const certificate = this.certificate;
    if ( certificate == null ) {
      return;
    }

    const curriculum = ViewHelper.getViewData(certificate)?.curriculum;

    const id = certificate.curid;
    this.content = {
      id,
      objType: Core.DistributableType.lms_curriculum,
      pictureId: parseInt(String(curriculum?.pictureId), 10),
      pictureUUID: curriculum?.pictureUUID,
      title: curriculum?.title ?? certificate.curriculum,
    };

    this.contentLink = UrlHelper
      .execUrlFor({ id, objType: Core.DistributableType.lms_curriculum });

    this.updateImageUrl();
  }

  onNavigateToDetails(): void {
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  onDetailsClicked(): void {
    this._detailsClicked.emit();
  }

  onDownloadClicked(): void {
    this._downloadClicked.emit();
  }

  private updateImageUrl(): void {

    if ( !this.content ) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if ( pictureFile != null ) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    if (this.content.pictureUUID === undefined) {
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
      this.content.pictureId,
      this.content.cardPictureUUID ??
      this.content.cardPicture?.uuid ??
      this.content.pictureUUID);
    this._imageUrl$.next(imageUrl);
  }
}
