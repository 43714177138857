import { CommonModule } from '@angular/common';
import { NgModule, TemplateRef, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { GridsterModule } from 'angular-gridster2';
import { Observable } from 'rxjs';
import { CertificatesModule } from 'src/app/route/user/certificates/certificates.module';
import { PipesModule } from '../../core/pipes/pipes.module';
import { Widget } from '../rag-layout.types';
import { CodeRedeemWidgetComponent } from './code-redeem-widget/code-redeem-widget.component';
import { HtmlWidgetComponent } from './html-widget/html-widget.component';
import { MycertificatesWidgetComponent } from './mycertificates-widget/mycertificates-widget.component';
import { NewsPreviewDialogComponent } from './news-widget/news-preview-dialog/news-preview-dialog.component';
import { NewsWidgetComponent } from './news-widget/news-widget.component';
import { OverviewWidgetComponent } from './overview-widget/overview-widget.component';
import { ReportBarChartWidgetComponent } from './report-bar-chart-widget/report-bar-chart-widget.component';
import { ReportFavoritesWidgetComponent } from './report-favorites-widget/report-favorites-widget.component';
import { ReportLinksWidgetComponent } from './report-links-widget/report-links-widget.component';
import { ReportPieChartWidgetComponent } from './report-pie-chart-widget/report-pie-chart-widget.component';
import { SpecialsWidgetComponent } from './specials-widget/specials-widget.component';
import { TodoContentWidgetComponent } from './todo-content-widget/todo-content-widget.component';
import { WidgetWrapperComponent } from './widget-wrapper/widget-wrapper.component';
import { AbstractCardWidgetComponent, AbstractChartWidgetComponent, BaseWidgetComponent } from './widgets.types';
import { EventsWidgetComponent } from './events-widget/events-widget.component';
import { CatalogViewModule } from 'src/app/component/catalog/catalog-view/catalog-view.module';
import { GamificationWidgetComponent } from './gamification-widget/gamification-widget.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { SlideshowComponent } from 'src/app/component/slideshow/slideshow.component';
import { AttachmentsComponent } from 'src/app/component/attachments/attachments.component';
import { DoughnutChartModule } from 'src/app/component/chart/doughnut-chart/doughnut-chart.module';
import { LearningTimeChartModule } from 'src/app/component/chart/learning-time-chart/learning-time-chart.module';
import { ContentOverviewComponent } from '../../route/user/content-overview/content-overview.component';

export interface WidgetComponent {
  readonly title$: Observable<string>;
  widget: Widget;

  getMenuReferences?(): TemplateRef<any>[];

  isDataDriven?(): boolean;
}

export class WidgetMapping {
  constructor(public component: Type<any>,
              public widget: Widget) {
  }
}

@NgModule({
  declarations: [
    AbstractCardWidgetComponent,
    AbstractChartWidgetComponent,
    BaseWidgetComponent,
    CodeRedeemWidgetComponent,
    HtmlWidgetComponent,
    MycertificatesWidgetComponent,
    NewsPreviewDialogComponent,
    NewsWidgetComponent,
    OverviewWidgetComponent,
    ReportBarChartWidgetComponent,
    ReportFavoritesWidgetComponent,
    ReportLinksWidgetComponent,
    ReportPieChartWidgetComponent,
    ReportPieChartWidgetComponent,
    SpecialsWidgetComponent,
    TodoContentWidgetComponent,
    WidgetWrapperComponent,
    EventsWidgetComponent,
    GamificationWidgetComponent,
  ],
  imports: [
    CommonModule,
    AttachmentsComponent,
    GridsterModule,
    DoughnutChartModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatGridListModule,
    LearningTimeChartModule,
    MatMenuModule,
    MatInputModule,
    MatDividerModule,
    FormsModule,
    MatTableModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CertificatesModule,
    SlideshowComponent,
    RouterModule,
    PipesModule,
    DialogTitleComponent,
    DialogHeaderComponent,
    CatalogViewModule,
    MatTooltipModule,
    MatBadgeModule,
    ContentOverviewComponent,
  ],
  exports: [
    WidgetWrapperComponent,
    GridsterModule,
  ],
})

export class WidgetsModule {
}
