import { TableColumnBuilder } from '../table-column.builder';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';
import { TableColumnDataType, TableControllerTypes } from './table-controller.types';
import { User } from '../../../core/core.types';


export interface UserModelControllingColumnMap<T = ControllingSingleUserTypes.ControllingUser>
  extends TableControllerTypes.ColumnMenuItemMap<T> {
  userArea: TableControllerTypes.ColumnMenuItem<T>;
  userCompany: TableControllerTypes.ColumnMenuItem<T>;
  userCostCenter: TableControllerTypes.ColumnMenuItem<T>;
  userCostcenterDesc: TableControllerTypes.ColumnMenuItem<T>;
  userDateOfBirth: TableControllerTypes.ColumnMenuItem<T>;
  userEmail: TableControllerTypes.ColumnMenuItem<T>;
  userEmployeeID: TableControllerTypes.ColumnMenuItem<T>;
  userExtID: TableControllerTypes.ColumnMenuItem<T>;
  userFirstname: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute1: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute2: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute3: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute4: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute5: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute6: TableControllerTypes.ColumnMenuItem<T>;
  userFreeTextAttribute7: TableControllerTypes.ColumnMenuItem<T>;
  userGeID: TableControllerTypes.ColumnMenuItem<T>;
  userHireDate: TableControllerTypes.ColumnMenuItem<T>;
  userHostID: TableControllerTypes.ColumnMenuItem<T>;
  userId: TableControllerTypes.ColumnMenuItem<T>;
  userJobCode: TableControllerTypes.ColumnMenuItem<T>;
  userLanguage: TableControllerTypes.ColumnMenuItem<T>;
  userLastname: TableControllerTypes.ColumnMenuItem<T>;
  userLocation: TableControllerTypes.ColumnMenuItem<T>;
  userLocationDesc: TableControllerTypes.ColumnMenuItem<T>;
  userLogin: TableControllerTypes.ColumnMenuItem<T>;
  userSex: TableControllerTypes.ColumnMenuItem<T>;
  userSoeID: TableControllerTypes.ColumnMenuItem<T>;
  userState: TableControllerTypes.ColumnMenuItem<T>;
  userSubstitute2ID: TableControllerTypes.ColumnMenuItem<T>;
  userSubstituteID: TableControllerTypes.ColumnMenuItem<T>;
  userSuperiorID: TableControllerTypes.ColumnMenuItem<T>;
}

export const UserModelControllingColumns: UserModelControllingColumnMap = {

  userId: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userId')
    .withType(TableColumnDataType.number)
    .withTitle($localize`:@@global_model_user_userId:User ID`)
    .withPreserve()
    .build(),
  userLogin: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userLogin')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_username:Username`)
    .build(),
  userFirstname: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFirstname')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFirstname:First name`)
    .withPreserve()
    .build(),
  userLastname: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userLastname')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userLastname:Last name`)
    .withPreserve()
    .build(),
  userEmail: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userEmail')
    .withType(TableColumnDataType.email)
    .withTitle($localize`:@@global_model_user_userEmail:E-mail address`)
    .build(),

  userArea: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userArea')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userArea:Division`)
    .build(),
  userCompany: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userCompany')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userCompany:Company`)
    .build(),
  userCostCenter: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userCostCenter')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userCostCenter:Cost center`)
    .build(),
  userCostcenterDesc: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userCostcenterDesc')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userCostcenterDesc:Department`)
    .build(),
  userDateOfBirth: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userDateOfBirth')
    .withType(TableColumnDataType.date)
    .withTitle($localize`:@@global_model_user_userDateOfBirth:Date of birth`)
    .build(),
  userEmployeeID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userEmployeeID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userEmployeeID:Employee ID`)
    .build(),
  userExtID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userExtID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userExtID:External ID`)
    .build(),
  userFreeTextAttribute1: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute1')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute1:2nd job description`)
    .build(),
  userFreeTextAttribute2: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute2')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute2:3rd job description`)
    .build(),
  userFreeTextAttribute3: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute3')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute3:4th job description`)
    .build(),
  userFreeTextAttribute4: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute4')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute4:5th job description`)
    .build(),
  userFreeTextAttribute5: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute5')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute5:Alternate contact 1`)
    .build(),
  userFreeTextAttribute6: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute6')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute6:Alternate contact 2`)
    .build(),
  userFreeTextAttribute7: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userFreeTextAttribute7')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userFreeTextAttribute7:Alternate contact 3`)
    .build(),
  userGeID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userGeID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userGeID:Business phone`)
    .build(),
  userHireDate: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userHireDate')
    .withType(TableColumnDataType.date)
    .withTitle($localize`:@@global_model_user_userHireDate:Date of employment`)
    .build(),
  userHostID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userHostID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userHostID:Mobile phone`)
    .build(),
  userJobCode: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userJobCode')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userJobCode:Job description`)
    .build(),
  userLanguage: TableColumnBuilder.start<any>()
    .withColumnId('userLanguage')
    .withType(TableColumnDataType.dropdown)
    .withTitle($localize`:@@select_language:Language`)
    .withDropDownOptions({
      de_DE: $localize`:@@global_language_german:German`,
      en_GB: $localize`:@@global_language_english:English`,
      fr_FR: $localize`:@@global_language_french:French`,
      pl_PL: $localize`:@@global_language_polish:Polish`,
    })
    .build(),
  userLocation: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userLocation')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userLocation:Location code`)
    .build(),
  userLocationDesc: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userLocationDesc')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userLocationDesc:Location name`)
    .build(),
  userSex: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withType(TableColumnDataType.dropdown)
    .withColumnId('userSex')
    .withTitle($localize`:@@global_model_user_userSex:Gender`)
    .withPlaceholder($localize`:@@global_model_user_userSex_filter_placeholder:Gender is...`)
    .withDropDownOptions({
      1: $localize`:@@global_model_user_userSex_filter_1:Female`,
      2: $localize`:@@global_model_user_userSex_filter_2:Male`,
      3: $localize`:@@global_model_user_userSex_filter_3:Diverse`,
      4: $localize`:@@global_model_user_userSex_filter_4:Unknown`,
    })
    .build(),
  userSoeID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userSoeID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userSoeID:Private phone`)
    .build(),
  userState: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withType(TableColumnDataType.dropdown)
    .withColumnId('userState')
    .withTitle($localize`:@@global_model_user_userState:Superior`)
    .withPlaceholder($localize`:@@global_model_user_userState_filter_placeholder:User is superior?`)
    .withDropDownOptions({
      1: $localize`:@@global_model_user_userState_filter_1:Yes`,
      2: $localize`:@@global_model_user_userState_filter_2:No`,
    })
    .build(),
  userSubstitute2ID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userSubstitute2ID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userSubstitute2ID:2nd deputy (User ID)`)
    .build(),
  userSubstituteID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userSubstituteID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userSubstituteID:1st deputy (User ID)`)
    .build(),
  userSuperiorID: TableColumnBuilder.start<ControllingSingleUserTypes.ControllingUser>()
    .withColumnId('userSuperiorID')
    .withType(TableColumnDataType.text)
    .withTitle($localize`:@@global_model_user_userSuperiorID:Superior (User ID)`)
    .build(),

};

export interface UserModelAdminColumnMap<T = User>
  extends TableControllerTypes.ColumnMenuItemMap<T> {
  activationDate: TableControllerTypes.ColumnMenuItem<T>;
  active: TableControllerTypes.ColumnMenuItem<T>;
  area: TableControllerTypes.ColumnMenuItem<T>;
  company: TableControllerTypes.ColumnMenuItem<T>;
  costCenter: TableControllerTypes.ColumnMenuItem<T>;
  costcenterDesc: TableControllerTypes.ColumnMenuItem<T>;
  dateOfBirth: TableControllerTypes.ColumnMenuItem<T>;
  deactivationDate: TableControllerTypes.ColumnMenuItem<T>;
  email: TableControllerTypes.ColumnMenuItem<T>;
  employeeID: TableControllerTypes.ColumnMenuItem<T>;
  extID: TableControllerTypes.ColumnMenuItem<T>;
  firstname: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute1: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute2: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute3: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute4: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute5: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute6: TableControllerTypes.ColumnMenuItem<T>;
  freeTextAttribute7: TableControllerTypes.ColumnMenuItem<T>;
  geID: TableControllerTypes.ColumnMenuItem<T>;
  gender: TableControllerTypes.ColumnMenuItem<T>;
  hireDate: TableControllerTypes.ColumnMenuItem<T>;
  hostID: TableControllerTypes.ColumnMenuItem<T>;
  jobCode: TableControllerTypes.ColumnMenuItem<T>;
  language: TableControllerTypes.ColumnMenuItem<T>;
  lastname: TableControllerTypes.ColumnMenuItem<T>;
  location: TableControllerTypes.ColumnMenuItem<T>;
  locationDesc: TableControllerTypes.ColumnMenuItem<T>;
  login: TableControllerTypes.ColumnMenuItem<T>;
  soeID: TableControllerTypes.ColumnMenuItem<T>;
  state: TableControllerTypes.ColumnMenuItem<T>;
  substitute2ID: TableControllerTypes.ColumnMenuItem<T>;
  substituteID: TableControllerTypes.ColumnMenuItem<T>;
  superiorID: TableControllerTypes.ColumnMenuItem<T>;
  userId: TableControllerTypes.ColumnMenuItem<T>;
}

export const UserModelAdminColumns: UserModelAdminColumnMap = {

  userId: TableColumnBuilder.start<any>(UserModelControllingColumns.userId)
    .withColumnId('userId')
    .build(),
  login: TableColumnBuilder.start<any>(UserModelControllingColumns.userLogin)
    .withColumnId('login')
    .build(),
  firstname: TableColumnBuilder.start<any>(UserModelControllingColumns.userFirstname)
    .withColumnId('firstname')
    .build(),
  lastname: TableColumnBuilder.start<any>(UserModelControllingColumns.userLastname)
    .withColumnId('lastname')
    .build(),
  email: TableColumnBuilder.start<any>(UserModelControllingColumns.userEmail)
    .withColumnId('email')
    .build(),
  active: TableColumnBuilder.start<any>()
    .withColumnId('active')
    .withType(TableColumnDataType.dropdown)
    .withTitle($localize`:@@global_model_user_userActive:Active`)
    .withDropDownOptions({
      true: $localize`:@@global_enabled:enabled`,
      false: $localize`:@@global_disabled:disabled`,
    })
    .withFilterDefaultValue('true')
    .withPreserve()
    .build(),

  activationDate: TableColumnBuilder.start<any>()
    .withColumnId('activationDate')
    .withType(TableColumnDataType.date)
    .withTitle($localize`:@@global_model_user_activationDate:Activation Date`)
    // todo .withPreserve()
    .build(),

  area: TableColumnBuilder.start<any>(UserModelControllingColumns.userArea)
    .withColumnId('area')
    .build(),
  company: TableColumnBuilder.start<any>(UserModelControllingColumns.userCompany)
    .withColumnId('company')
    .build(),
  costCenter: TableColumnBuilder.start<any>(UserModelControllingColumns.userCostCenter)
    .withColumnId('costCenter')
    .build(),
  costcenterDesc: TableColumnBuilder.start<any>(UserModelControllingColumns.userCostcenterDesc)
    .withColumnId('costcenterDesc')
    .build(),
  dateOfBirth: TableColumnBuilder.start<any>(UserModelControllingColumns.userDateOfBirth)
    .withColumnId('dateOfBirth')
    .build(),

  deactivationDate: TableColumnBuilder.start<any>()
    .withColumnId('deactivationDate')
    .withType(TableColumnDataType.date)
    .withTitle($localize`:@@global_model_user_deactivation_date:Deactivation Date`)
    // todo .withPreserve()
    .build(),

  employeeID: TableColumnBuilder.start<any>(UserModelControllingColumns.userEmployeeID)
    .withColumnId('employeeID')
    .build(),
  extID: TableColumnBuilder.start<any>(UserModelControllingColumns.userExtID)
    .withColumnId('extID')
    .build(),
  freeTextAttribute1: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute1)
    .withColumnId('freeTextAttribute1')
    .build(),
  freeTextAttribute2: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute2)
    .withColumnId('freeTextAttribute2')
    .build(),
  freeTextAttribute3: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute3)
    .withColumnId('freeTextAttribute3')
    .build(),
  freeTextAttribute4: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute4)
    .withColumnId('freeTextAttribute4')
    .build(),
  freeTextAttribute5: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute5)
    .withColumnId('freeTextAttribute5')
    .build(),
  freeTextAttribute6: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute6)
    .withColumnId('freeTextAttribute6')
    .build(),
  freeTextAttribute7: TableColumnBuilder.start<any>(UserModelControllingColumns.userFreeTextAttribute7)
    .withColumnId('freeTextAttribute7')
    .build(),
  geID: TableColumnBuilder.start<any>(UserModelControllingColumns.userGeID)
    .withColumnId('geID')
    .build(),
  gender: TableColumnBuilder.start<any>(UserModelControllingColumns.userSex)
    .withColumnId('gender')
    .withDataAccessor(entry => entry)
    .withFilterDataAccessor(entry => entry?.gender)
    .withDisplayValueAccessor(entry => {
      switch (entry?.gender) {
        case 1: return $localize`:@@gender_1:Female`;
        case 2: return $localize`:@@gender_2:Male`;
        case 3: return $localize`:@@gender_3:Diverse`;
        case 4: return $localize`:@@gender_4:Unknown`;
      }
    })
    .withSortingAccessor(entry => entry?.gender)
    .build(),
  hireDate: TableColumnBuilder.start<any>(UserModelControllingColumns.userHireDate)
    .withColumnId('hireDate')
    .build(),
  hostID: TableColumnBuilder.start<any>(UserModelControllingColumns.userHostID)
    .withColumnId('hostID')
    .build(),
  jobCode: TableColumnBuilder.start<any>(UserModelControllingColumns.userJobCode)
    .withColumnId('jobCode')
    .build(),
  language: TableColumnBuilder.start<any>(UserModelControllingColumns.userLanguage)
    .withColumnId('language')
    .build(),
  location: TableColumnBuilder.start<any>(UserModelControllingColumns.userLocation)
    .withColumnId('location')
    .build(),
  locationDesc: TableColumnBuilder.start<any>(UserModelControllingColumns.userLocationDesc)
    .withColumnId('locationDesc')
    .build(),
  soeID: TableColumnBuilder.start<any>(UserModelControllingColumns.userSoeID)
    .withColumnId('soeID')
    .build(),
  state: TableColumnBuilder.start<any>(UserModelControllingColumns.userState)
    .withColumnId('state')
    .build(),
  substitute2ID: TableColumnBuilder.start<any>(UserModelControllingColumns.userSubstitute2ID)
    .withColumnId('substitute2ID')
    .build(),
  substituteID: TableColumnBuilder.start<any>(UserModelControllingColumns.userSubstituteID)
    .withColumnId('substituteID')
    .build(),
  superiorID: TableColumnBuilder.start<any>(UserModelControllingColumns.userSuperiorID)
    .withColumnId('superiorID')
    .build(),

};
