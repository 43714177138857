import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { CurriculumInfoComponent } from './curriculum-info.component';

@NgModule({
  declarations: [
    CurriculumInfoComponent,
  ],
  exports: [
    CurriculumInfoComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
  ],
})
export class CurriculumInfoModule {
}
