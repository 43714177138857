import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { TransactionView } from '../transactions.types';
import { ApiUrls } from '../../../../core/api.urls';
import { ApiResponse } from '../../../../core/global.types';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TransactionsListService {

  @Output() readonly updateData$: Observable<void>;
  private _updateData = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
  ) {
    this.updateData$ = this._updateData.asObservable();
  }

  getTransactions(): Observable<TransactionView[]> {

    const url = ApiUrls.getKey('GetTransactions');
    return this.http.get<ApiResponse<TransactionView[]>>(url).pipe(map(response => response.purchases));
  }

}
