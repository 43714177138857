<ng-template #tplLoading>
  <rag-loading-indicator
  ></rag-loading-indicator>
</ng-template>

<ng-template #tplLogin>
  <rag-login-form
    [data]="loginFormData$ | async"
    [showDoubleOptInSuccessInline]="false"
  ></rag-login-form>
</ng-template>

<div class="flex-wrapper">
  <ng-container
    [ngTemplateOutlet]="getTemplate(
      tplLoading, tplLogin
    )"
  ></ng-container>

</div>
