import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { LearnerAccountTypes } from '../../../../../core/learner-account/learner-account.types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { Core } from '../../../../../core/core.types';
import { CtrlEditDisplayStatusTypes } from './ctrl-edit-display-status.types';


declare type fnOnChange = (displayStatus: number) => any;
declare type fnOnTouched = () => any;

@Component({
  selector: 'rag-ctrl-edit-display-status',
  templateUrl: './ctrl-edit-display-status.component.html',
  styleUrls: [ './ctrl-edit-display-status.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CtrlEditDisplayStatusComponent),
      multi: true,
    },
  ],
})
export class CtrlEditDisplayStatusComponent
  implements ControlValueAccessor, OnInit {

  @Input() confirmActive: boolean;
  @Input() contentType: Core.DistributableType;
  @Input() disabled: boolean;
  onChange: fnOnChange;
  onTouched: fnOnTouched;
  statusOptions: CtrlEditDisplayStatusTypes.StatusOptions[];
  private _courseType: Core.CourseType;
  private _displayStatus: DisplayStatus;
  private _originalValue: DisplayStatus;

  constructor() {
  }

  @Input() set courseType(value: number | string | Core.CourseType) {
    this._courseType = LearnerAccountTypes.courseTypeFactory(value);
  }

  get displayStatus(): DisplayStatus {
    return this._displayStatus;
  }

  set displayStatus(value: DisplayStatus) {
    this._displayStatus = value;
    if ( this.onChange ) {
      this.onChange(value);
    }
    if ( this.onTouched ) {
      this.onTouched();
    }
  }

  @HostBinding('class.rag-dirty')
  get isDirty(): boolean {
    return (this._originalValue != null) && (this._displayStatus !== this._originalValue);
  }

  get originalValue(): DisplayStatus {
    return this._originalValue;
  }

  set originalValue(value: DisplayStatus) {
    this._originalValue = value;
  }

  ngOnInit(): void {
    this.statusOptions = CtrlEditDisplayStatusTypes.Util.getStatusForType({
      contentType: this.contentType,
      courseConfirmActive: this.confirmActive,
      courseType: this._courseType,
    });
  }

  onValueChange(value: DisplayStatus) {
    if ( this._originalValue == null ) {
      this._originalValue = this._displayStatus;
    }
    this.displayStatus = value;
  }

  registerOnChange(fn: fnOnChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: fnOnTouched): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: DisplayStatus): void {
    this.displayStatus = value;
  }

}
