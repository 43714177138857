import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PrincipalService } from './core/principal/principal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuestGuard
  implements CanActivate {

  constructor(
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // fetchUserData cannot be included in the pipe as it does not return anything if user is not logged in!
    this.principalService.fetchUserData(false);
    return this.principalService.isLogged$
      .pipe(take(1))
      .pipe(map(isLogged => {
        if ( isLogged ) {
          // user is logged in and is not redirected
          if ( console?.warn != null ) {
            console.warn('You are logged in. Redirecting to home site...');
          }

          return this.router.parseUrl('/home');
        }
        return true;
      }));
  }

}
