<ng-template #tplDescription>
  <div class="container padding curriculum-container">

    <div *ngIf="hasDistSubType" class="dist-sub-type">
      {{objSubTypeAsText}}
    </div>

    <rag-clamp
      [clamp]="4"
      class="description"
      [input]="catalogEntry.description | localeText">
    </rag-clamp>

  </div>
</ng-template>

<ng-template #tplOfflineEvents>
  <rag-catalog-card-schedules-pager
    [schedulesViews]="catalogEntry.offlineEventsViews"
  ></rag-catalog-card-schedules-pager>
</ng-template>

<ng-container
  [ngTemplateOutlet]="getTemplate(
    tplDescription,
    tplOfflineEvents
  )"
></ng-container>
