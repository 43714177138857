import { Component, Input } from '@angular/core';
import { Content } from '../../../core/content/content.types';

@Component({
  selector: 'rag-content-duration',
  templateUrl: './content-duration.component.html',
  styleUrls: [ './content-duration.component.scss' ],
})
export class ContentDurationComponent {

  displayMode: 'text' | 'start' | 'duration';
  durationText: string;
  private _content: Content;

  constructor() {
  }

  get content(): Content {
    return this._content;
  }

  @Input()
  set content(value: Content) {
    const doUpdate = value && (value !== this._content);
    this._content = value;
    if ( !doUpdate ) {
      return;
    }

    this.displayMode = null;

    this.durationText = null;
    if ( value.calculatedDuration && value.calculatedDuration !== '0 m' ) {
      this.durationText = value.calculatedDuration;
      this.displayMode = 'text';
    } else if ( value.eventDate > 0 ) {
      this.displayMode = 'duration';
    }
  }

}
