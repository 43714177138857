import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';


/**
 * @see https://stackoverflow.com/a/43172992
 * @see https://angular.io/guide/structural-directives#structural-directives
 */
@Directive({
  selector: '[ragVar]',
})
export class RagVarDirective {
  context: any = {};

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
  }

  @Input()
  set ragVar(context: any) {
    this.context.$implicit = this.context.ragVar = context;
    this.updateView();
  }

  updateView() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }
}
