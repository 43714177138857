import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { actionTypeToLocale, JournalEntityView } from 'src/app/core/journal/journal.types';
import { TableColumnMenuService } from '../../table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from '../../table/table-controller/table-controller.component';
import { JournalDialog, JournalDialogData } from './journal-dialog.types';
import { UNIX_TIMESTAMP_REGEX } from '../../../core/core.types';
import { isNothing } from '../../../core/utils';
import * as moment from 'moment';
import { InfoService } from 'src/app/core/info/info.service';
import { GenericMessageDialogComponent } from '../../generic-message-dialog/generic-message-dialog.component';
import { OkButton } from 'src/app/core/info/info.types';

@Component({
  selector: 'rag-journal-dialog',
  templateUrl: './journal-dialog.component.html',
  styleUrls: ['./journal-dialog.component.scss']
})
export class JournalDialogComponent extends TableControllerComponent<JournalEntityView> implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JournalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JournalDialogData,
    protected tableColumnMenuService: TableColumnMenuService,
    private infoService: InfoService,
  ) {
    super(tableColumnMenuService);
  }

  ngOnInit(): void {
    this.updateData(this.data.journal);
  }

  private formatMessage(data: Array<JournalEntityView>) {
    data.forEach(entry => {
      let message = entry.message;
      const groups = message.match(UNIX_TIMESTAMP_REGEX);
      if ( !isNothing(groups) ) {
        for ( let i = 1; i <= groups.length; i++ ) {
          const unix_timestamp = groups[i];
          const momentTime = moment(parseInt(unix_timestamp, 10));
          message = message.replace(unix_timestamp, momentTime.format('DD.MM.YYYY'));
        }
        entry.message = message;
      }
    });
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(JournalDialog.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateData(data: Array<JournalEntityView>) {
    this.updateAvailableColumns();
    this.formatMessage(data);
    this.setTableData(data);
  }

  protected showMessageDialog(entity: JournalEntityView) {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      message: entity.message,
      title:  `${actionTypeToLocale(entity.actionType)??''} von ${entity.firstName??''} ${entity.lastName??''} am ${moment.unix(entity.date/1000).format('LLL')??''}`,
      titleKey: '',
      buttons: OkButton,
    },
    {
      panelClass: 'allowLineBreaks'
    });
  }
}
