import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    SelectLanguageComponent
  ],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTooltipModule,
    PipesModule
  ],
  exports: [SelectLanguageComponent]
})
export class SelectLanguageModule { }
