import { Injectable } from '@angular/core';
import { State } from '../app.state';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class OwnMatPaginatorIntl
  extends MatPaginatorIntl {

  from = 'of';

  constructor() {
    super();
    this.itemsPerPageLabel = $localize`:@@paginator_itemsPerPageLabel:Items per page`;
    this.nextPageLabel = $localize`:@@paginator_nextPageLabel:Next page`;
    this.previousPageLabel = $localize`:@@paginator_previousPageLabel:Previous page`;
    this.lastPageLabel = $localize`:@@paginator_lastPageLabel:Last page`;
    this.firstPageLabel = $localize`:@@paginator_firstPageLabel:First page`;
    this.from = $localize`:@@paginator_fromLabel:of`;
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    if ( length === 0 || pageSize <= 0 ) {
      return `0 ${this.from} ${length}`;
    }
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.from} ${length}`;
  };

}
