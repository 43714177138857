import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtOauth2EditModule } from '../ext-oauth2/ext-oauth2-edit/ext-oauth2-edit.module';
import { ExtOauth2ListModule } from '../ext-oauth2/ext-oauth2-list/ext-oauth2-list.module';
import { ExtPaymentModuleComponent } from './ext-payment-module.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  imports: [
    CommonModule,
    ExtOauth2EditModule,
    ExtOauth2ListModule,
    PageHeaderComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
  ],
  declarations: [
    ExtPaymentModuleComponent,
  ],
})
export class ExtPaymentModuleModule {
}
