import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportTargetType } from 'src/app/core/report/report.types';
import { TableColumnSettings } from '../../../core/column-settings/column-settings.service';
import { AdminConfigurationService } from '../../../core/admin/configuration/admin-configuration.service';

@Component({
  selector: 'rag-report-gen-admin',
  templateUrl: './report-gen-admin.component.html',
  styleUrls: [ './report-gen-admin.component.scss' ],
})
export class ReportGenAdminComponent
  implements OnInit {

  columnSettings$: Observable<TableColumnSettings>;
  targetType: ReportTargetType;

  constructor(
    private adminConfigurationService: AdminConfigurationService,
    private route: ActivatedRoute,
  ) {
    this.targetType = this.route.snapshot.paramMap.get('targetType') as ReportTargetType;
    this.columnSettings$ = this.route.data
      .pipe(map(data => data.columnSettings));
  }

  ngOnInit() {
    this.adminConfigurationService.setSaveButtonVisible(false);
    this.adminConfigurationService.setPermissionExportVisible(false);
  }

}
