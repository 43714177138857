import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../component/table/table-controller/table-controller.types';
import { TableColumnBuilder } from '../../../../component/table/table-column.builder';

export interface AdminGamificationDetailsColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: AdminGamificationDetailsColumnMenuItemMap;
}

export interface AdminGamificationDetailsColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  targetTitle: TableControllerTypes.ColumnMenuItem;
  targetId: TableControllerTypes.ColumnMenuItem;
  targetType: TableControllerTypes.ColumnMenuItem;
  actions: TableControllerTypes.ColumnMenuItem;
}

export const ADMIN_GAMIFICATION_DETAILS_DEFAULT_MENU_COLUMNS: AdminGamificationDetailsColumnMenuData = {
  startWith: ['targetTitle'],
  endWith: [],
  menuItems: {
    targetTitle: TableColumnBuilder.start()
      .withColumnId('targetTitle')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@global_title:Title`)
      .withSelected()
      .build(),

    targetId: TableColumnBuilder.start()
      .withColumnId('targetId')
      .withType(TableColumnDataType.number)
      .withTitle('Id')
      .build(),

    targetType: TableColumnBuilder.start()
      .withColumnId('targetType')
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        lms_curriculum: $localize`:@@select_curriculum:Curriculum`,
        lms_course: $localize`:@@select_course:Course`,
        lms_offlineCnt: $localize`:@@global_event:Event`
      })
      .withTitle($localize`:@@global_type:Type`)
      .build(),

    actions: TableColumnBuilder.start()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),
  }
}
