import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, finalize, map, mapTo, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { LearningSession } from 'src/app/core/content/content.types';
import { ApiResponse, HttpRequestOptions } from 'src/app/core/global.types';
import { ApiUrls } from '../../../../core/api.urls';
import { ControllingSingleUserTypes } from '../../../../core/ctrl-single-user.types';
import { InfoService } from '../../../../core/info/info.service';
import {
  CancelButton,
  DiscardButton,
  InfoType,
  MessageConstants,
  YesButton,
  YesNoButtons,
} from '../../../../core/info/info.types';
import { LanguageHelper } from '../../../../core/language.helper';
import { OpenEntityTypes } from '../../../../core/open-entity.types';
import { ViewHelper } from '../../../../core/view-helper';
import { CtrlSingleUserScormLogTypes } from '../ctrl-single-user-scorm-log/ctrl-single-user-scorm-log.types';
import { SetCoursesStateRequest } from './ctrl-single-user.types';
import { CtrlSingleUserDetailsCurriculumEditTypes } from '../ctrl-single-user-details/ctrl-single-user-details-curriculum-edit/ctrl-single-user-details-curriculum-edit.types';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarProgressService } from '../../../../component/snackbar-progress/snackbar-progress.service';
import { LearningSessionDataDialogComponent } from '../../../../component/learning-session-data-dialog/learning-session-data-dialog.component';
import { CtrlSingleUserScormLogComponent } from '../ctrl-single-user-scorm-log/ctrl-single-user-scorm-log.component';
import { Core, NumberedAnyObject } from '../../../../core/core.types';
import { CtrlSingleUserDetailsCourseTypes } from '../ctrl-single-user-details/ctrl-single-user-details-course/ctrl-single-user-details-course.types';
import { CachedSubject } from '../../../../core/cached-subject';
import { CTRL_SINGLE_USER_MSG } from '../ctrl-single-user-details/ctrl-single-user-details.types';
import { AdminCoursesService } from '../../../admin/admin-courses/admin-courses-util/admin-courses.service';
import { InteractionData } from '../ctrl-single-user-interactions-evaluation/ctrl-single-user-interactions-evaluation.types';

@Injectable({ providedIn: 'root' })
export class ControllingSingleUserService {

  private _openedCurricula = new OpenEntityTypes.OpenEntities<ControllingSingleUserTypes.TabCurriculum, string>(
    curriculum => String(curriculum.userId) + '_' + String(curriculum.curriculumId));
  private _openedCourse = new OpenEntityTypes.OpenEntities<ControllingSingleUserTypes.TabCourse, string>(
    course => String(course.userId) + '_' + String(course.courseId));
  private _openedUsers = new OpenEntityTypes.OpenEntities<ControllingSingleUserTypes.TabUser, number>(
    user => user.userId);
  private _userDetails: NumberedAnyObject<CachedSubject<ControllingSingleUserTypes.UserDetailsResponse>> = {};

  constructor(
    private adminCoursesService: AdminCoursesService,
    private http: HttpClient,
    private infoService: InfoService,
    private snackbarProgressService: SnackbarProgressService,
  ) {
  }

  get openedCurricula$(): Observable<ControllingSingleUserTypes.TabCurriculum[]> {
    return this._openedCurricula.entities$;
  }

  get openedCourse$(): Observable<ControllingSingleUserTypes.TabCourse[]> {
    return this._openedCourse.entities$;
  }

  get openedUsers$(): Observable<ControllingSingleUserTypes.TabUser[]> {
    return this._openedUsers.entities$;
  }

  closeCurriculum(curriculum: ControllingSingleUserTypes.TabCurriculum): void {
    this._openedCurricula.closeEntity(curriculum);
  }

  closeCourse(course: ControllingSingleUserTypes.TabCourse): void {
    this._openedCourse.closeEntity(course);
  }

  closeUser(user: ControllingSingleUserTypes.TabUser): void {
    this._openedUsers.closeEntity(user);
  }

  confirmClose(dialogRef: MatDialogRef<any>, dirty: boolean): void {
    if ( !dirty ) {
      // no change -> close dialog without confirmation
      return dialogRef.close(false);
    }

    this.infoService
      .showMessage(MessageConstants.DIALOG.MESSAGE.UNSAVED_CLOSE, {
        title: MessageConstants.DIALOG.TITLE.CONFIRM,
        buttons: CancelButton | DiscardButton,
      })
      .pipe(takeWhile(button => button === DiscardButton))
      .pipe(take(1))
      .pipe(tap(() => dialogRef.close(false)))
      .subscribe();
  }

  getCourseDetails(userId: number, courseId: number): Observable<ControllingSingleUserTypes.CourseAccountDetails> {
    // todo implement details API
    return this.getUserDetails(userId)
      .pipe(switchMap(response => {

        let course = response.courses
          ?.find(c => c.courseId === courseId);
        if ( course != null ) {
          if ( course.courseType !== Core.CourseType.ToDo ) {
            return of(course);
          }

          return this.getCourseToDo(userId, course);
        }

        const curricula = response.curricula
          ?.map(curriculum => ({
            curriculum,
            curriculumItem: curriculum.curriculumItems
              ?.find(item => (item?.targetId === courseId) &&
                (item.targetType === Core.DistributableType.lms_course) &&
                (item.course != null)),
          }))
          .filter(entry => entry.curriculumItem != null);
        if ( curricula?.length > 0 ) {
          // todo handle explicit curriculum id
          const entry = curricula[0];
          course = entry.curriculumItem?.course as ControllingSingleUserTypes.CourseAccountDetails;
          const curriculumId = entry.curriculum?.curriculumId;
          if ( (curriculumId > 0) && (course != null) ) {
            return this.getCourseToDoInCurriculum(userId, curriculumId, course);
          }
        }

        throw Error('course-not-found');
      }));
  }

  getCourseToDo(
    userId: number,
    courseDetails: ControllingSingleUserTypes.CourseAccountDetails,
  ): Observable<ControllingSingleUserTypes.CourseAccountDetails> {
    return this.adminCoursesService
      .getCourseAccountForUser(userId, courseDetails.courseId)
      .pipe(map(response => this.getCourseToFromResponse(response, courseDetails)));
  }

  getCourseToDoInCurriculum(
    userId: number,
    curriculumId: number,
    courseDetails: ControllingSingleUserTypes.CourseAccountDetails,
  ): Observable<ControllingSingleUserTypes.CourseAccountDetails> {
    return this.adminCoursesService
      .getCourseAccountInCurriculumForUser(userId, courseDetails.courseId, curriculumId)
      .pipe(map(response => this.getCourseToFromResponse(response, courseDetails)));
  }

  getCourseToFromResponse(
    response,
    courseDetails: ControllingSingleUserTypes.CourseAccountDetails,
  ): ControllingSingleUserTypes.CourseAccountDetails {
    const { course, courseAccount, contribution, extensions } = response;
    if ( course == null ) {
      return courseDetails;
    }

    course.title = LanguageHelper.objectToText(course.title);
    course.courseType = courseDetails.courseType;

    if ( courseAccount == null ) {
      return course;
    }

    // the user has provided solution for this task
    return { ...course, courseAccount, ...courseDetails, contribution, extensions };
  }

  getCurriculumItemDetails(userId: number, curriculumId: number, curriculumItemId: number):
    Observable<ControllingSingleUserTypes.CurriculumItemResponse> {
    // todo implement details API
    return this.getUserDetails(userId)
      .pipe(map(response => response.curricula
        .find(curriculum => curriculum.curriculumId === curriculumId)))
      .pipe(map(curriculum => {
        const curriculumItem = curriculum?.curriculumItems
          ?.find(c => c.curriculumItemId === curriculumItemId);
        if ( curriculumItem == null ) {
          throw Error('item-not-found');
        }

        return {
          curriculum,
          curriculumItem,
        };
      }));
  }

  getInteractions(userId: number, scoId: number, attempt?: number): Observable<InteractionData> {
    let url = ApiUrls.getKey('CtrlSingleUserInteractions')
      .replace(/{userId}/gi, String(userId))
      .replace(/{scoId}/gi, String(scoId));

    if (attempt != null) {
      url = url + `/${attempt}`;
    }
    return this.http.get<ApiResponse<InteractionData>>(url)
      .pipe(map(response => response.interactionData));
  }

  getScormLogUser(userId: number): Observable<CtrlSingleUserScormLogTypes.ScormLogResponse> {
    const url = ApiUrls.getKey('UserScormLog')
      .replace(/{userId}/, String(userId));
    return this.http.get<CtrlSingleUserScormLogTypes.ScormLogResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getScormLogUserAndSco(userId: number, scoId: number): Observable<CtrlSingleUserScormLogTypes.ScormLogResponse> {
    const url = ApiUrls.getKey('UserAndScoScormLog')
      .replace(/{userId}/, String(userId))
      .replace(/{scoId}/, String(scoId));
    return this.http.get<CtrlSingleUserScormLogTypes.ScormLogResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getUserDetails(userId: number, forceReload = false): Observable<ControllingSingleUserTypes.UserDetailsResponse> {
    const query = this._userDetails[userId] =
      this._userDetails[userId] ?? new CachedSubject<ControllingSingleUserTypes.UserDetailsResponse>(null);

    if ( forceReload ) {
      query.reset();
    }

    if ( query.queryStart() ) {
      const url = ApiUrls.getKey('CtrlSingleUserDetails')
        .replace(/{userId}/, String(userId));
      this.http.get<ControllingSingleUserTypes.UserDetailsResponse>(url)
        .pipe(catchError(this.handleError))
        .pipe(tap(query.next))
        .pipe(finalize(query.queryDone))
        .subscribe();
    }
    return query.withoutEmptyValuesWithInitial();
  }

  getUserFullName(user: ControllingSingleUserTypes.ControllingUser): string {
    const viewData = ViewHelper.getViewData(user);
    if ( viewData.fullName == null ) {
      if ( user.userFirstname && user.userLastname ) {
        viewData.fullName = `${user.userFirstname} ${user.userLastname} (${user.userId})`;
      } else if ( user.userFirstname ) {
        viewData.fullName = `${user.userFirstname} (${user.userId})`;
      } else if ( user.userFirstname ) {
        viewData.fullName = `${user.userLastname} (${user.userId})`;
      } else {
        viewData.fullName = `${user.userId}`;
      }
    }
    return viewData.fullName;
  }

  getUserList(): Observable<ControllingSingleUserTypes.UserListResponse> {
    const url = ApiUrls.getKey('CtrlSingleUsers');
    return this.http.get<ControllingSingleUserTypes.UserListResponse>(url)
      .pipe(catchError(this.handleError));
  }

  openCurriculum(userId: number, curriculum: ControllingSingleUserTypes.CurriculumAccount): void {
    this._openedCurricula.openEntity({
      curriculumId: curriculum.curriculumId,
      label: LanguageHelper.objectToText(curriculum.title),
      userId,
    });
  }

  openCourse(userId: number, course: ControllingSingleUserTypes.CourseAccount): void {
    this._openedCourse.openEntity({
      courseId: course.courseId,
      label: LanguageHelper.objectToText(course.title),
      userId,
    });
  }

  openUser(user: ControllingSingleUserTypes.ControllingUser): void {
    this._openedUsers.openEntity({
      label: this.getUserFullName(user),
      userId: user.userId,
    });
  }

  resetCourses(userId: number, courseIds: number[]): Observable<void> {

    const message = courseIds.length > 1 ?
      $localize`:@@ctrl_single_user_course_reset_confirm_multi:
        Do you want to reset the selected courses? This removes all progress the learner made.` :
      $localize`:@@ctrl_single_user_course_reset_confirm:
        Do you want to reset the selected course? This removes all progress the learner made.`;

    return this.infoService.showMessage(message, {
      buttons: YesNoButtons,
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
    })
      .pipe(takeWhile(button => button === YesButton))

      .pipe(switchMap(() => this.resetCoursesProgress(userId, courseIds)))
      .pipe(catchError(this.handleError))

      .pipe(map(() => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  saveCourseStatesForUser(userId: number, request: SetCoursesStateRequest): Observable<void> {

    if ( !(request?.states?.length > 0) ) {
      return of(void (0));
    }

    // TF-4407 workaround for train-classic API bug
    // request?.states.map(item => item.displayStatus = 0);

    const url = ApiUrls.getKey('CtrlSingleUserSetStatus')
      .replace('{userId}', String(userId));
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(request), HttpRequestOptions)
      .pipe(catchError(this.handleError))
      .pipe(mapTo(void (0)));
  }

  saveCurriculumForUser(userId: number,
    data: CtrlSingleUserDetailsCurriculumEditTypes.PostDataCurriculum[]): Observable<void> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrEdit')
      .replace(/{userId}/gi, String(userId));
    return this.http.post<ApiResponse<any>>(url, data)
      .pipe(catchError(this.handleError))

      .pipe(map(() => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  sessionDataForUserAndContent(userId: number, contentId: number): Observable<LearningSession[]> {

    const url = ApiUrls.getKey('CtrlUser').replace('{userId}', String(userId)).replace('{courseId}', String(contentId)) + '/sessionData';
    return this.http.get<ApiResponse<LearningSession[]>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.data));
  }

  getCurriculumSteering(userId: number, curriculumId: number):
    Observable<ControllingSingleUserTypes.CurriculumSteering> {

    const url = ApiUrls.getKey('CtrlSingleUserCertificatesSteering')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId));
    return this.http.get<ApiResponse<any>>(url)
      .pipe(map(response => response.steering));
  }

  getCurriculumHistory(userId: number, curriculumId: number): Observable<ControllingSingleUserTypes.CurriculumHistory> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', '0')
      .replace('{action}', 'history');
    return this.http.get<ApiResponse<ControllingSingleUserTypes.CurriculumHistory>>(url)
      .pipe(map(response => response.history));
  }

  getCurriculumIterationStatus(userId: number, curriculumId: number, iteration: number):
    Observable<CtrlSingleUserDetailsCourseTypes.CurriculumStatus> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', String(iteration))
      .replace('{action}', 'status');
    return this.http.get<ApiResponse<CtrlSingleUserDetailsCourseTypes.CurriculumStatus>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.status));
  }

  deleteValidity(userId: number, curriculumId: number, iteration: number): Observable<any> {

    return this.infoService.showMessage($localize`:@@ctrl_single_user_delete_validity:
      Would you like to delete this iteration?`, {
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
      buttons: YesNoButtons,
    })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(take(1))

      .pipe(switchMap(() => {
        const url = ApiUrls.getKey('CtrlSingleUserIterationValidity')
          .replace('{curriculumId}', String(curriculumId))
          .replace('{userId}', String(userId))
          .replace('{iteration}', String(iteration));
        return this.http.delete<ApiResponse<any>>(url);
      }))
      .pipe(catchError(this.handleError))

      .pipe(tap(() => this.infoService.showMessage($localize`:@@ctrl_single_user_delete_confirmed:
          Selected iteration has been deleted successfully`, { infoType: InfoType.Success })));
  }

  resetIteration(userId: number, curriculumId: number): Observable<any> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', '0')
      .replace('{action}', 'reset-current-iteration');
    return this.http.get<ApiResponse<any>>(url)
      .pipe(catchError(this.handleError));
  }

  resetGeneratedCertificate(userId: number, curriculumId: number, iteration: number): Observable<any> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', String(iteration))
      .replace('{action}', 'reset-certificate');
    return this.http.get<ApiResponse<any>>(url)
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_certificate_reset_success:The certificate has been reset.`, { infoType: InfoType.Success });
      }));
  }

  deleteUploadedCertificate(userId: number, curriculumId: number, iteration: number): Observable<any> {

    const formData = new FormData();
    formData.append('dispatch', 'deleteUploadPdfCertificate_v1');
    formData.append('userId', String(userId));
    formData.append('curId', String(curriculumId));
    formData.append('iteration', String(iteration));

    const url = ApiUrls.getKey('UploadPdfCertificateActionV1');
    return this.http.request<any>('POST', url, { body: formData })
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`, { infoType: InfoType.Success });
      }));
  }

  lockCertificate(userId: number, curriculumId: number, iteration: number): Observable<any> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', String(iteration))
      .replace('{action}', 'lock-certificate');
    return this.http.get<ApiResponse<any>>(url)
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@ctrl_single_user_cert_lock_success:
          The certificate has been locked successfully`, { infoType: InfoType.Success });
      }));
  }

  unlockCertificate(userId: number, curriculumId: number, iteration: number): Observable<any> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', String(iteration))
      .replace('{action}', 'unlock-certificate');
    return this.http.get<ApiResponse<any>>(url)
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@ctrl_single_user_cert_unlock_success:
          The certificate has been unlocked successfully`, { infoType: InfoType.Success });
      }));
  }

  uploadCertificatePdf(userId: number, curriculumId: number, iteration: number, file: File): Observable<any> {

    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('dispatch', 'uploadPdfCertificate_v1');
    formData.append('userId', String(userId));
    formData.append('curId', String(curriculumId));
    formData.append('iteration', String(iteration));

    const url = ApiUrls.getKey('UploadPdfCertificateActionV1');
    return this.http.request<any>('POST', url, { body: formData })
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage(MessageConstants.UPLOAD.SUCCESS, { infoType: InfoType.Success });
      }));
  }

  createNewPlannedIteration(curriculumId: number, userId: number):
    Observable<ControllingSingleUserTypes.CertificateIteration> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', '0')
      .replace('{action}', 'create-new');
    return this.http.get<ApiResponse<ControllingSingleUserTypes.CertificateIteration>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.current));
  }

  savePlannedIteration(curriculumId: number, userId: number,
    iteration: ControllingSingleUserTypes.CertificateIteration):
    Observable<ControllingSingleUserTypes.CertificateIteration> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationSave')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId));
    return this.http.post<ApiResponse<ControllingSingleUserTypes.CertificateIteration>>(url, iteration)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.current));
  }

  certifyIteration(curriculumId: number, userId: number, certifyTimestamp: number):
    Observable<ControllingSingleUserTypes.CertificateIteration> {

    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationCertify')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{certifyDate}', String(certifyTimestamp));
    return this.http.get<ApiResponse<ControllingSingleUserTypes.CertificateIteration>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.current));
  }

  finishIteration(curriculumId: number, userId: number): Observable<ControllingSingleUserTypes.CertificateIteration> {
    const url = ApiUrls.getKey('CtrlSingleUserCurrIterationAction')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', '0')
      .replace('{action}', 'finish');
    return this.http.get<ApiResponse<ControllingSingleUserTypes.CertificateIteration>>(url)
      .pipe(catchError(this.handleError))
      .pipe(map(response => response.current));
  }

  showScormLogUserAndSco(user: ControllingSingleUserTypes.ControllingUser,
    course: ControllingSingleUserTypes.CourseAccount): Observable<void> {
    // todo allow selection of specific scoId
    const scoId = course?.courseItems?.[0]?.scoId;
    if ( !(scoId > 0) || !(user?.userId > 0) ) {
      // ignore course without SCOs
      return EMPTY;
    }

    return this.getScormLogUserAndSco(user.userId, scoId)
      .pipe(take(1))

      .pipe(takeWhile(response => {
        const isEmpty = !(response?.scormLogEntries?.length > 0);

        if ( isEmpty ) {
          this.infoService.showMessage($localize`:@@ctrl_single_user_scorm_log_empty:Scorm log is not available yet.`,
            { infoType: InfoType.Warning });
        }
        return !isEmpty;
      }))

      .pipe(switchMap(response => this.infoService
        .showDialog(CtrlSingleUserScormLogComponent, {
          scormLogEntries: response.scormLogEntries,
          userLastname: user.userLastname,
          scoId,
        }, {
          width: '62vw',
        })))

      .pipe(mapTo(void(0)));
  }

  showSessionDataForUserAndContent(user: ControllingSingleUserTypes.ControllingUser,
    course: ControllingSingleUserTypes.CourseAccount): Observable<void> {
    if ( !(user?.userId > 0) || !(course?.courseId > 0) ) {
      // ignore empty values
      return of();
    }

    return this.sessionDataForUserAndContent(user.userId, course.courseId)
      .pipe(map(sessionData => {
        if ( sessionData?.length === 0 ) {
          this.infoService.showAlert($localize`:@@ctrl_single_user_session_data_empty:
            Session data for this user and content is not available yet`);
          return;
        }
        this.infoService.showDialog(LearningSessionDataDialogComponent, {
          course,
          user,
          sessions: sessionData,
        });
      }));
  }

  downloadInteractionsPDF(userId: number, courseId: number): void {
    const interactionsUrl = window.location.origin + ApiUrls.getKey('UserInteractionsReport')
      .replace(/[{]userId[}]/gi, String(userId))
      .replace(/[{]courseId[}]/gi, String(courseId));

    const pdfToHTMLUrl = ApiUrls.getKey('PdfFromHTML')
      .replace(/[{]filename[}]/gi, 'userInteractions.pdf')
      .replace(/[{]fullURL[}]/gi, encodeURIComponent(interactionsUrl));

    window.open(pdfToHTMLUrl, '_blank');
  }

  saveCertificateAccount(userId: number, cert: ControllingSingleUserTypes.CertificateAccountMinimumData): Observable<void> {

    const url = ApiUrls
      .getKey('CtrlSingleUserIterationValidity')
      .replace('{userId}', String(userId))
      .replace('{curriculumId}', String(cert.curriculumId))
      .replace('{iteration}', String(cert.iteration));

    const payload = {
      validSince: cert.validSince,
    };

    if ( cert.validUntil != null && !isNaN(cert.validUntil) ) {
      payload['validUntil'] = cert.validUntil;
    }

    return this.http.post<void>(url, JSON.stringify(payload), HttpRequestOptions)
      .pipe(catchError(this.handleError))

      .pipe(tap(() => {
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
      }));
  }

  setLastToCurrentIteration(curriculumId: number, userId: number) {
    const url = ApiUrls.getKey('CtrlCurriculumLastToCurrent')
      .replace(/[{]curId[}]/gi, String(curriculumId))
      .replace(/[{]userId[}]/gi, String(userId));
    return this.http.request<any>('put', url, { body: null })
    .pipe(catchError(this.handleError));
  }

  setValidityDates(curriculumId: number, userId: number, iteration: number, validSince: string, validUntil: number): Observable<void> {
    const url = ApiUrls.getKey('CtrlCurriculumValidityDates')
      .replace('{curriculumId}', String(curriculumId))
      .replace('{userId}', String(userId))
      .replace('{iteration}', String(iteration));
    const payload = {
      validSince,
      validUntil
    };
    return this.http.post<any>(url, payload, HttpRequestOptions);
  }

  private handleError = (error: HttpErrorResponse): Observable<any> => {
    let message;
    switch ( error?.status ?? 0 ) {
      case 403:
        message = CTRL_SINGLE_USER_MSG.NOT_AUTHORIZED;
        break;
      default:
        message = MessageConstants.ERRORS.GENERAL;
    }

    this.infoService.showMessage(message, { infoType: InfoType.Error });
    return error as any;
  };

  private resetCoursesProgress(userId: number, courseIds: number[]): Observable<void> {
    const url = ApiUrls.getKey('CtrlSingleUserCourseReset')
      .replace(/{userId}/gi, String(userId));
    const queries = courseIds
      .map(courseId => this.http.get(url.replace(/{courseId}/, String(courseId))));
    return this.snackbarProgressService.showProgress(queries);
  }

}
