<div class="map" [class.pointer]="showMap">
  <ng-container *ngIf="showMap; else showMapTempl">
    <iframe
      [src]="imgEmbeddedLocation | safe:'resourceUrl'"
      allowfullscreen
      frameborder="no"
      height="100%"
      width="100%">
    </iframe>
  </ng-container>

  <div *ngIf="showCoordinates" class="geo-coordinates">
    {{location.latitude}}, {{location.longitude}}
  </div>
</div>

<ng-template #showMapTempl>
  <button
    mat-stroked-button
    (click)="onShowMap($event)">

    <mat-icon svgIcon="google-maps"></mat-icon>
    <span i18n="@@show_map">Show map</span>
  </button>
</ng-template>
