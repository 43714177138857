<rag-dialog-header [closingEnabled]="step !== dialogSteps.LoadingStep">
  <span i18n="@@global_self_registration">Self registration</span>
</rag-dialog-header>


<mat-dialog-content>
  <div *ngIf="step === dialogSteps.RegistrationStep" class="height">

    <div class="description">
      <span>{{data?.description ?? ''}}</span>
    </div>

    <form [formGroup]="form">
      <mat-form-field appearance="outline" class="registration-code">
        <mat-label i18n="@@global_registration_code">Registration code</mat-label>
        <input
          formControlName="code"
          matInput
          type="text"
          required
        >
      </mat-form-field>
    </form>
  </div>

  <div *ngIf="step === dialogSteps.LoadingStep" class="loading height">
    <rag-loading-indicator></rag-loading-indicator>
  </div>

  <div *ngIf="step === dialogSteps.SuccessStep" class="height success">
    <span i18n="@@global_successfully_registred">You have been successfully registered.</span>
    <span class="margin-bottom" i18n="@@global_note_login_details">Please make a note of your login details.</span>

    <div class="bold" >
      <span i18n="@@global_username">Username</span>
      <span> {{createdUserName}}</span>
    </div>

    <div class="margin-bottom bold">
      <span i18n="@@global_password">Password</span>
      <span> {{createdPassword}}</span>
    </div>

    <span i18n="@@global_automatically_login_text">Click on "Login" to be automatically logged in with your data.</span>
  </div>
</mat-dialog-content>


<mat-dialog-actions *ngIf="step !== dialogSteps.LoadingStep">
  <button
    type="button"
    mat-stroked-button
    [matDialogClose]="null"
  >
    <span i18n="@@global_cancel">Cancel</span>
  </button>
  <button
    type="button"
    mat-flat-button
    color="primary"
    (click)="onRegister()"
    [disabled]="form.invalid || (loginButtonDisabled$ | async)"
  >
    <span *ngIf="step === dialogSteps.RegistrationStep" i18n="@@global_register">Register</span>
    <span *ngIf="step === dialogSteps.SuccessStep" i18n="@@global_login">Login</span>
  </button>
</mat-dialog-actions>
