import { Color, ScatterDataPoint } from 'chart.js';


export interface LearningTimeChartOptions {
  axisType?: 'linear' | 'logarithmic';
  showLastMonths?: number;
  showLegend?: false | 'bottom' | 'right';
  stacked?: boolean;
  unit?: 'month' | 'quarter';
}

export interface LearningTimeChartDataSet {
  backgroundColor: Color;
  data: ScatterDataPoint[];
  label: string;
  stack: string;
}

export const DEFAULT_OPTIONS: LearningTimeChartOptions = {
  axisType: 'linear',
  showLastMonths: 12,
  showLegend: false,
  unit: 'quarter',
};
