import { Component, OnInit } from '@angular/core';
import { Badge, GamificationProfile, ReducedBadge } from '../../../core/gamification/gamification.types';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { LearnerGamificationService } from '../../../core/gamification/learner-gamification.service';
import { Core } from '../../../core/core.types';

@Component({
  selector: 'rag-gamification-overview',
  templateUrl: './gamification-overview.component.html',
  styleUrls: ['./gamification-overview.component.scss']
})
export class GamificationOverviewComponent implements OnInit {
  gamificationProfile: GamificationProfile;
  badges: ReducedBadge[];
  xpForNextLevel: number;
  learningBadges: ReducedBadge[] = [];
  curriculumBadges: ReducedBadge[] = [];
  offlineContentBadges: ReducedBadge[] = [];
  selectedIndex = 0;
  progressBarValue = 0;

  constructor(
    private learnerGamificationService: LearnerGamificationService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.data
      .pipe(map(response => this.updateRouteData(response.data)))
      .pipe(take(1))
      .subscribe();
  }

  getBadgesForType(): ReducedBadge[] {
    switch ( this.selectedIndex ) {
      case 0:
        return this.learningBadges;
      case 1:
        return this.curriculumBadges;
      case 2:
        return this.offlineContentBadges;
    }
  }

  getXpForNextLevelTooltip(): string {
    return `${this.gamificationProfile?.xp} / ${this.xpForNextLevel} xp`;
  }

  private initBadges(): void {
    this.badges
      .forEach(badge => {
        switch ( badge.objectType ) {
          case Core.DistributableType.lms_course:
            this.learningBadges.push(badge);
            return;
          case Core.DistributableType.lms_curriculum:
            this.curriculumBadges.push(badge);
            return;
          case Core.DistributableType.lms_offlineCnt:
            this.offlineContentBadges.push(badge);
            return;
        }
      });
  }

  private updateRouteData(data): void {
    this.gamificationProfile = data.gamificationProfile;
    this.badges = data.badges;
    this.xpForNextLevel = this.learnerGamificationService
      .getXpForGamificationLevel(this.gamificationProfile?.level + 1);
    this.progressBarValue = (((this.gamificationProfile?.xp ?? 0) / this.xpForNextLevel) * 100);
    this.initBadges();
  }
}
