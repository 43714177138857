import { Observable } from 'rxjs';
import { ViewData } from '../../core/view-helper';
import { Core, PrincipalType } from '../../core/core.types';

export namespace AssignmentDialogTypes {

  export const ASSIGNMENT_DIALOG_CATEGORIES: AssignmentDialogTypes.AssignmentEntryCategory<PrincipalType>[] = [ {
    icon: 'account-group',
    type: PrincipalType.targetGroup,
  }, {
    icon: 'account',
    type: PrincipalType.user,
  } ];

  export interface AssignmentDialogData<T, C = any> {
    allowSortingInContainers?: boolean;
    data?: AssignmentDialogEntries<T, C>;
    enableAssignmentType?: boolean;
    i18n: AssignmentDialogTranslations;
    query$?: Observable<AssignmentDialogEntries<T, C>>;
  }

  export interface AssignmentDialogEntries<T, C = any> {
    available: AssignmentEntry<T, C>[];
    categories?: AssignmentEntryCategory<C>[];
    maxSelections?: number;
    selected: AssignmentEntry<T, C>[];
  }

  export interface AssignmentEntry<T, C = any> {
    $view?: ViewData;
    changed?: boolean;
    selected: boolean;
    title: string;
    type: C;
    value: T;
    disabled?: boolean;
  }

  export interface AssignmentEntryCategory<C> {
    icon: string;
    orderIndex?: number;
    type: C;
  }

  export interface AssignmentEntryCategoryMap<C> {
    [key: string]: AssignmentDialogTypes.AssignmentEntryCategory<C>;
  }

  export interface AssignmentDialogTranslations {
    available: string;
    search: string;
    selected: string;
    title: string;
    tooManySelections: string;
  }

  export interface AssignmentInfo {
    assignmentLocked?: boolean;
    assignmentType: Core.DistAssignmentType;
    principalId: number;
    principalName: string;
    principalType: PrincipalType;
  }

}
