<button
  #tooltip="matTooltip"
  *ngIf="hasInfo"
  (click)="tooltip?.toggle()"
  [matTooltip]="infoText"
  mat-icon-button
  type="button"
>
  <mat-icon svgIcon="information-outline"></mat-icon>
</button>
