<span i18n="@@admin_permission_concept_text">Configure the DOCX file here,
  which lists all the authorizations assigned in the system at this time,
  sorted by role, at the push of a button.
  To do this, upload a file that contains general explanations at the beginning (part 1),
  e.g. on the principle of assigning authorizations.
  The data read out for the authorizations in the system are then automatically attached as part 2 of the document,
  unless you have excluded them in the configuration.</span>
<div class="container">
    <div class="left">
      <span class="strong" i18n="@@docx_template">Docx Template</span>
    <rag-file
      [actionButtonsDisabled]="false"
      [file]="selectedFile"
      [fileInfo]="fileInfo"
      [acceptTypes]="[
       'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ]"
      (selectedFile)="onSelectedFile($event)"
      class="file"
      (removeFile)="onRemoveFile()"
    >

    </rag-file>
  </div>
  <div class="right">
    <span class="strong" i18n="@@admin_excluded_roles">Excluded roles</span>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@admin_excluded_roles">Excluded roles</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let column of selectedExcludedRoles"
          [removable]="true"
          (removed)="removeExcludedRole(column)"
          [value]="column"
        >
          {{permissionNames[column] | localeText}}
          <mat-icon matChipRemove svgIcon="close"></mat-icon>
        </mat-chip-row>
        <input
          i18n-placeholder="@@admin_excluded_roles"
          placeholder="Excluded roles"
          #columnInput
          [formControl]="excludedRolesFormControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        >
      </mat-chip-grid>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedColumn($event); columnInput.blur();">
        <mat-option *ngFor="let column of filteredRoles$ | async" [value]="column">
          {{permissionNames[column] | localeText}}
        </mat-option>
      </mat-autocomplete>
      <mat-icon
        matIconSuffix
        svgIcon="information-outline"
        [matTooltip]="exclusionCriteriaText"
      ></mat-icon>
    </mat-form-field>
  </div>
</div>
