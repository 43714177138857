<rag-dialog-header [closingEnabled]="false">
  <span i18n="@@global_information">Information</span>
</rag-dialog-header>

<div mat-dialog-content>
  <div>
    <rag-clamp
      [clamp]="5"
      [input]="content.description | localeText"></rag-clamp>
  </div>
</div>

<div mat-dialog-actions>
  <!-- Start new iteration -->
  <button
    [mat-dialog-close]
    mat-stroked-button>
    <span i18n="@@global_close">Close</span>
  </button>
</div>
