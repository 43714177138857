import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminPermissionConceptService } from './admin-permission-concept.service';
import { inject } from "@angular/core";
import { PermissionConceptSettings } from './admin-permission-concept.types';

export const AdminPermissionConceptResolver: ResolveFn<PermissionConceptSettings> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  adminPermissionConceptService: AdminPermissionConceptService = inject(AdminPermissionConceptService)
): Observable<PermissionConceptSettings> => {
  return adminPermissionConceptService.getPermissionConceptSettings();
}
