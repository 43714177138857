import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminSelfRegistrationComponent } from './admin-self-registration.component';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { FileComponent } from 'src/app/component/file/file.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';


@NgModule({
  declarations: [
    AdminSelfRegistrationComponent
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    MatButtonModule,
    PipesModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    FileComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    MatSelectModule,
  ],
})
export class AdminSelfRegistrationModule { }
