import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { ComponentsModule } from './components/components.module';
import { ExtLoginsComponent } from './ext-logins.component';

@NgModule({
  declarations: [
    ExtLoginsComponent,
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    ComponentsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatDividerModule
  ],
})
export class ExtLoginsModule {
}
