import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminNotificationsDetailsComponent } from './admin-notifications-details.component';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { AdminNotificationsDetailsContentModule } from '../admin-notifications-details-content/admin-notifications-details-content.module';
import { AdminNotificationsDetailsContentComponent } from '../admin-notifications-details-content/admin-notifications-details-content.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AdminEventActionsNavigationModule } from '../../admin-event-actions-navigation/admin-event-actions-navigation.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';

@NgModule({
  declarations: [
    AdminNotificationsDetailsComponent
  ],
  imports: [
    CommonModule,
    PageHeaderComponent,
    PipesModule,
    MatButtonModule,
    AdminNotificationsDetailsContentModule,
    MatSidenavModule,
    RouterModule,
    AdminEventActionsNavigationModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [
    AdminNotificationsDetailsComponent,
    AdminNotificationsDetailsContentComponent,
  ]
})
export class AdminNotificationsDetailsModule { }
