import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControllingCurriculumComponent } from '../controlling-curriculum/controlling-curriculum.component';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';


@Component({
  selector: 'rag-controlling-curriculum-multi-actions',
  templateUrl: './controlling-curriculum-multi-actions.component.html',
  styleUrls: [ './controlling-curriculum-multi-actions.component.scss' ],
})
export class ControllingCurriculumMultiActionsComponent
  implements OnChanges {

  @Input() controllingComponent: ControllingCurriculumComponent;
  editUserUrl: string;
  maySaveUser = false;
  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;

  multiActionsDisabled(): boolean {

    if ( this.controllingComponent?.inputDisabled ) {
      return true;
    }

    if ( this.maySaveUser ) {
      return false;
    }

    return this.controllingComponent?.multiActionsDisabled ||
      this.controllingComponent?.canChangeData === false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const controllingUser = this.userDetailsView?.controllingUser;
    this.maySaveUser = (controllingUser?.userId > 0) && controllingUser?.rbacActions?.maySave;
    this.editUserUrl = this.maySaveUser ? `/admin/user/edit/${controllingUser.userId}` : '';
  }

}
