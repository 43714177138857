import { TableColumnDataType, TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { TableGroupingTypes } from '../../table/table-grouping/table-grouping.types';
import { naturalCompare } from '../../../core/natural-sort';
import { filterNumberV2, FilterOperator, filterTextV2 } from '../../../core/column-settings/column-filter.types';
import { TableGroupingHelper } from '../../table/table-grouping/table-grouping.helper';
import { IBasisUserName } from 'src/app/core/principal/principal.types';
import { CurriculumPathSwitchTypes } from 'src/app/core/curriculum/curriculum-path-switch.types';


export namespace CurriculumPathSwitchColumns {

  export type RowData = TableGroupingTypes.TableRow<CurriculumPathSwitchTypes.CurriculumSwitchData,
      CurriculumPathSwitchTypes.UserInfo>;

  export interface RowDataParent
    extends RowData, TableGroupingTypes.TableRowParent<CurriculumPathSwitchTypes.CurriculumSwitchData,
      CurriculumPathSwitchTypes.UserInfo> {
    $data: CurriculumPathSwitchTypes.CurriculumSwitchData;
    formControl: string;
  }

  export interface RowDataChild
    extends RowData, IBasisUserName, TableGroupingTypes.TableRowChild<CurriculumPathSwitchTypes.CurriculumSwitchData,
      CurriculumPathSwitchTypes.UserInfo> {
    $data: CurriculumPathSwitchTypes.UserInfo;
    currentPath: string;
    currentVariationCounter: number;
    userId: number;
  }

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    currentPath: TableControllerTypes.ColumnMenuItem;
    firstName: TableControllerTypes.ColumnMenuItem;
    lastName: TableControllerTypes.ColumnMenuItem;
    userId: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [],
    endWith: [],
    menuItems: {
      userId: {
        id: 'userId',
        orderIndex: 0,
        options: {
          dataType: TableColumnDataType.number,
          filter: { identifier: 'userId', action: FilterOperator.EQ, value: null },
          filterMethod: filterNumberV2,
        },
        selected: true,
        title: $localize`:@@global_model_user_userId:User ID`,
      },
      lastName: {
        id: 'lastName',
        orderIndex: 1,
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'lastName', action: FilterOperator.LIKE, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@global_model_user_userLastname:Last name`,
      },
      firstName: {
        id: 'firstName',
        orderIndex: 2,
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'firstName', action: FilterOperator.LIKE, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@global_model_user_userFirstname:First name`,
      },
      currentPath: {
        id: 'currentPath',
        orderIndex: 3,
        selected: true,
        options: {
          dataType: TableColumnDataType.text,
          filter: { identifier: 'currentPath', action: FilterOperator.LIKE, value: null },
          filterMethod: filterTextV2,
        },
        title: $localize`:@@curriculum_path_switch_column_current_path:Current path`,
      },
    },
  };

  export class Util {

    static curriculumToColumns(curriculum: CurriculumPathSwitchTypes.CurriculumSwitchData): RowDataParent {
      const parent: RowDataParent = {
        $children: [],
        $data: curriculum,
        $rowSort: [ curriculum.curriculumId ],
        $rowType: 'parent',

        formControl: String(curriculum.curriculumId),
      };

      parent.$parentRoot = parent;
      return parent;
    }

    static toTableRows<P extends CurriculumPathSwitchTypes.CurriculumSwitchData,
      C extends CurriculumPathSwitchTypes.UserInfo>(curricula: P[]): RowData[] {
      const rows = curricula
        .sort((a, b) => naturalCompare(a.curriculumTitle, b.curriculumTitle))
        .flatMap(curriculum => {
          const parentRow = Util.curriculumToColumns(curriculum);
          const childRows = curriculum.users.map(user => Util.userToColumns(user, parentRow))
            .sort(TableGroupingTypes.Util.compareRowSort);
          return [ parentRow, ...childRows ];
        });

      // calculate string to keep sorting stable in grouped tables
      TableGroupingHelper.addDefaultSorting(rows);

      return rows;
    }

    static userToColumns(user: CurriculumPathSwitchTypes.UserInfo, parent: RowDataParent): RowData {
      const row: RowDataChild = {
        $data: user,
        $parent: parent,
        $rowSort: TableGroupingTypes.Util.calculateRowSort(user.userId, parent),
        $rowType: 'child',

        currentPath: user.curriculumVariationTitle,
        currentVariationCounter: user.curriculumVariationCounter,
        firstName: user.userFirstname,
        lastName: user.userLastname,
        userId: user.userId,
      };

      row.$parentRoot = parent.$parentRoot;
      parent.$parentRoot.$maxDepth = Math.max(parent.$parentRoot.$maxDepth || 0, row.$rowSort.length);
      parent.$children.push(row);
      return row;
    }

  }

}
