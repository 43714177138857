import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LearnerCockpitContactDialogComponent } from './learner-cockpit-contact-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [ LearnerCockpitContactDialogComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    RouterModule,
    MatIconModule,
  ]
})
export class LearnerCockpitContactDialogModule { }
