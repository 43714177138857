<div class="filter-box " [ngClass]="{'new-design-border': outsourcedFilter}">

  <mat-form-field appearance="outline" class="filter-box " [ngClass]="{'new-design': outsourcedFilter}">
    <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>

    <input
      (keydown)="onKeyDown($event)"
      (ngModelChange)="onChange($event)"
      (blur)="onBlur()"
      [formControl]="formControl"
      [name]="filter.identifier"
      [pattern]="pattern"
      [placeholder]="placeholderText"
      inputmode="numeric"
      matNativeControl
      type="text"
      [title]="''"
      i18n-matTooltip="@@global_price_filter_tooltip"
      matTooltip='Only integer inputs are allowed'
      [matTooltipDisabled]="numberMatchRegex()"
    />

    <button
      (click)="clearValue()"
      [class.hidden]="!hasChanged"
      mat-icon-button
      matSuffix
      matTooltip="revert filter to default"
      i18n-matTooltip="@@pivot_filter_tooltip_revert"
      [matTooltipDisabled]="!hasDefaultValue"
    >
      <mat-icon *ngIf="hasDefaultValue" svgIcon="restore"></mat-icon>
      <mat-icon *ngIf="!hasDefaultValue" svgIcon="close"></mat-icon>
    </button>
  </mat-form-field>

  <button
    *ngIf="showActions"
    [matMenuTriggerFor]="menu"
    mat-icon-button
    type="button"
  >
    <mat-icon *ngIf="!hasValue" svgIcon="filter-outline"></mat-icon>
    <mat-icon *ngIf="hasValue" svgIcon="filter"></mat-icon>

    <mat-menu #menu>
      <ng-template matMenuContent>
        <mat-radio-group
          [(ngModel)]="action"
          (ngModelChange)="setAction($event)"
        >
          <div mat-menu-item *ngFor="let actionOption of actionOptions">
            <mat-radio-button
              [value]="actionOption.value"
            >{{actionOption.label}}</mat-radio-button>
          </div>
        </mat-radio-group>
      </ng-template>
    </mat-menu>
  </button>

</div>
