import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AccountDesignService } from '../route/admin/account-design/account-design.service';
import { ApiUrls } from './api.urls';
import { ReportConfig } from './report/report.types';
import { LearningTimeChartOptions } from '../component/chart/learning-time-chart/learning-time-chart.types';


@Injectable({
  providedIn: 'root',
})
export class ExportService {

  constructor(
    private accountDesignService: AccountDesignService,
    private http: HttpClient,
  ) {
  }

  static fileNameByReportConfig(prefix: string = '', suffix: string = '', reportConfig: ReportConfig): string {
    let fileName = prefix;
    if ( reportConfig.title ) {
      fileName += '_' + reportConfig.title;
    }
    const now = moment();
    fileName += '_' + now.format('HH_mm') + suffix;
    return fileName;
  }

  zipExport(
    reportConfig: ReportConfig,
  ): void {
    forkJoin([
      this.getReportTitle(reportConfig, 'zip'),
      this.downloadZip(reportConfig),
    ])
      .pipe(tap(([ fileName, zip ]) => {
        const fileType = 'application/zip;charset=UTF-8';
        const zipBlob = new Blob([ zip ], { type: fileType });
        FileSaver.saveAs(zipBlob, fileName);
      }))
      .pipe(take(1))
      .subscribe();
  }

  csvExport(
    reportConfig: ReportConfig,
  ): void {
    forkJoin([
      this.getReportTitle(reportConfig, 'csv'),
      this.downloadCsv(reportConfig),
    ])
      .pipe(tap(([ fileName, csv ]) => {
        const csvBlob = new Blob([ csv ], { type: 'text/csv;charset=utf-8' });
        FileSaver.saveAs(csvBlob, fileName);
      }))
      .pipe(take(1))
      .subscribe();
  }

  xlsxExport(
    reportConfig: ReportConfig,
  ): void {
    forkJoin([
      this.getReportTitle(reportConfig, 'xlsx'),
      this.downloadXlsx(reportConfig),
    ])
      .pipe(tap(([ fileName, xlsxExport ]) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const xlsxBlob = new Blob([ xlsxExport ], { type: fileType });
        FileSaver.saveAs(xlsxBlob, fileName);
      }))
      .pipe(take(1))
      .subscribe();
  }

  xlsxExportLearningTime(
    reportConfig: ReportConfig | null,
    options?: LearningTimeChartOptions,
  ): void {

    if ( (reportConfig == null) ) {
      return;
    }

    let notBeforeCount = options?.showLastMonths ?? 12;
    let notBeforeUnit = 'MONTH';
    if ( (options?.unit ?? 'quarter') === 'quarter' ) {
      notBeforeCount /= 3;
      notBeforeUnit = 'QUARTER';
    }

    const url = ApiUrls.getKey('ReportTimelineChartXLSX')
      .replaceAll(/{notBeforeCount}/gi, String(notBeforeCount))
      .replaceAll(/{notBeforeType:MONTH\|QUARTER}/gi, notBeforeUnit);
    forkJoin([
      this.getReportTitle(reportConfig, 'xlsx'),
      this.downloadXlsx(reportConfig, url),
    ])
      .pipe(tap(([ fileName, xlsxExport ]) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const xlsxBlob = new Blob([ xlsxExport ], { type: fileType });
        FileSaver.saveAs(xlsxBlob, fileName);
      }))
      .pipe(take(1))
      .subscribe();
  }

  private downloadZip(
    reportConfig: ReportConfig,
    url?: string,
  ): Observable<any> {

    if ( !url ) {
      url = `${ApiUrls.getKey('Export')}/export.zip`;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(url, JSON.stringify(reportConfig), { headers, responseType: 'blob' })
      .pipe(take(1));
  }

  private downloadCsv(
    reportConfig: ReportConfig,
  ): Observable<string> {
    const url = `${ApiUrls.getKey('Export')}/export.csv`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .request('POST', url, {
        headers,
        body: JSON.stringify(reportConfig),
        responseType: 'text',
      })
      .pipe(take(1));
  }

  private downloadXlsx(
    reportConfig: ReportConfig,
    url?: string,
  ): Observable<any> {

    if ( !url ) {
      url = `${ApiUrls.getKey('Export')}/export.xlsx`;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(url, JSON.stringify(reportConfig), { headers, responseType: 'blob' })
      .pipe(take(1));
  }

  private getReportTitle(
    reportConfig: ReportConfig,
    extension: 'csv' | 'xlsx' | 'zip',
  ): Observable<string> {
    return this.accountDesignService.getStyleSettings(false)
      .pipe(map(settings => settings?.acc?.pageTitle || 'export'))
      .pipe(map(prefix => ExportService.fileNameByReportConfig(prefix, `.${extension}`, reportConfig)))
      .pipe(take(1));
  }

}
