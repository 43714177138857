import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import * as moment from 'moment';
import { Moment } from 'moment';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { DateHelper } from '../../core/date.helper';
import { MyValidators } from '../../core/validators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
  ],
  selector: 'rag-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: [ './date-time.component.scss' ],
})
export class DateTimeComponent
  implements OnInit, OnDestroy {

  form: UntypedFormGroup;

  @Input() dateLabel: string;
  @Input() timeLabel: string;
  @Input() dateOnly = false;
  @Input() required = true;
  @Output() validityChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChanges: EventEmitter<Moment> = new EventEmitter<Moment>();

  private _lessThan: DateTimeComponent;
  private _dateTime: Moment;
  private _greatThan: DateTimeComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  @Input()
  set greatThan(value: DateTimeComponent) {
    this._greatThan = value;
  }

  get greatThan() {
    return this._greatThan;
  }

  @Input()
  set lessThan(value: DateTimeComponent) {
    this._lessThan = value;
  }

  get lessThan() {
    return this._lessThan;
  }

  @Input()
  set dateTime(value: Moment) {
    if (this._dateTime !== undefined) {
      // for some reasons this setter get executed multiple times with initial value when
      // the user switches between the time components
      return;
    }

    this._dateTime = DateHelper.toMoment(value);
  }

  get dateTime() {
    return this._dateTime;
  }

  ngOnInit(): void {
    if ( this._dateTime == null ) {
      if (this.required) {
        this.validityChanges.emit(false);
      }
    }
    const dateValidators: Array<ValidatorFn> = [];
    const timeValidators: Array<ValidatorFn> = [];

    if (this.required) {
      dateValidators.push(Validators.required);
    }
    dateValidators.push(this.referenceDateCompValidator);

    if (!this.dateOnly && this.required) {
      timeValidators.push(Validators.required);
    }
    timeValidators.push(MyValidators.TimeValidator);
    timeValidators.push(this.referenceTimeCompValidator);

    this.form = this.formBuilder.group({
      date: [ this._dateTime, dateValidators ],
      time: [ this._dateTime?.format('HH:mm'), timeValidators ],
    });

    this.form.valueChanges
      .pipe(tap(() => this.emitChanges()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  emitChanges = () => {
    const isValid = this.form.valid;
    this.validityChanges.emit(isValid);
    if ( isValid ) {
      this.valueChanges.emit(this._dateTime);
    }
  };

  hasError(componentName: string, errorCode: string): boolean {
    return this.form?.get(componentName)?.hasError(errorCode);
  }

  /**
   * Validators get executed bevor the form is created
   * @param c form control
   */
  referenceDateCompValidator = (c: UntypedFormControl) => {

    const date: moment.Moment = c.value;
    if ( date == null ) {
      if ( this.required ) {
        return { required: true };
      } else {
        this._dateTime = null;
        return;
      }
    }

    if (this.form == null) {
      // no form, no errors
      return null;
    }

    // add the time to this date time
    this.setTime(date);

    if (this.dateOnly) {
      // handle the case the user select only a date. In this case we need
      // to manipulate the time regarding lessThan or greatThan fields in order
      // to support same selected dates
      if (this.lessThan != null) {
        date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      } else if (this.greatThan != null) {
        date.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999);
      }
    }
    // set the datime no matter if it is invalid
    this._dateTime = date;

    const validationError = this.checkReferencedComponents(date);
    if ( validationError != null ) {
      return validationError;
    }
    this.resetError();
    return null;
  };

  referenceTimeCompValidator = (c: UntypedFormControl) => {

    if (this.form == null) {
      // no form, no errors
      return null;
    }

    const time: string = c.value;
    if (time == null || time === '') {
      return null;
    }
    const pairs = time.split(':');
    if ( pairs.length === 2 ) {

      // parse hours & validate
      const hours = parseInt(pairs[0], 10);
      if (hours < 0 || hours > 23) {
        return {
          timeFormat: true
        };
      }

      // parse minutes & validate
      const minutes = parseInt(pairs[1], 10);
      if (minutes < 0 || minutes > 59) {
        return {
          timeFormat: true
        };
      }

      const dateTime = this.form.get('date')?.value;
      if (dateTime != null) {
        dateTime
          .set('hour', hours)
          .set('minutes', minutes);
      }

      this._dateTime = dateTime;
      const validationError = this.checkReferencedComponents(dateTime);
      if ( validationError != null ) {
        return validationError;
      }
      this.resetError();
      return null;
    }

    return {
      timeFormat: true,
    };
  };

  resetError() {
    if ( this.form == null ) {
      return;
    }

    const hasAtleastOneError = (
      Object.keys(this.form.get('date').errors ?? {}).length +
      Object.keys(this.form.get('time').errors ?? {}).length
      ) > 0;

    if (!hasAtleastOneError) {
      return;
    }

    this.form.get('date').setErrors(null);
    this.form.get('time').setErrors(null);
    this.valueChanges.emit(this._dateTime);
    this.validityChanges.emit(true);
  }

  private setTime(dateTime: Moment) {
    if (this.form === undefined) {
      return;
    }
    const pairs = this.form.get('time').value;
    if (pairs != null) {
      const timeStr = pairs.split(':');
      if (timeStr.length === 2) {
        const hours = parseInt(timeStr[0], 10);
        const minutes = parseInt(timeStr[1], 10);
        dateTime
          .set('hours', hours)
          .set('minutes', minutes);
        this._dateTime = dateTime;
      }
    }
  }

  private checkReferencedComponents = (dateTime: moment.Moment) => {
    if ( this.lessThan != null && this.lessThan.dateTime != null) {
      // console.log(dateTime.format('lll'), this.lessThan._dateTime.format('lll'))
      if ( dateTime?.isValid() && this.lessThan.dateTime.isValid() ) {
        if ( dateTime?.isSameOrAfter(this.lessThan.dateTime) ) {
          return {
            invalid: true,
          };
        } else {
          this.lessThan.resetError();
        }
      }
    }
    if ( this.greatThan != null && this.greatThan.dateTime != null ) {
      // console.log(this.greatThan.dateTime.format('lll'), dateTime.format('lll'))
      if ( dateTime.isValid() && this.greatThan.dateTime.isValid() ) {
          if (dateTime.isSameOrBefore(this.greatThan.dateTime) ) {
            return {
              invalid: true,
            };
          } else {
            this.greatThan.resetError();
          }
      }
    }
    return null;
  };

}
