import { Component, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../../component/page-header/page-header.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import {
  AdminGamificationSettings,
  AdminGamificationSettingsSaveData,
} from '../../../../core/gamification/gamification.types';
import { AdminGamificationService } from '../../../../core/gamification/admin-gamification.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants } from '../../../../core/info/info.types';
import { EMPTY } from 'rxjs';
import { LearnerGamificationService } from '../../../../core/gamification/learner-gamification.service';

@Component({
  standalone: true,
  imports: [
    PageHeaderComponent,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  selector: 'rag-admin-gamification-settings',
  templateUrl: './admin-gamification-settings.component.html',
  styleUrls: ['./admin-gamification-settings.component.scss']
})
export class AdminGamificationSettingsComponent implements OnInit {

  form: UntypedFormGroup;
  gamificationSettings: AdminGamificationSettings;

  constructor(
    private adminGamificationService: AdminGamificationService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private learnerGamificationService: LearnerGamificationService,
  ) {
    this.gamificationSettings = {
      enabled: false,
    }

    this.learnerGamificationService.gamificationEnabled$
      .pipe(map(gamificationEnabled => this.gamificationSettings.enabled = gamificationEnabled))
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit() {
    this.buildFormGroup();
  }

  onSaveSettings(): void {
    const saveData: AdminGamificationSettingsSaveData = {
      enabled: this.form.get('enabled').value,
    }
    this.adminGamificationService.saveAdminGamificationSettings(saveData)
      .pipe(map(response => {
        this.gamificationSettings = response;
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success
        })
      }))
      .pipe(catchError(() => {
        this.infoService.showMessage(MessageConstants.API.ERROR, {
          infoType: InfoType.Error,
        })
        return EMPTY;
      }))
      .pipe(take(1))
      .subscribe();
  }

  private buildFormGroup(): void {
    this.form = this.formBuilder.group({
      enabled: [this.gamificationSettings.enabled],
    })
  }
}
