<div class="container">

  <span *ngIf="hasCaption()">{{fragment.caption}}</span>

  <!-- header -->
  <div class="row">
    <div class="text">&nbsp;</div>
    <div class="options">
      <div class="option header" *ngFor="let option of fragment.options">
        <span>{{option.label}}</span>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let item of fragment.items">

    <form [formGroup]="form">
      <div class="text">
        <span>{{item.text}}</span>
      </div>
      <div class="options">
        <mat-radio-group class="radio-options" [formControlName]="item.uuid">
          <div class="option" *ngFor="let option of fragment.options">
            <mat-radio-button class="radio" [value]="option.value"></mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </form>

  </div>

</div>