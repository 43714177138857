<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="onColumnsChanged($event)"
  [menuData$]="columnMenuData$"
  [updateOnClose]="true"
></rag-table-column-menu>


<mat-menu #childActionsMenu>
  <ng-template matMenuContent let-row="row">
    <rag-report-table-single-actions
      [reportConfig]="reportConfig"
      [inputDisabled]="inputDisabled"
      [row]="row"
    ></rag-report-table-single-actions>
  </ng-template>
</mat-menu>

<rag-participant-offline-menu
  #menus
  [offlineContent]="offlineContent"
  [eventSchedule]="eventSchedule"
  (finished)="onMenuFinished()"
  (removeParticipant)="onOfflineRemoveParticipant($event)"
></rag-participant-offline-menu>


<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplFilterDefault let-column="column">
  <rag-table-header-filter-default
    [column]="column"
    (filterChange)="onFilterChange($event.filter, column)"
  ></rag-table-header-filter-default>
</ng-template>

<ng-template #tplFilterDisplayStatus let-column="column">
  <rag-pivot-filter-account-display-status
    [filter]="column.options.filter"
    (change$)="onFilterChange($event, column)"
    [debounceTimeout]="0"
    defaultAction="$eq"
  ></rag-pivot-filter-account-display-status>
</ng-template>

<rag-table-filter
  (filterChange)="onFilterChange($event.filter, $event.column)"
  [menuData]="columnMenuData"
  [tableId]="targetType != null ? ('report-table-' + targetType) : null"
></rag-table-filter>

<rag-report-table-controls
></rag-report-table-controls>

<ng-container
  *ngIf="isDataLoaded else tplDataLoading"
>
  <cdk-virtual-scroll-viewport [itemSize]="60" headerHeight="60">
    <table
      *ngIf="hasTableData"
      [dataSource]="dataSource"
      mat-table
      matSort
    >

      <ng-container matColumnDef="checkboxFilter">
        <th *matHeaderCellDef class="batch-checkbox" mat-header-cell></th>
      </ng-container>

      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
        <th *matHeaderCellDef mat-header-cell>
          <ng-container
            *ngTemplateOutlet="getFilterTemplate(
              column,
              tplFilterDefault,
              tplFilterDisplayStatus
            ); context: {column: column}"
          ></ng-container>
        </th>
      </ng-container>

      <ng-container matColumnDef="actionsFilter">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button
            (click)="onResetFilter()"
            [disabled]="!isFilterChanged"
            mat-icon-button
            type="button"
          >
            <mat-icon svgIcon="filter-off-outline"></mat-icon>
          </button>
        </th>
      </ng-container>


      <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
      <tr *matRowDef="let row; columns: parentColumns; when: isParentRow" mat-row class="parent"
          [ngClass]="{'filter-no-match': !isFilterMatch(row)}"></tr>
      <tr *matRowDef="let row; columns: columns; when: isChildRow" mat-row class="child"
          [ngClass]="{'filter-no-match': !isFilterMatch(row)}"></tr>

      <ng-container matColumnDef="checkbox">
        <th *matHeaderCellDef mat-header-cell class="batch-checkbox">
          <mat-checkbox
            (change)="onToggleAll()"
            [checked]="isSelectAllChecked()"
            [disabled]="isSelectAllDisabled()"
            [indeterminate]="isSelectAllIndeterminate()"
          ></mat-checkbox>
        </th>
        <td *matCellDef="let row" mat-cell class="batch-checkbox">
          <mat-checkbox
            (change)="onToggleSelection($event, row)"
            [checked]="isRowChecked(row)"
            [indeterminate]="isRowIndeterminate(row)"
            [disabled]="!hasActions(row)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="parent">
        <td *matCellDef="let row" mat-cell [colSpan]="columns.length - 2" class="overflow-auto">
          <ng-container
            *ngIf="asParent(row) as parentRow"
          >
            <rag-report-table-grouping-value
              *ngIf="getTableColumn(parentRow.column) as column"
              [column]="column"
              [row]="parentRow"
              [targetType]="reportConfig?.targetType"
            ></rag-report-table-grouping-value>
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header [disabled]="isSortingDisabled(column)">
          {{column.title | localeText}}
        </th>
        <td *matCellDef="let row" mat-cell class="data-column">
          <rag-tables-column-render-default
            [column]="column"
            [row]="row"
            [targetType]="targetType"
          ></rag-tables-column-render-default>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
            type="button"
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
        </th>
        <td *matCellDef="let row" class="actions" mat-cell>
          <rag-spacer
            *ngIf="isParent(row)"
            [widthInPx]="40"
          ></rag-spacer>

          <button
            *ngIf="isChild(row)"
            [disabled]="inputDisabled"
            [matMenuTriggerFor]="isOffline ? menus.participantActionMenu : childActionsMenu"
            [matMenuTriggerData]="{participant: participantForRow(asChild(row)), config: {}, row: row}"
            (menuOpened)="onMenuOpened($event, row)"
            mat-icon-button
            type="button"
          >
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
        </td>
      </ng-container>

    </table>

    <div
      *ngIf="localReload"
      class="no-data loading"
    >
      <rag-loading-indicator></rag-loading-indicator>
    </div>

    <div
      *ngIf="hasNoFilteredData() && !localReload"
      class="no-data"
    >
      <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
    </div>

    <div
      *ngIf="!hasTableData() && !localReload"
      class="no-data"
    >
    <span i18n="@@content_distribution_no_data">There is no available data to be displayed.
          Please contact your system administrator for clarification.</span>
    </div>

    <!--NOTE -----------------------------------------------!!!!!!!!!!!!!!!!

      This is a temporary placeholder to hide the table body through CSS cheating, as styling
      the table body manually isn't possible. Strongly recommend to think about creating a wholly
      custom, transparent, modular table component instead of relying on Mattable and a custom version
      of it.

    -->
    <div *ngIf="localReload" id="localReloadLoader">
    </div>
    <!----------------------------------------------------------------------------->

  </cdk-virtual-scroll-viewport>

  <!-- belongs to the placeholder -->
  <rag-loading-indicator *ngIf="localReload" id="localReloadLoaderIndicator"></rag-loading-indicator>
  <!--------------------------->


</ng-container>
