import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DetailsRowModule } from '../../table/details-row/details-row.module';
import { ContentActionModule } from '../content-action/content-action.module';
import { ContentDurationModule } from '../content-duration/content-duration.module';
import { ContentInfoButtonModule } from '../content-info-button/content-info-button.module';
import { ImportantContentInfoModule } from '../important-content-info/important-content-info.module';
import { ItemTypeIconModule } from '../item-type-icon/item-type-icon.module';
import { StatusLightsModule } from '../status-lights/status-lights.module';
import { ItemListComponent } from './item-list.component';
import { ItemRecursionIndentComponent } from './item-recursion-indent/item-recursion-indent.component';
import { ContentOfflineInvitationModule } from '../../content-offline-invitation/content-offline-invitation.module';
import { CurriculumLockedByModule } from '../curriculum-locked-by/curriculum-locked-by.module';
import { ItemListReadConfirmDialogComponent } from './item-list-read-confirm-dialog/item-list-read-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { SubTitleComponent } from '../../sub-title/sub-title.component';
import {PipesModule} from '../../../core/pipes/pipes.module';
import { ClampComponent } from '../../clamp/clamp.component';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { LearnerOflEventsStateComponent } from '../../learner-ofl-events-state/learner-ofl-events-state.component';


@NgModule({
  declarations: [ ItemListComponent, ItemRecursionIndentComponent, ItemListReadConfirmDialogComponent ],
  exports: [
    ItemListComponent,
    ItemListReadConfirmDialogComponent
  ],
  imports: [
    ClampComponent,
    CommonModule,
    ContentActionModule,
    ContentDurationModule,
    ContentInfoButtonModule,
    DetailsRowModule,
    ImportantContentInfoModule,
    ItemTypeIconModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatDialogModule,
    MatRadioModule,
    DialogHeaderComponent,
    RouterModule,
    StatusLightsModule,
    SubTitleComponent,
    ContentOfflineInvitationModule,
    LearnerOflEventsStateComponent,
    CurriculumLockedByModule,
    PipesModule,
  ],
})
export class ItemListModule {
}
