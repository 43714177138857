import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';

export interface SelectDateDialogParams {
  maxDate?: Moment;
  date?: Moment;
}

@Component({
  selector: 'rag-select-date-dialog',
  templateUrl: './select-date-dialog.component.html',
  styleUrls: ['./select-date-dialog.component.scss']
})
export class SelectDateDialogComponent implements OnInit {

  date: Moment;

  constructor(
    private dialogRef: MatDialogRef<SelectDateDialogComponent, Moment>,
    @Inject(MAT_DIALOG_DATA) public data: SelectDateDialogParams
    ) {
     }

  ngOnInit(): void {
    if (this.data.date != null) {
      this.date = this.data.date;
    }
  }

  onDate(date: Moment) {
    this.date = date;
    this.dialogRef.close(this.date);
  }

}
