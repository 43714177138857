import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from './global.types';

interface ErrorsContainer {
  errors?: ApiError[];
}

export class ErrorHelper {

  public static asString(error): string {
    if ( !error ) {
      return '';
    }

    if ( typeof (error) === 'string' ) {
      return error;
    } else if ( typeof (error.error) === 'string' ) {
      return error.error;
    } else if ( typeof (error.errorMessage) === 'string' ) {
      return error.errorMessage;
    }
    return JSON.stringify(error);
  }

  static findApiError(
    response: HttpErrorResponse,
  ): ApiError | null {

    return ErrorHelper.findFirstApiError(response?.error);
  }

  static findFirstApiError(
    error: ApiError | ErrorsContainer | null,
  ): ApiError | null {

    if ( error == null ) {
      return null;
    }

    const apiError = error as ApiError;
    if ( apiError.errorCode != null ) {
      return apiError;
    }

    const errors = (error as ErrorsContainer).errors;
    if ( !(errors?.length > 0) ) {
      return null;
    }

    return ErrorHelper.findFirstApiError(errors[0]);
  }

}
