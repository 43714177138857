import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  destroySubscriptions,
  subscribeUntilDestroyed,
  takeUntilDestroyed,
} from '../../../../core/reactive/until-destroyed';
import { catchError, map, takeWhile, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PaymentModuleSettings } from './ext-payment-module.types';
import { ExtPaymentModuleService } from './ext-payment-module.service';
import { DeleteButton, DeleteCancelButtons, InfoType } from '../../../../core/info/info.types';
import { EMPTY } from 'rxjs';
import { InfoService } from '../../../../core/info/info.service';
import { AdminOfflineService } from '../../admin-offline/admin-offline.service';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../core/principal/permission.states';

@Component({
  selector: 'rag-ext-payment-module',
  templateUrl: './ext-payment-module.component.html',
  styleUrls: [ './ext-payment-module.component.scss' ],
})
export class ExtPaymentModuleComponent
  implements OnInit, OnDestroy {

  paymentFormGroup: UntypedFormGroup;
  paymentSettings: PaymentModuleSettings;
  oldPaymentSettings: PaymentModuleSettings;
  permissions: PermissionStates;
  private _isSaveEnabled: boolean;
  private _isDeleteEnabled: boolean;

  constructor(
    private adminOfflineService: AdminOfflineService,
    private dirtyCheckService: DirtyCheckService,
    private extPaymentModuleService: ExtPaymentModuleService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private principalService: PrincipalService,
  ) {
  }

  ngOnInit(): void {

    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    subscribeUntilDestroyed(this.route.data.pipe(map(data => {


      const paymentSettingsData = data.paymentSettings ?? {};
      this.paymentSettings = paymentSettingsData;
      this.oldPaymentSettings = MergeHelper.cloneDeep(paymentSettingsData);

      this.validateDeactivateButton();
    })), this);

    const paymentSettings = this.paymentSettings;
    this.paymentFormGroup = this.formBuilder.group({
      clientId: [ paymentSettings.clientId ],
      token: [ paymentSettings.token, [ Validators.required ] ],
      webhookUrl: [ paymentSettings.webhookUrl ],
      webhookSecret: [ paymentSettings.webhookSecret ],
      authLogin: [ paymentSettings.login ],
      authPassword: [ paymentSettings.password ],
      baseUrl: [ paymentSettings.url, [ Validators.required ] ],
      templateId: [ paymentSettings.templateId ],
    });

    this.paymentFormGroup.get('clientId').valueChanges.subscribe(clientId => {
      this.paymentSettings.clientId = clientId;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('token').valueChanges.subscribe(token => {
      this.paymentSettings.token = token;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('webhookUrl').valueChanges.subscribe(webhookUrl => {
      this.paymentSettings.webhookUrl = webhookUrl;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('webhookSecret').valueChanges.subscribe(webhookSecret => {
      this.paymentSettings.webhookSecret = webhookSecret;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('authLogin').valueChanges.subscribe(authLogin => {
      this.paymentSettings.login = authLogin;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('authPassword').valueChanges.subscribe(authPassword => {
      this.paymentSettings.password = authPassword;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('baseUrl').valueChanges.subscribe(baseUrl => {
      this.paymentSettings.url = baseUrl;
      this.validateSaveButton();
    });

    this.paymentFormGroup.get('templateId').valueChanges.subscribe(templateId => {
      this.paymentSettings.templateId = templateId;
      this.validateSaveButton();
    });

  }

  onDelete(): void {

    if ( this.permissions?.adminPaymentGeneral === false ) {
      return;
    }

    this.infoService
      .showMessage($localize`:@@global_confirm_delete_dsb:
        If you delete the DSB gateway, all saved settings are deleted.<br/>
        Without these settings, chargeable bookings can no longer be billed.<br/>
        Are you sure you want to delete the DSB gateway?
      `,
        {
          buttons: DeleteCancelButtons,
          title: $localize`:@@global_delete:Delete`,
        })
      .pipe(takeWhile(button => button === DeleteButton))
      .pipe(map(() => this.extPaymentModuleService.deletePaymentSettings().subscribe()))
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`,
          {
            infoType: InfoType.Success,
          });
        this.paymentSettings = {
          clientId: '',
          token: '',
          webhookUrl: '',
          webhookSecret: '',
          login: '',
          password: '',
          url: '',
          templateId: '',
          uuid: '',
        };
        this.oldPaymentSettings = MergeHelper.cloneDeep(this.paymentSettings);
        this.paymentFormGroup.controls.clientId.setValue('');
        this.paymentFormGroup.controls.token.setValue('');
        this.paymentFormGroup.controls.webhookUrl.setValue('');
        this.paymentFormGroup.controls.webhookSecret.setValue('');
        this.paymentFormGroup.controls.authLogin.setValue('');
        this.paymentFormGroup.controls.authPassword.setValue('');
        this.paymentFormGroup.controls.authPassword.setValidators([Validators.required]);
        this.paymentFormGroup.controls.baseUrl.setValue('');
        this.paymentFormGroup.controls.templateId.setValue('');
        this._isSaveEnabled = false;
        this._isDeleteEnabled = false;
        this.emitChange(false);
      }))
      .subscribe();
  }

  onSave() {

    if ( this.isSaveButtonDisabled() ) {
      return;
    }

    if ( this.paymentSettings == null ) {
      return;
    } else {

      const savePaymentSettings = MergeHelper.cloneDeep(this.paymentSettings);

      if ( this.paymentSettings.password === this.oldPaymentSettings.password ) {
        savePaymentSettings.password = '';
      }

      this.extPaymentModuleService.savePaymentSettings(savePaymentSettings)
        .pipe(catchError(() => {
          this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
            { infoType: InfoType.Error });
          return EMPTY;
        }))
        .pipe(tap(() => {
          this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
            {
              infoType: InfoType.Success,
            });
          this.oldPaymentSettings = MergeHelper.cloneDeep(this.paymentSettings);
          this._isSaveEnabled = false;
          this._isDeleteEnabled = true;
          this.emitChange(false);
        }))
        .subscribe();
    }

  }

  validateSaveButton() {

    if ( this.paymentSettings.clientId !== this.oldPaymentSettings.clientId ||
      this.paymentSettings.token !== this.oldPaymentSettings.token ||
      this.paymentSettings.webhookUrl !== this.oldPaymentSettings.webhookUrl ||
      this.paymentSettings.webhookSecret !== this.oldPaymentSettings.webhookSecret ||
      this.paymentSettings.login !== this.oldPaymentSettings.login ||
      this.paymentSettings.password !== this.oldPaymentSettings.password ||
      this.paymentSettings.url !== this.oldPaymentSettings.url ||
      this.paymentSettings.templateId !== this.oldPaymentSettings.templateId
    ) {
      this._isSaveEnabled = true;
      this.emitChange(true);
    } else {
      this._isSaveEnabled = false;
      this.emitChange(false);
    }
  }

  validateDeactivateButton() {

    if (this.paymentSettings.uuid == null || this.paymentSettings.uuid === '') {
      this._isDeleteEnabled = false;
    } else {
      this._isDeleteEnabled = true;
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  isDeleteButtonDisabled() {

    if ( this.permissions?.adminPaymentGeneral === false ) {
      return true;
    }

    if ( !this._isDeleteEnabled ) {
      return true;
    }

    return false;
  }

  isSaveButtonDisabled() {

    if ( this.permissions?.adminPaymentGeneral === false ) {
      return true;
    }

    if ( !this._isSaveEnabled ) {
      return true;
    }
    if ( this.paymentFormGroup.invalid ) {
      return true;
    }
    return false;
  }

  private emitChange(changed: boolean = true) {
    this.dirtyCheckService.submitNextState('ExtPaymentModuleComponent', changed);
  }

}
