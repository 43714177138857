<rag-dialog-header>
  <span i18n="@@add_user_dialog_header">Add linked user</span>
</rag-dialog-header>

<form (ngSubmit)="onClick(true)" [formGroup]="formGroupAddUser">

  <div mat-dialog-content>

    <p >
      <span i18n="@@add_user_dialog_add_linked_user_helper_text">
        Please enter the login and password of the user to be linked.
      </span>
    </p>

    <div class="spacing-element">
      <mat-form-field appearance="outline">
        <mat-label>
          <span i18n="@@add_user_dialog_label_login">Login</span>
        </mat-label>
        <input formControlName="login" inputmode="email" matInput required type="text"/>
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          <span i18n="@@add_user_dialog_label_password">Password</span>
        </mat-label>
        <input formControlName="password" inputmode="text" matInput required type="password"/>
        <mat-error></mat-error>
      </mat-form-field>
    </div>

  </div>

  <mat-dialog-actions>
    <div class="button-wrapper">
      <button (click)="onClick(false)" autofocus class="rag-stroked-button"
              mat-stroked-button type="button">
        <span i18n="@@add_user_dialog_button_cancel">Cancel</span>
      </button>
      <button [disabled]="!formGroupAddUser.valid" color="primary"
              mat-flat-button
              type="submit">
        <span i18n="@@add_user_dialog_button_add">Add</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
