import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { url as BASE_URL } from '../../../../app.state';
import { CachedSubject } from '../../../../core/cached-subject';
import { AccountInterceptor } from '../../../../core/interceptors/account.interceptor';
import { RegistrationService } from '../../../../core/principal/registration.service';


@Component({
  selector: 'rag-self-register-activation',
  templateUrl: './self-register-activation.component.html',
  styleUrls: [ './self-register-activation.component.scss' ],
})
export class SelfRegisterActivationComponent
  implements OnInit {

  errorAlreadyActivated: boolean;
  readonly errorMessage$: Observable<string>;
  hasResponse = false;
  key: string;
  private _errorMessage = new CachedSubject<string>(null);

  constructor(
    private platformLocation: PlatformLocation,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
  ) {
    this.errorMessage$ = this._errorMessage.withoutEmptyValues();
    this.key = route.snapshot.params['key'];
  }

  ngOnInit() {
    if ( this.key != null ) {
      const accountKey = AccountInterceptor.getAccountKey();
      const loginUrl = BASE_URL + window.location.pathname + '#/login?key=' + accountKey;
      this.registrationService.activateKey(this.key, loginUrl)
        .pipe(finalize(() => this.hasResponse = true))
        .pipe(catchError(error => {
          if ( error && (error.errorCode === '#rfe2') ) {
            this.errorAlreadyActivated = true;
          } else {
            this._errorMessage.next(error.error);
          }
          return throwError(error);
        }))
        .subscribe();
    }
  }

}
