import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminQAService } from '../admin-qa.service';
import { QAQuestion } from '../../../user/qa/qa.types';

@Injectable({ providedIn: 'root' })
export class AdminQAListResolver
  implements Resolve<QAQuestion[]> {

  constructor(
    private adminQAService: AdminQAService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QAQuestion[]> {
    return this.adminQAService.getAllQuestions();
  }
}
