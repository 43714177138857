import { NgModule } from '@angular/core';
import { NotFound404Module } from '../main/not-found-404/not-found-404.module';
import { AccountDesignModule } from './account-design/account-design.module';
import { AdminRoutingModule } from './admin.routing';
import { DevelopmentGoalsModule } from './development-goals/development-goals.module';
import { ExternalsModule } from './externals/externals.module';
import { ReportGenAdminModule } from './report-gen-admin/report-gen-admin.module';
import { AdminIncludesModule } from './admin-includes/admin-includes.module';
import { AdminScriptModule } from './admin-script/admin-script.module';
import { AdminComponentsModule } from './admin-components/admin-components.module';
import { AdminSelfRegistrationModule } from './admin-self-registration/admin-self-registration.module';
import { AdminNotificationsDetailsModule } from './admin-event-actions/admin-notifications/admin-notifications-details/admin-notifications-details.module';
import { AdminSignaturesModule } from './admin-signatures/admin-signatures.module';
import { AdminQAEditModule } from './admin-qa/admin-qa-edit/admin-qa-edit.module';
import { AdminQAListModule } from './admin-qa/admin-qa-list/admin-qa-list.module';

@NgModule({
  imports: [
    AccountDesignModule,
    AdminComponentsModule,
    AdminIncludesModule,
    AdminRoutingModule,
    AdminScriptModule,
    AdminSelfRegistrationModule,
    DevelopmentGoalsModule,
    ExternalsModule,
    NotFound404Module,
    ReportGenAdminModule,
    AdminNotificationsDetailsModule,
    AdminSignaturesModule,
    AdminQAEditModule,
    AdminQAListModule,
  ],
})
export class AdminModule {
}
