import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ApiUrls } from './api.urls';
import { CachedSubject } from './cached-subject';
import { ApiError, ApiResponse, TrainResponse } from './global.types';
import { AccountInterceptor } from './interceptors/account.interceptor';
import { PrincipalData } from './principal/principal.types';
import { environment } from '../../environments/environment';
import { StartPageSettings, StyleSettings } from '../route/admin/account-design/account-design.types';
import { Catalogs } from './catalog/catalog.types';
import { LanguageHelper, LanguageInfo } from './language.helper';
import { LandingPageConfSettings } from './landing-page-conf.types';
import { LoginMethod, LoginType } from './principal/login-method';
import { State } from '../app.state';
import { NavigationService } from './navigation/navigation.service';
import { UrlHelper } from './url.helper';
import { NavigationData } from './navigation/navigation.types';
import { BYPASS_ERRORS_INTERCEPTOR } from './interceptors/errors-interceptor.service';
import { SEO } from './seo.types';
import { GamificationSettings } from './gamification/gamification.types';
import { Bots } from './bots/bots.types';


interface PreloadData {
  accountId: number;
  accountKey: string;
  accountStyle: StyleSettings;
  catalogState: Catalogs.CatalogState;
  doubleOptIn: boolean;
  errors?: ApiError[];
  isPaymentModuleEnabled: boolean;
  userProfileExportable: boolean;
  languages: LanguageInfo[];
  loginMethods: LoginMethod[];
  navigationData: NavigationData;
  principal: PrincipalData;
  startPage: StartPageSettings;
  anonymousRegistration: boolean;
  selfRegistration: boolean;
  seo?: SEO;
  gamification?: GamificationSettings;
  botConfig?: Bots.Config;
}

@Injectable({
  providedIn: 'root',
})
export class PreloadService {

  isPaymentModuleEnabled$: Observable<boolean>;
  isUserProfileExportable$: Observable<boolean>;

  private _accountId = new CachedSubject<number>(0);
  private _catalogState = new CachedSubject<Catalogs.CatalogState>(null);
  private _doubleOptIn$ = new CachedSubject<boolean>(false);
  private _anonymousRegistration$ = new CachedSubject<boolean>(false);
  private _selfRegistration$ = new CachedSubject<boolean>(false);
  private _seo$ = new CachedSubject<SEO>(null);
  private _gamificationEnabled$ = new CachedSubject<boolean>(false);
  private _envJson = new CachedSubject<any>(null);
  private _errors: ApiError[];
  private _landingPageSettings = new CachedSubject<LandingPageConfSettings>(null);
  private _loginMethods$ = new CachedSubject<LoginMethod[]>(null);
  private _mdiSvg = new CachedSubject<string>(null);
  private _navigationData = new CachedSubject<NavigationData>(null);
  private _paymentModuleEnabled = new CachedSubject<boolean>(false);
  private _preloadFinished: boolean;
  private _preloadFinished$ = new CachedSubject<boolean>(null);
  private _principalData = new CachedSubject<PrincipalData>(null);
  private _startPage = new CachedSubject<TrainResponse>(null);
  private _styleInfo = new CachedSubject<TrainResponse>(null);
  private _userProfileExportable = new CachedSubject<boolean>(false);
  private _botSettings$ = new CachedSubject<Bots.Settings[]>(null);

  constructor(
    private http: HttpClient,
  ) {
    this.isPaymentModuleEnabled$ = this._paymentModuleEnabled.asObservable();
    this.isUserProfileExportable$ = this._userProfileExportable.asObservable()
  }

  get errors(): ApiError[] {
    return this._errors;
  }

  get isPaymentModuleEnabled(): boolean {
    return this._paymentModuleEnabled.value;
  }

  get isUserProfileExportable(): boolean {
    return this._userProfileExportable.value;
  }

  get preloadFinished$(): Observable<boolean> {
    return this._preloadFinished$
      .pipe(startWith(this._preloadFinished))
      .pipe(filter(CachedSubject.isNotEmpty));
  }

  private static redirectToLanguage(validLanguage: string | null): boolean {

    if ( validLanguage === State.language ) {
      return false;
    }

    const languagePath = LanguageHelper.LANGUAGE_PATHS[validLanguage];
    if ( languagePath == null ) {
      console?.warn('valid language without path?', validLanguage);
      return false;
    }

    const locationInfo = NavigationService.parseBaseHrefFromUrl(window.location.pathname);
    if ( !locationInfo?.languagePath ) {
      console?.warn('redirecting to valid language:', validLanguage);
      // skip anything without explicit language
      return false;
    }

    const baseHref = locationInfo.baseHref;
    const url = baseHref + languagePath + window.location.hash;
    console?.warn('redirecting to valid language: ' + url);
    window.location.href = url;
    return true;
  }

  /**
   * Until the whole preload process has finished, the method returns an observable from a cached subject (populated
   * when preload finishes).
   * After preload has finished, the method returns a fresh query from the API call.
   */
  getCatalogState = (reset = false): Observable<Catalogs.CatalogState> => {
    if ( !this._preloadFinished ) {
      // delay requests until preload has finished
      return this.whenFinished(this._catalogState.withoutEmptyValuesWithInitial());
    }

    // any subsequent call should query train directly
    return this.queryCatalogState(reset);
  };

  getDoubleOptIn = (): Observable<boolean> => this._doubleOptIn$.withoutEmptyValuesWithInitial()
      .pipe(take(1));

  getAnonymousRegistration = (): Observable<boolean> => this._anonymousRegistration$.withoutEmptyValuesWithInitial()
      .pipe(take(1));

  getSelfRegistration = (): Observable<boolean> => this._selfRegistration$.withoutEmptyValuesWithInitial()
      .pipe(take(1));

  getSEO = (): Observable<SEO> => this._seo$.withoutEmptyValuesWithInitial()
    .pipe(take(1));

  getGamificationEnabled = (): Observable<boolean> => this._gamificationEnabled$.withoutEmptyValuesWithInitial()
    .pipe(take(1));

  getBotSettings = (): Observable<Bots.Settings[]> => this._botSettings$.withoutEmptyValuesWithInitial()
    .pipe(take(1));

  /**
   * The Environment is static, for now
   */
  getEnvJson(): Observable<any> {
    return this._envJson.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  getLandingPageSettings = (reset = false): Observable<LandingPageConfSettings> => {
    if ( !this._preloadFinished ) {
      return this.whenFinished(this._landingPageSettings.withoutEmptyValuesWithInitial());
    }
    return this.queryLandingPageSettings(reset);
  };

  getLoginMethods(): Observable<LoginMethod[]> {
    return this._loginMethods$.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  /**
   * mdi.svg is a static resource and does not need to be refreshed once cached
   */
  getMdiSvg(): Observable<string> {
    return this._mdiSvg.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  /**
   * Until the whole preload process has finished, the method returns an observable from a cached subject (populated
   * when preload finishes).
   * After preload has finished, the method returns a fresh query from the API call.
   */
  getNavigationData(reset = false): Observable<NavigationData | null> {
    if ( !this._preloadFinished ) {
      // delay requests until preload has finished
      return this.whenFinished(this._navigationData.withInitial());
    }

    // any subsequent call should query train directly
    return this.queryNavigation(reset);
  }

  /**
   * Until the whole preload process has finished, the method returns an observable from a cached subject (populated
   * when preload finishes).
   * After preload has finished, the method returns a fresh query from the API call.
   */
  getPrincipalData = (reset = false): Observable<PrincipalData> => {
    if ( !this._preloadFinished ) {
      // delay requests until preload has finished
      return this.whenFinished(this._principalData.withoutEmptyValuesWithInitial());
    }

    // any subsequent call should query train directly
    return this.queryPrincipal(reset);
  };

  /**
   * Until the whole preload process has finished, the method returns an observable from a cached subject (populated
   * when preload finishes).
   * After preload has finished, the method returns a fresh query from the API call.
   */
  getStartPage = (reset = false): Observable<TrainResponse> => {
    if ( !this._preloadFinished ) {
      // ignore anything until preload is finished
      return this.whenFinished(this._startPage.withoutEmptyValuesWithInitial());
    }

    return this.queryStartPage(reset);
  };

  /**
   * Until the whole preload process has finished, the method returns an observable from a cached subject (populated
   * when preload finishes).
   * After preload has finished, the method returns a fresh query from the API call.
   */
  getStyleInfo = (accountId: number, reset = false): Observable<any> => {
    if ( !this._preloadFinished ) {
      // ignore anything until preload is finished
      return this.whenFinished(this._styleInfo.withoutEmptyValuesWithInitial());
    }

    return this.queryStyleInfo(accountId, reset);
  };

  preload(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.queryEnv()
        .pipe(switchMap(env => {
          // update urls
          ApiUrls.setBaseUrls(env.lmsApi, env.trainApi);

          return forkJoin([
            this.queryPreloadData(),
            this.queryMdiSvg(),
            of(env),
          ]);
        }))
        .pipe(take(1))
        .pipe(tap(([ preloadData, mdiSvg, env ]) => {
          const accountKey = preloadData.accountKey;
          if ( accountKey ) {
            AccountInterceptor.writeAccountKeyToStorage(accountKey);
          }

          const languages = preloadData.languages;
          if ( languages != null ) {
            LanguageHelper.setLanguages(languages);

            const validLanguage = LanguageHelper.localeToLanguage(State.language);
            if ( PreloadService.redirectToLanguage(validLanguage) ) {
              return EMPTY;
            }
          }

          this._errors = preloadData.errors ?? [];
          this._envJson.next(env);
          this._mdiSvg.next(mdiSvg);

          this._accountId.next(preloadData.accountId);
          this._catalogState.next(preloadData.catalogState);
          this._doubleOptIn$.next(preloadData.doubleOptIn ?? false);
          this._anonymousRegistration$.next(preloadData.anonymousRegistration ?? false);
          this._selfRegistration$.next(preloadData.selfRegistration ?? false);
          this._loginMethods$.next(preloadData.loginMethods ?? [ { type: LoginType.Credentials } ]);
          this._principalData.next(preloadData.principal);
          this._startPage.next({ data: preloadData.startPage ?? {}, success: true });
          this._styleInfo.next({ data: preloadData.accountStyle ?? {}, success: true });
          this._paymentModuleEnabled.next(preloadData.isPaymentModuleEnabled ?? false);
          this._userProfileExportable.next(preloadData.userProfileExportable ?? false);
          this._navigationData.next(preloadData.navigationData);
          this._seo$.next(preloadData.seo);
          this._gamificationEnabled$.next(preloadData.gamification?.enabled ?? false);
          this._botSettings$.next(preloadData.botConfig?.bots ?? []);

          // mark preloading as complete
          this._preloadFinished$.next(this._preloadFinished = true);
          resolve(true);
        }))
        .pipe(catchError((e) => {
          console.error(e);
          reject();
          return EMPTY;
        }))
        .subscribe();
    });
  }

  private queryCatalogState(reset = false): Observable<Catalogs.CatalogState> {
    if ( reset ) {
      this._catalogState.reset();
    }

    if ( this._catalogState.queryStart() ) {
      const url = ApiUrls.getKey('CatalogState');
      this.http.get<ApiResponse<Catalogs.CatalogState>>(url)
        .pipe(tap(response => this._catalogState.next(response.catalogState)))
        .pipe(catchError(this._catalogState.nextError))
        .subscribe();
    }

    return this._catalogState.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  private queryEnv(): Observable<any> {
    if ( !environment.envJson ) {
      // business as usual
      return of(environment);
    } else {
      // fetch environment
      return this.http.get<any>('./assets/env/env.json?_=' + (new Date()).getTime().toString(16).toUpperCase(), { context: new HttpContext().set(BYPASS_ERRORS_INTERCEPTOR, true) })
        .pipe(catchError(() => of(environment)));
    }
  }

  private queryLandingPageSettings(reset = false): Observable<LandingPageConfSettings> {
    if ( reset ) {
      this._landingPageSettings.reset();
    }
    const url = ApiUrls.getKey('LandingPageSettings');
    this.http.get<ApiResponse<LandingPageConfSettings>>(url)
      .pipe(map(response => this._landingPageSettings.next(response.settings ?? { settings: [] })))
      .pipe(catchError(this._landingPageSettings.nextError))
      .subscribe();
    return this._landingPageSettings.withoutEmptyValuesWithInitial().pipe(take(1));
  }

  private queryMdiSvg(): Observable<string> {
    const headers = new HttpHeaders()
      .set('Cache-Control', '')
      .set('Pragma', '');
    return this.http.get('./assets/icons/svg/mdi.svg', { headers, responseType: 'text' });
  }

  private queryNavigation(reset = false): Observable<NavigationData | null> {
    if ( reset ) {
      this._navigationData.reset();
    }

    if ( this._navigationData.queryStart() ) {
      // TF-7849 disable query to navigation data as the api does not exist (relates to TF-6691)
      /*const url = ApiUrls.getKey('NavigationData');
      this.http.get<TrainResponse<NavigationData>>(url)
        .pipe(catchError(() => of(null)))
        .pipe(map(response => response?.data))
        .pipe(tap(this._navigationData.next))
        .subscribe();*/
      this._navigationData.next(null);
    }

    return this._navigationData.withInitial()
      .pipe(take(1));
  }

  private queryPreloadData(): Observable<PreloadData> {
    const url = ApiUrls.getKey('PreloadData')
      .replace(/{navLocation}/gi, encodeURIComponent(window.location.href));
    return this.http.get<PreloadData>(url);
  }

  private queryPrincipal(reset = false): Observable<PrincipalData> {
    if ( reset ) {
      this._principalData.reset();
    }

    if ( this._principalData.queryStart() ) {
      const url = ApiUrls.getKey('UserInfo')
        .replace(/{navLocation}/gi, encodeURIComponent(window.location.href));
      this.http.get<TrainResponse>(url)
        .pipe(tap(this._principalData.next))
        .pipe(catchError(err => this.redirectOnError(err)))
        .subscribe();
    }

    return this._principalData.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  private queryStartPage(reset = false): Observable<TrainResponse> {
    if ( reset ) {
      this._startPage.reset();
    }

    if ( this._startPage.queryStart() ) {
      const url = ApiUrls.getKeyWithParams('StartPage', '2');
      this.http.get<TrainResponse>(url)
        .pipe(tap(this._startPage.next))
        .pipe(catchError(this._startPage.nextError))
        .subscribe();
    }

    return this._startPage.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  private queryStyleInfo(accountId: number, reset = false): Observable<TrainResponse> {
    if ( reset ) {
      this._styleInfo.reset();
    }

    if ( this._styleInfo.queryStart() ) {
      const url = ApiUrls.getKey('GetAccountStyleInfo')
        .replace(/{accountId}/gi, String(accountId));
      this.http.get<TrainResponse>(url)
        .pipe(tap(this._styleInfo.next))
        .pipe(catchError(err => this.redirectOnError(err)))
        .subscribe();
    }

    return this._styleInfo.withoutEmptyValuesWithInitial()
      .pipe(take(1));
  }

  /**
   * Add handling for corrupt http sessions (roles are empty -> public API throws 403 errors)
   * @see TF-5035
   * @private
   */
  private redirectOnError(
    err?: HttpErrorResponse,
  ): Observable<never> {

    if ( (err?.status === 403) &&
      // prevent infinite loops
      !window.location.href.includes('/login/redirect') ) {

      const hash = (window.location.hash ?? '').replace(/^#/, '');
      const redirect = UrlHelper.getPublicRedirect(hash)
        .replace('#', `?_=${new Date().getTime()}#`);
      console?.warn(`found corrupted user session -> force login with redirect ${redirect}`);
      setTimeout(() => window.location.href = redirect, 500);
    }

    return EMPTY;
  }

  private whenFinished<T>(observable: Observable<T>): Observable<T> {
    return combineLatest([ observable, this.preloadFinished$ ])
      // check if finished is true
      .pipe(filter(([ , finished ]) => finished))
      // only return target value
      .pipe(map(([ value ]) => value))
      // complete after first value
      .pipe(take(1));
  }

}

export const preloadServiceFactory = (preloadService: PreloadService) => () => preloadService.preload();
