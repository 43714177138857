import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ModalDialog } from '../modal-dialog';
import { GenericMessageDialogComponent } from '../../component/generic-message-dialog/generic-message-dialog.component';
import {
  DialogButton,
  InfoDialogOptions,
  InfoServiceMessageOptions,
  InfoType,
  MessageKey,
  OkButton,
  SnackBarData,
  SnackBarOptions,
} from './info.types';
import { InfoSnackBarComponent } from './snack-bar/info-snack-bar.component';
import { filter, map, mapTo, take, takeUntil, tap } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class InfoService {
  constructor(
    public dialog: ModalDialog,
    private router: Router,
    public snackBar: MatSnackBar,
  ) {
  }

  showAlert(message: string): Observable<void> {
    return this.showDialog(GenericMessageDialogComponent, {
      title: $localize`:@@general_dialog_info_title:Information`,
      message,
      buttons: OkButton,
    }, {
      maxWidth: '80vw',
    }).pipe(map(() => null));
  }

  /**
   * @deprecated please use {@link InfoService.showMessage} with options {buttons: ...} instead
   */
  showConfirm(messageKey: string, buttons: DialogButton): Observable<DialogButton> {
    return this.showDialog(GenericMessageDialogComponent, {
      titleKey: MessageKey.DIALOG.CONFIRM.TITLE,
      messageKey,
      buttons,
    });
  }

  showDialog<T, D = { [key: string]: any }, R = number>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data: D,
    customConfig: MatDialogConfig = { hasBackdrop: true }): Observable<R> {
    return this.dialog.openModal<T, D, R>(componentOrTemplateRef, {
      ...customConfig,
      data,
    })
      .afterClosed();
  }

  showMessage(message: string, options: InfoServiceMessageOptions = {}): Observable<DialogButton | MatSnackBarDismiss> {
    const infoType = options.infoType;
    if ( infoType == null ) {
      return this.showDialog<any, InfoDialogOptions>(GenericMessageDialogComponent, {
        buttons: options.buttons || OkButton,
        message,
        title: options.title,
        closeable: options.closeable
      })
        .pipe(take(1));
    } else {
      return this.showSnackbarInternal({
        infoType,
        message,
        messageKey: null,
      }, options.durationInSeconds);
    }
  }

  /**
   * @deprecated please use {InfoService.showMessage} with options {infoType: ...} instead
   */
  showSnackbar(messageKey: string, infoType: InfoType, options: SnackBarOptions = {}): Observable<number> {
    return this.showSnackbarInternal({
      infoType,
      message: options.message,
      messageKey,
    }, options.durationInSeconds)
      .pipe(mapTo(OkButton));
  }

  private showSnackbarInternal(data: SnackBarData, duration?: number): Observable<MatSnackBarDismiss> {
    // display errors 5 seconds and other types for 2.5 seconds
    const defaultValueForSeconds = (data.infoType === InfoType.Error) ? 5 : 2.5;
    const durationInSeconds = (duration > 0) ? duration : defaultValueForSeconds;

    const snackBarReference = this.snackBar.openFromComponent(InfoSnackBarComponent, {
      data,
      duration: durationInSeconds * 1000,
    });

    if ( durationInSeconds > defaultValueForSeconds ) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .pipe(take(1))
        .pipe(takeUntil(snackBarReference.afterDismissed()))
        .pipe(tap(() => snackBarReference.dismiss()))
        .subscribe();
    }

    return snackBarReference.afterDismissed();
  }

}
