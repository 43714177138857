<div class="design-wrapper">
  <rag-page-header>
    <div class="page-header-title" i18n="@@account_design">Account design</div>
  </rag-page-header>

  <nav class="tab-group-container" color="primary" mat-tab-nav-bar>
    <a #rlaGeneral="routerLinkActive"
       [active]="rlaGeneral.isActive"
       mat-tab-link
       routerLink="general"
       routerLinkActive>
      <span i18n="@@account_design_general_style">General style</span>
    </a>
    <a #rlaLogin="routerLinkActive"
       [active]="rlaLogin.isActive"
       mat-tab-link
       routerLink="login"
       routerLinkActive>
      <span i18n="@@account_design_login_page">Login page</span>
    </a>

    <a #rlaFooter="routerLinkActive"
       [active]="rlaFooter.isActive"
       mat-tab-link
       routerLink="footer"
       routerLinkActive>
      <span i18n="@@account_design_footer">Footer</span>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
