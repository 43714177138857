import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
  ],
  selector: 'rag-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: [ './icon-text.component.scss' ],
})
export class IconTextComponent {

  @Input()
  svgIcon = 'warning-outline';

  constructor() {
  }

}
