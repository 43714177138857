<mat-form-field
  [formGroup]="form"
  appearance="outline"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <input
    matInput
    type="text"
    [formControlName]="field.fieldId"
    [name]="field.fieldId"
    [required]="field.required"
    [disabled]="isDisabled()"
  />

  <div *ngIf="suffixTemplate" matSuffix>
    <ng-container *ngTemplateOutlet="suffixTemplate"></ng-container>
  </div>

  <mat-error
    *ngIf="getErrorLinked() as error"
  >
    {{error.text}}
    <a
      target="_blank"
      [href]="error.link"
    >{{error.linkText}}</a>

  </mat-error>

</mat-form-field>
