<div class="widget-container">
  <gridster *ngIf="isLayoutInitialized && (widgets$ | async).length > 0; else noWidgets" [options]="options">
    <gridster-item
      *ngFor="let widget of widgets$ | async"
      [item]="widget.gridsterItem"
      class="gridster-item">

      <rag-widget-wrapper
        [edit]="options.draggable.enabled"
        [removeItem]="removeItem"
        [resizeEvent]="resizeEvent"
        [widget]="widget"
      >
      </rag-widget-wrapper>
    </gridster-item>
  </gridster>
  <ng-template #noWidgets>
    <div *ngIf="isLayoutInitialized" class="no-widgets" i18n="@@no_widgets_configured_yet">
      You do not have any widgets configured yet.
    </div>
  </ng-template>
</div>
