import { Component } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AbstractProfileFieldComponent } from '../abstract-profile-field.class';


@Component({
  selector: 'rag-profile-field-radio',
  templateUrl: './profile-field-radio.component.html',
  styleUrls: [ './profile-field-radio.component.scss' ],
})
export class ProfileFieldRadioComponent
  extends AbstractProfileFieldComponent<string> {

  constructor(
    controlContainer: ControlContainer,
  ) {
    super(controlContainer);
  }

}
