import { Component, OnDestroy, OnInit } from '@angular/core';
import { Content } from '../../../core/content/content.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { map, tap } from 'rxjs/operators';
import { ContentService } from '../../../core/content/content.service';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { StyleSettings } from '../../admin/account-design/account-design.types';
import { CachedSubject } from '../../../core/cached-subject';
import { ApplicationStateService, ViewMode } from '../../../core/application-state.service';
import { ModalDialog } from '../../../core/modal-dialog';
import { LearnerAccountService } from '../../../core/learner-account/learner-account.service';
import { LearnerAccountOfflineContentView } from '../../../core/learner-account/learner-account.types';
import { ContentOfflineDialogComponent } from './content-offline-dialog/content-offline-dialog.component';
import { PrincipalService } from '../../../core/principal/principal.service';
import { LearnerCockpitHelpDialogComponent } from './learner-cockpit-help-dialog/learner-cockpit-help-dialog.component';
import { OkButton } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import * as moment from 'moment';
import { LearnerCockpitContactDialogComponent } from './learner-cockpit-contact-dialog/learner-cockpit-contact-dialog.component';
import { GenericMessageDialogComponent } from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { RouteSnapshotHelper } from '../../../core/route-snapshot.helper';
import { Core, ImageableContentReference } from 'src/app/core/core.types';


@Component({
  selector: 'rag-content-learner-cockpit',
  templateUrl: './content-learner-cockpit.component.html',
  styleUrls: [ './content-learner-cockpit.component.scss' ],
})
export class ContentLearnerCockpitComponent
  implements OnInit, OnDestroy {

  Auswahl_1_live = false;
  Auswahl_2_live = false;
  Auswahl_3_live = false;
  Zeit_1 = '9:00 - 9:15';
  Zeit_10 = '12:35 - 12:45';
  Zeit_2 = '9:15 - 9:35';
  Zeit_3 = '9:40 - 9:55';
  Zeit_4 = '10:00 - 10:40';
  Zeit_5 = '10:45 - 10:50';
  Zeit_6 = '10:50 - 11:05';
  Zeit_7 = '11:05 - 11:20';
  Zeit_8 = '11:25 - 11:55';
  Zeit_9 = '12:00 - 12:30';
  account: ImageableContentReference[];
  auswahl_1_index = 1;
  auswahl_2_index = 5;
  auswahl_3_index = 11;
  content: ImageableContentReference;
  contentId: number;
  fullName = '';
  fullscreen = new CachedSubject<boolean>(true);
  live_1 = false;
  live_10 = false;
  live_2a = false;
  live_2b = false;
  live_3 = false;
  live_4a = false;
  live_4b = false;
  live_5 = false;
  live_6 = false;
  live_7 = false;
  live_8a = false;
  live_8b = false;
  live_9 = false;
  loading = true;
  styleSettings$: Observable<StyleSettings>;
  timeStampsOfEvents = [];

  constructor(
    private infoService: InfoService,
    private accountDesignService: AccountDesignService,
    private principalService: PrincipalService,
    private applicationStateService: ApplicationStateService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private learnerAccountService: LearnerAccountService,
    private router: Router,
    public dialog: ModalDialog,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();
  }

  fetchEvents(): void {
    this.learnerAccountService.fetchCurriculumEvents(this.content.id).subscribe(
      (response) => {
        this.content.items.map((item) => {
            if ( item.objType !== Core.DistributableType.lms_offlineCnt ) {
              return;
            }
            const events = response[item.id];
            events?.map(event => {
              // console.log(event.title);
              const eventDetails = {
                index: item.index,
                beginning: event.eventDate,
                end: event.eventDateUntil,
                title: item.title,
              };
              this.timeStampsOfEvents.push(eventDetails);
            });
          },
        );
        this.loading = false;
        // wait until all calls are resolved
        // console.log('fetchInfoOfItems - all calls are resolved');
        // console.log(this.timeStampsOfEvents, this.timeStampsOfEvents.length);
        this.updateLiveStatus();
        this.updateTimeInSVG();
      });
  }

  getTime(beginning: Date, end: Date): string {
    let timeFrame = '';
    timeFrame += moment(beginning).format('HH:mm');
    timeFrame += ' - ';
    timeFrame += moment(end).format('HH:mm');

    return timeFrame;
  }

  navigateToContact(): void {
    this.dialog.closeAll();
    this.dialog.open(LearnerCockpitContactDialogComponent, {
      data: '',
    });
  }

  navigateToHome(): void {
    this.router.navigateByUrl('/home').then();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.fullName = this.principalService.currentUser.fullName;

    this.route.data
      .pipe(map(data => {
        this.content = data.content.content;
        this.contentId = this.content.id;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.contentService.accountData$
      .pipe(tap(account => {
        this.account = account;
        const contentId = RouteSnapshotHelper.getParamNumber(this.route.snapshot, 'id');
        const content = ContentService
          .getContentById(account, contentId, false, [ Core.DistributableType.lms_curriculum ]);
        if ( content ) {
          this.content = content;
          this.fetchEvents();
        }
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  openHelp(): void {
    this.dialog.closeAll();
    this.dialog.open(LearnerCockpitHelpDialogComponent, {
      data: '',
    });
  }

  openWBT(index?: number): void {
    if ( index ) {
      const target = ContentService.getContentHref(this.content.items[index], true);
      this.router.navigateByUrl(target).then();
    } else {
      console.log('openWBT - id missing');
    }
  }

  resetStatus(): void {
    this.live_1 = false;
    this.live_2a = false;
    this.live_2b = false;
    this.live_3 = false;
    this.Auswahl_1_live = false;
    this.live_4a = false;
    this.live_4b = false;
    this.live_5 = false;
    this.live_6 = false;
    this.live_7 = false;
    this.live_8a = false;
    this.live_8b = false;
    this.Auswahl_2_live = false;
    this.Auswahl_3_live = false;
    this.live_9 = false;
    this.live_10 = false;
  }

  showEventItem(identifier: string): void {
    let selectedItem;
    switch ( identifier ) {
      case 'item1':
        this.content.items.forEach(item => {
          if ( item.index === 0 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item2a':
        this.content.items.forEach(item => {
          if ( item.index === 2 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item2b':
        this.content.items.forEach(item => {
          if ( item.index === 3 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item3':
        this.content.items.forEach(item => {
          if ( item.index === 4 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item4a':
        this.content.items.forEach(item => {
          if ( item.index === 6 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item4b':
        this.content.items.forEach(item => {
          if ( item.index === 7 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item5':
        this.content.items.forEach(item => {
          if ( item.index === 8 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item6':
        this.content.items.forEach(item => {
          if ( item.index === 9 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item7':
        this.content.items.forEach(item => {
          if ( item.index === 10 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item8a':
        this.content.items.forEach(item => {
          if ( item.index === 12 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item8b':
        this.content.items.forEach(item => {
          if ( item.index === 13 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item9':
        this.content.items.forEach(item => {
          if ( item.index === 14 ) {
            selectedItem = item;
          }
        });
        break;
      case 'item10':
        this.content.items.forEach(item => {
          if ( item.index === 15 ) {
            selectedItem = item;
          }
        });
        break;
      default:
        break;
    }
    // console.log('showEventItem fired', identifier, selectedItem, this.content);
    if ( selectedItem != null ) {
      let response: LearnerAccountOfflineContentView;
      this.learnerAccountService.fetchOfflineContentItem(this.content.id, selectedItem.curItemId).subscribe((contentView: LearnerAccountOfflineContentView) => {
        response = contentView;
        this.dialog.closeAll();
        this.dialog.open(ContentOfflineDialogComponent, {
          data: response,
        });
      });
    } else {
      this.infoService.showDialog(GenericMessageDialogComponent, {
        message: 'Dieser Schritt ist im Moment leider noch nicht verfügbar.',
        buttons: OkButton,
      });
    }
  }

  toggleFullscreen(fullscreen: boolean): void {
    this.applicationStateService.setViewMode(fullscreen ? ViewMode.FULLSCREEN : ViewMode.DEFAULT);
    this.fullscreen.next(fullscreen);
  }

  updateLiveStatus(): void {
    timer(0, 10000).subscribe(() => {
      const now = new Date();
      // console.log('checking live events', event.beginning <= now && event.end > now, now, event.beginning, event.end);

      // reset every status before checking
      this.resetStatus();

      this.timeStampsOfEvents.map((event) => {
        const start = new Date(event.beginning);
        const end = new Date(event.end);
        // console.log('checking live events', event.index, event.title, start <= now && end > now, now, start, end);
        if ( event.index === 0 && start <= now && end > now ) {
          this.live_1 = true;
        }
        if ( event.index === 2 && start <= now && end > now ) {
          this.live_2a = true;
          this.live_2b = true;
        }
        if ( event.index === 4 && start <= now && end > now ) {
          this.live_3 = true;
        }
        if ( event.index === 6 && start <= now && end > now ) {
          this.live_4a = true;
          this.live_4b = true;
        }
        if ( event.index === 8 && start <= now && end > now ) {
          this.live_5 = true;
        }
        if ( event.index === 9 && start <= now && end > now ) {
          this.live_6 = true;
        }
        if ( event.index === 10 && start <= now && end > now ) {
          this.live_7 = true;
        }
        if ( event.index === 12 && start <= now && end > now ) {
          this.live_8a = true;
          this.live_8b = true;
        }
        if ( event.index === 14 && start <= now && end > now ) {
          this.live_9 = true;
        }
        if ( event.index === 15 && start <= now && end > now ) {
          this.live_10 = true;
        }
      });
    });
  }

  updateTimeInSVG(): void {
    // fetch times for all events and update svg
    this.timeStampsOfEvents.map((event) => {
      // console.log('updateTimeInSVG', event);
      const timeFrame = this.getTime(event.beginning, event.end);

      if ( event.index === 0 ) {
        this.Zeit_1 = timeFrame;
      }
      if ( event.index === 2 ) {
        this.Zeit_2 = timeFrame;
      }
      if ( event.index === 4 ) {
        this.Zeit_3 = timeFrame;
      }
      if ( event.index === 6 ) {
        this.Zeit_4 = timeFrame;
      }
      if ( event.index === 8 ) {
        this.Zeit_5 = timeFrame;
      }
      if ( event.index === 9 ) {
        this.Zeit_6 = timeFrame;
      }
      if ( event.index === 10 ) {
        this.Zeit_7 = timeFrame;
      }
      if ( event.index === 12 ) {
        this.Zeit_8 = timeFrame;
      }
      if ( event.index === 14 ) {
        this.Zeit_9 = timeFrame;
      }
      if ( event.index === 15 ) {
        this.Zeit_10 = timeFrame;
      }
    });
  }
}
