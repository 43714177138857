import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-spacer',
  template: '',
  styleUrls: [ './spacer.component.scss' ],
})
export class SpacerComponent
  implements OnChanges {

  @HostBinding('style.height') height: string;
  @Input() heightInPx: number | null;

  @HostBinding('style.width') width: string;
  @Input() widthInPx: number | null;

  static getAsPx(value: number | null): string | null {
    if ( !(value > 0) ) {
      return null;
    }

    return value + 'px';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.height = SpacerComponent.getAsPx(this.heightInPx);
    this.width = SpacerComponent.getAsPx(this.widthInPx);
  }

}
