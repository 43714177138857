import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ContentOfflineInvitationComponent } from './content-offline-invitation.component';

@NgModule({
  declarations: [
    ContentOfflineInvitationComponent
   ],
  imports: [
    MatIconModule,
    MatMenuModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    CommonModule
  ],
  exports: [
    ContentOfflineInvitationComponent
   ]
})
export class ContentOfflineInvitationModule {
}
