import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { CachedSubject } from './cached-subject';
import { InfoService } from './info/info.service';
import { MessageKey, YesButton, YesNoButtons } from './info/info.types';
import { PrincipalService } from './principal/principal.service';
import { AnyObject } from './core.types';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckService
  implements CanDeactivate<void> {

  readonly isDirty$: Observable<boolean>;
  private _isDirty = new CachedSubject<boolean>(false);
  private dialogObservable: Observable<boolean> = null;
  private _disrtyFlags: Map<string, CachedSubject<boolean>>;

  constructor(
    private infoService: InfoService,
    private principalService: PrincipalService,
  ) {
    this._disrtyFlags = new Map();
    this.isDirty$ = this._isDirty.withoutEmptyValues();
    this.principalService.isLogged$
      .pipe(map(this.cleanUpContext))
      .subscribe();
  }

  canDeactivate() {
    return this.isDirty$
      .pipe(switchMap(isDirty => isDirty ? this.requestDirtyStateUserAction() : of(true)));
  }

  getGlobalState(): AnyObject<boolean> {
    const result: AnyObject<boolean> = {};
    this._disrtyFlags.forEach((value: CachedSubject<boolean>, key: string) => {
      result[key] = value.value;
    });
    return result;
  }

  getStateAsObservable(context: string): Observable<boolean> {
    return this._disrtyFlags.get(context) ?? EMPTY;
  }

  getState(context: string) {
    return this._disrtyFlags.get(context)?.value ?? false;
  }

  requestDirtyStateUserAction() {
    if ( this.dialogObservable ) {
      return this.dialogObservable;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    this.dialogObservable = this.infoService
      .showConfirm(MessageKey.GENERAL_UNSAVED_CHANGES, YesNoButtons)
      .pipe(finalize(() => this.dialogObservable = null))
      .pipe(map(button => button === YesButton))
      .pipe(tap(confirmed => {
        if ( confirmed ) {
          // clean up service before navigation
          this.cleanUpContext();
        }
      }));

    return this.dialogObservable;
  }

  restoreFlags(flags: AnyObject<boolean>): void {
    Object.keys(flags ?? {})
      .forEach(key => this._disrtyFlags.set(key, new CachedSubject(flags[key])));

    this.checkIsAnyDirty();
  }

  submitNextState(context: string, isDirty: boolean): Observable<boolean> {
    let chachedSubject = this._disrtyFlags.get(context);
    if (chachedSubject === undefined) {
      chachedSubject = new CachedSubject(isDirty);
      this._disrtyFlags.set(context, chachedSubject);
    }
    chachedSubject.next(isDirty);
    this.checkIsAnyDirty();
    return chachedSubject;
  }

  private checkIsAnyDirty(): void {
    let isDirty = false;
    this._disrtyFlags.forEach((value: CachedSubject<boolean>, key: string) => {
      isDirty = isDirty || value.value;
    });
    this._isDirty.next(isDirty);
  }

  private cleanUpContext = (): void => {
    this._disrtyFlags.clear();
    this.checkIsAnyDirty();
  };

}
