<input #fileInput (change)="uploadFile($event)" type="file">
<button (click)="chooseFile()" class="upload-button" color="primary" mat-flat-button>
  <mat-icon svgIcon="upload"></mat-icon>
  <span i18n="@@file_upload_text">Upload new file</span>
</button>

<mat-progress-bar [ngClass]="{'active' : hasFile, 'inactive' : !hasFile}" [value]="progress"
                  mode="indeterminate"></mat-progress-bar>
<span [ngClass]="{'active' : hasFile, 'inactive' : !hasFile}" class="file-name">{{fileName}}</span>

<div *ngIf="hasSizeError" class="error-messages">
  <span [ngClass]="{'active' : hasSizeError, 'inactive' : !hasSizeError}" class="error"
        i18n="@@file_upload_error_file_size">Invalid size! Allowed size (in MB):</span>
  <span [ngClass]="{'active' : hasSizeError, 'inactive' : !hasSizeError}" class="error">{{maxFileSizeMB}}</span>
</div>
<div class="button-wrapper">
  <button (click)="clearInput()" *ngIf="hasFile" color="warn" mat-flat-button>
    <span i18n="@@file_upload_clear_button">Clear input</span>
  </button>
</div>

<div *ngIf="testWithXHR">
  <input #fileInputXHR (change)="saveFile($event)" class="xhr" name="file" type="file">
  <mat-progress-bar [value]="progressXHR" class="active" color="primary"
                    mode="determinate"></mat-progress-bar>
  <code>{{progressXHR}}</code>
  <button (click)="uploadAsXHR()" mat-flat-button>Upload file</button>
</div>
