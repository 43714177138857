export class ObjectHelper {

  /**
   * <p>You can use this for example to collect all parent objects into an iterable array.</p>
   * <code>ObjectHelper.recurseToList(item, content => ViewHelper.getViewData(content)?.parent)</code>
   * @param object the initial object from which to start
   * @param getter select the object of recursion
   * @param toResult convert a parent object to one for the collection
   * @param result leave empty to receive a fresh array
   */
  static recurseToList<T>(object: any, getter: (obj: any) => any,
    toResult: (obj: any) => T = obj => obj,
    result = []): T[] {
    const target = getter(object);
    if ( target != null ) {
      result.push(toResult(target));
      ObjectHelper.recurseToList(target, getter, toResult, result);
    }
    return result;
  }

}
