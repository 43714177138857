import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountDesignService} from '../../admin/account-design/account-design.service';
import {DesignSettings} from '../../admin/account-design/account-design.types';
import {LoginFormModule} from '../../../component/login/login-form/login-form.module';
import {AsyncPipe, CommonModule, JsonPipe} from '@angular/common';
import {PipesModule} from '../../../core/pipes/pipes.module';
import {map, tap} from 'rxjs/operators';
import {destroySubscriptions, takeUntilDestroyed} from '../../../core/reactive/until-destroyed';
import {CachedSubject} from '../../../core/cached-subject';
import {ImageUrlHelper} from '../../../core/image-url-helper';
import {LanguageHelper} from '../../../core/language.helper';

interface DisplayData {
  pictureUrl: string;
  welcomeText: string;
}

@Component({
  standalone: true,
  selector: 'rag-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    LoginFormModule,
    AsyncPipe,
    JsonPipe,
    PipesModule,
    CommonModule,
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() previewMode: boolean = false;
  @Input() texts: DesignSettings;
  readonly data$: Observable<DisplayData>;
  private _data$ = new CachedSubject<DisplayData>(null);

  constructor(
    private accountDesignService: AccountDesignService,
  ) {
    this.data$ = this._data$.withoutEmptyValues();
  }

  ngOnInit(): void {
    if (this.previewMode) {
      this._data$.next({
        pictureUrl: ImageUrlHelper.urlForPicture(this.texts.welcomePictureId, null) ??
          'assets/images/train_bg_login.jpg',
        welcomeText: LanguageHelper.objectToText(this.texts.welcomeTexts),
      });
    } else {
      this.accountDesignService.getStartPage()
        .pipe(map(startPage => ({
          pictureUrl: startPage.welcomePictureUrl,
          welcomeText: LanguageHelper.objectToText(startPage.acc.welcomeTexts),
        })))
        .pipe(tap(this._data$.next))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

}
