import { Core, ImageableContentReference } from '../../core/core.types';
import { DisplayStatusHelper } from '../../core/display-status-helper';
import moment from 'moment';
import { DateHelper } from '../../core/date.helper';


export interface ImportantContentInfo {
  date: moment.Moment;
  label: string;
  isWarning: boolean;
}

export class ContentCardHelper {

  static getImportantContentInfo(
    content: ImageableContentReference,
  ): ImportantContentInfo | null {

    if (content.objType === Core.DistributableType.lms_offlineCnt) {
      return ContentCardHelper.asInfo(
        content.eventDate, $localize`:@@content_card_overview_scheduled_at:Scheduled at:`,
      );
    }

    if (content.objType === Core.DistributableType.lms_curriculum) {
      if (content.viewType === 'a294578e-ab2f-4576-a106-0177600b67ad') {
        return ContentCardHelper.asInfo(
          content.agendaDate, $localize`:@@content_card_overview_scheduled_at:Scheduled at:`,
        );
      }

      if (DisplayStatusHelper.isStatusRecert(content.displaystatus)) {
        return ContentCardHelper.asInfo(
          content.startdate, $localize`:@@content_card_overview_startdate:Start date:`,
        );
      }

      if (content.dueBy) {
        const info = ContentCardHelper.asInfo(
          content.dueBy, $localize`:@@content_card_overview_due_by:Expiration of validity:`,
          ContentCardHelper.isLessThanXDays(content),
        );
        // TF-8947
        console?.log('skipping expiration warning', info)
        return null;
      }

      if (content.startdate) {
        return ContentCardHelper.asInfo(
          content.startdate, $localize`:@@content_card_overview_available_since:Available Since:`,
        );
      }

    }
    return null;
  }

  static asInfo(date: number, label: string, isWarning?: boolean): ImportantContentInfo | null {
    if (!(date > 0)) {
      return null;
    }

    return { date: DateHelper.toMoment(date), label, isWarning: (isWarning === true) };
  }

  static isLessThanXDays(content: ImageableContentReference, futureDays: number = 30): boolean {
    if (!(content.dueBy && content.lastValidUntil)) {
      return false;
    }

    const warningDate = new Date();
    warningDate.setDate(warningDate.getDate() + futureDays);
    const lastValid = new Date(content.lastValidUntil);
    return (warningDate.getTime() >= lastValid.getTime());
  }

}
