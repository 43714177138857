import { naturalCompare } from './natural-sort';
import { Translation } from './translation/translation.types';


export class SortHelper {

  static compareDates(date1: number, date2: number, isAsc: boolean) {
    const _date1 = date1 || Number.MAX_VALUE;
    const _date2 = date2 || Number.MAX_VALUE;
    return (isAsc ? 1 : -1) * (_date1 - _date2);
  }

  /**
   * natural sorting of text-like values (that might be strings, numbers, or {@link Translation}).
   * Numerical values inside strings will be sorted naturally (... 1, 2, ..., 10, 11, ...).
   */
  static compareTextIsh(a: string | number | Translation, b: string | number | Translation, isAsc: boolean,
    caseInsensitive = true) {
    return (isAsc ? 1 : -1) * naturalCompare(a, b, caseInsensitive);
  }

}
