<rag-dialog-header [closingEnabled]="false">
  {{data.userName}} : {{data.curriculumTitle}}
</rag-dialog-header>

<div mat-dialog-content>

  <table mat-table [dataSource]="dataSource">

    <!-- Processing period -->
    <ng-container matColumnDef="validity_period">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@validity_period">Validity period</span>
      </th>
      <td mat-cell *matCellDef="let iteration">
        <div class="calendars">

          <rag-date-time #validSinceTimeComp
                         (valueChanges)="onAddValidSince($event)"
                         (validityChanges)="onValidityChanges($event)"
                         [dateTime]="dateFor(iteration.validSince)"
                         [lessThan]="validUntilTimeComp"
                         [dateOnly]="true"
                         dateLabel="Valid since"
                         i18n-dateLabel="@@ctrl_single_user_details_validSince"
                         i18n-timeLabel="@@global_start_time_label"
                         timeLabel="Start time (HH:MM)">
          </rag-date-time>

          <rag-date-time #validUntilTimeComp
                         (valueChanges)="onAddValidUntil($event)"
                         (validityChanges)="onValidityChanges($event)"
                         [dateTime]="dateFor(iteration.validUntil)"
                         [greatThan]="validSinceTimeComp"
                         [dateOnly]="true"
                         [required]="false"
                         dateLabel="Valid until"
                         i18n-dateLabel="@@ctrl_single_user_details_validUntil"
                         i18n-timeLabel="@@global_end_time_label"
                         timeLabel="End time (HH:MM)">
          </rag-date-time>

        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayColumns;" mat-row></tr>
  </table>
</div>

<div mat-dialog-actions>

  <!-- Close button -->
  <button (click)="onCancel()" class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_close">Close</span>
  </button>

  <!-- Save button -->
  <button (click)="onSaveChanges()" [disabled]="saveButtonDisabled$ | async" mat-flat-button color="accent">
    <span i18n="@@global_save">Save</span>
  </button>

</div>
