import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReportTableControlsComponent } from './report-table-controls.component';
import { StatusStatisticsModule } from '../../../report-generator/status-statistics/status-statistics.module';
import { ReportTableMultiActionsModule } from '../report-table-multi-actions/report-table-multi-actions.module';
import { ReportTableGroupingModule } from '../report-table-grouping/report-table-grouping.module';
import { ReportTableChartsComponent } from '../report-table-charts/report-table-charts.component';


@NgModule({
  declarations: [ ReportTableControlsComponent ],
  exports: [ ReportTableControlsComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ReportTableChartsComponent,
    StatusStatisticsModule,
    ReportTableMultiActionsModule,
    ReportTableGroupingModule,
  ],
})
export class ReportTableControlsModule {
}
