import { Translation } from '../../../core/translation/translation.types';
import { LoginMethod } from '../../../core/principal/login-method';

export interface AnonymousRegisterDialogData {
  description: Translation;
  loginMethod: LoginMethod;
}

export enum AnonymousRegisterDialogStep {
  RegistrationStep = 'registrationStep',
  LoadingStep = 'loadingStep',
  SuccessStep = 'successStep',
}
