import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostLoginUserDataComponent } from './post-login-user-data.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileFieldModule } from '../../../../component/input/profile-field/profile-field.module';
import { ProfileSectionModule } from '../../../../component/input/profile-section/profile-section.module';


@NgModule({
  declarations: [ PostLoginUserDataComponent ],
  exports: [ PostLoginUserDataComponent ],
  imports: [
    CommonModule,
    MatStepperModule,
    MatButtonModule,
    ReactiveFormsModule,
    ProfileFieldModule,
    ProfileSectionModule,
  ],
})
export class PostLoginUserDataModule {
}
