import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from './core/principal/principal.service';
import { RedirectHelper } from './core/redirect.helper';
import { PostLoginService } from './core/principal/post-login.service';
import { PermissionStates } from './core/principal/permission.states';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  implements CanActivate {

  constructor(
    private postLoginService: PostLoginService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // fetchUserData cannot be included in the pipe as it does not return anything if user is not logged in!
    this.principalService.fetchUserData(false);
    return this.checkIsLoggedIn(state)
      .pipe(switchMap(redirect => this.checkPostLoginRequest(redirect, state, route)))
      .pipe(switchMap(redirect => this.checkPermissions(redirect, route)));
  }

  private checkIsLoggedIn(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.principalService.isLogged$
      .pipe(take(1))
      .pipe(map(isLogged => {
        if ( isLogged ) {
          return null;
        }

        if ( console?.warn != null ) {
          console.warn('You are not logged in. Redirecting to login site...');
        }

        const redirect = state.url;
        let url: string;
        if (RedirectHelper.isValidRedirect(redirect)) {
          url = `/login/redirect?url=${encodeURIComponent(state.url)}`;
        } else {
          url = '/login';
        }

        return this.router.parseUrl(url);
      }));
  }

  private checkPermissions(redirect: boolean | UrlTree, route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if ( redirect != null ) {
      // already redirecting
      return of(redirect);
    }

    const checkPermission: string = route.data['checkPermission'];
    const checkPermissionCallback: (route: ActivatedRouteSnapshot, permissions: PermissionStates) => boolean =
      route.data['checkPermissionCallback'];
    if ( !checkPermission && !checkPermissionCallback ) {
      // no permission to check
      return of(true);
    }

    return this.principalService.permissionStates$
      .pipe(map(permissions => {

        const hasCheckedPermission = checkPermission && permissions[checkPermission];
        if ( hasCheckedPermission && checkPermissionCallback == null) {
          // only checkPermission is defined and user has the required permission
          return true;
        }

        const hasCallbackPermission = checkPermissionCallback && checkPermissionCallback(route, permissions);
        if (!checkPermission && hasCallbackPermission) {
          // checkPermission is not defined but checkPermissionCallback returns true
          return true;
        }

        if (hasCheckedPermission && hasCallbackPermission) {
          // both checks are defined and are satisfied
          return true;
        }

        if ( console?.warn != null ) {
          console.warn('Missing required permission...', checkPermission);
        }
        return this.router.parseUrl('/home');
      }));
  }

  private checkPostLoginRequest(redirect: boolean | UrlTree, state: RouterStateSnapshot,
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if ( redirect != null ) {
      // already redirecting
      return of(redirect);
    }

    if ( route.data['skipPostLoginRedirect'] === true ) {
      // do not redirect to /post-login
      return of(null);
    }

    return this.principalService.principal$
      .pipe(switchMap(principal => this.postLoginService.checkPostLoginActions(principal, state.url)));
  }

}
