<h4 i18n="@@password_must">The password must ...</h4>
<ul class="noBull">
  <li
    [class.mat-error]="!passwordField.pristine && errors.min"
    [class.green]="!passwordField.pristine && !errors.min ">

    <mat-icon [svgIcon]="!passwordField.pristine && !errors.min? 'check-circle-outline':'checkbox-blank-circle-outline'" class="noBull"></mat-icon>&nbsp;
    <span i18n="@@password_min_lenght">at least 8 characters long.</span></li>
  <li
    [class.mat-error]="!passwordField.pristine && errors.notHasCapital"
    [class.green]="!passwordField.pristine && !errors.notHasCapital">
    <mat-icon [svgIcon]="!passwordField.pristine && !errors.notHasCapital? 'check-circle-outline':'checkbox-blank-circle-outline'" class="noBull"></mat-icon>&nbsp;
    <span i18n="@@password_has_Capital">contain one capital.</span></li>
  <li
    [class.mat-error]="!passwordField.pristine && errors.notHasLow"
    [class.green]="!passwordField.pristine && !errors.notHasLow">
    <mat-icon [svgIcon]="!passwordField.pristine && !errors.notHasLow? 'check-circle-outline':'checkbox-blank-circle-outline'" class="noBull"></mat-icon>&nbsp;
    <span i18n="@@password_has_Low">contain one lowercase letter.</span></li>
  <li
    [class.mat-error]="!passwordField.pristine && errors.notHasNumber"
    [class.green]="!passwordField.pristine && !errors.notHasNumber">
    <mat-icon [svgIcon]="!passwordField.pristine && !errors.notHasNumber? 'check-circle-outline':'checkbox-blank-circle-outline'" class="noBull"></mat-icon>&nbsp;
    <span i18n="@@password_has_Number">contain a number.</span></li>
  <li
    [class.mat-error]="!passwordField.pristine && errors.notHasSymbol"
    [class.green]="!passwordField.pristine && !errors.notHasSymbol">
    <mat-icon [svgIcon]="!passwordField.pristine && !errors.notHasSymbol? 'check-circle-outline':'checkbox-blank-circle-outline'" class="noBull"></mat-icon>&nbsp;
    <span i18n="@@password_has_Symbol">contain a symbol.</span></li>
</ul>
