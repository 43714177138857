import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSidenavComponent } from './header-sidenav.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatDividerModule } from '@angular/material/divider';
import { HeaderSidenavMenuModule } from '../header-sidenav-menu/header-sidenav-menu.module';
import { MatListModule } from '@angular/material/list';
import { ContentAutocompleteModule } from '../../elearning/content-autocomplete/content-autocomplete.module';


@NgModule({
  declarations: [ HeaderSidenavComponent ],
  exports: [ HeaderSidenavComponent ],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    MatMenuModule,
    PipesModule,
    MatDividerModule,
    HeaderSidenavMenuModule,
    MatListModule,
    ContentAutocompleteModule,
  ],
})
export class HeaderSidenavModule {
}
