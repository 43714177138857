import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';
import { RagLayoutModule } from '../../../rag-layout/rag-layout.module';
import { DashboardComponent } from './dashboard.component';


@NgModule({
  declarations: [
    DashboardComponent,
  ],
  exports: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    PageHeaderComponent,
    RagLayoutModule,
  ],
})
export class DashboardModule {
}
