<div class="content-wrapper">
  <ng-content></ng-content>

  <div (click)="$event.stopPropagation()" class="actions">
    <button [matMenuTriggerFor]="actionMenu" mat-icon-button>
      <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <mat-menu #actionMenu xPosition="before">

      <div (click)="$event.stopPropagation()" class="chart-options">

        <mat-radio-group [(ngModel)]="modelOptions.showLegend" (ngModelChange)="emitOptions()">
          <div mat-menu-item>
            <mat-radio-button [value]="false">
              <span i18n="@@learning_time_legend_hidden">Legend hidden</span>
            </mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button value="bottom">
              <span i18n="@@learning_time_legend_bottom">Legend at bottom</span>
            </mat-radio-button>
          </div>
          <div mat-menu-item>
            <mat-radio-button value="right">
              <span i18n="@@learning_time_legend_right">Legend to the right</span>
            </mat-radio-button>
          </div>
        </mat-radio-group>

      </div>

      <mat-divider></mat-divider>

      <button (click)="downloadClicked.emit()" mat-menu-item>
        <mat-icon svgIcon="file-image"></mat-icon>
        <span i18n="@@chart_export_as_png">Export to PNG</span>
      </button>
    </mat-menu>
  </div>
</div>
