import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestOptions, TrainResponse } from 'src/app/core/global.types';
import { ApiUrls } from '../../../core/api.urls';
import { SysCheck } from '../tech-check/tech-check.types';
import { RagHttpParameterCodec } from '../../../core/rag-http-parameter-codec.class';

export interface ContactRequest {
  content: string;
  control: string;
  date?: string;
  email: string;
  firstname: string;
  lastname: string;
  phonenr?: string;
  reason: string;
  sysCheck?: SysCheck;
  time?: string;
  type: string;
}

interface PasswordResetRequest {
  accountid?: string;
  login: string;
  url?: string;
}

interface SetNewPasswordRequest {
  key: string;
  pass: string;
}

@Injectable({
  providedIn: 'root',
})
export class PasswordForgottenService {

  constructor(private http: HttpClient) {
  }

  /**
   * API/user/requestnewpass/v1
   *
   * @method POST
   * @description searches the user by unique login or in combi with the account he defined alternativ field (if defined)
   * @expects login String login
   * optional accountid Long accountid the users account
   * optional reseturl String url to the reset view containing {0} for the key to vbe inserted
   * @returns success boolean
   * @errors #URN1 error if logged in
   * #URN2 error if not found
   * #URN3 error if expired
   * #URN4 error if SSO permission - pw reset forbidden
   * #URN5 error if setting key failed
   * #URN6 error if current key still valid. Response includes currentKeyCreationDate and currentKeyValidityEndDate
   * #URN7 error if other error
   * #URN8 error if no email in user set
   */
  requestPasswordReset(pwResetRequest: PasswordResetRequest): Observable<TrainResponse> {
    const login = pwResetRequest.login;
    const accountId = pwResetRequest.accountid ?? -1;
    const url = ApiUrls.getKey('PasswordReset') + '?accountId=' + accountId + '&login=' + login;

    return this.http.post<TrainResponse>(url, JSON.stringify(pwResetRequest), HttpRequestOptions);
  }

  /**
   * API/contact/v1
   *
   * @method POST
   * @description Sends mail form a contact form
   * @expects control String stays empty
   * firstname String firstname
   * lastname String lastname
   * email String email
   * phonenr String for calling back reasons
   * reason String {technical,content,noaccess,lostpw,status,notopen,coursestuck,notfound,contentmistake}
   * content String messagebody
   * type String {technical,content,noaccess,lostpw,status,notopen,coursestuck,notfound,contentmistake}
   * @returns success boolean
   * @errors #SCM1 invalid control
   * #SCM2 invalid firstname
   * #SCM3 invalid content
   * #SCM4 invalid lastname
   * #SCM5 invalid email
   * #SCM7 invalid reason #1
   * #SCM8 invalid phonenr
   * #SCM9 invalid reason #2
   * #SCM10 no contacts set
   */
  requestPasswordResetEmail(requestData: ContactRequest) {

    return this.http.post<TrainResponse>(
      ApiUrls.getKey('ContactRequest'),
      JSON.stringify(requestData),
      HttpRequestOptions);
  }

  /**
   * API/user/resetpw/v1
   *
   * @method POST
   * @expects key String The key transmitted in the email
   * pass String The key transmitted in the email
   * @returns boolean success
   * @errors #URP1 error if logged in
   * #URP2 error if key not found
   * #URP3 error if expired
   */
  resetPassword(setPasswordRequest: SetNewPasswordRequest): Observable<TrainResponse> {
    const query = ApiUrls.getKeyWithParams('PasswordUpdate', '1');
    let body = new HttpParams({ encoder: new RagHttpParameterCodec() });
    // @see https://stackoverflow.com/a/54010521
    body = body.append('pass', setPasswordRequest.pass);
    return this.http.post<TrainResponse>(
      query + '?key=' + setPasswordRequest.key, body);
  }
}
