import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { TableColumnDataType, TableControllerTypes } from '../table-controller/table-controller.types';
import { ReportRowAccount } from '../../../core/report/report.types';
import { TableAccessors } from '../table.accessors';
import { TableHelper } from '../table-helper';
import { ImageUrlHelper } from '../../../core/image-url-helper';
import { Core } from '../../../core/core.types';


@Component({
  selector: 'rag-tables-column-render-default',
  templateUrl: './tables-column-render-default.component.html',
  styleUrls: [ './tables-column-render-default.component.scss' ],
})
export class TablesColumnRenderDefaultComponent {

  @Input() column: TableControllerTypes.ColumnMenuItem;
  displayHint = false;
  @Input() row: any;
  @Input() targetType: Core.DistributableType | string;

  get columnId(): string {
    return this.column?.id;
  }

  @HostBinding('class')
  get dataType(): TableColumnDataType {
    return this.column?.options?.dataType;
  }

  get value(): string {
    const value = TableAccessors.getColumnValue(this.row, this.column?.options, this.columnId);

    if ( value == null || value === '' ) {
      return '';
    }

    return value;
  }

  getCount(data: ReportRowAccount): { percentage: number; done: number; total: number } {
    const percentage = !(data?.accountItemsPercentage > 0) ? 0 : data.accountItemsPercentage;
    const done = !(data?.accountItemsDone > 0) ? 0 : data.accountItemsDone;
    const total = !(data?.accountItemsTotal > 0) ? 0 : data.accountItemsTotal;
    return { percentage, done, total };
  }

  getCourseType(): Core.CourseType | null {

    const row = this.row;
    if (row == null) {
      return null;
    }

    return row.courseType ??
      row.$data?.course?.courseType ??
      null;
  }

  getDropDownIcon(): string | null {
    const key = this.getDropdownValue();
    return this.column.options?.dropDownOptionsIcons?.[key];
  }

  getStyles(): any {
    return {
      color: this.getIconColor(),
    };
  }

  getIconColor() {
    const key = this.getDropdownValue();
    return this.column.options?.dropDownOptionsColor?.[key];
  }

  getDisplayValue(): string {
    return TableAccessors.getDisplayValue(this.row, this.column?.options, this.columnId);
  }

  getColumnValue(): string {
    return TableAccessors.getColumnValue(this.row, this.column?.options, this.columnId);
  }

  getDropdownValue(): string {
    return TableAccessors.getDropdownValue(this.getColumnValue(), this.column.options);
  }

  getPictureUrl(): string {
    return ImageUrlHelper.urlForPictureUUID(this.value);
  }

  getTags(): string[] {
    return TableHelper.splitTags(this.value)
      .map(tag => TableAccessors.getDropdownLabel(tag, this.column.options));
  }

  trim(value: string): string {
    return value.trim();
  }

  getTemplate(
    tplDefault: TemplateRef<any>,
    tplDate: TemplateRef<any>,
    tplDateTime: TemplateRef<any>,
    tplNumber: TemplateRef<any>,
    tplText: TemplateRef<any>,
    tplHtml: TemplateRef<any>,
    tplDisplayStatus: TemplateRef<any>,
    tplDropDown: TemplateRef<any>,
    tplPrice: TemplateRef<any>,
    tplPriceFree: TemplateRef<any>,
    tplAccountPercentage: TemplateRef<any>,
    tplTags: TemplateRef<any>,
    tplImage: TemplateRef<any>,
  ): TemplateRef<any> {

    const columnId = this.columnId;
    const dataType = this.dataType;
    if ( !(columnId?.trim()?.length > 0) ) {
      return tplText;
    }

    switch ( columnId ) {
      case 'displayStatus':
        this.displayHint = false;
        return tplDisplayStatus;

      case 'accountDisplayStatus':
        this.displayHint = true;
        return tplDisplayStatus;

      case 'accountItemsPercentage':
        return tplAccountPercentage;
    }

    switch ( dataType ) {
      case TableColumnDataType.date:
        return tplDate;

      case TableColumnDataType.dateTime:
        return tplDateTime;

      case TableColumnDataType.dropdown:
      case TableColumnDataType.radio:
        return tplDropDown;

      case TableColumnDataType.html:
        return tplHtml;

      case TableColumnDataType.number:
        return tplNumber;

      case TableColumnDataType.price:
        const value = parseInt(this.value, 10);
        if ( value > 0 ) {
          return tplPrice;
        } else {
          return tplPriceFree;
        }

      case TableColumnDataType.tags:
      case TableColumnDataType.multiselect:
        return tplTags;

      case TableColumnDataType.image:
        return tplImage;

      case TableColumnDataType.text:
        return tplText;

      default:
      case TableColumnDataType.email:
      case TableColumnDataType.other:
      case TableColumnDataType.password:
        return tplDefault;
    }
  }

  hasConfirmation(): boolean | null {

    const row = this.row;
    if (row == null) {
      return null;
    }

    return row.hasConfirmation ??
      row.confirmActive ??
      row.$data?.course?.hasConfirmation ??
      row.$data?.course?.confirmActive ??
      null;
  }

}
