import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forIn } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CachedSubject } from 'src/app/core/cached-subject';
import { ComponentCommunicationService } from 'src/app/core/component-communication.service';
import { RagPageComponent } from 'src/app/rag-layout/rag-page/rag-page.component';
import { GenericMessageDialogComponent } from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { InfoService } from '../../../core/info/info.service';
import { CancelButton, MessageKey, OkButton } from '../../../core/info/info.types';
import { PrincipalService } from '../../../core/principal/principal.service';
import {
  destroySubscriptions,
  subscribeUntilDestroyed,
  takeUntilDestroyed,
} from '../../../core/reactive/until-destroyed';
import { Layout, Widget } from '../../../rag-layout/rag-layout.types';
import { RagPageService } from '../../../rag-layout/rag-page/rag-page.service';
import { WidgetConf, WidgetContext, WidgetsUUID } from '../../../rag-layout/widgets/widgets.types';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { PreloadService } from '../../../core/preload.service';

export interface SelectableHelperEntry {
  initial: boolean;
  onlyOnce: boolean;
  selectable: boolean;
  uuid: string;
}

export interface SelectableHelper {
  codeRedeemWidget: SelectableHelperEntry;
  myCertificatesWidget: SelectableHelperEntry;
  newsWidget: SelectableHelperEntry;
  overviewWidget: SelectableHelperEntry;
  specialWidget: SelectableHelperEntry;
  eventsWidget: SelectableHelperEntry;
  gamificationWidget: SelectableHelperEntry;
}

@Component({
  selector: 'rag-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.scss' ],
})
export class HomeComponent
  implements OnInit, OnDestroy {

    @ViewChild('ragPage') ragPage: RagPageComponent;

    fullName: string;
  isInEditMode = false;
  login: string;
  myLayout$: Observable<Layout>;
  selectableHelper$: Observable<SelectableHelper>;
  showAddWidgetButton = true;
  showSelectionCodeRedeem = true;
  showSelectionMyCertificates = true;
  showSelectionNews = true;
  showSelectionOverview = true;
  showSelectionSpecials = true;
  showEventsWidget = true;
  showGamificationWidget = true;
  anonymous = false;
  widgetsEditableForUser = true;

  myWidgetsDisabled$: Observable<boolean>;

  private _myWidgetsDisabled$ = new CachedSubject(true);

  constructor(private principalService: PrincipalService,
              private componentCommunicationService: ComponentCommunicationService,
              private accountService: AccountDesignService,
              private ragPageService: RagPageService,
              private infoService: InfoService,
              private preloadService: PreloadService,
  ) {

    this.myWidgetsDisabled$ = this._myWidgetsDisabled$.withoutEmptyValues();

    subscribeUntilDestroyed(this.principalService.permissionStates$.pipe(map(permissionStates => {
      this._myWidgetsDisabled$.next(!permissionStates.navUserCertificates);
    })), this);

    subscribeUntilDestroyed(this.principalService.fetchUserData()
      .pipe(map(principal => {
        this.fullName = principal.fullName;
        this.login = principal.login;
      })), this);

    const fnGetHelperWidget = (widget: WidgetConf): SelectableHelperEntry => ({
        initial: widget.initial,
        selectable: widget.selectable && !widget.static,
        uuid: widget.uuid,
        onlyOnce: widget.onlyOnce,
      });

    this.selectableHelper$ = this.accountService.getStyleSettings()
      .pipe(map(styleSettings => {
        this.widgetsEditableForUser = styleSettings?.acc?.widgetsEditableByUser ?? true;
        return {
          newsWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.NewsWidgetUUID]),
          overviewWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.OverviewWidgetUUID]),
          specialWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.SpecialsWidgetUUID]),
          codeRedeemWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.CodeRedeemWidgetUUID]),
          myCertificatesWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.MycertificatesWidgetUUID]),
          eventsWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.EventsWidgetUUID]),
          gamificationWidget: fnGetHelperWidget(styleSettings.acc.widgets[WidgetsUUID.GamificationWidgetUUID]),
        };
      }));

    this.myLayout$ = this.ragPageService.getWidgetsLayout(WidgetContext.LayoutHome);

    subscribeUntilDestroyed(
      combineLatest([ this.selectableHelper$, this.myLayout$ ])
        .pipe(
          map(([ selectableHelper, myLayout ]) => {
            this.checkIfExistingAndOnlyOnce(myLayout, selectableHelper);
          })), this);

    this.preloadService.getAnonymousRegistration()
      .pipe(tap(anonymousRegistration => this.anonymous = anonymousRegistration))
      .pipe(take(1))
      .subscribe();
  }

  get getSpecialsWidgetUUID(): string {
    return WidgetsUUID.SpecialsWidgetUUID;
  }

  get mycertificatesUUID(): string {
    return WidgetsUUID.MycertificatesWidgetUUID;
  }

  get newsWidgetUUID(): string {
    return WidgetsUUID.NewsWidgetUUID;
  }

  get overviewWidgetUUID(): string {
    return WidgetsUUID.OverviewWidgetUUID;
  }

  get redeemWidgetUUID(): string {
    return WidgetsUUID.CodeRedeemWidgetUUID;
  }

  get gamificationWidgetUUID(): string {
    return WidgetsUUID.GamificationWidgetUUID;
  }

  get getEventsWidgetUUID(): string {
    return WidgetsUUID.EventsWidgetUUID;
  }

  addWidget(widgetUUID: string) {
    this.componentCommunicationService.addWidget(widgetUUID);
  }

  checkIfAddWidgetButtonNeedsToBeDisplayed(): void {
    this.showAddWidgetButton = (
      this.showSelectionCodeRedeem === true ||
      this.showSelectionMyCertificates === true ||
      this.showSelectionNews === true ||
      this.showSelectionOverview === true ||
      this.showSelectionSpecials === true ||
      this.showGamificationWidget === true
    );
  }

  checkIfExistingAndOnlyOnce(myLayout: Layout, selectableHelper: SelectableHelper) {
    // reset values
    this.showSelectionCodeRedeem = true;
    this.showSelectionMyCertificates = true;
    this.showSelectionNews = true;
    this.showSelectionOverview = true;
    this.showSelectionSpecials = true;
    this.showEventsWidget = true;
    this.showGamificationWidget = true;

    const hasLayoutItems = Object.keys(myLayout.layout || {}).length > 0;
    forIn(selectableHelper, (widget: SelectableHelperEntry) => {
      const uuid = widget.uuid;
      let showEntry = widget.selectable;
      if ( showEntry && widget.onlyOnce ) {
        // if widget can be added only once, check if widget is currently selected
        if ( hasLayoutItems ) {
          forIn(myLayout.layout, (w: Widget) => {
            if ( w.widgetUUID === uuid ) {
              showEntry = false;
              return false;
            }
          });
        } else if ( widget.initial ) {
          showEntry = false;
        }
      }

      switch ( uuid ) {
        case WidgetsUUID.CodeRedeemWidgetUUID:
          this.showSelectionCodeRedeem = showEntry;
          break;
        case WidgetsUUID.MycertificatesWidgetUUID:
          this.showSelectionMyCertificates = showEntry;
          break;
        case WidgetsUUID.NewsWidgetUUID:
          this.showSelectionNews = showEntry;
          break;
        case WidgetsUUID.OverviewWidgetUUID:
          this.showSelectionOverview = showEntry;
          break;
        case WidgetsUUID.SpecialsWidgetUUID:
          this.showSelectionSpecials = showEntry;
          break;
        case WidgetsUUID.EventsWidgetUUID:
          this.showEventsWidget = showEntry;
          break;
        case WidgetsUUID.GamificationWidgetUUID:
          this.showGamificationWidget = showEntry;
          break;
      }
    });
    this.checkIfAddWidgetButtonNeedsToBeDisplayed();
  }

  clearWidgetsAndLoadNewFromAdminSetting() {
    this.infoService.showDialog(GenericMessageDialogComponent, {
      messageKey: MessageKey.RAGPAGE_REALLY_WANT_TO_RESET_WIDGETS,
      titleKey: MessageKey.RAGPAGE_REALLY_WANT_TO_RESET_WIDGETS_TITLE,
      buttons: OkButton | CancelButton,
    }).subscribe(button => {
      if ( button === OkButton ) {
        this.componentCommunicationService.resetWidgets(WidgetContext.LayoutHome);
      }
    });
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
  }

  toggleEdit() {
    this.isInEditMode = !this.isInEditMode;
    this.ragPage.edit = this.isInEditMode;
  }
}
