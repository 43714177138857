import { ColumnFilterV2, splitFilterToStrings } from '../column-settings/column-filter.types';
import { QlExpression, QlExpressionValue } from './report.types';
import { forIn } from 'lodash';
import { ColumnName } from '../column-settings/column-settings.types';
import * as moment from 'moment';


export class ReportFilterHelper {

  static asFilterExpression(columnFilters: ColumnFilterV2<string, string>[] = []): QlExpression {
    return columnFilters
      .reduce((result, filter: ColumnFilterV2<ColumnName>) => {
        const value = filter?.value;
        if ( (value == null) || (value === '') ) {
          // filter is empty -> skip
          return result;
        }

        const columnId: ColumnName = filter.identifier;
        switch ( columnId ) {
          case ColumnName.assignmentMandatory:
          case ColumnName.courseItemLocked:
          case ColumnName.courseSessionRunning:
            ReportFilterHelper.addFilterBoolean(result, filter);
            break;

          case ColumnName.accountCreationDate:
          case ColumnName.accountEndDate:
          case ColumnName.accountStartDate:
          case ColumnName.accountValidSince:
          case ColumnName.accountValidUntil:
          case ColumnName.courseItemLastModified:
          case ColumnName.courseLastExecutionDate:
          case ColumnName.userDateOfBirth:
          case ColumnName.userHireDate:
            ReportFilterHelper.addFilterDate(result, filter);
            break;

          case ColumnName.accountDisplayStatus:
            ReportFilterHelper.addFilterDisplayStatus(result, filter);
            break;

          case ColumnName.courseItemStatus:
            ReportFilterHelper.addFilterCMILessonStatus(result, filter);
            break;

          case ColumnName.courseStatus:
            ReportFilterHelper.addFilterCourseStatus(result, filter);
            break;

          case ColumnName.accountItemsPercentage:
          case ColumnName.accountIteration:
          case ColumnName.courseId:
          case ColumnName.courseItemExecCount:
          case ColumnName.courseItemId:
          case ColumnName.courseItemResult:
          case ColumnName.courseSessionLastError:
          case ColumnName.curriculumId:
          case ColumnName.curriculumVariationCounter:
          case ColumnName.userId:
          case ColumnName.userState:
          case ColumnName.courseContributionsCount:
            ReportFilterHelper.addFilterNumber(result, filter);
            break;

          case ColumnName.accountProcessingTimeStatus:
            ReportFilterHelper.addFilterAccountProcessingStatus(result, filter);
            break;

          case ColumnName.courseItemTitle:
          case ColumnName.courseSessionDiagnostic:
          case ColumnName.courseTitle:
          case ColumnName.curriculumTitle:
          case ColumnName.curriculumVariationTitle:
          case ColumnName.userArea:
          case ColumnName.userCompany:
          case ColumnName.userCostCenter:
          case ColumnName.userCostcenterDesc:
          case ColumnName.userEmail:
          case ColumnName.userEmployeeID:
          case ColumnName.userExtID:
          case ColumnName.userFirstname:
          case ColumnName.userFreeTextAttribute1:
          case ColumnName.userFreeTextAttribute2:
          case ColumnName.userFreeTextAttribute3:
          case ColumnName.userFreeTextAttribute4:
          case ColumnName.userFreeTextAttribute5:
          case ColumnName.userFreeTextAttribute6:
          case ColumnName.userFreeTextAttribute7:
          case ColumnName.userGeID:
          case ColumnName.userHostID:
          case ColumnName.userJobCode:
          case ColumnName.userLastname:
          case ColumnName.userLocation:
          case ColumnName.userLocationDesc:
          case ColumnName.userLogin:
          case ColumnName.userSoeID:
          case ColumnName.userSubstitute2ID:
          case ColumnName.userSubstituteID:
          case ColumnName.userSuperiorID:
            ReportFilterHelper.addFilterText(result, filter);
            break;

          case ColumnName.userSex:
            ReportFilterHelper.addFilterGender(result, filter);
            break;

          case ColumnName.curriculumTags:
          case ColumnName.courseTags:
          default:
            // currently not available for filtering
            break;
        }

        return result;
      }, {});
  }

  /**
   * @deprecated replace use with {@link splitFilterToStrings}
   */
  static splitFilterToArray(value: string): string[] {
    return splitFilterToStrings(value);
  }

  private static addFilterAccountProcessingStatus(result: QlExpression, filter: ColumnFilterV2): void {
    const columnId = filter.identifier;
    switch ( filter.value ) {
      case 'locked':
        result[columnId] = { $eq: 'locked' };
        break;
      case 'valid':
        result[columnId] = { $eq: 'valid' };
        break;
      case 'not_attempted':
        result[columnId] = { $eq: 'not_attempted' };
        break;
      case 'anulled':
        result[columnId] = { $eq: 'anulled' };
        break;
      case 'ended':
        result[columnId] = { $eq: 'ended' };
        break;
    }
  }

  private static addFilterBoolean(result: QlExpression, filter: ColumnFilterV2): void {
    const columnId = filter.identifier;
    switch ( filter.value ) {
      case 'true':
        result[columnId] = { $eq: true };
        break;
      case 'false':
        result[columnId] = { $eq: false };
        break;
      case 'notTrue':
        result[columnId] = { $or: [ { $eq: false }, { $eq: null } ] };
        break;
    }
  }

  private static addFilterCMILessonStatus(result: QlExpression, filter: ColumnFilterV2): void {
    const columnId = filter.identifier;
    switch ( filter.value ) {
      case 'green':
        result[columnId] = { $in: [ 1, 2, 5 ] };
        break;
      case 'yellow':
        result[columnId] = { $eq: 4 };
        break;
      case 'failed':
        result[columnId] = { $eq: 3 };
        break;
      case 'not_attempted':
        result[columnId] = { $eq: 0 };
        break;
      case 'other':
        result[columnId] = { $notIn: [ 0, 1, 2, 3, 4, 5 ] };
        break;
    }
  }

  private static addFilterCourseStatus(result: QlExpression, filter: ColumnFilterV2): void {
    const columnId = filter.identifier;
    switch ( filter.value ) {
      case 'green':
        result[columnId] = { $eq: 1 };
        break;
      case 'yellow':
        result[columnId] = { $eq: 4 };
        break;
      case 'red':
        result[columnId] = { $eq: 16 };
        break;
      case 'other':
        result[columnId] = { $notIn: [ 1, 4, 16 ] };
        break;
    }
  }

  private static addFilterDate(result: QlExpression, filter: ColumnFilterV2): void {
    const action = filter.action || '$gte';
    if ((action === '$nextDays') ||
      (action === '$nextMonths') ||
      (action === '$previousDays') ||
      (action === '$previousMonths')) {
      // these filters do not work on unix dates but days or months
      const intValue = parseInt(filter.value, 10);
      if (!isNaN(intValue)) {
        const obj = result[filter.identifier] = {};
        obj[action] = intValue;
      }
      return;
    }

    const date = moment(filter.value);
    if ( !date.isValid() ) {
      return;
    }
    if ( (action === '$gt') || (action === '$lt') ) {
      if ( action === '$gt' ) {
        date.endOf('day');
      } else {
        date.startOf('day');
      }
      const value: QlExpressionValue = {};
      value[action] = date.unix() * 1000;
      result[filter.identifier] = value;
    } else if ( (action === '$gte') || (action === '$lte') ) {
      if ( action === '$gte' ) {
        date.startOf('day');
      } else {
        date.endOf('day');
      }
      const value: QlExpressionValue = {};
      value[action] = date.unix() * 1000;
      result[filter.identifier] = value;
    } else if ( action === '$eq' ) {
      date.startOf('day');
      const startTimestamp = date.unix() * 1000;
      date.endOf('day');
      const endTimestamp = date.unix() * 1000;
      result[filter.identifier] = { $and: [ { $gte: startTimestamp }, { $lte: endTimestamp } ] };
    }
  }

  private static addFilterDisplayStatus(result: QlExpression, filter: ColumnFilterV2): void {
    const columnId = filter.identifier;
    switch ( filter.value ) {
      case 'green':
        result[columnId] = { $eq: 1 };
        break;
      case 'red':
        result[columnId] = { $in: [ 0, 2 ] };
        break;
      case 'recertification':
        result[columnId] = { $eq: 7 };
        break;
      case 'not_green':
        result[columnId] = { $in: [ -1, 0, 2, 4, 6, 7 ] };
        break;
      case 'not_red':
        result[columnId] = { $in: [ 1, 3, 5, 7 ] };
        break;
    }
  }

  private static addFilterGender(result: QlExpression, filter: ColumnFilterV2) {
    let value = filter.value;
    if ( value === undefined ) {
      value = null;
    }
    result[filter.identifier] = { $eq: value };
  }

  private static addFilterNumber(result: QlExpression, filter: ColumnFilterV2): void {
    const action = filter.action || '$eq';
    const filterObj = {};
    if ( action === '$between' ) {
      const split = /^(\d+)\s*-\s*(\d+)$/.exec(filter.value);
      if ( !(split && split.length === 3) ) {
        // not a valid value for filtering
        return;
      }
      const from = Number(split[1]);
      const to = Number(split[2]);
      if ( from <= to ) {
        filterObj['$gte'] = from;
        filterObj['$lte'] = to;
        result[filter.identifier] = filterObj;
      }

    } else if ( (action === '$in') || (action === '$notIn') ) {
      const value: number[] = [];
      forIn(ReportFilterHelper.splitFilterToArray(filter.value), (v: string | number) => {
        v = Number(v);
        if ( !isNaN(v) ) {
          value.push(v);
        }
      });
      if ( value.length ) {
        filterObj[action] = value;
        result[filter.identifier] = filterObj;
      }

    } else {
      filterObj[action] = Number(filter.value);
      if ( !isNaN(filterObj[action]) ) {
        result[filter.identifier] = filterObj;
      }
    }
  }

  private static addFilterText(result: QlExpression, filter: ColumnFilterV2): void {
    const action = filter.action || '$like';
    const filterObj: QlExpressionValue = {};
    if ( (action === '$in') || (action === '$notIn') ) {
      filterObj[action] = ReportFilterHelper.splitFilterToArray(filter.value);
    } else {
      filterObj[action] = filter.value;
    }
    if ( filterObj[action] ) {
      result[filter.identifier] = filterObj;
    }
  }

}
