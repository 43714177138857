import {Injectable} from '@angular/core';
import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  Faro,
  FetchTransport,
  initializeFaro,
  LogLevel,
  SessionInstrumentation,
  WebVitalsInstrumentation,
} from '@grafana/faro-web-sdk';
import {RuntimeEnvironmentService} from '../runtime-environment.service';
import {VersionInfoService} from '../../component/version-info/version-info.service';

@Injectable({
  providedIn: 'root',
})
export class GrafanaFaroService {

  private _faro: Faro;
  private _initialized = false;

  constructor(
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private versionInfoService: VersionInfoService,
  ) {

  }

  public onInit() {
    if (this._initialized) {
      return;
    }
    this.initFaroAdvanced();
  }

  private initFaroAdvanced() {
    const environment = this.runtimeEnvironmentService.environment;
    const faro = environment?.faro;
    if (!faro?.url || !faro?.apiKey) {
      return;
    }
    const trainFrontEndVersion = this.versionInfoService.getTrainFrontEndVersion();

    this._faro = initializeFaro({
      instrumentations: [
        new ErrorsInstrumentation(),
        new WebVitalsInstrumentation(),
        new ConsoleInstrumentation({
          disabledLevels: [LogLevel.TRACE, LogLevel.LOG, LogLevel.INFO, LogLevel.WARN, LogLevel.DEBUG],
        }),
        new SessionInstrumentation(),
      ],
      transports: [
        new FetchTransport({
          url: faro.url,
          apiKey: faro.apiKey,
        }),
        new ConsoleTransport(),
      ],
      app: {
        name: faro.appidentifier == null ? 'unknown' : faro.appidentifier,
        version: trainFrontEndVersion.version + '-' + trainFrontEndVersion.commitHash,
      },
    });
    this._initialized = true;
  }

  public pushBackendError(errorCode: string) {
    if (!this._initialized) {
      return;
    }
    this._faro.api.pushError(new Error(errorCode), {type: 'backendError'});
  }

  public pushWhoops(message: string) {
    if (!this._initialized) {
      return;
    }
    this._faro.api.pushError(new Error('Whoops shown'), {
      context: {
        type: 'whoops',
        errorMessage: message,
      }
    });
  }

  public pushUnexpectedError(error: Error) {
    if (!this._initialized) {
      return;
    }
    this._faro.api.pushError(error, {type: 'frontendError'});
  }

  public pushNotPermitted(message: string) {
    if (!this._initialized) {
      return;
    }
    this._faro.api.pushError(new Error('403 thrown'), {
      context: {
        type: '403',
        errorMessage: message,
      }
    });
  }
}
