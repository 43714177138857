import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CatalogUserFieldsData } from './catalog-user-fields.types';
import { InfoType, OkButton } from '../../../core/info/info.types';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnyObject } from '../../../core/core.types';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { ProfileService } from '../../../core/profile.service';
import { EMPTY, Observable } from 'rxjs';
import { InfoService } from '../../../core/info/info.service';


@Component({
  selector: 'rag-catalog-user-fields',
  templateUrl: './catalog-user-fields.component.html',
  styleUrls: [ './catalog-user-fields.component.scss' ],
})
export class CatalogUserFieldsComponent {

  form: UntypedFormGroup;
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CatalogUserFieldsData,
    private dialogRef: MatDialogRef<CatalogUserFieldsComponent>,
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private profileService: ProfileService,
  ) {
    this.buildForm();
  }

  onSubmit(): void {

    if ( this.saving || this.form.pristine || this.form.invalid ) {
      return;
    }

    this.infoService.snackBar?.dismiss();
    this.saving = true;
    this.form.disable();

    const profileData = this.profileService.formToProfileData(this.form);
    this.profileService.putUserData(profileData)
      .pipe(take(1))

      .pipe(catchError(this.handleError))
      .pipe(finalize(() => {
        this.saving = false;
        this.form.enable();
      }))

      .pipe(tap(() => this.dialogRef.close(OkButton)))
      .subscribe();
  }

  private buildForm(): void {
    if ( this.form != null ) {
      return;
    }

    const controls = (this.data?.userFields ?? [])
      .reduce((pV, field) => {
        if ( field.required ) {
          pV[field.fieldId] = [ null, [ Validators.required ] ];
        } else {
          pV[field.fieldId] = [ null ];
        }
        return pV;
      }, {});
    this.form = this.formBuilder.group(controls);
  }

  private handleError = (): Observable<never> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

}
