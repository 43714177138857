import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import { ColumnFilterDropdownOption, FilterOperator } from '../../../core/column-settings/column-filter.types';
import { AnyObject } from '../../../core/core.types';
import { naturalCompare } from '../../../core/natural-sort';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CachedSubject } from '../../../core/cached-subject';
import { takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { TableHelper } from '../../table/table-helper';


@Component({
  selector: 'rag-pivot-filter-tags',
  templateUrl: './pivot-filter-tags.component.html',
  styleUrls: [ './pivot-filter-tags.component.scss' ],
})
export class PivotFilterTagsComponent
  extends AbstractPivotFilterComponent
  implements OnChanges {

  @Input() inline = true;
  @ViewChild('inputElement', { read: ElementRef }) inputElement: ElementRef<HTMLInputElement>;
  @Input() options: AnyObject<string>;
  tagsAvailable: ColumnFilterDropdownOption[];
  readonly tagsAvailable$: Observable<ColumnFilterDropdownOption[]>;
  tagsSelected: ColumnFilterDropdownOption[];
  private _tagsAvailable$ = new CachedSubject<ColumnFilterDropdownOption[]>(null);

  constructor() {
    super();

    this.defaultAction = FilterOperator.LIKE;

    this.tagsAvailable$ = this._tagsAvailable$.withoutEmptyValues();
    this.formControl.valueChanges
      .pipe(map(this.getFilteredAvailableTags))
      .pipe(tap(this._tagsAvailable$.next))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  static convertOptions(
    options: AnyObject<string> | null, sort = true
  ): ColumnFilterDropdownOption[] {

    let dropDownOptions = Object.entries(options)

      // create objects with cleaned filter values
      .map(([ value, label ]) => ({
        label,
        value: value?.trim().toLocaleLowerCase(),
      }))

      // remove any empty values
      .filter(entry => !!entry?.value);

    if (sort) {
      // sort by label
      dropDownOptions = dropDownOptions.sort((a, b) => naturalCompare(a.label, b.label));
    }

    return dropDownOptions;
  }

  static removeTag(
    collection: ColumnFilterDropdownOption[],
    tag: ColumnFilterDropdownOption | null,
  ): void {

    const index = collection.indexOf(tag);
    if ( index >= 0 ) {
      collection.splice(index, 1);
    }
  }

  afterFilterSet(): void {
    this.updateTags();
  }

  ngOnChanges(
    changes: SimpleChanges,
  ): void {

    if ( changes.options != null ) {
      this.updateTags();
    }
  }

  onTagRemoved(
    tag: ColumnFilterDropdownOption,
  ): void {

    PivotFilterTagsComponent.removeTag(this.tagsSelected, tag);

    this.tagsAvailable.push(tag);
    this.tagsAvailable = this.tagsAvailable
      .sort((a, b) => naturalCompare(a.label, b.label));

    this.updateFilterValue();
  }

  onTagSelected(
    event: MatAutocompleteSelectedEvent,
    trigger: MatAutocompleteTrigger
  ): void {

    const tag = event.option.value;

    PivotFilterTagsComponent.removeTag(this.tagsAvailable, tag);
    this.tagsSelected.push(tag);

    this.updateFilterValue();

    // automatically re-open suggestion panel after selection
    setTimeout(() => trigger.openPanel());
  }

  updateFilterValue(): void {

    const value = this.tagsSelected
      .map(tag => tag.value)
      .join(',');
    this.onChange(value);

    this.clearInput();

    this._tagsAvailable$.next(this.tagsAvailable);
  }

  private clearInput(): void {
    const elt: HTMLInputElement = this.inputElement?.nativeElement;
    if ( elt == null ) {
      return;
    }

    elt.value = null;
  }

  private getFilteredAvailableTags = (
    value: string | null,
  ): ColumnFilterDropdownOption[] => {

    if ( typeof (value) !== 'string' ) {
      // triggered after selection in autocomplete
      return this.tagsAvailable;
    }

    value = value?.trim().toLocaleLowerCase();

    if ( !value ) {
      return this.tagsAvailable;
    }

    return (this.tagsAvailable ?? [])
      .filter(tag => (tag.label.toLowerCase().includes(value) || tag.value.includes(value)));
  };

  private updateTags(): void {

    const allTags = PivotFilterTagsComponent.convertOptions(this.options ?? {}, false);

    const splitValue = TableHelper.splitTags(this.value);
    this.tagsSelected = allTags
      .filter(tag => splitValue.includes(tag.value));

    this.tagsAvailable = allTags
      .filter(tag => !this.tagsSelected.includes(tag));

    this._tagsAvailable$.next(this.tagsAvailable);
  }

}
