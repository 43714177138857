import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentService } from '../../../core/content/content.service';
import { CurriculumLinkDialogTypes } from './curriculum-link-dialog.types';
import { ImageableContentReference } from 'src/app/core/core.types';

@Component({
  selector: 'rag-curriculum-link-dialog',
  templateUrl: './curriculum-link-dialog.component.html',
  styleUrls: [ './curriculum-link-dialog.component.scss' ],
})
export class CurriculumLinkDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CurriculumLinkDialogTypes,
  ) {
  }

  getContentHref(curriculum: ImageableContentReference): string {
    return ContentService.getContentHref(curriculum, false);
  }

}
