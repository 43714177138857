import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'rag-menu-search',
  templateUrl: './menu-search.component.html',
  styleUrls: [ './menu-search.component.scss' ],
})
export class MenuSearchComponent {

  @Input() public label = $localize`:@@global_search:Search`;
  @HostBinding('class.mat-menu-item') matMenuItemClass = true;
  @Input() public value = '';
  @Output() public readonly valueChange: Observable<string>;
  private _valueChange = new EventEmitter<string>();

  constructor() {
    this.valueChange = this._valueChange.asObservable();
  }

  onChange(value: string | null): void {
    this._valueChange.emit(value);
  }

  onClear() {
    this.value = '';
    this._valueChange.emit('');
  }

}
