<mat-form-field appearance="outline"
  [formGroup]="form"
  appearance="outline"
>

  <mat-label>
    <mat-icon
      *ngIf="field.setByInterface === true"
      class="special-required"
      svgIcon="elevator"
    ></mat-icon>
    {{field.label | localeText}}
  </mat-label>

  <mat-select
    [formControlName]="field.fieldId"
    [disabled]="isDisabled()"
  >

    <mat-option
      *ngIf="!field.required"
      [value]="null"
    > - <span i18n="@@global_none_selected">None selected</span> - </mat-option>

    <mat-option
      *ngFor="let option of options"
      [value]="option.key"
    >{{option.value}}</mat-option>

  </mat-select>

  <mat-error
    *ngIf="getError() as error"
  >{{error}}</mat-error>

</mat-form-field>
