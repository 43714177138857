import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ImageableContentReference} from '../../../../core/core.types';
import {Observable} from 'rxjs';
import {CachedSubject} from '../../../../core/cached-subject';
import {DistributionTypeHelper} from '../../../../core/distribution-type-helper';
import {ViewHelper} from '../../../../core/view-helper';
import {ImageUrlHelper} from '../../../../core/image-url-helper';
import {OfflineContent} from '../../../../core/admin-offline.types';
import {IconHelper} from '../../../../core/icon.helper';
import {Router} from '@angular/router';
import {ContentService} from '../../../../core/content/content.service';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'rag-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('title') title:ElementRef;
  @Input() content!: ImageableContentReference;
  @Input() editorMode: boolean = false;
  height = 0;
  nextEvent: OfflineContent.EventSchedule;
  hasLiveEvent: boolean = false;
  iconForType: string = '';
  contentLink: string;
  readonly cardBackgroundStyles$: Observable<any | null>;
  readonly DEFAULT_PICTURE_URL = "assets/images/card_event.jpg";
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL)

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get objSubTypeAsText(): string {
    if (this.content?.blockEvent) {
      return $localize`:@@appointment_series:Appointment series`;
    }
    return $localize`:@@global_event:Event`;
  }

  get offlineContentSubType(): string | null {
    return DistributionTypeHelper.getOfflineContentSubType(this.content.objType, this.content?.objSubType ?? this.content.eventType);
  }

  ngOnInit(): void {
    this.contentLink = ContentService.getContentHref(this.content);
  }

  ngAfterViewInit(): void {
    this.height = this.title?.nativeElement?.offsetHeight ?? 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'content')) {
      this.updateImageUrl();
    }

    this.iconForType = IconHelper.svgIconForObjType(this.content?.objType, this.content?.objSubType);

    const now = new Date().getTime();
    const offlineEvents: OfflineContent.EventSchedule[] = this.content?.offlineEvents;
    const upcomingEvents = offlineEvents
      ?.sort((a,b) => a.eventDate - b.eventDate)
      .filter(o => o.eventDateUntil > now);

    if (upcomingEvents?.length > 0) {
      this.nextEvent = upcomingEvents[0];
      this.hasLiveEvent = this.nextEvent?.eventDate < now;
    }
  }

  onNavigateToDetails(): void {
    if (this.editorMode) {
      return;
    }
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }
}

