import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtOauth2EditComponent } from './ext-oauth2-edit.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../../../core/pipes/pipes.module';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';


@NgModule({
  declarations: [ ExtOauth2EditComponent ],
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    PipesModule,
  ],
})
export class ExtOauth2EditModule {
}
