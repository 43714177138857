import { CanDeactivateFn } from '@angular/router';

export const SideNavContainerHeightGuard: CanDeactivateFn<any> = (): boolean => {
  const sidenavContainerStyle = document.getElementById('sidenavContainer')?.style;
  if (sidenavContainerStyle != null) {
    sidenavContainerStyle.height = 'fit-content'; // - header - footer
  }
  return true;
};

