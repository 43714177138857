<rag-dialog-header>
  {{data.history.name}} : {{data.history.title}}
</rag-dialog-header>

<div mat-dialog-content>
  <div class="header">
    <span i18n="@@ctrl_single_user_steering">Runs after the last certification</span>
  </div>
  <!-- STEERING TABLE -->
  <table mat-table [dataSource]="dataSource">

    <!-- Attempt -->
    <ng-container matColumnDef="attempt">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_attempt">Attempt</span>
      </th>
      <td class="center" mat-cell *matCellDef="let iteration">
        <div>{{iteration.it + 1}}</div>
      </td>
    </ng-container>

    <!-- createAt -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_created_at">Created at</span>
      </th>
      <td class="center" mat-cell *matCellDef="let iteration">{{iteration.creation | formatDate: 'date-time'}}</td>
    </ng-container>

    <!-- startedAt -->
    <ng-container matColumnDef="attemptedAt">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_attempted_at">Attempted at</span>
      </th>
      <td class="center" mat-cell *matCellDef="let iteration">
        <span *ngIf="iteration.start; else noDate">{{iteration.start | formatDate: 'date-time'}}</span>
      </td>
    </ng-container>

    <!-- endedAt -->
    <ng-container matColumnDef="endedAt">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="@@global_accomplished_at">Accomplished at</span>
      </th>
      <td class="center" mat-cell *matCellDef="let iteration">
        <span *ngIf="iteration.end; else noDate">{{iteration.end | formatDate: 'date-time'}}</span>
      </td>
    </ng-container>

    <!-- action -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="center" mat-cell *matCellDef="let iteration">
        <button
          mat-menu-item
          (click)="onDetails(iteration)">
          <mat-icon svgIcon="magnify"></mat-icon>
          <span i18n="@@global_details">Details</span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="steeringColumnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: steeringColumnsToDisplay"></tr>
  </table>

  <mat-paginator
    [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons>
  </mat-paginator>
</div>

<!-- no date is available -->
<ng-template #noDate>
  <span>-</span>
</ng-template>
